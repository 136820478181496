import React, { Component, PropTypes } from 'react';
import { connect } from 'react-redux';
import { Grid, Row, Col } from 'react-bootstrap';
import Modal from 'react-modal';
import { FormattedMessage, injectIntl } from 'react-intl';
import _get from 'lodash/get';
import _isObject from 'lodash/isObject';
import _partial from 'lodash/partial';
import cs from 'classnames';
import {
  toggleCreateModal,
  createVoucherCode,
  clearVoucherGroupDetail,
  getVouchers,
  revokeVoucher,
  exportVouchers
} from '../Voucher.actions';

import routes from '../../../constants/routes-constants';
import AdvancedList from '../../../components/AdvancedList/AdvancedList';
import Loader from '../../../components/MaterialLoader/MaterialLoader';
import BackLink from '../../../components/BackLink/BackLink';
import { getAppFormattedDate, getAppFormattedDateTime, isEmpty, namedCompose } from '../../../utils/utils';
import {
  BOOKING_CREATION_DATE_BETWEEN,
  BOOKING_START_DATE_BETWEEN,
  VOUCHER_MAXIMUM_USES,
  BOOKING_START_PARKING_IN_LIST,
  BOOKING_SUB_COMPANY_IN_LIST,
  SORT_PROPERTY_CREATION_DATE,
  SORT_PROPERTY_CODE,
  SORT_PROPERTY_USAGE,
  SORT_PROPERTY_REVOKED,
  BOOKING_STATUS_SCHEDULED,
  BOOKING_STATUS_COMPLETED,
  PERCENT_DISCOUNT,
  VOUCHER_MAXIMUM_USES_PER_PROFILE
} from '../../../constants/backend-constants';

import { vouchersChips } from '../../../constants/options-constants';
import { modalStyles } from '../../../constants/style-constants';
import autoBind from 'react-autobind';
import { Tabs, Tab } from 'material-ui/Tabs';
import FlatButton from 'material-ui/FlatButton';
import RaisedButton from 'material-ui/RaisedButton';
import AddIcon from 'material-ui/svg-icons/content/add';
import DownloadIcon from 'material-ui/svg-icons/file/file-download';
import CreateVoucherCodeForm from '../CreateVoucherCodeForm/CreateVoucherCodeForm';
import { apiParams } from '../../../constants/api-params-constants';
import pure from 'recompose/pure';
import { localeSelector } from '../../../selectors/all-selectors';
import { routeActions } from 'react-router-redux';
import Filters from '../../../components/AdvancedList/components/Filters';
import { vouchersFilterTypes } from '../../../constants/filterTypes-constants';
import FilterChips from '../../../components/FilterChips/FilterChips';
import ReactSVG from 'react-svg';

class VoucherDetail extends Component {
  constructor(props) {
    super(props);
    autoBind(this);

    this.setHeader();

    this.state = {
      confirmRevokeOpen: false
    };

    const params = props.groupsParams || apiParams.default;
    this.backLink = '/#' + routes.vouchers.path.replace(':search', encodeURIComponent(JSON.stringify(params)));

    this.derivedStateFromProps(props);
  }

  setHeader() {
    this.colProps = [
      {
        md: 3,
        xs: 3
      },
      {
        md: 2,
        xs: 2
      },
      {
        md: 3,
        xs: 3
      },
      {
        md: 2,
        xs: 2
      },
      {
        md: 2,
        xs: 2
      }
    ];

    this.voucherDetailHead = [
      {
        labelKey: 'voucher_code',
        ...this.colProps[0],
        sortId: SORT_PROPERTY_CODE,
        sortable: true
      },
      {
        labelKey: 'voucher_uses',
        ...this.colProps[1],
        sortId: SORT_PROPERTY_USAGE,
        sortable: true
      },
      {
        labelKey: 'common_creation_date',
        ...this.colProps[2],
        sortId: SORT_PROPERTY_CREATION_DATE,
        sortable: true
      },
      {
        labelKey: 'voucher_revoked_label',
        ...this.colProps[3],
        sortId: SORT_PROPERTY_REVOKED,
        sortable: true
      },
      {
        labelKey: 'common_action',
        ...this.colProps[4]
      }
    ];
  }

  componentWillReceiveProps(props) {
    this.derivedStateFromProps(props);
  }

  componentWillUnmount() {
    this.props.dispatch(clearVoucherGroupDetail());
  }

  derivedStateFromProps(props) {
    this.voucherExportLabel = props.exporting
      ? props.intl.formatMessage({ id: 'common_button_exporting' })
      : props.intl.formatMessage({ id: 'common_export' });
    this.yesLabel = props.intl.formatMessage({ id: 'common_yes' });
    this.noLabel = props.intl.formatMessage({ id: 'common_no' });
  }

  handleChangePage(value) {
    const { listMetadata, urlParams } = this.props;
    const params = {
      page: {
        number: value || apiParams.default.page.number,
        size: _get(listMetadata, 'paginationInfo.pageSize') || apiParams.default.page.size
      }
    };
    const newParams = { ...urlParams, ...params };
    this.callApi(newParams);
    this.updateUrlParams(newParams);
  }

  handleRefreshList() {
    this.callApi(this.props.pageParams);
  }

  handleDeleteChip(item) {
    const { urlParams, listMetadata } = this.props;

    delete urlParams[item];
    delete urlParams.page;

    const newParams = {
      ...urlParams,
      page: {
        number: 1,
        size: listMetadata.paginationInfo.pageSize
      }
    };

    this.callApi(newParams);
    this.updateUrlParams(newParams);
  }

  handleExport() {
    const groupName = _get(this.props.groupDetail, 'name');
    this.props.dispatch(exportVouchers(this.props.urlParams, groupName));
  }

  handleNumberResultsSelect(value) {
    const { urlParams } = this.props;
    let params = {
      page: {
        number: 1,
        size: parseInt(value)
      }
    };
    let newParams = { ...urlParams, ...params };
    this.callApi(newParams);
    this.updateUrlParams(newParams);
  }

  cancelRevoke() {
    this.setState({ confirmRevokeOpen: false });
  }

  confirmRevoke() {
    const voucherItem = this.state.voucherItem || {};
    this.setState({ confirmRevokeOpen: false });
    this.props.dispatch(revokeVoucher(_get(voucherItem, 'id'))).then(() => {
      voucherItem.revoked = true;
      this.forceUpdate();
    });
  }

  displayConfirmRevokePopup() {
    return (
      <div className="vouchers-confirm-revoke-container">
        <div className="confirm-message">
          <FormattedMessage id="vouchers_confirm_revoke" />
        </div>
        <div className="code-container">
          <div className="voucher-code">{_get(this.state.voucherItem, 'code')}</div>
        </div>
        <div className="confirm-buttons">
          <RaisedButton className="confirm-button" id="cancel_voucher_revoke" label={this.noLabel} onClick={this.cancelRevoke} />
          <RaisedButton className="confirm-button" primary id="confirm_voucher_revoke" label={this.yesLabel} onClick={this.confirmRevoke} />
        </div>
      </div>
    );
  }

  handleFilterSearch(newSelectedFilters) {
    const { listMetadata, urlParams } = this.props;

    const newParams = {
      ...urlParams,
      ...newSelectedFilters,
      page: {
        number: 1,
        size: listMetadata.paginationInfo.pageSize
      }
    };

    this.callApi(newParams);
    this.updateUrlParams(newParams);
  }

  callApi(params) {
    const id = this.props.params.voucherGroupId;
    this.props.dispatch(getVouchers({ voucherGroupId: id, ...params }));
  }

  getCompanyName(value) {
    const {
      subCompanies: { subList }
    } = this.props;
    return subList.reduce(function(company, item) {
      if (item.id === value) {
        company.push(item.name);
      }
      return company;
    }, []);
  }

  renderItemRules(item, reactKey) {
    return (
      <Row id={reactKey} key={reactKey}>
        <Col className="listTitle" md={4} xs={4}>
          {item.rule === BOOKING_START_DATE_BETWEEN && <FormattedMessage id="voucher_restrict_start_date" />}
          {item.rule === BOOKING_CREATION_DATE_BETWEEN && <FormattedMessage id="voucher_restrict_creation_date" />}
          {item.rule === VOUCHER_MAXIMUM_USES && <FormattedMessage id="voucher_limit_usage" />}
          {item.rule === VOUCHER_MAXIMUM_USES_PER_PROFILE && <FormattedMessage id="voucher_limit_usage_per_profile" />}
          {item.rule === BOOKING_START_PARKING_IN_LIST && <FormattedMessage id="voucher_limit_parkings_usage" />}
          {item.rule === BOOKING_SUB_COMPANY_IN_LIST && <FormattedMessage id="voucher_limit_companies_usage" />}
        </Col>
        <Col className={cs('listValue', 'list-restrictions')} md={8} xs={8}>
          {item.parameters.map((value, index, arr) => (
            <div className={`marginRight10  ${_isObject(value) ? 'inline' : ''}`} key={_isObject(value) ? value.id : value}>
              {item.rule === BOOKING_CREATION_DATE_BETWEEN || item.rule === BOOKING_START_DATE_BETWEEN ? (
                <div className="voucherGroupDetail_displayFlex">
                  <div className="voucherGroupDetail_rulesValueLabel">
                    {!index ? (
                      value ? (
                        <FormattedMessage id="voucher_start_after" />
                      ) : (
                        ''
                      )
                    ) : value ? (
                      <FormattedMessage id="voucher_end_before" />
                    ) : (
                      ''
                    )}
                  </div>
                  {value ? ' ' + getAppFormattedDate(value, { local: true }) : ''}
                </div>
              ) : _isObject(value) ? (
                arr.length === index + 1 ? (
                  value.name
                ) : (
                  value.name + ','
                )
              ) : item.rule === BOOKING_SUB_COMPANY_IN_LIST ? (
                arr.length === index + 1 ? (
                  this.getCompanyName(value)
                ) : (
                  this.getCompanyName(value) + ','
                )
              ) : (
                value
              )}
            </div>
          ))}
        </Col>
      </Row>
    );
  }

  handleRevoke(item) {
    this.setState({ confirmRevokeOpen: true, voucherItem: item });
  }

  renderItemVoucher(item, id, reactKey) {
    const revoked = _get(item, 'revoked');
    return (
      <Row className="advancedList_row nonClickable" id={id} key={reactKey}>
        <Col {...this.colProps[0]}>{_get(item, 'code')}</Col>
        <Col {...this.colProps[1]}>{_get(item, 'uses')}</Col>
        <Col {...this.colProps[2]}>{getAppFormattedDateTime(_get(item, 'creationDate'), { local: true })}</Col>
        <Col {...this.colProps[3]}>{revoked ? <FormattedMessage id="common_yes" /> : <FormattedMessage id="common_no" />}</Col>
        <Col {...this.colProps[4]}>
          {revoked ? (
            <div>-</div>
          ) : (
            <div className="action-button flatButton" onClick={_partial(this.handleRevoke, item)}>
              <FormattedMessage id="common_revoke" />
            </div>
          )}
        </Col>
      </Row>
    );
  }

  handleToggleCreateVoucherModal() {
    this.props.dispatch(toggleCreateModal());
  }

  handleCreateVoucherCode(voucherCodes) {
    this.props.dispatch(createVoucherCode(voucherCodes)).then(() => {
      this.handleToggleCreateVoucherModal();
      setTimeout(() => this.handleRefreshList(), 1000);
    });
  }

  displayLoader() {
    if (this.props.loadingDetail) return <Loader />;
  }

  numberedBlock(icon, id_i18n, status) {
    let countBookingsByStatus = this.props.groupUsedinBookings;
    let linkParams = {
      page: {
        number: 1,
        size: 50
      },
      sort: {
        property: 'START_DATE',
        direction: 'DESC'
      },
      status,
      voucherGroupIds: _get(this.props.groupDetail, 'id')
    };

    if (countBookingsByStatus) {
      const aStatus = countBookingsByStatus.filter(item => {
        return item.bookingStatus === status;
      });

      return (
        <div className="numbered-block">
          <a href={`#${routes.bookings.path.replace(':search', encodeURIComponent(JSON.stringify(linkParams)))}`}>
            {icon}
            <span className="number">{aStatus.length === 1 ? aStatus[0].uses : '0'}</span>
            <FormattedMessage id={id_i18n} />
          </a>
        </div>
      );
    } else {
      return (
        <div className="numbered-loader">
          <Loader />
        </div>
      );
    }
  }

  updateUrlParams(params) {
    if (this.props.isExternal) {
      return;
    }

    const id = _get(this.props, 'params.voucherGroupId');
    const { voucherGroupId, ...cleanParams } = params;

    if (id) {
      let route = routes.voucherGroupDetail.path.replace(':search', encodeURIComponent(JSON.stringify(cleanParams)));
      route = route.replace(':voucherGroupId', id);
      this.props.dispatch(routeActions.push(route));
    }
  }

  handleSort(sortPropertyName) {
    const { urlParams } = this.props;
    const newParams = {
      ...urlParams,
      sort: {
        isDescending: _get(urlParams, 'sort.property', false) === sortPropertyName ? !_get(urlParams, 'sort.isDescending', false) : false,
        property: sortPropertyName
      }
    };
    this.callApi(newParams);
    this.updateUrlParams(newParams);
  }

  formatPrice(price) {
    return isEmpty(price) ? '?' : String(price);
  }

  displayVouchers() {
    return (
      <Row>
        <Col xs={12} md={12}>
          <div className="voucher-detail-container">
            <FilterChips
              id="vouchersChips"
              urlParams={this.props.urlParams}
              onDeleteChip={this.handleDeleteChip}
              translations={vouchersChips}
              filterTypes={vouchersFilterTypes}
            />
            <div className="voucher-detail-actions">
              <Filters id="vouchersFilters" filterTypes={vouchersFilterTypes} onFilteredSearchForm={this.handleFilterSearch} />
              <div className="voucher-detail-buttons">
                <FlatButton
                  id="vouchers_export_button"
                  className="flatButton"
                  disableTouchRipple
                  label={this.voucherExportLabel}
                  icon={<DownloadIcon />}
                  onClick={this.handleExport}
                  disabled={this.props.exporting}
                />
              </div>
            </div>
            <AdvancedList
              id="vouchers"
              data={this.props.list}
              listMetadata={this.props.listMetadata}
              loading={this.props.loadingList}
              error={this.props.errorVouchers}
              onRefreshList={this.handleRefreshList}
              onChangeRowsPerPage={this.handleNumberResultsSelect}
              onChangePage={this.handleChangePage}
              renderItem={this.renderItemVoucher}
              noResultsLabelKey="voucher_no_code"
              header={this.voucherDetailHead}
              onSort={this.handleSort}
              wrapStyle={{ minHeight: '300px' }}
              urlParams={this.props.urlParams}
            />
          </div>
        </Col>
      </Row>
    );
  }

  getValue() {
    const { groupDetail, intl } = this.props;
    if (_get(groupDetail, 'type') === PERCENT_DISCOUNT) return '%';
    return intl.formatMessage({ id: `unit_${_get(groupDetail, 'currency', 'EUR')}` });
  }

  displayDetail() {
    const { groupDetail, groupDetailCompany, intl } = this.props;

    if (!this.props.loadingDetail)
      return (
        <div className="voucherGroupDetail">
          <div className="voucherGroupDetail_actions">
            <h3>
              {!this.props.isExternal && (
                <span>
                  <FormattedMessage id="voucher_group_detail" />{' '}
                </span>
              )}
              {_get(groupDetail, 'name')}
            </h3>
            <div className="advancedList_actions">
              <FlatButton
                id="voucher_create_code_button"
                onClick={this.handleToggleCreateVoucherModal}
                label={intl.messages.voucher_create_code}
                icon={<AddIcon />}
              />
            </div>
          </div>
          <Grid>
            <Row>
              <Col md={6}>
                <div className="voucherGroupDetail_wrap">
                  <div className="voucherGroupDetail_row">
                    <div className="voucherGroupDetail_col2">
                      <Tabs initialSelectedIndex={0} inkBarStyle={{ height: '3px', marginTop: '-3px' }}>
                        <Tab label={<FormattedMessage id="voucher_detail" />} value="0">
                          <Grid className="detail-info-container">
                            <Row>
                              <Col md={4} xs={4} className="listTitle">
                                <FormattedMessage id="voucher_group_name" />:
                              </Col>
                              <Col md={8} xs={8} className="listValue">
                                {_get(groupDetail, 'name')}
                              </Col>
                            </Row>
                            <Row>
                              <Col md={4} xs={4} className="listTitle">
                                Prefix:
                              </Col>
                              <Col md={8} xs={8} className="listValue">
                                {_get(groupDetail, 'prefix')}
                              </Col>
                            </Row>
                            <Row>
                              <Col md={4} xs={4} className="listTitle">
                                <FormattedMessage id="backUserForm_superCompanie_label" />:
                              </Col>
                              <Col md={8} xs={8} className="listValue">
                                {groupDetailCompany && <div>{_get(groupDetailCompany, 'name')}</div>}
                              </Col>
                            </Row>
                            <Row>
                              <Col md={4} xs={4} className="listTitle">
                                <FormattedMessage id="invoices_tableView_label_value" />:
                              </Col>
                              <Col md={8} xs={8} className="listValue">
                                <FormattedMessage
                                  id="common_price_with_currency"
                                  values={{
                                    amount: this.formatPrice(_get(groupDetail, 'discount')),
                                    currency: this.getValue()
                                  }}
                                />
                              </Col>
                            </Row>
                            {(_get(groupDetail, 'maxAmount', false) || _get(groupDetail, 'maxAmount', false) === 0) && (
                              <Row>
                                <Col md={4} xs={4} className="listTitle">
                                  <FormattedMessage id="voucher_maximum_discount" />
                                </Col>
                                <Col md={8} xs={8} className="listValue">
                                  <FormattedMessage
                                    id="common_price_with_currency"
                                    values={{
                                      amount: this.formatPrice(_get(groupDetail, 'maxAmount')),
                                      currency: intl.formatMessage({ id: `unit_${_get(groupDetail, 'currency', 'EUR')}` })
                                    }}
                                  />
                                </Col>
                              </Row>
                            )}
                          </Grid>
                        </Tab>
                        <Tab label="Restrictions" value="1" className="tab" disabled={_get(groupDetail, 'rules.length', 0) === 0}>
                          {_get(groupDetail, 'rules', false) && (
                            <div className="voucher-group-rules">
                              {_get(groupDetail, 'rules.length') > 0 && (
                                <Grid id="voucher-group-rules">
                                  {groupDetail.rules.map(item => {
                                    return this.renderItemRules(item, item.rule);
                                  })}
                                </Grid>
                              )}
                            </div>
                          )}
                        </Tab>
                      </Tabs>
                    </div>
                  </div>
                </div>
              </Col>
              <Col md={6}>
                <div className="related-bookings">
                  <div className="numbered-container">
                    {this.numberedBlock(
                      <ReactSVG src="/img/calendar-clock.svg" svgClassName="icon" />,
                      'vouchers_uses_count_' + BOOKING_STATUS_SCHEDULED,
                      BOOKING_STATUS_SCHEDULED
                    )}
                    {this.numberedBlock(
                      <ReactSVG src="/img/check.svg" svgClassName="icon icon-ready" />,
                      'vouchers_uses_count_' + BOOKING_STATUS_COMPLETED,
                      BOOKING_STATUS_COMPLETED
                    )}
                  </div>
                </div>
              </Col>
            </Row>
            {this.displayVouchers()}
          </Grid>
        </div>
      );
  }

  render() {
    return (
      <div className="pageContainerCentered">
        {!this.props.isExternal && <BackLink link={this.backLink} labelKey="back_link_vouchers_list" />}
        {this.displayLoader()}
        {this.displayDetail()}
        <Modal isOpen={this.props.createVoucherCodeModal} style={modalStyles}>
          <CreateVoucherCodeForm onCallback={this.handleCreateVoucherCode} onCancel={this.handleToggleCreateVoucherModal} />
        </Modal>
        <Modal isOpen={this.state.confirmRevokeOpen} style={modalStyles}>
          {this.displayConfirmRevokePopup()}
        </Modal>
      </div>
    );
  }
}

VoucherDetail.propTypes = {
  isExternal: PropTypes.bool,
  params: PropTypes.object
};

export default namedCompose(
  connect(state => {
    const {
      vouchers: { exporting, groupDetail, loadingDetail, groupDetailCompany, createVoucherCodeModal, groupUsedinBookings },
      subCompanies,
      routing: {
        location: { pathname }
      }
    } = state;

    const vouchers = _get(state, 'vouchers');
    const paginatedResults = _get(vouchers, 'voucherResults');

    const pageInfo = _get(paginatedResults, 'paginationInfo');
    const listMetadata = pageInfo ? { paginationInfo: pageInfo } : {};

    const page = _get(state, 'page');

    return {
      urlParams: _get(vouchers, 'voucherDetailParams', {}),
      groupsParams: _get(vouchers, 'urlParams', {}),
      list: _get(paginatedResults, 'results', []),
      loadingList: _get(vouchers, 'loadingList'),
      errorVouchers: _get(vouchers, 'errorVouchers', false),
      pageParams: _get(page, 'params', {}),
      groupDetail,
      exporting,
      loadingDetail,
      pathname,
      groupDetailCompany,
      groupUsedinBookings,
      createVoucherCodeModal,
      listMetadata,
      subCompanies,
      locale: localeSelector(state) // 'pure' exception
    };
  }),
  injectIntl,
  pure
)(VoucherDetail);
