import React, { Component, PropTypes } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import classNames from 'classnames';

import EkButton from '../EkButton/EkButton';
import BoxedInput from '../BoxedInput/BoxedInput';
import { createValidator, number, notEmpty, positiveNumber, minimum } from '../../validation/all-validation';
import FieldErrorMsg from '../../components/FieldErrorMsg/FieldErrorMsg';

class TransversalCategoryPricingForm extends Component {
  render() {
    const {
      fields: { pricePerKm, freeKmPerDay, lateFeePrice, latePricePerHour, lateFeeGraceInMin, minimumDurationPrice },
      handleSubmit,
      limitedAccess,
      currency,
      intl
    } = this.props;

    const symbolCurrency = intl.formatMessage({ id: `unit_${currency}` });
    const submitAction = limitedAccess ? undefined : handleSubmit(this.props.onCallback);

    return (
      <form action="#" className="transversalCategoryPricingForm" onSubmit={submitAction}>
        {this.props.label && (
          <h4 className={this.props.label.class}>
            <FormattedMessage id={this.props.label.key} />
          </h4>
        )}
        <ul className="transversalCategoryPricingForm_InputBlock">
          <li className="transversalCategoryPricingForm_InputBlock_item">
            <label
              className="transversalCategoryPricingForm_InputBlock_label"
              htmlFor="transversalCategoryPricingForm_InputBlock_pricePerKm"
            >
              <FormattedMessage id="transversalCategoryPricingForm_label_pricePerKm" />
            </label>
            <br />

            <BoxedInput
              type="text"
              id="transversalCategoryPricingForm_InputBlock_pricePerKm"
              customClass="transversalCategoryPricingForm_InputBlock_inputWrapper"
              field={pricePerKm}
              disabled={limitedAccess}
              placeholder="0"
              customInputClass={classNames('transversalCategoryPricingForm_InputBlock_input', {
                _has_error: pricePerKm.error && pricePerKm.touched
              })}
            >
              <FieldErrorMsg customClass="fieldErrorMsg--transversalCategoryPricingForm" field={pricePerKm} />
            </BoxedInput>
            <span className="transversalCategoryPricingForm_InputBlock_unit">{symbolCurrency}</span>
          </li>

          <li className="transversalCategoryPricingForm_InputBlock_item">
            <label
              className="transversalCategoryPricingForm_InputBlock_label"
              htmlFor="transversalCategoryPricingForm_InputBlock_packagePrice"
            >
              <FormattedMessage id="common_free_km_per_day" />
            </label>
            <br />
            <BoxedInput
              type="text"
              id="transversalCategoryPricingForm_InputBlock_packagePrice"
              customClass="transversalCategoryPricingForm_InputBlock_inputWrapper"
              field={freeKmPerDay}
              disabled={limitedAccess}
              placeholder="0"
              customInputClass={classNames('transversalCategoryPricingForm_InputBlock_input', {
                _has_error: freeKmPerDay.error && freeKmPerDay.touched
              })}
            >
              <FieldErrorMsg customClass="fieldErrorMsg--transversalCategoryPricingForm" field={freeKmPerDay} />
            </BoxedInput>
            <span className="transversalCategoryPricingForm_InputBlock_unit">km</span>
          </li>

          <li className="transversalCategoryPricingForm_InputBlock_item">
            <label
              className="transversalCategoryPricingForm_InputBlock_label"
              htmlFor="transversalCategoryPricingForm_InputBlock_lateFeePrice"
            >
              <FormattedMessage id="transversalCategoryPricingForm_label_lateFeePrice" />
            </label>
            <br />
            <BoxedInput
              type="text"
              id="transversalCategoryPricingForm_InputBlock_lateFeePrice"
              customClass="transversalCategoryPricingForm_InputBlock_inputWrapper"
              field={lateFeePrice}
              disabled={limitedAccess}
              placeholder="0"
              customInputClass={classNames('transversalCategoryPricingForm_InputBlock_input', {
                _has_error: lateFeePrice.error && lateFeePrice.touched
              })}
            >
              <FieldErrorMsg customClass="fieldErrorMsg--transversalCategoryPricingForm" field={lateFeePrice} />
            </BoxedInput>
            <span className="transversalCategoryPricingForm_InputBlock_unit">{symbolCurrency}</span>
          </li>

          <li className="transversalCategoryPricingForm_InputBlock_item">
            <label
              className="transversalCategoryPricingForm_InputBlock_label"
              htmlFor="transversalCategoryPricingForm_InputBlock_latePricePerHour"
            >
              <FormattedMessage id="transversalCategoryPricingForm_label_latePricePerHour" />
            </label>
            <br />
            <BoxedInput
              type="text"
              id="transversalCategoryPricingForm_InputBlock_latePricePerHour"
              customClass="transversalCategoryPricingForm_InputBlock_inputWrapper"
              field={latePricePerHour}
              disabled={limitedAccess}
              placeholder="0"
              customInputClass={classNames('transversalCategoryPricingForm_InputBlock_input', {
                _has_error: latePricePerHour.error && latePricePerHour.touched
              })}
            >
              <FieldErrorMsg customClass="fieldErrorMsg--transversalCategoryPricingForm" field={latePricePerHour} />
            </BoxedInput>
            <span className="transversalCategoryPricingForm_InputBlock_unit">{symbolCurrency}</span>
          </li>
          <li className="transversalCategoryPricingForm_InputBlock_item">
            <label
              className="transversalCategoryPricingForm_InputBlock_label"
              htmlFor="transversalCategoryPricingForm_InputBlock_minimumPrice"
            >
              <FormattedMessage id="transversalCategoryPricingForm_label_minimum_price" />
            </label>
            <br />
            <BoxedInput
              type="text"
              id="transversalCategoryPricingForm_InputBlock_minimumPrice"
              customClass="transversalCategoryPricingForm_InputBlock_inputWrapper"
              field={minimumDurationPrice}
              disabled={limitedAccess}
              placeholder="0"
              customInputClass={classNames('transversalCategoryPricingForm_InputBlock_input', {
                _has_error: minimumDurationPrice.error && minimumDurationPrice.touched
              })}
            >
              <FieldErrorMsg customClass="fieldErrorMsg--transversalCategoryPricingForm" field={minimumDurationPrice} />
            </BoxedInput>
            <span className="transversalCategoryPricingForm_InputBlock_unit">{symbolCurrency}</span>
          </li>
          <li className="transversalCategoryPricingForm_InputBlock_item">
            <label
              className="transversalCategoryPricingForm_InputBlock_label"
              htmlFor="transversalCategoryPricingForm_InputBlock_lateFeeGraceInMin"
            >
              <FormattedMessage id="transversalCategoryPricingForm_label_lateFeeGraceInMin" />
            </label>
            <br />
            <BoxedInput
              type="number"
              id="transversalCategoryPricingForm_InputBlock_lateFeeGraceInMin"
              customClass="transversalCategoryPricingForm_InputBlock_inputWrapper"
              field={lateFeeGraceInMin}
              disabled={limitedAccess}
              placeholder="0"
              customInputClass={classNames('transversalCategoryPricingForm_InputBlock_input', {
                _has_error: lateFeeGraceInMin.error && lateFeeGraceInMin.touched
              })}
            >
              <FieldErrorMsg customClass="fieldErrorMsg--transversalCategoryPricingForm" field={lateFeeGraceInMin} />
            </BoxedInput>
            <span className="transversalCategoryPricingForm_InputBlock_unit">min</span>
          </li>
        </ul>

        {!limitedAccess && (
          <EkButton customClass="transversalCategoryPricingForm_submitButton" onAction={submitAction}>
            <FormattedMessage id="transversalCategoryPricingForm_submitButton_label" />
          </EkButton>
        )}
      </form>
    );
  }
}

TransversalCategoryPricingForm.displayName = 'TransversalCategoryPricingForm';

TransversalCategoryPricingForm.propTypes = {
  fields: PropTypes.object,
  onCallback: PropTypes.func,
  handleSubmit: PropTypes.func,
  limitedAccess: PropTypes.bool,
  currency: PropTypes.string
};

TransversalCategoryPricingForm = reduxForm({
  form: 'transversalCategoryPricingForm',
  fields: ['pricePerKm', 'freeKmPerDay', 'lateFeePrice', 'latePricePerHour', 'lateFeeGraceInMin', 'minimumDurationPrice'],
  validate: createValidator({
    pricePerKm: [notEmpty(), number(), positiveNumber()],
    freeKmPerDay: [notEmpty(), number(), positiveNumber()],
    lateFeePrice: [notEmpty(), number(), positiveNumber()],
    latePricePerHour: [notEmpty(), number(), positiveNumber()],
    lateFeeGraceInMin: [notEmpty(), number(), positiveNumber()],
    minimumDurationPrice: [notEmpty(), number(), positiveNumber()]
  })
})(TransversalCategoryPricingForm);

export default connect(state => {
  const {} = state;

  return {};
})(injectIntl(TransversalCategoryPricingForm));
