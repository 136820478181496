import React, { PropTypes } from 'react';
import { FormattedMessage } from 'react-intl';
import _get from 'lodash/get';
import _find from 'lodash/find';
import _isBoolean from 'lodash/isBoolean';
import TextureIcon from 'material-ui/svg-icons/image/texture';
import FileImage from '../FileImage/FileImage.jsx';
import { CUSTOM_FIELD_TYPE_FILE } from '../../constants/backend-constants';
import { iconStyles } from '../../constants/style-constants';
import Tooltip from '../Tooltip/Tooltip';
import { boolMap } from '../../utils/component';
import { getCompanyNameUrl, safe } from '../../utils/utils';

const displayTermsFields = terms => {
  if (terms) {
    return Object.keys(terms).map((k, i) => {
      return (
        <tr className="detailView_tr" key={i}>
          <td className="detailView_td_type_3">
            <span className="detailView_cell_type_1">{<FormattedMessage id={`${'member.agrement.' + k}`} />}</span>
          </td>
          <td className="detailView_td_type_3">
            <span className="detailView_cell_type_1">{boolMap(terms[k])}</span>
          </td>
        </tr>
      );
    });
  }
};

const getMemberTypeField = props => {
  const name = safe(() => props.detailMember.memberType.name);
  const enabled = props.memberRules.type;

  if (name && enabled) {
    return (
      <tr className="detailView_tr">
        <td className="detailView_td_type_3">
          <span className="detailView_cell_type_1">
            <FormattedMessage id="members_detail_professional" />
          </span>
        </td>
        <td className="detailView_td_type_3">
          <span className="detailView_cell_type_1">{name}</span>
        </td>
      </tr>
    );
  }
};

const MemberMoreInfo = props => {
  const { detailMember, showStatus, customFields, locale, customFieldsFiles, customFilesErrors } = props;

  return (
    <div className="memberView_moreInfo">
      <h4>
        <FormattedMessage id="memberDetail_additionalInformation_subtitle" />
      </h4>
      <div className="memberView_moreInfo_body">
        <table>
          <tbody>
            {showStatus && _get(detailMember, 'profileReviewStatus') && (
              <tr className="detailView_tr">
                <td className="detailView_td_type_3">
                  <span className="detailView_cell_type_1">
                    <FormattedMessage id="members_detail_status" />
                  </span>
                </td>
                <td className="detailView_td_type_3">
                  <span className="detailView_cell_type_1">
                    <span className={`member_status--${detailMember.profileReviewStatus.toLowerCase()}`}>
                      <FormattedMessage id={`members_status_${detailMember.profileReviewStatus.toLowerCase()}`} />
                    </span>
                  </span>
                </td>
              </tr>
            )}
            {_get(detailMember, 'subscriptionOrigin') && (
              <tr className="detailView_tr">
                <td className="detailView_td_type_3">
                  <span className="detailView_cell_type_1">
                    <FormattedMessage id="member_subscription_origin" />
                  </span>
                </td>
                <td className="detailView_td_type_3">
                  <FormattedMessage id={`member_subscription_origin_${detailMember.subscriptionOrigin.toLowerCase()}`} />
                </td>
              </tr>
            )}
            {_get(detailMember, 'civility') && (
              <tr className="detailView_tr">
                <td className="detailView_td_type_3">
                  <span className="detailView_cell_type_1">
                    <FormattedMessage id="members_detail_civility" />
                  </span>
                </td>
                <td className="detailView_td_type_3">
                  <span className="detailView_cell_type_1">{detailMember.civility}</span>
                </td>
              </tr>
            )}
            {getMemberTypeField(props)}
            {_isBoolean(_get(detailMember, 'suspended')) && (
              <tr className="detailView_tr">
                <td className="detailView_td_type_3">
                  <span className="detailView_cell_type_1">
                    <FormattedMessage id="members_detail_suspended" />
                  </span>
                </td>
                <td className="detailView_td_type_3">
                  <span className={`detailView_cell_type suspended_${detailMember.suspended}`}>
                    <FormattedMessage id={`members_detail_suspended_${detailMember.suspended}`} />
                  </span>
                </td>
              </tr>
            )}
            {_get(detailMember, 'company.name') && (
              <tr className="detailView_tr">
                <td className="detailView_td_type_3">
                  <div className="detailView_cell_type_1">
                    <FormattedMessage id="members_detail_enterprise_name" />
                  </div>
                </td>
                <td className="detailView_td_type_3">
                  <div className="detailView_cell_type_1">
                    {getCompanyNameUrl(detailMember.company.name, _get(detailMember, 'company.id'))}
                  </div>
                </td>
              </tr>
            )}

            {displayTermsFields(safe(() => detailMember.termsAndConditionsAcceptance))}

            {customFields &&
              customFields.length > 0 &&
              customFields.map((field, index) => {
                const customField = _get(field, 'companyCustomField');

                return (
                  <tr className="detailView_tr" key={_get(customField, 'id', index)}>
                    <td className="detailView_td_type_3">
                      <span className="detailView_cell_type_1">
                        {_get(
                          _find(customField.companyCustomFieldLabels, label => label.language === locale),
                          'label'
                        ) || _get(customField, 'name', <FormattedMessage id="common_unknown" />)}
                      </span>
                    </td>
                    {_get(field, 'companyCustomField.fieldType') !== CUSTOM_FIELD_TYPE_FILE && field.value && (
                      <td className="detailView_td_type_3">
                        <span className="detailView_cell_type_1">{field.value}</span>
                      </td>
                    )}
                    {_get(field, 'companyCustomField.fieldType') === CUSTOM_FIELD_TYPE_FILE &&
                      field.value &&
                      !_get(customFilesErrors, _get(field, 'value')) && (
                        <td className="detailView_td_type_3">
                          <span className="detailView_cell_type_1">
                            <FileImage imageObject={customFieldsFiles[field.value]} />
                          </span>
                        </td>
                      )}
                    {_get(field, 'companyCustomField.fieldType') === CUSTOM_FIELD_TYPE_FILE &&
                      field.value &&
                      _get(customFilesErrors, _get(field, 'value')) && (
                        <td className="detailView_td_type_3">
                          <span className="detailView_cell_type_1">
                            <Tooltip content={<FormattedMessage id="common_broken_image" />} placement="bottom">
                              <TextureIcon style={iconStyles.all} />
                            </Tooltip>
                          </span>
                        </td>
                      )}
                    {!field.value && (
                      <td className="detailView_td_type_3">
                        <span className="detailView_cell_type_1">-</span>
                      </td>
                    )}
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

MemberMoreInfo.displayName = 'MemberMoreInfo';

MemberMoreInfo.defaultProps = {
  showStatus: false,
  customFilesErrors: {}
};

MemberMoreInfo.propTypes = {
  showStatus: PropTypes.bool,
  memberRules: PropTypes.object,
  detailMember: PropTypes.object.isRequired,
  customFields: PropTypes.array,
  customFieldsFiles: PropTypes.object,
  customFilesErrors: PropTypes.object,
  locale: PropTypes.string
};

export default MemberMoreInfo;
