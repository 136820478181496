import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import classNames from 'classnames';
import MenuIcon from 'material-ui/svg-icons/navigation/menu';
import IconButton from 'material-ui/IconButton';

import config from '../../constants/config-constants';
import { toggleSideSubMenu, openSideMenu, openSideSubMenu, handleToggleMobileMenu } from '../../actions/all-actions';
import routes, {
  configurationsRules,
  hotlineRules,
  backuserRules,
  smartcardRules,
  smartcardEventRules,
  invoiceRules,
  voucherRules,
  bankoutRules,
  contractRules,
  supportRules
} from '../../constants/routes-constants';

import IsGranted from '../IsGranted/IsGranted';
import {
  BACKUSER_ROLE_ADMIN,
  BACKUSER_ROLE_CALL_CENTER_OPERATOR,
  BACKUSER_ROLE_SUPER_ADMIN,
  BACKUSER_ROLE_FLEET_MANAGER,
  BACKUSER_ROLE_ROOT,
  SORT_PROPERTY_START_DATE,
  SORT_PROPERTY_LASTNAME,
  SORT_PROPERTY_NAME,
  SORT_PROPERTY_CREATION_DATE,
  BACKUSER_ROLE_SERVICE_ADVISOR,
  BACKUSER_ROLE_CRM
} from '../../constants/backend-constants';
import { fillDark } from '../../constants/style-constants';
import { apiParams } from '../../constants/api-params-constants';
import { getMsg } from '../../utils/IntlGlobalProvider';
import {
  headerContractBookingValuesSelector,
  headerContractMemberValuesSelector,
  headerContractPaymentValuesSelector,
  headerContractReportingValuesSelector
} from '../../selectors/all-selectors';
import { strEqual } from '../../utils/utils';

class SideMenu extends Component {
  constructor(props) {
    super(props);

    this.handleToggleSubMenu = this.handleToggleSubMenu.bind(this);
    this.handleToggleFleetMenu = this.handleToggleFleetMenu.bind(this);
    this.handleToggleAccountMenu = this.handleToggleAccountMenu.bind(this);
    this.handleToggleMembersMenu = this.handleToggleMembersMenu.bind(this);
    this.handleToggleSettingsMenu = this.handleToggleSettingsMenu.bind(this);
    this.handleToggleMobileMenu = this.handleToggleMobileMenu.bind(this);
    this.handleToggleReportsMenu = this.handleToggleReportsMenu.bind(this);
  }

  handleToggleSubMenu(target) {
    const { dispatch } = this.props;
    dispatch(toggleSideSubMenu(target));
    if (!this.props.isOpen) {
      dispatch(openSideMenu());
      dispatch(openSideSubMenu(target));
    }
  }

  handleToggleFleetMenu(e) {
    this.handleToggleSubMenu('fleet', e);
  }

  handleToggleAccountMenu(e) {
    this.handleToggleSubMenu('accountManagement', e);
  }

  handleToggleMembersMenu(e) {
    this.handleToggleSubMenu('members', e);
  }

  handleToggleReportsMenu(e) {
    this.handleToggleSubMenu('reports', e);
  }

  handleToggleSettingsMenu(e) {
    this.handleToggleSubMenu('generalSettings', e);
  }

  handleToggleMobileMenu() {
    this.props.dispatch(handleToggleMobileMenu());
  }

  displayOrganizationItem() {
    if (config.devMode || config.enableMenuItems.organization) {
      if (this.props.enableNewUi) {
        return (
          <li
            className={classNames('sideMenu_section', 'sideMenu_section--organization', {
              _is_active: this.props.group === 'organization'
            })}
          >
            <a href={`#${routes.organization.path}`} className="sideMenu_item">
              <FormattedMessage id="common_organization" />
            </a>
          </li>
        );
      }
    }
  }

  contractQuickSightEnabled() {
    return this.props.reportingRules.quickSight;
  }

  contractVehicleStatusEnabled() {
    return this.props.reportingRules.vehicleStatus;
  }

  getScheduledExportItem() {
    if (config.enableMenuItems.scheduledExport) {
      const liClassNames = classNames('sideMenu_section', 'sideMenu_section--scheduledExport', {
        _is_active: this.scheduledExportIsActive()
      });

      return (
        <li className={liClassNames}>
          <a href={`#${routes.scheduledExport.path}`} className="sideMenu_item">
            <FormattedMessage id="feature.scheduled.export" />
          </a>
        </li>
      );
    }
  }

  getQuickSightItem() {
    if (config.enableMenuItems.quickSight && this.contractQuickSightEnabled()) {
      const { currentPage } = this.props;
      const href = `#${routes.quickSight.path}`;

      const liClassNames = classNames('sideMenu_section', 'sideMenu_section--quickSight', {
        _is_active: currentPage === 'quickSight'
      });

      return (
        <li className={liClassNames}>
          <a href={href} className="sideMenu_item">
            {getMsg('label.quick.sight')}
          </a>
        </li>
      );
    }
  }

  getVehicleStatusItem() {
    if (config.enableMenuItems.vehicleStatuses && this.contractVehicleStatusEnabled()) {
      const defaultParams = {
        ...apiParams.default,
        ...apiParams.fleet
      };

      const { currentPage } = this.props;
      const params = encodeURIComponent(JSON.stringify(defaultParams));
      const href = `#${routes.vehicleStatuses.path.replace(':search', params)}`;

      const liClassNames = classNames('sideMenu_section', 'sideMenu_section--vehicleStatuses', {
        _is_active: currentPage === 'vehicleStatuses'
      });

      return (
        <li className={liClassNames}>
          <a href={href} className="sideMenu_item">
            <FormattedMessage id="side_menu_section_reports_vehicle_statuses" />
          </a>
        </li>
      );
    }
  }

  configReportingEnabled() {
    const { quickSight, vehicleStatuses } = config.enableMenuItems;
    return quickSight || vehicleStatuses;
  }

  contractReportingEnabled() {
    return this.contractQuickSightEnabled() || this.contractVehicleStatusEnabled();
  }

  getMenuSectionReports() {
    const { isReportsOpen, currentPage } = this.props;

    const liClassNames = classNames('sideMenu_section', 'sideMenu_section--reports', {
      _is_open: isReportsOpen,
      _is_active: strEqual(['vehicleStatuses', 'quickSight', 'scheduledExportDetails'], currentPage)
    });

    if (this.configReportingEnabled() && this.contractReportingEnabled()) {
      return (
        <li className={liClassNames}>
          <button className={classNames('sideMenu_item', 'sideMenu_item--subnav')} onClick={this.handleToggleReportsMenu}>
            <FormattedMessage id="side_menu_section_reports" />
          </button>
          <ul className="sideMenu_sections--subnav">{this.getVehicleStatusItem()}</ul>
          <ul className="sideMenu_sections--subnav">{this.getQuickSightItem()}</ul>
          <ul className="sideMenu_sections--subnav">{this.getScheduledExportItem()}</ul>
        </li>
      );
    }
  }

  vehicleBrands() {
    const { currentPage, intl } = this.props;

    return (
      <li
        className={classNames('sideMenu_section', 'sideMenu_section--brands', {
          _is_active:
            currentPage === 'brands' ||
            currentPage === 'addBrand' ||
            currentPage === 'editBrand' ||
            currentPage === 'addModel' ||
            currentPage === 'editModel' ||
            currentPage === 'addVersion' ||
            currentPage === 'editVersion'
        })}
      >
        <a href={`#${routes.brands.path}`} className="sideMenu_item">
          {intl.messages.side_menu_section_settings_brands}
        </a>
      </li>
    );
  }

  newVehicleBrands() {
    return (
      <li
        className={classNames('sideMenu_section', 'sideMenu_section--vehicleBrands', {
          _is_active: this.vehicleBrandsIsActive()
        })}
      >
        <a href={`#${routes.vehicleBrands.path}`} className="sideMenu_item">
          <FormattedMessage id="side_menu_section_settings_brands" />
        </a>
      </li>
    );
  }

  getBrandsButton() {
    if (config.enableMenuItems.brands) {
      return this.newVehicleBrands();
    }
  }

  getSupportButton() {
    if (config.enableMenuItems.support) {
      return (
        <IsGranted allowed={supportRules.include} disallowed={supportRules.exclude}>
          <li
            className={classNames('sideMenu_section', `sideMenu_section--hotlines`, {
              _is_active: this.supportIsActive()
            })}
          >
            <a href={`#${routes.support.path}`} className="sideMenu_item">
              {this.props.intl.messages.side_menu_section_settings_support}
            </a>
          </li>
        </IsGranted>
      );
    }
  }

  getVouchersPath() {
    return routes.vouchersV2.path;
  }

  getBookingsPath() {
    return this.props.enableNewUi
      ? routes.bookingsV2.path
      : routes.bookings.path.replace(
          ':search',
          encodeURIComponent(
            JSON.stringify({
              ...apiParams.default,
              sort: {
                isDescending: true,
                property: SORT_PROPERTY_START_DATE
              }
            })
          )
        );
  }

  getInvoicesPath() {
    return routes.invoicesV2.path;
  }

  getVpPath() {
    return this.props.enableVpV1 ? routes.vehiclePlanning.path : routes.vehiclePlanningV2.path;
  }

  getColorsPath() {
    return routes.vehicleColors.path;
  }

  getCategoriesPath() {
    return routes.vehicleCategories.path;
  }

  getDashboardPath() {
    return this.props.enableNewUi ? routes.dashboardV2.path : routes.dashboard.path;
  }

  getContractPath() {
    return routes.contractV2.path;
  }

  getDashboardClass() {
    return this.props.enableNewUi ? 'dashboardV2' : 'dashboard';
  }

  getVouchersClass() {
    return 'voucherV2';
  }

  getBookingsClass() {
    return this.props.enableNewUi ? 'bookingV2' : 'booking';
  }

  getInvoicesClass() {
    return 'invoiceV2';
  }

  getSettingsClass() {
    return 'settingsV2';
  }

  getCategoriesClass() {
    return 'categoriesV2';
  }

  getVpClass() {
    return this.props.enableVpV1 ? 'vehiclePlanning' : 'vehiclePlanningV2';
  }

  getColorsClass() {
    return 'vehicleColors';
  }

  getAccountClass() {
    return this.props.enableNewUi ? 'accountV2' : 'accountManagement';
  }

  getContractClass() {
    return 'contractV2';
  }

  contractIsActive() {
    const { currentPage } = this.props;
    return currentPage === 'contract' || currentPage === 'contractV2';
  }

  bookingsIsActive() {
    const { currentPage, group } = this.props;
    return (
      ['bookings', 'bookingDetail', 'bookingFindMember', 'bookingFindVehicle', 'bookingEventsHistory', 'editBooking'].includes(
        currentPage
      ) || group === routes.bookingsV2.group
    );
  }

  invoicesIsActive() {
    const { currentPage, group } = this.props;
    return strEqual(['invoices', 'invoiceFindBooking', 'createInvoice'], currentPage) || group === routes.invoicesV2.group;
  }

  voucherIsActive() {
    const { currentPage, group } = this.props;
    return strEqual(['vouchers', 'voucherGroupDetail', 'addVoucherGroup'], currentPage) || group === routes.vouchersV2.group;
  }

  vehicleBrandsIsActive() {
    return this.props.group === routes.vehicleBrands.group;
  }

  scheduledExportIsActive() {
    return this.props.group === routes.scheduledExport.group;
  }

  categoriesIsActive() {
    const { currentPage, group } = this.props;
    return (
      strEqual(['vehiclesCategories', 'addVehiclesCategory', 'editVehiclesCategory'], currentPage) ||
      group === routes.vehicleCategories.group
    );
  }

  vpIsActive() {
    return strEqual(['vehiclePlanning', 'vehiclePlanningV2'], this.props.currentPage);
  }

  colorsIsActive() {
    const { currentPage, group } = this.props;
    return strEqual(['vehiclesColors', 'addVehiclesColor', 'editVehiclesColor'], currentPage) || group === routes.vehicleColors.group;
  }

  supportIsActive() {
    const { currentPage, group } = this.props;
    return strEqual(['support', 'configurationsV2', 'hotlinesV2'], currentPage) || group === routes.support.group;
  }

  dashboardIsActive() {
    const { currentPage } = this.props;
    return strEqual(['dashboard', 'dashboardV2'], currentPage);
  }

  getMemberTypes(show) {
    const { currentPage, intl } = this.props;

    if (show && config.enableMenuItems.memberTypes) {
      return (
        <li
          className={classNames('sideMenu_section', 'sideMenu_section--memberTypes', {
            _is_active: currentPage === 'memberTypes'
          })}
        >
          <a href={`#${routes.memberTypes.path}`} className="sideMenu_item">
            {intl.messages.side_menu_section_member_types}
          </a>
        </li>
      );
    }
  }

  getVouchersItem(show) {
    if (show && config.enableMenuItems.vouchers) {
      return (
        <IsGranted allowed={voucherRules.include} disallowed={voucherRules.exclude}>
          <li
            className={classNames('sideMenu_section', `sideMenu_section--${this.getVouchersClass()}`, {
              _is_active: this.voucherIsActive()
            })}
          >
            <a href={`#${this.getVouchersPath()}`} className="sideMenu_item">
              {this.props.intl.messages.side_menu_section_vouchers}
            </a>
          </li>
        </IsGranted>
      );
    }
  }

  getSmartcards(show) {
    const { currentPage, intl } = this.props;

    if (show && config.enableMenuItems.smartcards) {
      return (
        <IsGranted allowed={smartcardRules.include} disallowed={smartcardRules.exclude}>
          <li
            className={classNames('sideMenu_section', 'sideMenu_section--smartcard', {
              _is_active: currentPage === 'smartcards' || currentPage === 'smartcardsDetail'
            })}
          >
            <a
              href={`#${routes.smartcards.path.replace(':search', encodeURIComponent(JSON.stringify(apiParams.default)))}`}
              className="sideMenu_item"
            >
              {intl.messages.side_menu_section_users_smartcards}
            </a>
          </li>
        </IsGranted>
      );
    }
  }

  getSmartcardEvents(show) {
    const { currentPage, intl } = this.props;

    if (show && config.enableMenuItems.smartcardsEvents) {
      return (
        <IsGranted allowed={smartcardEventRules.include} disallowed={smartcardEventRules.exclude}>
          <li
            className={classNames('sideMenu_section', 'sideMenu_section--smartcardsEvents', {
              _is_active: currentPage === 'smartcardsEvents'
            })}
          >
            <a
              href={`#${routes.smartcardsEvents.path.replace(':search', encodeURIComponent(JSON.stringify(apiParams.default)))}`}
              className="sideMenu_item"
            >
              {intl.messages.side_menu_section_users_smartcards_events}
            </a>
          </li>
        </IsGranted>
      );
    }
  }

  render() {
    const {
      isOpen,
      isOpenMobile,
      isGeneralSettingsOpen,
      isAccountManagementOpen,
      isFleetOpen,
      isMembersOpen,
      currentPage,
      paymentRules,
      bookingRules,
      memberRules,
      intl
    } = this.props;

    return (
      <nav className={`sideMenu ${isOpen ? '' : '_is_close'}`}>
        {isOpenMobile && <div className="sideMenu_mobileMenuCloseOverlay" onClick={this.handleToggleMobileMenu} />}
        <ul className={classNames('sideMenu_sections', { openMobile: isOpenMobile })}>
          <li className="sideMenu_mobileTop">
            <IconButton iconStyle={fillDark} onClick={this.handleToggleMobileMenu}>
              <MenuIcon />
            </IconButton>
            <img alt="" className="sideMenuMobile_logo marginLeft12" />
          </li>

          {config.enableMenuItems.dashboard && (
            <li
              className={classNames('sideMenu_section', `sideMenu_section--${this.getDashboardClass()}`, {
                _is_active: this.dashboardIsActive()
              })}
            >
              <a href={`#${this.getDashboardPath()}`} className="sideMenu_item">
                {intl.messages.side_menu_section_dashboard}
              </a>
            </li>
          )}

          {this.displayOrganizationItem()}

          <IsGranted allowed={[BACKUSER_ROLE_FLEET_MANAGER]}>
            {config.enableMenuItems.subCompanies && (
              <li
                className={classNames('sideMenu_section', 'sideMenu_section--accountManagement', {
                  _is_active: currentPage === 'subCompanies' || currentPage === 'addSubCompany' || currentPage === 'subCompanyDetails'
                })}
              >
                <a href={`#${routes.subCompanies.path}`} className="sideMenu_item">
                  {intl.messages.side_menu_section_account_sub_companies}
                </a>
              </li>
            )}
          </IsGranted>

          <IsGranted allowed={[BACKUSER_ROLE_CALL_CENTER_OPERATOR, BACKUSER_ROLE_SUPER_ADMIN, BACKUSER_ROLE_ADMIN, BACKUSER_ROLE_ROOT]}>
            <li
              className={classNames('sideMenu_section', `sideMenu_section--${this.getSettingsClass()}`, {
                _is_open: isGeneralSettingsOpen,
                _is_active:
                  currentPage === 'vehiclesColors' ||
                  currentPage === 'addVehiclesColor' ||
                  currentPage === 'editVehiclesColor' ||
                  currentPage === 'brands' ||
                  currentPage === 'addBrand' ||
                  currentPage === 'editBrand' ||
                  currentPage === 'addModel' ||
                  currentPage === 'editModel' ||
                  currentPage === 'addVersion' ||
                  currentPage === 'editVersion' ||
                  this.supportIsActive() ||
                  this.vehicleBrandsIsActive()
              })}
            >
              <button className={classNames('sideMenu_item', 'sideMenu_item--subnav')} onClick={this.handleToggleSettingsMenu}>
                {intl.messages.side_menu_section_settings}
              </button>
              <ul className="sideMenu_sections--subnav">
                {config.enableMenuItems.vehiclesColors && (
                  <li
                    className={classNames('sideMenu_section', `sideMenu_section--${this.getColorsClass()}`, {
                      _is_active: this.colorsIsActive()
                    })}
                  >
                    <a href={`#${this.getColorsPath()}`} className="sideMenu_item">
                      {intl.messages.side_menu_section_settings_vehicles_colors}
                    </a>
                  </li>
                )}

                {this.getSupportButton()}

                {this.getBrandsButton()}

                {config.enableMenuItems.vehiclesCategories && (
                  <li
                    className={classNames('sideMenu_section', `sideMenu_section--${this.getCategoriesClass()}`, {
                      _is_active: this.categoriesIsActive()
                    })}
                  >
                    <a href={`#${this.getCategoriesPath()}`} className="sideMenu_item">
                      {intl.messages.side_menu_section_settings_vehicles_categories}
                    </a>
                  </li>
                )}
              </ul>
            </li>
          </IsGranted>

          <IsGranted disallowed={[BACKUSER_ROLE_FLEET_MANAGER, BACKUSER_ROLE_SERVICE_ADVISOR]}>
            <li
              className={classNames('sideMenu_section', `sideMenu_section--${this.getAccountClass()}`, {
                _is_open: isAccountManagementOpen,
                _is_active:
                  this.contractIsActive() ||
                  currentPage === 'pricing' ||
                  currentPage === 'sites' ||
                  currentPage === 'addSite' ||
                  currentPage === 'siteDetails' ||
                  currentPage === 'addParking' ||
                  currentPage === 'parkingDetails' ||
                  currentPage === 'companies' ||
                  currentPage === 'subCompanies' ||
                  currentPage === 'subCompanyDetails' ||
                  currentPage === 'addSubCompany' ||
                  currentPage === 'addCompany' ||
                  currentPage === 'companyDetail' ||
                  currentPage === 'editCompany' ||
                  currentPage === 'configurations' ||
                  currentPage === 'hotlines' ||
                  currentPage === 'addHotline' ||
                  currentPage === 'editHotline' ||
                  currentPage === 'addConfiguration' ||
                  currentPage === 'memberTypes' ||
                  currentPage === 'editConfiguration'
              })}
            >
              <button className={classNames('sideMenu_item', 'sideMenu_item--subnav')} onClick={this.handleToggleAccountMenu}>
                {intl.messages.side_menu_section_account}
              </button>

              <ul className="sideMenu_sections--subnav">
                <IsGranted disallowed={contractRules.exclude}>
                  {config.enableMenuItems.contract && (
                    <li
                      className={classNames('sideMenu_section', `sideMenu_section--${this.getContractClass()}`, {
                        _is_active: this.contractIsActive()
                      })}
                    >
                      <a href={`#${this.getContractPath()}`} className="sideMenu_item">
                        {intl.messages.side_menu_section_account_contract}
                      </a>
                    </li>
                  )}
                </IsGranted>

                <IsGranted disallowed={[BACKUSER_ROLE_CRM]}>
                  {config.enableMenuItems.companies && (
                    <li
                      className={classNames('sideMenu_section', 'sideMenu_section--companies', {
                        _is_active:
                          currentPage === 'companies' ||
                          currentPage === 'addCompany' ||
                          currentPage === 'companyDetail' ||
                          currentPage === 'editCompany'
                      })}
                    >
                      <a
                        href={`#${routes.companies.path.replace(
                          ':search',
                          encodeURIComponent(
                            JSON.stringify({
                              ...apiParams.default,
                              sort: {
                                isDescending: false,
                                property: SORT_PROPERTY_NAME
                              }
                            })
                          )
                        )}`}
                        className="sideMenu_item"
                      >
                        {intl.messages.side_menu_section_account_companies}
                      </a>
                    </li>
                  )}
                </IsGranted>

                {config.enableMenuItems.subCompanies && (
                  <li
                    className={classNames('sideMenu_section', 'sideMenu_section--sub-companies', {
                      _is_active: currentPage === 'subCompanies' || currentPage === 'addSubCompany' || currentPage === 'subCompanyDetails'
                    })}
                  >
                    <a href={`#${routes.subCompanies.path}`} className="sideMenu_item">
                      {intl.messages.side_menu_section_account_sub_companies}
                    </a>
                  </li>
                )}
                {config.enableMenuItems.configurations && (
                  <IsGranted disallowed={configurationsRules.exclude} allowed={configurationsRules.include}>
                    <li
                      className={classNames('sideMenu_section', 'sideMenu_section--configurations', {
                        _is_active:
                          currentPage === 'configurations' || currentPage === 'addConfiguration' || currentPage === 'editConfiguration'
                      })}
                    >
                      <a
                        href={`#${routes.configurations.path.replace(':search', encodeURIComponent(JSON.stringify(apiParams.default)))}`}
                        className="sideMenu_item"
                      >
                        {intl.messages.side_menu_section_account_configurations}
                      </a>
                    </li>
                  </IsGranted>
                )}
                {config.enableMenuItems.hotlines && (
                  <IsGranted disallowed={hotlineRules.exclude} allowed={hotlineRules.include}>
                    <li
                      className={classNames('sideMenu_section', 'sideMenu_section--hotlines', {
                        _is_active: currentPage === 'hotlines' || currentPage === 'addHotline' || currentPage === 'editHotline'
                      })}
                    >
                      <a href={`#${routes.hotlines.path}`} className="sideMenu_item">
                        {intl.messages.side_menu_section_account_hotlines}
                      </a>
                    </li>
                  </IsGranted>
                )}
                {config.enableMenuItems.sites && (
                  <li
                    className={classNames('sideMenu_section', 'sideMenu_section--sites', {
                      _is_active:
                        currentPage === 'sites' ||
                        currentPage === 'addSite' ||
                        currentPage === 'siteDetails' ||
                        currentPage === 'addParking' ||
                        currentPage === 'parkingDetails'
                    })}
                  >
                    <a href={`#${routes.sites.path}`} className="sideMenu_item">
                      {intl.messages.side_menu_section_account_sites}
                    </a>
                  </li>
                )}
                {this.getMemberTypes(memberRules.type)}
                {config.enableMenuItems.pricing && (
                  <li
                    className={classNames('sideMenu_section', 'sideMenu_section--pricing', {
                      _is_active: currentPage === 'pricing'
                    })}
                  >
                    <a href={`#${routes.pricing.path}`} className="sideMenu_item">
                      {intl.messages.side_menu_section_account_pricing}
                    </a>
                  </li>
                )}
              </ul>
            </li>
          </IsGranted>

          <li
            className={classNames('sideMenu_section', 'sideMenu_section--fleet', {
              _is_open: isFleetOpen,
              _is_active:
                currentPage === 'fleet' ||
                currentPage === 'addVehicle' ||
                currentPage === 'vehicleDetail' ||
                currentPage === 'feedbacks' ||
                currentPage === 'feedbackDetail' ||
                currentPage === 'vehicleDamages' ||
                currentPage === 'vehicleDamageDetail' ||
                currentPage === 'editVehicle' ||
                currentPage === 'parkings' ||
                currentPage === 'parkingDetails'
            })}
          >
            <button className={classNames('sideMenu_item', 'sideMenu_item--subnav')} onClick={this.handleToggleFleetMenu}>
              {intl.messages.side_menu_section_fleet}
            </button>

            <ul className="sideMenu_sections--subnav">
              {config.enableMenuItems.fleet && (
                <li
                  className={classNames('sideMenu_section', 'sideMenu_section--vehicle', {
                    _is_active:
                      currentPage === 'fleet' ||
                      currentPage === 'addVehicle' ||
                      currentPage === 'vehicleDetail' ||
                      currentPage === 'editVehicle'
                  })}
                >
                  <a
                    href={`#${routes.fleet.path.replace(
                      ':search',
                      encodeURIComponent(
                        JSON.stringify({
                          ...apiParams.default,
                          ...apiParams.fleet
                        })
                      )
                    )}`}
                    className="sideMenu_item"
                  >
                    {intl.messages.side_menu_section_fleet_vehicle}
                  </a>
                </li>
              )}

              <IsGranted allowed={[BACKUSER_ROLE_FLEET_MANAGER]}>
                {config.enableMenuItems.fleet && (
                  <li
                    className={classNames('sideMenu_section', 'sideMenu_section--sites', {
                      _is_active: currentPage === 'parkingDetails' || currentPage === 'parkings'
                    })}
                  >
                    <a href={`#${routes.parkings.path}`} className="sideMenu_item">
                      {intl.messages.side_menu_section_account_parkings}
                    </a>
                  </li>
                )}
              </IsGranted>

              {config.enableMenuItems.feedbacks && (
                <li
                  className={classNames('sideMenu_section', 'sideMenu_section--feedbacks', {
                    _is_active: currentPage === 'feedbacks' || currentPage === 'feedbackDetail'
                  })}
                >
                  <a
                    href={`#${routes.feedbacks.path.replace(':search', encodeURIComponent(JSON.stringify(apiParams.default)))}`}
                    className="sideMenu_item"
                  >
                    {intl.messages.side_menu_section_fleet_feedbacks}
                  </a>
                </li>
              )}
              {config.enableMenuItems.vehicleDamages && (
                <li
                  className={classNames('sideMenu_section', 'sideMenu_section--vehicleDamages', {
                    _is_active: currentPage === 'vehicleDamages' || currentPage === 'vehicleDamageDetail'
                  })}
                >
                  <a
                    href={`#${routes.vehicleDamages.path.replace(
                      ':search',
                      encodeURIComponent(
                        JSON.stringify({
                          ...apiParams.default,
                          sort: {
                            isDescending: true,
                            property: SORT_PROPERTY_CREATION_DATE
                          }
                        })
                      )
                    )}`}
                    className="sideMenu_item"
                  >
                    {intl.messages.side_menu_section_fleet_vehicleDamages}
                  </a>
                </li>
              )}
            </ul>
          </li>

          <li
            className={classNames('sideMenu_section', 'sideMenu_section--membersManagement', {
              _is_open: isMembersOpen,
              _is_active:
                currentPage === 'members' ||
                currentPage === 'uploadMember' ||
                currentPage === 'memberDetail' ||
                currentPage === 'backUsers' ||
                currentPage === 'addBackUser' ||
                currentPage === 'backUserDetail' ||
                currentPage === 'smartcardsEvents' ||
                currentPage === 'editBackUser' ||
                currentPage === 'smartcards' ||
                currentPage === 'smartcardsDetail'
            })}
          >
            <button className={classNames('sideMenu_item', 'sideMenu_item--subnav')} onClick={this.handleToggleMembersMenu}>
              {intl.messages.side_menu_section_users}
            </button>

            <ul className="sideMenu_sections--subnav">
              {config.enableMenuItems.members && (
                <li
                  className={classNames('sideMenu_section', 'sideMenu_section--members', {
                    _is_active: currentPage === 'members' || currentPage === 'memberDetail' || currentPage === 'uploadMember'
                  })}
                >
                  <a
                    href={`#${routes.members.path.replace(':search', encodeURIComponent(JSON.stringify(apiParams.default)))}`}
                    className="sideMenu_item"
                  >
                    {intl.messages.side_menu_section_users_members}
                  </a>
                </li>
              )}
              {config.enableMenuItems.backUsers && (
                <IsGranted allowed={backuserRules.include} disallowed={backuserRules.exclude}>
                  <li
                    className={classNames('sideMenu_section', 'sideMenu_section--backUsers', {
                      _is_active:
                        currentPage === 'backUsers' ||
                        currentPage === 'addBackUser' ||
                        currentPage === 'backUserDetail' ||
                        currentPage === 'editBackUser'
                    })}
                  >
                    <a
                      href={`#${routes.backUsers.path.replace(
                        ':search',
                        encodeURIComponent(
                          JSON.stringify({
                            ...apiParams.default,
                            sort: {
                              isDescending: false,
                              property: SORT_PROPERTY_LASTNAME
                            }
                          })
                        )
                      )}`}
                      className="sideMenu_item"
                    >
                      {intl.messages.side_menu_section_users_backUsers}
                    </a>
                  </li>
                </IsGranted>
              )}

              {this.getSmartcards(bookingRules.smartcards)}
              {this.getSmartcardEvents(bookingRules.smartcards)}
            </ul>
          </li>

          {this.getMenuSectionReports()}

          {config.enableMenuItems.bookings && (
            <li
              className={classNames('sideMenu_section', `sideMenu_section--${this.getBookingsClass()}`, {
                _is_active: this.bookingsIsActive()
              })}
            >
              <a href={`#${this.getBookingsPath()}`} className="sideMenu_item">
                {intl.messages.side_menu_section_booking}
              </a>
            </li>
          )}

          {config.enableMenuItems.vehiclePlanning && (
            <li
              className={classNames('sideMenu_section', `sideMenu_section--${this.getVpClass()}`, {
                _is_active: this.vpIsActive()
              })}
            >
              <a href={`#${this.getVpPath()}`} className="sideMenu_item">
                {intl.messages.side_menu_section_vehiclePlanning}
              </a>
            </li>
          )}

          {config.enableMenuItems.invoices && (
            <IsGranted disallowed={invoiceRules.exclude} allowed={invoiceRules.include}>
              <li
                className={classNames('sideMenu_section', `sideMenu_section--${this.getInvoicesClass()}`, {
                  _is_active: this.invoicesIsActive()
                })}
              >
                <a href={`#${this.getInvoicesPath()}`} className="sideMenu_item">
                  {intl.messages.side_menu_section_invoice}
                </a>
              </li>
            </IsGranted>
          )}

          {config.enableMenuItems.bankouts && (
            <IsGranted allowed={bankoutRules.include} disallowed={bankoutRules.exclude}>
              <li
                className={classNames('sideMenu_section', 'sideMenu_section--bankout', {
                  _is_active: ['bankouts', 'bankoutDetails'].indexOf(currentPage) !== -1
                })}
              >
                <a
                  href={`#${routes.bankouts.path.replace(
                    ':search',
                    encodeURIComponent(JSON.stringify({ ...apiParams.default, ...apiParams.bankoutList }))
                  )}`}
                  className="sideMenu_item"
                >
                  {intl.messages.side_menu_section_bankout}
                </a>
              </li>
            </IsGranted>
          )}

          {this.getVouchersItem(paymentRules.voucher)}
        </ul>
      </nav>
    );
  }
}

SideMenu.displayName = 'SideMenu';

export default connect(state => {
  const {
    user: { enableNewUi, enableVpV1 },
    sideMenu: { isOpen, isGeneralSettingsOpen, isAccountManagementOpen, isFleetOpen, isMembersOpen, isOpenMobile, isReportsOpen },
    page: { route: currentPage, group },
    companies: { currentCompany, currentCompanyDetails }
  } = state;

  return {
    group,
    isOpen,
    isOpenMobile,
    isGeneralSettingsOpen,
    isAccountManagementOpen,
    isFleetOpen,
    isMembersOpen,
    isReportsOpen,
    currentPage,
    enableNewUi,
    enableVpV1,
    reportingRules: headerContractReportingValuesSelector(state),
    bookingRules: headerContractBookingValuesSelector(state),
    memberRules: headerContractMemberValuesSelector(state),
    paymentRules: headerContractPaymentValuesSelector(state),
    currentCompany: currentCompany || {},
    currentCompanyDetails
  };
})(injectIntl(SideMenu));
