import React, { Component } from 'react';

class MapMarker extends Component {
  render() {
    return <div className="shapeDot">{}</div>;
  }
}

MapMarker.displayName = 'MapMarker';

export default MapMarker;
