/*eslint-disable react/jsx-no-bind */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Dialog from 'material-ui/Dialog';
import { FormattedMessage, injectIntl } from 'react-intl';
import { reduxForm } from 'redux-form';
import autoBind from 'react-autobind';
import moment from 'moment';
import _get from 'lodash/get';
import _merge from 'lodash/merge';
import _map from 'lodash/map';
import _find from 'lodash/find';
import _filter from 'lodash/filter';
import _includes from 'lodash/includes';
import _flatten from 'lodash/flatten';
import _difference from 'lodash/difference';
import _flattenDeep from 'lodash/flattenDeep';
import _compact from 'lodash/compact';
import _padStart from 'lodash/padStart';
import _range from 'lodash/range';

import config from '../../../../constants/config-constants';

import BoxedInput from '../../../../components/BoxedInput/BoxedInput';
import BoxedSelect from '../../../../components/BoxedSelect/BoxedSelect';
import FieldErrorMsg from '../../../../components/FieldErrorMsg/FieldErrorMsg';
import { createValidator, notEmpty, futureDate } from '../../../../validation/all-validation';
import { converTimeValueToDoubleDigit, scrollToFirstError, daysListOfDatesRange, getAppFormattedDateTime } from '../../../../utils/utils';
import {
  toggleShortenExtendBooking,
  requestBookingExtendShorten,
  toggleVehiclePlanningCard,
  closeExtendShortenBookingModal,
  getDataVehiclePlanning
} from '../../../../actions/all-actions';
import FlatButton from 'material-ui/FlatButton';
import EkButton from '../../../../components/EkButton/EkButton';

class VehiclePlanningExtendShortenBooking extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.setHoursOptions();
  }

  handleConfirm() {
    const { dispatch, selectedBooking, selectedVehicle, VehiclePlanningExtendShortenBookingForm, hideCard } = this.props;
    const form = VehiclePlanningExtendShortenBookingForm;
    const newEndDate = form.newEndDate.value;
    const hour = form.hour.value;
    const min = form.min.value;

    const offset = moment.parseZone(selectedBooking.estimatedEndDate).utcOffset();
    const localeOffset = moment.parseZone().utcOffset();

    let endDate = moment
      .utc(newEndDate)
      .utcOffset(offset)
      .set('hour', hour)
      .set('minute', min)
      .add(localeOffset - offset, 'minutes')
      .format('YYYY-MM-DDTHH:mmZ');

    const bookingId = _get(selectedBooking, 'id');
    const registrationNumber = _get(selectedVehicle, 'registrationNumber');
    dispatch(requestBookingExtendShorten(bookingId, registrationNumber, endDate)).then(() => {
      setTimeout(() => dispatch(getDataVehiclePlanning()), 1000); // wait server to process data
      this.handleToggle();
      if (!hideCard) this.props.dispatch(toggleVehiclePlanningCard(selectedBooking, selectedVehicle));
    });
  }

  handleToggle() {
    const { dispatch, selectedBooking, selectedVehicle, hideCard } = this.props;

    !hideCard
      ? dispatch(toggleShortenExtendBooking(selectedBooking, selectedVehicle))
      : dispatch(toggleShortenExtendBooking(selectedBooking, selectedVehicle, true));
  }

  findById(sites, id) {
    const schedule = sites.map(site => site.parkings.map(parking => (_get(parking, 'schedule.id', false) === id ? parking.schedule : '')));
    return _compact(_flattenDeep(schedule));
  }

  hoursFiltered(streams) {
    const { fields } = this.props;
    const _streams = _filter(_get(streams, 'recurringSlots', false), { open: true });
    const _streamsOpenSpecial = _filter(_get(streams, 'oneTimeSlots', false), { open: true });
    const dateH = fields.newEndDate.value;
    const d = moment(dateH).isoWeekday() - 1;
    const day = config.daysOfWeek[d];

    if (_streamsOpenSpecial) {
      _streamsOpenSpecial.map(specialDaysHoursStream => {
        if (
          moment(dateH).isBetween(
            moment(specialDaysHoursStream.startDate),
            moment(specialDaysHoursStream.endDate).subtract(1, 'days'),
            null,
            []
          )
        ) {
          specialDaysHoursStream.days = [];
          const daysList = daysListOfDatesRange(specialDaysHoursStream.startDate, specialDaysHoursStream.endDate);
          daysList.map(day =>
            specialDaysHoursStream.days.push(
              moment(day)
                .locale('en')
                .format('dddd')
                .toUpperCase()
            )
          );
          return specialDaysHoursStream;
        }
      });
    }
    if (_streams) {
      if (day) {
        const validSpecialStreams = _find(_streamsOpenSpecial, { days: [day.value] });
        const validStreams = _find(_streams, { days: [day.value] });
        let goodTimes = [];
        if (
          validSpecialStreams &&
          moment(dateH).isBetween(moment(validSpecialStreams.startDate), moment(validSpecialStreams.endDate).subtract(1, 'days'), null, [])
        ) {
          validSpecialStreams.timeIntervals.map(time => {
            const range = _range(Number(time.start.split(':')[0]), Number(time.end.split(':')[0]) + 1); //+ 1 as fn range exclude end
            const ranges = range.map(it => {
              return _padStart(it, 2, '0');
            });
            goodTimes.push(ranges);
          });
          return _flatten(goodTimes);
        }

        if (validStreams) {
          if (validStreams.prevTime) {
            validStreams.prevTime.map(time => {
              const range = _range(Number(time.start.split(':')[0]), Number(time.end.split(':')[0]) + 1); //+ 1 as fn range exclude end
              const ranges = range.map(it => {
                return _padStart(it, 2, '0');
              });
              goodTimes.push(ranges);
            });
          } else {
            validStreams.timeIntervals.map(time => {
              const range = _range(Number(time.start.split(':')[0]), Number(time.end.split(':')[0]) + 1); //+ 1 as fn range exclude end
              const ranges = range.map(it => {
                return _padStart(it, 2, '0');
              });
              goodTimes.push(ranges);
            });
          }
          return _flatten(goodTimes);
        }
      }
    }
  }

  setHoursOptions() {
    const { parkingSchedule } = this.props;
    const hours = parkingSchedule[0];
    this.hoursOptions = hours ? this.hoursFiltered(hours) : config.availableHours;
  }

  getClosedDays(date) {
    const { parkingSchedule, bankHolidays, data } = this.props;
    let closedDays = [];
    let openDays = [];
    let bankClosedDays = [];
    let closedRecurringDays = [];
    if (parkingSchedule[0]) {
      if (parkingSchedule[0].applyFrenchBankHolidays) {
        bankClosedDays = _map(bankHolidays, day => {
          if (moment(date).isSame(moment(day.startDate), 'day')) {
            return moment(day.startDate).format('YYYY-MM-DD');
          }
        });
      }
      const oneTimeSlots = _filter(_get(parkingSchedule[0], 'oneTimeSlots', false), { open: false });

      if (oneTimeSlots) {
        closedDays = _map(oneTimeSlots, day => {
          if (moment(date).isBetween(moment(day.startDate), moment(day.endDate).subtract(1, 'days'), null, [])) {
            return daysListOfDatesRange(day.startDate, day.endDate);
          }
        });
      }

      let oneTimeSlotsOpen = _filter(_get(parkingSchedule[0], 'oneTimeSlots', false), { open: true });
      if (oneTimeSlotsOpen) {
        openDays = _map(oneTimeSlotsOpen, slot => {
          if (moment(date).isBetween(moment(slot.startDate), moment(slot.endDate).subtract(1, 'days'), null, [])) {
            return daysListOfDatesRange(slot.startDate, slot.endDate);
          }
        });
      }

      let initialRecurringSlots = _get(data, 'immutableParkings', false);
      let initialRecurringSlotsFiltered = this.findById(initialRecurringSlots, parkingSchedule[0].id);

      if (initialRecurringSlotsFiltered.length) {
        const weekDays = _flatten(initialRecurringSlotsFiltered[0].recurringSlots.map(item => item.days));
        let fullCloseDays = _compact(config.daysOfWeek.map(day => (!_includes(weekDays, day.value) ? day.value : '')));
        _map(fullCloseDays, day => {
          if (
            day ===
            moment(date)
              .locale('en')
              .format('dddd')
              .toUpperCase()
          ) {
            closedRecurringDays.push(moment(date).format('YYYY-MM-DD'));
          }
        });
      }

      let final = _merge(bankClosedDays, closedDays, closedRecurringDays);
      const finalClosed = _difference(_flatten(_compact(final)), _flatten(_compact(openDays)));
      return _includes(_flatten(_compact(finalClosed)), moment(date).format('YYYY-MM-DD'));
    }
  }

  render() {
    const {
      intl,
      openShortenExtendBooking,
      selectedBooking,
      openShortenExtendBookingHover,
      fields: { newEndDate, hour, min }
    } = this.props;
    this.setHoursOptions();
    const now = new Date();
    return (
      <Dialog
        bodyStyle={{ overflow: 'inherit' }}
        actionsContainerClassName="extend-action"
        contentStyle={{ width: '470px' }}
        onRequestClose={this.handleToggle}
        open={openShortenExtendBooking || openShortenExtendBookingHover}
        title={intl.messages.booking_detail_extend_shorten_booking}
        actions={[
          <EkButton key="no" type="button" skinType="reverse" onAction={this.handleToggle} customClass="cancel">
            <FormattedMessage id="extendShortenBookingForm_abort_button" />
          </EkButton>,
          <EkButton key="yes" type="button" onAction={this.handleConfirm}>
            <FormattedMessage id="extendShortenBookingForm_save_button" />
          </EkButton>
        ]}
      >
        <div style={{ display: 'flex' }}>
          <span className="boxedInput_labelText_extendShortenBookingForm">
            <FormattedMessage id="extendShortenBookingForm_currentEndDate" />
          </span>
          <span className="boxedInput_labelText_extendShortenBookingForm">{getAppFormattedDateTime(selectedBooking.estimatedEndDate)}</span>
        </div>
        <div style={{ display: 'flex' }}>
          <span className="boxedInput_labelText_extendShortenBookingForm extendShortenBookingForm_newDateRow_label">
            <FormattedMessage id="extendShortenBookingForm_newEndDate" />
          </span>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <BoxedInput
            formRowItemKey="extendShortenBookingForm_newEndDate"
            id="newEndDate"
            skinType="date"
            type="date"
            minDate={now}
            customClass="boxedInputWrapper--label"
            field={newEndDate}
            shouldDisableDate={this.getClosedDays}
          >
            <FieldErrorMsg field={newEndDate} customClass="fieldErrorMsg--extshortBooking extendShortenBookingForm_customError" />
          </BoxedInput>
          <BoxedSelect
            formRowItemKey="extendShortenBookingForm_hour"
            options={this.hoursOptions}
            customClass="extendShortenBookingForm_boxedSelectWrapper"
            field={hour}
          >
            <FieldErrorMsg field={hour} customClass="fieldErrorMsg--extshortBooking" />
          </BoxedSelect>

          <BoxedSelect
            formRowItemKey="extendShortenBookingForm_min"
            options={['00', '15', '30', '45']}
            customClass="extendShortenBookingForm_boxedSelectWrapper"
            field={min}
          >
            <FieldErrorMsg field={min} customClass="fieldErrorMsg--extshortBooking" />
          </BoxedSelect>
        </div>
      </Dialog>
    );
  }
}

VehiclePlanningExtendShortenBooking.displayName = 'VehiclePlanningExtendShortenBooking';

VehiclePlanningExtendShortenBooking = reduxForm(
  {
    onSubmitFail: scrollToFirstError,
    form: 'VehiclePlanningExtendShortenBookingForm'
  },
  state => {
    const {
      vehiclePlanning: { openShortenExtendBooking, openShortenExtendBookingHover, selectedBooking, selectedVehicle, parkingSchedule, data },
      parkings: { bankHolidays },
      form: { VehiclePlanningExtendShortenBookingForm }
    } = state;

    return {
      openShortenExtendBooking,
      openShortenExtendBookingHover,
      parkingSchedule,
      bankHolidays,
      data,
      VehiclePlanningExtendShortenBookingForm,
      selectedBooking,
      selectedVehicle,
      fields: ['newEndDate', 'hour', 'min'],
      initialValues: {
        newEndDate: moment(selectedBooking.estimatedEndDate).toDate(),
        hour: converTimeValueToDoubleDigit(
          moment(selectedBooking.estimatedEndDate)
            .add(1, 'h')
            .hour()
        ),
        min: '00'
      },
      validate: createValidator({
        newEndDate: [notEmpty(), futureDate()],
        hour: [notEmpty()],
        min: [notEmpty()]
      })
    };
  }
)(VehiclePlanningExtendShortenBooking);

export default connect()(injectIntl(VehiclePlanningExtendShortenBooking));
