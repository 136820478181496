import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import BackLink from '../../components/BackLink/BackLink';
import routes from '../../constants/routes-constants';
import { apiParams } from '../../constants/api-params-constants';
import { addErrorMessage, namedCompose } from '../../utils/utils';
import { connect } from 'react-redux';
import { addMemberRequest, addMemberSuccess } from '../../actions/members-actions';
import { addFlashMessage } from '../../actions/flashMessage-actions';
import { FLASH_MESSAGE_TYPE_SUCCESS } from '../../constants/generic-constants';
import { routeActions } from 'react-router-redux';
import _values from 'lodash/values';
import { partialErrorCodes } from '../../constants/options-constants';
import { bundleSelector, validCompanyIdSelector, validSubCompanyIdSelector, localeSelector } from '../../selectors/all-selectors';
import { clearBackUserEmailValue } from '../../actions/user-actions';
import PreSubscribeForm from '../../components/PreSubscribeForm/PreSubscribeForm';
import { change, untouch } from 'redux-form';

/**
 * Form to pre-subscribe a member.
 *
 * The member profile will be created with the "to complete" status. A password will be automatically generated and
 * sent to the member by email. He will be able to log in Glide and complete the missing information about his profile.
 */
class AddMember extends Component {
  constructor(props) {
    super(props);

    this.sendAddMemberRequest = this.sendAddMemberRequest.bind(this);
    this.sendAddMemberRequestAndStay = this.sendAddMemberRequestAndStay.bind(this);
    this.resetForm = this.resetForm.bind(this);
    this.generateRelatedLinks(props.urlParams);
  }

  generateRelatedLinks(urlParams) {
    let encodedUrlParams = encodeURIComponent(JSON.stringify(urlParams || apiParams.default));
    this.backToListUrl = '/#' + routes.members.path.replace(':search', encodedUrlParams);

    let params = urlParams || apiParams.default;
    params.page.number = 1;
    let encodedUrlParamsPage1 = encodeURIComponent(JSON.stringify(params));
    this.goToListAfterSuccessUrl = routes.members.path.replace(':search', encodedUrlParamsPage1);

    this.displayAddMemberFormUrl = routes.addMember.path;
  }

  resetForm() {
    const { dispatch } = this.props;
    dispatch(change('addMemberForm', 'firstName', ''));
    dispatch(untouch('addMemberForm', 'firstName', ''));
    dispatch(change('addMemberForm', 'lastName', ''));
    dispatch(untouch('addMemberForm', 'lastName', ''));
    dispatch(change('addMemberForm', 'login', ''));
    dispatch(untouch('addMemberForm', 'login', ''));
    dispatch(change('addMemberForm', 'phoneNumber', ''));
    dispatch(untouch('addMemberForm', 'phoneNumber', ''));
  }

  sendAddMemberRequest() {
    const { dispatch, bundle } = this.props;
    dispatch(addMemberRequest(this.props.selectedCompanyId, this.props.selectedSubCompanyId))
      .then(memberAdded => {
        dispatch(addMemberSuccess(memberAdded));
        dispatch(
          addFlashMessage({
            contentKey: 'member_preSubscriptionForm_success',
            type: FLASH_MESSAGE_TYPE_SUCCESS,
            delayToNextRoute: true
          })
        );
        dispatch(routeActions.push(this.goToListAfterSuccessUrl));
      })
      .catch(error => {
        const partialErrors = _values(partialErrorCodes.members);
        const params = { bundle, error, partialErrors, def: 'common_error' };
        dispatch(addErrorMessage(params));
      });
  }

  sendAddMemberRequestAndStay() {
    const { dispatch, bundle } = this.props;
    dispatch(addMemberRequest(this.props.selectedCompanyId, this.props.selectedSubCompanyId))
      .then(memberAdded => {
        dispatch(addMemberSuccess(memberAdded));
        dispatch(
          addFlashMessage({
            contentKey: 'member_preSubscriptionForm_success',
            type: FLASH_MESSAGE_TYPE_SUCCESS,
            delayToNextRoute: true
          })
        );
        this.resetForm();
        dispatch(routeActions.push(this.displayAddMemberFormUrl));
      })
      .catch(error => {
        const partialErrors = _values(partialErrorCodes.members);
        const params = { bundle, error, partialErrors, def: 'common_error' };
        dispatch(addErrorMessage(params));
      });
  }

  render() {
    const { locale, drivingLicenceRequired, sendEmailsToManager, useExternalInvoiceSystem } = this.props;
    if (this.props.companySelected) {
      return (
        <div className="pageContainer">
          <BackLink link={this.backToListUrl} labelKey="back_link_members_list" />

          <PreSubscribeForm
            onCallback={this.sendAddMemberRequest}
            onAbort={this.backToListUrl}
            onCallbackRetry={this.sendAddMemberRequestAndStay}
            resetForm={this.resetForm}
            initialValues={{ locale }}
            actions
            drivingLicenceRequired={drivingLicenceRequired}
            sendEmailsToManager={sendEmailsToManager}
            useExternalInvoiceSystem={useExternalInvoiceSystem}
          />
        </div>
      );
    } else {
      return (
        <div className="pageContainer">
          <BackLink link={this.backToListUrl} labelKey="back_link_members_list" />
          <div className="page-info-block">
            <FormattedMessage id="common_select_super_company" />
          </div>
        </div>
      );
    }
  }
}

export default namedCompose(
  connect(state => {
    const {
      members: { urlParams },
      companies: {
        currentCompanyDetails: { drivingLicenceRequired, sendEmailsToManager, useExternalInvoiceSystem }
      }
    } = state;
    const selectedCompanyId = validCompanyIdSelector(state);
    const selectedSubCompanyId = validSubCompanyIdSelector(state);
    const companySelected = selectedCompanyId !== undefined;
    return {
      companySelected,
      selectedCompanyId,
      selectedSubCompanyId,
      drivingLicenceRequired,
      sendEmailsToManager,
      useExternalInvoiceSystem,
      urlParams,
      locale: localeSelector(state),
      bundle: bundleSelector(state)
    };
  }),
  injectIntl
)(AddMember);
