import React, { Component } from 'react';
import ColumnList from '../../../components/_v2/ColumnList';
import { connect } from 'react-redux';

import { createMenuItems, resolveActive, resolveSelected, showNextView, showParentView } from '../../../components/_v2/ColumnList/helpers';
import { renderItem } from './helpers';
import { FormattedMessage } from 'react-intl';
import { companiesLoadingSelector, companiesTotalResultsSelector, companyResultsSelector } from '../../../selectors/all-selectors';
import { debounce, isValidId, safe } from '../../../utils/utils';
import { apiParams } from '../../../constants/api-params-constants';
import { getCompaniesListWithSearch } from '../../../actions/companies-actions';

const fetchAmount = 50;
const updateDelay = 500;

class OrganizationSuperCompanies extends Component {
  componentWillMount() {
    this.setCallbacks();
    this.setVars();
  }

  setVars() {
    this.menuItems = createMenuItems({ onAdd: this.handleAdd, addKey: 'addCompany_title' });
  }

  setCallbacks() {
    this.handleSelect = ({ id } = {}, _, { openInNewTab }) => {
      showNextView.call(this, 'organizationSubCompanies', ':companyId', id, openInNewTab);
    };

    this.handleEdit = ({ id } = {}, _, { openInNewTab }) => {
      showNextView.call(this, 'organizationEditSuperCompany', ':companyId', id, openInNewTab);
    };

    this.handleAdd = () => {
      showNextView.call(this, 'organizationAddSuperCompany');
    };

    this.fetchListData = (other, loader) => {
      const { brandId } = this.props.params;
      const params = { ...apiParams.companiesList };

      if (isValidId(brandId)) {
        params.brandId = brandId;
      }
      this.props.dispatch(getCompaniesListWithSearch({ ...params, ...other }, loader));
    };

    this.getMoreResults = () => {
      if (this.props.loading) return;

      const { list, totalResults } = this.props;
      const currResults = safe(() => list.length) || 0;

      if (totalResults && totalResults > currResults) {
        const diff = totalResults - currResults;
        const increase = fetchAmount > diff ? diff : fetchAmount;
        const size = currResults + increase;
        this.fetchListData({ page: { number: 1, size } });
      }
    };

    this.handleInputChange = debounce(raw => {
      const input = safe(() => raw.trim());
      if (input) {
        this.fetchListData({ name: input }, true);
      } else {
        this.fetchListData({}, true);
      }
    }, updateDelay);

    this.resolveSelected = resolveSelected.bind(this);
    this.resolveActive = resolveActive.bind(this);
    this.showParentView = showParentView.bind(this);
  }

  render() {
    return (
      <ColumnList
        title={<FormattedMessage id="common_super_companies" />}
        inputRef={this.props.inputRef}
        totalResults={this.props.totalResults}
        items={this.props.list}
        renderItem={renderItem}
        onSelect={this.handleSelect}
        onInputChange={this.handleInputChange}
        onScrollToBottom={this.getMoreResults}
        selected={this.resolveSelected}
        active={this.resolveActive}
        onEdit={this.handleEdit}
        menuItems={this.menuItems}
        onBack={this.showParentView}
      />
    );
  }
}

const mapStateToProps = state => {
  return {
    list: companyResultsSelector(state),
    totalResults: companiesTotalResultsSelector(state),
    loading: companiesLoadingSelector(state)
  };
};

OrganizationSuperCompanies = connect(mapStateToProps)(OrganizationSuperCompanies);

export default OrganizationSuperCompanies;
