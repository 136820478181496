/* eslint-disable react/no-did-mount-set-state */
import React, { Component, PropTypes as T } from 'react';
import { connect } from 'react-redux';
import _get from 'lodash/get';
import _partial from 'lodash/partial';
import _times from 'lodash/times';
import _omit from 'lodash/omit';
import moment from 'moment';
import { FormattedMessage, injectIntl } from 'react-intl';
import autoBind from 'react-autobind';

import VehiclePlanningSetup from './components/VehiclePlanningSetup';
import {
  selectFirstDayForCreatingBooking,
  selectSecondDayForCreatingBooking,
  toggleCreateBookingMemberSelect,
  selectFirstHourForCreatingBooking,
  selectSecondHourForCreatingBooking,
  saveCreateStatusData,
  clearCreateBookingModal,
  getVehicleDetail,
  unselectVehicle
} from '../../actions/all-actions';
import Loader from '../../components/MaterialLoader/MaterialLoader';
import TimeMarker from './components/TimeMarker';
import VehiclePlanningBooking from './components/VehiclePlanningBooking';
import VehiclePlanningCreateBooking from './components/VehiclePlanningCreateBooking';
import VehiclePlanningMemberSelect from './components/VehiclePlanningMemberSelect';
import VehiclePlanningCard from './components/VehiclePlanningCard';
import VehiclePlanningCreateStatus from './components/VehiclePlanningCreateStatus';
import VehiclePlanningOpeningHours from './components/VehiclePlanningOpeningHours';
import VehiclePlanningExtendShortenBooking from './components/VehiclePlanningExtendShortenBooking';
import VehiclePlanningEditBooking from './components/VehiclePlanningEditBooking';
import VehiclePlanningChangeVehicle from './components/VehiclePlanningChangeVehicle';
import VehiclePlanningMemberChange from './components/VehiclePlanningMemberChange';
import VehiclePlanningStatus from './components/VehiclePlanningStatus';
import routes, { vehiclePlanningEditRules } from '../../constants/routes-constants';
import { siteColors, parkingColors } from '../../constants/style-constants';
import classNames from 'classnames';
import { vehiclePlanningClearData } from './VehiclePlanning.actions';
import { clearAllBookingData } from '../../actions/booking-actions';
import { ALL } from '../../constants/generic-constants';
import { VEHICLE_USAGE_TYPE_NO_USAGE } from '../../constants/backend-constants';
import { checkRole } from '../../constants/backuser-role-rules';

const now = new Date();

class VehiclePlanning extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.parkingIndex = -1;
    this.setReadOnly(props);
  }

  setReadOnly(props) {
    const { role } = props;
    this.readOnly = !checkRole(vehiclePlanningEditRules, role);
  }

  componentWillMount() {
    this.props.dispatch(clearAllBookingData());
  }

  componentDidMount() {
    this.updateHeight();
  }

  componentDidUpdate() {
    this.updateHeight();
  }

  updateHeight() {
    this.height =
      document.querySelectorAll('div.vehiclePlanning_vehicleRow').length * 110 +
      document.querySelectorAll('div.vehiclePlanning_parking_name').length * 20;
    if (!this.updated && this.height) {
      this.forceUpdate();
      this.updated = true;
    }
  }

  updateItsHeight() {
    setTimeout(() => {
      this.height =
        document.querySelectorAll('div.vehiclePlanning_vehicleRow').length * 110 +
        document.querySelectorAll('div.vehiclePlanning_parking_name').length * 20;
      this.forceUpdate();
    }, 100);
  }

  componentWillUnmount() {
    this.props.dispatch(vehiclePlanningClearData());
  }

  resetParkingIndex() {
    this.parkingIndex = -1;
  }

  handleSelectBookingToCreate(value) {
    const { firstDaySelected, dispatch, periodInDays, createStatusMode, startDate } = this.props;
    const dayIndex = value[0];
    const vehicle = value[1];
    const parking = value[2];
    const siteId = value[3];
    if (periodInDays === 1) return;

    if (createStatusMode) {
      dispatch(saveCreateStatusData(dayIndex, vehicle));
      dispatch(getVehicleDetail(vehicle.id));
      dispatch(clearCreateBookingModal());
      return;
    }

    if (this.readOnly) return;
    if (Object.keys(firstDaySelected).length > 0) {
      if (firstDaySelected.vehicle.id !== vehicle.id) {
        dispatch(unselectVehicle());
        dispatch(selectFirstDayForCreatingBooking(dayIndex, vehicle, parking, siteId));
      }
      if (firstDaySelected.vehicle.id === vehicle.id && firstDaySelected.value <= dayIndex) {
        dispatch(selectSecondDayForCreatingBooking(dayIndex, vehicle, parking, siteId));
        dispatch(toggleCreateBookingMemberSelect());
      }
    } else {
      if (
        periodInDays !== 1 &&
        !moment(
          moment(startDate)
            .add(dayIndex + 1, 'days')
            .format()
        ).isSameOrBefore(now, 'day')
      ) {
        dispatch(selectFirstDayForCreatingBooking(dayIndex, vehicle, parking, siteId));
      }
    }
  }

  handleSelectBookingHourToCreate(value) {
    const hourIndex = value[0];
    const vehicle = value[1];
    const parking = value[2];
    const siteId = value[3];
    const { firstHourSelected, dispatch, createStatusMode, startDate } = this.props;

    if (createStatusMode) {
      dispatch(saveCreateStatusData(hourIndex, vehicle));
      dispatch(clearCreateBookingModal());
      return;
    }

    if (
      this.readOnly ||
      moment(
        moment(startDate)
          .add(hourIndex + 1, 'hours')
          .format()
      ).isSameOrBefore(now, 'hours')
    )
      return;
    if (Object.keys(firstHourSelected).length > 0) {
      if (firstHourSelected.vehicle.id !== vehicle.id) {
        dispatch(unselectVehicle());
        dispatch(selectFirstHourForCreatingBooking(hourIndex, vehicle, parking, siteId));
      }
      if (firstHourSelected.vehicle.id === value[1].id && firstHourSelected.value <= value[0]) {
        dispatch(selectSecondHourForCreatingBooking(hourIndex, vehicle, parking, siteId));
        dispatch(toggleCreateBookingMemberSelect());
      }
    } else {
      dispatch(selectFirstHourForCreatingBooking(hourIndex, vehicle, parking, siteId));
    }
  }

  render() {
    const {
      startDate,
      periodInDays,
      subCompanySelected,
      currentCompany,
      loading,
      data,
      openedCard,
      selectedBooking,
      selectedVehicle,
      firstDaySelected,
      firstHourSelected,
      openCreateBookingModal,
      openMemberSelectModal,
      createStatusMode,
      openCreateStatusModal,
      openShortenExtendBooking,
      openShortenExtendBookingHover,
      openedEditBookingModal,
      openedChangeVehicleModal,
      openedMemberChangeModal,
      vehiclesUsagesFiltered,
      sideMenuOpen,
      intl
    } = this.props;

    return (
      <div className="vehiclePlanning mainContainer_content">
        <div className="pageContainer">
          {_get(currentCompany, 'id', '') !== ALL && _get(subCompanySelected, 'id', ALL) !== ALL && (
            <VehiclePlanningSetup readOnly={this.readOnly} resetParkingIndex={this.resetParkingIndex} updateHeight={this.updateItsHeight} />
          )}

          {(_get(currentCompany, 'id', ALL) === ALL || _get(subCompanySelected, 'id', ALL) === ALL) && (
            <div className="page-info-block">
              <FormattedMessage
                id={_get(currentCompany, 'id', ALL) === ALL ? 'vehiclePlanning_selectSuperCompany' : 'vehiclePlanning_selectCompany'}
              />
            </div>
          )}
          {_get(subCompanySelected, 'id', false) && _get(subCompanySelected, 'id') !== ALL && _get(currentCompany, 'id', ALL) !== ALL && (
            <div className="vehiclePlanning_container">
              {loading && (
                <div className="vehiclePlanning_loadingOverlay">
                  <Loader />
                </div>
              )}

              <TimeMarker startDate={startDate} period={periodInDays} height={this.height} />
              <div className={`vehiclePlanning_firstRow ${sideMenuOpen && 'vehiclePlanning_firstRow_sideMenuOpen'}`}>
                <div className="vehiclePlanning_firstRow_firstField"></div>

                {/*First row with dates*/}
                {_times(periodInDays, value => {
                  return (
                    <div key={'period' + value} className="vehiclePlanning_firstRow_field">
                      <div className="vehiclePlanning_firstRow_day">
                        {moment(startDate)
                          .add(value, 'd')
                          .format('ddd')}
                      </div>
                      <div>
                        {moment(startDate)
                          .add(value, 'd')
                          .format('D')}
                      </div>
                      <div className="vehiclePlanning_firstRow_hours">
                        {periodInDays === 1 &&
                          _times(24, hour => (
                            <div className={`vehiclePlanning_firstRow_hour`} key={'h' + hour}>
                              {hour}:00
                            </div>
                          ))}
                      </div>
                    </div>
                  );
                })}
              </div>

              <VehiclePlanningCard readOnly={this.readOnly} opened={openedCard} booking={selectedBooking} vehicle={selectedVehicle} />

              {/*Rows with cars and individual bookings*/}
              {data.sites.map((site, siteIndex) => (
                <div key={site.id} className="vehiclePlanning_site" style={{ borderRight: '5px solid ' + siteColors[siteIndex] }}>
                  {site.parkings.map(parking => {
                    if (parking.vehicles.some(v => vehiclesUsagesFiltered.includes(v.usage) || v.usage === VEHICLE_USAGE_TYPE_NO_USAGE)) {
                      return (
                        <div className="vehiclePlanning_site_name" key={'name' + parking.id} style={{ color: siteColors[siteIndex] }}>
                          {site.name}
                        </div>
                      );
                    }
                  })}
                  {site.parkings.map(parking => {
                    this.parkingIndex = siteIndex + 1;
                    if (this.parkingIndex >= parkingColors.length) {
                      this.parkingIndex = 0;
                    }
                    if (parking.vehicles.some(v => vehiclesUsagesFiltered.includes(v.usage) || v.usage === VEHICLE_USAGE_TYPE_NO_USAGE)) {
                      return (
                        <div
                          className="vehiclePlanning_parking"
                          key={parking.id}
                          style={{ borderLeft: '5px solid ' + parkingColors[this.parkingIndex] }}
                        >
                          <div
                            className="vehiclePlanning_parking_name"
                            style={{
                              color: parkingColors[this.parkingIndex],
                              bottom: parking.vehicles.length === 1 ? '0' : (parking.vehicles.length * 80) / 2
                            }}
                          >
                            {parking.name}
                          </div>
                          {_get(parking, 'schedule.recurringSlots', false) && (
                            <div>
                              {parking.schedule.recurringSlots.map(parkingOpenedSlots => (
                                <VehiclePlanningOpeningHours
                                  open
                                  key={parking.id + '-openstream-' + parkingOpenedSlots.id}
                                  id={'openstreams-' + parking.id + parkingOpenedSlots.id}
                                  parkingOpenedSlots={parkingOpenedSlots}
                                  startDate={startDate}
                                />
                              ))}
                            </div>
                          )}
                          {_get(parking, 'schedule.recurringSlots', false) && (
                            <div>
                              {parking.schedule.recurringSlots.map(parkingOpenedSlots => (
                                <VehiclePlanningOpeningHours
                                  open={false}
                                  key={parking.id + '-' + parkingOpenedSlots.id}
                                  id={'closed-streams-' + parking.id + parkingOpenedSlots.id}
                                  parkingOpenedSlots={parkingOpenedSlots}
                                  startDate={startDate}
                                />
                              ))}
                            </div>
                          )}
                          {parking.vehicles.map(vehicle => {
                            let sortedStatuses = vehicle.statuses.sort((a, b) => {
                              return new Date(b.date) > new Date(a.date) ? -1 : 1;
                            });
                            const href = `#${routes.vehicleDetail.path.replace(':vehicleId', vehicle.id)}`;
                            const usageKey = 'vehicle_usage_type_' + vehicle.usage.toLowerCase();
                            const isNoUsage = vehicle.usage === VEHICLE_USAGE_TYPE_NO_USAGE;

                            if (!vehicle.hasOwnProperty('visible') || vehicle.visible || vehicle.usage === VEHICLE_USAGE_TYPE_NO_USAGE) {
                              return (
                                <div className="vehiclePlanning_vehicleRow" key={vehicle.id}>
                                  <div className="vehiclePlanning_vehicleRow_firstField">
                                    <div
                                      className="vehicle-usage"
                                      style={{ backgroundColor: isNoUsage ? '#B2B2B2' : parkingColors[this.parkingIndex] }}
                                    >
                                      <FormattedMessage id={usageKey} />
                                    </div>
                                    <a href={href}>
                                      <img src={vehicle.picture} />
                                    </a>

                                    <div className="vehiclePlanning_vehicleRow_registrationNumber">
                                      <a href={href}>{vehicle.registrationNumber}</a>
                                    </div>
                                    <div className="vehiclePlanning_vehicleRow_vehicleInfo">
                                      <a href={href}>
                                        <span>
                                          {vehicle.brand} {vehicle.model} ({vehicle.color})
                                        </span>
                                      </a>
                                    </div>
                                    <div className="vehiclePlanning_vehicleRow_fuelLevel">
                                      <span>
                                        {vehicle.fuelLevel}
                                        {_get(vehicle, 'fuelLevel', false) && <span>%</span>}
                                      </span>
                                    </div>
                                    <div className="vehiclePlanning_vehicleRow_vehicleInfo">
                                      <span>
                                        {intl.messages[`vehicle_fuelType_${vehicle.fuelType}`] +
                                          ' - ' +
                                          intl.messages[`vehicle_transmissionType_${vehicle.transmissionType}`]}
                                      </span>
                                    </div>
                                  </div>
                                  {_times(periodInDays, value => {
                                    const condition =
                                      _get(firstDaySelected, 'value') === value && _get(firstDaySelected, 'vehicle.id') === vehicle.id;
                                    return (
                                      <div
                                        key={value}
                                        onClick={_partial(this.handleSelectBookingToCreate, [
                                          value,
                                          vehicle,
                                          _omit(parking, 'vehicles'),
                                          site.id
                                        ])}
                                        className={classNames(
                                          'vehiclePlanning_vehicleRow_field',
                                          condition ? 'vehiclePlanning_bookingMode_selectedDay' : '',
                                          createStatusMode && periodInDays !== 1 ? 'vehiclePlanning_bookingMode' : '',
                                          _get(firstDaySelected, 'vehicle.id') === vehicle.id && _get(firstDaySelected, 'value') <= value
                                            ? 'vehiclePlanning_bookingMode'
                                            : ''
                                        )}
                                      >
                                        {periodInDays === 1 &&
                                          _times(24, value => {
                                            const condition =
                                              _get(firstHourSelected, 'value') === value &&
                                              _get(firstHourSelected, 'vehicle.id') === vehicle.id;
                                            return (
                                              <div
                                                key={value}
                                                className={classNames(
                                                  'vehiclePlanning_vehicleRow_hourField',
                                                  condition ? 'vehiclePlanning_bookingMode_selectedDay' : '',
                                                  _get(firstHourSelected, 'vehicle.id') === vehicle.id &&
                                                    _get(firstHourSelected, 'value') <= value
                                                    ? 'vehiclePlanning_bookingMode'
                                                    : '',
                                                  createStatusMode ? 'vehiclePlanning_bookingMode' : ''
                                                )}
                                                onClick={_partial(this.handleSelectBookingHourToCreate, [
                                                  value,
                                                  vehicle,
                                                  _omit(parking, 'vehicles'),
                                                  site.id
                                                ])}
                                              />
                                            );
                                          })}
                                      </div>
                                    );
                                  })}
                                  {vehicle.bookings.map(booking => (
                                    <VehiclePlanningBooking
                                      booking={booking}
                                      reservationBuffer={site.reservationBuffer}
                                      key={booking.id}
                                      vehicle={vehicle}
                                      readOnly={this.readOnly}
                                    />
                                  ))}
                                  {sortedStatuses.map((status, statusIndex) => {
                                    return (
                                      <VehiclePlanningStatus
                                        status={status}
                                        key={'status-' + vehicle.id + '-' + statusIndex}
                                        vehicle={vehicle}
                                        statusIndex={statusIndex}
                                      />
                                    );
                                  })}
                                </div>
                              );
                            }
                          })}
                        </div>
                      );
                    }
                  })}
                </div>
              ))}

              {openCreateBookingModal && <VehiclePlanningCreateBooking />}
              {openMemberSelectModal && <VehiclePlanningMemberSelect />}
              {openCreateStatusModal && <VehiclePlanningCreateStatus />}
              {openShortenExtendBookingHover && <VehiclePlanningExtendShortenBooking hideCard />}
              {openShortenExtendBooking && <VehiclePlanningExtendShortenBooking />}
              {openedEditBookingModal && <VehiclePlanningEditBooking />}
              {openedChangeVehicleModal && <VehiclePlanningChangeVehicle />}
              {openedMemberChangeModal && <VehiclePlanningMemberChange />}

              {data.sites.length === 0 && (
                <div className="vehiclePlanning_noResults">
                  <FormattedMessage id="common_no_results" />
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
}

VehiclePlanning.displayName = 'VehiclePlanning';

VehiclePlanning.propTypes = {
  startDate: T.string,
  periodInDays: T.number,
  siteId: T.string,
  subCompanySelected: T.object,
  loading: T.bool,
  data: T.object,
  openCreateStatusModal: T.bool
};

export default connect(state => {
  const {
    vehiclePlanning: {
      startDate,
      periodInDays,
      siteId,
      loading,
      data,
      openedCard,
      selectedBooking,
      selectedVehicle,
      createBookingMode,
      firstDaySelected,
      secondDaySelected,
      firstHourSelected,
      secondHourSelected,
      openMemberSelectModal,
      openCreateBookingModal,
      createStatusMode,
      openCreateStatusModal,
      openShortenExtendBooking,
      openShortenExtendBookingHover,
      openedEditBookingModal,
      openedChangeVehicleModal,
      openedMemberChangeModal,
      vehiclesUsagesFiltered
    },
    subCompanies: { subCompanySelected },
    companies: { currentCompany },
    user: {
      userInfo: { role }
    },
    sideMenu: { isOpen }
  } = state;

  return {
    startDate,
    periodInDays,
    siteId,
    subCompanySelected,
    currentCompany,
    loading,
    data,
    openedCard,
    selectedBooking,
    selectedVehicle,
    createBookingMode,
    firstDaySelected,
    secondDaySelected,
    openMemberSelectModal,
    openCreateBookingModal,
    firstHourSelected,
    secondHourSelected,
    createStatusMode,
    openCreateStatusModal,
    role,
    openShortenExtendBookingHover,
    openShortenExtendBooking,
    openedEditBookingModal,
    openedChangeVehicleModal,
    openedMemberChangeModal,
    vehiclesUsagesFiltered,
    sideMenuOpen: isOpen
  };
})(injectIntl(VehiclePlanning));
