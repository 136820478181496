import React, { Component } from 'react';
import { currentCompanyContractSelector, currentCompanySelector } from '../../../../selectors/all-selectors';
import BoxedInput from '../../../../components/BoxedInput/BoxedInput';
import FieldErrorMsg from '../../../../components/FieldErrorMsg/FieldErrorMsg';
import { reduxForm } from 'redux-form';
import { scrollToFirstError } from '../../../../utils/utils';
import { createValidator, notEmpty } from '../../../../validation/sync-validation';
import { RaisedButton } from 'material-ui';
import { FormattedMessage } from 'react-intl';
import { getMsg } from '../../../../utils/IntlGlobalProvider';

class GeneralSettings extends Component {
  render() {
    const {
      limitedAccess,
      handleSubmit,
      fields: { name, startDate, endDate }
    } = this.props;

    return (
      <div className="contract-general-info">
        <div className="sc-title">
          <span className="sc-key">
            <FormattedMessage id="data.with.colon" values={{ data: getMsg('common_company') }} />
          </span>
          <span className="sc-value">{this.props.company.name}</span>
        </div>
        <div className="sc-details">
          <BoxedInput
            formRowItemKey="contractForm_name"
            type="text"
            id="contractForm_name"
            labelKey="contractForm_name"
            field={name}
            disabled={limitedAccess}
          >
            <FieldErrorMsg customClass="fieldErrorMsg--contractForm" field={name} />
          </BoxedInput>
          <BoxedInput
            formRowItemKey="contractForm_startDate"
            type="date"
            id="contractForm_startDate"
            labelKey="contractForm_startDate"
            field={startDate}
            disabled={limitedAccess}
          >
            <FieldErrorMsg customClass="fieldErrorMsg--contractForm" field={startDate} />
          </BoxedInput>
          <BoxedInput
            formRowItemKey="contractForm_endDate"
            type="date"
            id="contractForm_endDate"
            labelKey="contractForm_endDate"
            field={endDate}
            disabled={limitedAccess}
          >
            <FieldErrorMsg customClass="fieldErrorMsg--contractForm" field={endDate} />
          </BoxedInput>
        </div>
        {!limitedAccess && (
          <div className="sc-actions">
            <RaisedButton label={getMsg('common_save')} primary type="button" onClick={handleSubmit(this.props.onCallback)} />
          </div>
        )}
      </div>
    );
  }
}

GeneralSettings = reduxForm(
  {
    onSubmitFail: scrollToFirstError,
    form: 'contractGeneralSettings',
    fields: ['name', 'startDate', 'endDate'],
    validate: createValidator({
      name: [notEmpty()],
      startDate: [notEmpty()],
      endDate: [notEmpty()]
    })
  },
  state => {
    const contract = currentCompanyContractSelector(state);

    return {
      company: currentCompanySelector(state),
      initialValues: {
        name: contract.name,
        startDate: contract._contractStartDate,
        endDate: contract._contractEndDate
      }
    };
  }
)(GeneralSettings);

export default GeneralSettings;
