import React, { Component } from 'react';
import { safe, scrollToFirstError } from '../../../../../utils/utils';
import { connect } from 'react-redux';
import BoxedSelect from '../../../../../components/BoxedSelect/BoxedSelect';
import AddIcon from 'material-ui/svg-icons/content/add';
import { reduxForm } from 'redux-form';
import { createValidator, notEmpty, notValueDash } from '../../../../../validation/sync-validation';
import FieldErrorMsg from '../../../../../components/FieldErrorMsg/FieldErrorMsg';
import { getMsg } from '../../../../../utils/IntlGlobalProvider';

class CopySiteForm extends Component {
  render() {
    const {
      handleSubmit,
      list,
      fields: { siteId }
    } = this.props;
    const options = list.map(i => {
      return { label: i.name, value: i.id };
    });

    return (
      <div>
        <form action="#" className="siteCopyForm" onSubmit={handleSubmit(this.props.onCallback)}>
          {getMsg('copySite_label')}
          <BoxedSelect name="siteToCopy" customClass="site-select" options={options} field={siteId} />
          <button className="sc-edit" type="submit">
            <AddIcon className="align-svg" /> {getMsg('common_create')}
          </button>
        </form>
        <div>
          <FieldErrorMsg field={siteId} customClass="fieldErrorMsg--copySite" />
        </div>
      </div>
    );
  }
}

CopySiteForm = reduxForm({
  onSubmitFail: scrollToFirstError,
  form: 'copySiteForm',
  fields: ['siteId'],
  validate: createValidator({
    siteId: [notEmpty(), notValueDash()]
  })
})(CopySiteForm);

export default connect(state => {
  const {
    sites: { dupSite, list },
    companies: { detailCompany: company }
  } = state;
  return { company, list };
})(CopySiteForm);
