import React, { Component } from 'react';
import cs from 'classnames';
import ReactSVG from 'react-svg';
import GeneralSettings from './Settings/General';
import BookingSettings from './Settings/Booking';
import MemberSettings from './Settings/Member';
import PaymentSettings from './Settings/Payment';
import HomepageSettings from './Settings/Homepage';
import ReportingSettings from './Settings/Reporting';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { addErrorMessage, isValidId } from '../../../utils/utils';
import ListDetails from '../../../components/_v2/ColumnList/Details';
import { getMsg } from '../../../utils/IntlGlobalProvider';
import { requestSaveCompanyContract } from '../../../actions/companies-actions';
import { addSuccessMsg } from '../../../utils/flashMessage/creator';

import {
  currentContractReferenceObjectSelector,
  currentCompanyContractSelector,
  currentCompanyIdSelector,
  userRoleSelector
} from '../../../selectors/all-selectors';
import { enhanceHomepageSettingsPayload } from '../../../api/data-enhancer';
import _cloneDeep from 'lodash/cloneDeep';
import VehicleSettings from './Settings/Vehicle';
import merge from 'merge-deep';
import { contractEditRules } from '../../../constants/routes-constants';
import { checkRole } from '../../../constants/backuser-role-rules';

const saveContract = ({ companyId, values }) => {
  return dispatch => {
    dispatch(requestSaveCompanyContract({ companyId, values })).then(
      () => addSuccessMsg(getMsg('contract_save_success')),
      error => dispatch(addErrorMessage({ error }))
    );
  };
};

class ContractV2 extends Component {
  componentWillMount() {
    this.setCallbacks();
    this.initMenuItems();
    this.initState();
  }

  setCallbacks() {
    this.handleGeneralSettings = values => {
      const { dispatch, companyId, contract } = this.props;
      values = { ...contract, ...values };
      dispatch(saveContract({ companyId, values }));
    };

    this.handleBookingSettings = ({ booking, ...values }) => {
      let { dispatch, companyId, contract, reference } = this.props;
      reference = { ...reference, booking };
      reference = JSON.stringify(reference);
      values = merge(contract, values, { reference });

      dispatch(saveContract({ companyId, values }));
    };

    this.handleMemberSettings = member => {
      let { dispatch, companyId, contract, reference } = this.props;

      reference = { ...reference, member };
      reference = JSON.stringify(reference);
      const values = { ...contract, reference };

      dispatch(saveContract({ companyId, values }));
    };

    this.handleReportingSettings = reporting => {
      let { dispatch, companyId, contract, reference } = this.props;
      reference = { ...reference, reporting };
      reference = JSON.stringify(reference);
      const values = { ...contract, reference };
      dispatch(saveContract({ companyId, values }));
    };

    this.handleVehicleSettings = ({ vehicle, ...values }) => {
      let { dispatch, companyId, contract, reference } = this.props;

      reference = { ...reference, vehicle };
      reference = JSON.stringify(reference);
      values = merge(contract, values, { reference });

      dispatch(saveContract({ companyId, values }));
    };

    this.handlePaymentSettings = payment => {
      let { dispatch, companyId, contract, reference } = this.props;

      reference = { ...reference, payment };
      reference = JSON.stringify(reference);
      const values = { ...contract, reference };

      dispatch(saveContract({ companyId, values }));
    };

    this.handleClose = () => {
      this.setState({ selectedItem: -1 });
    };

    this.handleHomepageSettings = values => {
      let _serviceContent = enhanceHomepageSettingsPayload(values);
      this.saveEnhancedHomepageData(_serviceContent);
    };

    this.saveEnhancedHomepageData = values => {
      let { dispatch, companyId, contract, reference } = this.props;
      let _services = [];
      let newServices = _cloneDeep(reference.services);
      _services = newServices.map(obj => {
        if (obj.id === values.id) {
          return { ...obj, ...values };
        } else {
          return obj;
        }
      });

      reference = { ...reference, services: _services };
      reference = JSON.stringify(reference);
      values = { ...contract, reference };
      dispatch(saveContract({ companyId, values }));
    };
  }

  initMenuItems() {
    this.menuItems = [
      {
        titleKey: 'contract.menu.item.general',
        Component: GeneralSettings,
        callback: this.handleGeneralSettings
      },
      {
        titleKey: 'contract.menu.item.booking',
        Component: BookingSettings,
        callback: this.handleBookingSettings
      },
      {
        titleKey: 'contract.menu.item.member',
        Component: MemberSettings,
        callback: this.handleMemberSettings
      },
      {
        titleKey: 'contract.menu.item.payment',
        Component: PaymentSettings,
        callback: this.handlePaymentSettings
      },
      {
        titleKey: 'contract.menu.item.vehicle',
        Component: VehicleSettings,
        callback: this.handleVehicleSettings
      },
      {
        titleKey: 'contract.menu.item.homepage',
        Component: HomepageSettings,
        callback: this.handleHomepageSettings,
        overwriteClass: 'mobile-home'
      },
      {
        titleKey: 'contract.menu.item.reporting',
        Component: ReportingSettings,
        callback: this.handleReportingSettings
      }
    ];
  }

  initState() {
    const index = 0;
    this.state = { selectedItem: index, data: this.menuItems[index] };
  }

  handleMenuSelect(index) {
    this.setState({ selectedItem: index, data: this.menuItems[index] });
  }

  render() {
    if (!isValidId(this.props.companyId)) {
      return (
        <div className={cs('pageContainer', 'mainContainer_content')}>
          <div className="page-info-block">{getMsg('common_select_super_company')}</div>
        </div>
      );
    }

    const { selectedItem, data } = this.state;
    const { Component, titleKey, overwriteClass } = data || {};
    const limitedAccess = !checkRole(contractEditRules, this.props.userRole);

    return (
      <div className={cs('pageContainer', 'mainContainer_content')}>
        <div className={cs('contract-v2', 'side-list')}>
          <div className="sc-list">
            {this.menuItems.map((item, index) => {
              const selected = this.state.selectedItem === index;

              return (
                <div
                  key={index}
                  className={cs('sc-item', { 'sc-selected': selected, 'main-bg': selected })}
                  onClick={() => this.handleMenuSelect(index)}
                >
                  <div className="sc-name">{getMsg(item.titleKey)}</div>
                  <ReactSVG className="align-svg" src="/img/arrow-left.svg" />
                </div>
              );
            })}
          </div>
          {selectedItem !== -1 && (
            <ListDetails title={getMsg(titleKey)} onClose={this.handleClose} className={overwriteClass}>
              {Component && <Component onCallback={this.menuItems[selectedItem].callback} limitedAccess={limitedAccess} />}
            </ListDetails>
          )}
        </div>
      </div>
    );
  }
}

export default connect(state => {
  return {
    userRole: userRoleSelector(state),
    companyId: currentCompanyIdSelector(state),
    contract: currentCompanyContractSelector(state),
    reference: currentContractReferenceObjectSelector(state)
  };
})(injectIntl(ContractV2));
