import React, { Component } from 'react';
import ColumnList from '../../../components/_v2/ColumnList';
import { connect } from 'react-redux';
import { createMenuItems, resolveActive, resolveSelected, showNextView, showParentView } from '../../../components/_v2/ColumnList/helpers';
import { FormattedMessage } from 'react-intl';
import { renderItem } from './helpers';
import { checkRole } from '../../../constants/backuser-role-rules';
import { editVehicleBrandsRules } from '../../../constants/routes-constants';
import { userRoleSelector } from '../../../selectors/all-selectors';

class VehicleVersions extends Component {
  componentWillMount() {
    this.setCallbacks();
    this.setVars();
  }

  setVars() {
    if (checkRole(editVehicleBrandsRules, this.props.role)) {
      this.menuItems = createMenuItems({ onAdd: this.handleAdd, addKey: 'addVersion_title' });
    }
  }

  setCallbacks() {
    this.handleEdit = ({ id }, _, { openInNewTab }) => {
      showNextView.call(this, 'vehicleEditVersion', ':versionId', id, openInNewTab);
    };

    this.handleAdd = () => {
      showNextView.call(this, 'vehicleAddVersion');
    };

    this.resolveSelected = resolveSelected.bind(this);
    this.resolveActive = resolveActive.bind(this);
    this.showParentView = showParentView.bind(this);
  }

  render() {
    return (
      <ColumnList
        title={<FormattedMessage id="common_versions" />}
        inputRef={this.props.inputRef}
        items={this.props.list}
        renderItem={renderItem}
        onSelect={this.handleEdit}
        selected={this.resolveSelected}
        active={this.resolveActive}
        menuItems={this.menuItems}
        onBack={this.showParentView}
        localSearchEnabled
      />
    );
  }
}

const mapStateToProps = state => {
  return {
    list: state.versions.list,
    role: userRoleSelector(state)
  };
};

VehicleVersions = connect(mapStateToProps)(VehicleVersions);

export default VehicleVersions;
