import React, { Component, PropTypes as T } from 'react';
import { connect } from 'react-redux';
import Dialog from 'material-ui/Dialog';
import autoBind from 'react-autobind';
import { FormattedMessage, injectIntl } from 'react-intl';
import { reduxForm } from 'redux-form';
import _get from 'lodash/get';
import classNames from 'classnames';
import moment from 'moment';
import {
  createValidator,
  notEmptyWhen,
  futureMoment,
  endMomentBeforeStartMoment,
  notEmpty,
  length
} from '../../../validation/all-validation';
import { namedCompose, valuesToMoment } from '../../../utils/utils';
import {
  emptyCreateStatusData,
  setVehicleStatus,
  getDataVehiclePlanning,
  openConfirmAddStatusModal,
  closeConfirmAddStatusModal
} from '../../../actions/all-actions';
import { MINUTES_BEFORE_CHANGE_STATUS } from '../../../constants/business-constants';
import { hourOptions, minuteOptions } from '../../../constants/options-constants';
import { VEHICLE_STATUSES } from '../../../constants/backend-constants';
import BoxedInput from '../../../components/BoxedInput/BoxedInput';
import BoxedSelect from '../../../components/BoxedSelect/BoxedSelect';
import EkButton from '../../../components/EkButton/EkButton';
import FieldErrorMsg from '../../../components/FieldErrorMsg/FieldErrorMsg';
import { statusListLabels } from '../../../constants/options-constants';
import CalendarIcon from 'material-ui/svg-icons/action/date-range';
import TimeIcon from 'material-ui/svg-icons/device/access-time';
import DropDownMenu from 'material-ui/DropDownMenu';
import MenuItem from 'material-ui/MenuItem';
import FlatButton from 'material-ui/FlatButton';
import IconButton from 'material-ui/IconButton';
import CloseIcon from 'material-ui/svg-icons/navigation/close';

class VehiclePlanningCreateStatus extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.derivedStateFromProps(props);
  }

  componentWillReceiveProps(props) {
    this.derivedStateFromProps(props);
  }

  derivedStateFromProps(props) {
    this.handleSubmit = props.handleSubmit(this.ownSubmit);
  }

  handleClose() {
    this.props.dispatch(emptyCreateStatusData());
  }

  handleChangeDropDown(ev, key, value) {
    this.props.fields.status.onChange(value);
  }

  ownSubmit() {
    const { dispatch, statusForm, vehicleDeviceNumber } = this.props;

    const hasDeviceNumber = !!vehicleDeviceNumber;

    if (!hasDeviceNumber && statusForm.status.value === VEHICLE_STATUSES.READY) {
      dispatch(openConfirmAddStatusModal());
    } else {
      this.create();
    }
  }

  create() {
    const { dispatch, statusForm, vehicleId } = this.props;

    dispatch(setVehicleStatus(statusForm, vehicleId)).then(
      () => {
        dispatch(emptyCreateStatusData());
        setTimeout(() => dispatch(getDataVehiclePlanning()), 1000); // wait server to process data
      },
      () => true
    );
  }

  confirmDialogAddStatusReady() {
    const { dispatch } = this.props;
    return (
      <Dialog
        bodyStyle={{ overflow: 'inherit' }}
        contentStyle={{ width: '400px' }}
        onRequestClose={this.handleClose}
        open={this.props.openConfirmAddStatusModal}
      >
        <div style={{ marginBottom: '10px' }}>
          <FormattedMessage id="add-status-without-device-serial-number" />
        </div>
        <div style={{ textAlign: 'right' }}>
          <EkButton type="button" skinType="reverse" onAction={() => dispatch(closeConfirmAddStatusModal())}>
            <FormattedMessage id="common_no" />
          </EkButton>
          <span style={{ marginLeft: '10px' }}>
            <EkButton type="button" onAction={this.create}>
              <FormattedMessage id="common_yes" />
            </EkButton>
          </span>
        </div>
      </Dialog>
    );
  }

  render() {
    const {
      fields: { statusDate, endStatusDate, status, hour, endHour, endMinute, minute, comment },
      openCreateStatusModal,
      intl
    } = this.props;
    const today = new Date();

    return (
      <div>
        {openCreateStatusModal && (
          <Dialog
            bodyStyle={{ overflow: 'inherit' }}
            contentStyle={{ width: '300px' }}
            onRequestClose={this.handleClose}
            open={openCreateStatusModal}
            title={intl.messages.editStatusForm_title}
          >
            <div className="vehiclePlanning_closeIconAbosuluteWrap">
              <IconButton onClick={this.handleClose}>
                <CloseIcon />
              </IconButton>
            </div>
            <section className="vehiclePlanningCreateStatus">
              <form action="#" className="editStatusForm" onSubmit={this.handleSubmit}>
                <div className="vehiclePlanningCreateStatus_container">
                  <div className="VehiclePlanningEditBooking_col1Row2">
                    <CalendarIcon className="vehiclePlanningCreateStatus_calendarIcon" />
                    <BoxedInput
                      formRowItemKey="editStatusForm_date"
                      id="date"
                      skinType="date"
                      type="date"
                      minDate={today}
                      customClass={classNames('boxedInputWrapper--label', 'vehiclePlanning_date_block')}
                      labelKey="editStatusForm_date"
                      field={statusDate}
                    />

                    <div className="VehiclePlanningEditBooking_col1Row2_dateWrap">
                      <TimeIcon />
                      <BoxedSelect
                        formRowItemKey="editStatusForm_hour"
                        labelKey="editStatusForm_hour"
                        options={hourOptions}
                        customClass="searchVehicle_boxedSelectWrapper"
                        field={hour}
                        fullWidth
                        hideLabel
                      />
                      <span className="VehiclePlanningEditBooking_col1Row2_dvojtecka">:</span>
                      <BoxedSelect
                        formRowItemKey="editStatusForm_min"
                        labelKey="editStatusForm_min"
                        options={minuteOptions}
                        customClass="searchVehicle_boxedSelectWrapper"
                        field={minute}
                        hideLabel
                      />
                    </div>
                  </div>

                  <FieldErrorMsg field={statusDate} customClass="vps-error-field" />
                  <FieldErrorMsg field={hour} customClass="vps-error-field" />
                  <FieldErrorMsg field={minute} customClass="vps-error-field" />

                  <div className="VehiclePlanningEditBooking_col1Row2">
                    <CalendarIcon className="vehiclePlanningCreateStatus_calendarIcon" />
                    <BoxedInput
                      formRowItemKey="editStatusForm_endDate"
                      id="endDate"
                      skinType="date"
                      minDate={today}
                      type="date"
                      customClass={classNames('boxedInputWrapper--label', 'vehiclePlanning_date_block')}
                      labelKey="editStatusForm_end_date"
                      field={endStatusDate}
                    />
                    <div className="VehiclePlanningEditBooking_col1Row2_dateWrap">
                      <TimeIcon />
                      <BoxedSelect
                        formRowItemKey="editStatusForm_hour"
                        labelKey="editStatusForm_hour"
                        options={hourOptions}
                        customClass="searchVehicle_boxedSelectWrapper"
                        field={endHour}
                        hideLabel
                      />
                      <span className="VehiclePlanningEditBooking_col1Row2_dvojtecka">:</span>
                      <BoxedSelect
                        formRowItemKey="editStatusForm_min"
                        labelKey="editStatusForm_min"
                        options={minuteOptions}
                        customClass="searchVehicle_boxedSelectWrapper"
                        field={endMinute}
                        hideLabel
                      />
                    </div>
                  </div>

                  <FieldErrorMsg field={endStatusDate} customClass="vps-error-field" />
                  <FieldErrorMsg field={endHour} customClass="vps-error-field" />
                  <FieldErrorMsg field={endMinute} customClass="vps-error-field" />
                </div>

                <DropDownMenu
                  value={status.value}
                  onChange={this.handleChangeDropDown}
                  style={{ width: 'calc(100% + 15px)', marginLeft: '-15px' }}
                  underlineStyle={{ borderTop: 'rgba(0, 0, 0, .7)' }}
                >
                  {statusListLabels.map(item => (
                    <MenuItem value={item.value} primaryText={intl.messages[item.labelKey]} key={item.value} />
                  ))}
                </DropDownMenu>

                <BoxedInput
                  formRowItemKey="editStatusForm_comment"
                  id="status-comment"
                  customClass="boxedInput_inputText"
                  customInputClass="boxedInput_inputTextArea"
                  type="textarea"
                  placeholder={intl.messages.editStatusForm_comment}
                  field={comment}
                >
                  <FieldErrorMsg field={comment} customClass="fieldErrorMsg--editStatusForm_comment" />
                </BoxedInput>

                <div className="vehiclePlanningCreateStatus_buttonWrap">
                  <FlatButton
                    key="no"
                    label={intl.messages.booking_create_confirmation_abort_button}
                    onClick={this.handleClose}
                    className="flatButton"
                  />
                  <FlatButton key="yes" label={intl.messages.backUserForm_save_button} onClick={this.handleSubmit} className="flatButton" />
                </div>
              </form>
            </section>
          </Dialog>
        )}
        {this.confirmDialogAddStatusReady()}
      </div>
    );
  }
}

VehiclePlanningCreateStatus.propTypes = {
  openCreateStatusModal: T.bool,
  openCrateStatusData: T.object
};

const getStartDate = valuesToMoment({
  dateName: 'statusDate'
});

const getEndDate = valuesToMoment({
  dateName: 'endStatusDate',
  hourName: 'endHour',
  minuteName: 'endMinute'
});

export default namedCompose(
  connect(state => {
    return {
      statusForm: _get(state, 'form.VehiclePlanningCreateStatusForm'),
      vehicleId: _get(state, 'vehiclePlanning.createStatusData.vehicle.id'),
      openConfirmAddStatusModal: _get(state, 'vehiclePlanning.openConfirmAddStatusModal'),
      vehicleDeviceNumber: _get(state, 'vehicles.detailVehicle.device.serialNumber')
    };
  }),
  injectIntl,
  reduxForm(
    {
      form: 'VehiclePlanningCreateStatusForm'
    },
    state => {
      const {
        vehiclePlanning: { openCreateStatusModal, createStatusData, periodInDays, startDate }
      } = state;

      return {
        openCreateStatusModal,
        createStatusData,
        fields: ['statusDate', 'endStatusDate', 'status', 'hour', 'endHour', 'endMinute', 'minute', 'comment'],
        initialValues: {
          statusDate:
            periodInDays === 1
              ? moment(startDate).toDate()
              : moment(startDate)
                  .add(createStatusData.dayIndex, 'days')
                  .toDate(),
          hour: periodInDays === 1 ? createStatusData.dayIndex : '0',
          minute: '0',
          endStatusDate:
            periodInDays === 1
              ? moment(startDate).toDate()
              : moment(startDate)
                  .add(createStatusData.dayIndex + 1, 'days')
                  .toDate(),
          endHour: periodInDays === 1 ? createStatusData.dayIndex + 1 : '0',
          endMinute: 0,
          status: 'REPARATION'
        },
        validate: createValidator({
          status: [notEmpty()],
          statusDate: [
            futureMoment({
              getMoment: getStartDate,
              withOffsetMinute: MINUTES_BEFORE_CHANGE_STATUS
            }),
            notEmpty()
          ],
          hour: [notEmpty()],
          minute: [notEmpty()],
          endStatusDate: [
            endMomentBeforeStartMoment({
              getStartDate,
              getEndDate
            }),
            futureMoment({
              getMoment: getEndDate,
              withOffsetMinute: MINUTES_BEFORE_CHANGE_STATUS
            }),
            notEmptyWhen('endHour'),
            notEmptyWhen('endMinute')
          ],
          endHour: [notEmptyWhen('endStatusDate'), notEmptyWhen('endMinute')],
          endMinute: [notEmptyWhen('endStatusDate'), notEmptyWhen('endHour')],
          comment: [length({ max: 255 })]
        })
      };
    }
  )
)(VehiclePlanningCreateStatus);
