// noinspection ES6CheckImport
import React, { Component, PropTypes } from 'react';

import { connect } from 'react-redux';
import Modal from 'react-modal';
import { FormattedMessage } from 'react-intl';
import { routeActions } from 'react-router-redux';
import classNames from 'classnames';
import { reset } from 'redux-form';
import _pickBy from 'lodash/pickBy';

import TableView from '../../components/TableView/TableView';
import PageManagementHeader from '../../components/PageManagementHeader/PageManagementHeader';
import PaginationControls from '../../components/PaginationControls/PaginationControls';
import NumberResultsSelectForm from '../../components/NumberResultsSelectForm/NumberResultsSelectForm';
import SearchVehicleDamages from '../../components/SearchVehicleDamages/SearchVehicleDamages';
import VehicleAddDamageForm from '../../components/VehicleAddDamageForm/VehicleAddDamageForm';
import EkButton from '../../components/EkButton/EkButton';
import { addDamage, openAddDamageModal, closeAddDamageModal, toggleVehicleDamagesFilters } from '../../actions/all-actions';
import { datesToString } from '../../utils/utils';
import routes, { vehicleDamagesEditRules } from '../../constants/routes-constants';
import { AddDamageModalStyles } from '../../constants/style-constants';
import { vehicleDamagesTableViewParams } from '../../constants/table-constants';
import { apiParams } from '../../constants/api-params-constants';
import { userRoleSelector } from '../../selectors/all-selectors';
import { checkRole } from '../../constants/backuser-role-rules';

class VehicleDamages extends Component {
  constructor(props) {
    super(props);
    this.bindFunctions();
    this.setReadOnly(props);
  }

  setReadOnly(props) {
    const { role } = props;
    this.readOnly = !checkRole(vehicleDamagesEditRules, role);
  }

  // noinspection DuplicatedCode
  bindFunctions() {
    this.handleGotoPage = this.handleGotoPage.bind(this);
    this.handleNumberResultsSelect = this.handleNumberResultsSelect.bind(this);
    this.handleSearchVehicleDamagesForm = this.handleSearchVehicleDamagesForm.bind(this);
    this.handleFilteredResetSearch = this.handleFilteredResetSearch.bind(this);
    this.handleToggleFilters = this.handleToggleFilters.bind(this);
    this.handleAddDamage = this.handleAddDamage.bind(this);
    this.handleAbortAddDamage = this.handleAbortAddDamage.bind(this);
    this.handleConfirmAddDamage = this.handleConfirmAddDamage.bind(this);
  }

  handleGotoPage(value) {
    const { dispatch, listMetadata, urlParams } = this.props;
    let params = {
      page: {
        number: value,
        size: listMetadata.paginationInfo.pageSize
      }
    };
    let newParams = Object.assign({}, urlParams, params);
    dispatch(routeActions.push(routes.vehicleDamages.path.replace(':search', encodeURIComponent(JSON.stringify(newParams)))));
  }

  handleNumberResultsSelect(value) {
    const { dispatch, urlParams } = this.props;
    let params = {
      page: {
        number: 1,
        size: parseInt(value)
      }
    };
    let newParams = Object.assign({}, urlParams, params);
    dispatch(routeActions.push(routes.vehicleDamages.path.replace(':search', encodeURIComponent(JSON.stringify(newParams)))));
  }

  handleSearchVehicleDamagesForm(searchParams) {
    const { dispatch } = this.props;

    const payload = Object.assign(
      {},
      _pickBy(searchParams, function(value) {
        return value !== '' && value !== null && typeof value !== 'undefined';
      })
    );
    const newParams = Object.assign({}, apiParams.default, payload);
    dispatch(
      routeActions.push(routes.vehicleDamages.path.replace(':search', encodeURIComponent(JSON.stringify(datesToString(newParams)))))
    );
  }

  handleFilteredResetSearch() {
    const { dispatch, listMetadata } = this.props;
    dispatch(reset('searchVehicleDamages'));

    let params = {
      page: {
        number: 1,
        size: listMetadata.paginationInfo.pageSize
      }
    };
    dispatch(routeActions.push(routes.vehicleDamages.path.replace(':search', encodeURIComponent(JSON.stringify(params)))));
  }

  handleToggleFilters() {
    const { dispatch } = this.props;

    dispatch(toggleVehicleDamagesFilters());
  }

  handleAddDamage() {
    const { dispatch } = this.props;
    dispatch(openAddDamageModal());
  }

  handleAbortAddDamage() {
    const { dispatch } = this.props;
    dispatch(closeAddDamageModal());
  }

  handleConfirmAddDamage(formData) {
    const { dispatch } = this.props;

    dispatch(closeAddDamageModal());
    dispatch(addDamage(formData));
  }

  render() {
    const {
      list,
      listMetadata,
      currentSortedIndex,
      sortIsDescending,
      vehicleDamagesFiltersFormIsOpen,
      openedAddDamageModal,
      damageCurrentImage,
      urlParams
    } = this.props;

    const tableViewParams = Object.assign({}, vehicleDamagesTableViewParams, {
      sortById: currentSortedIndex,
      sortIsDescending
    });

    const defaultParams = {
      bookingId: urlParams.bookingId,
      plateNumber: urlParams.plateNumber,
      damageType: urlParams.damageType,
      status: urlParams.status,
      damageStartDate: urlParams.damageStartDate,
      damageEndDate: urlParams.damageEndDate
    };

    return (
      <div className="vehicleDamagesPage mainContainer_content">
        <div className="pageContainer">
          <div className="vehicleDamagesPage_content">
            <section className="vehicleDamagesPage_management">
              <div className="vehicleDamagesPage_filters">
                <div className="vehicleDamagesPage_actions">
                  <EkButton
                    skinType="variant1"
                    customClass={classNames('vehicleDamagesPage_filtersButton', {
                      _is_opened: vehicleDamagesFiltersFormIsOpen
                    })}
                    onAction={this.handleToggleFilters}
                  >
                    <FormattedMessage id="vehicleDamages_filters_button" />
                  </EkButton>

                  <EkButton skinType="reverse" onAction={this.handleAddDamage} disabled={this.readOnly}>
                    <FormattedMessage id="vehicleDamagesPage_addDamage" />
                  </EkButton>
                </div>

                {!vehicleDamagesFiltersFormIsOpen ? (
                  ''
                ) : (
                  <div className="vehicleDamagesFiltersFormWrapper">
                    <SearchVehicleDamages
                      onCallback={this.handleSearchVehicleDamagesForm}
                      onResetCallBack={this.handleFilteredResetSearch}
                      initialValues={defaultParams}
                    />
                  </div>
                )}

                {openedAddDamageModal && (
                  <Modal isOpen={openedAddDamageModal !== null} style={AddDamageModalStyles}>
                    <VehicleAddDamageForm
                      onCallback={this.handleConfirmAddDamage}
                      onAbort={this.handleAbortAddDamage}
                      currentImage={damageCurrentImage}
                    />
                  </Modal>
                )}
              </div>

              <div className="vehicleDamagesPage_list">
                <div className="membersPage_pagination">
                  <NumberResultsSelectForm
                    onCallback={this.handleNumberResultsSelect}
                    customClass="membersPage_numberResultsSelectForm"
                    customLabelClass="numberResultsSelectForm_label"
                    onNumberResultsSelect={this.handleNumberResultsSelect}
                    initialValues={{
                      numberResults: listMetadata.paginationInfo ? listMetadata.paginationInfo.pageSize : '10'
                    }}
                  />

                  <PaginationControls
                    paginationInfo={listMetadata.paginationInfo}
                    onPageChange={this.handleGotoPage}
                    customClass="membersPage_paginationControls"
                  />
                </div>

                <TableView customClass="groupsPage_tableViewWrapper" params={tableViewParams} content={list} />
              </div>
            </section>
          </div>
        </div>
      </div>
    );
  }
}

VehicleDamages.displayName = 'VehicleDamages';

VehicleDamages.propTypes = {
  list: PropTypes.array,
  listMetadata: PropTypes.object,
  vehicleDamagesFiltersFormIsOpen: PropTypes.bool
};

export default connect(state => {
  const {
    vehicleDamages: {
      paginatedResults,
      urlParams,
      currentSortedIndex,
      sortIsDescending,
      openedAddDamageModal,
      damageCurrentImage,
      vehicleDamagesFiltersFormIsOpen
    }
  } = state;

  return {
    list: paginatedResults.results,
    listMetadata: paginatedResults.metadata,
    urlParams,
    currentSortedIndex,
    sortIsDescending,
    openedAddDamageModal,
    damageCurrentImage,
    vehicleDamagesFiltersFormIsOpen,
    role: userRoleSelector(state)
  };
})(VehicleDamages);
