import React, { Component } from 'react';
import { connect } from 'react-redux';
import { routeActions } from 'react-router-redux';

import VehiclesColorForm from '../../components/VehiclesColorForm/VehiclesColorForm';
import { createColorRequest, showSuccessMsg } from '../../actions/all-actions';
import routes from '../../constants/routes-constants';

class AddVehiclesColor extends Component {
  componentWillMount() {
    this.handleAddColor = ({ code }) => {
      const { dispatch } = this.props;

      dispatch(createColorRequest({ code })).then(() => {
        dispatch(showSuccessMsg({ key: 'vehiclesColorForm_create_color_success', delay: true }));
        dispatch(routeActions.push(routes.vehiclesColors.path));
      });
    };
  }

  render() {
    return (
      <div className="addVehiclesColorPage mainContainer_content">
        <div className="pageContainer">
          <div className="addVehiclesColorPage_content">
            <section className="addVehiclesColorPage_management">
              <VehiclesColorForm
                onCallback={this.handleAddColor}
                initialValues={{
                  code: ''
                }}
              />
            </section>
          </div>
        </div>
      </div>
    );
  }
}

AddVehiclesColor.displayName = 'AddVehiclesColor';

export default connect(state => {
  const {} = state;

  return {};
})(AddVehiclesColor);
