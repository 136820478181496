/* eslint-disable react/jsx-no-bind */
import React, { Component, PropTypes as T } from 'react';
import { connect } from 'react-redux';
import _partial from 'lodash/partial';
import moment from 'moment';
import { injectIntl } from 'react-intl';
import cs from 'classnames';

import FlatButton from 'material-ui/FlatButton';
import AddIcon from 'material-ui/svg-icons/content/add';
import CloseIcon from 'material-ui/svg-icons/navigation/close';
import FilterIcon from 'material-ui/svg-icons/content/filter-list';
import SearchIcon from 'material-ui/svg-icons/action/search';

import { createAc } from './../../../../components/_v2/ColumnList/autoComplete';
import Fuse from 'fuse.js';

import BoxedInput from '../../../../components/BoxedInput/BoxedInput';
import IconButton from 'material-ui/IconButton';
import { RadioButton, RadioButtonGroup } from 'material-ui/RadioButton';
import RefreshIcon from 'material-ui/svg-icons/navigation/refresh';
import LeftArrow from 'material-ui/svg-icons/hardware/keyboard-arrow-left';
import RightArrow from 'material-ui/svg-icons/hardware/keyboard-arrow-right';

import {
  getDataVehiclePlanning,
  changeVehiclePlanningSetings,
  toggleCreateStatusMode,
  clearCreateBookingModal,
  toggleLegend,
  addFilter,
  editFilters
} from '../../../VehiclePlanning/VehiclePlanning.actions';
import { ALL } from '../../../../constants/generic-constants';

import { userRoleSelector } from '../../../../selectors/all-selectors';
import { BACKUSER_ROLE_FLEET_MANAGER } from '../../../../constants/backend-constants';
import { getAppDateFormatVP, getMainColor, removeMultiSpace, newProp, getFormValues, hasValues } from '../../../../utils/utils';
import ReactSVG from 'react-svg';
import { getMsg } from '../../../../utils/IntlGlobalProvider';
import { vehicleFuelTypes, vehicleUsageTypes } from '../../../../constants/options-constants';
import { createElement } from '../../../../utils/component';
import { regexPlateNumber } from '../../../../constants/regex';
import ListModal from '../../../../components/_v2/ColumnList/Modal';
import Filters from './Filters';
import { getFiltersTitle } from '../../../../components/_v2/ColumnList/helpers';

const fuseSearchKey = 'search';

const fuseOptions = {
  keys: [fuseSearchKey],
  minMatchCharLength: 3,
  threshold: 0.2
};

const getDatasetTypes = (childNodes, data) => {
  const { matches } = data;
  let indexes = { static: [] };

  for (let i = 0; i < matches.length; i++) {
    const { key, search, group } = matches[i].value;
    const node = childNodes[i];
    const item = group || key;

    if (node) {
      if (search) {
        if (!indexes[item]) indexes[item] = [];
        indexes[item].push(node);
      } else {
        indexes.static.push(node);
      }
    }
  }

  return indexes;
};

const addFuseResults = (results, fuse) => {
  for (let i = 0; i < fuse.length; i++) {
    results.push(fuse[i].item);
  }
};

const addSearchKey = (group, label) => ({
  search: group + ' ' + label,
  label
});

const getFuelTypeResults = () => {
  const key = 'fuelType';
  const group = getMsg(acLabels[key]);

  return vehicleFuelTypes.map(item => {
    return { key, value: item.value, ...addSearchKey(group, getMsg(item.labelKey)) };
  });
};

const getUsageResults = () => {
  const key = 'usages';
  const group = getMsg(acLabels[key]);

  return vehicleUsageTypes.map(item => {
    return { key, value: item.key, ...addSearchKey(group, getMsg(item.label)) };
  });
};

const getSitesResults = (sites) => {
  const key = 'sites';
  const group = getMsg(acLabels[key]);

  return sites.map(item => {
    return { key, value: item.id, ...addSearchKey(group, item.name) };
  });
};

const acLabels = {
  registrationNumber: 'common_placeHolder_searchForPlateNumber',
  usages: 'vehiclesFiltersForm_usage',
  fuelType: 'vehicleForm_fuelType',
  sites: 'autocomplete_company_sites_title',
  static: 'common_search_by'
};

const appendDataset = (list, nodes, title, subTitle) => {
  const dataset = createElement('div', 'sc-dataset');
  const suggestions = createElement('div', 'sc-suggestions');

  if (title) {
    const header = createElement('div', 'sc-header');
    const titleEl = createElement('span', 'sc-title');

    titleEl.textContent = title;
    header.appendChild(titleEl);

    if (subTitle) {
      const subTitleEl = createElement('span', 'sc-value');
      subTitleEl.textContent = ' ' + subTitle;
      header.appendChild(subTitleEl);
    }

    dataset.appendChild(header);
  }

  suggestions.append(...nodes);
  dataset.appendChild(suggestions);
  list.appendChild(dataset);

  return dataset;
};

const formatSearchSelect = (selection, input) => {
  const { key, value } = selection.value || {};

  if (value) {
    return { [key]: value };
  }
  return { [key]: input };
};

class VehiclePlanningSetup extends Component {
  componentDidMount() {
    this.createAc();
    this.bindEvents();
  }

  setVars() {
    this.state = { filtersOpen: false, input: '' };
    this.boundEvents = [];
  }

  componentWillMount() {
    this.setVars();
    this.setCallbacks();
    this.initProps();
  }

  initProps() {
    this.componentPropsUpdated(this.props);
    this.propsInit = true;
  }

  componentWillReceiveProps(props) {
    this.componentPropsUpdated(props);
  }

  componentPropsUpdated(props) {
    const newLocale = newProp.call(this, props, 'locale');
    if (newLocale) {
      this.fuseFuelType = new Fuse(getFuelTypeResults(), fuseOptions);
      this.fuseUsage = new Fuse(getUsageResults(), fuseOptions);
    }
    this.fuseSite = new Fuse(getSitesResults(props.sites), fuseOptions);

  }

  setCallbacks() {
    this.setInputRef = ref => {
      this.inputRef = ref;
    };

    this.getAcList = (list, data) => {
      const { static: regex, ...types } = getDatasetTypes(list.childNodes, data);
      const labelPrefix = getMsg(acLabels.static);

      for (const type in types) {
        const subTitle = `(${getMsg(acLabels[type])})`;
        appendDataset(list, types[type], labelPrefix, subTitle);
      }

      if (regex.length) {
        appendDataset(list, regex, labelPrefix);
      }
    };

    this.getAcResult = (item, { value: data }) => {
      const { key, label } = data || {};
      const children = [];

      const labelKey = acLabels[key];
      const result = createElement('span', 'sc-label');

      result.innerHTML = label ? label : getMsg(labelKey);
      children.push(result);

      item.replaceChildren(...children);
    };

    this.getAcData = query => {
      return new Promise(resolve => {
        const results = [];
        query = removeMultiSpace(query);
        if (!query) return resolve(results);

        addFuseResults(results, this.fuseFuelType.search(query));
        addFuseResults(results, this.fuseUsage.search(query));
        addFuseResults(results, this.fuseSite.search(query));

        if (regexPlateNumber.test(query)) {
          results.push({ key: 'registrationNumber' });
        }

        resolve(results);
      });
    };

    this.handleSearchSelect = (event = {}) => {
      const { query = '', selection = {} } = event.detail || {};
      const data = formatSearchSelect(selection, removeMultiSpace(query));

      if (data.registrationNumber) {
        this.props.onSelectCallback(data.registrationNumber, data);
      } else {
        this.props.dispatch(addFilter(data));
      }
      this.clearInput();
    };

    this.handleKeyDown = event => {
      const regex = /^[A-Za-z0-9]$/;

      if (regex.test(event.key)) {
        if (event.target.value === '') this.setState({ input: event.key });
        else this.setState({ input: event.target.value });
      } else {
        if (event.metaKey && event.key === 'Backspace') {
          this.setState({ input: '' });
        } else {
          if (event.key === 'Backspace' || event.key === 'Clear' || event.key === 'Delete') {
            if (
              event.target.value.length <= 1 ||
              (event.target.selectionStart === 0 && event.target.selectionEnd === event.target.value.length)
            ) {
              this.setState({ input: '' });
            }
          }

          if (event.key === 'Escape') {
            this.clearInput();
          }
        }
      }
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleChangeDuration = this.handleChangeDuration.bind(this);
    this.handleChangePeriod = this.handleChangePeriod.bind(this);
    this.handleToggleCreateStatusMode = this.handleToggleCreateStatusMode.bind(this);
    this.handleChangeSite = this.handleChangeSite.bind(this);
    this.handleRefresh = this.handleRefresh.bind(this);
    this.handleLegend = this.handleLegend.bind(this);

    this.clearInput = () => {
      this.setState({ input: '' });
      this.inputRef.value = '';
    };

    this.showFilters = () => {
      this.setState({ filtersOpen: true });
    };

    this.closeFilters = () => {
      this.setState({ filtersOpen: false });
    };

    this.getSearchIcon = () => {
      return this.state.input === '' ? <SearchIcon /> : <CloseIcon onClick={() => this.clearInput()} />;
    };

    this.handleFiltersApply = () => {
      const values = getFormValues('vehiclesFilters');
      if (hasValues(values) || !values.hasOwnProperty('registrationNumber')) {
        this.props.dispatch(editFilters(values));
        if (values.registrationNumber || !values.hasOwnProperty('registrationNumber')) {
          this.props.onSelectCallback(values.registrationNumber || null);
        }
        this.closeFilters();
      }
    };
  }

  bindEvent(el, name, func) {
    el.addEventListener(name, func);
    this.boundEvents.push({ el, name, func });
  }

  createAc() {
    this.ac = createAc(this.inputRef, {
      data: this.getAcData,
      list: this.getAcList,
      result: this.getAcResult
    });
  }

  bindEvents() {
    this.bindEvent(this.inputRef, 'keydown', this.handleKeyDown);
    this.bindEvent(this.inputRef, 'selection', this.handleSearchSelect);
  }

  handleChange(type, value) {
    const { dispatch, periodInDays, startDate, siteId, subCompanySelected } = this.props;
    let params = {
      periodInDays,
      startDate: moment(startDate).toISOString(),
      subCompanyId: subCompanySelected.id
    };

    if (siteId !== ALL) {
      params.siteId = siteId;
    }
    params[type] = type === 'startDate' ? moment(value).toISOString() : value;

    if (type === 'startDate' && value === startDate) {
      // TODO fix for double getting data when selecting date
      return;
    } else {
      dispatch(changeVehiclePlanningSetings(params));
      dispatch(getDataVehiclePlanning(params));
    }
    dispatch(clearCreateBookingModal());
  }

  handleRefresh() {
    this.props.dispatch(getDataVehiclePlanning());
  }

  handleLegend() {
    this.props.dispatch(toggleLegend());
  }

  handleChangePeriod(values) {
    this.handleChange(values[0], values[1]);
  }

  handleChangeDuration(e, value) {
    window.localStorage.setItem('planningPeriodInDays', String(value));
    this.handleChange('periodInDays', value);
  }

  handleChangeSite(e, key, value) {
    this.handleChange('siteId', value);
    setTimeout(() => this.props.updateHeight(), 1000);
  }

  handleToggleCreateStatusMode() {
    this.props.dispatch(toggleCreateStatusMode());
  }

  getFilters() {
    const { filters } = this.props;

    if (filters) {
      return (
        <ListModal
          title={getFiltersTitle(getMsg('common_vehicles'))}
          isOpen={this.state.filtersOpen}
          onClose={this.closeFilters}
          className="list-filters-modal"
        >
          <Filters onFiltersApply={this.handleFiltersApply} onFiltersReset={this.handleFiltersReset} initialValues={{ ...filters }} />
        </ListModal>
      );
    }
  }

  render() {
    const { startDate, periodInDays, intl, createStatusMode, sideMenuOpen, readOnly, userRole, legendShown, filters } = this.props;

    const startDateField = {
      value: new Date(startDate),
      onChange: _partial(this.handleChange, 'startDate')
    };
    const filterActive = filters.fuels.length > 0 || filters.usages.length > 0 || filters.sites.length > 0 || !!filters.registrationNumber;
    const inputStyle = { display: 'flex' };
    const labelStyle = { display: 'flex', position: 'relative', float: 'none' };
    const radioStyle = { display: 'flex' };
    const svgLegendStyle = legendShown ? { fill: getMainColor() } : { fill: 'rgba(0, 0, 0, 0.5)' };
    return (
      <div className="new-vehiclePlanningSetup_container_inline">
        <div className={`new-vehiclePlanningSetup_wrap_inline ${sideMenuOpen && 'new-vehiclePlanningSetup_sideMenuOpen'}`}>
          <div className="new-vehiclePlanningSetup_formRow">
            <div style={{ minWidth: '210px' }}>
              <BoxedInput
                type="date"
                dateFormat={getAppDateFormatVP()}
                id="new-vehiclePlanningSetup_startDate"
                field={startDateField}
                datePickerTextFieldStyle={{ width: '100%' }}
              />
            </div>
            <div className="new-vehiclePlanningSetup_changePeriodWrap">
              <IconButton
                tooltipPosition="bottom-center"
                iconStyle={{ color: 'rgba(0,0,0,.8)' }}
                onClick={_partial(this.handleChangePeriod, ['startDate', moment(startDate).subtract(periodInDays, 'days')])}
              >
                <LeftArrow />
              </IconButton>

              <IconButton
                tooltipPosition="bottom-center"
                iconStyle={{ color: 'rgba(0,0,0,.8)' }}
                onClick={_partial(this.handleChangePeriod, ['startDate', moment(startDate).add(periodInDays, 'days')])}
              >
                <RightArrow />
              </IconButton>
            </div>

            <RadioButtonGroup
              className="period"
              name="periodInDays"
              valueSelected={periodInDays}
              onChange={this.handleChangeDuration}
              style={{ width: '100%', display: 'inline-flex' }}
            >
              <RadioButton
                value={1}
                label={intl.messages.common_1day}
                iconStyle={{ display: 'none' }}
                inputStyle={inputStyle}
                labelStyle={labelStyle}
                style={radioStyle}
              />
              <RadioButton
                value={3}
                label={intl.messages.common_3days}
                iconStyle={{ display: 'none' }}
                inputStyle={inputStyle}
                labelStyle={labelStyle}
                style={radioStyle}
              />
              <RadioButton
                value={7}
                label={intl.messages.common_7days}
                iconStyle={{ display: 'none' }}
                inputStyle={inputStyle}
                labelStyle={labelStyle}
                style={radioStyle}
              />
            </RadioButtonGroup>
          </div>

          <div className="new-vehiclePlanningSetup_buttonWrap ">
            <div className="vp-filters-wrap">
              <div className="auto-complete dropdown-search-wrap">
                <input type="text" className="sc-search" placeholder={'search'} ref={this.setInputRef} onBlur={this.getSearchIcon} />
                <div className={cs('sc-search-icon', { close: this.state.input !== '' })}>{this.getSearchIcon()}</div>
              </div>
              <div className={cs('sc-filters-wrap', { 'sc-active': filterActive })}>
                <span onClick={this.showFilters}>
                  <FilterIcon />
                </span>
              </div>
            </div>

            {this.getFilters()}
            {(!readOnly || userRole === BACKUSER_ROLE_FLEET_MANAGER) && (
              <FlatButton
                label={intl.messages.editStatusForm_title}
                icon={createStatusMode ? <CloseIcon /> : <AddIcon />}
                onClick={this.handleToggleCreateStatusMode}
                className="flatButton"
              />
            )}
            <IconButton onClick={this.handleRefresh}>
              <RefreshIcon />
            </IconButton>

            <IconButton onClick={this.handleLegend}>
              <ReactSVG src="/img/info.svg" svgClassName="svg-icon" svgStyle={svgLegendStyle} />
            </IconButton>
          </div>
        </div>
      </div>
    );
  }
}

VehiclePlanningSetup.displayName = 'VehiclePlanningSetup';

VehiclePlanningSetup.propTypes = {
  savedData: T.object,
  startDate: T.string,
  periodInDays: T.number,
  updateHeight: T.func,
  createBookingMode: T.bool,
  readOnly: T.bool
};

export default connect(state => {
  const {
    vehiclePlanning: { startDate, periodInDays, createBookingMode, createStatusMode, data: { sites }, siteId, legendShown, filters },
    subCompanies: { subCompanySelected },
    sideMenu: { isOpen },
    i18n: { locale }
  } = state;

  return {
    startDate,
    periodInDays,
    subCompanySelected,
    createBookingMode,
    createStatusMode,
    siteId,
    sites,
    filters,
    legendShown,
    sideMenuOpen: isOpen,
    userRole: userRoleSelector(state),
    locale
  };
})(injectIntl(VehiclePlanningSetup));
