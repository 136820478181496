import * as types from '../constants/actionTypes-constants';
import { mapArrayIndexToObjectById } from '../utils/utils';

const defaultState = {
  scheduledExportList: [],
  scheduledExportMap: {},
  scheduledExportId: '',
  scheduledExportListFilters: undefined
};

function updateScheduledExportList(data) {
  return {
    scheduledExportList: data,
    scheduledExportMap: mapArrayIndexToObjectById(data)
  };
}

export default function exports(state = defaultState, action) {
  switch (action.type) {
    case types.EXPORTS_GET_CONFIGURATIONS_SUCCESS: {
      return { ...state, ...updateScheduledExportList(action.data) };
    }

    case types.EXPORTS_ADD_CONFIGURATION_SUCCESS: {
      const newList = [action.data, ...(state.scheduledExportList || [])];
      return { ...state, ...updateScheduledExportList(newList) };
    }

    case types.EXPORTS_DELETE_CONFIGURATIONS_SUCCESS: {
      const removeByIdPredicate = ({ id } = {}) => id !== action.id;
      const newList = (state.scheduledExportList || []).filter(removeByIdPredicate);
      return { ...state, ...updateScheduledExportList(newList) };
    }

    case types.EXPORTS_SET_CURRENT_CONFIGURATION_ID: {
      return { ...state, scheduledExportId: action.id };
    }

    case types.EXPORTS_ADD_LIST_FILTER: {
      const { scheduledExportListFilters = {} } = state;
      const { filter = {} } = action;

      for (const prop in filter) {
        const value = filter[prop];
        const array = scheduledExportListFilters[prop] || [];

        if (!array.includes(value)) {
          scheduledExportListFilters[prop] = [...array, value];
          return { ...state, scheduledExportListFilters: { ...scheduledExportListFilters } };
        }
      }
      return state;
    }

    case types.EXPORTS_RESET_LIST_FILTER: {
      return { ...state, scheduledExportListFilters: undefined };
    }

    case types.EXPORTS_EDIT_LIST_FILTER: {
      return { ...state, scheduledExportListFilters: action.values };
    }

    default: {
      return state;
    }
  }
}
