import callApi from '../actions/api-actions';
import * as types from '../constants/actionTypes-constants';
import { addErrorMessage, withCol } from '../utils/utils';
import { formatScheduledExportFormDataForApi } from '../utils/dataFormatUtils';
import { addSuccessMsg } from '../utils/flashMessage/creator';
import { getMsg } from '../utils/IntlGlobalProvider';

export function getScheduledExports() {
  return dispatch => {
    return dispatch(callApi('exports', 'getConfigurations')).then(
      data => {
        dispatch({ type: types.EXPORTS_GET_CONFIGURATIONS_SUCCESS, data });
        return Promise.resolve();
      },
      error => {
        dispatch(addErrorMessage({ error }));
        return Promise.resolve();
      }
    );
  };
}

export function addScheduledExport(values) {
  const data = formatScheduledExportFormDataForApi(values);

  return dispatch => {
    return dispatch(callApi('exports', 'createConfiguration', data)).then(
      data => {
        addSuccessMsg(withCol('scheduled.export.add') + ' ' + getMsg('common_success'));
        dispatch({ type: types.EXPORTS_ADD_CONFIGURATION_SUCCESS, data });
        return Promise.resolve();
      },
      error => {
        dispatch(addErrorMessage({ error }));
        return Promise.reject();
      }
    );
  };
}

export function setScheduledExportCurrentId(id) {
  return { type: types.EXPORTS_SET_CURRENT_CONFIGURATION_ID, id };
}

export function deleteScheduledExport(id) {
  return dispatch => {
    return dispatch(callApi('exports', 'deleteConfigurations', id)).then(
      () => {
        addSuccessMsg(withCol('common_delete') + ' ' + getMsg('common_success'));
        dispatch({ type: types.EXPORTS_DELETE_CONFIGURATIONS_SUCCESS, id });
        return Promise.resolve();
      },
      error => {
        dispatch(addErrorMessage({ error }));
        return Promise.reject();
      }
    );
  };
}

export function addScheduledExportListFilters(filter) {
  return {
    type: types.EXPORTS_ADD_LIST_FILTER,
    filter
  };
}

export function editScheduledExportListFilters(values) {
  return {
    type: types.EXPORTS_EDIT_LIST_FILTER,
    values
  };
}

export function resetScheduledExportListFilters() {
  return {
    type: types.EXPORTS_RESET_LIST_FILTER
  };
}
