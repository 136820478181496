import React, { Component, PropTypes } from 'react';
import { connect } from 'react-redux';
import { routeActions } from 'react-router-redux';

import _values from 'lodash/values';
import SubCompanyForm from '../SubCompanyForm/SubCompanyForm';
import { createSubCompanyRequest, createSubCompanyModalSuccess, addFlashMessage } from '../../../../actions/all-actions';
import { FLASH_MESSAGE_TYPE_SUCCESS, FLASH_MESSAGE_TYPE_ERROR } from '../../../../constants/generic-constants';
import routes from '../../../../constants/routes-constants';
import { bundleSelector } from '../../../../selectors/all-selectors';
import { addErrorMessage } from '../../../../utils/utils';
import BackLink from '../../../../components/BackLink/BackLink';
import { partialErrorCodes } from '../../../../constants/options-constants';

class AddSubCompany extends Component {
  componentWillMount() {
    this.handleCreateSubCompany = this.handleCreateSubCompany.bind(this);
    this.cancelAction = this.cancelAction.bind(this);
  }

  handleCreateSubCompany() {
    const { dispatch, currentCompany, bundle } = this.props;
    const companyId = this.props.companyId || currentCompany.id;

    dispatch(createSubCompanyRequest(companyId)).then(
      company => {
        dispatch(
          addFlashMessage({
            contentKey: 'subcompanyForm_create_company_success',
            type: FLASH_MESSAGE_TYPE_SUCCESS,
            delayToNextRoute: true
          })
        );

        if (this.props.isModal) {
          dispatch(createSubCompanyModalSuccess(company));
        } else {
          dispatch(routeActions.push(routes.subCompanies.path));
        }

        // should be called last
        if (this.props.onSubmit) {
          this.props.onSubmit();
        }
      },
      error => {
        if (error.status === 409 || error.status === 400) {
          dispatch(
            addFlashMessage({
              contentKey: 'subCompanies_add_error_conflict',
              type: FLASH_MESSAGE_TYPE_ERROR
            })
          );
        } else {
          const partialErrors = _values(partialErrorCodes.phoneNumbers);
          const msgParams = { bundle, partialErrors, error };
          dispatch(addErrorMessage(msgParams));
        }
      }
    );
  }

  getBackLink() {
    return `/#${routes.subCompanies.path}`;
  }

  cancelAction() {
    if (this.props.onCancel) {
      return this.props.onCancel();
    }
    this.props.dispatch(routeActions.push(routes.subCompanies.path));
  }

  render() {
    return (
      <div className="addSubCompanyPage mainContainer_content">
        <div className="pageContainer">
          {!this.props.isModal && <BackLink link={this.getBackLink()} labelKey="back_link_companies_list" />}
          <div className="addSubCompanyPage_content">
            <section className="addSubCompanyPage_management">
              <SubCompanyForm onCallback={this.handleCreateSubCompany} onCancel={this.cancelAction} />
            </section>
          </div>
        </div>
      </div>
    );
  }
}

AddSubCompany.displayName = 'AddSubCompany';

AddSubCompany.propTypes = {
  companyId: PropTypes.string,
  isModal: PropTypes.bool,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func
};

export default connect(state => {
  const {
    companies: { currentCompany }
  } = state;

  return {
    currentCompany,
    bundle: bundleSelector(state)
  };
})(AddSubCompany);
