import { getStore } from '../store/all-store';
import { toEventLogDate, fromEventLogDate, trimValues } from '../utils/utils';
import { smartcardsEventsActionTypes, smartcardsEventsResultTypes } from './options-constants';

// split jsx props from inFormat and stuff ?
// put validation aside

export const smartcardsEventsFormData = {
  name: 'searchSmartcardsEvents',
  fields: [
    {
      name: 'action',
      blockCustomClass: 'searchMembersForm_boxedSelectBlock',
      labelKey: 'smartcards_events_tableView_label_action',
      type: 'select',
      options: smartcardsEventsActionTypes
    },
    {
      name: 'result',
      blockCustomClass: 'searchMembersForm_boxedSelectBlock',
      labelKey: 'smartcards_events_tableView_label_result',
      type: 'select',
      options: smartcardsEventsResultTypes
    },
    {
      name: 'startDate',
      customClass: 'searchMembersForm_inputWrapper',
      labelKey: 'bookings_tableView_label_startDate',
      outFormat: toEventLogDate('min'),
      inFormat: fromEventLogDate,
      type: 'date',
      maxDate: new Date()
    },
    {
      name: 'endDate',
      customClass: 'searchMembersForm_inputWrapper',
      labelKey: 'bookings_tableView_label_endDate',
      outFormat: toEventLogDate('max'),
      inFormat: fromEventLogDate,
      type: 'date',
      maxDate: new Date()
    },
    {
      name: 'smartcardCardId',
      customClass: 'searchMembersForm_inputWrapper',
      labelKey: 'smartcards_events_tableView_label_smartcard_id',
      type: 'text'
    },
    {
      name: 'userLogin',
      customClass: 'searchMembersForm_inputWrapper',
      labelKey: 'common_user_login',
      type: 'text'
    },
    {
      name: 'vehicleRegistrationNumber',
      customClass: 'searchMembersForm_inputWrapper',
      labelKey: 'vehicleDamages_tableView_label_plateNumber',
      type: 'text'
    }
  ],
  inputFrom(inputs) {
    return this.fields
      .filter(field => inputs[field.name])
      .reduce((accumulator, field) => {
        accumulator[field.name] = field.inFormat ? field.inFormat(inputs[field.name]) : inputs[field.name];
        return accumulator;
      }, {});
  },
  get fieldsName() {
    return this.fields.map(field => field.name);
  },
  get fieldsValues() {
    const state = getStore().getState();
    const formValues = trimValues(state.form[this.name]);

    return this.fields
      .filter(field => formValues[field.name])
      .reduce((accumulator, field) => {
        accumulator[field.name] = field.outFormat ? field.outFormat(formValues[field.name]) : formValues[field.name];
        return accumulator;
      }, {});
  }
};

// Booking fields init values

export const PAID_BOOKING_INIT_VALUE = true;
export const BOOKING_COMMENT_INIT_VALUE = '';
export const BOOKING = 'BOOKING';
export const PREBOOKING = 'PREBOOKING';

// Other

export const COMPANY_EMAIL_SETUP_FORM = 'COMPANY_EMAIL_SETUP_FORM';
export const COMPANY_PAYBACK_SETTINGS_FORM = 'COMPANY_PAYBACK_SETTINGS_FORM';
export const COMPANY_STRIPE_SETTINGS_FORM = 'COMPANY_STRIPE_SETTINGS_FORM';
export const COMPANY_FORM = 'COMPANY_FORM';
