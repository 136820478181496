import React from 'react';
import _get from 'lodash/get';

import CustomField from '../../components/CustomFieldBooking/CustomFieldBooking';

function CustomFieldsBooking({ customFields, formFields, usage }) {
  return _get(customFields, 'length') > 0 ? (
    <section className="customFieldsBookingWrapper_section">
      <div className="customFieldsBookingWrapper">
        {customFields.reduce((result, fieldInfos = {}) => {
          const { vehicleUsages = [] } = fieldInfos;
          const usageAllowed = !(vehicleUsages.length && !vehicleUsages.includes(usage));

          if (usageAllowed) {
            result.push(<CustomField key={fieldInfos.id} fieldInfos={fieldInfos} field={formFields[fieldInfos.id]} />);
          }

          return result;
        }, [])}
      </div>
    </section>
  ) : (
    <div />
  );
}

CustomFieldsBooking.displayName = 'CustomFieldsBooking';

export default CustomFieldsBooking;
