/*eslint-disable react/jsx-no-bind */

import React, { Component } from 'react';
import Dialog from 'material-ui/Dialog';
import _pick from 'lodash/pick';
import _get from 'lodash/get';
import _merge from 'lodash/merge';
import _map from 'lodash/map';
import _find from 'lodash/find';
import _filter from 'lodash/filter';
import _includes from 'lodash/includes';
import _flatten from 'lodash/flatten';
import _isEmpty from 'lodash/isEmpty';
import _compact from 'lodash/compact';
import _flattenDeep from 'lodash/flattenDeep';
import _difference from 'lodash/difference';
import _padStart from 'lodash/padStart';
import _range from 'lodash/range';
import classNames from 'classnames';
import { FormattedHTMLMessage, FormattedMessage, injectIntl } from 'react-intl';
import autoBind from 'react-autobind';
import { reduxForm, getValues } from 'redux-form';
import moment from 'moment';
import classnames from 'classnames';
import TimeIcon from 'material-ui/svg-icons/device/access-time';
import CloseIcon from 'material-ui/svg-icons/navigation/close';
import IconButton from 'material-ui/IconButton';
import Loader from '../../../../components/MaterialLoader/MaterialLoader';
import config from '../../../../constants/config-constants';
import BoxedSelect from '../../../../components/BoxedSelect/BoxedSelect';
import BoxedInput from '../../../../components/BoxedInput/BoxedInput';
import AddressAutocomplete from '../../../../components/AddressAutocomplete/AddressAutocomplete';
import {
  toggleVehiclePlanningEditBooking,
  confirmBookingEdition,
  addFlashMessage,
  getDataVehiclePlanning,
  abortConfirmPreBooking,
  confirmPrebooking,
  clearFoundUsers,
  getEstimatedPriceBooking,
  toggleChangeMember
} from '../../../../actions/all-actions';
import { createValidator, notEmpty, stopValidationIf, addressOrSite, dateAfterOrEqualField } from '../../../../validation/all-validation';
import {
  namedCompose,
  enhanceSiteList,
  localToUTC,
  trimValues,
  daysListOfDatesRange,
  createCustomFieldsNames,
  createCustomFieldsValidators,
  addErrorMessage,
  vpChangeMemberType,
  scrollToFirstErrorDialog,
  vehicleAapter,
  toBoolean
} from '../../../../utils/utils';
import {
  bundleSelector,
  headerContractMemberValuesSelector,
  headerCompanyContractSelector,
  planningBookingCustomFieldsEditValuesSelector,
  planningBookingEditCustomFieldsSelector,
  vpEditBookingUsageOptionsSelector
} from '../../../../selectors/all-selectors';
import EkRadio from '../../../../components/EkRadio/EkRadio';
import FieldErrorMsg from '../../../../components/FieldErrorMsg/FieldErrorMsg';
import FormRow from '../../../../components/FormRow/FormRow';

import { FLASH_MESSAGE_TYPE_SUCCESS } from '../../../../constants/generic-constants';
import { enhanceBookingVehicleSearchRequestParams } from '../../../../api/data-enhancer';
import CustomFieldsBooking from '../../../../components/CustomFieldsBooking/CustomFieldsBooking';
import {
  ONE_WAY_TRIP,
  ROUND_TRIP,
  BOOKING_STATUS_PRE_BOOKED,
  BOOKING_USAGE_TYPE_BUSINESS,
  BOOKING_USAGE_TYPE_PRIVATE,
  BACKUSER_ROLE_ADMIN,
  BACKUSER_ROLE_SUPER_ADMIN,
  BACKUSER_ROLE_ROOT,
  VEHICLE_USAGE_TYPE_IMMEDIATE,
  VEHICLE_USAGE_TYPE_PLANNED,
  STATUS_APPROVED,
  BACKUSER_ROLE_ZONE_MANAGER,
  BACKUSER_ROLE_ADMIN_DEALER
} from '../../../../constants/backend-constants';
import { BOOKING, PREBOOKING } from '../../../../constants/form-constants';

import { pure } from 'recompose';
import EkTextarea from '../../../../components/EkTextarea/EkTextarea';
import EkButton from '../../../../components/EkButton/EkButton';
import IsGranted from '../../../../components/IsGranted/IsGranted';
import ReactSVG from 'react-svg';
import VehiclePlanningVehicleTile from './VehiclePlanningVehicleTile';
import GreenSwitch from '../../../../components/_v2/GreenSwitch/GreenSwitch';

class VehiclePlanningEditBooking extends Component {
  constructor(props) {
    super(props);
    autoBind(this);

    this.changeMemberType = vpChangeMemberType.bind(this);
    this.state = { memberTypeSelectDisabled: false };
    this.hoursOptions = [];
    this.setHoursOptions('pickupDate');
    this.setHoursOptions('returnDate');
    this.derivedStateFromProps(props, true);
  }

  // noinspection JSCheckFunctionSignatures
  componentWillReceiveProps(np) {
    const { values, bookingDetail } = this.props;
    if (bookingDetail && np.values.paidBooking) {
      if (np.values.paidBooking !== values.paidBooking) {
        if (!values.paidBooking && values.paidBooking !== undefined) this.props.dispatch(getEstimatedPriceBooking());
      }
    }
    this.derivedStateFromProps(np);
  }

  derivedStateFromProps(props, init) {
    this.updateMemberTypeOptions(props, init);
  }

  updateMemberTypeOptions(props, init) {
    const { memberTypes: newProp } = props;
    const { memberTypes: oldProp } = props;
    if (init || newProp !== oldProp) this.createMemberTypeOptions(newProp);
  }

  createMemberTypeOptions(memberTypes = []) {
    this.memberTypeOptions = memberTypes.map(type => ({
      label: type.name,
      value: type.id
    }));
  }

  componentWillMount() {
    const { dispatch } = this.props;
    dispatch(clearFoundUsers());
  }

  handleClose() {
    this.props.dispatch(abortConfirmPreBooking());
    this.props.dispatch(toggleVehiclePlanningEditBooking());
  }

  renderSuggestFiltered(list) {
    const { fields } = this.props;
    let suggestList;
    if (fields.from.value) {
      suggestList = list.filter(obj => obj.id !== fields.from.value.id);
    }
    return suggestList;
  }

  setHoursOptions(date) {
    const { bookingDetail } = this.props;
    const hours = _get(bookingDetail, 'schedule', false);
    return (this.hoursOptions[date] = hours ? this.hoursFiltered(hours, date) : config.availableHours);
  }

  findById(sites, id) {
    const schedule = sites.map(site => site.parkings.map(parking => (parking.id === id ? parking.schedule : '')));
    return _compact(_flattenDeep(schedule));
  }

  hoursFiltered(streams, date) {
    const { fields } = this.props;
    const _streams = _filter(_get(streams, 'recurringSlots', false), { open: true });
    const dateH = date === 'pickupDate' ? fields.pickupDate.value : fields.returnDate.value;
    const _streamsOpenSpecial = _filter(_get(streams, 'oneTimeSlots', false), { open: true });
    const d = moment(dateH).isoWeekday() - 1;
    const day = config.daysOfWeek[d];

    if (_streamsOpenSpecial) {
      _streamsOpenSpecial.map(specialDaysHoursStream => {
        if (
          moment(dateH).isBetween(
            moment(specialDaysHoursStream.startDate),
            moment(specialDaysHoursStream.endDate).subtract(1, 'days'),
            null,
            []
          )
        ) {
          specialDaysHoursStream.days = [];
          const daysList = daysListOfDatesRange(specialDaysHoursStream.startDate, specialDaysHoursStream.endDate);
          daysList.map(day =>
            specialDaysHoursStream.days.push(
              moment(day)
                .locale('en')
                .format('dddd')
                .toUpperCase()
            )
          );
          return specialDaysHoursStream;
        }
      });
    }

    if (_streams) {
      if (day) {
        const validSpecialStreams = _find(_streamsOpenSpecial, { days: [day.value] });
        const validStreams = _find(_streams, { days: [day.value] });
        let goodTimes = [];
        if (
          validSpecialStreams &&
          moment(dateH).isBetween(moment(validSpecialStreams.startDate), moment(validSpecialStreams.endDate).subtract(1, 'days'), null, [])
        ) {
          validSpecialStreams.timeIntervals.map(time => {
            const range = _range(Number(time.start.split(':')[0]), Number(time.end.split(':')[0]) + 1); //+ 1 as fn range exclude end
            const ranges = range.map(it => {
              return _padStart(it, 2, '0');
            });
            goodTimes.push(ranges);
          });
          return _flatten(goodTimes);
        }

        if (validStreams) {
          if (validStreams.prevTime) {
            validStreams.prevTime.map(time => {
              const range = _range(Number(time.start.split(':')[0]), Number(time.end.split(':')[0]) + 1); //+ 1 as fn range exclude end
              const ranges = range.map(it => {
                return _padStart(it, 2, '0');
              });
              goodTimes.push(ranges);
            });
          } else {
            validStreams.timeIntervals.map(time => {
              const range = _range(Number(time.start.split(':')[0]), Number(time.end.split(':')[0]) + 1); //+ 1 as fn range exclude end
              const ranges = range.map(it => {
                return _padStart(it, 2, '0');
              });
              goodTimes.push(ranges);
            });
          }
          return _flatten(goodTimes);
        }
      }
    }
  }

  getClosedDays(date) {
    const { bookingDetail, bankHolidays, data } = this.props;
    const parkingSchedule = _get(bookingDetail, 'schedule', false);
    let closedDays = [];
    let openDays = [];
    let bankClosedDays = [];
    let closedRecurringDays = [];
    if (parkingSchedule) {
      if (parkingSchedule.applyFrenchBankHolidays) {
        bankClosedDays = _map(bankHolidays, day => {
          if (moment(date).isSame(moment(day.startDate), 'day')) {
            return moment(day.startDate).format('YYYY-MM-DD');
          }
        });
      }
      let oneTimeSlots = _filter(_get(parkingSchedule, 'oneTimeSlots', false), { open: false });
      if (oneTimeSlots) {
        closedDays = _map(oneTimeSlots, day => {
          if (moment(date).isBetween(moment(day.startDate), moment(day.endDate).subtract(1, 'days'), null, [])) {
            return daysListOfDatesRange(day.startDate, day.endDate);
          }
        });
      }

      let oneTimeSlotsOpen = _filter(_get(parkingSchedule, 'oneTimeSlots', false), { open: true });
      if (oneTimeSlotsOpen) {
        openDays = _map(oneTimeSlotsOpen, slot => {
          if (moment(date).isBetween(moment(slot.startDate), moment(slot.endDate).subtract(1, 'days'), null, [])) {
            return daysListOfDatesRange(slot.startDate, slot.endDate);
          }
        });
      }

      let initialRecurringSlots = _get(data, 'immutableParkings', false);
      let initialRecurringSlotsFiltered = this.findById(initialRecurringSlots, _get(bookingDetail, 'start.parking.id', false));

      if (initialRecurringSlotsFiltered.length) {
        const weekDays = _flatten(initialRecurringSlotsFiltered[0].recurringSlots.map(item => item.days));
        let fullCloseDays = _compact(config.daysOfWeek.map(day => (!_includes(weekDays, day.value) ? day.value : '')));
        _map(fullCloseDays, day => {
          if (
            day ===
            moment(date)
              .locale('en')
              .format('dddd')
              .toUpperCase()
          ) {
            closedRecurringDays.push(moment(date).format('YYYY-MM-DD'));
          }
        });
      }

      let final = _merge(bankClosedDays, closedDays, closedRecurringDays);
      const finalClosed = _difference(_flatten(_compact(final)), _flatten(_compact(openDays)));
      return _includes(_flatten(_compact(finalClosed)), moment(date).format('YYYY-MM-DD'));
    }
  }

  handleConfirmPrebooking() {
    const { dispatch, bookingDetail, bundle } = this.props;
    this.handleCreateBookingConfirmation(false).then(
      () => {
        dispatch(confirmPrebooking(bookingDetail.id)).then(() => {
          setTimeout(() => dispatch(getDataVehiclePlanning()), 1000);
        });
      },
      error => {
        dispatch(addErrorMessage({ error, bundle, errorCodePrefixes: ['bookingResult_'] }));
      }
    );
  }

  handleCreateBookingConfirmation(refreshPlanning) {
    const { dispatch, bookingDetail, VehiclePlanningEditBooking, bundle } = this.props;
    const params = trimValues(VehiclePlanningEditBooking);

    params.returnDate = VehiclePlanningEditBooking.returnDate.value;
    params.returnDateHour = VehiclePlanningEditBooking.returnDateHour.value;
    params.returnDateMin = VehiclePlanningEditBooking.returnDateMin.value;
    params.oneWayTrip = params.tripType === ONE_WAY_TRIP;
    params.memberLogin = bookingDetail.member.login;
    params.originalBookingId = bookingDetail.id;

    if (params.oneWayTrip) {
      delete bookingDetail.end.date;
    }
    delete params.tripType;
    if (params.from && params.from._isSite) {
      params.from = _pick(params.from, ['id', '_isSite']);
    }
    if (params.to && params.to._isSite) {
      params.to = _pick(params.to, ['id', '_isSite']);
    }
    if (bookingDetail.status === BOOKING_STATUS_PRE_BOOKED) {
      params.preBooking = true;
    }
    const enhancedParams = enhanceBookingVehicleSearchRequestParams(params);
    if (_get(enhancedParams, 'start.date', false)) {
      bookingDetail.start.date = localToUTC(enhancedParams.start.date);
    }
    if (_get(enhancedParams, 'end.date', false)) {
      bookingDetail.end.date = localToUTC(enhancedParams.end.date);
    }

    if (_get(enhancedParams, 'memberTypeId')) {
      bookingDetail.memberTypeId = enhancedParams.memberTypeId;
    }

    const confirmDetails = {
      paidBooking: params.paidBooking,
      comment: params.comment
    };

    return dispatch(confirmBookingEdition(bookingDetail.member, bookingDetail, confirmDetails, bookingDetail.id))
      .then(() => {
        if (refreshPlanning) setTimeout(() => dispatch(getDataVehiclePlanning()), 1000); // wait server to process data
        this.handleClose();
        dispatch(
          addFlashMessage({
            contentKey: 'booking_edit_success',
            type: FLASH_MESSAGE_TYPE_SUCCESS
          })
        );
      })
      .catch(error => {
        dispatch(addErrorMessage({ error, bundle, errorCodePrefixes: ['bookingResult_'] }));
      });
  }

  getChangeMemberTypeField() {
    const {
      fields: { memberTypeId },
      bookingDetail: { carSharingInfo },
      memberRules
    } = this.props;

    if (memberRules.type && carSharingInfo.usageType === BOOKING_USAGE_TYPE_PRIVATE) {
      return (
        <BoxedSelect
          blockCustomClass="vp_memberType_boxedSelectBlock"
          customClass="vp_memberType_boxedSelectWrapper"
          noEmptyValue
          onChange={this.updatePrice}
          options={this.memberTypeOptions}
          disabled={this.state.memberTypeSelectDisabled}
          labelKey="member_type_association_label"
          id="memberType_select"
          sort={false}
          field={memberTypeId}
        />
      );
    }
  }

  updatePrice() {
    const { bookingDetail } = this.props;
    const carSharingInfo = _get(bookingDetail, 'carSharingInfo');
    if (carSharingInfo.usageType === BOOKING_USAGE_TYPE_PRIVATE) {
      this.props.dispatch(getEstimatedPriceBooking());
    }
  }

  displayPrice() {
    const {
      bookingDetail,
      noprices,
      loadingBookingPrice,
      intl: { formatMessage },
      fields: { paidBooking }
    } = this.props;

    const carSharingInfo = _get(bookingDetail, 'carSharingInfo');
    const cost = carSharingInfo.cost || {};
    const hasPrice = !_isEmpty(cost);
    const voucher = cost.voucherCode || _get(bookingDetail, 'voucher') || null;
    const currency = cost.currency || 'EUR';
    const zeroTrick = !paidBooking.value ? 'no-way' : 'estimatedPriceForDuration';
    const price_cost = _get(cost, voucher ? 'estimatedPriceWithoutVoucher' : zeroTrick, '0');
    const _amount = !toBoolean(paidBooking.value) ? formatMessage({ id: 'booking_view_free' }) : String(price_cost);
    const loader = (
      <div className="loading-price">
        <span>.</span>
        <span>.</span>
        <span>.</span>
      </div>
    );
    const currencyVal = !toBoolean(paidBooking.value) ? '' : formatMessage({ id: `unit_${currency}` });

    if (carSharingInfo.usageType !== BOOKING_USAGE_TYPE_BUSINESS && hasPrice && !noprices) {
      return (
        <div className="price">
          {voucher && !loadingBookingPrice && (
            <span>
              <FormattedHTMLMessage
                id="vp_price_with_currency"
                values={{
                  amount: !toBoolean(paidBooking.value) ? '' : String(_get(cost, 'estimatedPriceForDuration')),
                  currency: currencyVal
                }}
              />
            </span>
          )}
          {loadingBookingPrice ? (
            loader
          ) : (
            <span className={voucher && toBoolean(paidBooking.value) ? 'line-through' : ''}>
              <FormattedHTMLMessage
                id={voucher ? 'common_price_with_currency' : 'vp_price_with_currency'}
                values={{
                  amount: _amount,
                  currency: currencyVal
                }}
              />
            </span>
          )}
        </div>
      );
    }

    return (
      noprices && (
        <div className="price">
          {loadingBookingPrice && loader}
          {noprices && !loadingBookingPrice && <FormattedMessage id="common_status_ERROR" />}
        </div>
      )
    );
  }

  getTripTypeField() {
    const {
      fields: {
        tripType,
        usageOverride: { value: usage }
      },
      currentContract: { interfaceConfigDto }
    } = this.props;

    const { oneWayDisplay, returnTripDisplay } = interfaceConfigDto || {};

    if (oneWayDisplay && returnTripDisplay && (usage === VEHICLE_USAGE_TYPE_PLANNED || usage === VEHICLE_USAGE_TYPE_IMMEDIATE)) {
      return (
        <div className="trip-type">
          <EkRadio
            formRowItemKey="backUserForm_userRole"
            items={[
              {
                labelKey: 'search_vehicle_oneWay',
                value: ONE_WAY_TRIP
              },
              {
                labelKey: 'search_vehicle_return',
                value: ROUND_TRIP
              }
            ]}
            namespace="tripType"
            disabled
            field={tripType}
          >
            <FieldErrorMsg field={tripType} customClass="fieldErrorMsg--backUserForm" />
          </EkRadio>
        </div>
      );
    }
  }

  openMemberChange() {
    const { dispatch } = this.props;
    dispatch(toggleChangeMember({ skipConfirm: true }));
  }

  prebookSelect() {
    const {
      fields: { prebook },
      profileReviewStatus,
      VehiclePlanningCreateBooking
    } = this.props;
    const { usageOverride } = getValues(VehiclePlanningCreateBooking) || {};

    if (usageOverride === VEHICLE_USAGE_TYPE_IMMEDIATE || usageOverride === VEHICLE_USAGE_TYPE_PLANNED) return '';
    else {
      if (profileReviewStatus === STATUS_APPROVED) {
        return (
          <div className="frow prebook">
            <div className="frow-label">
              <FormattedMessage id="booking-prebooking-selector-label" />
            </div>
            <BoxedSelect
              formRowItemKey="searchVehicle_from_pickupDateHour"
              options={[
                { value: BOOKING, labelKey: 'common_booking' },
                { value: PREBOOKING, labelKey: 'common_preBooking' }
              ]}
              customClass="searchVehicle_boxedSelectWrapper"
              hideLabel
              noEmptyValue
              field={prebook}
            />
          </div>
        );
      }
    }
  }

  render() {
    const {
      openedEditBookingModal,
      confirmPrebooking,
      editMemberChange,
      loadingBookingDetail,
      sitesList,
      bookingDetail,
      bookingDetail: { vehicle },
      handleSubmit,
      customFields,
      visibleUsageOptions,
      currentContract: { businessCarSharing, privateCarSharing },
      fields: {
        from,
        to,
        pickupDate,
        pickupDateHour,
        pickupDateMin,
        returnDate,
        returnDateHour,
        returnDateMin,
        tripType,
        voucherCode,
        comment,
        paidBooking,
        usageOverride,
        prebook,
        isPrivate
      }
    } = this.props;

    const now = new Date();
    const sitesListEnhanced = enhanceSiteList(sitesList);
    const minReturnDate = pickupDate && typeof pickupDate.value === 'object' ? pickupDate.value : now;
    // const version = bookingDetail && bookingDetail.vehicle.version;

    const usageType = _get(bookingDetail, 'carSharingInfo.usageType');
    this.setHoursOptions('pickupDate');
    this.setHoursOptions('returnDate');

    return (
      <Dialog
        modal={false}
        open={openedEditBookingModal}
        onRequestClose={this.handleClose}
        contentStyle={{ width: '80%', padding: '0!important', maxWidth: 'none' }}
        bodyStyle={{ padding: '0!important' }}
        style={{ padding: '0!important' }}
        className={classNames('VehiclePlanningEditBooking', 'new-vehicle-planning-booking-create', { wide: editMemberChange })}
        title={
          !loadingBookingDetail && (
            <div className="header-title">
              <FormattedMessage
                id={prebook.value === PREBOOKING ? 'vehiclePlanning_edit_prebooking_for' : 'vehiclePlanning_edit_booking_for'}
              />
              <span className="member-name">{bookingDetail && bookingDetail.member.firstName + ' ' + bookingDetail.member.lastName}</span>
              <div className="new-vehicle-planning_closeIconWrap">
                <IconButton onClick={this.handleClose}>
                  <CloseIcon />
                </IconButton>
              </div>
            </div>
          )
        }
      >
        <form
          action="#"
          className="new-vehicle-planning-edit-booking"
          onSubmit={handleSubmit(confirmPrebooking ? this.handleConfirmPrebooking : this.handleCreateBookingConfirmation)}
        >
          {loadingBookingDetail && (
            <div className="new-vehicle-planning_loadingOverlay">
              <Loader />
            </div>
          )}
          {!loadingBookingDetail && (
            <div className={loadingBookingDetail ? 'edit-booking-loading' : 'new-vehicle-planning-edit-booking_container'}>
              <div className={classNames('new-vehicle-planning-edit-booking_col1', { wide: editMemberChange })}>
                {this.prebookSelect()}

                <div className="frow user-detail">
                  <div className="frow-label">
                    <FormattedMessage id="booking-member" />
                  </div>
                  <span className="member" onClick={this.openMemberChange}>
                    <ReactSVG src="/img/edit.svg" className="align-svg  edit-svg" />

                    <div className="detail">
                      <span className="user-name">
                        {bookingDetail && bookingDetail.member.firstName + ' ' + bookingDetail.member.lastName}
                      </span>
                      <span className="user-mail" children={bookingDetail && bookingDetail.member.login} />
                    </div>
                  </span>
                </div>

                <div className="frow trip">
                  <div className="frow-label">
                    <FormattedMessage id="booking-address" />
                  </div>

                  <ul className={classnames('trip-info', { loop: tripType.value !== ONE_WAY_TRIP })}>
                    <li className="item">
                      <span className="item">
                        <AddressAutocomplete field={from} fixtures={sitesListEnhanced} disabled />
                      </span>
                    </li>
                    {tripType.value !== ONE_WAY_TRIP && (
                      <li className="item">
                        <span className="item">
                          <AddressAutocomplete
                            field={tripType.value !== ONE_WAY_TRIP ? to : { value: '-' }}
                            fixtures={this.renderSuggestFiltered(sitesListEnhanced)}
                            disabled
                          />
                        </span>
                      </li>
                    )}
                  </ul>

                  {this.getTripTypeField()}
                </div>

                <div className="frow datetime">
                  <div className="frow-label">
                    <FormattedMessage id="booking-dates" />
                  </div>
                  <div className="new-vehicle-planning-edit-booking_col1Row2 paddingTop0">
                    <BoxedInput
                      formRowItemKey="searchVehicle_from_pickupDate"
                      skinType="date"
                      type="date"
                      customClass="boxedInputWrapper--label _is_year_hidden new-vehicle-planning-edit-booking_col1Row2_pickupDate"
                      labelKey="search_vehicle_pickup"
                      field={pickupDate}
                      minDate={now}
                      onChange={this.updatePrice}
                      disableYearSelection
                      shouldDisableDate={this.getClosedDays}
                    >
                      <FieldErrorMsg field={pickupDate} customClass="fieldErrorMsg--searchVehicleForm" />
                    </BoxedInput>

                    <div className="new-vehicle-planning-edit-booking_col1Row2_dateWrap">
                      <BoxedSelect
                        formRowItemKey="searchVehicle_from_pickupDateHour"
                        labelKey="search_vehicle_pickup_hour"
                        options={this.hoursOptions['pickupDate']}
                        customClass="searchVehicle_boxedSelectWrapper"
                        hideLabel
                        onChange={this.updatePrice}
                        field={pickupDateHour}
                      />
                      <span className="new-vehicle-planning-edit-booking_col1Row2_dvojtecka">:</span>
                      <BoxedSelect
                        formRowItemKey="searchVehicle_from_pickupDateMin"
                        labelKey="search_vehicle_pickup_min"
                        options={config.allAvailableMinutes}
                        customClass="searchVehicle_boxedSelectWrapper"
                        hideLabel
                        onChange={this.updatePrice}
                        field={pickupDateMin}
                      />
                      <TimeIcon />
                    </div>
                  </div>
                  {tripType.value !== ONE_WAY_TRIP && (
                    <div className="new-vehicle-planning-edit-booking_col1Row2">
                      <BoxedInput
                        formRowItemKey="searchVehicle_to_returnDate"
                        skinType="date"
                        type="date"
                        customClass="boxedInputWrapper--label new-vehicle-planning-edit-booking_col1Row2_pickupDate"
                        labelKey="search_vehicle_return_date"
                        field={returnDate}
                        onChange={this.updatePrice}
                        minDate={minReturnDate}
                        mandatory={!(tripType.value === ONE_WAY_TRIP)}
                        disableYearSelection
                        shouldDisableDate={this.getClosedDays}
                      >
                        <FieldErrorMsg field={returnDate} customClass="fieldErrorMsg--searchVehicleForm" />
                      </BoxedInput>

                      <div className="new-vehicle-planning-edit-booking_col1Row2_dateWrap">
                        <BoxedSelect
                          formRowItemKey="searchVehicle_from_pickupDateHour"
                          labelKey="search_vehicle_pickup_hour"
                          options={this.hoursOptions['returnDate']}
                          customClass="searchVehicle_boxedSelectWrapper"
                          onChange={this.updatePrice}
                          hideLabel
                          field={returnDateHour}
                        />
                        <span className="new-vehicle-planning-edit-booking_col1Row2_dvojtecka">:</span>
                        <BoxedSelect
                          formRowItemKey="searchVehicle_from_pickupDateMin"
                          labelKey="search_vehicle_pickup_min"
                          options={config.allAvailableMinutes}
                          customClass="searchVehicle_boxedSelectWrapper"
                          hideLabel
                          onChange={this.updatePrice}
                          field={returnDateMin}
                        />
                        <TimeIcon />
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div className={classNames('new-vehicle-planning-edit-booking_col2', { collapse: editMemberChange })}>
                <div className="box" id="box">
                  <div className="new-vehicle-planning-edit-booking_col2CarWrap">
                    <VehiclePlanningVehicleTile vehicle={vehicleAapter(vehicle)} displayUsages={false}>
                      <div className="price-wrapper">{this.displayPrice()}</div>
                    </VehiclePlanningVehicleTile>
                  </div>
                  <div></div>
                  <div className="specificities">
                    <div className="label">
                      <FormattedMessage id="booking-specificities" />
                    </div>
                    <div className={classnames('fields', { business: usageType === BOOKING_USAGE_TYPE_BUSINESS })}>
                      {visibleUsageOptions.length && (
                        <div id="vehicleUsage" className={classnames('new-vehicle-planning_usageSelector', 'vehicle-usage-selector')}>
                          <BoxedSelect
                            labelKey="label_booking_usage"
                            noEmptyValue
                            options={visibleUsageOptions}
                            field={usageOverride}
                            fullWidth
                          />
                        </div>
                      )}

                      <div className="new-vehicle-planning_usageSelector">
                        {businessCarSharing && privateCarSharing && (
                          <div className="realm-label">
                            <FormattedMessage id="credit-card-required" />
                          </div>
                        )}

                        <div className="private-realm">
                          {businessCarSharing && privateCarSharing && <GreenSwitch name="isPrivate" field={isPrivate} disabled />}

                          {usageType !== BOOKING_USAGE_TYPE_BUSINESS && (
                            <IsGranted
                              allowed={[
                                BACKUSER_ROLE_ADMIN,
                                BACKUSER_ROLE_SUPER_ADMIN,
                                BACKUSER_ROLE_ROOT,
                                BACKUSER_ROLE_ZONE_MANAGER,
                                BACKUSER_ROLE_ADMIN_DEALER
                              ]}
                            >
                              <BoxedSelect
                                options={[
                                  { labelKey: 'booking_free_of_charge', value: 'false' },
                                  { labelKey: 'booking_create_confirmation_usage', value: 'true' }
                                ]}
                                namespace="freeofcharges"
                                className="new-vehicle-planning_usageSelector"
                                field={paidBooking}
                                noEmptyValue
                              />
                            </IsGranted>
                          )}
                        </div>
                      </div>
                      {usageType !== BOOKING_USAGE_TYPE_BUSINESS && this.getChangeMemberTypeField()}
                    </div>
                  </div>

                  {/* {bookingDetail && visibleUsageOptions.length && (
                  <div className="vehiclePlanning_usageSelector">
                    <BoxedSelect
                      labelKey="label_booking_usage"
                      noEmptyValue
                      options={visibleUsageOptions}
                      field={usageOverride}
                      fullWidth
                    />
                  </div>
                )} */}

                  {/* {bookingDetail && bookingDetail.carSharingInfo.usageType !== BOOKING_USAGE_TYPE_BUSINESS && (
                  <IsGranted allowed={[BACKUSER_ROLE_ADMIN, BACKUSER_ROLE_SUPER_ADMIN, BACKUSER_ROLE_ROOT]}>
                    <LabeledCheckbox id="addBooking_freeOfCharge" customClass="addBookingConfirmationForm_option" field={paidBooking}>
                      <FormattedMessage id="booking_create_confirmation_usage" />
                    </LabeledCheckbox>
                  </IsGranted>
                )} */}
                  {/* {bookingDetail && bookingDetail.carSharingInfo.usageType !== BOOKING_USAGE_TYPE_BUSINESS && this.displayPrice()} */}
                  {bookingDetail.carSharingInfo.usageType !== BOOKING_USAGE_TYPE_BUSINESS && voucherCode.value !== '' && (
                    <div className="frow">
                      <BoxedInput
                        formRowItemKey="searchVehicle_voucherCode"
                        type="text"
                        customClass="boxedInputWrapper"
                        labelKey="common_searchFor_voucherCode"
                        field={voucherCode}
                        disabled
                        // onChange={this.updatePriceAuto}
                      />
                      {/* <EkButton type="button" formRowItemKey="voucher-trigger" customClass="voucher_button" onAction={this.updatePrice}>
                      <FormattedMessage id="common_apply" />
                    </EkButton> */}
                    </div>
                  )}

                  <div className="label">
                    <FormattedMessage id="booking-additional-infos" />
                  </div>
                  <CustomFieldsBooking
                    customFields={customFields}
                    customFieldsEditMode
                    formFields={this.props.fields}
                    usage={usageOverride.value}
                  />

                  <FormRow customClass="addBookingConfirmationForm_row">
                    <EkTextarea labelKey="booking_create_confirmation_comment" customClass="createBooking_textarea" field={comment} />
                  </FormRow>

                  <div className="addBookingConfirmationForm_buttonRow">
                    <EkButton
                      type="button"
                      formRowItemKey="vehicleForm_step_next"
                      customClass="addBookingConfirmationForm_button"
                      onAction={handleSubmit(confirmPrebooking ? this.handleConfirmPrebooking : this.handleCreateBookingConfirmation)}
                    >
                      <FormattedMessage id={confirmPrebooking ? 'confirm_prebooking' : 'booking_create_confirmation_save_button'} />
                    </EkButton>
                  </div>
                </div>
              </div>
            </div>
          )}
        </form>
      </Dialog>
    );
  }
}

export default namedCompose(
  reduxForm(
    {
      onSubmitFail: scrollToFirstErrorDialog,
      form: 'VehiclePlanningEditBooking'
    },
    state => {
      const customFields = planningBookingEditCustomFieldsSelector(state);
      const confirmationForm = getValues(state.form.createBookingConfirmationForm) || {};
      const { list: memberTypes } = state.memberTypes || {};

      const {
        vehiclePlanning: {
          bookingDetail,
          noprices,
          editMemberChange,
          openedEditBookingModal,
          loadingBookingDetail,
          loadingBookingPrice,
          selectedBookingId,
          data,
          errorMemberSelection,
          foundUsers,
          bookingPrice,
          confirmPrebooking,
          selectedMemberId
        },
        sites: { list },
        form: { VehiclePlanningEditBooking }
      } = state;

      const { vehicleUsageAtBookingCreation } = bookingDetail || {};
      const { usageOverride } = getValues(VehiclePlanningEditBooking) || {};
      const initialValues = {
        usageOverride: vehicleUsageAtBookingCreation,
        memberTypeId: _get(bookingDetail, 'memberType.id'),
        voucherCode: _get(bookingDetail, 'voucher.code'),
        from: {
          formattedAddress: _get(bookingDetail, 'start.parking.name', _get(bookingDetail, 'start.address.formattedAddress', '')),
          coordinates: _get(bookingDetail, 'start.coordinates', ''),
          _isSite: !!_get(bookingDetail, 'start.parking.name'),
          address: _get(bookingDetail, 'start.address', ''),
          id: _get(bookingDetail, 'start.parking.id', '')
        },
        to: !!_get(bookingDetail, 'end.address')
          ? {
              formattedAddress: _get(bookingDetail, 'end.parking.name', _get(bookingDetail, 'start.end.formattedAddress', '')),
              coordinates: _get(bookingDetail, '56end.coordinates', ''),
              _isSite: !!_get(bookingDetail, 'end.parking.name'),
              address: _get(bookingDetail, 'end.address', ''),
              id: _get(bookingDetail, 'end.parking.id', '')
            }
          : {},
        tripType:
          _get(bookingDetail, 'start.address.formattedAddress', '') === _get(bookingDetail, 'end.address.formattedAddress', '')
            ? ROUND_TRIP
            : ONE_WAY_TRIP,
        pickupDate: _get(bookingDetail, 'start.date') ? moment(bookingDetail.start.date).toDate() : '',
        returnDate: !!_get(bookingDetail, 'end.address') && _get(bookingDetail, 'end.date') ? moment(bookingDetail.end.date).toDate() : '',
        pickupDateHour: _get(bookingDetail, 'start.date')
          ? moment(bookingDetail.start.date).hour() === 0
            ? '00'
            : moment(bookingDetail.start.date).format('HH')
          : '',
        returnDateHour:
          !!_get(bookingDetail, 'end.address') && _get(bookingDetail, 'end.date')
            ? moment(bookingDetail.end.date).hour() === 0
              ? '00'
              : moment(bookingDetail.end.date).format('HH')
            : '',
        pickupDateMin: _get(bookingDetail, 'start.date')
          ? moment(bookingDetail.start.date).minute() === 0
            ? '00'
            : moment(bookingDetail.start.date).format('mm')
          : '00',
        returnDateMin:
          !!_get(bookingDetail, 'end.address') && _get(bookingDetail, 'end.date')
            ? moment(bookingDetail.end.date).minute() === 0
              ? '00'
              : moment(bookingDetail.end.date).format('mm')
            : '',
        comment: _get(bookingDetail, 'comment'),
        paidBooking: !_get(bookingDetail, 'carSharingInfo.freeOfCharges'),
        isPrivate: toBoolean(_get(bookingDetail, 'carSharingInfo.usageType') === BOOKING_USAGE_TYPE_PRIVATE),
        usageType: _get(bookingDetail, 'carSharingInfo.usageType'),
        ...planningBookingCustomFieldsEditValuesSelector(state)
      };

      return {
        fields: [
          'from',
          'to',
          'pickupDate',
          'pickupDateHour',
          'pickupDateMin',
          'returnDate',
          'returnDateHour',
          'returnDateMin',
          'passengers',
          'tripType',
          'memberTypeId',
          'voucherCode',
          'paidBooking',
          'usageOverride',
          'comment',
          'prebook',
          'usageType',
          'isPrivate',
          ...createCustomFieldsNames(customFields)
        ],
        validate: createValidator({
          ...createCustomFieldsValidators(customFields, usageOverride),
          from: [notEmpty(), addressOrSite()],
          to: [
            stopValidationIf({
              condition: (props, value) => {
                return _isEmpty(value);
              }
            }),
            addressOrSite()
          ],
          pickupDate: [notEmpty()],
          tripType: [notEmpty()],
          returnDate: [
            stopValidationIf({
              condition: props => {
                return props.form.tripType && props.form.tripType.value === ONE_WAY_TRIP ? props.form.tripType.value : false;
              }
            }),
            notEmpty(),
            dateAfterOrEqualField('pickupDate')
          ]
        }),
        openedEditBookingModal,
        sitesList: list,
        loadingBookingDetail,
        bookingDetail,
        noprices,
        VehiclePlanningEditBooking,
        selectedBookingId,
        selectedMemberId,
        customFields,
        data,
        bookingPrice,
        editMemberChange,
        confirmationForm,
        memberTypes,
        errorMemberSelection,
        loadingBookingPrice,
        initialValues,
        confirmPrebooking,
        foundUsers,
        memberRules: headerContractMemberValuesSelector(state),
        currentContract: headerCompanyContractSelector(state),
        visibleUsageOptions: vpEditBookingUsageOptionsSelector(state),
        bundle: bundleSelector(state)
      };
    }
  ),
  injectIntl,
  pure
)(VehiclePlanningEditBooking);
