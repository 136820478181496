import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { getEditTitle } from '../../../../components/_v2/ColumnList/helpers';
import { showParentView } from '../../../../components/_v2/ColumnList/helpers';
import { connect } from 'react-redux';
import ConfigurationView from './index';

import { updateConfigurationRequest, updateConfigurationSuccess, addFlashMessage } from '../../../../actions/all-actions';
import { FLASH_MESSAGE_TYPE_SUCCESS } from '../../../../constants/generic-constants';

import _values from 'lodash/values';

import { partialErrorCodes } from '../../../../constants/options-constants';
import { addErrorMessage, safe } from '../../../../utils/utils';
import { userRoleSelector } from '../../../../selectors/all-selectors';
import { checkRole } from '../../../../constants/backuser-role-rules';
import { editColorsRules } from '../../../../constants/routes-constants';

export class ConfigurationEdit extends Component {
  componentWillMount() {
    this.setVars();
    this.setCallbacks();
  }

  setVars() {
    this.limitedAccess = !checkRole(editColorsRules, this.props.role);
  }

  setCallbacks() {
    this.showParentView = showParentView.bind(this);

    this.handleCallback = () => {
      const { dispatch, data } = this.props;
      dispatch(updateConfigurationRequest(data))
        .then(configuration => {
          dispatch(updateConfigurationSuccess(configuration));
          dispatch(
            addFlashMessage({
              contentKey: 'configurationForm_update_configuration_success',
              type: FLASH_MESSAGE_TYPE_SUCCESS
            })
          );
        })
        .catch(error => {
          const partialErrors = _values(partialErrorCodes.generic);
          const msgParams = { error, partialErrors, def: 'configurationForm_update_configuration_error' };
          dispatch(addErrorMessage(msgParams));
        });
    };
  }

  render() {
    return (
      <ConfigurationView
        inputRef={this.props.inputRef}
        title={getEditTitle(<FormattedMessage id="editConfiguration_title" />)}
        onClose={this.showParentView}
        onCallback={this.handleCallback}
        limitedAccess={this.limitedAccess}
        selectedHotline={safe(() => this.props.data.hotline.name) || '-'}
        initialValues={{
          name: safe(() => this.props.data.name),
          hotlineId: safe(() => this.props.data.hotline.id),
          parentConfigurationId: safe(() => this.props.data.parent.id)
        }}
      />
    );
  }
}

const mapStateToProps = state => {
  const { detailConfiguration } = state.configuration;
  return { data: detailConfiguration, role: userRoleSelector(state) };
};

ConfigurationEdit = connect(mapStateToProps)(ConfigurationEdit);

export default ConfigurationEdit;
