import React, { Component, PropTypes } from 'react';
import { FormattedHTMLMessage } from 'react-intl';
import { connect } from 'react-redux';

import NavigationIcon from 'material-ui/svg-icons/navigation/arrow-upward';
import { history } from '../../routing/all-routing';
import { colorDark } from '../../constants/style-constants';
import IconButton from 'material-ui/IconButton';

class BackLink extends Component {
  constructor() {
    super();
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(e) {
    const { link, onClickBack } = this.props;

    if (onClickBack) {
      e.preventDefault();
      onClickBack();
      return;
    }

    if (!link) {
      e.preventDefault();
      history.goBack();
    }
  }

  render() {
    const { searchMode, link } = this.props;

    return (
      <div onClick={this.handleClick}>
        {!searchMode && (
          <a className="backLink" href={link ? link : ''}>
            <IconButton iconStyle={colorDark}>
              <NavigationIcon />
            </IconButton>
            <span className="backLink-text">
              <FormattedHTMLMessage id={this.props.labelKey} />
            </span>
          </a>
        )}
      </div>
    );
  }
}

BackLink.displayName = 'BackLink';

BackLink.defaultProps = {
  labelKey: 'common_up'
};

BackLink.propTypes = {
  labelKey: PropTypes.string,
  link: PropTypes.string,
  searchMode: PropTypes.bool,
  onClickBack: PropTypes.func
};

export default connect(state => {
  return {
    searchMode: state.header.searchMode
  };
})(BackLink);
