import React, { Component, PropTypes } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { reduxForm, reset } from 'redux-form';
import cs from 'classnames';
import classNames from 'classnames';
import _get from 'lodash/get';
import _includes from 'lodash/includes';
import _isEmpty from 'lodash/isEmpty';
import _isFunction from 'lodash/isFunction';
import _find from 'lodash/find';
import EkButton from '../../components/EkButton/EkButton';
import BoxedSelect from '../../components/BoxedSelect/BoxedSelect';
import CustomFieldForm from '../../components/CustomFieldForm/CustomFieldForm';
import CustomFields from '../../components/CustomFields';
import CompanyInvoiceParamsForm from '../../components/CompanyInvoiceParamsForm/CompanyInvoiceParamsForm';
import BackLink from '../../components/BackLink/BackLink';
import routes, { editCompanyRules } from '../../constants/routes-constants';
import { i18nAdditionnalDocFormKeys, safe } from '../../utils/utils';

import {
  addFlashMessage,
  authorizeApplications,
  clearCompanyInvoice,
  companyClearPaybackSettingsData,
  companyCreateBankoutConfigAction,
  companyGetPaybackSettingsAction,
  createConnectedAccount,
  fileUploadResetState,
  previewCompanyInvoice,
  saveCompanyAuthorizedApplications,
  setCurrentSuperCompanyInHeader,
  setCurrentTabIndex,
  setInvoiceParamsRequest,
  toggleDrivingAuthorisationPdfPreview,
  unauthorizeApplications,
  updateCompanyRequest,
  updateCompanySuccess,
  updateCurrencyReq
} from '../../actions/all-actions';
import { COMPANY_TYPE_SUPER, FLASH_MESSAGE_TYPE_ERROR, FLASH_MESSAGE_TYPE_SUCCESS, STORAGE_KEY } from '../../constants/generic-constants';
import {
  BACKUSER_ROLE_ADMIN,
  BACKUSER_ROLE_ROOT,
  BACKUSER_ROLE_SUPER_ADMIN,
  CUSTOM_FIELD_FORM_BOOKING,
  CUSTOM_FIELD_FORM_SUBSCRIPTION,
  PAYMENT_PROVIDERS,
  STRIPE_STATUS,
  TEMPLATE_EMAIL_DEFAULT
} from '../../constants/backend-constants';
import {
  addErrorMessage,
  arrayToString,
  boolToString,
  getShortId,
  namedCompose,
  scrollToFirstError,
  streetNameFormat,
  trimValues
} from '../../utils/utils';
import { apiParams } from '../../constants/api-params-constants';
import CompanyEmailSetupForm from './components/CompanyEmailSetupForm';

import {
  companyClearTemporaryBankDetails,
  companyUpdateBankDetails,
  companyUpdateBankoutConfigAction,
  deleteCompanyEmailSetup,
  getConnectedAccount,
  pullCompanyEmailSetup,
  setCompanyEmailSetup,
  stripeAccountLinkRefresh,
  supressConnectedAccount
} from '../../actions/companies-actions';

import { COMPANY_EMAIL_SETUP_FORM, COMPANY_PAYBACK_SETTINGS_FORM } from '../../constants/form-constants';
import autoBind from 'react-autobind';
import { isGranted } from '../../actions/user-actions';
import PdfModal from '../../components/PdfModal/PdfModal';
import moment from 'moment';
import { saveAs } from 'file-saver';
import {
  bundleSelector,
  headerContractBookingValuesSelector,
  headerContractMemberValuesSelector,
  headerContractPaymentValuesSelector,
  currentCompanyContractSelector,
  localeSelector,
  subscriptionUrlSelector,
  userRoleSelector,
  accountStripeCompanySelector
} from '../../selectors/all-selectors';

import {
  checkRole,
  superCompanyPaybackBankDetailsEditRules,
  superCompanyPaybackConfigTabRules,
  superCompanyPaybackSettingsEditRules
} from '../../constants/backuser-role-rules';
import PaybackSettingsTab from '../../components/PaybackSettingsTab/PaybackSettingsTab';
import { getStore } from '../../store/all-store';
import { successMessage } from '../../actions/flashMessage-actions';
import { partialErrorCodes } from '../../constants/options-constants';
import CompanyForm from '../../components/CompanyForm/CompanyForm';
import _values from 'lodash/values';
import { COMPANIES_GET_CONNECTED_ACCOUNT_ERROR } from '../../constants/actionTypes-constants';

class CompanyDetail extends Component {
  componentWillMount() {
    autoBind(this);
    this.initState();
    this.derivedStateFromProps(this.props, true);
  }

  initState() {
    this.state = { tabsData: [] };
  }

  // noinspection JSCheckFunctionSignatures
  componentWillReceiveProps(props) {
    this.derivedStateFromProps(props);
  }

  updateReadOnly(props) {
    const { role } = props;
    const needsUpdate = role !== this.props.role || this.readOnly === undefined;
    if (needsUpdate) this.readOnly = role ? !checkRole(editCompanyRules, role) : false;
  }

  derivedStateFromProps(props, init) {
    this.setTabsData(props, init);
    this.updatePaybackReadOnly(props, init);
    this.updateReadOnly(props);
    this.checkIfInvoiceParamsAreSet(props, init);
  }

  companyContractUpdated(props, init) {
    const { companyContract: newValue } = props;
    const { companyContract: oldValue } = this.props;
    return init || oldValue !== newValue;
  }

  connectedAccountUpdated(props, init) {
    const { connectedAccount: newValue } = props;
    const { connectedAccount: oldValue } = this.props;
    return init || oldValue !== newValue;
  }

  setTabsData(props, init) {
    if (!this.companyContractUpdated(props, init) && !this.connectedAccountUpdated(props, init)) return;

    this.setState({ tabsData: [] });
    const hold = props.paymentProvider === PAYMENT_PROVIDERS.STRIPE ? props.connectedAccount : props.paybackData;

    this.addTab({
      getTitle: () => safe(() => props.detailCompany.name) || <FormattedMessage id="company_detail_tab_company" />,
      displayTab: this.companyTab
    });
    this.addTab({
      titleKey: 'company_detail_tab_applications',
      displayTab: this.appsTabs
    });
    if (props.memberRules.customFields) {
      this.addTab({
        titleKey: 'company_detail_tab_subscription_custom_fields',
        displayTab: this.subscribeFields
      });
    }
    if (props.bookingRules.customFields) {
      this.addTab({
        titleKey: 'company_detail_tab_booking_custom_fields',
        displayTab: this.bookingFields
      });
    }
    if (isGranted({ allowed: [BACKUSER_ROLE_SUPER_ADMIN, BACKUSER_ROLE_ROOT, BACKUSER_ROLE_ADMIN] })) {
      this.addTab({
        id: 'invoice-params-tab',
        titleKey: 'company_detail_tab_invoices_params',
        displayTab: this.invoiceTab,
        icon: _isEmpty(props.detailInvoiceParams) ? <div className="warn" /> : ''
      });
    }
    this.addTab({
      titleKey: 'company_detail_tab_email_setup',
      displayTab: this.emailSetupSection
    });
    if (props.paymentRules.bankout) {
      if (isGranted({ allowed: superCompanyPaybackConfigTabRules.include, disallowed: superCompanyPaybackConfigTabRules.exclude })) {
        this.addTab({
          titleKey: 'company_detail_tab_payback_settings',
          displayTab: this.paybackSettings,
          icon: this.iconHelper(hold)
        });
      }
    }
  }

  iconHelper(hold) {
    return safe(() => hold.type) === COMPANIES_GET_CONNECTED_ACCOUNT_ERROR ||
      safe(() => hold.data.stripeAccount.status) === STRIPE_STATUS.INCOMPLETE ||
      _isEmpty(hold) ? (
      <div className="warn" />
    ) : (
      ''
    );
  }

  addBankDetailsCallback() {
    const store = getStore();
    const state = store.getState();
    const companyState = state.companies;
    const { currentTabIndex } = companyState;

    const values = trimValues(state.form[COMPANY_PAYBACK_SETTINGS_FORM]);

    const paybackData = JSON.stringify({
      values,
      currentTabIndex,
      type: COMPANY_TYPE_SUPER
    });

    localStorage.setItem(STORAGE_KEY.PAYBACK_DATA, paybackData);
  }

  updatePayback() {
    const { dispatch, companyId } = this.props;
    const supressError = () => '';

    dispatch(companyGetPaybackSettingsAction(companyId, true)).then(supressError, supressError);
  }

  submitPayback() {
    const { firstPayback, dispatch, role: userRole } = this.props;

    const canUpdateSettings = checkRole(superCompanyPaybackSettingsEditRules, userRole);

    const handleCreateOk = () => {
      dispatch(companyClearPaybackSettingsData());
      this.updatePayback();
    };

    const supressError = () => '';

    const updateSettingsAction = () => dispatch(companyUpdateBankoutConfigAction()).then(supressError, supressError);
    const clearBankDetailsAction = () => dispatch(companyClearTemporaryBankDetails());

    if (firstPayback) {
      if (canUpdateSettings) dispatch(companyCreateBankoutConfigAction()).then(handleCreateOk, supressError);
    } else {
      dispatch(companyUpdateBankDetails()).then(() => {
        if (canUpdateSettings) {
          updateSettingsAction();
        } else {
          dispatch(successMessage);
          clearBankDetailsAction();
        }
      }, supressError);
    }
  }

  updatePaybackReadOnly({ role: userRole, firstPayback }, init) {
    const { role: prevRole } = this.props;

    if (init || userRole !== prevRole) {
      const canEditSettings = checkRole(superCompanyPaybackSettingsEditRules, userRole);

      this.bankDetailsReadOnly = !checkRole(superCompanyPaybackBankDetailsEditRules, userRole);
      this.paybackSettingsReadOnly = !canEditSettings;
      this.showPaybackSubmit = !this.bankDetailsReadOnly || !this.paybackSettingsReadOnly;

      if (firstPayback) {
        this.bankDetailsReadOnly = !canEditSettings;
        this.showPaybackSubmit = canEditSettings;
      }
    }
  }

  invoiceParamsUpdated(props, init) {
    const { detailInvoiceParams: newValue } = props;
    const { detailInvoiceParams: oldValue } = this.props;
    return init || oldValue !== newValue;
  }

  vatRateParamsUpdated(props, init) {
    const { invoiceVatRateParam: newValue } = props;
    const { invoiceVatRateParam: oldValue } = this.props;
    return init || oldValue !== newValue;
  }

  allInvoiceParamsUpdated(props, init) {
    return this.invoiceParamsUpdated(props, init) || this.vatRateParamsUpdated(props, init);
  }

  invoiceParametersAreIncorrect(props) {
    const { detailCompany, detailInvoiceParams, invoiceVatRateParam } = props;

    const { id: currentDetailCompanyId } = detailCompany || {};
    const { companyId: invoiceParamsParentId } = detailInvoiceParams || {};
    const { companyId: vatRateParamsParentId } = invoiceVatRateParam || {};

    return currentDetailCompanyId !== invoiceParamsParentId || currentDetailCompanyId !== vatRateParamsParentId;
  }

  checkIfInvoiceParamsAreSet(props, init) {
    if (this.allInvoiceParamsUpdated(props, init)) {
      const {
        intl: { formatMessage }
      } = props;

      if (this.invoiceParametersAreIncorrect(props)) {
        const invoiceParamsTabName = formatMessage({ id: 'company_detail_tab_invoices_params' });
        this.paybackSettingsInfoMessage = formatMessage({ id: 'please_update_first' }, { something: invoiceParamsTabName });
      } else this.paybackSettingsInfoMessage = '';
    }
  }

  paybackSettingsTabChange() {
    const nextTabIndex = this.state.tabsData.findIndex(data => data.id === 'invoice-params-tab');
    if (nextTabIndex > 0) this.handleChangeTabIndex(nextTabIndex);
  }

  paybackSettings() {
    const { detailCompany, companyId, paybackData, currencies, firstPayback } = this.props;
    const { currency, paymentProvider } = detailCompany || {};

    return (
      <PaybackSettingsTab
        sectionClassName="companyDetail"
        addBankDetailsCallback={this.addBankDetailsCallback}
        submitPayback={this.submitPayback}
        companyId={companyId}
        paybackData={paybackData}
        paymentProvider={paymentProvider}
        bankAccountDetails={this.props.bankAccountDetails}
        firstPayback={firstPayback}
        onTabChangeRequest={this.paybackSettingsTabChange}
        paybackFormValues={this.props.paybackFormValues}
        showSubmit={this.showPaybackSubmit}
        paybackSettingsReadOnly={this.paybackSettingsReadOnly}
        bankDetailsReadOnly={this.bankDetailsReadOnly}
        currencies={currencies}
        currency={currency}
        createAccountCB={this.createAccountCallback}
        deleteAccountCB={this.deleteAccount}
        refreshLinkCB={this.stripeRefreshLink}
        showInfoMessage={this.paybackSettingsInfoMessage}
      />
    );
  }

  addTab(params) {
    this.setState(state => {
      const tabsData = [...state.tabsData];

      tabsData[tabsData.length] = {
        ...params,
        handleClick: this.handleChangeTabIndex.bind(this, tabsData.length)
      };

      return { tabsData };
    });
  }

  handleSaveInvoicePreview() {
    const { dispatch, detailCompany } = this.props;
    dispatch(setInvoiceParamsRequest(detailCompany.id)).then(resp => {
      if (!resp) {
        const tabToChange = _find(this.state.tabsData, { id: 'invoice-params-tab' });
        tabToChange.icon = '';
        this.setState({ tabsData: [...this.state.tabsData] });
      }
    });
  }

  createAccountCallback() {
    const { dispatch, companyId } = this.props;
    dispatch(createConnectedAccount(companyId));
  }

  stripeRefreshLink() {
    const { dispatch } = this.props;
    dispatch(stripeAccountLinkRefresh());
  }

  deleteAccount() {
    const { dispatch, companyId } = this.props;
    dispatch(supressConnectedAccount(companyId)).then(() => dispatch(getConnectedAccount(companyId)));
  }

  handleInvoicePreview() {
    this.props.dispatch(previewCompanyInvoice());
  }

  handleCloseInvoicePreview() {
    this.props.dispatch(clearCompanyInvoice());
  }

  handleView(item) {
    const { dispatch } = this.props;
    dispatch(toggleDrivingAuthorisationPdfPreview(item));
  }

  handleClosePreview() {
    this.props.dispatch(toggleDrivingAuthorisationPdfPreview(false));
  }

  handleDownloadInvoicePreview() {
    const { invoicePreview, bundle } = this.props;

    const blob = _get(invoicePreview, 'blob');
    const name = bundle.company_invoice_preview || 'Invoice_preview';
    const at = bundle.common_at || 'at';
    const now = moment();
    const date = now.format('DD-MM-YYYY');
    const time = now.format('HH.mm.ss');

    if (blob) saveAs(blob, `${name}_${date}_${at}_${time}.pdf`);
  }

  handleSaveEmailSetup() {
    const { dispatch } = this.props;

    dispatch(setCompanyEmailSetup()).then(() => {
      dispatch(reset(COMPANY_EMAIL_SETUP_FORM));
      dispatch(fileUploadResetState());
    });
  }

  handleDeleteEmailSetup() {
    const { detailCompany, dispatch } = this.props;
    const companyId = _get(detailCompany, 'id');

    function displayError() {
      return dispatch(
        addFlashMessage({
          contentKey: 'error_server_unknown',
          type: FLASH_MESSAGE_TYPE_ERROR
        })
      );
    }

    function handleSuccess() {
      dispatch(reset(COMPANY_EMAIL_SETUP_FORM));

      return dispatch(
        addFlashMessage({
          contentKey: 'company_email_setup_delete_success',
          type: FLASH_MESSAGE_TYPE_SUCCESS
        })
      );
    }

    dispatch(deleteCompanyEmailSetup()).then(
      () => {
        return dispatch(pullCompanyEmailSetup(companyId)).then(handleSuccess, displayError);
      },
      error => {
        if (_get(error, 'status') === 404) {
          return dispatch(pullCompanyEmailSetup(companyId)).then(handleSuccess, displayError);
        } else return displayError();
      }
    );
  }

  handleChangeTabIndex(index, e) {
    safe(() => e.preventDefault());
    this.props.dispatch(setCurrentTabIndex(index));
  }

  renderUnauthorizedApplicationsList() {
    const { allApplications, companyAuthorizedApplications } = this.props;
    let applicationsList = [];

    if (allApplications && allApplications.length > 0) {
      applicationsList = allApplications
        .filter(application => !_includes(companyAuthorizedApplications, application))
        .map(item => ({
          label: item,
          value: item
        }));
    }
    return applicationsList;
  }

  renderAuthorizedApplicationsList() {
    const { companyAuthorizedApplications } = this.props;

    return companyAuthorizedApplications.map(item => ({
      label: item,
      value: item
    }));
  }

  handleAuthorizeApplication() {
    const { dispatch, allApplications } = this.props;
    let applicationIds = this.props.fields.unauthorizedApplication.value;
    let applications = [];

    allApplications.forEach(application => {
      if (applicationIds.indexOf(application) !== -1) {
        applications.push(application);
      }
    });

    dispatch(authorizeApplications(applications));
  }

  handleUnauthorizeApplication() {
    const { dispatch, allApplications } = this.props;
    let applicationsIds = this.props.fields.authorizedApplication.value;
    let applications = [];

    allApplications.forEach(application => {
      if (applicationsIds.indexOf(application) !== -1) {
        applications.push(application);
      }
    });

    if (applications.length > 0) {
      dispatch(unauthorizeApplications(applications));
    }
  }

  handleSaveApplication() {
    const { dispatch, detailCompany, bundle } = this.props;

    dispatch(saveCompanyAuthorizedApplications(detailCompany.id)).then(
      () => {
        dispatch(
          addFlashMessage({
            contentKey: 'company_applications_update_success',
            type: FLASH_MESSAGE_TYPE_SUCCESS
          })
        );
      },
      error => {
        const msgParams = { bundle, error, def: 'company_applications_update_error' };
        dispatch(addErrorMessage(msgParams));
      }
    );
  }

  // set _get defaultValue to empty string
  emailSetupSection() {
    const { customizations } = this.props;

    return (
      <section className="companyDetail">
        <CompanyEmailSetupForm
          readOnly={this.readOnly}
          onSave={this.handleSaveEmailSetup}
          onDelete={this.handleDeleteEmailSetup}
          initialValues={{
            emailLogo: _get(customizations, 'logo', ''),
            colorPrimary: _get(customizations, 'colour1', ''),
            colorSecondary: _get(customizations, 'colour2', ''),
            linkedinLink: _get(customizations, 'linkedinLink', ''),
            twitterLink: _get(customizations, 'twitterLink', ''),
            homeDeeplink: _get(customizations, 'homeDeeplink', ''),
            productName: _get(customizations, 'productName', ''),
            senderEmail: _get(customizations, 'senderEmail', ''),
            senderSms: _get(customizations, 'senderSms', '')
          }}
        />
      </section>
    );
  }

  displayTab() {
    const { currentTabIndex } = this.props;
    const dataFunction = this.state.tabsData[currentTabIndex].displayTab;
    const key = 'companyTab_' + currentTabIndex;
    if (_isFunction(dataFunction)) return <div key={key}>{dataFunction()}</div>;
  }

  getBoolText(state) {
    return state ? <FormattedMessage id="common_yes" /> : <FormattedMessage id="common_no" />;
  }

  handleUpdateCompany() {
    const { dispatch, detailCompany, bundle } = this.props;

    dispatch(updateCompanyRequest(detailCompany)).then(
      company => {
        dispatch(updateCompanySuccess(company));
        dispatch(setCurrentSuperCompanyInHeader(company));
        dispatch(fileUploadResetState());

        dispatch(
          addFlashMessage({
            contentKey: 'companyForm_update_company_success',
            type: FLASH_MESSAGE_TYPE_SUCCESS
          })
        );
      },
      error => {
        const partialErrors = _values(partialErrorCodes.phoneNumbers);
        const msgParams = { bundle, partialErrors, error, def: 'companyForm_update_company_error' };
        dispatch(addErrorMessage(msgParams));
      }
    );
  }

  companyTab() {
    const { detailCompany, subscriptionUrl } = this.props;
    const {
      billingSettings,
      dekraSettings,
      vehicleInspectionPhoto,
      additionalLegalDocument,
      damageSafetyDepositReleaseDelayInDays,
      drivingAuthorisationSettings,
      pricesIncludingTaxes,
      externalId,
      ssoConfiguration
    } = detailCompany || {};

    const {
      enableBookingDamageSafetyDeposit,
      enableBookingPriceSafetyDepositBuffer,
      bookingPriceSafetyDepositBuffer,
      bookingDamageSafetyDepositAmount
    } = billingSettings || {};

    const enableDekraService = !_isEmpty(dekraSettings);
    const enabledReportAdditionalPhoto = !_isEmpty(vehicleInspectionPhoto);
    const enabledAdditionalDoc = !_isEmpty(additionalLegalDocument);
    const additionalLegalDoc = additionalLegalDocument || {};

    const { dekraCheckService, dekraExpertiseService } = dekraSettings || {};
    const { nameForElectricVehicle, nameForHybridVehicle, nameForThermalVehicle, startBooking, endBooking } = vehicleInspectionPhoto || {};

    return (
      <CompanyForm
        onCallback={this.handleUpdateCompany}
        readOnly={this.readOnly}
        isDisplayForm
        initialValues={{
          name: detailCompany.name,
          address: detailCompany.address,
          city: detailCompany.address.city,
          postalCode: detailCompany.address.postalCode,
          country: detailCompany.address.country,
          email: detailCompany.email,
          phoneNumber: {
            phonePrefix: _get(detailCompany, 'phoneNumber.countryCode'),
            phoneSuffix: _get(detailCompany, 'phoneNumber.nationalNumber'),
            valid: true
          },
          secondaryPhoneNumber: {
            phonePrefix: _get(detailCompany, 'secondaryPhoneNumber.countryCode', ''),
            phoneSuffix: _get(detailCompany, 'secondaryPhoneNumber.nationalNumber', ''),
            valid: true
          },
          access: detailCompany.access,
          termsOfUse: detailCompany.termsOfUseUrl,
          termsOfSubscription: detailCompany.termsOfSubscriptionUrl,
          privacyPolicy: detailCompany.privacyPolicyUrl,
          durationAfterTripToAllowLockUnlock: detailCompany.durationAfterTripToAllowLockUnlock,
          templateGroup: TEMPLATE_EMAIL_DEFAULT,
          websiteConfirmSubscriptionUrl: detailCompany.websiteConfirmSubscriptionUrl,
          websiteResetPasswordUrl: detailCompany.websiteResetPasswordUrl,
          backofficeResetPasswordUrl: detailCompany.backofficeResetPasswordUrl,
          employerCertificateUrl: detailCompany.employerCertificateUrl,
          configurationId: detailCompany.configurationId,
          expediteReview: detailCompany.expediteReview === true ? 'true' : 'false',
          secondaryEmail: detailCompany.secondaryEmail,
          currency: detailCompany.currency,
          vehicleReassignmentDelayInMin: detailCompany.vehicleReassignmentDelayInMin || '0',
          sendEmailsToManager: detailCompany.sendEmailsToManager ? 'true' : 'false',
          identityDocumentRequired: detailCompany.identityDocumentRequired === true ? 'true' : 'false',
          drivingLicenceRequired: detailCompany.drivingLicenceRequired === true ? 'true' : 'false',
          employerCertificateRequired: detailCompany.employerCertificateRequired === true ? 'true' : 'false',
          useExternalInvoiceSystem: detailCompany.useExternalInvoiceSystem,
          startBookingDamageReportMandatory: detailCompany.startBookingDamageReportMandatory === true ? 'true' : 'false',
          endBookingDamageReportMandatory: detailCompany.endBookingDamageReportMandatory === true ? 'true' : 'false',
          damageReportPhotos: detailCompany.damageReportPhotos === true ? 'true' : 'false',
          billBeforeBooking: detailCompany.billingSettings.billBeforeBooking === true ? 'true' : 'false',
          immediatePaymentForBookingExtension: detailCompany.immediatePaymentForBookingExtension === true ? 'true' : 'false',
          pricesIncludingTaxes: boolToString(pricesIncludingTaxes),
          preBookingBillingOffset: detailCompany.billingSettings.preBookingBillingOffset,
          secondPreBillingOffsetMinutes: detailCompany.billingSettings.secondPreBillingOffsetMinutes,
          rrsSafetyDepositAmount: detailCompany.billingSettings.rrsSafetyDepositAmount,
          deleteMemberDocumentsOnApproval: _get(detailCompany, 'deleteMemberDocumentsOnApproval') ? 'true' : 'false',
          memberExternalIdForImportCustomFieldId: _get(detailCompany, 'memberExternalIdForImportCustomFieldId'),
          sendDrivingAuthorisation: detailCompany.sendDrivingAuthorisation === true ? 'true' : 'false',
          genericPdfDocumentUrl: _get(drivingAuthorisationSettings, 'genericPdfDocumentUrl'),
          logoUrl: _get(drivingAuthorisationSettings, 'logoUrl'),
          signatureImageUrl: _get(drivingAuthorisationSettings, 'signatureImageUrl'),
          vehicleOwner: _get(drivingAuthorisationSettings, 'vehicleOwner'),
          drivingAuthCompanyName: _get(drivingAuthorisationSettings, 'companyName'),
          drivingAuthPhone: {
            phonePrefix: _get(drivingAuthorisationSettings, 'phoneNumber.countryCode'),
            phoneSuffix: _get(drivingAuthorisationSettings, 'phoneNumber.nationalNumber'),
            valid: true
          },
          drivingAuthAddress: _get(drivingAuthorisationSettings, 'address'),
          drivingAuth_postalCode: _get(drivingAuthorisationSettings, 'address.postalCode'),
          drivingAuth_city: _get(drivingAuthorisationSettings, 'address.city'),
          appBrandId: _get(detailCompany, 'brand.id'),
          externalId,
          fiscalCode: _get(detailCompany, 'fiscalCode'),
          enableBookingDamageSafetyDeposit: boolToString(enableBookingDamageSafetyDeposit),
          enableDekraService: boolToString(enableDekraService),
          dekraCheckService,
          dekraExpertiseService,
          enableBookingPriceSafetyDepositBuffer: boolToString(enableBookingPriceSafetyDepositBuffer),
          bookingPriceSafetyDepositBuffer,
          bookingDamageSafetyDepositAmount,
          damageSafetyDepositReleaseDelayInDays,
          subscriptionUrl,
          inviteCode: getShortId(detailCompany.id),
          ssoEnabled: detailCompany.ssoEnabled === true ? 'true' : 'false',
          identityProvider: _get(ssoConfiguration, 'identityProvider', ''),
          ssoDomains: arrayToString(_get(ssoConfiguration, 'domains', '')),
          allowRegistrationWithoutSso: _get(ssoConfiguration, 'allowRegistrationWithoutSso') === true ? 'true' : 'false',
          emailAutomaticallyVerified: _get(ssoConfiguration, 'emailAutomaticallyVerified') === true ? 'true' : 'false',
          profileAutomaticallyApproved: _get(ssoConfiguration, 'profileAutomaticallyApproved') === true ? 'true' : 'false',
          enabledReportAdditionalPhoto: boolToString(enabledReportAdditionalPhoto),
          companyLogo: safe(() => detailCompany.logoUrl),
          startBooking: boolToString(startBooking),
          endBooking: boolToString(endBooking),
          nameForElectricVehicle,
          nameForHybridVehicle,
          inversFleetId: _get(detailCompany, 'inversFleet.id'),
          hasCarwise: _get(detailCompany, 'carWiseOrganizationId') ? 'false' : 'true',
          carWiseOrganizationId: _get(detailCompany, 'carWiseOrganizationId'),
          nameForThermalVehicle,
          clientProfileType: _get(detailCompany, 'rrsConfiguration.clientProfileType'),
          taxiClientCostCustomFieldId: _get(detailCompany, 'rrsConfiguration.taxiClientCostCustomFieldId'),
          taxiClientIpnCustomFieldId: _get(detailCompany, 'rrsConfiguration.taxiClientIpnCustomFieldId'),
          taxiMissionOrderCustomFieldId: _get(detailCompany, 'rrsConfiguration.taxiMissionOrderCustomFieldId'),
          italianMemberProfileCustomFieldId: safe(() => detailCompany.italianMemberProfileCustomFieldId),
          displayAdditionalLegalDoc: boolToString(enabledAdditionalDoc),
          additionalLegalDocName: _get(additionalLegalDoc, 'name'),
          additionalLegalDocFileId: _get(additionalLegalDoc, 'fileUrl'),
          additionalLegalDocTranslations: i18nAdditionnalDocFormKeys(additionalLegalDoc.translations),
          additionalLegalDocTranslationsLength: safe(() => additionalLegalDoc.translations.length)
        }}
        companyId={detailCompany.id}
      />
    );
  }

  companyDetailTabs() {
    const { currentTabIndex } = this.props;

    return (
      <div className="companyDetail_tabs">
        {this.state.tabsData.map((data, index) => (
          <div
            className={cs({
              companyDetail_tabItem: true,
              __is_selected: currentTabIndex === index
            })}
            key={'tab-' + index}
            onClick={data.handleClick}
          >
            {data.getTitle ? data.getTitle() : <FormattedMessage id={data.titleKey} />}
            {data.icon && data.icon}
          </div>
        ))}
      </div>
    );
  }

  appsTabs() {
    const {
      fields: { unauthorizedApplication, authorizedApplication }
    } = this.props;

    return (
      <section className="companyDetail">
        <h4 className="companyDetail_title">
          <FormattedMessage id="companies_detail_applications" />
        </h4>

        <div className="companyDetail_line  companyDetail_line--quad">
          <div className="companyDetail_item">
            <BoxedSelect
              multiple
              formRowItemKey="companyDetail_usedApplications"
              id="usedApplications"
              disabled={this.readOnly}
              customClass="companyDetail_boxedSelectMultipleWrapper"
              customSelectClass="companyDetail_boxedSelect"
              labelKey="companyDetail_authorizedApplications_label"
              options={this.renderAuthorizedApplicationsList()}
              field={authorizedApplication}
            />
          </div>

          <div className="companyDetail_item">
            <div className="companyDetail_actionsAssign">
              <button
                type="button"
                className={classNames('companyDetail_assignButton', 'companyDetail_assignButton--left')}
                onClick={this.handleAuthorizeApplication}
                disabled={!unauthorizedApplication.value || this.readOnly}
              >
                <FormattedMessage id="companyDetail_addToUsed_button" />
              </button>

              <button
                type="button"
                className={classNames('companyDetail_assignButton', 'companyDetail_assignButton--right')}
                onClick={this.handleUnauthorizeApplication}
                disabled={!authorizedApplication.value || this.readOnly}
              >
                <FormattedMessage id="companyDetail_addToFree_button" />
              </button>
            </div>
          </div>

          <div className="companyDetail_item">
            <BoxedSelect
              disabled={this.readOnly}
              multiple
              formRowItemKey="companyDetail_unauthorizedApplications"
              id="unauthorizedApplications"
              customClass="companyDetail_boxedSelectMultipleWrapper"
              customSelectClass="companyDetail_boxedSelect"
              labelKey="companyDetail_unauthorizedApplications_label"
              options={this.renderUnauthorizedApplicationsList()}
              field={unauthorizedApplication}
            />
          </div>

          <div className="companyDetail_item">
            {!this.readOnly && (
              <EkButton customClass="companyForm_actionsButton" onAction={this.handleSaveApplication}>
                <FormattedMessage id="companyDetail_save_button" />
              </EkButton>
            )}
          </div>
        </div>
      </section>
    );
  }

  subscribeFields() {
    return (
      <section className="companyDetail ">
        {!this.readOnly && <CustomFieldForm formType={CUSTOM_FIELD_FORM_SUBSCRIPTION} />}
        <CustomFields limitedAccess={this.readOnly} type={CUSTOM_FIELD_FORM_SUBSCRIPTION} />
      </section>
    );
  }

  bookingFields() {
    return (
      <section className="companyDetail">
        {!this.readOnly && <CustomFieldForm formType={CUSTOM_FIELD_FORM_BOOKING} />}
        <CustomFields limitedAccess={this.readOnly} type={CUSTOM_FIELD_FORM_BOOKING} />
      </section>
    );
  }

  onUpdateCurrency(data) {
    const { dispatch, detailCompany } = this.props;
    const currency = _get(detailCompany, 'currency');

    if (data && currency !== data) {
      dispatch(updateCurrencyReq({ ...detailCompany, currency: data })).then(
        company => {
          dispatch(updateCompanySuccess(company));
          dispatch(setCurrentSuperCompanyInHeader(company));
        },
        () => {
          dispatch(
            addFlashMessage({
              contentKey: 'companyForm_update_company_error',
              type: FLASH_MESSAGE_TYPE_ERROR
            })
          );
        }
      );
    }
  }

  invoiceTab() {
    const { detailCompany, detailInvoiceParams, invoiceVatRateParam, invoicePreview, currencies } = this.props;
    const invoicingAddress = _get(detailInvoiceParams, 'invoicingAddress');
    const pdf = _get(invoicePreview, 'base64');

    return (
      <section className="companyDetail">
        <CompanyInvoiceParamsForm
          onPreview={this.handleInvoicePreview}
          onFormSubmit={this.handleSaveInvoicePreview}
          currencies={currencies}
          handleUpdateCurrency={this.onUpdateCurrency}
          initialValues={{
            companyCapital: _get(detailInvoiceParams, 'companyCapital'),
            invoicingDelegation: String(!!_get(detailInvoiceParams, 'invoicingDelegation')),
            legalForm: _get(detailInvoiceParams, 'legalForm'),
            fiscalNumber: _get(detailInvoiceParams, 'fiscalNumber'),
            vatCode: _get(detailInvoiceParams, 'vatCode'),
            invoiceNumberSuffix: _get(detailInvoiceParams, 'invoiceNumberSuffix'),
            paymentConditionsLabel: _get(detailInvoiceParams, 'paymentConditionsLabel'),
            currency: _get(detailCompany, 'currency'),
            templateModel: _get(detailInvoiceParams, 'templateModel'),
            invoicingAddress: _isEmpty(invoicingAddress)
              ? _get(detailCompany, 'address')
              : streetNameFormat(invoicingAddress.streetNumber, invoicingAddress.streetName),
            city: _isEmpty(invoicingAddress) ? _get(detailCompany, 'address.city') : invoicingAddress.city,
            postalCode: _isEmpty(invoicingAddress) ? _get(detailCompany, 'address.postalCode') : invoicingAddress.postalCode,
            country: _isEmpty(invoicingAddress) ? _get(detailCompany, 'address.country') : invoicingAddress.country,
            italianRea: _get(detailInvoiceParams, 'italianRea'),
            vatRate: _get(invoiceVatRateParam, 'vatRate'),
            companyCourt: _get(detailInvoiceParams, 'companyCourt'),
            companyRegisterNumber: _get(detailInvoiceParams, 'companyRegisterNumber'),
            dataProcessingRegistry: _get(detailInvoiceParams, 'dataProcessingRegistry'),
            companyName: _get(detailInvoiceParams, 'companyName'),
            invoiceNumberPrefix: _get(detailInvoiceParams, 'italianInvoicingParameters.numberPrefix'),
            taxIdCode: _get(detailInvoiceParams, 'italianInvoicingParameters.taxIdentificationCode'),
            memberCode: _get(detailInvoiceParams, 'italianInvoicingParameters.memberCode'),
            socioUnico: _get(detailInvoiceParams, 'italianInvoicingParameters.socioUnico'),
            fiscalScheme: _get(detailInvoiceParams, 'italianInvoicingParameters.fiscalScheme'),
            ufficio: _get(detailInvoiceParams, 'italianInvoicingParameters.ufficio'),
            statusLiquidation: _get(detailInvoiceParams, 'italianInvoicingParameters.statusLiquidation'),
            firstInvoiceNumber: _get(detailInvoiceParams, 'italianInvoicingParameters.numberCounterInit'),
            useExternalInvoiceSystem: _get(detailCompany, 'useExternalInvoiceSystem'),
            credentialsProvided: _get(detailInvoiceParams, 'italianInvoicingParameters.credentialsProvided', false)
          }}
        />
        <PdfModal show={!!pdf} src={pdf} onClose={this.handleCloseInvoicePreview} onDownload={this.handleDownloadInvoicePreview} />
      </section>
    );
  }

  getBackLink() {
    const params = this.props.urlParams || apiParams.default;
    return `/#${routes.companies.path.replace(':search', encodeURIComponent(JSON.stringify(params)))}`;
  }

  render() {
    return (
      <div className="companyDetailPage mainContainer_content">
        <div className="pageContainer">
          <div className="companyDetailPage_content">
            {!this.props.isModal && <BackLink link={this.getBackLink()} labelKey="back_link_super-companies_list" />}
            {this.companyDetailTabs()}
            {this.displayTab()}
          </div>
        </div>
      </div>
    );
  }
}

CompanyDetail.propTypes = {
  isModal: PropTypes.bool
};

CompanyDetail = reduxForm({
  onSubmitFail: scrollToFirstError,
  form: 'companyDetailForm',
  fields: ['unauthorizedApplication', 'authorizedApplication']
})(CompanyDetail);

export default namedCompose(
  connect(state => {
    const {
      companies: {
        detailCompany,
        detailInvoiceParams,
        invoiceVatRateParam,
        companyAuthorizedApplications,
        companyUsedRoutes,
        urlParams,
        currentTabIndex,
        customizations,
        invoicePreview,
        pdfPreview,
        currencies,
        paybackData,
        paybackFormValues,
        bankAccountDetails
      },
      applications: { allApplications },
      configuration: { allConfigurations }
    } = state;

    const { id: companyId, paymentProvider } = detailCompany || {};
    const firstPayback = !paybackData;

    return {
      detailCompany,
      currencies,
      detailInvoiceParams,
      invoiceVatRateParam,
      companyAuthorizedApplications,
      companyUsedRoutes,
      urlParams,
      allApplications,
      currentTabIndex,
      allConfigurations,
      customizations,
      invoicePreview,
      pdfPreview,
      companyId,
      paybackData,
      bankAccountDetails,
      firstPayback,
      paybackFormValues,
      paymentProvider,
      connectedAccount: accountStripeCompanySelector(state),
      companyContract: currentCompanyContractSelector(state),
      bookingRules: headerContractBookingValuesSelector(state),
      paymentRules: headerContractPaymentValuesSelector(state),
      memberRules: headerContractMemberValuesSelector(state),
      subscriptionUrl: subscriptionUrlSelector(state),
      invodeCode: subscriptionUrlSelector(state),
      bundle: bundleSelector(state),
      role: userRoleSelector(state),
      locale: localeSelector(state)
    };
  }),
  injectIntl
)(CompanyDetail);
