import React, { Component, PropTypes } from 'react';

import { FormattedMessage } from 'react-intl';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';

import FormRow from '../FormRow/FormRow';
import BoxedInput from '../BoxedInput/BoxedInput';
import EkButton from '../EkButton/EkButton';
import BoxedSelect from '../BoxedSelect/BoxedSelect';
import { allDamagesTypes } from '../../constants/filters-constants';
import { allDamagesStatues } from '../../constants/options-constants';
import { scrollToFirstError } from '../../utils/utils';

class SearchVehicleDamages extends Component {
  render() {
    const {
      fields: { bookingId, plateNumber, damageType, status, startDate, endDate },
      handleSubmit
    } = this.props;

    return (
      <section className="searchVehicleDamagesWrapper">
        <form action="#" className="searchBookingsForm" onSubmit={handleSubmit(this.props.onCallback)}>
          <FormRow>
            <div>
              <BoxedInput
                type="text"
                id="search_bookingID"
                placeholderKey="common_placeHolder_searchForBookingID"
                customClass="vehicleDamagesPage_search"
                field={bookingId}
              />

              <BoxedInput
                type="text"
                id="search_plateNumber"
                placeholderKey="common_placeHolder_searchForPlateNumber"
                customClass="vehicleDamagesPage_search"
                field={plateNumber}
              />
            </div>
          </FormRow>

          <FormRow>
            <div>
              <BoxedSelect
                customClass="bookingsPage_search_boxedSelectWrapper"
                labelKey="vehicleDamages_tableView_label_type"
                options={allDamagesTypes}
                field={damageType}
              />

              <BoxedSelect
                customClass="bookingsPage_search_boxedSelectWrapper"
                options={allDamagesStatues}
                labelKey="vehicleDamages_tableView_label_status"
                field={status}
              />

              <BoxedInput
                id="search_bookingId"
                type="date"
                skinType="date"
                labelKey="vehicleDamages_tableView_label_startDate"
                customClass="vehicleDamagesPage_search"
                field={startDate}
              />

              <BoxedInput
                id="search_bookingId"
                type="date"
                skinType="date"
                labelKey="vehicleDamages_tableView_label_endDate"
                customClass="vehicleDamagesPage_search"
                field={endDate}
              />
            </div>
          </FormRow>

          <div className="searchVehicleDamagesWrapper_actions">
            <EkButton
              onAction={this.props.onResetCallBack}
              customClass="vehiclesFiltersForm_actionsButton"
              skinType="reverse"
              type="button"
            >
              <FormattedMessage id="searchVehicleDamagesForm_reset_button" />
            </EkButton>

            <EkButton onAction={handleSubmit(this.props.onCallback)} customClass="vehiclesFiltersForm_actionsButton">
              <FormattedMessage id="searchVehicleDamagesForm_search_button" />
            </EkButton>
          </div>
        </form>
      </section>
    );
  }
}

SearchVehicleDamages.displayName = 'SearchVehicleDamages';

SearchVehicleDamages.propTypes = {
  fields: PropTypes.object,
  handleSubmit: PropTypes.func,
  publicId: PropTypes.string,
  onCallback: PropTypes.func,
  onResetCallBack: PropTypes.func.isRequired
};

SearchVehicleDamages = reduxForm({
  onSubmitFail: scrollToFirstError,
  form: 'searchVehicleDamages',
  fields: ['bookingId', 'plateNumber', 'damageType', 'status', 'startDate', 'endDate']
})(SearchVehicleDamages);

export default connect(state => {
  const {
    vehicleDamages: { paginatedResults }
  } = state;

  return {
    results: paginatedResults.results
  };
})(SearchVehicleDamages);
