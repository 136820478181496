import * as types from '../../constants/actionTypes-constants';
import moment from 'moment';
import _flattenDeep from 'lodash/flattenDeep';
import _compact from 'lodash/compact';
import _get from 'lodash/get';
import { ALL } from '../../constants/generic-constants';
import {
  VEHICLE_USAGE_TYPE_DELIVERY,
  VEHICLE_USAGE_TYPE_IMMEDIATE,
  VEHICLE_USAGE_TYPE_PLANNED,
  VEHICLE_USAGE_TYPE_PUBLIC_SERVICE,
  VEHICLE_USAGE_TYPE_RV,
  VEHICLE_USAGE_TYPE_TEST_DRIVE
} from '../../constants/backend-constants';
import { vehicleUsageTypes } from '../../constants/options-constants';

const defaultState = {
  startDate: moment(moment().startOf('day')).toISOString(),
  periodInDays: Number(window.localStorage.getItem('planningPeriodInDays')) || 1,
  siteId: ALL,
  loading: true,
  data: {
    sites: []
  },
  openedCard: false,
  selectedBooking: false,
  selectedVehicle: false,
  selectedMember: false,
  openedEditBookingModal: false,
  bookingDetail: false,
  bookingPrice: false,
  noprices: false,
  selectedBookingId: false,
  openedChangeVehicleModal: false,
  openedMemberChangeModal: false,
  editMemberChange: false,
  loadingBookingDetail: false,
  loadingBookingPrice: false,
  loadingAvailableVehicles: true,
  availableVehicles: [],
  vehiclesUsagesInit: [],
  vehiclesUsagesFiltered: vehicleUsageTypes.map(type => type.key),
  filters: { fuels: [], usages: [], sites: [], registrationNumber: null },
  createBookingMode: false,
  firstDaySelected: {},
  secondDaySelected: {},
  firstHourSelected: {},
  secondHourSelected: {},
  openMemberSelectModal: false,
  openConfirmAddStatusModal: false,
  errorMemberSelection: false,
  openCreateBookingModal: false,
  profileIdForCreateBooking: '',
  loginForCreateBooking: '',
  profileReviewStatus: '',
  userDetailCreateBooking: '',
  preSubMemberStatus: '',
  isPreBookingCreation: false,
  loginForEditBooking: '',
  userDetailEditBooking: {},
  createStatusMode: false,
  createStatusData: {},
  openCreateStatusModal: false,
  statusDetailData: {},
  openShortenExtendBooking: false,
  preSubMemberReady: false,
  preSubMember: null,
  memberExist: '',
  confirmPrebooking: false,
  proceedNew: false,
  openShortenExtendBookingHover: false,
  foundUsers: [],
  selectedMemberId: '',
  selectedMemberType: {},
  persist: true,
  skipConfirm: false,
  changeVehiclePayloadSearch: null,
  legendShown: false,
  loadingError: false
};

function findById(sites, id) {
  const schedule = sites.map(site => {
    return site.parkings.map(parking => {
      return parking.vehicles.map(vehicle => {
        if (vehicle.id === id) {
          return parking.schedule;
        }
      });
    });
  });
  return _compact(_flattenDeep(schedule));
}

function getUsages(data) {
  const usages = [];

  data.sites.forEach(site =>
    site.parkings.forEach(parking => {
      if (!usages.includes(VEHICLE_USAGE_TYPE_PLANNED))
        parking.vehicles.some(v => v.usage === VEHICLE_USAGE_TYPE_PLANNED) ? usages.push(VEHICLE_USAGE_TYPE_PLANNED) : null;
      if (!usages.includes(VEHICLE_USAGE_TYPE_IMMEDIATE))
        parking.vehicles.some(v => v.usage === VEHICLE_USAGE_TYPE_IMMEDIATE) ? usages.push(VEHICLE_USAGE_TYPE_IMMEDIATE) : null;
      if (!usages.includes(VEHICLE_USAGE_TYPE_PUBLIC_SERVICE))
        parking.vehicles.some(v => v.usage === VEHICLE_USAGE_TYPE_PUBLIC_SERVICE) ? usages.push(VEHICLE_USAGE_TYPE_PUBLIC_SERVICE) : null;
      if (!usages.includes(VEHICLE_USAGE_TYPE_RV))
        parking.vehicles.some(v => v.usage === VEHICLE_USAGE_TYPE_RV) ? usages.push(VEHICLE_USAGE_TYPE_RV) : null;
      if (!usages.includes(VEHICLE_USAGE_TYPE_DELIVERY))
        parking.vehicles.some(v => v.usage === VEHICLE_USAGE_TYPE_DELIVERY) ? usages.push(VEHICLE_USAGE_TYPE_DELIVERY) : null;
      if (!usages.includes(VEHICLE_USAGE_TYPE_TEST_DRIVE))
        parking.vehicles.some(v => v.usage === VEHICLE_USAGE_TYPE_TEST_DRIVE) ? usages.push(VEHICLE_USAGE_TYPE_TEST_DRIVE) : null;
    })
  );

  return usages;
}

function filterSites(sites = [], filters = []) {
  sites.forEach(site => {
    site.parkings.forEach(parking => {
      parking.vehicles = parking.vehicles.map(vehicle => {
        vehicle.visible = filters.includes(vehicle.usage);
        return vehicle;
      });
    });
  });
}

function filterSitesReset(sites = []) {
  sites.forEach(site => {
    site.parkings.forEach(parking => {
      parking.vehicles = parking.vehicles.map(vehicle => {
        vehicle.visible = true;
        return vehicle;
      });
    });
  });
}

export default function vehiclePlanning(state = defaultState, action) {
  switch (action.type) {
    case types.VEHICLE_PLANNING_TOGGLE_SHORTEN_EXTEND_MODAL: {
      const vId = state.selectedVehicle.id;
      const parkingSchedule = findById(state.data.sites, vId);
      if (action.openShortenExtendBookingHover) {
        return {
          ...state,
          parkingSchedule,
          selectedBooking: action.selectedBooking,
          selectedVehicle: action.selectedVehicle,
          openShortenExtendBookingHover: !state.openShortenExtendBookingHover
        };
      } else {
        return {
          ...state,
          parkingSchedule,
          selectedBooking: action.selectedBooking,
          selectedVehicle: action.selectedVehicle,
          openShortenExtendBooking: !state.openShortenExtendBooking
        };
      }
    }

    case types.SUB_COMPANIES_SET_CURRENT_SUB_COMPANY: {
      return {
        ...state,
        siteId: ALL
      };
    }

    case types.VEHICLE_PLANNING_EMPTY_CREATE_STATUS_DATA: {
      return {
        ...state,
        createStatusData: {},
        openCreateStatusModal: false,
        createStatusMode: false
      };
    }

    case types.VEHICLE_PLANNING_SAVE_CREATE_STATUS_DATA: {
      return {
        ...state,
        createStatusData: {
          dayIndex: action.dayIndex,
          vehicle: action.vehicle
        },
        openCreateStatusModal: true
      };
    }

    case types.VEHICLE_PLANNING_ADD_STATUS_CONFIRM_OPEN: {
      return {
        ...state,
        openConfirmAddStatusModal: true
      };
    }

    case types.VEHICLE_PLANNING_ADD_STATUS_CONFIRM_CLOSE: {
      return {
        ...state,
        openConfirmAddStatusModal: false
      };
    }

    case types.VEHICLE_PLANNING_TOGGLE_CREATE_STATUS_MODE: {
      return {
        ...state,
        createStatusMode: !state.createStatusMode
      };
    }

    case types.VEHICLE_PLANNING_CREATE_BOOKING_SUCCESS: {
      return {
        ...state,
        openCreateBookingModal: !state.openCreateBookingModal
      };
    }

    case types.VEHICLE_PLANNING_TOGGLE_CREATE_BOOKING_MODAL: {
      return {
        ...state,
        openCreateBookingModal: !state.openCreateBookingModal,
        bookingPrice: false
      };
    }

    case types.VEHICLE_PLANNING_SAVE_LOGIN_CREATE_BOOKING: {
      return {
        ...state,
        loginForCreateBooking: action.login,
        profileIdForCreateBooking: action.memberProfileId,
        profileReviewStatus: action.status,
        userDetailCreateBooking: action.user,
        preSubMemberStatus: action.preSubMemberStatus,
        isPreBookingCreation: action.isPreBooking
      };
    }

    case types.VEHICLE_PLANNING_SAVE_MEMBER_TYPE_CREATE_BOOKING: {
      return {
        ...state,
        selectedMemberId: action.memberId,
        selectedMemberType: action.memberTypeId
      };
    }

    case types.VEHICLE_PLANNING_TOGGLE_MEMBER_SELECT: {
      return {
        ...state,
        openMemberSelectModal: !state.openMemberSelectModal,
        errorMemberSelection: false
      };
    }

    case types.VEHICLE_PLANNING_SAVE_LOGIN_EDIT_BOOKING: {
      return {
        ...state,
        loginForEditBooking: action.login,
        userDetailEditBooking: action.userDetail
      };
    }

    case types.VEHICLE_PLANNING_CHANGE_MEMBER_EDIT_BOOKING: {
      return {
        ...state,
        bookingDetail: {
          ...state.bookingDetail,
          member: action.userDetail,
          persist: action.persist
        },
        editMemberChange: false
      };
    }

    case types.VEHICLE_PLANNING_ERROR_SELECTED_USER: {
      return {
        ...state,
        errorMemberSelection: true,
        proceedNew: false
      };
    }

    case types.VEHICLE_PLANNING_CLEAN_ERROR_SELECTED_USER: {
      return {
        ...state,
        errorMemberSelection: false
      };
    }

    case types.VEHICLE_PLANNING_CLEAR_CREATE_BOOKING_MODAL: {
      return {
        ...state,
        createBookingMode: false,
        firstDaySelected: {},
        secondDaySelected: {},
        firstHourSelected: {},
        secondHourSelected: {},
        preSubMember: null,
        proceedNew: false,
        preSubMemberReady: false,
        bookingPrice: false
      };
    }

    case types.VEHICLE_PLANNING_SELECT_SECOND_HOUR_FOR_CREATING_BBOKING: {
      return {
        ...state,
        createBookingMode: true,
        secondHourSelected: {
          value: action.value,
          vehicle: action.vehicle,
          parking: action.parking,
          siteId: action.siteId
        }
      };
    }

    case types.VEHICLE_PLANNING_SELECT_FIRST_HOUR_FOR_CREATING_BBOKING: {
      return {
        ...state,
        createBookingMode: true,
        firstHourSelected: {
          value: action.value,
          vehicle: action.vehicle,
          parking: action.parking,
          siteId: action.siteId
        }
      };
    }

    case types.VEHICLE_PLANNING_SELECT_SECOND_DAY_FOR_CREATING_BBOKING: {
      return {
        ...state,
        secondDaySelected: {
          value: action.value,
          vehicle: action.vehicle,
          parking: action.parking,
          siteId: action.siteId
        }
      };
    }

    case types.VEHICLE_PLANNING_CLEAR_SELECTED_VEHICLE: {
      return {
        ...state,
        firstDaySelected: {},
        firstHourSelected: {}
      };
    }

    case types.VEHICLE_PLANNING_SELECT_FIRST_DAY_FOR_CREATING_BBOKING: {
      return {
        ...state,
        createBookingMode: true,
        firstDaySelected: {
          value: action.value,
          vehicle: action.vehicle,
          parking: action.parking,
          siteId: action.siteId
        }
      };
    }

    case types.BOOKING_FIND_VEHICLE_GET_LIST_REQUEST_SUCCESS: {
      return {
        ...state,
        availableVehicles: action.list.results,
        changeVehiclePayloadSearch: action.urlParams,
        loadingAvailableVehicles: false
      };
    }

    case types.BOOKING_FIND_VEHICLE_GET_LIST_REQUEST: {
      return {
        ...state,
        loadingAvailableVehicles: true
      };
    }

    case types.VEHICLE_PLANNING_GET_DATA_REQUEST: {
      return {
        ...state,
        loading: true
      };
    }

    case types.VEHICLE_PLANNING_GET_DATA_ERROR: {
      return {
        ...state,
        loadingError: true
      };
    }

    case types.VEHICLE_PLANNING_GET_DATA_SUCCESS: {
      const { vehiclesUsagesFiltered: filters } = state;
      const { data } = action;
      const { sites } = data || {};
      const usagesInit = getUsages(data);

      filterSites(sites, filters);

      return {
        ...state,
        loading: false,
        vehiclesUsagesInit: usagesInit,
        data: { ...data, sites: [...sites] }
      };
    }

    case types.VEHICLE_PLANNING_TOGGLE_FILTER: {
      const { data, vehiclesUsagesFiltered: filters } = state;
      const { sites } = data || {};
      const { usage } = action;

      if (filters.includes(usage)) {
        filters.splice(filters.indexOf(usage), 1);
      } else {
        filters.push(usage);
      }

      filterSites(sites, filters);

      return {
        ...state,
        vehiclesUsagesFiltered: [...filters],
        data: { ...data, sites: [...sites] }
      };
    }

    case types.VEHICLE_PLANNING_RESET_FILTERS: {
      const { vehiclesUsagesFiltered: filters, data } = state;
      const { sites } = data || {};
      const usagesInit = getUsages(data);

      filterSitesReset(sites);

      return {
        ...state,
        sites,
        vehiclesUsagesFiltered: usagesInit
      };
    }

    case types.VEHICLE_PLANNING_GET_BOOKING_REQUEST: {
      return {
        ...state,
        loadingBookingDetail: true
      };
    }

    case types.VEHICLE_PLANNING_GET_BOOKING_PRICE_REQUEST: {
      return {
        ...state,
        loadingBookingPrice: true
      };
    }

    case types.VEHICLE_PLANNING_GET_BOOKING_PRICE_SUCCESS: {
      return {
        ...state,
        bookingPrice: action.data,
        noprices: false,
        loadingBookingPrice: false
      };
    }

    case types.VEHICLE_PLANNING_GET_BOOKING_PRICE_SUCCESS_EMPTY: {
      return {
        ...state,
        bookingPrice: false,
        noprices: true,
        loadingBookingPrice: false
      };
    }

    case types.VEHICLE_PLANNING_GET_BOOKING_PRICE_ERROR:
    case types.VEHICLE_PLANNING_GET_BOOKING_PRICE_CLEAN: {
      return {
        ...state,
        bookingPrice: false,
        loadingBookingPrice: false
      };
    }

    case types.VEHICLE_PLANNING_CREATE_BOOKING_GET_PRICE: {
      return {
        ...state,
        bookingPrice: action.bookingPrice
      };
    }

    case types.VEHICLE_PLANNING_EDIT_BOOKING_GET_PRICE: {
      const carSharingInfo = _get(action, 'bookingPrice.carSharingInfo');
      return {
        ...state,
        bookingDetail: {
          ...state.bookingDetail,
          carSharingInfo: {
            ...state.bookingDetail.carSharingInfo,
            ...carSharingInfo
          }
        }
      };
    }

    case types.VEHICLE_PLANNING_CLEAR_DATA: {
      const vehiclesUsagesFiltered = state.vehiclesUsagesFiltered;
      const filters = { fuels: [], sites: [], usages: [], registrationNumber: null };
      const periodInDays =
        Number(window.localStorage.getItem('planningPeriodInDays')) === 0 ? 1 : Number(window.localStorage.getItem('planningPeriodInDays'));

      return {
        ...state,
        filters,
        ...vehiclesUsagesFiltered,
        ...periodInDays
      };
    }

    case types.VEHICLE_PLANNING_GET_BOOKING_SUCCESS: {
      return {
        ...state,
        bookingDetail: action.data,
        loadingBookingDetail: false
      };
    }

    case types.VEHICLE_PLANNING_GET_BOOKING_ERROR: {
      return {
        ...state,
        bookingDetail: false,
        loadingBookingDetail: false
      };
    }

    case types.VEHICLE_PLANNING_START_NEW_PRESUB: {
      return {
        ...state,
        preSubMemberReady: true,
        memberExist: '',
        preSubMember: action.data
      };
    }

    case types.VEHICLE_PLANNING_CONFIRM_PREBOOKING: {
      return {
        ...state,
        confirmPrebooking: true
      };
    }

    case types.VEHICLE_PLANNING_CONFIRM_PREBOOKING_CANCEL: {
      return {
        ...state,
        confirmPrebooking: false
      };
    }

    case types.VEHICLE_PLANNING_PROCEED_NEW_PRESUB: {
      return {
        ...state,
        proceedNew: action.create,
        preSubMember: {
          email: action.data
        }
      };
    }

    case types.VEHICLE_PLANNING_TOGGLE_EDIT_BOOKING_MODAL: {
      return {
        ...state,
        openedEditBookingModal: !state.openedEditBookingModal,
        editMemberChange: state.editMemberChange ? false : state.editMemberChange,
        selectedBookingId: state.openedEditBookingModal ? false : state.openedEditBookingModal
      };
    }

    case types.VEHICLE_PLANNING_TOGGLE_CHANGE_VEHICLE_MODAL: {
      return {
        ...state,
        openedChangeVehicleModal: !state.openedChangeVehicleModal,
        selectedBookingId: state.openedEditBookingModal ? false : state.openedEditBookingModal,
        availableVehicles: state.openedEditBookingModal ? [] : state.availableVehicles
      };
    }

    case types.VEHICLE_PLANNING_TOGGLE_CHANGE_MEMBER_MODAL: {
      return {
        ...state,
        openedMemberChangeModal: !state.openedMemberChangeModal,
        selectedBookingId: state.openedEditBookingModal ? false : state.openedEditBookingModal,
        persist: action.persist,
        skipConfirm: action.skipConfirm
      };
    }

    case types.VEHICLE_PLANNING_TOGGLE_EDIT_CHANGE_MEMBER: {
      return {
        ...state,
        editMemberChange: !state.editMemberChange
      };
    }

    case types.VEHICLE_PLANNING_CHANGE_SETTINGS: {
      return {
        ...state,
        ...action.data
      };
    }

    case types.VEHICLE_PLANNING_SET_FOUND_USERS: {
      return {
        ...state,
        foundUsers: action.data
      };
    }

    case types.VEHICLE_PLANNING_CLEAR_FOUND_USERS: {
      return {
        ...state,
        foundUsers: [],
        memberExist: ''
      };
    }

    case types.VEHICLE_PLANNING_CLEAR_EXIST_MEMBER_MESSAGE: {
      return {
        ...state,
        memberExist: '',
        preSubMemberReady: false
      };
    }
    case types.VEHICLE_PLANNING_TOGGLE_LEGEND: {
      return {
        ...state,
        legendShown: !state.legendShown
      };
    }

    case types.VEHICLE_PLANNING_ADD_FILTER: {
      const { filters } = state;
      const { fuels, usages, sites } = filters || [];
      const filterKey = Object.keys(action.filter)[0];
      const filterVal = Object.values(action.filter)[0];

      if (filterKey === 'fuelType') {
        if (!filters.fuels.includes(filterVal)) filters.fuels = [...fuels, filterVal];
      }

      if (filterKey === 'usages') {
        if (!filters.usages.includes(filterVal)) filters.usages = [...usages, filterVal];
      }
      if (filterKey === 'sites') {
        if (!filters.sites.includes(filterVal)) filters.sites = [...sites, filterVal];
      }

      if (filterKey === 'registrationNumber') {
        filters.registrationNumber = filterVal;
      }

      return {
        ...state,
        filters: {
          ...filters
        }
      };
    }

    case types.VEHICLE_PLANNING_EDIT_FILTERS: {
      const { filters } = state;
      const { values } = action;
      const { fuels, usages, sites } = values || [];
      const { registrationNumber } = values || '';

      if (!fuels) filters.fuels = [];
      else filters.fuels = [...fuels];

      if (!usages) filters.usages = [];
      else filters.usages = [...usages];

      if (!sites) filters.sites = [];
      else filters.sites = [...sites];

      if (!values.hasOwnProperty('registrationNumber')) {
        filters.registrationNumber = null;
      } else filters.registrationNumber = registrationNumber;

      return {
        ...state,
        filters: {
          ...filters
        }
      };
    }

    case types.VEHICLE_PLANNING_REMOVE_FILTER: {
      const { item } = action;
      const { filters } = state;

      if (typeof item === 'string') {
        filters[item] = item === 'registrationNumber' ? null : [];
      } else {
        const { param, key } = item;
        filters[param].splice(key, 1);
      }
      return {
        ...state,
        filters: {
          ...filters
        }
      };
    }

    case types.VEHICLE_PLANNING_RESET_FILTERSv2: {
      const { filters } = state;

      filters.fuels = [];
      filters.usages = [];
      filters.registrationNumber = null;

      return {
        ...state,
        filters: {
          ...filters
        }
      };
    }

    case types.VEHICLE_PLANNING_TOGGLE_CARD: {
      if (state.openedCard) {
        return {
          ...state,
          openedCard: !state.openedCard,
          selectedBooking: false,
          selectedVehicle: false,
          selectedMember: false
        };
      }
      if (action.member) {
        return {
          ...state,
          openedCard: !state.openedCard,
          selectedBookingId: action.booking.id,
          selectedBooking: action.booking,
          selectedVehicle: action.vehicle,
          selectedMember: action.member
        };
      } else
        return {
          ...state,
          openedCard: !state.openedCard,
          selectedBookingId: action.booking.id,
          selectedBooking: action.booking,
          selectedVehicle: action.vehicle
        };
    }

    default:
      return state;
  }
}
