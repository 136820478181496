import * as types from '../constants/actionTypes-constants';
import { safe } from '../utils/utils';
import { STORAGE_KEY } from '../constants/generic-constants';

export default function api(
  state = {
    paybackRedirectSpinStarted: false,
    afterPaybackRedirect: false,
    redirectLocation: safe(() => sessionStorage.getItem(STORAGE_KEY.REDIRECT_LOCATION)) || '',
    delayedAction: null,
    callsCount: 0,
    token: null
  },
  action
) {
  switch (action.type) {
    case types.SAVE_REDIRECT_LOCATION: {
      const hashRoute = safe(() => window.location.hash.split('#')[1]);
      const currentRoute = safe(() => hashRoute.split('/')[1]);
      if (currentRoute && currentRoute !== 'login') {
        sessionStorage.setItem(STORAGE_KEY.REDIRECT_LOCATION, hashRoute);
        return { ...state, redirectLocation: hashRoute };
      }

      return state;
    }

    case types.CLEAR_REDIRECT_LOCATION: {
      sessionStorage.removeItem(STORAGE_KEY.REDIRECT_LOCATION);
      sessionStorage.removeItem(STORAGE_KEY.REDIRECT_NOTIFIED);
      return { ...state, redirectLocation: '' };
    }

    case types.API_CALL_START: {
      return Object.assign({}, state, {
        callsCount: state.callsCount + 1
      });
    }

    case types.API_CALL_END: {
      return Object.assign({}, state, {
        callsCount: state.callsCount - 1
      });
    }

    case types.API_SET_TOKEN_REQUEST: {
      return Object.assign({}, state, {
        token: action.token
      });
    }

    case types.API_UNSET_TOKEN_REQUEST: {
      return Object.assign({}, state, {
        token: null
      });
    }

    case types.AFTER_PAYBACK_REDIRECT_TRUE: {
      return {
        ...state,
        afterPaybackRedirect: true
      };
    }

    case types.AFTER_PAYBACK_REDIRECT_FALSE: {
      return {
        ...state,
        afterPaybackRedirect: false
      };
    }

    case types.AFTER_PAYBACK_REDIRECT_ACTION: {
      return {
        ...state,
        delayedAction: {
          delayedAction: action.delayedAction,
          delayedType: action.delayedActionType
        }
      };
    }

    case types.START_PAYBACK_REDIRECT_SPIN: {
      return { ...state, callsCount: state.callsCount + 1, paybackRedirectSpinStarted: true };
    }

    case types.STOP_PAYBACK_REDIRECT_SPIN: {
      if (state.paybackRedirectSpinStarted) {
        return {
          ...state,
          callsCount: state.callsCount - 1,
          paybackRedirectSpinStarted: false
        };
      }
      return state;
    }

    default:
      return state;
  }
}
