import React, { Component } from 'react';
import { showParentView } from '../../../../../components/_v2/ColumnList/helpers';
import AddParkingModal from '../../../../../containers/AddParking/Modal';
import { safe } from '../../../../../utils/utils';

export class OrganizationAddParking extends Component {
  componentWillMount() {
    this.showParentView = showParentView.bind(this);
  }

  render() {
    return <AddParkingModal isOpen onClose={this.showParentView} siteId={safe(() => this.props.params.siteId)} />;
  }
}

export default OrganizationAddParking;
