import React, { Component } from 'react';
import { connect } from 'react-redux';
import { routeActions } from 'react-router-redux';
import { getStore } from '../../store/all-store';

import TableView from '../../components/TableView/TableView';
import ModelForm from '../../components/ModelForm/ModelForm';
import PageManagementHeader from '../../components/PageManagementHeader/PageManagementHeader';
import { updateModelRequest } from '../../actions/all-actions';
import routes from '../../constants/routes-constants';
import { safe } from '../../utils/utils';

const tableViewParams = {
  namespace: 'version',
  sortIsDescending: false,
  columns: [
    {
      messageKey: 'version_tableView_label_name',
      content: 'name'
    },
    {
      messageKey: 'version_tableView_label_action',
      content: null,
      contentMessageKey: 'version_tableView_content_action',
      actionCustomClass: 'version_tableView_action',
      actionCallback: function(item) {
        getStore().dispatch(routeActions.push(routes.editVersion.path.replace(':versionId', item.id)));
      }
    }
  ]
};

class EditModel extends Component {
  componentWillMount() {
    this.handleUpdateModel = ({ name }) => {
      const params = {
        brandId: safe(() => this.props.model.brand.id),
        id: this.props.model.id,
        name
      };

      this.props.dispatch(updateModelRequest(params));
    };
  }

  render() {
    const { model, list } = this.props;

    return (
      <div className="editModelPage mainContainer_content">
        <div className="pageContainer">
          <div className="editModelPage_content">
            <section className="editModelPage_management">
              <PageManagementHeader
                title={model.name}
                href={`/#${routes.addVersion.path.replace(':modelId', model.id)}`}
                buttonLabelKey="editModel_add"
              />
              <ModelForm
                onCallback={this.handleUpdateModel}
                initialValues={{
                  name: model.name
                }}
              />
              {!!safe(() => list.length) && (
                <TableView customClass="editModelPage_tableViewWrapper" params={tableViewParams} content={list} />
              )}
            </section>
          </div>
        </div>
      </div>
    );
  }
}

EditModel.displayName = 'EditModel';

export default connect(state => {
  const {
    models: { currentModel: model },
    versions: { list }
  } = state;

  return { model, list };
})(EditModel);
