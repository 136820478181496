import React, { Component, PropTypes } from 'react';
import { FormattedMessage } from 'react-intl';
import { reduxForm } from 'redux-form';
import _find from 'lodash/find';
import _get from 'lodash/get';
import _has from 'lodash/has';
import _filter from 'lodash/filter';
import _omit from 'lodash/omitBy';
import _cloneDeep from 'lodash/cloneDeep';
import _isUndefined from 'lodash/isUndefined';
import _isNull from 'lodash/isNull';
import memoizeOne from 'memoize-one';
import cs from 'classnames';
import { connect } from 'react-redux';

import {
  saveCustomField,
  updateCustomField,
  unpickBookingCustomField,
  unpickSubscriptionCustomField
} from '../../actions/customFields-actions';
import { createValidator, minimum, maximum, notEmpty, stopValidationIf, excludeSpecialChars } from '../../validation/sync-validation';
import { pickedFieldSelector } from '../../selectors/all-selectors';
import { getShortId, safe, scrollToFirstError } from '../../utils/utils';
import {
  customFieldTypes,
  booleanOptionsCF,
  booleanOptions,
  threeValuesOptions,
  bookingUsageTypes,
  vehicleUsage
} from '../../constants/options-constants';
import {
  CUSTOM_FIELD_TYPE_TEXT,
  CUSTOM_FIELD_TYPE_NUMERIC,
  CUSTOM_FIELD_FORM_SUBSCRIPTION,
  CUSTOM_FIELD_FORM_BOOKING,
  CUSTOM_FIELD_CONDITIONED
} from '../../constants/backend-constants';

import ListSelect from '../ListSelect/ListSelect';
import FormField from '../FormField';
import ButtonWrapper from '../ButtonWrapper/ButtonWrapper';
import EkButton from '../EkButton/EkButton';
import FormSection from '../FormSection';
import config from '../../constants/config-constants';
import { getMsg } from '../../utils/IntlGlobalProvider';

const textOrNumberVisibilityFilter = ({ value }) => value === CUSTOM_FIELD_TYPE_NUMERIC || value === CUSTOM_FIELD_TYPE_TEXT;
const conditionedField2 = ({ value, formType }) => {
  return value.value === CUSTOM_FIELD_CONDITIONED && formType === CUSTOM_FIELD_FORM_SUBSCRIPTION;
};
const normalizeMax = (value, previousValue, allValues, previousAllValues) => {
  if (allValues.min !== previousAllValues.min) {
    // min changed
    if (value === undefined || Number(allValues.min) > Number(value)) {
      return allValues.min;
    }
  }
  return value;
};
const normalizeMin = (value, previousValue, allValues, previousAllValues) => {
  if (allValues.max !== previousAllValues.max) {
    // max changed
    if (value === undefined || Number(allValues.max) < Number(value)) {
      return allValues.max;
    }
  }
  return value;
};

const addLabel = (value, labelKey) => ({
  labelKey,
  value
});

const filterUsages = memoizeOne(usages => {
  usages = usages || [];
  const selected = [];
  const available = [];

  for (const key in vehicleUsage) {
    const option = addLabel(key, vehicleUsage[key]);
    let found;

    usages = usages.filter(usage => {
      const equal = usage === key;
      if (equal) found = true;
      return !equal;
    });

    if (found) selected.push(option);
    else available.push(option);
  }

  return { selected, available };
});

class CustomFieldForm extends Component {
  constructor(props) {
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleAddToSelected = this.handleAddToSelected.bind(this);
    this.handleAddToAvailable = this.handleAddToAvailable.bind(this);
  }

  handleSubmit() {
    const { values, dispatch, formType } = this.props;
    const valuesCopy = _cloneDeep(values);
    let cleanFilter = _omit(valuesCopy, _isUndefined);
    cleanFilter = _omit(cleanFilter, _isNull);
    cleanFilter.min = parseInt(cleanFilter.min);
    cleanFilter.max = parseInt(cleanFilter.max);
    if (!cleanFilter.min) delete cleanFilter.min;
    if (!cleanFilter.max) delete cleanFilter.max;
    cleanFilter.form = formType;

    dispatch(_has(cleanFilter, 'id') ? updateCustomField(cleanFilter) : saveCustomField(cleanFilter));
  }

  handleCancel() {
    const { dispatch, formType } = this.props;
    dispatch(formType === CUSTOM_FIELD_FORM_SUBSCRIPTION ? unpickSubscriptionCustomField() : unpickBookingCustomField());
  }

  handleAddToAvailable(data) {
    const { vehicleUsages } = this.props.fields;

    vehicleUsages.onChange(
      safe(() =>
        vehicleUsages.value.filter(value => {
          let found;

          data = data.filter(item => {
            const equal = item === value;
            if (equal) found = true;
            return !equal;
          });

          return !found;
        })
      ) || []
    );
  }

  handleAddToSelected(data) {
    const { vehicleUsages } = this.props.fields;

    safe(() =>
      vehicleUsages.value.forEach(item => {
        data = data.filter(value => value !== item);
      })
    );

    vehicleUsages.onChange(safe(() => [...vehicleUsages.value, ...data]) || []);
  }

  render() {
    const {
      fields: {
        fieldType,
        mandatory,
        mandatoryConditionDependsOnPosition,
        mandatoryConditionExpectedValueForPosition,
        min,
        name,
        max,
        id,
        position,
        companyCustomFieldLabels,
        visible,
        visibleConditionDependsOnPosition,
        visibleConditionExpectedValueForPosition,
        usageType,
        uniqueValues,
        vehicleUsages
      },
      formType,
      handleSubmit,
      customFields
    } = this.props;

    const opts = _filter(customFields.customFields.SUBSCRIPTION, { fieldType: 'BOOLEAN' }).map(item => {
      return { label: item.position, value: item.position };
    });

    const filteredUsages = filterUsages(vehicleUsages.value);

    return (
      <div className="customFieldCreation">
        <h4 className="companyDetail_title">
          <FormattedMessage id={id.value ? 'customFieldTitle_update' : 'customFieldTitle_add'} />
          {id.value && <span className="companyDetail_title_id"> ({getShortId(id.value)})</span>}
        </h4>
        <form onSubmit={handleSubmit(this.handleSubmit)}>
          <FormSection titleKey="common_settings">
            <FormField labelKey="customFieldLabel_name" type="text" field={name} maxlength="255" />
            <FormField labelKey="customFieldLabel_type" type="select" options={customFieldTypes} field={fieldType} />
            {formType === CUSTOM_FIELD_FORM_BOOKING && (
              <FormField
                labelKey="booking_create_find_vehicle_tableView_booking_type"
                type="select"
                options={bookingUsageTypes}
                field={usageType}
              />
            )}
            {textOrNumberVisibilityFilter(fieldType) && <FormField labelKey="common_minimum" type="number" field={min} />}
            {textOrNumberVisibilityFilter(fieldType) && <FormField labelKey="common_maximum" type="number" field={max} />}
            {!id.value && <FormField labelKey="customFieldLabel_position" type="number" field={position} />}
            <FormField
              labelKey="customFieldLabel_mandatory"
              type="select"
              options={formType === CUSTOM_FIELD_FORM_SUBSCRIPTION ? threeValuesOptions : booleanOptionsCF}
              field={mandatory}
            />

            {conditionedField2({ value: mandatory, formType }) && (
              <span>
                <FormField
                  labelKey="common_conditioned_field_position"
                  type="select"
                  options={opts}
                  field={mandatoryConditionDependsOnPosition}
                />
                <FormField
                  labelKey="common_conditionned_field_value_expected"
                  type="select"
                  options={booleanOptions}
                  field={mandatoryConditionExpectedValueForPosition}
                />
              </span>
            )}

            <FormField
              labelKey="customFieldLabel_visible"
              options={formType === CUSTOM_FIELD_FORM_SUBSCRIPTION ? threeValuesOptions : booleanOptionsCF}
              type="select"
              field={visible}
            />
            {formType === CUSTOM_FIELD_FORM_SUBSCRIPTION && (
              <FormField labelKey="customFieldLabel_Unicity" options={booleanOptions} type="select" field={uniqueValues} />
            )}
            {conditionedField2({ value: visible, formType }) && (
              <span>
                <FormField
                  labelKey="common_conditioned_field_position"
                  type="select"
                  options={opts}
                  field={visibleConditionDependsOnPosition}
                />
                <FormField
                  labelKey="common_conditionned_field_value_expected"
                  type="select"
                  options={booleanOptions}
                  field={visibleConditionExpectedValueForPosition}
                />
              </span>
            )}
          </FormSection>
          <FormSection titleKey="vehicle_detail_title_usage">
            {formType === CUSTOM_FIELD_FORM_BOOKING && (
              <div className={cs('formFieldWrapper', 'vehicle-usages')}>
                <ListSelect
                  listOneLabel={getMsg('common_available')}
                  listOneOptions={filteredUsages.available}
                  onAddToListOne={this.handleAddToAvailable}
                  listTwoLabel={getMsg('common_selected')}
                  listTwoOptions={filteredUsages.selected}
                  onAddToListTwo={this.handleAddToSelected}
                />
              </div>
            )}
          </FormSection>
          <FormSection titleKey="customFieldSection_translations">
            {config.theme.availableLanguages.map(language => {
              const field = _find(companyCustomFieldLabels, field => field.language.value === language);
              return (
                field && <FormField key={language} labelKey={`languageName_${language}`} type="text" field={field.label} maxlength="255" />
              );
            })}
          </FormSection>
          <ButtonWrapper>
            {id.value && (
              <EkButton skinType="reverse" customClass="buttonCancel" onAction={this.handleCancel}>
                <FormattedMessage id={'common_cancel'} />
              </EkButton>
            )}
            <EkButton onAction={handleSubmit(this.handleSubmit)}>
              <FormattedMessage id={id.value ? 'common_update' : 'common_add'} />
            </EkButton>
          </ButtonWrapper>
        </form>
      </div>
    );
  }
}

CustomFieldForm.propTypes = {
  dispatch: PropTypes.func,
  fields: PropTypes.object,
  values: PropTypes.object,
  handleSubmit: PropTypes.func
};

CustomFieldForm.displayName = 'CustomFieldForm';

// CustomFieldForm.normalized
CustomFieldForm.normalizedInputs = {
  min: normalizeMin,
  max: normalizeMax
};

CustomFieldForm = reduxForm({
  onSubmitFail: scrollToFirstError,
  form: 'customField',
  fields: [
    'fieldType',
    'mandatory',
    'mandatoryConditionDependsOnPosition',
    'mandatoryConditionExpectedValueForPosition',
    'usageType',
    'id',
    'min',
    'max',
    'name',
    'position',
    'uniqueValues',
    'vehicleUsages',
    'companyCustomFieldLabels[].label',
    'companyCustomFieldLabels[].language',
    'companyCustomFieldLabels[].id',
    'visible',
    'visibleConditionDependsOnPosition',
    'visibleConditionExpectedValueForPosition'
  ],
  validate: createValidator({
    name: [notEmpty(), excludeSpecialChars(',;<>/\\')],
    fieldType: [notEmpty()],
    min: [
      stopValidationIf({
        condition: props => {
          return _get(props, 'form.fieldType.value') !== CUSTOM_FIELD_TYPE_TEXT;
        }
      }),
      minimum(0),
      maximum(255)
    ],
    max: [
      stopValidationIf({
        condition: props => {
          return _get(props, 'form.fieldType.value') !== CUSTOM_FIELD_TYPE_TEXT;
        }
      }),
      minimum(0),
      maximum(255)
    ],
    position: [notEmpty(), minimum(1)],
    mandatoryConditionDependsOnPosition: [
      stopValidationIf({
        condition: props => {
          return _get(props, 'form.mandatory.value') !== CUSTOM_FIELD_CONDITIONED;
        }
      }),
      notEmpty()
    ],
    mandatoryConditionExpectedValueForPosition: [
      stopValidationIf({
        condition: props => {
          return _get(props, 'form.mandatory.value') !== CUSTOM_FIELD_CONDITIONED;
        }
      }),
      notEmpty()
    ],
    visibleConditionDependsOnPosition: [
      stopValidationIf({
        condition: props => {
          return _get(props, 'form.visible.value') !== CUSTOM_FIELD_CONDITIONED;
        }
      }),
      notEmpty()
    ],
    visibleConditionExpectedValueForPosition: [
      stopValidationIf({
        condition: props => {
          return _get(props, 'form.visible.value') !== CUSTOM_FIELD_CONDITIONED;
        }
      }),
      notEmpty()
    ],
    usageType: [
      stopValidationIf({
        condition: props => {
          return _get(props, 'formType') === CUSTOM_FIELD_FORM_SUBSCRIPTION;
        }
      }),
      notEmpty()
    ]
  })
})(CustomFieldForm);

export default connect((state, ownProps) => {
  const currentField = pickedFieldSelector(state, ownProps);
  const languages = config.theme.availableLanguages.map(language => {
    const existingLabel = _find(_get(currentField, 'companyCustomFieldLabels'), labelInfo => labelInfo.language === language) || null;
    return {
      id: _get(existingLabel, 'id'),
      label: _get(existingLabel, 'label', ''),
      language
    };
  });

  return {
    initialValues: {
      ...currentField,
      companyCustomFieldLabels: languages
    },
    customFields: _get(state, 'customFields')
  };
})(CustomFieldForm);
