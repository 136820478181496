import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import _get from 'lodash/get';
import { routeActions } from 'react-router-redux';
import { injectIntl } from 'react-intl';
import AdvancedList from '../../components/AdvancedList/AdvancedList';
import routes from '../../constants/routes-constants';
import {
  addOpenNewTabEvents,
  datesToString,
  getAppFormattedDateTime,
  hideButtonHoverMsg,
  namedCompose,
  openUrlBackgroundTab
} from '../../utils/utils';
import autoBind from 'react-autobind';
import { apiParams } from '../../constants/api-params-constants';
import { getBankoutList, clearBankoutResults } from '../../actions/bankout-actions';
import { FormattedMessage } from 'react-intl';
import { bankoutListChips, bankoutStatusKeys, billingEntityTypeKeys } from '../../constants/options-constants';
import {
  SORT_PROPERTY_BANKOUT_EXECUTION_DATE,
  SORT_PROPERTY_BANKOUT_STATUS,
  SORT_PROPERTY_BILLING_ENTITY_NAME,
  SORT_PROPERTY_BILLING_ENTITY_TYPE
} from '../../constants/backend-constants';
import FilterChips from '../../components/FilterChips/FilterChips';
import { bankoutListFilterTypes } from '../../constants/filterTypes-constants';
import Filters from '../../components/AdvancedList/components/Filters';
import _omit from 'lodash/omit';
import { isSubCompanyLevelUserSelector, userRoleSelector, validSubCompanyIdSelector } from '../../selectors/all-selectors';

class BankoutList extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.colWidth = 3;
    this.bankoutHeader = [
      {
        labelKey: 'bankout_execution_date',
        md: this.colWidth,
        xs: this.colWidth,
        sortId: SORT_PROPERTY_BANKOUT_EXECUTION_DATE,
        sortable: true
      },
      {
        labelKey: 'billing_entity',
        md: this.colWidth,
        xs: this.colWidth,
        sortId: SORT_PROPERTY_BILLING_ENTITY_NAME,
        sortable: true
      },
      {
        labelKey: 'billing_entity_type',
        md: this.colWidth,
        xs: this.colWidth,
        sortId: SORT_PROPERTY_BILLING_ENTITY_TYPE,
        sortable: true
      },
      {
        labelKey: 'bankout_amount',
        md: this.colWidth,
        xs: this.colWidth,
        sortable: false
      },
      {
        labelKey: 'common_status',
        md: this.colWidth,
        xs: this.colWidth,
        sortId: SORT_PROPERTY_BANKOUT_STATUS,
        sortable: true
      }
    ];
  }

  componentWillMount() {
    this.callApi(this.props.pageParams);
  }

  componentWillUnmount() {
    this.props.dispatch(clearBankoutResults());
    hideButtonHoverMsg();
  }

  callApi(params) {
    const validDates = datesToString(params);
    this.props.dispatch(getBankoutList(validDates));
  }

  goToDetail(bankoutId, openInNewTab) {
    let route = routes.bankoutDetails.path.replace(':bankoutId', bankoutId);
    const params = { ...apiParams.default, ...apiParams.paybackList };
    route = route.replace(':search', encodeURIComponent(JSON.stringify(params)));

    if (openInNewTab) {
      return openUrlBackgroundTab('/#' + route);
    }
    this.props.dispatch(routeActions.push(route));
  }

  handleChangePage(value) {
    const { listMetadata, urlParams } = this.props;

    const params = {
      page: {
        number: value || apiParams.default.page.number,
        size: _get(listMetadata, 'paginationInfo.pageSize') || apiParams.default.page.size
      }
    };

    const newParams = { ...urlParams, ...params };

    this.callApi(newParams);
    this.updateUrlParams(newParams);
  }

  handleRefreshList() {
    this.callApi(this.props.pageParams);
  }

  handleSort(sortPropertyName) {
    const { urlParams } = this.props;
    const findSortProp = _get(urlParams, 'sort.property', false) === sortPropertyName;
    const newProp = !_get(urlParams, 'sort.isDescending', false);

    const newParams = {
      ...urlParams,
      sort: {
        isDescending: findSortProp ? newProp : false,
        property: sortPropertyName
      }
    };

    this.callApi(newParams);
    this.updateUrlParams(newParams);
  }

  updateUrlParams(params) {
    const validDates = datesToString(params);
    const encodedParams = encodeURIComponent(JSON.stringify(validDates));
    this.props.dispatch(routeActions.push(routes.bankouts.path.replace(':search', encodedParams)));
  }

  handleNumberResultsSelect(value) {
    const { urlParams } = this.props;

    const params = {
      page: {
        number: 1,
        size: parseInt(value)
      }
    };

    const newParams = { ...urlParams, ...params };

    this.callApi(newParams);
    this.updateUrlParams(newParams);
  }

  handleFilterSearch(newSelectedFilters) {
    const { listMetadata, urlParams } = this.props;
    const { paginationInfo } = listMetadata;
    const { pageSize } = paginationInfo;

    const newParams = {
      ...urlParams,
      ...newSelectedFilters,
      page: {
        number: 1,
        size: pageSize
      }
    };

    this.callApi(newParams);
    this.updateUrlParams(newParams);
  }

  // noinspection DuplicatedCode
  handleDeleteChip(item) {
    const { urlParams, listMetadata } = this.props;
    const { paginationInfo } = listMetadata || {};
    const { pageSize } = paginationInfo || {};
    const params = _omit(urlParams, item);
    const defSize = apiParams.default.page.size;

    const newParams = {
      ...params,
      page: {
        number: 1,
        size: pageSize || defSize
      }
    };

    this.callApi(newParams);
    this.updateUrlParams(newParams);
  }

  renderItem(item, id, reactKey) {
    const bankoutId = _get(item, 'bankoutId');
    const entityName = _get(item, 'billingEntityName');
    const billingEntityType = _get(item, 'billingEntityType');
    const bankoutExecutionDate = _get(item, 'executionDate');
    const bankoutAmount = _get(item, 'amount');
    const bankoutStatus = _get(item, 'status');

    const unknownKey = 'common_unknown';
    const colProps = { xs: this.colWidth, md: this.colWidth };
    const statusClassName = 'bankout-status-' + bankoutStatus;
    const callback = openInNewTab => this.goToDetail(bankoutId, openInNewTab);
    const formattedDate = getAppFormattedDateTime(bankoutExecutionDate, { local: true });

    const statusKey = bankoutStatusKeys[bankoutStatus] || unknownKey;
    const billingEntityTypeKey = billingEntityTypeKeys[billingEntityType] || unknownKey;

    return (
      <Row className="advancedList_row" {...addOpenNewTabEvents(callback)} id={id} key={reactKey}>
        <Col {...colProps}>{formattedDate}</Col>
        <Col {...colProps}>{entityName}</Col>
        <Col {...colProps}>
          <FormattedMessage id={billingEntityTypeKey} />
        </Col>
        <Col {...colProps}>{bankoutAmount}</Col>
        <Col {...colProps}>
          <span className={statusClassName}>
            <FormattedMessage id={statusKey} />
          </span>
        </Col>
      </Row>
    );
  }

  render() {
    if (this.props.subCompanyLevelUser && !this.props.headerSubCompanyId) {
      return (
        <div className="pageContainer">
          <div className="page-info-block">
            <FormattedMessage id="vehiclePlanning_selectCompany" />
          </div>
        </div>
      );
    }

    return (
      <div className="pageContainer">
        <div className="bankoutList">
          <FilterChips
            id="bankoutList"
            urlParams={this.props.urlParams}
            chipClass="v2-filter-chip"
            onDeleteChip={this.handleDeleteChip}
            translations={bankoutListChips}
            filterTypes={bankoutListFilterTypes}
          />
          <div className="advancedList_actions page">
            <Filters id="bankoutListFilters" filterTypes={bankoutListFilterTypes} onFilteredSearchForm={this.handleFilterSearch} />
          </div>
          <AdvancedList
            id="bankouts"
            data={this.props.list}
            listMetadata={this.props.listMetadata}
            loading={this.props.loadingList}
            error={this.props.loadingError}
            onRefreshList={this.handleRefreshList}
            onChangeRowsPerPage={this.handleNumberResultsSelect}
            onChangePage={this.handleChangePage}
            renderItem={this.renderItem}
            noResultsLabelKey="bankouts_no_results"
            header={this.bankoutHeader}
            onSort={this.handleSort}
            urlParams={this.props.urlParams}
          />
        </div>
      </div>
    );
  }
}

export default namedCompose(
  connect(state => {
    const bankouts = _get(state, 'bankouts');
    const paginatedResults = _get(bankouts, 'bankoutResults');
    const page = _get(state, 'page');
    const listMetadata = _get(paginatedResults, 'metadata');

    return {
      subCompanyLevelUser: isSubCompanyLevelUserSelector(state),
      userRole: userRoleSelector(state),
      headerSubCompanyId: validSubCompanyIdSelector(state),
      companyId: _get(state, 'companies.currentCompany.id'),
      list: _get(paginatedResults, 'results', []),
      pageParams: _get(page, 'params'),
      urlParams: _get(bankouts, 'bankoutParams'),
      loadingList: _get(bankouts, 'loadingList'),
      loadingError: _get(bankouts, 'loadingError', false),
      listMetadata
    };
  }),
  injectIntl
)(BankoutList);
