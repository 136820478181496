import React, { Component } from 'react';
import { connect } from 'react-redux';
import VehiclesColorForm from '../../components/VehiclesColorForm/VehiclesColorForm';
import { updateColorRequest } from '../../actions/all-actions';
import { BACKUSER_ROLE_CALL_CENTER_OPERATOR } from '../../constants/backend-constants';

class EditVehiclesColor extends Component {
  componentWillMount() {
    this.handleUpdateColor = data => {
      this.props.dispatch(updateColorRequest({ id: this.props.currentColor.id, ...data }));
    };
  }

  render() {
    const { currentColor, role } = this.props;

    return (
      <div className="editVehiclesColorPage mainContainer_content">
        <div className="pageContainer">
          <div className="editVehiclesColorPage_content">
            <section className="editVehiclesColorPage_management">
              <VehiclesColorForm
                onCallback={this.handleUpdateColor}
                initialValues={{
                  code: currentColor.code
                }}
                limitedAccess={role === BACKUSER_ROLE_CALL_CENTER_OPERATOR}
              />
            </section>
          </div>
        </div>
      </div>
    );
  }
}

EditVehiclesColor.displayName = 'EditVehiclesColor';

export default connect(state => {
  const {
    user: {
      userInfo: { role }
    },
    colors: { currentColor }
  } = state;

  return {
    currentColor,
    role
  };
})(EditVehiclesColor);
