import * as types from '../constants/actionTypes-constants';
import { safe } from '../utils/utils';

export default function models(
  state = {
    list: [],
    loading: false,
    latestCreatedModel: null,
    currentModel: {}
  },
  action
) {
  switch (action.type) {
    case types.MODELS_GET_LIST_REQUEST: {
      return { ...state, loading: true };
    }

    case types.MODELS_GET_LIST_SUCCESS: {
      return { ...state, list: action.list, loading: false };
    }

    case types.MODELS_GET_LIST_ERROR: {
      return { ...state, loading: false };
    }

    case types.MODELS_GET_LIST_EMPTY:
      return Object.assign({}, state, {
        list: []
      });

    case types.MODELS_CREATE_MODEL_SUCCESS: {
      return {
        ...state,
        latestCreatedModel: action.model,
        list: [{ ...action.model }, ...state.list]
      };
    }

    case types.MODELS_GET_SINGLE_MODEL_SUCCESS:
      return Object.assign({}, state, {
        currentModel: action.model
      });

    case types.MODELS_UPDATE_MODEL_SUCCESS: {
      const { model } = action;
      const { list } = state;

      safe(() => (list.find(item => item.id === model.id).name = model.name));

      return {
        ...state,
        currentModel: model,
        list: safe(() => [...list]) || []
      };
    }

    default:
      return state;
  }
}
