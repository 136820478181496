import React, { Component, PropTypes } from 'react';
import { FormattedMessage } from 'react-intl';
import { reduxForm } from 'redux-form';

import FormRow from '../FormRow/FormRow';
import BoxedInput from '../BoxedInput/BoxedInput';
import EkButton from '../EkButton/EkButton';
import FieldErrorMsg from '../FieldErrorMsg/FieldErrorMsg';
import { createValidator, notEmpty } from '../../validation/all-validation';
import routes from '../../constants/routes-constants';
import BackLink from '../BackLink/BackLink';
import { scrollToFirstError } from '../../utils/utils';

class VehiclesColorForm extends Component {
  constructor() {
    super();
  }

  render() {
    const {
      fields: { code },
      handleSubmit,
      limitedAccess
    } = this.props;

    return (
      <section className="vehiclesColorFormWrapper">
        <BackLink link={`/#${routes.vehiclesColors.path}`} labelKey="back_link_colors_list" />
        <form action="#" className="vehiclesColorForm" onSubmit={handleSubmit(this.props.onCallback)}>
          <div className="vehiclesColorForm_container">
            <h4 className="vehiclesColorForm_title">
              <FormattedMessage id="vehiclesColorForm_title" />
            </h4>

            <FormRow customClass="vehiclesColorForm_row">
              <BoxedInput
                formRowItemKey="vehiclesColorForm_submitBtn"
                type="text"
                id="name"
                labelKey="vehiclesColorForm_name_label"
                customClass="vehiclesColorForm_boxedInput"
                field={code}
                disabled={limitedAccess}
              >
                <FieldErrorMsg field={code} customClass="fieldErrorMsg--vehiclesColorForm" />
              </BoxedInput>
            </FormRow>
          </div>

          <div className="vehiclesColorForm_actions">
            <a href={`/#${routes.vehiclesColors.path}`} className="ekButton ekButton--reverse vehiclesColorForm_actionsLink">
              <FormattedMessage id="vehiclesColorForm_cancel_button" />
            </a>
            <EkButton customClass="vehiclesColorForm_actionsButton" onAction={handleSubmit(this.props.onCallback)} disabled={limitedAccess}>
              <FormattedMessage id="vehiclesColorForm_save_button" />
            </EkButton>
          </div>
        </form>
      </section>
    );
  }
}

VehiclesColorForm.displayName = 'VehiclesColorForm';

VehiclesColorForm.propTypes = {
  fields: PropTypes.object,
  handleSubmit: PropTypes.func,
  onCallback: PropTypes.func,
  limitedAcces: PropTypes.bool
};

VehiclesColorForm = reduxForm({
  onSubmitFail: scrollToFirstError,
  form: 'vehiclesColorForm',
  fields: ['code'],
  validate: createValidator({
    code: [notEmpty()]
  })
})(VehiclesColorForm);

export default VehiclesColorForm;
