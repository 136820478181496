import { UPDATE_LOCATION } from 'react-router-redux';
import shortid from 'shortid';
import _get from 'lodash/get';

import * as types from '../constants/actionTypes-constants';
import { FLASH_MESSAGE_TYPE_INFO } from '../constants/generic-constants';

const defaultMessage = {
  contentKey: null,
  contentData: {},
  type: FLASH_MESSAGE_TYPE_INFO,
  params: {},
  content: null,
  delayToNextRoute: false,
  numberOfRoutes: 1
};

export default function flashMessage(
  state = {
    list: [],
    nextRouteList: []
  },
  action
) {
  switch (action.type) {
    case types.FLASH_MESSAGE_ADD: {
      if (!_get(action, 'message.contentKey') && !_get(action, 'message.content')) {
        throw new Error("FlashMessage requires a 'contentKey' or 'content' to display a message.");
      }

      const message = Object.assign({}, defaultMessage, { _uid: shortid.generate() }, action.message, { type: action.message.type });

      if (message.delayToNextRoute) {
        return Object.assign({}, state, {
          nextRouteList: [...state.nextRouteList.slice(0, state.nextRouteList.length), message],
          numberOfRoutes: message.numberOfRoutes || 1
        });
      } else {
        return Object.assign({}, state, {
          list: [...state.list.slice(0, state.list.length), message]
        });
      }
    }

    case types.FLASH_MESSAGE_REMOVE: {
      return Object.assign({}, state, {
        list: [...state.list.slice(0, action.index), ...state.list.slice(action.index + 1)]
      });
    }

    case UPDATE_LOCATION: {
      if (state.numberOfRoutes > 1) {
        return {
          ...state,
          numberOfRoutes: state.numberOfRoutes - 1
        };
      } else {
        return {
          ...state,
          list: state.nextRouteList,
          nextRouteList: []
        };
      }
    }

    default: {
      return state;
    }
  }
}
