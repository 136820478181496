import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import {
  createMenuItems,
  resolveActive,
  resolveSelected,
  updateColumnFilters,
  showNextView
} from '../../../../components/_v2/ColumnList/helpers';

import ColumnList from '../../../../components/_v2/ColumnList';
import { voucherGroupsSelector, voucherGroupsFiltersSelector } from '../../../../selectors/all-selectors';
import { getAppFormattedDate, getFormValues, hasValues, newProp, removeMultiSpace } from '../../../../utils/utils';
import { voucherTypeLabels } from '../../../../constants/options-constants';
import { getMsg } from '../../../../utils/IntlGlobalProvider';
import Filters from './Filters';
import { regexDate, regexVoucherCode } from '../../../../constants/regex';
import { createElement } from '../../../../utils/component';
import moment from 'moment';
import { DMY_FORMAT, ISO_DATE_FLAT } from '../../../../constants/generic-constants';
import Fuse from 'fuse.js';
import { voucherTypes } from '../../../../constants/filters-constants';
import { URL_ALL } from '../../../../constants/backend-constants';

const fuseSearchKey = 'search';

const fuseOptions = {
  keys: [fuseSearchKey],
  minMatchCharLength: 3,
  threshold: 0.2
};

const getDatasetTypes = (childNodes, data) => {
  const { matches } = data;
  let indexes = { static: [] };

  for (let i = 0; i < matches.length; i++) {
    const { key, search, group } = matches[i].value;
    const node = childNodes[i];
    const item = group || key;

    if (node) {
      if (search) {
        if (!indexes[item]) indexes[item] = [];
        indexes[item].push(node);
      } else {
        indexes.static.push(node);
      }
    }
  }

  return indexes;
};

const addFuseResults = (results, fuse) => {
  for (let i = 0; i < fuse.length; i++) {
    results.push(fuse[i].item);
  }
};

const addSearchKey = (group, label) => ({
  search: group + ' ' + label,
  label
});

const getCommonResults = (key, options) => {
  const group = getMsg(acLabels[key]);

  return options.map(item => {
    return { key, value: item.value, ...addSearchKey(group, getMsg(item.labelKey)) };
  });
};

const getTypeResults = () => {
  return getCommonResults('voucherType', voucherTypes);
};

const acLabels = {
  voucherType: 'common_type',
  fromCreationDate: 'voucher_creation_date_start',
  toCreationDate: 'voucher_creation_date_end',
  name: 'common_searchFor_VoucherGroupName',
  voucherCode: 'common_searchFor_voucherCode',
  static: 'common_search_by'
};

const acDateResults = [{ key: 'fromCreationDate' }, { key: 'toCreationDate' }];
const acVoucherCodeResults = [{ key: 'voucherCode' }];
const acStaticResults = [{ key: 'name' }];

const dateFormatter = date => moment(date, DMY_FORMAT).format(ISO_DATE_FLAT);

const dateFormats = {
  fromCreationDate: dateFormatter,
  toCreationDate: dateFormatter
};

const formatSearchSelect = (selection, input) => {
  const { key, value } = selection.value || {};
  const formatter = dateFormats[key];

  if (value) {
    return { [key]: value };
  }
  if (formatter) {
    return { [key]: formatter(input) };
  }
  return { [key]: input };
};

const appendDataset = (list, nodes, title, subTitle) => {
  const dataset = createElement('div', 'sc-dataset');
  const suggestions = createElement('div', 'sc-suggestions');

  if (title) {
    const header = createElement('div', 'sc-header');
    const titleEl = createElement('span', 'sc-title');

    titleEl.textContent = title;
    header.appendChild(titleEl);

    if (subTitle) {
      const subTitleEl = createElement('span', 'sc-value');
      subTitleEl.textContent = ' ' + subTitle;
      header.appendChild(subTitleEl);
    }

    dataset.appendChild(header);
  }

  suggestions.append(...nodes);
  dataset.appendChild(suggestions);
  list.appendChild(dataset);

  return dataset;
};

class VoucherGroups extends Component {
  componentWillMount() {
    this.setCallbacks();
    this.setVars();
  }

  setVars() {
    this.menuItems = createMenuItems({ onAdd: this.handleAdd, addKey: 'voucher_create_group' });
  }

  setCallbacks() {
    this.handleEdit = (data = {}, _, { openInNewTab }) => {
      showNextView.call(this, 'vouchersEditGroup', ':voucherGroupId', data.id, openInNewTab);
    };

    this.handleAdd = () => {
      showNextView.call(this, 'vouchersAddGroup');
    };

    this.handleFiltersApply = () => {
      const values = getFormValues('voucherGroupFilters');

      if (hasValues(values)) {
        updateColumnFilters.call(this, values);
      } else {
        updateColumnFilters.call(this, URL_ALL);
      }
    };

    this.handleFiltersReset = () => {
      updateColumnFilters.call(this, URL_ALL);
    };

    this.renderItem = ({ name = '', voucherGroupCreationDate = '', type = '' } = {}) => {
      return (
        <div className="sc-list-item" title={name}>
          <div className="sc-name-wrap">
            <span>{name}</span>
          </div>
          <span>{getAppFormattedDate(voucherGroupCreationDate, { local: true })}</span>
          <span>&nbsp;</span>
          <span>({getMsg(voucherTypeLabels[type])})</span>
        </div>
      );
    };

    this.resolveSelected = resolveSelected.bind(this);
    this.resolveActive = resolveActive.bind(this);

    this.getAcList = (list, data) => {
      const { static: regex, ...types } = getDatasetTypes(list.childNodes, data);
      const labelPrefix = getMsg(acLabels.static);

      for (const type in types) {
        const subTitle = `(${getMsg(acLabels[type])})`;
        appendDataset(list, types[type], labelPrefix, subTitle);
      }
      appendDataset(list, regex, labelPrefix);
    };

    this.getAcResult = (item, { value: data }) => {
      const { key, label } = data || {};
      const children = [];

      const labelKey = acLabels[key];
      const result = createElement('span', 'sc-label');

      result.innerHTML = label ? label : getMsg(labelKey);
      children.push(result);

      item.replaceChildren(...children);
    };

    this.getAcData = query => {
      return new Promise(resolve => {
        const results = [];

        query = removeMultiSpace(query);
        if (!query) return resolve(results);

        addFuseResults(results, this.fuseType.search(query));

        if (regexDate.test(query)) {
          results.push(...acDateResults);
        }
        if (regexVoucherCode.test(query)) {
          results.push(...acVoucherCodeResults);
        }

        results.push(...acStaticResults);
        resolve(results);
      });
    };

    this.handleSearchSelect = (event = {}) => {
      const { query = '', selection = {} } = event.detail || {};
      const data = formatSearchSelect(selection, removeMultiSpace(query));
      updateColumnFilters.call(this, { ...this.props.filters, ...data });
    };
  }

  componentWillReceiveProps(props) {
    this.componentPropsUpdated(props);
  }

  componentPropsUpdated(props) {
    const newLocale = newProp.call(this, props, 'locale');

    if (newLocale) {
      this.fuseType = new Fuse(getTypeResults(), fuseOptions);
    }
  }

  render() {
    const title = <FormattedMessage id="side_menu_section_vouchers" />;

    return (
      <ColumnList
        title={title}
        filtersTitle={title}
        filters={<Filters initialValues={this.props.filters} />}
        onFiltersApply={this.handleFiltersApply}
        onFiltersReset={this.handleFiltersReset}
        filtersActive={!!this.props.filters}
        inputRef={this.props.inputRef}
        items={this.props.list}
        renderItem={this.renderItem}
        onSelect={this.handleEdit}
        selected={this.resolveSelected}
        active={this.resolveActive}
        totalResults={this.props.totalResults}
        menuItems={this.menuItems}
        autoCompleteEnabled
        autoCompleteData={this.getAcData}
        autoCompleteList={this.getAcList}
        autoCompleteResult={this.getAcResult}
        autoCompleteOnSelect={this.handleSearchSelect}
      />
    );
  }
}

const mapStateToProps = state => {
  return { list: voucherGroupsSelector(state), filters: voucherGroupsFiltersSelector(state) };
};

VoucherGroups = connect(mapStateToProps)(VoucherGroups);

export default VoucherGroups;
