/*  eslint-disable react/no-set-state */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import { routeActions } from 'react-router-redux';
import { SEARCH_INVOICES_DATE_FORMAT, SORT_ASC, SORT_DESC } from '../../constants/generic-constants';
import AdvancedList from '../../components/AdvancedList/AdvancedList';
import { Row, Col } from 'react-bootstrap';
import { invoicesListHeader } from '../../constants/table-constants';
import { addTippyContent, getAppFormattedDateTime } from '../../utils/utils';
import EkButton from '../../components/EkButton/EkButton';
import { exportInvoicesListWithSearch, getInvoicesList, toggleInvoicePreview, retryPayment } from '../../actions/all-actions';
import { isGranted } from '../../actions/user-actions';
import { datesToString } from '../../utils/utils';
import routes, { invoiceEditRules } from '../../constants/routes-constants';
import { PAYMENT_STATUS_UNPAID, INVOICE_TYPE, SORT_INVOICE_EMISSIOM_DATE } from '../../constants/backend-constants';
import { apiParams } from '../../constants/api-params-constants';
import autoBind from 'react-autobind';
import { Tooltip } from 'react-tippy';
import IconButton from 'material-ui/IconButton';
import DetailIcon from 'material-ui/svg-icons/image/remove-red-eye';
import PdfModal from '../../components/PdfModal/PdfModal';
import _get from 'lodash/get';
import _partial from 'lodash/partial';
import { checkRole, invoiceRetryPaymentRules } from '../../constants/backuser-role-rules';
import FilterChips from '../../components/FilterChips/FilterChips';
import { invoicesFilterChips } from '../../constants/options-constants';
import { invoicesFilterTypes } from '../../constants/filterTypes-constants';
import Filters from '../../components/AdvancedList/components/Filters';
import _includes from 'lodash/includes';

class Invoices extends Component {
  constructor(props) {
    super(props);
    autoBind(this);

    this.setReadOnly(props);

    this.invoicesChipsOmit = ['number', 'size', 'sortDirection', 'sortProperty', 'withPdfLink', 'companyId', 'subCompanyId'];
    this.actionParamsToOmit = ['withPdfLink'];

    this.handlePdfRef = element => {
      this.pdfRef = element;
    };

    this.derivedStateFromProps(props, true);
  }

  // eslint-disable-next-line no-unused-vars
  componentWillReceiveProps(nextProps, nextContext) {
    this.derivedStateFromProps(nextProps);
  }

  derivedStateFromProps(nextProps) {
    this.setAdvancedListParams(nextProps);
  }

  setAdvancedListParams(nextProps) {
    const { urlParams } = nextProps;
    const { size, number, sortDirection, sortProperty } = urlParams || {};

    this.advancedListParams = {
      page: {
        number: number || 1,
        size: size || 50
      },
      sort: {
        isDescending: sortDirection === SORT_DESC,
        property: sortProperty || SORT_INVOICE_EMISSIOM_DATE
      }
    };
  }

  componentWillMount() {
    const { pageParams } = this.props;
    this.omitActionParams(pageParams);
    this.callApi(pageParams);
  }

  setReadOnly(props) {
    const { role } = props;
    this.readOnly = !checkRole(invoiceEditRules, role);
  }

  callApi(params) {
    const { dispatch } = this.props;
    dispatch(getInvoicesList(params));
  }

  handleChangePage(value) {
    const { listMetadata, urlParams } = this.props;

    const params = {
      ...urlParams,
      number: value,
      size: listMetadata.paginationInfo.pageSize
    };

    this.omitActionParams(params);
    this.callApi(params);
    this.updatePath(params);
  }

  handleNumberResultsSelect(value) {
    const { urlParams } = this.props;

    let params = {
      ...urlParams,
      number: 1,
      size: parseInt(value)
    };

    this.omitActionParams(params);
    this.callApi(params);
    this.updatePath(params);
  }

  handleSort(sortPropertyName) {
    const { urlParams } = this.props;
    const { sortDirection } = urlParams || {};

    const newParams = {
      ...urlParams,
      number: 1,
      sortDirection: sortDirection === SORT_DESC ? SORT_ASC : SORT_DESC,
      sortProperty: sortPropertyName
    };

    this.omitActionParams(newParams);
    this.callApi(newParams);
    this.updatePath(newParams);
  }

  handleCreateInvoice() {
    const { dispatch } = this.props;
    dispatch(routeActions.push(routes.addInvoice.path.replace(':search', encodeURIComponent(JSON.stringify(apiParams.default)))));
  }

  handlePayment(item = {}) {
    this.props.dispatch(retryPayment(item.id));
  }

  handleRetryPaymentLink(item) {
    const invoiceStatus = `${item.status === 'PAID' ? 'paid' : 'unpaid'}`;

    if (
      item.status === PAYMENT_STATUS_UNPAID &&
      item.type === INVOICE_TYPE.MISC_INVOICE &&
      isGranted({ allowed: invoiceRetryPaymentRules.include, disallowed: invoiceRetryPaymentRules.exclude })
    ) {
      return (
        <a className={`retry-payment ${invoiceStatus}`} id="handle_invoice_payment" onClick={_partial(this.handlePayment, item)}>
          <span className="initial">
            <FormattedMessage id={`payment_status_${item.status}`} />
          </span>

          <span className="retry">
            <FormattedMessage id="booking_detail_settle_booking" />
          </span>
        </a>
      );
    }

    return (
      <span className={invoiceStatus}>
        <FormattedMessage id={`payment_status_${item.status}`} />
      </span>
    );
  }

  handleFullExport() {
    const { dispatch, urlParams } = this.props;
    dispatch(exportInvoicesListWithSearch(urlParams, true));
  }

  handleView(item) {
    const { dispatch } = this.props;
    dispatch(toggleInvoicePreview(item[0]));
  }

  renderItem(item, id, reactKey) {
    const { intl } = this.props;
    const { messages } = intl;
    const { invoice_params_label_vat_rate, invoice_params_label_vat_code } = messages || {};
    const { invoicingCompany, emissionDate, priceIncludingTaxes, recallmoney, priceExcludingTaxes, taxes, downloadUrl } = item || {};

    const tool = (
      <Tooltip
        followCursor
        delay="300"
        className="detail-tooltip"
        {...addTippyContent(<div style={{ padding: '10px 20px' }}>{invoicingCompany}</div>)}
      >
        {
          <div>
            <span>{getAppFormattedDateTime(emissionDate)}</span>
            <span>
              <FormattedMessage id={`invoiceForm_invoiceType_${item.type}`} values={{ reference: item.reference }} />
            </span>
          </div>
        }
      </Tooltip>
    );
    return (
      <div className="advancedList_rowWrap" key={reactKey}>
        <Row id={id} className="advancedList_row">
          <Col xs={4} md={4}>
            <div className="invoice-ref">{tool}</div>
          </Col>
          <Col xs={3} md={3} className="member-detail" children={[item.firstName + ' ' + item.lastName, item.email]} />
          <Col
            xs={2}
            md={2}
            className="price-detail"
            children={[
              priceIncludingTaxes + ' ' + intl.formatMessage({ id: `unit_${item.currency}` }),
              '(' + priceExcludingTaxes + ' + ' + taxes + ')'
            ]}
          />
          <Col xs={1} md={1} className="vat-detail">
            <Tooltip
              followCursor
              className="detail-tooltip"
              delay="300"
              {...addTippyContent(
                <div style={{ padding: '10px 20px' }} className="tooltip-content">
                  {invoice_params_label_vat_code}
                  {invoice_params_label_vat_rate}
                </div>
              )}
            >
              {
                <div>
                  {item.vatCode}
                  {item.vatRate + '%'}
                </div>
              }
            </Tooltip>
          </Col>
          <Col xs={1} md={1} className="status">
            {recallmoney ? <FormattedMessage id="groups_status_PENDING" /> : this.handleRetryPaymentLink(item)}
          </Col>
          <Col xs={1} md={1}>
            <div className="advancedList_detailIcon">
              {downloadUrl && (
                <IconButton onClick={_partial(this.handleView, [item])}>
                  <DetailIcon />
                </IconButton>
              )}
            </div>
          </Col>
        </Row>
      </div>
    );
  }

  handleDownloadInvoice() {
    if (this.pdfRef) this.pdfRef.click();
  }

  displayPdfDownloader() {
    const invoiceUrl = _get(this.props.invoice, 'downloadUrl');

    return (
      <a
        href={invoiceUrl}
        ref={this.handlePdfRef}
        target="_blank"
        style={{
          position: 'absolute',
          visibility: 'hidden'
        }}
      />
    );
  }

  handleRefreshList() {
    const { urlParams, pageParams } = this.props;
    const params = urlParams || pageParams;

    this.omitActionParams(params);
    this.callApi(urlParams || pageParams);
  }

  handleCloseInvoice() {
    this.props.dispatch(toggleInvoicePreview(false));
  }

  handleDeleteChip(item) {
    const { urlParams, listMetadata } = this.props;

    const newParams = {
      ...urlParams,
      number: 1,
      size: listMetadata.paginationInfo.pageSize
    };

    delete newParams[item];

    this.omitActionParams(newParams);
    this.callApi(newParams);
    this.updatePath(newParams);
  }

  handleFilterSearch(newSelectedFilters) {
    const { urlParams } = this.props;

    const paramsDatesString = datesToString(newSelectedFilters, SEARCH_INVOICES_DATE_FORMAT);

    const newParams = {
      ...urlParams,
      ...paramsDatesString
    };

    this.omitActionParams(newParams);
    this.callApi(newParams);
    this.updatePath(newParams);
  }

  // will mutate object
  omitActionParams(params) {
    Object.keys(params).forEach(paramName => {
      if (_includes(this.actionParamsToOmit, paramName)) {
        delete params[paramName];
      }
    });
  }

  updatePath(newParams) {
    const { dispatch } = this.props;
    const encodedParams = encodeURIComponent(JSON.stringify(newParams));
    dispatch(routeActions.push(routes.invoices.path.replace(':search', encodedParams)));
  }

  render() {
    const { list, loadingList, listMetadata, urlParams, exporting, errorList, preview, invoice } = this.props;
    const { downloadUrl } = invoice || {};

    return (
      <div className="pageContainer">
        <div className="invoicesList">
          <FilterChips
            id="invoicesChips"
            urlParams={urlParams}
            omit={this.invoicesChipsOmit}
            onDeleteChip={this.handleDeleteChip}
            translations={invoicesFilterChips}
            filterTypes={invoicesFilterTypes}
          />
          <div className="advancedList_legacyActions page">
            <Filters id="invoicesFilters" filterTypes={invoicesFilterTypes} onFilteredSearchForm={this.handleFilterSearch} />
            <div className="voucherList_actionsButtons">
              <EkButton
                type="button"
                id="export-invoices"
                formRowItemKey="Invoices_exportBtn"
                customClass={`membersPage_actionButton ekButton ${exporting ? 'disabled' : ''} ekButton--variant3`}
                onAction={this.handleFullExport}
              >
                {exporting ? <FormattedMessage id="common_button_exporting" /> : <FormattedMessage id="invoices_export_all_button" />}
              </EkButton>

              {!this.readOnly && (
                <EkButton
                  type="button"
                  id="create-invoice"
                  formRowItemKey="Invoices_createBtn"
                  customClass="membersPage_actionButton ekButton ekButton--variant3"
                  onAction={this.handleCreateInvoice}
                >
                  <FormattedMessage id="create_invoice_button" />
                </EkButton>
              )}
            </div>
          </div>
          <AdvancedList
            id="invoices"
            data={list}
            listMetadata={listMetadata}
            loading={loadingList}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleNumberResultsSelect}
            renderItem={this.renderItem}
            header={invoicesListHeader}
            onRefreshList={this.handleRefreshList}
            urlParams={this.advancedListParams}
            error={errorList}
            onSort={this.handleSort}
          />

          <PdfModal show={preview} src={downloadUrl} onDownload={this.handleDownloadInvoice} onClose={this.handleCloseInvoice} />
          {this.displayPdfDownloader()}
        </div>
      </div>
    );
  }
}

Invoices.displayName = 'Invoices';

Invoices.propTypes = {
  list: PropTypes.array,
  listMetadata: PropTypes.object,
  invoicesFiltersFormIsOpen: PropTypes.bool
};

export default connect(state => {
  const {
    invoices: {
      paginatedResults,
      loadingList,
      urlParams,
      currentSortedIndex,
      sortIsDescending,
      invoicesFiltersFormIsOpen,
      exporting,
      preview,
      invoice
    },
    user: {
      userInfo: { role }
    },
    page: { params }
  } = state;

  return {
    list: _get(paginatedResults, 'results', []),
    listMetadata: _get(paginatedResults, 'metadata', {}),
    loadingList,
    role,
    urlParams,
    pageParams: params,
    currentSortedIndex,
    sortIsDescending,
    invoicesFiltersFormIsOpen,
    exporting,
    preview,
    invoice
  };
})(injectIntl(Invoices));
