import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { goToSearchLocation, goToDetailsLocation } from '../../../actions/all-actions';
import routes from '../../../constants/routes-constants';

import MemberResultsRow from './MemberResultsRow';
import { memberResultsSelector, memberResultsTotalSelector, membersSourceSelector } from '../../../selectors/all-selectors';
import { MEMBER_SOURCE_NAME } from '../../../constants/generic-constants';
import { addOpenNewTabEvents, hideButtonHoverMsg } from '../../../utils/utils';

class MemberResults extends Component {
  constructor(props) {
    super(props);
    this.onGoToDetail = this.onGoToDetail.bind(this);
    this.handleGoToAll = this.handleGoToAll.bind(this);
  }

  componentWillUnmount() {
    hideButtonHoverMsg();
  }

  onGoToDetail(...props) {
    this.props.goToDetail(...props);
  }

  handleGoToAll(...props) {
    this.props.goToAll(...props);
  }

  getMemberResultsTotalUrl() {
    const { memberResultsTotal, membersSource, searchText } = this.props;

    const callback = openInNewTab => {
      if (membersSource === MEMBER_SOURCE_NAME) {
        this.handleGoToAll(routes.members.path, { memberName: searchText }, openInNewTab);
      } else {
        this.handleGoToAll(routes.members.path, { email: searchText }, openInNewTab);
      }
    };

    if (memberResultsTotal) {
      return (
        <a {...addOpenNewTabEvents(callback)} className="link">
          <FormattedMessage id="common_show_all" />
          {' ' + memberResultsTotal + ' '}
        </a>
      );
    }
  }

  render() {
    const { memberResults, memberResultsTotal } = this.props;

    return (
      <div>
        {memberResults.length > 0 && (
          <div>
            <div className="search-resultTitle">
              <FormattedMessage id="side_menu_section_users_members" />
              {memberResultsTotal <= 5 && (
                <div>
                  <FormattedMessage id="common_results" />:{' ' + memberResultsTotal}
                </div>
              )}
              {this.getMemberResultsTotalUrl()}
            </div>
            <div className="search-resultsWrap">
              {memberResults.map(member => {
                return (
                  <MemberResultsRow
                    key={member.id}
                    member={member}
                    handleGoToDetail={this.onGoToDetail}
                    handleGoToBookings={this.handleGoToAll}
                  />
                );
              })}
            </div>
          </div>
        )}
      </div>
    );
  }
}

MemberResults.displayName = 'MemberResults';

const mapStateToProps = state => {
  const {
    header: { searchText }
  } = state;

  return {
    memberResults: memberResultsSelector(state),
    memberResultsTotal: memberResultsTotalSelector(state),
    membersSource: membersSourceSelector(state),
    searchText
  };
};

const mapDispatchToProps = dispatch => {
  return {
    goToDetail(...props) {
      goToDetailsLocation(...props)(dispatch);
    },
    goToAll(...props) {
      goToSearchLocation(...props)(dispatch);
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MemberResults);
