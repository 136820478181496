import React, { Component } from 'react';
import autoBind from 'react-autobind';
import pure from 'recompose/pure';
import { hookTimesData } from './../../../utils/utils';
import { namedCompose, humanShortDuration, hasStartBeforeCancel } from '../../../utils/utils';
import { FormattedMessage, injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import None from '../../None';
import moment from 'moment';
import { BOOKING_STATUS_COMPLETED, STATUS_CANCELED, BOOKING_STATUS_IN_PROGRESS } from './../../../constants/backend-constants';

class BookingStatusHistoryV2 extends Component {
  constructor(props) {
    super(props);
    autoBind(this);

    this.derivedStateFromProps(props);
  }

  componentWillReceiveProps(props) {
    this.derivedStateFromProps(props);
  }

  derivedStateFromProps(props) {
    this.bookingStatuses = props.statusHistory.sort(function compare(a, b) {
      const dateA = new Date(a.date);
      const dateB = new Date(b.date);
      return dateA - dateB;
    });
    this.dateFormat = 'DD/MM/YYYY, HH[h]mm';
    this.creationDate = moment(props.creationDate).format(this.dateFormat);
    this.estimatedStart = moment(props.estimatedDates.start).format(this.dateFormat);
    this.estimatedEnd = moment(props.estimatedDates.end).format(this.dateFormat);
    this.effectiveStart = moment(props.effectiveDates.start).format(this.dateFormat);
    this.effectiveEnd = moment(props.effectiveDates.end).format(this.dateFormat);
    this.componentCanRender = this.checkComponentRender();
  }

  checkComponentRender() {
    return this.bookingStatuses.length !== 0;
  }

  parseData(item, index) {
    const status = String(item.type).toLowerCase();
    const isStartedBefore = this.isStartedBeforeEstimation(item);
    const isStartedAfter = this.isStartedAfterEstimation(item);
    const isEndedBefore = this.isEndedBeforeEstimation(item);
    const isEndedAfter = this.isEndedAfterEstimation(item);
    const isStartOnTime = this.isSameStart(item);
    const isEndOnTime = this.isSameEnd(item);
    const statusClass = 'booking_status_' + status;
    const statusKey = 'view_booking_status_' + status;
    const hasTimeZone = moment(item.date).parseZone(item.date);
    const hasTzDefined = typeof hasTimeZone._tzm === 'number';
    const _status = status.toUpperCase();
    const options = {
      creationDate: this.creationDate,
      effectiveEnd: this.effectiveEnd,
      effectiveStart: this.effectiveStart
    };

    const date = hasTzDefined ? moment(item.date).format(this.dateFormat) : hookTimesData(_status, options);
    return (
      <div
        key={item.id || index}
        id={status}
        className={classnames({
          'start-before-estimation': isStartedBefore,
          'start-after-estimation': isStartedAfter,
          'start-on-time': isStartOnTime,
          'end-before-estimation': isEndedBefore,
          'end-after-estimation': isEndedAfter,
          'end-on-time': isEndOnTime
        })}
      >
        <span className={statusClass}>
          <FormattedMessage id={statusKey} />
        </span>
        <span>{date}</span>
      </div>
    );
  }

  isStartedBeforeEstimation(event) {
    const { estimatedDates } = this.props;

    if (event.type === BOOKING_STATUS_IN_PROGRESS || event.type === STATUS_CANCELED) {
      return moment(event.date).isBefore(estimatedDates.start);
    }

    return false;
  }

  isStartedAfterEstimation(event) {
    const { estimatedDates } = this.props;

    if (event.type === BOOKING_STATUS_IN_PROGRESS || event.type === STATUS_CANCELED) {
      return moment(event.date).isAfter(estimatedDates.start);
    }

    return false;
  }

  // boolean : effectiveStart and esitmatedStart are same datetime (excluding seconds)
  isSameStart(event) {
    const { estimatedDates } = this.props;

    if (event.type === BOOKING_STATUS_IN_PROGRESS) {
      return moment(event.date).isSame(estimatedDates.start, 'minute');
    }

    return false;
  }
  // boolean : effectiveEnd and esitmatedEnd are same datetime (excluding seconds)
  isSameEnd(event) {
    const { estimatedDates } = this.props;

    if (event.type === BOOKING_STATUS_COMPLETED) {
      return moment(event.date).isSame(estimatedDates.end, 'minute');
    }

    return false;
  }

  isEndedBeforeEstimation(event) {
    const { estimatedDates } = this.props;

    if (event.type === BOOKING_STATUS_COMPLETED) {
      return moment(event.date).isBefore(estimatedDates.end);
    }

    return false;
  }

  isEndedAfterEstimation(event) {
    const { estimatedDates } = this.props;

    if (event.type === BOOKING_STATUS_COMPLETED) {
      return moment(event.date).isAfter(estimatedDates.end);
    }

    return false;
  }

  isStartThenCancelBeforeEstimationStart(event) {
    const { estimatedDates } = this.props;

    if (event.type === STATUS_CANCELED) {
      return moment(event.date).isBefore(estimatedDates.start);
    }

    return false;
  }

  estimationSide() {
    if (this.estimatedStart) {
      const event = hasStartBeforeCancel(this.bookingStatuses);
      const isStartCancelBeforeEstimStart = this.isStartThenCancelBeforeEstimationStart(event);
      return (
        <div id="estimations-side">
          <div id="estimated-start" className={classnames({ 'start-cancel-before-estim-start': isStartCancelBeforeEstimStart })}>
            <FormattedMessage id="booking_detail_trip_estimated_start" />
            {this.estimatedStart}
          </div>

          <div id="estimated-end">
            <FormattedMessage id="booking_detail_trip_estimated_end" />
            {this.estimatedEnd}
          </div>
        </div>
      );
    }
  }

  isLateBooking() {
    const {
      bookingStatus,
      effectiveDates: { end },
      locale,
      intl
    } = this.props;

    const realEnd = end ? moment(end) : moment();
    const forecastEnd = moment(this.props.estimatedDates.end);
    const diffInSeconds = moment.duration(forecastEnd.diff(realEnd, 'seconds'), 'seconds');
    const delay = humanShortDuration(diffInSeconds, locale, intl);

    if (forecastEnd.isBefore(realEnd) && bookingStatus === BOOKING_STATUS_IN_PROGRESS)
      return (
        <div id="late-booking">
          <span>
            <FormattedMessage id="view_booking_late" />
          </span>
          <span>
            <FormattedMessage id="booking_view_since" /> {delay}
          </span>
        </div>
      );
  }

  statusEventsSide() {
    if (this.bookingStatuses)
      return (
        <div id="events-side">
          {this.bookingStatuses.map((item, index) => this.parseData(item, index))}
          {this.isLateBooking()}
        </div>
      );
  }

  render() {
    if (this.componentCanRender)
      return (
        <div className="booking-tile">
          <h4 className="booking-tile_headline">
            <FormattedMessage id="booking_view_trip_history_tile_header" />
          </h4>
          <div className="booking-tile_wrap timeline">
            {this.estimationSide()}
            {this.statusEventsSide()}
          </div>
          <div className="booking-tile_actions">
            <a className="url-button" href={this.props.viewEventsHref} onClick={this.props.viewEventOnClick}>
              <FormattedMessage id="booking_view_events_history" />
            </a>
          </div>
        </div>
      );
    else return <None />;
  }
}

// -----

BookingStatusHistoryV2.propTypes = {
  viewEventsHref: PropTypes.string,
  viewEventOnClick: PropTypes.func,
  statusHistory: PropTypes.array,
  estimatedDates: PropTypes.object,
  effectiveDates: PropTypes.object,
  creationDate: PropTypes.string,
  bookingStatus: PropTypes.string,
  locale: PropTypes.string
};

// -----

export default namedCompose(injectIntl, pure)(BookingStatusHistoryV2);
