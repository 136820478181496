import React, { Component, PropTypes } from 'react';
import { FormattedMessage } from 'react-intl';
import { reduxForm } from 'redux-form';
import _partial from 'lodash/partial';
import { routeActions } from 'react-router-redux';

import FormRow from '../FormRow/FormRow';
import BoxedInput from '../BoxedInput/BoxedInput';
import EkButton from '../EkButton/EkButton';
import PhoneNumberField from '../PhoneNumberField/PhoneNumberField';
import FieldErrorMsg from '../FieldErrorMsg/FieldErrorMsg';
import { validateHotlines } from '../../validation/all-validation';
import routes from '../../constants/routes-constants';
import BackLink from '../BackLink/BackLink';
import { scrollToFirstError } from '../../utils/utils';

class HotlineForm extends Component {
  constructor() {
    super();
    this.goBack = this.goBack.bind(this);
    this.handleAddHotlineNumber = this.handleAddHotlineNumber.bind(this);
    this.handleRemoveHotlineNumber = this.handleRemoveHotlineNumber.bind(this);
  }

  goBack() {
    this.props.dispatch(routeActions.push(routes.hotlines.path));
  }

  handleAddHotlineNumber(event) {
    event.preventDefault();
    this.props.fields.phoneNumbers.addField({});
  }

  handleRemoveHotlineNumber(index, event) {
    event.preventDefault();
    this.props.fields.phoneNumbers.removeField(index);
  }

  render() {
    const {
      fields: { name, customerServiceName, customerServiceEmail, noReplyEmail, phoneNumbers },
      handleSubmit
    } = this.props;

    return (
      <section className="hotlineFormWrapper">
        <BackLink link={`/#${routes.hotlines.path}`} labelKey="back_link_hotlines_list" />
        <form action="#" className="hotlineForm" onSubmit={handleSubmit(this.props.onCallback)}>
          <div className="hotlineForm_container">
            <FormRow customClass="hotlineForm_row">
              <BoxedInput
                formRowItemKey="hotlineForm_name"
                type="text"
                id="name"
                labelKey="hotlineForm_name_label"
                customClass="vehiclesColorForm_boxedInput"
                field={name}
              >
                <FieldErrorMsg field={name} customClass="fieldErrorMsg--vehiclesColorForm" />
              </BoxedInput>
            </FormRow>

            <FormRow customClass="hotlineForm_row">
              <BoxedInput
                formRowItemKey="hotlineForm_customer_service_name"
                type="text"
                id="customerServiceName"
                labelKey="hotlineForm_customer_service_name_label"
                customClass="vehiclesColorForm_boxedInput"
                field={customerServiceName}
              >
                <FieldErrorMsg field={customerServiceName} customClass="fieldErrorMsg--vehiclesColorForm" />
              </BoxedInput>
            </FormRow>

            <FormRow customClass="hotlineForm_row">
              <BoxedInput
                formRowItemKey="hotlineForm_customer_service_email"
                type="text"
                id="customerServiceEmail"
                labelKey="hotlineForm_customer_service_email_label"
                customClass="hotlineForm_boxedInput"
                field={customerServiceEmail}
              >
                <FieldErrorMsg field={customerServiceEmail} customClass="fieldErrorMsg--companyForm" />
              </BoxedInput>

              <BoxedInput
                formRowItemKey="hotlineForm_no_reply_email"
                type="text"
                id="noReplyEmail"
                labelKey="hotlineForm_no_reply_email_label"
                customClass="hotlineForm_boxedInput"
                field={noReplyEmail}
              >
                <FieldErrorMsg field={noReplyEmail} customClass="fieldErrorMsg--companyForm" />
              </BoxedInput>
            </FormRow>

            <FormRow customClass="hotlineForm_row hotlineForm_row--phoneNumbers">
              {phoneNumbers.map((item, index) => {
                const key = 'line' + index;
                return (
                  <div key={key} className="hotline-entry">
                    <PhoneNumberField
                      id="phoneNumber"
                      className="boxedInputWrapper"
                      label={<FormattedMessage id="hotlineForm_phone_number_label" />}
                      field={item.phoneNumber}
                    />

                    <BoxedInput
                      type="text"
                      id="phoneNumberName"
                      placeholderKey="hotlineForm_phone_number_name_label"
                      maxlength="255"
                      customClass="boxedInputWrapper--name extra"
                      field={item.phoneNumberName}
                    />

                    <button className="ekButton ekButton--reverse extra" onClick={_partial(this.handleRemoveHotlineNumber, index)}>
                      ✕
                    </button>
                  </div>
                );
              })}
            </FormRow>
            <button className="ekButton extra" onClick={this.handleAddHotlineNumber}>
              <FormattedMessage id="hotlineForm_phone_number_add" />
            </button>
          </div>

          <div className="hotlineForm_actions">
            <EkButton skinType="reverse" customClass="hotlineForm_actionsButton" onAction={this.goBack}>
              <FormattedMessage id="common_cancel" />
            </EkButton>
            <EkButton customClass="hotlineForm_actionsButton" onAction={handleSubmit(this.props.onCallback)}>
              <FormattedMessage id="common_save" />
            </EkButton>
          </div>
        </form>
      </section>
    );
  }
}

HotlineForm.displayName = 'HotlineForm';

HotlineForm.propTypes = {
  fields: PropTypes.object,
  handleSubmit: PropTypes.func,
  onCallback: PropTypes.func
};

HotlineForm = reduxForm({
  onSubmitFail: scrollToFirstError,
  form: 'hotlineForm',
  fields: [
    'name',
    'customerServiceName',
    'customerServiceEmail',
    'noReplyEmail',
    'phoneNumbers[]',
    'phoneNumbers[].phoneNumber',
    'phoneNumbers[].phoneNumberName'
  ],
  validate: validateHotlines()
})(HotlineForm);

export default HotlineForm;
