import axios from 'axios';
import config from '../constants/config-constants';
import urlJoin from 'url-join';
import configConstants from '../constants/config-constants';
import { localeSelector } from '../selectors/all-selectors';
import { getStore } from '../store/all-store';
import { LOCALE_COUNTRIES } from '../constants/locale-countries';
import { getAppObj, safe } from './utils';

export const getJsonData = url => {
  return new Promise((resolve, reject) => {
    axios
      .get(url)
      .then(response => resolve(response.data))
      .catch(error => reject(error));
  });
};

export const createGetCountryIsoByIpAction = fallbackCountry => {
  return success => {
    getJsonData(config.geoIpUrl).then(
      (data = '') => success(data.split(';')[1] || fallbackCountry),
      () => success(fallbackCountry)
    );
  };
};

export const forwardGeocoding = (query, params) => {
  return new Promise((resolve, reject) => {
    axios
      .get(urlJoin(config.mapboxEndpoint, 'mapbox.places', `${query}.json`), { params })
      .then(response => {
        resolve(response);
      })
      .catch(error => {
        reject(error);
      });
  });
};

export const reverseGeocoding = ([lat, lng], params) => {
  return new Promise((resolve, reject) => {
    axios
      .get(urlJoin(config.mapboxEndpoint, 'mapbox.places', `${lng},${lat}.json`), {
        params
      })
      .then(response => {
        resolve(response);
      })
      .catch(error => {
        reject(error);
      });
  });
};

export const fetchUserCountry = () => {
  return getJsonData(configConstants.geoIpUrl).then(
    (data = '') => Promise.resolve(data.split(';')[1]),
    () => Promise.resolve('')
  );
};

export const getUserCountry = cb => {
  const locale = localeSelector(getStore().getState());
  const country = LOCALE_COUNTRIES[locale] || getAppObj().userCountry;
  const validCountry = safe(() => country.length);
  const predicate = country => cb(country || 'fr');

  if (validCountry) return cb(country);
  if (country) return country.then(predicate);

  fetchUserCountry().then(predicate);
};
