import React, { Component } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import autoBind from 'react-autobind';

import Dialog from 'material-ui/Dialog';
import FlatButton from 'material-ui/FlatButton';
import RaisedButton from 'material-ui/RaisedButton';

import { requestAnonymizeMember, requestDeanonymizedMember, closeAnonymizeMemberDialog } from '../../../actions/all-actions';
import { safe } from '../../../utils/utils';

/* eslint-disable react/no-set-state */

class AnonymizeMemberDialog extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
  }

  isAnonymized() {
    return safe(() => this.props.detailMember.anonymizationEffectiveDate);
  }

  handleSubmit() {
    const { dispatch, detailMember } = this.props;
    const payload = {
      memberId: detailMember.id
    };
    dispatch(this.isAnonymized() ? requestDeanonymizedMember(payload) : requestAnonymizeMember(payload));
  }

  handleClose() {
    const { dispatch } = this.props;
    dispatch(closeAnonymizeMemberDialog());
  }

  render() {
    const { intl } = this.props;

    let actions = [
      <FlatButton key="createMemberTypeForm_abortBtn" label={intl.messages['common_cancel']} primary onClick={this.handleClose} />,
      <RaisedButton
        key="createMemberTypeForm_submitBtn"
        label={intl.messages['common_confirm']}
        type="button"
        primary
        onClick={this.handleSubmit}
      />
    ];

    return (
      <Dialog
        open
        title={this.isAnonymized() ? intl.messages.member_deanonymize_dialog_title : intl.messages.member_anonymize_dialog_title}
        actionsContainerStyle={{ textAlign: 'center' }}
        contentStyle={{ width: '350px' }}
        actions={actions}
      />
    );
  }
}

export default connect(state => {
  const {
    user: { userInfo },
    companies: { currentCompany },
    members: { detailMember }
  } = state;

  return {
    userInfo,
    companyId: currentCompany.id,
    detailMember
  };
})(injectIntl(AnonymizeMemberDialog));
