import React, { Component, PropTypes } from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import _ from 'lodash';
import { routeActions } from 'react-router-redux';
import { getFeedbacksList } from '../../actions/all-actions';
import { feedbackFilterChips } from '../../constants/options-constants';
import routes from '../../constants/routes-constants';
import { feedbacksHeaderParams } from '../../constants/table-constants';
import AdvancedList from '../../components/AdvancedList/AdvancedList';
import Filters from '../../components/AdvancedList/components/Filters';
import FilterChips from '../../components/FilterChips/FilterChips';
import { feedbacksFilterTypes } from '../../constants/filterTypes-constants';
import { getAppFormattedDateTime } from '../../utils/utils';

class Feedbacks extends Component {
  constructor() {
    super();
    this.renderItem = this.renderItem.bind(this);
    this.handleChangePage = this.handleChangePage.bind(this);
    this.handleNumberResultsSelect = this.handleNumberResultsSelect.bind(this);
    this.handleRefreshList = this.handleRefreshList.bind(this);
    this.handleSort = this.handleSort.bind(this);
    this.handleGotoDetail = this.handleGotoDetail.bind(this);
    this.handleSearcForm = this.handleSearcForm.bind(this);
    this.handleDeleteChip = this.handleDeleteChip.bind(this);
  }

  componentWillMount() {
    const { pageParams, dispatch } = this.props;
    this.callApi(pageParams);
  }

  callApi(params) {
    const { dispatch } = this.props;
    dispatch(getFeedbacksList(params));
  }

  handleChangePage(value) {
    const { dispatch, listMetadata, urlParams } = this.props;
    const params = {
      page: {
        number: value,
        size: listMetadata.paginationInfo.pageSize
      }
    };
    const newParams = { ...urlParams, ...params };
    this.callApi(newParams);
    dispatch(routeActions.push(routes.feedbacks.path.replace(':search', encodeURIComponent(JSON.stringify(newParams)))));
  }

  handleRefreshList() {
    const { pageParams } = this.props;
    this.callApi(pageParams);
  }

  handleNumberResultsSelect(value) {
    const { dispatch, urlParams } = this.props;
    const params = {
      page: {
        number: 1,
        size: parseInt(value)
      }
    };
    const newParams = Object.assign({}, urlParams, params);
    this.callApi(newParams);
    dispatch(routeActions.push(routes.feedbacks.path.replace(':search', encodeURIComponent(JSON.stringify(newParams)))));
  }

  handleSort(sortPropertyName) {
    const { dispatch, listMetadata, urlParams } = this.props;
    const newParams = {
      ...urlParams,
      sort: {
        isDescending: _.get(urlParams, 'sort.property', false) === sortPropertyName ? !urlParams.sort.isDescending : false,
        property: sortPropertyName
      }
    };
    this.callApi(newParams);
    dispatch(routeActions.push(routes.feedbacks.path.replace(':search', encodeURIComponent(JSON.stringify(newParams)))));
  }

  handleGotoDetail(params) {
    this.props.dispatch(routeActions.push(routes[params[0]].path.replace(params[1], params[2])));
  }

  handleSearcForm(params) {
    const { dispatch, listMetadata, urlParams } = this.props;
    params.page = {
      number: 1,
      size: listMetadata.paginationInfo.pageSize
    };
    const newParams = {
      ...urlParams,
      ...params
    };
    this.callApi(newParams);
    dispatch(routeActions.push(routes.feedbacks.path.replace(':search', encodeURIComponent(JSON.stringify(newParams)))));
  }

  renderItem(item, id, reactKey) {
    const colProps = { xs: 2, md: 2 };

    return (
      <div className="advancedList_rowWrap" key={reactKey}>
        <Row
          className="advancedList_row feedbacksPage_listRow"
          onClick={_.partial(this.handleGotoDetail, ['feedbackDetail', ':feedbackId', item.bookingId])}
          id={id}
        >
          <Col xs="1" md="1">
            {getAppFormattedDateTime(item.booking.end.date)}
          </Col>
          <Col xs="1" md="1">
            {item.booking.functionalId}
          </Col>
          <Col {...colProps}>{item.memberName}</Col>
          <Col xs="1" md="1">
            {item.companyName}
          </Col>
          <Col xs="2" md="2">
            {item.booking.vehicle.registrationNumber}
          </Col>
          <Col xs="2" md="2">
            <div className="bookingsPage_doubleColumnWrap">{item.booking.vehicle.version.model.brand.name}</div>
            {item.booking.vehicle.version.model.name}
          </Col>
          <Col xs="2" md="2">
            <div>
              {item.hasOwnProperty('startInternalCleanliness') ? item.startInternalCleanliness : '-'}/
              {item.hasOwnProperty('startExternalCleanliness') ? item.startExternalCleanliness : '-'}
            </div>
            <div>
              {item.hasOwnProperty('endInternalCleanliness') ? item.endInternalCleanliness : '-'}/
              {item.hasOwnProperty('endExternalCleanliness') ? item.endExternalCleanliness : '-'}
            </div>
          </Col>
          <Col xs="1" md="1">
            {_.get(item, 'numberOfDeclaredDamages', '-')}
          </Col>
        </Row>
      </div>
    );
  }

  handleDeleteChip(item) {
    let { urlParams, dispatch } = this.props;
    delete urlParams[item];
    this.callApi(urlParams);
    dispatch(routeActions.push(routes.feedbacks.path.replace(':search', encodeURIComponent(JSON.stringify(urlParams)))));
  }

  render() {
    const { list, listMetadata, currentSortedIndex, sortIsDescending, feedbacksFiltersFormIsOpen, urlParams, loadingList } = this.props;

    return (
      <div className="feedbacksPage mainContainer_content">
        <div className="pageContainer">
          <div className="feedbacksPage_content">
            <section className="feedbacksPage_management">
              <FilterChips
                urlParams={urlParams}
                onDeleteChip={this.handleDeleteChip}
                id="feedbacks-chips"
                filterTypes={feedbacksFilterTypes}
                translations={feedbackFilterChips}
              />

              <div className="advancedList_actions page">
                <Filters filterTypes={feedbacksFilterTypes} onFilteredSearchForm={this.handleSearcForm} id="feedbacks-filters" />
              </div>

              <AdvancedList
                id="feedbacks-list"
                data={list}
                listMetadata={listMetadata}
                loading={loadingList}
                renderItem={this.renderItem}
                onChangePage={this.handleChangePage}
                onRefreshList={this.handleRefreshList}
                onChangeRowsPerPage={this.handleNumberResultsSelect}
                error={false}
                urlParams={urlParams}
                header={feedbacksHeaderParams}
                onSort={this.handleSort}
              />
            </section>
          </div>
        </div>
      </div>
    );
  }
}

Feedbacks.displayName = 'Feedbacks';

Feedbacks.propTypes = {
  list: PropTypes.array,
  listMetadata: PropTypes.object,
  feedbacksFiltersFormIsOpen: PropTypes.bool
};

export default connect(state => {
  const {
    feedbacks: { paginatedResults, urlParams, currentSortedIndex, sortIsDescending, feedbacksFiltersFormIsOpen, loadingList },
    page: { params }
  } = state;

  return {
    list: _.get(paginatedResults, 'results', []),
    listMetadata: _.get(paginatedResults, 'metadata', {}),
    urlParams,
    currentSortedIndex,
    sortIsDescending,
    feedbacksFiltersFormIsOpen,
    pageParams: params,
    loadingList
  };
})(Feedbacks);
