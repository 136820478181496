import React, { Component, PropTypes } from 'react';
import { connect } from 'react-redux';
import { getValues } from 'redux-form';
import { routeActions } from 'react-router-redux';
import { getStore } from '../../store/all-store';
import _get from 'lodash/get';
import { adjustAddressFormatted, bundleSelector } from '../../selectors/all-selectors';

import TableView from '../../components/TableView/TableView';
import SiteForm from '../../components/SiteForm/SiteForm';
import PageManagementHeader from '../../components/PageManagementHeader/PageManagementHeader';

import {
  updateSiteRequest,
  updateSiteSuccess,
  addFlashMessage,
  getTimezoneIdFromLocation,
  updateTimeZone
} from '../../actions/all-actions';

import { FLASH_MESSAGE_TYPE_SUCCESS } from '../../constants/generic-constants';
import { BACKUSER_ROLE_CALL_CENTER_OPERATOR } from '../../constants/backend-constants';
import routes, { siteEditRules } from '../../constants/routes-constants';
import { addErrorMessage } from '../../utils/utils';
import { checkRole } from '../../constants/backuser-role-rules';
import { createEditSiteInitialValues } from '../../utils/parsers';

const tableViewParams = {
  namespace: 'parking',
  sortIsDescending: false,
  columns: [
    {
      messageKey: 'parking_tableView_label_name',
      content: 'name'
    },
    {
      messageKey: 'parking_tableView_label_privateAccess',
      content: item => {
        return item.privateAccess ? 'true' : 'false';
      },
      contentMessageKey: 'parking_tableView_privateAccess_[content]'
    },
    {
      messageKey: 'parking_tableView_label_electricCharging',
      content: item => {
        return item.electricCharging ? 'true' : 'false';
      },
      contentMessageKey: 'parking_tableView_electricCharging_[content]'
    },
    {
      messageKey: 'parking_tableView_label_disabledAccess',
      content: item => {
        return item.disabledAccess ? 'true' : 'false';
      },
      contentMessageKey: 'parking_tableView_disabledAccess_[content]'
    },
    {
      messageKey: 'parking_tableView_label_alwaysOpen',
      content: item => {
        return item.alwaysOpen ? 'true' : 'false';
      },
      contentMessageKey: 'parking_tableView_alwaysOpen_[content]'
    },
    {
      messageKey: 'parking_tableView_label_gsmConnection',
      content: item => {
        return item.gsmConnection ? 'true' : 'false';
      },
      contentMessageKey: 'parking_tableView_gsmConnection_[content]'
    },
    {
      messageKey: 'parking_tableView_label_action',
      content: (item, props = {}) => (props.viewAccess ? 'common_view' : 'parking_tableView_content_action'),
      contentMessageKey: '[content]',
      actionCustomClass: 'parking_tableView_action',
      actionCallback: function(item) {
        getStore().dispatch(routeActions.push(routes.parkingDetails.path.replace(':parkingId', item.id)));
      }
    }
  ]
};

class SiteDetails extends Component {
  constructor(props) {
    super(props);
    this.bindFunctions();
    this.derivedStateFromProps(props);
  }

  bindFunctions() {
    this.handleUpdateSite = this.handleUpdateSite.bind(this);
    this.handleTimeZoneId = this.handleTimeZoneId.bind(this);
  }

  // noinspection JSCheckFunctionSignatures
  componentWillReceiveProps(props) {
    this.derivedStateFromProps(props);
  }

  derivedStateFromProps(props) {
    this.setReadOnly(props);
  }

  setReadOnly(props) {
    const { role } = props;
    this.readOnly = role === BACKUSER_ROLE_CALL_CENTER_OPERATOR || !checkRole(siteEditRules, role);
  }

  handleUpdateSite() {
    const { dispatch, currentSite, bundle } = this.props;

    dispatch(updateSiteRequest(currentSite))
      .then(site => {
        dispatch(updateSiteSuccess(site));

        dispatch(
          addFlashMessage({
            contentKey: 'siteForm_update_site_success',
            type: FLASH_MESSAGE_TYPE_SUCCESS
          })
        );

        // should be called last
        if (this.props.onSubmit) {
          this.props.onSubmit();
        }
      })
      .catch(error => {
        const msgParams = { bundle, error, errorCodePrefixes: ['addSite_'], def: 'siteForm_update_site_error' };
        dispatch(addErrorMessage(msgParams));
      });
  }

  handleTimeZoneId(position) {
    const { dispatch } = this.props;

    dispatch(getTimezoneIdFromLocation(position, updateTimeZone));
  }

  render() {
    const { currentSite, list, initialValues, detailCompany, currentSubCompany, isModal } = this.props;
    const showDekraDetails = !!_get(detailCompany, 'dekraSettings.dekraCheckService', false);
    const dekraDetails = _get(detailCompany, 'dekraSettings');

    return (
      <div className="siteDetailsPage mainContainer_content">
        <div className="pageContainer">
          <div className="siteDetailsPage_content">
            <section className="siteDetailsPage_management">
              {!isModal && (
                <PageManagementHeader
                  title={currentSite.name}
                  href={`/#${routes.addParking.path.replace(':siteId', currentSite.id)}`}
                  buttonLabelKey="siteDetails_add"
                  limitedAccess={this.readOnly}
                />
              )}
              <SiteForm
                edit
                company={detailCompany}
                subCompany={currentSubCompany}
                isModal={isModal}
                onCancel={this.props.onCancel}
                onCallback={this.handleUpdateSite}
                displayDekra={showDekraDetails}
                dekraDetails={dekraDetails}
                onGetCoordinatesLocationCallback={this.handleTimeZoneId}
                initialValues={initialValues}
                limitedAccess={this.readOnly}
              />
              {!isModal && !!list.length && (
                <TableView
                  viewAccess={this.readOnly}
                  customClass="siteDetailsPage_tableViewWrapper"
                  params={tableViewParams}
                  content={list}
                />
              )}
            </section>
          </div>
        </div>
      </div>
    );
  }
}

SiteDetails.displayName = 'SiteDetails';

SiteDetails.propTypes = {
  isModal: PropTypes.bool,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func
};

export default connect(state => {
  const {
    user: {
      userInfo: { role }
    },
    sites: { currentSite },
    parkings: { list },
    companies: { detailCompany },
    subCompanies: { currentSubCompany }
  } = state;

  return {
    currentSite,
    list,
    role,
    initialValues: createEditSiteInitialValues(currentSite, detailCompany),
    bundle: bundleSelector(state),
    detailCompany,
    currentSubCompany
  };
})(SiteDetails);
