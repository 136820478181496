import React, { Component } from 'react';
import { showParentView } from '../../../components/_v2/ColumnList/helpers';
import BookingViewV2 from '../../../containers/_v2/BookingView/BookingView';
import ListDetails from '../../../components/_v2/ColumnList/Details';
import { getMsg } from '../../../utils/IntlGlobalProvider';

export class BookingDetails extends Component {
  componentWillMount() {
    this.showParentView = showParentView.bind(this);
  }

  render() {
    return (
      <ListDetails title={getMsg('booking_detail_title')} onClose={this.showParentView}>
        <BookingViewV2 withList params={this.props.params} />
      </ListDetails>
    );
  }
}

export default BookingDetails;
