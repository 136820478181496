import * as types from '../constants/actionTypes-constants';
import { safe } from '../utils/utils';

export default function colors(
  state = {
    list: [],
    currentColor: null
  },
  action
) {
  switch (action.type) {
    case types.COLORS_GET_COLORS_SUCCESS:
      return Object.assign({}, state, {
        list: action.colors
      });

    case types.COLORS_CREATE_SUCCESS: {
      return { ...state, list: [{ ...action.color }, ...state.list] };
    }

    case types.COLORS_UPDATE_COLOR_SUCCESS: {
      const { color } = action;
      const { list } = state;

      safe(() => (list.find(item => item.id === color.id).code = color.code));

      return {
        ...state,
        currentColor: color,
        list: safe(() => [...list]) || []
      };
    }

    case types.COLORS_GET_SINGLE_COLOR_SUCCESS:
      return Object.assign({}, state, {
        currentColor: action.color
      });

    default:
      return state;
  }
}
