import React, { Component, PropTypes as T } from 'react';
import moment from 'moment';
// import moment from 'moment-timezone';
import { connect } from 'react-redux';
import _partial from 'lodash/partial';
import { injectIntl } from 'react-intl';
import { Tooltip } from 'react-tippy';
import TooltipCard from './TooltipCard';
import classNames from 'classnames';

import {
  BOOKING_STATUS_COMPLETED,
  BOOKING_STATUS_SCHEDULED,
  STATUS_CANCELED,
  BOOKING_STATUS_IN_PROGRESS,
  BOOKING_STATUS_PRE_BOOKED
} from '../../../../constants/backend-constants';
import {
  addTippyContent,
  getAppFormattedDayMonthTime,
  getBookingColor,
  localTime,
  hex2rgb,
  addOpenNewTabEvents,
  openUrlBackgroundTab,
  hideButtonHoverMsg
} from '../../../../utils/utils';
// import { toggleVehiclePlanningCardV2 } from '../../../../actions/all-actions';
import { routeActions } from 'react-router-redux';
import routes from '../../../../constants/routes-constants';

const leftColumnWidth = '295px';
class VehiclePlanningBooking extends Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  componentWillUnmount() {
    hideButtonHoverMsg();
  }

  handleClick(booking, vehicle, openInNewTab) {
    const { enableNewUi } = this.props;
    let url = '';

    // this.props.dispatch(toggleVehiclePlanningCardV2(booking, vehicle));

    if (enableNewUi) url = `${routes.bookingDetailV2.path.replace(':bookingId', booking.id)}`;
    else url = `${routes.bookingDetail.path.replace(':bookingId', booking.id)}`;

    if (openInNewTab) openUrlBackgroundTab('/#' + url);
    else this.props.dispatch(routeActions.push(url));
  }

  bookingContentTooltip() {
    const { booking, vehicle, readOnly } = this.props;
    return (
      <div className="new-card">
        <TooltipCard readOnly={readOnly} vehicle={vehicle} booking={booking} />
      </div>
    );
  }

  getThemeFromStatus() {
    const { booking } = this.props;
    return booking.status === BOOKING_STATUS_PRE_BOOKED
      ? 'light'
      : booking.status === BOOKING_STATUS_IN_PROGRESS
      ? 'glide'
      : booking.status === BOOKING_STATUS_COMPLETED
      ? 'completed'
      : 'dark';
  }

  bookingContent() {
    return (
      <Tooltip
        className="detail-tooltip"
        useContext
        position="left-start"
        arrow
        {...addTippyContent(this.bookingContentTooltip())}
        delay="400"
        interactive
        theme={this.getThemeFromStatus()}
        interactiveBorder="150"
        sticky
        style={{ display: 'flex' }}
        stickyDuration={0}
      >
        {this.bookingTiming()}
      </Tooltip>
    );
  }

  bookingTiming() {
    const {
      booking,
      intl: { formatMessage },
      locale
    } = this.props;
    let startTime = null;
    let endTime = null;
    let strTiming = '';
    const status = booking.status;
    const isFinished = status === BOOKING_STATUS_COMPLETED;
    const isStarted = status === BOOKING_STATUS_IN_PROGRESS;

    switch (status) {
      case BOOKING_STATUS_COMPLETED:
        startTime = booking.effectiveStartDate;
        endTime = booking.effectiveEndDate;
        break;
      case BOOKING_STATUS_IN_PROGRESS:
        startTime = booking.effectiveStartDate;
        endTime = booking.estimatedEndDate;
        break;
      case STATUS_CANCELED:
      case BOOKING_STATUS_SCHEDULED:
      case BOOKING_STATUS_PRE_BOOKED:
        startTime = booking.estimatedStartDate;
        endTime = booking.estimatedEndDate;
        break;
      default:
    }

    if (startTime && endTime)
      strTiming =
        getAppFormattedDayMonthTime(startTime) + ' ' + formatMessage({ id: 'common_to' }) + ' ' + getAppFormattedDayMonthTime(endTime);

    const delayStart = isStarted || isFinished ? localTime(booking.estimatedEndDate) : moment();
    const delayEnd = booking.status === BOOKING_STATUS_COMPLETED ? localTime(booking.effectiveEndDate) : moment();
    const delay = moment
      .duration(delayStart.diff(delayEnd, 'minutes'), 'minutes')
      .locale(locale)
      .humanize({ m: 60, h: 24, d: 7, w: 4 });
    const memberName = booking.member.firstName + ' ' + booking.member.lastName;

    return (
      <span
        className={classNames('new-vehicle-planning_booking_name', {
          'new-vehicle-planning_booking_name_delayed': booking.delayed
        })}
      >
        {memberName}
        {booking.delayed && ' - ' + formatMessage({ id: 'booking_details_late' }, { duration: delay })}
        {strTiming}
      </span>
    );
  }

  render() {
    const { startDate, periodInDays, booking, vehicle, reservationBuffer, intl } = this.props;
    const status = booking.status;
    const isFinished = status === BOOKING_STATUS_COMPLETED;
    const isStarted = status === BOOKING_STATUS_IN_PROGRESS;
    const bookingDuration = moment(
      localTime(isFinished ? booking.effectiveEndDate : !booking.effectivEndDate && booking.delayed ? moment() : booking.estimatedEndDate)
    ).diff(moment(localTime(isStarted || isFinished ? booking.effectiveStartDate : booking.estimatedStartDate)), 'minutes');

    const reservationBufferWidth = 1440 / reservationBuffer;

    const startingInPx = moment(localTime(isFinished || isStarted ? booking.effectiveStartDate : booking.estimatedStartDate)).diff(
      moment(startDate),
      'minutes'
    );

    // const hasDiffZone = moment.parseZone(isFinished || isStarted ? booking.effectiveStartDate : booking.estimatedStartDate).utcOffset();

    const bookingWidth = bookingDuration <= 0 ? 1440 : 1440 / bookingDuration;
    const isStartingBefore = moment(isFinished || isStarted ? booking.effectiveStartDate : booking.estimatedStartDate).isBefore(
      moment(startDate)
    );
    const isEndingAfter = moment(isFinished ? booking.effectiveEndDate : booking.estimatedEndDate).isAfter(
      moment(startDate).add(periodInDays, 'days')
    );

    const isEndingInPeriod = moment(isFinished ? booking.effectiveEndDate : booking.estimatedEndDate).isBetween(
      moment(startDate),
      moment(startDate).add(periodInDays, 'days'),
      null,
      '[]'
    );
    const isStartingInPeriod = moment(isFinished || isStarted ? booking.effectiveStartDate : booking.estimatedStartDate).isBetween(
      moment(startDate),
      moment(startDate).add(periodInDays, 'days'),
      null,
      '[]'
    );

    const width = isStartingInPeriod
      ? `calc( ((100% - ${leftColumnWidth}) / ${periodInDays}) / ${bookingWidth} )`
      : isStartingBefore && isEndingAfter
      ? `calc(100% - ${leftColumnWidth})`
      : isStartingBefore
      ? `calc( ((100% - ${leftColumnWidth}) / ${periodInDays}) / 1440 * ${bookingDuration + startingInPx} )`
      : `calc( ((100% - ${leftColumnWidth}) / ${periodInDays}) / ${bookingWidth} )`;

    const width2 = isStartingBefore
      ? `calc( ((100% - ${leftColumnWidth}) / ${periodInDays}) / 1440 * ${bookingDuration + startingInPx} )`
      : `calc( ((100% - ${leftColumnWidth}) / ${periodInDays}) / ${bookingWidth} )`;

    const left = isStartingInPeriod
      ? `calc( (((100% - ${leftColumnWidth}) / ${periodInDays}) / 1440 * ${startingInPx}) + ${leftColumnWidth})`
      : isStartingBefore && isEndingAfter
      ? `calc(${leftColumnWidth})`
      : isEndingInPeriod
      ? `calc(${leftColumnWidth})`
      : `calc( (((100% - ${leftColumnWidth}) / ${periodInDays}) / 1440 * ${startingInPx}) + ${leftColumnWidth})`;

    const left2 = isStartingInPeriod
      ? `calc( (((100% - ${leftColumnWidth}) / ${periodInDays}) / 1440 * ${startingInPx}) + ${leftColumnWidth} +
      (((100% - ${leftColumnWidth}) / ${periodInDays}) / ${bookingWidth}))`
      : `${leftColumnWidth}`;

    const delayedWidth =
      isStarted && booking.delayed ? 1440 / moment(moment()).diff(localTime(booking.estimatedEndDate), 'minutes') : false;

    const bookingOnClick = openInNewTab => this.handleClick(booking, vehicle, openInNewTab);

    return (
      <div className="new-vehicle-planning_booking_wrap">
        {!isFinished && (
          <div
            className="new-vehicle-planning_booking_reservationBuffer"
            style={{
              borderRadius: '3px 0 0 3px',
              width: `calc( ((100% - ${leftColumnWidth}) + 1px / ${periodInDays}) / ${reservationBufferWidth})`,
              left: `
            calc( (((100% - ${leftColumnWidth} - 1px) / ${periodInDays}) / 1440 * ${startingInPx}) + ${leftColumnWidth} + 1px -
            (((100% - ${leftColumnWidth} - 1px ) / ${periodInDays}) / ${reservationBufferWidth}))`,
              backgroundColor: `rgba(${hex2rgb(getBookingColor(booking.status))}, .6 )`
            }}
          />
        )}

        <div
          key={booking.id}
          data={booking.member.completedBookings === 0 ? intl.messages.member_first_booking_label : ''}
          className={classNames('new-vehicle-planning_booking', {
            counter0: booking.member.completedBookings === 0,
            borderR: isFinished,
            'new-vehicle-planning_booking_delayed': booking.delayed && !isFinished && !isStartingInPeriod
          })}
          {...addOpenNewTabEvents(bookingOnClick)}
          style={{
            width: booking.delayed && !isFinished && !isStartingInPeriod ? width2 : width,
            left: booking.delayed && !isFinished && !isStartingInPeriod ? left2 : left,
            backgroundColor: isStarted && booking.delayed ? 'red' : getBookingColor(booking.status)
          }}
        >
          {this.bookingContent()}
        </div>

        {!isFinished && (
          <div
            className="new-vehicle-planning_booking_reservationBuffer"
            style={{
              borderRadius: '0 3px 3px 0',
              width: `calc( ((100% - ${leftColumnWidth}) / ${periodInDays}) / ${reservationBufferWidth})`,
              left:
                delayedWidth !== false
                  ? `calc( (((100% - ${leftColumnWidth}) / ${periodInDays}) / 1440 * ${startingInPx}) + ${leftColumnWidth} +
              (((100% - ${leftColumnWidth}) / ${periodInDays}) / ${bookingWidth})) + calc( ((100% - ${leftColumnWidth}) / ${periodInDays}) / ${delayedWidth})`
                  : `calc( (((100% - ${leftColumnWidth}) / ${periodInDays}) / 1440 * ${startingInPx}) + ${leftColumnWidth} +
              (((100% - ${leftColumnWidth}) / ${periodInDays}) / ${bookingWidth}))`,
              backgroundColor: `rgba(${hex2rgb(getBookingColor(booking.status))}, .6 )`
            }}
          />
        )}
      </div>
    );
  }
}

VehiclePlanningBooking.displayName = 'VehiclePlanningBooking';

VehiclePlanningBooking.propTypes = {
  booking: T.object,
  startDate: T.string,
  periodInDays: T.number,
  siteId: T.string,
  sitesList: T.array,
  resetParkingIndex: T.func,
  reservationBuffer: T.number
};

export default connect(state => {
  const {
    vehiclePlanning: { startDate, periodInDays, siteId, loading, data },
    subCompanies: { subCompanySelected },
    i18n: { locale },
    user: { enableNewUi }
  } = state;

  return {
    startDate,
    periodInDays,
    siteId,
    subCompanySelected,
    loading,
    data,
    locale,
    enableNewUi
  };
})(injectIntl(VehiclePlanningBooking));
