import * as types from '../../constants/actionTypes-constants';

export default function advancedList(
  state = {
    filterValue: 'select_filter',
    filterInputText: '',
    filterSelectValue: 'select_filter'
  },
  action
) {
  switch (action.type) {
    case types.ADVANCED_LIST_CHANGE_FILTER_VALUE:
      return {
        ...state,
        filterValue: action.value
      };

    case types.ADVANCED_LIST_CHANGE_FILTER_TEXT_INPUT:
      return {
        ...state,
        filterInputText: action.value
      };

    case types.ADVANCED_LIST_CHANGE_FILTER_SELECT:
      return {
        ...state,
        filterSelectValue: action.value
      };

    default:
      return state;
  }
}
