import callApi from './api-actions';
import * as types from '../constants/actionTypes-constants';
import config from '../constants/config-constants';
import { addErrorMessage, isLocalEnv, safe } from '../utils/utils';
import defaultBrand from '../../mocks/defaultBrand.json';
import urlJoin from 'url-join';
import { successMessage } from './flashMessage-actions';
import { getJsonData } from '../utils/api';

export function getAppBrands() {
  return dispatch => {
    return dispatch(callApi('appBrands', 'getAll')).then(
      data => {
        dispatch({
          type: types.APP_BRANDS_GET_LIST_SUCCESS,
          list: data
        });

        return Promise.resolve();
      },
      () => {
        return Promise.resolve();
      }
    );
  };
}

export function updateAppBrand({ id, ...params }) {
  return dispatch => {
    return dispatch(callApi('appBrands', 'updateBrand', { id, params })).then(
      data => {
        dispatch({ type: types.APP_BRANDS_UPDATE_ITEM_SUCCESS, data });
        dispatch(successMessage);
        return Promise.resolve();
      },
      error => {
        dispatch(addErrorMessage({ error }));
        return Promise.reject(error);
      }
    );
  };
}

function setConfigFetchedState(state) {
  return {
    type: types.APP_BRANDS_SET_CONFIG_FETCHED_STATE,
    state
  };
}

function updateBrandConfig(config) {
  return {
    type: types.APP_BRANDS_UPDATE_CONFIG,
    config
  };
}

function handleLocalBrand() {
  return dispatch => {
    dispatch(updateBrandConfig(defaultBrand));
    dispatch(setConfigFetchedState(true));
  };
}

export function getAwsBanner() {
  return dispatch => {
    const {
      aws: { baseUrl, configFolder, banner }
    } = config;

    const configFolderUrl = urlJoin(baseUrl, configFolder);
    const bannerUrl = urlJoin(configFolderUrl, banner);

    getJsonData(bannerUrl).then(data => {
      const { enabled, message, style = {} } = data || {};

      if (enabled) {
        dispatch(updateBannerMessage(message));
        dispatch(updateBannerStyle(style));
      }
    });
  };
}

export function updateBannerStyle(style) {
  return { type: types.APP_BRANDS_UPDATE_BANNER_STYLE, style };
}

export function updateBannerMessage(message) {
  return { type: types.APP_BRANDS_UPDATE_BANNER_MESSAGE, message };
}

export function updateBannerDisplay(display) {
  return { type: types.APP_BRANDS_UPDATE_BANNER_DISPLAY, display };
}

export function getAwsBrand() {
  return dispatch => {
    const {
      aws: { baseUrl, configFolder, brandMap, brandsFolder }
    } = config;

    const configFolderUrl = urlJoin(baseUrl, configFolder);
    const mapUrl = urlJoin(configFolderUrl, brandMap);

    if (isLocalEnv()) {
      dispatch(handleLocalBrand());
      return;
    }

    const handleError = () => {
      dispatch(loaderShowError());
    };

    getJsonData(mapUrl).then(map => {
      const { [window.location.hostname]: configPathPart } = map || {};
      const configUrl = safe(() => urlJoin(configFolderUrl, brandsFolder, configPathPart));

      if (configPathPart) {
        getJsonData(configUrl).then(config => {
          const { theme } = config || {};

          if (theme) {
            dispatch(updateBrandConfig(config));
            dispatch(setConfigFetchedState(true));
          } else handleError();
        }, handleError);
      } else handleError();
    }, handleError);
  };
}

export function clearAppBrands() {
  return { type: types.APP_BRANDS_CLEAR_LIST_SUCCESS };
}

export function loaderOnRouteLoad() {
  return { type: types.APP_BRANDS_ROUTE_LOADED };
}

export function loaderShowError() {
  return { type: types.APP_BRANDS_SHOW_ERROR };
}
