import _pick from 'lodash/pick';
import _get from 'lodash/get';

import * as types from '../../constants/actionTypes-constants';
import callApi from '../../actions/api-actions';
import { trySet, trimValues, setApiCompanyIds, append } from '../../utils/utils';
import { FLASH_MESSAGE_TYPE_ERROR, FLASH_MESSAGE_TYPE_SUCCESS } from '../../constants/generic-constants';
import { addFlashMessage } from '../../actions/all-actions';
import { checkRole, subCompanyListRules, subCompanySelectRules, superCompanyListRules } from '../../constants/backuser-role-rules';
import { cleanDeep } from '../../utils/cleanDeep';

export function getBackUsersList(params, loader = false) {
  return (dispatch, getState) => {
    const state = getState();

    let paramsObj;

    if (params) {
      let pageNumber = params.page ? params.page.number : state.backUsers.paginatedResults.metadata.paginationInfo.currentPage;
      let pageSize = params.page ? params.page.size : state.backUsers.paginatedResults.metadata.paginationInfo.pageSize;

      paramsObj = {
        page: {
          number: pageNumber,
          size: pageSize
        }
      };

      let sortProperty = params.sort ? params.sort.property : state.backUsers.currentSortedIndex;
      let sortIsDescending = params.sort ? params.sort.isDescending : state.backUsers.sortIsDescending;

      let sort = {
        property: sortProperty,
        isDescending: sortIsDescending
      };

      if (sort.property) {
        paramsObj = Object.assign(paramsObj, { sort });
      }

      setApiCompanyIds(paramsObj, state);

      paramsObj = Object.assign({}, paramsObj, _pick(params, ['firstName', 'lastName', 'email', 'role']));
    } else {
      paramsObj = null;
    }

    dispatch({
      type: types.BACK_USERS_GET_LIST_REQUEST
    });

    return dispatch(callApi('backUsers', 'getList', paramsObj, loader)).then(
      function(data) {
        dispatch({
          type: types.BACK_USERS_GET_LIST_SUCCESS,
          paginatedObj: data,
          urlParams: paramsObj
        });
        return data;
      },
      () => {
        dispatch({
          type: types.BACK_USERS_GET_LIST_ERROR
        });
      }
    );
  };
}

export function clearBackUsersListData() {
  return {
    type: types.BACK_USERS_EMPTY_LIST
  };
}

export function createBackUserRequest() {
  return (dispatch, getState) => {
    const state = getState();

    const { selectedCompanies, selectedSubCompanies } = state.backUsers;
    const { firstName, lastName, login, phoneNumber, address, userRole, subCompanyId } = trimValues(state.form.backUserForm);
    const { phonePrefix, phoneSuffix } = phoneNumber || {};

    let params = {
      firstName,
      lastName,
      login,
      phoneNumber: {
        countryCode: phonePrefix,
        nationalNumber: phoneSuffix
      },
      address,
      userRole,
      locale: state.i18n.locale
    };

    if (checkRole(superCompanyListRules, userRole)) trySet(params, 'companyIds', append(selectedCompanies));
    else if (checkRole(subCompanyListRules, userRole)) trySet(params, 'companyIds', append(selectedSubCompanies));
    else if (checkRole(subCompanySelectRules, userRole)) trySet(params, 'companyIds', append([subCompanyId]));

    dispatch({
      type: types.BACK_USERS_CREATE_USER_REQUEST,
      params
    });

    return dispatch(callApi('backUsers', 'createBackUser', cleanDeep(params)));
  };
}

export function createBackUserSuccess(backUser) {
  return {
    type: types.BACK_USERS_CREATE_USER_SUCCESS,
    backUser
  };
}

export function getBackUserDetail(id) {
  return dispatch => {
    dispatch({
      type: types.BACK_USERS_GET_DETAIL_USER_REQUEST
    });

    return dispatch(callApi('backUsers', 'getBackUserDetail', id)).then(function(data) {
      dispatch({
        type: types.BACK_USERS_GET_DETAIL_USER_SUCCESS,
        backUser: data
      });
      return data;
    });
  };
}

export function updateBackUserRequest(backUser) {
  return (dispatch, getState) => {
    const state = getState();

    const { firstName, lastName, login, phoneNumber, address, userRole, subCompanyId } = trimValues(state.form.backUserForm);
    const { selectedCompanies, selectedSubCompanies, detailBackUser } = state.backUsers;
    const { phonePrefix, phoneSuffix } = phoneNumber || {};
    let params = {
      firstName,
      lastName,
      login,
      phoneNumber: {
        countryCode: phonePrefix,
        nationalNumber: phoneSuffix
      },
      role: userRole,
      locale: detailBackUser.locale || 'en'
    };

    if (address) trySet(params, 'address', append({ ...address }));
    let chosenCompanies = null;
    if (checkRole(superCompanyListRules, userRole)) chosenCompanies = selectedCompanies;
    if (checkRole(subCompanyListRules, userRole)) chosenCompanies = selectedSubCompanies;
    if (checkRole(subCompanySelectRules, userRole)) chosenCompanies = [subCompanyId];

    trySet(params, 'companyIds', chosenCompanies);

    dispatch({
      type: types.BACK_USERS_UPDATE_BACK_USER_REQUEST,
      params
    });

    return dispatch(callApi('backUsers', 'updateBackUser', cleanDeep({ backUserId: backUser.id, params })));
  };
}

export function updateBackUserSuccess(backUser) {
  return {
    type: types.BACK_USERS_UPDATE_BACK_USER_SUCCESS,
    backUser
  };
}

export function openStatusBackUserModal(backUser) {
  return {
    type: types.BACK_USERS_OPEN_STATUS_BACK_USER_MODAL,
    backUser
  };
}

export function closeStatusBackUserModal() {
  return {
    type: types.BACK_USERS_CLOSE_STATUS_BACK_USER_MODAL
  };
}

export function toggleStatusBackUser() {
  return {
    type: types.BACK_USERS_STATUS_BACK_USER_TOGGLE
  };
}

export function toggleModalResetPasswordBackUser() {
  return {
    type: types.BACK_USERS_RESET_PASSWORD_BACK_USER_MODAL_TOGGLE
  };
}

export function requestResetPasswordBackUser(backUserId) {
  return dispatch => {
    return dispatch(callApi('backUsers', 'resetPasswordBackUser', { id: backUserId }))
      .then(() => {
        dispatch(toggleModalResetPasswordBackUser());
        dispatch(
          addFlashMessage({
            contentKey: `backUser_password_updated_success`,
            type: FLASH_MESSAGE_TYPE_SUCCESS
          })
        );
      })
      .catch(() => {
        dispatch(toggleModalResetPasswordBackUser());
        dispatch(
          addFlashMessage({
            contentKey: `backUser_password_updated_error`,
            type: FLASH_MESSAGE_TYPE_ERROR
          })
        );
      });
  };
}

export function requestChangeStatusBackUser(backUser) {
  return (dispatch, getState) => {
    const state = getState();

    let backUserId = _get(backUser, 'id');
    const suspendBackUser = _get(state, 'backUsers.isSuspendedBackUser');
    const action = suspendBackUser ? 'suspend' : 'unsuspend';

    dispatch({
      type: types.BACK_USERS_CHANGE_STATUS_BACK_USER_REQUEST,
      params: {
        backUser,
        suspendBackUser
      }
    });

    return dispatch(callApi('backUsers', 'changeStatusBackUser', { backUserId, suspendBackUser }))
      .then(data => {
        dispatch(callChangeStatusBackUserSuccess(data));
      })
      .then(() => {
        dispatch(
          addFlashMessage({
            contentKey: `member_${action}_success`,
            type: FLASH_MESSAGE_TYPE_SUCCESS
          })
        );
      })
      .catch(error => {
        if (!error.type) {
          // make sure native errors not swallowed
          console.error(error.stack); // eslint-disable-line
        }
        dispatch(
          addFlashMessage({
            contentKey: `members_detail_${action}_failed`,
            type: FLASH_MESSAGE_TYPE_ERROR
          })
        );
      });
  };
}

export function callChangeStatusBackUserSuccess(backUser) {
  return {
    type: types.BACK_USERS_CHANGE_STATUS_BACK_USER_SUCCESS,
    backUser
  };
}

export function toggleBackUsersFilters() {
  return {
    type: types.BACK_USERS_FILTERS_FORM_OPENED_STATE_TOGGLE
  };
}

export function setBackUserCompanies(availableCompanies, selectedCompanies) {
  return {
    type: types.BACK_USERS_SET_COMPANIES,
    availableCompanies,
    selectedCompanies
  };
}

export function setBackUserSubCompanies(availableSubCompanies, selectedSubCompanies) {
  return {
    type: types.BACK_USERS_SET_SUBCOMPANIES,
    availableSubCompanies,
    selectedSubCompanies
  };
}

export function removeBackUserCompanies() {
  return {
    type: types.BACK_USERS_REMOVE_COMPANIES
  };
}

export function openBackUserEmailModal() {
  return {
    type: types.BACK_USERS_EMAIL_MODAL_OPEN
  };
}

export function closeBackUserEmailModal() {
  return {
    type: types.BACK_USERS_EMAIL_MODAL_CLOSE
  };
}

export function changeBackUserEmailRequest(userId) {
  return (dispatch, getState) => {
    const { login } = trimValues(getState().form.changeEmailForm);

    return dispatch(callApi('backUsers', 'changeEmail', { userId, login })).then(
      payload => {
        dispatch({ type: types.BACK_USERS_CHANGE_EMAIL_MEMBER_SUCCESS, payload });
        dispatch(closeBackUserEmailModal());

        dispatch(
          addFlashMessage({
            contentKey: 'changeEmail_member_success',
            type: FLASH_MESSAGE_TYPE_SUCCESS
          })
        );
      },
      () =>
        dispatch(
          addFlashMessage({
            contentKey: 'changeEmail_member_error',
            type: FLASH_MESSAGE_TYPE_ERROR
          })
        )
    );
  };
}
