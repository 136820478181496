import React, { Component } from 'react';
import cs from 'classnames';
import ReactSVG from 'react-svg';

import { connect } from 'react-redux';
import { getFirstObjKey, getFirstObjValue, safe, testProp } from '../../utils/utils';
import ListDetails from '../../components/_v2/ColumnList/Details';
import { getMsg } from '../../utils/IntlGlobalProvider';

import { headerContractReportingValuesSelector } from '../../selectors/all-selectors';
import routes from '../../constants/routes-constants';
import { routeActions } from 'react-router-redux';
import urlJoin from 'url-join';
import { quickSightIds, mapReportingFieldsToPaths, quickSightTabFields } from '../../constants/options-constants';
import QsDashboard from './Dasbhoard';
import { FormattedMessage } from 'react-intl';

class QuickSight extends Component {
  componentWillMount() {
    this.initVars();
    this.setCallbacks();
    this.initProps();
  }

  initVars() {
    this.menuItems = {};
  }

  initProps() {
    this.componentPropsUpdated(this.props);
    this.propsInit = true;
  }

  componentWillReceiveProps(props) {
    this.componentPropsUpdated(props);
  }

  // new props
  componentPropsUpdated(props) {
    this.handleFiltering(props); // 1
    this.handleRedirect(props); // 2
  }

  handleFiltering(props) {
    const { reportingRules } = props;

    if (testProp.call(this, props, { reportingRules })) {
      this.menuItems = {};

      for (let fieldName of quickSightTabFields) {
        const path = mapReportingFieldsToPaths[fieldName];

        if (reportingRules[fieldName]) {
          this.menuItems[path] = quickSightIds[path];
        }
      }
    }
  }

  handleRedirect(props) {
    const { reportingRules } = props;

    if (testProp.call(this, props, { reportingRules })) {
      const firstPath = getFirstObjKey(this.menuItems);
      const childPath = this.getChildPath(props);
      const currentPathAllowed = this.menuItems[childPath];

      if (!currentPathAllowed && childPath && firstPath && childPath !== firstPath) {
        this.changeRouteToTab(firstPath);
      }
    }
  }

  setCallbacks() {
    this.handleClose = () => {
      this.props.dispatch(routeActions.push(routes.quickSight.path));
    };
  }

  getChildPath(props = this.props) {
    return safe(() => props.page.props.route.path);
  }

  changeRouteToTab(path) {
    this.props.dispatch(routeActions.push(urlJoin(routes.quickSight.path, path)));
  }

  displayMenuItems() {
    const path = this.getChildPath();
    const items = [];

    for (let key in this.menuItems) {
      const item = this.menuItems[key];
      const selected = path === key;

      items.push(
        <div
          key={key}
          className={cs('sc-item', { 'sc-selected': selected, 'main-bg': selected })}
          onClick={() => this.changeRouteToTab(key)}
        >
          <div className="sc-name">{getMsg(item.titleKey)}</div>
          <ReactSVG className="align-svg" src="/img/arrow-left.svg" />
        </div>
      );
    }

    return items;
  }

  render() {
    const noItems = !getFirstObjValue(this.menuItems);
    const path = this.getChildPath();
    const { titleKey, infoKey } = quickSightIds[path] || {};
    const { dashboardId } = this.menuItems[path] || {};
    const title = titleKey && getMsg(titleKey);
    const hideChild = !noItems && !titleKey;

    return (
      <div className={cs('pageContainer', 'mainContainer_content')}>
        <div className={cs('quick-sight', 'side-list', { 'sc-no-items': noItems, 'sc-hide-details': hideChild })}>
          <div className="sc-list">{this.displayMenuItems()}</div>
          <ListDetails title={title} onClose={this.handleClose}>
            <div className="qs-dashboard-wrap">
              <div className="qs-dashboard-info">
                <div className="qs-info-icon">?</div>
                {infoKey && <FormattedMessage id={infoKey} />}
              </div>
              <QsDashboard dashboardId={dashboardId} />
            </div>
          </ListDetails>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  reportingRules: headerContractReportingValuesSelector(state)
});

QuickSight = connect(mapStateToProps)(QuickSight);

export default QuickSight;
