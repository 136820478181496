import moment from 'moment';
import axios from 'axios';
import _values from 'lodash/values';
import * as types from '../constants/actionTypes-constants';
import callApi from './api-actions';
import { downloadUrlContent, addErrorMessage, safe } from '../utils/utils';
import { addFlashMessage } from './all-actions';
import { bundleSelector } from '../selectors/all-selectors';

import { FLASH_MESSAGE_TYPE_ERROR, FLASH_MESSAGE_TYPE_SUCCESS } from '../constants/generic-constants';
import { partialErrorCodes } from '../constants/options-constants';
import { addErrorMsg } from '../utils/flashMessage/creator';
import { getMsg } from '../utils/IntlGlobalProvider';

export function exportFile(fileId, type) {
  return dispatch => {
    return dispatch(callApi('file', 'getFileUrl', fileId, false))
      .then((data = {}) => {
        if (data.partialFile) {
          dispatch(poolExport(fileId, type));
        } else {
          downloadUrlContent(data.url, type + '-' + moment().format('YYYY-MM-DD HH:mm:ss'));
          dispatch(
            addFlashMessage({
              contentKey: type + '_download_success',
              type: FLASH_MESSAGE_TYPE_SUCCESS
            })
          );
        }
      })
      .catch(() => {
        dispatch(
          addFlashMessage({
            contentKey: type + '_download_failed',
            type: FLASH_MESSAGE_TYPE_ERROR
          })
        );
      });
  };
}

export function poolExport(fileId, type) {
  return dispatch => {
    const timer = setTimeout(() => {
      clearTimeout(timer);
      dispatch(exportFile(fileId, type));
    }, 10000);
  };
}

export function exportUrl(id) {
  return dispatch => {
    const handleDownloadError = () => {
      addErrorMsg(getMsg('bookings_download_failed'));
      dispatch({ type: types['BOOKINGS_EXPORTV3_FINISHED'] });
      window.localStorage.removeItem('idExportBookings');
    };

    return dispatch(callApi('bookings', 'getExportUrl', id, false))
      .then(data => {
        window.localStorage.removeItem('idExportBookings');

        if (!safe(() => data.url)) {
          return handleDownloadError();
        }

        downloadUrlContent(data.url, 'bookings-' + moment().format('YYYY-MM-DD HH:mm:ss'));

        dispatch(
          addFlashMessage({
            contentKey: 'bookings_download_success',
            type: FLASH_MESSAGE_TYPE_SUCCESS
          })
        );
        dispatch({
          type: types['BOOKINGS_EXPORTV3_FINISHED']
        });
      })
      .catch(error => {
        const v3exportError = safe(() => error.message === 'booking.export.finishedWithError');

        if ((error.status === 404 || error.status === 422) && !v3exportError) {
          dispatch(pollingExport(id));
        } else {
          handleDownloadError();
        }
      });
  };
}

export function exportMembersUrl(id) {
  return dispatch => {
    return dispatch(callApi('members', 'getExportUrl', id, false))
      .then(data => {
        window.localStorage.removeItem('idExportMembers');
        downloadUrlContent(data.url, 'members-' + moment().format('YYYY-MM-DD HH:mm:ss'));

        dispatch(
          addFlashMessage({
            contentKey: 'members_download_success',
            type: FLASH_MESSAGE_TYPE_SUCCESS
          })
        );
        dispatch({
          type: types['MEMBERS_EXPORT_FINISHED']
        });
      })
      .catch(error => {
        if (error.status === 404 || error.status === 422) {
          dispatch(pollingMembersExport(id));
        } else {
          dispatch(
            addFlashMessage({
              contentKey: 'members_download_failed',
              type: FLASH_MESSAGE_TYPE_ERROR
            })
          );
          dispatch({
            type: types['MEMBERS_EXPORT_FINISHED']
          });

          window.localStorage.removeItem('idExportMembers');
        }
      });
  };
}

export function exportStatusesUrl(id) {
  return dispatch => {
    return dispatch(callApi('vehicles', 'getExportUrl', id, false))
      .then(data => {
        window.localStorage.removeItem('idExportStatuses');

        downloadUrlContent(data, 'statuses-' + moment().format('YYYY-MM-DD HH:mm:ss'));
        dispatch(
          addFlashMessage({
            contentKey: 'statuses_download_success',
            type: FLASH_MESSAGE_TYPE_SUCCESS
          })
        );
        dispatch({
          type: types['VEHICLE_STATUSES_GET_EXPORT_FINISHED']
        });
      })
      .catch(error => {
        if (error.status === 404) {
          dispatch(pollingStatusesExport(id));
        } else {
          dispatch(
            addFlashMessage({
              contentKey: 'statuses_download_error',
              type: FLASH_MESSAGE_TYPE_ERROR
            })
          );
          dispatch({
            type: types['VEHICLE_STATUSES_GET_EXPORT_FINISHED']
          });
          window.localStorage.removeItem('idExportStatuses');
        }
      });
  };
}

export function pollingExport(id) {
  return dispatch => {
    const timer = setTimeout(() => {
      clearTimeout(timer);
      dispatch(exportUrl(id));
    }, 10000);
  };
}

export function pollingMembersExport(id) {
  return dispatch => {
    const timer = setTimeout(() => {
      clearTimeout(timer);
      dispatch(exportMembersUrl(id));
    }, 10000);
  };
}

export function pollingStatusesExport(id) {
  return dispatch => {
    const timer = setTimeout(() => {
      clearTimeout(timer);
      dispatch(exportStatusesUrl(id));
    }, 1000);
  };
}

export const webFileUpload = (file, apiUrl) => {
  return () => {
    const bodyFormData = new FormData();
    bodyFormData.append('file', file);

    return axios({
      method: 'post',
      url: apiUrl + '/v3/files',
      data: bodyFormData,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
      .then(response => {
        return Promise.resolve(response.data);
      })
      .catch(error => {
        return Promise.reject(error);
      });
  };
};

export function uploadFile(file, field = { onChange: () => '' }, apiUrl, isPublicUpload = true) {
  return (dispatch, getState) => {
    const state = getState();
    const bundle = bundleSelector(state);

    if (file) {
      let action = callApi('file', 'upload', { file, isPublicUpload }, false);
      if (apiUrl) action = webFileUpload(file, apiUrl);

      return dispatch(action).then(
        data => {
          safe(() => field.onChange(data.id));
          return Promise.resolve(data);
        },
        error => {
          const partialErrors = _values(partialErrorCodes.files);
          const msgParams = { bundle, error, partialErrors };

          dispatch(addErrorMessage(msgParams));
          return Promise.reject(error);
        }
      );
    }
  };
}

export function importedFileSummary(data) {
  return dispatch => {
    dispatch({
      type: types.COMPANIES_FILE_IMPORTED_SUCCESS,
      data
    });
  };
}

export function importedFailedFileSummary(data) {
  return dispatch => {
    dispatch({
      type: types.COMPANIES_FILE_IMPORTED_ERROR,
      data
    });
  };
}

export function emptyImportedFiles() {
  return dispatch => {
    dispatch({
      type: types.COMPANIES_FILES_IMPORTED_EMPTY
    });
  };
}

export function deleteImportedFile(data) {
  return dispatch => {
    dispatch({
      type: types.COMPANIES_FILE_IMPORTED_CLEAN,
      data
    });
  };
}

export function clearImportFileFailed(data) {
  return dispatch => {
    dispatch({
      type: types.COMPANIES_FILE_IMPORTED_CLEAR_ERROR,
      data
    });
  };
}

export function fileUploadResetState() {
  return {
    type: types.FILE_UPLOAD_RESET_STATE
  };
}

export function fileUpload(props) {
  return dispatch => {
    return dispatch(callApi('file', 'upload', props)).then(
      file => Promise.resolve(file),
      error => {
        dispatch(addErrorMessage({ error }));
        return Promise.reject(error);
      }
    );
  };
}

export function uploadFileGetUrl(file) {
  return dispatch => {
    return dispatch(fileUpload({ file })).then(({ id }) => dispatch(getPublicFileUrl(id)));
  };
}

export function getPublicFileUrl(publicId) {
  return dispatch => {
    return dispatch(callApi('file', 'getPublicFile', publicId)).then(
      ({ url }) => Promise.resolve(url),
      error => {
        dispatch(addErrorMessage({ error }));
        return Promise.reject(error);
      }
    );
  };
}

export function getFileUrl(id) {
  return dispatch => {
    return dispatch(callApi('file', 'getFileUrl', id, false)).then(
      ({ url }) => Promise.resolve(url),
      error => {
        dispatch(addErrorMessage({ error }));
        return Promise.reject(error);
      }
    );
  };
}

export function getBasicFile(id) {
  return dispatch => {
    return dispatch(callApi('file', 'getFileContent', id, false)).then(
      data => Promise.resolve(data),
      error => {
        dispatch(addErrorMessage({ error }));
        return Promise.reject(error);
      }
    );
  };
}
