import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import {
  getCurrentPath,
  resolveActive,
  resolveSelected,
  showNextView,
  updateColumnFilters
} from '../../../../components/_v2/ColumnList/helpers';
import ColumnList from '../../../../components/_v2/ColumnList';
import cs from 'classnames';
import Filters from './Filters';
import { invoicesFilterChipsV2 } from '../../../../constants/options-constants';
import { getMsg } from '../../../../utils/IntlGlobalProvider';
import FilterChips from '../../../../components/FilterChips/FilterChips';
import { regexDate, regexGuid, regexInvoiceNumber, regexShortId } from '../../../../constants/regex';
import { createElement } from '../../../../utils/component';
import { DMY_FORMAT, ISO_DATE_FLAT } from '../../../../constants/generic-constants';
import routes from '../../../../constants/routes-constants';
import moment from 'moment';
import Fuse from 'fuse.js';

import {
  getAppFormattedDateTime,
  getCurrencySym,
  getFormValues,
  getShortId,
  getUriObj,
  hasValues,
  isObjEmpty,
  newProp,
  removeMultiSpace,
  safe
} from '../../../../utils/utils';

import {
  invoicesExportingSelector,
  invoicesFiltersSelector,
  invoicesLoadingSelector,
  invoicesResultsSelector,
  invoicesTotalResultsSelector,
  localeSelector
} from '../../../../selectors/all-selectors';

import {
  exportInvoicesListWithSearch,
  getInvoicesList,
  retryPayment,
  setStatusCreateInvoiceV2
} from '../../../../actions/invoices-actions';

import { invoicesFilterTypesV2 } from '../../../../constants/filterTypes-constants';
import { invoiceStatuses } from '../../../../constants/filters-constants';
import { cssColors } from '../../../../constants/style-constants';
import { addInfoMsg } from '../../../../utils/flashMessage/creator';
import { routeActions } from 'react-router-redux';
import { apiParams } from '../../../../constants/api-params-constants';

import InvoiceListIcon from './Icon';
import ExportInvoicesIcon from 'material-ui/svg-icons/file/file-download';
import AddInvoiceIcon from 'material-ui/svg-icons/content/add';
import { URL_ALL } from '../../../../constants/backend-constants';

const fetchAmount = 50;
const fuseSearchKey = 'search';

const fuseOptions = {
  keys: [fuseSearchKey],
  minMatchCharLength: 2,
  threshold: 0.2
};

const getDatasetTypes = (childNodes, data) => {
  const { matches } = data;
  let indexes = { static: [] };

  for (let i = 0; i < matches.length; i++) {
    const { key, search, group } = matches[i].value;
    const node = childNodes[i];
    const item = group || key;

    if (node) {
      if (search) {
        if (!indexes[item]) indexes[item] = [];
        indexes[item].push(node);
      } else {
        indexes.static.push(node);
      }
    }
  }

  return indexes;
};

const addFuseResults = (results, fuse) => {
  for (let i = 0; i < fuse.length; i++) {
    results.push(fuse[i].item);
  }
};

const addSearchKey = (group, label) => ({
  search: group + ' ' + label,
  label
});

const getCommonResults = (key, options) => {
  const group = getMsg(acLabels[key]);

  return options.map(item => {
    return { key, value: item.value, ...addSearchKey(group, getMsg(item.labelKey)) };
  });
};

const getStatusResults = () => {
  return getCommonResults('status', invoiceStatuses);
};

const acLabels = {
  reference: 'common_placeHolder_searchForInvoiceNumber',
  startDate: 'invoices_tableView_label_issueDateFrom',
  endDate: 'invoices_tableView_label_issueDateTo',
  bookingId: 'common_booking_id',
  static: 'common_search_by',
  status: 'common_status'
};

const acDateResults = [{ key: 'startDate' }, { key: 'endDate' }];

const dateFormatter = date => moment(date, DMY_FORMAT).format(ISO_DATE_FLAT);

const dateFormats = {
  startDate: dateFormatter,
  endDate: dateFormatter
};

const formatSearchSelect = (selection, input) => {
  const { key, value } = selection.value || {};
  const formatter = dateFormats[key];

  if (value) {
    return { [key]: value };
  }
  if (formatter) {
    return { [key]: formatter(input) };
  }
  return { [key]: input };
};

const appendDataset = (list, nodes, title, subTitle) => {
  const dataset = createElement('div', 'sc-dataset');
  const suggestions = createElement('div', 'sc-suggestions');

  if (title) {
    const header = createElement('div', 'sc-header');
    const titleEl = createElement('span', 'sc-title');

    titleEl.textContent = title;
    header.appendChild(titleEl);

    if (subTitle) {
      const subTitleEl = createElement('span', 'sc-value');
      subTitleEl.textContent = ' ' + subTitle;
      header.appendChild(subTitleEl);
    }

    dataset.appendChild(header);
  }

  suggestions.append(...nodes);
  dataset.appendChild(suggestions);
  list.appendChild(dataset);

  return dataset;
};

class InvoicesList extends Component {
  componentWillMount() {
    this.updateMenu();
    this.setVars();
    this.setCallbacks();
    this.initProps();
  }

  setVars() {
    this.state = {};
  }

  initProps() {
    this.componentPropsUpdated(this.props);
    this.propsInit = true;
  }

  componentWillReceiveProps(props) {
    this.componentPropsUpdated(props);
  }

  componentPropsUpdated(props) {
    const newLocale = newProp.call(this, props, 'locale');

    if (newProp.call(this, props, 'invoicesExporting')) {
      this.updateMenu({ exporting: props.invoicesExporting });
    }

    if (newLocale) {
      this.fuseStatus = new Fuse(getStatusResults(), fuseOptions);
    }
  }

  detailsOpen() {
    return safe(() => this.props.params.invoiceRef);
  }

  setCallbacks() {
    this.handleView = (data = {}, _, { openInNewTab }) => {
      showNextView.call(this, 'invoiceDetails', ':invoiceRef', data.reference, openInNewTab);
    };

    this.handleCreateInvoice = () => {
      const { dispatch } = this.props;
      const encodedUri = encodeURIComponent(JSON.stringify(apiParams.default));

      dispatch(setStatusCreateInvoiceV2(getCurrentPath.call(this)));
      dispatch(routeActions.push(routes.addInvoice.path.replace(':search', encodedUri)));
    };

    this.handleExportInvoices = () => {
      if (!this.props.invoicesExporting) {
        addInfoMsg(getMsg('common_button_exporting'));
        this.props.dispatch(exportInvoicesListWithSearch(this.props.filters));
      }
    };

    this.getAcList = (list, data) => {
      const { static: regex, ...types } = getDatasetTypes(list.childNodes, data);
      const labelPrefix = getMsg(acLabels.static);

      for (const type in types) {
        const subTitle = `(${getMsg(acLabels[type])})`;
        appendDataset(list, types[type], labelPrefix, subTitle);
      }

      if (regex.length) {
        appendDataset(list, regex, labelPrefix);
      }
    };

    this.getAcResult = (item, { value: data }) => {
      const { key, label } = data || {};
      const children = [];

      const labelKey = acLabels[key];
      const result = createElement('span', 'sc-label');

      result.innerHTML = label ? label : getMsg(labelKey);
      children.push(result);

      item.replaceChildren(...children);
    };

    this.getAcData = query => {
      return new Promise(resolve => {
        const results = [];

        query = removeMultiSpace(query);
        if (!query) return resolve(results);

        addFuseResults(results, this.fuseStatus.search(query));

        if (regexShortId.test(query) || regexGuid.test(query)) {
          results.push({ key: 'bookingId' });
        }
        if (regexInvoiceNumber.test(query)) {
          results.push({ key: 'reference' });
        }
        if (regexDate.test(query)) {
          results.push(...acDateResults);
        }

        resolve(results);
      });
    };

    this.getMoreResults = () => {
      if (this.props.loading) return;

      const { list, totalResults, dispatch } = this.props;
      const currResults = safe(() => list.length) || 0;

      if (totalResults && totalResults > currResults) {
        const params = safe(() => getUriObj(this.props.params.invoicesFilters));
        const diff = totalResults - currResults;
        const increase = fetchAmount > diff ? diff : fetchAmount;
        const size = currResults + increase;

        dispatch(getInvoicesList({ ...params, number: 1, size }, { withPdf: false }));
      }
    };

    this.handleDeleteChip = item => {
      const { [item]: omit, ...filters } = this.props.filters;
      const values = isObjEmpty(filters) ? URL_ALL : filters;

      updateColumnFilters.call(this, values);
    };

    this.handleSearchSelect = (event = {}) => {
      const { query = '', selection = {} } = event.detail || {};
      const data = formatSearchSelect(selection, removeMultiSpace(query));

      updateColumnFilters.call(this, { ...this.props.filters, ...data });
    };

    this.handleFiltersApply = () => {
      const values = getFormValues('invoicesFilters');

      if (hasValues(values)) {
        updateColumnFilters.call(this, values);
      } else {
        updateColumnFilters.call(this, URL_ALL);
      }
    };

    this.handleFiltersReset = () => {
      updateColumnFilters.call(this, URL_ALL);
    };

    this.renderItem = (i = {}) => {
      const u = getMsg('common_unknown');

      const {
        bookingId = '',
        reference = u,
        emissionDate = u,
        email = u,
        firstName = '',
        lastName = '',
        priceIncludingTaxes: priceVat = 0,
        priceExcludingTaxes: priceNoVat = 0,
        taxes = 0,
        currency = ''
      } = i;

      const priceShort = priceVat + ' ' + getCurrencySym(currency);
      const priceDetails = priceVat ? `(${priceNoVat} + ${taxes})` : '';
      const date = getAppFormattedDateTime(emissionDate, { v2: true });
      const client = (firstName + ' ' + lastName).trim() || u;
      const shortId = getShortId(bookingId) || u;

      return (
        <div className="sc-list-items">
          <div className={cs('sc-list-item', 'sc-icon-item')}>
            <InvoiceListIcon invoice={i} />
          </div>
          <div className="sc-list-item">
            <div className="sc-multi-item">
              <span title={reference}>{reference}</span>
              <span title={date}>{date}</span>
            </div>
          </div>
          <div className="sc-list-item">
            <span title={shortId}>{shortId}</span>
          </div>
          <div className="sc-list-item">
            <div className="sc-multi-item">
              <span title={client}>{client}</span>
              <span title={email}>{email}</span>
            </div>
          </div>
          <div className="sc-list-item">
            <div className="sc-multi-item">
              <span title={priceShort}>{priceShort}</span>
              <span title={priceDetails}>{priceDetails}</span>
            </div>
          </div>
        </div>
      );
    };

    this.renderHeader = () => {
      const number = getMsg('invoices_tableView_label_invoiceNumber');
      const bookingId = getMsg('bookings_tableView_label_bookingId');
      const client = getMsg('invoices_tableView_label_memberName');
      const amount = getMsg('common_amount');

      return (
        <div className="sc-list-header">
          <div className="sc-item-wrap">
            <div className={cs('sc-list-item', 'sc-icon-header')}>
              <span title={number}>{number}</span>
            </div>
            <div className="sc-list-item">
              <span title={bookingId}>{bookingId}</span>
            </div>
            <div className="sc-list-item">
              <span title={client}>{client}</span>
            </div>
            <div className="sc-list-item">
              <span title={amount}>{amount}</span>
            </div>
          </div>
        </div>
      );
    };

    this.resolveSelected = resolveSelected.bind(this);
    this.resolveActive = resolveActive.bind(this);
  }

  getFilters() {
    return <Filters initialValues={this.props.filters} />;
  }

  getFilterChips() {
    if (!this.detailsOpen()) {
      return (
        <FilterChips
          id="invoices-chips"
          chipClass="v2-filter-chip"
          urlParams={this.props.filters}
          onDeleteChip={this.handleDeleteChip}
          translations={invoicesFilterChipsV2}
          filterTypes={invoicesFilterTypesV2}
        />
      );
    }
  }

  updateMenu({ exporting } = {}) {
    this.setState({
      menuItems: [
        {
          id: 'invoices-export-button',
          labelKey: exporting ? 'common_button_exporting' : 'common_export',
          icon: <ExportInvoicesIcon color={cssColors.listItem} />,
          handleClick: this.handleExportInvoices,
          disabled: exporting
        },
        {
          id: 'invoices-create-button',
          labelKey: 'create_invoice_button',
          handleClick: this.handleCreateInvoice,
          icon: <AddInvoiceIcon />
        }
      ]
    });
  }

  render() {
    const title = <FormattedMessage id="side_menu_section_invoice" />;

    return (
      <ColumnList
        placeholder={getMsg('placeholder.invoices.search')}
        title={title}
        filtersTitle={title}
        filters={this.getFilters()}
        filterChips={this.getFilterChips()}
        onFiltersApply={this.handleFiltersApply}
        onFiltersReset={this.handleFiltersReset}
        onScrollToBottom={this.getMoreResults}
        renderItem={this.renderItem}
        onSelect={this.handleView}
        header={this.renderHeader()}
        filtersActive={!!this.props.filters}
        totalResults={this.props.totalResults}
        items={this.props.list}
        inputRef={this.props.inputRef}
        selected={this.resolveSelected}
        active={this.resolveActive}
        menuItems={this.state.menuItems}
        autoCompleteEnabled
        autoCompleteData={this.getAcData}
        autoCompleteList={this.getAcList}
        autoCompleteResult={this.getAcResult}
        autoCompleteOnSelect={this.handleSearchSelect}
      />
    );
  }
}

const mapStateToProps = state => {
  return {
    locale: localeSelector(state),
    list: invoicesResultsSelector(state),
    filters: invoicesFiltersSelector(state),
    totalResults: invoicesTotalResultsSelector(state),
    invoicesExporting: invoicesExportingSelector(state),
    loading: invoicesLoadingSelector(state)
  };
};

InvoicesList = connect(mapStateToProps)(InvoicesList);

export default InvoicesList;
