/* eslint-disable no-unused-vars */

import moment from 'moment';

// every locale should be imported for moment.locale() to work
// search key-word 'en'

import fr from 'moment/locale/fr';
import es from 'moment/locale/es';
import it from 'moment/locale/it';
import pt from 'moment/locale/pt';
import da from 'moment/locale/da';
import nl from 'moment/locale/nl';
import pl from 'moment/locale/pl';
import sv from 'moment/locale/sv';
import de from 'moment/locale/de';
import cs from 'moment/locale/cs';
import sk from 'moment/locale/sk';
import br from 'moment/locale/pt-br';

import * as types from '../constants/actionTypes-constants';
import { I18N_LOADING_FAILED } from '../constants/errors-constants';
import config from '../constants/config-constants';
import { getMaterialLocale, getQueryParams } from '../utils/utils';

export function setLanguage(locale) {
  const queryParams = getQueryParams();

  if (!locale && queryParams.locale) {
    locale = queryParams.locale;
  }
  if (!locale) {
    locale = window.localStorage.getItem('locale');
  }
  if (!locale) {
    locale = config.browserLocale;
  }
  locale = config.theme.availableLanguages.indexOf(locale) !== -1 ? locale : config.defaultLocale;

  return dispatch => {
    // set moment locale

    moment.locale(getMaterialLocale(locale));

    dispatch({
      type: types.I18N_BUNDLE_REQUEST,
      locale
    });

    const fileName = config.i18nRevManifest[locale + '-i18n.json'];

    return fetch(`i18n/${fileName}`, {
      method: 'GET'
    })
      .then(res => {
        if (res.status !== 200) {
          throw {
            name: I18N_LOADING_FAILED,
            code: res.status
          };
        }
        return res;
      })
      .then(res => res.json())
      .then(bundle => {
        dispatch({
          type: types.I18N_BUNDLE_SUCCESS,
          locale,
          bundle
        });
      });
  };
}
