// noinspection ES6CheckImport
import React, { Component, PropTypes } from 'react';

import { FormattedMessage } from 'react-intl';
import { reduxForm } from 'redux-form';

import FormRow from '../FormRow/FormRow';
import BoxedInput from '../BoxedInput/BoxedInput';
import BoxedSelect from '../BoxedSelect/BoxedSelect';

import { hourOptions, minuteOptions } from '../../constants/options-constants';

import FieldErrorMsg from '../FieldErrorMsg/FieldErrorMsg';
import { createValidator, notEmpty } from '../../validation/all-validation';

import { scrollToFirstError } from '../../utils/utils';

import EkButton from '../EkButton/EkButton';
import { vehicleTasksTypeOptions } from '../../constants/options-constants';

class TaskForm extends Component {
  render() {
    const {
      fields: { fieldAgentId, type, startDate, startHour, startMinute },
      handleSubmit
    } = this.props;

    return (
      <section className="taskFormWrapper">
        <form action="#" className="taskForm" onSubmit={handleSubmit(this.props.onCallback)}>
          <div className="taskForm_container">
            <FormRow customClass="taskForm_row">
              <BoxedSelect
                formRowItemKey="taskForm_type"
                id="type"
                fullWidth
                labelKey="vehicleDetail_tasksTab_tasktype"
                options={vehicleTasksTypeOptions}
                field={type}
              >
                <FieldErrorMsg field={type} />
              </BoxedSelect>

              <BoxedSelect
                formRowItemKey="taskForm_fieldAgent"
                labelKey="vehicleDetail_tasksTab_FieldAgent"
                fullWidth
                id="fieldAgent"
                field={fieldAgentId}
                options={this.props.agents}
              >
                <FieldErrorMsg field={fieldAgentId} />
              </BoxedSelect>
            </FormRow>

            <FormRow customClass="taskForm_row">
              <BoxedInput
                formRowItemKey="tasksForm_date"
                id="startDate"
                skinType="date"
                type="date"
                customClass="boxedInputWrapper--label"
                labelKey="vehicleDetail_tasksTab_startDate"
                field={startDate}
              >
                <FieldErrorMsg field={startDate} />
              </BoxedInput>
              <div className="time-inline">
                <BoxedSelect
                  formRowItemKey="taskForm_hour"
                  labelKey="vehicleDetail_tasksTab_startTime"
                  options={hourOptions}
                  field={startHour}
                >
                  <FieldErrorMsg field={startHour} />
                </BoxedSelect>
                <div className="box-minutes">
                  <BoxedSelect formRowItemKey="taskForm_minute" options={minuteOptions} field={startMinute}>
                    <FieldErrorMsg field={startMinute} />
                  </BoxedSelect>
                </div>
                <div className="taskForm_actions">
                  <EkButton id="save-button" customClass="taskForm_actionsButton" onAction={handleSubmit(this.props.onCallback)}>
                    <FormattedMessage id="backUserForm_save_button" />
                  </EkButton>
                </div>
              </div>
            </FormRow>
          </div>
        </form>
      </section>
    );
  }
}

TaskForm.displayName = 'AddTaskForm';

TaskForm.propTypes = {
  fields: PropTypes.object,
  onCallback: PropTypes.func,
  handleSubmit: PropTypes.func
};

// noinspection DuplicatedCode
TaskForm = reduxForm({
  onSubmitFail: scrollToFirstError,
  form: 'AddTaskForm',
  fields: ['fieldAgentId', 'startDate', 'type', 'startHour', 'startMinute'],
  validate: createValidator({
    fieldAgentId: [notEmpty()],
    startDate: [notEmpty()],
    type: [notEmpty()],
    startHour: [notEmpty()],
    startMinute: [notEmpty()]
  })
  // asyncBlurFields is needed if we want an async validator on blur. Use onSubmit validation otherwise.
})(TaskForm);

export default TaskForm;
