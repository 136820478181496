/* eslint-disable react/no-set-state */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import autoBind from 'react-autobind';
import _isFunction from 'lodash/isFunction';
import CloseIcon from 'material-ui/svg-icons/navigation/close';
import RotateRightIcon from 'material-ui/svg-icons/image/rotate-right';
import RotateLeftIcon from 'material-ui/svg-icons/image/rotate-left';
import ZoomInIcon from 'material-ui/svg-icons/content/add';
import ZoomOutIcon from 'material-ui/svg-icons/content/remove';
import { floatPrecision, openImageNewTab } from '../../utils/utils';
import ReactSVG from 'react-svg';
import { NEW_TAB_ICON_PATH } from '../../constants/generic-constants';

class ImagePreview extends Component {
  constructor(props) {
    super(props);
    autoBind(this);

    this.setDefaultVars();

    this.state = {
      open: false,
      imgStyle: { transform: this.setTransform() }
    };
  }

  setDefaultVars() {
    this.zoomStep = 0.2;
    this.scale = 1;
    this.rotate = 0;
  }

  handleKeys(event) {
    if (this.documentKeyFunc) this.documentKeyFunc(event);
    switch (event.key) {
      case 'Esc':
      case 'Escape':
        this.handleClose();
        break;
    }
  }

  setTransform() {
    return `scale(${this.scale}) rotate(${this.rotate}deg)`;
  }

  updateImgStyle() {
    this.setState({ imgStyle: { transform: this.setTransform() } });
  }

  displayImageThumbnail() {
    const { src, thumbnailSrc, alt, thumbnailAlt } = this.props;
    const imageSrc = thumbnailSrc || src;
    const imageAlt = thumbnailAlt || alt;

    if (imageSrc)
      return (
        <img
          src={imageSrc}
          alt={imageAlt}
          className={classNames(this.props.thumbnailClass, 'component-thumbnail')}
          onClick={this.handleOpen}
          style={this.props.thumbnailStyle}
        />
      );
  }

  handleOpen() {
    this.setState({ open: true });

    if (_isFunction(document.onkeydown)) this.documentKeyFunc = document.onkeydown;
    document.onkeydown = this.handleKeys;
  }

  handleClose() {
    this.setState({ open: false });

    document.onkeydown = null;
    if (this.documentKeyFunc) document.onkeydown = this.documentKeyFunc;
  }

  handleZoomIn() {
    this.scale += this.zoomStep;
    this.scale = floatPrecision(this.scale, 3);
    this.updateImgStyle();
  }

  handleZoomOut() {
    let scale = this.scale;

    scale -= this.zoomStep;
    scale = floatPrecision(scale, 3);

    if (scale > 0) {
      this.scale = scale;
      this.updateImgStyle();
    }
  }

  handleRotateRight() {
    let rotate = this.rotate;

    rotate += 90;
    if (Math.abs(rotate) > 270) rotate = 0;

    this.rotate = rotate;
    this.updateImgStyle();
  }

  handleRotateLeft() {
    let rotate = this.rotate;

    rotate -= 90;
    if (Math.abs(rotate) > 270) rotate = 0;

    this.rotate = rotate;
    this.updateImgStyle();
  }

  displayActionButtons() {
    return (
      <div className="component-action-buttons">
        <div className="component-icon-container">
          <button className="component-icon-button" onClick={this.handleClose} type="button">
            <CloseIcon />
          </button>
        </div>
        <div className="component-icon-container">
          <button className="component-icon-button" onClick={this.handleRotateRight} type="button">
            <RotateRightIcon />
          </button>
        </div>
        <div className="component-icon-container">
          <button className="component-icon-button" onClick={this.handleRotateLeft} type="button">
            <RotateLeftIcon />
          </button>
        </div>
        <div className="component-icon-container">
          <button className="component-icon-button" onClick={this.handleZoomIn} type="button">
            <ZoomInIcon />
          </button>
        </div>
        <div className="component-icon-container">
          <button className="component-icon-button" onClick={this.handleZoomOut} type="button">
            <ZoomOutIcon />
          </button>
        </div>
      </div>
    );
  }

  displayImage() {
    const { src, alt } = this.props;

    if (src && this.state.open) {
      return (
        <div className="component-modal">
          <div className="component-overlay" onClick={this.handleClose} />
          <div>
            {this.displayActionButtons()}
            <img
              src={src}
              alt={alt}
              style={{
                ...this.state.imgStyle,
                ...this.props.srcStyle
              }}
              className={classNames(this.props.srcClass, 'component-img-open')}
            />
          </div>
        </div>
      );
    }
  }

  handleClickExternal() {
    if (this.props.src) {
      openImageNewTab(this.props.src, this.props.alt);
    }
  }

  openNewTabIcon() {
    if (this.props.showNewTabIcon) {
      return (
        <div className="external-link-icon" onClick={this.handleClickExternal}>
          <ReactSVG src={NEW_TAB_ICON_PATH} />
        </div>
      );
    }
  }

  render() {
    return (
      <div className={classNames('image-preview-component', this.props.className)} style={this.props.style}>
        {this.openNewTabIcon()}
        {this.displayImageThumbnail()}
        {this.displayImage()}
      </div>
    );
  }
}

ImagePreview.defaultProps = {
  alt: '',
  srcClass: '',
  thumbnailClass: '',
  thumbnailAlt: '',
  rootContainerClass: '',
  showNewTabIcon: true
};

ImagePreview.propTypes = {
  src: PropTypes.string, // src of the image opened
  srcClass: PropTypes.string,
  srcStyle: PropTypes.object,
  alt: PropTypes.string,
  thumbnailSrc: PropTypes.string, // src of the thumbnail image | if missing: 'src' is used instead
  thumbnailClass: PropTypes.string,
  thumbnailStyle: PropTypes.object,
  thumbnailAlt: PropTypes.string, // if missing: 'alt' is used instead
  className: PropTypes.string, // root container class name
  style: PropTypes.object, // root container style
  showNewTabIcon: PropTypes.bool // add open in new tab icon
};

export default ImagePreview;
