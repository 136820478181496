import React, { Component } from 'react';
import { connect } from 'react-redux';
import { routeActions } from 'react-router-redux';

import VehiclesCategoryForm from '../../components/VehiclesCategoryForm/VehiclesCategoryForm';
import { createCategoryRequest, showSuccessMsg } from '../../actions/all-actions';
import routes from '../../constants/routes-constants';

class AddVehiclesCategory extends Component {
  componentWillMount() {
    this.handleAddCategory = params => {
      const { dispatch } = this.props;

      dispatch(createCategoryRequest(params)).then(() => {
        dispatch(showSuccessMsg({ key: 'vehiclesCategoryForm_create_category_success', delay: true }));
        dispatch(routeActions.push(routes.vehiclesCategories.path));
      });
    };
  }

  render() {
    return (
      <div className="addVehiclesCategoryPage mainContainer_content">
        <div className="pageContainer">
          <div className="addVehiclesCategoryPage_content">
            <section className="addVehiclesCategoryPage_management">
              <VehiclesCategoryForm
                onCallback={this.handleAddCategory}
                initialValues={{
                  type: '',
                  description: ''
                }}
              />
            </section>
          </div>
        </div>
      </div>
    );
  }
}

AddVehiclesCategory.displayName = 'AddVehiclesCategory';

export default connect(state => {
  const {} = state;

  return {};
})(AddVehiclesCategory);
