import React, { Component } from 'react';
import autoBind from 'react-autobind';
import pure from 'recompose/pure';
import { getAppFormattedDateTime, namedCompose } from '../../utils/utils';
import { FormattedMessage, injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { localeSelector } from '../../selectors/all-selectors';
import PlaceIcon from 'material-ui/svg-icons/maps/place';
import TodayIcon from 'material-ui/svg-icons/action/today';
import { iconStyles } from '../../constants/style-constants';
import Tooltip from '../Tooltip/Tooltip';
import None from '../None';

class TripMoreInfo extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.derivedStateFromProps(props);
  }

  // eslint-disable-next-line no-unused-vars
  componentWillReceiveProps(nextProps, nextContext) {
    this.derivedStateFromProps(nextProps);
  }

  derivedStateFromProps(nextProps) {
    if (!this.checkRender(nextProps)) return false;
    const { pickupDate, pickupAdress, comment } = nextProps;
    this.singleCol = (pickupDate || pickupAdress) && comment ? '' : 'single-column';
  }

  checkRender(nextProps) {
    const { pickupDate, pickupAdress, comment } = nextProps;
    this.componentCanRender = !(!pickupDate && !pickupAdress && !comment);
    return this.componentCanRender;
  }

  displayComment() {
    const { comment } = this.props;
    if (comment)
      return (
        <div className="tripMoreInfo_sub_block">
          <h5>
            <FormattedMessage id="booking_detail_comment" />
          </h5>
          <div className="tripMoreInfo_comment">
            <span className="detailView_cell_type_1">{comment}</span>
          </div>
        </div>
      );
  }

  pickupInfo() {
    const { pickupAdress, pickupDate } = this.props;

    if (pickupAdress || pickupDate)
      return (
        <div className="tripMoreInfo_sub_block">
          <h5>
            <FormattedMessage id="booking_detail_pickup" />
          </h5>
          <table>
            <tbody>
              {this.displayRow('common_address', this.getPlaceIcon(), pickupAdress)}
              {this.displayRow('booking_detail_pickup_date', this.getTodayIcon(), getAppFormattedDateTime(pickupDate))}
            </tbody>
          </table>
        </div>
      );
  }

  displayRow(label, icon, value) {
    if (value)
      return (
        <tr className="detailView_tr">
          <td className="detailView_td_type_1">
            <span className="detailView_cell_type_1">
              <Tooltip content={<FormattedMessage id={label} />} placement="bottom">
                {icon}
              </Tooltip>
            </span>
          </td>
          <td className="detailView_td_type_1">
            <span className="detailView_cell_type_1">{value}</span>
          </td>
        </tr>
      );
  }

  getPlaceIcon() {
    return <PlaceIcon style={iconStyles.all} />;
  }

  getTodayIcon() {
    return <TodayIcon style={iconStyles.all} />;
  }

  render() {
    if (this.componentCanRender)
      return (
        <div className={`tripMoreInfo`}>
          <h4 className={`tripMoreInfo_headline ${this.singleCol}`}>
            <FormattedMessage id="booking_trip_more_info" />
          </h4>
          <div className="tripMoreInfo_body_2">
            {this.pickupInfo()}
            {this.displayComment()}
          </div>
        </div>
      );
    else return <None />;
  }
}

TripMoreInfo.defaultProps = {
  freeOfCharges: false
};

// -----

TripMoreInfo.propTypes = {
  pickupDate: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object // date
  ]),
  pickupAdress: PropTypes.string,
  comment: PropTypes.string
};

// -----

export default namedCompose(
  connect(state => {
    return {
      locale: localeSelector(state)
    };
  }),
  injectIntl,
  pure
)(TripMoreInfo);
