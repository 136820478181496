import React, { Component, PropTypes } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { routeActions } from 'react-router-redux';
import {
  toggleSideMenu,
  logout,
  setCurrentCompany,
  setSubCompanySelected,
  getHeaderSubCompaniesList,
  setLanguage,
  toggleCompanyAutoComplete,
  toggleSuperCompanyAutoComplete,
  searchMode,
  clearResults,
  handleToggleMobileMenu,
  updateBannerDisplay
} from '../../actions/all-actions';
import { refreshPageWithDefaultPagination } from '../../routing/all-routing';
import routes from '../../constants/routes-constants';
import MenuIcon from 'material-ui/svg-icons/navigation/menu';
import IconButton from 'material-ui/IconButton';
import AccountIcon from 'material-ui/svg-icons/action/account-circle';
import IconMenu from 'material-ui/IconMenu';
import MenuItem from 'material-ui/MenuItem';
import ProfileIcon from 'material-ui/svg-icons/action/perm-contact-calendar';
import ExitIcon from 'material-ui/svg-icons/action/exit-to-app';
import BackIcon from 'material-ui/svg-icons/navigation/arrow-back';
import FlatButton from 'material-ui/FlatButton';
import SuperCompanyAutoComplete from './components/SuperCompanyAutoComplete';
import CompanyAutoComplete from './components/CompanyAutoComplete';
import {
  fillWhite,
  fillDark,
  dimensions45,
  dimensions52,
  dropdownAnchorOrigin,
  paddingLeft75,
  headerAccountIconWhite,
  headerAccountIconDark,
  marginLeftMinus16
} from '../../constants/style-constants';
import Search from './components/Search';
import SearchResults from '../SearchResults/SearchResults';
import Language from './components/Language/Language';
import IsGranted from '../IsGranted/IsGranted';
import {
  BACKUSER_ROLE_ADMIN,
  BACKUSER_ROLE_CALL_CENTER_OPERATOR,
  BACKUSER_ROLE_SUPER_ADMIN,
  BACKUSER_ROLE_ROOT
} from '../../constants/backend-constants';
import { COMPANY_ALL, STORAGE_KEY } from '../../constants/generic-constants';
import _includes from 'lodash/includes';
import { bannerMessageSelector } from '../../selectors/all-selectors';
import ReactSVG from 'react-svg';
import { parseFirstAutoCompleteResult } from './utils';

class Header extends Component {
  constructor(props) {
    super(props);
    this.bindFunctions();
    this.derivedStateFromProps(props, true);
  }

  bindFunctions() {
    this.handleAccountClick = this.handleAccountClick.bind(this);
    this.handleSignOut = this.handleSignOut.bind(this);
    this.handleToggleMenu = this.handleToggleMenu.bind(this);
    this.handleCompanySelect = this.handleCompanySelect.bind(this);
    this.handleChangeLanguage = this.handleChangeLanguage.bind(this);
    this.handleSubCompanySelect = this.handleSubCompanySelect.bind(this);
    this.handleCancelSearchMode = this.handleCancelSearchMode.bind(this);
    this.handleToggleMobileMenu = this.handleToggleMobileMenu.bind(this);
    this.openBanner = this.openBanner.bind(this);
  }

  // noinspection JSCheckFunctionSignatures
  componentWillReceiveProps(nextProps) {
    this.derivedStateFromProps(nextProps);
  }

  derivedStateFromProps(nextProps, init) {
    this.hideCompanySelectors(nextProps, init);
  }

  pathUpdated(nextProps, init) {
    const { pathname: nextValue } = nextProps;
    const { pathname: prevValue } = this.props;
    return init || nextValue !== prevValue;
  }

  hideCompanySelectors(nextProps, init) {
    if (this.pathUpdated(nextProps, init)) {
      const { pathname } = nextProps;

      const companyListPath = routes.companies.path.replace(':search', '');
      const companyDetailPath = routes.companyDetail.path.replace(':companyId', '');
      const editCompanyPath = routes.editCompany.path.replace(':companyId', '');
      const subCompanyDetailPath = routes.subCompanyDetails.path.replace(':subCompanyId', '');

      const isCompanyListPath = _includes(pathname, companyListPath);
      const isSubCompanyDetailPath = _includes(pathname, subCompanyDetailPath);
      const isEditCompanyPath = _includes(pathname, editCompanyPath);
      const isCompanyDetailPath = _includes(pathname, companyDetailPath);

      this.isSuperCompanyDisabled = isCompanyListPath || isEditCompanyPath || isCompanyDetailPath || isSubCompanyDetailPath;

      this.isCompanyDisabled =
        routes.subCompanies.path === pathname || isCompanyListPath || isSubCompanyDetailPath || isEditCompanyPath || isCompanyDetailPath;
    }
  }

  handleCancelSearchMode() {
    this.props.searchMode(false);
    this.props.clearResults();
  }

  saveSelectedCompany({ id } = {}) {
    window.localStorage.setItem(STORAGE_KEY.HEADER_COMPANY_ID, id);
  }

  saveSelectedSubCompany({ id } = {}) {
    window.localStorage.setItem(STORAGE_KEY.HEADER_SUB_COMPANY_ID, id);
  }

  handleCompanySelect(data) {
    const parsed = parseFirstAutoCompleteResult(data, this.props.headerList);
    const { value: company = COMPANY_ALL } = parsed || {};

    this.saveSelectedCompany(company);
    this.saveSelectedSubCompany(COMPANY_ALL);

    this.props.setCurrentCompany(company);
    this.props.setSubCompanySelected(COMPANY_ALL);
    this.props.toggleSuperCompanyAutoComplete();

    if (company === COMPANY_ALL) {
      refreshPageWithDefaultPagination();
    } else {
      this.props.singleSubCompanyRefresh();
    }
  }

  handleSubCompanySelect(data) {
    const parsed = parseFirstAutoCompleteResult(data, this.props.headerSubList);
    const { value: subCompany = COMPANY_ALL } = parsed || {};

    this.saveSelectedSubCompany(subCompany);
    this.props.setSubCompanySelected(subCompany);
    this.props.toggleCompanyAutoComplete();

    refreshPageWithDefaultPagination();
  }

  openBanner() {
    this.props.updateBannerDisplay(true);
  }

  showWarningButton() {
    if (this.props.bannerMessage) {
      return (
        <div className="header-warning-container">
          <button type="button" className="sc-warning">
            <ReactSVG src="/img/warning.svg" className="align-svg" onClick={this.openBanner} />
          </button>
        </div>
      );
    }
  }

  handleToggleMenu(e) {
    e.preventDefault();
    this.props.toggleSideMenu();
  }

  handleAccountClick(e) {
    e.preventDefault();
    this.props.navigateToAccount();
  }

  handleSignOut(e) {
    e.preventDefault();
    this.props.logout();
    this.props.navigateToLogin();
  }

  handleChangeLanguage(language) {
    this.props.setLanguage(language);
  }

  handleToggleMobileMenu() {
    this.props.handleToggleMobileMenu();
  }

  render() {
    const { userInfo, currentCompany, subCompanySelected, userLocale, searchModeState } = this.props;

    return (
      <div>
        <header className={(searchModeState && 'header-searchMode') + ' header'}>
          <div className="header-menu">
            {searchModeState ? (
              <IconButton iconStyle={fillDark} onClick={this.handleCancelSearchMode}>
                <BackIcon />
              </IconButton>
            ) : (
              <div>
                <IconButton className="header-menuIcon" iconStyle={fillWhite} onClick={this.handleToggleMenu}>
                  <MenuIcon />
                </IconButton>
                <IconButton className="header-menuIconMobile" iconStyle={fillWhite} onClick={this.handleToggleMobileMenu}>
                  <MenuIcon />
                </IconButton>
              </div>
            )}
          </div>

          <div className="header-page-name">
            {searchModeState ? (
              <div className="header-searchMode-back">
                <FlatButton
                  label={<FormattedMessage id="common_back" />}
                  onClick={this.handleCancelSearchMode}
                  labelStyle={{ ...fillDark, ...marginLeftMinus16 }}
                />
              </div>
            ) : (
              <img className="sideMenu_logo" />
            )}
          </div>

          <Search />

          <div className="header-actionIcons">
            <div className="header-actionIcons-icon">
              <div className="header-actionIcons-companyWrap">
                <IsGranted
                  allowed={[BACKUSER_ROLE_SUPER_ADMIN, BACKUSER_ROLE_CALL_CENTER_OPERATOR, BACKUSER_ROLE_ROOT, BACKUSER_ROLE_ADMIN]}
                >
                  {!this.isSuperCompanyDisabled && (
                    <SuperCompanyAutoComplete
                      currentCompany={currentCompany}
                      initialValues={{ subCompany: subCompanySelected && subCompanySelected.id }}
                      onCompanySelect={this.handleCompanySelect}
                    />
                  )}
                </IsGranted>

                {!this.isCompanyDisabled && (
                  <CompanyAutoComplete
                    initialValues={{ company: currentCompany && currentCompany.id }}
                    onCompanySelect={this.handleSubCompanySelect}
                  />
                )}
              </div>
            </div>

            {this.showWarningButton()}
            <Language userLocale={userLocale} onChangeLanguage={this.handleChangeLanguage} />

            <div className="header-actionIcons-icon header-actionIcons-accountIcon">
              <IconMenu
                iconButtonElement={
                  <IconButton iconStyle={searchModeState ? headerAccountIconDark : headerAccountIconWhite} style={dimensions52}>
                    <AccountIcon />
                  </IconButton>
                }
                anchorOrigin={dropdownAnchorOrigin}
                targetOrigin={dropdownAnchorOrigin}
              >
                <div className="header-actionIcons-account">
                  <AccountIcon style={{ ...dimensions45, fill: '#757575' }} />
                  <div className="header-actionIcons-flexColumn">
                    <span className="header-actionIcons-accountUsername">
                      {userInfo.firstName} {userInfo.lastName}
                    </span>
                    <span className="header-actionIcons-accountEmail">{userInfo.login}</span>
                  </div>
                </div>
                <MenuItem
                  primaryText={<FormattedMessage id="header_nav_account" />}
                  leftIcon={<ProfileIcon />}
                  innerDivStyle={paddingLeft75}
                  onClick={this.handleAccountClick}
                />
                <MenuItem
                  primaryText={<FormattedMessage id="header_nav_signOut" />}
                  leftIcon={<ExitIcon />}
                  innerDivStyle={paddingLeft75}
                  onClick={this.handleSignOut}
                />
              </IconMenu>
            </div>
          </div>
        </header>
        <SearchResults />
      </div>
    );
  }
}

Header.displayName = 'Header';

const mapStateToProps = state => {
  const {
    user: { userInfo },
    header: { searchMode },
    companies: { headerList, currentCompany },
    subCompanies: { subCompanySelected, headerSubList },
    i18n: { locale },
    routing: {
      location: { pathname }
    }
  } = state;

  return {
    bannerMessage: bannerMessageSelector(state),
    userInfo,
    pathname,
    currentCompany,
    subCompanySelected,
    headerList,
    headerSubList,
    userLocale: locale,
    searchModeState: searchMode
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateBannerDisplay(status) {
      dispatch(updateBannerDisplay(status));
    },
    setCurrentCompany(company) {
      dispatch(setCurrentCompany(company));
    },
    toggleSideMenu() {
      dispatch(toggleSideMenu());
    },
    navigateToAccount() {
      dispatch(routeActions.push(routes.account.path));
    },
    logout() {
      dispatch(logout());
    },
    navigateToLogin() {
      dispatch(routeActions.push(routes.login.path));
    },
    setLanguage(locale) {
      dispatch(setLanguage(locale));
    },
    setSubCompanySelected(subCompany) {
      dispatch(setSubCompanySelected(subCompany));
    },
    singleSubCompanyRefresh() {
      return dispatch(getHeaderSubCompaniesList()).then(resp => {
        if (resp.length === 1 && this.pathname.indexOf('vehicle-planning') > 0) dispatch(setSubCompanySelected(resp[0]));
        refreshPageWithDefaultPagination();
        return resp;
      });
    },
    toggleCompanyAutoComplete() {
      dispatch(toggleCompanyAutoComplete());
    },
    toggleSuperCompanyAutoComplete() {
      dispatch(toggleSuperCompanyAutoComplete());
    },
    searchMode(bool) {
      dispatch(searchMode(bool));
    },
    clearResults() {
      dispatch(clearResults());
    },
    handleToggleMobileMenu() {
      dispatch(handleToggleMobileMenu());
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
