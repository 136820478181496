import React, { Component, PropTypes } from 'react';
import { FormattedMessage } from 'react-intl';

class TabsItems extends Component {
  handleClick(index, e) {
    e.preventDefault();
    this.props.onChangeTab(index);
  }

  render() {
    let tabItem = this.props.tabList.map((tab, index) => {
      let boundHandleClick = this.handleClick.bind(this, index);

      let pelletElement = tab.numberItems && (
        <span className={`tabsItem_number ${this.props.currentTab === index ? '' : 'tabsItem_number--variant1Reverse'}`}>
          {tab.numberItems}
        </span>
      );

      let indexItem = this.props.showTabItemsIndex && (
        <span
          className={`
          tabsItem_number
          ${this.props.currentTab === index ? 'tabsItem_number--reverse' : 'tabsItem_number--variant1Reverse'}`}
        >
          {index + 1}
        </span>
      );

      let tabItemButton = !this.props.actionButtons ? (
        <span className="tabsItem_label">
          <FormattedMessage id={tab.name} />
        </span>
      ) : (
        <button className="tabsItem_button" onClick={boundHandleClick}>
          <span className="tabsItem_label">
            <FormattedMessage id={tab.name} />
          </span>
          {pelletElement}
        </button>
      );

      return (
        <li className={`tabsItem ${this.props.currentTab === index ? 'tabsItem--current' : ''}`} key={index}>
          {indexItem}
          {tabItemButton}
        </li>
      );
    }, this);

    return (
      <nav>
        <ul className="tabsItems">{tabItem}</ul>
      </nav>
    );
  }
}

TabsItems.displayName = 'TabsItems';

TabsItems.propTypes = {
  url: PropTypes.string,
  tabList: PropTypes.array,
  showTabItemsIndex: PropTypes.bool,
  actionButtons: PropTypes.bool
};

export default TabsItems;
