import React from 'react';
import PhoneNumber from '../PhoneNumber/PhoneNumber';
import classnames from 'classnames';
import FieldErrorMsg from '../FieldErrorMsg/FieldErrorMsg';

export default function PhoneNumberField({ field, label, mandatory, disabled, ...props }) {
  if (disabled) {
    let disabledValue = '';

    if (field.value) {
      const phonePrefix = field.value.phonePrefix || '';
      const phoneSuffix = field.value.phoneSuffix || '';
      disabledValue = phonePrefix + ' ' + phoneSuffix;
    }

    return (
      <div className="boxedInputWrapper">
        <div className="scope-field-label">{label}</div>
        <div className={classnames('new-design-field', 'sub-class-disabled')}>{disabledValue}</div>
      </div>
    );
  } else {
    return (
      <div className={props.className || ''}>
        <div className="boxedInput_labelText phone-number-field">
          {label}
          {mandatory && <span className="mandatory">*</span>}
        </div>
        <div className="phone-number-container">
          <PhoneNumber inputClass="boxedInput" onChange={field.onChange} onBlur={field.onBlur} value={field.value} {...props} />
          <FieldErrorMsg field={field} customClass="fieldErrorMsg--backUserForm" />
        </div>
      </div>
    );
  }
}
