import React, { Component, PropTypes as T } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import autoBind from 'react-autobind';

class VehiclePlanningOpeningHours extends Component {
  constructor(props) {
    super(props);
    const { startDate, periodInDays } = props;
    this.currDay = moment(startDate)
      .locale('en')
      .add(periodInDays - 1, 'd')
      .format('dddd')
      .toUpperCase();
    this.nextDay = undefined;
    this.nextDay2 = undefined;
    this.nextDay3 = undefined;
    this.nextDay4 = undefined;
    this.nextDay5 = undefined;
    this.nextDay6 = undefined;
    this.marginLeft = '0px';
    autoBind(this);
  }

  componentWillMount() {
    const { startDate, periodInDays } = this.props;
    if (periodInDays === 1 || periodInDays === 3 || periodInDays === 7) {
      this.currentDate = moment(startDate).locale('en');
      this.currDay = this.currentDate.format('dddd').toUpperCase();
      this.nextDay = this.currentDate
        .add(1, 'd')
        .format('dddd')
        .toUpperCase();
      this.nextDay2 = this.currentDate
        .add(1, 'd')
        .format('dddd')
        .toUpperCase();
      this.nextDay3 = this.currentDate
        .add(1, 'd')
        .format('dddd')
        .toUpperCase();
      this.nextDay4 = this.currentDate
        .add(1, 'd')
        .format('dddd')
        .toUpperCase();
      this.nextDay5 = this.currentDate
        .add(1, 'd')
        .format('dddd')
        .toUpperCase();
      this.nextDay6 = this.currentDate
        .add(1, 'd')
        .format('dddd')
        .toUpperCase();
      this.marginLeft3dayscol = `(( 100% - 150px ) / 3)`;
      this.marginLeft3days2col = `${this.marginLeft3dayscol}`;
      this.marginLeft3days3col = ` ${this.marginLeft3dayscol} * 2 `;
      this.marginLeft7dayscol = `(( 100% - 150px ) / 7)`;
      this.marginLeft7days2col = ` ${this.marginLeft7dayscol} * 2 `;
      this.marginLeft7days3col = ` ${this.marginLeft7dayscol} * 3 `;
      this.marginLeft7days4col = ` ${this.marginLeft7dayscol} * 4 `;
      this.marginLeft7days5col = ` ${this.marginLeft7dayscol} * 5 `;
      this.marginLeft7days6col = ` ${this.marginLeft7dayscol} * 6 `;
    }
  }

  componentWillReceiveProps() {
    const { startDate, periodInDays } = this.props;

    if (periodInDays === 1 || periodInDays === 3 || periodInDays === 7) {
      this.currentDate = moment(startDate).locale('en');
      this.currDay = this.currentDate.format('dddd').toUpperCase();
      this.nextDay = this.currentDate
        .add(1, 'd')
        .format('dddd')
        .toUpperCase();
      this.nextDay2 = this.currentDate
        .add(1, 'd')
        .format('dddd')
        .toUpperCase();
      this.nextDay3 = this.currentDate
        .add(1, 'd')
        .format('dddd')
        .toUpperCase();
      this.nextDay4 = this.currentDate
        .add(1, 'd')
        .format('dddd')
        .toUpperCase();
      this.nextDay5 = this.currentDate
        .add(1, 'd')
        .format('dddd')
        .toUpperCase();
      this.nextDay6 = this.currentDate
        .add(1, 'd')
        .format('dddd')
        .toUpperCase();
      this.marginLeft3dayscol = `(( 100% - 150px ) / 3)`;
      this.marginLeft3days2col = `${this.marginLeft3dayscol}`;
      this.marginLeft3days3col = ` ${this.marginLeft3dayscol} * 2 `;
      this.marginLeft7dayscol = `(( 100% - 150px ) / 7)`;
      this.marginLeft7days2col = ` ${this.marginLeft7dayscol} * 2 `;
      this.marginLeft7days3col = ` ${this.marginLeft7dayscol} * 3 `;
      this.marginLeft7days4col = ` ${this.marginLeft7dayscol} * 4 `;
      this.marginLeft7days5col = ` ${this.marginLeft7dayscol} * 5 `;
      this.marginLeft7days6col = ` ${this.marginLeft7dayscol} * 6 `;
    }
  }

  render() {
    const { parkingOpenedSlots, startDate, periodInDays, open } = this.props;
    let divs = [];

    return (
      <div className="scheduleHours" key={parkingOpenedSlots.id + '' + open ? 'open' : 'close'}>
        {parkingOpenedSlots.days &&
          parkingOpenedSlots.days.map(day => {
            const provideStreams = open ? parkingOpenedSlots.timeIntervals : parkingOpenedSlots.closedHours;
            if (provideStreams) {
              divs[day] = provideStreams.map((item, idx) => {
                const startHour = item.start.split(':')[0];
                const startMinutes = item.start.split(':')[1];
                const endHour = item.end.split(':')[0];
                const endMinutes = item.end.split(':')[1];
                const h1 = moment(startDate)
                  .hour(startHour)
                  .minutes(startMinutes);
                const h2 = moment(startDate)
                  .hour(endHour)
                  .minutes(endMinutes);
                const openingHoursDuration = moment.duration(h2.diff(h1)).as('minutes');
                const openHoursWidth = openingHoursDuration <= 0 ? 1440 : 1440 / openingHoursDuration;
                const startingInPx = moment.duration(h1.diff(moment(startDate))).as('minutes');
                const end = item.end === '23:59' ? '00:00' : item.end;

                if (periodInDays === 1) {
                  return (
                    <div
                      key={parkingOpenedSlots.id + '_' + day + '_' + idx}
                      className={open ? 'vehiclePlanning_openhours' : 'vehiclePlanning_closedhours'}
                      style={{
                        width: `calc( ((100% - 150px) / ${periodInDays}) / ${openHoursWidth} )`,
                        left: `calc( (((100% - 150px) / ${periodInDays}) / 1440 * ${startingInPx}) + 150px) `
                      }}
                    >
                      {open ? <span className="start">{item.start}</span> : ''}
                      {open ? <span className="end">{end}</span> : ''}
                    </div>
                  );
                }

                if (periodInDays === 3) {
                  let left =
                    this.currDay === day
                      ? `calc( (((100% - 150px) / ${periodInDays}) / 1440 * ${startingInPx}) + 150px) `
                      : this.nextDay === day
                      ? `calc( (((100% - 150px) / ${periodInDays}) / 1440 * ${startingInPx}) + 150px + ${this.marginLeft3days2col} )`
                      : this.nextDay2 === day
                      ? `calc( (((100% - 150px) / ${periodInDays}) / 1440 * ${startingInPx}) + 150px  + ${this.marginLeft3days3col} )`
                      : '150px';

                  return (
                    <div
                      key={parkingOpenedSlots.id + '_' + day + '_' + idx}
                      className={open ? 'vehiclePlanning_openhours' : 'vehiclePlanning_closedhours'}
                      style={{
                        width: `calc( ((100% - 150px) / ${periodInDays}) / ${openHoursWidth} )`,
                        left
                      }}
                    >
                      {open ? <span className="start">{item.start}</span> : ''}
                      {open ? <span className="end">{end}</span> : ''}
                    </div>
                  );
                }

                if (periodInDays === 7) {
                  let left =
                    this.currDay === day
                      ? `calc( (((100% - 150px) / ${periodInDays}) / 1440 * ${startingInPx}) + 150px) `
                      : this.nextDay === day
                      ? `calc( (((100% - 150px) / ${periodInDays}) / 1440 * ${startingInPx}) + 150px + ${this.marginLeft7dayscol} )`
                      : this.nextDay2 === day
                      ? `calc( (((100% - 150px) / ${periodInDays}) / 1440 * ${startingInPx}) + 150px  + ${this.marginLeft7days2col} )`
                      : this.nextDay3 === day
                      ? `calc( (((100% - 150px) / ${periodInDays}) / 1440 * ${startingInPx}) + 150px  + ${this.marginLeft7days3col} )`
                      : this.nextDay4 === day
                      ? `calc( (((100% - 150px) / ${periodInDays}) / 1440 * ${startingInPx}) + 150px  + ${this.marginLeft7days4col} )`
                      : this.nextDay5 === day
                      ? `calc( (((100% - 150px) / ${periodInDays}) / 1440 * ${startingInPx}) + 150px  + ${this.marginLeft7days5col} )`
                      : this.nextDay6 === day
                      ? `calc( (((100% - 150px) / ${periodInDays}) / 1440 * ${startingInPx}) + 150px  + ${this.marginLeft7days6col} )`
                      : '';

                  return (
                    <div
                      key={parkingOpenedSlots.id + '_' + day + '_' + idx}
                      className={open ? 'vehiclePlanning_openhours' : 'vehiclePlanning_closedhours'}
                      style={{
                        width: `calc( ((100% - 150px) / ${periodInDays}) / ${openHoursWidth} )`,
                        left
                      }}
                    >
                      {open ? <span className="start">{item.start}</span> : ''}
                      {open ? <span className="end">{end}</span> : ''}
                    </div>
                  );
                }
              });
            }
          })}

        {divs && divs[this.currDay] && divs[this.currDay]}

        {divs && divs[this.nextDay] && (periodInDays === 3 || periodInDays === 7) && divs[this.nextDay]}
        {divs && divs[this.nextDay2] && (periodInDays === 3 || periodInDays === 7) && divs[this.nextDay2]}
        {divs && divs[this.nextDay3] && periodInDays === 7 && divs[this.nextDay3]}
        {divs && divs[this.nextDay4] && periodInDays === 7 && divs[this.nextDay4]}
        {divs && divs[this.nextDay5] && periodInDays === 7 && divs[this.nextDay5]}
        {divs && divs[this.nextDay6] && periodInDays === 7 && divs[this.nextDay6]}
      </div>
    );
  }
}

VehiclePlanningOpeningHours.displayName = 'VehiclePlanningOpeningHours';

VehiclePlanningOpeningHours.propTypes = {
  parkingOpenedSlots: T.object
};

export default connect(state => {
  const {
    vehiclePlanning: { startDate, periodInDays, siteId, loading, data },
    subCompanies: { subCompanySelected }
  } = state;

  return {
    startDate,
    periodInDays,
    siteId,
    subCompanySelected,
    loading,
    data
  };
})(VehiclePlanningOpeningHours);
