import React, { Component } from 'react';
import { showParentView } from '../../../../../components/_v2/ColumnList/helpers';
import CompanyDetailModal from '../../../../../containers/CompanyDetail/Modal';

export class OrganizationEditSuperCompany extends Component {
  componentWillMount() {
    this.showParentView = showParentView.bind(this);
  }

  render() {
    return <CompanyDetailModal isOpen onClose={this.showParentView} />;
  }
}

export default OrganizationEditSuperCompany;
