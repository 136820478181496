import cs from 'classnames';
import React, { Component, PropTypes } from 'react';
import BoxedSelect from '../../../components/BoxedSelect/BoxedSelect';
import BoxedInput from '../../../components/BoxedInput/BoxedInput';
import FieldErrorMsg from '../../../components/FieldErrorMsg/FieldErrorMsg';
import EkRadio from '../../../components/EkRadio/EkRadio';
import FileUpload from '../../../components/FileUlpoad/FileUpload';
import ListModal from '../../../components/_v2/ColumnList/Modal';
import { ListFilters } from '../../../components/_v2/ColumnList/Filters';

import {
  scheduledExportDateFormatOptions,
  scheduledExportDestinationOptions,
  scheduledExportFrequencyOptions,
  scheduledExportSecurityOptions,
  scheduledExportTypeOptions
} from '../../../constants/options-constants';
import { reduxForm } from 'redux-form';
import { callbackNot as not, fallbackFunc, getFieldValue, intlMsg, isObjEmpty, safe, scrollToFirstError } from '../../../utils/utils';
import { SCHEDULED_EXPORT } from '../../../constants/backend-constants';
import BookingFilters from '../Filters/Booking';
import MemberFilters from '../Filters/Member';
import { cleanDeep } from '../../../utils/cleanDeep';
import { FormattedMessage, injectIntl } from 'react-intl';
import { condition as con, createValidator, notEmpty, stopValidationIf } from '../../../validation/sync-validation';
import { addErrorMsg } from '../../../utils/flashMessage/creator';

export class ScheduledExportForm extends Component {
  constructor(props) {
    super(props);
    this.initState();
    this.setCallbacks();
    this.setFiltersMap();
  }

  initState() {
    this.state = { filtersOpen: false, bookingFilters: null, memberFilters: null };
  }

  setCallbacks() {
    this.showFilters = () => {
      this.setState({ filtersOpen: true });
    };

    this.closeFilters = () => {
      this.setState({ filtersOpen: false });
    };

    this.handleSave = fields => {
      const filters = this.getCurrentFilters();

      if (!safe(() => filters.companyIds.length)) {
        return addErrorMsg(intlMsg(this, 'scheduled.export.filters.mandatory'));
      }
      this.props.onSave({ fields, filters });
    };
  }

  getCurrentFilters() {
    return safe(() => this.state[this.getFiltersData().stateFilters]);
  }

  getFiltersMapProps(Component, stateFilters) {
    let filtersSubmit = fallbackFunc;

    return {
      stateFilters,
      getFilters: () => (
        <Component
          initialValues={this.state[stateFilters] || this.props.filtersInitialValues}
          isView={this.props.isView}
          onSubmitSet={submit => {
            filtersSubmit = submit;
          }}
        />
      ),
      onFiltersApply: () => {
        filtersSubmit(data => {
          data = cleanDeep(data);
          this.setState({ [stateFilters]: isObjEmpty(data) ? null : data });
          this.closeFilters();
        })();
      },
      onFiltersReset: () => {
        this.setState({ [stateFilters]: null });
        this.closeFilters();
      }
    };
  }

  setFiltersMap() {
    this.filtersMap = {
      [SCHEDULED_EXPORT.TYPE.BOOKING]: {
        getTitle: () => <FormattedMessage id="common_booking" />,
        ...this.getFiltersMapProps(BookingFilters, 'bookingFilters')
      },
      [SCHEDULED_EXPORT.TYPE.MEMBER_PROFILE]: {
        getTitle: () => <FormattedMessage id="user_type_member" />,
        ...this.getFiltersMapProps(MemberFilters, 'memberFilters')
      }
    };
  }

  isSftpDestination() {
    return this.props.fields.destination.value === SCHEDULED_EXPORT.DESTINATION.SFTP;
  }

  isS3Destination() {
    return this.props.fields.destination.value === SCHEDULED_EXPORT.DESTINATION.S3;
  }

  isFileSecurity() {
    return this.props.fields.security.value === SCHEDULED_EXPORT.SECURITY.FILE;
  }

  shouldDisplayFile() {
    return !this.props.isView && this.isSftpDestination() && this.isFileSecurity();
  }

  getSftpFile() {
    const { fields: f } = this.props;
    return (
      <div className="sc-file">
        <FileUpload label="scheduled.export.sftp.file" id="sftp-keyfile" field={f.privateKeyFileId} isPublicUpload={false} />
        <FieldErrorMsg field={f.privateKeyFileId} />
      </div>
    );
  }

  getDestinationSwitch() {
    return (
      <BoxedSelect
        id="export-dest"
        field={this.props.fields.destination}
        labelKey="scheduled.export.destination"
        options={scheduledExportDestinationOptions}
        disabled={this.props.isView}
        noEmptyValue
        fullWidth
        sort={false}
      />
    );
  }

  getSftpFields() {
    const p = this.props;
    const f = p.fields;

    return (
      <div className={cs('sc-settings-fields', 'sc-sftp-fields', { hidden: this.isS3Destination() })}>
        <div className="sc-destination-fields">
          {this.getDestinationSwitch()}
          <BoxedInput id="sftp-host" labelKey="common_host" field={f.host} disabled={p.isView}>
            <FieldErrorMsg field={f.host} />
          </BoxedInput>
          <BoxedInput id="sftp-path" labelKey="common_path" field={f.path} disabled={p.isView}>
            <FieldErrorMsg field={f.path} />
          </BoxedInput>
          <BoxedInput id="sftp-port" labelKey="common_port" field={f.port} disabled={p.isView}>
            <FieldErrorMsg field={f.port} />
          </BoxedInput>
        </div>
        <div className={cs('sc-security-fields', { 'sc-file-security': this.isFileSecurity() })}>
          {!p.isView && (
            <BoxedSelect
              id="sftp-security"
              labelKey="scheduled.export.sftp.security"
              field={f.security}
              options={scheduledExportSecurityOptions}
              disabled={p.isView}
              noEmptyValue
              fullWidth
              sort={false}
            />
          )}
          <BoxedInput id="sftp-username" labelKey="scheduled.export.sftp.login" field={f.username} disabled={p.isView}>
            <FieldErrorMsg field={f.username} />
          </BoxedInput>
          {!p.isView && !this.isFileSecurity() && (
            <BoxedInput id="sftp-password" labelKey="common_password" type="password" field={f.password}>
              <FieldErrorMsg field={f.password} />
            </BoxedInput>
          )}
        </div>
      </div>
    );
  }

  getS3Fields() {
    const p = this.props;
    const f = p.fields;

    return (
      <div className={cs('sc-settings-fields', 'sc-s3-fields', { hidden: !this.isS3Destination() })}>
        {this.getDestinationSwitch()}
        <BoxedInput id="s3-bucket" labelKey="aws.s3.bucket" field={f.bucket} disabled={p.isView}>
          <FieldErrorMsg field={f.bucket} />
        </BoxedInput>
        <BoxedInput id="s3-path" labelKey="common_path" field={f.path} disabled={p.isView}>
          <FieldErrorMsg field={f.path} />
        </BoxedInput>
        <BoxedInput id="s3-access-key" labelKey="aws.s3.access.key" field={f.accessKey} disabled={p.isView}>
          <FieldErrorMsg field={f.accessKey} />
        </BoxedInput>
        {!p.isView && (
          <BoxedInput id="s3-secret-key" type="password" labelKey="aws.s3.secret.access.key" field={f.secretAccessKey}>
            <FieldErrorMsg field={f.secretAccessKey} />
          </BoxedInput>
        )}
      </div>
    );
  }

  getFiltersData() {
    return this.filtersMap[this.props.fields.resourceType.value];
  }

  getFiltersModal() {
    const d = this.getFiltersData();
    if (d) {
      return (
        <ListModal title={d.getTitle()} isOpen={this.state.filtersOpen} onClose={this.closeFilters} className="list-filters-modal">
          <ListFilters onFiltersApply={d.onFiltersApply} onFiltersReset={d.onFiltersReset} readOnly={this.props.isView}>
            {d.getFilters()}
          </ListFilters>
        </ListModal>
      );
    }
  }

  getFiltersButtonLabel() {
    const p = this.props;

    if (p.isView) {
      return p.filtersInitialValues ? (
        <FormattedMessage id="scheduled.export.filters.view" />
      ) : (
        <FormattedMessage id="scheduled.export.filters.empty" />
      );
    }
    return this.getCurrentFilters() ? (
      <FormattedMessage id="scheduled.export.filters.update" />
    ) : (
      <FormattedMessage id="scheduled.export.filters.set" />
    );
  }

  render() {
    const p = this.props;
    const f = p.fields;

    return (
      <div className={cs('scheduled-export-form', { 'sc-view': p.isView })}>
        <div className="sc-details">
          <div className="sc-details-info">
            <div className="sc-details-fields">
              <BoxedSelect
                id="export-type"
                field={f.resourceType}
                labelKey="scheduled.export.type"
                options={scheduledExportTypeOptions}
                disabled={p.isView}
                noEmptyValue
                fullWidth
                sort={false}
              />
              {p.isView && <BoxedInput id="export-creator" field={f.creator} labelKey="scheduled.export.creator" disabled={p.isView} />}
            </div>
            <div className="sc-details-desc">
              <BoxedInput id="export-desc" field={f.description} labelKey="common_description" type="textarea" disabled={p.isView}>
                <FieldErrorMsg field={f.description} />
              </BoxedInput>
            </div>
          </div>
          <div className="sc-export-filters">
            <button
              className={cs('ekButton', { 'ekButton--reverse': this.getCurrentFilters() || p.isView })}
              disabled={p.isView && !p.filtersInitialValues}
              id="export-filters"
              onClick={this.showFilters}
            >
              {this.getFiltersButtonLabel()}
            </button>
          </div>
        </div>
        <div className="sc-settings">
          {this.getS3Fields()}
          {this.getSftpFields()}
          {this.shouldDisplayFile() && this.getSftpFile()}
          <div className="sc-frequency">
            <EkRadio
              id="export-frequency"
              titleKey="common.frequency"
              field={f.frequency}
              items={scheduledExportFrequencyOptions}
              disabled={p.isView}
            />
          </div>
        </div>
        <div className="sc-file-format">
          <div className="sc-title">
            <FormattedMessage id="scheduled.export.file.name.format" />
          </div>
          <div className="sc-fields">
            <BoxedInput id="export-file-prefix" labelKey="common_prefix" field={f.prefix} disabled={p.isView} />
            <BoxedSelect
              id="export-file-date"
              labelKey="common_date_format"
              field={f.dateFormat}
              options={scheduledExportDateFormatOptions}
              disabled={p.isView}
              sort={false}
              fullWidth
            />
            <BoxedInput id="export-file-suffix" labelKey="common_suffix" field={f.suffix} disabled={p.isView} />
          </div>
        </div>
        <div className="sc-actions">
          {p.onDelete && (
            <button className={cs('ekButton', 'ekButton--reverse')} id="delete-export-config" onClick={p.onDelete}>
              <FormattedMessage id="common_delete" />
            </button>
          )}
          {p.onSave && (
            <button className="ekButton" id="save-export-config" onClick={p.handleSubmit(this.handleSave)}>
              <FormattedMessage id="common_save" />
            </button>
          )}
        </div>
        {this.getFiltersModal()}
      </div>
    );
  }
}

const destinationS3 = (props, value, values) => {
  return getFieldValue(props, values, 'destination') === SCHEDULED_EXPORT.DESTINATION.S3;
};

const fileSecurity = (props, value, values) => {
  return getFieldValue(props, values, 'security') === SCHEDULED_EXPORT.SECURITY.FILE;
};

ScheduledExportForm.propTypes = {
  isView: PropTypes.bool,
  onSave: PropTypes.func,
  onDelete: PropTypes.func,
  filtersInitialValues: PropTypes.object
};

ScheduledExportForm = reduxForm({
  onSubmitFail: scrollToFirstError,
  form: 'scheduledExport',
  fields: [
    'resourceType',
    'description',
    'creator',
    'destination',
    'frequency',
    'host',
    'path',
    'port',
    'security',
    'username',
    'password',
    'bucket',
    'accessKey',
    'secretAccessKey',
    'privateKeyFileId',
    'dateFormat',
    'prefix',
    'suffix'
  ],
  validate: createValidator({
    description: [notEmpty()],
    path: [notEmpty()],
    bucket: [stopValidationIf(con(not(destinationS3))), notEmpty()],
    accessKey: [stopValidationIf(con(not(destinationS3))), notEmpty()],
    secretAccessKey: [stopValidationIf(con(not(destinationS3))), notEmpty()],
    host: [stopValidationIf(con(destinationS3)), notEmpty()],
    port: [stopValidationIf(con(destinationS3)), notEmpty()],
    username: [stopValidationIf(con(destinationS3)), notEmpty()],
    password: [stopValidationIf(con(destinationS3)), stopValidationIf(con(fileSecurity)), notEmpty()],
    privateKeyFileId: [stopValidationIf(con(destinationS3)), stopValidationIf(con(not(fileSecurity))), notEmpty()]
  })
})(ScheduledExportForm);

ScheduledExportForm = injectIntl(ScheduledExportForm);

export default ScheduledExportForm;
