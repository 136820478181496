import DashboardItem from '../../components/DashboardItem/DashboardItem';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { BACKUSER_ROLE_ROOT, BACKUSER_ROLE_SUPER_ADMIN } from '../../constants/backend-constants';
import { dashboardLinks as links } from '../../constants/api-params-constants';
import { headerContractMemberValuesSelector } from '../../selectors/all-selectors';
import IsGranted from '../../components/IsGranted/IsGranted';

class Dashboard extends Component {
  render() {
    const props = this.props;

    return (
      <div className="dashboardPage">
        <div className="pageContainer">
          <div className="dashboardPage_content">
            <section className="dashboardPage_management">
              <div className="dashboardPage_list">
                <ul className="dashboardItems">
                  <DashboardItem
                    title="dashboard_last_bookings_title"
                    label="dashboard_last_bookings_label"
                    count={props.lastBookings.count}
                    link={links.lastBookings}
                    icon="/img/schedule.svg"
                  />
                  <DashboardItem
                    title="dashboard_delayed_bookings_title"
                    label="dashboard_delayed_bookings_label"
                    count={props.delayedBookings.count}
                    link={links.delayedBookings}
                    icon="/img/delayed.svg"
                  />
                  <DashboardItem
                    title="dashboard_damages_title"
                    label="dashboard_damages_label"
                    count={props.unmanagedDamages.count}
                    link={links.unmanagedDamages}
                    icon="/img/icon-damage.svg"
                  />
                  <DashboardItem
                    title="dashboard_cleanliness_title"
                    label="dashboard_cleanliness_label"
                    count={props.cleanlinessProblems.count}
                    link={links.cleanlinessProblems}
                    icon="/img/automatic-wash-car.svg"
                  />
                  <DashboardItem
                    title="dashboard_member_applicants_title"
                    label="dashboard_member_applicants_label"
                    count={props.nonValidatedMembers.count}
                    link={links.nonValidatedMembers}
                    icon="/img/applicant.svg"
                  />
                  {props.memberRules.expediteReview && (
                    <DashboardItem
                      title="dashboard_member_expedite_title"
                      label="dashboard_member_expedite_label"
                      count={props.expediteMembers.count}
                      link={links.expediteMembers}
                      icon="/img/stopwatch.svg"
                    />
                  )}
                  <DashboardItem
                    title="dashboard_low_battery_vehicles_title"
                    label="dashboard_low_battery_vehicles_label"
                    count={props.lowAccessoryBatVehicles.count}
                    link={links.lowAccessoryBatVehicles}
                    icon="/img/battery.svg"
                  />
                  <IsGranted allowed={[BACKUSER_ROLE_ROOT, BACKUSER_ROLE_SUPER_ADMIN]}>
                    <DashboardItem
                      title="dashboard_failed_bookings_title"
                      label="dashboard_failed_bookings_label"
                      count={props.failedBookings.count}
                      link={links.failedBookings}
                      icon="/img/incident.svg"
                    />
                  </IsGranted>
                  <IsGranted allowed={[BACKUSER_ROLE_ROOT, BACKUSER_ROLE_SUPER_ADMIN]}>
                    <DashboardItem
                      title="dashboard_failed_bookings_title"
                      label="dashboard_failed_rrs_bookings_label"
                      count={props.rrsFailedBookings.count}
                      link={links.rrsFailedBookings}
                      icon="/img/incident.svg"
                    />
                  </IsGranted>
                  <DashboardItem
                    id="non-operating-vehicles"
                    title="label.non.operating.vehicles"
                    label="label.non.operating.vehicles"
                    count={props.nonOperatingVehicles.count}
                    link={links.nonOperatingVehicles}
                    icon="/img/dashboard/vehicle-warning.svg"
                  />
                  <DashboardItem
                    id="fuel-charge-level"
                    title="dashboard.fuelCharge.Level"
                    label="dashboard.fuelCharge.Level.label"
                    count={props.lowFuelLevel.count}
                    link={links.lowFuelLevel}
                    icon="/img/dashboard/low-fuel-charge.svg"
                  />
                </ul>
              </div>
            </section>
          </div>
        </div>
      </div>
    );
  }
}

Dashboard.displayName = 'Dashboard';

export default connect(state => {
  const {
    dashboard: {
      lastBookings,
      delayedBookings,
      nonValidatedMembers,
      unmanagedDamages,
      cleanlinessProblems,
      failedBookings,
      expediteMembers,
      rrsFailedBookings,
      lowAccessoryBatVehicles,
      nonOperatingVehicles,
      lowFuelLevel
    }
  } = state;

  return {
    lastBookings,
    delayedBookings,
    nonValidatedMembers,
    unmanagedDamages,
    cleanlinessProblems,
    failedBookings,
    expediteMembers,
    rrsFailedBookings,
    lowAccessoryBatVehicles,
    nonOperatingVehicles,
    lowFuelLevel,
    memberRules: headerContractMemberValuesSelector(state)
  };
})(Dashboard);
