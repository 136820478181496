import React, { Component } from 'react';
import { showParentView } from '../../../../../components/_v2/ColumnList/helpers';
import ParkingDetailsModal from '../../../../../containers/ParkingDetails/Modal';

export class OrganizationEditParking extends Component {
  componentWillMount() {
    this.showParentView = showParentView.bind(this);
  }

  render() {
    return <ParkingDetailsModal isOpen onClose={this.showParentView} />;
  }
}

export default OrganizationEditParking;
