import React, { Component, PropTypes } from 'react';
import { connect } from 'react-redux';
import { routeActions } from 'react-router-redux';
import _get from 'lodash/get';
import _partial from 'lodash/partial';
import { Row, Col } from 'react-bootstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import Dialog from 'material-ui/Dialog';
import IconButton from 'material-ui/IconButton';
import CloseIcon from 'material-ui/svg-icons/navigation/close';
import FlatButton from 'material-ui/FlatButton';
import classNames from 'classnames';

import { smartcardsEventsHeaderParams } from '../../constants/table-constants';
import AdvancedList from '../../components/AdvancedList/AdvancedList';
import Filters from '../../components/AdvancedList/components/Filters';
import {
  getSmartcardsEventsList,
  clearSmartcardsListData,
  getDetailWithCardId,
  createSmartcard,
  errorUserSelectedToEventSmartcard,
  smartcardsSearchBackUser,
  smartcardsSearchMember
} from '../../actions/all-actions';
import { smartcardsEventsFilterChips } from '../../constants/options-constants';
import { getAppFormattedDateTime, trySet, append, safe, getMainColor } from '../../utils/utils';
import routes, { smartcardEventEditRules } from '../../constants/routes-constants';
import CardIcon from 'material-ui/svg-icons/action/credit-card';
import FilterChips from '../../components/FilterChips/FilterChips';
import { smartCardEventsFilterTypes } from '../../constants/filterTypes-constants';
import { userRoleSelector } from '../../selectors/all-selectors';
import { checkRole } from '../../constants/backuser-role-rules';
import { Tab, Tabs } from 'material-ui/Tabs';
import SearchForUser from '../SmartcardsDetail/SearchForUser';
import { smartcardsUserSearchTypes as searchTypes } from '../../constants/generic-constants';
import autoBind from 'react-autobind';
import _debounce from 'lodash/debounce';

const searchProp = {
  member: {
    [searchTypes.EMAIL]: 'email',
    [searchTypes.LAST_NAME]: 'lastname',
    [searchTypes.FIRST_NAME]: 'firstname'
  },
  backUser: {
    [searchTypes.EMAIL]: 'email',
    [searchTypes.LAST_NAME]: 'lastName',
    [searchTypes.FIRST_NAME]: 'firstName'
  }
};

const initialSelectedIndex = 0;

class SmartcardsEvents extends Component {
  constructor(props) {
    super(props);
    autoBind(this);

    this.initState();
    this.delayFunctions();
    this.setVariables();
    this.setReadOnly(props);
  }

  initState() {
    this.state = {
      searchUserModalOpen: false,
      foundUsers: [],
      selectedEvent: {},
      cannotAssignModalOpen: false,
      searchText: '',
      focusedTab: initialSelectedIndex,
      selectField: { value: searchTypes.EMAIL, onChange: this.handleSelectUpdate }
    };
  }

  delayFunctions() {
    this.handleGetMembers = _debounce(this.handleGetMembers.bind(this), 250);
    this.handleGetBackUsers = _debounce(this.handleGetBackUsers.bind(this), 250);
  }

  setVariables() {
    this.userHandlers = {
      0: this.handleGetMembers,
      1: this.handleGetBackUsers
    };

    this.styles = {
      headline: {
        fontSize: 24,
        paddingTop: 16,
        fontWeight: 400
      },
      tab: {
        backgroundColor: 'white',
        color: getMainColor()
      },
      inkBar: {
        backgroundColor: getMainColor()
      }
    };
  }

  setReadOnly(props) {
    const { role } = props;
    this.readOnly = !checkRole(smartcardEventEditRules, role);
  }

  componentWillMount() {
    const { pageParams } = this.props;
    this.callApi(pageParams);
  }

  componentWillUnmount() {
    this.props.dispatch(clearSmartcardsListData());
  }

  handleSelectUpdate(value) {
    this.setState({ selectField: { value, onChange: this.handleSelectUpdate } });
    this.focusTab(this.state.focusedTab);
    this.clearFoundUsers();
    this.clearInput();
  }

  handleTabChange(tab) {
    this.setState({ focusedTab: tab.props.index });
    this.focusTab(tab.props.index);
    this.clearFoundUsers();
    this.clearInput();
  }

  clearInput() {
    this.setState({ searchText: '' });
  }

  clearFoundUsers() {
    this.setState({ foundUsers: [] });
  }

  focusFirstTab() {
    this.focusTab(initialSelectedIndex);
  }

  focusTab(index) {
    document.getElementById('smartcards.search.' + index).focus();
  }

  handleUpdateInput(input) {
    const text = input.trim();
    this.setState({ searchText: input });

    if (text) {
      this.userHandlers[this.state.focusedTab](text);
    } else {
      this.setState({ foundUsers: [] });
    }
  }

  callApi(params) {
    const { dispatch } = this.props;
    dispatch(getSmartcardsEventsList(params));
    dispatch(routeActions.push(routes.smartcardsEvents.path.replace(':search', encodeURIComponent(JSON.stringify(params)))));
  }

  handleChangePage(value) {
    const { dispatch, listMetadata, urlParams } = this.props;
    const params = {
      page: {
        number: value,
        size: listMetadata.paginationInfo.pageSize
      }
    };
    const newParams = { ...urlParams, ...params };
    this.callApi(newParams);
    dispatch(routeActions.push(routes.smartcardsEvents.path.replace(':search', encodeURIComponent(JSON.stringify(newParams)))));
  }

  handleRefreshList() {
    const { pageParams } = this.props;
    this.callApi(pageParams);
  }

  handleNumberResultsSelect(value) {
    const { dispatch, urlParams } = this.props;
    const params = {
      page: {
        number: 1,
        size: parseInt(value)
      }
    };
    const newParams = { ...urlParams, ...params };
    this.callApi(newParams);
    dispatch(routeActions.push(routes.smartcardsEvents.path.replace(':search', encodeURIComponent(JSON.stringify(newParams)))));
  }

  handleGotoDetail(params) {
    this.props.dispatch(routeActions.push(routes[params[0]].path.replace(params[1], params[2])));
  }

  handleMemberCardOwner(item) {
    const { companyId, vehicleCompanyId, userFullName, smartcardCardId } = item || {};
    const vehicleInSameCompany = companyId === vehicleCompanyId;

    if (vehicleInSameCompany && userFullName) {
      return userFullName;
    }

    return smartcardCardId || '-';
  }

  handleCheckSmartcard(smartcardEvent) {
    const { dispatch } = this.props;

    if (!_get(smartcardEvent, 'vehicleCompanyId', false)) {
      this.handleToggleCannotAssignModal();
      return;
    }

    dispatch(getDetailWithCardId(smartcardEvent.smartcardCardId)).then(
      res => {
        this.handleGotoDetail(['smartcardsDetail', ':id', res.id]);
      },
      () => this.setState({ searchUserModalOpen: true, selectedEvent: smartcardEvent })
    );
  }

  handleToggleCannotAssignModal() {
    this.setState({ cannotAssignModalOpen: !this.state.cannotAssignModalOpen });
  }

  renderItem(item, id, reactKey) {
    const colProps = { xs: 2, md: 2 };

    return (
      <div className="advancedList_rowWrap" key={reactKey}>
        <Row
          id={id}
          className="advancedList_row bookingsPage_listRow"
          onClick={this.readOnly ? undefined : _partial(this.handleCheckSmartcard, item)}
        >
          <Col xs="3" mad="3">
            <div className="bookingsPage_firstItemWrap">
              <div className="bgCar smarctcardsListIcon">
                <CardIcon />
              </div>
              <div>
                <div className="bookingsPage_doubleColumnWrap">{getAppFormattedDateTime(item.eventDateTime, { local: true })}</div>
              </div>
            </div>
          </Col>
          <Col {...colProps}>{this.handleMemberCardOwner(item)}</Col>
          <Col {...colProps}>{item.vehicleRegistrationNumber}</Col>
          <Col {...colProps}>{item.vehicleCompanyName}</Col>
          <Col xs="1" mad="1">
            {item.action}
          </Col>
          <Col {...colProps}>
            <FormattedMessage id={'smartcardsEvents_result_' + item.result} />
          </Col>
        </Row>
      </div>
    );
  }

  handleSearchForm(params) {
    const { dispatch, listMetadata, urlParams } = this.props;
    params.page = {
      number: 1,
      size: listMetadata.paginationInfo.pageSize
    };
    let newParams = {
      ...urlParams,
      ...params
    };
    this.callApi(newParams);
    dispatch(routeActions.push(routes.smartcardsEvents.path.replace(':search', encodeURIComponent(JSON.stringify(newParams)))));
  }

  handleDeleteChip(item) {
    let { urlParams, dispatch } = this.props;
    delete urlParams[item];
    this.callApi(urlParams);
    dispatch(routeActions.push(routes.smartcardsEvents.path.replace(':search', encodeURIComponent(JSON.stringify(urlParams)))));
  }

  handleGetMembers(input) {
    const smartcardCompanyId = safe(() => this.state.selectedEvent.vehicleCompanyId);
    const params = { [searchProp.member[this.state.selectField.value]]: input };

    trySet(params, 'companyIds', append([smartcardCompanyId]));

    this.props.dispatch(smartcardsSearchMember(params)).then((data = {}) => {
      const users = (data.results || []).map((item = {}) => {
        const brand = item.brandName;
        const brandMsg = brand ? ' — ' + brand : '';

        return {
          text: item.firstName + ' ' + item.lastName + ' — ' + item.login + brandMsg,
          value: item.userId || item.id
        };
      });

      this.setState({ foundUsers: users });
    });
  }

  handleGetBackUsers(input) {
    const smartcardCompanyId = safe(() => this.state.selectedEvent.vehicleCompanyId);
    const params = { [searchProp.backUser[this.state.selectField.value]]: input };

    trySet(params, 'companyIds', append([smartcardCompanyId]));

    this.props.dispatch(smartcardsSearchBackUser(params)).then((data = {}) => {
      const users = (data.results || []).map((item = {}) => {
        return {
          text: item.firstName + ' ' + item.lastName + ' — ' + item.login,
          value: item.userId || item.id
        };
      });

      this.setState({ foundUsers: users });
    });
  }

  handleSelectUser(user) {
    const { selectedEvent } = this.state;
    const { vehicleCompanyId, smartcardCardId, smartcardProtocol } = selectedEvent || {};

    if (user.hasOwnProperty('value')) {
      this.props.dispatch(createSmartcard(vehicleCompanyId, user.value, smartcardCardId, smartcardProtocol)).then(res => {
        this.handleGotoDetail(['smartcardsDetail', ':id', res.id]);
      });

      this.handleCloseSearchMember();
    } else {
      this.props.dispatch(errorUserSelectedToEventSmartcard());
    }
  }

  handleCloseSearchMember() {
    this.setState({ searchUserModalOpen: false, selectedEvent: {}, foundUsers: [], errorMemberSelection: true });
  }

  assignUserDialog() {
    const { errorMemberSelection } = this.props;

    return (
      <Dialog
        bodyStyle={{ overflow: 'inherit' }}
        contentStyle={{ width: '600px' }}
        onRequestClose={this.handleCloseSearchMember}
        open={this.state.searchUserModalOpen}
      >
        <div className="smartcardsDetail_closeIconAbosuluteWrap">
          <IconButton onClick={this.handleCloseSearchMember}>
            <CloseIcon />
          </IconButton>
        </div>
        <Tabs
          style={{ marginTop: '10px' }}
          className="tabs-rci smartcardsDetail_tabs"
          inkBarStyle={this.styles.inkBar}
          initialSelectedIndex={initialSelectedIndex}
        >
          <Tab
            label={<FormattedMessage id="user_type_member" />}
            buttonStyle={this.styles.tab}
            className="tab"
            onActive={this.handleTabChange}
          >
            <SearchForUser
              selectTitleKey="common_search_by"
              inputId="smartcards.search.0"
              onDidMount={this.focusFirstTab}
              onUpdate={this.handleUpdateInput}
              onRequest={this.handleSelectUser}
              searchText={this.state.searchText}
              selectField={this.state.selectField}
              foundUsers={this.state.foundUsers}
              errorDuringSelection={errorMemberSelection}
            />
          </Tab>
          <Tab
            label={<FormattedMessage id="common_back_user" />}
            buttonStyle={this.styles.tab}
            className="tab"
            onActive={this.handleTabChange}
          >
            <SearchForUser
              selectTitleKey="common_search_by"
              inputId="smartcards.search.1"
              onUpdate={this.handleUpdateInput}
              onRequest={this.handleSelectUser}
              searchText={this.state.searchText}
              selectField={this.state.selectField}
              foundUsers={this.state.foundUsers}
              errorDuringSelection={errorMemberSelection}
            />
          </Tab>
        </Tabs>
      </Dialog>
    );
  }

  render() {
    const { list, listMetadata, loadingList, urlParams, intl } = this.props;

    return (
      <div className="smartcardsEventsPage mainContainer_content">
        <div className="pageContainer">
          <div className={classNames('smartcardsEventsPage_content', 'bookingsPage_management', { readOnly: this.readOnly })}>
            <FilterChips
              urlParams={urlParams}
              onDeleteChip={this.handleDeleteChip}
              translations={smartcardsEventsFilterChips}
              filterTypes={smartCardEventsFilterTypes}
              id="smartcardsEvents-chips"
            />

            <div className="advancedList_actions page">
              <Filters
                filterTypes={smartCardEventsFilterTypes}
                onFilteredSearchForm={this.handleSearchForm}
                id="smartcardsEvents-filters"
              />
            </div>

            <AdvancedList
              id="smartcardsEvents-list"
              data={list}
              listMetadata={listMetadata}
              loading={loadingList}
              renderItem={this.renderItem}
              onChangePage={this.handleChangePage}
              onRefreshList={this.handleRefreshList}
              onChangeRowsPerPage={this.handleNumberResultsSelect}
              error={false}
              urlParams={urlParams}
              header={smartcardsEventsHeaderParams}
            />
          </div>

          {this.assignUserDialog()}

          {this.state.cannotAssignModalOpen && (
            <Dialog
              title={intl.messages.smartcard_cannot_be_assigned}
              contentStyle={{ width: '500px' }}
              onRequestClose={this.handleToggleCannotAssignModal}
              open={this.state.cannotAssignModalOpen}
              actions={[<FlatButton key="ok" label="ok" primary className="flatButton" onClick={this.handleToggleCannotAssignModal} />]}
            />
          )}
        </div>
      </div>
    );
  }
}

SmartcardsEvents.displayName = 'SmartcardsEvents';

SmartcardsEvents.propTypes = {
  list: PropTypes.array,
  listMetadata: PropTypes.object
};

export default connect(state => {
  const {
    smartcardsEvents: {
      paginatedResults,
      currentSortedIndex,
      sortIsDescending,
      urlParams,
      filtersFormIsOpen,
      errorMemberSelection,
      loadingList
    },
    page: { params },
    user: { userInfo },
    companies: { currentCompany },
    subCompanies: { subCompanySelected }
  } = state;

  return {
    list: _get(paginatedResults, 'results', []),
    listMetadata: _get(paginatedResults, 'metadata', {}),
    currentSortedIndex,
    sortIsDescending,
    urlParams,
    filtersFormIsOpen,
    loadingList,
    pageParams: params,
    userInfo,
    currentCompany,
    subCompanySelected,
    errorMemberSelection,
    role: userRoleSelector(state)
  };
})(injectIntl(SmartcardsEvents));
