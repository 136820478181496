import * as types from '../constants/actionTypes-constants';
import callApi from './api-actions';

import {
  addErrorMessage,
  append,
  fieldEnabled,
  isInternetExplorer11,
  isValidId,
  setIfString,
  trimValues,
  trySet,
  renameKey,
  toBoolean,
  fallbackFunc,
  i18nAdditionnalDocTitleKeys,
  safe
} from '../utils/utils';

import {
  adjustSafetyDepositValues,
  fixTypesForSafetyDepositValues,
  getItalianInvoiceCredentials,
  setFormattedAddress
} from '../utils/dataFormatUtils';
import _pick from 'lodash/pick';
import _get from 'lodash/get';

import _isString from 'lodash/isString';
import _isObject from 'lodash/isObject';
import _includes from 'lodash/includes';
import _isEqual from 'lodash/isEqual';
import _union from 'lodash/union';
import _isEmpty from 'lodash/isEmpty';
import { validateInvoiceErrors } from './subCompanies-actions';
import { addFlashMessage } from './flashMessage-actions';

import { ALL, FLASH_MESSAGE_TYPE_ERROR, FLASH_MESSAGE_TYPE_SUCCESS, invoicePreviewDefault } from '../constants/generic-constants';

import { COMPANY_EMAIL_SETUP_FORM, COMPANY_FORM, COMPANY_STRIPE_SETTINGS_FORM } from '../constants/form-constants';
import moment from 'moment';
import { saveAs } from 'file-saver';
import { getInvoiceParamsBase, getItalianInvoiceParams } from '../utils/dataFormatUtils';
import {
  detailedCompanySelector,
  headerCompanyDetailsSelector,
  headerCompanyListSelector,
  isSubCompanyLevelUserSelector,
  userCompaniesSelector,
  userRoleSelector,
  validCompanyIdSelector
} from '../selectors/all-selectors';
import { getPaybackSettingsParse } from '../api/data-enhancer';
import {
  sharedCompanyCreateBankoutConfigAction,
  sharedCompanyUpdateBankDetailsAction,
  sharedCompanyUpdateBankoutConfigAction
} from './sharedCompany-actions';
import {
  BE_CURRENCIES_FALLBACK,
  BILLING_ENTITY_TYPE_SUPER_COMPANY,
  EXTERNAL_INVOICE,
  TEMPLATE_EMAIL_DEFAULT,
  TEMPLATE_MODEL_ITALIAN
} from '../constants/backend-constants';

import { checkRole, superCompanyPaybackBankDetailsEditRules } from '../constants/backuser-role-rules';
import { getStore } from '../store/all-store';
import { getMsg } from '../utils/IntlGlobalProvider';

export function setCurrentTabIndex(index) {
  return {
    type: types.COMPANIES_SET_CURRENT_INDEX,
    index
  };
}

export function getHeaderCompaniesList() {
  return dispatch => {
    dispatch({ type: types.COMPANIES_GET_HEADER_LIST_REQUEST });

    return dispatch(callApi('companies', 'getList'))
      .then(data => {
        dispatch(setHeaderCompaniesList(data));
        return Promise.resolve(data);
      })
      .catch(error => {
        dispatch(addErrorMessage({ error }));
        dispatch(clearHeaderCompaniesList());
        return Promise.resolve([]);
      });
  };
}

export function setHeaderCompaniesList(data) {
  return {
    type: types.COMPANIES_GET_HEADER_LIST_SUCCESS,
    list: data
  };
}

export function clearHeaderCompaniesList() {
  return { type: types.COMPANIES_CLEAR_HEADER_LIST_DATA };
}

export function getCompanyPaymentDetails(companyId) {
  return dispatch => {
    dispatch({ type: types.COMPANIES_PAYMENT_DETAILS_REQUEST });

    return dispatch(callApi('bankouts', 'getCompanyPaymentDetails', companyId)).then(function(data) {
      if (_isEmpty(data)) return null;

      dispatch({
        type: types.COMPANIES_PAYMENT_DETAILS_SUCCESS,
        companyPaymentDetails: data
      });

      return data;
    });
  };
}

export function getCompaniesListWithSearch(params, loader = false) {
  return (dispatch, getState) => {
    const state = getState();

    let paramsObj;

    if (params) {
      let pageNumber = params.page ? params.page.number : state.companies.paginatedResults.metadata.paginationInfo.currentPage;
      let pageSize = params.page ? params.page.size : state.companies.paginatedResults.metadata.paginationInfo.pageSize;

      paramsObj = {
        page: {
          number: pageNumber,
          size: pageSize
        }
      };

      let sortProperty = params.sort ? params.sort.property : state.companies.currentSortedIndex;
      let sortIsDescending = params.sort ? params.sort.isDescending : state.companies.sortIsDescending;

      let sort = {
        property: sortProperty,
        isDescending: sortIsDescending
      };

      if (sortProperty) {
        paramsObj = Object.assign(paramsObj, { sort });
      }

      paramsObj = Object.assign({}, paramsObj, _pick(params, ['name', 'brandId']));
    } else {
      paramsObj = null;
    }

    dispatch({
      type: types.COMPANIES_GET_LIST_REQUEST
    });

    return dispatch(callApi('companies', 'getListWithSearch', paramsObj, loader)).then(
      data => {
        dispatch({
          type: types.COMPANIES_GET_LIST_WITH_SEARCH_SUCCESS,
          paginatedObj: data,
          urlParams: paramsObj
        });
        return Promise.resolve(data);
      },
      error => {
        const msgPrefix = getMsg('data.with.colon', { values: { data: getMsg('common_super_companies') } }) + ' ';
        dispatch(addErrorMessage({ msgPrefix, error }));
        dispatch({ type: types.COMPANIES_GET_LIST_WITH_SEARCH_ERROR, urlParams: paramsObj });
        return Promise.resolve([]);
      }
    );
  };
}

export function getCompaniesListIfNeeded() {
  return (dispatch, getState) => {
    const state = getState();
    const companiesList = headerCompanyListSelector(state);

    if (isSubCompanyLevelUserSelector(state)) {
      const userCompanies = userCompaniesSelector(state);

      dispatch(setHeaderCompaniesList(userCompanies));
      return Promise.resolve(userCompanies);
    }

    if (!companiesList.length) {
      return dispatch(getHeaderCompaniesList());
    } else {
      return Promise.resolve(companiesList);
    }
  };
}

export function setCurrentCompany(company) {
  return (dispatch, getState) => {
    dispatch({ type: types.COMPANIES_SET_CURRENT_COMPANY, company });

    const subCompanyLevelUser = isSubCompanyLevelUserSelector(getStore().getState());

    const setQuickCompany = () => {
      dispatch({
        type: types.COMPANIES_SET_CURRENT_COMPANY_DETAILS,
        companyDetails: company
      });
    };

    if (company.id === ALL) {
      return setQuickCompany();
    }

    // needed to set 'contract' when using 'userHaveOnlyOneSuperCompanySelector' in 'hooks-routing.js'
    if (subCompanyLevelUser) {
      setQuickCompany();
      const companyId = validCompanyIdSelector(getState());
      if (companyId) dispatch(getCompanyCurrentContract(companyId));
      return;
    }

    return dispatch(callApi('companies', 'getCompanyDetail', company.id, false)).then(data => {
      dispatch({
        type: types.COMPANIES_SET_CURRENT_COMPANY_DETAILS,
        companyDetails: data
      });
      return data;
    });
  };
}

export function getCompanyCurrentContract(companyId) {
  return (dispatch, getState) => {
    dispatch({ type: types.COMPANIES_GET_CURRENT_CONTRACT_REQUEST });

    if (!isValidId(companyId)) return Promise.resolve();

    return dispatch(callApi('companies', 'getCurrentContract', companyId)).then(data => {
      const state = getState();
      const headerCompany = headerCompanyDetailsSelector(state);

      if (headerCompany.id === companyId) {
        dispatch(setCurrentSuperCompanyInHeader({ ...headerCompany, contract: data }));
      }

      dispatch({
        type: types.COMPANIES_GET_CURRENT_CONTRACT_SUCCESS,
        contract: data
      });
      return data;
    });
  };
}

export function clearCompanyCurrentContract() {
  return { type: types.COMPANIES_CLEAR_CURRENT_CONTRACT };
}

export function requestSaveCompanyContract({ companyId, values, loader = true }) {
  return (dispatch, getState) => {
    dispatch({ type: types.COMPANIES_SAVE_CONTRACT_REQUEST, companyId, values });

    return dispatch(callApi('companies', 'saveContract', { companyId, values }, loader)).then(data => {
      const state = getState();
      const headerCompany = headerCompanyDetailsSelector(state);

      if (headerCompany.id === companyId) {
        dispatch(setCurrentSuperCompanyInHeader({ ...headerCompany, contract: data }));
      }

      dispatch({ type: types.CONTRACTS_SAVE_CONTRACT_SUCCESS });
      dispatch({ type: types.COMPANIES_GET_CURRENT_CONTRACT_SUCCESS, contract: data });

      return data;
    });
  };
}

export function createCompanyRequest() {
  return (dispatch, getState) => {
    const state = getState();
    const { [COMPANY_FORM]: companyForm } = state.form;

    const {
      name,
      address,
      postalCode,
      city,
      country,
      email,
      phoneNumber,
      termsOfUse,
      termsOfSubscription,
      privacyPolicy,
      durationAfterTripToAllowLockUnlock,
      secondaryEmail,
      secondaryPhoneNumber,
      employerCertificateUrl,
      configurationId,
      websiteConfirmSubscriptionUrl,
      websiteResetPasswordUrl,
      backofficeResetPasswordUrl,
      identityDocumentRequired,
      employerCertificateRequired,
      useExternalInvoiceSystem,
      startBookingDamageReportMandatory,
      endBookingDamageReportMandatory,
      damageReportPhotos,
      deleteMemberDocumentsOnApproval,
      expediteReview,
      rrsSafetyDepositAmount,
      billBeforeBooking,
      pricesIncludingTaxes,
      preBookingBillingOffset,
      secondPreBillingOffsetMinutes,
      sendDrivingAuthorisation,
      genericPdfDocumentUrl,
      logoUrl,
      signatureImageUrl,
      vehicleOwner,
      drivingAuthCompanyName,
      drivingAuthAddress,
      drivingAuthPhone,
      drivingAuth_postalCode,
      drivingAuth_city,
      sendEmailsToManager,
      vehicleReassignmentDelayInMin,
      appBrandId,
      externalId,
      drivingLicenceRequired,
      enableBookingDamageSafetyDeposit,
      enableBookingPriceSafetyDepositBuffer,
      bookingPriceSafetyDepositBuffer,
      bookingDamageSafetyDepositAmount,
      damageSafetyDepositReleaseDelayInDays,
      currency,
      companyLogo,
      enableDekraService,
      dekraCheckService,
      dekraExpertiseService,
      enabledReportAdditionalPhoto,
      nameForElectricVehicle,
      nameForHybridVehicle,
      nameForThermalVehicle,
      startBooking,
      endBooking,
      carWiseOrganizationId,
      inversFleetId,
      clientProfileType,
      displayAdditionalLegalDoc,
      additionalLegalDocTranslations,
      additionalLegalDocName,
      additionalLegalDocFileId,
      access,
      paymentProvider
    } = trimValues(companyForm);

    const { phonePrefix, phoneSuffix } = phoneNumber || {};
    const { phonePrefix: secondPhonePrefix, phoneSuffix: secondPhoneSuffix } = secondaryPhoneNumber || {};

    let params = {
      name,
      address,
      email,
      phoneNumber: {
        countryCode: phonePrefix,
        nationalNumber: phoneSuffix
      },
      durationAfterTripToAllowLockUnlock,
      employerCertificateUrl,
      templateGroup: TEMPLATE_EMAIL_DEFAULT,
      websiteConfirmSubscriptionUrl,
      websiteResetPasswordUrl,
      backofficeResetPasswordUrl,
      configurationId,
      secondaryEmail,
      identityDocumentRequired,
      expediteReview,
      employerCertificateRequired,
      useExternalInvoiceSystem,
      drivingLicenceRequired,
      sendEmailsToManager,
      vehicleReassignmentDelayInMin: vehicleReassignmentDelayInMin || 0,
      deleteMemberDocumentsOnApproval,
      damageSafetyDepositReleaseDelayInDays,
      currency,
      access,
      paymentProvider,
      pricesIncludingTaxes,
      startBookingVehicleInspectionReportMandatory: toBoolean(startBookingDamageReportMandatory),
      endBookingVehicleInspectionReportMandatory: toBoolean(endBookingDamageReportMandatory),
      vehicleInspectionReportPhotos: toBoolean(damageReportPhotos),
      billingSettings: {
        billBeforeBooking,
        preBookingBillingOffset,
        rrsSafetyDepositAmount,
        secondPreBillingOffsetMinutes,
        enableBookingDamageSafetyDeposit,
        enableBookingPriceSafetyDepositBuffer,
        bookingPriceSafetyDepositBuffer,
        bookingDamageSafetyDepositAmount
      },
      carWiseOrganizationId,
      inversFleetId
    };

    if (isValidId(companyLogo)) {
      params.logoFileId = companyLogo;
    }

    if (isValidId(termsOfUse)) {
      params.termsOfUseFileId = termsOfUse;
    }

    if (isValidId(termsOfSubscription)) {
      params.termsOfSubscriptionFileId = termsOfSubscription;
    }

    if (isValidId(privacyPolicy)) {
      params.privacyPolicyFileId = privacyPolicy;
    }

    fixTypesForSafetyDepositValues(params);
    adjustSafetyDepositValues(params);

    trySet(params, 'brandId', appBrandId);

    if (useExternalInvoiceSystem === EXTERNAL_INVOICE.DMS) {
      trySet(params, 'externalId', externalId);
    }

    if (useExternalInvoiceSystem === EXTERNAL_INVOICE.RRS) {
      trySet(params, 'rrsConfiguration', append({ clientProfileType }));
    }

    if (fieldEnabled(sendDrivingAuthorisation)) {
      const { phonePrefix: drivingPhonePrefix, phoneSuffix: drivingPhoneSuffix } = drivingAuthPhone || {};
      let phoneNumber;
      if (drivingPhoneSuffix && drivingAuthPhone) {
        phoneNumber = {
          countryCode: drivingPhonePrefix,
          nationalNumber: drivingPhoneSuffix
        };
      }

      trySet(
        params,
        'drivingAuthorisationSettings',
        append({
          genericPdfDocumentUrl,
          logoUrl,
          signatureImageUrl,
          vehicleOwner,
          companyName: drivingAuthCompanyName,
          address: drivingAuthAddress,
          phoneNumber
        })
      );

      if (drivingAuth_postalCode) {
        trySet(params, 'drivingAuthorisationSettings.address.postalCode', drivingAuth_postalCode);
      }

      if (drivingAuth_city) {
        trySet(params, 'drivingAuthorisationSettings.address.city', drivingAuth_city);
      }

      if (_isString(drivingAuthAddress)) {
        trySet(
          params,
          'drivingAuthorisationSettings.address',
          append({
            streetName: drivingAuthAddress,
            city: drivingAuth_city,
            postalCode: drivingAuth_postalCode
          })
        );
      }

      if (_isObject(drivingAuthAddress)) {
        trySet(params, 'drivingAuthorisationSettings.address', append(setFormattedAddress(drivingAuthAddress)));
      }
    }
    if (fieldEnabled(displayAdditionalLegalDoc)) {
      const translations = i18nAdditionnalDocTitleKeys(additionalLegalDocTranslations);
      trySet(
        params,
        'additionalLegalDocument',
        append({
          fileId: additionalLegalDocFileId,
          name: additionalLegalDocName,
          shouldDeleteFile: false,
          translations
        })
      );
    }

    params.sendDrivingAuthorisation = !!params.drivingAuthorisationSettings;

    if (secondPhonePrefix && secondPhoneSuffix) {
      params.secondaryPhoneNumber = {
        countryCode: secondPhonePrefix,
        nationalNumber: secondPhoneSuffix
      };
    }

    if (_isString(address)) {
      params.address = {
        streetName: address
      };
    }

    if (postalCode) {
      trySet(params.address, 'postalCode', postalCode);
    }

    if (city) {
      trySet(params.address, 'city', city);
    }

    if (country) {
      trySet(params.address, 'country', country);
    }

    trySet(params, 'address', append(setFormattedAddress(params.address)));

    if (fieldEnabled(enableDekraService)) {
      trySet(
        params,
        'dekraSettings',
        append({
          dekraCheckService,
          dekraExpertiseService
        })
      );
    }

    if (fieldEnabled(enabledReportAdditionalPhoto)) {
      trySet(
        params,
        'vehicleInspectionPhoto',
        append({
          nameForElectricVehicle,
          nameForHybridVehicle,
          nameForThermalVehicle,
          startBooking: toBoolean(startBooking),
          endBooking: toBoolean(endBooking)
        })
      );
    }

    dispatch({
      type: types.COMPANIES_CREATE_COMPANY_REQUEST,
      params
    });

    return dispatch(callApi('companies', 'createCompany', params));
  };
}

export function createCompanyModalSuccess(company) {
  return {
    type: types.COMPANIES_CREATE_COMPANY_MODAL_SUCCESS,
    company
  };
}

export function getCompanyDetail(id) {
  return dispatch => {
    dispatch({
      type: types.COMPANIES_GET_DETAIL_COMPANY_REQUEST
    });

    return dispatch(callApi('companies', 'getCompanyDetail', id)).then(function(data) {
      dispatch({
        type: types.COMPANIES_GET_DETAIL_COMPANY_SUCCESS,
        company: data
      });
      return data;
    });
  };
}

export function createConnectedAccount() {
  return (dispatch, getState) => {
    const state = getState();
    const detailCompany = state.companies.detailCompany;
    const companyGlideId = detailCompany.id;
    const { [COMPANY_STRIPE_SETTINGS_FORM]: stripeForm } = state.form;
    const formValues = trimValues(stripeForm);

    const params = {
      companyGlideId,
      countryCode: formValues.bankCountry,
      email: formValues.email,
      refreshUrl: window.location.href,
      returnUrl: window.location.href
    };

    dispatch({
      type: types.COMPANIES_SET_CONNECTED_ACCOUNT_REQUEST,
      params
    });

    return dispatch(callApi('bankouts', 'createStripeAccountLink', params, false))
      .then(data => {
        dispatch({
          type: types.COMPANIES_SET_CONNECTED_ACCOUNT_SUCCESS,
          data
        });

        return (window.location.href = data.stripeAccount.registrationLink);
      })
      .catch(error => {
        dispatch({
          type: types.COMPANIES_SET_CONNECTED_ACCOUNT_ERROR,
          error
        });
        dispatch(addErrorMessage({ error }));
      });
  };
}

export function stripeAccountLinkRefresh() {
  return (dispatch, getState) => {
    const state = getState();
    const detailCompany = state.companies.detailCompany;

    const params = {
      companyGlideId: detailCompany.id,
      refreshUrl: window.location.href,
      returnUrl: window.location.href,
      type: 'ACCOUNT_ONBOARDING'
    };

    return dispatch(callApi('bankouts', 'stripeAccountLinkRefresh', params, false))
      .then(data => {
        return (window.location.href = data.url);
      })
      .catch(err => {
        dispatch(addErrorMessage({ err }));
      });
  };
}

export function getConnectedAccount(companyGlideId) {
  return dispatch => {
    dispatch({
      type: types.COMPANIES_GET_CONNECTED_ACCOUNT_REQUEST
    });

    return dispatch(callApi('bankouts', 'getStripeAccount', companyGlideId, false))
      .then(data => {
        return dispatch({
          type: types.COMPANIES_GET_CONNECTED_ACCOUNT_SUCCESS,
          data
        });
      })
      .catch(error => {
        if (error.status !== 404) dispatch(addErrorMessage(error));
        return dispatch({
          type: types.COMPANIES_GET_CONNECTED_ACCOUNT_ERROR,
          error
        });
      });
  };
}

export function supressConnectedAccount(companyGlideId) {
  return dispatch => {
    dispatch({
      type: types.COMPANIES_REMOVE_CONNECTED_ACCOUNT_REQUEST
    });
    return dispatch(callApi('bankouts', 'supressAccount', companyGlideId, false))
      .then(data => {
        return dispatch({
          type: types.COMPANIES_REMOVE_CONNECTED_ACCOUNT_SUCCESS,
          data
        });
      })
      .catch(err => {
        return dispatch({
          type: types.COMPANIES_REMOVE_CONNECTED_ACCOUNT_FAILED,
          err
        });
      });
  };
}

export function onMigratePaymentProvider() {
  return dispatch =>
    dispatch({
      type: types.COMPANY_PAYMENT_PROVIDER_STRIPE_MIGRATION
    });
}

export function getInvoiceParams(companyId) {
  return dispatch => {
    dispatch({
      type: types.COMPANIES_GET_CURRENT_INVOICE_PARAMS_REQUEST
    });

    return dispatch(callApi('invoices', 'getParams', companyId))
      .then(function(invoiceParams) {
        dispatch({
          type: types.COMPANIES_GET_CURRENT_INVOICE_PARAMS_SUCCESS,
          invoiceParams
        });
        return invoiceParams;
      })
      .catch(error => {
        if (error.status === 404) return dispatch({ type: types.COMPANIES_GET_CURRENT_INVOICE_PARAMS_NO_CONTENT });
        return error;
      });
  };
}

export function getInvoiceVatRateParam(companyId) {
  return dispatch => {
    dispatch({
      type: types.COMPANIES_GET_INVOICE_VAT_RATE_PARAM_REQUEST
    });

    return dispatch(callApi('invoices', 'getVatRateParam', companyId))
      .then(invoiceVatRateParam => {
        dispatch({
          type: types.COMPANIES_GET_INVOICE_VAT_RATE_PARAM_SUCCESS,
          invoiceVatRateParam
        });
        return invoiceVatRateParam;
      })
      .catch(error => {
        if (error.status === 404) return dispatch({ type: types.COMPANIES_GET_INVOICE_VAT_RATE_PARAM_NO_CONTENT });
        return error;
      });
  };
}

export function setInvoiceParamsRequest(companyId) {
  return (dispatch, getState) => {
    const state = getState();
    const { companyInvoiceParamsForm } = state.form;
    const formValues = trimValues(companyInvoiceParamsForm);
    const template = formValues.templateModel;
    const { vatRate } = formValues;

    let params = {
      ...getInvoiceParamsBase(formValues),
      companyId
    };

    if (template === TEMPLATE_MODEL_ITALIAN) {
      let italianParams = getItalianInvoiceParams(formValues);
      renameKey(italianParams, 'numberPrefix', 'invoiceNumberPrefix');
      renameKey(italianParams, 'taxIdentificationCode', 'taxIdCode');
      renameKey(italianParams, 'numberCounterInit', 'firstInvoiceNumber');
      params.italianInvoicingParameters = italianParams;

      let italianCredentials = getItalianInvoiceCredentials(formValues);
      renameKey(italianCredentials, 'login', 'invoiceParamsLogin');
      renameKey(italianCredentials, 'password', 'invoiceParamsPassword');
      if (!!italianCredentials.login && !!italianCredentials.password) params.italianInvoicingParameters.credentials = italianCredentials;
    }

    if (_isString(params.invoicingAddress)) {
      params.invoicingAddress = {
        streetName: params.invoicingAddress
      };
    }
    trySet(params.invoicingAddress, 'city', formValues.city);
    trySet(params.invoicingAddress, 'postalCode', formValues.postalCode);
    trySet(params.invoicingAddress, 'country', formValues.country);
    trySet(params, 'invoicingAddress', setFormattedAddress(params.invoicingAddress));

    const isUpdate = state.companies.detailInvoiceParams.companyId === state.companies.detailCompany.id;
    const action = isUpdate ? 'update' : 'create';

    let param = { vatRate, companyId };
    const payloadParams = isUpdate ? { companyId, params } : { params };
    const payloadParam = isUpdate ? { companyId, param } : { param };

    dispatch({
      type: types.COMPANIES_SET_CURRENT_INVOICE_PARAMS_REQUEST
    });

    return Promise.all([
      dispatch(callApi('invoices', action + 'Params', payloadParams)).then(invoiceParams => {
        dispatch({
          type: types.COMPANIES_GET_CURRENT_INVOICE_PARAMS_SUCCESS,
          invoiceParams
        });
        return invoiceParams;
      }),
      dispatch(callApi('invoices', action + 'VatRateParam', payloadParam)).then(invoiceVatRateParam => {
        dispatch({
          type: types.COMPANIES_GET_INVOICE_VAT_RATE_PARAM_SUCCESS,
          invoiceVatRateParam
        });
        return invoiceVatRateParam;
      })
    ])
      .then(() =>
        dispatch(
          addFlashMessage({
            contentKey: 'COMPANIES_SET_CURRENT_INVOICE_PARAMS_SUCCESS',
            type: FLASH_MESSAGE_TYPE_SUCCESS
          })
        )
      )
      .catch(error => {
        const promise = validateInvoiceErrors(dispatch, error);
        if (promise) return promise;
        return dispatch(
          addFlashMessage({
            contentKey: 'COMPANIES_SET_CURRENT_INVOICE_PARAMS_FAILED',
            type: FLASH_MESSAGE_TYPE_ERROR
          })
        );
      });
  };
}

export function updateCompanyRequest(company) {
  return (dispatch, getState) => {
    const state = getState();
    const { [COMPANY_FORM]: companyForm } = state.form;

    const authorizedApplications = state.companies.detailCompany.authorizedApplications;
    const currency = state.companies.detailCompany.currency;
    const removeSubscriptionTermsOfUseFile = state.companies.deleteTermsOfSubscriptionFile;
    const removePrvacyPolicyFile = state.companies.deletePrivacyPolicyFile;
    const additionalLegalDoc1 = safe(() => state.companies.detailCompany.additionalLegalDocument);

    const {
      name,
      address,
      postalCode,
      city,
      country,
      email,
      phoneNumber,
      termsOfUse,
      termsOfSubscription,
      privacyPolicy,
      durationAfterTripToAllowLockUnlock,
      templateGroup,
      websiteConfirmSubscriptionUrl,
      websiteResetPasswordUrl,
      backofficeResetPasswordUrl,
      secondaryPhoneNumber,
      employerCertificateUrl,
      configurationId,
      secondaryEmail,
      identityDocumentRequired,
      employerCertificateRequired,
      useExternalInvoiceSystem,
      startBookingDamageReportMandatory,
      endBookingDamageReportMandatory,
      immediatePaymentForBookingExtension,
      damageReportPhotos,
      deleteMemberDocumentsOnApproval,
      preBookingBillingOffset,
      secondPreBillingOffsetMinutes,
      billBeforeBooking,
      pricesIncludingTaxes,
      drivingLicenceRequired,
      expediteReview,
      genericPdfDocumentUrl,
      logoUrl,
      signatureImageUrl,
      rrsSafetyDepositAmount,
      sendDrivingAuthorisation,
      vehicleOwner,
      drivingAuthCompanyName,
      drivingAuthPhone,
      drivingAuthAddress,
      drivingAuth_postalCode,
      drivingAuth_city,
      sendEmailsToManager,
      vehicleReassignmentDelayInMin,
      appBrandId,
      externalId,
      enableBookingDamageSafetyDeposit,
      enableDekraService,
      dekraCheckService,
      dekraExpertiseService,
      enableBookingPriceSafetyDepositBuffer,
      bookingPriceSafetyDepositBuffer,
      bookingDamageSafetyDepositAmount,
      damageSafetyDepositReleaseDelayInDays,
      memberExternalIdForImportCustomFieldId,
      ssoEnabled,
      fiscalCode,
      identityProvider,
      ssoDomains,
      allowRegistrationWithoutSso,
      emailAutomaticallyVerified,
      profileAutomaticallyApproved,
      companyLogo,
      enabledReportAdditionalPhoto,
      nameForElectricVehicle,
      nameForHybridVehicle,
      nameForThermalVehicle,
      startBooking,
      clientProfileType,
      taxiClientCostCustomFieldId,
      taxiClientIpnCustomFieldId,
      taxiMissionOrderCustomFieldId,
      endBooking,
      inversFleetId,
      carWiseOrganizationId,
      italianMemberProfileCustomFieldId,
      displayAdditionalLegalDoc,
      additionalLegalDocTranslations,
      additionalLegalDocName,
      additionalLegalDocFileId,
      access
    } = trimValues(companyForm);

    const { phonePrefix, phoneSuffix } = phoneNumber || {};
    const { phonePrefix: secondPhonePrefix, phoneSuffix: secondPhoneSuffix } = secondaryPhoneNumber || {};

    let params = {
      name,
      address,
      email,
      phoneNumber: {
        countryCode: phonePrefix,
        nationalNumber: phoneSuffix
      },
      durationAfterTripToAllowLockUnlock,
      templateGroup,
      websiteConfirmSubscriptionUrl,
      websiteResetPasswordUrl,
      backofficeResetPasswordUrl,
      employerCertificateUrl,
      configurationId,
      identityDocumentRequired,
      employerCertificateRequired,
      useExternalInvoiceSystem,
      startBookingVehicleInspectionReportMandatory: toBoolean(startBookingDamageReportMandatory),
      endBookingVehicleInspectionReportMandatory: toBoolean(endBookingDamageReportMandatory),
      vehicleInspectionReportPhotos: toBoolean(damageReportPhotos),
      immediatePaymentForBookingExtension: toBoolean(immediatePaymentForBookingExtension),
      deleteMemberDocumentsOnApproval,
      damageSafetyDepositReleaseDelayInDays,
      memberExternalIdForImportCustomFieldId,
      pricesIncludingTaxes,
      billingSettings: {
        billBeforeBooking,
        preBookingBillingOffset,
        rrsSafetyDepositAmount,
        secondPreBillingOffsetMinutes,
        enableBookingDamageSafetyDeposit,
        enableBookingPriceSafetyDepositBuffer,
        bookingPriceSafetyDepositBuffer,
        bookingDamageSafetyDepositAmount
      },
      currency,
      access,
      drivingLicenceRequired,
      expediteReview,
      sendEmailsToManager,
      inversFleetId,
      carWiseOrganizationId,
      vehicleReassignmentDelayInMin: vehicleReassignmentDelayInMin || 0
    };

    if (isValidId(companyLogo)) {
      params.logoFileId = companyLogo;
    }

    if (isValidId(termsOfUse)) {
      params.termsOfUseFileId = termsOfUse;
    }

    if (removePrvacyPolicyFile) {
      params.deletePrivacyPolicyFile = removePrvacyPolicyFile;
    }

    if (removeSubscriptionTermsOfUseFile) {
      params.deleteTermsOfSubscriptionFile = removeSubscriptionTermsOfUseFile;
    }

    if (isValidId(termsOfSubscription)) {
      params.termsOfSubscriptionFileId = termsOfSubscription;
    }

    if (isValidId(privacyPolicy)) {
      params.privacyPolicyFileId = privacyPolicy;
    }

    fixTypesForSafetyDepositValues(params);
    adjustSafetyDepositValues(params);

    trySet(params, 'brandId', appBrandId);

    if (useExternalInvoiceSystem === EXTERNAL_INVOICE.ITALIAN) {
      trySet(params, 'italianMemberProfileCustomFieldId', italianMemberProfileCustomFieldId);
      trySet(params, 'fiscalCode', fiscalCode);
    }

    if (useExternalInvoiceSystem === EXTERNAL_INVOICE.DMS) {
      trySet(params, 'externalId', externalId);
      trySet(params, 'fiscalCode', fiscalCode);
    }

    if (useExternalInvoiceSystem === EXTERNAL_INVOICE.RRS) {
      trySet(
        params,
        'rrsConfiguration',
        append({
          clientProfileType,
          taxiClientCostCustomFieldId,
          taxiClientIpnCustomFieldId,
          taxiMissionOrderCustomFieldId
        })
      );
    }

    if (fieldEnabled(enableDekraService)) {
      trySet(
        params,
        'dekraSettings',
        append({
          dekraCheckService,
          dekraExpertiseService
        })
      );
    }
    if (fieldEnabled(enabledReportAdditionalPhoto)) {
      trySet(
        params,
        'vehicleInspectionPhoto',
        append({
          nameForElectricVehicle,
          nameForHybridVehicle,
          nameForThermalVehicle,
          startBooking: toBoolean(startBooking),
          endBooking: toBoolean(endBooking)
        })
      );
    }

    setIfString(params.address, 'streetName', params.address);
    trySet(params.address, 'postalCode', postalCode);
    trySet(params.address, 'city', city);
    trySet(params.address, 'country', country);
    trySet(params, 'address', append(setFormattedAddress(params.address)));

    const translations = i18nAdditionnalDocTitleKeys(additionalLegalDocTranslations);
    const file = !isValidId(additionalLegalDocFileId) && additionalLegalDoc1 ? additionalLegalDoc1.fileId : additionalLegalDocFileId;
    if (fieldEnabled(displayAdditionalLegalDoc)) {
      trySet(
        params,
        'additionalLegalDocument',
        append({
          fileId: file,
          name: additionalLegalDocName,
          shouldDeleteFile: false,
          translations
        })
      );
    }
    if (!fieldEnabled(displayAdditionalLegalDoc) && additionalLegalDoc1) {
      trySet(
        params,
        'additionalLegalDocument',
        append({
          fileId: file,
          name: additionalLegalDocName,
          shouldDeleteFile: true,
          translations
        })
      );
    }

    if (fieldEnabled(sendDrivingAuthorisation)) {
      const { phonePrefix: drivingPhonePrefix, phoneSuffix: drivingPhoneSuffix } = drivingAuthPhone || {};
      let phoneNumber;
      if (drivingAuthPhone) {
        if (drivingPhoneSuffix && drivingAuthPhone) {
          phoneNumber = {
            countryCode: drivingPhonePrefix,
            nationalNumber: drivingPhoneSuffix
          };
        }
      }

      trySet(
        params,
        'drivingAuthorisationSettings',
        append({
          genericPdfDocumentUrl,
          logoUrl,
          signatureImageUrl,
          vehicleOwner,
          companyName: drivingAuthCompanyName,
          phoneNumber
        })
      );

      if (_isString(drivingAuthAddress)) {
        trySet(
          params,
          'drivingAuthorisationSettings.address',
          append({
            streetName: drivingAuthAddress,
            city: drivingAuth_city,
            postalCode: drivingAuth_postalCode
          })
        );
      }

      if (_isObject(drivingAuthAddress)) {
        trySet(drivingAuthAddress, 'postalCode', drivingAuth_postalCode);
        trySet(drivingAuthAddress, 'city', drivingAuth_city);
        trySet(params, 'drivingAuthorisationSettings.address', append(setFormattedAddress(drivingAuthAddress)));
      }
    }

    params.sendDrivingAuthorisation = !!params.drivingAuthorisationSettings;

    if (secondaryEmail) {
      params.secondaryEmail = secondaryEmail;
    }

    if (secondPhonePrefix && secondPhoneSuffix) {
      params.secondaryPhoneNumber = {
        countryCode: secondPhonePrefix,
        nationalNumber: secondPhoneSuffix
      };
    }

    if (authorizedApplications && authorizedApplications.length > 0) {
      params.authorizedApplications = authorizedApplications;
    }

    let payloadSSo;

    if (fieldEnabled(ssoEnabled)) {
      payloadSSo = {
        identityProvider,
        domains: ssoDomains.split(',').map(item => item.trim()),
        allowRegistrationWithoutSso: toBoolean(allowRegistrationWithoutSso),
        emailAutomaticallyVerified: toBoolean(emailAutomaticallyVerified),
        profileAutomaticallyApproved: toBoolean(profileAutomaticallyApproved),
        disabled: false
      };
    } else {
      payloadSSo = {
        disabled: true
      };
    }

    const updateCompany = () => {
      dispatch({ type: types.COMPANIES_UPDATE_COMPANY_REQUEST, params });
      return dispatch(callApi('companies', 'updateCompany', { companyId: company.id, params }));
    };

    return dispatch(callApi('companies', 'saveSsoConfiguration', { companyId: company.id, values: payloadSSo })).then(
      updateCompany,
      error => {
        dispatch(addFlashMessage({ contentKey: 'company_form_sso_config_update_error', type: FLASH_MESSAGE_TYPE_ERROR }));
        dispatch(addErrorMessage({ error }));
        return updateCompany();
      }
    );
  };
}

export function removeCompanyFile(field) {
  return {
    type: types.COMPANIES_FILE_IMPORTED_REMOVE,
    field
  };
}

export function updateCurrencyReq(company) {
  trySet(company, 'brandId', company.brand.id);
  delete company.brand;
  return dispatch => {
    dispatch({
      type: types.COMPANIES_UPDATE_COMPANY_REQUEST,
      company
    });
    return dispatch(callApi('companies', 'updateCompany', { companyId: company.id, params: company }));
  };
}

export function updateCompanySuccess(company) {
  return {
    type: types.COMPANIES_UPDATE_COMPANY_SUCCESS,
    company
  };
}

export function authorizeApplications(applications) {
  return (dispatch, getState) => {
    let state = getState();
    let { companyAuthorizedApplications: companyAuthorizedApplicationsInitial } = state.companies;

    companyAuthorizedApplicationsInitial = companyAuthorizedApplicationsInitial || [];
    companyAuthorizedApplicationsInitial = companyAuthorizedApplicationsInitial.sort();

    let concatApplications = companyAuthorizedApplicationsInitial.concat(applications);

    concatApplications = _union(concatApplications);
    concatApplications = concatApplications.sort();

    if (!_isEqual(companyAuthorizedApplicationsInitial, concatApplications)) {
      return dispatch({
        type: types.COMPANIES_AUTHORIZE_APPLICATION_LIST_SUCCESS,
        applications: concatApplications
      });
    }
  };
}

export function unauthorizeApplications(applications) {
  return (dispatch, getState) => {
    let state = getState();

    let companyAuthorizedApplicationsInitial = state.companies.companyAuthorizedApplications || [];
    companyAuthorizedApplicationsInitial = companyAuthorizedApplicationsInitial.sort();

    let concatApplications = companyAuthorizedApplicationsInitial.filter(item => !_includes(applications, item));
    concatApplications = concatApplications.sort();

    if (!_isEqual(companyAuthorizedApplicationsInitial, concatApplications)) {
      return dispatch({
        type: types.COMPANIES_UNAUTHORIZE_APPLICATION_LIST_SUCCESS,
        applications: concatApplications
      });
    }
  };
}

export function saveCompanyAuthorizedApplications(companyId) {
  return (dispatch, getState) => {
    const billingSettings = getState().companies.detailCompany.billingSettings;
    const drivingAuthorisationSettings = getState().companies.detailCompany.drivingAuthorisationSettings;
    const {
      companyAuthorizedApplications,
      detailCompany: {
        name,
        address,
        email,
        secondaryEmail,
        phoneNumber,
        secondaryPhoneNumber,
        durationAfterTripToAllowLockUnlock,
        templateGroup,
        websiteConfirmSubscriptionUrl,
        websiteResetPasswordUrl,
        backofficeResetPasswordUrl,
        employerCertificateUrl,
        configurationId,
        identityDocumentRequired,
        employerCertificateRequired,
        drivingLicenceRequired,
        expediteReview,
        currency,
        useExternalInvoiceSystem,
        startBookingDamageReportMandatory,
        damageSafetyDepositReleaseDelayInDays,
        sendEmailsToManager,
        brand,
        pricesIncludingTaxes,
        vehicleReassignmentDelayInMin,
        deleteMemberDocumentsOnApproval,
        endBookingDamageReportMandatory,
        damageReportPhotos,
        dekraSettings,
        vehicleInspectionPhoto,
        inversFleet
      }
    } = getState().companies;

    let params = {
      name,
      address,
      email,
      secondaryEmail,
      phoneNumber,
      secondaryPhoneNumber,
      durationAfterTripToAllowLockUnlock,
      templateGroup,
      employerCertificateUrl,
      websiteConfirmSubscriptionUrl,
      websiteResetPasswordUrl,
      backofficeResetPasswordUrl,
      configurationId,
      billingSettings,
      sendDrivingAuthorisation: !!drivingAuthorisationSettings,
      drivingAuthorisationSettings,
      expediteReview,
      currency,
      identityDocumentRequired,
      drivingLicenceRequired,
      employerCertificateRequired,
      useExternalInvoiceSystem,
      startBookingVehicleInspectionReportMandatory: toBoolean(startBookingDamageReportMandatory),
      endBookingVehicleInspectionReportMandatory: toBoolean(endBookingDamageReportMandatory),
      vehicleInspectionReportPhotos: toBoolean(damageReportPhotos),
      authorizedApplications: companyAuthorizedApplications,
      damageSafetyDepositReleaseDelayInDays,
      sendEmailsToManager,
      vehicleReassignmentDelayInMin,
      brandId: brand.id,
      pricesIncludingTaxes,
      deleteMemberDocumentsOnApproval,
      inversFleetId: inversFleet.id
    };

    if (!_isEmpty(dekraSettings)) {
      trySet(
        params,
        'dekraSettings',
        append({
          dekraCheckService: dekraSettings.dekraCheckService,
          dekraExpertiseService: dekraSettings.dekraExpertiseService
        })
      );
    }
    if (!_isEmpty(vehicleInspectionPhoto)) {
      trySet(
        params,
        'vehicleInspectionPhoto',
        append({
          nameForElectricVehicle: vehicleInspectionPhoto.nameForElectricVehicle,
          nameForHybridVehicle: vehicleInspectionPhoto.nameForHybridVehicle,
          nameForThermalVehicle: vehicleInspectionPhoto.nameForThermalVehicle,
          startBooking: toBoolean(vehicleInspectionPhoto.startBooking),
          endBooking: toBoolean(vehicleInspectionPhoto.endBooking)
        })
      );
    }

    dispatch({
      type: types.COMPANIES_UPDATE_COMPANY_REQUEST,
      params
    });

    return dispatch(callApi('companies', 'updateCompany', { companyId, params })).then(data => {
      dispatch(updateCompanySuccess(data));
    });
  };
}

export function toggleDrivingAuthorisationPdfPreview() {
  return (dispatch, getState) => {
    const state = getState();
    dispatch({
      type: types.COMPANIES_DRIVING_AUTHORISATION_PDF_PREVIEW_TOGGLE,
      pdfPreview: !state.companies.pdfPreview
    });
  };
}

export function getCompanyEmailSetup(companyId) {
  return dispatch => {
    const params = {
      companyId,
      defaultTemplate: true
    };

    dispatch({
      type: types.COMPANIES_GET_EMAIL_SETUP_REQUEST
    });

    if (!isValidId(companyId)) return Promise.resolve();

    return dispatch(callApi('customizations', 'getAssets', params)).then(
      data =>
        dispatch({
          type: types.COMPANIES_GET_EMAIL_SETUP_SUCCESS,
          data,
          companyId
        }),
      error =>
        dispatch({
          type: types.COMPANIES_GET_EMAIL_SETUP_ERROR,
          error
        })
    );
  };
}

export function pullCompanyEmailSetup(companyId) {
  return dispatch => {
    const params = {
      companyId,
      defaultTemplate: true
    };

    dispatch({
      type: types.COMPANIES_PULL_EMAIL_SETUP_REQUEST
    });

    if (!isValidId(companyId)) return Promise.reject('company ID is not valid');

    return dispatch(callApi('customizations', 'getAssets', params)).then(
      data =>
        dispatch({
          type: types.COMPANIES_GET_EMAIL_SETUP_SUCCESS,
          data,
          companyId
        }),
      error =>
        Promise.reject(
          dispatch({
            type: types.COMPANIES_PULL_EMAIL_SETUP_ERROR,
            error
          })
        )
    );
  };
}

export function setCompanyEmailSetup() {
  return (dispatch, getState) => {
    const state = getState();
    const detailCompanyId = _get(state.companies.detailCompany, 'id');
    const form = _get(state.form, COMPANY_EMAIL_SETUP_FORM);
    const {
      colorPrimary,
      colorSecondary,
      linkedinLink,
      twitterLink,
      emailLogo,
      productName,
      senderEmail,
      senderSms,
      homeDeeplink
    } = trimValues(form);

    const subParams = {};

    if (isValidId(emailLogo)) {
      subParams.logoFileId = emailLogo;
    }

    trySet(subParams, 'colour1', colorPrimary);
    trySet(subParams, 'colour2', colorSecondary);
    trySet(subParams, 'linkedinLink', linkedinLink);
    trySet(subParams, 'twitterLink', twitterLink);
    trySet(subParams, 'productName', productName);
    trySet(subParams, 'senderEmail', senderEmail);
    trySet(subParams, 'senderSms', senderSms);
    trySet(subParams, 'homeDeeplink', homeDeeplink);

    const params = {
      companyId: detailCompanyId,
      params: subParams
    };

    dispatch({
      type: types.COMPANIES_UPDATE_EMAIL_SETUP_REQUEST
    });

    function dispatchErrorMsg() {
      return dispatch(
        addFlashMessage({
          contentKey: 'company_email_setup_update_error',
          type: FLASH_MESSAGE_TYPE_ERROR
        })
      );
    }

    if (!isValidId(detailCompanyId)) {
      dispatchErrorMsg();
      return Promise.reject('company ID is not valid');
    }

    return dispatch(callApi('customizations', 'setAssets', params)).then(
      data => {
        dispatch({
          type: types.COMPANIES_UPDATE_EMAIL_SETUP_SUCCESS,
          data
        });

        dispatch(
          addFlashMessage({
            contentKey: 'company_email_setup_update_success',
            type: FLASH_MESSAGE_TYPE_SUCCESS
          })
        );

        return Promise.resolve();
      },
      error => {
        dispatch({
          type: types.COMPANIES_UPDATE_EMAIL_SETUP_ERROR,
          error
        });

        dispatchErrorMsg();
        return Promise.reject(error);
      }
    );
  };
}

export function deleteCompanyEmailSetup() {
  return (dispatch, getState) => {
    dispatch({
      type: types.COMPANIES_DELETE_EMAIL_SETUP_REQUEST
    });

    const state = getState();
    const detailCompanyId = _get(state.companies.detailCompany, 'id');

    if (!isValidId(detailCompanyId)) return Promise.reject('company ID is not valid');

    return dispatch(callApi('customizations', 'deleteAssets', detailCompanyId)).then(
      () => {
        dispatch({
          type: types.COMPANIES_DELETE_EMAIL_SETUP_SUCCESS
        });

        return Promise.resolve();
      },
      error => {
        dispatch({
          type: types.COMPANIES_DELETE_EMAIL_SETUP_ERROR
        });

        return Promise.reject(error);
      }
    );
  };
}

export function previewCompanyInvoice() {
  return (dispatch, getState) => {
    const state = getState();
    const companyDetail = state.companies.detailCompany;
    const companyId = _get(companyDetail, 'id');
    const language = state.i18n.locale;

    const params = {
      companyId,
      language
    };

    dispatch({
      type: types.COMPANIES_PREVIEW_INVOICE_REQUEST
    });

    return dispatch(callApi('invoices', 'preview', params)).then(
      data =>
        data.blob().then(
          blob =>
            new Promise(resolve => {
              if (isInternetExplorer11()) {
                dispatch({
                  type: types.COMPANIES_PREVIEW_INVOICE_SUCCESS,
                  ...invoicePreviewDefault
                });

                const bundle = state.i18n.bundle || {};
                const { company_invoice_preview, common_at } = bundle;
                const name = company_invoice_preview || 'Invoice_preview';
                const at = common_at || 'at';
                const now = moment();
                const date = now.format('DD-MM-YYYY');
                const time = now.format('HH.mm.ss');

                saveAs(blob, `${name}_${date}_${at}_${time}.pdf`);
                resolve();
              } else {
                const reader = new FileReader();
                reader.onloadend = () => {
                  dispatch({
                    type: types.COMPANIES_PREVIEW_INVOICE_SUCCESS,
                    blob,
                    base64: reader.result
                  });
                  resolve();
                };
                reader.readAsDataURL(blob);
              }
            })
        ),
      error =>
        dispatch({
          type: types.COMPANIES_PREVIEW_INVOICE_ERROR,
          error
        })
    );
  };
}

export function clearCompanyInvoice() {
  return {
    type: types.COMPANIES_PREVIEW_INVOICE_CLEAR
  };
}

export function getCurrencies() {
  return dispatch => {
    dispatch({
      type: types.COMPANIES_CURRENCIES_LIST_REQUEST
    });

    return dispatch(callApi('currencies', 'list'))
      .then(data => {
        dispatch({
          type: types.COMPANIES_CURRENCIES_LIST_SUCCESS,
          data
        });
      })
      .catch(() => {
        dispatch({
          type: types.COMPANIES_CURRENCIES_LIST_SUCCESS,
          data: BE_CURRENCIES_FALLBACK
        });
      });
  };
}

export function clearDetailCompany() {
  return {
    type: types.COMPANIES_GET_DETAIL_COMPANY_CLEAR
  };
}

const successMessage = addFlashMessage({
  contentKey: 'common_success',
  type: FLASH_MESSAGE_TYPE_SUCCESS
});

export function companyUpdateBankoutConfigAction() {
  return (dispatch, getState) => {
    const state = getState();
    const companyDetails = detailedCompanySelector(state);

    return dispatch(sharedCompanyUpdateBankoutConfigAction(companyDetails)).then(
      data => {
        data = getPaybackSettingsParse(data);
        dispatch(companyClearPaybackSettingsData());
        dispatch({ type: types.COMPANY_GET_PAYBACK_CONFIG_SUCCESS, data });
        dispatch(successMessage);
        return Promise.resolve(data);
      },
      error => {
        dispatch(addErrorMessage({ error }));
        return Promise.reject(error);
      }
    );
  };
}

export function companyUpdateBankDetails() {
  return (dispatch, getState) => {
    const state = getState();
    const companyState = state.companies;
    const companyDetails = detailedCompanySelector(state);
    const userRole = userRoleSelector(state);
    const canUpdate = checkRole(superCompanyPaybackBankDetailsEditRules, userRole);

    if (!canUpdate) return Promise.resolve();

    return dispatch(sharedCompanyUpdateBankDetailsAction(companyState, companyDetails)).then(
      data => Promise.resolve(data),
      error => {
        dispatch(addErrorMessage({ error }));
        return Promise.reject(error);
      }
    );
  };
}

export function companyCreateBankoutConfigAction() {
  return (dispatch, getState) => {
    const state = getState();
    const companyState = state.companies;
    const companyDetails = detailedCompanySelector(state);

    return dispatch(sharedCompanyCreateBankoutConfigAction(companyState, companyDetails, BILLING_ENTITY_TYPE_SUPER_COMPANY)).then(
      data => {
        dispatch(successMessage);
        return Promise.resolve(data);
      },
      error => {
        dispatch(addErrorMessage({ error }));
        return Promise.reject(error);
      }
    );
  };
}

export function companyGetPaybackSettingsAction(companyId, displayError = false) {
  return dispatch => {
    return dispatch(callApi('bankouts', 'getConfig', companyId)).then(
      data => {
        data = getPaybackSettingsParse(data);
        dispatch({ type: types.COMPANY_GET_PAYBACK_CONFIG_SUCCESS, data });
        return Promise.resolve(data);
      },
      error => {
        dispatch({ type: types.COMPANY_GET_PAYBACK_CONFIG_ERROR, error });
        if (displayError) dispatch(addErrorMessage({ error }));
        return Promise.reject(error);
      }
    );
  };
}

export function getInversFleets() {
  return dispatch => {
    return dispatch(callApi('invers', 'getFleets')).then(
      data => {
        dispatch({ type: types.COMPANY_GET_INVERS_FLEET_SUCCESS, data });
        return Promise.resolve(data);
      },
      error => {
        dispatch({ type: types.COMPANY_GET_INVERS_FLEET_ERROR, error });
        return Promise.reject(error);
      }
    );
  };
}

export function companyClearPaybackSettingsData() {
  return { type: types.COMPANY_CLEAR_PAYBACK_SETTINGS_DATA };
}

export function companyClearTemporaryBankDetails() {
  return { type: types.COMPANY_CLEAR_TEMPORARY_BANK_DETAILS };
}

export function companySavePaybackRedirectData(data) {
  return {
    type: types.COMPANY_SAVE_PAYBACK_REDIRECT_DATA,
    ...data
  };
}

export function setCurrentSuperCompanyInHeader(companyDetails) {
  return (dispatch, getState) => {
    const state = getState();

    const { id: newCompanyId, name: newCompanyName } = companyDetails || {};
    const { id: headerCompanyId } = headerCompanyDetailsSelector(state);

    if (newCompanyId !== headerCompanyId) {
      dispatch({
        type: types.COMPANIES_SET_CURRENT_COMPANY,
        company: {
          id: newCompanyId,
          name: newCompanyName
        }
      });
    }

    dispatch({
      type: types.COMPANIES_SET_CURRENT_COMPANY_DETAILS,
      companyDetails
    });

    return Promise.resolve();
  };
}

export function getBasicCompany(id) {
  return dispatch => {
    return dispatch(callApi('companies', 'getCompanyInfo', id)).then(
      data => Promise.resolve(data),
      error => Promise.resolve(error)
    );
  };
}

export function migratePaymentCompany(id) {
  const successMessage = addFlashMessage({
    contentKey: 'stripe_migration_success',
    type: FLASH_MESSAGE_TYPE_SUCCESS
  });
  const errorMessage = addFlashMessage({
    contentKey: 'stripe_migration_error',
    type: FLASH_MESSAGE_TYPE_ERROR
  });

  return dispatch => {
    return dispatch(callApi('companies', 'migrateToStripe', id, false)).then(
      data => {
        dispatch(successMessage);
        return Promise.resolve(data);
      },
      error => {
        dispatch(errorMessage);
        return Promise.resolve(error);
      }
    );
  };
}
