import React, { Component } from 'react';
import { showParentView } from '../../../../components/_v2/ColumnList/helpers';
import ListDetails from '../../../../components/_v2/ColumnList/Details';
import AddVoucherGroup from '../../../../containers/Voucher/AddVoucherGroup/AddVoucherGroup';
import { getMsg } from '../../../../utils/IntlGlobalProvider';

export class VouchersAddGroup extends Component {
  componentWillMount() {
    this.showParentView = showParentView.bind(this);
  }

  render() {
    return (
      <ListDetails title={getMsg('voucher_create_group')} onClose={this.showParentView}>
        <AddVoucherGroup isExternal onCancel={this.showParentView} onSubmit={this.showParentView} inputRef={this.props.inputRef} />
      </ListDetails>
    );
  }
}

export default VouchersAddGroup;
