import React, { PropTypes } from 'react';
import { FormattedMessage } from 'react-intl';
import _map from 'lodash/map';
import _partial from 'lodash/partial';

import BoxedInput from '../BoxedInput/BoxedInput';
import EkButton from '../EkButton/EkButton';
import FieldErrorMsg from '../FieldErrorMsg/FieldErrorMsg';
import TimeSlots from '../SlotsTypes/TimeSlots';
import { getAppDateFormat } from '../../utils/utils';

const OpeningHoursSlots = ({
  slotTitle,
  limitedAccess,
  slotData,
  onHandleAddSlot,
  onHandleRemoveSlot,
  onHandleAddSubSlot,
  onHandleRemoveSubSlot
}) => {
  return (
    <div className="parkingForm_container">
      <h4 className="parkingForm_title">
        <FormattedMessage id={slotTitle} />
      </h4>

      {!limitedAccess && (
        <EkButton
          id="add-special-open-dates"
          onAction={onHandleAddSlot}
          customClass="ekButton--add parkingForm_addTimeSlot_button"
          skinType="reverse"
        >
          <FormattedMessage id="parkingForm_addSpecialDates" />
        </EkButton>
      )}

      <ul className="parkingForm_timeSlots">
        {_map(slotData, (item, index) => {
          return (
            <li className="parkingForm_timeSlots_item" key={index}>
              <div className="parkingForm_timeSlots_item_content">
                <div className="parkingForm_timeSlots_group">
                  <BoxedInput
                    id={'specialClosingDates_dateApply-' + index}
                    type="date"
                    skinType="date"
                    blockCustomClass="timeSlot_boxedSelectBlock timeSlot_boxedSelectBlock--from"
                    customClass="parkingTimeSlot parkingTimeSlot_dateWrapper"
                    labelKey="timeSlot_apply_label"
                    customLabelClass="parkingTimeSlot parkingTimeSlot_from_label"
                    field={item.startDate}
                    dateFormat={'dddd ' + getAppDateFormat()}
                    readOnly={limitedAccess === 'true'}
                  >
                    <FieldErrorMsg field={item.startDate} customClass="fieldErrorMsg--parkingForm" />
                  </BoxedInput>

                  <BoxedInput
                    id={'specialClosingDates_dateTo' + index}
                    type="date"
                    skinType="date"
                    blockCustomClass="timeSlot_boxedSelectBlock timeSlot_boxedSelectBlock--from"
                    customClass="parkingTimeSlot parkingTimeSlot_dateWrapper"
                    labelKey="timeSlot_to_label"
                    customLabelClass="parkingTimeSlot parkingTimeSlot_from_label"
                    field={item.endDate}
                    dateFormat={'dddd ' + getAppDateFormat()}
                    readOnly={limitedAccess === 'true'}
                  >
                    <FieldErrorMsg field={item.endDate} customClass="fieldErrorMsg--parkingForm" />
                  </BoxedInput>

                  {!limitedAccess && (
                    <EkButton
                      id={'special-open-remove-time' + index}
                      onAction={_partial(onHandleRemoveSlot, index)}
                      customClass="ekButton--remove parkingForm_addTime_button"
                      skinType="reverse"
                    >
                      <FormattedMessage id="parkingForm_deleteSpecialDates" />
                    </EkButton>
                  )}

                  {!limitedAccess && (
                    <EkButton
                      id={'special-open-add-time' + index}
                      onAction={_partial(onHandleAddSubSlot, index)}
                      customClass="ekButton--add parkingForm_addTime_button"
                      skinType="reverse"
                    >
                      <FormattedMessage id="parkingForm_addTimeFrame" />
                    </EkButton>
                  )}
                </div>
              </div>

              <TimeSlots
                prefix="openHours"
                limitedAccess={limitedAccess}
                slotData={item}
                slotIndex={index}
                onHandleRemoveSlot={onHandleRemoveSubSlot}
              />
            </li>
          );
        })}
      </ul>
    </div>
  );
};

OpeningHoursSlots.displayName = 'OpeningHoursSlots';

OpeningHoursSlots.propTypes = {
  slotTitle: PropTypes.string.isRequired,
  limitedAccess: PropTypes.bool,
  slotData: PropTypes.array.isRequired,
  onHandleAddSlot: PropTypes.func,
  onHandleRemoveSlot: PropTypes.func,
  onHandleAddSubSlot: PropTypes.func,
  onHandleRemoveSubSlot: PropTypes.func
};

export default OpeningHoursSlots;
