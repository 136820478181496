import React, { Component, PropTypes } from 'react';
import { FormattedMessage } from 'react-intl';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';

import FormRow from '../FormRow/FormRow';
import BoxedSelect from '../BoxedSelect/BoxedSelect';
import EkButton from '../EkButton/EkButton';
import FieldErrorMsg from '../FieldErrorMsg/FieldErrorMsg';
import { createValidator, notEmpty } from '../../validation/all-validation';
import { allDamagesStatues } from '../../constants/options-constants';
import { scrollToFirstError } from '../../utils/utils';

class VehicleDamageEditStatusForm extends Component {
  render() {
    const {
      fields: { status },
      handleSubmit
    } = this.props;

    return (
      <section className="vehicleDamageEditStatusFormWrapper">
        <form action="#" className="vehicleDamageEditStatusForm" onSubmit={handleSubmit(this.props.onCallback)}>
          <div className="vehicleDamageEditStatusForm_container">
            <h4 className="vehicleDamageEditStatusForm_title">
              <FormattedMessage id="vehicleDamageEditStatusForm_title" />
            </h4>

            <FormRow customClass="vehicleDamageEditStatusForm_row">
              <BoxedSelect
                formRowItemKey="vehicleDamageEditStatusForm_status"
                id="status"
                customClass="editStatus_boxedSelectWrapper editStatus_boxedSelectWrapper--alt"
                labelKey="vehicleDamageEditStatusForm_status"
                options={allDamagesStatues}
                field={status}
                fullWidth
              >
                <FieldErrorMsg field={status} customClass="fieldErrorMsg--vehicleForm" />
              </BoxedSelect>
            </FormRow>

            <FormRow customClass="vehicleDamageEditStatusForm_row">
              <EkButton
                type="button"
                skinType="reverse"
                formRowItemKey="vehicleDamageEditStatusForm_abortBtn"
                customClass="vehicleDamageEditStatusForm_button"
                onAction={this.props.onAbort}
              >
                <FormattedMessage id="vehicleDamageEditStatusForm_abort_button" />
              </EkButton>

              <EkButton
                type="button"
                formRowItemKey="vehicleDamageEditStatusForm_submitBtn"
                customClass="vehicleDamageEditStatusForm_button"
                onAction={handleSubmit(this.props.onCallback)}
              >
                <FormattedMessage id="vehicleDamageEditStatusForm_save_button" />
              </EkButton>
            </FormRow>
          </div>
        </form>
      </section>
    );
  }
}

VehicleDamageEditStatusForm.displayName = 'VehicleDamageEditStatusForm';

VehicleDamageEditStatusForm.propTypes = {
  fields: PropTypes.object,
  handleSubmit: PropTypes.func,
  onCallback: PropTypes.func,
  onAbort: PropTypes.func
};

VehicleDamageEditStatusForm = reduxForm({
  onSubmitFail: scrollToFirstError,
  form: 'vehicleDamageEditStatusForm',
  fields: ['status'],
  validate: createValidator({
    status: [notEmpty()]
  })
})(VehicleDamageEditStatusForm);

export default connect(state => {
  const {} = state;
  return {};
})(VehicleDamageEditStatusForm);
