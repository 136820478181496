import * as types from '../../constants/actionTypes-constants';
import _get from 'lodash/get';
import { apiParams } from '../../constants/api-params-constants';
import _reduce from 'lodash/reduce';
import _map from 'lodash/map';
import moment from 'moment';

function computeOptions(data) {
  return _map(data, (entity, index) => {
    const label = _get(entity, 'name');
    return {
      value: index,
      label
    };
  });
}

function handleAddToList(state, srcObjName = {}, destObjName = {}, srcListName = [], destListName = [], ids = []) {
  const srcObj = _get(state, srcObjName, {});
  const destObj = _get(state, destObjName, {});

  const newSrc = { ...srcObj };
  const newDest = { ...destObj };

  ids.forEach(id => {
    const element = srcObj[id];

    if (element) {
      newDest[id] = element;
      delete newSrc[id];
    }
  });

  const srcList = computeOptions(newSrc);
  const destList = computeOptions(newDest);
  return {
    ...state,
    [srcObjName]: newSrc,
    [destObjName]: newDest,
    [srcListName]: srcList || [],
    [destListName]: destList || []
  };
}

function scopeAction(func) {
  return func();
}

const addParkingsDefault = {
  availableParkingsData: {},
  allowedParkingsData: {},
  availableParkings: [],
  allowedParkings: []
};

const addCompaniesDefault = {
  availableCompaniesData: {},
  allowedCompaniesData: {},
  availableCompanies: [],
  allowedCompanies: []
};

export default function header(
  state = {
    loadingList: true,
    createVoucherCodeModal: false,
    loadingDetail: true,
    groups: [],
    errorGroups: false,
    errorVouchers: false,
    paginatedResults: {},
    voucherResults: {},
    urlParams: apiParams.default,
    voucherDetailParams: {},
    exporting: false,
    filters: undefined,
    ...addParkingsDefault,
    ...addCompaniesDefault
  },
  action
) {
  switch (action.type) {
    case types.VOUCHER_GROUPS_CLEAR:
      return {
        ...state,
        paginatedResults: {}
      };

    case types.VOUCHER_CLEAR_DETAIL: {
      return {
        ...state,
        voucherResults: {}
      };
    }

    case types.VOUCHER_GROUP_CREATE_SUCCESS: {
      const { group } = action;
      const { paginatedResults } = state;
      const { results = [] } = paginatedResults || {};

      return {
        ...state,
        paginatedResults: {
          ...paginatedResults,
          results: [{ ...group, voucherGroupCreationDate: moment().format('YYYY-MM-DD') }, ...results]
        }
      };
    }

    case types.VOUCHER_GROUPS_UPDATE_FILTERS: {
      return { ...state, filters: action.filters };
    }

    case types.VOUCHER_GET_GROUPS_REQUEST:
      return {
        ...state,
        loadingList: true,
        errorGroups: false
      };

    case types.VOUCHER_GET_GROUPS_SUCCESS:
      return {
        ...state,
        paginatedResults: _get(action, 'paginatedObj'),
        urlParams: _get(action, 'urlParams'),
        loadingList: false
      };

    case types.VOUCHER_GET_GROUPS_ERROR:
      return {
        ...state,
        errorGroups: true
      };

    case types.VOUCHERS_GET_COMPANIES_REQUEST:
      return {
        ...state,
        ...addCompaniesDefault
      };
    case types.VOUCHERS_GET_PARKINGS_REQUEST:
      return {
        ...state,
        ...addParkingsDefault
      };
    case types.VOUCHERS_GET_PARKINGS_SUCCESS:
      const stateParkings = action.data;

      return {
        ...state,
        availableParkingsData: {
          ...state.availableParkingsData,
          ...stateParkings
        }
      };
    case types.SUB_COMPANIES_GET_LIST_SUCCESS: {
      const stateCompanies = action.list;
      return {
        ...state,
        availableCompaniesData: {
          ...state.availableCompaniesData,
          ...stateCompanies
        },
        availableCompanies: computeOptions(stateCompanies)
      };
    }

    case types.VOUCHERS_ADD_TO_ALLOWED_COMPANIES:
      return handleAddToList(state, 'availableCompaniesData', 'allowedCompaniesData', 'availableCompanies', 'allowedCompanies', action.ids);

    case types.VOUCHERS_ADD_TO_AVAILABLE_COMPANIES:
      return handleAddToList(state, 'allowedCompaniesData', 'availableCompaniesData', 'allowedCompanies', 'availableCompanies', action.ids);

    case types.VOUCHERS_ADD_TO_ALLOWED_PARKINGS:
      return handleAddToList(state, 'availableParkingsData', 'allowedParkingsData', 'availableParkings', 'allowedParkings', action.ids);

    case types.VOUCHERS_ADD_TO_AVAILABLE_PARKINGS:
      return handleAddToList(state, 'allowedParkingsData', 'availableParkingsData', 'allowedParkings', 'availableParkings', action.ids);

    case types.VOUCHERS_ADD_AVAILABLE_PARKINGS:
      return {
        ...state,
        availableParkings: computeOptions(state.availableParkingsData)
      };
    case types.VOUCHERS_ADD_AVAILABLE_COMPANIES:
      return {
        ...state,
        availableCompanies: computeOptions(state.availableCompaniesData)
      };

    case types.VOUCHERS_SAVE_PARKINGS:
      return scopeAction(() => {
        const { companyId, parkings } = action;
        const parkingsPredicate = (result, parking) => {
          const id = _get(parking, 'id');
          const name = _get(parking, 'name');

          if (id && name) {
            result[id] = {
              name,
              companyId
            };
          }
          return result;
        };
        if (companyId) {
          const stateParkings = _reduce(parkings, parkingsPredicate, {});
          return {
            ...state,
            availableParkingsData: {
              ...stateParkings
            }
          };
        }
        return state;
      });
    case types.VOUCHERS_SAVE_COMPANIES:
      return scopeAction(() => {
        const { companyId, companies } = action;
        const companiesPredicate = (result, company) => {
          const id = _get(company, 'id');
          const name = _get(company, 'name');

          if (id && name) {
            result[id] = {
              name,
              companyId
            };
          }
          return result;
        };
        if (companyId) {
          const stateCompanies = _reduce(companies, companiesPredicate, {});
          return {
            ...state,
            availableCompaniesData: {
              ...stateCompanies
            }
          };
        }
        return state;
      });

    case types.VOUCHER_GET_LIST_REQUEST:
      return {
        ...state,
        loadingList: true,
        errorVouchers: false
      };

    case types.VOUCHER_GET_LIST_SUCCESS:
      return {
        ...state,
        voucherResults: _get(action, 'paginatedObj', {}),
        voucherDetailParams: _get(action, 'urlParams', {}),
        loadingList: false
      };

    case types.VOUCHERS_EXPORT_REQUEST:
      return {
        ...state,
        exporting: true
      };

    case types.VOUCHERS_EXPORT_FAIL:
    case types.VOUCHERS_EXPORT_SUCCESS:
      return {
        ...state,
        exporting: false
      };

    case types.VOUCHER_REVOKE_REQUEST:
      return {
        ...state,
        loadingList: true
      };

    case types.VOUCHER_REVOKE_SUCCESS:
    case types.VOUCHER_REVOKE_FAIL:
      return {
        ...state,
        loadingList: false
      };

    case types.VOUCHER_GET_LIST_ERROR:
      return {
        ...state,
        errorVouchers: true
      };

    case types.VOUCHER_GET_GROUP_DETAIL_REQUEST:
      return {
        ...state,
        loadingDetail: true
      };

    case types.VOUCHER_GET_GROUP_DETAIL_SUCCESS:
      return {
        ...state,
        groupDetail: action.data,
        loadingDetail: false
      };

    case types.VOUCHER_GROUP_GET_COMPANY_SUCCESS:
      return {
        ...state,
        groupDetailCompany: action.data
      };

    case types.VOUCHERS_USED_IN_BOOKINGS_SUCCESS:
      return {
        ...state,
        groupUsedinBookings: action.data
      };

    case types.VOUCHER_TOGGLE_CREATE_CODE_MODAL:
      return {
        ...state,
        createVoucherCodeModal: !state.createVoucherCodeModal
      };

    default:
      return state;
  }
}
