import * as types from '../constants/actionTypes-constants';
import { safe } from '../utils/utils';

export default function versions(
  state = {
    list: [],
    latestCreatedVersion: null,
    currentVersion: {}
  },
  action
) {
  switch (action.type) {
    case types.VERSIONS_GET_LIST_SUCCESS:
      return Object.assign({}, state, {
        list: action.list
      });

    case types.VERSIONS_EMPTY_LIST:
      return {
        ...state,
        list: []
      };

    case types.VERSIONS_CREATE_VERSION_SUCCESS: {
      return {
        ...state,
        latestCreatedVersion: action.version,
        list: [{ ...action.version }, ...state.list]
      };
    }

    case types.VERSIONS_GET_SINGLE_VERSION_SUCCESS:
      return Object.assign({}, state, {
        currentVersion: action.version
      });

    case types.VERSIONS_UPDATE_VERSION_SUCCESS: {
      const { version } = action;
      const { list } = state;

      safe(() => (list.find(item => item.id === version.id).name = version.name));

      return {
        ...state,
        currentVersion: version,
        list: safe(() => [...list]) || []
      };
    }

    default:
      return state;
  }
}
