import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import _partial from 'lodash/partial';
import _get from 'lodash/get';
import { routeActions } from 'react-router-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import AdvancedList from '../../components/AdvancedList/AdvancedList';
import routes from '../../constants/routes-constants';

import {
  addOpenNewTabEvents,
  datesToString,
  getAppFormattedDateTime,
  getShortId,
  namedCompose,
  openUrlBackgroundTab
} from '../../utils/utils';
import autoBind from 'react-autobind';
import { apiParams } from '../../constants/api-params-constants';
import { clearPaybackResults, getPaybackList } from '../../actions/bankout-actions';

import {
  SORT_PROPERTY_PAYBACK_AMOUNT,
  SORT_PROPERTY_PAYBACK_CREATION_DATE,
  SORT_PROPERTY_PAYBACK_STATUS
} from '../../constants/backend-constants';
import FilterChips from '../FilterChips/FilterChips';
import Filters from '../AdvancedList/components/Filters';
import _omit from 'lodash/omit';
import { paybackListChips } from '../../constants/options-constants';
import { paybacksFilterTypes } from '../../constants/filterTypes-constants';
import classNames from 'classnames';

class PaybackList extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.setHeader();
  }

  setHeader() {
    this.colWidth = 2;

    this.paybackHeader = [
      {
        labelKey: 'common_creation_date',
        md: this.colWidth,
        xs: this.colWidth,
        sortId: SORT_PROPERTY_PAYBACK_CREATION_DATE,
        sortable: true
      },
      {
        labelKey: 'common_booking_id',
        md: this.colWidth,
        xs: this.colWidth,
        sortable: false
      },
      {
        labelKey: 'common_amount',
        md: this.colWidth,
        xs: this.colWidth,
        sortId: SORT_PROPERTY_PAYBACK_AMOUNT,
        sortable: true
      },
      {
        labelKey: 'payback_rate',
        md: this.colWidth,
        xs: this.colWidth,
        sortable: false
      },
      {
        labelKey: 'common_vat_rate',
        md: this.colWidth,
        xs: this.colWidth,
        sortable: false
      },
      {
        labelKey: 'common_status',
        md: this.colWidth,
        xs: this.colWidth,
        sortId: SORT_PROPERTY_PAYBACK_STATUS,
        sortable: true
      }
    ];
  }

  componentWillMount() {
    this.callApi(this.props.pageParams);
  }

  componentWillUnmount() {
    this.props.dispatch(clearPaybackResults());
  }

  callApi(params) {
    const { bankoutId } = this.props.params || {};
    const validDates = datesToString(params);

    this.props.dispatch(getPaybackList(bankoutId, validDates));
  }

  handleChangePage(value) {
    const { listMetadata, urlParams } = this.props;

    const params = {
      page: {
        number: value || apiParams.default.page.number,
        size: _get(listMetadata, 'paginationInfo.pageSize') || apiParams.default.page.size
      }
    };

    const newParams = { ...urlParams, ...params };

    this.callApi(newParams);
    this.updateUrlParams(newParams);
  }

  updateUrlParams(params) {
    const { bankoutId: id } = this.props.params || {};
    const { bankoutId, ...cleanParams } = params;

    if (id) {
      const validDates = datesToString(cleanParams);
      const encodedParams = encodeURIComponent(JSON.stringify(validDates));
      const searchRoute = routes.bankoutDetails.path.replace(':search', encodedParams);
      const finalRoute = searchRoute.replace(':bankoutId', id);

      this.props.dispatch(routeActions.push(finalRoute));
    }
  }

  handleRefreshList() {
    this.callApi(this.props.pageParams);
  }

  handleSort(sortPropertyName) {
    const { urlParams } = this.props;
    const newParams = {
      ...urlParams,
      sort: {
        isDescending: _get(urlParams, 'sort.property', false) === sortPropertyName ? !_get(urlParams, 'sort.isDescending', false) : false,
        property: sortPropertyName
      }
    };
    this.callApi(newParams);
    this.updateUrlParams(newParams);
  }

  handleNumberResultsSelect(value) {
    const { urlParams } = this.props;

    const params = {
      page: {
        number: 1,
        size: parseInt(value)
      }
    };

    const newParams = { ...urlParams, ...params };

    this.callApi(newParams);
    this.updateUrlParams(newParams);
  }

  goToDetail(bookingId, openInNewTab) {
    const route = routes.bookingDetail.path.replace(':bookingId', bookingId);

    if (openInNewTab) {
      return openUrlBackgroundTab('/#' + route);
    }
    this.props.dispatch(routeActions.push(route));
  }

  renderItem(item, id, reactKey) {
    const colProps = { xs: this.colWidth, md: this.colWidth };
    const { creationDate, bookingId, amount, rate, vatRate, status } = item || {};
    const callback = openInNewTab => this.goToDetail(bookingId, openInNewTab);

    return (
      <Row className="advancedList_row" {...addOpenNewTabEvents(callback)} id={id} key={reactKey}>
        <Col {...colProps}>{getAppFormattedDateTime(creationDate, { local: true })}</Col>
        <Col {...colProps}>{getShortId(bookingId)}</Col>
        <Col {...colProps}>{amount}</Col>
        <Col {...colProps}>{rate}</Col>
        <Col {...colProps}>{vatRate}</Col>
        <Col {...colProps}>
          <span className={classNames('bankout-status', 'bankout-status-' + status)}>
            <FormattedMessage id={status ? 'common_status_' + status : 'common_unknown'} />
          </span>
        </Col>
      </Row>
    );
  }

  // noinspection DuplicatedCode
  handleDeleteChip(item) {
    const { urlParams, listMetadata } = this.props;
    const { paginationInfo } = listMetadata || {};
    const { pageSize } = paginationInfo || {};
    const params = _omit(urlParams, item);
    const def = apiParams.default.page.size;

    const newParams = {
      ...params,
      page: {
        number: 1,
        size: pageSize || def
      }
    };

    this.callApi(newParams);
    this.updateUrlParams(newParams);
  }

  handleFilterSearch(newSelectedFilters) {
    const { listMetadata, urlParams } = this.props;

    const newParams = {
      ...urlParams,
      ...newSelectedFilters,
      page: {
        number: 1,
        size: listMetadata.paginationInfo.pageSize
      }
    };

    this.callApi(newParams);
    this.updateUrlParams(newParams);
  }

  render() {
    return (
      <div className="PaybackList">
        <FilterChips
          id="paybackListChips"
          urlParams={this.props.urlParams}
          onDeleteChip={this.handleDeleteChip}
          translations={paybackListChips}
          omit="bankoutId"
          filterTypes={paybacksFilterTypes}
        />
        <div className="payback-detail-actions">
          <Filters id="paybacksFilters" filterTypes={paybacksFilterTypes} onFilteredSearchForm={this.handleFilterSearch} />
        </div>
        <AdvancedList
          id="paybacks"
          data={this.props.list}
          listMetadata={this.props.listMetadata}
          loading={this.props.loadingList}
          error={this.props.loadingError}
          onRefreshList={this.handleRefreshList}
          onChangeRowsPerPage={this.handleNumberResultsSelect}
          onChangePage={this.handleChangePage}
          renderItem={this.renderItem}
          noResultsLabelKey="paybacks_no_results"
          header={this.paybackHeader}
          onSort={this.handleSort}
          urlParams={this.props.urlParams}
        />
      </div>
    );
  }
}

export default namedCompose(
  connect(state => {
    const {
      bankouts: { paybackResults, paybackParams, loadingList, loadingError }
    } = state;

    const listMetadata = _get(paybackResults, 'metadata');
    const page = _get(state, 'page');

    return {
      list: _get(paybackResults, 'results', []),
      pageParams: _get(page, 'params', apiParams.default),
      urlParams: paybackParams || apiParams.default,
      loadingList,
      loadingError,
      listMetadata
    };
  }),
  injectIntl
)(PaybackList);
