import React, { Component } from 'react';
import autoBind from 'react-autobind';
import pure from 'recompose/pure';
import { getAppFormattedDate, namedCompose, withCol } from '../../utils/utils';
import { FormattedMessage, injectIntl } from 'react-intl';

// noinspection ES6CheckImport
import PropTypes from 'prop-types';

import _get from 'lodash/get';
import { connect } from 'react-redux';
import { BOOKING_PAYMENT_STATUS_NA, BOOKING_USAGE_TYPE_PRIVATE } from '../../constants/backend-constants';
import { bookingPaymentStatus } from '../../constants/options-constants';
import { localeSelector } from '../../selectors/all-selectors';
import Tooltip from '../Tooltip/Tooltip';
import { isEmpty } from '../../utils/utils';
import _map from 'lodash/map';
import None from '../None';

class BookingBilling extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.derivedStateFromProps(props);
  }

  // eslint-disable-next-line no-unused-vars
  componentWillReceiveProps(nextProps, nextContext) {
    this.derivedStateFromProps(nextProps);
  }

  derivedStateFromProps(props) {
    if (!this.checkDisplayStatus(props)) return false;
    const { statusPayment } = props;
    this.setPriceWithVat(props);
    this.headlineStatusClass =
      'payment_status_' +
      String(statusPayment)
        .split(' ')[0]
        .toLowerCase();
    this.paymentStatusKey = bookingPaymentStatus[statusPayment] || 'common_unknown';
    this.setPaymentRetryStatusValue(props);
  }

  setPaymentRetryStatusValue(props) {
    const {
      automaticPaymentRetry,
      intl: { formatMessage }
    } = props;

    if (isEmpty(automaticPaymentRetry)) this.automaticPaymentRetry = '';
    else {
      this.automaticPaymentRetry = automaticPaymentRetry
        ? formatMessage({ id: 'common_enabled' })
        : formatMessage({ id: 'common_disabled' });
    }
  }

  setPriceWithVat(props) {
    const { freeOfCharges, totalPriceIncludingTaxes, estimatedPriceForDuration, currency, intl } = props;

    const currencySymbol = intl.formatMessage({ id: `unit_${currency}` });
    const priceWithVat = freeOfCharges ? 0 : totalPriceIncludingTaxes;
    this.estimatedPriceForDuration = isEmpty(estimatedPriceForDuration)
      ? ''
      : intl.formatMessage({ id: 'common_price_with_currency' }, { amount: String(estimatedPriceForDuration), currency: currencySymbol });
    this.priceWithCurrency = isEmpty(priceWithVat)
      ? ''
      : intl.formatMessage({ id: 'common_price_with_currency' }, { amount: String(priceWithVat), currency: currencySymbol });
  }

  checkDisplayStatus(nextProps) {
    const {
      statusPayment,
      freeOfCharges,
      bookingUsageType,
      invoiceNumber,
      estimatedPriceForDuration,
      totalPriceIncludingTaxes,
      freeKmPerDay,
      voucherDiscountAmount,
      bookingInvoices,
      automaticPaymentRetry
    } = nextProps;

    this.singleHeadline =
      !invoiceNumber &&
      !estimatedPriceForDuration &&
      !totalPriceIncludingTaxes &&
      !freeKmPerDay &&
      !voucherDiscountAmount &&
      isEmpty(automaticPaymentRetry)
        ? 'detailView_single_headline'
        : '';

    const invoicesPresent = bookingInvoices && bookingInvoices.length > 0;

    this.componentCanRender =
      bookingUsageType === BOOKING_USAGE_TYPE_PRIVATE && !(this.singleHeadline && !freeOfCharges && !statusPayment && !invoicesPresent);

    return this.componentCanRender;
  }

  headlineStatus() {
    const { statusPayment } = this.props;
    if (statusPayment && statusPayment !== BOOKING_PAYMENT_STATUS_NA)
      return (
        <span>
          <span className="detailView_dash">-</span>
          <span className="detailView_component_headline_status">
            <Tooltip content={<FormattedMessage id="booking_detail_payment_status" />} placement="bottom">
              <span className={this.headlineStatusClass}>
                <FormattedMessage id={this.paymentStatusKey} />
              </span>
            </Tooltip>
          </span>
        </span>
      );
  }

  displayRow(key, value) {
    if (key && !isEmpty(value))
      return (
        <tr className="detailView_tr">
          <td className="detailView_td_type_2">
            <span className="detailView_cell_type_1">
              <FormattedMessage id={key} />
            </span>
          </td>
          <td className="detailView_td_type_2">
            <span className="detailView_cell_type_1">
              <span>{value}</span>
            </span>
          </td>
        </tr>
      );
  }

  getInvoiceList() {
    const {
      bookingInvoices,
      intl: { formatMessage }
    } = this.props;

    return _map(bookingInvoices, (invoice, index) => {
      let price = _get(invoice, 'priceIncludingTaxes');
      price = isEmpty(price) ? '?' : String(price);

      return (
        <li key={invoice.id + '' + index}>
          <a href={invoice.downloadUrl} title="PDF" target="_blank">
            <FormattedMessage
              id="booking_detail_accounting_document_item"
              values={{
                type: withCol(`invoice.type.${invoice.type}`),
                reference: invoice.reference,
                price,
                currency: formatMessage({ id: `unit_${invoice.currency}` }),
                date: getAppFormattedDate(invoice.emissionDate)
              }}
            />
          </a>
        </li>
      );
    });
  }

  render() {
    const { invoiceNumber, freeKmPerDay, voucherDiscountAmount } = this.props;

    if (this.componentCanRender)
      return (
        <div className={`bookingBilling ${this.singleHeadline}`}>
          <h4 className="bookingBilling_headline">
            <FormattedMessage id="booking_detail_title_billing_information" />
            {this.headlineStatus()}
          </h4>
          <div className="bookingBilling_body">
            <table>
              <tbody>
                {this.displayRow('booking_detail_billing_identifier', invoiceNumber)}
                {this.displayRow('booking_estimated_price', this.estimatedPriceForDuration)}
                {this.displayRow('common_price_with_vat', this.priceWithCurrency)}
                {this.displayRow('common_free_km_per_day', freeKmPerDay)}
                {this.displayRow('booking_discount_amount', voucherDiscountAmount)}
                {this.displayRow('booking.automatic.payment.status.label', this.automaticPaymentRetry)}
              </tbody>
            </table>
            <ul className="bookingBilling_invoiceList">{this.getInvoiceList()}</ul>
          </div>
        </div>
      );
    else return <None />;
  }
}

BookingBilling.defaultProps = {
  freeOfCharges: false
};

// -----

BookingBilling.propTypes = {
  statusPayment: PropTypes.string,
  bookingUsageType: PropTypes.string,
  freeOfCharges: PropTypes.bool,
  invoiceNumber: PropTypes.string,
  currency: PropTypes.string,
  estimatedPriceForDuration: PropTypes.number,
  totalPriceIncludingTaxes: PropTypes.number,
  voucherDiscountAmount: PropTypes.number,
  bookingInvoices: PropTypes.array,
  automaticPaymentRetry: PropTypes.bool
};

// -----

export default namedCompose(
  connect(state => {
    return {
      locale: localeSelector(state)
    };
  }),
  injectIntl,
  pure
)(BookingBilling);
