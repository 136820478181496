import React, { Component, PropTypes } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { routeActions } from 'react-router-redux';
import Modal from 'react-modal';
import { reset } from 'redux-form';
import classNames from 'classnames';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _forEach from 'lodash/forEach';
import _cloneDeep from 'lodash/cloneDeep';
import autoBind from 'react-autobind';
import _isFunction from 'lodash/isFunction';

import TableView from '../../components/TableView/TableView';
import EkButton from '../../components/EkButton/EkButton';
import EditStatusForm from '../../components/EditStatusForm/EditStatusForm';
import LeaseContractForm from '../../components/LeaseContractForm/LeaseContractForm';
import InsuranceContractForm from '../../components/InsuranceContractForm/InsuranceContractForm';
import FuelCardForm from '../../components/FuelCardForm/FuelCardForm';
import AutolibCardForm from '../../components/AutolibCardForm/AutolibCardForm';
import BackLink from '../../components/BackLink/BackLink';
import routes, { fleetEditRules, siteRules } from '../../constants/routes-constants';
import { FLASH_MESSAGE_TYPE_ERROR, FLASH_MESSAGE_TYPE_SUCCESS } from '../../constants/generic-constants';
import {
  openChangeStatusModal,
  closeChangeStatusModal,
  setVehicleStatus,
  openConfirmStatusModal,
  closeConfirmStatusModal,
  deleteStatus,
  updateCleanlinessStatus,
  closeDeleteStatusModal,
  addFlashMessage,
  unlockVehicle,
  lockVehicle,
  getDeviceStatus,
  setCurrentDetailVehicleTabIndex,
  requestLeaseContractCreate,
  callCreateLeaseContractSuccess,
  requestInsuranceContractCreate,
  callCreateInsuranceContractSuccess,
  requestFuelCardCreate,
  callCreateFuelCardSuccess,
  requestAutolibCardCreate,
  callCreateAutolibCardSuccess,
  getLeaseContractsList,
  getInsuranceContractsList,
  getFuelCardsList,
  getAutolibCardsList,
  copyVehicle,
  getVehicleDeviceID
} from '../../actions/all-actions';
import {
  addErrorMessage,
  getAppFormattedDate,
  getAppFormattedDateTime,
  namedCompose,
  newProps,
  hideVehiclePositionForCustomCompanyId,
  safe
} from '../../utils/utils';
import {
  BACK_USER,
  BACKUSER_ROLE_SERVICE_ADVISOR,
  VEHICLE_FUEL_TYPE_ELECTRIC,
  VEHICLE_STATUSES,
  VEHICLE_SYSTEM_MOVINBLUE
} from '../../constants/backend-constants';
import { VehicleDetailsModalStyles } from '../../constants/style-constants';
import { vehicleDetailTableViewParams } from '../../constants/table-constants';
import { getStatuses } from '../../actions/vehicles-actions';
import { apiParams } from '../../constants/api-params-constants';
import VehicleReports from './components/VehicleReports';
import VehicleTasks from './components/VehicleTasks';
import { bundleSelector, currentContractVehicleValuesSelector, userRoleSelector } from '../../selectors/all-selectors';
import { partialErrorCodes, vehicleTypes, allSystemTypes } from '../../constants/options-constants';
import { checkRole } from '../../constants/backuser-role-rules';
import _find from 'lodash/find';
import { Dialog } from 'material-ui';
import memoizeOne from 'memoize-one';
const googleMapsUrl = 'http://maps.google.com/maps?q=loc';

const findSubCompany = memoizeOne((subList, subCompanyId) => {
  if (subList && subCompanyId) {
    return subList.filter(subCompany => {
      return subCompany.id === subCompanyId;
    });
  }
  return [];
});

class VehicleDetail extends Component {
  constructor(props) {
    super(props);

    autoBind(this);
    this.setTabsData();
    this.setReadOnly(props);
    this.derivedStateFromProps(props, true);
  }

  componentWillMount() {
    this.props.dispatch(setCurrentDetailVehicleTabIndex(0));
  }

  componentDidMount() {
    this.openDeviceRemovalReminder();
  }

  // eslint-disable-next-line no-unused-vars
  componentWillReceiveProps(props, nextContext) {
    this.derivedStateFromProps(props);
  }

  derivedStateFromProps(props, init) {
    if (newProps(this.props, props, init, 'statuses')) {
      this.statuses = _cloneDeep(props.statuses);
      if (!_isEmpty(props.statuses)) {
        _forEach(props.statuses, (item, key) => {
          if (_get(item, 'date')) this.statuses[key].date = getAppFormattedDateTime(item.date, { local: true });
        });
      }
    }
  }

  openDeviceRemovalReminder() {
    const { dispatch, deviceRemovalReminder } = this.props;

    if (deviceRemovalReminder) {
      dispatch(openChangeStatusModal());
    }
  }

  setReadOnly(props) {
    const { role } = props;
    this.readOnly = !checkRole(fleetEditRules, role);
    this.canSeeSites = checkRole(siteRules, role);
    this.canLockUnlock = role === BACKUSER_ROLE_SERVICE_ADVISOR || !this.readOnly;
  }

  setTabsData() {
    this.tabsData = [];

    this.addTab({
      titleKey: 'vehicle_tab_vehicle',
      displayTab: this.getVehicleTab
    });
    this.addTab({
      titleKey: 'vehicle_tab_contract_lease',
      displayTab: this.getLeaseContractTab
    });
    this.addTab({
      titleKey: 'vehicle_tab_contract_insurance',
      displayTab: this.getInsuranceTab
    });
    this.addTab({
      titleKey: 'vehicle_tab_card_fuel',
      displayTab: this.getFuelCardTab
    });
    this.addTab({
      titleKey: 'vehicle_tab_card_autolib',
      displayTab: this.getAutoLibTab
    });
    this.addTab({
      titleKey: 'vehicle_tab_vehicleDamages',
      displayTab: this.getDamageReports
    });
    this.addTab({
      titleKey: 'vehicle_tab_vehicleTasks',
      displayTab: this.getVehicleTasks
    });
  }

  addTab(params) {
    this.tabsData[this.tabsData.length] = {
      ...params,
      handleClick: this.handleChangeTabIndex.bind(this, this.tabsData.length)
    };
  }

  displayTabs() {
    const { currentDetailTabIndex } = this.props;

    return this.tabsData.map((data, index) => (
      <div
        className={classNames({
          vehicleDetailPage_tabItem: true,
          __is_selected: currentDetailTabIndex === index
        })}
        key={'tab-' + index}
        onClick={data.handleClick}
      >
        <FormattedMessage id={data.titleKey} />
      </div>
    ));
  }

  selectTab() {
    const { currentDetailTabIndex } = this.props;
    const dataFunction = _get(this.tabsData[currentDetailTabIndex], 'displayTab');
    const key = 'companyTab_' + currentDetailTabIndex;
    if (_isFunction(dataFunction)) {
      return (
        <div className="company-tab" key={key}>
          {dataFunction()}
        </div>
      );
    }
  }

  getVehicleTabActions() {
    const { detailVehicle } = this.props;

    return (
      <div className="vehicleDetailPage_actions">
        {!this.readOnly && (
          <EkButton
            href={`/#${routes.editVehicle.path.replace(':vehicleId', detailVehicle.id)}`}
            customClass="vehicleDetailPage_actionButton vehicleDetailPage_actionButton--header"
            skinType="variant3"
          >
            <FormattedMessage id="companies_detail_edit_button" />
          </EkButton>
        )}
        {!this.readOnly && (
          <EkButton
            onAction={this.handleCopyVehicle}
            customClass="vehicleDetailPage_actionButton vehicleDetailPage_actionButton--header"
            skinType="variant3"
          >
            <FormattedMessage id="companies_detail_duplicate_button" />
          </EkButton>
        )}
        {!this.readOnly && (
          <EkButton
            customClass="vehicleDetailPage_actionButton vehicleDetailPage_actionButton--header"
            skinType="reverse"
            onAction={this.handleOpenModalStatus}
          >
            <FormattedMessage id="vehicle_detail_change_status" />
          </EkButton>
        )}
      </div>
    );
  }

  handleCopyVehicle() {
    const { dispatch } = this.props;
    dispatch(copyVehicle());
    dispatch(routeActions.push(routes.addVehicle.path));
  }

  getFuelAutonomyRow() {
    const {
      detailVehicle: { fuelType },
      deviceStatus: { electricVehicleState }
    } = this.props;

    const cruisingRange = _get(electricVehicleState, 'cruisingRange');
    const charge = _get(electricVehicleState, 'charge');
    const chargeAdapter = _get(electricVehicleState, 'chargeAdapter');
    const quickCharge = _get(electricVehicleState, 'quickCharge');

    if (fuelType === VEHICLE_FUEL_TYPE_ELECTRIC) {
      return (
        <div className="vehicleDetail_line">
          {cruisingRange && (
            <div className="vehicleDetail_item">
              <span className="vehicleDetail_item_label">
                <FormattedMessage id="vehicle_detail_device_status_electric_cruising_range" />
              </span>
              <span className="vehicleDetail_item_result">{cruisingRange} km</span>
            </div>
          )}

          {charge && (
            <div className="vehicleDetail_item">
              <span className="vehicleDetail_item_label">
                <FormattedMessage id="vehicle_detail_device_status_electric_charge" />
              </span>
              <span className="vehicleDetail_item_result">{charge}</span>
            </div>
          )}

          {chargeAdapter && (
            <div className="vehicleDetail_item">
              <span className="vehicleDetail_item_label">
                <FormattedMessage id="vehicle_detail_device_status_electric_chargeAdapter" />
              </span>
              <span className="vehicleDetail_item_result">{chargeAdapter}</span>
            </div>
          )}

          {quickCharge && (
            <div className="vehicleDetail_item">
              <span className="vehicleDetail_item_label">
                <FormattedMessage id="vehicle_detail_device_status_electric_quickCharge" />
              </span>
              <span className="vehicleDetail_item_result">{quickCharge}</span>
            </div>
          )}
        </div>
      );
    }
  }

  getCompanyUrl() {
    const { detailVehicle: { company } = {} } = this.props;

    if (company) {
      return (
        <span className="vehicleDetail_item_result">
          <a className="vehicleDetail_item_link" href={`#${routes.companyDetail.path.replace(':companyId', company.id)}`}>
            <span>{company.name}</span>
          </a>
        </span>
      );
    }
  }

  getSubCompanyUrl() {
    const { detailVehicle, subList } = this.props;

    const subCompany = findSubCompany(
      subList,
      safe(() => detailVehicle.lastPosition.parking.site.subCompanyId)
    );

    if (subCompany[0]) {
      return (
        <span className="vehicleDetail_item_result">
          <a className="vehicleDetail_item_link" href={`#${routes.subCompanyDetails.path.replace(':subCompanyId', subCompany[0].id)}`}>
            <span>{subCompany[0].name}</span>
          </a>
        </span>
      );
    }
  }

  getVehicleTab() {
    const { detailVehicle, deviceStatus, vehicleRules, userInfo } = this.props;

    const {
      detailVehicle: { company }
    } = this.props;

    const { id: companyId } = company || {};

    const accessories = detailVehicle.accessories.map((item, index) => {
      return (
        <span key={item}>
          <FormattedMessage id={`vehicle_detail_accessories_${item}`} />
          {index + 1 < detailVehicle.accessories.length && ', '}
        </span>
      );
    });

    const mileage = _get(deviceStatus, 'mileage');

    const devicesStatusMileage =
      typeof mileage === 'number' ? mileage.toFixed(1) : <FormattedMessage id="vehicle_detail_device_status_unknown" />;

    let cards;

    if (deviceStatus) {
      cards = Object.keys(deviceStatus.rfidTagStates).map((item, index) => {
        return (
          <li key={item}>
            <FormattedMessage id={`vehicle_detail_device_status_${deviceStatus.rfidTagStates[index + 1]}`} />
          </li>
        );
      });
    }

    let errors;

    if (deviceStatus) {
      errors = Object.keys(deviceStatus.errors).map((item, index) => {
        return (
          <li key={item}>
            <FormattedMessage id={`vehicle_detail_device_status_${deviceStatus.errors[index]}`} />
          </li>
        );
      });
    }

    const vehicleType = _find(vehicleTypes, (type = {}) => type.value === detailVehicle.type) || {};
    const vehicleTypeLabelKey = vehicleType.labelKey;
    const usageKey = 'vehicle_usage_type_' + detailVehicle.usage.toLowerCase();

    return (
      <div className="vehicleDetail_container">
        {this.getVehicleTabActions()}
        {this.confirmDialogAddStatusReady()}

        <section className="vehicleDetail">
          <h4 className="vehicleDetail_title">
            <FormattedMessage id="vehicle_detail_title_description" />
          </h4>

          <div className="vehicleDetail_bloc">
            <div className="vehicleDetail_line">
              <div className="vehicleDetail_item">
                <span className="vehicleDetail_item_label">
                  <FormattedMessage id="vehicle_detail_car_brand" />
                </span>
                <span className="vehicleDetail_item_result">{detailVehicle.version.model.brand.name}</span>
              </div>
              <div className="vehicleDetail_item">
                <span className="vehicleDetail_item_label">
                  <FormattedMessage id="vehicle_detail_car_model" />
                </span>
                <span className="vehicleDetail_item_result">{detailVehicle.version.model.name}</span>
              </div>
              <div className="vehicleDetail_item">
                <span className="vehicleDetail_item_label">
                  <FormattedMessage id="vehicle_detail_car_version" />
                </span>
                <span className="vehicleDetail_item_result">{detailVehicle.version.name}</span>
              </div>
              {detailVehicle.colorCode && (
                <div className="vehicleDetail_item">
                  <span className="vehicleDetail_item_label">
                    <FormattedMessage id="vehicle_detail_color" />
                  </span>
                  <span className="vehicleDetail_item_result">{detailVehicle.colorCode}</span>
                </div>
              )}
              <div className="vehicleDetail_item">
                <span className="vehicleDetail_item_label">
                  <FormattedMessage id="vehicle_detail_status" />
                </span>
                <span className="vehicleDetail_item_result">
                  <span className={`vehicleDetail_statusTypeCell vehicleDetail_statusTypeCell--${detailVehicle.statusType}`}>
                    <FormattedMessage id={`vehicle_statusType_${detailVehicle.statusType}`} />
                  </span>
                </span>
              </div>
            </div>

            <div className="vehicleDetail_line">
              {vehicleRules.vehicleCategory && (
                <div className="vehicleDetail_item">
                  <span className="vehicleDetail_item_label">
                    <FormattedMessage id="vehicle_detail_vehicle_class" />
                  </span>
                  <span className="vehicleDetail_item_result">{detailVehicle.category.type}</span>
                </div>
              )}
              <div className="vehicleDetail_item">
                <span className="vehicleDetail_item_label">
                  <FormattedMessage id="vehicle_detail_gearbox" />
                </span>
                <span className="vehicleDetail_item_result">
                  <FormattedMessage id={'vehicle_transmissionType_' + detailVehicle.transmissionType} />
                </span>
              </div>
              <div className="vehicleDetail_item">
                <span className="vehicleDetail_item_label">
                  <FormattedMessage id="vehicle_detail_fuel_type" />
                </span>
                <span className="vehicleDetail_item_result">
                  <FormattedMessage id={'vehicle_fuelType_' + detailVehicle.fuelType} />
                </span>
              </div>
              {detailVehicle.type && (
                <div className="vehicleDetail_item">
                  <span className="vehicleDetail_item_label">
                    <FormattedMessage id="vehicle_detail_type" />
                  </span>
                  <span className="vehicleDetail_item_result">{vehicleTypeLabelKey && <FormattedMessage id={vehicleTypeLabelKey} />}</span>
                </div>
              )}
              {detailVehicle.doorsNumber && (
                <div className="vehicleDetail_item">
                  <span className="vehicleDetail_item_label">
                    <FormattedMessage id="vehicle_detail_doorsNumber" />
                  </span>
                  <span className="vehicleDetail_item_result">{detailVehicle.doorsNumber}</span>
                </div>
              )}
            </div>

            <div className="vehicleDetail_line">
              <div className="vehicleDetail_item">
                <span className="vehicleDetail_item_label">
                  <FormattedMessage id="vehicle_detail_number_seats" />
                </span>
                <span className="vehicleDetail_item_result">{detailVehicle.seats}</span>
              </div>
              {accessories && accessories.length > 0 && (
                <div className="vehicleDetail_item">
                  <span className="vehicleDetail_item_label">
                    <FormattedMessage id="vehicle_detail_accessories" />
                  </span>
                  <span className="vehicleDetail_item_result">{accessories}</span>
                </div>
              )}
              {detailVehicle.description && (
                <div className="vehicleDetail_item">
                  <span className="vehicleDetail_item_label">
                    <FormattedMessage id="vehicleForm_vehicleDesc_label" />
                  </span>
                  <span className="vehicleDetail_item_result">{detailVehicle.description}</span>
                </div>
              )}
            </div>
          </div>

          <figure className="vehicleDetail_picture">
            <img alt="" src={detailVehicle.pictureUrl} />
          </figure>
        </section>

        <section className="vehicleDetail">
          <h4 className="vehicleDetail_title">
            <FormattedMessage id="vehicle_detail_title_identification" />
          </h4>

          <div className="vehicleDetail_line vehicleDetail_line--triple">
            <div className="vehicleDetail_item">
              <span className="vehicleDetail_item_label">
                <FormattedMessage id="vehicle_detail_vin_number" />
              </span>
              <span className="vehicleDetail_item_result">{detailVehicle.vin}</span>
            </div>
            <div className="vehicleDetail_item">
              <span className="vehicleDetail_item_label">
                <FormattedMessage id="vehicle_detail_licence_plate" />
              </span>
              <span className="vehicleDetail_item_result">{detailVehicle.registrationNumber}</span>
            </div>
            {detailVehicle.registrationDate && (
              <div className="vehicleDetail_item">
                <span className="vehicleDetail_item_label">
                  <FormattedMessage id="vehicle_detail_first_date_registration" />
                </span>
                <span className="vehicleDetail_item_result">{detailVehicle.registrationDate}</span>
              </div>
            )}
          </div>
        </section>

        <section className="vehicleDetail">
          {!this.readOnly && (
            <EkButton
              customClass="vehicleDetailPage_actionButton vehicleDetailPage_actionButton--header"
              skinType="variant3"
              onAction={this.handleUpdateCleanlinessStatus}
            >
              <FormattedMessage
                id={`vehicle_detail_tag_${detailVehicle.cleanliness ? detailVehicle.cleanliness.status.toLowerCase() : 'dirty'}`}
              />
            </EkButton>
          )}

          <h4 className="vehicleDetail_title">
            <FormattedMessage id="vehicle_detail_title_cleanlinessStatus" />
          </h4>

          <div className="vehicleDetail_line vehicleDetail_line--triple">
            <div className="vehicleDetail_item">
              <span className="vehicleDetail_item_label">
                <FormattedMessage id="vehicle_detail_cleanlinessStatus" />
              </span>
              <span className="vehicleDetail_item_result">
                {detailVehicle.cleanliness && (
                  <FormattedMessage id={`vehicleForm_cleanlinessStatus_${detailVehicle.cleanliness.status.toLowerCase()}`} />
                )}
              </span>
            </div>
            <div className="vehicleDetail_item">
              <span className="vehicleDetail_item_label">
                <FormattedMessage id="vehicle_detail_reporter" />
              </span>
              <span className="vehicleDetail_item_result">
                {detailVehicle.cleanliness ? (
                  _get(detailVehicle, 'cleanliness.userFirstname') || _get(detailVehicle, 'cleanliness.userLastname') ? (
                    detailVehicle.cleanliness.userFirstname + ' ' + detailVehicle.cleanliness.userLastname
                  ) : detailVehicle.cleanliness.userType === BACK_USER ? (
                    <FormattedMessage id="vehicle_update_cleanliness_type_back_user" />
                  ) : (
                    <FormattedMessage id="vehicle_update_cleanliness_type_member" />
                  )
                ) : null}
              </span>
            </div>
            {
              <div className="vehicleDetail_item">
                <span className="vehicleDetail_item_label">
                  <FormattedMessage id="feedbacks_detail_submissionDate" />
                </span>
                <span className="vehicleDetail_item_result">
                  {getAppFormattedDateTime(_get(detailVehicle, 'cleanliness.createdDate'), { local: true })}
                </span>
              </div>
            }
          </div>
        </section>

        <section className="vehicleDetail">
          <h4 className="vehicleDetail_title">
            <FormattedMessage id="vehicle_detail_title_company_account" />
          </h4>
          <div className="vehicleDetail_line vehicleDetail_line--triple">
            <div className="vehicleDetail_item">
              <span className="vehicleDetail_item_label">
                <FormattedMessage id="vehicle_detail_owner_vehicle" />
                {': '}
                <FormattedMessage tagName="i" id={'vehicle_detail_ownedByRci_' + detailVehicle.ownedByRci} />
              </span>
              {this.getCompanyUrl()}
              {this.getSubCompanyUrl()}
            </div>
            {detailVehicle.lastPosition && detailVehicle.lastPosition.parking && (
              <div className="vehicleDetail_item">
                <span className="vehicleDetail_item_label">
                  <FormattedMessage id="vehicle_detail_site_name" />
                </span>
                {this.canSeeSites ? (
                  <a
                    className="vehicleDetail_item_link"
                    href={`#${routes.siteDetails.path.replace(':siteId', detailVehicle.lastPosition.parking.site.id)}`}
                  >
                    <span className="vehicleDetail_item_result">{detailVehicle.lastPosition.parking.site.name}</span>
                  </a>
                ) : (
                  <span className="vehicleDetail_item_result">{detailVehicle.lastPosition.parking.site.name}</span>
                )}
              </div>
            )}
            {detailVehicle.lastPosition && detailVehicle.lastPosition.parking && (
              <div className="vehicleDetail_item">
                <span className="vehicleDetail_item_label">
                  <FormattedMessage id="vehicle_detail_parking_name" />
                </span>
                {this.canSeeSites ? (
                  <a
                    className="vehicleDetail_item_link"
                    href={`#${routes.parkingDetails.path.replace(':parkingId', detailVehicle.lastPosition.parking.id)}`}
                  >
                    <span className="vehicleDetail_item_result">{detailVehicle.lastPosition.parking.name}</span>
                  </a>
                ) : (
                  <span className="vehicleDetail_item_result">{detailVehicle.lastPosition.parking.name}</span>
                )}
              </div>
            )}
          </div>
        </section>

        <section className="vehicleDetail">
          <h4 className="vehicleDetail_title">
            <FormattedMessage id="vehicle_detail_title_usage" />
          </h4>
          <div className="vehicleDetail_line">
            <div className="vehicleDetail_item">
              <FormattedMessage id={usageKey} />
            </div>
          </div>
        </section>

        <section className="vehicleDetail">
          <h4 className="vehicleDetail_title">
            <FormattedMessage id="vehicle_detail_title_box_configuration" />
          </h4>

          <div className="vehicleDetail_line vehicleDetail_line--triple">
            <div className="vehicleDetail_item">
              <span className="vehicleDetail_item_label">
                <FormattedMessage id="vehicle_detail_system_use" />
              </span>
              <span className="vehicleDetail_item_result">
                {detailVehicle.systemType && allSystemTypes.filter(op => op.value === detailVehicle.systemType)[0].label}
              </span>
            </div>
            {detailVehicle.device && (
              <div className="vehicleDetail_item">
                <span className="vehicleDetail_item_label">
                  <FormattedMessage id="device-key-specs" />
                </span>
                <span className="vehicleDetail_item_result">
                  {detailVehicle.device.withKey ? <FormattedMessage id="device-with-keys" /> : <FormattedMessage id="device-keyless" />}
                </span>
              </div>
            )}
            {detailVehicle.device && (
              <div className="vehicleDetail_item">
                <span className="vehicleDetail_item_label">
                  <FormattedMessage id="vehicle_detail_device_serial_number" />
                </span>
                <span className="vehicleDetail_item_result">{detailVehicle.device.serialNumber}</span>
              </div>
            )}
          </div>

          {detailVehicle.device && (
            <div className="vehicleDetail_line vehicleDetail_line--triple">
              <div className="vehicleDetail_item">
                <span className="vehicleDetail_item_label">
                  <FormattedMessage id="vehicle_detail_device_installation" />
                </span>
                <span className="vehicleDetail_item_result">{getAppFormattedDate(detailVehicle.device.installationDate)}</span>
              </div>
              {detailVehicle.device.removalDate && (
                <div className="vehicleDetail_item">
                  <span className="vehicleDetail_item_label">
                    <FormattedMessage id="vehicle_detail_device_removal" />
                  </span>
                  <span className="vehicleDetail_item_result">{getAppFormattedDate(detailVehicle.device.removalDate)}</span>
                </div>
              )}
            </div>
          )}
          {deviceStatus && (
            <div className="vehicleDetail_line">
              <div className="vehicleDetail_item">
                <span className="vehicleDetail_item_label">
                  <FormattedMessage id="vehicle_detail_device_status_mileage" />
                </span>
                <span className="vehicleDetail_item_result">{devicesStatusMileage}</span>
              </div>
              {!hideVehiclePositionForCustomCompanyId(companyId, userInfo.role) && vehicleRules.geolocalisation && (
                <div className="vehicleDetail_item">
                  <span className="vehicleDetail_item_label">
                    <FormattedMessage id="vehicle_detail_device_status_position" />
                  </span>
                  {deviceStatus.position ? (
                    <span className="vehicleDetail_item_result">
                      {getAppFormattedDateTime(_get(deviceStatus, 'position.timestamp'), { local: true })}
                      <br />
                      <a href={`${googleMapsUrl}:${deviceStatus.position.lat},${deviceStatus.position.lon}`} target="_blank">
                        <span>
                          <FormattedMessage id="vehicle_detail_device_status_position_lat" />
                          {`: ${deviceStatus.position.lat} `}
                          <FormattedMessage id="vehicle_detail_device_status_position_lon" />
                          {`: ${deviceStatus.position.lon}`}
                        </span>
                      </a>
                    </span>
                  ) : (
                    <span className="vehicleDetail_item_result">
                      <FormattedMessage id="vehicle_detail_device_status_unknown" />
                    </span>
                  )}
                </div>
              )}
              <div className="vehicleDetail_item">
                <span className="vehicleDetail_item_label">
                  <FormattedMessage
                    id={
                      detailVehicle.fuelType === VEHICLE_FUEL_TYPE_ELECTRIC
                        ? 'vehicle_detail_device_status_electric_charge_level'
                        : 'vehicle_detail_device_status_fuelLevel'
                    }
                  />
                </span>
                <span className="vehicleDetail_item_result">{deviceStatus.fuelLevel}%</span>
              </div>
              <div className="vehicleDetail_item">
                <span className="vehicleDetail_item_label">
                  <FormattedMessage id="vehicle_detail_device_status_centralLock" />
                </span>
                <span className="vehicleDetail_item_result">
                  <FormattedMessage id={`vehicle_detail_doors_status_${_get(deviceStatus, 'centralLock', 'unknown')}`} />
                </span>
              </div>
              <div className="vehicleDetail_item">
                <span className="vehicleDetail_item_label">
                  <FormattedMessage id="vehicle_detail_device_status_immobilizer" />
                </span>
                <span className="vehicleDetail_item_result">
                  <FormattedMessage id={`vehicle_detail_engine_status_${_get(deviceStatus, 'immobilizer', 'unknown')}`} />
                </span>
              </div>
            </div>
          )}

          {deviceStatus && this.getFuelAutonomyRow()}
          {deviceStatus && (
            <div className="vehicleDetail_line">
              <div className="vehicleDetail_item">
                <span className="vehicleDetail_item_label">
                  <FormattedMessage id="vehicle_detail_device_status_key" />
                </span>
                <span className="vehicleDetail_item_result">
                  <FormattedMessage id={`vehicle_detail_device_status_${deviceStatus.keyfob}`} />
                </span>
              </div>
              <div className="vehicleDetail_item">
                <span className="vehicleDetail_item_label">
                  <FormattedMessage id="vehicle_detail_device_status_cards" />
                </span>
                <span className="vehicleDetail_item_result">
                  <ul>{cards}</ul>
                </span>
              </div>
              <div className="vehicleDetail_item">
                <span className="vehicleDetail_item_label">
                  <FormattedMessage id="vehicle_detail_device_board_voltage" />
                </span>
                <span className="vehicleDetail_item_result">{deviceStatus.boardVoltage} V</span>
              </div>
              <div className="vehicleDetail_item">
                <span className="vehicleDetail_item_label">
                  <FormattedMessage id="vehicle_detail_device_status_errors" />
                </span>
                <span className="vehicleDetail_item_result">{_isEmpty(errors) ? <span>-</span> : <ul>{errors}</ul>}</span>
              </div>
            </div>
          )}

          <div className="vehicleDetailPage_buttonWrap">
            {detailVehicle.device && (
              <div>
                {detailVehicle.systemType === VEHICLE_SYSTEM_MOVINBLUE && <EkButton onAction={this.handleGetDeviceId}>get device</EkButton>}
                {this.canLockUnlock && (
                  <EkButton customClass="vehicleDetailPage_actionButton" skinType="variant3" onAction={this.handleLockVehicle}>
                    <FormattedMessage id="vehicle_detail_lock" />
                  </EkButton>
                )}

                {this.canLockUnlock && (
                  <EkButton customClass="vehicleDetailPage_actionButton" skinType="variant3" onAction={this.handleUnlockVehicle}>
                    <FormattedMessage id="vehicle_detail_unlock" />
                  </EkButton>
                )}

                <EkButton customClass="vehicleDetailPage_actionButton" skinType="variant3" onAction={this.handleGetDeviceStatus}>
                  <FormattedMessage id="vehicle_detail_device_status_update" />
                </EkButton>
              </div>
            )}
          </div>
        </section>

        {!_isEmpty(this.statuses) && (
          <section className="vehicleDetail">
            <h4 className="vehicleDetail_title">
              <FormattedMessage id="vehicle_detail_title_status" />
            </h4>
            <TableView customClass="vehicleDetail_statuses" params={vehicleDetailTableViewParams} content={this.statuses} />
          </section>
        )}
      </div>
    );
  }

  getLeaseContractTab() {
    return (
      <div className="vehicleDetail_container">
        <LeaseContractForm limitedAccess={this.readOnly} onCallback={this.handleAddLeaseContract} />
      </div>
    );
  }

  getInsuranceTab() {
    const { insuranceContractCurrentImage } = this.props;

    return (
      <div className="vehicleDetail_container">
        <InsuranceContractForm
          limitedAccess={this.readOnly}
          onCallback={this.handleAddInsuranceContract}
          currentImage={insuranceContractCurrentImage}
        />
      </div>
    );
  }

  getFuelCardTab() {
    return (
      <div className="vehicleDetail_container">
        <FuelCardForm limitedAccess={this.readOnly} onCallback={this.handleAddFuelCard} />
      </div>
    );
  }

  getAutoLibTab() {
    return (
      <div className="vehicleDetail_container">
        <AutolibCardForm limitedAccess={this.readOnly} onCallback={this.handleAddAutolibCard} />
      </div>
    );
  }

  handleOpenModalStatus() {
    const { dispatch } = this.props;
    dispatch(openChangeStatusModal());
  }

  confirmDialogAddStatusReady() {
    const { dispatch, detailVehicle, statusForm } = this.props;
    return (
      <Dialog
        bodyStyle={{ overflow: 'inherit' }}
        contentStyle={{ width: '400px' }}
        onRequestClose={this.handleClose}
        open={!!this.props.confirmAddStatusModal}
      >
        <div style={{ marginBottom: '10px' }}>
          <FormattedMessage id="add-status-without-device-serial-number" />
        </div>
        <div style={{ textAlign: 'right' }}>
          <EkButton type="button" skinType="reverse" onAction={() => dispatch(closeConfirmStatusModal())}>
            <FormattedMessage id="common_no" />
          </EkButton>
          <span style={{ marginLeft: '10px' }}>
            <EkButton
              type="button"
              onAction={() =>
                dispatch(setVehicleStatus(statusForm, detailVehicle.id)).then(
                  () => {
                    dispatch(getStatuses(detailVehicle.id));
                    dispatch(closeConfirmStatusModal());
                  },
                  () => true
                )
              }
            >
              <FormattedMessage id="common_yes" />
            </EkButton>
          </span>
        </div>
      </Dialog>
    );
  }

  handleCloseModalStatus() {
    const { dispatch } = this.props;
    dispatch(closeChangeStatusModal());
  }

  handleAddStatus() {
    const { dispatch, detailVehicle, statusForm } = this.props;
    const hasDeviceNumber = !!_get(detailVehicle, 'device.serialNumber');

    if (!hasDeviceNumber && statusForm.status.value === VEHICLE_STATUSES.READY) {
      dispatch(closeChangeStatusModal());
      dispatch(openConfirmStatusModal());
    } else {
      dispatch(setVehicleStatus(statusForm, detailVehicle.id)).then(
        () => {
          dispatch(getStatuses(detailVehicle.id));
          dispatch(closeChangeStatusModal());
        },
        () => true
      );
    }
  }

  handleAbortDeleteStatus() {
    const { dispatch } = this.props;
    dispatch(closeDeleteStatusModal());
  }

  handleConfirmDeleteStatus() {
    const { dispatch, statusToDelete, detailVehicle } = this.props;
    dispatch(deleteStatus(statusToDelete))
      .then(() => {
        dispatch(getStatuses(detailVehicle.id));
        dispatch(closeDeleteStatusModal());
        dispatch(
          addFlashMessage({
            contentKey: 'vehicle_remove_status_success',
            type: FLASH_MESSAGE_TYPE_SUCCESS
          })
        );
      })
      .catch(error => {
        if (!error.type) {
          // make sure native errors not swallowed
          console.error(error.stack); // eslint-disable-line
        }
        dispatch(
          addFlashMessage({
            contentKey: 'vehicle_remove_status_failed',
            type: FLASH_MESSAGE_TYPE_ERROR
          })
        );
      });
  }

  handleUpdateCleanlinessStatus() {
    const { dispatch, detailVehicle } = this.props;

    dispatch(updateCleanlinessStatus(detailVehicle));
  }

  handleLockVehicle() {
    const { dispatch, detailVehicle } = this.props;
    dispatch(lockVehicle(detailVehicle.id)).catch(error => {
      if (!error.type) {
        // make sure native errors not swallowed
        console.error(error.stack); // eslint-disable-line
      }
      dispatch(
        addFlashMessage({
          contentKey: 'vehicle_lock_failed',
          type: FLASH_MESSAGE_TYPE_ERROR
        })
      );
    });
  }

  handleUnlockVehicle() {
    const { dispatch, detailVehicle } = this.props;
    dispatch(unlockVehicle(detailVehicle.id)).catch(error => {
      if (!error.type) {
        // make sure native errors not swallowed
        console.error(error.stack); // eslint-disable-line
      }
      dispatch(
        addFlashMessage({
          contentKey: 'vehicle_unlock_failed',
          type: FLASH_MESSAGE_TYPE_ERROR
        })
      );
    });
  }

  handleGetDeviceStatus() {
    const { dispatch, detailVehicle } = this.props;
    dispatch(getDeviceStatus(detailVehicle.id)).catch(error => {
      if (!error.type) {
        // make sure native errors not swallowed
        console.error(error.stack); // eslint-disable-line
      }
      dispatch(
        addFlashMessage({
          contentKey: 'vehicle_get_device_status_failed',
          type: FLASH_MESSAGE_TYPE_ERROR
        })
      );
    });
  }

  handleGetDeviceId() {
    const { dispatch, detailVehicle } = this.props;
    dispatch(getVehicleDeviceID(detailVehicle.vin));
  }

  handleAddLeaseContract() {
    const { dispatch, detailVehicle, bundle } = this.props;

    dispatch(requestLeaseContractCreate()).then(
      () => {
        dispatch(callCreateLeaseContractSuccess());
        dispatch(getLeaseContractsList(detailVehicle.id));
        dispatch(
          addFlashMessage({
            contentKey: 'vehicle_add_contract_success',
            type: FLASH_MESSAGE_TYPE_SUCCESS
          })
        );
        dispatch(reset('leaseContractForm'));
      },
      error => {
        const msgParams = { bundle, error, def: 'vehicle_add_status_failed' };
        dispatch(addErrorMessage(msgParams));
      }
    );
  }

  handleAddInsuranceContract() {
    const { dispatch, detailVehicle, bundle } = this.props;

    dispatch(requestInsuranceContractCreate()).then(
      () => {
        dispatch(callCreateInsuranceContractSuccess());
        dispatch(getInsuranceContractsList(detailVehicle.id));
        dispatch(
          addFlashMessage({
            contentKey: 'vehicle_add_contract_success',
            type: FLASH_MESSAGE_TYPE_SUCCESS
          })
        );
        dispatch(reset('insuranceContractForm'));
      },
      error => {
        const partialError = partialErrorCodes.vehicleInsurance.duplicateContractNumber;
        const msgParams = { bundle, error, partialErrors: [partialError], def: 'vehicle_add_contract_failed' };
        dispatch(addErrorMessage(msgParams));
      }
    );
  }

  handleAddFuelCard() {
    const { dispatch, detailVehicle, bundle } = this.props;

    dispatch(requestFuelCardCreate()).then(
      () => {
        dispatch(callCreateFuelCardSuccess());
        dispatch(getFuelCardsList(detailVehicle.id));
        dispatch(
          addFlashMessage({
            contentKey: 'vehicle_add_card_success',
            type: FLASH_MESSAGE_TYPE_SUCCESS
          })
        );
        dispatch(reset('fuelCardForm'));
      },
      error => {
        const msgParams = { bundle, error, def: 'vehicle_add_status_failed' };
        dispatch(addErrorMessage(msgParams));
      }
    );
  }

  handleAddAutolibCard() {
    const { dispatch, detailVehicle } = this.props;

    dispatch(requestAutolibCardCreate())
      .then(() => {
        dispatch(callCreateAutolibCardSuccess());
        dispatch(getAutolibCardsList(detailVehicle.id));
        dispatch(
          addFlashMessage({
            contentKey: 'vehicle_add_status_success',
            type: FLASH_MESSAGE_TYPE_SUCCESS
          })
        );
        dispatch(reset('autolibCardForm'));
      })
      .catch(error => {
        if (!error.type) {
          // make sure native errors not swallowed
          console.error(error.stack); // eslint-disable-line
        }
        dispatch(
          addFlashMessage({
            contentKey: 'vehicle_add_status_failed',
            type: FLASH_MESSAGE_TYPE_ERROR
          })
        );
      });
  }

  handleChangeTabIndex(index, e) {
    e.preventDefault();
    this.props.dispatch(setCurrentDetailVehicleTabIndex(index));
  }

  getEditStatusFormModal() {
    const { openedChangeStatusModal } = this.props;

    return (
      <Dialog
        bodyStyle={{ overflow: 'inherit' }}
        contentStyle={{ width: '300px' }}
        onRequestClose={this.handleClose}
        open={!!openedChangeStatusModal}
      >
        <EditStatusForm onCallback={this.handleAddStatus} onAbort={this.handleCloseModalStatus} />
      </Dialog>
    );
  }

  getDeleteStatusModal() {
    const { openedDeleteStatusModal } = this.props;

    return (
      <Modal isOpen={!!openedDeleteStatusModal} style={VehicleDetailsModalStyles}>
        <p>
          <FormattedMessage id="vehicle_confirm_delete_text" />
        </p>
        <div className="vehicleDetail_status_remove_buttonsWrapper">
          <EkButton
            type="button"
            skinType="reverse"
            customClass="bookingsPage_cancelBooking_abort_button"
            onAction={this.handleAbortDeleteStatus}
          >
            <FormattedMessage id="Vehicle_deleteStatus_abort_button" />
          </EkButton>
          <EkButton type="button" customClass="bookingsPage_cancelBooking_confirm_button" onAction={this.handleConfirmDeleteStatus}>
            <FormattedMessage id="Vehicle_deleteStatus_confirm_button" />
          </EkButton>
        </div>
      </Modal>
    );
  }

  getDamageReports() {
    return <VehicleReports />;
  }

  getVehicleTasks() {
    return <VehicleTasks />;
  }

  render() {
    const { urlParams } = this.props;

    const params = urlParams || apiParams.default;
    const backToListURL = '/#' + routes.fleet.path.replace(':search', encodeURIComponent(JSON.stringify(params)));

    return (
      <div className="vehicleDetailPage mainContainer_content">
        <div className="pageContainer">
          <div className="vehicleDetailPage_content">
            <BackLink link={backToListURL} labelKey="back_link_vehicles_list" />
            <div className="vehicleDetailPage_tabs">{this.displayTabs()}</div>
            {this.selectTab()}
            {this.getEditStatusFormModal()}
            {this.getDeleteStatusModal()}
          </div>
        </div>
      </div>
    );
  }
}

VehicleDetail.propTypes = {
  deviceStatus: PropTypes.object
};

export default namedCompose(
  connect(state => {
    const {
      vehicles: {
        detailVehicle,
        openedChangeStatusModal,
        confirmAddStatusModal,
        statuses,
        openedDeleteStatusModal,
        statusToDelete,
        urlParams,
        deviceStatus,
        currentDetailTabIndex,
        insuranceContractCurrentImage,
        deviceRemovalReminder
      },
      subCompanies: { subList },
      user: { userInfo }
    } = state;

    return {
      statusForm: _get(state, 'form.editStatusForm'),
      detailVehicle,
      openedChangeStatusModal,
      confirmAddStatusModal,
      statuses,
      openedDeleteStatusModal,
      statusToDelete,
      urlParams,
      deviceStatus,
      currentDetailTabIndex,
      insuranceContractCurrentImage,
      userInfo,
      subList,
      deviceRemovalReminder,
      vehicleRules: currentContractVehicleValuesSelector(state),
      role: userRoleSelector(state),
      bundle: bundleSelector(state)
    };
  })
)(VehicleDetail);
