import { createSelector } from 'reselect';
import { formatRouteLocation } from '../../utils/utils';

export const makeSelectLocation = state => state.routing.location.pathname;

export const makeLocationSelector = createSelector(
  makeSelectLocation,
  location => {
    let foundArray = [];
    location.split('').map((char, index) => {
      if (char === '/') foundArray.push(index);
    });
    return formatRouteLocation(location, foundArray);
  }
);
