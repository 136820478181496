import { UPDATE_LOCATION } from 'react-router-redux';
import * as types from '../constants/actionTypes-constants';
import { trySet } from '../utils/utils';

import _get from 'lodash/get';
import _without from 'lodash/without';
import _isArray from 'lodash/isArray';
import _clone from 'lodash/clone';
import { LOADING } from '../constants/generic-constants';

export default function members(
  state = {
    paginatedResults: [],
    loadingList: false,
    errorList: false,
    detailMember: {},
    drivingLicenseFiles: [],
    identityDocumentFiles: [],
    employerCertificateFiles: [],
    contractFileUrl: null,
    drivingLicenseMember: null,
    currentSortedIndex: null,
    sortIsDescending: false,
    urlParams: null,
    memberResetPasswordFormIsOpen: false,
    openAssignMemberType: null,
    openAnonymizeMember: null,
    resetPasswordDeliveryMode: true,
    isSuspendedMember: null,
    openedCommentModalMember: null,
    openedEmailModalMember: null,
    commentModalMemberType: null,
    memberMigrateFormIsOpen: false,
    exporting: null,
    companyUsedByMember: [],
    comments: [],
    customFields: [],
    customFieldsFiles: {},
    memberProfileHistory: [],
    memberProfileHistoryError: false,
    loadingProfileHistory: false,
    customFilesErrors: {},
    selectedMemberType: null,
    membersImportResume: {}
  },
  action
) {
  switch (action.type) {
    case types.MEMBERS_GET_LIST_REQUEST: {
      return {
        ...state,
        loadingList: true,
        errorList: false
      };
    }

    case types.MEMBERS_GET_LIST_SUCCESS: {
      return Object.assign({}, state, {
        paginatedResults: action.paginatedObj,
        urlParams: action.urlParams,
        loadingList: false
      });
    }

    case types.MEMBERS_GET_LIST_ERROR: {
      return Object.assign({}, state, {
        paginatedResults: action.paginatedObj,
        urlParams: action.urlParams,
        loadingList: false
      });
    }

    case types.MEMBERS_DETAILS_COMMENTS_SUCCESS: {
      return Object.assign({}, state, {
        comments: action.comments
      });
    }

    case types.MEMBER_UPDATE_CHORUS_DATA_SUCCESS: {
      return { ...state, detailMember: { ...state.detailMember, enterprise: action.data } };
    }

    case types.MEMBERS_GET_DETAIL_MEMBER_SUCCESS: {
      return Object.assign({}, state, {
        customFieldsFiles: {},
        customFilesErrors: {},
        memberMigrateError: false,
        detailMember: Object.assign(
          {},
          _get(action, 'member.member', {}),
          _get(action, 'member.suspensionInformation', ''),
          _get(action, 'member.userLoginInformation', {}),
          _get(action, 'member.memberProfileStatusInfo', {}),
          { profileReviewRejectionReason: _get(action, 'member.profileReviewRejectionReason', '') },
          { userSubscriptionDate: _get(action, 'member.userSubscriptionDate', '') },
          { profileSubscriptionDate: _get(action, 'member.profileSubscriptionDate', '') },
          { anonymizationEffectiveDate: _get(action, 'member.anonymizationEffectiveDate', '') }
        )
      });
    }

    case types.MEMBERS_DETAILS_DRIVING_LICENSE_REQUEST: {
      if (_get(state, 'drivingLicenseFiles.length', -1) === 0) {
        return state;
      }

      return Object.assign({}, state, {
        drivingLicenseFiles: []
      });
    }

    case types.MEMBERS_DETAILS_DRIVING_LICENSE_ERROR: {
      let driverFiles = _without(_get(state, 'detailMember.drivingLicence.files', []), action.fileToRemove);

      return {
        ...state,
        detailMember: {
          ..._get(state, 'detailMember', {}),
          drivingLicence: {
            ..._get(state, 'detailMember.drivingLicence', {}),
            files: driverFiles
          }
        }
      };
    }

    case types.MEMBERS_DETAILS_DRIVING_LICENSE_SUCCESS: {
      let driverImages = _clone(state.drivingLicenseFiles);

      if (_isArray(driverImages)) driverImages.push(action.drivingLicense);
      else driverImages = [];

      return {
        ...state,
        drivingLicenseFiles: driverImages
      };
    }

    case types.MEMBERS_DETAILS_CONTRACT_FILE_REQUEST: {
      if (!state.contractFileUrl) {
        return state;
      }

      return Object.assign({}, state, {
        contractFileUrl: null
      });
    }

    case types.MEMBERS_DETAILS_CONTRACT_FILE_SUCCESS: {
      return Object.assign({}, state, {
        contractFileUrl: action.contractFileUrl
      });
    }

    case types.MEMBERS_DETAILS_IDENTITY_DOCUMENT_REQUEST: {
      if (_get(state, 'identityDocumentFiles.length', -1) === 0) {
        return state;
      }

      return Object.assign({}, state, {
        identityDocumentFiles: []
      });
    }

    case types.MEMBERS_DETAILS_IDENTITY_DOCUMENT_ERROR: {
      let identityFiles = _without(_get(state, 'detailMember.identityDocument.files', []), action.fileToRemove);

      return {
        ...state,
        detailMember: {
          ..._get(state, 'detailMember', {}),
          identityDocument: {
            ..._get(state, 'detailMember.identityDocument', {}),
            files: identityFiles
          }
        }
      };
    }

    case types.MEMBERS_DETAILS_IDENTITY_DOCUMENT_SUCCESS: {
      let identityImages = _clone(state.identityDocumentFiles);

      if (_isArray(identityImages)) identityImages.push(action.identityDocument);
      else identityImages = [];

      return {
        ...state,
        identityDocumentFiles: identityImages
      };
    }

    case types.MEMBERS_DETAILS_EMPLOYER_CERTIFICATE_REQUEST: {
      if (_get(state, 'employerCertificateFiles.length', -1) === 0) {
        return state;
      }

      return Object.assign({}, state, {
        employerCertificateFiles: []
      });
    }

    case types.MEMBERS_DETAILS_EMPLOYER_CERTIFICATE_ERROR: {
      const employerFiles = _without(_get(state, 'detailMember.employerCertificate.files', []), action.fileToRemove);

      return {
        ...state,
        detailMember: {
          ..._get(state, 'detailMember', {}),
          employerCertificate: {
            ..._get(state, 'detailMember.employerCertificate', {}),
            files: employerFiles
          }
        }
      };
    }

    case types.MEMBERS_DETAILS_EMPLOYER_CERTIFICATE_SUCCESS: {
      let employerImages = _clone(state.employerCertificateFiles);

      if (_isArray(employerImages)) employerImages.push(action.employerCertificate);

      return {
        ...state,
        employerCertificateFiles: employerImages
      };
    }

    case types.MEMBERS_SET_CURRENT_SORTED_INDEX: {
      return Object.assign({}, state, {
        currentSortedIndex: action.index
      });
    }

    case types.MEMBERS_GET_PROFILE_HISTORY_MEMBER_REQUEST: {
      return Object.assign({}, state, {
        loadingProfileHistory: true
      });
    }

    case types.MEMBERS_GET_PROFILE_HISTORY_MEMBER_SUCCESS: {
      return Object.assign({}, state, {
        memberProfileHistory: action.memberProfileHistory,
        loadingProfileHistory: false,
        memberProfileHistoryError: false
      });
    }

    case types.MEMBERS_GET_PROFILE_HISTORY_MEMBER_ERROR: {
      return Object.assign({}, state, {
        loadingProfileHistory: false,
        memberProfileHistoryError: true,
        memberProfileHistory: []
      });
    }

    case types.MEMBERS_GET_PROFILE_HISTORY_MEMBER_CLEAR: {
      return Object.assign({}, state, {
        memberProfileHistory: []
      });
    }

    case types.MEMBERS_SET_SORT_DIRECTION: {
      return Object.assign({}, state, {
        sortIsDescending: action.isDescending
      });
    }

    case types.MEMBERS_OPEN_RESET_PASSWORD_MEMBER_MODAL: {
      return Object.assign({}, state, {
        memberResetPasswordFormIsOpen: true
      });
    }

    case types.MEMBERS_CLOSE_RESET_PASSWORD_MEMBER_MODAL: {
      return Object.assign({}, state, {
        memberResetPasswordFormIsOpen: false
      });
    }

    case types.MEMBERS_STATUS_MEMBER_TOGGLE: {
      return Object.assign({}, state, {
        isSuspendedMember: !state.detailMember.suspended
      });
    }

    case types.MEMBERS_EXPORT_REQUEST: {
      return Object.assign({}, state, {
        exporting: true
      });
    }

    case types.MEMBERS_EXPORT_FINISHED: {
      return Object.assign({}, state, {
        exporting: null
      });
    }

    case types.MEMBER_COMMENT_MODAL_OPEN: {
      return Object.assign({}, state, {
        openedCommentModalMember: true,
        commentModalMemberType: action.modalType
      });
    }

    case types.MEMBER_COMMENT_MODAL_CLOSE: {
      return Object.assign({}, state, {
        openedCommentModalMember: null,
        commentModalMemberType: null
      });
    }

    case types.MEMBER_EMAIL_MODAL_OPEN: {
      return Object.assign({}, state, {
        openedEmailModalMember: true
      });
    }

    case types.MEMBER_EMAIL_MODAL_CLOSE: {
      return Object.assign({}, state, {
        openedEmailModalMember: null
      });
    }

    case types.MEMBER_ASSIGN_TYPE_MODAL_OPEN: {
      return {
        ...state,
        openAssignMemberType: true
      };
    }

    case types.MEMBER_ASSIGN_TYPE_MODAL_CLOSE: {
      return {
        ...state,
        openAssignMemberType: null
      };
    }

    case types.MEMBER_ANONYMIZE_DIALOG_OPEN: {
      return {
        ...state,
        openAnonymizeMember: true
      };
    }

    case types.MEMBER_ANONYMIZE_DIALOG_CLOSE: {
      return {
        ...state,
        openAnonymizeMember: false
      };
    }

    case types.MEMBER_ANONYMIZE_SUCCESS: {
      return {
        ...state,
        detailMember: {
          ...state.detailMember,
          anonymizationEffectiveDate: new Date().toISOString().slice(0, 10)
        }
      };
    }

    case types.MEMBER_DEANONYMIZE_SUCCESS: {
      const memberDetail = { ...state.detailMember };
      delete memberDetail.anonymizationEffectiveDate;

      return {
        ...state,
        detailMember: memberDetail
      };
    }

    case types.MEMBER_TYPE_CHANGE_ASSIGNATION: {
      return {
        ...state,
        detailMember: {
          ...state.detailMember,
          selectedMemberType: action.memberType
        }
      };
    }

    case types.MEMBER_CHANGE_MEMBER_TYPE_SUCCESS: {
      const memberType = action.data.memberType;
      return {
        ...state,
        detailMember: {
          ...state.detailMember,
          memberType
        }
      };
    }

    case types.MEMBERS_CHANGE_EMAIL_MEMBER_SUCCESS: {
      return Object.assign({}, state, {
        detailMember: Object.assign({}, state.detailMember, {
          login: action.member.login
        })
      });
    }

    case types.MEMBERS_GET_COMPANIES_USED_LIST_SUCCESS:
    case types.MEMBERS_EDIT_COMPANIES_USED_LIST_SUCCESS:
    case types.MEMBERS_SAVE_COMPANIES_USED_LIST_SUCCESS: {
      return Object.assign({}, state, {
        companyUsedByMember: action.companyUsedByMember
      });
    }

    case UPDATE_LOCATION: {
      return Object.assign({}, state, {
        currentSortedIndex: null,
        sortIsDescending: false,
        openedCommentModalMember: null,
        openedEmailModalMember: null,
        commentModalMemberType: null,
        comments: []
      });
    }

    case types.MEMBERS_CUSTOM_FIELDS_CLEAR: {
      return {
        ...state,
        customFields: [],
        customFieldsFiles: {}
      };
    }

    case types.MEMBERS_CUSTOM_FIELDS_SUCCESS: {
      return {
        ...state,
        customFields: action.customFields
      };
    }

    case types.MEMBERS_CUSTOM_FIELDS_FILE_REQUEST: {
      return {
        ...state,
        customFieldsFiles: {
          ...state.customFieldsFiles,
          [action.fieldId]: LOADING
        }
      };
    }

    case types.MEMBERS_CUSTOM_FIELDS_FILE_ERROR: {
      const customErrors = { ...state.customFilesErrors };
      const customFieldsFiles = { ...state.customFieldsFiles };

      trySet(customErrors, action.id, true);

      Object.keys(customFieldsFiles).map(file => {
        if (file === action.id) {
          customFieldsFiles[file] = '';
        }
      });

      return {
        ...state,
        customFieldsFiles,
        customFilesErrors: {
          ...customErrors
        }
      };
    }

    case types.MEMBERS_CUSTOM_FIELDS_FILE_SUCCESS: {
      let customFieldsFiles = { ...state.customFieldsFiles };

      Object.keys(customFieldsFiles).map(file => {
        if (file === action.file.id) {
          customFieldsFiles[file] = action.file;
        }
      });

      return {
        ...state,
        customFieldsFiles
      };
    }

    case types.MEMBERS_IMPORTED_SUCCESS: {
      return {
        ...state,
        membersImportResume: action.data
      };
    }

    case types.MEMBERS_IMPORTED_EMPTY_RESUME: {
      return {
        ...state,
        membersImportResume: {}
      };
    }

    case types.MEMBER_OPEN_MIGRATE_MODAL:
      return Object.assign({}, state, {
        memberMigrateFormIsOpen: true
      });

    case types.MEMBER_CLOSE_MIGRATE_MODAL:
      return Object.assign({}, state, {
        memberMigrateFormIsOpen: false
      });

    default: {
      return state;
    }
  }
}
