import React, { Component } from 'react';
import { showParentView } from '../../../components/_v2/ColumnList/helpers';
import ListDetails from '../../../components/_v2/ColumnList/Details';
import { connect } from 'react-redux';
import { bookingDelayedSelector, bookingDetailsSelector, invoiceDetailsSelector } from '../../../selectors/all-selectors';
import { getMsg } from '../../../utils/IntlGlobalProvider';
import cs from 'classnames';
import { PAYMENT_STATUS_UNPAID, INVOICE_TYPE } from '../../../constants/backend-constants';
import { isGranted } from '../../../actions/user-actions';
import { retryPayment } from '../../../actions/invoices-actions';
import { FormattedMessage } from 'react-intl';
import BookingInfoV2 from '../../../components/_v2/BookingInfo/BookingInfoV2';
import { getBookingInfo, safe } from '../../../utils/utils';
import { RaisedButton } from 'material-ui';
import { invoiceRetryPaymentRules } from '../../../constants/backuser-role-rules';

export class InvoiceDetails extends Component {
  getStatusMsg() {
    const { status } = this.props.invoice;
    const statusMsg = getMsg(`payment_status_${status}`) || getMsg('common_unknown');
    const className = `${status === 'PAID' ? 'paid' : 'unpaid'}`;

    return <span className={cs('sc-status', className)}>{statusMsg}</span>;
  }

  displayRetryBtn() {
    const { status, type, recallmoney } = this.props.invoice;
    const retry = getMsg('booking_detail_settle_booking');
    const pending = getMsg('groups_status_PENDING');

    if (
      status === PAYMENT_STATUS_UNPAID &&
      type === INVOICE_TYPE.MISC_INVOICE &&
      isGranted({ allowed: invoiceRetryPaymentRules.include, disallowed: invoiceRetryPaymentRules.exclude })
    ) {
      return (
        <RaisedButton
          label={recallmoney ? pending : retry}
          primary
          disabled={recallmoney}
          type="button"
          onClick={this.handlePayment}
          className={cs('sc-status', 'button', 'invoice-payment')}
        />
      );
    }
  }

  getTitle() {
    return <div>{getMsg('company_invoice_preview')}</div>;
  }

  componentWillMount() {
    this.showParentView = showParentView.bind(this);

    this.handlePayment = () => {
      this.props.dispatch(retryPayment(this.props.invoice.id));
    };
  }

  render() {
    const u = getMsg('common_unknown');

    const { invoice: { reference = u, type = u, vatCode = u, vatRate = 0, companyId, bookingId } = {} } = this.props;

    return (
      <ListDetails title={this.getTitle()} onClose={this.showParentView}>
        <div className="v2-invoice-details">
          <div className="sc-more-details">
            <div className="sc-invoice-info">
              <div className="sc-item">
                <div className="sc-key">
                  <FormattedMessage id="common_status" />
                </div>
                <div className="sc-value">{this.getStatusMsg()}</div>
              </div>
              <div className="sc-item">
                <div className="sc-key">
                  <FormattedMessage id="invoices_tableView_label_invoiceNumber" />
                </div>
                <div className="sc-value">{reference}</div>
              </div>
              <div className="sc-item">
                <div className="sc-key">
                  <FormattedMessage id="common_type" />
                </div>
                <div className="sc-value">{<FormattedMessage id={`invoice.type.${type}`} />}</div>
              </div>
              <div className="sc-item">
                <div className="sc-key">
                  <FormattedMessage id="invoice_params_label_vat_code" />
                </div>
                <div className="sc-value">{vatCode}</div>
              </div>
              <div className="sc-item">
                <div className="sc-key">
                  <FormattedMessage id="invoice_params_label_vat_rate" />
                </div>
                <div className="sc-value">{vatRate}</div>
              </div>
              <div className="sc-item">{this.displayRetryBtn()}</div>
            </div>

            <BookingInfoV2
              companyId={companyId}
              bookingId={bookingId}
              price={this.props.price}
              memberId={this.props.memberId}
              companyName={this.props.companyName}
              bookingStatus={this.props.bookingStatus}
              bookingUsage={this.props.bookingUsage}
              bookingUsageType={this.props.bookingUsageType}
              bookingMemberType={this.props.bookingMemberType}
              currency={this.props.bookingCurrency}
              freeOfCharges={this.props.freeOfCharges}
              score={this.props.score}
              delayedBooking={this.props.delayedBooking}
            />
          </div>
          <iframe className="invoice-pdf-container" src={this.props.invoice.downloadUrl} />
        </div>
      </ListDetails>
    );
  }
}

InvoiceDetails = connect(state => {
  const booking = bookingDetailsSelector(state);

  const {
    score,
    carSharingInfo,
    member: bookingMember,
    status: bookingStatus,
    vehicleUsageAtBookingCreation: bookingUsage,
    end: bookingEnd
  } = booking;

  const memberCompany = safe(() => bookingMember.company);
  const carSharingCost = safe(() => carSharingInfo.cost);

  const effectiveEnd = getBookingInfo(booking, 'effectiveEndDate');
  const delayedBooking = bookingDelayedSelector(state);

  const priceWithTax = safe(() => carSharingCost.totalPriceIncludingTaxes);
  const priceWithDuration = safe(() => carSharingCost.estimatedPriceForDuration);

  return {
    invoice: invoiceDetailsSelector(state),
    booking: bookingDetailsSelector(state),
    memberId: safe(() => bookingMember.id),
    companyName: safe(() => memberCompany.name),
    bookingUsageType: safe(() => carSharingInfo.usageType),
    bookingMemberType: safe(() => booking.memberType.name),
    priceWithTax: safe(() => carSharingCost.totalPriceIncludingTaxes),
    priceWithDuration: safe(() => carSharingCost.estimatedPriceForDuration),
    bookingCurrency: safe(() => booking.currency),
    freeOfCharges: safe(() => carSharingInfo.freeOfCharges),
    estimatedEnd: safe(() => bookingEnd.date),
    price: priceWithTax || priceWithDuration,
    delayedBooking,
    bookingUsage,
    bookingStatus,
    effectiveEnd,
    score
  };
})(InvoiceDetails);

export default InvoiceDetails;
