/*eslint-disable react/no-set-state */
import React, { Component, PropTypes as T } from 'react';
import Dialog from 'material-ui/Dialog';
import { connect } from 'react-redux';
import _partial from 'lodash/partial';
import _get from 'lodash/get';
import _map from 'lodash/map';
import _find from 'lodash/find';
import _isEmpty from 'lodash/isEmpty';
import { reduxForm, change } from 'redux-form';
import { FormattedMessage, injectIntl } from 'react-intl';
import TimeIcon from 'material-ui/svg-icons/device/access-time';
import FlatButton from 'material-ui/FlatButton';
import CloseIcon from 'material-ui/svg-icons/navigation/close';
import IconButton from 'material-ui/IconButton';
import FilterIcon from 'material-ui/svg-icons/content/filter-list';
import classnames from 'classnames';

import config from '../../../../constants/config-constants';
import BoxedSelect from '../../../../components/BoxedSelect/BoxedSelect';
import BoxedInput from '../../../../components/BoxedInput/BoxedInput';
import AddressAutocomplete from '../../../../components/AddressAutocomplete/AddressAutocomplete';
import {
  toggleChangeVehicle,
  confirmBookingEdition,
  getDataVehiclePlanning,
  addFlashMessage,
  getBookingFindAvailableVehicle,
  setBookingDetailUpdateVehicle,
  setBookingsListUpdateVehicle
} from '../../../../actions/all-actions';
import { createValidator, notEmpty, stopValidationIf, addressOrSite, dateAfterOrEqualField } from '../../../../validation/all-validation';
import Loader from '../../../../components/MaterialLoader/MaterialLoader';
import {
  createInitVehiclePlanning,
  scrollToFirstError,
  valueToString,
  addErrorMessage,
  vehicleAapter,
  findNestedObj
} from '../../../../utils/utils';
import EkRadio from '../../../../components/EkRadio/EkRadio';
import FieldErrorMsg from '../../../../components/FieldErrorMsg/FieldErrorMsg';
import { ALL, FLASH_MESSAGE_TYPE_SUCCESS } from '../../../../constants/generic-constants';
import { BOOKING_COMMENT_INIT_VALUE, PAID_BOOKING_INIT_VALUE } from '../../../../constants/form-constants';
import {
  ONE_WAY_TRIP,
  ROUND_TRIP,
  VEHICLE_USAGE_TYPE_IMMEDIATE,
  VEHICLE_USAGE_TYPE_PLANNED
} from '../../../../constants/backend-constants';
import { bundleSelector, headerCompanyContractSelector } from '../../../../selectors/all-selectors';
import VehiclePlanningVehicleTile from './VehiclePlanningVehicleTile';
import VehiclePlanningCollapsible from './VehiclePlanningCollapsible';
import ReactSVG from 'react-svg';
import Popover from 'material-ui/Popover';
import HardwareKeyboardArrowDown from 'material-ui/svg-icons/hardware/keyboard-arrow-down';
import KeyboardArrowUp from 'material-ui/svg-icons/hardware/keyboard-arrow-up';

class VehiclePlanningChangeVehicle extends Component {
  constructor() {
    super();
    this.handleClose = this.handleClose.bind(this);
    this.renderList = this.renderList.bind(this);
    this.handleFiltered = this.handleFiltered.bind(this);
    this.getVehicles = this.getVehicles.bind(this);
    this.handleToggleConfirmDialog = this.handleToggleConfirmDialog.bind(this);
    this.handleConfirm = this.handleConfirm.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleRequestClose = this.handleRequestClose.bind(this);
    this.getSiteParkLabel = this.getSiteParkLabel.bind(this);
    this.state = {
      openConfirmDialog: false,
      confirmDialogData: {},
      availableVehicles: [],
      changedParking: '',
      open: false
    };
  }

  componentWillUpdate(props, state) {
    this.displayVehicles = _isEmpty(state.availableVehicles) ? props.availableVehicles : state.availableVehicles;
  }

  handleClose() {
    this.props.dispatch(toggleChangeVehicle());
  }

  handleFiltered(ev) {
    const { availableVehicles } = this.props;
    let newList = availableVehicles.filter(obj => {
      return obj.vehicle.registrationNumber.toUpperCase().indexOf(ev.target.value.toUpperCase()) > -1 ? obj : '';
    });
    this.setState({ availableVehicles: newList });
  }

  handleToggleConfirmDialog(price) {
    this.setState({
      openConfirmDialog: !this.state.openConfirmDialog,
      confirmDialogData: this.state.openConfirmDialog ? {} : price
    });
  }

  getTripTypeField() {
    const {
      fields: {
        tripType,
        usageOverride: { value: usage }
      },
      currentContract: { interfaceConfigDto }
    } = this.props;

    const { oneWayDisplay, returnTripDisplay } = interfaceConfigDto || {};

    if (oneWayDisplay && returnTripDisplay && (usage === VEHICLE_USAGE_TYPE_PLANNED || usage === VEHICLE_USAGE_TYPE_IMMEDIATE)) {
      return (
        <div className="trip-type">
          <EkRadio
            formRowItemKey="backUserForm_userRole"
            items={[
              {
                labelKey: 'search_vehicle_oneWay',
                value: ONE_WAY_TRIP
              },
              {
                labelKey: 'search_vehicle_return',
                value: ROUND_TRIP
              }
            ]}
            namespace="tripType"
            field={tripType}
            disabled
          >
            <FieldErrorMsg field={tripType} customClass="fieldErrorMsg--backUserForm" />
          </EkRadio>
        </div>
      );
    }
  }

  handleConfirm() {
    let { dispatch, bookingDetail, bundle, VehiclePlanningChangeVehicle, subCompanySelected } = this.props;

    const confirmationDetails = {
      comment: _get(bookingDetail, 'comment', BOOKING_COMMENT_INIT_VALUE),
      paidBooking: !_get(bookingDetail, 'carSharingInfo.freeOfCharges', !PAID_BOOKING_INIT_VALUE),
      newStartLocation: VehiclePlanningChangeVehicle.from.value.id
    };
    bookingDetail.vehicle.id = this.state.confirmDialogData[1];
    dispatch(confirmBookingEdition(bookingDetail.member, bookingDetail, confirmationDetails, bookingDetail.id))
      .then(data => {
        setTimeout(() => {
          if (subCompanySelected.id !== ALL) dispatch(getDataVehiclePlanning());

          dispatch(setBookingDetailUpdateVehicle(data));
          if (this.props.withList) dispatch(setBookingsListUpdateVehicle(data.id, data.vehicle));
        }, 1000); // wait server to process data
        this.handleClose();
        dispatch(
          addFlashMessage({
            contentKey: 'booking_edit_success',
            type: FLASH_MESSAGE_TYPE_SUCCESS
          })
        );
      })
      .catch(error => {
        dispatch(addErrorMessage({ error, bundle, errorCodePrefixes: ['bookingResult_'] }));
      });
  }

  renderList() {
    const { availableVehicles, loadingBookingDetail, loadingAvailableVehicles } = this.props;

    if (loadingBookingDetail || loadingAvailableVehicles) {
      return (
        <div className="new-vehicle-planning-change-vehicle_loaderWrap">
          <Loader />
        </div>
      );
    }
    if (availableVehicles.length === 0) {
      return <FormattedMessage id="vehicle-change-no-vehicles-available" />;
    }
    return _map(this.displayVehicles, item => {
      const vehicle = vehicleAapter(item.vehicle);
      const usage = item.carSharingInfo.usageType;
      const cost = item.carSharingInfo.cost;
      return (
        <div
          className="new-vehicle-planning-change-vehicle_row"
          key={`${vehicle.id}-${usage}`}
          onClick={_partial(this.handleToggleConfirmDialog, [cost, vehicle.id])}
        >
          <VehiclePlanningVehicleTile vehicle={vehicle} nolink displayUsages />
        </div>
      );
    });
  }

  sitesParkingsList() {
    const { immutableParkings, availableVehicles, loadingBookingDetail, loadingAvailableVehicles, urlParams } = this.props;

    if (!loadingBookingDetail && !loadingAvailableVehicles) {
      const bookStart = availableVehicles.length > 0 ? availableVehicles[0].start : urlParams.start;
      const parkingIdStart = _get(bookStart, 'parking.id') || _get(urlParams, 'startParkingId');
      const siteIdStart = _get(bookStart, 'parking.siteId') || _get(urlParams, 'start.siteId');
      return _map(immutableParkings, (site, i) => {
        return (
          <div key={site.id + '--' + i + 'end'} className="new-vehicle-planning_site">
            <VehiclePlanningCollapsible
              title={
                <span>
                  {site.name} <small> ({site.zoneId})</small>
                </span>
              }
              key={site.id + '_' + i}
            >
              <div>
                {_map(site.parkings, parking => {
                  const currentList = parking.id === parkingIdStart && site.id === siteIdStart;
                  return (
                    <div className={classnames('new-vehicle-planning_parking', { selected: currentList })} key={parking.id}>
                      <div
                        className={classnames('new-vehicle-planning_parking_name ', { selected: currentList })}
                        onClick={() => this.getVehicles(parking.id, site.id)}
                        style={{
                          bottom: parking.vehicles.length === 1 ? '0' : (parking.vehicles.length * 80) / 2
                        }}
                      >
                        <ReactSVG src="/img/parking-icon.svg" svgClassName="icon" className="icon-parking" />
                        {parking.name}
                      </div>
                    </div>
                  );
                })}
              </div>
            </VehiclePlanningCollapsible>
          </div>
        );
      });
    }
  }

  getSiteParkLabel() {
    const { bookingDetail, sitesList, changeVehiclePayloadSearch } = this.props;

    if (bookingDetail) {
      if (changeVehiclePayloadSearch && bookingDetail.start.parking.id !== changeVehiclePayloadSearch.parkingIdStart) {
        const siteId = changeVehiclePayloadSearch.start.siteId;
        const currentSite = _find(sitesList, { id: siteId });
        const siteParkings = _get(currentSite, 'parkings');
        const parking = _find(siteParkings, { id: changeVehiclePayloadSearch.startParkingId });
        const siteName = _get(currentSite, 'name');
        const parkingName = _get(parking, 'name');

        return siteName + ' / ' + parkingName;
      }
      return bookingDetail.start.parking.site.name + ' / ' + bookingDetail.start.parking.name;
    }
  }

  getVehicles(parkingId, siteId) {
    const { dispatch, changeVehiclePayloadSearch, sitesList } = this.props;
    let payload = { ...changeVehiclePayloadSearch };
    this.handleRequestClose();
    const parking = findNestedObj(sitesList, 'id', parkingId);

    payload.start.siteId = siteId;
    payload.startParkingId = parkingId;
    payload.end.siteId = siteId;

    dispatch(change('VehiclePlanningChangeVehicle', 'from', { name: parking.name, id: parking.id }));
    dispatch(change('VehiclePlanningChangeVehicle', 'to', { name: parking.name, id: parking.id }));
    dispatch(getBookingFindAvailableVehicle(payload, false, true));
  }

  plateFilter() {
    const {
      availableVehicles,
      intl: { messages }
    } = this.props;
    return (
      <div className="new-vehicle-planning_filterWrap">
        {availableVehicles.length > 5 && (
          <span className="filter">
            <FilterIcon />
            <input
              type="text"
              id="filter"
              className="boxedInput input vehicleFilter"
              onChange={this.handleFiltered}
              placeholder={messages.booking_detail_vehicle_registration_number}
            />
          </span>
        )}
      </div>
    );
  }

  handleClick(event) {
    // This prevents ghost click.
    event.preventDefault();

    this.setState({
      open: true,
      anchorEl: event.currentTarget
    });
  }

  handleRequestClose() {
    this.setState({
      open: false
    });
  }

  render() {
    const {
      openedChangeVehicleModal,
      loadingBookingDetail,
      loadingAvailableVehicles,
      bookingDetail,
      fields: { from, to, pickupDate, pickupDateHour, pickupDateMin, returnDate, returnDateHour, returnDateMin, tripType },
      intl: { formatMessage }
    } = this.props;
    const now = new Date();
    const minReturnDate = pickupDate && typeof pickupDate.value === 'object' ? pickupDate.value : now;
    return (
      <Dialog
        modal={false}
        open={openedChangeVehicleModal}
        onRequestClose={this.handleClose}
        bodyStyle={{ padding: 0 }}
        style={{ padding: 0 }}
        className="new-vehicle-planning-booking-create"
        contentStyle={{ width: '80%', padding: 0, maxWidth: 'none' }}
        title={
          bookingDetail && (
            <div className="header-title">
              <FormattedMessage id="vehiclePlanning_changeVehicle_of_booking_for" />
              <span className="member-name">{bookingDetail.member.firstName + ' ' + bookingDetail.member.lastName}</span>
              <div className="new-vehicle-planning_closeIconWrap">
                <IconButton onClick={this.handleClose}>
                  <CloseIcon />
                </IconButton>
              </div>
            </div>
          )
        }
      >
        <div className="new-vehicle-planning-edit-booking">
          <div className="new-vehicle-planning-edit-booking_container">
            <div className="new-vehicle-planning-edit-booking_col1">
              <div className="frow user-detail">
                <div className="frow-label">
                  <FormattedMessage id="booking-member" />
                </div>
                <span className="member">
                  {/* <ReactSVG src="/img/edit.svg" className="align-svg  edit-svg" /> */}

                  <div className="detail">
                    <span className="user-name">
                      {bookingDetail && bookingDetail.member.firstName + ' ' + bookingDetail.member.lastName}
                    </span>
                    <span className="user-mail" children={bookingDetail && bookingDetail.member.login} />
                  </div>
                </span>
              </div>
              <div className="frow trip">
                <div className="frow-label">
                  <FormattedMessage id="booking-address" />
                </div>
                <ul className={classnames('trip-info', { loop: tripType.value !== ONE_WAY_TRIP })}>
                  <li className="item">
                    <span className="item">
                      <AddressAutocomplete field={from} disabled />
                    </span>
                  </li>
                  {tripType.value !== ONE_WAY_TRIP && (
                    <li className="item">
                      <span className="item">
                        <AddressAutocomplete field={tripType.value !== ONE_WAY_TRIP ? to : { value: '-' }} disabled />
                      </span>
                    </li>
                  )}
                </ul>

                {this.getTripTypeField()}
              </div>
              <div className="frow datetime">
                <div className="frow-label">
                  <FormattedMessage id="booking-dates" />
                </div>
                <div className="new-vehicle-planning-edit-booking_col1Row2 paddingTop0">
                  <BoxedInput
                    formRowItemKey="searchVehicle_from_pickupDate"
                    skinType="date"
                    type="date"
                    customClass="boxedInputWrapper--label _is_year_hidden new-vehicle-planning-edit-booking_col1Row2_pickupDate"
                    labelKey="search_vehicle_pickup"
                    field={pickupDate}
                    minDate={now}
                    disableYearSelection
                    disabled
                  >
                    <FieldErrorMsg field={pickupDate} customClass="fieldErrorMsg--searchVehicleForm" />
                  </BoxedInput>

                  <div className="new-vehicle-planning-edit-booking_col1Row2_dateWrap">
                    <BoxedSelect
                      formRowItemKey="searchVehicle_from_pickupDateHour"
                      labelKey="search_vehicle_pickup_hour"
                      customClass="searchVehicle_boxedSelectWrapper"
                      hideLabel
                      disabled
                      field={pickupDateHour}
                      options={config.availableHours}
                    />
                    <span className="new-vehicle-planning-edit-booking_col1Row2_dvojtecka">:</span>
                    <BoxedSelect
                      formRowItemKey="searchVehicle_from_pickupDateMin"
                      labelKey="search_vehicle_pickup_min"
                      customClass="searchVehicle_boxedSelectWrapper"
                      hideLabel
                      disabled
                      field={pickupDateMin}
                      options={config.availableMinutes}
                    />
                    <TimeIcon />
                  </div>
                </div>
                {tripType.value !== ONE_WAY_TRIP && (
                  <div className="new-vehicle-planning-edit-booking_col1Row2">
                    <BoxedInput
                      formRowItemKey="searchVehicle_to_returnDate"
                      skinType="date"
                      type="date"
                      customClass="boxedInputWrapper--label new-vehicle-planning-edit-booking_col1Row2_pickupDate"
                      labelKey="search_vehicle_return_date"
                      field={returnDate}
                      minDate={minReturnDate}
                      mandatory={!(tripType.value === ONE_WAY_TRIP)}
                      disableYearSelection
                      disabled
                      shouldDisableDate={this.getClosedDays}
                    >
                      <FieldErrorMsg field={returnDate} customClass="fieldErrorMsg--searchVehicleForm" />
                    </BoxedInput>

                    <div className="new-vehicle-planning-edit-booking_col1Row2_dateWrap">
                      <BoxedSelect
                        formRowItemKey="searchVehicle_from_pickupDateHour"
                        labelKey="search_vehicle_pickup_hour"
                        customClass="searchVehicle_boxedSelectWrapper"
                        hideLabel
                        field={returnDateHour}
                        options={config.availableHours}
                        disabled
                      />
                      <span className="new-vehicle-planning-edit-booking_col1Row2_dvojtecka">:</span>
                      <BoxedSelect
                        formRowItemKey="searchVehicle_from_pickupDateMin"
                        labelKey="search_vehicle_pickup_min"
                        customClass="searchVehicle_boxedSelectWrapper"
                        hideLabel
                        field={returnDateMin}
                        options={config.availableMinutes}
                        disabled
                      />
                      <TimeIcon />
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="new-vehicle-planning-change-vehicle new-vehicle-planning-edit-booking_col2">
              <div className="new-vehicle-planning-change-vehicle-available-list box">
                {!loadingBookingDetail && !loadingAvailableVehicles && (
                  <FlatButton
                    onClick={this.handleClick}
                    label={this.getSiteParkLabel()}
                    icon={
                      !this.state.open ? (
                        <HardwareKeyboardArrowDown style={{ marginLeft: '0', width: '26px', height: '26px' }} />
                      ) : (
                        <KeyboardArrowUp style={{ marginLeft: '0', width: '26px', height: '26px' }} />
                      )
                    }
                  />
                )}
                {!loadingBookingDetail && !loadingAvailableVehicles && (
                  <Popover
                    open={this.state.open}
                    anchorEl={this.state.anchorEl}
                    anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
                    targetOrigin={{ horizontal: 'left', vertical: 'top' }}
                    onRequestClose={this.handleRequestClose}
                  >
                    <div className="menu-sites" style={{ maxHeight: '600px!important' }}>
                      {this.sitesParkingsList()}
                    </div>
                  </Popover>
                )}
                {this.plateFilter()}
                {this.renderList()}
              </div>
            </div>
          </div>
        </div>
        {this.state.openConfirmDialog && (
          <Dialog
            actions={[
              <FlatButton key="no" label={<FormattedMessage id="common_cancel" />} onClick={this.handleToggleConfirmDialog} />,
              <FlatButton key="yes" label={<FormattedMessage id="common_confirm" />} primary onClick={this.handleConfirm} />
            ]}
            title={formatMessage({ id: 'vehiclePlanning_changeVehicle_confirm' })}
            modal={false}
            contentStyle={{ width: '320px' }}
            open={this.state.openConfirmDialog}
            onRequestClose={this.handleToggleConfirmDialog}
          >
            <FormattedMessage id="common_price_per_km" />
            {' : '}
            <FormattedMessage
              id="common_price_with_currency"
              values={{
                amount: valueToString(this.state.confirmDialogData[0].pricePerKm),
                currency: formatMessage({ id: `unit_` + _get(bookingDetail, 'currency') })
              }}
            />{' '}
            <br />
            <FormattedMessage id="common_price_per_duration" />
            {' : '}
            <FormattedMessage
              id="common_price_with_currency"
              values={{
                amount: valueToString(this.state.confirmDialogData[0].estimatedPriceForDuration),
                currency: formatMessage({ id: `unit_` + _get(bookingDetail, 'currency') })
              }}
            />
          </Dialog>
        )}
      </Dialog>
    );
  }
}

VehiclePlanningChangeVehicle.displayName = 'VehiclePlanningChangeVehicle';

VehiclePlanningChangeVehicle.propTypes = {
  action: T.func,
  fields: T.object,
  onCallback: T.func,
  handleSubmit: T.func
};

VehiclePlanningChangeVehicle = reduxForm(
  {
    onSubmitFail: scrollToFirstError,
    form: 'VehiclePlanningChangeVehicle',
    fields: [
      'from',
      'to',
      'pickupDate',
      'pickupDateHour',
      'pickupDateMin',
      'returnDate',
      'returnDateHour',
      'returnDateMin',
      'passengers',
      'tripType',
      'memberTypeId',
      'usageOverride',
      'prebook'
    ],
    validate: createValidator({
      from: [notEmpty(), addressOrSite()],
      to: [
        stopValidationIf({
          condition: (props, value) => {
            return !value;
          }
        }),
        addressOrSite()
      ],
      pickupDate: [notEmpty()],
      tripType: [notEmpty()],
      returnDate: [
        stopValidationIf({
          condition: props => {
            return props.form.tripType && props.form.tripType.value === ONE_WAY_TRIP ? props.form.tripType.value : false;
          }
        }),
        notEmpty(),
        dateAfterOrEqualField('pickupDate')
      ]
    })
  },
  state => {
    const {
      bookingFindVehicle: { urlParams },
      vehiclePlanning: {
        openedChangeVehicleModal,
        bookingDetail,
        selectedBookingId,
        loadingBookingDetail,
        loadingAvailableVehicles,
        availableVehicles,
        changeVehiclePayloadSearch,
        data: { immutableParkings, sites },
        siteId
      },
      form: { VehiclePlanningChangeVehicle },
      subCompanies: { subCompanySelected }
    } = state;

    return {
      currentContract: headerCompanyContractSelector(state),
      openedChangeVehicleModal,
      bookingDetail,
      selectedBookingId,
      VehiclePlanningChangeVehicle,
      loadingBookingDetail,
      loadingAvailableVehicles,
      availableVehicles,
      changeVehiclePayloadSearch,
      immutableParkings,
      sitesList: sites,
      siteId,
      urlParams,
      subCompanySelected
    };
  }
)(VehiclePlanningChangeVehicle);

export default connect((state, props) => {
  const { bookingDetail: bd, availableVehicles } = state.vehiclePlanning;
  const { bookingDetail: bv2 } = state.bookings;
  const bookingDetail = createInitVehiclePlanning(bd || bv2);

  return {
    initialValues: bookingDetail,
    bookingDetail,
    bundle: bundleSelector(state),
    availableVehicles
  };
})(injectIntl(VehiclePlanningChangeVehicle));
