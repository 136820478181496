import React, { Component } from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { injectIntl } from 'react-intl';
import autoBind from 'react-autobind';

import _partial from 'lodash/partial';
import Dialog from 'material-ui/Dialog';
import SelectField from 'material-ui/SelectField';
import MenuItem from 'material-ui/MenuItem';
import FormRow from '../../../components/FormRow/FormRow';
import FlatButton from 'material-ui/FlatButton';
import RaisedButton from 'material-ui/RaisedButton';

import { requestMemberTypeChange, closeAssignMemberTypeModal } from '../../../actions/all-actions';

class AssignMemberTypeDialog extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {
      memberTypeId: this.props.detailMember.memberType.id
    };
  }

  handleChangeField(field, event, index, value) {
    this.setState({ memberTypeId: value });
  }

  handleSubmit() {
    const { dispatch, detailMember } = this.props;
    const payload = {
      memberId: detailMember.id,
      memberTypeId: this.state.memberTypeId
    };
    dispatch(requestMemberTypeChange(payload));
  }

  handleClose() {
    const { dispatch } = this.props;
    dispatch(closeAssignMemberTypeModal());
  }

  render() {
    const {
      fields: { memberType },
      list,
      intl
    } = this.props;

    let actions = [
      <FlatButton
        key="createMemberTypeForm_abortBtn"
        label={intl.messages['common_cancel']}
        formRowItemKey="createMemberTypeForm_abortBtn"
        primary
        onClick={this.handleClose}
      />,
      <RaisedButton
        primary
        key="createMemberTypeForm_submitBtn"
        label={intl.messages['common_save']}
        type="button"
        formRowItemKey="createMemberTypeForm_submitBtn"
        onClick={this.handleSubmit}
      />
    ];

    return (
      <Dialog open title={intl.messages.member_type_association_title} actions={actions} actionsContainerClassName="dialog-actions">
        <form action="#" className="assignMemberTypeForm">
          <FormRow customClass="changeTypeForm_row">
            <SelectField
              onChange={_partial(this.handleChangeField, memberType)}
              floatingLabelText={intl.messages.member_type_association_label}
              fullWidth
              value={this.state.memberTypeId}
            >
              {list.map(item => (
                <MenuItem key={item.id} value={item.id} primaryText={item.name} />
              ))}
            </SelectField>
          </FormRow>
        </form>
      </Dialog>
    );
  }
}

AssignMemberTypeDialog.displayName = 'AssignMemberTypeForm';

AssignMemberTypeDialog = reduxForm({
  form: 'assignMemberTypeForm',
  fields: ['memberType']
})(AssignMemberTypeDialog);

export default connect(state => {
  const {
    user: { userInfo },
    companies: { currentCompany },
    members: { detailMember }
  } = state;

  return {
    userInfo,
    companyId: currentCompany.id,
    detailMember
  };
})(injectIntl(AssignMemberTypeDialog));
