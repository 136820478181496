/*eslint-disable react/no-set-state */
import React, { Component, PropTypes as T } from 'react';
import Dialog from 'material-ui/Dialog';
import { connect } from 'react-redux';
import _partial from 'lodash/partial';
import _get from 'lodash/get';
import _map from 'lodash/map';
import _isEmpty from 'lodash/isEmpty';
import { reduxForm } from 'redux-form';
import { FormattedMessage, injectIntl } from 'react-intl';
import LocationIcon from 'material-ui/svg-icons/communication/location-on';
import CalendarIcon from 'material-ui/svg-icons/action/date-range';
import TimeIcon from 'material-ui/svg-icons/device/access-time';
import FlatButton from 'material-ui/FlatButton';
import CloseIcon from 'material-ui/svg-icons/navigation/close';
import IconButton from 'material-ui/IconButton';
import UserIcon from 'material-ui/svg-icons/social/person';
import FilterIcon from 'material-ui/svg-icons/content/filter-list';

import config from '../../../constants/config-constants';
import BoxedSelect from '../../../components/BoxedSelect/BoxedSelect';
import BoxedInput from '../../../components/BoxedInput/BoxedInput';
import AddressAutocomplete from '../../../components/AddressAutocomplete/AddressAutocomplete';
import { toggleChangeVehicle, confirmBookingEdition, getDataVehiclePlanning, addFlashMessage } from '../../../actions/all-actions';
import { createValidator, notEmpty, stopValidationIf, addressOrSite, dateAfterOrEqualField } from '../../../validation/all-validation';
import Loader from '../../../components/MaterialLoader/MaterialLoader';
import { enhanceSiteList, createInitVehiclePlanning, scrollToFirstError, valueToString, addErrorMessage } from '../../../utils/utils';
import EkRadio from '../../../components/EkRadio/EkRadio';
import FieldErrorMsg from '../../../components/FieldErrorMsg/FieldErrorMsg';
import { FLASH_MESSAGE_TYPE_SUCCESS } from '../../../constants/generic-constants';
import { BOOKING_COMMENT_INIT_VALUE, PAID_BOOKING_INIT_VALUE } from '../../../constants/form-constants';
import { ONE_WAY_TRIP, ROUND_TRIP } from '../../../constants/backend-constants';
import { bundleSelector } from '../../../selectors/all-selectors';

class VehiclePlanningChangeVehicle extends Component {
  constructor() {
    super();
    this.handleClose = this.handleClose.bind(this);
    this.handleFiltered = this.handleFiltered.bind(this);
    this.handleToggleConfirmDialog = this.handleToggleConfirmDialog.bind(this);
    this.handleConfirm = this.handleConfirm.bind(this);
    this.state = {
      openConfirmDialog: false,
      confirmDialogData: {},
      availableVehicles: []
    };
  }

  componentWillUpdate(props, state) {
    this.displayVehicles = _isEmpty(state.availableVehicles) ? props.availableVehicles : state.availableVehicles;
  }

  handleClose() {
    this.props.dispatch(toggleChangeVehicle());
  }

  renderSuggestFiltered(list) {
    const { fields } = this.props;
    let suggestList;
    if (fields.from.value) {
      suggestList = list.filter(obj => obj.id !== fields.from.value.id);
    }
    return suggestList;
  }

  handleFiltered(ev) {
    const { availableVehicles } = this.props;
    let newList = availableVehicles.filter(obj => {
      return obj.vehicle.registrationNumber.toUpperCase().indexOf(ev.target.value.toUpperCase()) > -1 ? obj : '';
    });
    this.setState({ availableVehicles: newList });
  }

  handleToggleConfirmDialog(price) {
    this.setState({
      openConfirmDialog: !this.state.openConfirmDialog,
      confirmDialogData: this.state.openConfirmDialog ? {} : price
    });
  }

  handleConfirm() {
    let { dispatch, bookingDetail, bundle } = this.props;

    const confirmationDetails = {
      comment: _get(bookingDetail, 'comment', BOOKING_COMMENT_INIT_VALUE),
      paidBooking: !_get(bookingDetail, 'carSharingInfo.freeOfCharges', !PAID_BOOKING_INIT_VALUE)
    };
    bookingDetail.vehicle.id = this.state.confirmDialogData[1];
    dispatch(confirmBookingEdition(bookingDetail.member, bookingDetail, confirmationDetails, bookingDetail.id))
      .then(() => {
        setTimeout(() => dispatch(getDataVehiclePlanning()), 1000); // wait server to process data
        this.handleClose();
        dispatch(
          addFlashMessage({
            contentKey: 'booking_edit_success',
            type: FLASH_MESSAGE_TYPE_SUCCESS
          })
        );
      })
      .catch(error => {
        dispatch(addErrorMessage({ error, bundle, errorCodePrefixes: ['bookingResult_'] }));
      });
  }

  render() {
    const {
      openedChangeVehicleModal,
      sitesList,
      loadingBookingDetail,
      loadingAvailableVehicles,
      availableVehicles,
      bookingDetail,
      fields: { from, to, pickupDate, pickupDateHour, pickupDateMin, returnDate, returnDateHour, returnDateMin, tripType },
      intl: { formatMessage, messages }
    } = this.props;
    const now = new Date();
    const sitesListEnhanced = enhanceSiteList(sitesList);
    const minReturnDate = pickupDate && typeof pickupDate.value === 'object' ? pickupDate.value : now;

    return (
      <Dialog
        modal={false}
        open={openedChangeVehicleModal}
        onRequestClose={this.handleClose}
        contentStyle={{ width: '600px', padding: 0 }}
        bodyStyle={{ padding: 0 }}
        style={{ padding: 0 }}
      >
        <div className="VehiclePlanningEditBooking">
          <div className="VehiclePlanningEditBooking_container">
            <div className="VehiclePlanningEditBooking_col1">
              {bookingDetail && (
                <div className="user-detail">
                  <span className="user-name">
                    <UserIcon />
                    {bookingDetail.member.firstName + ' ' + bookingDetail.member.lastName}
                  </span>
                  <span className="user-mail" children={bookingDetail.member.login} />
                </div>
              )}
              <div className="VehiclePlanningEditBooking_col1Row1">
                <div className="VehiclePlanningEditBooking_col1Row1Icons">
                  <div className="VehiclePlanningEditBooking_col1Row1Icons_bigDot" />
                  <div className="VehiclePlanningEditBooking_col1Row1Icons_smallDot" />
                  <div className="VehiclePlanningEditBooking_col1Row1Icons_smallDot" />
                  <div className="VehiclePlanningEditBooking_col1Row1Icons_smallDot" />
                  <LocationIcon />
                </div>
                <AddressAutocomplete field={from} fixtures={sitesListEnhanced} placeholderKey={false} disabled />
                <AddressAutocomplete
                  field={tripType.value !== ONE_WAY_TRIP ? to : { value: '-' }}
                  fixtures={this.renderSuggestFiltered(sitesListEnhanced)}
                  placeholderKey={false}
                  disabled
                />
                <div className="searchVehicle_tripSelection clearfix">
                  <EkRadio
                    formRowItemKey="backUserForm_userRole"
                    items={[
                      {
                        labelKey: 'search_vehicle_oneWay',
                        value: ONE_WAY_TRIP
                      },
                      {
                        labelKey: 'search_vehicle_return',
                        value: ROUND_TRIP
                      }
                    ]}
                    namespace="tripType"
                    field={tripType}
                    disabled
                  >
                    <FieldErrorMsg field={tripType} customClass="fieldErrorMsg--backUserForm" />
                  </EkRadio>
                </div>
              </div>
              <div className="VehiclePlanningEditBooking_col1Row2 paddingTop0">
                <CalendarIcon />
                <BoxedInput
                  formRowItemKey="searchVehicle_from_pickupDate"
                  skinType="date"
                  type="date"
                  customClass="boxedInputWrapper--label _is_year_hidden VehiclePlanningEditBooking_col1Row2_pickupDate"
                  labelKey="search_vehicle_pickup"
                  field={pickupDate}
                  minDate={now}
                  disabled
                  disableYearSelection
                >
                  <FieldErrorMsg field={pickupDate} customClass="fieldErrorMsg--searchVehicleForm" />
                </BoxedInput>

                <div className="VehiclePlanningEditBooking_col1Row2_dateWrap">
                  <TimeIcon />
                  <BoxedSelect
                    formRowItemKey="searchVehicle_from_pickupDateHour"
                    labelKey="search_vehicle_pickup_hour"
                    options={config.availableHours}
                    customClass="searchVehicle_boxedSelectWrapper"
                    hideLabel
                    disabled
                    field={pickupDateHour}
                  />
                  <span className="VehiclePlanningEditBooking_col1Row2_dvojtecka">:</span>
                  <BoxedSelect
                    formRowItemKey="searchVehicle_from_pickupDateMin"
                    labelKey="search_vehicle_pickup_min"
                    options={config.allAvailableMinutes}
                    customClass="searchVehicle_boxedSelectWrapper"
                    hideLabel
                    disabled
                    field={pickupDateMin}
                  />
                </div>
              </div>
              {tripType.value !== ONE_WAY_TRIP && (
                <div className="VehiclePlanningEditBooking_col1Row2">
                  <CalendarIcon />
                  <BoxedInput
                    formRowItemKey="searchVehicle_to_returnDate"
                    skinType="date"
                    type="date"
                    customClass="boxedInputWrapper--label VehiclePlanningEditBooking_col1Row2_pickupDate"
                    labelKey="search_vehicle_return_date"
                    field={returnDate}
                    minDate={minReturnDate}
                    disabled
                    mandatory={!(tripType.value === ONE_WAY_TRIP)}
                    disableYearSelection
                  >
                    <FieldErrorMsg field={returnDate} customClass="fieldErrorMsg--searchVehicleForm" />
                  </BoxedInput>

                  <div className="VehiclePlanningEditBooking_col1Row2_dateWrap">
                    <TimeIcon />
                    <BoxedSelect
                      formRowItemKey="searchVehicle_from_pickupDateHour"
                      labelKey="search_vehicle_pickup_hour"
                      options={config.availableHours}
                      customClass="searchVehicle_boxedSelectWrapper"
                      hideLabel
                      disabled
                      field={returnDateHour}
                    />
                    <span className="VehiclePlanningEditBooking_col1Row2_dvojtecka">:</span>
                    <BoxedSelect
                      formRowItemKey="searchVehicle_from_pickupDateMin"
                      labelKey="search_vehicle_pickup_min"
                      options={config.allAvailableMinutes}
                      customClass="searchVehicle_boxedSelectWrapper"
                      hideLabel
                      disabled
                      field={returnDateMin}
                    />
                  </div>
                </div>
              )}
            </div>
            <div className="vehiclePlanningChangeVehicle">
              <div className="vehiclePlanning_closeIconWrap">
                {availableVehicles.length > 5 && (
                  <span className="filter">
                    <FilterIcon />
                    <input
                      type="text"
                      id="filter"
                      className="boxedInput input vehicleFilter"
                      onChange={this.handleFiltered}
                      placeholder={messages.booking_detail_vehicle_registration_number}
                    />
                  </span>
                )}
                <IconButton onClick={this.handleClose}>
                  <CloseIcon />
                </IconButton>
              </div>
              {(loadingBookingDetail || loadingAvailableVehicles) && (
                <div className="vehiclePlanningChangeVehicle_loaderWrap">
                  <Loader />
                </div>
              )}
              <div className="vehiclePlanningChangeVehicle-available-list">
                {_map(this.displayVehicles, item => (
                  <div
                    className="vehiclePlanningChangeVehicle_row"
                    key={`${item.vehicle.id}-${item.carSharingInfo.usageType}`}
                    onClick={_partial(this.handleToggleConfirmDialog, [item.carSharingInfo.cost, item.vehicle.id])}
                  >
                    <img src={item.vehicle.pictureUrl} />
                    <div>
                      {item.vehicle.registrationNumber}
                      <br />
                      <span>
                        {item.vehicle.version.model.brand.name} {item.vehicle.version.model.name} ({item.vehicle.color.code})
                      </span>
                      <br />
                      <span>{item.vehicle.transmissionType}</span>
                    </div>
                    <div className="vehiclePlanningChangeVehicle_row_fuelLevel">
                      <span>
                        {_get(item.vehicle, 'fuelLevel.percentage', '')}
                        {_get(item.vehicle, 'fuelLevel', false) && <span>%</span>}
                      </span>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        {this.state.openConfirmDialog && (
          <Dialog
            actions={[
              <FlatButton key="no" label={<FormattedMessage id="common_cancel" />} onClick={this.handleToggleConfirmDialog} />,
              <FlatButton key="yes" label={<FormattedMessage id="common_confirm" />} primary onClick={this.handleConfirm} />
            ]}
            title={formatMessage({ id: 'vehiclePlanning_changeVehicle' })}
            modal={false}
            contentStyle={{ width: '320px' }}
            open={this.state.openConfirmDialog}
            onRequestClose={this.handleToggleConfirmDialog}
          >
            <FormattedMessage id="common_price_per_km" />
            {' : '}
            <FormattedMessage
              id="common_price_with_currency"
              values={{
                amount: valueToString(this.state.confirmDialogData[0].pricePerKm),
                currency: formatMessage({ id: `unit_` + _get(bookingDetail, 'currency') })
              }}
            />{' '}
            <br />
            <FormattedMessage id="common_price_per_duration" />
            {' : '}
            <FormattedMessage
              id="common_price_with_currency"
              values={{
                amount: valueToString(this.state.confirmDialogData[0].estimatedPriceForDuration),
                currency: formatMessage({ id: `unit_` + _get(bookingDetail, 'currency') })
              }}
            />
          </Dialog>
        )}
      </Dialog>
    );
  }
}

VehiclePlanningChangeVehicle.displayName = 'VehiclePlanningChangeVehicle';

VehiclePlanningChangeVehicle.propTypes = {
  action: T.func,
  fields: T.object,
  onCallback: T.func,
  handleSubmit: T.func
};

VehiclePlanningChangeVehicle = reduxForm(
  {
    onSubmitFail: scrollToFirstError,
    form: 'VehiclePlanningChangeVehicle',
    fields: [
      'from',
      'to',
      'pickupDate',
      'pickupDateHour',
      'pickupDateMin',
      'returnDate',
      'returnDateHour',
      'returnDateMin',
      'passengers',
      'tripType',
      'memberTypeId',
      'usageOverride'
    ],
    validate: createValidator({
      from: [notEmpty(), addressOrSite()],
      to: [
        stopValidationIf({
          condition: (props, value) => {
            return !value;
          }
        }),
        addressOrSite()
      ],
      pickupDate: [notEmpty()],
      tripType: [notEmpty()],
      returnDate: [
        stopValidationIf({
          condition: props => {
            return props.form.tripType && props.form.tripType.value === ONE_WAY_TRIP ? props.form.tripType.value : false;
          }
        }),
        notEmpty(),
        dateAfterOrEqualField('pickupDate')
      ]
    })
  },
  state => {
    const {
      vehiclePlanning: {
        openedChangeVehicleModal,
        bookingDetail,
        selectedBookingId,
        loadingBookingDetail,
        loadingAvailableVehicles,
        availableVehicles
      },
      sites: { list },
      form: { VehiclePlanningChangeVehicle }
    } = state;

    return {
      openedChangeVehicleModal,
      sitesList: list,
      bookingDetail,
      selectedBookingId,
      VehiclePlanningChangeVehicle,
      loadingBookingDetail,
      loadingAvailableVehicles,
      availableVehicles
    };
  }
)(VehiclePlanningChangeVehicle);

export default connect((state, props) => {
  const { bookingDetail } = state.vehiclePlanning;
  return {
    initialValues: createInitVehiclePlanning(bookingDetail),
    bundle: bundleSelector(state)
  };
})(injectIntl(VehiclePlanningChangeVehicle));
