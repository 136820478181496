import React, { Component } from 'react';
import ColumnList from '../../../components/_v2/ColumnList';
import { connect } from 'react-redux';

import { createMenuItems, resolveActive, resolveSelected, showNextView } from '../../../components/_v2/ColumnList/helpers';
import { FormattedMessage } from 'react-intl';
import { renderItem } from './helpers';
import { configurationsTotalResultsSelector, userRoleSelector } from '../../../selectors/all-selectors';
import { checkRole } from '../../../constants/backuser-role-rules';
import { configurationsEditRules } from '../../../constants/routes-constants';
import { safe } from '../../../utils/utils';
import { getConfigurationList } from '../../../actions/configuration-actions';

const fetchAmount = 50;

class ConfigurationsList extends Component {
  componentWillMount() {
    this.setCallbacks();
    this.setVars();
  }

  setVars() {
    if (checkRole(configurationsEditRules, this.props.role)) {
      this.menuItems = createMenuItems({
        onAdd: this.handleAdd,
        addKey: 'addConfiguration_title'
      });
    }
  }

  setCallbacks() {
    this.handleEdit = ({ id }, _, { openInNewTab }) => {
      showNextView.call(this, 'configurationEdit', ':configurationId', id, openInNewTab);
    };

    this.handleAdd = () => {
      showNextView.call(this, 'configurationAdd');
    };

    this.resolveSelected = resolveSelected.bind(this);
    this.resolveActive = resolveActive.bind(this);

    this.fetchListData = params => {
      this.props.dispatch(getConfigurationList({ ...params }));
    };

    this.getMoreResults = () => {
      if (this.props.loading) return;

      const { results, totalResults } = this.props;
      const currResults = safe(() => results.length) || 0;

      if (totalResults && totalResults > currResults) {
        const diff = totalResults - currResults;
        const increase = fetchAmount > diff ? diff : fetchAmount;
        const size = currResults + increase;

        this.fetchListData({ page: { number: 1, size } });
      }
    };
  }

  render() {
    return (
      <ColumnList
        title={<FormattedMessage id="configurations-list" />}
        inputRef={this.props.inputRef}
        items={this.props.results}
        renderItem={renderItem}
        totalResults={this.props.totalResults}
        onScrollToBottom={this.getMoreResults}
        onSelect={this.handleEdit}
        selected={this.resolveSelected}
        active={this.resolveActive}
        menuItems={this.menuItems}
        localSearchEnabled
      />
    );
  }
}

const mapStateToProps = state => {
  const totalResults = configurationsTotalResultsSelector(state);
  const { results, metadata } = state.configuration.paginatedResults;
  const { loadingList: loading } = state.configuration;
  return { totalResults, loading, metadata, results, role: userRoleSelector(state) };
};

ConfigurationsList = connect(mapStateToProps)(ConfigurationsList);

export default ConfigurationsList;
