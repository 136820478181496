import React, { Component, PropTypes } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import _get from 'lodash/get';
import _isFunction from 'lodash/isFunction';
import autoBind from 'react-autobind';
import {
  accountStripeSubCompanySelector,
  adjustAddressFormatted,
  adjustInvoiceAddressFormatted
} from '../../../../selectors/all-selectors';
import IsGranted from '../../../../components/IsGranted/IsGranted';
import SubCompanyForm from '../SubCompanyForm/SubCompanyForm';
import routes, { editSubCompanyRules } from '../../../../constants/routes-constants';
import BackLink from '../../../../components/BackLink/BackLink';
import { BACKUSER_ROLE_CALL_CENTER_OPERATOR, BACKUSER_ROLE_FLEET_MANAGER } from '../../../../constants/backend-constants';
import { apiParams } from '../../../../constants/api-params-constants';
import { addErrorMessage, namedCompose, safe, trimValues } from '../../../../utils/utils';
import classNames from 'classnames';
import {
  clearSubCompanyInvoice,
  deleteSubCompanyEmailSetup,
  pullSubCompanyEmailSetup,
  previewSubCompanyInvoice,
  setSubCompaniesTabIndex,
  setSubCompanyEmailSetup,
  subCompanyClearPaybackSettingsData,
  subCompanyClearTemporaryBankDetails,
  subCompanyCreateBankoutConfigAction,
  subCompanyGetPaybackSettingsAction,
  subCompanyUpdateBankDetails,
  subCompanyUpdateBankoutConfigAction,
  updateSubCompanyInvoiceData,
  updateSubCompanyRequest,
  updateSubCompanySuccess,
  createSubConnectedAccount,
  supressSubConnectedAccount,
  stripeSubAccountLinkRefresh,
  getSubCompanyConnectedAccount
} from '../../../../actions/subCompanies-actions';
import _isEmpty from 'lodash/isEmpty';
import CompanyInvoiceParamsForm from '../../../../components/CompanyInvoiceParamsForm/CompanyInvoiceParamsForm';
import { isGranted } from '../../../../actions/user-actions';
import PdfModal from '../../../../components/PdfModal/PdfModal';
import moment from 'moment';
import { saveAs } from 'file-saver';
import {
  checkRole,
  subCompanyPaybackBankDetailsEditRules,
  subCompanyPaybackConfigTabRules,
  subCompanyPaybackSettingsEditRules
} from '../../../../constants/backuser-role-rules';
import PaybackSettingsTab from '../../../../components/PaybackSettingsTab/PaybackSettingsTab';
import { getStore } from '../../../../store/all-store';
import { COMPANY_EMAIL_SETUP_FORM, COMPANY_PAYBACK_SETTINGS_FORM } from '../../../../constants/form-constants';
import {
  COMPANY_TYPE_SUB,
  FLASH_MESSAGE_TYPE_ERROR,
  FLASH_MESSAGE_TYPE_SUCCESS,
  STORAGE_KEY
} from '../../../../constants/generic-constants';
import { iconStyles } from '../../../../constants/style-constants';
import PanoramaFishEyeIcon from 'material-ui/svg-icons/image/panorama-fish-eye';
import { addFlashMessage, successMessage } from '../../../../actions/flashMessage-actions';
import CompanyEmailSetupForm from '../../../../containers/CompanyDetail/components/CompanyEmailSetupForm';
import { reset } from 'redux-form';
import { fileUploadResetState } from '../../../../actions/file-actions';
import _values from 'lodash/values';
import { partialErrorCodes } from '../../../../constants/options-constants';

class SubCompanyDetail extends Component {
  constructor(props) {
    super(props);
    autoBind(this);

    this.setTabsData();
    this.derivedStateFromProps(props, true);
  }

  // noinspection JSCheckFunctionSignatures
  componentWillReceiveProps(nextProps) {
    this.derivedStateFromProps(nextProps);
  }

  derivedStateFromProps(props, init) {
    this.updateReadOnly(props);
    this.updatePaybackReadOnly(props, init);
    this.handleLegacyPaymentVariables(props);
  }

  updatePaybackReadOnly({ role: userRole, firstPayback }, init) {
    const { role: prevRole } = this.props;

    if (init || userRole !== prevRole) {
      const canEditSettings = checkRole(subCompanyPaybackSettingsEditRules, userRole);

      this.bankDetailsReadOnly = !checkRole(subCompanyPaybackBankDetailsEditRules, userRole);
      this.paybackSettingsReadOnly = !canEditSettings;
      this.showPaybackSubmit = !this.bankDetailsReadOnly || !this.paybackSettingsReadOnly;

      if (firstPayback) {
        this.bankDetailsReadOnly = !canEditSettings;
        this.showPaybackSubmit = canEditSettings;
      }
    }
  }

  addBankDetailsCallback() {
    const store = getStore();
    const state = store.getState();
    const companyState = state.subCompanies;
    const { currentTabIndex } = companyState;

    const values = trimValues(state.form[COMPANY_PAYBACK_SETTINGS_FORM]);

    const paybackData = JSON.stringify({
      values,
      currentTabIndex,
      type: COMPANY_TYPE_SUB
    });

    localStorage.setItem(STORAGE_KEY.PAYBACK_DATA, paybackData);
  }

  updatePayback() {
    const { dispatch, subCompanyId } = this.props;
    const supressError = () => '';

    dispatch(subCompanyGetPaybackSettingsAction(subCompanyId, true)).then(supressError, supressError);
  }

  submitPayback() {
    const { firstPayback, dispatch, role: userRole } = this.props;

    const canUpdateSettings = checkRole(subCompanyPaybackSettingsEditRules, userRole);

    const handleCreateOk = () => {
      dispatch(subCompanyClearPaybackSettingsData());
      this.updatePayback();
    };

    const supressError = () => '';

    const updateSettingsAction = () => dispatch(subCompanyUpdateBankoutConfigAction()).then(supressError, supressError);
    const clearBankDetailsAction = () => dispatch(subCompanyClearTemporaryBankDetails());

    if (firstPayback) {
      if (canUpdateSettings) dispatch(subCompanyCreateBankoutConfigAction()).then(handleCreateOk, supressError);
    } else {
      dispatch(subCompanyUpdateBankDetails()).then(() => {
        if (canUpdateSettings) {
          updateSettingsAction();
        } else {
          dispatch(successMessage);
          clearBankDetailsAction();
        }
      }, supressError);
    }
  }

  createAccountCallback() {
    const { dispatch } = this.props;
    dispatch(createSubConnectedAccount());
  }

  stripeRefreshLink() {
    const { dispatch } = this.props;
    dispatch(stripeSubAccountLinkRefresh());
  }

  deleteAccount() {
    const { dispatch, subCompanyId } = this.props;
    dispatch(supressSubConnectedAccount(subCompanyId)).then(() => dispatch(getSubCompanyConnectedAccount(subCompanyId)));
  }

  paybackSettings() {
    const { currentCompanyDetails, currencies } = this.props;
    const { currency, paymentProvider } = currentCompanyDetails || {};

    return (
      <PaybackSettingsTab
        sectionClassName="subCompanyDetail"
        addBankDetailsCallback={this.addBankDetailsCallback}
        submitPayback={this.submitPayback}
        companyId={this.props.subCompanyId}
        paybackData={this.props.paybackData}
        bankAccountDetails={this.props.bankAccountDetails}
        firstPayback={this.props.firstPayback}
        paybackFormValues={this.props.paybackFormValues}
        showSubmit={this.showPaybackSubmit}
        parentId={currentCompanyDetails.id}
        bankDetailsReadOnly={this.bankDetailsReadOnly}
        paybackSettingsReadOnly={this.paybackSettingsReadOnly}
        currencies={currencies}
        currency={currency}
        paymentProvider={paymentProvider}
        createAccountCB={this.createAccountCallback}
        deleteAccountCB={this.deleteAccount}
        refreshLinkCB={this.stripeRefreshLink}
      />
    );
  }

  updateReadOnly(props) {
    const { role } = props;
    const needsUpdate = role !== this.props.role || this.readOnly === undefined;
    if (needsUpdate) this.readOnly = role ? !checkRole(editSubCompanyRules, role) : false;
  }

  setTabsData() {
    this.tabsData = [];

    this.addTab({
      titleKey: 'common_company',
      displayTab: this.companyDetails
    });

    this.addTab({
      titleKey: 'company_detail_tab_email_setup',
      displayTab: this.emailSetupSection
    });

    if (isGranted({ disallowed: [BACKUSER_ROLE_FLEET_MANAGER, BACKUSER_ROLE_CALL_CENTER_OPERATOR] })) {
      this.addTab({
        titleKey: 'company_detail_tab_invoices_params',
        displayTab: this.invoiceTab
      });

      if (isGranted({ allowed: subCompanyPaybackConfigTabRules.include, disallowed: subCompanyPaybackConfigTabRules.exclude })) {
        this.addTab({
          titleKey: 'company_detail_tab_payback_settings',
          displayTab: this.paybackSettings
        });
      }
    }
  }

  addTab(params) {
    this.tabsData[this.tabsData.length] = {
      ...params,
      handleClick: this.handleChangeTabIndex.bind(this, this.tabsData.length)
    };
  }

  handleChangeTabIndex(index, e) {
    e.preventDefault();
    this.props.dispatch(setSubCompaniesTabIndex(index));
  }

  handleSaveInvoice() {
    this.props.dispatch(updateSubCompanyInvoiceData());
  }

  handleInvoicePreview() {
    this.props.dispatch(previewSubCompanyInvoice());
  }

  handleCloseInvoicePreview() {
    this.props.dispatch(clearSubCompanyInvoice());
  }

  handleSaveEmailSetup() {
    const { dispatch } = this.props;

    dispatch(setSubCompanyEmailSetup()).then(() => {
      dispatch(reset(COMPANY_EMAIL_SETUP_FORM));
      dispatch(fileUploadResetState());
    });
  }

  handleDeleteEmailSetup() {
    const { currentSubCompany, dispatch } = this.props;
    const companyId = _get(currentSubCompany, 'id');

    function displayError() {
      return dispatch(
        addFlashMessage({
          contentKey: 'error_server_unknown',
          type: FLASH_MESSAGE_TYPE_ERROR
        })
      );
    }

    function handleSuccess() {
      dispatch(reset(COMPANY_EMAIL_SETUP_FORM));

      return dispatch(
        addFlashMessage({
          contentKey: 'company_email_setup_delete_success',
          type: FLASH_MESSAGE_TYPE_SUCCESS
        })
      );
    }

    dispatch(deleteSubCompanyEmailSetup()).then(
      () => {
        return dispatch(pullSubCompanyEmailSetup(companyId)).then(handleSuccess, displayError);
      },
      error => {
        if (_get(error, 'status') === 404) {
          return dispatch(pullSubCompanyEmailSetup(companyId)).then(handleSuccess, displayError);
        } else return displayError();
      }
    );
  }

  handleDownloadInvoicePreview() {
    const {
      invoicePreview,
      intl: { formatMessage }
    } = this.props;

    const blob = _get(invoicePreview, 'blob');
    const name = formatMessage({ id: 'company_invoice_preview' });
    const at = formatMessage({ id: 'common_at' });
    const now = moment();
    const date = now.format('DD-MM-YYYY');
    const time = now.format('HH.mm.ss');

    if (blob) saveAs(blob, `${name}_${date}_${at}_${time}.pdf`);
  }

  invoiceTab() {
    const { detailInvoiceParams, invoiceVatRateParam, currentSubCompany, invoicePreview, currencies, currentCompanyDetails } = this.props;

    const pdf = _get(invoicePreview, 'base64');
    const invoicingAddress = _get(detailInvoiceParams, 'invoicingAddress');

    return (
      <section className="subCompanyDetail">
        <CompanyInvoiceParamsForm
          onPreview={this.handleInvoicePreview}
          onFormSubmit={this.handleSaveInvoice}
          currencies={currencies}
          readOnly={this.readOnly}
          readOnlyPreview={this.readOnly}
          readOnlyCurrency
          initialValues={{
            companyCapital: _get(detailInvoiceParams, 'companyCapital'),
            invoicingDelegation: String(!!_get(detailInvoiceParams, 'invoicingDelegation')),
            legalForm: _get(detailInvoiceParams, 'legalForm'),
            fiscalNumber: _get(detailInvoiceParams, 'fiscalNumber'),
            vatCode: _get(detailInvoiceParams, 'vatCode'),
            invoiceNumberSuffix: _get(detailInvoiceParams, 'invoiceNumberSuffix'),
            paymentConditionsLabel: _get(detailInvoiceParams, 'paymentConditionsLabel'),
            currency: _get(currentCompanyDetails, 'currency'),
            templateModel: _get(detailInvoiceParams, 'templateModel'),
            invoicingAddress: _isEmpty(invoicingAddress) ? _get(currentSubCompany, 'address') : invoicingAddress,
            postalCode: _isEmpty(invoicingAddress) ? _get(currentSubCompany, 'address.postalCode') : invoicingAddress.postalCode,
            city: _isEmpty(invoicingAddress) ? _get(currentSubCompany, 'address.city') : invoicingAddress.city,
            country: _isEmpty(invoicingAddress) ? _get(currentSubCompany, 'address.country') : invoicingAddress.country,
            italianRea: _get(detailInvoiceParams, 'italianRea'),
            vatRate: _get(invoiceVatRateParam, 'vatRate'),
            companyCourt: _get(detailInvoiceParams, 'companyCourt'),
            companyRegisterNumber: _get(detailInvoiceParams, 'companyRegisterNumber'),
            dataProcessingRegistry: _get(detailInvoiceParams, 'dataProcessingRegistry'),
            companyName: _get(detailInvoiceParams, 'companyName'),
            invoiceNumberPrefix: _get(detailInvoiceParams, 'italianInvoicingParameters.numberPrefix'),
            taxIdCode: _get(detailInvoiceParams, 'italianInvoicingParameters.taxIdentificationCode'),
            socioUnico: _get(detailInvoiceParams, 'italianInvoicingParameters.socioUnico'),
            memberCode: _get(detailInvoiceParams, 'italianInvoicingParameters.memberCode'),
            fiscalScheme: _get(detailInvoiceParams, 'italianInvoicingParameters.fiscalScheme'),
            ufficio: _get(detailInvoiceParams, 'italianInvoicingParameters.ufficio'),
            statusLiquidation: _get(detailInvoiceParams, 'italianInvoicingParameters.statusLiquidation'),
            firstInvoiceNumber: _get(detailInvoiceParams, 'italianInvoicingParameters.numberCounterInit'),
            useExternalInvoiceSystem:
              _get(detailInvoiceParams, 'externalInvoicing') || _get(currentCompanyDetails, 'useExternalInvoiceSystem'),
            credentialsProvided: _get(detailInvoiceParams, 'italianInvoicingParameters.credentialsProvided', false)
          }}
        />
        <PdfModal show={!!pdf} src={pdf} onClose={this.handleCloseInvoicePreview} onDownload={this.handleDownloadInvoicePreview} />
      </section>
    );
  }

  emailSetupSection() {
    const { customizations } = this.props;

    return (
      <section className="companyDetail">
        <CompanyEmailSetupForm
          readOnly={this.readOnly}
          onSave={this.handleSaveEmailSetup}
          onDelete={this.handleDeleteEmailSetup}
          initialValues={{
            emailLogo: _get(customizations, 'logo', ''),
            colorPrimary: _get(customizations, 'colour1', ''),
            colorSecondary: _get(customizations, 'colour2', ''),
            linkedinLink: _get(customizations, 'linkedinLink', ''),
            twitterLink: _get(customizations, 'twitterLink', ''),
            homeDeeplink: _get(customizations, 'homeDeeplink', ''),
            productName: _get(customizations, 'productName', ''),
            senderEmail: _get(customizations, 'senderEmail', ''),
            senderSms: _get(customizations, 'senderSms', '')
          }}
        />
      </section>
    );
  }

  handleUpdateSubCompany() {
    const { dispatch, currentSubCompany, bundle } = this.props;

    dispatch(updateSubCompanyRequest(currentSubCompany.id)).then(
      subCompany => {
        dispatch(updateSubCompanySuccess(subCompany));
        dispatch(
          addFlashMessage({
            contentKey: 'subCompanyForm_update_subCompany_success',
            type: FLASH_MESSAGE_TYPE_SUCCESS,
            delayToNextRoute: true
          })
        );
      },
      error => {
        const partialErrors = _values(partialErrorCodes.phoneNumbers);
        const msgParams = { bundle, partialErrors, error, def: 'subCompanyForm_update_subCompany_error' };

        dispatch(addErrorMessage(msgParams));
      }
    );
  }

  companyDetails() {
    const { currentSubCompany } = this.props;

    return (
      <div>
        <SubCompanyForm
          onCallback={this.handleUpdateSubCompany}
          readOnly={this.readOnly}
          initialValues={{
            name: safe(() => currentSubCompany.name),
            address: safe(() => currentSubCompany.address),
            postalCode: safe(() => currentSubCompany.address.postalCode),
            city: safe(() => currentSubCompany.address.city),
            country: safe(() => currentSubCompany.address.country),
            email: safe(() => currentSubCompany.email),
            secondaryEmail: safe(() => currentSubCompany.secondaryEmail),
            subCompanyId: safe(() => currentSubCompany.subCompanyId),
            phoneNumber: {
              phonePrefix: safe(() => currentSubCompany.phoneNumber.countryCode),
              phoneSuffix: safe(() => currentSubCompany.phoneNumber.nationalNumber),
              valid: true
            },
            secondaryPhoneNumber: {
              phonePrefix: safe(() => currentSubCompany.secondaryPhoneNumber.countryCode),
              phoneSuffix: safe(() => currentSubCompany.secondaryPhoneNumber.nationalNumber),
              valid: true
            },
            configurationId: safe(() => currentSubCompany.configurationId),
            agencyCode: safe(() => currentSubCompany.agencyCode),
            logoUrl: safe(() => currentSubCompany.logoUrl),
            externalId: safe(() => currentSubCompany.externalId)
          }}
          subCompanyId={safe(() => currentSubCompany.id)}
          isDisplayForm
        />
      </div>
    );
  }

  legacyPaymentText() {
    return (
      <div className="subCompanyDetail_text">
        {<div className="payment-icon">{this.legacyPaymentTextIcon}</div>}
        <FormattedMessage id={this.legacyPaymentTextId} />
      </div>
    );
  }

  handleLegacyPaymentVariables(props) {
    const {
      legacyPaymentDetails,
      intl: { formatMessage }
    } = props;

    const { status: paymentStatus } = legacyPaymentDetails || {};

    this.legacyPaymentTextId = paymentStatus ? 'subCompanies_paymentDetails_existing' : 'subCompanies_paymentDetails_empty';
    this.legacyPaymentTextIcon = paymentStatus ? <div className="circle-outline" /> : <PanoramaFishEyeIcon style={iconStyles.all} />;

    this.paymentLabel = paymentStatus
      ? formatMessage({ id: 'subCompanies_paymentDetails_replace' })
      : formatMessage({ id: 'subCompanies_paymentDetails_add' });
  }

  companyDetailTabs() {
    const { currentTabIndex } = this.props;

    return (
      <div className="subCompanyDetail_tabs">
        {this.tabsData.map((data, index) => (
          <div
            className={classNames({
              companyDetail_tabItem: true,
              __is_selected: currentTabIndex === index
            })}
            key={'tab-' + index}
            onClick={data.handleClick}
          >
            <FormattedMessage id={data.titleKey} />
          </div>
        ))}
      </div>
    );
  }

  selectTab() {
    const { currentTabIndex } = this.props;
    const dataFunction = _get(this.tabsData[currentTabIndex], 'displayTab');
    const key = 'companyTab_' + currentTabIndex;
    if (_isFunction(dataFunction)) return <div key={key}>{dataFunction()}</div>;
  }

  getBackLink() {
    const params = this.props.urlParams || apiParams.default;
    return `/#${routes.subCompanies.path.replace(':search', encodeURIComponent(JSON.stringify(params)))}`;
  }

  render() {
    return (
      <div className="subCompanyDetailPage mainContainer_content">
        <div className="pageContainer">
          <div className="subCompanyDetailPage_content">
            {!this.props.isModal && (
              <IsGranted disallowed={[BACKUSER_ROLE_FLEET_MANAGER]}>
                <BackLink link={this.getBackLink()} labelKey="back_link_companies_list" />
              </IsGranted>
            )}
            {this.companyDetailTabs()}
            {this.selectTab()}
          </div>
        </div>
      </div>
    );
  }
}

SubCompanyDetail.propTypes = {
  isModal: PropTypes.bool
};

export default namedCompose(
  connect(state => {
    const detailInvoiceParams = adjustInvoiceAddressFormatted(state);
    const {
      user: {
        userInfo: { role, locale }
      },
      subCompanies: {
        currentSubCompany,
        urlParams,
        currentTabIndex,
        invoiceVatRateParam,
        invoicePreview,
        paybackData,
        paybackFormValues,
        legacyPaymentDetails,
        bankAccountDetails,
        customizations
      },
      configuration: { allConfigurations },
      companies: { currencies, currentCompanyDetails }
    } = state;

    const { id: subCompanyId } = currentSubCompany || {};
    const firstPayback = !paybackData;

    return {
      currentSubCompany: adjustAddressFormatted(currentSubCompany),
      role,
      locale,
      urlParams,
      allConfigurations,
      currentTabIndex,
      detailInvoiceParams,
      invoiceVatRateParam,
      invoicePreview,
      currencies,
      currentCompanyDetails,
      subCompanyId,
      paybackData,
      customizations,
      bankAccountDetails,
      firstPayback,
      legacyPaymentDetails,
      paybackFormValues,
      connectedAccount: accountStripeSubCompanySelector(state)
    };
  }),
  injectIntl
)(SubCompanyDetail);
