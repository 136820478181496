import React, { Component } from 'react';
import { connect } from 'react-redux';
import { addOpenNewTabEvents, hideButtonHoverMsg, namedCompose, openUrlBackgroundTab } from '../../utils/utils';
import { injectIntl } from 'react-intl';
import VehicleStatusesReportForm from './components/VehicleStatusesReportForm';

import {
  exportVehicleStatuses,
  setVehicleStatusesDates,
  getVehicleStatusesList,
  toggleReportFormModal,
  vehicleStatusesClear,
  getStatusesReport
} from '../../actions/vehicleStatuses-actions';

import autoBind from 'react-autobind';
import AdvancedList from '../../components/AdvancedList/AdvancedList';
import { Col, Row } from 'react-bootstrap';
import _get from 'lodash/get';
import BookingListIcon from '../../components/BookingListIcon/BookingListIcon';
import { BOOKING_TYPE_RIDE_SHARING, SORT_PROPERTY_PLATE_NUMBER } from '../../constants/backend-constants';
import { routeActions } from 'react-router-redux';
import routes from '../../constants/routes-constants';
import { apiParams } from '../../constants/api-params-constants';
import VehicleStatusesPie from './components/VehicleStatusesPie';
import ArrowIcon from 'material-ui/svg-icons/navigation/arrow-forward';

class VehicleStatuses extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.setListData();
  }

  componentWillUnmount() {
    this.props.dispatch(vehicleStatusesClear());
    hideButtonHoverMsg();
  }

  setListData() {
    this.setColProps();
    this.setHead();
  }

  setColProps() {
    this.colProps = {
      plateNumber: {
        md: 12,
        xs: 12
      }
    };
  }

  setHead() {
    this.headerParams = [
      {
        labelKey: 'fleet_tableView_label_registrationNumber',
        sortId: SORT_PROPERTY_PLATE_NUMBER,
        sortable: true,
        ...this.colProps.plateNumber
      }
    ];
  }

  handleToggleExport() {
    this.props.dispatch(toggleReportFormModal());
  }

  onSubmitExport(data) {
    this.props.dispatch(exportVehicleStatuses(data));
    this.handleToggleExport();
  }

  onCloseExport() {
    this.handleToggleExport();
  }

  handleOpenExport() {
    this.props.dispatch(setVehicleStatusesDates());
    this.handleToggleExport();
  }

  getCarImage(item) {
    const src = _get(item, 'pictureUrl', false);

    if (src) {
      return <img alt="" src={item.pictureUrl} className="fleetPage_listImage" />;
    } else {
      return <BookingListIcon statusIcon={false} type={BOOKING_TYPE_RIDE_SHARING} status={item.status} />;
    }
  }

  getPieData(vehicleId, openInNewTab) {
    if (openInNewTab) {
      const vehiclePage = '/#' + routes.vehicleDetail.path.replace(':vehicleId', vehicleId);
      return openUrlBackgroundTab(vehiclePage);
    }
    const { vehicleStatusesDates } = this.props;
    const params = { vehicleId, body: vehicleStatusesDates };
    this.props.dispatch(getStatusesReport(params));
  }

  getArrow(show) {
    if (show) {
      return (
        <div className="vehicle-statuses-arrow">
          <ArrowIcon />
        </div>
      );
    }
  }

  renderItem(item, id, reactKey) {
    const {
      intl: { formatMessage },
      selectedPieData
    } = this.props;

    const unknown = formatMessage({ id: 'common_unknown' });
    const fuelType = _get(item, 'fuelType');

    const plateNumber = _get(item, 'registrationNumber') || unknown;
    const fuelTypeLabel = fuelType ? formatMessage({ id: 'vehicle_fuelType_' + fuelType }) : unknown;

    const itemId = _get(item, 'id');
    const pieId = _get(selectedPieData, 'id');

    const callback = openInNewTab => this.getPieData(itemId, openInNewTab);

    return (
      <div className="advancedList_rowWrap" key={reactKey}>
        <Row className="advancedList_row" id={id} {...addOpenNewTabEvents(callback)}>
          <Col {...this.colProps.plateNumber}>
            <div className="bookingsPage_firstItemWrap">
              {this.getCarImage(item)}
              <div>
                <div className="bookingsPage_doubleColumnWrap">{plateNumber}</div>
                <div className="firstLetterUppercase">{fuelTypeLabel}</div>
              </div>
            </div>
          </Col>
          {this.getArrow(itemId === pieId)}
        </Row>
      </div>
    );
  }

  handleChangePage(value) {
    const { metadata, urlParams } = this.props;

    const size = _get(metadata, 'paginationInfo.pageSize');
    const def = apiParams.default.page.size;

    const params = {
      page: {
        number: value,
        size: size || def
      }
    };

    const newParams = { ...urlParams, ...params };
    this.callApi(newParams);
    this.setUrl(newParams);
  }

  setUrl(params) {
    const encoded = encodeURIComponent(JSON.stringify(params));
    const route = routes.vehicleStatuses.path.replace(':search', encoded);
    this.props.dispatch(routeActions.push(route));
  }

  handleRefreshList() {
    const { urlParams } = this.props;
    this.callApi(urlParams);
  }

  handlePageSizeSelect(value) {
    const { urlParams } = this.props;

    let params = {
      page: {
        number: 1,
        size: value
      }
    };

    const newParams = { ...urlParams, ...params };
    this.callApi(newParams);
    this.setUrl(newParams);
  }

  handleSort(sortPropertyName) {
    const { urlParams } = this.props;
    const currentProperty = _get(urlParams, 'sort.property') === sortPropertyName;
    const toggleCurrentProperty = !_get(urlParams, 'sort.isDescending');

    const newParams = {
      ...urlParams,
      sort: {
        isDescending: currentProperty ? toggleCurrentProperty : false,
        property: sortPropertyName
      }
    };

    this.callApi(newParams);
    this.setUrl(newParams);
  }

  callApi(params) {
    const { dispatch } = this.props;
    dispatch(getVehicleStatusesList(params));
  }

  getStatusesList() {
    const { results, metadata, urlParams, loadingList, loadingError, selectedPieData } = this.props;

    return (
      <AdvancedList
        id="vehicle-statuses-list"
        data={results}
        listMetadata={metadata}
        loading={loadingList}
        renderItem={this.renderItem}
        onChangePage={this.handleChangePage}
        onRefreshList={this.handleRefreshList}
        onChangeRowsPerPage={this.handlePageSizeSelect}
        onSort={this.handleSort}
        error={loadingError}
        urlParams={urlParams}
        header={this.headerParams}
        renderAnchor={selectedPieData}
      />
    );
  }

  render() {
    const { selectedPieData, vehicleStatusesDates, reportStarted } = this.props;

    return (
      <div className="pageContainer">
        <div className="vehicle-statuses-page">
          <div className="vehicle-statuses-actions">
            <div className="vehicle-statuses-action-buttons">
              <VehicleStatusesReportForm
                handleSubmit={this.onSubmitExport}
                vehicleStatusesDates={vehicleStatusesDates}
                reportStarted={reportStarted}
              />
            </div>
          </div>
          <div className="vehicle-statuses-body">
            {this.getStatusesList()}
            <VehicleStatusesPie data={selectedPieData} />
          </div>
        </div>
      </div>
    );
  }
}

export default namedCompose(
  connect(state => {
    const {
      vehicleStatuses: {
        openedReportFormModal,
        vehicleStatusesDates,
        selectedPieData,
        reportStarted,
        results,
        metadata,
        urlParams,
        loadingList,
        loadingError
      }
    } = state;

    return {
      metadata,
      results,
      openedReportFormModal,
      vehicleStatusesDates,
      loadingList,
      urlParams,
      loadingError,
      selectedPieData,
      reportStarted
    };
  }),
  injectIntl
)(VehicleStatuses);
