import React, { Component, PropTypes } from 'react';
import ListModal from '../../components/_v2/ColumnList/Modal';
import { getEditModalTitle } from '../../components/_v2/ColumnList/helpers';
import SiteDetails from './SiteDetails';
import { getMsg } from '../../utils/IntlGlobalProvider';

export class SiteDetailsModal extends Component {
  getTitle() {
    return getEditModalTitle(getMsg('common_site'));
  }

  render() {
    return (
      <ListModal title={this.getTitle()} isOpen={this.props.isOpen} onClose={this.props.onClose}>
        <SiteDetails isModal onCancel={this.props.onClose} onSubmit={this.props.onClose} />
      </ListModal>
    );
  }
}

ListModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func
};

export default SiteDetailsModal;
