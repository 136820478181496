// noinspection ES6CheckImport
import React, { Component, PropTypes } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { reduxForm } from 'redux-form';
import _ from 'lodash';

import FormRow from '../FormRow/FormRow';
import EkButton from '../EkButton/EkButton';
import EkRadio from '../EkRadio/EkRadio';

import { resetPasswordDeliveryOptions } from '../../constants/options-constants';
import { scrollToFirstError } from '../../utils/utils';

class ResetPasswordModalForm extends Component {
  render() {
    const {
      handleSubmit,
      onCallback,
      onAbort,
      fields: { resetPasswordDeliveryMode }
    } = this.props;
    const defaultFormName = 'member_resetForm';

    return (
      <form action="#" onSubmit={handleSubmit(onCallback)}>
        <FormRow>
          <EkRadio
            formRowItemKey="memberDetail_resetPasswordDelivery"
            items={resetPasswordDeliveryOptions}
            namespace={_.get(this.props, 'formName', defaultFormName)}
            titleKey="resetPassword_deliveryMode"
            field={resetPasswordDeliveryMode}
          />
          <div />
        </FormRow>

        <EkButton
          id={_.get(this.props, 'formName', defaultFormName) + '_cancel_button'}
          type="button"
          skinType="reverse"
          customClass="resetPasswordModal_abort_button"
          onAction={onAbort}
        >
          <FormattedMessage id="members_detail_abort_button" />
        </EkButton>

        <EkButton
          id={_.get(this.props, 'formName', defaultFormName) + '_push_button'}
          type="button"
          customClass="resetPasswordModal_confirm_button"
          onAction={handleSubmit(onCallback)}
        >
          <FormattedMessage id="resetPassword_forgotPasswordBtn" />
        </EkButton>
      </form>
    );
  }
}

ResetPasswordModalForm.displayName = 'ResetPasswordModalForm';

ResetPasswordModalForm.propTypes = {
  fields: PropTypes.object,
  handleSubmit: PropTypes.func,
  onCallback: PropTypes.func,
  onAbort: PropTypes.func,
  formName: PropTypes.string
};

ResetPasswordModalForm = reduxForm({
  onSubmitFail: scrollToFirstError,
  form: 'resetPasswordModalForm',
  fields: ['resetPasswordDeliveryMode']
})(ResetPasswordModalForm);

export default connect(state => {
  const {
    members: { resetPasswordDeliveryMode }
  } = state;

  return {
    resetPasswordDeliveryMode
  };
})(ResetPasswordModalForm);
