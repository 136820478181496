import {
  VEHICLE_STATUSES_CLEAR_LIST_DATA,
  VEHICLE_STATUSES_GET_FLEET_ERROR,
  VEHICLE_STATUSES_GET_FLEET_REQUEST,
  VEHICLE_STATUSES_GET_FLEET_SUCCESS,
  VEHICLE_STATUSES_GET_PIE_DATA_ERROR,
  VEHICLE_STATUSES_GET_PIE_DATA_REQUEST,
  VEHICLE_STATUSES_GET_PIE_DATA_SUCCESS,
  VEHICLE_STATUSES_GET_EXPORT_REQUEST,
  VEHICLE_STATUSES_GET_EXPORT_ERROR,
  VEHICLE_STATUSES_SET_DATES,
  VEHICLE_STATUSES_SET_START_DATE,
  VEHICLE_STATUSES_SET_END_DATE,
  VEHICLE_STATUSES_TOGGLE_REPORT_MODAL
} from '../constants/actionTypes-constants';
import { pollingStatusesExport } from './all-actions';
import moment from 'moment';
import { append, getLocalDate, selectCompanyId, selectSubCompanyId, trySet } from '../utils/utils';
import { VEHICLE_STATUSES_REPORT_DATE_FORMAT } from '../constants/backend-constants';
import _get from 'lodash/get';
import _pick from 'lodash/pick';
import callApi from './api-actions';
import _forEach from 'lodash/forEach';
import _isEmpty from 'lodash/isEmpty';

export function setVehicleStatusesDates() {
  return {
    type: VEHICLE_STATUSES_SET_DATES
  };
}

export function setVehicleStatusesStartDate(date) {
  return {
    type: VEHICLE_STATUSES_SET_START_DATE,
    startDate: date
  };
}

export function setVehicleStatusesEndDate(date) {
  return {
    type: VEHICLE_STATUSES_SET_END_DATE,
    endDate: date
  };
}

export function toggleReportFormModal() {
  return {
    type: VEHICLE_STATUSES_TOGGLE_REPORT_MODAL
  };
}

export function getVehicleStatusesDates() {
  return {
    startDate: moment().toDate(),
    endDate: moment()
      .add(1, 'months')
      .toDate()
  };
}

export function exportVehicleStatuses(data) {
  return (dispatch, getState) => {
    const state = getState();
    const paramsObj = {};

    const dateFormat = VEHICLE_STATUSES_REPORT_DATE_FORMAT;

    function trySetDate(source, path, dest) {
      const value = _get(source, path);
      const beDate = getLocalDate(value, dateFormat);
      trySet(dest, path, beDate);
    }

    trySetDate(data, 'startDate', paramsObj);
    trySetDate(data, 'endDate', paramsObj);

    trySet(paramsObj, 'companyIds', append([selectCompanyId(state)]));
    trySet(paramsObj, 'subCompanyIds', append([selectSubCompanyId(state)]));

    if (!_isEmpty(paramsObj)) {
      dispatch({ type: VEHICLE_STATUSES_GET_EXPORT_REQUEST });

      return dispatch(callApi('vehicles', 'startExportStatuses', { params: paramsObj }, false))
        .then(data => {
          window.localStorage.setItem('idExportStatuses', data);
          dispatch(pollingStatusesExport(data));
        })
        .catch(() => {
          dispatch({
            type: VEHICLE_STATUSES_GET_EXPORT_ERROR
          });
          window.localStorage.removeItem('startExportStatuses');
        });
    }
  };
}

function selectFirstVehicle(dispatch, results, state) {
  let idFound = false;
  const selectedId = _get(state.selectedPieData, 'id');
  const body = getVehicleStatusesDates();

  if (selectedId) {
    _forEach(results, item => {
      const itemId = _get(item, 'id');
      if (itemId === selectedId) {
        idFound = true;
        return false;
      }
    });
  }

  if (!idFound) {
    const firstItem = _get(results, '[0]');
    const vehicleId = _get(firstItem, 'id');

    if (vehicleId) {
      dispatch(getStatusesReport({ vehicleId, body }));
    }
  }
}

export function getVehicleStatusesList(params, loader = false) {
  return (dispatch, getState) => {
    const state = getState();
    const paramsObj = _pick(params, ['page', 'sort']);

    trySet(paramsObj, 'companyIds', append([selectCompanyId(state)]));
    trySet(paramsObj, 'subCompanyIds', append([selectSubCompanyId(state)]));

    dispatch({ type: VEHICLE_STATUSES_GET_FLEET_REQUEST });

    return dispatch(callApi('vehicles', 'getFleet', { params: paramsObj }, loader))
      .then(data => {
        const results = _get(data, 'results', []);
        selectFirstVehicle(dispatch, results, state.vehicleStatuses);
        return dispatch({
          type: VEHICLE_STATUSES_GET_FLEET_SUCCESS,
          metadata: _get(data, 'metadata', {}),
          results: results,
          urlParams: params
        });
      })
      .catch(() =>
        dispatch({
          type: VEHICLE_STATUSES_GET_FLEET_ERROR,
          urlParams: params
        })
      );
  };
}

export function vehicleStatusesClear() {
  return {
    type: VEHICLE_STATUSES_CLEAR_LIST_DATA
  };
}

export function getStatusesReport(data, loader = false) {
  return dispatch => {
    const dateFormat = VEHICLE_STATUSES_REPORT_DATE_FORMAT;

    function trySetDate(source, path, dest) {
      const value = _get(source, path);
      const beDate = getLocalDate(value, dateFormat);
      trySet(dest, path, beDate);
    }

    const vehicleId = _get(data, 'vehicleId');
    let body = {};

    trySetDate(data.body, 'startDate', body);
    trySetDate(data.body, 'endDate', body);

    const params = {
      vehicleId,
      body
    };

    if (vehicleId) {
      dispatch({ type: VEHICLE_STATUSES_GET_PIE_DATA_REQUEST });
      return dispatch(callApi('vehicles', 'getReportStatuses', params, loader)).then(
        resp =>
          dispatch({
            type: VEHICLE_STATUSES_GET_PIE_DATA_SUCCESS,
            data: {
              ...resp,
              id: vehicleId
            }
          }),
        dispatch({ type: VEHICLE_STATUSES_GET_PIE_DATA_ERROR })
      );
    } else return Promise.resolve();
  };
}
