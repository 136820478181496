import React, { Component, PropTypes } from 'react';
import { FlatButton, RaisedButton } from 'material-ui';
import { FormattedMessage } from 'react-intl';
import cs from 'classnames';

export class ListFilters extends Component {
  render() {
    return (
      <div className={cs('list-filters', { 'sc-read-only': this.props.readOnly })}>
        <div className="sc-main-wrap">{this.props.children}</div>
        {!this.props.readOnly && (
          <div className="sc-actions">
            <FlatButton primary className="sc-flat" onClick={this.props.onFiltersReset}>
              <FormattedMessage id="common_reset" />
            </FlatButton>
            <RaisedButton primary className="sc-raised" onClick={this.props.onFiltersApply}>
              <FormattedMessage id="common_apply" />
            </RaisedButton>
          </div>
        )}
      </div>
    );
  }
}

export default ListFilters;

ListFilters.PropTypes = {
  readOnly: PropTypes.bool,
  onFiltersReset: PropTypes.func,
  onFiltersApply: PropTypes.func
};
