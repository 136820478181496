import React, { Component, PropTypes } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import classNames from 'classnames';
import _uniq from 'lodash/uniq';
import TransversalCategoryPricingForm from '../TransversalCategoryPricingForm/TransversalCategoryPricingForm';
import EkButton from '../EkButton/EkButton';
import TimeSlotsForm from '../../components/TimeSlotsForm/TimeSlotsForm';
import CancellationFeesForm from '../CancellationFeesForm/CancellationFeesForm';
import {
  toggleCategoryWidgetOpenedState,
  requestSendTransversalPricing,
  addFlashMessage,
  requestSendTimeSlots,
  requestGetPricingCategoryTransversalPrices,
  requestGetPricingCategoryTimeSlots,
  prepareTimeSlotsToSend,
  setSaveAllTimeSlots,
  setTimeSlotsErrors,
  clearTimeSlotsErrors,
  resetTimeSlotsToSend,
  requestGetCancellationFees,
  saveCancellationFees
} from '../../actions/all-actions';
import { FLASH_MESSAGE_TYPE_SUCCESS, FLASH_MESSAGE_TYPE_ERROR } from '../../constants/generic-constants';
import {
  TIME_SLOT_USAGE_PRIVATE,
  TIME_SLOT_USAGE_BUSINESS,
  TIME_SLOT_TYPE_PRICE_PER_HOUR,
  TIME_SLOT_TYPE_PACKAGE_BETWEEN_DATES,
  TIME_SLOT_TYPE_PACKAGE_FOR_DURATION,
  TIME_SLOT_TAG_DAY_PACKAGE,
  TIME_SLOT_TAG_WEEK_PACKAGE,
  TIME_SLOT_TAG_MONTH_PACKAGE,
  TIME_SLOT_TAG_WEEK_END_PACKAGE
} from '../../constants/backend-constants';
import { newProp, safe, scrollToFirstError } from '../../utils/utils';
import memoizeOne from 'memoize-one';
import { headerContractVehicleValuesSelector, headerCompanyContractSelector } from '../../selectors/all-selectors';

const filterTimeslots = memoizeOne(timeSlots => {
  const initialTimeSlotsPricePerHour = timeSlots.filter(item => {
    return item.type === TIME_SLOT_TYPE_PRICE_PER_HOUR && item.usage === TIME_SLOT_USAGE_PRIVATE;
  });

  const initialTimeSlotsBetweenDates = timeSlots.filter(item => {
    return item.type === TIME_SLOT_TYPE_PACKAGE_BETWEEN_DATES && item.usage === TIME_SLOT_USAGE_PRIVATE;
  });

  const initialTimeSlotsDuration = timeSlots.filter(item => {
    return item.type === TIME_SLOT_TYPE_PACKAGE_FOR_DURATION && item.usage === TIME_SLOT_USAGE_PRIVATE;
  });

  const initialTimeSlotsBusiness = timeSlots.filter(item => {
    return item.type === TIME_SLOT_TYPE_PRICE_PER_HOUR && item.usage === TIME_SLOT_USAGE_BUSINESS;
  });

  return { initialTimeSlotsPricePerHour, initialTimeSlotsBetweenDates, initialTimeSlotsDuration, initialTimeSlotsBusiness };
});

class VehicleCategoryPricingWidget extends Component {
  constructor(props) {
    super(props);
    this.handleToggleOpenedState = this.handleToggleOpenedState.bind(this);
    this.handleCategoryTransversalPricingCallback = this.handleCategoryTransversalPricingCallback.bind(this);
    this.handleBusinessTimeSlotsCallback = this.handleBusinessTimeSlotsCallback.bind(this);
    this.handleCategoryTimeSlotsCallback = this.handleCategoryTimeSlotsCallback.bind(this);
    this.handleCancellationFeesCallback = this.handleCancellationFeesCallback.bind(this);
    this.handleGlobalSave = this.handleGlobalSave.bind(this);
    this.handleSaveCallback = this.handleSaveCallback.bind(this);
    this.memberTypeId = null;
  }

  componentWillMount() {
    this.initProps();
  }

  initProps() {
    this.componentPropsUpdated(this.props);
    this.propsInit = true;
  }

  componentWillReceiveProps(props) {
    this.componentPropsUpdated(props);
  }

  componentPropsUpdated(props) {
    if (newProp.call(this, props, 'openedVehicleCategoryWidgets') || newProp.call(this, props, 'vehicleRules')) {
      if (!props.vehicleRules.vehicleCategory && !this.categoryOpened(props)) {
        this.handleToggleOpenedState(props);
      }
    }
  }

  categoryOpened(props = this.props) {
    return safe(() => props.openedVehicleCategoryWidgets.has(props.category.id));
  }

  handleToggleOpenedState(props = this.props) {
    const { dispatch, category } = props;

    dispatch(clearTimeSlotsErrors());
    dispatch(resetTimeSlotsToSend());

    if (!this.categoryOpened(props)) {
      let getPricingCategoryTransversalPricesPromise = dispatch(requestGetPricingCategoryTransversalPrices(category.id));
      let getPricingCategoryTimeSlotsPromise = dispatch(requestGetPricingCategoryTimeSlots(category.id));
      let getCancellationPenaltiesPromise = dispatch(requestGetCancellationFees(category.id));

      Promise.all([getPricingCategoryTransversalPricesPromise, getPricingCategoryTimeSlotsPromise, getCancellationPenaltiesPromise]).then(
        () => {
          dispatch(toggleCategoryWidgetOpenedState(category.id));
        }
      );
    } else {
      dispatch(toggleCategoryWidgetOpenedState(category.id));
    }
  }

  handleBusinessTimeSlotsCallback(values) {
    const { dispatch, category } = this.props;

    const params = {
      values: values.timeSlots,
      usage: TIME_SLOT_USAGE_BUSINESS,
      categoryType: category.type,
      vehicleCategoryId: category.id
    };

    dispatch(requestSendTimeSlots(params));
  }

  handleCategoryTransversalPricingCallback(values) {
    const { dispatch, category } = this.props;

    dispatch(
      requestSendTransversalPricing({
        vehicleCategoryId: category.id,
        values
      })
    )
      .then(() => {
        dispatch(
          addFlashMessage({
            contentKey: 'vehicleCategoryPricingWidget_transversal_save_success',
            contentData: {
              categoryType: category.type
            },
            type: FLASH_MESSAGE_TYPE_SUCCESS
          })
        );
      })
      .catch(() => {
        dispatch(
          addFlashMessage({
            contentKey: 'vehicleCategoryPricingWidget_transversal_save_error',
            contentData: {
              categoryType: category.type
            },
            type: FLASH_MESSAGE_TYPE_ERROR
          })
        );
      });
  }

  handleCategoryTimeSlotsCallback(timeSlot) {
    const { dispatch } = this.props;
    dispatch(prepareTimeSlotsToSend(timeSlot));
  }

  handleCancellationFeesCallback() {
    const { dispatch, category } = this.props;
    dispatch(saveCancellationFees(category.id));
  }

  handleGlobalSave() {
    const { dispatch } = this.props;
    dispatch(setSaveAllTimeSlots(true));
  }

  getTimeSlotsFormsErrors() {
    const { timeSlotsToSend } = this.props;
    const errors = [];
    const timeSlotsByType = timeSlotsToSend.reduce((obj, ts) => {
      if (!obj[ts.type]) {
        obj[ts.type] = [];
      }
      obj[ts.type].push(ts);
      return obj;
    }, {});

    for (let type in timeSlotsByType) {
      const timeSlots = timeSlotsByType[type];
      // if one tag not empty, all tags must be filled
      const emptyTagsLength = timeSlots.filter(ts => ts.tag === '' || ts.tag === '-').length;
      const allEmptyTagsLength = timeSlotsToSend.filter(ts => ts.tag === '' || ts.tag === '-').length;
      if (
        (emptyTagsLength > 0 && emptyTagsLength !== timeSlots.length) ||
        (allEmptyTagsLength > 0 && timeSlotsToSend.length !== allEmptyTagsLength)
      ) {
        if (emptyTagsLength > 0 && emptyTagsLength <= timeSlots.length) {
          errors.push({ type, messageLabel: 'timeSlot_tags_error_all_labels_must_be_filled' });
        }
      }
      // For type "between 2 dates": 1 tag "weekend package" allowed
      if (type === TIME_SLOT_TYPE_PACKAGE_BETWEEN_DATES && timeSlots.filter(ts => ts.tag === TIME_SLOT_TAG_WEEK_END_PACKAGE).length > 1) {
        errors.push({ type, messageLabel: 'timeSlot_tags_error_only_one_weekend_package_allowed' });
      }
      // For the type "package for duration": 1 tag "day package" allowed
      if (type === TIME_SLOT_TYPE_PACKAGE_FOR_DURATION) {
        if (timeSlots.filter(ts => ts.tag === TIME_SLOT_TAG_DAY_PACKAGE).length > 1) {
          errors.push({ type, messageLabel: 'timeSlot_tags_error_only_one_day_package_allowed' });
        }
        if (timeSlots.filter(ts => ts.tag === TIME_SLOT_TAG_WEEK_PACKAGE).length > 1) {
          errors.push({ type, messageLabel: 'timeSlot_tags_error_only_one_week_package_allowed' });
        }
        if (timeSlots.filter(ts => ts.tag === TIME_SLOT_TAG_MONTH_PACKAGE).length > 1) {
          errors.push({ type, messageLabel: 'timeSlot_tags_error_only_one_month_package_allowed' });
        }
      }
      // Prices must be equal for the same tag
      if (type === TIME_SLOT_TYPE_PRICE_PER_HOUR || type === TIME_SLOT_TYPE_PACKAGE_BETWEEN_DATES) {
        const pricesByTag = timeSlots
          .filter(ts => ts.tag !== '')
          .reduce((obj, ts) => {
            if (!obj[ts.tag]) {
              obj[ts.tag] = [];
            }
            if (ts.price) {
              obj[ts.tag].push(ts.price.toString());
            }
            return obj;
          }, {});
        for (let tag in pricesByTag) {
          if (_uniq(pricesByTag[tag]).length > 1) {
            errors.push({ type, messageLabel: 'timeSlot_tags_error_prices_should_be_identical' });
            break;
          }
        }
      }
    }

    if (errors.length) scrollToFirstError();
    return errors;
  }

  handleSaveCallback() {
    const { dispatch, timeSlotsToSend } = this.props;

    dispatch(setSaveAllTimeSlots(false));
    dispatch(clearTimeSlotsErrors());

    const timeSlotFormsErrors = this.getTimeSlotsFormsErrors();

    if (timeSlotFormsErrors.length) {
      dispatch(setTimeSlotsErrors(timeSlotFormsErrors));
      dispatch(resetTimeSlotsToSend());
    } else {
      const params = {
        usage: TIME_SLOT_USAGE_PRIVATE,
        categoryType: this.props.category.type,
        vehicleCategoryId: this.props.category.id,
        values: timeSlotsToSend
      };

      dispatch(requestSendTimeSlots(params));
    }
  }

  updateTimeslots() {
    const { category, categoriesTimeSlots } = this.props;

    if (categoriesTimeSlots.has(category.id) && this.categoryOpened()) {
      const initialTimeSlots = categoriesTimeSlots.get(category.id);
      return filterTimeslots(initialTimeSlots);
    }
  }

  render() {
    const { category, categoriesTransversalPrices, cancellationFees, limitedAccess } = this.props;

    let categoryIsOpened = this.categoryOpened();
    let transversalPriceInitialValues, cancellationFeesInitial;

    if (categoriesTransversalPrices.has(category.id)) {
      let values = categoriesTransversalPrices.get(category.id);
      transversalPriceInitialValues = Object.assign({}, values);
    }

    if (cancellationFees.has(category.id)) {
      let values = cancellationFees.get(category.id);
      cancellationFeesInitial = { ...values };
    }

    const { initialTimeSlotsPricePerHour, initialTimeSlotsBetweenDates, initialTimeSlotsDuration, initialTimeSlotsBusiness } =
      this.updateTimeslots() || {};

    return (
      <div
        className={classNames('vehicleCategoryPricingWidget', {
          _is_closed: !categoryIsOpened
        })}
      >
        <header className="vehicleCategoryPricingWidget_header" onClick={() => this.handleToggleOpenedState()}>
          <button
            className={classNames('vehicleCategoryPricingWidget_toggle', {
              _is_closed: !categoryIsOpened
            })}
          >
            <FormattedMessage id="vehicleCategoryPricingWidget_toggleLabel" />
          </button>

          <h3 className="vehicleCategoryPricingWidget_title">{category.type}</h3>
        </header>

        {categoryIsOpened && (
          <article className="vehicleCategoryPricingWidget_content">
            {this.props.currentContract.businessCarSharing && (
              <section className="pricingPage_management_section pricingPage_management_section--business">
                <TimeSlotsForm
                  formKey={`business-${category.id}`}
                  label={{
                    key: 'pricing_page_sectionTitle_business',
                    class: 'vehicleCategoryPricingWidget_section_title'
                  }}
                  onCallback={this.handleBusinessTimeSlotsCallback}
                  customClass="surrounded-section"
                  usage={TIME_SLOT_USAGE_BUSINESS}
                  type={TIME_SLOT_TYPE_PRICE_PER_HOUR}
                  initialTimeSlots={initialTimeSlotsBusiness}
                  limitedAccess={limitedAccess}
                />
              </section>
            )}

            <section className="vehicleCategoryPricingWidget_content_section surrounded-section">
              <TransversalCategoryPricingForm
                formKey={category.id}
                label={{
                  class: 'vehicleCategoryPricingWidget_section_title no-float',
                  key: 'vehicleCategoryPricingWidget_transversal_title'
                }}
                onCallback={this.handleCategoryTransversalPricingCallback}
                initialValues={transversalPriceInitialValues}
                limitedAccess={limitedAccess}
                currency={this.props.currency}
              />
            </section>

            <section className="vehicleCategoryPricingWidget_content_section surrounded-section">
              <CancellationFeesForm
                onCallback={this.handleCancellationFeesCallback}
                label={{ class: 'vehicleCategoryPricingWidget_section_title', key: 'pricing_cancellation_fees_label' }}
                formKey={`fees-${category.id}`}
                limitedAccess={limitedAccess}
                categoryId={category.id}
                initialValues={cancellationFeesInitial}
              />
            </section>
            {this.props.currentContract.privateCarSharing && (
              <section className="vehicleCategoryPricingWidget_content_section surrounded-section">
                <TimeSlotsForm
                  ref="timeSlots_price_per_hour"
                  formKey={`category-${category.id}`}
                  label={{
                    key: 'vehicleCategoryPricingWidget_private_title',
                    class: 'vehicleCategoryPricingWidget_section_title'
                  }}
                  customClass="timeSlots_price_per_hour"
                  onCallback={this.handleCategoryTimeSlotsCallback}
                  usage={TIME_SLOT_USAGE_PRIVATE}
                  type={TIME_SLOT_TYPE_PRICE_PER_HOUR}
                  onSaveCallback={this.handleSaveCallback}
                  initialTimeSlots={initialTimeSlotsPricePerHour}
                  limitedAccess={limitedAccess}
                  currency={this.props.currency}
                />
                <div className="dash" />
                <TimeSlotsForm
                  ref="timeSlots_package_between_dates"
                  formKey={`category-forfait-${category.id}`}
                  label={{
                    key: 'vehicleCategoryPricingWidget_forfait_dayNight_title',
                    class: 'vehicleCategoryPricingWidget_section_title'
                  }}
                  customClass="timeSlots_package_between_dates"
                  onCallback={this.handleCategoryTimeSlotsCallback}
                  usage={TIME_SLOT_USAGE_PRIVATE}
                  type={TIME_SLOT_TYPE_PACKAGE_BETWEEN_DATES}
                  onSaveCallback={this.handleSaveCallback}
                  initialTimeSlots={initialTimeSlotsBetweenDates}
                  limitedAccess={limitedAccess}
                  currency={this.props.currency}
                />
                <div className="dash" />
                <TimeSlotsForm
                  ref="timeSlots_package_for_duration"
                  formKey={`category-forfait-duration-${category.id}`}
                  label={{
                    key: 'vehicleCategoryPricingWidget_forfait_duration_title',
                    class: 'vehicleCategoryPricingWidget_section_title'
                  }}
                  customClass="timeSlots_package_for_duration"
                  onCallback={this.handleCategoryTimeSlotsCallback}
                  usage={TIME_SLOT_USAGE_PRIVATE}
                  type={TIME_SLOT_TYPE_PACKAGE_FOR_DURATION}
                  onSaveCallback={this.handleSaveCallback}
                  initialTimeSlots={initialTimeSlotsDuration}
                  limitedAccess={limitedAccess}
                  currency={this.props.currency}
                />

                {!limitedAccess ? (
                  <div className="container-submit-private-slots">
                    <EkButton customClass="timeSlotsForm_submitButton submit-private-slots" onAction={this.handleGlobalSave}>
                      <FormattedMessage id="timeSlotsForm_submitButton" />
                    </EkButton>
                  </div>
                ) : null}
              </section>
            )}
          </article>
        )}
      </div>
    );
  }
}

VehicleCategoryPricingWidget.displayName = 'VehicleCategoryPricingWidget';

VehicleCategoryPricingWidget.propTypes = {
  fields: PropTypes.object,
  limitedAccess: PropTypes.bool,
  onCallback: PropTypes.func
};

export default connect(state => {
  const {
    user: {
      userInfo: { role }
    },
    pricing: {
      openedVehicleCategoryWidgets,
      categoriesTransversalPrices,
      categoriesTimeSlots,
      currentTabIndex,
      timeSlotsToSend,
      currentCompanyBusinessTimeSlots,
      cancellationFees
    }
  } = state;

  return {
    currentContract: headerCompanyContractSelector(state),
    vehicleRules: headerContractVehicleValuesSelector(state),
    openedVehicleCategoryWidgets,
    categoriesTransversalPrices,
    categoriesTimeSlots,
    currentTabIndex,
    role,
    timeSlotsToSend,
    currentCompanyBusinessTimeSlots,
    cancellationFees
  };
})(VehicleCategoryPricingWidget);
