import * as types from '../constants/actionTypes-constants';

export function storeParams(params) {
  return {
    type: types.STORE_PAGE_PARAMS,
    params
  };
}

export function updateCurrentRouteName(route) {
  return {
    type: types.PAGE_UPDATE_CURRENT_ROUTE,
    route
  };
}

export function updateCurrentRouteGroup(group) {
  return {
    type: types.PAGE_UPDATE_CURRENT_GROUP,
    group
  };
}

export function updateRootClasses(rootClasses) {
  return {
    type: types.PAGE_UPDATE_ROOT_CLASSES,
    rootClasses
  };
}
