import React, { Component } from 'react';
import { connect } from 'react-redux';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _debounce from 'lodash/debounce';
import moment from 'moment';
import { Tabs, Tab } from 'material-ui/Tabs';
import { FormattedMessage, injectIntl } from 'react-intl';
import { smartcardsUserSearchTypes as searchTypes } from '../../constants/generic-constants';

import DateIcon from 'material-ui/svg-icons/action/date-range';
import PersonIcon from 'material-ui/svg-icons/action/account-circle';
import Dialog from 'material-ui/Dialog';
import FlatButton from 'material-ui/FlatButton';
import PersonAddIcon from 'material-ui/svg-icons/social/person-add';
import Loader from '../../components/MaterialLoader/MaterialLoader';
import BackLink from '../../components/BackLink/BackLink';
import routes, { smartcardEditRules } from '../../constants/routes-constants';
import { getSmartcardsDetail, smartcardsSearchBackUser, smartcardsSearchMember } from '../../actions/all-actions';

import {
  removeUserFromSmartcard,
  changeSmartcardStatus,
  assignSmartCardUser,
  getDetailWithCardId,
  clearSmartcardDetail,
  errorUserSelectedToPairSmartcard
} from '../../actions/all-actions';

import { getAppFormattedDateTime, trySet, append, safe, getMainColor } from '../../utils/utils';
import { apiParams } from '../../constants/api-params-constants';
import { userRoleSelector } from '../../selectors/all-selectors';
import { checkRole } from '../../constants/backuser-role-rules';
import IconButton from 'material-ui/IconButton';
import CloseIcon from 'material-ui/svg-icons/navigation/close';
import SearchForUser from './SearchForUser';
import autoBind from 'react-autobind';

const searchProp = {
  member: {
    [searchTypes.EMAIL]: 'email',
    [searchTypes.LAST_NAME]: 'lastname',
    [searchTypes.FIRST_NAME]: 'firstname'
  },
  backUser: {
    [searchTypes.EMAIL]: 'email',
    [searchTypes.LAST_NAME]: 'lastName',
    [searchTypes.FIRST_NAME]: 'firstName'
  }
};

const initialSelectedIndex = 0;

class SmartcardsDetail extends Component {
  constructor(props) {
    super(props);
    autoBind(this);

    this.initState();
    this.delayFunctions();
    this.setVariables();
    this.setReadOnly(props);
  }

  delayFunctions() {
    this.handleGetMembers = _debounce(this.handleGetMembers.bind(this), 250);
    this.handleGetBackUsers = _debounce(this.handleGetBackUsers.bind(this), 250);
  }

  setVariables() {
    this.userHandlers = {
      0: this.handleGetMembers,
      1: this.handleGetBackUsers
    };

    this.styles = {
      headline: {
        fontSize: 24,
        paddingTop: 16,
        fontWeight: 400
      },
      tab: {
        backgroundColor: 'white',
        color: getMainColor()
      },
      inkBar: {
        backgroundColor: getMainColor()
      }
    };
  }

  initState() {
    this.state = {
      searchText: '',
      openModalRemove: false,
      openModalAdd: false,
      focusedTab: initialSelectedIndex,
      selectField: { value: searchTypes.EMAIL, onChange: this.handleSelectUpdate },
      foundUsers: []
    };
  }

  setReadOnly(props) {
    const { role } = props;
    this.readOnly = !checkRole(smartcardEditRules, role);
  }

  componentWillMount() {
    const id = this.props.pathname.replace('smartcardsDetail/', '').replace('/', '');

    if (id.length === 17) {
      this.props.dispatch(getDetailWithCardId(id));
    } else {
      this.props.dispatch(getSmartcardsDetail(id));
    }
  }

  componentWillUnmount() {
    this.props.dispatch(clearSmartcardDetail());
  }

  handleSelectUpdate(value) {
    this.setState({ selectField: { value, onChange: this.handleSelectUpdate } });
    this.focusTab(this.state.focusedTab);
    this.clearFoundUsers();
    this.clearInput();
  }

  clearInput() {
    this.setState({ searchText: '' });
  }

  clearFoundUsers() {
    this.setState({ foundUsers: [] });
  }

  handleGetMembers(input) {
    const { dispatch, detailSmartcard } = this.props;
    const smartcardCompanyId = safe(() => detailSmartcard.company.id);
    const params = { [searchProp.member[this.state.selectField.value]]: input };

    trySet(params, 'companyIds', append([smartcardCompanyId]));

    dispatch(smartcardsSearchMember(params)).then((data = {}) => {
      const users = (data.results || []).map((item = {}) => {
        const brand = item.brandName;
        const brandMsg = brand ? ' — ' + brand : '';

        return {
          text: item.firstName + ' ' + item.lastName + ' — ' + item.login + brandMsg,
          value: item.userId || item.id
        };
      });

      this.setState({ foundUsers: users });
    });
  }

  handleGetBackUsers(input) {
    const { dispatch, detailSmartcard } = this.props;
    const smartcardCompanyId = safe(() => detailSmartcard.company.id);
    const params = { [searchProp.backUser[this.state.selectField.value]]: input };

    trySet(params, 'companyIds', append([smartcardCompanyId]));

    dispatch(smartcardsSearchBackUser(params)).then((data = {}) => {
      const users = (data.results || []).map((item = {}) => {
        return {
          text: item.firstName + ' ' + item.lastName + ' — ' + item.login,
          value: item.userId || item.id
        };
      });

      this.setState({ foundUsers: users });
    });
  }

  handleChangeSmartCardUser(user) {
    const { detailSmartcard, dispatch } = this.props;

    if (user.hasOwnProperty('value')) {
      dispatch(
        assignSmartCardUser({
          smartcardId: detailSmartcard.id,
          userId: user.value
        })
      );
      this.setState({ openModalAdd: false });
    } else dispatch(errorUserSelectedToPairSmartcard());
  }

  handleChangeEmailInput(value) {
    this.setState({ emailInput: value });
  }

  handleOpenModalRemove() {
    this.setState({ openModalRemove: true });
  }

  handleCloseModalRemove() {
    this.setState({ openModalRemove: false });
  }

  handleOpenModalAdd() {
    this.setState({ openModalAdd: true });
  }

  handleCloseModalAdd() {
    this.setState({ openModalAdd: false });
  }

  handleActivate() {
    const { detailSmartcard, dispatch } = this.props;

    dispatch(
      changeSmartcardStatus({
        activationDate: moment().toISOString(),
        deactivationDate: null,
        protocol: detailSmartcard.protocol,
        id: detailSmartcard.id,
        cardId: detailSmartcard.cardId
      })
    );
  }

  handleDeactivate() {
    const { detailSmartcard, dispatch } = this.props;

    dispatch(
      changeSmartcardStatus({
        activationDate: detailSmartcard.activationDate,
        deactivationDate: moment().toISOString(),
        protocol: detailSmartcard.protocol,
        id: detailSmartcard.id,
        cardId: detailSmartcard.cardId
      })
    );
  }

  handleLogChange(val) {
    this.setState({ userSelectValue: val });
  }

  handleRemove() {
    const { detailSmartcard, dispatch } = this.props;

    dispatch(removeUserFromSmartcard(detailSmartcard.id));

    this.setState({ openModalRemove: false });
  }

  handleTabChange(tab) {
    this.setState({ focusedTab: tab.props.index });
    this.focusTab(tab.props.index);
    this.clearFoundUsers();
    this.clearInput();
  }

  focusFirstTab() {
    this.focusTab(initialSelectedIndex);
  }

  focusTab(index) {
    document.getElementById('smartcards.search.' + index).focus();
  }

  handleUpdateInput(input) {
    const text = input.trim();
    this.setState({ searchText: input });

    if (text) {
      this.userHandlers[this.state.focusedTab](text);
    } else {
      this.setState({ foundUsers: [] });
    }
  }

  assignUserDialog() {
    const { errorMemberSelection } = this.props;

    return (
      <Dialog
        bodyStyle={{ overflow: 'inherit' }}
        contentStyle={{ width: '600px' }}
        onRequestClose={this.handleCloseModalAdd}
        open={this.state.openModalAdd}
      >
        <div className="smartcardsDetail_closeIconAbosuluteWrap">
          <IconButton onClick={this.handleCloseModalAdd}>
            <CloseIcon />
          </IconButton>
        </div>
        <Tabs
          style={{ marginTop: '10px' }}
          className="tabs-rci smartcardsDetail_tabs"
          inkBarStyle={this.styles.inkBar}
          initialSelectedIndex={initialSelectedIndex}
        >
          <Tab
            label={<FormattedMessage id="user_type_member" />}
            buttonStyle={this.styles.tab}
            className="tab"
            onActive={this.handleTabChange}
          >
            <SearchForUser
              selectTitleKey="common_search_by"
              inputId="smartcards.search.0"
              onDidMount={this.focusFirstTab}
              onUpdate={this.handleUpdateInput}
              onRequest={this.handleChangeSmartCardUser}
              searchText={this.state.searchText}
              selectField={this.state.selectField}
              foundUsers={this.state.foundUsers}
              errorDuringSelection={errorMemberSelection}
            />
          </Tab>
          <Tab
            label={<FormattedMessage id="common_back_user" />}
            buttonStyle={this.styles.tab}
            className="tab"
            onActive={this.handleTabChange}
          >
            <SearchForUser
              selectTitleKey="common_search_by"
              inputId="smartcards.search.1"
              onUpdate={this.handleUpdateInput}
              onRequest={this.handleChangeSmartCardUser}
              searchText={this.state.searchText}
              selectField={this.state.selectField}
              foundUsers={this.state.foundUsers}
              errorDuringSelection={errorMemberSelection}
            />
          </Tab>
        </Tabs>
      </Dialog>
    );
  }

  render() {
    const { detailSmartcard, loadingDetail, urlParams, intl } = this.props;
    const activationDate = _get(detailSmartcard, 'activationDate', false) ? detailSmartcard.activationDate : false;
    const status = moment(detailSmartcard.activationDate).isBefore(moment());
    const encodedUrl = encodeURIComponent(JSON.stringify(urlParams || apiParams.default));
    const backLink = `/#${routes.smartcards.path.replace(':search', encodedUrl)}`;

    return (
      <div className="smartcardsDetail mainContainer_content">
        <div className="pageContainer">
          <BackLink link={backLink} labelKey="back_link_smartcards_list" />
          {loadingDetail && !_isEmpty(detailSmartcard, true) ? (
            <Loader />
          ) : (
            <div className="smartcardsDetail_content">
              <div className="smartcardsDetail_smartcardCard_wrap">
                <div className="smartcardsDetail_smartcardCard">
                  <div className="smartcardsDetail_smartcardImage">
                    <div className="smartcardsDetail_smartcardImage_name">
                      <span>
                        {status && (
                          <span className="smartcardsDetail_smartcardImage_status_active">
                            <FormattedMessage id="groups_status_ACTIVE" />
                          </span>
                        )}
                        {!activationDate && (
                          <span className="smartcardsDetail_smartcardImage_status_inactive">
                            <FormattedMessage id="backUsers_tableView_status_DISABLED" />
                          </span>
                        )}
                      </span>
                    </div>
                    <div className="smartcardsDetail_smartcardImage_id">
                      {_get(detailSmartcard, 'cardId', false) && (
                        <span>
                          ID: <b className="smartcardsDetail_smartcardImage_id_number">{detailSmartcard.cardId}</b>
                        </span>
                      )}
                    </div>
                  </div>
                </div>
                {activationDate && (
                  <div className="smartcardsDetail_smartcardCard_bottom">
                    <div className="smartcardsDetail_smartcardCard_bottom_row">
                      <DateIcon />
                      <span style={{ color: 'green' }}>
                        <FormattedMessage id="smartcards_activation" />:
                      </span>
                      {getAppFormattedDateTime(detailSmartcard.activationDate)}
                    </div>

                    {detailSmartcard.deactivationDate && (
                      <div className="smartcardsDetail_smartcardCard_bottom_row">
                        <DateIcon />
                        <span style={{ color: 'red' }}>
                          <FormattedMessage id="smartcards_deactivation" />:
                        </span>
                        {getAppFormattedDateTime(detailSmartcard.deactivationDate)}
                      </div>
                    )}
                  </div>
                )}
              </div>

              {detailSmartcard.user && (
                <div className="smartcardsDetail_user">
                  <PersonIcon style={{ width: '90px', height: '90px' }} />
                  <div className="smartcardsDetail_user_info">
                    <span>{detailSmartcard.user.brandName}</span>
                    <span>
                      {detailSmartcard.user.firstname} {detailSmartcard.user.lastname}
                    </span>
                    <span>{detailSmartcard.user.login}</span>
                    {_get(detailSmartcard, 'company', false) && <span>{detailSmartcard.company.name}</span>}
                  </div>
                  {!this.readOnly && (
                    <div className="smartcardsDetail_user_actions">
                      <div className="smartcardsDetail_link" onClick={this.handleOpenModalRemove}>
                        <FlatButton label={<FormattedMessage id="companyDetail_addToFree_button" />} className="flatButton" />
                      </div>
                    </div>
                  )}
                </div>
              )}

              {!this.readOnly && !detailSmartcard.user && (
                <div className="smartcardsDetail_user">
                  <div className="smartcardsDetail_user_add_wrap">
                    <PersonAddIcon style={{ width: '50px', height: '50px' }} className="flatButton" />
                    <div className="smartcardsDetail_user_add">
                      <FlatButton
                        label={intl.formatMessage({ id: 'smartcards_assign_user' })}
                        className="flatButton"
                        onTouchTap={this.handleOpenModalAdd}
                      />
                    </div>
                  </div>
                </div>
              )}

              <Dialog
                title={intl.formatMessage({ id: 'smartcards_remove_user' })}
                actions={[
                  <FlatButton
                    key="remove_cancel"
                    className="flatButton"
                    label={<FormattedMessage id="backUsers_detail_abort_button" />}
                    onTouchTap={this.handleCloseModalRemove}
                  />,
                  <FlatButton
                    key="remove_ok"
                    className="flatButton"
                    label={<FormattedMessage id="companyDetail_addToFree_button" />}
                    onTouchTap={this.handleRemove}
                  />
                ]}
                modal
                contentStyle={{ width: '385px' }}
                open={this.state.openModalRemove}
                onRequestClose={this.handleCloseModalRemove}
              >
                <FormattedMessage id="smartcards_remove_user_info" />
              </Dialog>

              {this.assignUserDialog()}
            </div>
          )}
        </div>
      </div>
    );
  }
}

SmartcardsDetail.displayName = 'SmartcardsDetail';

SmartcardsDetail = connect(state => {
  const {
    smartcards: { detailSmartcard, loadingDetail, urlParams, errorMemberSelection },
    routing: {
      location: { pathname }
    }
  } = state;

  return {
    errorMemberSelection,
    detailSmartcard,
    loadingDetail,
    pathname,
    urlParams,
    role: userRoleSelector(state)
  };
})(SmartcardsDetail);

export default injectIntl(SmartcardsDetail);
