import React, { Component, PropTypes } from 'react';
import cs from 'classnames';
import ListHead from './Head';
import ListItems from './Items';
import { debounce, namedCompose } from '../../../utils/utils';
import pure from 'recompose/pure';
import { injectIntl } from 'react-intl';

const updateDelay = 500;

class ColumnList extends Component {
  constructor(p) {
    super(p);
    this.initState();
    this.setCallbacks();
  }

  initState() {
    this.state = { input: '' };
  }

  setCallbacks() {
    this.updateInput = debounce(input => {
      if (input) {
        this.setState({ input });
      } else {
        this.setState({ input: '' });
      }
    }, updateDelay);

    this.setInputRef = ref => {
      const { inputRef } = this.props;
      inputRef && inputRef(ref);
    };

    this.sendInputChange = input => {
      const { onInputChange } = this.props;
      if (onInputChange) onInputChange(input);
    };

    this.handleInputChange = input => {
      this.sendInputChange(input);
      this.updateInput(input);
    };

    this.handleInputClear = debounce(() => {
      this.sendInputChange('');
      this.updateInput();
    }, updateDelay);
  }

  render() {
    return (
      <section className={cs('column-list', this.props.className)}>
        <ListHead
          inputRef={this.setInputRef}
          onChange={this.handleInputChange}
          onClear={this.handleInputClear}
          placeholder={this.props.placeholder}
          autoCompleteEnabled={this.props.autoCompleteEnabled}
          autoCompleteData={this.props.autoCompleteData}
          autoCompleteKey={this.props.autoCompleteKey}
          autoCompleteOnSelect={this.props.autoCompleteOnSelect}
          autoCompleteEngine={this.props.autoCompleteEngine}
          autoCompleteList={this.props.autoCompleteList}
          autoCompleteResult={this.props.autoCompleteResult}
          filtersActive={this.props.filtersActive}
          filtersTitle={this.props.filtersTitle}
          onFiltersApply={this.props.onFiltersApply}
          onFiltersReset={this.props.onFiltersReset}
          filterChips={this.props.filterChips}
          filters={this.props.filters}
          onBack={this.props.onBack}
          menuItems={this.props.menuItems}
        />
        <ListItems
          onEdit={this.props.onEdit}
          onSelect={this.props.onSelect}
          input={this.state.input}
          items={this.props.items}
          renderItem={this.props.renderItem}
          title={this.props.title}
          header={this.props.header}
          selected={this.props.selected}
          active={this.props.active}
          autoCompleteEnabled={this.props.autoCompleteEnabled}
          localSearchEnabled={this.props.localSearchEnabled}
          onScrollToBottom={this.props.onScrollToBottom}
          totalResults={this.props.totalResults}
          localSearchKey={this.props.localSearchKey}
        />
      </section>
    );
  }
}

/*
  selected: index || (item) => bool
  active: index || (item) => bool
  menuItems: Look <IconList />
  onEdit: (item, index)
  onSelect: (item, index)
 */

ColumnList.propTypes = {
  selected: PropTypes.oneOfType([PropTypes.func, PropTypes.number]),
  active: PropTypes.oneOfType([PropTypes.func, PropTypes.number]),
  autoCompleteEnabled: PropTypes.bool,
  autoCompleteData: PropTypes.any,
  autoCompleteKey: PropTypes.string,
  autoCompleteOnSelect: PropTypes.func,
  autoCompleteEngine: PropTypes.func,
  autoCompleteList: PropTypes.func,
  autoCompleteResult: PropTypes.func,
  localSearchEnabled: PropTypes.bool,
  localSearchKey: PropTypes.string,
  onScrollToBottom: PropTypes.func,
  onFiltersApply: PropTypes.func,
  onFiltersReset: PropTypes.func,
  totalResults: PropTypes.number,
  placeholder: PropTypes.string,
  filtersActive: PropTypes.bool,
  filtersTitle: PropTypes.any,
  className: PropTypes.string,
  filterChips: PropTypes.any,
  renderItem: PropTypes.func,
  menuItems: PropTypes.array,
  inputRef: PropTypes.func,
  onSelect: PropTypes.func,
  onEdit: PropTypes.func,
  onInputChange: PropTypes.func,
  onBack: PropTypes.func,
  filters: PropTypes.any,
  items: PropTypes.array,
  header: PropTypes.any,
  title: PropTypes.any
};

ColumnList.defaultProps = {
  items: [],
  title: 'Title',
  localSearchKey: 'name',
  renderItem: () => 'Item'
};

export default ColumnList;
