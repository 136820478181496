import React, { Component, PropTypes } from 'react';
import { connect } from 'react-redux';
import { routeActions } from 'react-router-redux';
import { Col, Row } from 'react-bootstrap';
import _get from 'lodash/get';
import _partial from 'lodash/partial';
import { FormattedMessage, injectIntl } from 'react-intl';

import AdvancedList from '../../components/AdvancedList/AdvancedList';
import BookingListIcon from '../../components/BookingListIcon/BookingListIcon';
import { dateToYMD, getAppFormattedDateTime } from '../../utils/utils';

import { bookingsHeaderParams } from '../../constants/table-constants';
import {
  clearBookingListData,
  emptyModelList,
  exportBookingsListWithSearchV3,
  getBookingsList,
  getBrandsList,
  getModelsList
} from '../../actions/all-actions';

import IsGranted from '../../components/IsGranted/IsGranted';
import routes, { bookingEditRules } from '../../constants/routes-constants';
import ArrowIcon from 'material-ui/svg-icons/navigation/arrow-forward';
import FlatButton from 'material-ui/FlatButton';
import AddIcon from 'material-ui/svg-icons/content/add';
import DownloadIcon from 'material-ui/svg-icons/file/file-download';
import FilterChips from '../../components/FilterChips/FilterChips';
import Filters from '../../components/AdvancedList/components/Filters';
import DetailIcon from 'material-ui/svg-icons/image/remove-red-eye';
import IconButton from 'material-ui/IconButton';
import { bookingFilterChips } from '../../constants/options-constants';
import autoBind from 'react-autobind';
import { bookingsFilterTypesSelector } from '../../selectors/all-selectors';

class Bookings extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
  }

  componentWillMount() {
    this.props.dispatch(getBrandsList(false)).then(() => this.getModels(this.props.pageParams));
  }

  componentWillUnmount() {
    this.props.dispatch(clearBookingListData());
  }

  handleRefreshList() {
    const { pageParams, dispatch } = this.props;
    dispatch(getBookingsList(pageParams));
  }

  handleChangePage(value) {
    const { dispatch, listMetadata, urlParams } = this.props;
    const params = {
      page: {
        number: value,
        size: listMetadata.paginationInfo.pageSize
      }
    };
    const newParams = { ...urlParams, ...params };
    dispatch(routeActions.push(routes.bookings.path.replace(':search', encodeURIComponent(JSON.stringify(newParams)))));
  }

  handleNumberResultsSelect(value) {
    const { dispatch, urlParams } = this.props;
    let params = {
      page: {
        number: 1,
        size: parseInt(value)
      }
    };
    let newParams = Object.assign({}, urlParams, params);
    dispatch(routeActions.push(routes.bookings.path.replace(':search', encodeURIComponent(JSON.stringify(newParams)))));
  }

  handleSearchBookingsForm(params) {
    const { dispatch, listMetadata, urlParams } = this.props;
    params.page = {
      number: 1,
      size: listMetadata.paginationInfo.pageSize
    };
    if (params.startDate !== undefined) {
      params.startDate = dateToYMD(params.startDate);
    }
    if (params.endDate !== undefined) {
      params.endDate = dateToYMD(params.endDate);
    }
    let newParams = {
      ...urlParams,
      ...params
    };
    this.getModels(newParams);
    dispatch(routeActions.push(routes.bookings.path.replace(':search', encodeURIComponent(JSON.stringify(newParams)))));
  }

  handleFullExportV3() {
    const { dispatch, urlParams } = this.props;
    dispatch(exportBookingsListWithSearchV3(urlParams));
  }

  handleCreateBooking() {
    this.props.dispatch(routeActions.push(routes.addBooking.path));
  }

  handleGotoDetail(params) {
    const { enableNewUi } = this.props;
    const _V2route = enableNewUi ? routes[params[0] + 'V2'] : routes[params[0]];
    this.props.dispatch(routeActions.push(_V2route.path.replace(params[1], params[2])));
  }

  renderItem(item, id, reactKey) {
    const colProps = { xs: 3, md: 3 };
    const usage = _get(item, 'vehicleUsageAtBookingCreation', '');
    return (
      <div className="advancedList_rowWrap" key={reactKey}>
        <Row id={id} className="advancedList_row bookingsPage_listRow">
          <Col xs={2} md={2}>
            <div className="bookingsPage_firstItemWrap">
              <BookingListIcon type={_get(item, 'carSharingUsageType', null) || _get(item, 'type', 'unknown')} status={item.status} />
              {item.id.slice(0, 8)}
            </div>
          </Col>
          <Col xs={1} md={1}>
            {usage !== '' && <FormattedMessage id={'vehicle_usage_type_' + usage.toLowerCase()} />}
          </Col>
          <Col xs={2} md={2}>
            <div
              onClick={_partial(this.handleGotoDetail, ['memberDetail', ':memberId', item.memberId])}
              className="advancedList_rowClickable"
            >
              {item.memberFirstName} {item.memberLastName}
            </div>
          </Col>
          <Col {...colProps}>
            {_get(item, 'vehicleRegistrationNumber', false) ? (
              <div
                className="advancedList_rowClickable"
                onClick={_partial(this.handleGotoDetail, ['vehicleDetail', ':vehicleId', item.vehicleId])}
              >
                <div className="bookingsPage_doubleColumnWrap">{item.vehicleRegistrationNumber}</div>
                {item.hasOwnProperty('vehicleBrandName') ? item.vehicleBrandName : ''}
                {item.hasOwnProperty('vehicleModelName') ? item.vehicleModelName : ''}
              </div>
            ) : (
              <span>-</span>
            )}
          </Col>
          <Col {...colProps}>
            <div className="bookingsPage_doubleColumnWrap">
              {getAppFormattedDateTime(item.startDate)}
              <ArrowIcon />
              {getAppFormattedDateTime(item.endDate)}
            </div>
            {_get(item, 'startParkingName', '')}
          </Col>
          <div className="advancedList_detailIcon">
            <IconButton onClick={_partial(this.handleGotoDetail, ['bookingDetail', ':bookingId', item.id])}>
              <DetailIcon />
            </IconButton>
          </div>
        </Row>
      </div>
    );
  }

  handleDeleteChip(item) {
    let { urlParams, dispatch } = this.props;
    delete urlParams[item];
    if (item === 'vehicleBrand') {
      dispatch(emptyModelList());
    }
    dispatch(routeActions.push(routes.bookings.path.replace(':search', encodeURIComponent(JSON.stringify(urlParams)))));
  }

  getModels(params) {
    const { dispatch, brandList } = this.props;
    if (_get(params, 'vehicleBrand', false)) {
      let brandId = '';
      brandList.map(item => {
        if (item.name === params.vehicleBrand) brandId = item.id;
      });
      dispatch(getModelsList(brandId, false));
    }
  }

  handleSort(sortPropertyName) {
    const { dispatch, urlParams } = this.props;
    const newParams = {
      ...urlParams,
      sort: {
        isDescending: _get(urlParams, 'sort.property', false) === sortPropertyName ? !urlParams.sort.isDescending : false,
        property: sortPropertyName
      }
    };
    dispatch(routeActions.push(routes.bookings.path.replace(':search', encodeURIComponent(JSON.stringify(newParams)))));
  }

  getExportButton() {
    const {
      exportingV3: exporting,
      intl: { formatMessage }
    } = this.props;

    const label = exporting ? formatMessage({ id: 'common_button_exporting' }) : formatMessage({ id: 'common_export' });

    return (
      <FlatButton
        id="bookings_export_button red"
        label={label}
        icon={<DownloadIcon />}
        disabled={exporting}
        onClick={this.handleFullExportV3}
      />
    );
  }

  render() {
    const { list, listMetadata, urlParams, loadingList, errorList, intl, filterTypes } = this.props;

    return (
      <div className="bookingsPage mainContainer_content">
        <div className="pageContainer">
          <div className="bookingsPage_content">
            <section className="bookingsPage_management">
              <FilterChips
                id="bookings-chips"
                urlParams={urlParams}
                onDeleteChip={this.handleDeleteChip}
                translations={bookingFilterChips}
                filterTypes={filterTypes}
              />

              <div className="advancedList_actions page">
                <Filters filterTypes={filterTypes} onFilteredSearchForm={this.handleSearchBookingsForm} id="bookings" />

                <div className="bookingsPage_actionsButtons">
                  {this.getExportButton()}
                  <IsGranted allowed={bookingEditRules.include} disallowed={bookingEditRules.exclude}>
                    <FlatButton
                      id="bookings_create_button"
                      label={intl.messages.booking_create_button}
                      icon={<AddIcon />}
                      onClick={this.handleCreateBooking}
                    />
                  </IsGranted>
                </div>
              </div>

              <AdvancedList
                id="bookings"
                data={list}
                listMetadata={listMetadata}
                loading={loadingList}
                renderItem={this.renderItem}
                onChangePage={this.handleChangePage}
                onRefreshList={this.handleRefreshList}
                onChangeRowsPerPage={this.handleNumberResultsSelect}
                error={errorList}
                urlParams={urlParams}
                header={bookingsHeaderParams}
                onSort={this.handleSort}
              />
            </section>
          </div>
        </div>
      </div>
    );
  }
}

Bookings.displayName = 'Bookings';

Bookings.propTypes = {
  list: PropTypes.array,
  listMetadata: PropTypes.object
};

export default connect(state => {
  const {
    user: { enableNewUi },
    bookings: {
      paginatedResults,
      urlParams,
      currentSortedIndex,
      sortIsDescending,
      bookingFiltersFormIsOpen,
      exportingV3,
      loadingList,
      facets,
      errorList
    },
    brands: { list: brandList },
    page: { params },
    routing: {
      location: { pathname }
    }
  } = state;

  return {
    list: _get(paginatedResults, 'results', []),
    listMetadata: _get(paginatedResults, 'metadata', {}),
    urlParams,
    currentSortedIndex,
    sortIsDescending,
    bookingFiltersFormIsOpen,
    exportingV3,
    pathname,
    loadingList,
    pageParams: params,
    facets,
    errorList,
    brandList,
    enableNewUi,
    filterTypes: bookingsFilterTypesSelector(state)
  };
})(injectIntl(Bookings));
