import React, { Component, PropTypes } from 'react';
import { FormattedMessage } from 'react-intl';
import { reduxForm } from 'redux-form';

import FormRow from '../FormRow/FormRow';
import BoxedInput from '../BoxedInput/BoxedInput';
import BoxedSelect from '../BoxedSelect/BoxedSelect';
import EkButton from '../EkButton/EkButton';
import AddressAutocomplete from '../AddressAutocomplete/AddressAutocomplete';
import EkRadio from '../EkRadio/EkRadio';
import FieldErrorMsg from '../FieldErrorMsg/FieldErrorMsg';
import config from '../../constants/config-constants';
import { createValidator, notEmpty, stopValidationIf, addressOrSite, dateAfterOrEqualField } from '../../validation/all-validation';
import { safe, scrollToFirstError } from '../../utils/utils';
import { ONE_WAY_TRIP, ROUND_TRIP } from '../../constants/backend-constants';
import { currentCompanyContractSelector } from '../../selectors/all-selectors';

class SearchVehiclesForm extends Component {
  renderSuggestFiltered(list) {
    const { fields } = this.props;

    let suggestList = list;
    if (fields.from.value) {
      suggestList = list.filter(obj => obj.id !== fields.from.value.id);
    }

    return suggestList;
  }

  render() {
    const {
      fields: { from, to, pickupDate, pickupDateHour, pickupDateMin, returnDate, returnDateHour, returnDateMin, passengers, tripType },
      companyContract: { interfaceConfigDto },
      userInfo,
      searchError,
      handleSubmit
    } = this.props;

    const { oneWayDisplay, returnTripDisplay } = interfaceConfigDto || {};
    const now = new Date();

    let minReturnDate = pickupDate && typeof pickupDate.value === 'object' ? pickupDate.value : now;
    let sites;

    if (safe(() => userInfo.company)) {
      sites = userInfo.company.sites;

      if (userInfo.defaultSite !== '' && userInfo.company.sites) {
        userInfo.company.sites.forEach((item, index) => {
          if (userInfo.defaultSite === item.id && index !== 0) {
            let picked = sites.splice(index, 1);
            sites.unshift(picked[0]);
          }
        });
      }
    }

    if (window.addtocalendar !== undefined) window.addtocalendar = undefined;
    if (window.ifaddtocalendar === 1) window.ifaddtocalendar = undefined;

    return (
      <div className="searchVehicle_container">
        <form action="#" method="post" className="searchVehicle" onSubmit={handleSubmit(this.props.onCallback)} noValidate>
          {searchError && (
            <div className="searchVehicle_mainErrorMsg">
              <FormattedMessage id="error_server_unknown" />
            </div>
          )}

          {oneWayDisplay && returnTripDisplay && (
            <div className="searchVehicle_tripSelection clearfix">
              <EkRadio
                formRowItemKey="backUserForm_userRole"
                items={[
                  {
                    labelKey: 'search_vehicle_oneWay',
                    value: ONE_WAY_TRIP
                  },
                  {
                    labelKey: 'search_vehicle_return',
                    value: ROUND_TRIP
                  }
                ]}
                namespace="tripType"
                field={tripType}
              >
                <FieldErrorMsg field={tripType} customClass="fieldErrorMsg--backUserForm" />
              </EkRadio>
            </div>
          )}

          <div className="searchVehicle_col searchVehicle_col--left">
            <FormRow customWrapperClass="searchVehicle_formRow">
              <label formRowItemKey="searchVehicle_from_label" className="boxedInput_label">
                <span className="boxedInput_labelText">
                  <FormattedMessage id="search_vehicle_from" />
                </span>

                <AddressAutocomplete
                  field={from}
                  className={`searchVehicleForm_AddressAutocomplete ${safe(() => userInfo.defaultSite) !== '' ? '_is_favorite' : ''}`}
                  fixtures={safe(() => userInfo.company) && sites}
                />

                <FieldErrorMsg field={from} customClass="fieldErrorMsg--searchVehicleForm" />
              </label>

              <BoxedInput
                formRowItemKey="searchVehicle_from_pickupDate"
                skinType="date"
                type="date"
                customClass="boxedInputWrapper--label _is_year_hidden"
                labelKey="search_vehicle_pickup"
                field={pickupDate}
                minDate={now}
                disableYearSelection
              >
                <FieldErrorMsg field={pickupDate} customClass="fieldErrorMsg--searchVehicleForm" />
              </BoxedInput>

              <BoxedSelect
                formRowItemKey="searchVehicle_from_pickupDateHour"
                labelKey="search_vehicle_pickup_hour"
                options={config.availableHours}
                customClass="searchVehicle_boxedSelectWrapper"
                hideLabel
                field={pickupDateHour}
              />

              <BoxedSelect
                formRowItemKey="searchVehicle_from_pickupDateMin"
                labelKey="search_vehicle_pickup_min"
                options={config.allAvailableMinutes}
                customClass="searchVehicle_boxedSelectWrapper"
                hideLabel
                field={pickupDateMin}
              />
            </FormRow>

            <FormRow customWrapperClass="searchVehicle_formRow">
              <label className="boxedInput_label" formRowItemKey="searchVehicle_to_label">
                <span className="boxedInput_labelText">
                  <FormattedMessage id="search_vehicle_to" />
                </span>

                {tripType.value === ONE_WAY_TRIP ? (
                  <AddressAutocomplete
                    field={to}
                    className="searchVehicle_AddressAutocomplete"
                    fixtures={safe(() => userInfo.company) && this.renderSuggestFiltered(sites)}
                  />
                ) : from.value && from.value._isSite ? (
                  <input type="text" value={from.value.label} className="searchVehicle_AdressAutoCompleteDisabled" disabled />
                ) : (
                  <input
                    type="text"
                    value={from.value && from.value.formattedAddress}
                    className="searchVehicle_AdressAutoCompleteDisabled"
                    disabled
                  />
                )}
              </label>

              <BoxedInput
                formRowItemKey="searchVehicle_to_returnDate"
                skinType="date"
                type="date"
                customClass="boxedInputWrapper--label"
                labelKey="search_vehicle_return_date"
                field={returnDate}
                minDate={minReturnDate}
                disabled={tripType.value === ONE_WAY_TRIP}
                mandatory={!(tripType.value === ONE_WAY_TRIP)}
                disableYearSelection
              >
                <FieldErrorMsg field={returnDate} customClass="fieldErrorMsg--searchVehicleForm" />
              </BoxedInput>

              <BoxedSelect
                formRowItemKey="searchVehicle_to_returnDateHour"
                labelKey="search_vehicle_return_hour"
                options={config.availableHours}
                customClass="searchVehicle_boxedSelectWrapper"
                hideLabel
                field={returnDateHour}
                disabled={tripType.value === ONE_WAY_TRIP}
              />

              <BoxedSelect
                formRowItemKey="searchVehicle_to_returnDateMin"
                labelKey="search_vehicle_return_min"
                options={config.allAvailableMinutes}
                customClass="searchVehicle_boxedSelectWrapper"
                hideLabel
                field={returnDateMin}
                disabled={tripType.value === ONE_WAY_TRIP}
              />
            </FormRow>
          </div>

          <div className="searchVehicle_col searchVehicle_col--right">
            <FormRow>
              <BoxedSelect
                formRowItemKey="searchVehicle_passengers"
                labelKey="search_vehicle_passenger"
                options={[1, 2, 3, 4, 5, 6]}
                customClass="searchVehicle_boxedSelectWrapper"
                field={passengers}
              />

              <EkButton
                formRowItemKey="searchVehicle_submitBtn"
                skinType="variant3"
                customClass="submit"
                onAction={handleSubmit(this.props.onCallback)}
                fullWidth
              >
                <FormattedMessage id="search_vehicle_btn" />
              </EkButton>
            </FormRow>
          </div>
        </form>
      </div>
    );
  }
}

SearchVehiclesForm.displayName = 'SearchVehiclesForm';

SearchVehiclesForm.propTypes = {
  action: PropTypes.func,
  fields: PropTypes.object,
  onCallback: PropTypes.func,
  handleSubmit: PropTypes.func
};

SearchVehiclesForm = reduxForm(
  {
    onSubmitFail: scrollToFirstError,
    form: 'searchVehicles',
    fields: [
      'from',
      'to',
      'pickupDate',
      'pickupDateHour',
      'pickupDateMin',
      'returnDate',
      'returnDateHour',
      'returnDateMin',
      'passengers',
      'tripType'
    ],
    validate: createValidator({
      from: [notEmpty(), addressOrSite()],
      to: [
        stopValidationIf({
          condition: (props, value) => {
            return !value;
          }
        }),
        addressOrSite()
      ],
      pickupDate: [notEmpty()],
      tripType: [notEmpty()],
      returnDate: [
        stopValidationIf({
          condition: props => {
            return props.form.tripType && props.form.tripType.value === ONE_WAY_TRIP ? props.form.tripType.value : false;
          }
        }),
        notEmpty(),
        dateAfterOrEqualField('pickupDate')
      ]
    })
  },
  state => ({
    companyContract: currentCompanyContractSelector(state)
  })
)(SearchVehiclesForm);

export default SearchVehiclesForm;
