import _includes from 'lodash/includes';
import _isEmpty from 'lodash/isEmpty';

import { ALL } from './generic-constants';
import {
  BACKUSER_ROLE_ADMIN,
  BACKUSER_ROLE_ADMIN_DEALER,
  BACKUSER_ROLE_CALL_CENTER_OPERATOR,
  BACKUSER_ROLE_FIELD_AGENT,
  BACKUSER_ROLE_CRM,
  BACKUSER_ROLE_EXTERNAL_FINE_COMPANY,
  BACKUSER_ROLE_FLEET_MANAGER,
  BACKUSER_ROLE_ROOT,
  BACKUSER_ROLE_SERVICE_ADVISOR,
  BACKUSER_ROLE_SUPER_ADMIN,
  BACKUSER_ROLE_ZONE_MANAGER
} from './backend-constants';
import memoizeOne from 'memoize-one';

// QuickSight all access whitelist
export const quickSightAllAccessRoles = [BACKUSER_ROLE_ROOT, BACKUSER_ROLE_SUPER_ADMIN];

// payback rules ~ START ~

// sub company

export const subCompanyPaybackConfigTabRules = {
  include: [BACKUSER_ROLE_ROOT, BACKUSER_ROLE_SUPER_ADMIN, BACKUSER_ROLE_ADMIN, BACKUSER_ROLE_ZONE_MANAGER, BACKUSER_ROLE_ADMIN_DEALER]
};

export const subCompanyPaybackSettingsEditRules = {
  include: [BACKUSER_ROLE_ROOT, BACKUSER_ROLE_SUPER_ADMIN, BACKUSER_ROLE_ADMIN]
};

export const subCompanyPaybackBankDetailsEditRules = {
  include: [BACKUSER_ROLE_ROOT, BACKUSER_ROLE_SUPER_ADMIN, BACKUSER_ROLE_ADMIN, BACKUSER_ROLE_ZONE_MANAGER, BACKUSER_ROLE_ADMIN_DEALER]
};

// super company

export const superCompanyPaybackConfigTabRules = {
  include: [BACKUSER_ROLE_ROOT, BACKUSER_ROLE_SUPER_ADMIN, BACKUSER_ROLE_ADMIN]
};

export const superCompanyPaybackSettingsEditRules = {
  include: [BACKUSER_ROLE_ROOT, BACKUSER_ROLE_SUPER_ADMIN, BACKUSER_ROLE_ADMIN]
};

export const superCompanyPaybackBankDetailsEditRules = {
  include: [BACKUSER_ROLE_ROOT, BACKUSER_ROLE_SUPER_ADMIN, BACKUSER_ROLE_ADMIN, BACKUSER_ROLE_ZONE_MANAGER, BACKUSER_ROLE_ADMIN_DEALER]
};

// payback rules ~ END ~

export const invoiceRetryPaymentRules = {
  include: [
    BACKUSER_ROLE_ROOT,
    BACKUSER_ROLE_SUPER_ADMIN,
    BACKUSER_ROLE_ADMIN,
    BACKUSER_ROLE_ZONE_MANAGER,
    BACKUSER_ROLE_ADMIN_DEALER,
    BACKUSER_ROLE_CRM,
    BACKUSER_ROLE_SERVICE_ADVISOR,
    BACKUSER_ROLE_FLEET_MANAGER
  ]
};

// RENAULT ITALY ROLES

export const renaultItalyRoles = [
  BACKUSER_ROLE_SERVICE_ADVISOR,
  BACKUSER_ROLE_CRM,
  BACKUSER_ROLE_ADMIN_DEALER,
  BACKUSER_ROLE_ZONE_MANAGER,
  BACKUSER_ROLE_EXTERNAL_FINE_COMPANY
];

// ALL ROLES

export const backuserAllRoles = [
  BACKUSER_ROLE_ROOT,
  BACKUSER_ROLE_SUPER_ADMIN,
  BACKUSER_ROLE_ADMIN,
  BACKUSER_ROLE_ADMIN_DEALER,
  BACKUSER_ROLE_CALL_CENTER_OPERATOR,
  BACKUSER_ROLE_FIELD_AGENT,
  BACKUSER_ROLE_CRM,
  BACKUSER_ROLE_EXTERNAL_FINE_COMPANY,
  BACKUSER_ROLE_FLEET_MANAGER,
  BACKUSER_ROLE_SERVICE_ADVISOR,
  BACKUSER_ROLE_ZONE_MANAGER
];

// selector rules == Start ==
// please use only 'include' prop here

// display (multi) list of available and selected companies
export const subCompanyListRules = {
  include: [
    BACKUSER_ROLE_FLEET_MANAGER,
    BACKUSER_ROLE_EXTERNAL_FINE_COMPANY,
    BACKUSER_ROLE_ZONE_MANAGER,
    BACKUSER_ROLE_ADMIN_DEALER,
    BACKUSER_ROLE_CRM
  ]
};

// display super company (single) select   if user role match this list
export const superCompanySelectRules = { include: [...subCompanyListRules.include, BACKUSER_ROLE_SERVICE_ADVISOR] };

// display sub company (single) select if user role match this list
export const subCompanySelectRules = { include: [BACKUSER_ROLE_SERVICE_ADVISOR] };

// display (multi) list of available and selected super companies
export const superCompanyListRules = {
  include: [BACKUSER_ROLE_ADMIN, BACKUSER_ROLE_CALL_CENTER_OPERATOR, BACKUSER_ROLE_FIELD_AGENT]
};

// selector rules == End ==

// you can use both 'exclude' and 'include' for 'backuserRoleRules'
export const backUserPageRoleRules = {
  [BACKUSER_ROLE_ROOT]: {
    include: ALL
  },
  [BACKUSER_ROLE_SUPER_ADMIN]: {
    exclude: [BACKUSER_ROLE_ROOT]
  },
  [BACKUSER_ROLE_ADMIN]: {
    exclude: [BACKUSER_ROLE_ROOT, BACKUSER_ROLE_SUPER_ADMIN, BACKUSER_ROLE_CALL_CENTER_OPERATOR]
  },
  [BACKUSER_ROLE_ZONE_MANAGER]: {
    exclude: [
      BACKUSER_ROLE_ROOT,
      BACKUSER_ROLE_SUPER_ADMIN,
      BACKUSER_ROLE_ADMIN,
      BACKUSER_ROLE_CALL_CENTER_OPERATOR,
      BACKUSER_ROLE_EXTERNAL_FINE_COMPANY
    ]
  },
  [BACKUSER_ROLE_ADMIN_DEALER]: {
    include: [BACKUSER_ROLE_ADMIN_DEALER, BACKUSER_ROLE_CRM, BACKUSER_ROLE_SERVICE_ADVISOR, BACKUSER_ROLE_FIELD_AGENT]
  },
  [BACKUSER_ROLE_CALL_CENTER_OPERATOR]: {
    include: [BACKUSER_ROLE_FLEET_MANAGER, BACKUSER_ROLE_CALL_CENTER_OPERATOR]
  },
  [BACKUSER_ROLE_CRM]: {
    include: [BACKUSER_ROLE_CRM, BACKUSER_ROLE_SERVICE_ADVISOR]
  },
  [BACKUSER_ROLE_SERVICE_ADVISOR]: {
    include: [BACKUSER_ROLE_SERVICE_ADVISOR]
  },
  [BACKUSER_ROLE_FLEET_MANAGER]: { exclude: ALL },
  [BACKUSER_ROLE_EXTERNAL_FINE_COMPANY]: { exclude: ALL },
  [BACKUSER_ROLE_FIELD_AGENT]: { exclude: ALL }
};

const backuserRolesBase = [
  { labelKey: 'user_role_group_super_company', roles: [BACKUSER_ROLE_ROOT, BACKUSER_ROLE_SUPER_ADMIN, BACKUSER_ROLE_ADMIN] },
  {
    labelKey: 'user_role_group_company',
    roles: [
      BACKUSER_ROLE_ZONE_MANAGER,
      BACKUSER_ROLE_ADMIN_DEALER,
      BACKUSER_ROLE_CRM,
      BACKUSER_ROLE_SERVICE_ADVISOR,
      BACKUSER_ROLE_FLEET_MANAGER
    ]
  },
  {
    labelKey: 'user_role_group_other',
    roles: [BACKUSER_ROLE_EXTERNAL_FINE_COMPANY, BACKUSER_ROLE_CALL_CENTER_OPERATOR, BACKUSER_ROLE_FIELD_AGENT]
  }
];

// returns true if allowed
export const checkRole = memoizeOne((rules, role) => {
  const { include, exclude } = rules || {};
  if (include === ALL) return true;
  if (exclude === ALL) return false;
  if (_includes(include, role)) return true;
  if (_includes(exclude, role)) return false;
  return _isEmpty(include) && !_isEmpty(exclude);
});

export const getBackuserRoles = currentRole => {
  const rules = backUserPageRoleRules[currentRole] || {};
  return backuserRolesBase.map(section => ({
    ...section,
    roles: section.roles.filter(role => checkRole(rules, role))
  }));
};

export const getBackuserKeys = (sections = []) =>
  sections.map((section = {}) => {
    return {
      ...section,
      roles: section.roles.map(role => ({
        labelKey: 'backUsers_role_' + role,
        value: role
      }))
    };
  });
