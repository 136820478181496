import React, { Component, PropTypes } from 'react';
import { addOpenNewTabEvents, getShortId, hideButtonHoverMsg, intlMsg, namedCompose, safe, throttle } from '../../../utils/utils';
import cs from 'classnames';
import Fuse from 'fuse.js';
import ReactSVG from 'react-svg';
import { injectIntl } from 'react-intl';
import pure from 'recompose/pure';

const triggerHeight = 2000;

class ListItems extends Component {
  constructor(p) {
    super(p);
    this.setVars();
    this.initState();
    this.setCallbacks();
    this.componentPropsUpdated(this.props, true);
  }

  setVars() {
    this.boundEvents = [];
  }

  initState() {
    this.state = { items: null };
  }

  setCallbacks() {
    this.setItemsRef = ref => {
      this.itemsRef = ref;
    };

    this.handleItemsScroll = () => {
      if (this.itemsRef.scrollHeight - this.itemsRef.clientHeight - this.itemsRef.scrollTop < triggerHeight) {
        safe(() => this.props.onScrollToBottom());
      }
    };

    this.localSearchEnabled = (props = this.props) => {
      return props.localSearchEnabled && !props.autoCompleteEnabled;
    };

    this.handleSelected = (item, index) => {
      const { selected } = this.props;
      return typeof selected === 'function' ? selected(item, index) : selected === index;
    };

    this.handleActive = (item, index) => {
      const { active } = this.props;
      return typeof active === 'function' ? active(item, index) : active === index;
    };

    this.fuseSearch = raw => {
      const input = safe(() => raw.trim());
      if (input) {
        const result = this.fuse.search(input);
        this.setState({ items: result });
      } else {
        this.setState({ items: null });
      }
    };

    this.getItemWrap = (item, index) => {
      const { id } = item || {};
      const { onSelect } = this.props;
      const callback = openInNewTab => onSelect(item, index, { openInNewTab });
      const isActive = this.handleActive(item, index);

      return (
        <div
          key={getShortId(id) + '-' + index}
          className={cs('sc-item-wrap', {
            selected: this.handleSelected(item, index),
            active: isActive,
            'main-bg': isActive,
            'main-border': isActive
          })}
        >
          <div className={cs('sc-item-inner', { pointer: onSelect })} {...addOpenNewTabEvents(callback)}>
            {this.props.renderItem(item, index)}
          </div>
          {this.getEditButton(item, index)}
        </div>
      );
    };
  }

  getEditButton(item = {}, index) {
    const { onEdit } = this.props;
    const callback = openInNewTab => onEdit(item, index, { openInNewTab });

    if (onEdit) {
      return (
        <button className="sc-edit" {...addOpenNewTabEvents(callback)}>
          <ReactSVG src="/img/edit.svg" className="align-svg" />
        </button>
      );
    }
  }

  noItemsFound() {
    return <div className="no-result">{intlMsg(this, 'common_no_result')}</div>;
  }

  showCount(itemsCount) {
    const { totalResults } = this.props;

    if (totalResults) {
      return (
        <span className="sc-count">
          <strong>— {itemsCount}</strong> {intlMsg(this, 'paginationControls_outOf')} <strong>{totalResults}</strong>
        </span>
      );
    }
    return <span className="sc-main">({itemsCount})</span>;
  }

  componentDidMount() {
    this.bindEvents();
  }

  bindEvent(el, name, func) {
    el.addEventListener(name, func);
    this.boundEvents.push({ el, name, func });
  }

  bindEvents() {
    this.bindEvent(this.itemsRef, 'scroll', this.handleItemsScroll);
  }

  clearEvents() {
    this.boundEvents.forEach(({ el, name, func }) => {
      el.removeEventListener(name, func);
    });
  }

  componentWillUnmount() {
    hideButtonHoverMsg();
    this.clearEvents();
  }

  componentWillReceiveProps(props) {
    this.componentPropsUpdated(props);
  }

  componentPropsUpdated(props, init) {
    this.updateItems(props, init);
  }

  updateItems(props, init) {
    if (this.localSearchEnabled(props)) {
      const { input } = props;
      if (this.itemsUpdated(props, init)) {
        this.fuse = new Fuse(props.items, { keys: [props.localSearchKey] });
        if (!init) this.fuseSearch(input);
      } else if (this.inputUpdated(props)) {
        this.fuseSearch(input);
      }
    }
  }

  inputUpdated(props) {
    return props.input !== this.props.input;
  }

  itemsUpdated(props, init) {
    return init || props.items !== this.props.items;
  }

  getItems() {
    const predicate = (data, index) => this.getItemWrap(data.item, index);
    const sorted = () => safe(() => this.state.items.map(predicate));
    const regular = () => safe(() => this.props.items.map(this.getItemWrap));
    return sorted() || regular();
  }

  render() {
    const items = this.getItems();
    const itemsCount = safe(() => items.length);
    const itemsClass = cs('sc-items', { 'no-result': itemsCount <= 0 });

    return (
      <div className="sc-body">
        <div className="sc-title">
          <span className="sc-main">{this.props.title}</span> {this.showCount(itemsCount)}
        </div>
        {this.props.header}
        <div ref={this.setItemsRef} className={itemsClass}>
          {itemsCount > 0 ? items : this.noItemsFound()}
        </div>
      </div>
    );
  }
}

/*
  selected: index || (item) => bool
  active: index || (item) => bool
  onEdit: (item, index)
  onSelect: (item, index)
 */

ListItems.propTypes = {
  renderItem: PropTypes.func,
  selected: PropTypes.oneOfType([PropTypes.func, PropTypes.number]),
  active: PropTypes.oneOfType([PropTypes.func, PropTypes.number]),
  onSelect: PropTypes.func,
  onEdit: PropTypes.func,
  items: PropTypes.array,
  header: PropTypes.any,
  title: PropTypes.any,
  input: PropTypes.string,
  localSearchKey: PropTypes.string,
  autoCompleteEnabled: PropTypes.bool,
  localSearchEnabled: PropTypes.bool,
  onScrollToBottom: PropTypes.func,
  totalResults: PropTypes.number
};

ListItems = namedCompose(injectIntl, pure)(ListItems);

export default ListItems;
