import React, { Component, PropTypes } from 'react';
import ListModal from '../../components/_v2/ColumnList/Modal';
import AddSite from './AddSite';
import { getAddModalTitle } from '../../components/_v2/ColumnList/helpers';
import { getMsg } from '../../utils/IntlGlobalProvider';
import { getValues } from 'redux-form';
import { connect } from 'react-redux';
import { getSiteDetails, copySite } from '../../actions/sites-actions';
import CopySiteForm from '../_v2/Organization/Details/Site/CopySiteForm';

class AddSiteModal extends Component {
  constructor(props) {
    super(props);
    this.getTitle = this.getTitle.bind(this);
    this.prepareCopy = this.prepareCopy.bind(this);
    this.state = { isCopy: false };
  }

  getTitle() {
    return getAddModalTitle(getMsg('common_site'));
  }

  prepareCopy() {
    this.setState({ isCopy: true });
    const { siteId } = getValues(this.props.copySiteForm);
    this.props.dispatch(getSiteDetails(siteId)).then(data => {
      this.props.dispatch(copySite(data));
    });
  }

  render() {
    const { list, isCopy, className } = this.props;

    const options = list.map(i => {
      return { label: i.name, value: i.id };
    });
    return (
      <ListModal title={this.getTitle()} isOpen={this.props.isOpen} onClose={this.props.onClose} className={isCopy ? className : ''}>
        {isCopy && options.length >= 0 && !this.state.isCopy && (
          <div>
            <CopySiteForm onCallback={this.prepareCopy} />
          </div>
        )}
        {(!isCopy || this.state.isCopy) && (
          <AddSite
            isModal
            company={this.props.company}
            onCancel={this.props.onClose}
            onSubmit={this.props.onClose}
            initialValues={this.props.initialValues}
            readOnlyCompany={this.props.readOnlyCompany}
          />
        )}
      </ListModal>
    );
  }
}

AddSiteModal.propTypes = {
  initialValues: PropTypes.object,
  readOnlyCompany: PropTypes.bool,
  company: PropTypes.object,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  isCopy: PropTypes.bool
};

export default connect(state => {
  const {
    sites: { list },
    form: { copySiteForm }
  } = state;

  return {
    list,
    copySiteForm
  };
})(AddSiteModal);
