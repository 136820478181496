import React, { Component } from 'react';
import { connect } from 'react-redux';
import { routeActions } from 'react-router-redux';

import ModelForm from '../../components/ModelForm/ModelForm';
import { createModelRequest, showSuccessMsg } from '../../actions/all-actions';
import routes from '../../constants/routes-constants';

class AddModel extends Component {
  componentWillMount() {
    this.handleCreateModel = ({ name }) => {
      const {
        dispatch,
        currentBrand: { id }
      } = this.props;

      dispatch(createModelRequest({ id, name, brandId: id })).then(() => {
        dispatch(showSuccessMsg({ key: 'modelForm_create_model_success', delay: true }));
        dispatch(routeActions.push(routes.editBrand.path.replace(':brandId', id)));
      });
    };
  }

  render() {
    return (
      <div className="addModelPage mainContainer_content">
        <div className="pageContainer">
          <div className="addModelPage_content">
            <section className="addModelPage_management">
              <ModelForm onCallback={this.handleCreateModel} />
            </section>
          </div>
        </div>
      </div>
    );
  }
}

AddModel.displayName = 'AddModel';

export default connect(state => {
  const {
    brands: { currentBrand }
  } = state;

  return { currentBrand };
})(AddModel);
