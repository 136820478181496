import React, { Component } from 'react';
import { showParentView } from '../../../../components/_v2/ColumnList/helpers';
import VoucherDetail from '../../../../containers/Voucher/VoucherDetail/VoucherDetail';
import ListDetails from '../../../../components/_v2/ColumnList/Details';
import { getMsg } from '../../../../utils/IntlGlobalProvider';

export class VouchersEditGroup extends Component {
  componentWillMount() {
    this.showParentView = showParentView.bind(this);
  }

  render() {
    return (
      <ListDetails title={getMsg('voucher_group_detail')} onClose={this.showParentView}>
        <VoucherDetail isExternal params={this.props.params} />
      </ListDetails>
    );
  }
}

export default VouchersEditGroup;
