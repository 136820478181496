/* eslint-disable react/no-set-state */

// noinspection ES6CheckImport
import React, { Component, PropTypes } from 'react';

import { connect } from 'react-redux';
import { namedCompose } from '../../../utils/utils';
import { VictoryPie } from 'victory-pie';
import _filter from 'lodash/filter';
import _map from 'lodash/map';
import _get from 'lodash/get';
import _head from 'lodash/head';
import _reduce from 'lodash/reduce';
import autoBind from 'react-autobind';
import { FormattedMessage, injectIntl } from 'react-intl';
import { vehicleStatusesLocales } from '../../../constants/options-constants';
import { intlMsg } from '../../../utils/utils';
import moment from 'moment';
import victoryTheme from '../victoryTheme';
import PieLegend from './PieLegend';
import Measure from 'react-measure';
import { colorBlack, colorWhite, vehicleStatusColors } from '../../../constants/style-constants';

class VehicleStatusesPie extends Component {
  constructor(props) {
    super(props);
    autoBind(this);

    this.pieStyle = {
      labels: {
        fill: colorWhite.color
      },
      data: {
        fill: this.mapFill
      }
    };

    this.events = [
      {
        target: 'data',
        eventHandlers: {}
      }
    ];

    this.parentDimStyle = { width: 100 + '%', height: 100 + '%' };
    this.childDimStyle = { display: 'inline-block' };

    this.setLabelRadius();
    this.derivedStateFromProps(props, {});
  }

  // noinspection JSCheckFunctionSignatures
  componentWillUpdate(props, state) {
    this.derivedStateFromProps(props, state);
  }

  // can be set to custom attribute
  derivedStateFromProps(props, state) {
    this.getVehicle(props);
    this.setData(props);
    this.setPieMiddleSize(state); // p - 1
    this.setOrientation(state); // p - 2
    this.setPieInnerStyle(state); // p - 3
    this.setPieMiddleStyle(); // p - 4
    this.setPieContainerStyle(state);
  }

  setLabelRadius() {
    this.labelRadius = 100; // will affect - isEnoughSpaceForLabel
    this.isEnoughSpaceForLabel = percentage => percentage > 2; // affected by victoryTheme.js - padding
  }

  getVehicle(props) {
    const { results, selectedPieData } = props;
    const predicate = item => _get(item, 'id', '-') === _get(selectedPieData, 'id');
    this.vehicle = _filter(results, predicate);
  }

  setData(props) {
    const { data } = props;
    const info = data && data.statuses;
    const total = _reduce(info, (total, number) => total + number, 0);

    this.legendData = [];
    this.data = _map(info, (time, name) => {
      const label = this.getStatusLocale(name);
      const color = this.getColorByStatus(name);
      const humanizedTime = this.getHumanizedDate(time);

      this.legendData.push({
        label,
        color,
        time: humanizedTime
      });

      return {
        color,
        y: time,
        percentage: Math.round((time / total) * 100)
      };
    });
  }

  setPieMiddleSize(state) {
    const { pieContainerMiddleSize } = state;
    if (pieContainerMiddleSize) {
      this.pieMiddleSize = {
        width: pieContainerMiddleSize.width,
        height: pieContainerMiddleSize.height
      };
    }
  }

  setPieMiddleStyle() {
    this.pieMiddleStyle = this.parentDimStyle;
    if (this.pieMiddleSize) {
      this.pieMiddleStyle = {
        ...this.pieMiddleSize,
        display: 'flex',
        flexDirection: this.orientation === 'l' ? 'row' : 'column'
      };
    }
  }

  setOrientation() {
    if (this.pieMiddleSize) {
      const obj = this.pieMiddleSize;
      if (obj.width > obj.height) this.orientation = 'l';
      else this.orientation = 'p';
    }
  }

  setPieContainerStyle(state) {
    const { rootSize, headerSize } = state;
    this.pieContainerStyle = this.parentDimStyle;

    if (rootSize && headerSize) {
      this.pieContainerStyle = {
        width: this.parentDimStyle.width,
        height: rootSize.height - headerSize.height
      };
    }
  }

  setPieInnerStyle(state) {
    const { legendSize } = state;
    this.pieChartInnerStyle = this.parentDimStyle;

    if (legendSize && this.pieMiddleSize) {
      if (this.orientation === 'l') {
        this.pieChartInnerStyle = {
          width: this.pieMiddleSize.width - legendSize.width,
          height: this.pieMiddleSize.height
        };
      } else {
        this.pieChartInnerStyle = {
          width: this.pieMiddleSize.width,
          height: this.pieMiddleSize.height - legendSize.height
        };
      }
    }
  }

  getStatusLocale(status) {
    return intlMsg(this, vehicleStatusesLocales[status]);
  }

  getColorByStatus(status) {
    return vehicleStatusColors[status] || '#555';
  }

  mapFill({ color }) {
    return color;
  }

  getHumanizedDate(time) {
    const date = time && moment.duration(time, 'minutes');
    return date && date.isValid() && date.humanize();
  }

  formatLabels(data = {}) {
    const num = data.percentage;
    return this.isEnoughSpaceForLabel(num) ? num + ' %' : null;
  }

  displayPieChart() {
    if (this.data.length > 0) {
      return (
        <VictoryPie
          data={this.data}
          theme={victoryTheme}
          startAngle={90}
          endAngle={450}
          sortKey="y"
          style={this.pieStyle}
          events={this.events}
          labelRadius={this.labelRadius}
          labels={this.formatLabels}
        />
      );
    } else {
      return (
        <div style={{ textAlign: 'center', width: '100%', paddingTop: '20px' }}>
          <FormattedMessage id="common_no_data_available" />
        </div>
      );
    }
  }

  getPieChartInner() {
    return (
      <div style={this.pieChartInnerStyle}>
        <div style={this.parentDimStyle} className="vehicle-statuses-pie-inner">
          {this.displayPieChart()}
        </div>
      </div>
    );
  }

  handleRootResize(contentRect) {
    this.setState({ rootSize: contentRect.bounds });
  }

  displayHeaderContent() {
    const { intl } = this.props;
    if (this.vehicle.length > 0) {
      const plateNumber = _head(this.vehicle).registrationNumber;
      return (
        <span>
          <span style={colorBlack}>
            <FormattedMessage id="data.with.colon" values={{ data: intl.formatMessage({ id: 'vehiclesFiltersForm_plateNumber' }) }} />
          </span>
          <span> </span>
          <span>{plateNumber} </span>
        </span>
      );
    }
  }

  displayHeader({ measureRef }) {
    return (
      <div className="advancedList_header" ref={measureRef}>
        {this.displayHeaderContent()}
      </div>
    );
  }

  handleHeaderResize(contentRect) {
    this.setState({ headerSize: contentRect.bounds });
  }

  handleLegendResize(contentRect) {
    this.setState({ legendSize: contentRect.bounds });
  }

  getPieLegend({ measureRef }) {
    return (
      <div style={{ flexShrink: 0 }}>
        <div style={this.childDimStyle} ref={measureRef}>
          <PieLegend legendData={this.legendData} />
        </div>
      </div>
    );
  }

  handlePieContainerMiddle(contentRect) {
    this.setState({ pieContainerMiddleSize: contentRect.bounds });
  }

  displayPieContainerMiddle({ measureRef }) {
    return (
      <div style={this.parentDimStyle} ref={measureRef}>
        <div style={this.pieMiddleStyle}>
          <Measure bounds onResize={this.handleLegendResize}>
            {this.getPieLegend}
          </Measure>
          {this.getPieChartInner()}
        </div>
      </div>
    );
  }

  displayRoot({ measureRef }) {
    return (
      <div className="vehicle-statuses-pie-component">
        <div className="advancedList_container">
          <div ref={measureRef} style={this.parentDimStyle}>
            <Measure bounds onResize={this.handleHeaderResize}>
              {this.displayHeader}
            </Measure>
            <div style={this.pieContainerStyle}>
              <div style={this.parentDimStyle} className="vehicle-statuses-pie-container">
                <Measure bounds onResize={this.handlePieContainerMiddle}>
                  {this.displayPieContainerMiddle}
                </Measure>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <Measure bounds onResize={this.handleRootResize}>
        {this.displayRoot}
      </Measure>
    );
  }
}

VehicleStatusesPie.propTypes = {
  data: PropTypes.any
};

VehicleStatusesPie.displayName = 'VehicleStatusesPie';

export default namedCompose(
  connect(state => {
    const {
      vehicleStatuses: { vehicleStatusesDates, selectedPieData, results }
    } = state;

    return {
      vehicleStatusesDates,
      selectedPieData,
      results
    };
  }),
  injectIntl
)(VehicleStatusesPie);
