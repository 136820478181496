import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import thunkMiddleware from 'redux-thunk';
import createLogger from 'redux-logger';
import { reducer as formReducer } from 'redux-form';
import { syncHistory, routeReducer } from 'react-router-redux';
import { history } from '../routing/all-routing';
import SiteForm from '../components/SiteForm/SiteForm';
import appReducers from '../reducers/all-reducers';
import config from '../constants/config-constants';
import { searchBooking } from '../reducers/form-plugin';
import CustomFieldForm from '../components/CustomFieldForm/CustomFieldForm';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const reducer = combineReducers(
  Object.assign({}, appReducers, {
    routing: routeReducer,
    form: formReducer
      .normalize({
        siteForm: SiteForm.normalizedInputs,
        customField: CustomFieldForm.normalizedInputs
      })
      .plugin({
        VehiclePlanningCreateBooking: searchBooking,
        VehiclePlanningEditBooking: searchBooking
      })
  })
);

let createStoreWithMiddleware = composeEnhancers(applyMiddleware(thunkMiddleware))(createStore);

if (config.useLogger) {
  createStoreWithMiddleware = applyMiddleware(createLogger())(createStoreWithMiddleware);
}

let store;

export default function initializeStore() {
  // Sync dispatched route actions to the history
  const reduxRouterMiddleware = syncHistory(history);
  createStoreWithMiddleware = applyMiddleware(reduxRouterMiddleware)(createStoreWithMiddleware);
  store = createStoreWithMiddleware(reducer);

  // Required for replaying actions from devtools to work
  reduxRouterMiddleware.listenForReplays(store);

  return store;
}

export function getStore() {
  return store;
}
