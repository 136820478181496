import { safe } from '../utils/utils';
import values from 'lodash/mapValues';
import urlJoin from 'url-join';
import config from './config-constants';
import Color from 'color';

export function s3(value) {
  if (typeof value === 'string') {
    const {
      aws: { baseUrl, configFolder }
    } = config;

    return value.replace('s3:/', urlJoin(baseUrl, configFolder));
  }
}

function imp(value) {
  if (value) return `${value} !important`;
}

function handleValue(value) {
  if (typeof value === 'object') {
    return getProps(value);
  }
  return imp(s3(value));
}

function getProps(props) {
  return values(props, handleValue);
}

function getClasses(classes) {
  return values(classes, getProps);
}

function logoProps() {
  return {
    width: imp('auto'),
    'max-width': imp('130px'),
    'max-height': imp('30px')
  };
}

function multi(...args) {
  return args.join(', ');
}

function getVpColor1(theme) {
  return safe(() => theme.vehiclePlanning.color1) || safe(() => theme.color);
}

function getVpColor2(theme) {
  return safe(() => theme.vehiclePlanning.color2) || safe(() => alter(theme.color, 0.2));
}

function getVpColorDisabled(theme) {
  return safe(() => theme.vehiclePlanning.colorDisabled) || '#f7f7f7';
}

function alter(color, ratio = 0.12) {
  return safe(() => {
    const lib = Color(color);
    const altered = lib.isLight() ? lib.darken(ratio) : lib.lighten(ratio);
    return altered.hex();
  });
}

function getPrimaryButton(theme) {
  const { primary } = safe(() => theme.buttons) || {};
  if (primary) return getProps(primary);
  const { color } = theme || {};

  return {
    color: imp('white'),
    background: imp(color),
    'border-color': imp(color),
    '&:hover': {
      background: imp(alter(color)),
      'border-color': imp(alter(color))
    }
  };
}

function getOutlinedButton(theme) {
  const { outlined } = safe(() => theme.buttons) || {};
  if (outlined) return getProps(outlined);
  const { color } = theme || {};

  return {
    background: imp('white'),
    border: imp(`2px solid ${color}`),
    color: imp(color),
    '&:hover': {
      background: imp('#e1e1e1'),
      'border-color': imp(color)
    }
  };
}

function getTextButton(theme) {
  const { text } = safe(() => theme.buttons) || {};
  if (text) return getProps(text);
  const { color } = theme || {};
  return { color: imp(color) };
}

// TODO: check why we need: width: '60% !important' | move to static CSS if not required
export default theme => ({
  '@global': {
    '.ekButton': {
      '&:not(:disabled):not(&--reverse)': getPrimaryButton(theme),
      '&--reverse:not(:disabled)': getOutlinedButton(theme),
      '&.voucher_button': {
        width: '60% !important'
      }
    },
    ':root': {
      '--ss-primary-color': safe(() => imp(theme.color))
    },
    [multi('.advancedList_actions', '.vehicle-statuses-page .actions', '.voucher-detail-buttons')]: {
      '& button:not(:disabled):not(.ekButton)': getTextButton(theme)
    },
    '.flatButton': getTextButton(theme),
    '.advancedList_filtersButton:not(.disabled)': getPrimaryButton(theme),
    '.numbered-block': {
      [multi('& .icon', '& .number')]: {
        color: safe(() => imp(theme.color))
      }
    },
    '.tabs-rci .tabPre:after': {
      background: safe(() => imp(theme.color))
    },
    '.tabs-rci .tab-list-active': {
      color: safe(() => imp(theme.color))
    },
    '.tabs-rci .tab-list-active div hr': {
      background: safe(() => imp(theme.color))
    },
    '.main-bg': {
      background: safe(() => imp(theme.color))
    },
    '.bookingViewV2 .url-button': {
      background: safe(() => imp(theme.color)),
      '&:hover': { background: safe(() => imp(alter(theme.color))) }
    },
    '.main-color': {
      color: safe(() => imp(theme.color))
    },
    '.main-border': {
      'border-color': safe(() => imp(theme.color))
    },
    '.VehiclePlanningEditBooking': {
      '& &': {
        '&_col1': {
          background: imp(getVpColor1(theme)),
          '& .user-detail': {
            background: imp(getVpColor1(theme))
          }
        },
        '&_col1Row2': {
          background: imp(getVpColor1(theme)),
          '& .boxedSelect:disabled': {
            color: imp(getVpColorDisabled(theme))
          }
        },
        '&_col1Row1': {
          background: imp(getVpColor2(theme)),
          '& .geosuggest__input:disabled': {
            color: imp(getVpColorDisabled(theme))
          },
          '& .ekRadio_wrapper_disabled': {
            '& .ekRadio_label': {
              color: imp(getVpColorDisabled(theme))
            }
          }
        }
      }
    },
    '.vehicleCategoryPricingWidget': {
      '&_content': { 'border-color': safe(() => imp(theme.color)) },
      '&_header': getPrimaryButton(theme)
    },
    '.sk-circle .sk-child:before': {
      background: safe(() => imp(theme.color))
    },
    '.loginForm_forgotPassword': {
      color: safe(() => imp(theme.color))
    },
    '.checkboxMaterial svg': {
      fill: safe(() => imp(theme.color))
    },
    '.boxedInput:focus:not(:disabled)': {
      'border-color': safe(() => imp(theme.color))
    },
    '.ui-wrap:not(.new-ui):not(.new-header)': {
      '& .backLink': {
        background: safe(() => imp(theme.color)),
        color: imp('white'),
        '& svg': {
          fill: imp('white')
        }
      }
    },
    '.new-ui': {
      '& .sideMenu_logo': {
        content: safe(() => imp(`url('${s3(theme.logo.sideMenu)}')`))
      },
      '& .header-companyAutoComplete-button i.company:before': {
        color: imp('#333')
      }
    },
    '.header': {
      background: safe(() => imp(theme.color)),
      '&-searchMode': {
        background: imp('white'),
        '.header-companyAutoComplete-button i.company:before': {
          color: imp('#333')
        }
      }
    },
    '.dashboardItem_statusValue': {
      color: safe(() => imp(theme.color))
    },
    '.dashboardItem_statusIcon': {
      '& .stroke': {
        stroke: safe(() => imp(theme.color))
      },
      '& .fill': {
        fill: safe(() => imp(theme.color))
      }
    },
    '.sideMenu_logo': {
      content: safe(() => imp(`url('${s3(theme.logo.header)}')`)),
      ...logoProps()
    },
    '.sideMenuMobile_logo': {
      content: safe(() => imp(`url('${s3(theme.logo.sideMenu)}')`)),
      ...logoProps()
    },
    '.loginBox': {
      'background-image': safe(() => imp(`url('${s3(theme.logo.login)}')`))
    },
    '.sideMenu_section': {
      [multi('&:hover', '&._is_active')]: {
        [multi('& > a', '&:before')]: {
          color: safe(() => imp(theme.color))
        },
        '& > button': {
          [multi('&, &:after')]: {
            color: safe(() => imp(theme.color))
          }
        }
      }
    },
    '.tabsItem--current': {
      'border-color': safe(() => imp(theme.color)),
      '& .tabsItem_label': {
        color: safe(() => imp(theme.color))
      }
    },
    '.tabsItem_button:hover': {
      '& .tabsItem_label': {
        color: safe(() => imp(theme.color))
      }
    },
    '.mapbox-complete': {
      '& input:focus:not(:disabled)': {
        'border-color': safe(() => imp(theme.color))
      }
    },
    '.ekRadio:checked:not(:disabled)': {
      '& + label:before': {
        background: safe(() => imp(theme.color))
      }
    },
    '.lds-rolling': {
      [multi('& div', '& div:after')]: {
        'border-color': safe(() => imp(theme.color)),
        'border-top-color': imp('transparent')
      }
    },
    ...safe(() => getClasses(theme.classes))
  }
});
