import { UPDATE_LOCATION } from 'react-router-redux';

import * as types from '../constants/actionTypes-constants';

export default function configuration(
  state = {
    paginatedResults: {},
    loadingList: false,
    detailConfiguration: null,
    urlParams: null,
    allConfigurations: []
  },
  action
) {
  switch (action.type) {
    case types.CONFIGURATION_GET_LIST_REQUEST:
      return { ...state, loadingList: true };

    case types.CONFIGURATION_GET_LIST_SUCCESS:
      return Object.assign({}, state, {
        paginatedResults: action.paginatedObj,
        urlParams: action.urlParams,
        loadingList: false
      });

    case types.CONFIGURATION_GET_ALL_LIST_SUCCESS: {
      return Object.assign({}, state, {
        allConfigurations: action.configurations
      });
    }

    case types.CONFIGURATION_CREATE_CONFIGURATION_REQUEST:
    case types.CONFIGURATION_CREATE_CONFIGURATION_SUCCESS:
    case types.CONFIGURATION_UPDATE_CONFIGURATION_REQUEST:
    case types.CONFIGURATION_UPDATE_CONFIGURATION_SUCCESS:
      return state;

    case types.CONFIGURATION_GET_DETAIL_CONFIGURATION_REQUEST:
      return state;

    case types.CONFIGURATION_GET_DETAIL_CONFIGURATION_SUCCESS:
      return Object.assign({}, state, {
        detailConfiguration: action.configuration
      });

    case UPDATE_LOCATION:
      if (action.payload.pathname.startsWith('/add-')) {
        return Object.assign({}, state, {
          detailConfiguration: null
        });
      }

    default:
      return state;
  }
}
