import React, { Component, PropTypes } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

import { fillWhite, fillDark } from '../../../constants/style-constants';
import SearchIcon from 'material-ui/svg-icons/action/search';
import {
  changeSearchInputText,
  searchMode,
  searchMemberName,
  searchMemberEmail,
  clearResults,
  searchBookings,
  searchVehicles,
  searchCompanies
} from '../../../actions/all-actions';
import { checkRole } from '../../../constants/backuser-role-rules';
import { companyRules } from '../../../constants/routes-constants';
import { getMsg } from '../../../utils/IntlGlobalProvider';
import { localeSelector } from '../../../selectors/all-selectors';

class Search extends Component {
  constructor(props) {
    super(props);

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleFocusInput = this.handleFocusInput.bind(this);
    this.callApis = _.debounce(this.callApis.bind(this), 500);
  }

  handleInputChange(ev) {
    const text = ev.target.value;
    this.props.dispatch(changeSearchInputText(text));

    if (text.length > 2) {
      this.callApis(text.trim());
    } else {
      this.props.dispatch(clearResults());
    }
  }

  callApis(text) {
    const { dispatch, role } = this.props;

    dispatch(searchMemberEmail(text));
    dispatch(searchMemberName(text));
    dispatch(searchBookings(text));
    dispatch(searchVehicles(text));

    if (checkRole(companyRules, role)) {
      dispatch(searchCompanies(text));
    }
  }

  handleFocusInput() {
    this.props.dispatch(searchMode(true));
  }

  render() {
    const { searchText, searchMode, searching } = this.props;

    return (
      <div className="header-searchWrap">
        <div className={(searchMode && 'header-searchInputWrap-searchMode') + ' header-searchInputWrap'}>
          <div className="header-searchInput-searchIcon">
            {searching > 0 && (
              <div style={{ width: '24px', height: '24px' }} className="lds-rolling">
                <div />
              </div>
            )}
            {!searching && <SearchIcon style={searchMode ? fillDark : fillWhite} />}
          </div>
          <input
            type="text"
            className={(searchMode && 'header-searchInput-searchMode') + ' header-searchInput'}
            placeholder={getMsg('header.label.search')}
            value={searchText}
            onFocus={this.handleFocusInput}
            onChange={this.handleInputChange}
          />
        </div>
      </div>
    );
  }
}

Search.displayName = 'Search';

Search.propTypes = {
  searchText: PropTypes.string,
  searchMode: PropTypes.bool,
  searching: PropTypes.number
};

export default connect(state => {
  const {
    header: { searchText, searchMode },
    searchResults: { searching },
    user: {
      userInfo: { role }
    }
  } = state;

  return {
    locale: localeSelector(state), // to trigger getMsg
    searchText,
    searchMode,
    searching,
    role
  };
})(Search);
