import { getStore } from '../../../../store/all-store';
import { routeActions } from 'react-router-redux';
import routes from '../../../../constants/routes-constants';

const tableViewParams = {
  namespace: 'subCompanies',
  sortIsDescending: false,
  columns: [
    {
      messageKey: 'sub_companies_tableView_label_name',
      content: 'name'
    },
    {
      messageKey: 'sub_companies_tableView_label_email',
      content: 'email'
    },
    {
      messageKey: 'sub_companies_tableView_label_phone',
      content: item => {
        return item.phoneNumber.countryCode + item.phoneNumber.nationalNumber;
      }
    },
    {
      messageKey: 'sub_companies_tableView_label_agencyCode',
      content: 'agencyCode'
    },
    {
      messageKey: 'sub_companies_tableView_label_action',
      content: null,
      contentMessageKey: 'sub_companies_tableView_content_action',
      actionCustomClass: 'subCompanies_tableView_action',
      actionCallback: function(item) {
        getStore().dispatch(routeActions.push(routes.subCompanyDetails.path.replace(':subCompanyId', item.id)));
      }
    }
  ]
};

export default tableViewParams;
