const sep = '/';
const dot = '.';
const protocol = 'https://';
const subDomain = 'rcimob-assets';
const amazonWest = 's3-eu-west-1.amazonaws.com';
const documentsFolder = 'template';
const fileName = 'member_import_file_template';
const fileExt = '.xlsx';
const filePath = protocol + subDomain + dot + amazonWest + sep + documentsFolder + sep + fileName;

export const links = {
  uploadMemberTemplate: {
    en: filePath + '-en' + fileExt,
    fr: filePath + '-fr' + fileExt,
    es: filePath + '-en' + fileExt,
    it: filePath + '-en' + fileExt,
    pt: filePath + '-en' + fileExt,
    de: filePath + '-en' + fileExt,
    da: filePath + '-en' + fileExt,
    nl: filePath + '-en' + fileExt,
    pl: filePath + '-en' + fileExt,
    sv: filePath + '-en' + fileExt,
    cs: filePath + '-en' + fileExt,
    pt_BR: filePath + '-en' + fileExt,
    sk: filePath + '-en' + fileExt
  }
};

export function getMemberImportTemplateUrl(language) {
  return links.uploadMemberTemplate[language];
}
