import * as types from '../constants/actionTypes-constants';
import { safe } from '../utils/utils';

export default function categories(
  state = {
    list: [],
    currentCategory: null
  },
  action
) {
  switch (action.type) {
    case types.CATEGORIES_GET_CATEGORIES_SUCCESS:
      return Object.assign({}, state, {
        list: action.categories
      });

    case types.CATEGORIES_CREATE_CATEGORY_SUCCESS: {
      return { ...state, list: [{ ...action.category }, ...state.list] };
    }

    case types.CATEGORIES_UPDATE_CATEGORY_SUCCESS: {
      const { category } = action;
      const { list } = state;

      safe(() =>
        list.find((v, i, a) => {
          if (v.id === category.id) {
            a[i] = category;
          }
        })
      );

      return {
        ...state,
        currentCategory: category,
        list: safe(() => [...list]) || []
      };
    }

    case types.CATEGORIES_GET_SINGLE_CATEGORY_SUCCESS:
      return Object.assign({}, state, {
        currentCategory: action.category
      });

    default:
      return state;
  }
}
