/* eslint-disable react/jsx-no-bind */
import React, { Component, PropTypes as T } from 'react';
import { connect } from 'react-redux';
import _partial from 'lodash/partial';
import moment from 'moment';
import { injectIntl } from 'react-intl';
import autoBind from 'react-autobind';
import FlatButton from 'material-ui/FlatButton';
import AddIcon from 'material-ui/svg-icons/content/add';
import CloseIcon from 'material-ui/svg-icons/navigation/close';

import BoxedInput from '../../../components/BoxedInput/BoxedInput';
import IconButton from 'material-ui/IconButton';
import RefreshIcon from 'material-ui/svg-icons/navigation/refresh';
import LeftArrow from 'material-ui/svg-icons/hardware/keyboard-arrow-left';
import RightArrow from 'material-ui/svg-icons/hardware/keyboard-arrow-right';
import DropDownMenu from 'material-ui/DropDownMenu';
import MenuItem from 'material-ui/MenuItem';
import { Checkbox } from 'material-ui';

import {
  getDataVehiclePlanning,
  changeVehiclePlanningSetings,
  toggleCreateStatusMode,
  clearCreateBookingModal
} from '../VehiclePlanning.actions';
import { ALL } from '../../../constants/generic-constants';
import { userRoleSelector } from '../../../selectors/all-selectors';
import { BACKUSER_ROLE_FLEET_MANAGER } from '../../../constants/backend-constants';
import { vehicleUsageTypes } from '../../../constants/options-constants';
import { addUsageFilter } from '../VehiclePlanning.actions';

class VehiclePlanningSetup extends Component {
  constructor() {
    super();
    autoBind(this);
  }

  handleChange(type, value) {
    const { dispatch, periodInDays, startDate, siteId, subCompanySelected, resetParkingIndex } = this.props;
    let params = {
      periodInDays,
      startDate: moment(startDate).toISOString(),
      subCompanyId: subCompanySelected.id
    };
    if (siteId !== ALL) {
      params.siteId = siteId;
    }

    params[type] = type === 'startDate' ? moment(value).toISOString() : value;

    if (type === 'startDate' && value === startDate) {
      // TODO fix for double getting data when selecting date
      return;
    } else {
      dispatch(changeVehiclePlanningSetings(params));
      dispatch(getDataVehiclePlanning(params));
    }
    resetParkingIndex();
    dispatch(clearCreateBookingModal());
  }

  handleRefresh() {
    this.props.dispatch(getDataVehiclePlanning());
  }

  handleChangePeriod(values) {
    this.handleChange(values[0], values[1]);
  }

  handleChangeDuration(e, key, value) {
    window.localStorage.setItem('planningPeriodInDays', String(value));
    this.handleChange('periodInDays', value);
  }

  handleChangeSite(e, key, value) {
    this.handleChange('siteId', value);
    setTimeout(() => this.props.updateHeight(), 1000);
  }

  handleToggleCreateStatusMode() {
    this.props.dispatch(toggleCreateStatusMode());
  }

  filterUsage(usage) {
    this.props.dispatch(addUsageFilter(usage));
    this.props.updateHeight();
  }

  render() {
    const {
      sitesList,
      startDate,
      periodInDays,
      siteId,
      intl,
      createStatusMode,
      sideMenuOpen,
      readOnly,
      userRole,
      vehiclesUsagesInit,
      vehiclesUsagesFiltered
    } = this.props;

    const startDateField = {
      value: new Date(startDate),
      onChange: _partial(this.handleChange, 'startDate')
    };

    return (
      <div className="vehiclePlanningSetup_container_inline">
        <div className={`vehiclePlanningSetup_wrap_inline ${sideMenuOpen && 'vehiclePlanningSetup_sideMenuOpen'}`}>
          <div className="vehiclePlanningSetup_formRow">
            <span className="vehiclePlanningSetup_label">Start date</span>
            <BoxedInput
              formRowItemKey="vehiclePlanningSetup_startDate"
              type="date"
              id="vehiclePlanningSetup_startDate"
              field={startDateField}
            />
          </div>

          <div className="vehiclePlanningSetup_formRow">
            <span className="vehiclePlanningSetup_label">Period</span>
            <DropDownMenu
              value={periodInDays}
              onChange={this.handleChangeDuration}
              underlineStyle={{ border: 0 }}
              style={{ marginBottom: '8px' }}
              labelStyle={{ color: 'rgba(0,0,0,.8)' }}
            >
              <MenuItem value={1} primaryText={intl.messages.common_1day} />
              <MenuItem value={3} primaryText={intl.messages.common_3days} />
              <MenuItem value={7} primaryText={intl.messages.common_7days} />
            </DropDownMenu>
          </div>

          <div className="vehiclePlanningSetup_changePeriodWrap">
            <IconButton
              tooltipPosition="bottom-center"
              iconStyle={{ color: 'rgba(0,0,0,.8)' }}
              onClick={_partial(this.handleChangePeriod, ['startDate', moment(startDate).subtract(periodInDays, 'days')])}
            >
              <LeftArrow />
            </IconButton>

            <IconButton
              tooltipPosition="bottom-center"
              iconStyle={{ color: 'rgba(0,0,0,.8)' }}
              onClick={_partial(this.handleChangePeriod, ['startDate', moment(startDate).add(periodInDays, 'days')])}
            >
              <RightArrow />
            </IconButton>
          </div>

          <div className="vehiclePlanningSetup_formRow">
            <span className="vehiclePlanningSetup_label">Site</span>
            <DropDownMenu
              value={siteId}
              onChange={this.handleChangeSite}
              underlineStyle={{ border: 0 }}
              style={{ marginBottom: '8px' }}
              labelStyle={{ color: 'rgba(0,0,0,.8)' }}
            >
              <MenuItem value={ALL} primaryText={intl.messages.common_allSites} />
              {sitesList.map(item => (
                <MenuItem key={item.id} value={item.id} primaryText={item.name} />
              ))}
            </DropDownMenu>
          </div>
          <div className="vehiclePlanningSetup_formRow usages">
            {vehiclesUsagesInit.length > 1 &&
              vehicleUsageTypes.map(item => {
                const usageKey = 'vehicle_usage_type_' + item.key.toLowerCase();
                if (vehiclesUsagesInit.includes(item.key)) {
                  return (
                    <Checkbox
                      key={item.key}
                      className="usage"
                      style={{ display: 'flex', maxHeight: '21px', overflow: 'hidden' }}
                      iconStyle={{ display: 'flex' }}
                      title={this.props.intl.messages[usageKey]}
                      label={this.props.intl.messages[usageKey]}
                      onCheck={() => this.filterUsage(item.key)}
                      checked={vehiclesUsagesFiltered.includes(item.key)}
                      labelStyle={{ color: 'rgba(0,0,0,.65)', width: '100%', display: 'inline-block', float: 'none', marginLeft: '10px' }}
                    />
                  );
                }
              })}
          </div>
          <div className="vehiclePlanningSetup_buttonWrap">
            {(!readOnly || userRole === BACKUSER_ROLE_FLEET_MANAGER) && (
              <FlatButton
                label={intl.messages.editStatusForm_title}
                icon={createStatusMode ? <CloseIcon /> : <AddIcon />}
                onClick={this.handleToggleCreateStatusMode}
                className="flatButton"
              />
            )}
            <IconButton onClick={this.handleRefresh}>
              <RefreshIcon />
            </IconButton>
          </div>
        </div>
      </div>
    );
  }
}

VehiclePlanningSetup.displayName = 'VehiclePlanningSetup';

VehiclePlanningSetup.propTypes = {
  savedData: T.object,
  startDate: T.string,
  periodInDays: T.number,
  siteId: T.string,
  sitesList: T.array,
  resetParkingIndex: T.func,
  updateHeight: T.func,
  createBookingMode: T.bool,
  readOnly: T.bool
};

export default connect(state => {
  const {
    vehiclePlanning: { startDate, periodInDays, siteId, createBookingMode, createStatusMode, vehiclesUsagesInit, vehiclesUsagesFiltered },
    subCompanies: { subCompanySelected },
    sideMenu: { isOpen }
  } = state;

  return {
    sitesList: state.sites.list,
    startDate,
    periodInDays,
    siteId,
    vehiclesUsagesInit,
    vehiclesUsagesFiltered,
    subCompanySelected,
    createBookingMode,
    createStatusMode,
    sideMenuOpen: isOpen,
    userRole: userRoleSelector(state)
  };
})(injectIntl(VehiclePlanningSetup));
