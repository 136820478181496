import React, { Component, PropTypes } from 'react';
import { FormattedHTMLMessage, injectIntl } from 'react-intl';
import cs from 'classnames';
import BoxedInput from '../../BoxedInput/BoxedInput';
import BoxedSelect from '../../BoxedSelect/BoxedSelect';
import config from '../../../constants/config-constants';
import Delete from 'material-ui/svg-icons/action/delete';
import AddIcon from 'material-ui/svg-icons/content/add';
import _find from 'lodash/find';
import { IconButton } from 'material-ui';
import { getMsg } from '../../../utils/IntlGlobalProvider';
import KeyboardArrowDown from 'material-ui/svg-icons/hardware/keyboard-arrow-down';
import KeyboardArrowUp from 'material-ui/svg-icons/hardware/keyboard-arrow-up';
import { FlatButton } from 'material-ui';
import FieldErrorMsg from '../../FieldErrorMsg/FieldErrorMsg';

class TranslationsFields extends Component {
  constructor(props) {
    super(props);
    this.removeTranslations = this.removeTranslations.bind(this);
    this.addTranslations = this.addTranslations.bind(this);
    this.addHandler = this.addHandler.bind(this);
    this.labelHandler = this.labelHandler.bind(this);
    this.handleChangeLanguage = this.handleChangeLanguage.bind(this);
    this.handleOpen = this.handleOpen.bind(this);

    const initLanguages = config.theme.availableLanguages;
    const options = initLanguages.map(item => (item ? { language: item, disabled: false } : ' '));
    this.state = { options, isOpen: false };
  }

  handleOpen() {
    this.setState(prevState => ({
      isOpen: !prevState.isOpen
    }));
  }

  removeTranslations(index, event) {
    event.preventDefault();

    this.props.serviceNames.map((item, i) => {
      if (i === index) {
        const t = _find(this.state.options, { language: item.language.value });
        t.disabled = false;
      }
    });
    this.setState({ options: [...this.state.options] });
    this.props.serviceNames.removeField(index);
  }

  addTranslations(event) {
    event.preventDefault();

    this.props.serviceNames.map(item => {
      const t = _find(this.state.options, { language: item.language.value });
      t.disabled = true;
    });
    this.setState({ options: [...this.state.options] });
    const nextLang = this.state.options.find(option => !option.disabled);
    if (nextLang) {
      this.props.serviceNames.addField({ language: nextLang.language });
      if (!this.state.isOpen) this.handleOpen();
    }
  }

  handleChangeLanguage(value, field) {
    this.props.serviceNames.map(item => {
      if (item.language.value === field.value) {
        const t = _find(this.state.options, { language: field.value });
        t.disabled = false;
      }
      if (value === item.language.value) {
        const r = _find(this.state.options, { language: value });
        r.disabled = true;
      }
    });
    this.setState({ options: [...this.state.options] });
  }

  addHandler(withText = false) {
    const { serviceNames } = this.props;

    if (serviceNames.length < this.state.options.length) {
      if (withText) {
        return (
          <div className="add-translation">
            <FlatButton onClick={this.addTranslations} labelPosition="before" icon={<AddIcon />}>
              {getMsg('commom_add-translation')}
              {/* {!this.state.isOpen && <span>{' (' + serviceNames.length + '/' + this.state.options.length + ')'}</span>} */}
            </FlatButton>
          </div>
        );
      } else {
        return (
          <div className="add-translation">
            <IconButton onClick={this.addTranslations} tooltip={getMsg('commom_add-translation')} tooltipPosition="top-center">
              <AddIcon />
            </IconButton>
          </div>
        );
      }
    }
  }

  labelHandler() {
    const { serviceNames, keyLabel, mandatory } = this.props;
    return (
      <div style={{ display: 'inline-flex' }}>
        <FormattedHTMLMessage
          id="contract.homesetting.define-translations-for"
          values={{
            field: getMsg(keyLabel),
            count: ' (' + serviceNames.length + '/' + this.state.options.length + ')'
          }}
        />
        {mandatory && <span className="labelMandatory">*</span>}
      </div>
    );
  }

  render() {
    const {
      serviceNames,
      intl: { formatMessage },
      fieldType
    } = this.props;

    const isSimpleInput = fieldType === undefined || fieldType === 'text';
    const fieldPlaceHolderKey = isSimpleInput ? 'hotlineForm_phone_number_name_label' : 'booking_view_transaction_description';
    const langOptions = this.state.options.map(item => {
      return {
        label: formatMessage({ id: `languageName_${item.language}` }),
        value: item.language,
        disabled: item.disabled
      };
    });

    return (
      <div className={cs('translations', { area: !isSimpleInput })}>
        <FlatButton
          labelPosition="after"
          className="btn"
          onClick={this.handleOpen}
          label={this.labelHandler()}
          labelStyle={{ paddingLeft: '0', textTransform: 'initial', fontSize: '14px' }}
          style={{ width: '100%', textAlign: 'left', textTransform: 'none' }}
          icon={
            !this.state.isOpen ? (
              <KeyboardArrowDown style={{ marginLeft: '0', width: '26px', height: '26px' }} />
            ) : (
              <KeyboardArrowUp style={{ marginLeft: '0', width: '26px', height: '26px' }} />
            )
          }
        />
        {this.state.isOpen && this.state.options.length > 0 && (
          <div className="translation-labels">
            <div className="lang">{getMsg('common_language')}</div>
            <div className="text">{getMsg('customFieldSection_translations')}</div>
            {this.addHandler()}
          </div>
        )}

        {this.state.isOpen &&
          serviceNames.map((item, index) => {
            const key = 'linedd' + index;
            return (
              <div key={key} className="translation-entry">
                <BoxedSelect
                  options={langOptions}
                  field={item.language}
                  blockCustomClass="lang"
                  onChange={value => this.handleChangeLanguage(value, item.language)}
                  noEmptyValue
                  sort={false}
                />

                <BoxedInput
                  placeholderKey={fieldPlaceHolderKey}
                  customClass="boxedInputWrapper--name text"
                  field={item.translation}
                  type={fieldType}
                  autoFocus
                >
                  <FieldErrorMsg field={item.translation} customClass="fieldErrorMsg--companyForm" />
                </BoxedInput>

                <IconButton onClick={event => this.removeTranslations(index, event)}>
                  <Delete />
                </IconButton>
              </div>
            );
          })}
        {this.addHandler(true)}
      </div>
    );
  }
}

TranslationsFields.displayName = 'TranslationsFields';

TranslationsFields.propTypes = {
  serviceNames: PropTypes.array,
  fieldType: PropTypes.string,
  label: PropTypes.string,
  mandatory: PropTypes.bool,
  addTranslation: PropTypes.func,
  removeTranslation: PropTypes.func
};

export default injectIntl(TranslationsFields);
