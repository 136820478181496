import React, { Component } from 'react';
import { showParentView } from '../../../../../components/_v2/ColumnList/helpers';
import AddSiteModal from '../../../../../containers/AddSite/Modal';
import { safe } from '../../../../../utils/utils';
import { connect } from 'react-redux';

export class OrganizationAddSite extends Component {
  componentWillMount() {
    this.showParentView = showParentView.bind(this);
  }

  render() {
    return (
      <AddSiteModal
        isOpen
        company={this.props.company}
        onClose={this.showParentView}
        initialValues={{ subCompanyId: safe(() => this.props.params.subCompanyId) }}
        readOnlyCompany
      />
    );
  }
}

export default connect(state => {
  const {
    companies: { detailCompany: company }
  } = state;

  return { company };
})(OrganizationAddSite);
