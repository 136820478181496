const themeColors = {
  glide: {
    primary1Color: '#0871b9',
    primary2Color: '#0871b9',
    accent1Color: '#0871b9',
    pickerHeaderColor: '#0871b9'
  },
  renaultB2B: {
    primary1Color: '#f45000',
    primary2Color: '#f45000',
    accent1Color: '#37474f',
    pickerHeaderColor: '#f45000'
  },
  nissan: {
    primary1Color: '#008dc7',
    primary2Color: '#0076ab',
    accent1Color: '#06affb',
    pickerHeaderColor: '#0076ab'
  },
  engie: {
    primary1Color: '#00AAFF',
    primary2Color: '#00AAFF',
    accent1Color: '#0074ac',
    pickerHeaderColor: '#00AAFF'
  },
  ego: {
    primary1Color: '#3CB4E5',
    primary2Color: '#3CB4E5',
    accent1Color: '#0087c3',
    pickerHeaderColor: '#3CB4E5'
  },
  'share-mobility': {
    primary1Color: '#D5751F',
    primary2Color: '#D5751F',
    accent1Color: '#a55c00',
    pickerHeaderColor: '#D5751F'
  },
  sncf: {
    primary1Color: '#9B2743',
    primary2Color: '#9B2743',
    accent1Color: '#a55c00',
    pickerHeaderColor: '#701c30'
  },
  agilauto: {
    primary1Color: '#1AAEB7',
    primary2Color: '#1AAEB7',
    accent1Color: '#37cba8',
    pickerHeaderColor: '#1AAEB7'
  }
};

module.exports = themeColors;
