import React, { Component } from 'react';
import { reduxForm } from 'redux-form';
import { namedCompose, scrollToFirstError } from '../../utils/utils';
import { localeSelector } from '../../selectors/all-selectors';
import { FormattedMessage, injectIntl } from 'react-intl';
import BoxedInput from '../BoxedInput/BoxedInput';
import { FlatButton, RaisedButton } from 'material-ui';
import classNames from 'classnames';

class BookingStartForm extends Component {
  constructor(props) {
    super(props);
    this.bindFunctions();
  }
  bindFunctions() {
    this.state = { pendingStart: false };
    this.handleConfirm = this.handleConfirm.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
  }

  handleConfirm() {
    this.setState({ pendingStart: true });
    return this.props.confirm();
  }

  handleCancel() {
    return this.props.cancel();
  }

  handleLabel() {
    const label = this.state.pendingStart ? (
      this.props.errorReq ? (
        <FormattedMessage id="common.retry" />
      ) : (
        <FormattedMessage id="common_loading" />
      )
    ) : (
      <FormattedMessage id="common_confirm" />
    );
    return label;
  }

  render() {
    const {
      fields: { mileage },
      handleSubmit,
      errorReq
    } = this.props;

    return (
      <form className="booking-start-form" action="#" onSubmit={handleSubmit(this.handleConfirm)}>
        <h4 className="companyDetail_title">
          <FormattedMessage id="booking.start.title" />
        </h4>
        <BoxedInput name="mileage" labelKey="booking.start.mileage" field={mileage} />
        <div className="actions">
          <FlatButton
            label={<FormattedMessage id="common_cancel" />}
            onClick={this.handleCancel}
            primary
            type="button"
            id="cancel-start-booking"
          />

          <RaisedButton
            label={this.handleLabel()}
            className={classNames('start-booking', { error: errorReq })}
            onClick={this.handleConfirm}
            primary
            type="button"
            id="submit-start-booking"
          />
        </div>
      </form>
    );
  }
}

export default namedCompose(
  reduxForm(
    {
      onSubmitFail: scrollToFirstError,
      form: 'startBookingForm',
      fields: ['mileage']
    },
    state => ({ locale: localeSelector(state).toUpperCase() })
  ),
  injectIntl
)(BookingStartForm);
