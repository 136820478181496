import React, { Component } from 'react';
import autoBind from 'react-autobind';
import pure from 'recompose/pure';
import cs from 'classnames';
import { getShortId, namedCompose, safe } from '../../../utils/utils';
import { FormattedMessage } from 'react-intl';
import routes, { companyRules, bookingEditRules } from '../../../constants/routes-constants';
import { isGranted } from '../../../actions/user-actions';
import { connect } from 'react-redux';
import { localeSelector, headerContractMemberValuesSelector } from '../../../selectors/all-selectors';
import CopyToClipBoard from '../CopyToClipBoard/CopyToClipBoard';
import { vehicleUsage } from '../../../constants/options-constants';
import {
  BOOKING_STATUS_COMPLETED,
  BOOKING_STATUS_IN_PROGRESS,
  BOOKING_STATUS_PRE_BOOKED,
  BOOKING_STATUS_SCHEDULED,
  BOOKING_USAGE_TYPE_PRIVATE
} from '../../../constants/backend-constants';
import { getMsg } from '../../../utils/IntlGlobalProvider';
import { RaisedButton } from 'material-ui';
import IsGranted from '../../IsGranted/IsGranted';
import ReactSVG from 'react-svg';

class BookingInfoV2 extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.derivedStateFromProps(props);
  }

  // eslint-disable-next-line no-unused-vars
  componentWillReceiveProps(nextProps, nextContext) {
    this.derivedStateFromProps(nextProps);
  }

  derivedStateFromProps(nextProps) {
    const { companyId, memberId, score = {} } = nextProps;

    this.companyHref = safe(() =>
      companyId &&
      isGranted({
        disallowed: companyRules.exclude,
        allowed: companyRules.include
      })
        ? `#${routes.companyDetail.path.replace(':companyId', companyId)}`
        : undefined
    );

    this.userHref = memberId ? `#${routes.memberDetail.path.replace(':memberId', memberId)}` : undefined;
    this.npsValue = score.value || 0;
    this.classNameNps = this.npsValue >= 9 ? 'green' : this.npsValue <= 6 ? 'red' : 'orange';
    this.txtComment = score.comment && score.comment !== '' ? <div className="comment">&laquo; {score.comment} &raquo;</div> : null;
  }

  addUrl(child, href) {
    if (href) return <a href={href}>{child}</a>;
    else return child;
  }

  userType() {
    const { bookingMemberType, memberRules } = this.props;
    if (bookingMemberType && memberRules.type) {
      return (
        <div className="row-info">
          <span className="label">
            <FormattedMessage id="booking_view_group" />
          </span>
          <span className="value">{bookingMemberType}</span>
        </div>
      );
    }
  }

  companyName() {
    const { companyName } = this.props;

    if (companyName)
      return (
        <div className="row-info">
          <span className="label">
            <FormattedMessage id="booking_view_environment" />
          </span>
          <span className="value">{this.addUrl(companyName, this.companyHref)}</span>
        </div>
      );
  }

  nps() {
    if (this.npsValue)
      return (
        <div className="row-info">
          <span className="label">
            <FormattedMessage id="booking_view_nps" />
          </span>
          <span className={cs('value', 'nps-bullet', this.classNameNps)}>{this.npsValue}/10</span>
        </div>
      );
  }

  bookingId() {
    const { bookingId } = this.props;
    const shortID = getShortId(bookingId);
    if (bookingId)
      return (
        <div className="row-info">
          <span className="label">
            <FormattedMessage id="booking_view_bookingId" />
          </span>
          <span className="value">
            {shortID}
            <CopyToClipBoard stringToCopy={shortID} keyCopy="booking_view_copy_bookingId" keyCopied="booking_view_copied_bookingId" />
          </span>
        </div>
      );
  }

  price() {
    const { price, currency, freeOfCharges, updatePrice } = this.props;

    const contextPrice = freeOfCharges ? (
      <FormattedMessage id="booking_view_free" />
    ) : (
      <span>
        <FormattedMessage id="common_price_with_currency" values={{ amount: price, currency: getMsg(`unit_${currency}`) }} />
        {updatePrice && (
          <IsGranted disallowed={bookingEditRules.exclude}>
            <span onClick={updatePrice}>
              <ReactSVG src="/img/edit.svg" className="align-svg" />
            </span>
          </IsGranted>
        )}
      </span>
    );

    if (price) {
      return (
        <div className="row-info">
          <span className="label">{this.priceLabel()}</span>
          <span className="value">{contextPrice}</span>
        </div>
      );
    }
  }

  priceLabel() {
    const { bookingStatus } = this.props;
    if (bookingStatus === BOOKING_STATUS_SCHEDULED || bookingStatus === BOOKING_STATUS_IN_PROGRESS)
      return <FormattedMessage id="booking_view_estimated_price" />;
    else return <FormattedMessage id="booking_view_final_price" />;
  }

  bookingUsage() {
    const { bookingUsage } = this.props;
    if (bookingUsage)
      return (
        <div className="row-info">
          <span className="label">
            <FormattedMessage id="booking_view_usage" />
          </span>
          <span className="value">
            <FormattedMessage id={vehicleUsage[bookingUsage]} />
          </span>
        </div>
      );
  }

  header() {
    const { bookingStatus, delayedBooking } = this.props;
    const bookingStatusClass = 'booking_status_' + String(bookingStatus).toLowerCase();

    return (
      <span className={`booking-info-v2_status ${bookingStatusClass}`}>
        <FormattedMessage id={'booking_view_status_' + bookingStatus} defaultMessage="common_unknown" />
        {delayedBooking && (
          <span style={{ paddingLeft: '5px', color: 'red' }}>
            <FormattedMessage id="booking_detail_delayed" />
          </span>
        )}
      </span>
    );
  }

  bookingActions() {
    const { bookingStatus, bookingUsageType } = this.props;

    const isPrivate = bookingUsageType === BOOKING_USAGE_TYPE_PRIVATE;
    if (
      bookingStatus === BOOKING_STATUS_IN_PROGRESS ||
      bookingStatus === BOOKING_STATUS_SCHEDULED ||
      bookingStatus === BOOKING_STATUS_PRE_BOOKED ||
      bookingStatus === BOOKING_STATUS_COMPLETED
    ) {
      return (
        <IsGranted disallowed={bookingEditRules.exclude}>
          <div className="actions">
            {bookingStatus !== BOOKING_STATUS_COMPLETED && this.props.cancelBooking && (
              <RaisedButton
                label={<FormattedMessage id="booking_detail_cancel_booking" />}
                primary
                type="button"
                onClick={this.props.cancelBooking}
              />
            )}
            {bookingStatus === BOOKING_STATUS_SCHEDULED && this.props.editBooking && (
              <RaisedButton
                label={<FormattedMessage id="booking_detail_edit_booking" />}
                primary
                type="button"
                onClick={this.props.editBooking}
              />
            )}
            {bookingStatus === BOOKING_STATUS_SCHEDULED && this.props.openStartBooking && (
              <RaisedButton
                label={<FormattedMessage id="booking_detail_start_booking" />}
                primary
                type="button"
                onClick={this.props.openStartBooking}
              />
            )}
            {bookingStatus === BOOKING_STATUS_COMPLETED && isPrivate && this.props.updatePrice && (
              <RaisedButton label={<FormattedMessage id="common_update_price" />} primary type="button" onClick={this.props.updatePrice} />
            )}
            {bookingStatus === BOOKING_STATUS_IN_PROGRESS && this.props.extendBooking && (
              <RaisedButton
                label={<FormattedMessage id="booking_detail_extend_shorten_booking" />}
                primary
                type="button"
                onClick={this.props.extendBooking}
              />
            )}
            {bookingStatus === BOOKING_STATUS_IN_PROGRESS && this.props.finishBooking && (
              <RaisedButton
                label={<FormattedMessage id="booking_detail_finish_booking" />}
                primary
                type="button"
                onClick={this.props.finishBooking}
              />
            )}
          </div>
        </IsGranted>
      );
    }
  }

  render() {
    return (
      <div className="booking-info-v2">
        <h4 className="booking-info-v2_headline">{this.header()}</h4>
        <div className="booking-info-v2_wrap">
          {this.bookingId()}
          {this.price()}
          {this.companyName()}
          {this.userType()}
          {this.bookingUsage()}
          {this.nps()}
          {this.txtComment !== '' && this.txtComment}
        </div>
        {this.bookingActions()}
      </div>
    );
  }
}

BookingInfoV2.defaultProps = {
  bookingStatus: undefined, //String
  bookingMemberType: undefined, // String
  bookingUsageType: undefined, // String
  companyName: undefined, // String
  bookingId: undefined, // String
  companyId: undefined, // String
  price: undefined, // string
  freeOfCharges: false, // bool
  memberId: undefined, // String
  score: undefined, // String
  delayedBooking: false, // bool
  pendingStart: false, // bool
  cancelBooking: undefined,
  updatePrice: undefined,
  openStartBooking: undefined
};

export default namedCompose(
  connect(state => {
    return {
      locale: localeSelector(state), // pure exception,
      memberRules: headerContractMemberValuesSelector(state)
    };
  }),
  pure
)(BookingInfoV2);
