import React, { Component, PropTypes } from 'react';
import { FormattedMessage } from 'react-intl';
import { reduxForm } from 'redux-form';

import FormRow from '../FormRow/FormRow';
import BoxedInput from '../BoxedInput/BoxedInput';
import LabeledCheckbox from '../LabeledCheckbox/LabeledCheckbox';
import EkButton from '../EkButton/EkButton';
import FieldErrorMsg from '../FieldErrorMsg/FieldErrorMsg';
import { createValidator, notEmpty } from '../../validation/all-validation';
import { scrollToFirstError } from '../../utils/utils';

class ContractForm extends Component {
  constructor(props) {
    super(props);
    this.preventSubmit = this.preventSubmit.bind(this);
  }

  preventSubmit(e) {
    e.preventDefault();
  }

  render() {
    const {
      fields: {
        name,
        reference,
        startDate,
        endDate,
        businessCarSharing,
        privateCarSharing,
        rideSharing,
        preBooking,
        interfaceConfigDto,
        flexibleSearch
      },
      handleSubmit,
      company,
      limitedAccess
    } = this.props;

    const submitAction = limitedAccess ? this.preventSubmit : handleSubmit(this.props.onCallback);

    return (
      <form action="#" className="contractForm" onSubmit={submitAction}>
        <h4 className="siteForm_title">
          <FormattedMessage id="contractForm_title" />
        </h4>

        <dl className="contractForm_definitionList">
          <dt className="contractForm_definitionList_term">
            <FormattedMessage id="contractForm_currentCompany" />
          </dt>
          <dd className="contractForm_definitionList_definition">{company.name}</dd>
        </dl>

        <FormRow customWrapperClass="contractForm_row">
          <BoxedInput
            formRowItemKey="contractForm_name"
            type="text"
            id="contractForm_name"
            labelKey="contractForm_name"
            field={name}
            disabled={limitedAccess}
          >
            <FieldErrorMsg customClass="fieldErrorMsg--contractForm" field={name} />
          </BoxedInput>

          <BoxedInput
            formRowItemKey="contractForm_reference"
            type="text"
            id="contractForm_reference"
            labelKey="contractForm_reference"
            field={reference}
            disabled={limitedAccess}
          >
            <FieldErrorMsg customClass="fieldErrorMsg--contractForm" field={reference} />
          </BoxedInput>
        </FormRow>

        <FormRow customWrapperClass="contractForm_row">
          <BoxedInput
            formRowItemKey="contractForm_startDate"
            type="date"
            id="contractForm_startDate"
            labelKey="contractForm_startDate"
            field={startDate}
            disabled={limitedAccess}
          >
            <FieldErrorMsg customClass="fieldErrorMsg--contractForm" field={startDate} />
          </BoxedInput>

          <BoxedInput
            formRowItemKey="contractForm_endDate"
            type="date"
            id="contractForm_endDate"
            labelKey="contractForm_endDate"
            field={endDate}
            disabled={limitedAccess}
          >
            <FieldErrorMsg customClass="fieldErrorMsg--contractForm" field={endDate} />
          </BoxedInput>
        </FormRow>
        <FormRow customWrapperClass="contractForm_row_options">
          <div>
            <dl className="contractForm_definitionList">
              <dt className="contractForm_definitionList_term">
                <FormattedMessage id="contractForm_options" />
              </dt>
              <dd className="contractForm_definitionList_definition">
                <LabeledCheckbox
                  id="businessCarSharing"
                  customClass="contractForm_option"
                  field={businessCarSharing}
                  disabled={limitedAccess}
                >
                  <FormattedMessage id="contractForm_businessCarSharing" />
                </LabeledCheckbox>

                <LabeledCheckbox
                  id="privateCarSharing"
                  customClass="contractForm_option"
                  field={privateCarSharing}
                  disabled={limitedAccess}
                >
                  <FormattedMessage id="contractForm_privateCarSharing" />
                </LabeledCheckbox>

                <LabeledCheckbox id="rideSharing" customClass="contractForm_option" field={rideSharing} disabled={limitedAccess}>
                  <FormattedMessage id="contractForm_rideSharing" />
                </LabeledCheckbox>

                <LabeledCheckbox id="preBooking" customClass="contractForm_option" field={preBooking} disabled={limitedAccess}>
                  <FormattedMessage id="contractForm_preBooking" />
                </LabeledCheckbox>

                <LabeledCheckbox id="flexibleSearch" customClass="contractForm_option" field={flexibleSearch} disabled={limitedAccess}>
                  <FormattedMessage id="feature_flexible_search" />
                </LabeledCheckbox>
              </dd>
            </dl>
            <dl className="contractForm_definitionList">
              <dt className="contractForm_definitionList_term">
                <FormattedMessage id="conf_interface_fo_options" />
              </dt>
              <dd className="contractForm_definitionList_definition">
                <LabeledCheckbox
                  id="oneWayDisplay"
                  customClass="contractForm_option"
                  field={interfaceConfigDto.oneWayDisplay}
                  disabled={limitedAccess}
                >
                  <FormattedMessage id="contractForm_oneWayDisplay" />
                </LabeledCheckbox>

                <LabeledCheckbox
                  id="returnTripDisplay"
                  customClass="contractForm_option"
                  field={interfaceConfigDto.returnTripDisplay}
                  disabled={limitedAccess}
                >
                  <FormattedMessage id="contractForm_returnTripDisplay" />
                </LabeledCheckbox>

                <LabeledCheckbox
                  id="replacementVehicleDisplay"
                  customClass="contractForm_option"
                  field={interfaceConfigDto.replacementVehicleDisplay}
                  disabled={limitedAccess}
                >
                  <FormattedMessage id="contractForm_replacementVehicleDisplay" />
                </LabeledCheckbox>
              </dd>
            </dl>
          </div>
        </FormRow>

        {!limitedAccess && (
          <EkButton customClass="contractForm_actionsButton" onAction={submitAction}>
            <FormattedMessage id="contractForm_submit" />
          </EkButton>
        )}
      </form>
    );
  }
}

ContractForm.displayName = 'ContractForm';

ContractForm.propTypes = {
  fields: PropTypes.object,
  handleSubmit: PropTypes.func,
  onCallback: PropTypes.func,
  limitedAccess: PropTypes.bool
};

ContractForm = reduxForm({
  onSubmitFail: scrollToFirstError,
  form: 'contractForm',
  fields: [
    'name',
    'reference',
    'startDate',
    'endDate',
    'businessCarSharing',
    'privateCarSharing',
    'rideSharing',
    'preBooking',
    'flexibleSearch',
    'interfaceConfigDto.oneWayDisplay',
    'interfaceConfigDto.returnTripDisplay',
    'interfaceConfigDto.replacementVehicleDisplay'
  ],
  validate: createValidator({
    name: [notEmpty()],
    reference: [notEmpty()],
    startDate: [notEmpty()],
    endDate: [notEmpty()]
  })
})(ContractForm);

export default ContractForm;
