import React, { Component } from 'react';
import { createBrandRequest } from '../../../../../actions/brands-actions';
import { FormattedMessage } from 'react-intl';
import { getAddTitle } from '../../../../../components/_v2/ColumnList/helpers';
import { showParentView } from '../../../../../components/_v2/ColumnList/helpers';
import VehicleView from '../index';
import { showSuccessMsg } from '../../../../../actions/flashMessage-actions';
import { getStore } from '../../../../../store/all-store';
import { editVehicleBrandsRules } from '../../../../../constants/routes-constants';
import { checkRole } from '../../../../../constants/backuser-role-rules';
import { connect } from 'react-redux';
import { userRoleSelector } from '../../../../../selectors/all-selectors';

export class VehicleAddBrand extends Component {
  componentWillMount() {
    this.setVars();
    this.setCallbacks();
  }

  setVars() {
    this.limitedAccess = !checkRole(editVehicleBrandsRules, this.props.role);
  }

  setCallbacks() {
    this.showParentView = showParentView.bind(this);

    this.handleCallback = ({ name }) => {
      const { dispatch } = getStore();

      dispatch(createBrandRequest({ name })).then(() => {
        dispatch(showSuccessMsg({ key: 'brandForm_create_brand_success' }));
        this.showParentView();
      });
    };
  }

  render() {
    return (
      <VehicleView
        inputRef={this.props.inputRef}
        title={getAddTitle(<FormattedMessage id="brandForm_title" />)}
        fieldLabel={<FormattedMessage id="brandForm_name_label" />}
        onClose={this.showParentView}
        onCallback={this.handleCallback}
        limitedAccess={this.limitedAccess}
      />
    );
  }
}

const mapStateToProps = state => ({ role: userRoleSelector(state) });

VehicleAddBrand = connect(mapStateToProps)(VehicleAddBrand);

export default VehicleAddBrand;
