/*eslint-disable react/jsx-no-bind */
/*eslint-disable react/no-set-state */

import React, { Component, PropTypes as T } from 'react';
import { connect } from 'react-redux';
import _get from 'lodash/get';
import _debounce from 'lodash/debounce';
import Dialog from 'material-ui/Dialog';
import autoBind from 'react-autobind';
import AutoComplete from 'material-ui/AutoComplete';
import IconButton from 'material-ui/IconButton';
import CloseIcon from 'material-ui/svg-icons/navigation/close';
import FlatButton from 'material-ui/FlatButton';
import { BOOKING_COMMENT_INIT_VALUE, PAID_BOOKING_INIT_VALUE } from '../../../../constants/form-constants';
import { FLASH_MESSAGE_TYPE_SUCCESS } from '../../../../constants/generic-constants';

import {
  toggleChangeMember,
  updateUserLoginForBooking,
  errorWhileSelectingUserForBooking,
  confirmBookingEdition,
  getDataVehiclePlanning,
  addFlashMessage,
  searchMembers,
  clearFoundUsers,
  changeMemberForBooking,
  saveUserTypeForBookingCreate,
  updateMemberSuccess
} from '../../../../actions/all-actions';
import { FormattedHTMLMessage, FormattedMessage, injectIntl } from 'react-intl';
import { addErrorMessage, allowFunc, safe } from '../../../../utils/utils';
import { bundleSelector } from '../../../../selectors/all-selectors';

class VehiclePlanningMemberChange extends Component {
  constructor() {
    super();
    autoBind(this);
    this.state = {
      openConfirmDialog: false,
      confirmDialogData: {}
    };
    this.handleGetUsers = _debounce(this.handleGetUsers, 300);
  }

  componentWillMount() {
    const { dispatch } = this.props;
    dispatch(clearFoundUsers());
  }

  handleClose() {
    this.props.dispatch(toggleChangeMember());
  }

  handleGetUsers(input) {
    const { dispatch, companyId } = this.props;
    dispatch(searchMembers(companyId, input));
  }

  handleSelectUser(user) {
    const { dispatch, bookingDetail } = this.props;
    if (user.hasOwnProperty('value')) {
      if (!bookingDetail || this.props.skipConfirm) dispatch(updateUserLoginForBooking(user));
      dispatch(changeMemberForBooking(user));
      if (this.props.skipConfirm) dispatch(toggleChangeMember());
      this.setState({ openConfirmDialog: true, confirmDialogData: { user, prevUser: bookingDetail.member } });
    } else dispatch(errorWhileSelectingUserForBooking());
  }

  handleToggleConfirmDialog(member) {
    this.setState({
      openConfirmDialog: !this.state.openConfirmDialog
      // confirmDialogData: this.state.openConfirmDialog ? {} : member
    });
  }

  handleConfirm(ctx) {
    const { dispatch, bookingDetail, bundle, persist, newMember } = this.props;
    const confirmationDetails = {
      comment: _get(bookingDetail, 'comment', BOOKING_COMMENT_INIT_VALUE),
      paidBooking: !_get(bookingDetail, 'carSharingInfo.freeOfCharges', !PAID_BOOKING_INIT_VALUE)
    };

    if (persist || this.props.skipConfirm || ctx) {
      dispatch(confirmBookingEdition(newMember, bookingDetail, confirmationDetails, bookingDetail.id))
        .then(() => {
          dispatch(updateMemberSuccess(newMember));
          safe(() => (this.props.refreshPlanning ? setTimeout(() => dispatch(getDataVehiclePlanning()), 1000) : ''));

          this.handleClose();
          dispatch(
            addFlashMessage({
              contentKey: 'booking_edit_success',
              type: FLASH_MESSAGE_TYPE_SUCCESS
            })
          );
        })
        .catch(error => {
          dispatch(addErrorMessage({ error, bundle, errorCodePrefixes: ['bookingResult_'] }));
        });
    } else {
      dispatch(saveUserTypeForBookingCreate(this.state.confirmDialogData.user));
      dispatch(changeMemberForBooking(this.state.confirmDialogData.user));
      this.setState({ openConfirmDialog: false });
      dispatch(toggleChangeMember());
    }
  }

  displayConfirmModal() {
    const { intl } = this.props;
    if (this.state.openConfirmDialog && !this.props.skipConfirm) {
      return (
        <Dialog
          actions={[
            <FlatButton key="no" label={<FormattedMessage id="common_cancel" />} onClick={this.handleToggleConfirmDialog} />,
            <FlatButton key="yes" label={<FormattedMessage id="common_confirm" />} primary onClick={() => this.handleConfirm(true)} />
          ]}
          title={intl.messages.vehiclePlanning_changeMember}
          modal={false}
          contentStyle={{ width: '520px' }}
          open={this.state.openConfirmDialog}
          onRequestClose={this.handleToggleConfirmDialog}
        >
          <dl>
            <dt>{intl.messages.vehiclePlanning_changeMember_from}</dt>
            <dd>
              {this.state.confirmDialogData.prevUser.firstName +
                ' ' +
                this.state.confirmDialogData.prevUser.lastName +
                ' <' +
                this.state.confirmDialogData.prevUser.login +
                '>'}
            </dd>
            <dt>{intl.messages.vehiclePlanning_changeMember_to}</dt>
            <dd>{this.state.confirmDialogData.user.shortName + ' <' + this.state.confirmDialogData.user.value + '>'}</dd>
          </dl>
        </Dialog>
      );
    }
  }

  render() {
    const { openedMemberChangeModal, errorMemberSelection, intl, foundUsers, persist } = this.props;
    const { formatMessage } = this.props.intl;

    return (
      <Dialog
        bodyStyle={{ overflow: 'inherit' }}
        contentStyle={{ width: '600px' }}
        onRequestClose={this.handleClose}
        contentClassName="member-select-container"
        open={openedMemberChangeModal}
        title={
          <div className="search-member-header">
            <FormattedHTMLMessage id="vehiclePlanning_editMember_for_booking" />
            <div className="vehiclePlanning_closeIconAbosuluteWrap">
              <IconButton onClick={this.handleClose}>
                <CloseIcon />
              </IconButton>
            </div>
          </div>
        }
      >
        <div className="search-member">
          <FormattedMessage id="hint_search_by_name" />
          <AutoComplete
            hintText={formatMessage({ id: 'hint_search_by_name' })}
            hintStyle={{ left: '30px', top: '4px' }}
            dataSource={foundUsers}
            onUpdateInput={this.handleGetUsers}
            onNewRequest={this.handleSelectUser}
            filter={allowFunc}
            fullWidth
            autoFocus
            underlineShow={false}
            menuStyle={{ maxHeight: '200px', overflowY: 'auto' }}
            className="ac-input-wrapper"
          />
          {errorMemberSelection && (
            <div className="fieldErrorMsg">{this.props.intl.formatMessage({ id: 'member_cannot_be_selected' })}</div>
          )}
          {!this.props.skipConfirm && this.displayConfirmModal()}
        </div>
      </Dialog>
    );
  }
}

VehiclePlanningMemberChange.displayName = 'VehiclePlanningBooking';

VehiclePlanningMemberChange.propTypes = {
  booking: T.object
};

export default connect(state => {
  const companyId = _get(state, 'vehiclePlanning.data.company.id') || _get(state, 'bookings.bookingDetail.member.company.id') || {};
  const b = _get(state, 'vehiclePlanning.bookingDetail');
  const newMember = b.member;
  const b2 = _get(state, 'bookings.bookingDetail');
  const bookingDetail = b.hasOwnProperty('id') ? b : b2;
  const {
    vehiclePlanning: { openedMemberChangeModal, errorMemberSelection, data, userDetailEditBooking, foundUsers, persist, skipConfirm }
  } = state;

  return {
    openedMemberChangeModal,
    errorMemberSelection,
    data,
    bookingDetail,
    persist,
    skipConfirm,
    companyId,
    newMember,
    userDetailEditBooking,
    foundUsers,
    bundle: bundleSelector(state)
  };
})(injectIntl(VehiclePlanningMemberChange));
