import BoxedSelect from '../../components/BoxedSelect/BoxedSelect';
import { reduxForm } from 'redux-form';
import classNames from 'classnames';
import _isFunction from 'lodash/isFunction';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';

// noinspection ES6CheckImport
import React, { Component, PropTypes } from 'react';

class ListSelect extends Component {
  constructor(props) {
    super(props);

    this.handleAddToListOne = this.handleAddToListOne.bind(this);
    this.handleAddToListTwo = this.handleAddToListTwo.bind(this);

    this.derivedStateFromProps(props);
  }

  // noinspection JSCheckFunctionSignatures
  componentWillReceiveProps(props) {
    this.derivedStateFromProps(props);
  }

  derivedStateFromProps(props) {
    const { listOneOptions, listTwoOptions } = props;
    const listOneLength = _get(listOneOptions, 'length');
    const listTwoLength = _get(listTwoOptions, 'length');
    this.listHeight = Math.max(listOneLength, listTwoLength);
  }

  callFunc(func, value) {
    if (!_isEmpty(value) && _isFunction(func)) func(value);
  }

  handleAddToListOne() {
    const {
      fields: { listTwo },
      onAddToListOne
    } = this.props;
    const value = _get(listTwo, 'value');
    this.callFunc(onAddToListOne, value);
  }

  handleAddToListTwo() {
    const {
      fields: { listOne },
      onAddToListTwo
    } = this.props;
    const value = _get(listOne, 'value');
    this.callFunc(onAddToListTwo, value);
  }

  render() {
    const {
      fields: { listOne, listTwo },
      listOneOptions,
      listTwoOptions,
      listOneLabel,
      listTwoLabel
    } = this.props;

    return (
      <div className="list-select-component">
        <BoxedSelect
          multiple
          id="list-select-one"
          field={listOne}
          label={listOneLabel}
          options={listOneOptions}
          customSelectClass="list-select"
          blockCustomClass="list-select-container"
          size={this.listHeight}
        />
        <div className="button-container">
          <button type="button" className={classNames('select-button', 'left-button')} onClick={this.handleAddToListOne} />
          <button type="button" className={classNames('select-button', 'right-button')} onClick={this.handleAddToListTwo} />
        </div>
        <BoxedSelect
          multiple
          id="list-select-two"
          field={listTwo}
          label={listTwoLabel}
          options={listTwoOptions}
          customSelectClass="list-select"
          blockCustomClass="list-select-container"
          size={this.listHeight}
        />
      </div>
    );
  }
}

export const listSelectItem = PropTypes.shape({
  label: PropTypes.string,
  labelKey: PropTypes.string,
  value: PropTypes.string.isRequired
});

ListSelect.propTypes = {
  listOneOptions: PropTypes.arrayOf(listSelectItem),
  listOneLabel: PropTypes.string,
  onAddToListOne: PropTypes.func,
  listTwoOptions: PropTypes.arrayOf(listSelectItem),
  listTwoLabel: PropTypes.string,
  onAddToListTwo: PropTypes.func
};

const formConfig = {
  form: 'listSelectForm',
  fields: ['listOne', 'listTwo']
};

ListSelect.displayName = 'ListSelect';
export default reduxForm(formConfig)(ListSelect);
