import React, { Component, PropTypes } from 'react';
import { FormattedMessage } from 'react-intl';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import _ from 'lodash';

import FormRow from '../FormRow/FormRow';
import BoxedInput from '../BoxedInput/BoxedInput';
import EkButton from '../EkButton/EkButton';
import FieldErrorMsg from '../FieldErrorMsg/FieldErrorMsg';
import { createValidator, notEmpty } from '../../validation/all-validation';
import routes from '../../constants/routes-constants';
import BackLink from '../BackLink/BackLink';
import { scrollToFirstError } from '../../utils/utils';

class VersionForm extends Component {
  constructor() {
    super();
  }

  getBackLink() {
    const { currentModel, currentVersion } = this.props;

    return `/#${routes.editModel.path.replace(':modelId', _.get(currentVersion, 'model.id') || _.get(currentModel, 'id'))}`;
  }

  render() {
    const {
      fields: { name },
      handleSubmit
    } = this.props;

    return (
      <section className="versionFormWrapper">
        <BackLink link={this.getBackLink()} labelKey="back_link_versions_list" />
        <form action="#" className="versionForm" onSubmit={handleSubmit(this.props.onCallback)}>
          <div className="versionForm_container">
            <h4 className="versionForm_title">
              <FormattedMessage id="versionForm_title" />
            </h4>
            <FormRow customClass="versionForm_row">
              <BoxedInput
                formRowItemKey="versionForm_submitBtn"
                type="text"
                id="name"
                labelKey="versionForm_name_label"
                customClass="versionForm_boxedInput"
                field={name}
              >
                <FieldErrorMsg field={name} customClass="fieldErrorMsg--versionForm" />
              </BoxedInput>
            </FormRow>
          </div>
          <div className="versionForm_actions">
            <a href={this.getBackLink()} className="ekButton ekButton--reverse versionForm_actionsLink">
              <FormattedMessage id="versionForm_cancel_button" />
            </a>
            <EkButton customClass="versionForm_actionsButton" onAction={handleSubmit(this.props.onCallback)}>
              <FormattedMessage id="versionForm_save_button" />
            </EkButton>
          </div>
        </form>
      </section>
    );
  }
}

VersionForm.displayName = 'VersionForm';

VersionForm.propTypes = {
  fields: PropTypes.object,
  handleSubmit: PropTypes.func,
  onCallback: PropTypes.func
};

VersionForm = reduxForm({
  onSubmitFail: scrollToFirstError,
  form: 'versionForm',
  fields: ['name'],
  validate: createValidator({
    name: [notEmpty()]
  })
})(VersionForm);

export default connect(state => {
  const {
    models: { currentModel },
    versions: { currentVersion }
  } = state;

  return {
    currentModel,
    currentVersion
  };
})(VersionForm);
