import React, { Component } from 'react';
import { connect } from 'react-redux';
import Tabs from './Tabs/Tabs';
import { userRoleSelector } from '../../../selectors/all-selectors';
import { checkRole } from '../../../constants/backuser-role-rules';
import routes, { hotlineRules } from '../../../constants/routes-constants';
import { FormattedMessage as Fm } from 'react-intl';
import { routeActions } from 'react-router-redux';
import urlJoin from 'url-join';
import { newProp, safe } from '../../../utils/utils';

const mainRoute = routes.support.path;

const childRouteHontlines = routes.hotlinesV2.path;
const childRouteConfigs = routes.configurationsV2.path;

const fullHotlinesRoute = urlJoin(mainRoute, childRouteHontlines);
const fullConfigsRoute = urlJoin(mainRoute, childRouteConfigs);

const tabRouteLevel = 1;

const listRouteLevel = tabRouteLevel + 1;
const routesLengthOnTabLevel = tabRouteLevel + 1;

class Support extends Component {
  componentWillMount() {
    this.setCallbacks();
    this.setTabs();
  }

  componentDidMount() {
    this.componentDidCycle(this.props);
    this.propsInit = true;
  }

  componentDidUpdate(pp) {
    this.componentDidCycle(pp);
  }

  componentDidCycle(pp) {
    this.setFirstTab(pp);
  }

  setCallbacks() {
    this.isTabActive = route => {
      return route === this.getListRoute();
    };
  }

  getListRoute() {
    return safe(() => this.props.routes[listRouteLevel].path);
  }

  setTabs() {
    const { dispatch: d } = this.props;

    const tabs = [];

    if (checkRole(hotlineRules, this.props.role)) {
      tabs.push({
        id: 'hotlines',
        childRoute: childRouteHontlines,
        getLabel: () => <Fm id="side_menu_section_account_hotlines" />,
        onClick: () => d(routeActions.push(fullHotlinesRoute))
      });
    }

    tabs.push({
      id: 'configs',
      childRoute: childRouteConfigs,
      getLabel: () => <Fm id="side_menu_section_account_configurations" />,
      onClick: () => d(routeActions.push(fullConfigsRoute))
    });

    this.tabs = tabs;
  }

  isTabSelected() {
    for (let tab of this.tabs) {
      if (this.isTabActive(tab.childRoute)) {
        return true;
      }
    }
  }

  atTabLevel() {
    return safe(() => this.props.routes.length === routesLengthOnTabLevel);
  }

  setFirstTab(pp) {
    if (newProp.call(this, pp, 'location')) {
      if (this.atTabLevel() && !this.isTabSelected()) {
        safe(() => this.tabs[0].onClick());
      }
    }
  }

  render() {
    return (
      <div className="pageContainer">
        <div className="support-page">
          <Tabs {...this.props} tabs={this.tabs} isActive={this.isTabActive} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { list } = state.hotlines;
  const { results } = state.configuration.paginatedResults;
  return { list, results, role: userRoleSelector(state) };
};

Support = connect(mapStateToProps)(Support);

export default Support;
