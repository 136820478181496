import React, { Component, PropTypes } from 'react';
import cs from 'classnames';

class Header extends Component {
  render() {
    const { id, label, active, onClick } = this.props;

    return (
      <li id={id} className={cs('tab-list-item', { 'tab-list-active': active })} onClick={onClick}>
        <div className="item-tab">
          {label}
          <hr />
        </div>
      </li>
    );
  }
}

Header.propTypes = {
  id: PropTypes.string,
  label: PropTypes.any,
  active: PropTypes.bool,
  onClick: PropTypes.func
};

export default Header;
