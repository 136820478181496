import React from 'react';
import { FormattedMessage } from 'react-intl';

export default function FormSection({ titleKey, children }) {
  return (
    <div className="formSection">
      <div className="formSectionTitle">
        {' '}
        <FormattedMessage id={titleKey} />{' '}
      </div>
      <div className="formSectionFields">{children}</div>
    </div>
  );
}

FormSection.displayName = 'FormSection';
