import React, { Component, PropTypes as T } from 'react';
import { toggleVehiclePlanningCard } from '../../../actions/all-actions';
import IconButton from 'material-ui/IconButton';
import CloseIcon from 'material-ui/svg-icons/navigation/close';
import { FormattedMessage, injectIntl } from 'react-intl';
import autoBind from 'react-autobind';
import _get from 'lodash/get';

import { connect } from 'react-redux';
import routes from '../../../constants/routes-constants';
import { getBookingColor, createInitVehiclePlanning, getAppFormattedDayMonthTime } from '../../../utils/utils';
import {
  requestBookingCancel,
  getDataVehiclePlanning,
  addFlashMessage,
  getImpersonateMember,
  toggleVehiclePlanningEditBooking,
  getBookingVehiclePlanning,
  toggleChangeVehicle,
  toggleChangeMember,
  getBookingFindAvailableVehicle,
  requestBookingFinish,
  toggleShortenExtendBooking,
  confirmPreBooking
} from '../../../actions/all-actions';
import {
  BOOKING_STATUS_SCHEDULED,
  BOOKING_STATUS_PRE_BOOKED,
  BOOKING_STATUS_IN_PROGRESS,
  BOOKING_STATUS_COMPLETED,
  STATUS_TO_REVIEW,
  STATUS_APPROVED,
  STATUS_CANCELED,
  BOOKING_TYPE_GROUP_SHARING,
  BOOKING_TYPE_RRS_SHARING,
  BOOKING_TYPE_RIDE_SHARING,
  BOOKING_TYPE_CAR_SHARING
} from '../../../constants/backend-constants';
import { darkBoolean, MEMBER_STATUS_COLOR } from '../../../constants/style-constants';
import { enhanceSearchVehicleData } from '../../../api/data-enhancer';
import Completed from 'material-ui/svg-icons/action/done';
import Canceled from 'material-ui/svg-icons/navigation/close';
import Scheduled from 'material-ui/svg-icons/device/access-time';
import Progress from 'material-ui/svg-icons/action/cached';
import IconMenu from 'material-ui/IconMenu';
import MenuItem from 'material-ui/MenuItem';
import MoreVertIcon from 'material-ui/svg-icons/navigation/more-vert';
import Dialog from 'material-ui/Dialog';
import FlatButton from 'material-ui/FlatButton';
import { FLASH_MESSAGE_TYPE_ERROR } from '../../../constants/generic-constants';
import ReactSVG from 'react-svg';
import { saveUserTypeForBookingEdit } from '../VehiclePlanning.actions';
import { currentCompanyIdSelector } from '../../../selectors/all-selectors';

class VehiclePlanningCard extends Component {
  constructor(props) {
    super(props);
    autoBind(this);

    this.state = {
      dialogOpened: false,
      openFinishConfirm: false
    };
  }

  handleImpersonate() {
    const { dispatch, booking, companyId } = this.props;

    dispatch(getImpersonateMember(booking.member.id, companyId));
  }

  handleToggleDialog() {
    this.setState({ dialogOpened: !this.state.dialogOpened }); //eslint-disable-line
  }

  handleWrapClick(e) {
    e.stopPropagation();
  }

  handleToggle() {
    this.props.dispatch(toggleVehiclePlanningCard());
  }

  handleToggleEditBooking() {
    const { dispatch, openedEditBookingModal, selectedBookingId } = this.props;
    if (!openedEditBookingModal) {
      dispatch(getBookingVehiclePlanning(selectedBookingId)).then(data => dispatch(saveUserTypeForBookingEdit(data)));
    }
    this.props.dispatch(toggleVehiclePlanningEditBooking());
    this.handleToggle();
  }

  handleToggleChangeVehicle() {
    const { dispatch, openedChangeVehicleModal, selectedBookingId } = this.props;

    if (!openedChangeVehicleModal) {
      dispatch(getBookingVehiclePlanning(selectedBookingId)).then(data => {
        const selectedMember = { id: data.member.id };
        const values = createInitVehiclePlanning(data, true);
        const newParams = enhanceSearchVehicleData(values, data, selectedMember, true);

        newParams.usages = [data.carSharingInfo.usageType];
        newParams.startParkingId = data.start.parking.id;
        dispatch(getBookingFindAvailableVehicle(newParams, false));
      });
    }

    this.props.dispatch(toggleChangeVehicle());
    this.handleToggle();
  }

  handleToggleChangeMember() {
    const { dispatch, openedMemberChangeModal, selectedBookingId } = this.props;

    if (!openedMemberChangeModal) {
      dispatch(getBookingVehiclePlanning(selectedBookingId)).then(data => {
        dispatch(toggleChangeMember());
      });
    }
    this.handleToggle();
  }

  handleCancelBooking() {
    const { dispatch, booking } = this.props;
    this.handleToggleDialog();

    dispatch(requestBookingCancel(_get(booking, 'id'))).then(
      () => {
        this.handleToggle();
        setTimeout(() => dispatch(getDataVehiclePlanning()), 1000); // wait server to process data
      },
      () => {
        setTimeout(() => dispatch(getDataVehiclePlanning()), 1000); // wait server to process data
        dispatch(
          addFlashMessage({
            contentKey: 'booking_detail_cancel_failed',
            type: FLASH_MESSAGE_TYPE_ERROR
          })
        );
      }
    );
  }

  handleFinish() {
    const { dispatch, selectedBookingId } = this.props;
    dispatch(requestBookingFinish(selectedBookingId, false)).then(
      () => {
        this.handleToggle();
        this.handleToggleFinishConfirm();
        setTimeout(() => dispatch(getDataVehiclePlanning()), 1000); // wait server to process data
      },
      () => {
        setTimeout(() => dispatch(getDataVehiclePlanning()), 1000); // wait server to process data
        dispatch(
          addFlashMessage({
            contentKey: 'booking_detail_finish_failed',
            type: FLASH_MESSAGE_TYPE_ERROR
          })
        );
      }
    );
  }

  handleToggleFinishConfirm() {
    this.setState({ openFinishConfirm: !this.state.openFinishConfirm }); //eslint-disable-line
  }

  handleToggleExtendShortenBooking() {
    this.props.dispatch(toggleShortenExtendBooking(this.props.booking, this.props.vehicle));
  }

  handleConfirm() {
    const { dispatch } = this.props;
    dispatch(confirmPreBooking());
    this.handleToggleEditBooking();
  }

  displayTimes() {
    const { booking } = this.props;
    const status = booking.status;
    let startTime = '';
    let endTime = '';
    switch (status) {
      case BOOKING_STATUS_COMPLETED:
        startTime = booking.effectiveStartDate;
        endTime = booking.effectiveEndDate;
        break;
      case BOOKING_STATUS_IN_PROGRESS:
        startTime = booking.effectiveStartDate;
        endTime = booking.estimatedEndDate;
        break;
      case STATUS_CANCELED:
      case BOOKING_STATUS_SCHEDULED:
      case BOOKING_STATUS_PRE_BOOKED:
        startTime = booking.estimatedStartDate;
        endTime = booking.estimatedEndDate;
        break;
      default:
    }

    return (
      <div className="vehiclePlanning_card_lineData">
        <span>
          {getAppFormattedDayMonthTime(startTime)}
          <span> - </span>
          {getAppFormattedDayMonthTime(endTime)}
        </span>
      </div>
    );
  }

  render() {
    const { readOnly, opened, booking, vehicle, intl } = this.props;
    const href = `#${routes.vehicleDetail.path.replace(':vehicleId', vehicle.id)}`;
    const memberHref = '#' + routes.memberDetail.path.replace(':memberId', _get(booking, 'member.id'));
    const reviewMemberLink = !readOnly && (
      <a href={memberHref} className="actions">
        {intl.messages.member_review}
      </a>
    );
    const confirmNBookingLink = !readOnly && (
      <a onClick={this.handleConfirm} className="actions">
        {intl.messages.confirm_booking}
      </a>
    );
    return (
      <div className={opened ? 'vehiclePlanning_card_container vehiclePlanning_card_opened' : 'vehiclePlanning_card_container'}>
        {opened && (
          <div className="vehiclePlanning_card_wrap">
            <div className="vehiclePlanning_card_header" style={{ backgroundColor: getBookingColor(booking.status) }}>
              <div className="vehiclePlanning_card_headerTopLine">
                <div className="vehiclePlanning_card_headerStatus">
                  {booking.status === BOOKING_STATUS_PRE_BOOKED && (
                    <ReactSVG src="/img/pre-booked.svg" className="prebooked-icon" svgClassName="svg-icon" />
                  )}
                  {booking.status === BOOKING_STATUS_SCHEDULED && <Scheduled />}
                  {booking.status === BOOKING_STATUS_IN_PROGRESS && <Progress />}
                  {booking.status === BOOKING_STATUS_COMPLETED && <Completed />}
                  {booking.status === STATUS_CANCELED && <Canceled />}
                  <FormattedMessage id={'bookings_tableView_status_' + booking.status} />
                </div>
                <div className="vehiclePlanning_card_headerClose">
                  {!readOnly && (
                    <IconMenu
                      iconButtonElement={
                        <IconButton>
                          <MoreVertIcon />
                        </IconButton>
                      }
                      iconStyle={{ fill: 'white' }}
                      anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
                      targetOrigin={{ horizontal: 'right', vertical: 'top' }}
                    >
                      {(booking.status === BOOKING_STATUS_SCHEDULED ||
                        booking.status === BOOKING_STATUS_IN_PROGRESS ||
                        booking.status === BOOKING_STATUS_PRE_BOOKED) && (
                        <MenuItem primaryText={intl.messages.common_cancel} onClick={this.handleToggleDialog} />
                      )}
                      {booking.status === BOOKING_STATUS_IN_PROGRESS && (
                        <MenuItem
                          primaryText={intl.messages.booking_detail_extend_shorten_booking}
                          onClick={this.handleToggleExtendShortenBooking}
                        />
                      )}
                      {booking.status === BOOKING_STATUS_IN_PROGRESS && (
                        <MenuItem primaryText={intl.messages.booking_detail_finish_booking} onClick={this.handleToggleFinishConfirm} />
                      )}
                      {(booking.status === BOOKING_STATUS_SCHEDULED || booking.status === BOOKING_STATUS_PRE_BOOKED) &&
                        booking.type !== BOOKING_TYPE_GROUP_SHARING &&
                        booking.type !== BOOKING_TYPE_RIDE_SHARING &&
                        booking.type !== BOOKING_TYPE_RRS_SHARING && (
                          <MenuItem primaryText={intl.messages.companies_detail_edit_button} onClick={this.handleToggleEditBooking} />
                        )}
                      {(booking.status === BOOKING_STATUS_SCHEDULED || booking.status === BOOKING_STATUS_PRE_BOOKED) &&
                        booking.type !== BOOKING_TYPE_GROUP_SHARING &&
                        booking.type !== BOOKING_TYPE_RIDE_SHARING &&
                        booking.type !== BOOKING_TYPE_RRS_SHARING && (
                          <MenuItem primaryText={intl.messages.vehiclePlanning_changeVehicle} onClick={this.handleToggleChangeVehicle} />
                        )}
                      {booking.status === BOOKING_STATUS_SCHEDULED &&
                        booking.type !== BOOKING_TYPE_GROUP_SHARING &&
                        booking.type !== BOOKING_TYPE_RIDE_SHARING &&
                        booking.type !== BOOKING_TYPE_RRS_SHARING && (
                          <MenuItem primaryText={intl.messages.vehiclePlanning_changeMember} onClick={this.handleToggleChangeMember} />
                        )}
                      <MenuItem primaryText={intl.messages.members_detail_impersonate_button} onClick={this.handleImpersonate} />
                    </IconMenu>
                  )}
                  <IconButton onClick={this.handleToggle} iconStyle={{ fill: 'white' }}>
                    <CloseIcon />
                  </IconButton>
                </div>
              </div>
              <div className="vehiclePlanning_card_headerCarLine">
                <div className="vehiclePlanning_vehicleRow_firstField">
                  <a href={href}>
                    <img src={vehicle.picture} />
                  </a>
                  <div style={{ marginLeft: '10px' }}>
                    <div className="vehiclePlanning_vehicleRow_registrationNumber">
                      <a href={href}>{vehicle.registrationNumber}</a>
                    </div>
                    <div className="vehiclePlanning_vehicleRow_vehicleInfo">
                      <a href={href}>
                        <span>
                          {vehicle.brand} {vehicle.model} ({vehicle.color})
                        </span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="vehiclePlanning_card_line">
              <div className="vehiclePlanning_card_lineIcon">ID:</div>
              <div className="vehiclePlanning_card_lineData">
                <a href={'#' + routes.bookingDetail.path.replace(':bookingId', booking.id)}>
                  <span>{booking.functionalId}</span>
                </a>
              </div>
            </div>
            <div className="vehiclePlanning_card_line">
              <div className="vehiclePlanning_card_lineIcon">
                <FormattedMessage id="vehicle_update_cleanliness_type_member" />:
              </div>
              <div className="vehiclePlanning_card_lineData">
                <a href={'#' + routes.memberDetail.path.replace(':memberId', booking.member.id)}>
                  <span>
                    {booking.member.firstName} {booking.member.lastName}
                  </span>
                </a>
              </div>
            </div>
            <div className="vehiclePlanning_card_line">
              <div className="vehiclePlanning_card_lineIcon">
                <FormattedMessage id="member_status" />:
              </div>
              <div className="vehiclePlanning_card_lineData">
                <span
                  className="member-status"
                  style={{ backgroundColor: MEMBER_STATUS_COLOR['STATUS_' + booking.member.memberStatus] }}
                  children={<FormattedMessage id={`members_status_${booking.member.memberStatus.toLowerCase()}`} />}
                />
                {booking.member.memberStatus === STATUS_TO_REVIEW && reviewMemberLink}
                {booking.member.memberStatus === STATUS_APPROVED && booking.status === BOOKING_STATUS_PRE_BOOKED && confirmNBookingLink}
              </div>
            </div>
            {booking.type === BOOKING_TYPE_CAR_SHARING && (
              <div className="vehiclePlanning_card_line">
                <div className="vehiclePlanning_card_lineIcon">
                  <FormattedMessage id="booking.for.vr.short" />:
                </div>
                <strong>
                  <span style={darkBoolean} className="vehiclePlanning_card_lineData">
                    {String(!!booking.replacementVehicle)}
                  </span>
                </strong>
              </div>
            )}
            <div className="vehiclePlanning_card_line">
              <div className="vehiclePlanning_card_lineIcon">
                <FormattedMessage id="timeSlot_duration_label" />:
              </div>
              {this.displayTimes()}
            </div>
            {booking.usageType !== undefined && (
              <div className="vehiclePlanning_card_line">
                <div className="vehiclePlanning_card_lineIcon">
                  <FormattedMessage id="booking_create_find_vehicle_tableView_usage" />:
                </div>
                <div className="vehiclePlanning_card_lineData">
                  <FormattedMessage id={'siteForm_spontaneousBookingUsage_' + booking.usageType} />
                </div>
              </div>
            )}
            <div className="vehiclePlanning_card_line">
              <div className="vehiclePlanning_card_lineIcon">
                <FormattedMessage id="common_type" />:
              </div>
              <div className="vehiclePlanning_card_lineData">
                <FormattedMessage id={'bookings_tableView_serviceType_' + booking.type} />
              </div>
            </div>
            {booking.comment && (
              <div className="vehiclePlanning_card_line">
                <div className="vehiclePlanning_card_lineIcon">
                  <FormattedMessage id="booking_create_confirmation_comment" />:
                </div>
                <div className="vehiclePlanning_card_lineData">
                  <span>{booking.comment}</span>
                </div>
              </div>
            )}
          </div>
        )}
        <Dialog
          actions={[
            <FlatButton
              key="no"
              label={intl.messages.booking_create_confirmation_abort_button}
              onClick={this.handleToggleDialog}
              className="flatButton"
            />,
            <FlatButton key="yes" label="Ok" onClick={this.handleCancelBooking} className="flatButton" />
          ]}
          modal={false}
          contentStyle={{ width: '320px' }}
          open={this.state.dialogOpened}
          onRequestClose={this.handleClose}
        >
          <FormattedMessage id="booking_detail_cancelBooking_text" />
        </Dialog>
        <Dialog
          actions={[
            <FlatButton
              key="no"
              label={intl.messages.booking_create_confirmation_abort_button}
              onClick={this.handleToggleFinishConfirm}
              className="flatButton"
            />,
            <FlatButton key="yes" label="Ok" onClick={this.handleFinish} className="flatButton" />
          ]}
          modal={false}
          contentStyle={{ width: '320px' }}
          open={this.state.openFinishConfirm}
          onRequestClose={this.handleToggleFinishConfirm}
        >
          <FormattedMessage id="finalizeBookingForm_title" />
        </Dialog>
      </div>
    );
  }
}

VehiclePlanningCard.displayName = 'VehiclePlanningCard';

VehiclePlanningCard.propTypes = {
  opened: T.bool,
  data: T.object,
  openedEditBookingModal: T.bool
};

export default connect(state => {
  const {
    vehiclePlanning: { data, selectedBookingId, openedEditBookingModal, openedChangeVehicleModal, openedMemberChangeModal }
  } = state;

  return {
    data,
    selectedBookingId,
    openedEditBookingModal,
    openedChangeVehicleModal,
    openedMemberChangeModal,
    companyId: currentCompanyIdSelector(state)
  };
})(injectIntl(VehiclePlanningCard));
