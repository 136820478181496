import React, { Component } from 'react';
import { connect } from 'react-redux';
import { routeActions } from 'react-router-redux';
import BrandForm from '../../components/BrandForm/BrandForm';
import { createBrandRequest, showSuccessMsg } from '../../actions/all-actions';
import routes from '../../constants/routes-constants';

class AddBrand extends Component {
  componentWillMount() {
    this.handleCreateBrand = ({ name }) => {
      const { dispatch } = this.props;

      dispatch(createBrandRequest({ name })).then(() => {
        dispatch(showSuccessMsg({ key: 'brandForm_create_brand_success', delay: true }));
        dispatch(routeActions.push(routes.brands.path));
      });
    };
  }

  render() {
    return (
      <div className="addBrandPage mainContainer_content">
        <div className="pageContainer">
          <div className="addBrandPage_content">
            <section className="addBrandPage_management">
              <BrandForm onCallback={this.handleCreateBrand} />
            </section>
          </div>
        </div>
      </div>
    );
  }
}

AddBrand.displayName = 'AddBrand';

export default connect(state => {
  const {} = state;

  return {};
})(AddBrand);
