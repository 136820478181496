import React, { Component, PropTypes } from 'react';
import { reduxForm, change } from 'redux-form';
import SlimSelect from 'slim-select';
import BoxedSelect from '../../BoxedSelect/BoxedSelect';
import { scrollToFirstError, safe } from '../../../utils/utils';
import AddressAutocomplete from '../../AddressAutocomplete/AddressAutocomplete';
import TranslationsFields from '../TranslationsFields';
import { MOBILE_ACTIONS } from '../../../constants/backend-constants';
import { getMsg } from '../../../utils/IntlGlobalProvider';
import {
  mobileHomepageActionsOptions,
  vehicleTypes,
  vehicleTransmissionTypes,
  usageTypesOptions,
  vehicleAccessories,
  vehicleFuelTypes,
  tripTyoe
} from '../../../constants/options-constants';
import { FlatButton, RaisedButton } from 'material-ui';
import BoxedInput from '../../BoxedInput/BoxedInput';
import { createValidator, notEmpty } from '../../../validation/sync-validation';
import FieldErrorMsg from '../../FieldErrorMsg/FieldErrorMsg';

class HomepageSettingsActionForm extends Component {
  setCallbacks() {
    this.getAccessoriesRef = ref => {
      this.accessoriesRef = ref;
    };
    this.getFuelRef = ref => {
      this.fuelRef = ref;
    };
    this.updateFuelField = () => {
      this.props.dispatch(change('HomepageSettingsActionForm', 'action.filters.fuel', this.fuelSelect.getSelected()));
    };
    this.updateAccessoriesField = () => {
      this.props.dispatch(change('HomepageSettingsActionForm', 'action.filters.accessories', this.accSelect.getSelected()));
    };

    this.onSave = data => {
      this.props.onCallback(data);
      this.props.onClose();
    };
  }

  componentWillMount() {
    this.setCallbacks();
    this.componentPropsUpdated(this.props);
  }

  componentWillReceiveProps(props) {
    this.componentPropsUpdated(props);
  }

  // Updated
  componentPropsUpdated(props) {
    this.prevShowFilters = this.showFilters;
    this.showFilters = props.fields.action.type.value === MOBILE_ACTIONS.DEEPLINK;
  }

  componentDidMount() {
    this.componentUpdatedCycle();
  }

  componentDidUpdate() {
    this.componentUpdatedCycle();
  }

  // Mounted
  componentUpdatedCycle() {
    if (this.showFilters && !this.prevShowFilters) {
      this.accSelect && this.accSelect.destroy();
      this.fuelSelect && this.fuelSelect.destroy();

      this.accSelect = new SlimSelect({
        select: this.accessoriesRef,
        settings: { closeOnSelect: false, showSearch: false, placeholderText: getMsg('contract.homesetting.select-filter.placehoder') },
        events: {
          afterChange: this.updateAccessoriesField
        }
      });

      this.fuelSelect = new SlimSelect({
        select: this.fuelRef,
        settings: { closeOnSelect: false, showSearch: false, placeholderText: getMsg('contract.homesetting.select-filter.placehoder') },
        events: {
          afterChange: this.updateFuelField
        }
      });

      if (safe(() => this.props.values.action.filters.accessories))
        this.accSelect.setSelected(this.props.values.action.filters.accessories);
      if (safe(() => this.props.values.action.filters.fuel)) this.fuelSelect.setSelected(this.props.values.action.filters.fuel);
      this.prevShowFilters = true;
    }
  }

  displayAction() {
    const { fields: f } = this.props;
    return (
      <div>
        <div className="col">
          <BoxedInput name="name" field={f.action.nameAlias} labelKey="common_name" mandatory>
            <FieldErrorMsg field={f.action.nameAlias} customClass="fieldErrorMsg--companyForm" />
          </BoxedInput>
          <TranslationsFields serviceNames={f.action.title} keyLabel="contract.homesetting.action.button.label" />
        </div>
        <div className="col">
          <BoxedSelect options={mobileHomepageActionsOptions} id="action-type" label="Action" noEmptyValue field={f.action.type} />
        </div>
      </div>
    );
  }

  displayFilters() {
    const { fields: f } = this.props;

    if (this.showFilters) {
      return (
        <div className="hs-filters">
          <BoxedSelect
            blockCustomClass="col"
            labelKey="vehicleForm_transmissionType"
            options={vehicleTransmissionTypes}
            field={f.action.filters.transmissionTypes}
          />

          <BoxedSelect
            blockCustomClass="col"
            labelKey="vehiclesFiltersForm_usage"
            options={usageTypesOptions}
            field={f.action.filters.usageTypes}
          />

          <BoxedSelect blockCustomClass="col" labelKey="vehicleForm_type" options={vehicleTypes} field={f.action.filters.vehicleType} />

          <BoxedSelect blockCustomClass="col" labelKey="booking_tripType" options={tripTyoe} field={f.action.filters.tripType} />

          <div className="col">
            <label className="boxedSelect_label">{getMsg('vehicleForm_accesories_title')}</label>
            <select ref={this.getAccessoriesRef} multiple field={f.action.filters.accessories}>
              {vehicleAccessories.map(opt => (
                <option key={opt.value} value={opt.value}>
                  {getMsg(opt.labelKey)}
                </option>
              ))}
            </select>
          </div>
          <div className="col">
            <label className="boxedSelect_label">{getMsg('vehicleForm_fuelType')}</label>
            <select ref={this.getFuelRef} multiple field={f.action.filters.fuel}>
              {vehicleFuelTypes.map(opt => (
                <option key={opt.value} value={opt.value}>
                  {getMsg(opt.labelKey)}
                </option>
              ))}
            </select>
          </div>

          <div className="col">
            <label className="boxedSelect_label">{getMsg('contract.homesetting.address-filter')}</label>
            <AddressAutocomplete
              placeholderKey="contract.homesetting.address-filter"
              searchType={this.searchType}
              className="companyForm_AddressAutocomplete"
              field={f.action.filters.address}
            />
          </div>
        </div>
      );
    }
  }

  render() {
    const { handleSubmit, onClose } = this.props;
    return (
      <div className="hs-form-wrapper">
        <form action="#" className="HomepageSettings-form-action" onSubmit={handleSubmit(this.onSave)}>
          {this.displayAction()}
          {this.displayFilters()}

          <div className="actions">
            <FlatButton label={getMsg('common_cancel')} onClick={onClose} secondary />
            <RaisedButton label={getMsg('common_save')} type="submit" primary />
          </div>
        </form>
      </div>
    );
  }
}

HomepageSettingsActionForm.displayName = 'HomepageSettingsForm';

HomepageSettingsActionForm.propTypes = {
  fields: PropTypes.object,
  handleSubmit: PropTypes.func,
  onCallback: PropTypes.func,
  onAbort: PropTypes.func
};

HomepageSettingsActionForm = reduxForm({
  onSubmitFail: scrollToFirstError,
  form: 'HomepageSettingsActionForm',
  fields: [
    'id',
    'action.type',
    'action.nameAlias',
    'action.title[]',
    'action.title[].language',
    'action.title[].translation',
    'action.filters.transmissionTypes',
    'action.filters.usageTypes',
    'action.filters.tripType',
    'action.filters.address',
    'action.filters.vehicleType',
    'action.filters.accessories',
    'action.filters.fuel'
  ],
  validate: createValidator({
    'action.nameAlias': [notEmpty()]
  })
})(HomepageSettingsActionForm);

export default HomepageSettingsActionForm;
