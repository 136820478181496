import React, { Component } from 'react';
import { connect } from 'react-redux';
import autoBind from 'react-autobind';

import routes from '../../constants/routes-constants';
import BackLink from '../../components/BackLink/BackLink';

import { clearInvoiceCreationSession } from '../../actions/all-actions';
import { routeActions } from 'react-router-redux';
import { apiParams } from '../../constants/api-params-constants';
import { currentV2InvoicesUrlSelector } from '../../selectors/all-selectors';

class AddInvoice extends Component {
  constructor() {
    super();
    autoBind(this);
  }

  componentWillUnmount() {
    this.props.dispatch(clearInvoiceCreationSession());
  }

  handleClickBack() {
    const { invoicesUrlParams, dispatch, listUrlV2 } = this.props;

    if (listUrlV2) {
      return dispatch(routeActions.push(listUrlV2));
    }

    dispatch(
      routeActions.push(routes.invoices.path.replace(':search', encodeURIComponent(JSON.stringify(invoicesUrlParams || apiParams.flat))))
    );
  }

  render() {
    const { page: stepPage } = this.props;

    return (
      <div className="addInvoicePage mainContainer_content">
        <div className="pageContainer">
          <div className="addInvoicePage_content">
            <BackLink onClickBack={this.handleClickBack} labelKey="back_link_invoices_list" />
            <section className="addInvoicePage_management">{stepPage}</section>
          </div>
        </div>
      </div>
    );
  }
}

AddInvoice.displayName = 'AddInvoice';

export default connect(state => {
  const {
    members: { urlParams: memberUrlParams },
    invoices: { urlParams: invoicesUrlParams, selectedMember, titleID, stepTitleID }
  } = state;

  return {
    titleID,
    stepTitleID,
    memberUrlParams,
    invoicesUrlParams,
    selectedMember,
    listUrlV2: currentV2InvoicesUrlSelector(state)
  };
})(AddInvoice);
