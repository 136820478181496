import React from 'react';
import classnames from 'classnames';
import ListWrap from '../../../components/_v2/ColumnList/Wrap';
import { safe } from '../../../utils/utils';

function detailsOpen(props) {
  return safe(() => props.params.invoiceRef);
}

export default function InvoicesV2(props) {
  return (
    <div className={classnames('pageContainer', 'invoices-v2', { 'sc-wide': !detailsOpen(props) })}>
      <ListWrap {...props} />
    </div>
  );
}
