import * as types from '../constants/actionTypes-constants';
import callApi from './api-actions';
import _sortBy from 'lodash/sortBy';
import { addErrorMessage } from '../utils/utils';
import { addFlashMessage } from './flashMessage-actions';
import { FLASH_MESSAGE_TYPE_SUCCESS } from '../constants/generic-constants';

export function getColors(loader = true) {
  return dispatch => {
    return dispatch(callApi('colors', 'getList', null, loader)).then(
      data => {
        data = _sortBy(data, color => color.code.toLowerCase());
        dispatch({ type: types.COLORS_GET_COLORS_SUCCESS, colors: data });
        return Promise.resolve(data);
      },
      error => {
        dispatch(addErrorMessage({ error }));
        return Promise.reject(error);
      }
    );
  };
}

export function createColorRequest(params) {
  return dispatch => {
    return dispatch(callApi('colors', 'createColor', params)).then(
      data => {
        dispatch(createColorSuccess(data));
        return Promise.resolve(data);
      },
      error => {
        const params = { error, def: 'brandForm_create_brand_error', errorCodePrefixes: ['vehiclesColorForm_'] };
        dispatch(addErrorMessage(params));
        return Promise.reject(error);
      }
    );
  };
}

export function createColorSuccess(color) {
  return {
    type: types.COLORS_CREATE_SUCCESS,
    color
  };
}

export function getSingleColor(colorId) {
  return dispatch => {
    return dispatch(callApi('colors', 'getColor', colorId)).then(
      data => {
        dispatch({ type: types.COLORS_GET_SINGLE_COLOR_SUCCESS, color: data });
        return Promise.resolve(data);
      },
      error => {
        dispatch(addErrorMessage({ error }));
        return Promise.reject(error);
      }
    );
  };
}

export function updateColorRequest({ id, ...params }) {
  return dispatch => {
    return dispatch(callApi('colors', 'updateColor', { id, params })).then(
      data => {
        dispatch(updateColorSuccess(data));
        dispatch(
          addFlashMessage({
            contentKey: 'vehiclesColorForm_update_color_success',
            type: FLASH_MESSAGE_TYPE_SUCCESS
          })
        );
        return Promise.resolve(data);
      },
      error => {
        dispatch(addErrorMessage({ error, def: 'vehiclesColorForm_update_color_error' }));
        return Promise.reject(error);
      }
    );
  };
}

export function updateColorSuccess(color) {
  return {
    type: types.COLORS_UPDATE_COLOR_SUCCESS,
    color
  };
}
