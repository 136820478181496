import * as types from '../constants/actionTypes-constants';
import { COMPANY_ALL, invoicePreviewDefault } from '../constants/generic-constants';
import { safe } from '../utils/utils';

// fix BE missing response
function fixItalianInvoicingParameters(action) {
  const { italianInvoicingParameters: dataParams } = action.data;
  const { italianInvoicingParameters: actionParams } = action;

  if (!dataParams && actionParams) {
    return actionParams;
  }
  return dataParams || {};
}

export default function subCompanies(
  state = {
    headerSubList: [],
    subList: [],
    subListLoading: false,
    currentSubCompany: {},
    urlParams: null,
    subCompaniesOptions: [],
    subCompanySelected: COMPANY_ALL, // set to COMPANY_ALL
    invoiceVatRateParam: null,
    detailInvoiceParams: null,
    currentTabIndex: 0,
    invoicePreview: invoicePreviewDefault,
    legacyPaymentDetails: null,
    paybackData: null,
    paybackFormValues: null,
    connectedAccount: null,
    bankAccountDetails: null,
    customizations: null
  },
  action
) {
  switch (action.type) {
    case types.SUB_COMPANIES_HEADER_GET_LIST_REQUEST:
    case types.SUB_COMPANIES_CREATE_SUB_COMPANY_REQUEST:
    case types.SUB_COMPANIES_UPDATE_SUB_COMPANY_REQUEST:
    case types.SUB_COMPANIES_DETAIL_SUB_COMPANY_REQUEST:
    case types.SUB_COMPANIES_DETAIL_STORE_PAYMENT_REQUEST:
    case types.SUB_COMPANIES_DETAIL_STORE_PAYMENT_SUCCESS:
    case types.SUB_COMPANIES_CREATE_INVOICE_VAT_RATE_REQUEST:
    case types.SUB_COMPANIES_CREATE_INVOICE_VAT_RATE_ERROR:
    case types.SUB_COMPANIES_CREATE_INVOICE_PARAMS_REQUEST:
    case types.SUB_COMPANIES_CREATE_INVOICE_PARAMS_ERROR:
    case types.SUB_COMPANIES_UPDATE_INVOICE_PARAMS_REQUEST:
    case types.SUB_COMPANIES_UPDATE_INVOICE_PARAMS_ERROR:
      return state;

    case types.USER_LOGOUT:
      return {
        ...state,
        subList: [],
        subListLoading: false,
        currentSubCompany: null
      };

    case types.SUB_COMPANIES_CREATE_COMPANY_MODAL_SUCCESS: {
      const { subCompany } = action;
      const { subList = [] } = state;

      return {
        ...state,
        subList: [{ ...subCompany }, ...subList]
      };
    }

    case types.SUB_COMPANIES_GET_LIST_REQUEST: {
      return { ...state, subListLoading: true };
    }

    case types.SUB_COMPANIES_GET_LIST_SUCCESS: {
      return { ...state, subList: action.list, subListLoading: false };
    }

    case types.SUB_COMPANIES_GET_LIST_CLEAR: {
      return { ...state, subList: [], subListLoading: false };
    }

    case types.SUB_COMPANIES_HEADER_GET_LIST_SUCCESS:
      return Object.assign({}, state, {
        headerSubList: action.list
      });

    case types.SUB_COMPANIES_DETAIL_SUB_COMPANY_SUCCESS:
      return Object.assign({}, state, {
        currentSubCompany: action.subCompany
      });

    case types.SUB_COMPANIES_PAYMENT_DETAILS_REQUEST:
      return { ...state, legacyPaymentDetails: null };

    case types.SUB_COMPANIES_PAYMENT_DETAILS_SUCCESS:
      return { ...state, legacyPaymentDetails: action.subCompanyPaymentDetails };

    case types.SUB_COMPANIES_UPDATE_SUB_COMPANY_SUCCESS: {
      const { subCompany } = action;
      const { subList = [] } = state;

      safe(() => (subList.find(item => item.id === subCompany.id).name = subCompany.name));

      return {
        ...state,
        currentSubCompany: subCompany,
        subList: [...subList]
      };
    }

    case types.SUB_COMPANIES_SET_SUB_COMPANY_OPTIONS:
      return Object.assign({}, state, {
        subCompaniesOptions: action.options
      });

    case types.SUB_COMPANIES_SET_CURRENT_SUB_COMPANY:
      return Object.assign({}, state, {
        subCompanySelected: action.subCompany
      });

    case types.SUB_COMPANIES_GET_INVOICE_VAT_RATE_ERROR:
    case types.SUB_COMPANIES_GET_INVOICE_VAT_RATE_REQUEST:
      return {
        ...state,
        invoiceVatRateParam: null
      };

    case types.SUB_COMPANIES_GET_INVOICE_VAT_RATE_SUCCESS:
    case types.SUB_COMPANIES_CREATE_INVOICE_VAT_RATE_SUCCESS:
    case types.SUB_COMPANIES_UPDATE_INVOICE_VAT_RATE_SUCCESS:
      return {
        ...state,
        invoiceVatRateParam: action.data
      };

    case types.SUB_COMPANIES_UPDATE_INVOICE_PARAMS_SUCCESS: {
      return {
        ...state,
        detailInvoiceParams: { ...action.data, italianInvoicingParameters: fixItalianInvoicingParameters(action) }
      };
    }

    case types.SUB_COMPANIES_GET_INVOICE_PARAMS_SUCCESS:
    case types.SUB_COMPANIES_CREATE_INVOICE_PARAMS_SUCCESS: {
      return {
        ...state,
        detailInvoiceParams: action.data
      };
    }

    case types.SUB_COMPANIES_SET_TAB_INDEX:
      return {
        ...state,
        currentTabIndex: action.index
      };

    case types.SUB_COMPANIES_PREVIEW_INVOICE_REQUEST:
    case types.SUB_COMPANIES_PREVIEW_INVOICE_ERROR:
    case types.SUB_COMPANIES_PREVIEW_INVOICE_CLEAR:
      return {
        ...state,
        invoicePreview: invoicePreviewDefault
      };

    case types.SUB_COMPANIES_PREVIEW_INVOICE_SUCCESS:
      return {
        ...state,
        invoicePreview: {
          blob: action.blob,
          base64: action.base64
        }
      };

    case types.SUB_COMPANY_GET_PAYBACK_CONFIG_SUCCESS:
      return {
        ...state,
        paybackData: action.data
      };

    case types.SUB_COMPANY_GET_PAYBACK_CONFIG_ERROR:
      return {
        ...state,
        paybackData: null
      };

    case types.SUB_COMPANY_CLEAR_TEMPORARY_BANK_DETAILS:
      return { ...state, bankAccountDetails: null };

    case types.SUB_COMPANY_CLEAR_PAYBACK_SETTINGS_DATA:
      return {
        ...state,
        paybackData: null,
        paybackFormValues: null,
        bankAccountDetails: null,
        legacyPaymentDetails: null
      };

    case types.SUB_COMPANY_SAVE_PAYBACK_REDIRECT_DATA: {
      return {
        ...state,
        currentTabIndex: action.currentTabIndex || state.currentTabIndex,
        paybackFormValues: action.values,
        bankAccountDetails: action.bankAccountDetails
      };
    }

    case types.SUB_COMPANY_GET_EMAIL_SETUP_REQUEST:
    case types.SUB_COMPANY_GET_EMAIL_SETUP_ERROR:
      return {
        ...state,
        customizations: null
      };

    case types.SUB_COMPANY_GET_EMAIL_SETUP_SUCCESS:
      return {
        ...state,
        customizations: action.data
      };

    case types.SUB_COMPANY_UPDATE_EMAIL_SETUP_SUCCESS:
      return {
        ...state,
        customizations: action.data
      };
    case types.SUB_COMPANY_GET_CONNECTED_ACCOUNT_SUCCESS:
      return Object.assign({}, state, {
        connectedAccount: action
      });
    case types.SUB_COMPANY_GET_CONNECTED_ACCOUNT_ERROR:
      return Object.assign({}, state, {
        connectedAccount: action
      });
    default:
      return state;
  }
}
