import {
  allDelayedStatues,
  allFailedOptions,
  bookingStatus,
  allSystemTypes,
  backUsersRolesStatic,
  bookingTypes,
  feedbackStatusOptions,
  smartcardsEventsActionTypes,
  smartcardsEventsResultTypes,
  statusListLabels,
  withCleanlinessProblemsOptions,
  memberSubscriptionOriginOptions,
  memberStatusOptions,
  booleanOptions,
  vehicleFuelTypes
} from './options-constants';
import {
  invoiceStatuses,
  bankoutStatusOptionKeys,
  booleanTypes,
  vehicleCleanlinessStatuses,
  vehicleTypes,
  voucherTypes,
  vehicleUsages
} from './filters-constants';

export const membersListFilterTypes = {
  select_filter: {
    type: 'default',
    labelKey: 'common_filters'
  },
  memberName: {
    type: 'text',
    labelKey: 'booking_detail_member',
    placeholder: 'Doe'
  },
  email: {
    type: 'text',
    labelKey: 'backUsers_filterChip_email',
    placeholder: 'john.doe@glide.io'
  },
  status: {
    type: 'select',
    labelKey: 'members_detail_validationStatus',
    options: memberStatusOptions
  },
  subscriptionOrigins: {
    type: 'select',
    labelKey: 'member_subscription_origin',
    options: memberSubscriptionOriginOptions
  },
  expediteReview: {
    type: 'select',
    labelKey: 'members_label_type_expediteReview',
    options: booleanOptions
  },
  technician: {
    type: 'select',
    labelKey: 'members_detail_technician',
    options: booleanOptions
  }
};

export const smartCardEventsFilterTypes = {
  select_filter: {
    type: 'default',
    labelKey: 'vehicleDamages_filters_button'
  },
  action: {
    type: 'select',
    labelKey: 'smartcards_events_tableView_label_action',
    options: smartcardsEventsActionTypes
  },
  result: {
    type: 'select',
    labelKey: 'smartcards_events_tableView_label_result',
    options: smartcardsEventsResultTypes
  },
  startDate: {
    type: 'date',
    subtype: 'start',
    labelKey: 'bookings_tableView_label_startDate'
  },
  endDate: {
    type: 'date',
    subtype: 'end',
    labelKey: 'bookings_tableView_label_endDate'
  },
  smartcardCardId: {
    type: 'text',
    labelKey: 'fuelCards_tableView_label_number',
    placeholder: '00000000C043B348'
  },
  userLogin: {
    type: 'text',
    labelKey: 'common_user_login',
    placeholder: 'FrançoisLeFrançais@gmail.com'
  },
  vehicleRegistrationNumber: {
    type: 'text',
    labelKey: 'groups_tableView_label_plateNumber',
    placeholder: 'DX-275-KV'
  }
};

export const createBookingFilterTypes = (allBrands, allModels, allUsages) => ({
  select_filter: {
    type: 'default',
    labelKey: 'vehicleDamages_filters_button'
  },
  memberFirstName: {
    type: 'text',
    labelKey: 'common_placeHolder_searchForMemberFirstName',
    placeholder: 'John'
  },
  memberLastName: {
    type: 'text',
    labelKey: 'common_placeHolder_searchForMemberLastName',
    placeholder: 'Doe'
  },
  memberLogin: {
    type: 'text',
    labelKey: 'common_placeHolder_searchForMemberEmail',
    placeholder: 'John.Doe@gmail.com'
  },
  status: {
    type: 'select',
    labelKey: 'bookings_tableView_label_status',
    options: bookingStatus
  },
  vehicleUsageAtBookingCreation: {
    type: 'select',
    labelKey: 'vehiclesFiltersForm_usage',
    options: allUsages
  },
  type: {
    type: 'select',
    labelKey: 'common_type',
    options: bookingTypes
  },
  vehicleBrand: {
    type: 'select',
    labelKey: 'vehiclesFiltersForm_brand',
    options: allBrands
  },
  vehicleModel: {
    type: 'select',
    labelKey: 'vehiclesFiltersForm_model',
    options: allModels,
    disabled: allModels.length === 0
  },
  vehicleRegistrationNumber: {
    type: 'text',
    labelKey: 'common_placeHolder_searchForRegistrationNumber'
  },
  startDateMin: {
    type: 'date',
    subtype: 'start',
    labelKey: 'booking_start_date_after'
  },
  startDateMax: {
    type: 'date',
    subtype: 'end',
    labelKey: 'booking_start_date_before'
  },
  endDateMin: {
    type: 'date',
    subtype: 'start',
    labelKey: 'booking_end_date_after'
  },
  endDateMax: {
    type: 'date',
    subtype: 'end',
    labelKey: 'booking_end_date_before'
  },
  delayed: {
    type: 'select',
    labelKey: 'bookings_delayed_status',
    options: allDelayedStatues
  },
  failed: {
    type: 'select',
    labelKey: 'bookings_failed',
    options: allFailedOptions
  },
  rrsUpdateFailed: {
    type: 'select',
    labelKey: 'bookings_rrs_failed',
    options: allFailedOptions
  },
  bookingId: {
    type: 'text',
    labelKey: 'common_placeHolder_searchForBookingID',
    placeholder: 'a2bfcdcb'
  },
  voucherCode: {
    type: 'text',
    labelKey: 'common_searchFor_voucherCode',
    placeholder: 'voucher code'
  },
  voucherGroupName: {
    type: 'text',
    labelKey: 'common_searchFor_VoucherGroupName',
    placeholder: 'voucher group name'
  },
  voucherGroupIds: {
    type: 'text',
    labelKey: 'common_searchFor_voucherGroupId',
    placeholder: 'voucher group ID'
  }
});

export const createVpFilterTypes = allSites => ({
  select_filter: {
    type: 'default',
    labelKey: 'vehicleDamages_filters_button'
  },
  registrationNumber: {
    type: 'text',
    labelKey: 'common_placeHolder_searchForRegistrationNumber',
    placeholder: 'FR-123-GG'
  },
  usages: {
    type: 'select',
    labelKey: 'vehiclesFiltersForm_usage',
    options: vehicleUsages
  },
  fuels: {
    type: 'select',
    labelKey: 'vehicleForm_fuelType',
    options: vehicleFuelTypes
  },
  sites: {
    type: 'select',
    labelKey: 'autocomplete_company_sites_title',
    options: allSites
  }
});

export const feedbacksFilterTypes = {
  select_filter: {
    type: 'default',
    labelKey: 'backUsers_filters_button'
  },
  bookingId: {
    type: 'text',
    labelKey: 'feedbacks_detail_bookingId',
    placeholder: '...'
  },
  memberEmail: {
    type: 'text',
    labelKey: 'group_detail_tableView_email',
    placeholder: '...'
  },
  memberName: {
    type: 'text',
    labelKey: 'booking_detail_member',
    placeholder: '...'
  },
  plateNumber: {
    type: 'text',
    labelKey: 'booking_detail_vehicle_registration_number',
    placeholder: '...'
  },
  withCleanlinessProblems: {
    type: 'select',
    labelKey: 'feedbacks_label_cleanliness_problem',
    options: withCleanlinessProblemsOptions
  },
  withDamagesDeclared: {
    type: 'select',
    labelKey: 'feedbacks_label_damage_declared',
    options: withCleanlinessProblemsOptions
  },
  status: {
    type: 'select',
    labelKey: 'feedbacks_label_statuses',
    options: feedbackStatusOptions
  }
};

export const backUsersFilterTypes = {
  select_filter: {
    type: 'default',
    labelKey: 'backUsers_filters_button'
  },
  firstName: {
    type: 'text',
    labelKey: 'common_placeHolder_searchForMemberFirstName',
    placeholder: 'John'
  },
  lastName: {
    type: 'text',
    labelKey: 'common_placeHolder_searchForMemberLastName',
    placeholder: 'Doe'
  },
  email: {
    type: 'text',
    labelKey: 'common_placeHolder_searchForMemberEmail',
    placeholder: 'John.Doe@gmail.com'
  },
  role: {
    type: 'select',
    labelKey: 'backUsers_detail_role',
    options: backUsersRolesStatic
  }
};

export const companiesFilterTypes = {
  select_filter: {
    type: 'default',
    labelKey: 'backUsers_filters_button'
  },
  name: {
    type: 'text',
    labelKey: 'booking_detail_company',
    placeholder: '...'
  }
};

export const createVehicleFilterTypes = (allBrands, allModels, allCategories, allColors, allVersions, allUsages) => ({
  select_filter: {
    type: 'default',
    labelKey: 'vehicleDamages_filters_button'
  },
  brand: {
    type: 'select',
    labelKey: 'vehiclesFiltersForm_brand',
    options: allBrands
  },
  model: {
    type: 'select',
    labelKey: 'vehiclesFiltersForm_model',
    options: allModels,
    disabled: allModels.length === 0
  },
  version: {
    type: 'select',
    labelKey: 'vehiclesFiltersForm_version',
    options: allVersions,
    disabled: allVersions.length === 0
  },
  category: {
    type: 'select',
    labelKey: 'vehiclesFiltersForm_category',
    options: allCategories
  },
  usage: {
    type: 'select',
    labelKey: 'vehiclesFiltersForm_usage',
    options: allUsages
  },
  color: {
    type: 'select',
    labelKey: 'vehiclesFiltersForm_color',
    options: allColors
  },
  systemType: {
    type: 'select',
    labelKey: 'vehiclesFiltersForm_systemType',
    options: allSystemTypes
  },
  vehicleStatus: {
    type: 'select',
    labelKey: 'vehiclesFiltersForm_status',
    options: statusListLabels
  },
  type: {
    type: 'select',
    labelKey: 'vehicleForm_type',
    options: vehicleTypes
  },
  cleanliness: {
    type: 'select',
    labelKey: 'vehicleForm_cleanlinessStatus',
    options: vehicleCleanlinessStatuses
  },
  plateNumber: {
    type: 'text',
    labelKey: 'vehiclesFiltersForm_plateNumber',
    placeholder: 'DX-275-KV'
  },
  vin: {
    type: 'text',
    labelKey: 'vehiclesFiltersForm_VIN',
    placeholder: '4S3BMHB68B3286050'
  },
  lowFuelLevel: {
    type: 'option',
    labelKey: 'dashboard.fuelCharge.Level.label'
  },
  telematicDeviceId: {
    type: 'text',
    labelKey: 'vehiclesFiltersForm_deviceId',
    placeholder: '9C000018940E3201'
  },
  includeUninstalledDevice: {
    type: 'option', // always true if selected
    labelKey: 'vehicle_list_include_uninstalled_devices'
  }
});

export const smartCardFilterTypes = {
  select_filter: {
    type: 'default',
    labelKey: 'vehicleDamages_filters_button'
  },
  userLogin: {
    type: 'text',
    labelKey: 'common_placeHolder_searchForMemberEmail',
    placeholder: 'mike@gmail.com'
  }
};

export const invoicesFilterTypes = {
  select_filter: {
    type: 'default',
    labelKey: 'common_filters'
  },
  reference: {
    type: 'text',
    labelKey: 'common_placeHolder_searchForInvoiceNumber'
  },
  status: {
    type: 'select',
    labelKey: 'invoices_tableView_label_status',
    options: invoiceStatuses
  },
  startDate: {
    type: 'date',
    subtype: 'start',
    labelKey: 'invoices_tableView_label_issueDateFrom'
  },
  endDate: {
    type: 'date',
    subtype: 'end',
    labelKey: 'invoices_tableView_label_issueDateTo'
  }
};

export const invoicesFilterTypesV2 = {
  ...invoicesFilterTypes,
  bookingId: {
    type: 'text',
    labelKey: 'common_booking_id'
  }
};

export const voucherGroupsFilterTypes = {
  select_filter: {
    type: 'default',
    labelKey: 'common_filters'
  },
  name: {
    type: 'text',
    labelKey: 'voucher_group_name'
  },
  voucherType: {
    type: 'select',
    labelKey: 'common_type',
    options: voucherTypes
  },
  fromCreationDate: {
    type: 'date',
    subtype: 'start',
    labelKey: 'voucher_creation_date_start'
  },
  toCreationDate: {
    type: 'date',
    subtype: 'end',
    labelKey: 'voucher_creation_date_end'
  }
};

export const vouchersFilterTypes = {
  select_filter: {
    type: 'default',
    labelKey: 'common_filters'
  },
  revoked: {
    type: 'select',
    labelKey: 'voucher_revoked_label',
    options: booleanTypes
  },
  creationDate: {
    type: 'date',
    labelKey: 'common_creation_date'
  }
};

export const paybacksFilterTypes = {
  select_filter: {
    type: 'default',
    labelKey: 'common_filters'
  },
  bookingId: {
    type: 'text',
    labelKey: 'common_booking_id'
  },
  creationDatefrom: {
    type: 'date',
    labelKey: 'common_creation_date_after',
    subtype: 'start'
  },
  creationDateUntil: {
    type: 'date',
    labelKey: 'common_creation_date_before',
    subtype: 'start'
  }
};

export const bankoutListFilterTypes = {
  select_filter: {
    type: 'default',
    labelKey: 'common_filters'
  },
  billingEntityName: {
    type: 'text',
    labelKey: 'billing_entity_name_label'
  },
  bookingId: {
    type: 'text',
    labelKey: 'common_booking_id'
  },
  creationDateLowerLimit: {
    type: 'date',
    subtype: 'start',
    labelKey: 'common_creation_date_after'
  },
  creationDateUpperLimit: {
    type: 'date',
    subtype: 'start',
    labelKey: 'common_creation_date_before'
  },
  bankoutStatus: {
    type: 'select',
    labelKey: 'bankout_status_label',
    options: bankoutStatusOptionKeys
  }
};

export const vehicleDetailFilterTypes = {
  select_filter: {
    type: 'default',
    labelKey: 'common_filters'
  },
  from: {
    type: 'date',
    subtype: 'start',
    labelKey: 'common_start_date'
  },
  to: {
    type: 'date',
    subtype: 'end',
    labelKey: 'common_end_date'
  }
};
