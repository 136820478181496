import React, { Component, PropTypes } from 'react';
import { FormattedMessage } from 'react-intl';
import { reduxForm } from 'redux-form';

import FormRow from '../FormRow/FormRow';
import BoxedInput from '../BoxedInput/BoxedInput';
import EkButton from '../EkButton/EkButton';
import FieldErrorMsg from '../FieldErrorMsg/FieldErrorMsg';
import { createValidator, notEmpty } from '../../validation/all-validation';
import routes from '../../constants/routes-constants';
import BackLink from '../BackLink/BackLink';
import { scrollToFirstError } from '../../utils/utils';

class VehiclesCategoryForm extends Component {
  constructor() {
    super();
  }

  render() {
    const {
      limitedAccess,
      fields: { type, description },
      handleSubmit
    } = this.props;

    return (
      <section className="vehiclesCategoryFormWrapper">
        <BackLink link={`/#${routes.vehiclesCategories.path}`} labelKey="back_link_categories_list" />
        <form action="#" className="vehiclesCategoryForm" onSubmit={handleSubmit(this.props.onCallback)}>
          <div className="vehiclesCategoryForm_container">
            <h4 className="vehiclesCategoryForm_title">
              <FormattedMessage id="vehiclesCategoryForm_title" />
            </h4>
            <FormRow customClass="vehiclesCategoryForm_row">
              <BoxedInput
                formRowItemKey="vehiclesCategoryForm_submitBtn"
                type="text"
                id="type"
                labelKey="vehiclesCategoryForm_name_label"
                customClass="vehiclesCategoryForm_boxedInput"
                field={type}
                disabled={limitedAccess}
              >
                <FieldErrorMsg field={type} customClass="fieldErrorMsg--vehiclesCategoryForm" />
              </BoxedInput>
            </FormRow>
            <FormRow customClass="vehiclesCategoryForm_row">
              <BoxedInput
                formRowItemKey="vehiclesCategoryForm_submitBtn"
                type="text"
                id="description"
                labelKey="vehiclesCategoryForm_description_label"
                customClass="vehiclesCategoryForm_boxedInput"
                field={description}
                disabled={limitedAccess}
              >
                <FieldErrorMsg field={description} customClass="fieldErrorMsg--vehiclesCategoryForm" />
              </BoxedInput>
            </FormRow>
          </div>
          <div className="vehiclesCategoryForm_actions">
            <a href={`/#${routes.vehiclesCategories.path}`} className="ekButton ekButton--reverse vehiclesCategoryForm_actionsLink">
              <FormattedMessage id="vehiclesCategoryForm_cancel_button" />
            </a>
            <EkButton
              customClass="vehiclesCategoryForm_actionsButton"
              onAction={handleSubmit(this.props.onCallback)}
              disabled={limitedAccess}
            >
              <FormattedMessage id="vehiclesCategoryForm_save_button" />
            </EkButton>
          </div>
        </form>
      </section>
    );
  }
}

VehiclesCategoryForm.displayName = 'VehiclesCategoryForm';

VehiclesCategoryForm.propTypes = {
  fields: PropTypes.object,
  handleSubmit: PropTypes.func,
  limitedAccess: PropTypes.bool,
  onCallback: PropTypes.func
};

VehiclesCategoryForm = reduxForm({
  onSubmitFail: scrollToFirstError,
  form: 'vehiclesCategoryForm',
  fields: ['type', 'description'],
  validate: createValidator({
    type: [notEmpty()]
  })
})(VehiclesCategoryForm);

export default VehiclesCategoryForm;
