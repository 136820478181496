import React, { Component, PropTypes } from 'react';
import { FormattedMessage } from 'react-intl';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';

import FormRow from '../FormRow/FormRow';
import BoxedInput from '../BoxedInput/BoxedInput';
import EkRadio from '../EkRadio/EkRadio';
import BoxedSelect from '../BoxedSelect/BoxedSelect';
import BoxedToggle from '../BoxedToggle/BoxedToggle';
import EkButton from '../EkButton/EkButton';
import AddressAutocomplete from '../AddressAutocomplete/AddressAutocomplete';
import FieldErrorMsg from '../FieldErrorMsg/FieldErrorMsg';

import { createValidator, notEmpty, address, length, integer, minimum, stopValidationIf, condition } from '../../validation/all-validation';
import routes from '../../constants/routes-constants';
import { booleanOptions, spontaneousBookingUsageTypes } from '../../constants/options-constants';
import { LIST_TIMEZONE_ID } from '../../constants/timeZoneId-constantes';
import { number as normalizeNumber } from '../../normalizers';
import BackLink from '../BackLink/BackLink';
import {
  callbackNot as not,
  getFieldValue,
  scrollToFirstError,
  toBoolean,
  safe,
  getCompanyNameUrl,
  getSubCompanyNameUrl,
  isObjEmpty
} from '../../utils/utils';
import { routeActions } from 'react-router-redux';
import {
  currentContractBookingValuesSelector,
  currentCompanyContractSelector,
  subCompaniesListSelector
} from '../../selectors/all-selectors';

class SiteForm extends Component {
  constructor(props) {
    super(props);
    this.bindCallbacks();
  }

  bindCallbacks() {
    this.handleSubCompanySelect = this.handleSubCompanySelect.bind(this);
    this.getCoordinatesLocation = this.getCoordinatesLocation.bind(this);
    this.goToSites = this.goToSites.bind(this);
  }

  goToSites() {
    if (this.props.onCancel) {
      return this.props.onCancel();
    }
    this.props.dispatch(routeActions.push(routes.sites.path));
  }

  handleSubCompanySelect(value) {
    if (this.props.onSubCompanySelect) {
      this.props.onSubCompanySelect(value);
    }
  }

  getCoordinatesLocation(coords) {
    this.props.onGetCoordinatesLocationCallback(coords);
  }

  photoOverride(showDamageExtraPhoto) {
    const {
      fields: { enablePhotoOverride, overrideSuperCompanyReportConfig }
    } = this.props;

    if (!safe(() => overrideSuperCompanyReportConfig.value)) return;
    if (!showDamageExtraPhoto) return;

    return (
      <FormRow customWrapperClass="siteForm-override-params" customClass="siteForm_row">
        <BoxedToggle
          labelKey="siteForm_vehicleInspectionPhotos"
          field={enablePhotoOverride}
          labelStyle={{ fontSize: 'inherit', lineHeight: 'inherit' }}
          className="override-toggle"
        />
        {this.photoOverrideRadios()}
      </FormRow>
    );
  }

  photoOverrideRadios() {
    const {
      fields: { photoStartBooking, photoEndBooking, enablePhotoOverride, overrideSuperCompanyReportConfig }
    } = this.props;

    if (!safe(() => enablePhotoOverride.value)) return;
    if (!safe(() => overrideSuperCompanyReportConfig.value)) return;

    return (
      <FormRow customClass="siteForm_report-params">
        <EkRadio
          customContainerClass="siteForm_ekRadioContainer"
          customWrapperClass="siteForm_ekRadioWrapper"
          customTitleClass="siteForm_ekRadioTitle"
          titleKey="companyForm_startBookingReportAdditionalPhoto_label"
          items={booleanOptions}
          field={photoStartBooking}
        />
        <EkRadio
          customContainerClass="siteForm_ekRadioContainer"
          customWrapperClass="siteForm_ekRadioWrapper"
          customTitleClass="siteForm_ekRadioTitle"
          titleKey="companyForm_endBookingReportAdditionalPhoto_label"
          items={booleanOptions}
          field={photoEndBooking}
        />
      </FormRow>
    );
  }

  prebookingFeature(showPrebookingFeature) {
    const {
      fields: { preBookingConfirmDelayMin, preBookingCancelDelay, preBookingStartDelay },
      limitedAccess
    } = this.props;
    if (!showPrebookingFeature) return '';

    return (
      <FormRow customWrapperClass="siteForm_parameters" customClass="siteForm_row">
        <BoxedInput
          formRowItemKey="siteForm_preBookingConfirmDelay"
          labelKey="siteForm_preBookingConfirmDelayMin_label"
          id="preBookingConfirmDelayMin"
          type="number"
          min={0}
          customClass="siteForm_boxedInput"
          field={preBookingConfirmDelayMin}
          readOnly={limitedAccess}
        >
          <FieldErrorMsg field={preBookingConfirmDelayMin} customClass="fieldErrorMsg--siteForm" />
        </BoxedInput>
        <BoxedInput
          type="number"
          min={0}
          labelKey="site.pre.booking.cancel.delay"
          customClass="siteForm_boxedInput"
          field={preBookingCancelDelay}
          readOnly={limitedAccess}
        >
          <FieldErrorMsg field={preBookingCancelDelay} customClass="fieldErrorMsg--siteForm" />
        </BoxedInput>
        <BoxedInput
          formRowItemKey="siteForm_preBookingStartDelay"
          labelKey="site.pre.booking.start.delay"
          id="preBookingStartDelay"
          type="number"
          min={0}
          customClass="siteForm_boxedInput"
          field={preBookingStartDelay}
          readOnly={limitedAccess}
        >
          <FieldErrorMsg field={preBookingStartDelay} customClass="fieldErrorMsg--siteForm" />
        </BoxedInput>
      </FormRow>
    );
  }

  photoOverrideFields(showDamageExtraPhoto) {
    const {
      fields: {
        nameForElectricVehicle,
        nameForHybridVehicle,
        nameForThermalVehicle,
        enablePhotoOverride,
        overrideSuperCompanyReportConfig
      },
      limitedAccess
    } = this.props;

    if (!safe(() => enablePhotoOverride.value)) return;
    if (!safe(() => overrideSuperCompanyReportConfig.value)) return;
    if (!showDamageExtraPhoto) return;

    return (
      <FormRow customClass="siteForm_row">
        <BoxedInput
          type="text"
          labelKey="reportAdditionalPhotoNameForElectricVehicle_label"
          customClass="siteForm_boxedInput"
          field={nameForElectricVehicle}
          readOnly={limitedAccess}
        >
          <FieldErrorMsg field={nameForElectricVehicle} customClass="fieldErrorMsg--siteForm" />
        </BoxedInput>
        <BoxedInput
          type="text"
          labelKey="reportAdditionalPhotoNameForHybridVehicle_label"
          customClass="siteForm_boxedInput"
          field={nameForHybridVehicle}
          readOnly={limitedAccess}
        >
          <FieldErrorMsg field={nameForHybridVehicle} customClass="fieldErrorMsg--siteForm" />
        </BoxedInput>
        <BoxedInput
          type="text"
          labelKey="reportAdditionalPhotoNameForThermalVehicle_label"
          customClass="siteForm_boxedInput"
          field={nameForThermalVehicle}
          readOnly={limitedAccess}
        >
          <FieldErrorMsg field={nameForThermalVehicle} customClass="fieldErrorMsg--siteForm" />
        </BoxedInput>
      </FormRow>
    );
  }

  displayDamageOverrideParams(showDamageWithPhoto) {
    const {
      fields: {
        overrideSuperCompanyReportConfig: { value: damageEnabled = false } = {},
        startBookingReportMandatory,
        endBookingReportMandatory,
        reportPhotos
      }
    } = this.props;

    if (damageEnabled) {
      return (
        <FormRow customClass="siteForm_report-params">
          <EkRadio
            formRowItemKey="siteForm_startBookingDamageReportMandatory"
            customContainerClass="siteForm_ekRadioContainer"
            customWrapperClass="siteForm_ekRadioWrapper"
            customTitleClass="siteForm_ekRadioTitle"
            titleKey="companyForm_startBookingDamageReportMandatory_label"
            items={booleanOptions}
            namespace="startBookingReportMandatory"
            field={startBookingReportMandatory}
          />
          <EkRadio
            formRowItemKey="siteForm_endBookingDamageReportMandatory"
            customContainerClass="siteForm_ekRadioContainer"
            customWrapperClass="siteForm_ekRadioWrapper"
            customTitleClass="siteForm_ekRadioTitle"
            titleKey="companyForm_endBookingDamageReportMandatory_label"
            items={booleanOptions}
            namespace="endBookingReportMandatory"
            field={endBookingReportMandatory}
          />
          {showDamageWithPhoto && (
            <EkRadio
              formRowItemKey="siteForm_damageReportPhotos"
              customContainerClass="siteForm_ekRadioContainer"
              customWrapperClass="siteForm_ekRadioWrapper"
              customTitleClass="siteForm_ekRadioTitle"
              titleKey="companyForm_damageReportPhotos_label"
              items={booleanOptions}
              namespace="reportPhotos"
              field={reportPhotos}
            />
          )}
        </FormRow>
      );
    }
  }

  getSubCompanyDetails() {
    const { subCompany } = this.props;

    if (!isObjEmpty(subCompany)) {
      return (
        <span>
          <span className="sc-dash">—</span>
          {getSubCompanyNameUrl(subCompany.name, subCompany.id)}
        </span>
      );
    }
  }

  getCompanyDetails() {
    const { company } = this.props;

    if (!isObjEmpty(company)) {
      return (
        <span className="sc-company-info">
          {getCompanyNameUrl(company.name, company.id)}
          {this.getSubCompanyDetails()}
        </span>
      );
    }
  }

  addSmartcardFeature(showSmartcards) {
    const {
      fields: { smartcardEnabled, smartcardFishingEnabled, spontaneousBookingEnabled, spontaneousBookingUsage },
      limitedAccess
    } = this.props;

    if (showSmartcards) {
      return (
        <div>
          <FormRow customWrapperClass="siteForm_parameters" customClass="siteForm_row">
            <EkRadio
              formRowItemKey="siteForm_smartcardEnabled"
              customContainerClass="siteForm_ekRadioContainer"
              customWrapperClass="siteForm_ekRadioWrapper"
              customTitleClass="siteForm_ekRadioTitle"
              titleKey="siteForm_smartcardEnabled_label"
              items={booleanOptions}
              namespace="smartcardEnabled"
              field={smartcardEnabled}
              disabled={limitedAccess}
            >
              <FieldErrorMsg field={smartcardEnabled} customClass="fieldErrorMsg--siteForm" />
            </EkRadio>
          </FormRow>
          <FormRow customWrapperClass="siteForm_parameters" customClass="siteForm_row">
            <EkRadio
              disabled={!toBoolean(smartcardEnabled.value) || limitedAccess}
              formRowItemKey="siteForm_spontaneousBookingEnabled"
              customContainerClass="siteForm_ekRadioContainer"
              customWrapperClass="siteForm_ekRadioWrapper"
              customTitleClass="siteForm_ekRadioTitle"
              titleKey="siteForm_spontaneousBookingEnabled_label"
              items={booleanOptions}
              namespace="spontaneousBookingEnabled"
              field={spontaneousBookingEnabled}
            >
              <FieldErrorMsg field={spontaneousBookingEnabled} customClass="fieldErrorMsg--siteForm" />
            </EkRadio>
          </FormRow>
          <FormRow customWrapperClass="siteForm_parameters" customClass="siteForm_row">
            <EkRadio
              disabled={!toBoolean(smartcardEnabled.value) || spontaneousBookingEnabled.value === 'false' || limitedAccess}
              formRowItemKey="siteForm_spontaneousBookingUsage"
              customContainerClass="siteForm_ekRadioContainer"
              customWrapperClass="siteForm_ekRadioWrapper"
              customTitleClass="siteForm_ekRadioTitle"
              titleKey="siteForm_spontaneousBookingUsage_label"
              items={spontaneousBookingUsageTypes}
              namespace="spontaneousBookingUsage"
              field={spontaneousBookingUsage}
            >
              <FieldErrorMsg field={spontaneousBookingUsage} customClass="fieldErrorMsg--siteForm" />
            </EkRadio>
          </FormRow>
          <FormRow customWrapperClass="siteForm_parameters" customClass="siteForm_row">
            <EkRadio
              disabled={!toBoolean(smartcardEnabled.value) || limitedAccess}
              formRowItemKey="siteForm_smartcardFishingEnabled"
              customContainerClass="siteForm_ekRadioContainer"
              customWrapperClass="siteForm_ekRadioWrapper"
              customTitleClass="siteForm_ekRadioTitle"
              titleKey="siteForm_smartcardFishingEnabled_label"
              items={booleanOptions}
              namespace="smartcardFishingEnabled"
              field={smartcardFishingEnabled}
            >
              <FieldErrorMsg field={smartcardFishingEnabled} customClass="fieldErrorMsg--siteForm" />
            </EkRadio>
          </FormRow>
        </div>
      );
    }
  }

  render() {
    const {
      fields: {
        name,
        address,
        postalCode,
        city,
        country,
        zoneId,
        subCompanyId,
        timeUnitOfBooking,
        minDurationOfBooking,
        maxDurationOfBooking,
        reservationBuffer,
        maxStartPlannedBookingInDays,
        minStartPlannedBookingInMin,
        maxStartImmediateBookingInMin,
        periodBeforeCantNoMoreUpdateStartDate,
        overrideSuperCompanyReportConfig,
        periodBeforeCantNoMoreUpdateEndDate,
        periodBeforeCantNoMoreCancel,
        carPriorReservation,
        automaticShortening,
        automaticExtension,
        chargeExpiredBooking,
        automaticNoShowOneWay,
        automaticNoShowRoundTrip,
        sendReportToDekra
      },
      list,
      handleSubmit,
      limitedAccess,
      edit,
      displayDekra,
      dekraDetails
    } = this.props;

    const submitAction = limitedAccess ? undefined : handleSubmit(this.props.onCallback);

    const subCompanies = list.map(item => {
      return {
        label: item.name,
        value: item.id
      };
    });

    const { damageReport, damageWithPhoto, damageExtraPhoto, dekra, smartcards } = this.props.bookingRules;
    const { preBooking } = this.props.contract;
    const showDamageReport = damageReport;
    const showDamageWithPhoto = damageWithPhoto;
    const showDamageExtraPhoto = damageExtraPhoto;
    const showDekra = dekra;
    const showSmartcards = smartcards;
    const showPrebookingFeature = preBooking;

    return (
      <section className="siteFormWrapper">
        {!this.props.isModal && <BackLink link={`/#${routes.sites.path}`} labelKey="back_link_sites_list" />}
        <form action="#" className="siteForm" onSubmit={submitAction}>
          <div className="siteForm_container">
            <h4 className="siteForm_title">
              <FormattedMessage id="siteForm_title" />
              {this.getCompanyDetails()}
            </h4>
            <FormRow customClass="siteForm_row">
              <BoxedInput
                formRowItemKey="siteForm_submitBtn"
                type="text"
                id="name"
                labelKey="siteForm_name_label"
                customClass="siteForm_boxedInput"
                field={name}
                readOnly={limitedAccess}
              >
                <FieldErrorMsg field={name} customClass="fieldErrorMsg--siteForm" />
              </BoxedInput>

              {!edit && (
                <BoxedSelect
                  formRowItemKey="companyForm_parent_configuration"
                  id="subCompanyId"
                  labelKey="siteForm_company_label"
                  options={subCompanies}
                  field={subCompanyId}
                  customClass="siteForm_boxedSelectWrapper"
                  onChange={this.handleSubCompanySelect}
                  disabled={this.props.readOnlyCompany}
                >
                  <FieldErrorMsg field={subCompanyId} customClass="fieldErrorMsg--siteForm" />
                </BoxedSelect>
              )}
            </FormRow>
            <FormRow customClass="siteForm_row">
              <label formRowItemKey="siteForm_address_label" className="boxedInput_label">
                <span className="boxedInput_labelText">
                  <FormattedMessage id="siteForm_address_label" />
                </span>
                <AddressAutocomplete
                  field={address}
                  className="siteForm_AddressAutocomplete"
                  getValuesLocationCallback={this.getCoordinatesLocation}
                  form="siteForm"
                  searchType={['address']}
                  spreadExternalFields
                  disabled={limitedAccess}
                />
                <FieldErrorMsg field={address} customClass="fieldErrorMsg--siteForm" />
              </label>
              <BoxedInput
                formRowItemKey="siteForm_postalCode"
                type="text"
                id="postalCode"
                labelKey="common_autocomplete_postal_code"
                customClass="siteForm_boxedInput"
                field={postalCode}
                readOnly={limitedAccess}
              >
                <FieldErrorMsg field={postalCode} customClass="fieldErrorMsg--siteForm" />
              </BoxedInput>
              <BoxedInput
                formRowItemKey="siteForm_city"
                type="text"
                id="city"
                labelKey="common_autocomplete_city"
                customClass="siteForm_boxedInput"
                field={city}
                readOnly={limitedAccess}
              >
                <FieldErrorMsg field={city} customClass="fieldErrorMsg--siteForm" />
              </BoxedInput>
              <label className="boxedInput_label">
                <span className="boxedInput_labelText">
                  <FormattedMessage id="common_autocomplete_country" />
                </span>
                <AddressAutocomplete field={country} searchType={['country']} disabled={limitedAccess} translateCountryCode />
                <FieldErrorMsg field={country} customClass="fieldErrorMsg--siteForm" />
              </label>
            </FormRow>
            <FormRow>
              <BoxedSelect
                formRowItemKey="siteForm_timeZoneId"
                id="zoneId"
                labelKey="siteForm_timeZone_label"
                options={LIST_TIMEZONE_ID}
                field={zoneId}
                disabled={limitedAccess}
                customClass="siteForm_boxedSelectWrapper"
              >
                <FieldErrorMsg field={zoneId} customClass="fieldErrorMsg--siteForm" />
              </BoxedSelect>
            </FormRow>
            <FormRow customWrapperClass="siteForm_parameters" customClass="siteForm_row">
              <BoxedInput
                formRowItemKey="siteForm_timeUnitOfBooking"
                labelKey="siteForm_timeUnitOfBooking_label"
                id="timeUnitOfBooking"
                type="number"
                min={0}
                customClass="siteForm_boxedInput"
                field={timeUnitOfBooking}
                readOnly={limitedAccess}
              >
                <FieldErrorMsg field={timeUnitOfBooking} customClass="fieldErrorMsg--siteForm" />
              </BoxedInput>

              <BoxedInput
                formRowItemKey="siteForm_minDurationOfBooking"
                labelKey="siteForm_minDurationOfBooking_label"
                id="minDurationOfBooking"
                type="number"
                min={0}
                customClass="siteForm_boxedInput"
                field={minDurationOfBooking}
                readOnly={limitedAccess}
              >
                <FieldErrorMsg field={minDurationOfBooking} customClass="fieldErrorMsg--siteForm" />
              </BoxedInput>

              <BoxedInput
                formRowItemKey="siteForm_maxDurationOfBooking"
                labelKey="siteForm_maxDurationOfBooking_label"
                id="maxDurationOfBooking"
                type="number"
                min={0}
                customClass="siteForm_boxedInput"
                field={maxDurationOfBooking}
                readOnly={limitedAccess}
              >
                <FieldErrorMsg field={maxDurationOfBooking} customClass="fieldErrorMsg--siteForm" />
              </BoxedInput>
            </FormRow>
            <FormRow customWrapperClass="siteForm_parameters" customClass="siteForm_row">
              <BoxedInput
                formRowItemKey="siteForm_reservationBuffer"
                labelKey="siteForm_reservationBuffer_label"
                id="reservationBuffer"
                type="number"
                min={0}
                customClass="siteForm_boxedInput"
                field={reservationBuffer}
                readOnly={limitedAccess}
              >
                <FieldErrorMsg field={reservationBuffer} customClass="fieldErrorMsg--siteForm" />
              </BoxedInput>

              <BoxedInput
                formRowItemKey="siteForm_maxStartPlannedBookingInDays"
                labelKey="siteForm_maxStartPlannedBookingInDays_label"
                id="maxStartPlannedBookingInDays"
                type="number"
                min={0}
                customClass="siteForm_boxedInput"
                field={maxStartPlannedBookingInDays}
                readOnly={limitedAccess}
              >
                <FieldErrorMsg field={maxStartPlannedBookingInDays} customClass="fieldErrorMsg--siteForm" />
              </BoxedInput>

              <BoxedInput
                formRowItemKey="siteForm_minStartPlannedBookingInMin"
                labelKey="siteForm_minStartPlannedBookingInMin_label"
                id="minStartPlannedBookingInMin"
                type="number"
                min={0}
                customClass="siteForm_boxedInput"
                field={minStartPlannedBookingInMin}
                readOnly={limitedAccess}
              >
                <FieldErrorMsg field={minStartPlannedBookingInMin} customClass="fieldErrorMsg--siteForm" />
              </BoxedInput>
            </FormRow>
            <FormRow customWrapperClass="siteForm_parameters" customClass="siteForm_row">
              <BoxedInput
                formRowItemKey="siteForm_periodBeforeCantNoMoreUpdateStartDate"
                labelKey="siteForm_periodBeforeCantNoMoreUpdateStartDate_label"
                id="periodBeforeCantNoMoreUpdateStartDate"
                type="number"
                min={0}
                customClass="siteForm_boxedInput"
                field={periodBeforeCantNoMoreUpdateStartDate}
                readOnly={limitedAccess}
              >
                <FieldErrorMsg field={periodBeforeCantNoMoreUpdateStartDate} customClass="fieldErrorMsg--siteForm" />
              </BoxedInput>

              <BoxedInput
                formRowItemKey="siteForm_periodBeforeCantNoMoreUpdateEndDate"
                labelKey="siteForm_periodBeforeCantNoMoreUpdateEndDate_label"
                id="periodBeforeCantNoMoreUpdateEndDate"
                type="number"
                min={0}
                customClass="siteForm_boxedInput"
                field={periodBeforeCantNoMoreUpdateEndDate}
                readOnly={limitedAccess}
              >
                <FieldErrorMsg field={periodBeforeCantNoMoreUpdateEndDate} customClass="fieldErrorMsg--siteForm" />
              </BoxedInput>

              <BoxedInput
                formRowItemKey="siteForm_periodBeforeCantNoMoreCancel"
                labelKey="siteForm_periodBeforeCantNoMoreCancel_label"
                id="periodBeforeCantNoMoreCancel"
                type="number"
                min={0}
                customClass="siteForm_boxedInput"
                field={periodBeforeCantNoMoreCancel}
                readOnly={limitedAccess}
              >
                <FieldErrorMsg field={periodBeforeCantNoMoreCancel} customClass="fieldErrorMsg--siteForm" />
              </BoxedInput>
            </FormRow>

            {this.prebookingFeature(showPrebookingFeature)}

            <FormRow customWrapperClass="siteForm_parameters" customClass="siteForm_row">
              <BoxedInput
                formRowItemKey="siteForm_carPriorReservation"
                labelKey="siteForm_carPriorReservation_label"
                id="carPriorReservation"
                type="number"
                min={0}
                customClass="siteForm_boxedInput"
                field={carPriorReservation}
                readOnly={limitedAccess}
              >
                <FieldErrorMsg field={carPriorReservation} customClass="fieldErrorMsg--siteForm" />
              </BoxedInput>
            </FormRow>
            <FormRow customWrapperClass="siteForm_parameters" customClass="siteForm_row">
              <BoxedInput
                formRowItemKey="siteForm_maxStartImmediateBookingInMin"
                labelKey="siteForm_maxStartImmediateBookingInMin_label"
                id="maxStartImmediateBookingInMin"
                type="number"
                min={0}
                customClass="siteForm_boxedInput"
                field={maxStartImmediateBookingInMin}
                readOnly={limitedAccess}
              >
                <FieldErrorMsg field={maxStartImmediateBookingInMin} customClass="fieldErrorMsg--siteForm" />
              </BoxedInput>
              <BoxedInput
                formRowItemKey="siteForm_automaticNoShowOneWay"
                labelKey="siteForm_automaticNoShowOneWay_label"
                id="automaticNoShowOneWay"
                type="number"
                min={0}
                customClass="siteForm_boxedInput"
                field={automaticNoShowOneWay}
                readOnly={limitedAccess}
              >
                <FieldErrorMsg field={automaticNoShowOneWay} customClass="fieldErrorMsg--siteForm" />
              </BoxedInput>

              <BoxedInput
                formRowItemKey="siteForm_automaticNoShowRoundTrip"
                labelKey="siteForm_automaticNoShowRoundTrip_label"
                id="automaticNoShowRoundTrip"
                type="number"
                min={0}
                customClass="siteForm_boxedInput"
                field={automaticNoShowRoundTrip}
                readOnly={limitedAccess}
              >
                <FieldErrorMsg field={automaticNoShowRoundTrip} customClass="fieldErrorMsg--siteForm" />
              </BoxedInput>
            </FormRow>
            <FormRow customWrapperClass="siteForm_parameters" customClass="siteForm_row">
              <EkRadio
                formRowItemKey="siteForm_automaticShortening"
                customContainerClass="siteForm_ekRadioContainer"
                customWrapperClass="siteForm_ekRadioWrapper"
                customTitleClass="siteForm_ekRadioTitle"
                titleKey="siteForm_automaticShortening_label"
                items={booleanOptions}
                namespace="automaticShortening"
                field={automaticShortening}
                disabled={limitedAccess}
              >
                <FieldErrorMsg field={automaticShortening} customClass="fieldErrorMsg--siteForm" />
              </EkRadio>
            </FormRow>
            <FormRow customWrapperClass="siteForm_parameters" customClass="siteForm_row">
              <EkRadio
                formRowItemKey="siteForm_automaticExtension"
                customContainerClass="siteForm_ekRadioContainer"
                customWrapperClass="siteForm_ekRadioWrapper"
                customTitleClass="siteForm_ekRadioTitle"
                titleKey="siteForm_automaticExtension_label"
                items={booleanOptions}
                namespace="automaticExtension"
                field={automaticExtension}
                disabled={limitedAccess}
              >
                <FieldErrorMsg field={automaticExtension} customClass="fieldErrorMsg--siteForm" />
              </EkRadio>
            </FormRow>
            <FormRow customWrapperClass="siteForm_parameters" customClass="siteForm_row">
              <EkRadio
                formRowItemKey="siteForm_chargeExpiredBooking"
                customContainerClass="siteForm_ekRadioContainer"
                customWrapperClass="siteForm_ekRadioWrapper"
                customTitleClass="siteForm_ekRadioTitle"
                titleKey="siteForm_chargeExpiredBooking_label"
                items={booleanOptions}
                namespace="chargeExpiredBooking"
                field={chargeExpiredBooking}
                disabled={limitedAccess}
              >
                <FieldErrorMsg field={chargeExpiredBooking} customClass="fieldErrorMsg--siteForm" />
              </EkRadio>
            </FormRow>

            {this.addSmartcardFeature(showSmartcards)}

            {displayDekra && showDekra && (
              <FormRow customWrapperClass="siteForm_parameters" customClass="siteForm_row">
                <EkRadio
                  formRowItemKey="siteForm_sendReportToDekra"
                  customContainerClass="siteForm_ekRadioContainer"
                  customWrapperClass="siteForm_ekRadioWrapper"
                  customTitleClass="siteForm_ekRadioTitle"
                  titleKey="siteForm_sendReportToDekra_label"
                  items={booleanOptions}
                  namespace="sendReportToDekra"
                  field={sendReportToDekra}
                  disabled={limitedAccess}
                >
                  <FieldErrorMsg field={sendReportToDekra} customClass="fieldErrorMsg--siteForm" />
                </EkRadio>
              </FormRow>
            )}
            {showDamageReport && (
              <FormRow customWrapperClass="siteForm-override-params" customClass="siteForm_row">
                <BoxedToggle
                  labelKey="siteForm_damageReportOverride"
                  field={overrideSuperCompanyReportConfig}
                  labelStyle={{ fontSize: 'inherit', lineHeight: 'inherit' }}
                  className="override-toggle"
                />
                {this.displayDamageOverrideParams(showDamageWithPhoto)}
              </FormRow>
            )}
            {this.photoOverride(showDamageExtraPhoto)}
            {this.photoOverrideFields(showDamageExtraPhoto)}
            {dekraDetails && showDekra && (
              <FormRow customWrapperClass="siteForm-override-params" customClass="siteForm_row">
                <div ormRowItemKey="siteForm_dekra_details">
                  <span className="info">
                    <FormattedMessage id="common_settings" /> Dekra
                  </span>

                  {dekraDetails.dekraCheckService + ' ' + dekraDetails.dekraExpertiseService}
                </div>
              </FormRow>
            )}
          </div>
          {!limitedAccess && (
            <div className="siteForm_actions">
              <EkButton type="button" skinType="reverse" onAction={this.goToSites}>
                <FormattedMessage id="siteForm_cancel_button" />
              </EkButton>
              <EkButton customClass="siteForm_actionsButton" onAction={submitAction}>
                <FormattedMessage id="siteForm_save_button" />
              </EkButton>
            </div>
          )}
        </form>
      </section>
    );
  }
}

SiteForm.displayName = 'SiteForm';

SiteForm.propTypes = {
  onCancel: PropTypes.func,
  isModal: PropTypes.bool,
  fields: PropTypes.object,
  handleSubmit: PropTypes.func,
  onCallback: PropTypes.func,
  onSubCompanySelect: PropTypes.func,
  limitedAccess: PropTypes.bool,
  edit: PropTypes.bool,
  displayDekra: PropTypes.bool,
  company: PropTypes.object,
  subCompany: PropTypes.object,
  readOnlyCompany: PropTypes.bool
};

SiteForm.defaultProps = {
  edit: false,
  displayDekra: false
};

SiteForm.normalizedInputs = {
  timeUnitOfBooking: normalizeNumber,
  minDurationOfBooking: normalizeNumber,
  maxDurationOfBooking: normalizeNumber,
  reservationBuffer: normalizeNumber,
  maxStartPlannedBookingInDays: normalizeNumber,
  minStartPlannedBookingInMin: normalizeNumber,
  preBookingStartDelay: normalizeNumber,
  preBookingCancelDelay: normalizeNumber,
  preBookingConfirmDelayMin: normalizeNumber,
  maxStartImmediateBookingInMin: normalizeNumber,
  periodBeforeCantNoMoreUpdateStartDate: normalizeNumber,
  periodBeforeCantNoMoreUpdateEndDate: normalizeNumber,
  periodBeforeCantNoMoreCancel: normalizeNumber,
  carPriorReservation: normalizeNumber,
  automaticNoShowOneWay: normalizeNumber,
  automaticNoShowRoundTrip: normalizeNumber
};

const siteParametersValidations = [notEmpty(), integer(), minimum(0)];
const siteParametersValidation = [notEmpty(), integer()];

function sendReportToDekra(props, value, values) {
  return toBoolean(getFieldValue(props, values, 'sendReportToDekra'));
}

function hasPrebooking(props, value, values) {
  return toBoolean(props.contract.preBooking);
}
function enablePhotoOverride(props, value, values) {
  const photoOverride = getFieldValue(props, values, 'enablePhotoOverride');
  const globalOverride = getFieldValue(props, values, 'overrideSuperCompanyReportConfig');
  return photoOverride && globalOverride;
}

SiteForm = reduxForm({
  onSubmitFail: scrollToFirstError,
  form: 'siteForm',
  fields: [
    'name',
    'address',
    'postalCode',
    'city',
    'country',
    'companyId',
    'subCompanyId',
    'zoneId',
    'timeUnitOfBooking',
    'minDurationOfBooking',
    'maxDurationOfBooking',
    'reservationBuffer',
    'maxStartPlannedBookingInDays',
    'minStartPlannedBookingInMin',
    'preBookingStartDelay',
    'preBookingCancelDelay',
    'preBookingConfirmDelayMin',
    'maxStartImmediateBookingInMin',
    'periodBeforeCantNoMoreUpdateStartDate',
    'periodBeforeCantNoMoreUpdateEndDate',
    'overrideSuperCompanyReportConfig',
    'startBookingReportMandatory',
    'endBookingReportMandatory',
    'reportPhotos',
    'periodBeforeCantNoMoreCancel',
    'carPriorReservation',
    'automaticShortening',
    'automaticExtension',
    'chargeExpiredBooking',
    'automaticNoShowOneWay',
    'automaticNoShowRoundTrip',
    'smartcardEnabled',
    'smartcardFishingEnabled',
    'spontaneousBookingEnabled',
    'spontaneousBookingUsage',
    'sendReportToDekra',
    'enablePhotoOverride',
    'photoStartBooking',
    'photoEndBooking',
    'nameForElectricVehicle',
    'nameForHybridVehicle',
    'nameForThermalVehicle'
  ],
  validate: createValidator({
    name: [notEmpty()],
    address: [address(), length({ max: 80 })],
    postalCode: [stopValidationIf(condition(not(sendReportToDekra))), notEmpty(), length({ max: 80 })],
    city: [stopValidationIf(condition(not(sendReportToDekra))), notEmpty(), length({ max: 80 })],
    country: [stopValidationIf(condition(not(sendReportToDekra))), address(), length({ max: 80 })],
    timeUnitOfBooking: siteParametersValidations,
    minDurationOfBooking: siteParametersValidations,
    maxDurationOfBooking: siteParametersValidations,
    reservationBuffer: siteParametersValidations,
    maxStartPlannedBookingInDays: siteParametersValidations,
    minStartPlannedBookingInMin: siteParametersValidations,
    preBookingStartDelay: [stopValidationIf(condition(not(hasPrebooking))), notEmpty(), integer(), minimum(0)],
    preBookingConfirmDelayMin: [stopValidationIf(condition(not(hasPrebooking))), notEmpty(), integer(), minimum(0)],
    preBookingCancelDelay: [stopValidationIf(condition(not(hasPrebooking))), notEmpty(), integer(), minimum(0)],
    maxStartImmediateBookingInMin: siteParametersValidations,
    periodBeforeCantNoMoreUpdateStartDate: siteParametersValidations,
    periodBeforeCantNoMoreUpdateEndDate: siteParametersValidation,
    periodBeforeCantNoMoreCancel: siteParametersValidations,
    carPriorReservation: siteParametersValidations,
    automaticNoShowOneWay: siteParametersValidations,
    automaticNoShowRoundTrip: siteParametersValidations,
    automaticShortening: [notEmpty()],
    automaticExtension: [notEmpty()],
    chargeExpiredBooking: [notEmpty()],
    spontaneousBookingUsage: [notEmpty()],
    subCompanyId: [notEmpty()],
    zoneId: [notEmpty()],
    nameForElectricVehicle: [stopValidationIf(condition(not(enablePhotoOverride))), notEmpty()],
    nameForHybridVehicle: [stopValidationIf(condition(not(enablePhotoOverride))), notEmpty()],
    nameForThermalVehicle: [stopValidationIf(condition(not(enablePhotoOverride))), notEmpty()]
  })
})(SiteForm);

export default connect(state => {
  return {
    list: subCompaniesListSelector(state),
    contract: currentCompanyContractSelector(state),
    bookingRules: currentContractBookingValuesSelector(state)
  };
})(SiteForm);
