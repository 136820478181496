import * as types from '../../constants/actionTypes-constants';
import { addFlashMessage } from '../../actions/all-actions';
import { FLASH_MESSAGE_TYPE_ERROR } from '../../constants/generic-constants';

export function changeFilterType(value) {
  return {
    type: types.ADVANCED_LIST_CHANGE_FILTER_VALUE,
    value
  };
}

export function changeFilterTextInput(value) {
  return {
    type: types.ADVANCED_LIST_CHANGE_FILTER_TEXT_INPUT,
    value
  };
}

export function textFilterEmpty() {
  return dispatch => {
    dispatch(
      addFlashMessage({
        contentKey: 'FILTER_SEARCH_TEXT_CANNOT_BE_EMPTY',
        type: FLASH_MESSAGE_TYPE_ERROR
      })
    );
  };
}
