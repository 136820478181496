import { condition, notEmpty, stopValidationIf } from './sync-validation';
import { callbackNot as not, getFieldValue } from '../utils/utils';
import { FREQUENCY_WEEKLY } from '../constants/backend-constants';

// bank transfer validators

const isWeeklyFrequency = (props, value, values) => {
  return getFieldValue(props, values, 'bankoutFrequency') === FREQUENCY_WEEKLY;
};

export const bankTransferValidators = {
  bankoutExecutionDay: [stopValidationIf(condition(not(isWeeklyFrequency))), notEmpty()],
  bankoutFrequency: [notEmpty()]
};

// Commission params validators

export const commissionParamsValidators = {
  commissionVatRate: [notEmpty()],
  commissionRate: [notEmpty()]
};

// all submit validators
export const paybackConfigValidators = { ...bankTransferValidators, ...commissionParamsValidators };

// all fields to validate
export const PAYBACK_FIELDS_TO_VALIDATE = [...Object.keys(bankTransferValidators), ...Object.keys(commissionParamsValidators)];
