/* eslint-disable max-len */
export const template = {
  general: {
    name: 'General',
    prefill: 'general',
    imageUrl: 'https://rcimob-assets.s3.eu-west-1.amazonaws.com/mobile/v3/illustrations/carsharing.png',
    title: {
      fr: 'Location',
      en: 'Lease',
      it: 'Locazione',
      es: 'Alquiler',
      cs: 'Pronájem',
      nl: 'Huren',
      da: 'Leje',
      pl: 'Wynajem',
      de: 'Mieten',
      pt: 'Alugar',
      pt_BR: 'Alugar',
      sk: 'Prenájom',
      sv: 'Hyra'
    },
    description: {
      fr:
        'Accédez à une large gamme de véhicules en autopartage pour vos déplacements.Vous pourrez réaliser l’ensemble des étapes depuis votre smartphone :\n- Recherche de véhicule\n- Création de réservation\n- Ouverture et fermeture\n- Etat des lieux',
      en:
        'Access a wide range of car -sharing vehicles for your trips. You can make all the steps from your smartphone:\n- Vehicle search\n- Reservation creation\n- Opening and closing]n- Inventory',
      it:
        'Accedi a una vasta gamma di veicoli per la condivisione di auto per i tuoi viaggi. Puoi fare tutti i passaggi dal tuo smartphone:\n- Ricerca del veicolo\n- Creazione di prenotazione\n- Apertura e chiusura\n- Inventario',
      es:
        'Acceda a una amplia gama de vehículos compartidos para automóviles para sus viajes. Puede dar todos los pasos desde su teléfono inteligente:\n- Búsqueda de vehículos\n- Creación de reservas\n- Abriendo y cerrando\n- Inventario',
      cs:
        'Získejte přístup k široké škále vozidel pro vaše výlety.\n- Hledání vozidel\n- Vytváření rezervace\n- Otevření a uzavření]n- Inventář',
      nl:
        'Toegang tot een breed scala aan autoverdeling voertuigen voor uw reizen. U kunt alle stappen maken vanaf uw smartphone:\n- Voertuig zoeken\n- Reserveringscreatie\n- Opening en sluiten\n- Inventaris',
      da:
        'Få adgang til en bred vifte af biler, der har biler til dine ture. Du kan foretage alle trin fra din smartphone:\n- Køretøjssøgning\n- Oprettelse af reservation\n- Åbning og lukning\n- Beholdning',
      pl:
        'Uzyskaj dostęp do szerokiej gamy pojazdów do wspólnego samochodu na wycieczki. Możesz wykonać wszystkie kroki ze smartfona:\n- Wyszukiwanie pojazdu\n- Tworzenie rezerwacji\n- Otwarcie i zamykanie\n- Spis',
      de:
        'Greifen Sie für Ihre Reisen auf eine breite Palette von Fahrzeugen mit Auto -Sharing -Fahrzeugen zu. Sie können alle Schritte von Ihrem Smartphone aus ausführen:\n- Fahrzeugsuche\n- Reservierungsschöpfung\n- Öffnen und Schließen\n- Inventar',
      pt:
        'Acesse uma ampla variedade de veículos compartilhados por carros para suas viagens. Você pode fazer todas as etapas do seu smartphone:\n- Pesquisa de veículo\n- Criação de reserva\n- Abrindo e fechando\n- Inventário',
      pt_BR:
        'Acesse uma ampla variedade de veículos compartilhados por carros para suas viagens. Você pode fazer todas as etapas do seu smartphone:\n- Pesquisa de veículo\n- Criação de reserva\n- Abrindo e fechando\n- Inventário',
      sk:
        'Prístup k širokej škále vozidiel na zdieľanie automobilov pre vaše cesty. Všetky kroky zo svojho smartfónu môžete urobiť:\n- Vyhľadávanie vozidla\n- Vytvorenie rezervácie\n- Otváranie a zatváranie\n- Inventár',
      sv:
        'Få tillgång till ett brett utbud av bilar för att dela för dina resor. Du kan göra alla steg från din smartphone:\n- fordonssökning\n- Reservationsskapande\n- Öppning och stängning\n- inventering'
    },
    options: { size: 'default' },
    action: {
      type: 'DEEPLINK',
      title: {
        fr: 'Allons-y !',
        en: "Let's go !",
        it: 'Andiamo !',
        es: 'Vamos !',
        cs: 'Pojďme !',
        nl: 'Laten we gaan !',
        da: 'Lad os gå !',
        pl: 'Chodźmy !',
        de: 'Lass uns gehen !',
        pt: 'Vamos !',
        pt_BR: 'Vamos !',
        sk: 'Poďme !',
        sv: 'Nu går vi !'
      }
    }
  },
  business: {
    name: 'Business',
    prefill: 'business',
    imageUrl: 'https://rcimob-assets.s3.eu-west-1.amazonaws.com/mobile/v3/illustrations/corporate-carsharing.png',
    title: {
      fr: 'Business',
      en: 'Business',
      it: 'Attività commerciale',
      es: 'Negocio',
      cs: 'Podnikání',
      nl: 'Bedrijf',
      da: 'Forretning',
      pl: 'Biznes',
      de: 'Geschäft',
      pt: 'Negócios',
      pt_BR: 'Negócios',
      sk: 'Podnikanie',
      sv: 'Företag'
    },
    description: {
      fr:
        '"Accédez à une large gamme de véhicules disponible gratuitement pour vos déplacements professionels.Vous pourrez réaliser l’ensemble des étapes depuis votre smartphone :\n- Recherche de véhicule\n- Création de réservation\n- Ouverture et fermeture\n- Etat des lieux"',
      en:
        'Access a wide range of vehicles available for free for your professional trips. You can make all the steps from your smartphone:\n- Vehicle search\n- Reservation creation\n- Opening and closing]n- Inventory',
      it:
        'Accedi a una vasta gamma di veicoli disponibili gratuitamente per i tuoi viaggi professionali. Puoi fare tutti i passaggi dal tuo smartphone:\n- Ricerca del veicolo\n- Creazione di prenotazione\n- Apertura e chiusura\n- Inventario',
      es:
        'Acceda a una amplia gama de vehículos disponibles de forma gratuita para sus viajes profesionales. Puede dar todos los pasos desde su teléfono inteligente:\n- Búsqueda de vehículos\n- Creación de reservas\n- Abriendo y cerrando\n- Inventario',
      cs:
        'Přístup k široké škále vozidel dostupných zdarma pro vaše profesionální cesty. Můžete udělat všechny kroky od smartphonu:.\n- Hledání vozidel\n- Vytváření rezervace\n- Otevření a uzavření]n- Inventář',
      nl:
        'Toegang tot een breed scala aan voertuigen die gratis beschikbaar zijn voor uw professionele reizen. U kunt alle stappen maken vanaf uw smartphone:\n- Voertuig zoeken\n- Reserveringscreatie\n- Opening en sluiten\n- Inventaris',
      da:
        'Få adgang til en bred vifte af køretøjer, der er tilgængelige gratis til dine professionelle ture. Du kan foretage alle trin fra din smartphone::\n- Køretøjssøgning\n- Oprettelse af reservation\n- Åbning og lukning\n- Beholdning',
      pl:
        'Uzyskaj dostęp do szerokiej gamy pojazdów do wspólnego samochodu na wycieczki. Możesz wykonać wszystkie kroki ze smartfona:\n- Wyszukiwanie pojazdu\n- Tworzenie rezerwacji\n- Otwarcie i zamykanie\n- Spis',
      de:
        'Greifen Sie auf eine breite Palette von Fahrzeugen zu, die kostenlos für Ihre professionellen Reisen zur Verfügung stehen.\n- Fahrzeugsuche\n- Reservierungsschöpfung\n- Öffnen und Schließen\n- Inventar',
      pt:
        'Acesse uma ampla gama de veículos disponíveis gratuitamente para suas viagens profissionais. Você pode fazer todas as etapas do seu smartphone:\n- Pesquisa de veículo\n- Criação de reserva\n- Abrindo e fechando\n- Inventário',
      pt_BR:
        'Acesse uma ampla gama de veículos disponíveis gratuitamente para suas viagens profissionais. Você pode fazer todas as etapas do seu smartphone:\n- Pesquisa de veículo\n- Criação de reserva\n- Abrindo e fechando\n- Inventário',
      sk:
        'Prístup k širokej škále vozidiel, ktoré sú k dispozícii zadarmo pre vaše profesionálne výlety, môžete urobiť všetky kroky zo svojho smartfónu:\n- Vyhľadávanie vozidla\n- Vytvorenie rezervácie\n- Otváranie a zatváranie\n- Inventár',
      sv:
        'Få tillgång till ett brett utbud av fordon tillgängliga gratis för dina professionella resor. Du kan göra alla steg från din smartphone:\n- fordonssökning\n- Reservationsskapande\n- Öppning och stängning\n- inventering'
    },
    options: { size: 'default' },
    action: {
      type: 'DEEPLINK',
      title: {
        fr: 'Allons-y !',
        en: "Let's go !",
        it: 'Andiamo !',
        es: 'Vamos !',
        cs: 'Pojďme !',
        nl: 'Laten we gaan !',
        da: 'Lad os gå !',
        pl: 'Chodźmy !',
        de: 'Lass uns gehen !',
        pt: 'Vamos !',
        pt_BR: 'Vamos !',
        sk: 'Poďme !',
        sv: 'Nu går vi !'
      },
      filters: {
        usageTypes: 'BUSINESS'
      }
    }
  },
  private: {
    name: 'Private',
    prefill: 'private',
    imageUrl: 'https://rcimob-assets.s3.eu-west-1.amazonaws.com/mobile/v3/illustrations/private-usage.png',
    title: {
      fr: 'Privée',
      en: 'Private',
      it: 'Privato',
      es: 'Privado',
      cs: 'Soukromé',
      nl: 'Privaat',
      da: 'Privat',
      pl: 'Prywatny',
      de: 'Privatgelände',
      pt: 'Privado',
      pt_BR: 'Privado',
      sk: 'Súkromné',
      sv: 'Privat'
    },
    description: {
      fr:
        '"Accédez à une large gamme de véhicules disponible à faible prix pour vos déplacements privée le soir et le week-end.Vous pourrez réaliser l’ensemble des étapes depuis votre smartphone :\n- Recherche de véhicule\n- Création de réservation\n- Ouverture et fermeture\n- Etat des lieux"',
      en:
        'Access a wide range of vehicles available at low prices for your private trips in the evening and the weekend. You can make all the steps from your smartphone:\n- Vehicle search\n- Reservation creation\n- Opening and closing]n- Inventory',
      it:
        'Accedi a una vasta gamma di veicoli disponibili a prezzi bassi per i tuoi viaggi privati ​​la sera e il fine settimana. Puoi fare tutti i passaggi dal tuo smartphone:\n- Ricerca del veicolo\n- Creazione di prenotazione\n- Apertura e chiusura\n- Inventario',
      es:
        'Acceda a una amplia gama de vehículos disponibles de forma gratuita para sus viajes profesionales. Puede dar todos los pasos desde su teléfono inteligente:\n- Búsqueda de vehículos\n- Creación de reservas\n- Abriendo y cerrando\n- Inventario',
      cs:
        'Přístup k široké škále vozidel dostupných za nízké ceny pro vaše soukromé výlety večer a o víkendu. Můžete udělat všechny kroky od smartphonu:\n- Hledání vozidel\n- Vytváření rezervace\n- Otevření a uzavření]n- Inventář',
      nl:
        "Toegang tot een breed scala aan voertuigen die beschikbaar zijn tegen lage prijzen voor uw privéreizen's avonds en het weekend. U kunt alle stappen maken vanaf uw smartphone:\n- Voertuig zoeken\n- Reserveringscreatie\n- Opening en sluiten\n- Inventaris",
      da:
        'Få adgang til en bred vifte af køretøjer, der er tilgængelige til lave priser til dine private ture om aftenen og weekenden. Du kan foretage alle trin fra din smartphone:\n- Køretøjssøgning\n- Oprettelse af reservation\n- Åbning og lukning\n- Beholdning',
      pl:
        'Uzyskaj dostęp do szerokiej gamy pojazdów dostępnych po niskich cenach na prywatne wycieczki wieczorem i weekend. Możesz wykonać wszystkie kroki ze smartfona:\n- Wyszukiwanie pojazdu\n- Tworzenie rezerwacji\n- Otwarcie i zamykanie\n- Spis',
      de:
        'Greifen Sie auf eine breite Palette von Fahrzeugen zu niedrigen Preisen für Ihre privaten Reisen abends und am Wochenende zu. Sie können alle Schritte von Ihrem Smartphone aus ausführen:\n- Fahrzeugsuche\n- Reservierungsschöpfung\n- Öffnen und Schließen\n- Inventar',
      pt:
        'Acesse uma ampla gama de veículos disponíveis a preços baixos para suas viagens particulares à noite e no fim de semana. Você pode fazer todas as etapas do seu smartphone:\n- Pesquisa de veículo\n- Criação de reserva\n- Abrindo e fechando\n- Inventário',
      pt_BR:
        'Acesse uma ampla gama de veículos disponíveis a preços baixos para suas viagens particulares à noite e no fim de semana. Você pode fazer todas as etapas do seu smartphone:\n- Pesquisa de veículo\n- Criação de reserva\n- Abrindo e fechando\n- Inventário',
      sk:
        'Prístup k širokej škále vozidiel, ktoré sú k dispozícii za nízke ceny za vaše súkromné ​​výlety večer a víkend. Môžete urobiť všetky kroky zo svojho smartfónu:\n- Vyhľadávanie vozidla\n- Vytvorenie rezervácie\n- Otváranie a zatváranie\n- Inventár',
      sv:
        'Få tillgång till ett brett utbud av fordon tillgängliga till låga priser för dina privata resor på kvällen och helgen. Du kan göra alla steg från din smartphone:\n- fordonssökning\n- Reservationsskapande\n- Öppning och stängning\n- inventering'
    },
    options: { size: 'default' },
    action: {
      type: 'DEEPLINK',
      title: {
        fr: 'Allons-y !',
        en: "Let's go !",
        it: 'Andiamo !',
        es: 'Vamos !',

        cs: 'Pojďme !',
        nl: 'Laten we gaan !',
        da: 'Lad os gå !',
        pl: 'Chodźmy !',
        de: 'Lass uns gehen !',
        pt: 'Vamos !',
        pt_BR: 'Vamos !',
        sk: 'Poďme !',
        sv: 'Nu går vi !'
      },
      filters: {
        usageTypes: 'PRIVATE'
      }
    }
  }
};
