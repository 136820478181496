// noinspection ES6CheckImport
import React, { Component, PropTypes } from 'react';

import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import IconButton from 'material-ui/IconButton';
import CloseIcon from 'material-ui/svg-icons/navigation/close';
import FlatButton from 'material-ui/FlatButton';
import AutoComplete from 'material-ui/AutoComplete';
import AllIcon from 'material-ui/svg-icons/content/select-all';
import { makeLocationSelector } from '../Header.selectors';
import {
  closeIconSuperCompanyAutoComplete,
  borderWhite,
  lightGrayColor,
  colorWhite,
  fillWhite,
  fillDark,
  colorDark,
  borderDark,
  autoCompleteStyle,
  allIconSuperCompanyAutoComplete,
  autoCompleteAnchorOrigin,
  autoCompleteTargetOrigin,
  autoCompletePopoverProps,
  autoCompleteListStyle
} from '../../../constants/style-constants';
import { toggleCompanyAutoComplete, companyInputChange } from '../Header.actions';
import { namedCompose } from '../../../utils/utils';
import autoBind from 'react-autobind';
import pure from 'recompose/pure';
import { localeSelector, useNewHeaderSelector } from '../../../selectors/all-selectors';
import Tooltip from '../../Tooltip/Tooltip';
import { ALL } from '../../../constants/generic-constants';
import memoizeOne from 'memoize-one';
import { autoCompleteCompare, createAutoCompleteDataSource } from '../utils';

const memoizeSubCompanyListDataSource = memoizeOne(createAutoCompleteDataSource);

class CompanyAutoComplete extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.derivedStateFromProps(props);
  }

  // eslint-disable-next-line no-unused-vars
  componentWillReceiveProps(props, context) {
    this.derivedStateFromProps(props);
  }

  derivedStateFromProps(props) {
    this.companyLabel = props.intl.formatMessage({ id: 'backUsers_tableView_label_company' });
  }

  handleCompanySelect(value, index) {
    if (this.props.onCompanySelect) {
      this.props.onCompanySelect(value, index);
    }
  }

  handleEditing() {
    setTimeout(() => {
      const input = document.querySelector('div.header-superCompanyAutoCompleteWrap input');
      if (input) input.focus();
    }, 50);
    this.props.dispatch(toggleCompanyAutoComplete());
  }

  handleInputChange(text) {
    this.props.dispatch(companyInputChange(text));
  }

  handleSetToAll() {
    if (this.props.onCompanySelect) {
      this.props.onCompanySelect(ALL);
    }
  }

  render() {
    const { subCompanySelected, companyIsEditing, currentCompany, searchMode, companyText, headerSubList } = this.props;

    // noinspection JSUnresolvedVariable
    return (
      <div>
        {currentCompany && currentCompany.id !== ALL && (
          <div className="header-superCompanyAutoComplete">
            <div className={companyIsEditing ? 'header-superCompanyEditing header-superCompanyEditing-edit' : 'header-superCompanyEditing'}>
              {companyIsEditing && (
                <div>
                  <div className="header-superCompanyAutoCompleteWrap">
                    <AutoComplete
                      anchorOrigin={autoCompleteAnchorOrigin}
                      targetOrigin={autoCompleteTargetOrigin}
                      popoverProps={autoCompletePopoverProps}
                      listStyle={autoCompleteListStyle}
                      dataSource={memoizeSubCompanyListDataSource(headerSubList)}
                      underlineStyle={searchMode ? borderDark : borderWhite}
                      underlineFocusStyle={searchMode ? borderDark : borderWhite}
                      hintStyle={lightGrayColor}
                      inputStyle={searchMode ? colorDark : colorWhite}
                      onNewRequest={this.handleCompanySelect}
                      filter={autoCompleteCompare}
                      maxSearchResults={100}
                      menuStyle={autoCompleteStyle}
                      onUpdateInput={this.handleInputChange}
                      searchText={companyText}
                      openOnFocus
                      id="comp"
                    />
                    <IconButton
                      onClick={this.handleEditing}
                      style={closeIconSuperCompanyAutoComplete}
                      iconStyle={searchMode ? fillDark : fillWhite}
                      tooltipPosition="bottom-center"
                      tooltip={<FormattedMessage id="parkingForm_confirmation_abort" />}
                    >
                      <CloseIcon />
                    </IconButton>
                    <IconButton
                      onClick={this.handleSetToAll}
                      style={allIconSuperCompanyAutoComplete}
                      iconStyle={searchMode ? fillDark : fillWhite}
                      tooltipPosition="bottom-center"
                      tooltip={<FormattedMessage id="select_all_companies" />}
                    >
                      <AllIcon />
                    </IconButton>
                  </div>
                  <div className="header-superCompanyCloseOverlay" onClick={this.handleEditing} />
                </div>
              )}
            </div>
            {!companyIsEditing && (
              <div>
                {subCompanySelected && (
                  <Tooltip placement="bottom" distance={25} content={this.companyLabel}>
                    <div className="header-actionIcons-companyName header-companyAutoComplete-button">
                      <FlatButton
                        label={subCompanySelected.name === ALL ? <FormattedMessage id="all_companies" /> : subCompanySelected.name}
                        onClick={this.handleEditing}
                        style={searchMode ? colorDark : colorWhite}
                        labelStyle={searchMode ? colorDark : colorWhite}
                        icon={<i className="company" style={searchMode ? fillDark : fillWhite} />} // eslint-disable-line
                      />
                    </div>
                  </Tooltip>
                )}
                {!subCompanySelected && (
                  <Tooltip placement="bottom" distance={19} content={this.companyLabel}>
                    <div className="header-companyAutoComplete-button">
                      <IconButton onClick={this.handleEditing}>
                        <i className={searchMode ? 'company search' : 'company'} />
                      </IconButton>
                    </div>
                  </Tooltip>
                )}
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}

CompanyAutoComplete.propTypes = {
  onCompanySelect: PropTypes.func,
  subCompanySelected: PropTypes.object,
  userInfo: PropTypes.object,
  companyIsEditing: PropTypes.bool,
  companyText: PropTypes.string
};

export default namedCompose(
  connect(state => {
    const {
      user: { userInfo, enableNewUi },
      header: { companyIsEditing, searchMode, companyText },
      subCompanies: { subCompanySelected, headerSubList },
      companies: { currentCompany }
    } = state;

    return {
      currentCompany,
      userInfo,
      companyIsEditing,
      subCompanySelected,
      headerSubList,
      searchMode: searchMode || enableNewUi || useNewHeaderSelector(state),
      companyText,
      location: makeLocationSelector(state),
      locale: localeSelector(state) // pure exception
    };
  }),
  injectIntl,
  pure
)(CompanyAutoComplete);
