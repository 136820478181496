/*eslint-disable react/jsx-no-bind */
import React, { Component, PropTypes } from 'react';
import { connect } from 'react-redux';
import _get from 'lodash/get';
import { Row, Col } from 'react-bootstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import { MEMBER_TYPE_CATEGORY_CUSTOM } from '../../constants/backend-constants';
import Tooltip from '../../components/Tooltip/Tooltip';
import FlatButton from 'material-ui/FlatButton';
import AddIcon from 'material-ui/svg-icons/content/add';
import DeleteIcon from 'material-ui/svg-icons/action/delete';
import EditIcon from 'material-ui/svg-icons/image/edit';
import MemberTypeFormDialog from './components/MemberTypeForm';
import AdvancedList from '../../components/AdvancedList/AdvancedList';

import { BACKUSER_ROLE_ADMIN, BACKUSER_ROLE_SUPER_ADMIN, BACKUSER_ROLE_ROOT } from '../../constants/backend-constants';

import {
  getMemberTypesList,
  editMemberType,
  openEditMemberTypeModal,
  openCreateMemberTypeModal,
  confirmMemberTypeDeletionModal
} from '../../actions/all-actions';

import autoBind from 'react-autobind';
import { ALL } from '../../constants/generic-constants';
import { userRoleSelector } from '../../selectors/all-selectors';
import { memberTypeEditRules } from '../../constants/routes-constants';
import { checkRole } from '../../constants/backuser-role-rules';

class MemberTypes extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.setAccess(props); // 1
    this.setVariables(); // 2
    this.setHeader(); // 2
  }

  componentWillMount() {
    const { dispatch, currentCompany } = this.props;
    if (_get(currentCompany, 'id', ALL) !== ALL) {
      dispatch(getMemberTypesList());
    }
  }

  setHeader() {
    this.headerProps = [
      {
        labelKey: 'member_types_name',
        md: 7
      },
      {
        labelKey: 'member_types_maximum_scheduled_bookings',
        md: 3
      }
    ];

    if (!this.readOnly)
      this.headerProps.push({
        labelKey: 'common_actions',
        md: 2
      });
  }

  setVariables() {
    this.list = [];
    this.memberType = null;
    this.isOpen = false;
    this.editMode = null;
    this.memberTypeDel = null;
  }

  setAccess(props) {
    const { role } = props;
    this.readOnly = !(
      role === BACKUSER_ROLE_ADMIN ||
      role === BACKUSER_ROLE_SUPER_ADMIN ||
      role === BACKUSER_ROLE_ROOT ||
      checkRole(memberTypeEditRules, role)
    );
  }

  handleEdit(item) {
    const { dispatch } = this.props;
    dispatch(openEditMemberTypeModal());
    dispatch(editMemberType(item));
  }

  handleToggleCreateModal() {
    const { dispatch } = this.props;
    dispatch(openCreateMemberTypeModal());
  }

  handleRemove(item) {
    this.props.dispatch(confirmMemberTypeDeletionModal(item));
  }

  renderItem(item, id, reactKey) {
    const colProps = { md: 1 };
    const { intl } = this.props;

    return (
      <Row className="advancedList_row" id={id} key={reactKey}>
        <Col md={7}>{item.name}</Col>
        <Col md={3}>
          {item.maximumScheduledBookings} - {item.category}
        </Col>
        <Col {...colProps}>
          {!this.readOnly && (
            <div>
              <a
                onClick={() => {
                  this.handleEdit(item);
                }}
              >
                <Tooltip content={intl.messages['common_edit'] + ' ' + item.name} placement="left">
                  <EditIcon color={'rgba(0, 0, 0, .54)'} hoverColor={'rgba(0, 0, 0, .84)'} style={{ width: '20px' }} />
                </Tooltip>
              </a>

              {item.category === MEMBER_TYPE_CATEGORY_CUSTOM && (
                <a
                  onClick={() => {
                    this.handleRemove(item);
                  }}
                >
                  <Tooltip content={intl.messages['common_delete'] + ' ' + item.name} placement="right">
                    <DeleteIcon color={'rgba(0, 0, 0, .54)'} hoverColor={'rgba(0, 0, 0, .84)'} style={{ width: '20px' }} />
                  </Tooltip>
                </a>
              )}
            </div>
          )}
        </Col>
      </Row>
    );
  }

  render() {
    const { currentCompany, list, intl, loadingList, errorList, isOpen, editMode, memberType, memberTypeDel } = this.props;

    return (
      <div className="mainContainer_content">
        <div className="pageContainer ">
          <div className="memberTypes">
            {_get(currentCompany, 'id', ALL) === ALL && (
              <div className="page-info-block">
                <FormattedMessage id="common_select_super_company" />
              </div>
            )}
            {!this.readOnly && _get(currentCompany, 'id', ALL) !== ALL && (
              <div className="advancedList_actions page no-filters">
                <FlatButton
                  id="member-types-create-button"
                  onClick={this.handleToggleCreateModal}
                  label={intl.messages.member_types_create}
                  style={{ flexDirection: 'flex-end' }}
                  icon={<AddIcon />}
                />
              </div>
            )}
            {_get(currentCompany, 'id', ALL) !== ALL && (
              <AdvancedList
                id="memberTypes"
                data={list}
                loading={loadingList}
                renderItem={this.renderItem}
                error={errorList}
                header={this.headerProps}
                footer={[
                  {
                    labelKey: 'member_types_count',
                    count: list.length,
                    md: 12
                  }
                ]}
              />
            )}
            {editMode && <MemberTypeFormDialog titleKey="member_types_edit" isOpen={isOpen} initialValues={memberType} />}
            {!editMode && !memberTypeDel && <MemberTypeFormDialog titleKey="member_types_create" isOpen={isOpen} editMode={editMode} />}
            {!editMode && memberTypeDel && (
              <MemberTypeFormDialog titleKey="member_types_delete" isOpen={isOpen} memberTypeDel={memberTypeDel} />
            )}
          </div>
        </div>
      </div>
    );
  }
}

MemberTypes.displayName = 'MemberTypes';

MemberTypes.propTypes = {
  list: PropTypes.array,
  memberType: PropTypes.object,
  isOpen: PropTypes.bool,
  editMode: PropTypes.bool
};

export default connect(state => {
  const {
    memberTypes: { loadingList, errorList, list, memberType, isOpen, editMode, memberTypeDel },
    user: { userInfo },
    companies: { currentCompany }
  } = state;

  return {
    list,
    memberType,
    isOpen,
    editMode,
    memberTypeDel,
    userInfo,
    currentCompany,
    loadingList,
    errorList,
    role: userRoleSelector(state)
  };
})(injectIntl(MemberTypes));
