import React, { Component, PropTypes } from 'react';
import { FormattedMessage } from 'react-intl';
import ReactSVG from 'react-svg';

class DashboardItem extends Component {
  render() {
    const { title, label, count, link, icon, id } = this.props;

    return (
      <li className="dashboardItem" id={id}>
        <article className="dashboardItem_container">
          <header className="dashboardItem_head">
            <FormattedMessage id={title} />
          </header>
          <a href={link} className="dashboardItem_link">
            <div className="dashboardItem_content">
              <div className="dashboardItem_statusWrapper">
                <figure className="dashboardItem_status">
                  <div className="dashboardItem_statusIcon_container">
                    <ReactSVG src={icon} svgClassName="dashboardItem_statusIcon" />
                  </div>
                  <figcaption className="dashboardItem_statusValue">{count === -1 ? '-' : count}</figcaption>
                </figure>
                <span className="dashboardItem_label">
                  <FormattedMessage id={label} />
                </span>
              </div>
            </div>
          </a>
        </article>
      </li>
    );
  }
}

DashboardItem.displayName = 'DashboardItem';

DashboardItem.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  label: PropTypes.string,
  count: PropTypes.number,
  link: PropTypes.string,
  icon: PropTypes.string
};

export default DashboardItem;
