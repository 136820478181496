/* eslint-disable strict */
'use strict';

var path = require('path');

var basePaths = {
  root: path.resolve(process.cwd())
};

basePaths.src = path.join(basePaths.root, '/src');
basePaths.projectRoot = path.join(basePaths.src, '/project-root');
basePaths.resources = path.join(basePaths.src, '/resources');
basePaths.mocks = path.join(basePaths.src, '/mocks');
basePaths.mockups = path.join(basePaths.src, '/mockups');
basePaths.dest = path.join(basePaths.root, '/dist');
basePaths.reports = path.join(basePaths.root, '/reports');
basePaths.iconfont = path.join(basePaths.resources, '/iconfont');

module.exports = basePaths;
