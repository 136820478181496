import React, { Component, PropTypes } from 'react';
import cs from 'classnames';
import Modal from 'react-modal';
import ReactSVG from 'react-svg';
import None from '../../None';

const modalStyles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.55)',
    zIndex: 900
  },
  content: {
    background: '#f4f4f4',
    margin: '8vh 8vw',
    padding: 0,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0
  }
};

export class ListModal extends Component {
  render() {
    if (!this.props.isOpen) return <None />;
    return (
      <Modal isOpen className={cs('list-modal', this.props.className)} style={modalStyles} onRequestClose={this.props.onClose}>
        <div className="sc-title-wrap">
          <div className="sc-title">{this.props.title}</div>
          <button className="sc-edit" onClick={this.props.onClose}>
            <ReactSVG src="/img/close.svg" className="align-svg" />
          </button>
        </div>
        <div className="sc-child-wrap">{this.props.children}</div>
      </Modal>
    );
  }
}

export default ListModal;

ListModal.defaultProps = {
  title: 'Title'
};

ListModal.propTypes = {
  title: PropTypes.any,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  className: PropTypes.string
};
