import React, { PropTypes } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { getAppFormattedDateTime } from '../../../../utils/utils';
import { BOOKING_TYPE_CAR_SHARING } from '../../../../constants/backend-constants';

const BookingDetail = props => {
  const { bookingDetail, intl } = props;

  const bookingType =
    bookingDetail.type === BOOKING_TYPE_CAR_SHARING
      ? intl.messages[`booking_detail_type_${bookingDetail.type}_${bookingDetail.carSharingUsageType}`]
      : intl.messages[`booking_detail_type_${bookingDetail.type}`];

  return (
    <section className="bookingDetail">
      <div className="grid-container">
        <div className="item r1c1">
          <FormattedMessage id="booking_detail_member" />
        </div>
        <div className="item r1c2" children={bookingDetail.memberFirstName + ' ' + bookingDetail.memberLastName} />
        <div className="item r2c1">
          <FormattedMessage id="booking_departure_arrival" />
        </div>
        <div
          className="item r2c2"
          children={getAppFormattedDateTime(bookingDetail.startDate) + ' / ' + getAppFormattedDateTime(bookingDetail.endDate)}
        />
        <div className="item r3c1" children={bookingType} />
        <div
          className="item r3c2"
          children={bookingDetail.vehicleBrandName + ' ' + bookingDetail.vehicleModelName + ' ' + bookingDetail.vehicleRegistrationNumber}
        />
      </div>
    </section>
  );
};

BookingDetail.displayName = 'BookingDetail';

BookingDetail.propTypes = {
  bookingDetail: PropTypes.object.isRequired
};

export default injectIntl(BookingDetail);
