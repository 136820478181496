import React, { Component } from 'react';
import AddCompanyModal from '../../../../AddCompany/Modal';
import { showParentView } from '../../../../../components/_v2/ColumnList/helpers';
import { safe, isValidId } from '../../../../../utils/utils';

export class OrganizationAddSuperCompany extends Component {
  componentWillMount() {
    this.showParentView = showParentView.bind(this);
  }

  render() {
    const brandId = safe(() => this.props.params.brandId);
    return (
      <AddCompanyModal isOpen onClose={this.showParentView} initialValues={{ appBrandId: brandId }} readOnlyBrands={isValidId(brandId)} />
    );
  }
}

export default OrganizationAddSuperCompany;
