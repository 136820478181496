import React, { Component, PropTypes } from 'react';
import { connect } from 'react-redux';
import { routeActions } from 'react-router-redux';
import Modal from 'react-modal';
import _get from 'lodash/get';
import autoBind from 'react-autobind';

import routes from '../../constants/routes-constants';
import { FLASH_MESSAGE_TYPE_SUCCESS } from '../../constants/generic-constants';
import {
  closeConfirmationModal,
  setBookingConfirmationDetails,
  confirmBookingCreation,
  confirmBookingEdition,
  bookingCreationErrored,
  clearBookingCreationSession,
  addFlashMessage
} from '../../actions/all-actions';

import BookingFindVehicle from '../../components/BookingFindVehicle/BookingFindVehicle';
import ConfirmBookingCreation from '../../components/ConfirmBookingCreation/ConfirmBookingCreation';
import BackLink from '../../components/BackLink/BackLink';
import { apiParams } from '../../constants/api-params-constants';
import { BOOKING_COMMENT_INIT_VALUE, PAID_BOOKING_INIT_VALUE } from '../../constants/form-constants';
import { addErrorMessage } from '../../utils/utils';
import { bundleSelector } from '../../selectors/all-selectors';

const modalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '380px'
  }
};

export const creationSteps = {
  findMember: 'find-member',
  findVehicle: 'find-vehicle',
  confirmBooking: 'confirm-booking'
};

class AddBooking extends Component {
  constructor() {
    super();
    autoBind(this);
  }

  componentWillUnmount() {
    this.props.dispatch(clearBookingCreationSession());
  }

  handleClickBack() {
    const { currentEditedBooking, editionMode, currentStep, bookingsParams, findMemberParams, dispatch } = this.props;
    const bookingId = _get(currentEditedBooking, 'id');

    if (editionMode) {
      dispatch(routeActions.push(routes.bookingDetail.path.replace(':bookingId', bookingId)));
    } else if (currentStep === creationSteps.findVehicle) {
      dispatch(clearBookingCreationSession());
      dispatch(
        routeActions.push(
          `${routes.addBooking.path}/${routes.bookingFindMember.path.replace(
            ':search',
            encodeURIComponent(JSON.stringify(findMemberParams))
          )}`
        )
      );
    } else
      dispatch(
        routeActions.push(routes.bookings.path.replace(':search', encodeURIComponent(JSON.stringify(bookingsParams || apiParams.default))))
      );
  }

  handleLabelBack() {
    const { editionMode, currentStep } = this.props;

    if (editionMode) {
      return 'back_link_booking_details';
    } else if (currentStep === creationSteps.findVehicle) {
      return 'back_link_member_search';
    } else {
      return 'back_link_bookings_list';
    }
  }

  handleCreateBookingConfirmation(confirmationDetails) {
    const { dispatch, editionMode, selectedMember, selectedVehicle, currentEditedBooking, bundle } = this.props;

    dispatch(setBookingConfirmationDetails(confirmationDetails));
    dispatch(closeConfirmationModal());

    dispatch(this.finalAction(selectedMember, selectedVehicle, confirmationDetails, editionMode ? currentEditedBooking.id : null))
      .then(() => {
        dispatch(routeActions.push(routes.bookings.path.replace(':search', encodeURIComponent(JSON.stringify(apiParams.default)))));

        dispatch(
          addFlashMessage({
            contentKey: editionMode ? 'booking_edit_success' : 'booking_create_success',
            type: FLASH_MESSAGE_TYPE_SUCCESS
          })
        );
      })
      .catch(error => {
        dispatch(bookingCreationErrored());
        dispatch(addErrorMessage({ error, bundle, errorCodePrefixes: ['bookingResult_'] }));
      });
  }

  handleCloseModalConfirmation() {
    this.props.dispatch(closeConfirmationModal());
  }

  get finalAction() {
    const { editionMode } = this.props;

    if (editionMode) {
      return confirmBookingEdition;
    } else {
      return confirmBookingCreation;
    }
  }

  render() {
    const { page: stepPage, currentStep, creationFinalized, selectedVehicle, detailMember } = this.props;
    const { chorusData } = detailMember.enterprise || {};
    return (
      <div className="addBookingPage mainContainer_content">
        <div className="pageContainer">
          <div className="addBookingPage_content">
            <BackLink onClickBack={this.handleClickBack} labelKey={this.handleLabelBack()} />
            <section className="addBookingPage_management">{stepPage || <BookingFindVehicle />}</section>
          </div>

          {!creationFinalized && currentStep === creationSteps.confirmBooking && (
            <Modal isOpen style={modalStyles}>
              <ConfirmBookingCreation
                initialValues={{
                  paidBooking: this.props.paidBooking,
                  comment: this.props.comment,
                  ...chorusData
                }}
                onCallback={this.handleCreateBookingConfirmation}
                onAbort={this.handleCloseModalConfirmation}
                selectedVehicle={selectedVehicle}
              />
            </Modal>
          )}
        </div>
      </div>
    );
  }
}

AddBooking.displayName = 'AddBooking';
AddBooking.propTypes = {
  titleID: PropTypes.string,
  stepTitleID: PropTypes.string,
  currentStep: PropTypes.string
};

export default connect(state => {
  const {
    bookingFindMember: { urlParams: findMemberParams },
    booking: { editionMode, titleID, stepTitleID, currentStep, selectedMember, selectedVehicle, creationFinalized, confirmationDetails },
    bookings: { bookingDetail: currentEditedBooking, urlParams: bookingsParams },
    members: { detailMember }
  } = state;

  const paidBooking = editionMode
    ? !_get(currentEditedBooking, 'carSharingInfo.freeOfCharges', !PAID_BOOKING_INIT_VALUE)
    : PAID_BOOKING_INIT_VALUE;
  const comment = editionMode ? _get(currentEditedBooking, 'comment', BOOKING_COMMENT_INIT_VALUE) : BOOKING_COMMENT_INIT_VALUE;

  return {
    paidBooking,
    comment,
    editionMode,
    currentEditedBooking,
    titleID,
    stepTitleID,
    currentStep,
    selectedMember,
    selectedVehicle,
    creationFinalized,
    confirmationDetails,
    findMemberParams,
    bookingsParams,
    detailMember,
    bundle: bundleSelector(state)
  };
})(AddBooking);
