import React, { PropTypes } from 'react';
import { FormattedMessage } from 'react-intl';

import DomainIcon from 'material-ui/svg-icons/social/domain';
import EmailIcon from 'material-ui/svg-icons/communication/email';
import PlaceIcon from 'material-ui/svg-icons/maps/place';
import { iconStyles } from '../../constants/style-constants';
import Tooltip from '../Tooltip/Tooltip';
import { safe } from '../../utils/utils';
import _isEmpty from 'lodash/isEmpty';
import { cleanDeep } from '../../utils/cleanDeep';
import memoizeOne from 'memoize-one';

function renderAddress(enterprise) {
  const { address, zipCode, city, country } = enterprise;
  const zipCodeAndCity = [zipCode, city].filter(Boolean).join(' ');
  return [address, zipCodeAndCity, country].filter(Boolean).join(', ');
}

const isChorusPresent = memoizeOne(chorusData => {
  const { administration: omit, ...data } = chorusData || {};
  return safe(() => !_isEmpty(cleanDeep(data)));
});

const MemberCompanyInfo = props => {
  const { enterprise } = props;
  const chorusPresent = isChorusPresent(enterprise.chorusData);

  return (
    <div className="memberView_companyInfo">
      <h4>
        <FormattedMessage id="members_detail_enterprise_subtitle" />
      </h4>
      <div className="memberView_companyInfo_body">
        <div className="detailView_table">
          {safe(() => enterprise.name) && (
            <div className="detailView_row">
              <div className="detailView_cell_type_1">
                <Tooltip content={<FormattedMessage id="members_detail_enterprise_name" />} placement="bottom">
                  <DomainIcon style={iconStyles.all} />
                </Tooltip>
              </div>
              <div className="detailView_cell_type_1">{enterprise.name}</div>
            </div>
          )}
          {safe(() => enterprise.email) && (
            <div className="detailView_row">
              <div className="detailView_cell_type_1">
                <Tooltip content={<FormattedMessage id="members_detail_enterprise_email" />} placement="bottom">
                  <EmailIcon style={iconStyles.all} />
                </Tooltip>
              </div>
              <div className="detailView_cell_type_1">{enterprise.email}</div>
            </div>
          )}
          {safe(() => enterprise) && (
            <div className="detailView_row">
              <div className="detailView_cell_type_1">
                <Tooltip content={<FormattedMessage id="members_detail_enterprise_address" />} placement="bottom">
                  <PlaceIcon style={iconStyles.all} />
                </Tooltip>
              </div>
              <div className="detailView_cell_type_1">{renderAddress(enterprise)}</div>
            </div>
          )}
        </div>
        <table>
          <tbody>
            {safe(() => enterprise.accountType) && (
              <tr className="detailView_tr">
                <td className="detailView_td_type_1">
                  <span className="detailView_cell_type_1">
                    <FormattedMessage id="members_detail_enterprise_account_type" />
                  </span>
                </td>
                <td className="detailView_td_type_1">
                  <span className="detailView_cell_type_1">{enterprise.accountType}</span>
                </td>
              </tr>
            )}
            {safe(() => enterprise.discountPercentage) && (
              <tr className="detailView_tr">
                <td className="detailView_td_type_1">
                  <span className="detailView_cell_type_1">
                    <FormattedMessage id="members_detail_enterprise_discount" />
                  </span>
                </td>
                <td className="detailView_td_type_1">
                  <span className="detailView_cell_type_1">{enterprise.discountPercentage}</span>
                </td>
              </tr>
            )}
            {safe(() => enterprise.agencyCode) && (
              <tr className="detailView_tr">
                <td className="detailView_td_type_1">
                  <span className="detailView_cell_type_1">
                    <FormattedMessage id="members_detail_enterprise_agency_code" />
                  </span>
                </td>
                <td className="detailView_td_type_1">
                  <span className="detailView_cell_type_1">{enterprise.agencyCode}</span>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {chorusPresent && (
        <h4 className="sub-title">
          <FormattedMessage id="members_detail_enterprise_chorus_detail" />
        </h4>
      )}
      {chorusPresent && (
        <div className="memberView_companyInfo_body">
          <div className="detailView_table">
            {safe(() => enterprise.chorusData.chorusAlias) && (
              <div className="detailView_row">
                <div className="detailView_cell_type_3">
                  <FormattedMessage id="members_detail_enterprise_chorus_chorusAlias" />
                </div>
                <div className="detailView_cell_type_3">{enterprise.chorusData.chorusAlias}</div>
              </div>
            )}
            {safe(() => enterprise.chorusData.chorusEngagement) && (
              <div className="detailView_row">
                <div className="detailView_cell_type_3">
                  <FormattedMessage id="members_detail_enterprise_chorus_chorusEngagement" />
                </div>
                <div className="detailView_cell_type_3">{enterprise.chorusData.chorusEngagement}</div>
              </div>
            )}
          </div>
          <table>
            <tbody>
              {safe(() => enterprise.chorusData.chorusMarche) && (
                <tr className="detailView_tr">
                  <td className="detailView_td_type_1">
                    <span className="detailView_cell_type_1">
                      <FormattedMessage id="members_detail_enterprise_chorus_chorusMarche" />
                    </span>
                  </td>
                  <td className="detailView_td_type_1">{enterprise.chorusData.chorusMarche}</td>
                </tr>
              )}
              {safe(() => enterprise.chorusData.chorusService) && (
                <tr className="detailView_tr">
                  <td className="detailView_td_type_1">
                    <span className="detailView_cell_type_1">
                      <FormattedMessage id="members_detail_enterprise_chorus_chorusService" />
                    </span>
                  </td>
                  <td className="detailView_td_type_1">{enterprise.chorusData.chorusService}</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

MemberCompanyInfo.propTypes = {
  enterprise: PropTypes.object.isRequired
};

export default MemberCompanyInfo;
