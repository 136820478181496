/* eslint-disable react/no-set-state */

import React, { Component, PropTypes as T } from 'react';
import { FormattedMessage } from 'react-intl';
import cs from 'classnames';

import Person from 'material-ui/svg-icons/social/person';
import Group from 'material-ui/svg-icons/social/group';
import Car from 'material-ui/svg-icons/maps/directions-car';
import Business from 'material-ui/svg-icons/communication/business';
import Completed from 'material-ui/svg-icons/action/done';
import Canceled from 'material-ui/svg-icons/navigation/close';
import Scheduled from 'material-ui/svg-icons/device/access-time';
import Progress from 'material-ui/svg-icons/action/cached';

import {
  BOOKING_STATUS_SCHEDULED,
  BOOKING_STATUS_IN_PROGRESS,
  BOOKING_STATUS_COMPLETED,
  STATUS_CANCELED,
  BOOKING_TYPE_RIDE_SHARING,
  BOOKING_USAGE_TYPE_BUSINESS,
  BOOKING_USAGE_TYPE_PRIVATE,
  BOOKING_TYPE_GROUP_SHARING,
  BOOKING_STATUS_PRE_BOOKED
} from '../../constants/backend-constants';
import ReactSVG from 'react-svg';

class BookingListIcon extends Component {
  constructor() {
    super();
    this.state = { hovered: false };
    this.handleHover = this.handleHover.bind(this);
  }

  handleHover() {
    this.setState((prevState, props) => ({
      hovered: !prevState.hovered
    }));
  }

  render() {
    const { type, status, statusIcon } = this.props;

    let classNameType = '';

    if (type === BOOKING_USAGE_TYPE_BUSINESS) {
      classNameType = 'bgBusiness';
    } else if (type === BOOKING_USAGE_TYPE_PRIVATE) {
      classNameType = 'bgPerson';
    } else if (type === BOOKING_TYPE_GROUP_SHARING) {
      classNameType = 'bgGroup';
    } else {
      classNameType = 'bgCar';
    }

    let classNameStatus = '';

    if (status === BOOKING_STATUS_SCHEDULED) {
      classNameStatus = 'bgScheduled';
    } else if (status === BOOKING_STATUS_IN_PROGRESS) {
      classNameStatus = 'bgProgress';
    } else if (status === BOOKING_STATUS_COMPLETED) {
      classNameStatus = 'bgCompleted';
    } else if (status === BOOKING_STATUS_PRE_BOOKED) {
      classNameStatus = 'bgPrebooked';
    } else {
      classNameStatus = 'bgCanceled';
    }

    return (
      <div className={cs(classNameType, 'column-list-icon', 'merged-cell')} onMouseEnter={this.handleHover} onMouseLeave={this.handleHover}>
        {type === BOOKING_USAGE_TYPE_BUSINESS && <Business />}
        {type === BOOKING_USAGE_TYPE_PRIVATE && <Person />}
        {type === BOOKING_TYPE_GROUP_SHARING && <Group />}
        {type === BOOKING_TYPE_RIDE_SHARING && <Car />}
        {statusIcon && (
          <div className={cs(classNameStatus, 'sc-status-icon')}>
            {status === BOOKING_STATUS_SCHEDULED && <Scheduled />}
            {status === BOOKING_STATUS_IN_PROGRESS && <Progress />}
            {status === BOOKING_STATUS_COMPLETED && <Completed />}
            {status === BOOKING_STATUS_PRE_BOOKED && (
              <ReactSVG src="/img/pre-booked.svg" className="prebooked-icon" svgClassName="svg-icon" />
            )}
            {status === STATUS_CANCELED && <Canceled />}
          </div>
        )}
        {this.state.hovered && (
          <div className="bookingsPage_bookingListIcon_tootlip">
            <span className="inline">
              <FormattedMessage id="members_detail_professional" />
              {': '}
              <FormattedMessage id={'bookings_tableView_serviceType_' + type} />
            </span>
            <span className="inline">
              <FormattedMessage id="backUsers_tableView_label_status" />
              {': '}
              <FormattedMessage id={'common_booking_status_' + status} />
            </span>
          </div>
        )}
      </div>
    );
  }
}

BookingListIcon.displayName = 'BookingListIcon';

BookingListIcon.propTypes = {
  type: T.string,
  status: T.string,
  statusIcon: T.bool
};

BookingListIcon.defaultProps = {
  statusIcon: true
};

export default BookingListIcon;
