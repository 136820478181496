// noinspection ES6CheckImport
import React, { Component, PropTypes } from 'react';

import { FormattedMessage } from 'react-intl';
import { reduxForm } from 'redux-form';
import classNames from 'classnames';

import config from '../../constants/config-constants';
import DropDownMenu from 'material-ui/DropDownMenu';
import MenuItem from 'material-ui/MenuItem';
import { scrollToFirstError } from '../../utils/utils';

class NumberResultsSelectForm extends Component {
  constructor(props) {
    super(props);

    this.handleNumberResultsSelect = this.handleNumberResultsSelect.bind(this);
  }

  handleNumberResultsSelect(event, index, value) {
    if (this.props.onNumberResultsSelect) {
      this.props.onNumberResultsSelect(value);
    }
  }

  render() {
    const {
      fields: { numberResults },
      handleSubmit
    } = this.props;

    return (
      <form
        action="#"
        className={classNames('numberResultsSelectForm', this.props.customClass)}
        onSubmit={handleSubmit(this.props.onCallback)}
      >
        <div className="numberResultsSelectForm_rowslabelText">
          <FormattedMessage id="numberResultsSelectForm_labelText" />
        </div>

        <DropDownMenu
          value={numberResults.value}
          onChange={this.handleNumberResultsSelect}
          underlineStyle={{ border: 0 }}
          style={{ marginBottom: '8px' }}
          labelStyle={{ color: 'rgba(0,0,0,.6)', fontSize: '12px' }}
        >
          {config.numberResults.map(item => (
            <MenuItem value={item} primaryText={item} key={item} />
          ))}
        </DropDownMenu>
      </form>
    );
  }
}

NumberResultsSelectForm.displayName = 'NumberResultsSelectForm';

NumberResultsSelectForm.propTypes = {
  fields: PropTypes.object,
  onCallback: PropTypes.func,
  handleSubmit: PropTypes.func,
  customClass: PropTypes.string,
  onNumberResultsSelect: PropTypes.func
};

export default NumberResultsSelectForm = reduxForm({
  onSubmitFail: scrollToFirstError,
  form: 'numberResultsSelectForm',
  fields: ['numberResults']
})(NumberResultsSelectForm);
