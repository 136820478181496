import React, { Component, PropTypes } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { reduxForm, addArrayValue } from 'redux-form';
import { connect } from 'react-redux';
import shortid from 'shortid';
import classNames from 'classnames';
import _map from 'lodash/map';
import _get from 'lodash/get';
import EkButton from '../EkButton/EkButton';
import DeleteIcon from 'material-ui/svg-icons/action/delete';
import BoxedInput from '../BoxedInput/BoxedInput';
import Tooltip from '../../components/Tooltip/Tooltip';
import FieldErrorMsg from '../FieldErrorMsg/FieldErrorMsg';
import autoBind from 'react-autobind';
import { validateCancellationFees } from '../../validation/all-validation';
import { scrollToFirstError } from '../../utils/utils';

class CancellationFeesForm extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
  }

  nextValue(obj, key, increment) {
    return Number(obj[key].value) + increment;
  }

  handleAddRule() {
    const _field = this.props.fields.rates;
    if (_field.length) {
      _field.addField({
        id: shortid(),
        offsetMin: this.nextValue(_field[_field.length - 1], 'offsetMax', 0),
        offsetMax: this.nextValue(_field[_field.length - 1], 'offsetMax', 15),
        rate: 50
        // rate: this.nextValue(_field[_field.length - 1], 'rate', -10)
      });
    } else {
      _field.addField({
        id: shortid(),
        offsetMin: 0,
        offsetMax: 15,
        rate: 50
      });
    }
  }

  handleRemoveRule(index) {
    this.props.fields.rates.removeField(index);
  }

  render() {
    const {
      fields: { rates },
      errors,
      limitedAccess,
      handleSubmit
    } = this.props;

    const submitAction = limitedAccess ? undefined : handleSubmit(this.props.onCallback);

    return (
      <form
        action="#"
        className={classNames('cancellation-fees-form', this.props.customClass)}
        onSubmit={!limitedAccess ? submitAction : null}
      >
        {!limitedAccess ? (
          <EkButton
            onAction={this.handleAddRule}
            customClass="ekButton--add cancellation-fees-form_addRule_button"
            skinType="reverse"
            id={`add-cancellationfee-${this.props.formKey}`}
          >
            <FormattedMessage id="pricing_cancellation_fees_add_rule" />
          </EkButton>
        ) : null}
        {this.props.label && (
          <h4 className={this.props.label.class}>
            <FormattedMessage id={this.props.label.key} />
          </h4>
        )}

        <ul className="cancellation-rules">
          {!rates.length && (
            <li className="no-rule">
              <FormattedMessage id="pricing_no_cancellation_fees_defined" />
            </li>
          )}
          {_map(rates, (item, index) => {
            let boundRuleRemove = this.handleRemoveRule.bind(this, index);

            return (
              <li key={`Rule-${item.id.value}`}>
                <div
                  className={classNames({
                    rules: true,
                    _has_error: errors.rates && _get(errors.rates[index], 'id', false) && item.error === item.value
                  })}
                  id={`Rule-${item.id.value}`}
                >
                  <div className="rule-content">
                    {errors.rates && _get(errors.rates[index], 'id', false) && item.error === item.value && (
                      <span className="overlap-errors fieldErrorMsg">
                        <FormattedMessage id="validation_fees_overlap" />
                      </span>
                    )}
                    <div className="item pipe">
                      <label className="cancellation-rule-label" htmlFor={`cancellation-rates-${index}`}>
                        <FormattedMessage id="pricing_cancellation_fees_rate_label" />
                      </label>
                      <BoxedInput
                        type="text"
                        id={`cancellation-rates-${index}`}
                        customClass="rule_boxedInputWrapper"
                        customLabelClass="rule_boxedInputLabel"
                        customLabelTextClass="rule_boxedInputLabelText"
                        customInputClass={classNames('rate-rule', 'rule_boxedInput', {
                          _has_error: item.rate.error && item.rate.error.hasOwnProperty('type')
                        })}
                        field={item.rate}
                        disabled={limitedAccess}
                      />
                      <span className="unit">%</span>
                      <FieldErrorMsg customClass="fieldErrorMsg--cancellationFees" field={item.rate} />
                    </div>
                    <div className="item">
                      <label className="cancellation-rule-label" htmlFor={`offsetMin-${index}`}>
                        <FormattedMessage id="pricing_cancellation_fees_offsetMin_label" />
                      </label>
                      <BoxedInput
                        type="text"
                        id={`offsetMin-${index}`}
                        customClass="rule_boxedInputWrapper"
                        customLabelClass="rule_boxedInputLabel"
                        customLabelTextClass="rule_boxedInputLabelText"
                        customInputClass={classNames('rule_boxedInput', {
                          _has_error: item.offsetMin.error && item.offsetMin.error.hasOwnProperty('type')
                        })}
                        field={item.offsetMin}
                        placeholder="0"
                        disabled={limitedAccess}
                      />
                      <span className="unit">
                        <FormattedMessage id="common_minutes" />
                      </span>
                      <FieldErrorMsg customClass="fieldErrorMsg--cancellationFees" field={item.offsetMin} />
                    </div>
                    <div className="item">
                      <label className="cancellation-rule-label" htmlFor={`offsetMax-${index}`}>
                        <FormattedMessage id="pricing_cancellation_fees_offsetMax_label" />
                      </label>
                      <BoxedInput
                        type="text"
                        id={`offsetMax-${index}`}
                        customClass="rule_boxedInputWrapper"
                        customLabelClass="rule_boxedInputLabel"
                        customLabelTextClass="rule_boxedInputLabelText"
                        customInputClass={classNames('rule_boxedInput', {
                          _has_error: item.offsetMax.error && item.offsetMax.error.hasOwnProperty('type')
                        })}
                        field={item.offsetMax}
                        placeholder="0"
                        disabled={limitedAccess}
                      />
                      <span className="unit">
                        <FormattedMessage id="common_minutes" />
                      </span>
                      <FieldErrorMsg customClass="fieldErrorMsg--cancellationFees" field={item.offsetMax} />
                    </div>
                  </div>
                  {!limitedAccess && (
                    <div className="delete">
                      <div className="cancellation-rules_item_actionButton">
                        <Tooltip placement="top" content={<FormattedMessage id="pricing_delete_fee" />}>
                          <DeleteIcon onClick={boundRuleRemove} className="delete" />
                        </Tooltip>
                      </div>
                    </div>
                  )}
                </div>
              </li>
            );
          })}
        </ul>

        {!limitedAccess && (
          <EkButton
            customClass={classNames('cancellation-fees-form-submit_button', this.props.customClass)}
            onAction={submitAction}
            id={`submit-cancellationfee-${this.props.formKey}`}
          >
            <FormattedMessage id="boxedInput_defaultActionMsg" />
          </EkButton>
        )}
      </form>
    );
  }
}

CancellationFeesForm.displayName = 'CancellationFeesForm';

CancellationFeesForm.propTypes = {
  fields: PropTypes.object,
  limitedAccess: PropTypes.bool,
  onCallback: PropTypes.func,
  handleSubmit: PropTypes.func
};

CancellationFeesForm = reduxForm(
  {
    onSubmitFail: scrollToFirstError,
    touchOnBlur: false,
    form: 'cancellationFees',
    fields: ['rates[].id', 'rates[].offsetMin', 'rates[].offsetMax', 'rates[].rate'],
    validate: validateCancellationFees()
  },
  undefined,
  {
    addValue: addArrayValue
  }
)(CancellationFeesForm);

export default connect(state => {
  const {
    pricing: { cancellationFees }
  } = state;

  return {
    cancellationFees
  };
})(injectIntl(CancellationFeesForm));
