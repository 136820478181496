import React, { Component, PropTypes } from 'react';
import { FormattedMessage } from 'react-intl';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';

import FormRow from '../FormRow/FormRow';
import BoxedInput from '../BoxedInput/BoxedInput';
import EkButton from '../EkButton/EkButton';
import { scrollToFirstError } from '../../utils/utils';

class FeedbackAddCommentForm extends Component {
  constructor() {
    super();
  }

  render() {
    const {
      fields: { comment },
      handleSubmit
    } = this.props;

    return (
      <section className="feedbackAddCommentFormWrapper">
        <form action="#" className="feedbackAddCommentForm" onSubmit={handleSubmit(this.props.onCallback)}>
          <div className="feedbackAddCommentForm_container">
            <h4 className="feedbackAddCommentForm_title">
              <FormattedMessage id="feedbackAddCommentForm_title" />
            </h4>

            <FormRow customClass="feedbackAddCommentForm_row">
              <BoxedInput
                formRowItemKey="feedbackAddCommentForm_comment"
                id="comment"
                customClass="boxedInput_inputText"
                customInputClass="boxedInput_inputTextArea"
                labelKey="feedbackAddCommentForm_comment"
                type="textarea"
                field={comment}
              />
            </FormRow>

            <FormRow customClass="feedbackAddCommentForm_row">
              <EkButton
                type="button"
                skinType="reverse"
                formRowItemKey="feedbackAddCommentForm_abortBtn"
                customClass="feedbackAddComment_button"
                onAction={this.props.onAbort}
              >
                <FormattedMessage id="feedbackAddCommentForm_abort_button" />
              </EkButton>

              <EkButton
                type="button"
                formRowItemKey="feedbackAddCommentForm_submitBtn"
                customClass="feedbackAddComment_button"
                onAction={handleSubmit(this.props.onCallback)}
              >
                <FormattedMessage id="feedbackAddCommentForm_save_button" />
              </EkButton>
            </FormRow>
          </div>
        </form>
      </section>
    );
  }
}

FeedbackAddCommentForm.displayName = 'FeedbackAddCommentForm';

FeedbackAddCommentForm.propTypes = {
  fields: PropTypes.object,
  handleSubmit: PropTypes.func,
  onCallback: PropTypes.func,
  onAbort: PropTypes.func
};

FeedbackAddCommentForm = reduxForm({
  onSubmitFail: scrollToFirstError,
  form: 'feedbackAddCommentForm',
  fields: ['comment']
})(FeedbackAddCommentForm);

export default connect(state => {
  const {} = state;
  return {};
})(FeedbackAddCommentForm);
