import React, { Component, PropTypes } from 'react';
import { routeActions } from 'react-router-redux';
import { connect } from 'react-redux';
import _get from 'lodash/get';
import autoBind from 'react-autobind';
import pure from 'recompose/pure';
import routes from '../../../constants/routes-constants';
import AddVoucherGroupForm from '../AddVoucherGroupForm/AddVoucherGroupForm';
import { apiParams } from '../../../constants/api-params-constants';
import { namedCompose } from '../../../utils/utils';
import { FormattedMessage } from 'react-intl';
import { currentCompanyIdSelector } from '../../../selectors/all-selectors';
import { ALL } from '../../../constants/generic-constants';

import {
  createVoucherGroup,
  vouchersAddToAllowedParkings,
  vouchersAddToAvailableParkings,
  vouchersAddToAllowedCompanies,
  vouchersAddToAvailableCompanies
} from '../Voucher.actions';

class AddVoucherGroup extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.derivedStateFromProps(props);
  }

  componentWillReceiveProps(props) {
    this.derivedStateFromProps(props);
  }

  derivedStateFromProps(props) {
    this.renderInfo = props.currentCompanyId === ALL;
  }

  handleCancelCreateGroup() {
    if (!this.props.isExternal) {
      const params = this.props.urlParams || apiParams.default;
      const backToList = routes.vouchers.path.replace(':search', encodeURIComponent(JSON.stringify(params)));
      this.props.dispatch(routeActions.push(backToList));
    }

    if (this.props.onCancel) {
      this.props.onCancel();
    }
  }

  handleCreateGroup() {
    this.props.dispatch(createVoucherGroup()).then(data => {
      if (!this.props.isExternal) {
        let route = routes.voucherGroupDetail.path.replace(':voucherGroupId', data.id);
        route = route.replace(':search', encodeURIComponent(JSON.stringify(apiParams.default)));
        if (data) this.props.dispatch(routeActions.push(route));
      }

      // should be called last
      if (this.props.onSubmit) {
        this.props.onSubmit();
      }
    });
  }

  addToAllowedParkings(ids) {
    this.props.dispatch(vouchersAddToAllowedParkings(ids));
  }

  addToAvailableParkings(ids) {
    this.props.dispatch(vouchersAddToAvailableParkings(ids));
  }

  addToAllowedCompanies(ids) {
    this.props.dispatch(vouchersAddToAllowedCompanies(ids));
  }

  addToAvailableCompanies(ids) {
    this.props.dispatch(vouchersAddToAvailableCompanies(ids));
  }

  renderPage() {
    const { currency, availableParkings, allowedParkings, availableCompanies, allowedCompanies } = this.props;

    if (this.renderInfo)
      return (
        <div className="pageContainer">
          <div className="page-info-block">
            <FormattedMessage id="common_select_super_company" />
          </div>
        </div>
      );
    else
      return (
        <div className="pageContainerCentered">
          <div className="mainContainer_content">
            <div className="pageContainerCentered">
              <AddVoucherGroupForm
                inputRef={this.props.inputRef}
                onCallback={this.handleCreateGroup}
                onCancel={this.handleCancelCreateGroup}
                currency={currency}
                availableParkings={availableParkings}
                allowedParkings={allowedParkings}
                addToAllowedParkings={this.addToAllowedParkings}
                addToAvailableParkings={this.addToAvailableParkings}
                availableCompanies={availableCompanies}
                allowedCompanies={allowedCompanies}
                addToAllowedCompanies={this.addToAllowedCompanies}
                addToAvailableCompanies={this.addToAvailableCompanies}
              />
            </div>
          </div>
        </div>
      );
  }

  render() {
    return <div className="mainContainer_content">{this.renderPage()}</div>;
  }
}

AddVoucherGroup.propTypes = {
  isExternal: PropTypes.bool,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  inputRef: PropTypes.func
};

export default namedCompose(
  connect(state => {
    const vouchers = _get(state, 'vouchers');
    const currency = _get(state, 'companies.currentCompanyDetails.currency');

    return {
      availableParkings: _get(vouchers, 'availableParkings'),
      allowedParkings: _get(vouchers, 'allowedParkings'),
      availableCompanies: _get(vouchers, 'availableCompanies'),
      allowedCompanies: _get(vouchers, 'allowedCompanies'),
      currentCompanyId: currentCompanyIdSelector(state),
      createVoucherCodeModal: _get(vouchers, 'createVoucherCodeModal'),
      urlParams: _get(vouchers, 'urlParams'),
      locale: _get(state, 'i18n.locale'), // 'pure' exception
      currency
    };
  }),
  pure
)(AddVoucherGroup);
