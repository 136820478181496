import { FRONT, BACK, SELFIE } from '../../../constants/generic-constants';
import { isValidId } from '../../../utils/utils';
import { cleanDeep } from '../../../utils/cleanDeep';
import _map from 'lodash/map';

export function setFrontFile(newId, fileId) {
  if (isValidId(newId)) fileId = newId;

  if (fileId) {
    return { type: FRONT, fileId };
  }
}

export function setBackFile(newId, fileId) {
  if (isValidId(newId)) fileId = newId;

  if (fileId) {
    return { type: BACK, fileId };
  }
}

export function setSelfieFile(newId, fileId) {
  if (isValidId(newId)) fileId = newId;

  if (fileId) {
    return { type: SELFIE, fileId };
  }
}

export function cleanDocumentFile(fileData) {
  const goodFile = { ...fileData };
  const { files } = goodFile;

  goodFile.files = _map(files, (file = {}) => {
    const { fileId, type } = file;
    return { fileId, type };
  });

  delete goodFile.id;
  delete goodFile.fileId;

  return goodFile;
}

export function cleanFilesForApi(apiData = {}) {
  const newData = { ...apiData };
  const { drivingLicence, identityDocument } = newData;

  if (drivingLicence) newData.drivingLicence = cleanDocumentFile(drivingLicence);
  if (identityDocument) newData.identityDocument = cleanDocumentFile(identityDocument);

  return newData;
}

export function cleanUserDataForApi(apiData) {
  return cleanDeep(cleanFilesForApi(apiData));
}
