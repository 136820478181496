import React, { Component } from 'react';
import { showParentView } from '../../../../../components/_v2/ColumnList/helpers';
import SiteDetailsModal from '../../../../../containers/SiteDetails/Modal';

export class OrganizationEditSite extends Component {
  componentWillMount() {
    this.showParentView = showParentView.bind(this);
  }

  render() {
    return <SiteDetailsModal isOpen onClose={this.showParentView} />;
  }
}

export default OrganizationEditSite;
