import React, { Component } from 'react';
import { reduxForm } from 'redux-form';
import BoxedInput from '../../../../components/BoxedInput/BoxedInput';
import BoxedSelect from '../../../../components/BoxedSelect/BoxedSelect';
import { invoiceStatuses } from '../../../../constants/filters-constants';
import { ISO_DATE_FLAT } from '../../../../constants/generic-constants';

class Filters extends Component {
  render() {
    const { props: p } = this;
    const { fields: f } = p;

    return (
      <form className="invoices-filters">
        <div className="sc-block">
          <div className="sc-items">
            <BoxedInput id="invoice-filters-ref" type="text" labelKey="common_placeHolder_searchForInvoiceNumber" field={f.reference} />
            <BoxedInput id="invoice-filters-booking-id" type="text" labelKey="common_booking_id" field={f.bookingId} />
          </div>
          <div className="sc-items">
            <BoxedInput
              id="invoice-filters-start"
              type="date"
              skinType="date"
              field={f.startDate}
              jsonDateFormat={ISO_DATE_FLAT}
              labelKey="invoices_tableView_label_issueDateFrom"
            />
            <BoxedInput
              id="invoice-filters-end"
              type="date"
              skinType="date"
              jsonDateFormat={ISO_DATE_FLAT}
              field={f.endDate}
              labelKey="invoices_tableView_label_issueDateTo"
            />
          </div>
          <div className="sc-items">
            <BoxedSelect
              id="invoice-filters-status"
              labelKey="invoices_tableView_label_status"
              options={invoiceStatuses}
              field={f.status}
            />
          </div>
        </div>
      </form>
    );
  }
}

Filters = reduxForm({
  form: 'invoicesFilters',
  fields: ['bookingId', 'reference', 'status', 'startDate', 'endDate']
})(Filters);

export default Filters;
