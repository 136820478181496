import React, { Component } from 'react';
import autoBind from 'react-autobind';
import pure from 'recompose/pure';
import { formatCustomDate, isEmpty, namedCompose, safe } from '../../../utils/utils';
import { FormattedMessage, injectIntl } from 'react-intl';
import _forEach from 'lodash/forEach';
import PropTypes from 'prop-types';
import cs from 'classnames';
import {
  BACKUSER_ROLE_ADMIN,
  BACKUSER_ROLE_FLEET_MANAGER,
  BACKUSER_ROLE_ROOT,
  BACKUSER_ROLE_SUPER_ADMIN,
  STATUS_REFUSED
} from '../../../constants/backend-constants';
import { ORANGE, transactionStatus, transactionType } from '../../../constants/options-constants';
import shortid from 'shortid';
import None from '../../None';
import { reduxForm } from 'redux-form';
import GreenSwitch from '../../../components/_v2/GreenSwitch/GreenSwitch';
import { automaticPaymentRetrySekector } from '../../../selectors/all-selectors';
import { RaisedButton } from 'material-ui';
import CopyToClipBoard from '../CopyToClipBoard/CopyToClipBoard';
import IsGranted from '../../IsGranted/IsGranted';

class BookingTransactionInfo extends Component {
  constructor(props) {
    super(props);
    autoBind(this);

    this.displayProps = new Map();
    this.defaultDisplay = { singleRow: true, multiRow: true };

    this.setDisplayProp('date', { key: 'common_date', parse: this.parseDate });
    this.setDisplayProp('amount', { key: 'common_amount', parse: this.parseAmount });
    this.setDisplayProp('type', { key: 'common_type', parse: this.parseType });
    this.setDisplayProp('status', {
      key: 'common_status',
      parse: this.parseStatus,
      custom: this.customStatus,
      display: { multiRow: true },
      classNames: { td: 'status' }
    });
    this.setDisplayProp('orderDescription', { key: 'booking_view_transaction_description', 'extra-cs': 'desc', parse: this.parseDesc });
    this.state = { expand: false };
    // this.setDisplayProp('paymentRefusalReason', { key: 'transaction_refusal_reason', display: { singleRow: true } });

    this.derivedStateFromProps(props);
  }

  componentWillReceiveProps(props) {
    this.derivedStateFromProps(props);
  }

  derivedStateFromProps(props) {
    this.status = null;
    this.dateFormat = 'DD/MM/YYYY, HH[h]mm';
    this.header = this.getHeader(props);
    this.rows = this.getRows(props);

    // should not be used in parsers
    this.displaySingleRow = this.rows.length === 1;
    this.isExpandable = this.rows.length > 2;
    this.componentCanRender = this.checkComponentRender();
  }

  addDefaultOptions(o) {
    if (!o.parse) o.parse = v => v;
    if (!o.custom) o.custom = () => '';
    if (!o.classNames) o.classNames = {};
    if (!o.display) o.display = this.defaultDisplay;
  }

  setDisplayProp(key, options) {
    this.addDefaultOptions(options);
    this.displayProps.set(key, options);
  }

  getStatus(status) {
    const statusObject = transactionStatus[status];
    const statusKey = safe(() => statusObject.key) || status;
    const colorClass = safe(() => statusObject.class) || ORANGE;

    return (
      <span className={colorClass}>
        <FormattedMessage id={statusKey} />
      </span>
    );
  }

  showMoreBtn() {
    if (this.isExpandable) {
      const label = (
        <FormattedMessage id={this.state.expand ? 'booking_view_see_less_transactions' : 'booking_view_see_more_transactions'} />
      );
      return (
        <div className="booking-tile_actions">
          <RaisedButton primary onClick={() => this.setState({ expand: !this.state.expand })} label={label} />
        </div>
      );
    }
  }

  checkComponentRender() {
    return this.rows.length !== 0 || this.status;
  }

  parseType(value) {
    const key = transactionType[value] || value;
    return <FormattedMessage id={key} />;
  }

  parseDesc(value, transaction) {
    const reason = safe(() => transaction.paymentRefusalReason);

    if (transaction.status === STATUS_REFUSED) {
      return (
        <span>
          {value} ; {reason}
        </span>
      );
    }
    return value;
  }

  parseDate(value, transaction) {
    return (
      <span>
        {formatCustomDate(value, this.dateFormat)}
        <CopyToClipBoard
          stringToCopy={transaction.orderCode}
          keyCopy="booking_view_copy_order_code"
          keyCopied="booking_view_copied_order_code"
        />
      </span>
    );
  }

  customStatus(value) {
    this.status = value;
  }

  parseStatus(status) {
    return this.getStatus(status);
  }

  parseAmount(value, transaction) {
    const currencyType = safe(() => transaction.currency);
    const { formatMessage } = this.props.intl;
    let currencyKey = 'unit_EUR';

    if (currencyType) {
      currencyKey = 'unit_' + currencyType;
    }

    return formatMessage({ id: 'common_price_with_currency' }, { amount: String(value), currency: formatMessage({ id: currencyKey }) });
  }

  parseHeader(key) {
    return (
      <th key={key}>
        <FormattedMessage id={key} />
      </th>
    );
  }

  canAddMultiRow(transaction, v, k) {
    const tValue = safe(() => transaction[k]);
    return v.display.multiRow && !isEmpty(tValue);
  }

  getHeader(props) {
    const headerTable = {};
    const header = [];

    _forEach(props.transactions, transaction => {
      this.displayProps.forEach((v, k) => {
        if (this.canAddMultiRow(transaction, v, k)) headerTable[k] = true;
      });
    });

    this.displayProps.forEach((v, k) => {
      if (headerTable[k]) header.push(this.parseHeader(v.key));
    });

    this.headerTable = headerTable;
    return header;
  }

  parseRow([props, value]) {
    if (props) {
      return (
        <td className={cs(props.classNames.td, props['extra-cs'])} key={shortid.generate()}>
          <span>{value}</span>
        </td>
      );
    } else {
      return <td className="empty-td" key={shortid.generate()} />;
    }
  }

  getRows(props) {
    const rows = [];

    _forEach(props.transactions, transaction => {
      const row = [];
      let rowEmpty = true;

      this.displayProps.forEach((v, k) => {
        const tValue = safe(() => transaction[k]);

        if (this.canAddMultiRow(transaction, v, k)) {
          row.push([v, v.parse(tValue, transaction)]);
          rowEmpty = false;
        } else if (this.headerTable[k]) row.push('');

        if (v.custom) v.custom(tValue, transaction);
      });

      if (!rowEmpty) {
        const htmlRow = [];

        row.forEach(array => htmlRow.push(this.parseRow(array)));
        rows.push(<tr key={shortid.generate()}>{htmlRow}</tr>);
      }
    });

    return rows;
  }

  tHead() {
    return (
      <thead>
        <tr>{this.header}</tr>
      </thead>
    );
  }

  retryPayment() {
    this.props.paymentAutoRetry();
  }

  render() {
    const {
      fields: { retry }
    } = this.props;
    if (this.componentCanRender)
      return (
        <div className="booking-tile" id="transactions-booking">
          <h4 className={cs('booking-tile_headline ', { 'table-headline': !this.displaySingleRow })}>
            <FormattedMessage id="booking_view_transactions_tile_header" />
          </h4>

          <div className="booking-tile_wrap">
            <IsGranted allowed={[BACKUSER_ROLE_ROOT, BACKUSER_ROLE_SUPER_ADMIN, BACKUSER_ROLE_ADMIN, BACKUSER_ROLE_FLEET_MANAGER]}>
              <div className="row-info">
                <span className="label">
                  <FormattedMessage id="booking.automatic.payment.status.label" />
                </span>
                <span className="value">
                  <GreenSwitch name="isPrivate" field={retry} onClick={this.retryPayment} />
                </span>
              </div>
            </IsGranted>
            <table className={cs({ expandable: this.isExpandable && !this.state.expand })}>
              {this.tHead()}
              <tbody>{this.rows}</tbody>
            </table>
          </div>
          {this.showMoreBtn()}
        </div>
      );
    else return <None />;
  }
}

BookingTransactionInfo.defaultProps = {
  transactions: []
};

// -----

BookingTransactionInfo.propTypes = {
  transactions: PropTypes.array,
  locale: PropTypes.string, // locale reload
  paymentAutoRetry: PropTypes.func
};

// -----

export default namedCompose(
  reduxForm(
    {
      form: 'autoRetryPayment',
      overwriteOnInitialValuesChange: false
    },
    state => {
      const retry = automaticPaymentRetrySekector(state);
      return {
        fields: ['retry'],
        initialValues: { retry }
      };
    }
  ),
  injectIntl,
  pure
)(BookingTransactionInfo);
