import callApi from './api-actions';
import { addErrorMessage } from '../utils/utils';
import * as types from '../constants/actionTypes-constants';

export function createOrganizationBrand(params) {
  return dispatch => {
    return dispatch(callApi('appBrands', 'createBrand', params)).then(
      data => {
        dispatch(createOrganizationBrandSuccess(data));
        return Promise.resolve(data);
      },
      error => {
        const params = { error, def: 'brandForm_create_brand_error' };
        dispatch(addErrorMessage(params));
        return Promise.reject(error);
      }
    );
  };
}

export function createOrganizationBrandSuccess(data) {
  return {
    type: types.ORGANIZATION_CREATE_BRAND_SUCCESS,
    data
  };
}
