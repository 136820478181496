import React, { Component } from 'react';
import moment from 'moment';
// import { connect } from 'react-redux';

class TimeMarker extends Component {
  constructor(props) {
    super(props);
    const now = new Date();
    this.state = {
      now,
      hour: now.getHours(),
      minute: now.getMinutes(),
      offset: now.getHours() * 60 + now.getMinutes(),
      period: props.period
    };
  }

  componentDidMount() {
    this.interval = setInterval(() => {
      this.tic();
    }, 60000);
  }

  tic() {
    this.now2 = new Date();
    // eslint-disable-next-line react/no-set-state
    this.setState({
      now: this.now2,
      hour: this.now2.getHours(),
      minute: this.now2.getMinutes(),
      offset: this.state.hour * 60 + this.state.minute,
      period: this.props.period
    });
  }

  componentWillReceiveProps(nextProps) {
    let start = new Date();
    // eslint-disable-next-line react/no-set-state
    this.setState({
      now: start,
      hour: start.getHours(),
      minute: start.getMinutes(),
      offset: this.state.hour * 60 + this.state.minute,
      period: nextProps.period
    });
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  render() {
    let widthGap = diff => {
      diff === 0 ? 1 : diff;
      let daysGap = 0;
      const interfaceDayGap = document.querySelector('div.vehiclePlanning_firstRow_field');
      const interfaceDayGapWidth = interfaceDayGap && interfaceDayGap.clientWidth;

      if (this.state.period > 1) {
        daysGap = interfaceDayGapWidth * diff;
      }
      daysGap === 0 ? (daysGap = '') : (daysGap = ' + ' + daysGap + 'px');
      return this.state.period === 1 ? '' : `${daysGap}`;
    };

    let marker = width => {
      let left = `calc( ((100% - 150px) / 1440 / ${this.state.period}) * ${this.state.offset} ${width} + 150px)`;
      let height = this.props.height;
      return <div className="current-time" style={{ left, height }} />;
    };

    const nowDate = moment(this.state.now);
    const _startDate = moment(this.props.startDate);
    const __startD = moment(_startDate);

    const sForm = __startD.format('YYYY-MM-DD');
    const eForm = __startD.add(this.props.period, 'd').format('YYYY-MM-DD');

    if (nowDate.isBetween(sForm, eForm, 'h', '[]')) {
      const step = nowDate.diff(_startDate, 'd');
      const width = widthGap(step);
      return marker(width);
    }
    return <div />;
  }
}

TimeMarker.displayName = 'TimeMarker';

export default TimeMarker;
