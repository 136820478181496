import React, { PropTypes } from 'react';
import { FormattedMessage } from 'react-intl';
import _ from 'lodash';
import FlatButton from 'material-ui/FlatButton';

import { MemberBasicInfoStyles } from '../../constants/style-constants';
import BoxedSelect from '../BoxedSelect/BoxedSelect';

const MemberTechnicianInfo = props => {
  const {
    freeCompanies,
    renderFreeCompaniesList,
    renderUsedCompaniesList,
    usedCompanies,
    onHandleAssignFreeRoute,
    onHandleAssignUseRoute,
    onHandleAssignSaveRoute,
    anonymizationEffectiveDate,
    readOnly
  } = props;

  return (
    <div className="memberView_techInfo">
      <h4>
        <FormattedMessage id="members_detail_companies_technician_title" />
      </h4>
      <div className="memberView_techInfo_body">
        <div className="memberView_techInfo_main">
          {renderUsedCompaniesList && usedCompanies && (
            <div className="memberView_techInfo_companies">
              <BoxedSelect
                id="memberView_techInfo_selected_companies"
                multiple
                formRowItemKey="companyDetail_usedCompanies"
                customClass="companyDetail_boxedSelectMultipleWrapper"
                customSelectClass="companyDetail_boxedSelect"
                labelKey="members_detail_associated_companies_label"
                options={renderUsedCompaniesList()}
                field={usedCompanies}
                disabled={readOnly}
              />
            </div>
          )}
          {renderUsedCompaniesList && onHandleAssignUseRoute && freeCompanies && renderFreeCompaniesList && (
            <div className="memberView_techInfo_arrows">
              <div className="companyDetail_actionsAssign" formRowItemKey="companyDetail_assignUsages">
                <button
                  id="memberView_techInfo_add_company"
                  type="button"
                  className="companyDetail_assignButton companyDetail_assignButton--left"
                  onClick={onHandleAssignUseRoute}
                  disabled={!_.get(freeCompanies, 'value') || readOnly}
                >
                  <FormattedMessage id="companyDetail_addToUsed_button" />
                </button>
                <button
                  id="memberView_techInfo_remove_company"
                  type="button"
                  className="companyDetail_assignButton companyDetail_assignButton--right"
                  onClick={onHandleAssignFreeRoute}
                  disabled={!_.get(usedCompanies, 'value') || readOnly}
                >
                  <FormattedMessage id="companyDetail_addToFree_button" />
                </button>
              </div>
            </div>
          )}
          {renderUsedCompaniesList && onHandleAssignUseRoute && freeCompanies && renderFreeCompaniesList && (
            <div className="memberView_techInfo_companies">
              <BoxedSelect
                id="memberView_techInfo_free_companies"
                multiple
                formRowItemKey="companyDetail_freeCompanies"
                customClass="companyDetail_boxedSelectMultipleWrapper"
                customSelectClass="companyDetail_boxedSelect"
                labelKey="members_detail_available_companies_label"
                options={renderFreeCompaniesList()}
                field={freeCompanies}
                disabled={readOnly}
              />
            </div>
          )}
        </div>
        <div className="memberView_reviewButtonsContainer">
          {!readOnly && onHandleAssignSaveRoute && !anonymizationEffectiveDate && (
            <FlatButton
              id="memberView_techInfo_companies_save_button"
              label={<FormattedMessage id="companyDetail_save_button" />}
              onClick={onHandleAssignSaveRoute}
              style={MemberBasicInfoStyles.buttons.flat}
            />
          )}
        </div>
      </div>
    </div>
  );
};

MemberTechnicianInfo.displayName = 'MemberTechnicianInfo';

MemberTechnicianInfo.propTypes = {
  usedCompanies: PropTypes.object.isRequired,
  freeCompanies: PropTypes.object,
  anonymizationEffectiveDate: PropTypes.any,
  renderUsedCompaniesList: PropTypes.func.isRequired,
  renderFreeCompaniesList: PropTypes.func,
  onHandleAssignUseRoute: PropTypes.func,
  onHandleAssignSaveRoute: PropTypes.func,
  onHandleAssignFreeRoute: PropTypes.func
};

export default MemberTechnicianInfo;
