import { AutoComplete } from 'material-ui';
import { ALL } from '../../constants/generic-constants';

// memoize it separatelly for each component
export const createAutoCompleteDataSource = headerCompanies => {
  return (headerCompanies || []).map(company => ({
    text: company.name,
    value: company
  }));
};

export const autoCompleteCompare = AutoComplete.caseInsensitiveFilter;

const firstResultData = data => data && typeof data === 'string' && data !== ALL;

export const parseFirstAutoCompleteResult = (data, list) => {
  list = list || [];

  if (firstResultData(data)) {
    for (let i = 0; i < list.length; i++) {
      if (autoCompleteCompare(data, list[i].name)) {
        return { value: list[i] };
      }
    }
  }
  return data;
};
