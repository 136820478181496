import React, { Component } from 'react';
import ColumnList from '../../../components/_v2/ColumnList';
import { connect } from 'react-redux';

import { createMenuItems, resolveActive, resolveSelected, showNextView } from '../../../components/_v2/ColumnList/helpers';
import { FormattedMessage } from 'react-intl';
import { userRoleSelector } from '../../../selectors/all-selectors';
import { editCategoriesRules } from '../../../constants/routes-constants';
import { checkRole } from '../../../constants/backuser-role-rules';

class CategoriesList extends Component {
  componentWillMount() {
    this.setCallbacks();
    this.setVars();
  }

  setVars() {
    if (checkRole(editCategoriesRules, this.props.role)) {
      this.menuItems = createMenuItems({ onAdd: this.handleAdd, addKey: 'vehiclesCategory_add_button' });
    }
  }

  setCallbacks() {
    this.handleSelect = ({ id }, _, { openInNewTab }) => {
      showNextView.call(this, 'vehicleEditCategory', ':categoryId', id, openInNewTab);
    };

    this.handleAdd = () => {
      showNextView.call(this, 'vehicleAddCategory');
    };

    this.renderItem = ({ type }) => {
      return <div className="sc-list-item">{type}</div>;
    };

    this.resolveSelected = resolveSelected.bind(this);
    this.resolveActive = resolveActive.bind(this);
  }

  render() {
    return (
      <ColumnList
        title={<FormattedMessage id="common_categories" />}
        inputRef={this.props.inputRef}
        items={this.props.list}
        renderItem={this.renderItem}
        onSelect={this.handleSelect}
        selected={this.resolveSelected}
        active={this.resolveActive}
        menuItems={this.menuItems}
        localSearchEnabled
        localSearchKey="type"
      />
    );
  }
}

const mapStateToProps = state => {
  const { list } = state.categories;
  return { list, role: userRoleSelector(state) };
};

CategoriesList = connect(mapStateToProps)(CategoriesList);

export default CategoriesList;
