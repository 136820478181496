import * as types from '../../constants/actionTypes-constants';
import callApi from '../../actions/api-actions';
import { defaultParamsPage } from '../../constants/options-constants';
import { trySet, append, selectCompanyId, setApiCompanyIds, safe, openUrlBackgroundTab } from '../../utils/utils';
import { apiParams } from '../../constants/api-params-constants';
import { routeActions } from 'react-router-redux';
import { searchMode } from '../Header/Header.actions';
import { refreshPageWithDefaultPagination } from '../../routing/helpers-routing';

export function searchMemberName(text) {
  return (dispatch, getState) => {
    const state = getState();
    const params = { ...defaultParamsPage, memberName: text };

    setApiCompanyIds(params, state, { setSubCompanyId: false });
    dispatch({ type: types.HEADER_SEARCH_REQUEST });

    return dispatch(callApi('search', 'memberName', params, false))
      .then(data => {
        dispatch({ type: types.SEARCH_MEMBER_NAME_SUCCESS, result: data });
      })
      .catch(() => {
        dispatch({ type: types.SEARCH_MEMBER_NAME_SUCCESS, result: null });
      });
  };
}

export function clearResults() {
  return {
    type: types.SEARCH_CLEAR_RESULTS
  };
}

export function searchBookings(bookingId) {
  return (dispatch, getState) => {
    const state = getState();
    const defaultParams = {
      ...defaultParamsPage,
      id: bookingId
    };

    setApiCompanyIds(defaultParams, state);

    dispatch({
      type: types.HEADER_SEARCH_REQUEST
    });

    return dispatch(callApi('search', 'bookings', defaultParams, false)).then(function(data) {
      dispatch({
        type: types.SEARCH_BOOKINGS_SUCCESS,
        result: data
      });

      return data;
    });
  };
}

export function searchVehicles(licencePlate) {
  return (dispatch, getState) => {
    const state = getState();
    const defaultParams = {
      ...defaultParamsPage,
      plateNumber: licencePlate,
      includeUninstalledDevice: true
    };

    setApiCompanyIds(defaultParams, state);

    dispatch({
      type: types.HEADER_SEARCH_REQUEST
    });

    return dispatch(callApi('search', 'vehicles', defaultParams, false)).then(function(data) {
      dispatch({
        type: types.SEARCH_VEHICLES_SUCCESS,
        result: data
      });

      return data;
    });
  };
}

export function searchCompanies(companyName) {
  return (dispatch, getState) => {
    const state = getState();
    const defaultParams = {
      ...defaultParamsPage,
      name: companyName
    };

    trySet(defaultParams, 'companyIds', append([selectCompanyId(state)]));

    dispatch({
      type: types.HEADER_SEARCH_REQUEST
    });

    return dispatch(callApi('search', 'companies', defaultParams, false)).then(function(data) {
      dispatch({
        type: types.SEARCH_COMPANIES_SUCCESS,
        result: data
      });

      return data;
    });
  };
}

export function searchMemberEmail(text) {
  return (dispatch, getState) => {
    const state = getState();
    const params = { ...defaultParamsPage, email: text };

    setApiCompanyIds(params, state, { setSubCompanyId: false });
    dispatch({ type: types.HEADER_SEARCH_REQUEST });

    return dispatch(callApi('search', 'memberEmail', params, false))
      .then(data => {
        dispatch({ type: types.SEARCH_MEMBER_EMAIL_SUCCESS, result: data });
      })
      .catch(() => {
        dispatch({ type: types.SEARCH_MEMBER_EMAIL_SUCCESS, result: null });
      });
  };
}

const isSameLocation = location => {
  const hashRoute = safe(() => window.location.hash.split('#')[1]);
  const currentRoute = safe(() => hashRoute.split('/')[1]);
  const currentLocation = safe(() => location.split('/')[1]);
  return currentRoute === currentLocation;
};

export const goToSearchLocation = (location, toAdd, openInNewTab) => {
  return dispatch => {
    const sameRoute = isSameLocation(location);
    const params = { ...apiParams.default, ...toAdd };
    const encodedParams = encodeURIComponent(JSON.stringify(params));
    const url = location.replace(':search', encodedParams);

    if (openInNewTab) {
      return openUrlBackgroundTab('/#' + url);
    }

    dispatch(routeActions.push(url));
    dispatch(clearResults());
    dispatch(searchMode(false));

    if (sameRoute) {
      refreshPageWithDefaultPagination(true);
    }
  };
};

export const goToDetailsLocation = (location, toReplace, id, openInNewTab) => {
  return dispatch => {
    const url = location.replace(toReplace, id);

    if (openInNewTab) {
      return openUrlBackgroundTab('/#' + url);
    }
    dispatch(routeActions.push(url));
    dispatch(clearResults());
    dispatch(searchMode(false));
  };
};
