import React, { Component, PropTypes } from 'react';
import { FormattedMessage } from 'react-intl';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { getStore } from '../../store/all-store';

import FormRow from '../FormRow/FormRow';
import IsGranted from '../IsGranted/IsGranted';
import BoxedInput from '../BoxedInput/BoxedInput';
import EkButton from '../EkButton/EkButton';
import TableView from '../../components/TableView/TableView';
import FieldErrorMsg from '../FieldErrorMsg/FieldErrorMsg';
import { createValidator, notEmpty } from '../../validation/all-validation';
import { FLASH_MESSAGE_TYPE_ERROR, FLASH_MESSAGE_TYPE_SUCCESS } from '../../constants/generic-constants';

import { deleteAutolibCard, addFlashMessage, getAutolibCardsList } from '../../actions/all-actions';
import { scrollToFirstError } from '../../utils/utils';

const tableViewParams = {
  namespace: 'autolibsCards',
  sortIsDescending: false,
  columns: [
    {
      messageKey: 'autolibsCards_tableView_label_number',
      content: 'cardNumber'
    },
    {
      messageKey: 'autolibsCards_tableView_label_startDate',
      content: 'startDate'
    },
    {
      messageKey: 'autolibsCards_tableView_label_endDate',
      content: 'endDate'
    },
    {
      messageKey: 'autolibsCards_tableView_label_expirationDate',
      content: 'expirationDate'
    },
    {
      messageKey: 'autolibsCards_tableView_label_pinCode',
      content: 'pinCode'
    },
    {
      messageKey: 'autolibsCards_tableView_label_ownerName',
      content: 'ownerName'
    },
    {
      messageKey: 'fuelCards_tableView_label_action',
      content: null,
      contentMessageKey: 'fuelCards_tableView_content_action',
      actionCustomClass: 'vehicleDetail_tableView_action',
      actionCallback: function(item) {
        getStore()
          .dispatch(deleteAutolibCard(item.id))
          .then(() => {
            getStore().dispatch(
              addFlashMessage({
                contentKey: 'vehicle_delete_card_success',
                type: FLASH_MESSAGE_TYPE_SUCCESS
              })
            );
            getStore().dispatch(getAutolibCardsList(getStore().getState().vehicles.detailVehicle.id));
          })
          .catch(error => {
            if (!error.type) {
              // make sure native errors not swallowed
              console.error(error.stack); // eslint-disable-line
            }
            getStore().dispatch(
              addFlashMessage({
                contentKey: 'vehicle_delete_card_failed',
                type: FLASH_MESSAGE_TYPE_ERROR
              })
            );
          });
      }
    }
  ]
};

class AutolibCardForm extends Component {
  constructor() {
    super();
  }

  render() {
    const {
      fields: { cardNumber, expirationDate, startDate, endDate, pinCode, ownerName },
      handleSubmit,
      list,
      limitedAccess,
      onCallback
    } = this.props;

    const submitAction = limitedAccess ? undefined : handleSubmit(onCallback);

    return (
      <div className="autolibCardForm_container">
        {!limitedAccess && (
          <form action="#" className="autolibCardForm vehicleDetail_form" onSubmit={submitAction}>
            <h4 className="autolibCardForm_title">
              <FormattedMessage id="autolibCardForm_title" />
            </h4>

            <FormRow customWrapperClass="autolibCardForm_row">
              <BoxedInput
                formRowItemKey="autolibCardForm_number"
                type="text"
                id="autolibCardForm_number"
                labelKey="autolibCardForm_number"
                field={cardNumber}
              >
                <FieldErrorMsg customClass="fieldErrorMsg--autolibCardForm" field={cardNumber} />
              </BoxedInput>

              <BoxedInput
                formRowItemKey="autolibCardForm_pinCode"
                type="text"
                id="autolibCardForm_pinCode"
                labelKey="autolibCardForm_pinCode"
                field={pinCode}
              >
                <FieldErrorMsg customClass="fieldErrorMsg--autolibCardForm" field={pinCode} />
              </BoxedInput>
            </FormRow>

            <FormRow customWrapperClass="autolibCardForm_row">
              <BoxedInput
                formRowItemKey="autolibCardForm_startDate"
                type="date"
                id="autolibCardForm_startDate"
                labelKey="autolibCardForm_startDate"
                field={startDate}
              >
                <FieldErrorMsg customClass="fieldErrorMsg--autolibCardForm" field={startDate} />
              </BoxedInput>

              <BoxedInput
                formRowItemKey="autolibCardForm_endDate"
                type="date"
                id="autolibCardForm_endDate"
                labelKey="autolibCardForm_endDate"
                field={endDate}
              >
                <FieldErrorMsg customClass="fieldErrorMsg--autolibCardForm" field={endDate} />
              </BoxedInput>
            </FormRow>

            <FormRow customWrapperClass="autolibCardForm_row">
              <BoxedInput
                formRowItemKey="autolibCardForm_expirationDate"
                type="date"
                id="autolibCardForm_expirationDate"
                labelKey="autolibCardForm_expirationDate"
                field={expirationDate}
              >
                <FieldErrorMsg customClass="fieldErrorMsg--autolibCardForm" field={expirationDate} />
              </BoxedInput>

              <BoxedInput
                formRowItemKey="autolibCardForm_name"
                type="text"
                id="autolibCardForm_name"
                labelKey="autolibCardForm_name"
                field={ownerName}
              >
                <FieldErrorMsg customClass="fieldErrorMsg--autolibCardForm" field={ownerName} />
              </BoxedInput>
            </FormRow>

            <EkButton customClass="autolibCardForm_actionsButton" onAction={submitAction}>
              <FormattedMessage id="contractForm_submit" />
            </EkButton>
          </form>
        )}

        <section className="vehiclesColorsPage_management">
          <TableView
            customClass="vehiclesColorsPage_tableViewWrapper"
            params={tableViewParams}
            content={list}
            limitedAccess={limitedAccess}
          />
        </section>
      </div>
    );
  }
}

AutolibCardForm.displayName = 'AutolibCardForm';

AutolibCardForm.propTypes = {
  fields: PropTypes.object,
  handleSubmit: PropTypes.func,
  onCallback: PropTypes.func,
  limitedAccess: PropTypes.bool
};

AutolibCardForm = reduxForm({
  onSubmitFail: scrollToFirstError,
  form: 'autolibCardForm',
  fields: ['cardNumber', 'expirationDate', 'startDate', 'endDate', 'pinCode', 'ownerName'],
  validate: createValidator({
    cardNumber: [notEmpty()]
  })
})(AutolibCardForm);

export default connect(state => {
  const {
    vehicles: { autolibCardsList },
    user: { userInfo }
  } = state;

  return {
    list: autolibCardsList,
    role: userInfo.role
  };
})(AutolibCardForm);
