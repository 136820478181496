import React, { Component, PropTypes as T } from 'react';
import { FormattedMessage } from 'react-intl';

class VehiclePlanningLegend extends Component {
  render() {
    const { contract } = this.props;
    const { preBooking } = contract || {};

    return (
      <div className="legend-wrapper">
        {this.props.visible && (
          <div className="legend">
            {preBooking && (
              <span className="prebooked">
                <FormattedMessage className="test" id="common_booking_status_PRE_BOOKED" />
              </span>
            )}
            <span className="scheduled">
              <FormattedMessage id="common_booking_status_SCHEDULED" />
            </span>
            <span className="progress">
              <FormattedMessage id="common_booking_status_IN_PROGRESS" />
            </span>
            <span className="delayed">
              <FormattedMessage id="booking_detail_delayed" />
            </span>
            <span className="completed">
              <FormattedMessage id="common_booking_status_COMPLETED" />
            </span>
          </div>
        )}
      </div>
    );
  }
}

export default VehiclePlanningLegend;
