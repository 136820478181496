import React from 'react';

class GreenSwitch extends React.Component {
  render() {
    const { field, meta, ...props } = this.props;

    return (
      <label className="switch-component">
        <input type="checkbox" checked={field.value} {...field} {...props} />
        <span className="slider round" />
      </label>
    );
  }
}

export default GreenSwitch;
