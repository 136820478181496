import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { routeActions } from 'react-router-redux';
import classNames from 'classnames';
import moment from 'moment';

import _get from 'lodash/get';

import {
  setBookingVehicleToggleSelectedMemberInfos,
  setBookingSelectedVehicle,
  getBookingFindAvailableVehicle,
  getBookingsCustomFields,
  clearCompanyCurrentContract,
  getCompanyCurrentContract
} from '../../actions/all-actions';
import { getAppFormattedDateTime, isValidId, safe, trySet } from '../../utils/utils';
import routes from '../../constants/routes-constants';
import { enhanceSearchVehicleData } from '../../api/data-enhancer';
import MemberInformations from '../../components/MemberInformations/MemberInformations';
import SearchVehiclesForm from '../../components/SearchVehicleForm/SearchVehicleForm';
import TableView from '../TableView/TableView';
import FlashMessageDisplayer from '../../components/FlashMessageDisplayer/FlashMessageDisplayer';
import EkButton from '../../components/EkButton/EkButton';
import { ONE_WAY_TRIP, ROUND_TRIP } from '../../constants/backend-constants';
import { currentCompanyContractSelector, selectLegacyBookingVehiclesList } from '../../selectors/all-selectors';

const searchVehicleInitialValues = {
  pickupDateHour: '00',
  pickupDateMin: '00',
  returnDateHour: '00',
  returnDateMin: '00',
  passengers: '1',
  tripType: ONE_WAY_TRIP
};

class BookingFindVehicle extends Component {
  componentWillMount() {
    this.handleToggleFilters = this.handleToggleFilters.bind(this);
    this.handleSearchForm = this.handleSearchForm.bind(this);
    this.tableActionHandler = this.tableActionHandler.bind(this);

    this.tableViewVehiclesParams = {
      namespace: 'vehicles',
      sortIsDescending: false,
      columns: [
        {
          messageKey: 'fleet_tableView_label_registrationNumber',
          content: 'vehicle.registrationNumber'
        },
        {
          messageKey: 'fleet_tableView_label_model',
          content: 'vehicle.version.model.name'
        },
        {
          messageKey: 'booking_create_find_vehicle_tableView_start_parking',
          content: 'start.parking.name'
        },
        {
          messageKey: 'booking_create_find_vehicle_tableView_start',
          content: item => getAppFormattedDateTime(item.start.date)
        },
        {
          messageKey: 'booking_create_find_vehicle_tableView_end',
          content: item => getAppFormattedDateTime(item.end.date)
        },
        {
          messageKey: 'fleet_tableView_label_companyName',
          content: 'vehicle.company.name'
        },
        {
          messageKey: 'booking_create_find_vehicle_tableView_usage',
          content: 'carSharingInfo.usageType'
        },
        {
          messageKey: 'booking_create_find_vehicle_tableView_booking_type',
          content: 'type',
          contentMessageKey: 'bookings_tableView_serviceType_[content]'
        },
        {
          messageKey: 'fleet_tableView_label_statusType',
          content: 'vehicle.statusType',
          contentMessageKey: 'vehicle_statusType_[content]',
          contentCustomClass: 'fleet_statusTypeCell fleet_statusTypeCell--[content]'
        },
        {
          messageKey: 'members_tableView_label_action',
          content: null,
          contentMessageKey: 'booking_create_find_vehicle_tableView_action',
          actionCustomClass: 'fleet_tableView_action',
          actionCallback: this.tableActionHandler
        }
      ]
    };

    this.componentPropsUpdated(this.props, true);
  }

  componentWillReceiveProps(props) {
    this.componentPropsUpdated(props);
  }

  componentPropsUpdated(props, init) {
    this.getCompanyContract(props, init);
    this.setSearchInitialValues(props, init);
    this.prefillBookingData(props);
  }

  getCompanyContract(props, init) {
    if (this.selectedMemberUpdated(props, init)) {
      const { dispatch } = props;
      const companyId = safe(() => props.selectedMember.company.id);

      if (isValidId(companyId)) {
        dispatch(getCompanyCurrentContract(companyId));
      } else {
        dispatch(clearCompanyCurrentContract());
      }
    }
  }

  setSearchInitialValues(props, init) {
    if (this.companyContractUpdated(props, init)) {
      this.searchVehicleInitialValues = { ...searchVehicleInitialValues, tripType: this.setTripType(props) };
    }
  }

  setTripType(props) {
    const { interfaceConfigDto } = props.companyContract;
    const { oneWayDisplay, returnTripDisplay } = interfaceConfigDto || {};
    return oneWayDisplay && !returnTripDisplay ? ONE_WAY_TRIP : ROUND_TRIP;
  }

  selectedMemberUpdated(props, init) {
    const { selectedMember: prev } = this.props;
    const { selectedMember: next } = props;
    return init || prev !== next;
  }

  companyContractUpdated(props, init) {
    const { companyContract: prev } = this.props;
    const { companyContract: next } = props;
    return init || prev !== next;
  }

  getAddress(object) {
    const startAddress = _get(object, 'address');
    const bookingStartParking = _get(object, 'parking');
    const bookingStartSite = _get(bookingStartParking, 'site');
    const startSiteAddress = _get(bookingStartSite, 'address.formattedAddress');

    if (startSiteAddress) {
      const siteObject = { ...bookingStartSite, _isSite: true };
      const siteName = _get(bookingStartSite, 'name');
      trySet(siteObject, 'label', siteName);
      return siteObject;
    }
    return startAddress;
  }

  isRoundTrip(from, to) {
    const isSite = _get(from, '_isSite');
    if (isSite) {
      const startSiteId = _get(from, 'id');
      const endSiteId = _get(to, 'id');
      return startSiteId && startSiteId === endSiteId;
    } else {
      const startAddress = _get(from, 'formattedAddress');
      const endAddress = _get(to, 'formattedAddress');
      return startAddress && startAddress === endAddress;
    }
  }

  getDateObject(date) {
    if (date) {
      const momentDate = moment(date);
      const validDate = momentDate.isValid();
      if (validDate)
        return {
          date: momentDate.toDate(),
          hour: momentDate.format('HH'),
          min: momentDate.format('mm')
        };
    }
    return {};
  }

  prefillBookingData(props) {
    const { bookingDetail, editionMode } = props;

    if (editionMode) {
      const bookingStart = _get(bookingDetail, 'start');
      const bookingEnd = _get(bookingDetail, 'end');

      const from = this.getAddress(bookingStart);
      const to = this.getAddress(bookingEnd);
      const roundTrip = this.isRoundTrip(from, to);
      const startDate = _get(bookingStart, 'date');
      const endDate = _get(bookingEnd, 'date');
      const startMoment = this.getDateObject(startDate);
      const endMoment = this.getDateObject(endDate);
      const reservedSeats = _get(bookingDetail, 'reservedSeats');

      const values = {
        ...searchVehicleInitialValues,
        tripType: roundTrip ? ROUND_TRIP : ONE_WAY_TRIP
      };

      trySet(values, 'from', from);
      trySet(values, 'pickupDate', startMoment.date);
      trySet(values, 'pickupDateHour', startMoment.hour);
      trySet(values, 'pickupDateMin', startMoment.min);
      trySet(values, 'passengers', reservedSeats);

      if (roundTrip) {
        trySet(values, 'returnDate', endMoment.date);
        trySet(values, 'returnDateHour', endMoment.hour);
        trySet(values, 'returnDateMin', endMoment.min);
      } else {
        trySet(values, 'to', to);
      }

      this.searchVehicleInitialValues = values;
    }
  }

  handleToggleFilters() {
    this.props.dispatch(setBookingVehicleToggleSelectedMemberInfos());
  }

  handleSearchForm(values) {
    const { dispatch, editionMode, selectedMember, bookingDetail } = this.props;
    const newParams = enhanceSearchVehicleData(values, bookingDetail, selectedMember, editionMode);

    if (!editionMode) {
      dispatch(
        routeActions.push(
          `${routes.addBooking.path}/${routes.bookingFindVehicle.path.replace(
            '(/:search)',
            '/' + encodeURIComponent(JSON.stringify(newParams))
          )}`
        )
      );
    } else {
      dispatch(getBookingFindAvailableVehicle(newParams));
      dispatch(getBookingsCustomFields(_get(selectedMember, 'company.id')));
    }
  }

  tableActionHandler(item) {
    this.props.dispatch(setBookingSelectedVehicle(item));
  }

  render() {
    const { list, selectedMember, selectedMemberInfosOpened } = this.props;

    const displaySelectedMemberInfosCSSClassnames = classNames({
      bookingFindVehicle_filtersButton: true,
      _is_opened: selectedMemberInfosOpened
    });

    return (
      <div className="bookingFindVehicle">
        <div className="bookingFindVehicle_filterWrapper">
          <EkButton skinType="variant1" customClass={displaySelectedMemberInfosCSSClassnames} onAction={this.handleToggleFilters}>
            <FormattedMessage id="booking_create_find_members_infos_toggle_button" />
          </EkButton>

          {selectedMemberInfosOpened && <MemberInformations detailMember={selectedMember} />}
        </div>

        <FlashMessageDisplayer />

        <div className="vehiclesFiltersFormWrapper">
          <SearchVehiclesForm
            userInfo={selectedMember}
            onCallback={this.handleSearchForm}
            initialValues={this.searchVehicleInitialValues}
          />
        </div>

        <div className="bookingFindVehicle_list">
          {list.length && (
            <TableView customClass="bookingFindVehicle_tableViewWrapper" params={this.tableViewVehiclesParams} content={list} />
          )}

          {!list.length && (
            <p className="noResultMessage">
              <FormattedMessage id="booking_create_no_vehicle_found" />
            </p>
          )}
        </div>
      </div>
    );
  }
}

BookingFindVehicle.displayName = 'BookingFindVehicle';

export default connect(state => {
  const {
    bookingFindVehicle: { selectedMemberInfosOpened },
    bookings: { bookingDetail },
    booking: { editionMode, selectedMember }
  } = state;

  return {
    companyContract: currentCompanyContractSelector(state),
    selectedMember,
    editionMode,
    list: selectLegacyBookingVehiclesList(state),
    selectedMemberInfosOpened,
    bookingDetail
  };
})(BookingFindVehicle);
