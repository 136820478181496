import React, { Component, PropTypes } from 'react';

import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import autoBind from 'react-autobind';
import pure from 'recompose/pure';
import IconButton from 'material-ui/IconButton';
import CloseIcon from 'material-ui/svg-icons/navigation/close';
import AllIcon from 'material-ui/svg-icons/content/select-all';
import FlatButton from 'material-ui/FlatButton';
import AutoComplete from 'material-ui/AutoComplete';
import { makeLocationSelector } from '../Header.selectors';
import {
  closeIconSuperCompanyAutoComplete,
  borderWhite,
  lightGrayColor,
  colorWhite,
  fillWhite,
  fillDark,
  colorDark,
  borderDark,
  allIconSuperCompanyAutoComplete,
  autoCompleteStyle,
  autoCompleteListStyle,
  autoCompletePopoverProps,
  autoCompleteAnchorOrigin,
  autoCompleteTargetOrigin
} from '../../../constants/style-constants';
import { toggleSuperCompanyAutoComplete, superCompanyInputChange } from '../Header.actions';
import { namedCompose } from '../../../utils/utils';
import { localeSelector, useNewHeaderSelector } from '../../../selectors/all-selectors';
import Tooltip from '../../Tooltip/Tooltip';
import { ALL } from '../../../constants/generic-constants';
import memoizeOne from 'memoize-one';
import { autoCompleteCompare, createAutoCompleteDataSource } from '../utils';

const memoizeSuperCompanyDataSource = memoizeOne(createAutoCompleteDataSource);

class SuperCompanyAutoComplete extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
  }

  handleEditing() {
    setTimeout(() => {
      const input = document.querySelector('div.header-superCompanyAutoCompleteWrap input');
      if (input) input.focus();
    }, 50);
    this.props.dispatch(toggleSuperCompanyAutoComplete());
  }

  handleInputChange(text) {
    this.props.dispatch(superCompanyInputChange(text));
  }

  handleSetToAll() {
    if (this.props.onCompanySelect) {
      this.props.onCompanySelect(ALL);
    }
  }

  render() {
    const { currentCompany, headerList, userInfo, superCompanyIsEditing, searchMode, superCompanyText, intl } = this.props;
    const companyLabel = intl.formatMessage({ id: 'backUserForm_superCompanie_label' });

    // noinspection JSUnresolvedVariable
    return (
      <div className="header-superCompanyAutoComplete">
        <div
          className={superCompanyIsEditing ? 'header-superCompanyEditing header-superCompanyEditing-edit' : 'header-superCompanyEditing'}
        >
          {superCompanyIsEditing && (
            <div>
              <div className="header-superCompanyAutoCompleteWrap">
                <AutoComplete
                  anchorOrigin={autoCompleteAnchorOrigin}
                  targetOrigin={autoCompleteTargetOrigin}
                  popoverProps={autoCompletePopoverProps}
                  listStyle={autoCompleteListStyle}
                  dataSource={memoizeSuperCompanyDataSource(headerList)}
                  underlineStyle={searchMode ? borderDark : borderWhite}
                  underlineFocusStyle={searchMode ? borderDark : borderWhite}
                  hintStyle={lightGrayColor}
                  inputStyle={searchMode ? colorDark : colorWhite}
                  onNewRequest={this.props.onCompanySelect}
                  filter={autoCompleteCompare}
                  maxSearchResults={100}
                  onUpdateInput={this.handleInputChange}
                  menuStyle={autoCompleteStyle}
                  searchText={superCompanyText}
                  openOnFocus
                  id="supcomp"
                />
                <IconButton
                  onClick={this.handleEditing}
                  style={closeIconSuperCompanyAutoComplete}
                  iconStyle={searchMode ? fillDark : fillWhite}
                  tooltipPosition="bottom-center"
                  tooltip={<FormattedMessage id="parkingForm_confirmation_abort" />}
                >
                  <CloseIcon />
                </IconButton>
                <IconButton
                  onClick={this.handleSetToAll}
                  style={allIconSuperCompanyAutoComplete}
                  iconStyle={searchMode ? fillDark : fillWhite}
                  tooltipPosition="bottom-center"
                  tooltip={<FormattedMessage id="select_all_super_companies" />}
                >
                  <AllIcon />
                </IconButton>
              </div>
              <div className="header-superCompanyCloseOverlay" onClick={this.handleEditing} />
            </div>
          )}
        </div>
        {!superCompanyIsEditing && (
          <div>
            {currentCompany && (
              <Tooltip placement="bottom" distance={25} content={companyLabel}>
                <div className="header-actionIcons-companyName header-superCompanyAutoComplete-button">
                  <FlatButton
                    label={currentCompany.name === ALL ? <FormattedMessage id="all_super_companies" /> : currentCompany.name}
                    onClick={this.handleEditing}
                    style={searchMode ? colorDark : colorWhite}
                    labelStyle={searchMode ? colorDark : colorWhite}
                    icon={<i className="super-company" style={searchMode ? fillDark : fillWhite} />} // eslint-disable-line
                  />
                </div>
              </Tooltip>
            )}
            {!currentCompany && (
              <Tooltip placement="bottom" distance={19} content={companyLabel}>
                <div>
                  <IconButton
                    iconStyle={searchMode ? fillDark : fillWhite}
                    onClick={this.handleEditing}
                    icon={<i className="super-company" />}
                  />
                </div>
              </Tooltip>
            )}
          </div>
        )}
      </div>
    );
  }
}

SuperCompanyAutoComplete.propTypes = {
  onCompanySelect: PropTypes.func,
  currentCompany: PropTypes.object,
  userInfo: PropTypes.object,
  superCompanyIsEditing: PropTypes.bool,
  searchMode: PropTypes.bool,
  superCompanyText: PropTypes.string
};

export default namedCompose(
  connect(state => {
    const {
      companies: { headerList },
      user: { userInfo, enableNewUi },
      header: { superCompanyIsEditing, searchMode, superCompanyText }
    } = state;

    return {
      headerList,
      userInfo,
      superCompanyIsEditing,
      searchMode: searchMode || enableNewUi || useNewHeaderSelector(state),
      superCompanyText,
      location: makeLocationSelector(state),
      locale: localeSelector(state) // 'pure' exception
    };
  }),
  injectIntl,
  pure
)(SuperCompanyAutoComplete);
