import React, { Component } from 'react';
import { connect } from 'react-redux';
import { routeActions } from 'react-router-redux';
import { getStore } from '../../store/all-store';

import TableView from '../../components/TableView/TableView';
import PageManagementHeader from '../../components/PageManagementHeader/PageManagementHeader';
import routes from '../../constants/routes-constants';
import { BACKUSER_ROLE_CALL_CENTER_OPERATOR } from '../../constants/backend-constants';

const tableViewParams = {
  namespace: 'brands',
  /*sortByIndex: 0,*/
  sortIsDescending: false,
  columns: [
    {
      messageKey: 'brands_tableView_label_brand',
      content: 'name' /*,
      isSortable: true*/
    },
    {
      messageKey: 'brands_tableView_label_action',
      content: null,
      contentMessageKey: 'brands_tableView_content_action',
      actionCustomClass: 'brands_tableView_action',
      actionCallback: function(item) {
        getStore().dispatch(routeActions.push(routes.editBrand.path.replace(':brandId', item.id)));
      }
    }
  ]
};

class Brands extends Component {
  render() {
    const { list, role } = this.props;

    return (
      <div className="brandsPage mainContainer_content">
        <div className="pageContainer">
          <div className="brandsPage_content">
            <section className="brandsPage_management">
              <PageManagementHeader
                titleKey="brands_subtitle"
                href={`#${routes.addBrand.path}`}
                buttonLabelKey="brands_add"
                customClass="brandsPage_managementHeader"
                limitedAccess={role === BACKUSER_ROLE_CALL_CENTER_OPERATOR}
              />

              <div className="brandsPage_list">
                <TableView customClass="brandsPage_tableViewWrapper" params={tableViewParams} content={list} />
              </div>
            </section>
          </div>
        </div>
      </div>
    );
  }
}

Brands.displayName = 'Brands';

Brands.propTypes = {};

export default connect(state => {
  const {
    user: {
      userInfo: { role }
    },
    brands: { list }
  } = state;

  return {
    list,
    role
  };
})(Brands);
