import React, { Component } from 'react';
import { connect } from 'react-redux';
import { change } from 'redux-form';
import { routeActions } from 'react-router-redux';
import { FormattedMessage, defineMessages, injectIntl } from 'react-intl';

import {
  SORT_PROPERTY_LASTNAME,
  SORT_PROPERTY_FIRSTNAME,
  SORT_PROPERTY_EMAIL,
  SORT_PROPERTY_COMPANY_NAME
} from '../../constants/backend-constants';
import routes from '../../constants/routes-constants';

import { getBookingFindMemberAssociatedSites, getMemberDetail, setBookingSelectedMember } from '../../actions/all-actions';
import { enhanceUserInfo } from '../../api/data-enhancer';

import SearchMembers, { withoutFields as searchMembersWithoutFields } from '../SearchMembers/SearchMembers';
import NumberResultsSelectForm from '../NumberResultsSelectForm/NumberResultsSelectForm';
import TableView from '../TableView/TableView';
import PaginationControls from '../PaginationControls/PaginationControls';
import FlashMessageDisplayer from '../../components/FlashMessageDisplayer/FlashMessageDisplayer';
import { apiParams } from '../../constants/api-params-constants';
import { addErrorMessage } from '../../utils/utils';

class BookingFindMember extends Component {
  constructor(props) {
    super(props);

    this.handleSearchForm = this.handleSearchForm.bind(this);
    this.handleFilteredResetSearch = this.handleFilteredResetSearch.bind(this);
    this.handleGotoPage = this.handleGotoPage.bind(this);
    this.handleNumberResultsSelect = this.handleNumberResultsSelect.bind(this);
    this.handleTableViewSorting = this.handleTableViewSorting.bind(this);
    this.tableActionHandler = this.tableActionHandler.bind(this);

    this.tableViewMembersParamsColumns = [
      {
        id: SORT_PROPERTY_LASTNAME,
        messageKey: 'members_tableView_label_lastName',
        content: 'lastName',
        isSortable: true,
        sortActionCallback: this.handleTableViewSorting
      },
      {
        id: SORT_PROPERTY_FIRSTNAME,
        messageKey: 'members_tableView_label_firstName',
        content: 'firstName',
        isSortable: true,
        sortActionCallback: this.handleTableViewSorting
      },
      {
        id: SORT_PROPERTY_EMAIL,
        messageKey: 'members_tableView_label_email',
        content: 'login',
        isSortable: true,
        sortActionCallback: this.handleTableViewSorting
      },
      {
        id: SORT_PROPERTY_COMPANY_NAME,
        messageKey: 'members_tableView_label_companyName',
        content: 'superCompanyName',
        isSortable: false
      },
      {
        messageKey: 'members_tableView_label_action',
        content: null,
        contentMessageKey: 'booking_create_find_member_tableView_action',
        actionCustomClass: 'members_tableView_action',
        actionCallback: this.tableActionHandler
      }
    ];
  }

  componentDidMount() {
    const { editionMode, bookingDetail } = this.props;

    if (editionMode) {
      this.tableActionHandler(bookingDetail.member);
    }
  }

  handleSearchForm(searchParams) {
    const { dispatch } = this.props;

    for (let key in searchParams) {
      if (searchParams[key] === '') {
        delete searchParams[key];
      }
    }

    let newParams = Object.assign({}, apiParams.default, searchParams);

    dispatch(
      routeActions.push(
        `${routes.addBooking.path}/${routes.bookingFindMember.path.replace(':search', encodeURIComponent(JSON.stringify(newParams)))}`
      )
    );
  }

  handleFilteredResetSearch() {
    const { dispatch, listMetadata } = this.props;

    dispatch(change('searchMembers', 'memberFirstName', undefined));
    dispatch(change('searchMembers', 'memberLastName', undefined));
    dispatch(change('searchMembers', 'memberEmail', undefined));

    let newParams = {
      page: {
        number: 1,
        size: listMetadata.paginationInfo.pageSize
      }
    };

    dispatch(
      routeActions.push(
        `${routes.addBooking.path}/${routes.bookingFindMember.path.replace(':search', encodeURIComponent(JSON.stringify(newParams)))}`
      )
    );
  }

  handleGotoPage(value) {
    const { dispatch, listMetadata, urlParams } = this.props;

    let params = {
      page: {
        number: value,
        size: listMetadata.paginationInfo.pageSize
      }
    };

    let newParams = Object.assign(urlParams, params);

    dispatch(
      routeActions.push(
        `${routes.addBooking.path}/${routes.bookingFindMember.path.replace(':search', encodeURIComponent(JSON.stringify(newParams)))}`
      )
    );
  }

  handleNumberResultsSelect(value) {
    const { dispatch, urlParams } = this.props;

    let params = {
      page: {
        number: 1,
        size: +value
      }
    };

    let newParams = Object.assign(urlParams, params);

    dispatch(
      routeActions.push(
        `${routes.addBooking.path}/${routes.bookingFindMember.path.replace(':search', encodeURIComponent(JSON.stringify(newParams)))}`
      )
    );
  }

  handleTableViewSorting(sortById, isDescending) {
    const { dispatch, urlParams } = this.props;

    let params = {
      sort: {
        property: sortById,
        isDescending: isDescending
      }
    };

    let newParams = Object.assign(urlParams, params);

    dispatch(
      routeActions.push(
        `${routes.addBooking.path}/${routes.bookingFindMember.path.replace(':search', encodeURIComponent(JSON.stringify(newParams)))}`
      )
    );
  }

  tableActionHandler(memberFound) {
    const { dispatch } = this.props;

    dispatch(getBookingFindMemberAssociatedSites(memberFound.superCompanyId)).then(sites => {
      dispatch(getMemberDetail(memberFound.id)).then(
        obj => {
          try {
            const member = obj.member;
            member.company.sites = sites;
            dispatch(setBookingSelectedMember(enhanceUserInfo(member)));
            dispatch(routeActions.push(`${routes.addBooking.path}/${routes.bookingFindVehicle.path.replace('(/:search)', '')}`));
          } catch (error) {
            dispatch(addErrorMessage({ error }));
          }
        },
        error => {
          dispatch(addErrorMessage({ error }));
        }
      );
    });
  }

  render() {
    const { list, listMetadata, currentSortedIndex, sortIsDescending, urlParams } = this.props;

    const tableViewMembersParams = {
      namespace: 'findMember',
      sortById: currentSortedIndex,
      sortIsDescending,
      columns: this.tableViewMembersParamsColumns
    };

    return (
      <div className="bookingFindMember">
        <FlashMessageDisplayer />

        <div className="membersFiltersFormWrapper">
          <SearchMembers
            onCallback={this.handleSearchForm}
            onResetCallBack={this.handleFilteredResetSearch}
            fields={searchMembersWithoutFields(['vip', 'validatedDriver', 'technician'])}
            statusDisabled
            initialValues={{
              memberName: urlParams.memberName || '',
              memberEmail: urlParams.memberEmail || ''
            }}
          />
        </div>

        <div className="bookingFindMember_list">
          {listMetadata.paginationInfo.totalResults > 10 && (
            <div className="bookingFindMember_pagination">
              <NumberResultsSelectForm
                onCallback={this.handleNumberResultsSelect}
                customClass="bookingFindMember_numberResultsSelectForm"
                customLabelClass="numberResultsSelectForm_label"
                onNumberResultsSelect={this.handleNumberResultsSelect}
                initialValues={{
                  numberResults: listMetadata.paginationInfo.pageSize
                }}
              />

              <PaginationControls
                paginationInfo={listMetadata.paginationInfo}
                onPageChange={this.handleGotoPage}
                customClass="bookingFindMember_paginationControls"
              />
            </div>
          )}

          {listMetadata.paginationInfo.totalResults > 0 && (
            <TableView customClass="bookingFindMember_tableViewWrapper" params={tableViewMembersParams} content={list} />
          )}

          {listMetadata.paginationInfo.totalResults === 0 && (
            <p className="noResultMessage">
              <FormattedMessage id="booking_create_no_member_found" />
            </p>
          )}
        </div>
      </div>
    );
  }
}

BookingFindMember.displayName = 'BookingFindMember';

export default connect(state => {
  const {
    booking: { editionMode },
    bookingFindMember: { paginatedResults, currentSortedIndex, sortIsDescending, urlParams },
    bookings: { bookingDetail }
  } = state;

  return {
    list: paginatedResults.results,
    listMetadata: paginatedResults.metadata,
    currentSortedIndex,
    sortIsDescending,
    urlParams,
    editionMode,
    bookingDetail
  };
})(injectIntl(BookingFindMember));
