import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import _partial from 'lodash/partial';
import _get from 'lodash/get';
import { routeActions } from 'react-router-redux';
import { injectIntl } from 'react-intl';
import { getVoucherGroups } from '../Voucher.actions';
import AdvancedList from '../../../components/AdvancedList/AdvancedList';
import routes from '../../../constants/routes-constants';
import FlatButton from 'material-ui/FlatButton';
import AddIcon from 'material-ui/svg-icons/content/add';
import { voucherGroupsFilterTypes } from '../../../constants/filterTypes-constants';
import { getAppFormattedDateTime, namedCompose } from '../../../utils/utils';
import autoBind from 'react-autobind';
import { apiParams } from '../../../constants/api-params-constants';
import { clearVoucherGroupsResults } from '../Voucher.actions';
import BlockIcon from 'material-ui/svg-icons/content/block';
import Tooltip from '../../../components/Tooltip/Tooltip';
import { voucherGroupsChips, voucherTypeLabels } from '../../../constants/options-constants';
import FilterChips from '../../../components/FilterChips/FilterChips';
import Filters from '../../../components/AdvancedList/components/Filters';
import { FormattedMessage } from 'react-intl';
import {
  SORT_PROPERTY_CREATION_DATE,
  SORT_PROPERTY_USES,
  SORT_PROPERTY_VOUCHER_GROUP_NAME,
  SORT_PROPERTY_VOUCHER_TYPE
} from '../../../constants/backend-constants';
import { ALL } from '../../../constants/generic-constants';

class VoucherList extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.derivedStateFromProps(props);
    this.colWidth = 3;
    this.voucherHeader = [
      {
        labelKey: 'voucher_group_name',
        md: this.colWidth,
        xs: this.colWidth,
        sortId: SORT_PROPERTY_VOUCHER_GROUP_NAME,
        sortable: true
      },
      {
        labelKey: 'voucher_group_creation_date',
        md: this.colWidth,
        xs: this.colWidth,
        sortId: SORT_PROPERTY_CREATION_DATE,
        sortable: true
      },
      {
        labelKey: 'common_type',
        md: this.colWidth,
        xs: this.colWidth,
        sortId: SORT_PROPERTY_VOUCHER_TYPE,
        sortable: true
      },
      {
        labelKey: 'voucher_uses',
        md: this.colWidth,
        xs: this.colWidth,
        sortId: SORT_PROPERTY_USES,
        sortable: true
      }
    ];
  }

  componentWillMount() {
    this.callApi(this.props.pageParams);
  }

  componentWillReceiveProps(props) {
    this.derivedStateFromProps(props);
  }

  componentWillUnmount() {
    this.props.dispatch(clearVoucherGroupsResults());
  }

  derivedStateFromProps(props) {
    const { intl } = props;
    this.voucherCreateGroupLabel = intl.formatMessage({ id: 'voucher_create_group' });
    this.chooseCompanyText = intl.formatMessage(
      { id: 'common_choose_company_to' },
      { doSomething: this.voucherCreateGroupLabel.toLowerCase() }
    );
    this.noCompanySelected = props.companyId === ALL;
    this.addIcon = this.noCompanySelected ? <BlockIcon /> : <AddIcon />;
  }

  callApi(params) {
    this.props.dispatch(getVoucherGroups(params));
  }

  goToDetail(id) {
    let route = routes.voucherGroupDetail.path.replace(':voucherGroupId', id);
    route = route.replace(':search', encodeURIComponent(JSON.stringify(apiParams.default)));
    this.props.dispatch(routeActions.push(route));
  }

  handleToggleCreateGroupModal() {
    this.props.dispatch(routeActions.push(routes.addVoucherGroup.path));
  }

  handleChangePage(value) {
    const { dispatch, listMetadata, urlParams } = this.props;
    const params = {
      page: {
        number: value || apiParams.default.page.number,
        size: _get(listMetadata, 'paginationInfo.pageSize') || apiParams.default.page.size
      }
    };
    const newParams = { ...urlParams, ...params };
    this.callApi(newParams);
    dispatch(routeActions.push(routes.vouchers.path.replace(':search', encodeURIComponent(JSON.stringify(newParams)))));
  }

  handleRefreshList() {
    this.callApi(this.props.pageParams);
  }

  handleSort(sortPropertyName) {
    const { dispatch, urlParams } = this.props;
    const newParams = {
      ...urlParams,
      sort: {
        isDescending: _get(urlParams, 'sort.property', false) === sortPropertyName ? !_get(urlParams, 'sort.isDescending', false) : false,
        property: sortPropertyName
      }
    };
    this.callApi(newParams);
    dispatch(routeActions.push(routes.vouchers.path.replace(':search', encodeURIComponent(JSON.stringify(newParams)))));
  }

  handleNumberResultsSelect(value) {
    const { dispatch, urlParams } = this.props;
    let params = {
      page: {
        number: 1,
        size: parseInt(value)
      }
    };
    let newParams = { ...urlParams, ...params };
    this.callApi(newParams);
    dispatch(routeActions.push(routes.vouchers.path.replace(':search', encodeURIComponent(JSON.stringify(newParams)))));
  }

  handleDeleteChip(item) {
    const { urlParams, dispatch, listMetadata } = this.props;

    delete urlParams[item];
    delete urlParams.page;

    const newParams = {
      ...urlParams,
      page: {
        number: 1,
        size: listMetadata.paginationInfo.pageSize
      }
    };

    this.callApi(newParams);
    dispatch(routeActions.push(routes.vouchers.path.replace(':search', encodeURIComponent(JSON.stringify(newParams)))));
  }

  handleFilterSearch(newSelectedFilters) {
    const { dispatch, listMetadata, urlParams } = this.props;

    const newParams = {
      ...urlParams,
      ...newSelectedFilters,
      page: {
        number: 1,
        size: listMetadata.paginationInfo.pageSize
      }
    };

    this.callApi(newParams);
    dispatch(routeActions.push(routes.vouchers.path.replace(':search', encodeURIComponent(JSON.stringify(newParams)))));
  }

  renderItem(item, id, reactKey) {
    const colProps = { xs: this.colWidth, md: this.colWidth };
    const usageNumber = _get(item, 'uses', 0);
    const type = _get(item, 'type');
    const typeLabel = _get(voucherTypeLabels, type);

    return (
      <Row className="advancedList_row" onClick={_partial(this.goToDetail, item.id)} id={id} key={reactKey}>
        <Col {...colProps}>{_get(item, 'name')}</Col>
        <Col {...colProps}>{getAppFormattedDateTime(_get(item, 'voucherGroupCreationDate'), { local: true })}</Col>
        <Col {...colProps}>
          <FormattedMessage id={typeLabel || 'common_unknown'} />
        </Col>
        <Col {...colProps}>{usageNumber}</Col>
      </Row>
    );
  }

  render() {
    const { urlParams } = this.props;
    return (
      <div className="pageContainer">
        <div className="voucherList">
          <FilterChips
            id="voucherGroupsChips"
            urlParams={urlParams}
            onDeleteChip={this.handleDeleteChip}
            translations={voucherGroupsChips}
            filterTypes={voucherGroupsFilterTypes}
          />
          <div className="advancedList_actions page">
            <Filters id="voucherGroupsFilters" filterTypes={voucherGroupsFilterTypes} onFilteredSearchForm={this.handleFilterSearch} />
            <div className="voucherList_actionsButtons">
              <Tooltip placement="top" distance={5} content={this.chooseCompanyText} disabled={!this.noCompanySelected}>
                <FlatButton
                  id="vouchers_create_group_button"
                  onClick={this.handleToggleCreateGroupModal}
                  label={this.voucherCreateGroupLabel}
                  icon={this.addIcon}
                  disabled={this.noCompanySelected}
                />
              </Tooltip>
            </div>
          </div>
          <AdvancedList
            id="voucher_groups"
            data={this.props.list}
            listMetadata={this.props.listMetadata}
            loading={this.props.loadingList}
            onChangePage={this.handleChangePage}
            renderItem={this.renderItem}
            error={this.props.errorGroups}
            noResultsLabelKey="voucher_groups_no_results"
            onRefreshList={this.handleRefreshList}
            onChangeRowsPerPage={this.handleNumberResultsSelect}
            header={this.voucherHeader}
            urlParams={urlParams}
            onSort={this.handleSort}
          />
        </div>
      </div>
    );
  }
}

export default namedCompose(
  connect(state => {
    const vouchers = _get(state, 'vouchers');
    const paginatedResults = _get(vouchers, 'paginatedResults');
    const page = _get(state, 'page');
    const pageInfo = _get(paginatedResults, 'pageInfo');
    const listMetadata = pageInfo ? { paginationInfo: pageInfo } : {};

    return {
      companyId: _get(state, 'companies.currentCompany.id'),
      list: _get(paginatedResults, 'results', []),
      pageParams: _get(page, 'params', {}),
      urlParams: _get(vouchers, 'urlParams', {}),
      loadingList: _get(vouchers, 'loadingList'),
      errorGroups: _get(vouchers, 'errorGroups', false),
      listMetadata
    };
  }),
  injectIntl
)(VoucherList);
