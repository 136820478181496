import {
  SMARTCARDS_FILTERS_FORM_OPENED_STATE_TOGGLE,
  SMARTCARDS_GET_LIST_SUCCESS,
  SMARTCARDS_GET_DETAIL_REQUEST,
  SMARTCARDS_GET_DETAIL_SUCCESS,
  SMARTCARDS_REMOVE_USER_REQUEST,
  SMARTCARDS_REMOVE_USER_SUCCESS,
  SMARTCARDS_CHANGE_STATUS_SUCCESS,
  SMARTCARD_USER_ASSIGN_SUCCESS,
  SMARTCARD_USER_ASSIGN_ERROR,
  SMARTCARDS_GET_LIST_REQUEST,
  SMARTCARDS_EMPTY_LIST,
  SMARTCARDS_EMPTY_DETAIL
} from '../constants/actionTypes-constants';

import callApi from './api-actions';
import { pickTruthy, selectCompanyId, selectSubCompanyId, append, trySet, addErrorMessage } from '../utils/utils';
import { apiParams } from '../constants/api-params-constants';

export function getSmartcardsList(params) {
  return (dispatch, getState) => {
    const state = getState();

    let paramsObj = null;

    if (params) {
      let pageNumber = params.page ? params.page.number : state.smartcards.paginatedResults.metadata.paginationInfo.currentPage;
      let pageSize = params.page ? params.page.size : state.smartcards.paginatedResults.metadata.paginationInfo.pageSize;

      paramsObj = {
        page: {
          number: pageNumber,
          size: pageSize
        }
      };

      paramsObj = Object.assign(paramsObj, pickTruthy(params, 'userLogin'));
    }

    trySet(paramsObj, 'companyIds', append([selectCompanyId(state)]));
    trySet(paramsObj, 'subCompanyIds', append([selectSubCompanyId(state)]));

    dispatch({
      type: SMARTCARDS_GET_LIST_REQUEST
    });

    return dispatch(callApi('smartcards', 'getList', paramsObj, false)).then(data => {
      dispatch({
        type: SMARTCARDS_GET_LIST_SUCCESS,
        paginatedObj: data,
        urlParams: paramsObj
      });
      return data;
    });
  };
}

export function toggleSmartcardsFilters() {
  return {
    type: SMARTCARDS_FILTERS_FORM_OPENED_STATE_TOGGLE
  };
}

export function getSmartcardsDetail(id) {
  return dispatch => {
    dispatch({
      type: SMARTCARDS_GET_DETAIL_REQUEST
    });

    return dispatch(callApi('smartcards', 'getDetail', id, false)).then(function(data) {
      dispatch({
        type: SMARTCARDS_GET_DETAIL_SUCCESS,
        smartcard: data
      });
      return data;
    });
  };
}

export function getDetailWithCardId(id) {
  return dispatch => {
    dispatch({
      type: SMARTCARDS_GET_DETAIL_REQUEST
    });

    return dispatch(callApi('smartcards', 'getDetailWithId', id, false)).then(data => {
      dispatch({
        type: SMARTCARDS_GET_DETAIL_SUCCESS,
        smartcard: data
      });
      return data;
    });
  };
}

export function removeUserFromSmartcard(smartcardId) {
  return dispatch => {
    dispatch({
      type: SMARTCARDS_REMOVE_USER_REQUEST
    });

    return dispatch(callApi('smartcards', 'removeUser', smartcardId))
      .then(function(data) {
        dispatch({
          type: SMARTCARDS_REMOVE_USER_SUCCESS,
          smartcard: data
        });
        return data;
      })
      .catch(error => {
        dispatch(addErrorMessage({ error }));
        return Promise.reject();
      });
  };
}

export function changeSmartcardStatus(data) {
  return dispatch => {
    return dispatch(callApi('smartcards', 'changeStatus', data)).then(function(data) {
      dispatch({
        type: SMARTCARDS_CHANGE_STATUS_SUCCESS,
        smartcard: data
      });
      return data;
    });
  };
}

export function assignSmartCardUser(data) {
  return dispatch => {
    return dispatch(callApi('smartcards', 'assignUser', data))
      .then(function(data) {
        dispatch({ type: SMARTCARD_USER_ASSIGN_SUCCESS, smartcard: data });
        return data;
      })
      .catch(error => {
        dispatch(addErrorMessage({ error }));
        return Promise.reject();
      });
  };
}

export function errorUserSelectedToPairSmartcard() {
  return {
    type: SMARTCARD_USER_ASSIGN_ERROR
  };
}

export function clearSmartcardsListData() {
  return {
    type: SMARTCARDS_EMPTY_LIST
  };
}

export function clearSmartcardDetail() {
  return {
    type: SMARTCARDS_EMPTY_DETAIL
  };
}

export function createSmartcard(companyId, userId, cardId, protocol) {
  return dispatch => {
    const params = {
      activationDate: new Date(),
      companyId,
      userId,
      cardId,
      protocol
    };

    return dispatch(callApi('smartcards', 'createSmartcard', params))
      .then(function(data) {
        return data;
      })
      .catch(error => {
        addErrorMessage({ error });
        return Promise.reject();
      });
  };
}

const handleSearchResp = resp => {
  return resp.then(
    data => data,
    error => {
      addErrorMessage({ error });
      return Promise.reject();
    }
  );
};

export function smartcardsSearchMember(params) {
  return dispatch => {
    params = {
      ...apiParams.smartcardsUserSearch,
      markedForAnonymization: false,
      ...params
    };

    return handleSearchResp(dispatch(callApi('members', 'search', params, false)));
  };
}

export function smartcardsSearchBackUser(params) {
  return dispatch => {
    params = {
      ...apiParams.smartcardsUserSearch,
      ...params
    };

    return handleSearchResp(dispatch(callApi('backUsers', 'search', params, false)));
  };
}
