import {
  SMARTCARDS_EVENTS_FILTERS_FORM_OPENED_STATE_TOGGLE,
  SMARTCARDS_EVENTS_SET_CURRENT_SORTED_INDEX,
  SMARTCARDS_EVENTS_SET_SORT_DIRECTION,
  SMARTCARDS_EVENTS_GET_LIST_SUCCESS,
  SMARTCARDS_EVENTS_GET_LIST_REQUEST,
  SMARTCARDS_EVENT_USER_ASSIGN_ERROR
} from '../constants/actionTypes-constants';
import {
  BACKUSER_ROLE_ADMIN,
  BACKUSER_ROLE_CALL_CENTER_OPERATOR,
  BACKUSER_ROLE_FLEET_MANAGER,
  BACKUSER_ROLE_SUPER_ADMIN,
  BACKUSER_ROLE_ROOT
} from '../constants/backend-constants';
import callApi from './api-actions';
import _get from 'lodash/get';
import { formatCustomDate } from '../utils/utils';
import { ALL } from '../constants/generic-constants';

export function getSmartcardsEventsList(params) {
  return (dispatch, getState) => {
    const state = getState(),
      _userInfo = state.user.userInfo,
      _currentCompany = state.companies.currentCompany,
      _currentSubCompany = state.subCompanies.currentSubCompany,
      _subCompanySelected = state.subCompanies.subCompanySelected;

    let paramsObj = null;

    if (params) {
      let pageNumber = params.page ? params.page.number : state.smartcardsEvents.paginatedResults.metadata.paginationInfo.currentPage;
      let pageSize = params.page ? params.page.size : state.smartcardsEvents.metadata.paginationInfo.pageSize;

      if (params.startDate !== undefined) {
        params.startDate = formatCustomDate(params.startDate, 'YYYY-MM-DDTHH:MM:SS');
      }
      if (params.endDate !== undefined) {
        params.endDate = formatCustomDate(params.endDate, 'YYYY-MM-DDTHH:MM:SS');
      }

      paramsObj = {
        page: {
          number: pageNumber,
          size: pageSize
        },
        ...params
      };
    }

    if (
      _userInfo.role === BACKUSER_ROLE_ADMIN ||
      (_userInfo.role === BACKUSER_ROLE_CALL_CENTER_OPERATOR && _get(_userInfo, 'companies', false))
    ) {
      paramsObj.vehicleCompanyIds = _userInfo.companies.map(company => company.id);
      if (_currentCompany.id !== ALL) {
        paramsObj.vehicleCompanyIds = [_currentCompany.id];
      }
      if (_subCompanySelected && _subCompanySelected.hasOwnProperty('id')) {
        paramsObj.vehicleSubCompanyIds = [_subCompanySelected.id];
      }
    }

    if (_userInfo.role === BACKUSER_ROLE_FLEET_MANAGER && _get(_userInfo, 'subCompanies', false)) {
      paramsObj.vehicleSubCompanyIds = _userInfo.subCompanies.map(subCompany => subCompany.id);
      if (_get(_subCompanySelected, 'id', ALL) !== ALL) {
        paramsObj.vehicleSubCompanyIds = [_subCompanySelected.id];
      }
    }

    if (_userInfo.role === BACKUSER_ROLE_SUPER_ADMIN || _userInfo.role === BACKUSER_ROLE_ROOT) {
      if (_currentCompany.id !== ALL) {
        paramsObj.vehicleCompanyIds = [_currentCompany.id];
        if (_get(_subCompanySelected, 'id', ALL) !== ALL) {
          paramsObj.vehicleSubCompanyIds = [_subCompanySelected.id];
        }
      } else {
        paramsObj.vehicleCompanyIds = [];
      }
    }
    if (_currentCompany.id === ALL || _get(_subCompanySelected, 'id', ALL) === ALL) delete paramsObj.vehicleSubCompanyIds;

    dispatch({
      type: SMARTCARDS_EVENTS_GET_LIST_REQUEST
    });

    return dispatch(callApi('smartcardsEvents', 'getList', paramsObj, false)).then(data => {
      dispatch({
        type: SMARTCARDS_EVENTS_GET_LIST_SUCCESS,
        paginatedObj: data,
        urlParams: paramsObj
      });
      return data;
    });
  };
}

export function errorUserSelectedToEventSmartcard() {
  return {
    type: SMARTCARDS_EVENT_USER_ASSIGN_ERROR
  };
}

export function setSmartcardsEventsCurrentSortedIndex(index) {
  return {
    type: SMARTCARDS_EVENTS_SET_CURRENT_SORTED_INDEX,
    index
  };
}

export function setSmartcardsEventsSortIsDescending(isDescending) {
  return {
    type: SMARTCARDS_EVENTS_SET_SORT_DIRECTION,
    isDescending
  };
}

export function toggleSmartcardsEventsFilters() {
  return {
    type: SMARTCARDS_EVENTS_FILTERS_FORM_OPENED_STATE_TOGGLE
  };
}
