// noinspection ES6CheckImport
import React, { PropTypes } from 'react';
import { FormattedMessage } from 'react-intl';
import _get from 'lodash/get';
import _map from 'lodash/map';
import _has from 'lodash/has';
import FlatButton from 'material-ui/FlatButton';

import FileUploadIcon from 'material-ui/svg-icons/file/file-upload';
import FileImage from '../FileImage/FileImage.jsx';
import { iconStyles, MemberBasicInfoStyles } from '../../constants/style-constants';
import { fillReactArray, getAppFormattedDate } from '../../utils/utils';
import { BACKUSER_ROLE_FLEET_MANAGER, STATUS_APPROVED, STATUS_NOT_APPROVED } from '../../constants/backend-constants';
import IsGranted from '../IsGranted/IsGranted';
import Tooltip from '../Tooltip/Tooltip';
import { memberDetailEditRules } from '../../constants/routes-constants';

const MemberIdCardInfo = props => {
  const { detailMember, identityDocumentFiles, handleApproveInfo, handleRejectInfo, enabledActions } = props;
  const { anonymizationEffectiveDate } = detailMember || {};

  return (
    <div className="memberView_idCardInfo">
      <h4>
        <FormattedMessage id="members_detail_ID_subtitle" />
        {_get(detailMember, 'identityDocument.status') && (
          <span>
            <span className="detailView_dash">-</span>
            <span className="detailView_component_headline_status">
              <Tooltip content={<FormattedMessage id="members_detail_driver_documentValidationStatus" />} placement="bottom">
                <span className={`member_status--${detailMember.identityDocument.status.toLowerCase()}`}>
                  <FormattedMessage id={`members_status_${detailMember.identityDocument.status.toLowerCase()}`} />
                </span>
              </Tooltip>
            </span>
          </span>
        )}
        {_get(detailMember, 'identityDocument.status') && _get(detailMember, 'identityDocument.lastStatusUpdate') && (
          <Tooltip content={<FormattedMessage id="members_detail_driver_approvalRejectionDate" />} placement="bottom">
            <span className="detailView_component_headline_date">
              {getAppFormattedDate(detailMember.identityDocument.lastStatusUpdate)}
            </span>
          </Tooltip>
        )}
      </h4>
      <div className="memberView_idCardInfo_body">
        <div className="memberView_idCardInfo_main">
          <div className="detailView_table">
            {_has(detailMember, 'identityDocument.uploadDate') && (
              <div className="detailView_row">
                <div className="detailView_cell_type_1">
                  <Tooltip content={<FormattedMessage id="members_detail_driver_uploadDate" />} placement="bottom">
                    <FileUploadIcon style={iconStyles.all} noPadding />
                  </Tooltip>
                </div>
                <div className="detailView_cell_type_1">{getAppFormattedDate(detailMember.identityDocument.uploadDate)}</div>
              </div>
            )}
          </div>
          {_get(detailMember, 'identityDocument.files.length') > 0 && (
            <div className={`memberView_idCardInfo_files_${detailMember.identityDocument.files.length}`}>
              {_map(identityDocumentFiles, (item, index) => {
                return <FileImage key={index} imageObject={item} />;
              })}
              {fillReactArray(<FileImage />, detailMember.identityDocument.files.length - _get(identityDocumentFiles, 'length', 0))}
            </div>
          )}
        </div>
        {enabledActions && (
          <IsGranted disallowed={[BACKUSER_ROLE_FLEET_MANAGER, ...memberDetailEditRules.exclude]} allowed={memberDetailEditRules.include}>
            <div className="memberView_reviewButtonsContainer">
              {_get(detailMember, 'identityDocument.status') !== STATUS_NOT_APPROVED && !anonymizationEffectiveDate && (
                <FlatButton
                  id="memberView_idCardInfo_reject"
                  label={<FormattedMessage id="common_reject" />}
                  onClick={handleRejectInfo}
                  style={MemberBasicInfoStyles.buttons.flat}
                />
              )}
              {_get(detailMember, 'identityDocument.status') !== STATUS_APPROVED && !anonymizationEffectiveDate && (
                <FlatButton
                  id="memberView_idCardInfo_approve"
                  label={<FormattedMessage id="common_approve" />}
                  onClick={handleApproveInfo}
                  style={MemberBasicInfoStyles.buttons.flat}
                />
              )}
            </div>
          </IsGranted>
        )}
      </div>
    </div>
  );
};

MemberIdCardInfo.displayName = 'MemberIdCardInfo';

MemberIdCardInfo.defaultProps = {
  identityDocumentFiles: []
};

MemberIdCardInfo.propTypes = {
  detailMember: PropTypes.object.isRequired,
  anonymizationEffectiveDate: PropTypes.any,
  identityDocumentFiles: PropTypes.array,
  handleApproveInfo: PropTypes.func,
  handleRejectInfo: PropTypes.func
};

export default MemberIdCardInfo;
