import React, { Component } from 'react';
import ColumnList from '../../../components/_v2/ColumnList';
import { connect } from 'react-redux';

import { createMenuItems, resolveActive, resolveSelected, showNextView } from '../../../components/_v2/ColumnList/helpers';
import { FormattedMessage } from 'react-intl';
import { renderItem } from './helpers';

class HotlinesList extends Component {
  componentWillMount() {
    this.setCallbacks();
    this.setVars();
  }

  setVars() {
    this.menuItems = createMenuItems({
      onAdd: this.handleAdd,
      addKey: 'hotlines_add_button'
    });
  }

  setCallbacks() {
    this.handleEdit = ({ id }, _, { openInNewTab }) => {
      showNextView.call(this, 'hotlineEdit', ':hotlineId', id, openInNewTab);
    };

    this.handleAdd = () => {
      showNextView.call(this, 'hotlineAdd');
    };

    this.resolveSelected = resolveSelected.bind(this);
    this.resolveActive = resolveActive.bind(this);
  }

  render() {
    return (
      <ColumnList
        title={<FormattedMessage id="hotlines-list" />}
        inputRef={this.props.inputRef}
        items={this.props.list}
        renderItem={renderItem}
        onSelect={this.handleEdit}
        selected={this.resolveSelected}
        active={this.resolveActive}
        menuItems={this.menuItems}
        localSearchEnabled
      />
    );
  }
}

const mapStateToProps = state => {
  const { list } = state.hotlines;
  return { list };
};

HotlinesList = connect(mapStateToProps)(HotlinesList);

export default HotlinesList;
