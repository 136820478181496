import { Component, PropTypes } from 'react';
import { connect } from 'react-redux';
import _includes from 'lodash/includes';

import { backuserAllRoles } from '../../constants/backuser-role-rules';

class IsGranted extends Component {
  render() {
    const { allowed, disallowed, role, children } = this.props;

    if (role && children) {
      if (_includes(allowed, role) && !_includes(disallowed, role)) return children;
    }

    return null;
  }
}

IsGranted.displayName = 'IsGranted';

IsGranted.defaultProps = {
  allowed: backuserAllRoles,
  disallowed: []
};

IsGranted.propTypes = {
  allowed: PropTypes.arrayOf(PropTypes.oneOf(backuserAllRoles)).isRequired,
  disallowed: PropTypes.arrayOf(PropTypes.oneOf(backuserAllRoles)),
  children: PropTypes.any
};

export default connect(state => {
  const {
    user: { userInfo }
  } = state;

  return {
    role: userInfo.role
  };
})(IsGranted);
