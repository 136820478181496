import * as types from '../../constants/actionTypes-constants';

export function openHeaderProfileMenu() {
  return {
    type: types.HEADER_PROFILE_MENU_OPEN
  };
}

export function closeHeaderProfileMenu() {
  return {
    type: types.HEADER_PROFILE_MENU_CLOSE
  };
}

export function toggleHeaderProfileMenu() {
  return {
    type: types.HEADER_PROFILE_MENU_TOGGLE
  };
}

export function closeHeaderLanguageMenu() {
  return {
    type: types.HEADER_LANGUAGE_MENU_CLOSE
  };
}

export function toggleHeaderLanguageMenu() {
  return {
    type: types.HEADER_LANGUAGE_MENU_TOGGLE
  };
}

export function toggleSuperCompanyAutoComplete() {
  return {
    type: types.HEADER_SUPER_COMPANY_TOGGLE
  };
}

export function toggleCompanyAutoComplete() {
  return {
    type: types.HEADER_COMPANY_TOGGLE
  };
}

export function changeSearchInputText(text) {
  return {
    type: types.HEADER_CHANGE_SEARCH_INPUT_TEXT,
    searchText: text
  };
}

export function searchMode(bool) {
  return {
    type: types.HEADER_SEARCH_MODE,
    searchMode: bool
  };
}

export function superCompanyInputChange(text) {
  return {
    type: types.HEADER_CHANGE_SUPERCOMPANY_INPUT_TEXT,
    text
  };
}

export function companyInputChange(text) {
  return {
    type: types.HEADER_CHANGE_COMPANY_INPUT_TEXT,
    text
  };
}
