import React, { Component, PropTypes } from 'react';
import { reduxForm } from 'redux-form';
import { FormattedMessage } from 'react-intl';
import { routeActions } from 'react-router-redux';

import BaseTitle from '../../components/BaseTitle/BaseTitle';
import BoxedInput from '../../components/BoxedInput/BoxedInput';
import EkButton from '../../components/EkButton/EkButton';
import FieldErrorMsg from '../../components/FieldErrorMsg/FieldErrorMsg';
import routes from '../../constants/routes-constants';
import { createValidator, notEmpty, password, length, match } from '../../validation/all-validation';
import { callResetPasswordApplyRequest, addFlashMessage } from '../../actions/all-actions';
import { FLASH_MESSAGE_TYPE_SUCCESS, PASSWORD_CONFIRM_FIELD_NAME, PASSWORD_FIELD_NAME } from '../../constants/generic-constants';
import { scrollToFirstError } from '../../utils/utils';

class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit() {
    const { dispatch } = this.props;
    dispatch(callResetPasswordApplyRequest())
      .then(() => {
        dispatch(
          addFlashMessage({
            contentKey: 'resetPassword_update_success',
            type: FLASH_MESSAGE_TYPE_SUCCESS,
            delayToNextRoute: true
          })
        );
        dispatch(routeActions.push(routes.login.path));
      })
      .catch(error => {
        if (!error.type) {
          // make sure native errors not swallowed
          console.error(error.stack); // eslint-disable-line
        }
      });
  }

  render() {
    const {
      fields: { password, confirmationPassword },
      handleSubmit
    } = this.props;

    return (
      <div className="resetPasswordPage">
        <form className="resetPassword" action="#" onSubmit={handleSubmit(this.handleSubmit)} noValidate>
          <BaseTitle customClass="baseTitle--variant1" title="resetPassword_new_title" />

          <BoxedInput
            formRowItemKey="resetPassword_password"
            type="password"
            id="password"
            placeholder="New password"
            labelKey="resetPassword_new_label_password"
            customClass="resetPassword_boxedInput"
            actionText="loginForm_password_actionText"
            field={password}
            mandatory
          >
            <FieldErrorMsg field={password} customClass="fieldErrorMsg--profileForm" />
          </BoxedInput>

          <BoxedInput
            formRowItemKey="resetPassword_confirmPassword"
            type="password"
            id="confirmPassword"
            placeholder="Confirm password"
            labelKey="resetPassword_new_label_confirm"
            customClass="resetPassword_boxedInput"
            actionText="loginForm_password_actionText"
            field={confirmationPassword}
            mandatory
          >
            <FieldErrorMsg field={confirmationPassword} customClass="fieldErrorMsg--profileForm" />
          </BoxedInput>

          <EkButton
            formRowItemKey="resetPassword_submitBtn"
            type="submit"
            customClass="resetPassword_actionsButton _is_alone"
            onAction={handleSubmit(this.handleSubmit)}
          >
            <FormattedMessage id="account_profile_save" />
          </EkButton>
        </form>
      </div>
    );
  }
}

ResetPassword.displayName = 'ResetPassword';

ResetPassword.propTypes = {
  handleSubmit: PropTypes.func
};

ResetPassword = reduxForm(
  {
    onSubmitFail: scrollToFirstError,
    form: 'resetPassword',
    fields: [PASSWORD_FIELD_NAME, PASSWORD_CONFIRM_FIELD_NAME],
    validate: createValidator({
      password: [notEmpty(), password(), length({ min: 8, max: 25 })],
      confirmationPassword: [notEmpty(), password(), length({ min: 8, max: 25 }), match('password')]
    })
  },
  state => {
    // mapStateToProps
    let initialValues = {
      password: '',
      confirmationPassword: ''
    };

    return {
      initialValues
    };
  }
)(ResetPassword);

export default ResetPassword;
