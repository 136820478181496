import React, { Component } from 'react';
import autoBind from 'react-autobind';
import pure from 'recompose/pure';
import { isEmpty, namedCompose, newProps } from '../../utils/utils';
import { FormattedMessage, injectIntl } from 'react-intl';
import { BOOKING_TYPE_CAR_SHARING } from '../../constants/backend-constants';
import { connect } from 'react-redux';
import _isEmpty from 'lodash/isEmpty';
import _reduce from 'lodash/reduce';
import _get from 'lodash/get';
import _find from 'lodash/find';
import { localeSelector } from '../../selectors/all-selectors';
import shortid from 'shortid';
import None from '../None';

class BookingMoreInfo extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.derivedStateFromProps(props, true);
  }

  // eslint-disable-next-line no-unused-vars
  componentWillReceiveProps(nextProps, nextContext) {
    this.derivedStateFromProps(nextProps);
  }

  derivedStateFromProps(nextProps, init) {
    if (!this.checkDisplayStatus(nextProps)) return false;

    const { bookingStatus, bookingType, bookingUsageType, bookingCustomValues, locale } = nextProps;
    const { formatMessage } = nextProps.intl;

    if (newProps(nextProps, this.props, init, ['bookingStatus', 'bookingType', 'bookingUsageType', 'locale'])) {
      this.bookingStatusClass = 'booking_status_' + String(bookingStatus).toLowerCase();
      this.bookingStatus = formatMessage({
        id: 'common_booking_status_' + bookingStatus,
        defaultMessage: formatMessage({ id: 'common_unknown' })
      });
      this.bookingType = formatMessage({
        id:
          bookingType === BOOKING_TYPE_CAR_SHARING
            ? `booking_detail_type_${bookingType}_${bookingUsageType}`
            : `booking_detail_type_${bookingType}`,
        defaultMessage: formatMessage({ id: 'common_unknown' })
      });
    }

    if (newProps(nextProps, this.props, init, ['bookingCustomValues', 'locale'])) {
      this.customFields = _reduce(
        bookingCustomValues,
        (result, field) => {
          const value = _get(field, 'value');
          const companyCustomField = _get(field, 'companyCustomField');
          const companyCustomFieldLabels = _get(companyCustomField, 'companyCustomFieldLabels');
          const fieldName = _get(companyCustomField, 'name');

          if (!isEmpty(value)) {
            const labelObject = _find(companyCustomFieldLabels, label => _get(label, 'language', -1) === locale);
            const label = _get(labelObject, 'label') || fieldName || formatMessage({ id: 'common_unknown' });
            result.push(this.getCustomField(label, value, shortid.generate()));
          }
          return result;
        },
        []
      );
    }
  }

  checkDisplayStatus(nextProps) {
    const { bookingStatus, bookingType, bookingCustomValues } = nextProps;
    this.componentCanRender = !(!bookingType && !bookingStatus && _isEmpty(bookingCustomValues));
    return this.componentCanRender;
  }

  getCustomField(label, value, key) {
    return (
      <tr className="detailView_tr" key={key}>
        <td className="detailView_td_type_2">
          <span className="detailView_cell_type_1">{label}</span>
        </td>
        <td className="detailView_td_type_2">
          <span className="detailView_cell_type_1">{value}</span>
        </td>
      </tr>
    );
  }

  displayBookingStatus() {
    const { bookingStatus } = this.props;
    if (bookingStatus)
      return (
        <tr className="detailView_tr">
          <td className="detailView_td_type_2">
            <FormattedMessage id="common_booking_status" />
          </td>
          <td className="detailView_td_type_2">
            <span className={this.bookingStatusClass}>
              <span>{this.bookingStatus}</span>
            </span>
          </td>
        </tr>
      );
  }

  displayBookingType() {
    const { bookingType } = this.props;
    if (bookingType)
      return (
        <tr className="detailView_tr">
          <td className="detailView_td_type_2">
            <span className="detailView_cell_type_1">
              <FormattedMessage id="booking_detail_type" />
            </span>
          </td>
          <td className="detailView_td_type_2">
            <span className="detailView_cell_type_1">
              <span>{this.bookingType}</span>
            </span>
          </td>
        </tr>
      );
  }

  render() {
    if (this.componentCanRender)
      return (
        <div className="bookingMoreInfo" style={this.bodyStyle}>
          <h4 className="bookingMoreInfo_headline">
            <FormattedMessage id="booking_more_info_title" />
          </h4>
          <div className="bookingMoreInfo_body">
            <table>
              <tbody>
                {this.displayBookingStatus()}
                {this.displayBookingType()}
                {this.customFields}
              </tbody>
            </table>
          </div>
        </div>
      );
    else return <None />;
  }
}

BookingMoreInfo.defaultProps = {
  bookingStatus: undefined, // String
  bookingType: undefined, // String
  bookingUsageType: undefined, // String
  bookingCustomValues: []
};

export default namedCompose(
  connect(state => {
    return {
      locale: localeSelector(state)
    };
  }),
  injectIntl,
  pure
)(BookingMoreInfo);
