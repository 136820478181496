import 'babel-polyfill';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import initializeStore from './store/all-store';
import Root from './containers/Root';
import { getAppObj, updateMobileViewTag } from './utils/utils';
import Flash from './utils/flashMessage/selector';
import FlashMessage from './utils/flashMessage/lib';
import { fetchUserCountry } from './utils/api';

const store = initializeStore();

function initGoogleMaps() {
  const apiKey = 'AIzaSyCVyfs-uSw_HOF7kaTMOOGqP5IwaXJF9UI';
  const mapsUrl = 'https://maps.googleapis.com/maps/api/js?callback=Function.prototype&libraries=places&key=';
  const googleMapsScript = document.createElement('script');

  googleMapsScript.setAttribute('src', mapsUrl + apiKey);
  document.head.appendChild(googleMapsScript);
}

function setFlashMessage() {
  getAppObj().Flash = Flash;
  getAppObj().FlashMessage = FlashMessage;
}

function setUserCountry() {
  getAppObj().userCountry = fetchUserCountry().then(country => {
    if (country) {
      getAppObj().userCountry = country;
    }
  });
}

initGoogleMaps();
updateMobileViewTag();
setFlashMessage();
setUserCountry();

ReactDOM.render(
  <Provider store={store}>
    <Root />
  </Provider>,
  document.getElementById('mainContainer')
);
