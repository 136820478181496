import React, { Component } from 'react';
import { connect } from 'react-redux';
import { routeActions } from 'react-router-redux';

import ConfigurationForm from '../../components/ConfigurationForm/ConfigurationForm';
import { updateConfigurationRequest, updateConfigurationSuccess, addFlashMessage } from '../../actions/all-actions';
import { FLASH_MESSAGE_TYPE_SUCCESS, FLASH_MESSAGE_TYPE_ERROR } from '../../constants/generic-constants';
import routes from '../../constants/routes-constants';
import { BACKUSER_ROLE_CALL_CENTER_OPERATOR } from '../../constants/backend-constants';
import { apiParams } from '../../constants/api-params-constants';

class EditConfiguration extends Component {
  constructor() {
    super();
    this.handleUpdateConfiguration = this.handleUpdateConfiguration.bind(this);
  }

  handleUpdateConfiguration() {
    const { dispatch, detailConfiguration, urlParams } = this.props;

    dispatch(updateConfigurationRequest(detailConfiguration))
      .then(configuration => {
        dispatch(updateConfigurationSuccess(configuration));
        dispatch(
          addFlashMessage({
            contentKey: 'configurationForm_update_configuration_success',
            type: FLASH_MESSAGE_TYPE_SUCCESS,
            delayToNextRoute: true
          })
        );
        let params = (urlParams && urlParams.page) || apiParams.default;
        dispatch(routeActions.push(routes.configurations.path.replace(':search', encodeURIComponent(JSON.stringify(params)))));
      })
      .catch(() => {
        dispatch(
          addFlashMessage({
            contentKey: 'configurationForm_update_configuration_error',
            type: FLASH_MESSAGE_TYPE_ERROR
          })
        );
      });
  }

  render() {
    const { detailConfiguration, role } = this.props;

    return (
      <div className="editConfigurationPage mainContainer_content">
        <div className="pageContainer">
          <div className="editConfigurationPage_content">
            <section className="editConfigurationPage_management">
              <ConfigurationForm
                onCallback={this.handleUpdateConfiguration}
                limitedAccess={role === BACKUSER_ROLE_CALL_CENTER_OPERATOR}
                initialValues={{
                  name: detailConfiguration.name || '',
                  hotlineId: (detailConfiguration.hotline && detailConfiguration.hotline.id) || '',
                  parentConfigurationId: (detailConfiguration.parent && detailConfiguration.parent.id) || ''
                }}
              />
            </section>
          </div>
        </div>
      </div>
    );
  }
}

EditConfiguration.displayName = 'EditConfiguration';

EditConfiguration.propTypes = {};

export default connect(state => {
  const {
    user: {
      userInfo: { role }
    },
    configuration: { detailConfiguration, urlParams }
  } = state;

  return {
    detailConfiguration,
    role,
    urlParams
  };
})(EditConfiguration);
