import React, { Component, PropTypes } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Row, Col } from 'react-bootstrap';
import AdvancedList from '../../components/AdvancedList/AdvancedList';
import { parkingsHeaderParams } from '../../constants/table-constants';
import { getList, clearParkingsList } from '../../actions/all-actions';
import { injectIntl } from 'react-intl';
import routes from '../../constants/routes-constants';
import EditIcon from 'material-ui/svg-icons/image/edit';

import { namedCompose } from '../../utils/utils';
import autoBind from 'react-autobind';

class Parkings extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
  }

  componentWillMount() {
    const { pageParams } = this.props;
    this.callApi(pageParams);
  }

  componentWillUnmount() {
    this.props.dispatch(clearParkingsList());
  }

  callApi(params) {
    const { dispatch } = this.props;
    dispatch(getList(params));
  }

  renderItem(item, id, reactKey) {
    return (
      <div className="advancedList_rowWrap" key={reactKey}>
        <Row id={id} className="advancedList_row">
          <Col xs={5} md={5}>
            <span>{item.name}</span>
          </Col>
          <Col xs={5} md={5}>
            {item.site.address.formattedAddress}
          </Col>
          <Col xs={2} md={2}>
            <FormattedMessage id={item.alwaysOpen ? 'common_yes' : 'common_no'} />
          </Col>
          <div className="advancedList_detailIcon">
            <a href={`#${routes.parkingDetails.path.replace(':parkingId', item.id)}`}>
              <EditIcon />
            </a>
          </div>
        </Row>
      </div>
    );
  }

  render() {
    const { list, loadingList, urlParams, errorList } = this.props;

    return (
      <div className="parkingsPage mainContainer_content">
        <div className="pageContainer">
          <div className="parkingsPage_content">
            <section className="parkingsPage_management">
              <AdvancedList
                id="parkings"
                data={list}
                loading={loadingList}
                renderItem={this.renderItem}
                header={parkingsHeaderParams}
                urlParams={urlParams}
                error={errorList}
                footer={[
                  {
                    labelKey: 'parkings_count',
                    count: errorList ? '-' : list.length,
                    md: 12
                  }
                ]}
              />
            </section>
          </div>
        </div>
      </div>
    );
  }
}

Parkings.displayName = 'Parkings';

Parkings.propTypes = {
  list: PropTypes.array
};

export default namedCompose(
  injectIntl,
  connect(state => {
    const {
      parkings: { list, urlParams, loadingList, errorList },
      routing: {
        location: { pathname }
      }
    } = state;

    return {
      list,
      urlParams,
      pathname,
      loadingList,
      errorList
    };
  })
)(Parkings);
