import * as types from '../../constants/actionTypes-constants';
import callApi from '../../actions/api-actions';
import { addErrorMessage, safe, selectCompanyId, trimValues } from '../../utils/utils';
import { addFlashMessage, getParkingsNoState, getSitesNoState } from '../../actions/all-actions';
import { FLASH_MESSAGE_TYPE_SUCCESS, FLASH_MESSAGE_TYPE_ERROR } from '../../constants/generic-constants';
import _get from 'lodash/get';
import { SORT_PROPERTY_VOUCHER_GROUP_NAME } from '../../constants/backend-constants';
import { saveAs } from 'file-saver';
import moment from 'moment';
import { enhanceVoucherCreateData, parseActionVoucherParams, parseVoucherDetailsRes } from '../../api/data-enhancer';
import { bundleSelector, validCompanyIdSelector } from '../../selectors/all-selectors';
import _forEach from 'lodash/forEach';
import _partial from 'lodash/partial';
import { apiParams } from '../../constants/api-params-constants';

export function getAllVoucherGroups({ params, firstLoader = true, secondLoader = true, minSize = 30 } = {}) {
  return dispatch => {
    const firstParams = {
      ...apiParams.voucherGroups,
      ...params,
      page: { number: 1, size: minSize }
    };

    const getSecondParams = totalResults => {
      firstParams.page.size = totalResults;
      return firstParams;
    };

    return dispatch(getVoucherGroups(firstParams, firstLoader))
      .then(data => {
        const totalResults = safe(() => data.pageInfo.totalResults);

        if (totalResults > minSize) {
          dispatch(getVoucherGroups(getSecondParams(totalResults), secondLoader));
        }

        return Promise.resolve(data);
      })
      .catch(error => {
        return Promise.reject(error);
      });
  };
}

export function setVoucherGroupsFilters(filters) {
  return {
    type: types.VOUCHER_GROUPS_UPDATE_FILTERS,
    filters
  };
}

export function getVoucherGroups(params, loader = false) {
  return (dispatch, getState) => {
    const state = getState();
    const page = _get(params, 'page');
    const pageNumber = _get(page, 'number');
    const pageSize = _get(page, 'size');
    const sort = _get(params, 'sort');

    const paramsObj = {
      page: {
        number: pageNumber,
        size: pageSize
      },
      sort: {
        isDescending: _get(sort, 'isDescending', false),
        property: _get(sort, 'property', SORT_PROPERTY_VOUCHER_GROUP_NAME)
      },
      superCompanyId: selectCompanyId(state),
      name: _get(params, 'name'),
      toCreationDate: _get(params, 'toCreationDate'),
      fromCreationDate: _get(params, 'fromCreationDate'),
      voucherType: _get(params, 'voucherType'),
      voucherCode: _get(params, 'voucherCode')
    };

    dispatch({ type: types.VOUCHER_GET_GROUPS_REQUEST });

    return dispatch(callApi('voucher', 'getGroups', paramsObj, loader))
      .then(data => {
        dispatch({
          type: types.VOUCHER_GET_GROUPS_SUCCESS,
          paginatedObj: data,
          urlParams: paramsObj
        });
        return data;
      })
      .catch(err => dispatch({ type: types.VOUCHER_GET_GROUPS_ERROR, err }));
  };
}

export function getVouchers(params, loader = false) {
  return dispatch => {
    const paramsObj = parseActionVoucherParams(params);

    dispatch({ type: types.VOUCHER_GET_LIST_REQUEST });

    return dispatch(callApi('voucher', 'getVouchers', paramsObj, loader))
      .then(function(data) {
        dispatch({
          type: types.VOUCHER_GET_LIST_SUCCESS,
          paginatedObj: data,
          urlParams: paramsObj
        });
        return data;
      })
      .catch(err => dispatch({ type: types.VOUCHER_GET_LIST_ERROR, err }));
  };
}

export function revokeVoucher(voucherId, loader = false) {
  return dispatch => {
    dispatch({ type: types.VOUCHER_REVOKE_REQUEST });

    return dispatch(callApi('voucher', 'revoke', voucherId, loader))
      .then(data => {
        dispatch({ type: types.VOUCHER_REVOKE_SUCCESS });
        dispatch(
          addFlashMessage({
            contentKey: 'voucher_revoke_success',
            type: FLASH_MESSAGE_TYPE_SUCCESS
          })
        );
        return data;
      })
      .catch(err => {
        dispatch({ type: types.VOUCHER_REVOKE_FAIL, err });
        try {
          err.body.json().then(parsedError =>
            dispatch(
              addFlashMessage({
                contentKey: parsedError.errorCode,
                type: FLASH_MESSAGE_TYPE_ERROR
              })
            )
          );
        } catch (e) {
          dispatch(
            addFlashMessage({
              contentKey: 'common_error_ocurred',
              type: FLASH_MESSAGE_TYPE_ERROR
            })
          );
        }
      });
  };
}

export function exportVouchers(params, groupName, loader = false) {
  return dispatch => {
    dispatch({ type: types.VOUCHERS_EXPORT_REQUEST });

    return dispatch(callApi('voucher', 'export', parseActionVoucherParams(params), loader))
      .then(data => {
        dispatch({ type: types.VOUCHERS_EXPORT_SUCCESS });

        let fileName = 'vouchers';
        const fileBlob = new Blob([data], { type: 'text/csv' });

        fileName = groupName ? fileName + ' - ' + groupName : fileName;
        fileName = fileName + ' - ' + moment().format('DD MMM YYYY') + '.csv';
        saveAs(fileBlob, fileName);

        dispatch(
          addFlashMessage({
            contentKey: 'vouchers_download_success',
            type: FLASH_MESSAGE_TYPE_SUCCESS
          })
        );
      })
      .catch(err => {
        dispatch({ type: types.VOUCHERS_EXPORT_FAIL });
        dispatch(
          addFlashMessage({
            contentKey: 'vouchers_download_failed',
            type: FLASH_MESSAGE_TYPE_SUCCESS
          })
        );
        err.body.json().then(parsedError =>
          dispatch(
            addFlashMessage({
              contentKey: parsedError.errorCode,
              type: FLASH_MESSAGE_TYPE_ERROR
            })
          )
        );
      });
  };
}

export function getVoucherGroupDetail(id) {
  return dispatch => {
    dispatch({
      type: types.VOUCHER_GET_GROUP_DETAIL_REQUEST
    });

    return dispatch(callApi('voucher', 'getVoucherGroupDetail', id, false)).then(data => {
      dispatch({
        type: types.VOUCHER_GET_GROUP_DETAIL_SUCCESS,
        data: parseVoucherDetailsRes(data)
      });
      return data;
    });
  };
}

export function clearVoucherGroupsResults() {
  return {
    type: types.VOUCHER_GROUPS_CLEAR
  };
}

export function clearVoucherGroupDetail() {
  return {
    type: types.VOUCHER_CLEAR_DETAIL
  };
}

export function createVoucherGroup() {
  function displayError(dispatch, errorKey) {
    dispatch(
      addFlashMessage({
        contentKey: errorKey,
        type: FLASH_MESSAGE_TYPE_ERROR
      })
    );
  }

  return (dispatch, getState) => {
    const state = getState();
    const data = trimValues(state.form.AddVoucherGroupForm);
    const superCompanyId = selectCompanyId(state);
    const allowedParkingsData = _get(state.vouchers, 'allowedParkingsData');
    const allowedCompaniesData = _get(state.vouchers, 'allowedCompaniesData');
    const enhancedData = enhanceVoucherCreateData({ ...data, superCompanyId, allowedParkingsData, allowedCompaniesData });

    dispatch({
      type: types.VOUCHER_GROUP_CREATE_REQUEST
    });

    return dispatch(callApi('voucher', 'createGroup', enhancedData))
      .then(data => {
        dispatch({
          type: types.VOUCHER_GROUP_CREATE_SUCCESS,
          group: data
        });
        dispatch(
          addFlashMessage({
            contentKey: 'voucher_group_created',
            type: FLASH_MESSAGE_TYPE_SUCCESS
          })
        );
        return data;
      })
      .catch(err => {
        err.body.json().then(parsedError => {
          displayError(dispatch, parsedError.errorCode);
        });
      });
  };
}

export function getCompany(id) {
  return (dispatch, getState) => {
    const data = getState().companies.headerList.filter(item => {
      return item.id === id;
    })[0];
    dispatch({
      type: types.VOUCHER_GROUP_GET_COMPANY_REQUEST
    });

    return dispatch({
      type: types.VOUCHER_GROUP_GET_COMPANY_SUCCESS,
      data
    });
  };
}

export function toggleCreateModal() {
  return {
    type: types.VOUCHER_TOGGLE_CREATE_CODE_MODAL
  };
}

export function createVoucherCode(voucherCodes) {
  return (dispatch, getState) => {
    const state = getState();
    const data = trimValues(state.form.CreateVoucherCodeForm);

    data.voucherGroupId = state.vouchers.groupDetail.id;

    if (data.typeBool === 'multiple') {
      data.voucherCodes = null;
    } else {
      data.numberOfVouchers = null;
      data.voucherCodes = voucherCodes;
    }

    delete data.typeBool;

    dispatch({
      type: types.VOUCHER_CREATE_CODE_REQUEST
    });

    return dispatch(callApi('voucher', 'createCode', data))
      .then(data => {
        dispatch({
          type: types.VOUCHER_CREATE_CODE_SUCCESS,
          data
        });
        dispatch(
          addFlashMessage({
            contentKey: 'voucher_code_created',
            type: FLASH_MESSAGE_TYPE_SUCCESS
          })
        );
        return data;
      })
      .catch(err => {
        err.body.json().then(parsedError => {
          dispatch(
            addFlashMessage({
              contentKey: parsedError.errorCode,
              type: FLASH_MESSAGE_TYPE_ERROR
            })
          );
        });
      });
  };
}

export function getBookingsCountByVoucherUses(id) {
  return dispatch => {
    dispatch({
      type: types.VOUCHERS_USED_IN_BOOKINGS_REQUEST
    });

    return dispatch(callApi('voucher', 'getBookingsCountUsingVouchers', id, false))
      .then(function(data) {
        dispatch({
          type: types.VOUCHERS_USED_IN_BOOKINGS_SUCCESS,
          data
        });
        return data;
      })
      .catch(err => {
        dispatch({
          type: types.VOUCHERS_USED_IN_BOOKINGS_ERROR,
          err
        });
      });
  };
}

export function vouchersAddToAllowedParkings(ids) {
  return {
    type: types.VOUCHERS_ADD_TO_ALLOWED_PARKINGS,
    ids
  };
}

export function vouchersAddToAvailableParkings(ids) {
  return {
    type: types.VOUCHERS_ADD_TO_AVAILABLE_PARKINGS,
    ids
  };
}

export function vouchersAddToAllowedCompanies(ids) {
  return {
    type: types.VOUCHERS_ADD_TO_ALLOWED_COMPANIES,
    ids
  };
}

export function vouchersAddToAvailableCompanies(ids) {
  return {
    type: types.VOUCHERS_ADD_TO_AVAILABLE_COMPANIES,
    ids
  };
}

export function getVouchersCompanyParkings() {
  return (dispatch, getState) => {
    const state = getState();
    const companyId = validCompanyIdSelector(state);
    const bundle = bundleSelector(state);

    const handleError = error => {
      dispatch({ type: types.VOUCHERS_GET_PARKINGS_ERROR });
      dispatch(addErrorMessage({ error, bundle }));
    };

    if (companyId) {
      dispatch({ type: types.VOUCHERS_GET_PARKINGS_REQUEST });

      return dispatch(callApi('parkings', 'getCompanyParkings', companyId, false)).then(function(data) {
        dispatch({
          type: types.VOUCHERS_GET_PARKINGS_SUCCESS,
          data
        });
        dispatch({
          type: types.VOUCHERS_SAVE_PARKINGS,
          companyId,
          parkings: data
        });
        dispatch({ type: types.VOUCHERS_ADD_AVAILABLE_PARKINGS });

        return data;
      }, handleError);
    }
  };
}

export function getVouchersCompanies() {
  return (dispatch, getState) => {
    const state = getState();
    const companyId = validCompanyIdSelector(state);
    const bundle = bundleSelector(state);

    const handleError = error => {
      dispatch({ type: types.VOUCHERS_GET_COMPANIES_ERROR });
      dispatch(addErrorMessage({ error, bundle }));
    };

    if (companyId) {
      dispatch({ type: types.VOUCHERS_GET_COMPANIES_REQUEST });

      return dispatch(callApi('subCompanies', 'getList', companyId, false)).then(function(data) {
        dispatch({
          type: types.VOUCHERS_GET_COMPANIES_SUCCESS,
          data
        });
        dispatch({
          type: types.VOUCHERS_SAVE_COMPANIES,
          companyId,
          companies: data
        });
        dispatch({ type: types.VOUCHERS_ADD_AVAILABLE_COMPANIES });

        return data;
      }, handleError);
    }
  };
}

export function getVouchersParkings() {
  return (dispatch, getState) => {
    const state = getState();
    const companyId = validCompanyIdSelector(state);
    const bundle = bundleSelector(state);
    let errorOccurred = false;

    const handleError = error => {
      dispatch({ type: types.VOUCHERS_GET_PARKINGS_ERROR });
      dispatch(addErrorMessage({ error, bundle }));
    };

    const handleParkingsError = error => {
      errorOccurred = error;
    };

    const handleAllDone = () => {
      if (errorOccurred) handleError(errorOccurred);
      dispatch({ type: types.VOUCHERS_ADD_AVAILABLE_PARKINGS });
    };

    const handleParkingsSuccess = ({ siteId, siteName }, parkings) => {
      dispatch({
        type: types.VOUCHERS_SAVE_PARKINGS,
        siteId,
        siteName,
        parkings
      });
    };

    const promises = [];

    const handleParkingsPredicate = site => {
      const siteId = _get(site, 'id');
      const siteName = _get(site, 'name');
      const handleSuccess = _partial(handleParkingsSuccess, { siteId, siteName });
      const action = getParkingsNoState({ siteId, loader: true });

      promises.push(dispatch(action).then(handleSuccess, handleParkingsError));
    };

    const handleSitesSuccess = sitesList => {
      _forEach(sitesList, handleParkingsPredicate);
      Promise.all(promises).then(handleAllDone);
    };

    if (companyId) {
      dispatch({ type: types.VOUCHERS_GET_PARKINGS_REQUEST });
      dispatch(getSitesNoState({ companyId, loader: true })).then(handleSitesSuccess, handleError);
    }
  };
}
