import React, { Component, PropTypes } from 'react';

import routes from '../../../constants/routes-constants';
import BookingIcon from 'material-ui/svg-icons/maps/directions-car';
import { fillWhite, dimensions20, marginBottom2 } from '../../../constants/style-constants';
import { addOpenNewTabEvents, hideButtonHoverMsg } from '../../../utils/utils';

class VehicleResultsRow extends Component {
  constructor(props) {
    super(props);
    this.handleGoToDetail = this.handleGoToDetail.bind(this);
  }

  componentWillUnmount() {
    hideButtonHoverMsg();
  }

  handleGoToDetail(openInNewTab) {
    this.props.handleGoToDetail(routes.vehicleDetail.path, ':vehicleId', this.props.vehicle.id, openInNewTab);
  }

  render() {
    const { vehicle } = this.props;

    return (
      <div key={vehicle.id} className="search-resultRow" {...addOpenNewTabEvents(this.handleGoToDetail)}>
        <div className="search-resultIcon">
          <BookingIcon style={{ ...fillWhite, ...dimensions20, ...marginBottom2 }} />
        </div>
        <div className="search-resultCol bold">
          {vehicle.version.model.brand.name}
          <span className="search-spacer"> </span>
          {vehicle.version.model.name}
        </div>
        <div className="search-resultCol">{vehicle.registrationNumber}</div>
        <div className="search-resultCol">{vehicle.company.name}</div>
        {/*<div className="search-resultActions">
          <MoreVert
            style={fillDark}
          />
        </div>*/}
      </div>
    );
  }
}

VehicleResultsRow.displayName = 'VehicleResultsRow';

VehicleResultsRow.propTypes = {
  vehicle: PropTypes.object,
  handleGoToDetail: PropTypes.func
};

export default VehicleResultsRow;
