import React, { Component, PropTypes } from 'react';
import ListModal from '../../../../components/_v2/ColumnList/Modal';
import { getEditModalTitle } from '../../../../components/_v2/ColumnList/helpers';
import SubCompanyDetail from './SubCompanyDetail';
import { getMsg } from '../../../../utils/IntlGlobalProvider';

export class SubCompanyDetailModal extends Component {
  getTitle() {
    return getEditModalTitle(getMsg('common_company'));
  }

  render() {
    return (
      <ListModal title={this.getTitle()} isOpen={this.props.isOpen} onClose={this.props.onClose}>
        <SubCompanyDetail isModal />
      </ListModal>
    );
  }
}

ListModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func
};

export default SubCompanyDetailModal;
