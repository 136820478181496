import * as types from '../../constants/actionTypes-constants';

export default function header(
  state = {
    searching: 0,
    bookingsResultsTotal: 0,
    vehiclesResultsTotal: 0,
    companiesResultsTotal: 0,
    memberNameResultsTotal: 0,
    memberEmailResultsTotal: 0,
    memberNameResults: [],
    memberEmailResults: [],
    bookingsResults: [],
    companiesResults: [],
    vehiclesResults: [],
    searched: false,
    companiesError: false,
    bookingsError: false,
    memberNameError: false,
    memberEmailError: false,
    vehiclesError: false
  },
  action
) {
  switch (action.type) {
    case types.HEADER_SEARCH_REQUEST: {
      return {
        ...state,
        searching: state.searching + 1
      };
    }

    case types.SEARCH_MEMBER_NAME_SUCCESS: {
      return {
        ...state,
        memberNameResults: action.result ? action.result.results : [],
        memberNameResultsTotal: action.result ? action.result.metadata.paginationInfo.totalResults : 0,
        searching: state.searching - 1,
        memberNameError: !action.result,
        searched: true
      };
    }

    case types.SEARCH_BOOKINGS_SUCCESS: {
      return {
        ...state,
        bookingsResults: action.result ? action.result.results : [],
        bookingsResultsTotal: action.result ? action.result.metadata.paginationInfo.totalResults : 0,
        searching: state.searching - 1,
        bookingsError: !action.result,
        searched: true
      };
    }

    case types.SEARCH_VEHICLES_SUCCESS: {
      return {
        ...state,
        vehiclesResults: action.result ? action.result.results : [],
        vehiclesResultsTotal: action.result ? action.result.metadata.paginationInfo.totalResults : 0,
        searching: state.searching - 1,
        vehiclesError: !action.result,
        searched: true
      };
    }

    case types.SEARCH_COMPANIES_SUCCESS: {
      return {
        ...state,
        companiesResults: action.result ? action.result.results : [],
        companiesResultsTotal: action.result ? action.result.metadata.paginationInfo.totalResults : 0,
        searching: state.searching - 1,
        companiesError: !action.result,
        searched: true
      };
    }

    case types.SEARCH_MEMBER_EMAIL_SUCCESS: {
      return {
        ...state,
        memberEmailResults: action.result ? action.result.results : [],
        memberEmailResultsTotal: action.result ? action.result.metadata.paginationInfo.totalResults : 0,
        searching: state.searching - 1,
        memberEmailError: !action.result,
        searched: true
      };
    }

    case types.SEARCH_CLEAR_RESULTS: {
      return {
        ...state,
        memberNameResults: [],
        memberEmailResults: [],
        bookingsResults: [],
        companiesResults: [],
        vehiclesResults: [],
        companiesError: false,
        bookingsError: false,
        memberNameError: false,
        memberEmailError: false,
        vehiclesError: false,
        searched: false
      };
    }

    default:
      return state;
  }
}
