import React, { Component } from 'react';
import autoBind from 'react-autobind';
import pure from 'recompose/pure';
import { namedCompose, vehicleAapter } from '../../../utils/utils';
import { FormattedMessage, injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { localeSelector, userRoleSelector } from '../../../selectors/all-selectors';
import routes, { bookingEditRules, fleetEditRules, subCompanyRules } from '../../../constants/routes-constants';
import { isGranted } from '../../../actions/user-actions';
import VehiclePlanningVehicleTile from '../../../containers/_v2/VehiclePlanning/components/VehiclePlanningVehicleTile';
import { RaisedButton } from 'material-ui';
import { BACKUSER_ROLE_SERVICE_ADVISOR, BOOKING_STATUS_PRE_BOOKED, BOOKING_STATUS_SCHEDULED } from '../../../constants/backend-constants';
import IsGranted from '../../IsGranted/IsGranted';
import { checkRole } from '../../../constants/backuser-role-rules';

const styleLabelBut = { paddingTop: '5px', paddingBottom: '5px' };
class BookingVehicleInfo extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.derivedStateFromProps(props, true);
  }

  componentWillReceiveProps(nextProps) {
    this.derivedStateFromProps(nextProps);
  }

  derivedStateFromProps(nextProps) {
    const { vehicleCompanyId, vehicleCompanyName, vehicleCategoryType, vehicle, bookingStatus, role } = nextProps;

    this.companyHref =
      vehicleCompanyId &&
      isGranted({
        disallowed: subCompanyRules.exclude,
        allowed: subCompanyRules.include
      })
        ? `#${routes.subCompanyDetails.path.replace(':subCompanyId', vehicleCompanyId)}`
        : '';

    this.companyName = vehicleCompanyName || vehicleCompanyId;
    this.vehicle = vehicleAapter(vehicle);
    this.categoryName = vehicleCategoryType;
    this.categoryPage = '/#/pricing';
    this.bookingStatus = bookingStatus;
    this.role = role;
    this.readOnly = !checkRole(fleetEditRules, this.role);
    this.canLockUnlock = this.role === BACKUSER_ROLE_SERVICE_ADVISOR || !this.readOnly;
    this.isUunlockableWithDevice = this.vehicle.device && this.canLockUnlock;
  }

  render() {
    if (this.vehicle) {
      return (
        <div className="booking-tile">
          <h4 className="booking-tile_headline">
            <FormattedMessage id="booking_view_vehicle_infos_tile_header" />
          </h4>
          <VehiclePlanningVehicleTile
            vehicle={this.vehicle}
            bookingViewMoreDetails
            companyName={this.companyName}
            companyHref={this.companyHref}
            categoryName={this.categoryName}
            displayUsages={false}
            nolink
          />
          <div className="actions">
            <IsGranted disallowed={bookingEditRules.exclude}>
              {(this.bookingStatus === BOOKING_STATUS_SCHEDULED || this.bookingStatus === BOOKING_STATUS_PRE_BOOKED) && (
                <RaisedButton
                  label={<FormattedMessage id="vehiclePlanning_changeVehicle" />}
                  primary
                  className="trigger"
                  type="button"
                  labelStyle={styleLabelBut}
                  onClick={this.props.changeVehicle}
                />
              )}
            </IsGranted>
            {this.isUunlockableWithDevice && (
              <RaisedButton
                className="trigger"
                label={<FormattedMessage id="vehicle_detail_lock" />}
                primary
                labelStyle={styleLabelBut}
                type="button"
                onClick={this.props.lockVehicle}
              />
            )}
            {this.isUunlockableWithDevice && (
              <RaisedButton
                className="trigger"
                label={<FormattedMessage id="vehicle_detail_unlock" />}
                primary
                type="button"
                labelStyle={styleLabelBut}
                onClick={this.props.unlockVehicle}
              />
            )}
          </div>
        </div>
      );
    }
  }
}

// -----

BookingVehicleInfo.propTypes = {
  vehicleCompanyId: PropTypes.string,
  vehicleCompanyName: PropTypes.string,
  vehicleCategoryType: PropTypes.string,
  vehicle: PropTypes.object
};

// -----

export default namedCompose(
  connect(state => {
    return {
      locale: localeSelector(state),
      role: userRoleSelector(state)
    };
  }),
  injectIntl,
  pure
)(BookingVehicleInfo);
