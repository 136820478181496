import React, { Component } from 'react';
import autoBind from 'react-autobind';
import pure from 'recompose/pure';
import { namedCompose, humanShortDuration, hasStartBeforeCancel, fuelLevelCap100 } from '../../../utils/utils';
import { FormattedMessage, injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { localeSelector } from '../../../selectors/all-selectors';
import moment from 'moment';
import FuelGauge from '../../FuelGauge/FuelGauge';
import { BOOKING_STATUS_COMPLETED, BOOKING_STATUS_IN_PROGRESS } from '../../../constants/backend-constants';

class BookingTripInfo extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.derivedStateFromProps(props);
  }

  // eslint-disable-next-line no-unused-vars
  componentWillReceiveProps(nextProps, nextContext) {
    this.derivedStateFromProps(nextProps);
  }

  derivedStateFromProps(nextProps) {
    const {
      estimatedStart,
      effectiveStart,
      estimatedEnd,
      effectiveEnd,
      bookingStartParking,
      bookingStartSite,
      bookingEndParking,
      bookingStartMileage,
      bookingEndMileage,
      distanceTravelled,
      freeKmPerDay,
      startFuel,
      endFuel,
      brandId,
      bookingStatus,
      statusHistory,
      intl
    } = nextProps;

    this.estimatedStart = estimatedStart;
    this.effectiveStart = effectiveStart;
    this.estimatedEnd = estimatedEnd;
    this.effectiveEnd = effectiveEnd;
    this.distanceTravelled = distanceTravelled ? distanceTravelled + 'km' : '-';
    this.freeKmPerDay = freeKmPerDay ? freeKmPerDay + 'km' : '-';
    this.startMileage = bookingStartMileage ? bookingStartMileage.toFixed(1) : bookingStartMileage;
    this.endMileage = bookingEndMileage ? bookingEndMileage.toFixed(1) : bookingEndMileage;
    this.bookingStartParking = bookingStartParking;
    this.bookingEndParking = bookingEndParking;
    this.bookingStatus = bookingStatus;
    this.isCompleted = this.bookingStatus === BOOKING_STATUS_COMPLETED;
    this.IsRoundTrip = bookingEndParking && bookingStartParking.id === bookingEndParking.id;
    this.bookingStartSite = bookingStartSite;
    this.companyId = bookingStartSite ? bookingStartSite.companyId : null;
    this.subCompanyId = bookingStartSite ? bookingStartSite.subCompanyId : null;
    this.siteId = bookingStartSite.id;
    this.siteLink =
      '/#/v2/organization/brands/' +
      brandId +
      '/super-companies/' +
      this.companyId +
      '/sub-companies/' +
      this.subCompanyId +
      '/sites/' +
      this.siteId +
      '/parkings';

    this.startParkingId = bookingStartParking ? bookingStartParking.id : null;
    this.startParkingName = bookingStartParking ? bookingStartParking.name : null;
    this.parkingLink =
      '/#/v2/organization/brands/' +
      brandId +
      '/super-companies/' +
      this.companyId +
      '/sub-companies/' +
      this.subCompanyId +
      '/sites/' +
      this.siteId +
      '/parkings/' +
      this.startParkingId +
      '/edit';

    this.startFuelWithUnit = startFuel ? fuelLevelCap100(startFuel) + '%' : '-';
    this.endFuelWithUnit = endFuel ? fuelLevelCap100(endFuel) + '%' : ' -';
    this.startFuelValue = startFuel ? fuelLevelCap100(startFuel) : '-';
    this.endFuelValue = endFuel ? fuelLevelCap100(endFuel) : '-';
    this.statuses = statusHistory;
  }

  tripLabel() {
    if (this.estimatedStart || this.effectiveStart)
      return (
        <div className="row-info">
          <span className="label">
            <FormattedMessage id="common_type" />
          </span>
          <span className="value">
            <FormattedMessage id={this.IsRoundTrip ? 'search_vehicle_return' : 'search_vehicle_oneWay'} />
          </span>
        </div>
      );
  }

  duration() {
    if (this.estimatedStart || this.effectiveStart) {
      const s = moment(this.isCompleted ? this.effectiveStart : this.estimatedStart);
      const e = moment(this.isCompleted ? this.effectiveEnd : this.estimatedEnd);
      const diffInSeconds = moment.duration(e.diff(s, 'seconds'), 'seconds');
      const displayDuration = humanShortDuration(diffInSeconds, this.props.locale, this.props.intl);
      const label = this.isCompleted ? (
        <FormattedMessage id="booking_view_duration" />
      ) : (
        <FormattedMessage id="booking_view_expected_duration" />
      );

      return (
        <div className="row-info">
          <span className="label">{label}</span>
          <span className="value">{displayDuration}</span>
        </div>
      );
    }
  }

  tripMileage() {
    return (
      <div className="row-info">
        <span className="label">
          <FormattedMessage id="booking_view_trip_mileage" />
        </span>
        <span className="value">{this.distanceTravelled} </span>
      </div>
    );
  }

  freeKmByDay() {
    if (this.freeKmPerDay)
      return (
        <div className="row-info">
          <span className="label">
            <FormattedMessage id="booking_view_free_km_by_day" />
          </span>
          <span className="value">{this.freeKmPerDay}</span>
        </div>
      );
  }

  site() {
    const label = <FormattedMessage id={this.IsRoundTrip ? 'booking_view_start_end_site' : 'booking_view_start_site'} />;
    if (this.bookingStartSite)
      return (
        <div className="row-info">
          <span className="label">{label}</span>
          <span className="value">
            <a href={this.siteLink}>{this.bookingStartSite.name}</a>
          </span>
        </div>
      );
  }

  parking() {
    const label = <FormattedMessage id={this.IsRoundTrip ? 'booking_view_start_end_parking' : 'booking_view_start_parking'} />;
    if (this.estimatedStart || this.effectiveStart)
      return (
        <div className="row-info">
          <span className="label">{label}</span>
          <span className="value">
            <a href={this.parkingLink}>{this.startParkingName}</a>
          </span>
        </div>
      );
  }

  startFuel() {
    const { bookingStatus } = this.props;
    const event = hasStartBeforeCancel(this.statuses);
    if ((event && event.type !== 'fake') || bookingStatus === BOOKING_STATUS_IN_PROGRESS || bookingStatus === BOOKING_STATUS_COMPLETED) {
      return (
        <div className="row-info">
          <span className="label">
            <FormattedMessage id="booking_view_start_fuel_level" />
          </span>
          <span className="value inline">
            {this.startFuelWithUnit}
            <FuelGauge percentage={this.startFuelValue} height={10} />
          </span>
        </div>
      );
    }
  }

  endFuel() {
    const { bookingStatus } = this.props;
    if (bookingStatus === BOOKING_STATUS_COMPLETED)
      return (
        <div className="row-info">
          <span className="label">
            <FormattedMessage id="booking_view_end_fuel_level" />
          </span>
          <span className="value inline">
            {this.endFuelWithUnit}
            <FuelGauge percentage={this.endFuelValue} height={10} />
          </span>
        </div>
      );
  }

  render() {
    return (
      <div className="booking-tile">
        <h4 className="booking-tile_headline">
          <FormattedMessage id="booking_view_trip_infos_tile_header" />
        </h4>
        <div className="booking-tile_wrap">
          {this.tripLabel()}
          {this.duration()}
          {this.tripMileage()}
          {this.freeKmByDay()}
          {this.site()}
          {this.parking()}
          {this.startFuel()}
          {this.endFuel()}
        </div>
      </div>
    );
  }
}

BookingTripInfo.defaultProps = {
  freeOfCharges: false
};

// -----

BookingTripInfo.propTypes = {
  bookingStatus: PropTypes.string,
  statusPayment: PropTypes.string,
  bookingUsageType: PropTypes.string,
  freeOfCharges: PropTypes.bool,
  invoiceNumber: PropTypes.string,
  estimatedPriceForDuration: PropTypes.number,
  totalPriceIncludingTaxes: PropTypes.number,
  voucherDiscountAmount: PropTypes.number,
  estimatedStart: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object // date
  ]),
  effectiveStart: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object // date
  ]),
  estimatedEnd: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object // date
  ]),
  effectiveEnd: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object // date
  ]),
  bookingStartParking: PropTypes.object,
  bookingStartSite: PropTypes.object,
  bookingEndParking: PropTypes.object,
  bookingStartMileage: PropTypes.number,
  bookingEndMileage: PropTypes.number,
  bookingHistory: PropTypes.array
};

// -----

export default namedCompose(
  connect(state => {
    return {
      locale: localeSelector(state)
    };
  }),
  injectIntl,
  pure
)(BookingTripInfo);
