import { UPDATE_LOCATION } from 'react-router-redux';
import * as types from '../constants/actionTypes-constants';
import { getStorageData, setStorageData, updateMobileViewTag } from '../utils/utils';
import { STORAGE_KEY } from '../constants/generic-constants';

const quickSightTestEmail = getStorageData(STORAGE_KEY.QUICK_SIGHT_TEST_EMAIL);

export default function user(
  state = {
    userInfo: null,
    enableNewUi: getStorageData(STORAGE_KEY.NEW_UI),
    enableMobileView: getStorageData(STORAGE_KEY.MOBILE_VIEW),
    enableVpV1: getStorageData(STORAGE_KEY.VP_V1),
    enableQuickSightTestEmail: !!quickSightTestEmail,
    quickSightTestEmail,
    defaultLogin: null,
    forgotPasswordMode: false,
    accountActiveTabIndex: 0,
    reportingError: false,
    reportingSpinner: false
  },
  action
) {
  switch (action.type) {
    case types.USER_LOGIN_REQUEST:
      return state;

    case types.USER_UPDATE_UI_STATUS: {
      setStorageData(STORAGE_KEY.NEW_UI, action.status);
      return { ...state, enableNewUi: action.status };
    }

    case types.USER_UPDATE_MOBILE_VIEW_STATUS: {
      setStorageData(STORAGE_KEY.MOBILE_VIEW, action.status);
      updateMobileViewTag();
      return { ...state, enableMobileView: action.status };
    }

    case types.USER_UPDATE_VP_STATUS: {
      setStorageData(STORAGE_KEY.VP_V1, action.status);
      return { ...state, enableVpV1: action.status };
    }

    case types.USER_UPDATE_QUICK_SIGHT_TEST_EMAIL: {
      setStorageData(STORAGE_KEY.QUICK_SIGHT_TEST_EMAIL, action.email);
      return { ...state, quickSightTestEmail: action.email, enableQuickSightTestEmail: !!action.email };
    }

    case types.USER_LOGIN_ERROR:
      return Object.assign({}, state, {
        loginServerError: action.error
      });

    case types.USER_RESET_PASSWORD_ERROR:
      return Object.assign({}, state, {
        loginServerError: action.error
      });

    case types.USER_LOGIN_SUCCESS: {
      return { ...state, userInfo: action.userInfo, loginServerError: null };
    }

    case types.USER_TOKEN_REFRESH_SUCCESS: {
      return { ...state, userInfo: action.userInfo };
    }

    case types.USER_RESET_PASSWORD_SUCCESS:
      return Object.assign({}, state, {
        loginServerError: null,
        resetPasswordSuccess: true
      });

    case types.USER_REPORTING_CLEAR_ERROR: {
      return { ...state, reportingError: false };
    }

    case types.USER_REPORTING_SET_ERROR: {
      return { ...state, reportingError: true };
    }

    case types.USER_REPORTING_SPINNER_START: {
      return { ...state, reportingSpinner: true };
    }

    case types.USER_REPORTING_SPINNER_STOP: {
      return { ...state, reportingSpinner: false };
    }

    case types.USER_LOGOUT:
      return Object.assign({}, state, {
        // we use an empty object instead of a null because we don't want an error to be thrown in current page and
        // prevent route change when we log out
        userInfo: {}
      });

    case types.USER_FORGOT_PASSWORD_MODE_TOGGLE:
      return Object.assign({}, state, {
        forgotPasswordMode: !state.forgotPasswordMode
      });

    case types.USER_STORED_LOGIN_EXPOSE:
      return Object.assign({}, state, {
        defaultLogin: action.login
      });

    case types.USER_EDIT_PROFILE_REQUEST:
      return state;

    case types.USER_EDIT_PROFILE_SUCCESS:
      return Object.assign({}, state, {
        userInfo: action.userInfo
      });

    case types.USER_ACCOUNT_ACTIVE_TAB_INDEX_UPDATE:
      return Object.assign({}, state, {
        accountActiveTabIndex: action.index
      });

    case types.USER_RESET_PASSWORD_CHECK_TOKEN_REQUEST:
      return state;

    case types.USER_RESET_PASSWORD_STORE_TOKEN:
      return Object.assign({}, state, {
        resetPasswordToken: action.validToken
      });

    case types.USER_RESET_PASSWORD_APPLY_REQUEST:
      return state;

    case UPDATE_LOCATION:
      if (action.path === '/') {
        return Object.assign({}, state, {
          userInfo: null
        });
      } else return state;

    default:
      return state;
  }
}
