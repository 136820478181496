import React, { Component } from 'react';
import autoBind from 'react-autobind';
import { vehicleDetailChips } from '../../../constants/options-constants';
import { connect } from 'react-redux';
import _get from 'lodash/get';
import { bundleSelector, localeSelector } from '../../../selectors/all-selectors';
import { vehicleDetailFilterTypes } from '../../../constants/filterTypes-constants';
import FilterChips from '../../../components/FilterChips/FilterChips';
import Filters from '../../../components/AdvancedList/components/Filters';
import AdvancedList from '../../../components/AdvancedList/AdvancedList';
import { apiParams } from '../../../constants/api-params-constants';
import { FormattedMessage } from 'react-intl';
import routes from '../../../constants/routes-constants';
import _forEach from 'lodash/forEach';
import classNames from 'classnames';
import { Col, Row } from 'react-bootstrap';
import { vehicleDamageTypes } from '../../../constants/options-constants';
import ImagePreview from '../../../components/ImagePreview/ImagePreview';
import { routeActions } from 'react-router-redux';
import _omit from 'lodash/omit';
import _partial from 'lodash/partial';
import pure from 'recompose/pure';

import {
  VEHICLE_AREA_FRONT_LEFT,
  VEHICLE_AREA_FRONT_RIGHT,
  VEHICLE_AREA_BACK_LEFT,
  VEHICLE_AREA_BACK_RIGHT,
  VEHICLE_AREA_OTHER
} from '../../../constants/backend-constants';

import { getAppFormattedDate, getAppFormattedTime, namedCompose } from '../../../utils/utils';

import { VEHICLES_GET_REPORTS_ID } from '../../../constants/generic-constants';

import { clearReportsData, getVehicleReports } from '../../../actions/vehicles-actions';

class VehicleReports extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.setDamagesData();
  }

  componentDidMount() {
    this.callReportsApi(
      {
        size: apiParams.default.page.size,
        ...this.props.damagesParams
      },
      false
    );
  }

  componentWillUnmount() {
    this.props.dispatch(clearReportsData());
  }

  setDamagesData() {
    this.setColProps();
    this.setDamagesHead();
    this.damagesOmit = ['number', 'size', VEHICLES_GET_REPORTS_ID];
    this.callbacks = {};
  }

  setColProps() {
    this.colProps = {};

    this.colProps['common_details'] = {
      md: 2,
      xs: 2
    };

    this.colProps['frontLeft'] = {
      md: 2,
      xs: 2
    };

    this.colProps['frontRight'] = {
      md: 2,
      xs: 2
    };

    this.colProps['backLeft'] = {
      md: 2,
      xs: 2
    };

    this.colProps['backRight'] = {
      md: 2,
      xs: 2
    };

    this.colProps['other'] = {
      md: 2,
      xs: 2
    };

    this.colProps['noImages'] = {
      md: 10,
      xs: 10
    };
  }

  setDamagesHead() {
    this.damagesHead = [
      {
        labelKey: 'common_date',
        ...this.colProps['common_details']
      },
      {
        labelKey: 'vehicleDetail_damagesTab_frontRight',
        ...this.colProps['frontRight']
      },
      {
        labelKey: 'vehicleDetail_damagesTab_frontLeft',
        ...this.colProps['frontLeft']
      },
      {
        labelKey: 'vehicleDetail_damagesTab_backLeft',
        ...this.colProps['backLeft']
      },
      {
        labelKey: 'vehicleDetail_damagesTab_backRight',
        ...this.colProps['backRight']
      },
      {
        labelKey: 'vehicleDetail_damagesTab_other',
        ...this.colProps['other']
      }
    ];
  }

  callReportsApi(data, loader) {
    const { detailVehicle, dispatch } = this.props;
    const vehicleId = _get(detailVehicle, 'id');
    const params = data || {};

    dispatch(
      getVehicleReports(
        {
          [VEHICLES_GET_REPORTS_ID]: vehicleId,
          ...params
        },
        loader
      )
    );
  }

  handleRefreshDamages() {
    this.callReportsApi(this.props.damagesParams);
  }

  handlePageSizeSelect(value) {
    const { damagesParams } = this.props;
    const params = {
      number: 1,
      size: value
    };

    this.callReportsApi({ ...damagesParams, ...params });
  }

  handleChangePage(value) {
    const { damagesMetadata, damagesParams } = this.props;

    const size = _get(damagesMetadata, 'paginationInfo.pageSize');
    const def = apiParams.default.page.size;

    const params = {
      number: value,
      size: size || def
    };

    const newParams = { ...damagesParams, ...params };
    this.callReportsApi(newParams);
  }

  getCarImage(data, index) {
    const preview = _get(data, 'thumbnailUrl');
    const src = _get(data, 'photoUrl');
    const defaultMessage = 'Could not load the image';
    const key = 'common_broken_image';
    const brokenImage = _get(this.props.bundle, key, defaultMessage);

    return (
      <Col {...this.colProps[index]} className="image-col" key={index}>
        <ImagePreview
          src={src}
          thumbnailSrc={preview}
          alt={brokenImage}
          srcClass="img-open"
          className="img-container"
          thumbnailClass="image-preview"
        />
      </Col>
    );
  }

  parseType(value) {
    const key = _get(vehicleDamageTypes, value, value);
    return <FormattedMessage id={key} />;
  }

  moveToFeedbacks(id) {
    const path = routes.feedbackDetail.path.replace(':feedbackId', id);
    this.props.dispatch(routeActions.push(path));
  }

  getDamagesCallback(id) {
    if (this.callbacks[id]) return this.callbacks[id];
    return (this.callbacks[id] = _partial(this.moveToFeedbacks, id));
  }

  displayCarImages(photos) {
    let frontLeft, frontRight, backLeft, backRight, other;
    _forEach(photos, photo => {
      const area = _get(photo, 'vehicleArea');

      if (area === VEHICLE_AREA_FRONT_LEFT) frontLeft = photo;
      if (area === VEHICLE_AREA_FRONT_RIGHT) frontRight = photo;
      if (area === VEHICLE_AREA_BACK_LEFT) backLeft = photo;
      if (area === VEHICLE_AREA_BACK_RIGHT) backRight = photo;
      if (area === VEHICLE_AREA_OTHER) other = photo;
    });

    if (frontLeft && frontRight && backLeft && backRight) {
      return [
        this.getCarImage(frontRight, 'frontRight'),
        this.getCarImage(frontLeft, 'frontLeft'),
        this.getCarImage(backLeft, 'backLeft'),
        this.getCarImage(backRight, 'backRight'),
        this.getCarImage(other, 'other')
      ];
    } else {
      return (
        <Col {...this.colProps['noImages']} className="no-images">
          <FormattedMessage id="vehicleDetail_damagesTab_noPhotos" />
        </Col>
      );
    }
  }

  renderDamages(item, id, reactKey) {
    const photos = _get(item, 'photos');
    const rawDate = _get(item, 'creationDateTime');
    const type = _get(item, 'type');
    const amount = _get(item, 'nbDamages', '?');
    const reportId = _get(item, 'bookingId');

    const date = getAppFormattedDate(rawDate, { local: true });
    const time = getAppFormattedTime(rawDate, { local: true });
    const callback = this.getDamagesCallback(reportId);

    return (
      <Row className={classNames('advancedList_row', 'nonClickable', 'damages-row')} id={id} key={reactKey} xs={12}>
        <Col {...this.colProps['common_details']} className={classNames('damages-date', 'before-image')}>
          <div>{date}</div>
          <div>{time}</div>
          <div>{this.parseType(type)}</div>
          <FormattedMessage values={{ amount }} id="feedbacks_label_damage_declared_amount" />
          <div className="action-button" onClick={callback}>
            <FormattedMessage id="common_view" />
          </div>
        </Col>
        {this.displayCarImages(photos)}
      </Row>
    );
  }

  handleDeleteChip(item) {
    const { damagesParams, damagesMetadata } = this.props;

    const params = _omit(damagesParams, item);
    const size = _get(damagesMetadata, 'paginationInfo.pageSize');
    const def = apiParams.default.page.size;

    const newParams = {
      ...params,
      number: 1,
      size: size || def
    };

    this.callReportsApi(newParams);
  }

  handleFilterSearch(filters) {
    const { damagesMetadata, damagesParams } = this.props;

    const size = _get(damagesMetadata, 'paginationInfo.pageSize');
    const def = apiParams.default.page.size;

    const params = {
      ...damagesParams,
      ...filters,
      number: 1,
      size: size || def
    };

    this.callReportsApi(params);
  }

  render() {
    return (
      <div className="damage-report-list">
        <FilterChips
          id="damage-report-chips"
          urlParams={this.props.damagesParams}
          onDeleteChip={this.handleDeleteChip}
          translations={vehicleDetailChips}
          filterTypes={vehicleDetailFilterTypes}
          omit={this.damagesOmit}
        />
        <div className="advancedList_actions">
          <Filters id="damage-report-filters" filterTypes={vehicleDetailFilterTypes} onFilteredSearchForm={this.handleFilterSearch} />
        </div>
        <AdvancedList
          id="damage-report-list"
          data={this.props.damagesList}
          listMetadata={this.props.damagesMetadata}
          loading={this.props.damagesLoading}
          error={this.props.damagesError}
          onRefreshList={this.handleRefreshDamages}
          onChangeRowsPerPage={this.handlePageSizeSelect}
          onChangePage={this.handleChangePage}
          renderItem={this.renderDamages}
          noResultsLabelKey="common_no_results"
          header={this.damagesHead}
          urlParams={this.props.damagesParams}
          renderAnchor={this.props.locale}
        />
      </div>
    );
  }
}

VehicleReports.displayName = 'VehicleReports';

export default namedCompose(
  connect(state => {
    const {
      vehicles: { detailVehicle, damagesParams, damagesError, damagesList, damagesLoading, damagesMetadata }
    } = state;

    return {
      detailVehicle,
      damagesParams,
      damagesError,
      damagesList,
      damagesLoading,
      damagesMetadata,
      bundle: bundleSelector(state),
      locale: localeSelector(state)
    };
  }),
  pure
)(VehicleReports);
