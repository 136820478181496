import React, { Component } from 'react';
import { reduxForm } from 'redux-form';
import { scrollToFirstError } from '../../../../utils/utils';
import LabeledCheckbox from '../../../../components/LabeledCheckbox/LabeledCheckbox';
import { headerContractVehicleValuesSelector } from '../../../../selectors/all-selectors';
import { RaisedButton } from 'material-ui';
import { getMsg } from '../../../../utils/IntlGlobalProvider';
import { FormattedMessage } from 'react-intl';

class VehicleSettings extends Component {
  render() {
    const {
      fields: {
        vehicle: {
          usageImmediate,
          usagePlanned,
          usageRV,
          testDrive,
          publicService,
          delivery,
          vehicleCategory,
          geolocalisation,
          carwise
        } = {}
      },
      limitedAccess
    } = this.props;

    return (
      <div className="contract-member-info">
        <div className="sc-option-details">
          <div className="sc-flat-boxes">
            <LabeledCheckbox id="usageImmediate" field={usageImmediate} disabled={limitedAccess}>
              <FormattedMessage id="vehicle_usage_type_b2b_immediate_usage" />
            </LabeledCheckbox>
            <LabeledCheckbox id="usagePlanned" field={usagePlanned} disabled={limitedAccess}>
              <FormattedMessage id="vehicle_usage_type_b2b_planned_usage" />
            </LabeledCheckbox>
            <LabeledCheckbox id="usageRV" field={usageRV} disabled={limitedAccess}>
              <FormattedMessage id="vehicle_usage_type_rv" />
            </LabeledCheckbox>
            <LabeledCheckbox id="testDrive" field={testDrive} disabled={limitedAccess}>
              <FormattedMessage id="vehicle_usage_type_test_drive" />
            </LabeledCheckbox>
            <LabeledCheckbox id="publicService" field={publicService} disabled={limitedAccess}>
              <FormattedMessage id="vehicle_usage_type_public_service" />
            </LabeledCheckbox>
            <LabeledCheckbox id="vehicleDelivery" field={delivery} disabled={limitedAccess}>
              <FormattedMessage id="vehicle_usage_type_delivery" />
            </LabeledCheckbox>
            <LabeledCheckbox id="vehicleCategory" field={vehicleCategory} disabled={limitedAccess}>
              <FormattedMessage id="vehiclesFiltersForm_category" />
            </LabeledCheckbox>
            <LabeledCheckbox id="vehicleLocation" field={geolocalisation} disabled={limitedAccess}>
              <FormattedMessage id="contract.vehicle.geolocalisation" />
            </LabeledCheckbox>
            <LabeledCheckbox id="carwise" field={carwise} disabled={limitedAccess}>
              <FormattedMessage id="contract.vehicle.carwise" />
            </LabeledCheckbox>
          </div>
        </div>
        {!limitedAccess && (
          <div className="sc-actions">
            <RaisedButton label={getMsg('common_save')} primary type="button" onClick={this.props.handleSubmit(this.props.onCallback)} />
          </div>
        )}
      </div>
    );
  }
}

VehicleSettings = reduxForm(
  {
    onSubmitFail: scrollToFirstError,
    form: 'contractVehicleSettings',
    fields: [
      'vehicle.usageRV',
      'vehicle.usageImmediate',
      'vehicle.usagePlanned',
      'vehicle.testDrive',
      'vehicle.publicService',
      'vehicle.delivery',
      'vehicle.vehicleCategory',
      'vehicle.carwise',
      'vehicle.geolocalisation'
    ]
  },
  state => {
    return {
      initialValues: {
        vehicle: headerContractVehicleValuesSelector(state)
      }
    };
  }
)(VehicleSettings);

export default VehicleSettings;
