import React, { Component } from 'react';
import autoBind from 'react-autobind';
import pure from 'recompose/pure';
import { getAppFormattedDateTime, hideVehiclePositionForCustomCompanyId, namedCompose, fuelLevelCap100, safe } from '../../../utils/utils';
import { FormattedMessage, injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import { connect } from 'react-redux';
import { localeSelector, serialNumberSelector, vehicleStatusSelector } from '../../../selectors/all-selectors';
import GoogleMapReact from 'google-map-react';
import config from '../../../constants/config-constants';
import VehicleCircleMarker from '../VehicleCircleMarker/VehicleCircleMarker';
import { RaisedButton } from 'material-ui';
import { getDeviceStatus, addFlashMessage } from '../../../actions/all-actions';
import { FLASH_MESSAGE_TYPE_ERROR } from '../../../constants/generic-constants';
import { VEHICLE_FUEL_TYPE_ELECTRIC } from '../../../constants/backend-constants';
import CopyToClipBoard from '../CopyToClipBoard/CopyToClipBoard';
import OpenInNew from 'material-ui/svg-icons/action/open-in-new';

const googleMapsUrl = 'http://maps.google.com/maps?q=loc';
class VehiclePosition extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = { loading: false };
    this.derivedStateFromProps(props);
  }

  componentWillMount() {
    this.mapCursorType = 'grab';
    this.defaultZoom = 18;
    this.defaultCenter = {
      lat: 48.853425,
      lng: 2.3486
    };
  }

  // eslint-disable-next-line no-unused-vars
  componentWillReceiveProps(nextProps, nextContext) {
    this.derivedStateFromProps(nextProps);
  }

  derivedStateFromProps(nextProps) {
    const { position, vehicleId, deviceNumber, vehicleStatus, vehicleFuelType, companyId, vehicle } = nextProps;
    this.position = position;
    this.vPos = { lat: null, lng: null };
    this.lat = this.vPos.lat || _get(this.position, 'coordinates.latitude') || _get(this.position, 'parking.coordinates.latitude');
    this.lng = this.vPos.lng || _get(this.position, 'coordinates.longitude') || _get(this.position, 'parking.coordinates.longitude');
    this.vehicleId = vehicleId;
    this.vehicle = vehicle;
    this.deviceNumber = deviceNumber;
    this.vehicleStatus = vehicleStatus;
    this.vehicleFuelType = vehicleFuelType;
    this.companyId = companyId;
    this.location = !!this.lat && !!this.lng ? { lat: this.lat, lng: this.lng } : {};

    if (!_isEmpty(this.vehicleStatus)) {
      this.wrapStatusData();
      this.vehiclePosition = this.vehicleStatus ? this.vehicleStatus.position : undefined;
      const hasPosition = this.vehicleStatus.hasOwnProperty('position');
      if (hasPosition) {
        this.vPos.lng = this.vehicleStatus.position.lon;
        this.vPos.lat = this.vehicleStatus.position.lat;

        if (!hideVehiclePositionForCustomCompanyId(this.companyId, this.props.userInfo.role)) {
          this.location = { lat: this.vPos.lat, lng: this.vPos.lng };
        }
      }

      this.date = getAppFormattedDateTime(_get(this.vehicleStatus, 'position.timestamp'), { local: true });
      this.setState({ loading: false });
    }

    if (this.vehicle.mileage || this.vehicle.boardVoltage || this.vehicle.fuelLevel) {
      this.vehicle.deviceStatus = {
        mileage: this.vehicle.mileage,
        boardVoltage: this.vehicle.boardVoltage,
        fuelLevel: fuelLevelCap100(_get(this.vehicle, 'fuelLevel.percentage'))
      };
    }
  }

  handleGetDeviceStatus() {
    const { dispatch } = this.props;
    this.setState({ loading: true });
    dispatch(getDeviceStatus(this.vehicleId)).catch(error => {
      if (!error.type) {
        // make sure native errors not swallowed
        console.error(error.stack); // eslint-disable-line
      }
      dispatch(
        addFlashMessage({
          contentKey: 'vehicle_get_device_status_failed',
          type: FLASH_MESSAGE_TYPE_ERROR
        })
      );
      this.setState({ loading: false });
    });
  }

  mapView() {
    if (!_isEmpty(this.location))
      return (
        <div className="booking-tile_map">
          <GoogleMapReact
            bootstrapURLKeys={{ key: config.gMapsApiKey }}
            center={this.location}
            defaultZoom={this.defaultZoom}
            defaultCenter={this.defaultCenter}
            options={{
              disableDoubleClickZoom: true,
              clickableIcons: false,
              draggableCursor: this.mapCursorType,
              fullscreenControl: false
            }}
          >
            <VehicleCircleMarker lat={this.location.lat} lng={this.location.lng} />
          </GoogleMapReact>
        </div>
      );
    return <div className="booking-tile_map"> </div>;
  }

  statusContent() {
    const hasStatus = !_isEmpty(this.vehicleStatus);
    const mileage = hasStatus ? _get(this.vehicleStatus, 'mileage') : _get(this.vehicle.deviceStatus, 'mileage');
    const fuelLevel = fuelLevelCap100(hasStatus ? _get(this.vehicleStatus, 'fuelLevel') : _get(this.vehicle.deviceStatus, 'fuelLevel'));
    const devicesStatusMileage =
      typeof mileage === 'number' ? mileage.toFixed(1) : <FormattedMessage id="vehicle_detail_device_status_unknown" />;

    if (hasStatus || (this.vehicle && !_isEmpty(this.vehicle.deviceStatus))) {
      return (
        <div className="booking-tile_wrap">
          <div className="row-info">
            <span className="label">
              <FormattedMessage id="vehicle_detail_device_status_mileage" />
            </span>
            <span className="value">{devicesStatusMileage} km</span>
          </div>
          <div className="row-info">
            <span className="label">
              <FormattedMessage
                id={
                  this.vehicleFuelType === VEHICLE_FUEL_TYPE_ELECTRIC
                    ? 'vehicle_detail_device_status_electric_cruising_range'
                    : 'vehicle_detail_device_status_fuelLevel'
                }
              />
            </span>
            <span className="value">{fuelLevel}%</span>
          </div>
          {hasStatus && (
            <div className="booking-tile_wrap">
              <div className="row-info">
                <span className="label">
                  <FormattedMessage id="vehicle_detail_device_status_centralLock" />
                </span>
                <span className="value">
                  <FormattedMessage id={`vehicle_detail_doors_status_${_get(this.vehicleStatus, 'centralLock', 'unknown')}`} />
                </span>
              </div>
              <div className="row-info">
                <span className="label">
                  <FormattedMessage id="vehicle_detail_device_status_immobilizer" />
                </span>
                <span className="value">
                  <FormattedMessage id={`vehicle_detail_engine_status_${_get(this.vehicleStatus, 'immobilizer', 'unknown')}`} />
                </span>
              </div>
              <div className="row-info">
                <span className="label">
                  <FormattedMessage id="vehicle_detail_device_status_key" />
                </span>
                <span className="value">
                  <FormattedMessage id={`vehicle_detail_device_status_${this.vehicleStatus.keyfob}`} />
                </span>
              </div>
              <div className="row-info">
                <span className="label">
                  <FormattedMessage id="vehicle_detail_device_board_voltage" />
                </span>
                <span className="value">{this.vehicleStatus.boardVoltage}</span>
              </div>
              <div className="row-info">
                <span className="label">
                  <FormattedMessage id="vehicle_detail_device_status_position" />
                </span>
                {this.vehiclePosition ? (
                  <span className="value">
                    {this.date}
                    <br />

                    <a href={`${googleMapsUrl}:${this.vehiclePosition.lat},${this.vehiclePosition.lon}`} target="_blank">
                      <span>
                        <FormattedMessage id="vehicle_detail_device_status_position_lat" />
                        {`: ${this.vehiclePosition.lat} `}
                        <br />
                        <FormattedMessage id="vehicle_detail_device_status_position_lon" />
                        {`: ${this.vehiclePosition.lon}`}
                      </span>
                      <OpenInNew />
                    </a>
                  </span>
                ) : (
                  <span className="value">
                    <FormattedMessage id="vehicle_detail_device_status_unknown" />
                  </span>
                )}
              </div>

              {!_isEmpty(this.cards) && (
                <div className="row-info">
                  <span className="label">
                    <FormattedMessage id="rfidTagStates" />
                  </span>
                  <span className="value">
                    <ul>{this.cards}</ul>
                  </span>
                </div>
              )}
              {safe(() => this.errors.length) > 0 && (
                <div className="row-info">
                  <span className="label">
                    <FormattedMessage id="eventErrrors" />
                  </span>
                  <span className="value">
                    <ul>{this.errors}</ul>
                  </span>
                </div>
              )}
            </div>
          )}

          {this.electricEngineRows()}
        </div>
      );
    }

    return <div></div>;
  }

  electricEngineRows() {
    const { electricVehicleState } = this.vehicleStatus || {};
    const cruisingRange = _get(electricVehicleState, 'cruisingRange');
    const charge = _get(electricVehicleState, 'charge');
    const chargeAdapter = _get(electricVehicleState, 'chargeAdapter');
    const quickCharge = _get(electricVehicleState, 'quickCharge');

    if (this.vehicleFuelType === VEHICLE_FUEL_TYPE_ELECTRIC && (charge || cruisingRange || chargeAdapter || quickCharge)) {
      const t1 = cruisingRange && (
        <div className="row-info">
          <span className="label">
            <FormattedMessage id="vehicle_detail_device_status_electric_cruising_range" />
          </span>
          <span className="value">{cruisingRange} km</span>
        </div>
      );

      const t2 = charge && (
        <div className="row-info">
          <span className="label">
            <FormattedMessage id="vehicle_detail_device_status_electric_charge" />
          </span>
          <span className="value">{charge}</span>
        </div>
      );

      const t3 = chargeAdapter && (
        <div className="row-info">
          <span className="label">
            <FormattedMessage id="vehicle_detail_device_status_electric_chargeAdapter" />
          </span>
          <span className="value">{chargeAdapter}</span>
        </div>
      );
      const t4 = quickCharge && (
        <div className="row-info">
          <span className="label">
            <FormattedMessage id="vehicle_detail_device_status_electric_quickCharge" />
          </span>
          <span className="value">{quickCharge}</span>
        </div>
      );
      return (
        <div className="booking-tile_wrap">
          {t1}
          {t2}
          {t3}
          {t4}
        </div>
      );
    }
  }

  wrapStatusData() {
    if (this.vehicleStatus) {
      if (this.vehicleStatus.rfidTagStates) {
        this.cards = Object.keys(this.vehicleStatus.rfidTagStates).map((item, index) => {
          return (
            <li key={item}>
              <FormattedMessage id={`vehicle_detail_device_status_${this.vehicleStatus.rfidTagStates[index + 1]}`} />
            </li>
          );
        });
      }

      if (this.vehicleStatus.errors) {
        let er = this.vehicleStatus.errors;
        this.errors = Object.keys(er).map((item, index) => {
          return (
            <li key={item}>
              <FormattedMessage id={`vehicle_detail_device_status_${this.vehicleStatus.errors[index]}`} />
            </li>
          );
        });
      }
    }
  }

  render() {
    return (
      <div id="vehicle-position" className="booking-tile">
        <h4 className="booking-tile_headline">
          <FormattedMessage id="booking_view_last_position_tile_header" />
          {this.deviceNumber && (
            <CopyToClipBoard
              stringToCopy={this.deviceNumber}
              keyCopied="booking_view_copied_device_number"
              keyCopy="booking_view_copy_device_number"
            />
          )}
        </h4>
        {this.mapView()}
        {this.statusContent()}

        <div className="booking-tile_actions">
          <RaisedButton
            label={<FormattedMessage id={this.state.loading ? 'common_loading' : 'booking_view_get_vehicle_status'} />}
            primary
            disabled={this.state.loading}
            type="button"
            onClick={this.handleGetDeviceStatus}
          />
        </div>
      </div>
    );
  }
}

VehiclePosition.propTypes = {
  position: PropTypes.object,
  vehicle: PropTypes.object,
  vehicleId: PropTypes.string,
  companyId: PropTypes.string,
  vehicleFuelType: PropTypes.string
};

export default namedCompose(
  connect(state => {
    const {
      user: { userInfo }
    } = state;
    return {
      vehicleStatus: vehicleStatusSelector(state),
      deviceNumber: serialNumberSelector(state),
      locale: localeSelector(state),
      userInfo
    };
  }),
  injectIntl,
  pure
)(VehiclePosition);
