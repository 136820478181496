import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { getAddTitle } from '../../../../../components/_v2/ColumnList/helpers';
import { showParentView } from '../../../../../components/_v2/ColumnList/helpers';
import { showSuccessMsg } from '../../../../../actions/flashMessage-actions';
import { getStore } from '../../../../../store/all-store';
import { createOrganizationBrand } from '../../../../../actions/organization-actions';
import BrandView from './index';
import pick from 'lodash/pick';
import { cleanDeep } from '../../../../../utils/cleanDeep';
import { getPublicFileUrl } from '../../../../../actions/file-actions';

export class OrganizationAddBrand extends Component {
  componentWillMount() {
    this.showParentView = showParentView.bind(this);

    this.handleCallback = data => {
      const { dispatch } = getStore();
      const payload = cleanDeep(pick(data, ['name', 'realm', 'publiclyAccessible']));

      const createBrand = pictureUrl => {
        if (pictureUrl) payload.pictureUrl = pictureUrl;

        dispatch(createOrganizationBrand(payload)).then(() => {
          dispatch(showSuccessMsg({ key: 'common_success' }));
          this.showParentView();
        });
      };

      if (data.pictureId) {
        dispatch(getPublicFileUrl(data.pictureId)).then(pictureUrl => createBrand(pictureUrl));
      } else {
        createBrand();
      }
    };
  }

  render() {
    return (
      <BrandView
        inputRef={this.props.inputRef}
        title={getAddTitle(<FormattedMessage id="brandForm_title" />)}
        onClose={this.showParentView}
        onCallback={this.handleCallback}
      />
    );
  }
}

export default OrganizationAddBrand;
