import React, { Component, PropTypes } from 'react';
import ListDetails from '../../../../../components/_v2/ColumnList/Details';
import BoxedInput from '../../../../../components/BoxedInput/BoxedInput';
import FieldErrorMsg from '../../../../../components/FieldErrorMsg/FieldErrorMsg';
import BoxedSelect from '../../../../../components/BoxedSelect/BoxedSelect';
import FormRow from '../../../../../components/FormRow/FormRow';
import { reduxForm } from 'redux-form';
import classNames from 'classnames';
import { createValidator, notEmpty, stopValidationIf, condition } from '../../../../../validation/sync-validation';
import { RaisedButton } from 'material-ui';
import { FormattedMessage } from 'react-intl';
import { fallbackFunc } from '../../../../../utils/utils';
import { accessOptions } from '../../../../../constants/options-constants';
import FileUpload from '../../../../../components/FileUlpoad/FileUpload';

export class BrandView extends Component {
  componentWillReceiveProps(props) {
    this.callback = props.handleSubmit(this.props.onCallback);
  }

  render() {
    const {
      fields: { name, realm, pictureId, access },
      editView,
      inputRef
    } = this.props;

    return (
      <ListDetails title={this.props.title} onClose={this.props.onClose}>
        <form className={classNames('organization-brand', 'organization-view', 'sc-details-form')} onSubmit={this.callback}>
          <FormRow>
            <BoxedInput id="brand-name" inputRef={inputRef} labelKey="common_name" field={name} maxlength="255" type="text">
              <FieldErrorMsg field={name} />
            </BoxedInput>
          </FormRow>
          {!editView && (
            <FormRow>
              <BoxedInput field={realm} labelKey="common_realm" id="brand-realm" maxlength="255" type="text">
                <FieldErrorMsg field={realm} />
              </BoxedInput>
            </FormRow>
          )}
          <FormRow>
            <BoxedSelect
              id="brand-access"
              labelKey="common_access"
              options={accessOptions}
              field={access}
              noEmptyValue
              disabled={editView}
              sort={false}
            />
          </FormRow>
          {!editView && (
            <FormRow>
              <FileUpload label="company_email_setup_logo" id="brand-picture" field={pictureId} />
            </FormRow>
          )}
          <div className="sc-actions">
            <RaisedButton label={this.props.submitLabel} primary type="button" onClick={this.callback} />
          </div>
        </form>
      </ListDetails>
    );
  }
}

BrandView.defaultProps = {
  fieldLabel: 'Field label',
  onCallback: fallbackFunc,
  submitLabel: <FormattedMessage id="common_save" />
};

BrandView.propTypes = {
  title: PropTypes.any,
  inputRef: PropTypes.func,
  onClose: PropTypes.func,
  submitLabel: PropTypes.any,
  onCallback: PropTypes.func,
  editView: PropTypes.bool
};

const isEditView = ({ editView }) => editView;

BrandView = reduxForm({
  form: 'brandView',
  fields: ['id', 'name', 'realm', 'access', 'pictureId'],
  touchOnBlur: false,
  validate: createValidator({
    name: [notEmpty()],
    realm: [stopValidationIf(condition(isEditView)), notEmpty()]
  })
})(BrandView);

export default BrandView;
