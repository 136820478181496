import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bannerMessageSelector, displayBannerSelector, bannerStyleSelector, localeSelector } from '../../selectors/all-selectors';
import { getFirstObjValue } from '../../utils/utils';
import cs from 'classnames';
import CloseIcon from 'material-ui/svg-icons/navigation/close';
import IconButton from 'material-ui/IconButton';
import { fillCurrentColorInherit } from '../../constants/style-constants';
import { updateBannerDisplay } from '../../actions/appBrands-actions';

class MessageBanner extends Component {
  componentWillMount() {
    this.onClose = () => this.props.dispatch(updateBannerDisplay(false));
  }

  getMessage() {
    const { message, locale } = this.props;

    if (typeof message === 'object') {
      return message[locale] || getFirstObjValue(message);
    } else {
      return message;
    }
  }

  render() {
    return (
      <div className={cs('banner-message', { 'sc-hide': !this.props.display })} style={this.props.style}>
        <span className="sc-msg">{this.getMessage()}</span>
        <IconButton className="sc-icon" iconStyle={fillCurrentColorInherit} onClick={this.onClose}>
          <CloseIcon />
        </IconButton>
      </div>
    );
  }
}

export default connect(state => {
  return {
    message: bannerMessageSelector(state),
    display: displayBannerSelector(state),
    style: bannerStyleSelector(state),
    locale: localeSelector(state)
  };
})(MessageBanner);
