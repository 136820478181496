import React, { Component, PropTypes } from 'react';
import { FormattedMessage } from 'react-intl';
import selectn from 'selectn';
import classnames from 'classnames';
import { CUSTOM_FIELD_CONDITIONED, CUSTOM_FIELD_YES } from '../../constants/backend-constants';
import _get from 'lodash/get';

import _ from 'lodash';

class TableView extends Component {
  getValuesForIntlMessage(param) {
    let valuesForIntlMessage = _.get(param, 'values');
    if (typeof valuesForIntlMessage === 'function') {
      return param.values(param);
    }
  }

  render() {
    const { params } = this.props;
    let hideIndex = false;
    const sortIsDescendingByDefault = false;

    const tableViewCell = (item, param, index) => {
      let content;
      if (typeof param.content === 'function') {
        content = param.content(item, this.props);
      } else {
        // prevent selectn from failing on missing contents
        // cf https://github.com/wilmoore/selectn.js/issues/21
        content = param.content == null ? '' : selectn(param.content, item);
      }

      const customClass = param.contentCustomClass ? param.contentCustomClass.replace('[content]', content) : '';

      const actionCustomClass = param.actionCustomClass ? param.actionCustomClass.replace('[content]', content) : '';

      let callback = param.actionCallback
        ? function(e) {
            e.preventDefault();
            param.actionCallback(item);
          }
        : null;

      if (typeof param.isDisabledActionCallback === 'function' && param.isDisabledActionCallback(item)) {
        callback = null;
      }

      let contentMessageKey = _.get(param, 'contentMessageKey');

      if (typeof contentMessageKey === 'function') contentMessageKey = contentMessageKey(item);

      const cellContent =
        contentMessageKey && param.href ? (
          !content && !actionCustomClass ? (
            ''
          ) : (
            <a href={content} target="_blank" className={`tableView_cell_action ${actionCustomClass || ''} `} download>
              <FormattedMessage id={contentMessageKey} />
            </a>
          )
        ) : contentMessageKey ? (
          !content && !actionCustomClass ? (
            ''
          ) : (
            <span className={`${customClass || ''} `}>
              {_get(item, 'mandatory', '') === CUSTOM_FIELD_CONDITIONED && param.messageKey === 'customFieldLabel_mandatory' ? (
                <span>
                  {this.props.content.filter(fld => fld.position === item.mandatoryCondition.dependsOnPosition)[0].name}
                  {' = '}
                  <FormattedMessage id={`common_${item.mandatoryCondition.expectedValueForPosition === 'true' ? 'yes' : 'no'}`} />
                </span>
              ) : _get(item, 'visible', '') === CUSTOM_FIELD_CONDITIONED && param.messageKey === 'customFieldLabel_visible' ? (
                <span>
                  {this.props.content.filter(fld => fld.position === item.visibleCondition.dependsOnPosition)[0].name}
                  {' = '}
                  <FormattedMessage id={`common_${item.visibleCondition.expectedValueForPosition === 'true' ? 'yes' : 'no'}`} />
                </span>
              ) : (
                <FormattedMessage id={contentMessageKey.replace('[content]', content)} />
              )}
            </span>
          )
        ) : (
          <span className={`tableView_cell_content ${customClass}`}>{content}</span>
        );

      const prefixKey = contentMessageKey ? contentMessageKey : param.content;
      const contentIsCentered = params.contentIsCentered;

      if (callback && this.props.limitedAccess) {
        return null;
      }

      return (
        <td className={`tableView_cell ${contentIsCentered ? 'tableView_cell_center' : ''}`} key={`${prefixKey}_${item.id}` + index}>
          {callback ? (
            <button className={`tableView_cell_action ${actionCustomClass}`} onClick={callback}>
              {cellContent}
            </button>
          ) : (
            <span className={`tableView_cell_contentInner`}>{cellContent}</span>
          )}
        </td>
      );
    };

    const body = (
      <tbody>
        {this.props.content.map((item, index) => {
          return (
            <tr
              className={classnames('tableView_row', { unused: item.visible && item.visible !== CUSTOM_FIELD_YES })}
              key={item.id ? item.id + index : index}
            >
              {params.columns.reduce((data, param, index) => {
                const result = tableViewCell(item, param, index);
                if (result) data.push(result);
                return data;
              }, [])}
            </tr>
          );
        }, this)}
      </tbody>
    );

    const headerLabels = params.columns.reduce((data, param, index) => {
      const currentSortedIndex = params.sortById;
      const sortIsDescending = params.sortIsDescending;
      const contentIsCentered = params.contentIsCentered;

      const isSortableColumnClass = param.isSortable;
      const isSortingColumnClass = currentSortedIndex && currentSortedIndex === param.id;
      const isSortReversedClass = sortIsDescending && currentSortedIndex && currentSortedIndex === param.id;

      const valuesForIntlMessage = this.getValuesForIntlMessage(param);

      let sortCallback = param.sortActionCallback
        ? function(e) {
            e.preventDefault();

            // if one selects a new column, we reset the direction of sorting null, otherwise we can reverse the sorting
            if (param.id === currentSortedIndex) {
              param.sortActionCallback(param.id, !sortIsDescending, params.namespace);
            } else {
              param.sortActionCallback(param.id, sortIsDescendingByDefault, params.namespace);
            }
          }
        : null;

      if (this.props.limitedAccess && param.actionCallback) return data;

      data.push(
        <th className={`tableView_headCell ${contentIsCentered ? 'tableView_cell_center' : ''}`} key={param.messageKey + index}>
          <span
            className={classnames('tableView_headCell_content', {
              'tableView_headCell_content--sortable': isSortableColumnClass,
              _is_sorting: isSortingColumnClass,
              _is_sortReversed: isSortReversedClass
            })}
          >
            {param.isSortable ? (
              <button
                className={`tableView_headCell_action ${contentIsCentered ? 'tableView_headCell_action_centered' : ''}`}
                onClick={sortCallback}
              >
                <FormattedMessage id={param.messageKey} values={valuesForIntlMessage} />
              </button>
            ) : (
              <FormattedMessage id={param.messageKey} values={valuesForIntlMessage} />
            )}
          </span>
        </th>
      );

      return data;
    }, []);

    const header = (
      <thead>
        <tr className="tableView_headRow">{headerLabels}</tr>
      </thead>
    );

    return (
      <div className={`tableView_wrapper ${this.props.customClass || ''}`}>
        <table className="tableView">
          {header}
          {body}
        </table>
      </div>
    );
  }
}

TableView.displayName = 'TableView';

TableView.propTypes = {
  params: PropTypes.object,
  content: PropTypes.any,
  values: PropTypes.any,
  currentSortedIndex: PropTypes.string,
  sortIsDescending: PropTypes.bool,
  customClass: PropTypes.string,
  limitedAccess: PropTypes.bool,
  viewAccess: PropTypes.bool // used by table view props
};

export default TableView;
