import * as types from '../constants/actionTypes-constants';
import { FLASH_MESSAGE_MAP, FLASH_MESSAGE_TYPE, FLASH_MESSAGE_TYPE_INFO, FLASH_MESSAGE_TYPE_SUCCESS } from '../constants/generic-constants';
import { getMsg } from '../utils/IntlGlobalProvider';
import { addFlashMsg } from '../utils/flashMessage/creator';

export function addFlashMessage(message) {
  return () => {
    const { type = FLASH_MESSAGE_TYPE_INFO, content, contentKey, contentData } = message || {};
    const newType = FLASH_MESSAGE_MAP[type] || FLASH_MESSAGE_TYPE.INFO;
    const newContent = content || (contentKey && getMsg(contentKey, { values: contentData }));
    if (newContent) addFlashMsg(newContent, newType);
  };
}

export function removeFlashMessage(index) {
  return {
    type: types.FLASH_MESSAGE_REMOVE,
    index
  };
}

export const successMessage = addFlashMessage({
  contentKey: 'common_success',
  type: FLASH_MESSAGE_TYPE_SUCCESS
});

export function showSuccessMsg({ key, content, delay }) {
  return dispatch => {
    dispatch(addFlashMessage({ contentKey: key, content, type: FLASH_MESSAGE_TYPE_SUCCESS, delayToNextRoute: delay }));
  };
}
