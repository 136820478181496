import React, { Component } from 'react';
import { reduxForm } from 'redux-form';
import { scheduledExportFrequencyOptions, scheduledExportTypeOptions } from '../../constants/options-constants';
import { headerCompanyListLoadingSelector, headerCompanyListSelector } from '../../selectors/all-selectors';
import { createCompanyOptions } from './utils';
import SlimerSelect from '../../components/SlimerSelect';

class ListFilters extends Component {
  render() {
    const { props: p } = this;
    const { fields: f } = p;

    return (
      <form className="bookings-filters exports-filters">
        <div className="sc-block">
          <div className="sc-items">
            <SlimerSelect
              id="filter-super-company"
              field={f.companyIds}
              options={p.companyOptions}
              disabled={p.companyOptionsLoading}
              labelKey="common_company"
            />
            <SlimerSelect
              id="filter-type-export"
              field={f.type}
              showSearch={false}
              options={scheduledExportTypeOptions}
              labelKey="scheduled.export.type"
            />
            <SlimerSelect
              id="filter-frequency"
              field={f.frequency}
              showSearch={false}
              options={scheduledExportFrequencyOptions}
              labelKey="common.frequency"
            />
          </div>
        </div>
      </form>
    );
  }
}

ListFilters = reduxForm(
  {
    form: 'scheduledExportListFilters',
    fields: ['frequency', 'type', 'companyIds']
  },
  state => ({
    companyOptions: createCompanyOptions(headerCompanyListSelector(state)),
    companyOptionsLoading: headerCompanyListLoadingSelector(state)
  })
)(ListFilters);

export default ListFilters;
