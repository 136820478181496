import memoizeOne from 'memoize-one';
import { boolToString, boolToStringStrict } from './utils';
import { adjustAddressFormatted } from '../selectors/all-selectors';
import config from '../constants/config-constants';
import { cleanDeep } from './cleanDeep';

export const createSiteDynamicValues = memoizeOne((siteValues, company) => {
  const c = company || {};
  const p = c.vehicleInspectionPhoto || {};

  const companyValues = {
    startBookingReportMandatory: boolToString(c.startBookingDamageReportMandatory),
    endBookingReportMandatory: boolToString(c.endBookingDamageReportMandatory),
    reportPhotos: boolToString(c.damageReportPhotos),
    photoStartBooking: boolToString(p.startBooking),
    photoEndBooking: boolToString(p.endBooking),
    nameForElectricVehicle: p.nameForElectricVehicle,
    nameForHybridVehicle: p.nameForHybridVehicle,
    nameForThermalVehicle: p.nameForThermalVehicle
  };

  return { ...companyValues, ...siteValues };
});

export const createEditSiteInitialValues = memoizeOne((site, company) => {
  const {
    automaticShortening,
    automaticExtension,
    chargeExpiredBooking,
    smartcardEnabled,
    smartcardFishingEnabled,
    spontaneousBookingEnabled,
    sendReportToDekra,
    vehicleInspectionReportConfig,
    address
  } = site || {};

  const {
    overrideSuperCompanyReportConfig,
    startBookingReportMandatory,
    endBookingReportMandatory,
    reportPhotos,
    vehicleInspectionPhotos
  } = vehicleInspectionReportConfig || {};
  const { city, postalCode, country } = address || {};

  const { startBooking, endBooking, nameForElectricVehicle, nameForHybridVehicle, nameForThermalVehicle } = vehicleInspectionPhotos || {};

  const currentSiteInfos = {
    ...site,
    overrideSuperCompanyReportConfig,
    enablePhotoOverride: vehicleInspectionPhotos,
    automaticShortening: boolToString(automaticShortening),
    automaticExtension: boolToString(automaticExtension),
    chargeExpiredBooking: boolToString(chargeExpiredBooking),
    smartcardEnabled: boolToString(smartcardEnabled),
    smartcardFishingEnabled: boolToString(smartcardFishingEnabled),
    spontaneousBookingEnabled: boolToString(spontaneousBookingEnabled),
    sendReportToDekra: boolToString(sendReportToDekra),
    address: address,
    city: city,
    postalCode: postalCode,
    country: country,
    startBookingReportMandatory: boolToStringStrict(startBookingReportMandatory),
    endBookingReportMandatory: boolToStringStrict(endBookingReportMandatory),
    reportPhotos: boolToStringStrict(reportPhotos),
    photoStartBooking: boolToStringStrict(startBooking),
    photoEndBooking: boolToStringStrict(endBooking),
    nameForElectricVehicle: nameForElectricVehicle,
    nameForHybridVehicle: nameForHybridVehicle,
    nameForThermalVehicle: nameForThermalVehicle
  };

  const values = cleanDeep(currentSiteInfos);
  return adjustAddressFormatted({ ...values, ...createSiteDynamicValues(values, company) });
});

export const createAddSiteInitialValues = memoizeOne((siteValues, company) => {
  const values = cleanDeep(siteValues);
  return { ...config.defaultSiteBookingParameters, ...values, ...createSiteDynamicValues(values, company) };
});
