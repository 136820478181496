// noinspection ES6CheckImport
import React, { Component, PropTypes } from 'react';

import { reduxForm } from 'redux-form';
import { COMPANY_PAYBACK_SETTINGS_FORM } from '../../constants/form-constants';
import { FormattedMessage } from 'react-intl';
import FormRow from '../FormRow/FormRow';
import BoxedInput from '../BoxedInput/BoxedInput';
import FieldErrorMsg from '../FieldErrorMsg/FieldErrorMsg';
import { namedCompose } from '../../utils/utils';
import { COMMISSION_FORM_FIELDS } from '../../constants/generic-constants';
import BoxedSelect from '../BoxedSelect/BoxedSelect';
import { invoicingCountryOptions } from '../../constants/options-constants';
import { createValidator } from '../../validation/sync-validation';
import { commissionParamsValidators } from '../../validation/paybackConfigValidators';

class CommissionParamsForm extends Component {
  constructor(props) {
    super(props);
    this.setStaticData();
  }

  setStaticData() {
    this.todaysDate = new Date();
  }

  render() {
    const {
      fields: { commissionVatRate, commissionRate, invoicingCountry, commissionRateFromDate, commissionCurrency },
      firstPayback,
      handleSubmit,
      commissionInfo,
      readOnly,
      currencyOptions
    } = this.props;

    return (
      <div className="payback-forms">
        <form action="#" className="bank-transfer-form" onSubmit={handleSubmit}>
          <h4 className="companyDetail_title">
            <FormattedMessage id="payback_settings_commission_parameters" />
          </h4>
          <FormRow customClass="companyForm_row">
            <BoxedInput
              readOnly={readOnly}
              labelKey="commission_vat_rate_applied"
              type="text"
              field={commissionVatRate}
              id="commission-vat-rate-applied"
            >
              <FieldErrorMsg customClass="fieldErrorMsg--companyForm" field={commissionVatRate} />
            </BoxedInput>

            <BoxedSelect
              disabled={readOnly}
              id="invoicing-country-select"
              field={invoicingCountry}
              labelKey="commission_country_invoicing"
              noEmptyValue
              options={invoicingCountryOptions}
            >
              <FieldErrorMsg field={invoicingCountry} customClass="fieldErrorMsg--companyForm" />
            </BoxedSelect>
          </FormRow>
          <FormRow customClass="companyForm_row">
            <BoxedInput
              readOnly={readOnly}
              infoContent={commissionInfo}
              labelKey="applied_commission_rate"
              type="text"
              field={commissionRate}
              id="applied-commission-rate"
            >
              <FieldErrorMsg customClass="fieldErrorMsg--companyForm" field={commissionRate} />
            </BoxedInput>
            <BoxedInput
              type="date"
              minDate={this.todaysDate}
              id="commission-rate-applied-date"
              labelKey="commission_rate_applied_from_date"
              readOnly={readOnly || firstPayback}
              field={commissionRateFromDate}
            >
              <FieldErrorMsg customClass="fieldErrorMsg--companyForm" field={commissionRateFromDate} />
            </BoxedInput>
          </FormRow>
          {firstPayback && (
            <FormRow customClass="companyForm_row">
              <BoxedSelect
                id="commission-rate-currency"
                labelKey="invoice_params_label_currency"
                field={commissionCurrency}
                options={currencyOptions}
                disabled
                noEmptyValue
              >
                <FieldErrorMsg customClass="fieldErrorMsg--companyForm" field={commissionCurrency} />
              </BoxedSelect>
            </FormRow>
          )}
        </form>
      </div>
    );
  }
}

CommissionParamsForm.propTypes = {
  commissionInfo: PropTypes.any, // HTML element to show when hovering mouse over eye icon
  currencyOptions: PropTypes.array, // available currencies to select
  readOnly: PropTypes.bool,
  firstPayback: PropTypes.bool
};

export default namedCompose(
  reduxForm({
    form: COMPANY_PAYBACK_SETTINGS_FORM,
    fields: COMMISSION_FORM_FIELDS,
    validate: createValidator(commissionParamsValidators)
  })
)(CommissionParamsForm);
