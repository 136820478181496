import React, { Component } from 'react';
import ScheduledExportForm from './Form';
import { showParentView } from '../../../components/_v2/ColumnList/helpers';
import ListDetails from '../../../components/_v2/ColumnList/Details';
import { addScheduledExport } from '../../../actions/exports-actions';
import { getStore } from '../../../store/all-store';
import { injectIntl } from 'react-intl';
import { fallbackFunc, intlMsg } from '../../../utils/utils';
import { FREQUENCY_DAILY, SCHEDULED_EXPORT } from '../../../constants/backend-constants';

export class ScheduledExportAddConfig extends Component {
  componentWillMount() {
    this.showParentView = showParentView.bind(this);

    this.handleSave = data => {
      getStore()
        .dispatch(addScheduledExport(data))
        .then(this.showParentView, fallbackFunc);
    };
  }

  render() {
    const { DESTINATION, SECURITY } = SCHEDULED_EXPORT;

    return (
      <ListDetails title={intlMsg(this, 'scheduled.export.add')} onClose={this.showParentView}>
        <ScheduledExportForm
          onSave={this.handleSave}
          initialValues={{ frequency: FREQUENCY_DAILY, destination: DESTINATION.SFTP, security: SECURITY.PASSWORD }}
        />
      </ListDetails>
    );
  }
}

ScheduledExportAddConfig = injectIntl(ScheduledExportAddConfig);

export default ScheduledExportAddConfig;
