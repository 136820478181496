import React, { Component, PropTypes } from 'react';

class MapCircle extends Component {
  constructor(props) {
    super(props);
    this.styleW = this.props.width;
    this.styleH = this.props.height;
    this.styleMarginTop = -(this.props.height / 2);
    this.styleMarginLeft = -(this.props.width / 2);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.width !== this.props.width || nextProps.height !== this.props.height) {
      this.styleW = nextProps.width;
      this.styleH = nextProps.height;
      this.styleMarginTop = -(nextProps.height / 2);
      this.styleMarginLeft = -(nextProps.width / 2);
    }
  }

  render() {
    return (
      <div
        style={{
          width: this.styleW,
          height: this.styleH,
          marginTop: this.styleMarginTop,
          marginLeft: this.styleMarginLeft
        }}
        className="shapeCircle"
      >
        {}
      </div>
    );
  }
}

MapCircle.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number
};

MapCircle.defaultProps = {
  width: 200,
  height: 100
};

MapCircle.displayName = 'MapCircle';

export default MapCircle;
