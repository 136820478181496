import React, { Component, PropTypes } from 'react';
import classNames from 'classnames';
import { FormattedMessage, injectIntl } from 'react-intl';
import { isEmpty, sortBoxedSelectAlphabetically, valueFirstInit, safe } from '../../utils/utils';
import autoBind from 'react-autobind';

const wrapOptionSelect = ({ label, value, key, disabled }) => {
  return (
    <option key={key} value={value ? value : ''} disabled={disabled}>
      {label}
    </option>
  );
};

class BoxedSelect extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
  }

  componentDidMount() {
    this.didUpdateCycle();
  }

  componentDidUpdate() {
    this.didUpdateCycle();
  }

  propsFirstInit() {
    if (!this.initDone) {
      const { initialValue, field } = this.props;
      const { value, onChange } = field;
      if (!isEmpty(value)) this.initDone = true;
      if (!this.initDone && !isEmpty(initialValue) && value !== initialValue) {
        if (onChange) {
          onChange(initialValue);
          this.initDone = true;
        }
      }
    }
  }

  didUpdateCycle() {
    this.setNoEmptyValue();
    this.propsFirstInit();
    valueFirstInit.call(this, this.props.field);
  }

  handleChange(e) {
    const { field, onChange } = this.props;
    let selectValue = e.target.value;

    field && field.onChange(selectValue); // trigger default behaviour
    onChange && onChange(selectValue); // call eventual additional callback
  }

  getCurrentValue() {
    if (!this.props.multiple) {
      const { field, options } = this.props;
      const firstOption = options ? options[0] : null;
      const firstOptionValue = firstOption ? firstOption.value : null;
      let fieldValue = safe(() => field.value);

      if (isEmpty(fieldValue)) fieldValue = this.props.noEmptyValue ? firstOptionValue : '';
      return fieldValue;
    }
  }

  getLabel(label, labelKey) {
    return labelKey ? <FormattedMessage id={labelKey} /> : label;
  }

  mandatoryStar() {
    if (this.props.mandatory) return <span className="labelMandatory">*</span>;
  }

  getBoxedSelectLabel() {
    if (this.props.label || this.props.labelKey)
      return (
        <label
          className={classNames('boxedSelect_label', this.props.customLabelClass, {
            _is_hidden: this.props.hideLabel,
            _is_not_visible: this.props.notVisibleLabel
          })}
        >
          {this.getLabel(this.props.label, this.props.labelKey)}
          {this.mandatoryStar()}
        </label>
      );
  }

  setNoEmptyValue() {
    const { noEmptyValue } = this.props;

    if (noEmptyValue && !this.noEmptySet) {
      const { field, options } = this.props;
      const { initialValue, value, onChange } = field || {};

      if (isEmpty(initialValue) && isEmpty(value)) {
        const firstOption = options ? options[0] : null;
        const firstOptionValue = firstOption ? firstOption.value : null;

        if (onChange && firstOptionValue) {
          onChange(firstOptionValue);
          this.noEmptySet = true;
        }
      }
    }
  }

  getInitialOption() {
    const { emptyValueLabel, noEmptyValue, multiple } = this.props;
    if (noEmptyValue || multiple) return [];
    return [this.wrapMessage(emptyValueLabel, undefined, 'emptyValue', true)];
  }

  wrapMessage(labelKey, value, key, disabled) {
    return (
      <FormattedMessage key={key} id={labelKey}>
        {label => wrapOptionSelect({ label, value, disabled })}
      </FormattedMessage>
    );
  }

  sortOptions() {
    const { sort } = this.props;
    const options = this.getInitialOption();
    const optionsToSort = this.props.options || [];
    const sortedOptions = sort ? sortBoxedSelectAlphabetically(optionsToSort) : optionsToSort;

    sortedOptions.forEach((optionItem, index) => {
      if (typeof optionItem === 'object') {
        const { value, labelKey, label, disabled } = optionItem;
        const key = value || labelKey || index;
        if (labelKey) options.push(this.wrapMessage(labelKey, value, key, disabled));
        else options.push(wrapOptionSelect({ label, value, key, disabled }));
      } else options.push(wrapOptionSelect({ label: optionItem, value: optionItem, key: index }));
    });

    return options;
  }

  render() {
    return (
      <div className={classNames('boxedSelectBlock', this.props.blockCustomClass)}>
        {this.getBoxedSelectLabel()}
        <div className={classNames('boxedSelectBlockInner', this.props.blockInnerCustomClass)}>
          <span
            className={classNames(this.props.customClass, {
              boxedSelectWrapper: !this.props.multiple,
              boxedSelectMultipleWrapper: this.props.multiple,
              'boxedSelectWrapper--fullWidth': this.props.fullWidth,
              _is_disabled: this.props.disabled
            })}
          >
            <select
              value={this.getCurrentValue()}
              id={this.props.id}
              className={classNames('boxedSelect', this.props.customSelectClass)}
              name={this.props.name}
              disabled={this.props.disabled}
              onChange={this.handleChange}
              size={this.props.size}
              onBlur={safe(() => this.props.field.onBlur)}
              multiple={Boolean(this.props.multiple)}
            >
              {this.sortOptions()}
            </select>
          </span>
          {this.props.children}
        </div>
      </div>
    );
  }
}

BoxedSelect.propTypes = {
  initialValue: PropTypes.string,
  id: PropTypes.string,
  onChange: PropTypes.func,
  blockCustomClass: PropTypes.string,
  blockInnerCustomClass: PropTypes.string,
  labelKey: PropTypes.string, // BoxedSelect Title (translation id)
  label: PropTypes.string, // BoxedSelect Title (string)
  customLabelClass: PropTypes.string,
  options: PropTypes.array,
  customClass: PropTypes.string,
  name: PropTypes.string,
  hideLabel: PropTypes.bool,
  notVisibleLabel: PropTypes.bool,
  multiple: PropTypes.bool, // if you use multi-select, the value prop must be an array
  customSelectClass: PropTypes.string,
  fullWidth: PropTypes.bool,
  disabled: PropTypes.bool,
  noEmptyValue: PropTypes.bool, // has no effect if field is 'multiple'
  emptyValueLabel: PropTypes.string,
  size: PropTypes.number,
  translate: PropTypes.bool, // translate option items or not
  sort: PropTypes.bool // sort options A-Z
};

/*
    optionItem: {
      value - value
      labelKey - translation id
      label - raw text
    }
*/

BoxedSelect.defaultProps = {
  emptyValueLabel: 'boxedSelect_defaultEmptyValue',
  noEmptyValue: false,
  sort: true
};

BoxedSelect.displayName = 'BoxedSelect';

export default injectIntl(BoxedSelect);
