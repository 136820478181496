import React, { Component, PropTypes } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { Row, Col } from 'react-bootstrap';
import { routeActions } from 'react-router-redux';

import { smartCardFilterTypes } from '../../constants/filterTypes-constants';
import { smartcardsHeaderParams } from '../../constants/table-constants';
import { smartcardsFilterChips } from '../../constants/options-constants';
import AdvancedList from '../../components/AdvancedList/AdvancedList';
import { getSmartcardsList, clearSmartcardsListData } from '../../actions/all-actions';
import routes from '../../constants/routes-constants';
import CardIcon from 'material-ui/svg-icons/action/credit-card';
import Filters from '../../components/AdvancedList/components/Filters';
import FilterChips from '../../components/FilterChips/FilterChips';

class Smartcards extends Component {
  constructor() {
    super();

    this.renderItem = this.renderItem.bind(this);
    this.handleChangePage = this.handleChangePage.bind(this);
    this.handleRefreshList = this.handleRefreshList.bind(this);
    this.handleNumberResultsSelect = this.handleNumberResultsSelect.bind(this);
    this.handleGotoDetail = this.handleGotoDetail.bind(this);
    this.handleSearchForm = this.handleSearchForm.bind(this);
    this.handleDeleteChip = this.handleDeleteChip.bind(this);
  }

  componentWillMount() {
    const { pageParams } = this.props;
    this.callApi(pageParams);
  }

  componentWillUnmount() {
    this.props.dispatch(clearSmartcardsListData());
  }

  callApi(params) {
    const { dispatch } = this.props;
    dispatch(getSmartcardsList(params));
  }

  handleChangePage(value) {
    const { dispatch, listMetadata, urlParams } = this.props;
    const params = {
      page: {
        number: value,
        size: listMetadata.paginationInfo.pageSize
      }
    };
    const newParams = { ...urlParams, ...params };
    this.callApi(newParams);
    dispatch(routeActions.push(routes.smartcards.path.replace(':search', encodeURIComponent(JSON.stringify(newParams)))));
  }

  handleRefreshList() {
    const { pageParams } = this.props;
    this.callApi(pageParams);
  }

  handleNumberResultsSelect(value) {
    const { dispatch, urlParams } = this.props;
    const params = {
      page: {
        number: 1,
        size: parseInt(value)
      }
    };
    const newParams = { ...urlParams, ...params };
    this.callApi(newParams);
    dispatch(routeActions.push(routes.smartcards.path.replace(':search', encodeURIComponent(JSON.stringify(newParams)))));
  }

  handleGotoDetail(params) {
    this.props.dispatch(routeActions.push(routes[params[0]].path.replace(params[1], params[2])));
  }

  renderItem(item, id, reactKey) {
    const colProps = { xs: 6, md: 6 };

    return (
      <div className="advancedList_rowWrap" key={reactKey}>
        <Row
          id={id}
          className="advancedList_row bookingsPage_listRow"
          onClick={_.partial(this.handleGotoDetail, ['smartcardsDetail', ':id', item.id])}
        >
          <Col {...colProps}>
            <div className="bookingsPage_firstItemWrap">
              <div className="bgCar smarctcardsListIcon">
                <CardIcon />
              </div>
              <div>
                <div className="bookingsPage_doubleColumnWrap">
                  {_.get(item, 'user', false) && (
                    <span>
                      {item.user.firstname} {item.user.lastname}
                    </span>
                  )}
                </div>
                {item.cardId}
              </div>
            </div>
          </Col>
          <Col {...colProps}>{_.get(item, 'company', false) && item.company.name}</Col>
        </Row>
      </div>
    );
  }

  handleSearchForm(params) {
    const { dispatch, listMetadata, urlParams } = this.props;
    params.page = {
      number: 1,
      size: listMetadata.paginationInfo.pageSize
    };
    let newParams = {
      ...urlParams,
      ...params
    };
    this.callApi(newParams);
    dispatch(routeActions.push(routes.smartcards.path.replace(':search', encodeURIComponent(JSON.stringify(newParams)))));
  }

  handleDeleteChip(item) {
    let { urlParams, dispatch } = this.props;
    delete urlParams[item];
    this.callApi(urlParams);
    dispatch(routeActions.push(routes.smartcards.path.replace(':search', encodeURIComponent(JSON.stringify(urlParams)))));
  }

  render() {
    const { list, urlParams, loadingList, listMetadata } = this.props;

    return (
      <div className="smartcardsPage mainContainer_content">
        <div className="pageContainer">
          <div className="smartcardsPage_content">
            <FilterChips
              urlParams={urlParams}
              onDeleteChip={this.handleDeleteChip}
              translations={smartcardsFilterChips}
              filterTypes={smartCardFilterTypes}
              id="bookings"
            />

            <div className="advancedList_actions page">
              <Filters filterTypes={smartCardFilterTypes} onFilteredSearchForm={this.handleSearchForm} id="smartcards" />
            </div>

            <AdvancedList
              id="smartcards"
              data={list}
              listMetadata={listMetadata}
              loading={loadingList}
              renderItem={this.renderItem}
              onChangePage={this.handleChangePage}
              onRefreshList={this.handleRefreshList}
              onChangeRowsPerPage={this.handleNumberResultsSelect}
              error={false}
              urlParams={urlParams}
              header={smartcardsHeaderParams}
            />
          </div>
        </div>
      </div>
    );
  }
}

Smartcards.displayName = 'Smartcards';

Smartcards.propTypes = {
  list: PropTypes.array,
  listMetadata: PropTypes.object
};

export default connect(state => {
  const {
    smartcards: { paginatedResults, currentSortedIndex, sortIsDescending, urlParams, filtersFormIsOpen, loadingList },
    page: { params }
  } = state;

  return {
    list: _.get(paginatedResults, 'results', []),
    listMetadata: _.get(paginatedResults, 'metadata', {}),
    currentSortedIndex,
    sortIsDescending,
    urlParams,
    filtersFormIsOpen,
    pageParams: params,
    loadingList
  };
})(Smartcards);
