import _pick from 'lodash/pick';

import * as types from '../constants/actionTypes-constants';
import callApi from './api-actions';
import { addFlashMessage, fileUpload } from './all-actions';
import { refreshPage } from '../routing/all-routing';

import { FLASH_MESSAGE_TYPE_ERROR, FLASH_MESSAGE_TYPE_SUCCESS } from '../constants/generic-constants';
import { selectCompanyId, selectSubCompanyId, maxDateFormat, safe } from '../utils/utils';

export function getVehicleDamagesList(params) {
  return (dispatch, getState) => {
    const state = getState();

    let paramsObj;

    if (params) {
      let pageNumber = params.page ? params.page.number : state.vehicleDamages.paginatedResults.paginationInfo.currentPage;
      let pageSize = params.page ? params.page.size : state.vehicleDamages.paginatedResults.paginationInfo.pageSize;

      paramsObj = {
        page: {
          number: pageNumber,
          size: pageSize
        }
      };

      let sortProperty = params.sort ? params.sort.property : state.vehicleDamages.currentSortedIndex;
      let sortIsDescending = params.sort ? params.sort.isDescending : state.vehicleDamages.sortIsDescending;

      let sort = {
        property: sortProperty,
        isDescending: sortIsDescending
      };

      if (sortProperty) {
        paramsObj = Object.assign(paramsObj, { sort });
      }

      delete params.page;

      params.endDate ? (params.endDate = maxDateFormat(params.endDate)) : null;

      paramsObj = Object.assign(paramsObj, params);

      const companyId = selectCompanyId(state);
      const subCompanyId = selectSubCompanyId(state);

      paramsObj.companyIds = companyId ? [companyId] : [];
      paramsObj.subCompanyIds = subCompanyId ? [subCompanyId] : [];
    } else {
      paramsObj = null;
    }

    dispatch({
      type: types.VEHICLE_DAMAGES_GET_LIST_REQUEST
    });

    return dispatch(callApi('vehicleDamages', 'getList', { params: paramsObj })).then(function(data) {
      dispatch({
        type: types.VEHICLE_DAMAGES_GET_LIST_SUCCESS,
        paginatedObj: data,
        urlParams: paramsObj
      });
      return data;
    });
  };
}

export function setVehicleDamagesCurrentSortedIndex(index) {
  return {
    type: types.VEHICLE_DAMAGES_SET_CURRENT_SORTED_INDEX,
    index
  };
}

export function setVehicleDamagesSortIsDescending(isDescending) {
  return {
    type: types.VEHICLE_DAMAGES_SORT_DIRECTION_SET,
    isDescending
  };
}

export function toggleVehicleDamagesFilters() {
  return {
    type: types.VEHICLE_DAMAGES_FILTERS_FORM_OPENED_STATE_TOGGLE
  };
}

export function openAddDamageModal() {
  return {
    type: types.VEHICLE_DAMAGES_ADD_DAMAGE_MODAL_OPEN
  };
}

export function closeAddDamageModal() {
  return {
    type: types.VEHICLE_DAMAGES_ADD_DAMAGE_MODAL_CLOSE
  };
}

export function displayDamageImage(fileData, type) {
  return {
    type,
    fileData: {
      content: fileData.content.split(';base64,')[1],
      mimeType: fileData.mimeType,
      name: fileData.name
    }
  };
}

export function getVehicleDamageDetail(id) {
  return dispatch => {
    dispatch({
      type: types.VEHICLE_DAMAGES_GET_DETAIL_DAMAGE_REQUEST
    });

    return dispatch(callApi('vehicleDamages', 'getVehicleDamageDetail', id)).then(function(data) {
      if (data.fileId) {
        dispatch(callApi('file', 'getDamageReportFile', id)).then(function(fileData) {
          dispatch({
            type: types.VEHICLE_DAMAGES_GET_DETAIL_IMAGE_SUCCESS,
            vehicleDamageImage: fileData
          });
        });
      }

      dispatch({
        type: types.VEHICLE_DAMAGES_GET_DETAIL_DAMAGE_SUCCESS,
        vehicleDamage: data
      });
      return data;
    });
  };
}

export function addDamage(formData) {
  return dispatch => {
    const params = _pick(formData, ['vehicleRegistrationNumber', 'area', 'type', 'comment']);
    const rawFile = safe(() => formData.damageFile.rawFile);

    const props = {
      file: rawFile,
      isPublicUpload: false
    };

    if (rawFile) {
      return dispatch(fileUpload(props)).then(file => dispatch(addDamageRequest({ ...params, fileId: file.id })));
    } else {
      return dispatch(addDamageRequest(params));
    }
  };
}

function addDamageRequest(params) {
  return dispatch => {
    return dispatch(callApi('vehicleDamages', 'addDamage', params))
      .then(function() {
        refreshPage();
        dispatch(
          addFlashMessage({
            contentKey: 'vehicle_damage_add_damage_success',
            type: FLASH_MESSAGE_TYPE_SUCCESS
          })
        );
      })
      .catch(() => {
        dispatch(
          addFlashMessage({
            contentKey: 'vehicle_damage_add_damage_failed',
            type: FLASH_MESSAGE_TYPE_ERROR
          })
        );
      });
  };
}

export function editStatus(vehicleDamageDetail, status) {
  return dispatch => {
    let vehicleDamageId = vehicleDamageDetail.id;

    return dispatch(callApi('vehicleDamages', 'editStatus', { vehicleDamageId, status }))
      .then(function() {
        refreshPage();
      })
      .catch(function() {
        dispatch(
          addFlashMessage({
            contentKey: 'vehicle_damage_edit_status_failed',
            type: FLASH_MESSAGE_TYPE_ERROR
          })
        );
      });
  };
}

export function openVehicleDamageEditStatusModal() {
  return {
    type: types.VEHICLE_DAMAGES_EDIT_STATUS_MODAL_OPEN
  };
}

export function closeVehicleDamageEditStatusModal() {
  return {
    type: types.VEHICLE_DAMAGES_EDIT_STATUS_MODAL_CLOSE
  };
}
