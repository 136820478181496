import React, { Component, PropTypes } from 'react';
import ListModal from '../../components/_v2/ColumnList/Modal';
import AddParking from './AddParking';
import { getAddModalTitle } from '../../components/_v2/ColumnList/helpers';
import { getMsg } from '../../utils/IntlGlobalProvider';

export class AddParkingModal extends Component {
  getTitle() {
    return getAddModalTitle(getMsg('common_parking'));
  }

  render() {
    return (
      <ListModal title={this.getTitle()} isOpen={this.props.isOpen} onClose={this.props.onClose}>
        <AddParking isModal onCancel={this.props.onClose} onSubmit={this.props.onClose} siteId={this.props.siteId} />
      </ListModal>
    );
  }
}

AddParkingModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  siteId: PropTypes.string
};

export default AddParkingModal;
