import React, { Component, PropTypes } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import classNames from 'classnames';
import DatePicker from 'material-ui/DatePicker';
import TimePicker from 'material-ui/TimePicker';
import ShowInfoIcon from 'material-ui/svg-icons/action/visibility';
import _get from 'lodash/get';
import _upperFirst from 'lodash/upperFirst';
import { connect } from 'react-redux';
import _isFunction from 'lodash/isFunction';
import autoBind from 'react-autobind';
import { Tooltip } from 'react-tippy';
import { ChromePicker } from 'react-color';
import moment from 'moment';
import injectTapEventPlugin from 'react-tap-event-plugin';

import {
  addTippyContent,
  fallbackFunc,
  formatAppDate,
  getAppFormattedDate,
  getMaterialLocale,
  isValidTime,
  namedCompose,
  safe,
  toNumber,
  valueFirstInit
} from '../../utils/utils';
import { iconStyles } from '../../constants/style-constants';
import { localeSelector } from '../../selectors/all-selectors';
import memoizeOne from 'memoize-one';
import shortid from 'shortid';
import ReactSVG from 'react-svg';

// required for DatePicker
injectTapEventPlugin();

function setDate(props) {
  const propDate = safe(() => props.field.value);
  if (propDate && props.type === 'date') {
    const date = moment(propDate);
    if (date.isValid()) return date.toDate();
  }
}

function setTime(props) {
  if (props.type === 'time') {
    const propTime = safe(() => props.field.value);
    if (isValidTime(propTime)) return propTime;
  }
}

function padZero(h) {
  return ('0' + h).slice(-2);
}

class BoxedInput extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.derivedStateFromProps(props);

    this.state = { displayColorPicker: false, showPassword: false };
    this.mainClassName = 'boxedInput';
    this.dateValue = setDate(props);
    this.timeValue = setTime(props);
  }

  // noinspection JSCheckFunctionSignatures
  componentWillReceiveProps(props) {
    this.derivedStateFromProps(props);
  }

  derivedStateFromProps(props) {
    this.validateDate(props);
  }

  componentDidMount() {
    this.didUpdateCycle();
  }

  // noinspection JSCheckFunctionSignatures
  componentDidUpdate() {
    this.didUpdateCycle();
  }

  didUpdateCycle() {
    valueFirstInit.call(this, this.props.field);
  }

  handleClick(e) {
    e.preventDefault();
    this.props.onAction();
  }

  handleOpenPicker() {
    this.setState({ displayColorPicker: true });

    if (_isFunction(document.onkeydown)) this.documentKeyFunc = document.onkeydown;
    document.onkeydown = this.handlePickerKey;
  }

  handleClosePicker() {
    this.setState({ displayColorPicker: false });

    document.onkeydown = null;
    if (this.documentKeyFunc) document.onkeydown = this.documentKeyFunc;
  }

  handleColorContainer(event) {
    event.preventDefault();
  }

  handleAssignColor(data) {
    this.handleChangeValue(data.hex);
  }

  handlePickerKey(event) {
    if (this.documentKeyFunc) this.documentKeyFunc(event);
    switch (event.key) {
      case 'Escape':
      case 'Esc':
      case ' ':
      case 'Spacebar':
        this.handleClosePicker();
        break;
      case 'Enter':
        event.preventDefault();
        this.handleClosePicker();
        break;
    }
  }

  colorPicker() {
    const value = this.props.value || this.props.field.value;

    if (this.state.displayColorPicker) {
      return (
        <div className="color-picker">
          <div className="picker-cover" onClick={this.handleClosePicker} />
          <ChromePicker disableAlpha color={value} onChange={this.handleAssignColor} />
        </div>
      );
    }
  }

  changePasswordState(e) {
    this.setState(state => ({ showPassword: !state.showPassword }));
    e.preventDefault();
  }

  getPasswordButton() {
    return (
      <button
        type="button"
        className={classNames('sc-password-svg', { 'sc-show': this.state.showPassword })}
        onClick={this.changePasswordState}
      >
        <ReactSVG src="/img/eye/open.svg" className={classNames('align-svg', 'sc-open')} />
        <ReactSVG src="/img/eye/closed.svg" className={classNames('align-svg', 'sc-closed')} />
      </button>
    );
  }

  getColorInput() {
    const {
      value: propsValue,
      field: { value: fieldValue },
      readOnly,
      disabled
    } = this.props;

    const value = propsValue || fieldValue;

    return (
      <div
        className={classNames(this.mainClassName + '--color-input-container', this.props.customInputClass)}
        onClick={this.handleColorContainer}
      >
        <div className="picker-container">
          <div
            className={classNames('picker-button', { readOnly: readOnly || disabled })}
            onClick={!readOnly && !disabled && this.handleOpenPicker}
          >
            <div className="picker-color" style={{ backgroundColor: value }} />
          </div>
          {this.colorPicker()}
        </div>
        <input
          type="text"
          placeholder={this.getPlaceholder()}
          className={this.mainClassName}
          id={this.props.id}
          maxLength={this.props.maxlength}
          readOnly={this.props.readOnly}
          value={value}
          disabled={this.props.disabled}
          onChange={this.handleChange}
          onBlur={this.props.field.onBlur}
        />
      </div>
    );
  }

  formatDate(date) {
    const { dateFormat } = this.props;
    return dateFormat ? _upperFirst(formatAppDate(date, dateFormat).toLowerCase()) : getAppFormattedDate(date);
  }

  handleDateChange(e, date) {
    if (e) e.preventDefault();

    // TODO: restore jsonDateFormat
    // const { jsonDateFormat } is needed
    // const { jsonDateFormat } = this.props;

    // date.toJSON = function() {
    //   // do not use this.props.jsonDateFormat
    //   return moment(this).format(jsonDateFormat);
    // };

    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);

    this.props.field.onChange(date);
    if (this.props.onChange) this.props.onChange(date);
  }

  handleTimeChange(e, value) {
    let time = new Date(value);
    const hh = time.getHours();
    const mm = time.getMinutes();
    const timestr = padZero(hh) + ':' + padZero(mm);
    this.props.field.onChange(timestr);
  }

  handleChange(e) {
    const result = e.target.value;
    this.handleChangeValue(result);
  }

  handleChangeValue(value) {
    const { onChange, field } = this.props;
    if (onChange) onChange(value);
    if (field) field.onChange(value);
  }

  handleDateBlurFix() {
    // when we focus on a date input, blur occerred while material-ui widget opens. We need this hack so that the
    // date doesn't get converted to a string at this time.
    this.props.field.onChange(this.props.field.value);
  }

  getPlaceholder() {
    const { placeholder, placeholderKey, intl } = this.props;
    if (placeholderKey) {
      return intl.formatMessage({ id: placeholderKey });
    }
    return placeholder;
  }

  validateDate(nextProps) {
    const dateType = safe(() => this.props.type === 'date');
    if (!dateType) return;

    const prevValue = safe(() => this.props.field.value);
    const nextValue = safe(() => nextProps.field.value);

    if (prevValue !== nextValue) {
      this.dateValue = setDate(nextProps);
    }
  }

  addInfoTooltip(inputContent) {
    const { infoContent } = this.props;
    if (!infoContent) return inputContent;

    return (
      <span className="info-content">
        {inputContent}
        <span className="icon-container">
          <Tooltip {...addTippyContent(infoContent)}>
            <ShowInfoIcon style={iconStyles.all} />
          </Tooltip>
        </span>
      </span>
    );
  }

  getActionButton() {
    if (this.props.onAction) {
      return (
        <button type="button" className="boxedInput_button" onClick={this.handleClick}>
          <FormattedMessage id={this.props.actionText || 'boxedInput_defaultActionMsg'} />
        </button>
      );
    }
  }

  isPasswordInput() {
    return this.props.type === 'password';
  }

  render() {
    const actionButton = this.getActionButton();
    const passwordInput = this.isPasswordInput();

    let boxedInputLabel =
      !this.props.label && !this.props.labelKey ? (
        ''
      ) : (
        <span
          className={`
          boxedInput_labelText
          ${!this.props.hideLabel ? '' : '_is_hidden'}
          ${this.props.customLabelTextClass || ''}
        `}
        >
          {this.props.labelKey ? <FormattedMessage id={this.props.labelKey} /> : this.props.label}
          {this.props.mandatory && <span className="labelMandatory">*</span>}

          {this.props.toolTipText && this.props.toolTipText.length > 0 && (
            <span
              className={`
            boxedInput__toolTip hint--right hint--no-animate hint--large ${_get(this.props, 'customToolTipClass', '')}`}
              aria-label={this.props.toolTipText}
            >
              {'\u003F'}
            </span>
          )}
        </span>
      );

    let inputContent;

    switch (this.props.type) {
      case 'color':
        inputContent = this.getColorInput();
        break;
      case 'time':
        inputContent = (
          <TimePicker
            format="24hr"
            hintText={this.getPlaceholder()}
            className={`${this.mainClassName} ${!this.props.type ? '' : `${this.mainClassName}--${this.props.type}`}`}
            id={_get(this.props, 'id', _get(this.props, 'field.name'))}
            value={this.timeValue}
            disabled={this.props.disabled || this.props.readOnly}
            onChange={this.handleTimeChange}
          />
        );
        break;
      case 'date':
        inputContent = (
          <DatePicker
            autoOk
            hintText={this.getPlaceholder()}
            className={`${this.mainClassName} ${!this.props.type ? '' : `${this.mainClassName}--${this.props.type}`}`}
            id={_get(this.props, 'id', _get(this.props, 'field.name'))}
            value={this.dateValue}
            minDate={this.props.minDate}
            maxDate={this.props.maxDate}
            onChange={this.handleDateChange}
            onBlur={this.handleDateBlurFix}
            shouldDisableDate={this.props.shouldDisableDate}
            locale={getMaterialLocale(this.props.locale)}
            DateTimeFormat={global.Intl.DateTimeFormat}
            formatDate={this.formatDate}
            disableYearSelection={this.props.disableYearSelection}
            disabled={this.props.disabled || this.props.readOnly}
            textFieldStyle={{
              fontFamily: 'inherit',
              fontSize: '12px',
              ...this.props.datePickerTextFieldStyle
            }}
          />
        );
        break;
      case 'textarea':
        inputContent = (
          <textarea
            placeholder={this.getPlaceholder()}
            className={classNames(
              `${this.mainClassName} ${!this.props.type ? '' : `${this.mainClassName}--${this.props.type}`}`,
              this.props.customInputClass
            )}
            id={this.props.id}
            maxLength={toNumber(this.props.maxlength)}
            readOnly={this.props.readOnly || ''}
            disabled={this.props.disabled || this.props.readOnly}
            {...this.props.field}
            onChange={this.handleChange}
            autoFocus={this.props.autoFocus}
          />
        );
        break;

      default:
        inputContent = (
          <input
            type={this.state.showPassword ? 'text' : this.props.type}
            placeholder={this.getPlaceholder()}
            className={classNames(
              `${this.mainClassName} ${!this.props.type ? '' : `${this.mainClassName}--${this.props.type}`}`,
              this.props.customInputClass
            )}
            id={this.props.id}
            maxLength={toNumber(this.props.maxlength)}
            readOnly={this.props.readOnly || ''}
            min={this.props.min}
            max={this.props.max}
            disabled={this.props.disabled || this.props.readOnly}
            value={this.props.value}
            {...this.props.field}
            autoFocus={this.props.autoFocus}
            ref={this.props.inputRef}
            onChange={this.handleChange}
          />
        );
    }

    inputContent = this.addInfoTooltip(inputContent);

    return (
      <div
        className={classNames('boxedInputWrapper', this.props.customClass, {
          boxedInputWrapper_disabled: this.props.disabled || this.props.readOnly,
          [`boxedInputWrapper--${this.props.skinType}`]: this.props.skinType
        })}
      >
        {this.props.type === 'date' ? (
          <label className={classNames('boxedInput_label', this.props.customLabelClass)} htmlFor={this.props.id}>
            {boxedInputLabel}
            {inputContent}
            {actionButton}
          </label>
        ) : (
          <div className={classNames('boxedInput_label', this.props.customLabelClass)}>
            {boxedInputLabel}
            <div className={classNames('boxedInput_inputText', { boxedInput_password: passwordInput })}>
              {inputContent}
              {passwordInput ? this.getPasswordButton() : actionButton}
            </div>
          </div>
        )}
        {this.props.children}
      </div>
    );
  }
}

BoxedInput.propTypes = {
  infoContent: PropTypes.any, // if present a tooltip will be added to the field to show HTML content
  onAction: PropTypes.func,
  minDate: PropTypes.any,
  maxDate: PropTypes.any,
  actionText: PropTypes.string,
  customClass: PropTypes.string,
  placeholder: PropTypes.string,
  placeholderKey: PropTypes.string,
  dateFormat: PropTypes.string,
  type: PropTypes.string,
  skinType: PropTypes.string,
  label: PropTypes.any,
  labelKey: PropTypes.string,
  id: PropTypes.string,
  inputRef: PropTypes.func,
  maxlength: PropTypes.string,
  min: PropTypes.number,
  max: PropTypes.number,
  hideLabel: PropTypes.bool,
  autoFocus: PropTypes.bool,
  readOnly: PropTypes.bool,
  customLabelTextClass: PropTypes.string,
  customInputClass: PropTypes.string,
  onChange: PropTypes.func,
  disableYearSelection: PropTypes.bool,
  shouldDisableDate: PropTypes.func,
  disabled: PropTypes.bool,
  toolTipText: PropTypes.string,
  customToolTipClass: PropTypes.string,
  value: PropTypes.string,
  datePickerTextFieldStyle: PropTypes.object,
  jsonDateFormat: PropTypes.string
};

BoxedInput.childContextTypes = {
  muiTheme: React.PropTypes.object
};

export default namedCompose(
  connect(state => ({
    locale: localeSelector(state)
  })),
  injectIntl
)(BoxedInput);

export const getBoxedInputField = memoizeOne(value => ({ name: shortid(), value, onChange: fallbackFunc }));
