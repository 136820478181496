// noinspection ES6CheckImport
import React, { Component, PropTypes } from 'react';
import { FormattedMessage } from 'react-intl';

import _map from 'lodash/map';
import _get from 'lodash/get';

import FlatButton from 'material-ui/FlatButton';

import CreditCardIcon from 'material-ui/svg-icons/action/credit-card';
import PlaceIcon from 'material-ui/svg-icons/maps/place';
import FileUploadIcon from 'material-ui/svg-icons/file/file-upload';
import DateRangeIcon from 'material-ui/svg-icons/action/date-range';
import TodayIcon from 'material-ui/svg-icons/action/today';
import EventIcon from 'material-ui/svg-icons/action/event';
import FileImage from '../FileImage/FileImage.jsx';
import DetailIcon from 'material-ui/svg-icons/image/picture-as-pdf';
import ImagePreview from '../../components/ImagePreview/ImagePreview';
import IsGranted from '../IsGranted/IsGranted';
import { iconStyles, MemberBasicInfoStyles } from '../../constants/style-constants';
import { fillReactArray, getAppFormattedDate, hideDriverImagesForCustomCompanyId } from '../../utils/utils';
import { BACKUSER_ROLE_FLEET_MANAGER, STATUS_APPROVED, STATUS_NOT_APPROVED } from '../../constants/backend-constants';
import Tooltip from '../Tooltip/Tooltip';
import { memberDetailEditRules } from '../../constants/routes-constants';
import { FileImageStyles } from '../../constants/style-constants';
import PdfModal from '../PdfModal/PdfModal';

class MemberDriverInfo extends Component {
  constructor(props) {
    super(props);
    this.state = { pdfPreview: '' };
    this.handleClose = this.handleClose.bind(this);
    this.handleOpen = this.handleOpen.bind(this);
  }

  handleOpen(url) {
    this.setState({ pdfPreview: url });
  }

  handleClose() {
    this.setState({ pdfPreview: '' });
  }

  render() {
    const { detailMember, drivingLicenseFiles, handleApproveInfo, handleRejectInfo, enabledActions } = this.props;
    const { company, drivingLicence, anonymizationEffectiveDate } = detailMember || {};
    const { id: companyId } = company || {};
    const { cName } = this.props;

    const {
      status: licenseStatus,
      lastStatusUpdate,
      licenceNumber: licenseNumber,
      cityDeliverance,
      uploadDate,
      deliveranceDate,
      expirationDate,
      files
    } = drivingLicence || {};

    const { length: filesLength } = files || {};
    const { length: globalFilesLength } = drivingLicenseFiles || {};

    return (
      <div className={cName || 'memberView_driverInfo'}>
        <h4>
          <FormattedMessage id="members_detail_driver_subtitle" />
          {licenseStatus && (
            <span>
              <span className="detailView_dash">-</span>
              <span className="detailView_component_headline_status">
                <Tooltip content={<FormattedMessage id="members_detail_driver_documentValidationStatus" />} placement="bottom">
                  <span className={`member_status--${licenseStatus.toLowerCase()}`}>
                    <FormattedMessage id={`members_status_${licenseStatus.toLowerCase()}`} />
                  </span>
                </Tooltip>
              </span>
            </span>
          )}
          {licenseStatus && lastStatusUpdate && (
            <Tooltip content={<FormattedMessage id="members_detail_driver_approvalRejectionDate" />} placement="bottom">
              <span className="detailView_component_headline_date">{getAppFormattedDate(lastStatusUpdate)}</span>
            </Tooltip>
          )}
        </h4>
        <div className="memberView_driverInfo_body">
          <div className="memberView_driverInfo_main">
            <div className="detailView_table">
              {licenseNumber && (
                <div className="detailView_row">
                  <div className="detailView_cell_type_1">
                    <Tooltip content={<FormattedMessage id="members_detail_driver_licence_number" />} placement="bottom">
                      <CreditCardIcon style={iconStyles.all} />
                    </Tooltip>
                  </div>
                  <div className="detailView_cell_type_1">{licenseNumber}</div>
                </div>
              )}
              {cityDeliverance && (
                <div className="detailView_row">
                  <div className="detailView_cell_type_1">
                    <Tooltip content={<FormattedMessage id="members_detail_driver_city_delivrance" />} placement="bottom">
                      <PlaceIcon style={iconStyles.all} />
                    </Tooltip>
                  </div>
                  <div className="detailView_cell_type_1">{cityDeliverance}</div>
                </div>
              )}
              {uploadDate && (
                <div className="detailView_row">
                  <div className="detailView_cell_type_1">
                    <Tooltip content={<FormattedMessage id="members_detail_driver_uploadDate" />} placement="bottom">
                      <FileUploadIcon style={iconStyles.all} noPadding />
                    </Tooltip>
                  </div>
                  <div className="detailView_cell_type_1">{getAppFormattedDate(uploadDate)}</div>
                </div>
              )}
              {deliveranceDate && expirationDate && (
                <div className="detailView_row">
                  <div className="detailView_cell_type_1">
                    <Tooltip
                      content={
                        <span>
                          <FormattedMessage id="members_detail_driver_deliverance_date" />
                          <span className="detailView_dash">-</span>
                          <FormattedMessage id="members_detail_driver_expiration_date" />
                        </span>
                      }
                      placement="bottom"
                    >
                      <DateRangeIcon style={iconStyles.all} />
                    </Tooltip>
                  </div>
                  <div className="detailView_cell_type_1">
                    {getAppFormattedDate(deliveranceDate)}
                    <span className="detailView_dash">-</span>
                    {getAppFormattedDate(expirationDate)}
                  </div>
                </div>
              )}
              {deliveranceDate && !expirationDate && (
                <div className="detailView_row">
                  <div className="detailView_cell_type_1">
                    <Tooltip content={<FormattedMessage id="members_detail_driver_deliverance_date" />} placement="bottom">
                      <TodayIcon style={iconStyles.all} />
                    </Tooltip>
                  </div>
                  <div className="detailView_cell_type_1">{getAppFormattedDate(deliveranceDate)}</div>
                </div>
              )}
              {!deliveranceDate && expirationDate && (
                <div className="detailView_row">
                  <div className="detailView_cell_type_1">
                    <Tooltip content={<FormattedMessage id="members_detail_driver_expiration_date" />} placement="bottom">
                      <EventIcon style={iconStyles.all} />
                    </Tooltip>
                  </div>
                  <div className="detailView_cell_type_1">{getAppFormattedDate(expirationDate)}</div>
                </div>
              )}
            </div>
            {filesLength > 0 && !hideDriverImagesForCustomCompanyId(companyId, licenseStatus) && (
              <div className={`memberView_driverInfo_files_${filesLength}`}>
                {_map(drivingLicenseFiles, (item, index) => {
                  const url = _get(item, 'url');
                  return _get(item, 'url') ? (
                    url.includes('.pdf') ? (
                      <div onClick={() => this.handleOpen(url)}>
                        <DetailIcon style={FileImageStyles.icons.pdf} />
                      </div>
                    ) : (
                      <ImagePreview
                        key={_get(item, 'id') + '-' + index}
                        thumbnailSrc={_get(item, 'thumbnailurl')}
                        src={url}
                        alt={_get(item, 'id')}
                      />
                    )
                  ) : (
                    <FileImage key={_get(item, 'id') + '-' + index} imageObject={item} />
                  );
                })}
                {fillReactArray(<FileImage />, filesLength - (globalFilesLength || 0))}
              </div>
            )}
          </div>
          {enabledActions && (
            <IsGranted disallowed={[BACKUSER_ROLE_FLEET_MANAGER, ...memberDetailEditRules.exclude]} allowed={memberDetailEditRules.include}>
              <div className="memberView_reviewButtonsContainer">
                {licenseStatus !== STATUS_NOT_APPROVED && !anonymizationEffectiveDate && handleRejectInfo && (
                  <FlatButton
                    id="memberView_driverInfo_reject"
                    label={<FormattedMessage id="common_reject" />}
                    onClick={handleRejectInfo}
                    style={MemberBasicInfoStyles.buttons.flat}
                  />
                )}
                {licenseStatus !== STATUS_APPROVED && !anonymizationEffectiveDate && handleApproveInfo && (
                  <FlatButton
                    id="memberView_driverInfo_approve"
                    label={<FormattedMessage id="common_approve" />}
                    onClick={handleApproveInfo}
                    style={MemberBasicInfoStyles.buttons.flat}
                  />
                )}
              </div>
            </IsGranted>
          )}
        </div>
        {this.state.pdfPreview && (
          <PdfModal
            show={this.state.pdfPreview}
            titleKey="companyForm_genericPdfDocumentView_label"
            src={this.state.pdfPreview}
            onClose={this.handleClose}
          />
        )}
      </div>
    );
  }
}

MemberDriverInfo.displayName = 'MemberDriverInfo';

MemberDriverInfo.defaultProps = {
  drivingLicenseFiles: []
};

MemberDriverInfo.propTypes = {
  detailMember: PropTypes.object.isRequired,
  drivingLicenseFiles: PropTypes.array,
  handleApproveInfo: PropTypes.func,
  handleRejectInfo: PropTypes.func
};

export default MemberDriverInfo;
