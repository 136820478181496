import React, { Component } from 'react';
import { append, getWebApiUrl, isEmpty, isValidId, newProp, safe, setBase64, updateMobileViewTag } from '../../../utils/utils';

import {
  clearCustomFieldsMember,
  getMemberDetail,
  getCustomFieldsFile,
  getCustomFieldsMember,
  updateBasicMember,
  getImpersonateData
} from '../../../actions/members-actions';

import EditMemberFilesForm from './form';
import { getStore } from '../../../store/all-store';
import { getBasicFile } from '../../../actions/file-actions';
import { getBasicCompany } from '../../../actions/companies-actions';
import { FRONT, BACK, SELFIE, LOADING, applicationToFoUrlBrandMap } from '../../../constants/generic-constants';
import config from '../../../constants/config-constants';
import { cleanUserDataForApi, setBackFile, setFrontFile, setSelfieFile } from './utils';

import { CUSTOM_FIELD_TYPE_FILE } from '../../../constants/backend-constants';
import { connect } from 'react-redux';
import { profileCustomFieldsSelector } from '../../../selectors/all-selectors';
import { getSubscriptionCustomFields } from '../../../actions/customFields-actions';
import _reduce from 'lodash/reduce';
import { successMessage } from '../../../actions/flashMessage-actions';

const selfieAllowed = {
  'fb10f1b9-abb4-483a-a308-8afae9749d57': true,
  'bf5fee16-55d1-433f-9207-609a19f8e666': true,
  '9e285023-e1a6-43df-b02b-f27b724f23a3': true
};

class EditMemberFiles extends Component {
  componentWillMount() {
    this.setVars();
    this.setCallbacks();
    this.initProps();
    updateMobileViewTag(true);
  }

  componentWillUnmount() {
    updateMobileViewTag();
  }

  setVars() {
    this.state = {};
    this.dispatch = getStore().dispatch;
  }

  initProps() {
    this.componentPropsUpdated(this.props);
    this.propsInit = true;
  }

  componentWillReceiveProps(props) {
    this.componentPropsUpdated(props);
  }

  componentPropsUpdated(props) {
    this.updateMemberData(props);
    this.updateCustomFiles(props);
  }

  updateMemberData(props) {
    if (newProp.call(this, props, 'params')) {
      const { memberId } = props.params;

      this.resetData();
      this.getUserData(memberId);
      this.getCustomFields(memberId);
    }
  }

  updateCustomFiles(props) {
    if (newProp.call(this, props, 'customFields')) {
      this.updateCustomFieldFiles(props);
    }
  }

  updateCustomFieldFiles(props) {
    safe(() => {
      props.customFields.map(field => {
        safe(() => {
          if (field.companyCustomField.fieldType === CUSTOM_FIELD_TYPE_FILE && field.value) {
            this.dispatch(getCustomFieldsFile(field.value));
          }
        });
      });
    });
  }

  clearState() {
    this.state = {};
    this.setState({});
  }

  saveDrivingLicence(data, { licenceFront, licenceBack }) {
    if (safe(() => this.company.drivingLicenceRequired)) {
      const drivingFiles = safe(() => data.drivingLicence.files);

      const frontId = safe(() => drivingFiles.find(f => f.type === FRONT).fileId);
      const backId = safe(() => drivingFiles.find(f => f.type === BACK).fileId);

      data.drivingLicence = {
        ...data.drivingLicence,
        files: append([setFrontFile(licenceFront, frontId), setBackFile(licenceBack, backId)])
      };
    }
  }

  saveIdentityDocuments(data, { identityFront, identityBack, selfie }) {
    if (safe(() => this.company.identityDocumentRequired)) {
      const identityFiles = safe(() => data.identityDocument.files);

      const frontId = safe(() => identityFiles.find(f => f.type === FRONT).fileId);
      const backId = safe(() => identityFiles.find(f => f.type === BACK).fileId);
      const selfieId = safe(() => identityFiles.find(f => f.type === SELFIE).fileId);

      const docs = [setFrontFile(identityFront, frontId), setBackFile(identityBack, backId)];

      if (config.devMode || selfieAllowed[this.company.id]) {
        docs.push(setSelfieFile(selfie, selfieId));
      }

      data.identityDocument = { files: append(docs) };
    }
  }

  formatCustomFieldsForApi(userData = {}, formValues = {}) {
    const customFieldIds = safe(() =>
      this.props.customFields.reduce((fields, field) => {
        safe(() => (fields[field.companyCustomField.id] = field.value));
        return fields;
      }, {})
    );

    userData.memberCustomValues = _reduce(
      formValues,
      (r, v, k) => {
        const value = isValidId(v) ? v : customFieldIds[k];

        if (isValidId(k) && !isEmpty(value)) {
          r.push({
            companyCustomFieldId: k,
            value
          });
        }

        return r;
      },
      []
    );
  }

  setCallbacks() {
    this.saveFiles = files => {
      let data = { ...this.member };

      this.saveDrivingLicence(data, files);
      this.saveIdentityDocuments(data, files);
      this.formatCustomFieldsForApi(data, files);

      this.dispatch(updateBasicMember(this.member.id, cleanUserDataForApi(data))).then(() => {
        this.dispatch(successMessage);
      });
    };
  }

  setApiUrl({ id } = {}) {
    this.dispatch(getImpersonateData(id)).then(
      ({ application } = {}) => {
        this.setState({ apiUrl: getWebApiUrl(applicationToFoUrlBrandMap[application]) });
      },
      () => {
        this.setState({ apiUrl: getWebApiUrl() });
      }
    );
  }

  setDrivingFiles(data) {
    const licenceFiles = safe(() => data.drivingLicence.files);
    const frontId = safe(() => licenceFiles.find(f => f.type === FRONT).fileId);
    const backId = safe(() => licenceFiles.find(f => f.type === BACK).fileId);

    if (frontId) {
      this.setState({ licenceFront: LOADING });

      this.dispatch(getBasicFile(frontId)).then(
        data => this.setState({ licenceFront: setBase64(data) }),
        () => this.setState({ licenceFront: '' })
      );
    }

    if (backId) {
      this.setState({ licenceBack: LOADING });

      this.dispatch(getBasicFile(backId)).then(
        data => this.setState({ licenceBack: setBase64(data) }),
        () => this.setState({ licenceBack: '' })
      );
    }
  }

  setIdentityFiles(data) {
    const identityFiles = safe(() => data.identityDocument.files);
    const frontId = safe(() => identityFiles.find(f => f.type === FRONT).fileId);
    const backId = safe(() => identityFiles.find(f => f.type === BACK).fileId);

    if (frontId) {
      this.setState({ identityFront: LOADING });

      this.dispatch(getBasicFile(frontId)).then(
        data => this.setState({ identityFront: setBase64(data) }),
        () => this.setState({ identityFront: '' })
      );
    }

    if (backId) {
      this.setState({ identityBack: LOADING });

      this.dispatch(getBasicFile(backId)).then(
        data => this.setState({ identityBack: setBase64(data) }),
        () => this.setState({ identityBack: '' })
      );
    }
  }

  setSelfieFile(data) {
    const fileId = safe(() => data.identityDocument.files.find(f => f.type === SELFIE).fileId);

    if (fileId) {
      this.setState({ selfie: LOADING });

      this.dispatch(getBasicFile(fileId)).then(
        data => this.setState({ selfie: setBase64(data) }),
        () => this.setState({ selfie: '' })
      );
    }
  }

  getCustomFields(memberId) {
    safe(() => this.dispatch(getCustomFieldsMember(memberId)));
  }

  resetData() {
    this.dispatch(clearCustomFieldsMember());
    this.clearState();
    this.member = '';
    this.company = '';
  }

  getUserData(memberId) {
    this.dispatch(getMemberDetail(memberId)).then(({ member } = {}) => {
      const companyId = safe(() => member.company.id);
      this.setState({ member });
      this.member = member;

      safe(() => {
        this.setState({ apiUrl: '' });
        this.setApiUrl(member);
      });

      safe(() => {
        this.dispatch(getSubscriptionCustomFields(companyId));
      });

      safe(() =>
        this.dispatch(getBasicCompany(companyId)).then(company => {
          this.company = company;

          if (safe(() => company.drivingLicenceRequired)) {
            this.setState({ licenceFront: '', licenceBack: '' });
            this.setDrivingFiles(member);
          }

          if (safe(() => company.identityDocumentRequired)) {
            this.setState({ identityFront: '', identityBack: '' });
            this.setIdentityFiles(member);

            if (config.devMode || selfieAllowed[companyId]) {
              this.setState({ selfie: '' });
              this.setSelfieFile(member);
            }
          }
        })
      );
    });
  }

  render() {
    return (
      <div className="pageContainer">
        <div className="edit-member-files">
          <EditMemberFilesForm stateData={this.state} callback={this.saveFiles} />
        </div>
      </div>
    );
  }
}

EditMemberFiles.displayName = 'EditMemberFiles';

EditMemberFiles = connect(state => {
  return { customFields: profileCustomFieldsSelector(state) };
})(EditMemberFiles);

export default EditMemberFiles;
