// noinspection ES6CheckImport
import React, { PropTypes } from 'react';
import { FormattedMessage } from 'react-intl';
import { reduxForm } from 'redux-form';
import _ from 'lodash';

import FormRow from '../FormRow/FormRow';
import BoxedInput from '../BoxedInput/BoxedInput';
import EkButton from '../EkButton/EkButton';
import FieldErrorMsg from '../FieldErrorMsg/FieldErrorMsg';

import { createValidator, notEmpty } from '../../validation/all-validation';
import { scrollToFirstError } from '../../utils/utils';

const MemberCommentForm = props => {
  const {
    fields: { comment },
    handleSubmit,
    type
  } = props;
  const defaultFormName = 'member_commentForm';

  return (
    <section className="memberCommentFormWrapper">
      <form action="#" className="memberCommentForm" onSubmit={handleSubmit(props.onCallback)}>
        <div className="memberCommentForm_container">
          <h4 className="memberCommentForm_title">
            <FormattedMessage id={`memberCommentForm_title_${type.toLowerCase()}`} />
          </h4>

          <FormRow customClass="memberCommentForm_row">
            <BoxedInput
              formRowItemKey="memberCommentForm_comment"
              id={_.get(props, 'formName', defaultFormName) + '_text_area'}
              customClass="boxedInput_inputText"
              customInputClass="boxedInput_inputTextArea"
              labelKey={`memberCommentForm_comment_${type.toLowerCase()}`}
              type="textarea"
              field={comment}
            >
              <FieldErrorMsg field={comment} customClass="fieldErrorMsg--memberCommentForm" />
            </BoxedInput>
          </FormRow>

          <FormRow customClass="memberCommentForm_row">
            <EkButton
              id={_.get(props, 'formName', defaultFormName) + '_cancel_button'}
              type="button"
              skinType="reverse"
              formRowItemKey="memberCommentForm_abortBtn"
              customClass="feedbackAddComment_button"
              onAction={props.onAbort}
            >
              <FormattedMessage id={`memberCommentForm_abort_button_${type.toLowerCase()}`} />
            </EkButton>

            <EkButton
              id={_.get(props, 'formName', defaultFormName) + '_push_button'}
              type="button"
              formRowItemKey="memberCommentForm_submitBtn"
              customClass="feedbackAddComment_button"
              onAction={handleSubmit(props.onCallback)}
            >
              <FormattedMessage id={`memberCommentForm_save_button_${type.toLowerCase()}`} />
            </EkButton>
          </FormRow>
        </div>
      </form>
    </section>
  );
};

MemberCommentForm.displayName = 'MemberCommentForm';

MemberCommentForm.propTypes = {
  fields: PropTypes.object,
  handleSubmit: PropTypes.func,
  onCallback: PropTypes.func,
  onAbort: PropTypes.func,
  type: PropTypes.string,
  formName: PropTypes.string // used as button, text area ID prefix
};

export default reduxForm({
  onSubmitFail: scrollToFirstError,
  form: 'memberCommentForm',
  fields: ['comment'],
  validate: createValidator({
    comment: [notEmpty()]
  })
})(MemberCommentForm);
