import React, { Component, PropTypes } from 'react';

class FormRow extends Component {
  render() {
    let options;

    const wrapOption = (item, key = 'key', index) => {
      let elementCustomClass = this.props.customElements && this.props.customClass ? this.props.customClass + '_' + index : '';

      return (
        <div className={`formRow_item ${this.props.customClass || ''} ${elementCustomClass}`} key={`${key}-${index}`}>
          {item}
        </div>
      );
    };

    if (Array.isArray(this.props.children)) {
      options = this.props.children
        .filter(a => a)
        .map(function(optionItem, index) {
          return wrapOption(optionItem, optionItem.props.formRowItemKey, index);
        });
    } else {
      options = wrapOption(this.props.children, 1);
    }

    return <div className={`formRow ${this.props.customWrapperClass || ''}`}>{options}</div>;
  }
}

FormRow.displayName = 'FormRow';

FormRow.propTypes = {
  customWrapperClass: PropTypes.string,
  customClass: PropTypes.string
};

export default FormRow;
