import React, { Component } from 'react';
import autoBind from 'react-autobind';
import { connect } from 'react-redux';
import _get from 'lodash/get';
import { bundleSelector, localeSelector } from '../../../selectors/all-selectors';
import AdvancedList from '../../../components/AdvancedList/AdvancedList';
import { apiParams } from '../../../constants/api-params-constants';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';

import { Col, Row } from 'react-bootstrap';
import pure from 'recompose/pure';

import { getAppFormattedDate, getAppFormattedTime, namedCompose } from '../../../utils/utils';

import { getFieldAgents, getVehicleTasks, addVehicleTask, vehicleClearTasks } from '../../../actions/vehicles-actions';
import TaskForm from '../../../components/TaskForm/TaskForm';
import { vehicleTaskTypes, vehicleTaskStatuses } from '../../../constants/options-constants';

class VehicleTasks extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.setTasksData();
    this.agentsOptions = [];
  }

  componentDidMount() {
    this.callTasksApi(
      {
        size: apiParams.default.page.size,
        ...this.props.tasksParams
      },
      true
    );
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.agentsList !== this.props.agentsList) {
      this.agentsOptionsParser(nextProps.agentsList);
    }
  }

  componentWillUnmount() {
    this.props.dispatch(vehicleClearTasks());
  }

  setTasksData() {
    this.setColProps();
    this.setTasksHead();
  }

  setColProps() {
    this.colProps = {};

    this.colProps['taskType'] = {
      md: 2,
      xs: 2
    };

    this.colProps['fieldAgent'] = {
      md: 4,
      xs: 4
    };

    this.colProps['startDate'] = {
      md: 2,
      xs: 2
    };

    this.colProps['startTime'] = {
      md: 2,
      xs: 2
    };

    this.colProps['status'] = {
      md: 2,
      xs: 2
    };
  }

  setTasksHead() {
    this.tasksHead = [
      {
        labelKey: 'vehicleDetail_tasksTab_tasktype',
        ...this.colProps['taskType']
      },
      {
        labelKey: 'vehicleDetail_tasksTab_FieldAgent',
        ...this.colProps['fieldAgent']
      },
      {
        labelKey: 'vehicleDetail_tasksTab_startDate',
        ...this.colProps['startDate']
      },
      {
        labelKey: 'vehicleDetail_tasksTab_startTime',
        ...this.colProps['startTime']
      },
      {
        labelKey: 'vehicleDetail_tasksTab_status',
        ...this.colProps['status']
      }
    ];
  }

  callTasksApi(data, loader) {
    const { detailVehicle, dispatch } = this.props;
    const vehicleId = _get(detailVehicle, 'id');
    const params = data || {};

    dispatch(getVehicleTasks(vehicleId, loader));
    dispatch(getFieldAgents());
  }

  handleRefreshTasks() {
    this.callTasksApi(this.props.tasksParams);
  }

  handlePageSizeSelect(value) {
    const { tasksParams } = this.props;
    const params = {
      number: 1,
      size: value
    };

    this.callTasksApi({ ...tasksParams, ...params });
  }

  handleChangePage(value) {
    const { damagesMetadata, damagesParams } = this.props;

    const size = _get(damagesMetadata, 'paginationInfo.pageSize');
    const def = apiParams.default.page.size;

    const params = {
      number: value,
      size: size || def
    };

    const newParams = { ...damagesParams, ...params };
    this.callReportsApi(newParams);
  }

  handleAddTask(form) {
    const { dispatch } = this.props;
    dispatch(addVehicleTask(form)).then(() => this.handleRefreshTasks());
  }

  parseType(value) {
    const key = _get(vehicleTaskTypes, value, value);
    return <FormattedMessage id={key} />;
  }

  parseStatus(value) {
    const key = _get(vehicleTaskStatuses, value, value);
    return <FormattedMessage id={key} />;
  }

  renderTasks(item, id, reactKey) {
    const rawDate = _get(item, 'startDate');
    const type = _get(item, 'type');
    const status = _get(item, 'status');
    const fieldAgent = _get(item, 'fieldAgent.firstName', '') + ' ' + _get(item, 'fieldAgent.lastName', '');
    const date = getAppFormattedDate(rawDate, { local: true });
    const time = getAppFormattedTime(rawDate, { local: true });

    return (
      <Row className={classNames('advancedList_row', 'nonClickable')} id={id} key={reactKey}>
        <Col {...this.colProps['taskType']}>
          <div>{this.parseType(type)}</div>
        </Col>
        <Col {...this.colProps['fieldAgent']}>{fieldAgent}</Col>
        <Col {...this.colProps['startDate']}>{date}</Col>
        <Col {...this.colProps['startTime']}>{time}</Col>
        <Col {...this.colProps['status']}>{this.parseStatus(status)}</Col>
      </Row>
    );
  }

  agentsOptionsParser(list) {
    this.agentsOptions = list.map(item => {
      return { label: item.firstName + ' ' + item.lastName, value: item.id };
    });
  }

  render() {
    return (
      <div>
        <div className="add-task-form">
          <TaskForm agents={this.agentsOptions} onCallback={this.handleAddTask} />
        </div>
        <div className="tasks-list">
          <AdvancedList
            id="tasks-list"
            data={this.props.tasksList}
            listMetadata={this.props.tasksMetadata}
            loading={this.props.tasksLoading}
            error={this.props.tasksError}
            onRefreshList={this.handleRefreshTasks}
            onChangeRowsPerPage={this.handlePageSizeSelect}
            onChangePage={this.handleChangePage}
            renderItem={this.renderTasks}
            noResultsLabelKey="common_no_results"
            header={this.tasksHead}
            urlParams={this.props.tasksParams}
            renderAnchor={this.props.locale}
          />
        </div>
      </div>
    );
  }
}

VehicleTasks.displayName = 'VehicleTasks';

export default namedCompose(
  connect(state => {
    const {
      vehicles: { detailVehicle, tasksParams, tasksError, tasksList, tasksLoading, tasksMetadata, agentsList }
    } = state;

    return {
      detailVehicle,
      tasksParams,
      tasksError,
      tasksList,
      tasksLoading,
      tasksMetadata,
      agentsList,
      bundle: bundleSelector(state),
      locale: localeSelector(state)
    };
  }),
  pure
)(VehicleTasks);
