import React, { Component } from 'react';
import { connect } from 'react-redux';

import TableView from '../../components/TableView/TableView';
import PageManagementHeader from '../../components/PageManagementHeader/PageManagementHeader';
import routes, { siteEditRules } from '../../constants/routes-constants';
import { BACKUSER_ROLE_CALL_CENTER_OPERATOR } from '../../constants/backend-constants';
import { sitesTableViewParams } from '../../constants/table-constants';
import { subCompaniesByIdObjectSelector } from '../../selectors/all-selectors';
import { checkRole } from '../../constants/backuser-role-rules';

class Sites extends Component {
  render() {
    const { list, subCompaniesByIdObject, role } = this.props;

    const readOnly = role === BACKUSER_ROLE_CALL_CENTER_OPERATOR || !checkRole(siteEditRules, role);

    return (
      <div className="sitesPage mainContainer_content">
        <div className="pageContainer">
          <section className="sitesPage_management">
            <PageManagementHeader
              titleKey="sites_subtitle"
              href={`#${routes.addSite.path}`}
              buttonLabelKey="sites_add"
              customClass="sitesPage_managementHeader"
              limitedAccess={readOnly}
            />

            <div className="sitesPage_list">
              <TableView
                viewAccess={readOnly}
                customClass="sitesPage_tableViewWrapper"
                params={sitesTableViewParams(subCompaniesByIdObject)}
                content={list}
              />
            </div>
          </section>
        </div>
      </div>
    );
  }
}

Sites.displayName = 'Sites';

Sites.propTypes = {};

export default connect(state => {
  const {
    sites: { list },
    user: {
      userInfo: { role }
    }
  } = state;

  return {
    list,
    subCompaniesByIdObject: subCompaniesByIdObjectSelector(state),
    role
  };
})(Sites);
