import React, { Component } from 'react';
import { reduxForm } from 'redux-form';
import { scrollToFirstError } from '../../../../utils/utils';
import LabeledCheckbox from '../../../../components/LabeledCheckbox/LabeledCheckbox';
import { contractReportingSettingsValuesSelector } from '../../../../selectors/all-selectors';
import { RaisedButton } from 'material-ui';
import { getMsg } from '../../../../utils/IntlGlobalProvider';
import { FormattedMessage } from 'react-intl';
import { otherReportingFields, quickSightTabFields } from '../../../../constants/options-constants';

class ReportingSettings extends Component {
  componentWillMount() {
    this.onReportingQuickSightUncheck = value => {
      const { fields: f } = this.props;

      if (!value) {
        for (let field of quickSightTabFields) {
          if (f[field].value) f[field].onChange();
        }
      }
    };
  }

  render() {
    const { fields: f, limitedAccess } = this.props;

    return (
      <div className="contract-member-info">
        <div className="sc-option-details">
          <div className="sc-flat-boxes">
            <LabeledCheckbox id="vehicleStatus" field={f.vehicleStatus} disabled={limitedAccess}>
              <FormattedMessage id="contract.reporting.vehicleStatus" />
            </LabeledCheckbox>
            <LabeledCheckbox id="damageReport" onCheck={this.onReportingQuickSightUncheck} field={f.quickSight} disabled={limitedAccess}>
              <FormattedMessage id="contract.reporting.quicksight" />
            </LabeledCheckbox>
            {f.quickSight.value && (
              <div className="sc-option-tab">
                <LabeledCheckbox id="completedBooking" field={f.completedBooking} disabled={limitedAccess}>
                  <FormattedMessage id="reporting.feature.completedBooking" />
                </LabeledCheckbox>
                <LabeledCheckbox id="averageDistance" field={f.averageDistance} disabled={limitedAccess}>
                  <FormattedMessage id="reporting.feature.averageDistance" />
                </LabeledCheckbox>
                <LabeledCheckbox id="averageDuration" field={f.averageDuration} disabled={limitedAccess}>
                  <FormattedMessage id="reporting.feature.averageDuration" />
                </LabeledCheckbox>
                <LabeledCheckbox id="canceledBooking" field={f.canceledBooking} disabled={limitedAccess}>
                  <FormattedMessage id="reporting.feature.canceledBooking" />
                </LabeledCheckbox>
                <LabeledCheckbox id="lateBooking" field={f.lateBooking} disabled={limitedAccess}>
                  <FormattedMessage id="reporting.feature.lateBooking" />
                </LabeledCheckbox>
                <LabeledCheckbox id="lateBookingOvertime" field={f.lateBookingOvertime} disabled={limitedAccess}>
                  <FormattedMessage id="reporting.feature.lateBookingOvertime" />
                </LabeledCheckbox>
                <LabeledCheckbox id="bookingPerVehicle" field={f.bookingPerVehicle} disabled={limitedAccess}>
                  <FormattedMessage id="reporting.feature.bookingPerVehicle" />
                </LabeledCheckbox>
                <LabeledCheckbox id="businessPrivate" field={f.businessPrivate} disabled={limitedAccess}>
                  <FormattedMessage id="reporting.feature.businessPrivate" />
                </LabeledCheckbox>
                <LabeledCheckbox id="turnover" field={f.turnover} disabled={limitedAccess}>
                  <FormattedMessage id="reporting.feature.turnover" />
                </LabeledCheckbox>
                <LabeledCheckbox id="activeMember" field={f.activeMember} disabled={limitedAccess}>
                  <FormattedMessage id="reporting.feature.activeMember" />
                </LabeledCheckbox>
                <LabeledCheckbox id="newMembers" field={f.newMembers} disabled={limitedAccess}>
                  <FormattedMessage id="reporting.feature.newMembers" />
                </LabeledCheckbox>
              </div>
            )}
          </div>
        </div>
        {!limitedAccess && (
          <div className="sc-actions">
            <RaisedButton label={getMsg('common_save')} primary type="button" onClick={this.props.handleSubmit(this.props.onCallback)} />
          </div>
        )}
      </div>
    );
  }
}

ReportingSettings = reduxForm(
  {
    onSubmitFail: scrollToFirstError,
    form: 'contractReportingSettings',
    fields: [...otherReportingFields, ...quickSightTabFields]
  },
  state => ({
    initialValues: contractReportingSettingsValuesSelector(state)
  })
)(ReportingSettings);

export default ReportingSettings;
