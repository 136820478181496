import * as types from '../constants/actionTypes-constants';
import callApi from './api-actions';
import { getValues } from 'redux-form';
import { TIME_SLOT_USAGE_BUSINESS } from '../constants/backend-constants';
import { FLASH_MESSAGE_TYPE_ERROR, FLASH_MESSAGE_TYPE_SUCCESS } from '../constants/generic-constants';
import { addFlashMessage } from '../actions/all-actions';
import _isEmpty from 'lodash/isEmpty';
import { validatePriceSlots } from '../validation/all-validation';
import { bundleSelector } from '../selectors/all-selectors';
import { addErrorMessage } from '../utils/utils';

export function initMemberTypeId(memberTypeId) {
  return {
    type: types.PRICING_SET_INITIAL_MEMBER_TYPE_ID,
    memberTypeId
  };
}

export function callPricingBusinessTimeSlotsSuccess(vehicleCategoryId, data) {
  return {
    type: types.PRICING_GET_BUSINESS_TIME_SLOTS_SUCCESS,
    vehicleCategoryId,
    data
  };
}

export function requestGetPricingCategoryTimeSlots(vehicleCategoryId) {
  return (dispatch, getState) => {
    const state = getState();
    const companyId = state.companies.currentCompany.id;
    const memberTypeId = state.pricing.memberTypeId;

    const params = {
      companyId,
      query: {
        companyId,
        memberTypeId,
        vehicleCategoryId
      }
    };

    return dispatch(callApi('pricing', 'getTimeSlots', params, false)).then(data => {
      dispatch(callPricingCategoryTimeSlotsSuccess({ vehicleCategoryId, data }));
      return data;
    });
  };
}

export function callPricingCategoryTimeSlotsSuccess({ vehicleCategoryId, data }) {
  return {
    type: types.PRICING_GET_CATEGORY_TIME_SLOTS_SUCCESS,
    vehicleCategoryId,
    data
  };
}

export function requestGetPricingCategoryTransversalPrices(vehicleCategoryId) {
  return (dispatch, getState) => {
    const state = getState();
    const companyId = state.companies.currentCompany.id;
    const memberTypeId = state.pricing.memberTypeId;

    const params = {
      companyId,
      query: {
        companyId,
        vehicleCategoryId,
        memberTypeId
      }
    };

    return dispatch(callApi('pricing', 'getCategoryTransversalPrices', params)).then(data => {
      return dispatch(callPricingCategoryTransversalPricesSuccess({ vehicleCategoryId, data }));
    });
  };
}

export function callPricingCategoryTransversalPricesSuccess({ vehicleCategoryId, data }) {
  return {
    type: types.PRICING_GET_CATEGORY_TRANSVERSAL_PRICES_SUCCESS,
    vehicleCategoryId,
    data
  };
}

export function requestSendTimeSlots({ usage, values, categoryType, vehicleCategoryId }) {
  return (dispatch, getState) => {
    const state = getState();
    const companyId = state.companies.currentCompany.id;
    const memberTypeId = state.pricing.memberTypeId;
    const bundle = bundleSelector(state);

    let ts = { timeSlots: values };
    let validate = validatePriceSlots();
    let errors = validate(ts);
    let key = [];
    for (let error in errors.timeSlots) {
      if (!_isEmpty(errors.timeSlots[error])) {
        key.push(Number(error));
      }
    }
    if (key.length > 0) {
      return dispatch({ type: types.PRICING_RESET_TIMESLOTS_TO_SEND });
    } else {
      return dispatch(
        callApi('pricing', 'sendTimeSlots', {
          companyId,
          usage,
          vehicleCategoryId,
          values,
          memberTypeId
        })
      ).then(
        params => {
          if (params.usage === TIME_SLOT_USAGE_BUSINESS) {
            dispatch(callPricingBusinessTimeSlotsSuccess(vehicleCategoryId, params));
          }

          dispatch(
            addFlashMessage({
              contentKey: `pricing_save_timeSlots_${params.usage}_success`,
              contentData: { categoryType },
              type: FLASH_MESSAGE_TYPE_SUCCESS
            })
          );

          dispatch(resetTimeSlotsToSend());
        },
        error => {
          const msgParams = { bundle, error };
          dispatch(addErrorMessage(msgParams));
        }
      );
    }
  };
}

export function toggleCategoryWidgetOpenedState(categoryId) {
  return {
    type: types.PRICING_CATEGORY_WIDGET_OPENED_STATE_TOGGLE,
    id: categoryId
  };
}

export function requestSendTransversalPricing({ vehicleCategoryId, values }) {
  return (dispatch, getState) => {
    const state = getState();
    const companyId = state.companies.currentCompany.id;
    const memberTypeId = state.pricing.memberTypeId;

    const params = {
      companyId,
      vehicleCategoryId,
      memberTypeId,
      values
    };

    return dispatch(callApi('pricing', 'sendTransversalPricing', params)).then(data => {
      return data;
    });
  };
}

export function requestGetCancellationFees(categoryId) {
  return (dispatch, getState) => {
    const state = getState();
    const companyId = state.companies.currentCompany.id;
    const memberTypeId = state.pricing.memberTypeId;

    dispatch({
      type: types.PRICING_GET_CATEGORY_CANCELLATION_FEES_REQUEST,
      params: {
        companyId,
        categoryId,
        memberTypeId
      }
    });

    return dispatch(
      callApi(
        'pricing',
        'getCancellationFees',
        {
          companyId,
          categoryId,
          memberTypeId
        },
        false
      )
    ).then(
      function(data) {
        dispatch(getCancellationFeesSuccess({ categoryId, companyId, memberTypeId, data }));
        return data;
      },
      error => {
        dispatch(getCancellationFeesFail({ categoryId, companyId, memberTypeId, error }));
      }
    );
  };
}

export function getCancellationFeesSuccess({ categoryId, companyId, memberTypeId, data }) {
  return {
    type: types.PRICING_GET_CATEGORY_CANCELLATION_FEES_SUCCESS,
    categoryId,
    companyId,
    memberTypeId,
    data
  };
}

export function getCancellationFeesFail({ categoryId, companyId, memberTypeId, error }) {
  return {
    type: types.PRICING_GET_CATEGORY_CANCELLATION_FEES_ERROR,
    categoryId,
    companyId,
    memberTypeId,
    error
  };
}

export function saveCancellationFees(categoryId) {
  return (dispatch, getState) => {
    const state = getState();
    const context = state.pricing.cancellationFees.get(categoryId).context;
    const ratesValues = getValues(state.form.cancellationFees['fees-' + categoryId]);
    const res = ratesValues.rates.map(rate => {
      delete rate.id;
      return rate;
    });
    const params = {
      context: context,
      rates: res
    };

    dispatch({
      type: types.PRICING_SAVE_CATEGORY_CANCELLATION_FEES_REQUEST,
      params
    });

    return dispatch(callApi('pricing', 'saveCancellationFees', params, true))
      .then(data => {
        dispatch(
          addFlashMessage({
            contentKey: 'pricing_cancellation_fees_save_success',
            type: FLASH_MESSAGE_TYPE_SUCCESS
          })
        );
        return data;
      })
      .catch(() => {
        dispatch(
          addFlashMessage({
            contentKey: 'pricing_cancellation_fees_save_error',
            type: FLASH_MESSAGE_TYPE_ERROR
          })
        );
      });
  };
}

export function toggleCopyPriceMemberTypeDialog() {
  return {
    type: types.PRICING_TOGGLE_DIALOG_COPY_TIME_SLOTS_OF_MEMBER_TYPE
  };
}

export function requestCopyPriceslotsMemberType(payload) {
  return (dispatch, getState) => {
    const state = getState();
    const companyId = state.companies.currentCompany.id;
    const sourceMemberTypeId = payload.fromMemberTypeId;
    const targetMemberTypeId = payload.toMemberTypeId;
    const params = {
      companyId,
      sourceMemberTypeId,
      targetMemberTypeId
    };

    dispatch({
      type: types.PRICING_COPY_TIME_SLOTS_OF_MEMBER_TYPE_REQUEST,
      params
    });

    return dispatch(callApi('pricing', 'copyPriceslots', params, false))
      .then(() => {
        dispatch(
          addFlashMessage({
            contentKey: 'member_type_copy_success',
            type: FLASH_MESSAGE_TYPE_SUCCESS
          })
        );
      })
      .catch(() => {
        dispatch(
          addFlashMessage({
            contentKey: 'member_type_copy_error',
            type: FLASH_MESSAGE_TYPE_ERROR
          })
        );
      });
  };
}

export function CopyMemberTypePricingSuccess(data) {
  return {
    type: types.PRICING_COPY_TIME_SLOTS_OF_MEMBER_TYPE_SUCCESS,
    data,
    open
  };
}

export function setCurrentPricingTabIndex(index, memberTypeId) {
  return {
    type: types.PRICING_SET_CURRENT_INDEX,
    index,
    memberTypeId
  };
}

export function resetOpenedCategoryWidget() {
  return {
    type: types.PRICING_RESET_OPENED_CATEGORY_WIDGET
  };
}

export function setSaveAllTimeSlots(bool) {
  return {
    type: types.PRICING_SAVE_ALL_TIMESLOTS,
    bool
  };
}

export function setTimeSlotsErrors(errors) {
  return {
    type: types.PRICING_SET_TIMESLOTS_ERRORS,
    errors
  };
}

export function clearTimeSlotsErrors() {
  return {
    type: types.PRICING_CLEAR_TIMESLOTS_ERRORS
  };
}

export function prepareTimeSlotsToSend(timeSlot) {
  return {
    type: types.PRICING_PREPARE_TIMESLOTS_TO_SEND,
    timeSlot
  };
}

export function resetTimeSlotsToSend() {
  return {
    type: types.PRICING_RESET_TIMESLOTS_TO_SEND
  };
}

export function resetCurrentCompanyBusinessTimeSlots() {
  return {
    type: types.PRICING_RESET_CURRENT_COMPANY_BUSINESS_TIMESLOTS
  };
}

export function resetCategoriesTransversalPrices() {
  return {
    type: types.PRICING_RESET_CATEGORIES_TRANSVERSAL_PRICES
  };
}
