import React, { Component, PropTypes } from 'react';
import { FormattedMessage } from 'react-intl';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';

import BoxedInput from '../BoxedInput/BoxedInput';
import LabeledCheckbox from '../LabeledCheckbox/LabeledCheckbox';
import EkButton from '../EkButton/EkButton';
import FieldErrorMsg from '../FieldErrorMsg/FieldErrorMsg';
import { toggleForgotPasswordMode } from '../../actions/all-actions';
import { createValidator, notEmpty, email, password, length } from '../../validation/all-validation';
import { PASSWORD_FIELD_NAME } from '../../constants/generic-constants';
import { scrollToFirstError } from '../../utils/utils';
import { redirectLocationSelector } from '../../selectors/all-selectors';
import classNames from 'classnames';

class LoginForm extends Component {
  constructor(props) {
    super(props);
    this.handleToggleForgotPasswordMode = this.handleToggleForgotPasswordMode.bind(this);
    this.derivedStateFromProps(props, true);
  }

  componentWillReceiveProps(props) {
    this.derivedStateFromProps(props);
  }

  derivedStateFromProps(props) {
    const { serverError } = props;
    this.serverErrorKey = '';

    if (serverError) {
      switch (serverError.code) {
        case 401:
          this.serverErrorKey = 'login_invalid_credentials';
          break;
        case 422:
          this.serverErrorKey = 'user_not_enabled';
          break;
        default:
          this.serverErrorKey = 'error_server_unknown';
          break;
      }
    }
  }

  handleToggleForgotPasswordMode() {
    const { dispatch } = this.props;
    dispatch(toggleForgotPasswordMode());
  }

  showDefaultActions() {
    const {
      fields: { rememberMe }
    } = this.props;

    return (
      <div className="loginForm_actionWrap">
        <button type="button" className="loginForm_forgotPassword" onClick={this.handleToggleForgotPasswordMode}>
          <FormattedMessage id="login_forgotPassword" />
        </button>

        <div className="loginForm_checkbox">
          <LabeledCheckbox id="rememberMe" field={rememberMe}>
            <FormattedMessage id="login_rememberMe" />
          </LabeledCheckbox>
        </div>
      </div>
    );
  }

  showRedirectActions() {
    const {
      fields: { rememberMe, redirectBack }
    } = this.props;

    return (
      <div className="loginForm_redirectAction_container">
        <div className="loginForm_redirectAction_row">
          <div className="loginForm_checkbox">
            <LabeledCheckbox id="redirectBack" field={redirectBack}>
              <FormattedMessage id="login_redirectBack" />
            </LabeledCheckbox>
          </div>
          <div className="loginForm_checkbox">
            <LabeledCheckbox id="rememberMe" field={rememberMe}>
              <FormattedMessage id="login_rememberMe" />
            </LabeledCheckbox>
          </div>
        </div>
        <div className="loginForm_redirectAction_row">
          <button type="button" className={classNames('loginForm_forgotPassword', 'solo')} onClick={this.handleToggleForgotPasswordMode}>
            <FormattedMessage id="login_forgotPassword" />
          </button>
        </div>
      </div>
    );
  }

  render() {
    const {
      fields: { login, password },
      handleSubmit,
      redirectLocation
    } = this.props;

    return (
      <form action="#" className="loginForm" onSubmit={handleSubmit(this.props.onCallback)}>
        {this.serverErrorKey && (
          <div className="loginForm_mainErrorMsg">
            <FormattedMessage id={this.serverErrorKey} />
          </div>
        )}

        <FieldErrorMsg field={login} customClass="fieldErrorMsg--loginForm" />
        <BoxedInput type="email" placeholder="Email" customClass="loginForm_boxedInput" field={login} />

        <FieldErrorMsg field={password} customClass="fieldErrorMsg--loginForm" />
        <BoxedInput
          type="password"
          placeholder="Password"
          actionText="loginForm_password_actionText"
          customClass="loginForm_boxedInput"
          field={password}
        />

        <EkButton customClass="loginForm_submitBtn" onAction={handleSubmit(this.props.onCallback)} fullWidth>
          <FormattedMessage id="login_submitBtn" />
        </EkButton>

        {redirectLocation ? this.showRedirectActions() : this.showDefaultActions()}
      </form>
    );
  }
}

LoginForm.displayName = 'LoginForm';

LoginForm.propTypes = {
  action: PropTypes.func,
  fields: PropTypes.object,
  onCallback: PropTypes.func,
  handleSubmit: PropTypes.func,
  serverError: PropTypes.object
};

LoginForm = reduxForm({
  onSubmitFail: scrollToFirstError,
  form: 'login',
  fields: ['login', PASSWORD_FIELD_NAME, 'rememberMe', 'redirectBack'],
  touchOnChange: false,
  validate: createValidator({
    login: [notEmpty(), email(), length({ max: 75 })],
    password: [notEmpty(), password(), length({ min: 8, max: 25 })]
  })
})(LoginForm);

export default connect(state => {
  const {
    user: { forgotPasswordMode }
  } = state;

  const redirectLocation = redirectLocationSelector(state);

  return {
    forgotPasswordMode,
    redirectLocation: redirectLocation,
    initialValues: {
      login: state.user.defaultLogin,
      rememberMe: !!state.user.defaultLogin,
      redirectBack: !!redirectLocation
    }
  };
})(LoginForm);
