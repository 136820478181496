import { UPDATE_LOCATION } from 'react-router-redux';
import _get from 'lodash/get';
import { safe } from '../../utils/utils';

import * as types from '../../constants/actionTypes-constants';

export default function backUsers(
  state = {
    paginatedResults: [],
    latestCreatedBackUser: null,
    detailBackUser: {},
    urlParams: null,
    backUserSelectedToSuspendId: null,
    toggleModalBackUserResetPassword: false,
    isSuspendedBackUser: null,
    latestBackUserWithChangedStatus: null,
    backUsersFiltersFormIsOpen: false,
    emailModalOpened: false,
    availableCompanies: [],
    selectedCompanies: [],
    availableSubCompanies: [],
    selectedSubCompanies: [],
    loadingList: true,
    errorList: false
  },
  action
) {
  switch (action.type) {
    case types.BACK_USERS_GET_LIST_REQUEST: {
      return {
        ...state,
        loadingList: true,
        errorList: false
      };
    }

    case types.BACK_USERS_GET_LIST_SUCCESS: {
      return Object.assign({}, state, {
        paginatedResults: action.paginatedObj,
        urlParams: action.urlParams,
        loadingList: false
      });
    }

    case types.BACK_USERS_GET_LIST_ERROR: {
      return {
        ...state,
        errorList: true
      };
    }

    case types.BACK_USERS_EMPTY_LIST: {
      return Object.assign({}, state, {
        paginatedResults: []
      });
    }

    case types.BACK_USERS_CREATE_USER_REQUEST: {
      return state;
    }

    case types.BACK_USERS_CREATE_USER_SUCCESS: {
      return Object.assign({}, state, {
        latestCreatedBackUser: action.backUser
      });
    }

    case types.BACK_USERS_GET_DETAIL_USER_REQUEST: {
      return state;
    }

    case types.BACK_USERS_GET_DETAIL_USER_SUCCESS: {
      return Object.assign({}, state, {
        detailBackUser: action.backUser
      });
    }

    case types.BACK_USERS_UPDATE_BACK_USER_REQUEST: {
      return state;
    }

    case types.BACK_USERS_UPDATE_BACK_USER_SUCCESS: {
      return Object.assign({}, state, {
        detailBackUser: action.backUser
      });
    }

    case types.BACK_USERS_OPEN_STATUS_BACK_USER_MODAL: {
      return {
        ...state,
        backUserSelectedToSuspendId: _get(action, 'backUser.id')
      };
    }

    case types.BACK_USERS_CLOSE_STATUS_BACK_USER_MODAL: {
      return Object.assign({}, state, {
        backUserSelectedToSuspendId: null
      });
    }

    case types.BACK_USERS_STATUS_BACK_USER_TOGGLE: {
      return Object.assign({}, state, {
        isSuspendedBackUser: !state.detailBackUser.suspended
      });
    }

    case types.BACK_USERS_CHANGE_STATUS_BACK_USER_REQUEST: {
      return state;
    }

    case types.BACK_USERS_CHANGE_STATUS_BACK_USER_SUCCESS: {
      return {
        ...state,
        detailBackUser: _get(action, 'backUser')
      };
    }

    case types.BACK_USERS_FILTERS_FORM_OPENED_STATE_TOGGLE: {
      return Object.assign({}, state, {
        backUsersFiltersFormIsOpen: !state.backUsersFiltersFormIsOpen
      });
    }

    case types.BACK_USERS_SET_COMPANIES: {
      return Object.assign({}, state, {
        availableCompanies: action.availableCompanies,
        selectedCompanies: action.selectedCompanies
      });
    }

    case types.BACK_USERS_SET_SUBCOMPANIES: {
      return Object.assign({}, state, {
        availableSubCompanies: action.availableSubCompanies,
        selectedSubCompanies: action.selectedSubCompanies
      });
    }

    case types.BACK_USERS_REMOVE_COMPANIES: {
      return Object.assign({}, state, {
        availableCompanies: [],
        selectedCompanies: [],
        availableSubCompanies: [],
        selectedSubCompanies: []
      });
    }

    case types.BACK_USERS_EMAIL_MODAL_OPEN: {
      return Object.assign({}, state, {
        emailModalOpened: true
      });
    }

    case types.BACK_USERS_EMAIL_MODAL_CLOSE: {
      return Object.assign({}, state, {
        emailModalOpened: false
      });
    }

    case types.BACK_USERS_CHANGE_EMAIL_MEMBER_SUCCESS: {
      return { ...state, detailBackUser: { ...state.detailBackUser, login: safe(() => action.payload.login) } };
    }

    case types.BACK_USERS_RESET_PASSWORD_BACK_USER_MODAL_TOGGLE:
      return Object.assign({}, state, {
        toggleModalBackUserResetPassword: !state.toggleModalBackUserResetPassword
      });

    case UPDATE_LOCATION: {
      const sharedProps = { emailModalOpened: false };

      if (action.payload.pathname.startsWith('/add-')) {
        return { ...state, ...sharedProps, detailBackUser: undefined };
      } else {
        return { ...state, ...sharedProps };
      }
    }

    default: {
      return state;
    }
  }
}
