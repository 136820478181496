import React, { Component, PropTypes } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { reduxForm } from 'redux-form';

import FormRow from '../FormRow/FormRow';
import BoxedInput from '../BoxedInput/BoxedInput';
import BoxedSelect from '../BoxedSelect/BoxedSelect';
import EkButton from '../EkButton/EkButton';

import FieldErrorMsg from '../FieldErrorMsg/FieldErrorMsg';
import { createValidator, notEmpty } from '../../validation/all-validation';
import routes from '../../constants/routes-constants';
import BackLink from '../BackLink/BackLink';
import { apiParams } from '../../constants/api-params-constants';
import { scrollToFirstError } from '../../utils/utils';

class ConfigurationForm extends Component {
  render() {
    const {
      fields: { name, hotlineId, parentConfigurationId },
      handleSubmit,
      urlParams,
      allConfigurations,
      detailConfiguration,
      limitedAccess,
      list
    } = this.props;

    let cancelUrl =
      '/#' + routes.configurations.path.replace(':search', encodeURIComponent(JSON.stringify(urlParams ? urlParams : apiParams.default)));

    let hotlines = [];
    list.map(item => {
      hotlines.push({
        label: item.name,
        value: item.id
      });
    });

    let configurations = [];
    allConfigurations.map(item => {
      if (detailConfiguration === null || (detailConfiguration && detailConfiguration.id !== item.id)) {
        configurations.push({
          label: item.name,
          value: item.id
        });
      }
    });

    return (
      <section className="configurationFormWrapper">
        <BackLink link={cancelUrl} labelKey="back_link_configurations_list" />
        <form action="#" className="configurationForm" onSubmit={handleSubmit(this.props.onCallback)}>
          <div className="configurationForm_container">
            <FormRow customClass="configurationForm_row">
              <BoxedInput
                formRowItemKey="configurationForm_name"
                type="text"
                id="name"
                labelKey="configurationForm_name_label"
                customClass="configurationForm_boxedInput"
                field={name}
                disabled={limitedAccess}
              >
                <FieldErrorMsg field={name} customClass="fieldErrorMsg--configurationForm" />
              </BoxedInput>
            </FormRow>

            <FormRow customClass="configurationForm_row">
              <BoxedSelect
                formRowItemKey="configurationForm_hotline"
                id="hotlinesSelect"
                labelKey="configurationForm_hotline_label"
                options={hotlines}
                field={hotlineId}
                customClass="configurationForm_boxedSelectWrapper"
                disabled={limitedAccess}
              />

              <BoxedSelect
                formRowItemKey="configurationForm_parent_configuration"
                id="parentConfigurationSelect"
                labelKey="configurationForm_parent_configuration_label"
                options={configurations}
                field={parentConfigurationId}
                customClass="configurationForm_boxedSelectWrapper"
                disabled={limitedAccess}
              />
            </FormRow>
          </div>

          <div className="configurationForm_actions">
            <a href={cancelUrl} className="ekButton ekButton--reverse configurationForm_actionsLink">
              <FormattedMessage id="common_cancel" />
            </a>

            <EkButton customClass="configurationForm_actionsButton" onAction={handleSubmit(this.props.onCallback)} disabled={limitedAccess}>
              <FormattedMessage id="common_save" />
            </EkButton>
          </div>
        </form>
      </section>
    );
  }
}

ConfigurationForm.displayName = 'ConfigurationForm';

ConfigurationForm.propTypes = {
  fields: PropTypes.object,
  handleSubmit: PropTypes.func,
  limitedAccess: PropTypes.bool,
  onCallback: PropTypes.func
};

ConfigurationForm = reduxForm({
  onSubmitFail: scrollToFirstError,
  form: 'ConfigurationView',
  fields: ['name', 'hotlineId', 'parentConfigurationId'],
  validate: createValidator({
    name: [notEmpty()]
  })
})(ConfigurationForm);

export default connect(state => {
  const {
    configuration: { urlParams, detailConfiguration, allConfigurations },
    hotlines: { list }
  } = state;

  return {
    urlParams,
    detailConfiguration,
    allConfigurations,
    list
  };
})(ConfigurationForm);
