import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { goToSearchLocation, goToDetailsLocation } from '../../../actions/all-actions';
import routes from '../../../constants/routes-constants';
import VehiclesResultsRow from './VehiclesResultsRow';
import { addOpenNewTabEvents, hideButtonHoverMsg } from '../../../utils/utils';

class VehiclesResults extends Component {
  constructor(props) {
    super(props);
    this.onGoToDetail = this.onGoToDetail.bind(this);
    this.handleGoToAll = this.handleGoToAll.bind(this);
  }

  componentWillUnmount() {
    hideButtonHoverMsg();
  }

  onGoToDetail(...props) {
    this.props.goToDetail(...props);
  }

  handleGoToAll(openInNewTab) {
    this.props.goToAll(routes.fleet.path, { plateNumber: this.props.searchText }, openInNewTab);
  }

  render() {
    const { vehiclesResults, vehiclesResultsTotal } = this.props;

    return (
      <div>
        {vehiclesResults.length > 0 && (
          <div>
            <div className="search-resultTitle">
              <FormattedMessage id="common_vehicles" />
              {vehiclesResultsTotal <= 5 && (
                <div>
                  <FormattedMessage id="common_results" />:{' ' + vehiclesResultsTotal}
                </div>
              )}
              {vehiclesResultsTotal > 5 && (
                <a {...addOpenNewTabEvents(this.handleGoToAll)} className="link">
                  <FormattedMessage id="common_show_all" />
                  {' ' + vehiclesResultsTotal + ' '}
                </a>
              )}
            </div>
            <div className="search-resultsWrap">
              {vehiclesResults.map(vehicle => {
                return <VehiclesResultsRow key={vehicle.id} vehicle={vehicle} handleGoToDetail={this.onGoToDetail} />;
              })}
            </div>
          </div>
        )}
      </div>
    );
  }
}

VehiclesResults.displayName = 'VehiclesResults';

const mapStateToProps = state => {
  const {
    header: { searchText },
    searchResults: { vehiclesResults, vehiclesResultsTotal }
  } = state;

  return {
    vehiclesResults,
    vehiclesResultsTotal,
    searchText
  };
};

const mapDispatchToProps = dispatch => {
  return {
    goToDetail(...props) {
      goToDetailsLocation(...props)(dispatch);
    },
    goToAll(...props) {
      goToSearchLocation(...props)(dispatch);
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(VehiclesResults);
