import React, { Component, PropTypes } from 'react';
import { connect } from 'react-redux';
import Modal from 'react-modal';
import _get from 'lodash/get';

import ParkingForm from '../../components/ParkingForm/ParkingForm';
import ConfirmationForm from '../../components/ConfirmationForm/ConfirmationForm';
import { updateParkingRequest, openParkingConfirmationModal, closeParkingConfirmationModal, setIsOpen } from '../../actions/all-actions';
import { BACKUSER_ROLE_CALL_CENTER_OPERATOR } from '../../constants/backend-constants';
import { GroupStartServiceModalStyles } from '../../constants/style-constants';
import { defaultWeeklySlot } from '../../constants/filters-constants';
import { currentParkingSelector } from '../../selectors/all-selectors';
import { siteEditRules } from '../../constants/routes-constants';
import { checkRole } from '../../constants/backuser-role-rules';
import { fallbackFunc } from '../../utils/utils';

class ParkingDetails extends Component {
  constructor(props) {
    super(props);
    this.bindFunctions();
  }

  bindFunctions() {
    this.handleRequest = this.handleRequest.bind(this);
    this.handleUpdateParking = this.handleUpdateParking.bind(this);
    this.handleOpenConfirmationModal = this.handleOpenConfirmationModal.bind(this);
    this.handleAbortUpdateParking = this.handleAbortUpdateParking.bind(this);
  }

  handleRequest(data) {
    const { dispatch } = this.props;

    dispatch(setIsOpen(data === 'true'));
  }

  handleUpdateParking() {
    const { dispatch } = this.props;

    dispatch(updateParkingRequest(this.props.currentParking, this.props.isModal)).then(this.props.onSubmit, fallbackFunc);
    dispatch(closeParkingConfirmationModal());
  }

  handleOpenConfirmationModal() {
    const { dispatch } = this.props;
    dispatch(openParkingConfirmationModal());
  }

  handleAbortUpdateParking() {
    const { dispatch } = this.props;

    dispatch(closeParkingConfirmationModal());
  }

  render() {
    const { openedParkingConfirmationModal, isOpen, currentParking, bankHolidays, role } = this.props;
    const isOpenRequest = isOpen === null ? currentParking.alwaysOpen : isOpen;

    return (
      <div className="parkingDetailsPage mainContainer_content">
        <div className="pageContainer">
          <div className="parkingDetailsPage_content">
            <section className="parkingDetailsPage_management">
              {openedParkingConfirmationModal && (
                <Modal isOpen style={GroupStartServiceModalStyles}>
                  <ConfirmationForm
                    title={`parkingForm_confirmation_title`}
                    confirmationMessage={`parkingForm_confirmation_message`}
                    confirmationText={`parkingForm_confirmation_save`}
                    abortText={`parkingForm_confirmation_abort`}
                    onCallback={this.handleUpdateParking}
                    onAbort={this.handleAbortUpdateParking}
                  />
                </Modal>
              )}

              <ParkingForm
                isModal={this.props.isModal}
                onCancel={this.props.onCancel}
                company={this.props.detailCompany}
                subCompany={this.props.currentSubCompany}
                onCallback={!isOpenRequest ? this.handleOpenConfirmationModal : this.handleUpdateParking}
                onChangeOpen={this.handleRequest}
                initialValues={{
                  name: currentParking.name,
                  latitude: currentParking.coordinates.latitude,
                  longitude: currentParking.coordinates.longitude,
                  radius: currentParking.radius,
                  parkingId: currentParking.parkingId,
                  additionalInformation: currentParking.additionalInformation,
                  alwaysOpen: String(currentParking.alwaysOpen),
                  privateAccess: String(currentParking.privateAccess),
                  disabledAccess: String(currentParking.disabledAccess),
                  rrsParkingId: currentParking.rrsParkingId,
                  electricCharging: String(currentParking.electricCharging),
                  gsmConnection: String(currentParking.gsmConnection),
                  findable: String(currentParking.findable),
                  pluggedCheck: String(currentParking.pluggedCheck),
                  outsideOpeningHours: String(currentParking.outsideOpeningHours),
                  outsideParkingArea: String(currentParking.outsideParkingArea),
                  specialClosingDates: currentParking.specialClosingDates,
                  specialOpeningHours: currentParking.specialOpeningHours,
                  weeklyOpeningHours: _get(currentParking, 'weeklyOpeningHours', []).length
                    ? currentParking.weeklyOpeningHours
                    : defaultWeeklySlot,
                  applyFrenchBankHolidays: String(_get(currentParking, 'schedule.applyFrenchBankHolidays', true)),
                  finishBookingOutsideOpeningHours: String(_get(currentParking, 'schedule.finishBookingOutsideOpeningHours', true)),
                  finishBookingOutsideParkingArea: String(_get(currentParking, 'finishBookingOutsideParkingArea', true)),
                  bankHolidays: bankHolidays
                }}
                limitedAccess={role === BACKUSER_ROLE_CALL_CENTER_OPERATOR || !checkRole(siteEditRules, role)}
              />
            </section>
          </div>
        </div>
      </div>
    );
  }
}

ParkingDetails.displayName = 'ParkingDetails';

ParkingDetails.propTypes = {
  isModal: PropTypes.bool,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func
};

export default connect(state => {
  const {
    user: {
      userInfo: { role }
    },
    parkings: { openedParkingConfirmationModal, isOpen, bankHolidays },
    companies: { detailCompany },
    subCompanies: { currentSubCompany }
  } = state;

  return {
    currentParking: currentParkingSelector(state),
    openedParkingConfirmationModal,
    currentSubCompany,
    detailCompany,
    bankHolidays,
    isOpen,
    role
  };
})(ParkingDetails);
