import React, { Component, PropTypes } from 'react';
import { FormattedMessage } from 'react-intl';
import { reduxForm } from 'redux-form';

import FormRow from '../FormRow/FormRow';
import BoxedInput from '../BoxedInput/BoxedInput';
import EkButton from '../EkButton/EkButton';
import BoxedSelect from '../BoxedSelect/BoxedSelect';
import { booleanOptions, memberStatusOptions, memberSubscriptionOriginOptions } from '../../constants/options-constants';
import { scrollToFirstError } from '../../utils/utils';
import { headerContractMemberValuesSelector } from '../../selectors/all-selectors';

class SearchMembers extends Component {
  showExpediteReview() {
    const {
      memberRules,
      fields: { expediteReview }
    } = this.props;

    if (memberRules.expediteReview && expediteReview) {
      return (
        <BoxedSelect
          blockCustomClass="searchMembersForm_boxedSelectBlock"
          labelKey="members_label_type_expediteReview"
          options={booleanOptions}
          field={expediteReview}
        />
      );
    }
  }

  render() {
    const {
      fields: { memberName, email, status, technician, memberTypeId, subscriptionOrigins },
      statusDisabled,
      handleSubmit,
      onResetCallBack,
      memberTypesList
    } = this.props;

    return (
      <section className="searchMembersWrapper">
        <form action="#" className="searchMembersForm" onSubmit={handleSubmit(this.props.onCallback)}>
          <FormRow>
            <div>
              {memberName && (
                <BoxedInput
                  type="text"
                  id="memberName"
                  placeholderKey="common_placeHolder_searchForMemberName"
                  customClass="searchMembersForm_inputWrapper"
                  field={memberName}
                />
              )}

              {email && (
                <BoxedInput
                  type="text"
                  id="email"
                  placeholderKey="common_placeHolder_searchForMemberEmail"
                  customClass="searchMembersForm_inputWrapper"
                  field={email}
                />
              )}

              {this.showExpediteReview()}
            </div>
          </FormRow>

          <FormRow>
            <div>
              {!statusDisabled && status && (
                <BoxedSelect
                  blockCustomClass="searchMembersForm_boxedSelectBlock"
                  labelKey="members_label_status"
                  options={memberStatusOptions}
                  field={status}
                />
              )}

              {technician && (
                <BoxedSelect
                  blockCustomClass="searchMembersForm_boxedSelectBlock"
                  labelKey="members_label_type_technician"
                  options={booleanOptions}
                  field={technician}
                />
              )}

              {memberTypeId && memberTypesList && memberTypesList.length > 0 && (
                <BoxedSelect
                  blockCustomClass="searchMembersForm_boxedSelectBlock"
                  labelKey="common_member_type"
                  options={memberTypesList}
                  field={memberTypeId}
                />
              )}
              {subscriptionOrigins && (
                <BoxedSelect
                  blockCustomClass="searchMembersForm_boxedSelectBlock"
                  labelKey="member_subscription_origin"
                  options={memberSubscriptionOriginOptions}
                  field={subscriptionOrigins}
                />
              )}
            </div>
          </FormRow>

          <div className="searchMembersForm_actions">
            <EkButton onAction={onResetCallBack} customClass="searchMembersForm_actionsButton" skinType="reverse" type="button">
              <FormattedMessage id="searchMembersForm_reset_button" />
            </EkButton>

            <EkButton onAction={handleSubmit(this.props.onCallback)} customClass="searchMembersForm_actionsButton">
              <FormattedMessage id="searchMembersForm_search_button" />
            </EkButton>
          </div>
        </form>
      </section>
    );
  }
}

SearchMembers.displayName = 'SearchMembers';

SearchMembers.propTypes = {
  fields: PropTypes.object,
  statusDisabled: PropTypes.bool,
  handleSubmit: PropTypes.func,
  onCallback: PropTypes.func,
  onResetCallBack: PropTypes.func.isRequired
};

SearchMembers.defaultProps = {
  statusDisabled: false
};

const defaultFields = ['memberName', 'email', 'status', 'memberTypeId', 'technician', 'expediteReview', 'subscriptionOrigins'];

export const withoutFields = fields => defaultFields.filter(field => fields.indexOf(field) === -1);

export default reduxForm(
  {
    onSubmitFail: scrollToFirstError,
    form: 'searchMembers',
    fields: defaultFields
  },
  state => ({
    memberRules: headerContractMemberValuesSelector(state)
  })
)(SearchMembers);
