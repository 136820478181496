import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { getAddTitle } from '../../../../../components/_v2/ColumnList/helpers';
import { showParentView } from '../../../../../components/_v2/ColumnList/helpers';
import VehicleView from '../index';
import { showSuccessMsg } from '../../../../../actions/flashMessage-actions';
import { connect } from 'react-redux';
import { safe } from '../../../../../utils/utils';
import { createVersionRequest } from '../../../../../actions/versions-actions';
import { editVehicleBrandsRules } from '../../../../../constants/routes-constants';
import { checkRole } from '../../../../../constants/backuser-role-rules';
import { userRoleSelector } from '../../../../../selectors/all-selectors';

export class VehicleAddVersion extends Component {
  componentWillMount() {
    this.setVars();
    this.setCallbacks();
  }

  setVars() {
    this.limitedAccess = !checkRole(editVehicleBrandsRules, this.props.role);
  }

  setCallbacks() {
    this.showParentView = showParentView.bind(this);

    this.handleCallback = params => {
      const { dispatch } = this.props;

      dispatch(createVersionRequest({ ...params, modelId: params.id })).then(() => {
        dispatch(showSuccessMsg({ key: 'versionForm_create_version_success' }));
        this.showParentView();
      });
    };
  }

  render() {
    return (
      <VehicleView
        inputRef={this.props.inputRef}
        title={getAddTitle(<FormattedMessage id="versionForm_title" />)}
        fieldLabel={<FormattedMessage id="versionForm_name_label" />}
        initialValues={{ id: safe(() => this.props.data.id) }}
        onCallback={this.handleCallback}
        onClose={this.showParentView}
        limitedAccess={this.limitedAccess}
      />
    );
  }
}

const mapStateToProps = state => {
  const { currentModel } = state.models;

  return {
    data: currentModel,
    role: userRoleSelector(state)
  };
};

VehicleAddVersion = connect(mapStateToProps)(VehicleAddVersion);

export default VehicleAddVersion;
