// noinspection ES6CheckImport
import React, { Component, PropTypes } from 'react';
import { connect } from 'react-redux';
import AccountCircleIcon from 'material-ui/svg-icons/action/account-circle';
import { FormattedMessage } from 'react-intl';
import _get from 'lodash/get';
import _filter from 'lodash/filter';
import autoBind from 'react-autobind';
import RefreshIcon from 'material-ui/svg-icons/navigation/refresh';
import ArrowBackIcom from 'material-ui/svg-icons/navigation/arrow-back';
import { history } from '../../routing/all-routing';

import { getMemberProfileHistory, clearMemberProfileHistory, getFileUrl } from '../../actions/all-actions';
import { memberCompanyContractMemberValuesSelector, userRoleSelector } from '../../selectors/all-selectors';
import { DetailViewStyles } from '../../constants/style-constants';
import {
  BACKUSER_ROLE_ADMIN,
  BACKUSER_ROLE_SUPER_ADMIN,
  BACKUSER_ROLE_CALL_CENTER_OPERATOR,
  BACKUSER_ROLE_ROOT
} from '../../constants/backend-constants';
import { memberDetailEditRules } from '../../constants/routes-constants';

import { safe } from '../../utils/utils';
import Tooltip from '../../components/Tooltip/Tooltip';
import { Avatar, IconButton } from 'material-ui';
import { checkRole } from '../../constants/backuser-role-rules';

import MemberProfileHistoryList from '../../components/MemberProfileHistoryList/MemberProfileHistoryList';

class MemberProfileHistory extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.setReadOnly(props);
    this.setVars();
  }

  setReadOnly(props) {
    const { role } = props;
    this.readOnly = !(
      role === BACKUSER_ROLE_SUPER_ADMIN ||
      role === BACKUSER_ROLE_ADMIN ||
      role === BACKUSER_ROLE_ROOT ||
      role === BACKUSER_ROLE_CALL_CENTER_OPERATOR ||
      checkRole(memberDetailEditRules, role)
    );
  }

  setVars() {
    this.state = { imageUrl: null };
  }

  componentDidMount() {
    const { dispatch, detailMember } = this.props;
    const Selfie = _filter(
      safe(() => detailMember.identityDocument.files),
      { type: 'SELFIE' }
    );
    if (Selfie.length) dispatch(getFileUrl(Selfie[0].fileId)).then(data => this.setState({ imageUrl: data }));
  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(clearMemberProfileHistory());
  }

  getHistoryProfil() {
    const { detailMember } = this.props;
    this.props.dispatch(clearMemberProfileHistory());
    this.props.dispatch(getMemberProfileHistory(detailMember.id));
  }

  refreshProfileHistory() {
    const { detailMember } = this.props;
    this.props.dispatch(getMemberProfileHistory(detailMember.id));
  }

  setBackLink() {
    this.props.dispatch(clearMemberProfileHistory());
    history.goBack();
  }

  buttons() {
    {
      return (
        <div>
          <IconButton onClick={this.refreshProfileHistory}>
            <RefreshIcon color={'rgba(0, 0, 0, .54)'} hoverColor={'rgba(0, 0, 0, .84)'} />
          </IconButton>
          <IconButton onClick={this.setBackLink}>
            <ArrowBackIcom color={'rgba(0, 0, 0, .54)'} hoverColor={'rgba(0, 0, 0, .84)'} />
          </IconButton>
        </div>
      );
    }
  }

  getProfileSelfie() {
    if (this.state.imageUrl) {
      return <Avatar src={this.state.imageUrl} />;
    }
    return <AccountCircleIcon style={DetailViewStyles.icons.header} />;
  }

  render() {
    const { detailMember, memberProfileHistory, loadingProfileHistory, memberProfileHistoryError } = this.props;

    const unknown = <FormattedMessage id="common_unknown" />;

    return (
      <div className="mainContainer_content">
        <div className="pageContainer">
          <div className="detailView profile-history">
            <div className="detailView_header">
              <div className="detailView_header_left">
                <Tooltip content={<FormattedMessage id="user_type_member" />} placement="bottom">
                  {this.getProfileSelfie()}
                  {/* <AccountCircleIcon style={DetailViewStyles.icons.header} /> */}
                </Tooltip>
                <h3 className="detailView_header_text">
                  <Tooltip content={<FormattedMessage id="members_detail_first_name" />} placement="bottom">
                    {_get(detailMember, 'firstName', unknown)}
                  </Tooltip>
                  <span> </span>
                  <Tooltip content={<FormattedMessage id="members_detail_last_name" />} placement="bottom">
                    {_get(detailMember, 'lastName', unknown)}
                  </Tooltip>
                  <span> </span>
                  <FormattedMessage id="memberDetail_proflle_history" />
                  <span> </span>
                  {safe(() => detailMember.status) && (
                    <span className={`detailView_header_status member_status--${detailMember.status.toLowerCase()}`}>
                      <Tooltip content={<FormattedMessage id="members_detail_validationStatus" />} placement="bottom">
                        <FormattedMessage id={`members_status_${detailMember.status.toLowerCase()}`} />
                      </Tooltip>
                    </span>
                  )}
                </h3>
              </div>
              <div className="detailView_header_right">{this.buttons()}</div>
            </div>
            <div className="detailView_body">
              <MemberProfileHistoryList
                historyList={memberProfileHistory}
                loading={loadingProfileHistory}
                error={memberProfileHistoryError}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

MemberProfileHistory.propTypes = {
  detailMember: PropTypes.object,
  memberProfileHistory: PropTypes.array,
  identityDocumentFiles: PropTypes.array,
  locale: PropTypes.string,
  loadingProfileHistory: PropTypes.bool
};

MemberProfileHistory.displayName = 'MemberProfileHistory';

export default connect(state => {
  const {
    members: { detailMember, loadingProfileHistory, memberProfileHistory, memberProfileHistoryError, identityDocumentFiles }
  } = state;

  return {
    detailMember,
    memberProfileHistory,
    memberProfileHistoryError,
    loadingProfileHistory,
    identityDocumentFiles,
    memberRules: memberCompanyContractMemberValuesSelector(state),
    role: userRoleSelector(state)
  };
})(MemberProfileHistory);
