/*eslint-disable react/jsx-no-bind */

import React, { Component } from 'react';
import Dialog from 'material-ui/Dialog';
import _pick from 'lodash/pick';
import _get from 'lodash/get';
import _merge from 'lodash/merge';
import _map from 'lodash/map';
import _find from 'lodash/find';
import _filter from 'lodash/filter';
import _includes from 'lodash/includes';
import _flatten from 'lodash/flatten';
import _isEmpty from 'lodash/isEmpty';
import _compact from 'lodash/compact';
import _flattenDeep from 'lodash/flattenDeep';
import _difference from 'lodash/difference';
import _padStart from 'lodash/padStart';
import _range from 'lodash/range';
import _debounce from 'lodash/debounce';
import classNames from 'classnames';
import { FormattedMessage, injectIntl } from 'react-intl';
import autoBind from 'react-autobind';
import { reduxForm, getValues } from 'redux-form';
import moment from 'moment';
import LocationIcon from 'material-ui/svg-icons/communication/location-on';
import CalendarIcon from 'material-ui/svg-icons/action/date-range';
import TimeIcon from 'material-ui/svg-icons/device/access-time';
import CloseIcon from 'material-ui/svg-icons/navigation/close';
import IconButton from 'material-ui/IconButton';
import EditIcon from 'material-ui/svg-icons/image/edit';
import AutoComplete from 'material-ui/AutoComplete';
import UserIcon from 'material-ui/svg-icons/social/person';
import Loader from '../../../components/MaterialLoader/MaterialLoader';
import config from '../../../constants/config-constants';
import BoxedSelect from '../../../components/BoxedSelect/BoxedSelect';
import BoxedInput from '../../../components/BoxedInput/BoxedInput';
import AddressAutocomplete from '../../../components/AddressAutocomplete/AddressAutocomplete';
import {
  toggleVehiclePlanningEditBooking,
  confirmBookingEdition,
  addFlashMessage,
  getDataVehiclePlanning,
  toggleEditChangeMember,
  changeMemberForBooking,
  errorWhileSelectingUserForBooking,
  abortConfirmPreBooking,
  confirmPrebooking,
  saveUserTypeForBookingCreate
} from '../../../actions/all-actions';
import { createValidator, notEmpty, stopValidationIf, addressOrSite, dateAfterOrEqualField } from '../../../validation/all-validation';
import {
  namedCompose,
  enhanceSiteList,
  localToUTC,
  trimValues,
  daysListOfDatesRange,
  createCustomFieldsNames,
  createCustomFieldsValidators,
  addErrorMessage,
  vpChangeMemberType,
  scrollToFirstErrorDialog
} from '../../../utils/utils';
import {
  bundleSelector,
  planningBookingCustomFieldsEditValuesSelector,
  planningBookingEditCustomFieldsSelector,
  vpEditBookingUsageOptionsSelector
} from '../../../selectors/all-selectors';
import EkRadio from '../../../components/EkRadio/EkRadio';
import FieldErrorMsg from '../../../components/FieldErrorMsg/FieldErrorMsg';
import FormRow from '../../../components/FormRow/FormRow';

import { FLASH_MESSAGE_TYPE_SUCCESS } from '../../../constants/generic-constants';
import { enhanceBookingVehicleSearchRequestParams } from '../../../api/data-enhancer';
import CustomFieldsBooking from '../../../components/CustomFieldsBooking/CustomFieldsBooking';
import {
  ONE_WAY_TRIP,
  ROUND_TRIP,
  BOOKING_STATUS_PRE_BOOKED,
  BOOKING_USAGE_TYPE_BUSINESS,
  BOOKING_USAGE_TYPE_PRIVATE,
  BACKUSER_ROLE_ADMIN,
  BACKUSER_ROLE_SUPER_ADMIN,
  BACKUSER_ROLE_ROOT,
  VEHICLE_USAGE_TYPE_IMMEDIATE,
  VEHICLE_USAGE_TYPE_PLANNED
} from '../../../constants/backend-constants';
import { clearFoundUsers, searchMembers, getEstimatedPriceBooking } from '../VehiclePlanning.actions';
import { pure } from 'recompose';
import EkTextarea from '../../../components/EkTextarea/EkTextarea';
import EkButton from '../../../components/EkButton/EkButton';
import IsGranted from '../../../components/IsGranted/IsGranted';
import LabeledCheckbox from '../../../components/LabeledCheckbox/LabeledCheckbox';

class VehiclePlanningEditBooking extends Component {
  constructor(props) {
    super(props);
    autoBind(this);

    this.changeMemberType = vpChangeMemberType.bind(this);
    this.state = { memberTypeSelectDisabled: false };
    this.handleGetUsers = _debounce(this.handleGetUsers, 150);
    this.hoursOptions = [];
    this.setHoursOptions('pickupDate');
    this.setHoursOptions('returnDate');
    this.derivedStateFromProps(props, true);
  }

  // noinspection JSCheckFunctionSignatures
  componentWillReceiveProps(np) {
    const { values, bookingDetail } = this.props;
    if (bookingDetail && np.values.paidBooking) {
      if (np.values.paidBooking !== values.paidBooking) {
        if (!values.paidBooking && values.paidBooking !== undefined) this.props.dispatch(getEstimatedPriceBooking());
      }
    }
    this.derivedStateFromProps(np);
  }

  derivedStateFromProps(props, init) {
    this.updateMemberTypeOptions(props, init);
  }

  updateMemberTypeOptions(props, init) {
    const { memberTypes: newProp } = props;
    const { memberTypes: oldProp } = props;
    if (init || newProp !== oldProp) this.createMemberTypeOptions(newProp);
  }

  createMemberTypeOptions(memberTypes = []) {
    this.memberTypeOptions = memberTypes.map(type => ({
      label: type.name,
      value: type.id
    }));
  }

  componentWillMount() {
    const { dispatch } = this.props;
    dispatch(clearFoundUsers());
  }

  handleClose() {
    this.props.dispatch(abortConfirmPreBooking());
    this.props.dispatch(toggleVehiclePlanningEditBooking());
  }

  handleGetUsers(input) {
    const { dispatch, data } = this.props;
    const companyId = _get(data, 'company.id');
    dispatch(searchMembers(companyId, input));
  }

  handleSelectUser(user) {
    const { dispatch } = this.props;
    if (user.hasOwnProperty('value')) {
      dispatch(changeMemberForBooking(user));
      dispatch(saveUserTypeForBookingCreate(user));
      this.updatePrice();
    } else dispatch(errorWhileSelectingUserForBooking());
  }

  renderSuggestFiltered(list) {
    const { fields } = this.props;
    let suggestList;
    if (fields.from.value) {
      suggestList = list.filter(obj => obj.id !== fields.from.value.id);
    }
    return suggestList;
  }

  setHoursOptions(date) {
    const { bookingDetail } = this.props;
    const hours = _get(bookingDetail, 'schedule', false);
    return (this.hoursOptions[date] = hours ? this.hoursFiltered(hours, date) : config.availableHours);
  }

  findById(sites, id) {
    const schedule = sites.map(site => site.parkings.map(parking => (parking.id === id ? parking.schedule : '')));
    return _compact(_flattenDeep(schedule));
  }

  hoursFiltered(streams, date) {
    const { fields } = this.props;
    const _streams = _filter(_get(streams, 'recurringSlots', false), { open: true });
    const dateH = date === 'pickupDate' ? fields.pickupDate.value : fields.returnDate.value;
    const _streamsOpenSpecial = _filter(_get(streams, 'oneTimeSlots', false), { open: true });
    const d = moment(dateH).isoWeekday() - 1;
    const day = config.daysOfWeek[d];

    if (_streamsOpenSpecial) {
      _streamsOpenSpecial.map(specialDaysHoursStream => {
        if (
          moment(dateH).isBetween(
            moment(specialDaysHoursStream.startDate),
            moment(specialDaysHoursStream.endDate).subtract(1, 'days'),
            null,
            []
          )
        ) {
          specialDaysHoursStream.days = [];
          const daysList = daysListOfDatesRange(specialDaysHoursStream.startDate, specialDaysHoursStream.endDate);
          daysList.map(day =>
            specialDaysHoursStream.days.push(
              moment(day)
                .locale('en')
                .format('dddd')
                .toUpperCase()
            )
          );
          return specialDaysHoursStream;
        }
      });
    }

    if (_streams) {
      if (day) {
        const validSpecialStreams = _find(_streamsOpenSpecial, { days: [day.value] });
        const validStreams = _find(_streams, { days: [day.value] });
        let goodTimes = [];
        if (
          validSpecialStreams &&
          moment(dateH).isBetween(moment(validSpecialStreams.startDate), moment(validSpecialStreams.endDate).subtract(1, 'days'), null, [])
        ) {
          validSpecialStreams.timeIntervals.map(time => {
            const range = _range(Number(time.start.split(':')[0]), Number(time.end.split(':')[0]) + 1); //+ 1 as fn range exclude end
            const ranges = range.map(it => {
              return _padStart(it, 2, '0');
            });
            goodTimes.push(ranges);
          });
          return _flatten(goodTimes);
        }

        if (validStreams) {
          if (validStreams.prevTime) {
            validStreams.prevTime.map(time => {
              const range = _range(Number(time.start.split(':')[0]), Number(time.end.split(':')[0]) + 1); //+ 1 as fn range exclude end
              const ranges = range.map(it => {
                return _padStart(it, 2, '0');
              });
              goodTimes.push(ranges);
            });
          } else {
            validStreams.timeIntervals.map(time => {
              const range = _range(Number(time.start.split(':')[0]), Number(time.end.split(':')[0]) + 1); //+ 1 as fn range exclude end
              const ranges = range.map(it => {
                return _padStart(it, 2, '0');
              });
              goodTimes.push(ranges);
            });
          }
          return _flatten(goodTimes);
        }
      }
    }
  }

  getClosedDays(date) {
    const { bookingDetail, bankHolidays, data } = this.props;
    const parkingSchedule = _get(bookingDetail, 'schedule', false);
    let closedDays = [];
    let openDays = [];
    let bankClosedDays = [];
    let closedRecurringDays = [];
    if (parkingSchedule) {
      if (parkingSchedule.applyFrenchBankHolidays) {
        bankClosedDays = _map(bankHolidays, day => {
          if (moment(date).isSame(moment(day.startDate), 'day')) {
            return moment(day.startDate).format('YYYY-MM-DD');
          }
        });
      }
      let oneTimeSlots = _filter(_get(parkingSchedule, 'oneTimeSlots', false), { open: false });
      if (oneTimeSlots) {
        closedDays = _map(oneTimeSlots, day => {
          if (moment(date).isBetween(moment(day.startDate), moment(day.endDate).subtract(1, 'days'), null, [])) {
            return daysListOfDatesRange(day.startDate, day.endDate);
          }
        });
      }

      let oneTimeSlotsOpen = _filter(_get(parkingSchedule, 'oneTimeSlots', false), { open: true });
      if (oneTimeSlotsOpen) {
        openDays = _map(oneTimeSlotsOpen, slot => {
          if (moment(date).isBetween(moment(slot.startDate), moment(slot.endDate).subtract(1, 'days'), null, [])) {
            return daysListOfDatesRange(slot.startDate, slot.endDate);
          }
        });
      }

      let initialRecurringSlots = _get(data, 'immutableParkings', false);
      let initialRecurringSlotsFiltered = this.findById(initialRecurringSlots, _get(bookingDetail, 'start.parking.id', false));

      if (initialRecurringSlotsFiltered.length) {
        const weekDays = _flatten(initialRecurringSlotsFiltered[0].recurringSlots.map(item => item.days));
        let fullCloseDays = _compact(config.daysOfWeek.map(day => (!_includes(weekDays, day.value) ? day.value : '')));
        _map(fullCloseDays, day => {
          if (
            day ===
            moment(date)
              .locale('en')
              .format('dddd')
              .toUpperCase()
          ) {
            closedRecurringDays.push(moment(date).format('YYYY-MM-DD'));
          }
        });
      }

      let final = _merge(bankClosedDays, closedDays, closedRecurringDays);
      const finalClosed = _difference(_flatten(_compact(final)), _flatten(_compact(openDays)));
      return _includes(_flatten(_compact(finalClosed)), moment(date).format('YYYY-MM-DD'));
    }
  }

  handleChangeMember() {
    const { dispatch } = this.props;
    dispatch(toggleEditChangeMember());
  }

  handleConfirmPrebooking() {
    const { dispatch, bookingDetail, bundle } = this.props;
    this.handleCreateBookingConfirmation(false).then(
      () => {
        dispatch(confirmPrebooking(bookingDetail.id)).then(() => {
          setTimeout(() => dispatch(getDataVehiclePlanning()), 1000);
        });
      },
      error => {
        dispatch(addErrorMessage({ error, bundle, errorCodePrefixes: ['bookingResult_'] }));
      }
    );
  }

  displayMemberSearch() {
    const { errorMemberSelection, foundUsers } = this.props;

    return (
      <div>
        <AutoComplete
          hintText={<FormattedMessage id="hint_search_by_name" />}
          dataSource={foundUsers}
          onUpdateInput={this.handleGetUsers}
          onNewRequest={this.handleSelectUser}
          fullWidth
          autoFocus
          style={{ position: 'relative', width: '540px', color: 'white', backgroundColor: 'white' }}
          menuStyle={{
            maxHeight: '200px',
            overflowY: 'auto',
            color: 'white'
          }}
        />
        {errorMemberSelection && (
          <div className="fieldErrorMsg">
            <FormattedMessage id="member_cannot_be_selected" />
          </div>
        )}
      </div>
    );
  }

  handleCreateBookingConfirmation(refreshPlanning) {
    const { dispatch, bookingDetail, VehiclePlanningEditBooking, bundle } = this.props;
    const params = trimValues(VehiclePlanningEditBooking);

    params.returnDate = VehiclePlanningEditBooking.returnDate.value;
    params.returnDateHour = VehiclePlanningEditBooking.returnDateHour.value;
    params.returnDateMin = VehiclePlanningEditBooking.returnDateMin.value;
    params.oneWayTrip = params.tripType === ONE_WAY_TRIP;
    params.memberLogin = bookingDetail.member.login;
    params.originalBookingId = bookingDetail.id;

    if (params.oneWayTrip) {
      delete bookingDetail.end.date;
    }
    delete params.tripType;
    if (params.from && params.from._isSite) {
      params.from = _pick(params.from, ['id', '_isSite']);
    }
    if (params.to && params.to._isSite) {
      params.to = _pick(params.to, ['id', '_isSite']);
    }
    if (bookingDetail.status === BOOKING_STATUS_PRE_BOOKED) {
      params.preBooking = true;
    }
    const enhancedParams = enhanceBookingVehicleSearchRequestParams(params);
    if (_get(enhancedParams, 'start.date', false)) {
      bookingDetail.start.date = localToUTC(enhancedParams.start.date);
    }
    if (_get(enhancedParams, 'end.date', false)) {
      bookingDetail.end.date = localToUTC(enhancedParams.end.date);
    }

    if (_get(enhancedParams, 'memberTypeId')) {
      bookingDetail.memberTypeId = enhancedParams.memberTypeId;
    }

    const confirmDetails = {
      paidBooking: params.paidBooking,
      comment: params.comment
    };

    return dispatch(confirmBookingEdition(bookingDetail.member, bookingDetail, confirmDetails, bookingDetail.id))
      .then(() => {
        if (refreshPlanning) setTimeout(() => dispatch(getDataVehiclePlanning()), 1000); // wait server to process data
        this.handleClose();
        dispatch(
          addFlashMessage({
            contentKey: 'booking_edit_success',
            type: FLASH_MESSAGE_TYPE_SUCCESS
          })
        );
      })
      .catch(error => {
        dispatch(addErrorMessage({ error, bundle, errorCodePrefixes: ['bookingResult_'] }));
      });
  }

  getChangeMemberTypeField() {
    const {
      fields: { memberTypeId },
      bookingDetail: { carSharingInfo }
    } = this.props;
    if (carSharingInfo.usageType === BOOKING_USAGE_TYPE_PRIVATE) {
      return (
        <BoxedSelect
          blockCustomClass="vp_memberType_boxedSelectBlock"
          customClass="vp_memberType_boxedSelectWrapper"
          noEmptyValue
          onChange={this.updatePrice}
          options={this.memberTypeOptions}
          disabled={this.state.memberTypeSelectDisabled}
          labelKey="member_type_association_label"
          id="memberType_select"
          field={memberTypeId}
        />
      );
    }
  }

  updatePrice() {
    const { bookingDetail } = this.props;
    const carSharingInfo = _get(bookingDetail, 'carSharingInfo');
    if (carSharingInfo.usageType === BOOKING_USAGE_TYPE_PRIVATE) {
      this.props.dispatch(getEstimatedPriceBooking());
    }
  }

  displayPrice() {
    const {
      bookingDetail,
      noprices,
      loadingBookingPrice,
      intl: { formatMessage },
      fields: { paidBooking }
    } = this.props;

    const carSharingInfo = _get(bookingDetail, 'carSharingInfo');
    const cost = carSharingInfo.cost || {};
    const hasPrice = !_isEmpty(cost);
    const voucher = cost.voucherCode || _get(bookingDetail, 'voucher') || null;
    const currency = cost.currency || 'EUR';
    const zeroTrick = !paidBooking.value ? 'no-way' : 'estimatedPriceForDuration';
    const price_cost = _get(cost, voucher ? 'estimatedPriceWithoutVoucher' : zeroTrick, '0');
    const amount = String(price_cost);
    const loader = (
      <div className="loading-price">
        <span>.</span>
        <span>.</span>
        <span>.</span>
      </div>
    );

    if (carSharingInfo.usageType !== BOOKING_USAGE_TYPE_BUSINESS && hasPrice && !noprices) {
      return (
        <FormRow>
          <div className="price">
            {loadingBookingPrice ? (
              loader
            ) : (
              <span className={voucher ? 'line-through' : ''}>
                <FormattedMessage
                  id="common_price_with_currency"
                  values={{
                    amount,
                    currency: formatMessage({ id: `unit_${currency}` })
                  }}
                />
              </span>
            )}
            {voucher && !loadingBookingPrice && (
              <FormattedMessage
                className={voucher || !paidBooking.value ? 'line-through' : ''}
                id="common_price_with_currency"
                values={{
                  amount: !paidBooking.value ? '0' : String(_get(cost, 'estimatedPriceForDuration')),
                  currency: formatMessage({ id: `unit_${currency}` })
                }}
              />
            )}
            {this.getChangeMemberTypeField()}
          </div>
        </FormRow>
      );
    }

    return (
      noprices && (
        <FormRow>
          <div className="price">
            {loadingBookingPrice && loader}
            {noprices && !loadingBookingPrice && <FormattedMessage id="common_status_ERROR" />}
            {this.getChangeMemberTypeField()}
          </div>
        </FormRow>
      )
    );
  }

  getTripTypeField() {
    const {
      fields: { tripType, usageOverride }
    } = this.props;
    if (usageOverride.value === VEHICLE_USAGE_TYPE_PLANNED || usageOverride.value === VEHICLE_USAGE_TYPE_IMMEDIATE)
      return (
        <div className="searchVehicle_tripSelection clearfix">
          <EkRadio
            formRowItemKey="backUserForm_userRole"
            items={[
              {
                labelKey: 'search_vehicle_oneWay',
                value: ONE_WAY_TRIP
              },
              {
                labelKey: 'search_vehicle_return',
                value: ROUND_TRIP
              }
            ]}
            namespace="tripType"
            disabled
            field={tripType}
          >
            <FieldErrorMsg field={tripType} customClass="fieldErrorMsg--backUserForm" />
          </EkRadio>
        </div>
      );
  }

  render() {
    const {
      openedEditBookingModal,
      confirmPrebooking,
      editMemberChange,
      loadingBookingDetail,
      sitesList,
      bookingDetail,
      handleSubmit,
      customFields,
      visibleUsageOptions,
      fields: {
        from,
        to,
        pickupDate,
        pickupDateHour,
        pickupDateMin,
        returnDate,
        returnDateHour,
        returnDateMin,
        tripType,
        voucherCode,
        comment,
        paidBooking,
        usageOverride
      }
    } = this.props;

    const now = new Date();
    const sitesListEnhanced = enhanceSiteList(sitesList);
    const minReturnDate = pickupDate && typeof pickupDate.value === 'object' ? pickupDate.value : now;
    const version = bookingDetail && bookingDetail.vehicle.version;
    this.setHoursOptions('pickupDate');
    this.setHoursOptions('returnDate');

    return (
      <Dialog
        modal={false}
        open={openedEditBookingModal}
        onRequestClose={this.handleClose}
        contentStyle={{ width: '600px', padding: '0!important' }}
        bodyStyle={{ padding: '0!important' }}
        style={{ padding: '0!important' }}
        className={classNames('VehiclePlanningEditBooking', { wide: editMemberChange })}
      >
        <div className={loadingBookingDetail ? 'edit-booking-loading' : 'VehiclePlanningEditBooking'}>
          {loadingBookingDetail && (
            <div className="vehiclePlanning_loadingOverlay">
              <Loader />
            </div>
          )}
          <form
            action="#"
            className="VehiclePlanningEditBooking"
            onSubmit={handleSubmit(confirmPrebooking ? this.handleConfirmPrebooking : this.handleCreateBookingConfirmation)}
          >
            <div className="VehiclePlanningEditBooking_container">
              <div className={classNames('VehiclePlanningEditBooking_col1', { wide: editMemberChange })}>
                <div className="title">
                  <FormattedMessage
                    id={
                      bookingDetail.status === BOOKING_STATUS_PRE_BOOKED
                        ? confirmPrebooking
                          ? 'confirm_prebooking'
                          : 'edit_prebooking'
                        : 'booking_detail_edit_booking'
                    }
                  />
                </div>
                {bookingDetail && (
                  <div
                    className={confirmPrebooking || bookingDetail.status === BOOKING_STATUS_PRE_BOOKED ? 'user-detail read' : 'user-detail'}
                    onClick={confirmPrebooking || bookingDetail.status === BOOKING_STATUS_PRE_BOOKED ? '' : this.handleChangeMember}
                  >
                    {!editMemberChange && (
                      <span>
                        <span className="user-name">
                          <UserIcon className="profil" />
                          <EditIcon className="edit" />
                          {bookingDetail.member.firstName + ' ' + bookingDetail.member.lastName}
                        </span>
                        <span className="user-mail">
                          <input
                            type="text"
                            readOnly
                            className={
                              confirmPrebooking || bookingDetail.status === BOOKING_STATUS_PRE_BOOKED
                                ? 'boxedInput email-read'
                                : 'boxedInput'
                            }
                            value={bookingDetail.member.login}
                          />
                        </span>
                      </span>
                    )}
                    {editMemberChange && <span>{this.displayMemberSearch()}</span>}
                  </div>
                )}
                <div className="VehiclePlanningEditBooking_col1Row1">
                  <div className="VehiclePlanningEditBooking_col1Row1Icons">
                    <div className="VehiclePlanningEditBooking_col1Row1Icons_bigDot" />
                    <div className="VehiclePlanningEditBooking_col1Row1Icons_smallDot" />
                    <div className="VehiclePlanningEditBooking_col1Row1Icons_smallDot" />
                    <div className="VehiclePlanningEditBooking_col1Row1Icons_smallDot" />
                    <LocationIcon />
                  </div>
                  <AddressAutocomplete field={from} fixtures={sitesListEnhanced} placeholderKey={false} disabled />
                  <AddressAutocomplete
                    field={tripType.value !== ONE_WAY_TRIP ? to : { value: '-' }}
                    fixtures={this.renderSuggestFiltered(sitesListEnhanced)}
                    placeholderKey={false}
                    disabled
                  />
                  {this.getTripTypeField()}
                </div>
                <div className="VehiclePlanningEditBooking_col1Row2 paddingTop0">
                  <CalendarIcon />
                  <BoxedInput
                    formRowItemKey="searchVehicle_from_pickupDate"
                    skinType="date"
                    type="date"
                    customClass="boxedInputWrapper--label _is_year_hidden VehiclePlanningEditBooking_col1Row2_pickupDate"
                    labelKey="search_vehicle_pickup"
                    field={pickupDate}
                    minDate={now}
                    onChange={this.updatePrice}
                    disableYearSelection
                    shouldDisableDate={this.getClosedDays}
                  >
                    <FieldErrorMsg field={pickupDate} customClass="fieldErrorMsg--searchVehicleForm" />
                  </BoxedInput>

                  <div className="VehiclePlanningEditBooking_col1Row2_dateWrap">
                    <TimeIcon />
                    <BoxedSelect
                      formRowItemKey="searchVehicle_from_pickupDateHour"
                      labelKey="search_vehicle_pickup_hour"
                      options={this.hoursOptions['pickupDate']}
                      customClass="searchVehicle_boxedSelectWrapper"
                      hideLabel
                      onChange={this.updatePrice}
                      field={pickupDateHour}
                    />
                    <span className="VehiclePlanningEditBooking_col1Row2_dvojtecka">:</span>
                    <BoxedSelect
                      formRowItemKey="searchVehicle_from_pickupDateMin"
                      labelKey="search_vehicle_pickup_min"
                      options={config.allAvailableMinutes}
                      customClass="searchVehicle_boxedSelectWrapper"
                      hideLabel
                      onChange={this.updatePrice}
                      field={pickupDateMin}
                    />
                  </div>
                </div>
                {tripType.value !== ONE_WAY_TRIP && (
                  <div className="VehiclePlanningEditBooking_col1Row2">
                    <CalendarIcon />
                    <BoxedInput
                      formRowItemKey="searchVehicle_to_returnDate"
                      skinType="date"
                      type="date"
                      customClass="boxedInputWrapper--label VehiclePlanningEditBooking_col1Row2_pickupDate"
                      labelKey="search_vehicle_return_date"
                      field={returnDate}
                      onChange={this.updatePrice}
                      minDate={minReturnDate}
                      mandatory={!(tripType.value === ONE_WAY_TRIP)}
                      disableYearSelection
                      shouldDisableDate={this.getClosedDays}
                    >
                      <FieldErrorMsg field={returnDate} customClass="fieldErrorMsg--searchVehicleForm" />
                    </BoxedInput>

                    <div className="VehiclePlanningEditBooking_col1Row2_dateWrap">
                      <TimeIcon />
                      <BoxedSelect
                        formRowItemKey="searchVehicle_from_pickupDateHour"
                        labelKey="search_vehicle_pickup_hour"
                        options={this.hoursOptions['returnDate']}
                        customClass="searchVehicle_boxedSelectWrapper"
                        onChange={this.updatePrice}
                        hideLabel
                        field={returnDateHour}
                      />
                      <span className="VehiclePlanningEditBooking_col1Row2_dvojtecka">:</span>
                      <BoxedSelect
                        formRowItemKey="searchVehicle_from_pickupDateMin"
                        labelKey="search_vehicle_pickup_min"
                        options={config.allAvailableMinutes}
                        customClass="searchVehicle_boxedSelectWrapper"
                        hideLabel
                        onChange={this.updatePrice}
                        field={returnDateMin}
                      />
                    </div>
                  </div>
                )}
              </div>
              <div className={classNames('VehiclePlanningEditBooking_col2', { collapse: editMemberChange })}>
                <div className={classNames('vehiclePlanning_closeIconWrap', { nomargin: editMemberChange })}>
                  <IconButton onClick={this.handleClose}>
                    <CloseIcon />
                  </IconButton>
                </div>
                <div className="box" id="box">
                  {bookingDetail && (
                    <div className="VehiclePlanningEditBooking_col2CarWrap">
                      <img src={bookingDetail.vehicle.pictureUrl} />
                      <div>
                        {bookingDetail.vehicle.registrationNumber}
                        <br />
                        <span>
                          {version.model.brand.name} {version.model.name} ({bookingDetail.vehicle.color.code})
                        </span>
                      </div>
                    </div>
                  )}

                  {bookingDetail && visibleUsageOptions.length && (
                    <div className="vehiclePlanning_usageSelector">
                      <BoxedSelect
                        labelKey="label_booking_usage"
                        noEmptyValue
                        options={visibleUsageOptions}
                        field={usageOverride}
                        fullWidth
                      />
                    </div>
                  )}

                  {bookingDetail && bookingDetail.carSharingInfo.usageType !== BOOKING_USAGE_TYPE_BUSINESS && (
                    <IsGranted allowed={[BACKUSER_ROLE_ADMIN, BACKUSER_ROLE_SUPER_ADMIN, BACKUSER_ROLE_ROOT]}>
                      <LabeledCheckbox id="addBooking_freeOfCharge" customClass="addBookingConfirmationForm_option" field={paidBooking}>
                        <FormattedMessage id="booking_create_confirmation_usage" />
                      </LabeledCheckbox>
                    </IsGranted>
                  )}
                  {bookingDetail && bookingDetail.carSharingInfo.usageType !== BOOKING_USAGE_TYPE_BUSINESS && this.displayPrice()}
                  {bookingDetail && bookingDetail.carSharingInfo.usageType !== BOOKING_USAGE_TYPE_BUSINESS && voucherCode.value !== '' && (
                    <FormRow>
                      <BoxedInput
                        formRowItemKey="searchVehicle_voucherCode"
                        type="text"
                        customClass="boxedInputWrapper"
                        labelKey="common_searchFor_voucherCode"
                        field={voucherCode}
                        disabled
                        // onChange={this.updatePriceAuto}
                      />
                      {/* <EkButton type="button" formRowItemKey="voucher-trigger" customClass="voucher_button" onAction={this.updatePrice}>
                      <FormattedMessage id="common_apply" />
                    </EkButton> */}
                    </FormRow>
                  )}
                  <CustomFieldsBooking
                    customFields={customFields}
                    customFieldsEditMode
                    formFields={this.props.fields}
                    usage={usageOverride.value}
                  />

                  <FormRow customClass="addBookingConfirmationForm_row">
                    <EkTextarea labelKey="booking_create_confirmation_comment" customClass="createBooking_textarea" field={comment} />
                  </FormRow>

                  <div className="addBookingConfirmationForm_buttonRow">
                    <EkButton
                      type="button"
                      formRowItemKey="vehicleForm_step_next"
                      customClass="addBookingConfirmationForm_button"
                      onAction={handleSubmit(confirmPrebooking ? this.handleConfirmPrebooking : this.handleCreateBookingConfirmation)}
                    >
                      <FormattedMessage id={confirmPrebooking ? 'confirm_prebooking' : 'booking_create_confirmation_save_button'} />
                    </EkButton>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </Dialog>
    );
  }
}

export default namedCompose(
  reduxForm(
    {
      onSubmitFail: scrollToFirstErrorDialog,
      form: 'VehiclePlanningEditBooking'
    },
    state => {
      const customFields = planningBookingEditCustomFieldsSelector(state);
      const confirmationForm = getValues(state.form.createBookingConfirmationForm) || {};
      const { list: memberTypes } = state.memberTypes || {};

      const {
        vehiclePlanning: {
          bookingDetail,
          noprices,
          editMemberChange,
          openedEditBookingModal,
          loadingBookingDetail,
          loadingBookingPrice,
          selectedBookingId,
          data,
          errorMemberSelection,
          foundUsers,
          confirmPrebooking,
          selectedMemberId
        },
        sites: { list },
        form: { VehiclePlanningEditBooking }
      } = state;

      const { vehicleUsageAtBookingCreation } = bookingDetail || {};

      const initialValues = {
        usageOverride: vehicleUsageAtBookingCreation,
        memberTypeId: _get(bookingDetail, 'memberType.id'),
        voucherCode: _get(bookingDetail, 'voucher.code'),
        from: {
          formattedAddress: _get(bookingDetail, 'start.parking.name', _get(bookingDetail, 'start.address.formattedAddress', '')),
          coordinates: _get(bookingDetail, 'start.coordinates', ''),
          _isSite: !!_get(bookingDetail, 'start.parking.name'),
          address: _get(bookingDetail, 'start.address', ''),
          id: _get(bookingDetail, 'start.parking.id', '')
        },
        to: !!_get(bookingDetail, 'end.address')
          ? {
              formattedAddress: _get(bookingDetail, 'end.parking.name', _get(bookingDetail, 'start.end.formattedAddress', '')),
              coordinates: _get(bookingDetail, '56end.coordinates', ''),
              _isSite: !!_get(bookingDetail, 'end.parking.name'),
              address: _get(bookingDetail, 'end.address', ''),
              id: _get(bookingDetail, 'end.parking.id', '')
            }
          : {},
        tripType:
          _get(bookingDetail, 'start.address.formattedAddress', '') === _get(bookingDetail, 'end.address.formattedAddress', '')
            ? ROUND_TRIP
            : ONE_WAY_TRIP,
        pickupDate: _get(bookingDetail, 'start.date') ? moment(bookingDetail.start.date).toDate() : '',
        returnDate: !!_get(bookingDetail, 'end.address') && _get(bookingDetail, 'end.date') ? moment(bookingDetail.end.date).toDate() : '',
        pickupDateHour: _get(bookingDetail, 'start.date')
          ? moment(bookingDetail.start.date).hour() === 0
            ? '00'
            : moment(bookingDetail.start.date).format('HH')
          : '',
        returnDateHour:
          !!_get(bookingDetail, 'end.address') && _get(bookingDetail, 'end.date')
            ? moment(bookingDetail.end.date).hour() === 0
              ? '00'
              : moment(bookingDetail.end.date).format('HH')
            : '',
        pickupDateMin: _get(bookingDetail, 'start.date')
          ? moment(bookingDetail.start.date).minute() === 0
            ? '00'
            : moment(bookingDetail.start.date).format('mm')
          : '00',
        returnDateMin:
          !!_get(bookingDetail, 'end.address') && _get(bookingDetail, 'end.date')
            ? moment(bookingDetail.end.date).minute() === 0
              ? '00'
              : moment(bookingDetail.end.date).format('mm')
            : '',
        comment: _get(bookingDetail, 'comment'),
        paidBooking: !_get(bookingDetail, 'carSharingInfo.freeOfCharges'),
        ...planningBookingCustomFieldsEditValuesSelector(state)
      };
      const { usageOverride } = getValues(VehiclePlanningEditBooking) || {};
      return {
        fields: [
          'from',
          'to',
          'pickupDate',
          'pickupDateHour',
          'pickupDateMin',
          'returnDate',
          'returnDateHour',
          'returnDateMin',
          'passengers',
          'tripType',
          'memberTypeId',
          'voucherCode',
          'paidBooking',
          'usageOverride',
          'comment',
          ...createCustomFieldsNames(customFields)
        ],
        validate: createValidator({
          ...createCustomFieldsValidators(customFields, usageOverride),
          from: [notEmpty(), addressOrSite()],
          to: [
            stopValidationIf({
              condition: (props, value) => {
                return _isEmpty(value);
              }
            }),
            addressOrSite()
          ],
          pickupDate: [notEmpty()],
          tripType: [notEmpty()],
          returnDate: [
            stopValidationIf({
              condition: props => {
                return props.form.tripType && props.form.tripType.value === ONE_WAY_TRIP ? props.form.tripType.value : false;
              }
            }),
            notEmpty(),
            dateAfterOrEqualField('pickupDate')
          ]
        }),
        openedEditBookingModal,
        sitesList: list,
        loadingBookingDetail,
        bookingDetail,
        noprices,
        VehiclePlanningEditBooking,
        selectedBookingId,
        selectedMemberId,
        customFields,
        data,
        editMemberChange,
        confirmationForm,
        memberTypes,
        errorMemberSelection,
        loadingBookingPrice,
        initialValues,
        confirmPrebooking,
        foundUsers,
        visibleUsageOptions: vpEditBookingUsageOptionsSelector(state),
        bundle: bundleSelector(state)
      };
    }
  ),
  injectIntl,
  pure
)(VehiclePlanningEditBooking);
