export const UNHANDLED_SERVER_ERROR = 'UNHANDLED_SERVER_ERROR';
export const INVALID_TOKEN = 'INVALID_TOKEN';

export const I18N_LOADING_FAILED = 'I18N_LOADING_FAILED';

export const LOGIN_MISSING_ARGUMENT = 'LOGIN_MISSING_ARGUMENT';
export const LOGIN_FAILED = 'LOGIN_FAILED';

export const USER_RESET_PASSWORD_MISSING_ARGUMENT = 'USER_RESET_PASSWORD_MISSING_ARGUMENT';
export const USER_RESET_PASSWORD_FAILED = 'USER_RESET_PASSWORD_FAILED';
export const USER_ENABLE_FAILED = 'USER_ENABLE_FAILED';

export const VALIDATION_FIELD_MISSING = 'VALIDATION_FIELD_MISSING';
export const VALIDATION_INCORRECT_COUNTRY_CODE = 'VALIDATION_INCORRECT_COUNTRY_CODE';
export const VALIDATION_FIELD_ONE_REQUIRED = 'VALIDATION_FIELD_ONE_REQUIRED';
export const VALIDATION_LENGTH_TOO_SHORT = 'VALIDATION_LENGTH_TOO_SHORT';
export const VALIDATION_LENGTH_TOO_LONG = 'VALIDATION_LENGTH_TOO_LONG';
export const VALIDATION_NOT_INTEGER = 'VALIDATION_NOT_INTEGER';
export const VALIDATION_LOWER = 'VALIDATION_LOWER';
export const VALIDATION_NOT_POSITIVE_NUMBER = 'VALIDATION_NOT_POSITIVE_NUMBER';
export const VALIDATION_MIN_VALUE = 'VALIDATION_MIN_VALUE';
export const VALIDATION_MAX_VALUE = 'VALIDATION_MAX_VALUE';
export const VALIDATION_NOT_NUMBER = 'VALIDATION_NOT_NUMBER';
export const VALIDATION_NOT_IN_ENUM = 'VALIDATION_NOT_IN_ENUM';
export const VALIDATION_MISMATCH = 'VALIDATION_MISMATCH';
export const VALIDATION_EMAIL_INVALID = 'VALIDATION_EMAIL_INVALID';
export const VALIDATION_EMAIL_ALREADY_EXISTS = 'VALIDATION_EMAIL_ALREADY_EXISTS';
export const VALIDATION_EMAIL_ALREADY_EXISTS_IN_SUPER_COMPANY = 'VALIDATION_EMAIL_ALREADY_EXISTS_IN_SUPER_COMPANY';
export const VALIDATION_BACK_USER_ALREADY_EXIST = 'VALIDATION_BACK_USER_ALREADY_EXIST';
export const VALIDATION_EMAIL_NOT_ASSOSIATED = 'VALIDATION_EMAIL_NOT_ASSOSIATED';
export const VALIDATION_PASSWORD_INVALID = 'VALIDATION_PASSWORD_INVALID';
export const VALIDATION_ARRAY_LENGTH = 'VALIDATION_ARRAY_LENGTH';

export const VALIDATION_FRENCH_PHONE_NUMBER_INVALID = 'VALIDATION_FRENCH_PHONE_NUMBER_INVALID';
export const VALIDATION_VIN_NUMBER_INVALID = 'VALIDATION_VIN_NUMBER_INVALID';
export const VALIDATION_URL_INVALID = 'VALIDATION_URL_INVALID';
export const VALIDATION_UUID_INVALID = 'VALIDATION_UUID_INVALID';
export const VALIDATION_ALPHANUMERIC_PHONE_NUMBER = 'VALIDATION_ALPHANUMERIC_PHONE_NUMBER';
export const VALIDATION_NOT_SECURE_IMAGE = 'VALIDATION_NOT_SECURE_IMAGE';
export const VALIDATION_NOT_LATITUDE = 'VALIDATION_NOT_LATITUDE';
export const VALIDATION_NOT_LONGITUDE = 'VALIDATION_NOT_LONGITUDE';
export const VALIDATION_INVALID_RADIUS = 'VALIDATION_INVALID_RADIUS';
export const VALIDATION_FIELD_NOT_PERCENTAGE = 'VALIDATION_FIELD_NOT_PERCENTAGE';
export const VALIDATION_FIELD_NOT_RATE = 'VALIDATION_FIELD_NOT_RATE';
export const VALIDATION_NOT_RRF_CODE = 'VALIDATION_NOT_RRF_CODE';
export const VALIDATION_NOT_VALID_ITLIAN_FISC_NUMBER = 'VALIDATION_NOT_VALID_ITLIAN_FISC_NUMBER';

export const VALIDATION_DATE_BEFORE_INVALID = 'VALIDATION_DATE_BEFORE_INVALID';
export const VALIDATION_DATE_IN_THE_PAST = 'VALIDATION_DATE_IN_THE_PAST';
export const VALIDATION_ENDDATE_BEFORE_STARTDATE = 'VALIDATION_ENDDATE_BEFORE_STARTDATE';
export const VALIDATION_DATE_SHOULD_BE_IN_THE_FUTURE = 'VALIDATION_DATE_SHOULD_BE_IN_THE_FUTURE';

export const VALIDATION_FIRST_CHAR_ALPHA = 'VALIDATION_FIRST_CHAR_ALPHA';
export const VALIDATION_REGISTRATION_FILE_INVALID = 'VALIDATION_REGISTRATION_FILE_INVALID';
export const VALIDATION_REGISTRATION_FILE_INVALID_MIME_TYPE = 'VALIDATION_REGISTRATION_FILE_INVALID_MIME_TYPE';
export const VALIDATION_REGISTRATION_FILE_SIZE_TOO_LARGE = 'VALIDATION_REGISTRATION_FILE_SIZE_TOO_LARGE';
export const VALIDATION_NOT_PRICE_NUMBER = 'VALIDATION_NOT_PRICE_NUMBER';
export const VALIDATION_NO_OVERLAP = 'VALIDATION_NO_OVERLAP';
export const VALIDATION_TIMESLOTS_OVERLAP = 'VALIDATION_TIMESLOTS_OVERLAP';
export const VALIDATION_TIMESLOTS_TO_DAY_EARLIER_THAN_START = 'VALIDATION_TIMESLOTS_TO_DAY_EARLIER_THAN_START';
export const VALIDATION_TIMESLOTS_TO_HOUR_MUST_BE_ZERO = 'VALIDATION_TIMESLOTS_TO_HOUR_MUST_BE_ZERO';
export const VALIDATION_TIMESLOTS_TO_MINUTES_MUST_BE_ZERO = 'VALIDATION_TIMESLOTS_TO_MINUTES_MUST_BE_ZERO';
export const VALIDATION_TIMESLOTS_TO_HOUR_EARLIER_THAN_FROM_HOUR = 'VALIDATION_TIMESLOTS_TO_HOUR_EARLIER_THAN_FROM_HOUR';
export const VALIDATION_TIMESLOTS_TO_MINUTES_EARLIER_THAN_FROM_MINUTES = 'VALIDATION_TIMESLOTS_TO_MINUTES_EARLIER_THAN_FROM_MINUTES';
export const VALIDATION_TIMESLOTS_TO_MINUTES_EQUAL_FROM_MINUTES = 'VALIDATION_TIMESLOTS_TO_MINUTES_EQUAL_FROM_MINUTES';
export const VALIDATION_FORBIDDEN_CHARACTERS = 'VALIDATION_FORBIDDEN_CHARACTERS';
export const VALIDATION_NUMBERS_CAPITALS_ALLOWED = 'VALIDATION_NUMBERS_CAPITALS_ALLOWED';
export const VALIDATION_DOMAINS_LIST = 'VALIDATION_DOMAINS_LIST';

export const VEHICLES_CREATE_VEHICLE_FAILED = 'VEHICLES_CREATE_VEHICLE_FAILED';
export const VEHICLES_SEARCH_VEHICLE_FAILED = 'VEHICLES_SEARCH_VEHICLE_FAILED';
export const VALIDATION_EMAIL_HEX_COLOR = 'VALIDATION_EMAIL_HEX_COLOR';

export const BACK_USERS_CREATE_BACK_USER_FAILED = 'BACK_USERS_CREATE_BACK_USER_FAILED';

export const COMPANIES_UPDATE_COMPANY_FAILED = 'COMPANIES_UPDATE_COMPANY_FAILED';

export const SITES_CREATE_SITE_FAILED = 'SITES_CREATE_SITE_FAILED';
export const SITES_UPDATE_SITE_FAILED = 'SITES_UPDATE_SITE_FAILED';

export const CONTRACTS_GET_CURRENT_CONTRACT_FAILED = 'CONTRACTS_GET_CURRENT_CONTRACT_FAILED';

export const FLEET_USAGES_SAVE_FLEET_USAGES_FAILED = 'FLEET_USAGES_SAVE_FLEET_USAGES_FAILED';

export const COLORS_CREATE_VEHICLES_COLOR_FAILED = 'COLORS_CREATE_VEHICLES_COLOR_FAILED';
export const COLORS_UPDATE_VEHICLES_COLOR_FAILED = 'COLORS_UPDATE_VEHICLES_COLOR_FAILED';

export const BRANDS_CREATE_BRAND_FAILED = 'BRANDS_CREATE_BRAND_FAILED';
export const BRANDS_UPDATE_BRAND_FAILED = 'BRANDS_UPDATE_BRAND_FAILED';

export const CATEGORIES_CREATE_VEHICLES_CATEGORY_FAILED = 'CATEGORIES_CREATE_VEHICLES_CATEGORY_FAILED';
export const CATEGORIES_UPDATE_VEHICLES_CATEGORY_FAILED = 'CATEGORIES_UPDATE_VEHICLES_CATEGORY_FAILED';

export const MODELS_CREATE_MODEL_FAILED = 'MODELS_CREATE_MODEL_FAILED';
export const MODELS_UPDATE_MODEL_FAILED = 'MODELS_UPDATE_MODEL_FAILED';

export const MEMBER_ANONYMIZE_SUCCESS = 'MEMBER_ANONYMIZE_SUCCESS';
export const MEMBER_DEANONYMIZE_SUCCESS = 'MEMBER_DEANONYMIZE_SUCCESS';

export const VERSIONS_CREATE_VERSION_FAILED = 'VERSIONS_CREATE_VERSION_FAILED';
export const VERSIONS_UPDATE_VERSION_FAILED = 'VERSIONS_UPDATE_VERSION_FAILED';

export const HOTLINES_CREATE_HOTLINE_FAILED = 'HOTLINES_CREATE_HOTLINE_FAILED';
export const HOTLINES_UPDATE_HOTLINE_FAILED = 'HOTLINES_UPDATE_HOTLINE_FAILED';

export const MEMBER_DEANONYMIZE_FAILED = 'MEMBER_DEANONYMIZE_FAILED';
export const MEMBER_ANONYMIZE_FAILED = 'MEMBER_ANONYMIZE_FAILED';
export const MEMBER_ANONYMIZE_FAILED_USER_IS_ACTIVE = 'MEMBER_ANONYMIZE_FAILED_USER_IS_ACTIVE';
export const MEMBER_ANONYMIZE_FAILED_USER_UNKNOWN = 'MEMBER_ANONYMIZE_FAILED_USER_UNKNOWN';
