import React, { Component, PropTypes } from 'react';
import _reduce from 'lodash/reduce';
import _get from 'lodash/get';

class PieLegend extends Component {
  itemsPredicate(res, item, index) {
    const label = _get(item, 'label');
    const color = _get(item, 'color', '#555');
    const time = _get(item, 'time');
    const sep = time ? ' ~ ' : '';

    if (label) {
      res.push(
        <div className="legend-item" key={index}>
          <div className="legend-circle" style={{ backgroundColor: color }} />
          <div className="legend-data">
            <span className="legend-label">{label}</span>
            <span className="legend-sep">{sep}</span>
            <span className="legend-time">{time}</span>
          </div>
        </div>
      );
    }

    return res;
  }

  displayLegendItems() {
    return _reduce(this.props.legendData, this.itemsPredicate, []);
  }

  render() {
    return <div className="vehicle-statuses-pie-legend">{this.displayLegendItems()}</div>;
  }
}

PieLegend.propTypes = {
  legendData: PropTypes.array
};

export default PieLegend;
