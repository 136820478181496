import React, { Component, PropTypes as T } from 'react';
import _partial from 'lodash/partial';
import _omit from 'lodash/omit';
import _omitBy from 'lodash/omitBy';
import { FormattedMessage, injectIntl } from 'react-intl';
import Chip from 'material-ui/Chip';
import { chipStyles } from '../../constants/style-constants';
import { intlMsg, isEmpty, translateChipsValues } from '../../utils/utils';
import autoBind from 'react-autobind';
import _isString from 'lodash/isString';
import _isArray from 'lodash/isArray';
import _isEmpty from 'lodash/isEmpty';
import _get from 'lodash/get';
import cs from 'classnames';
import { getMsg } from '../../utils/IntlGlobalProvider';

const isMultiSelect = value => {
  return Array.isArray(value) && value.length > 0;
};

class FilterChips extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
  }

  componentWillMount() {
    this.setFilters(this.props.urlParams);
  }

  componentWillReceiveProps(props) {
    if (props.urlParams !== this.props.urlParams) {
      this.setFilters(props.urlParams);
    }
  }

  legacyOmit(urlParams) {
    this.filterParams = _omitBy(
      _omit(urlParams, [
        'superCompanyId',
        'page',
        'companyIds',
        'subCompanyIds',
        'sort',
        'vehicleCompanyIds',
        'vehicleSubCompanyIds',
        'voucherGroupId',
        'property',
        'isDescending'
      ]),
      isEmpty
    );
  }

  newOmit(urlParams) {
    let array = this.props.omit;
    if (_isString(array)) array = [array];
    this.filterParams = _isArray(array) ? _omit(urlParams, array) : urlParams;
    this.filterParams = _omitBy(this.filterParams, isEmpty);
    if (this.props.hasArrayValues) this.filterParams = _omitBy(this.filterParams, _isEmpty);
  }

  setFilters(urlParams) {
    this.legacyOmit(urlParams);
    this.newOmit(this.filterParams);
  }

  getKeyFilterTranslation(translations, item, searchedKey, filterTypes) {
    const filterObject = _get(filterTypes, item);
    const filterType = _get(filterObject, 'type');
    const t_id = translations[item];

    if (filterType === 'option') {
      return intlMsg(this, t_id);
    }

    if (t_id) {
      return (
        <div className="text">
          <div className="label">
            <FormattedMessage id="data.with.colon" values={{ data: getMsg(t_id) }} />{' '}
          </div>
          <div className="value">{searchedKey}</div>
        </div>
      );
    }
  }

  render() {
    const { onDeleteChip, id, translations, intl, filterTypes, chipClass } = this.props;

    return (
      <div className="advancedList_chipsWrap">
        {Object.keys(this.filterParams).reduce((items, item) => {
          const values = this.filterParams[item];

          if (isMultiSelect(values)) {
            for (const key in values) {
              const value = values[key];
              const searchedKey = translateChipsValues(intl, value, item, filterTypes);

              items.push(
                <Chip
                  key={item + '_' + key}
                  id={id + '_filterChip_' + item + '_' + key}
                  className={cs('filter-chip', chipClass)}
                  style={chipStyles}
                  onRequestDelete={_partial(onDeleteChip, { param: item, key })}
                  children={this.getKeyFilterTranslation(translations, item, searchedKey, filterTypes)}
                />
              );
            }
          } else {
            const searchedKey = translateChipsValues(intl, values, item, filterTypes);

            items.push(
              <Chip
                key={item}
                id={id + '_filterChip_' + item}
                className={cs('filter-chip', chipClass)}
                style={chipStyles}
                onRequestDelete={_partial(onDeleteChip, item)}
                children={this.getKeyFilterTranslation(translations, item, searchedKey, filterTypes)}
              />
            );
          }

          return items;
        }, [])}
      </div>
    );
  }
}

FilterChips.propTypes = {
  urlParams: T.object,
  onDeleteChip: T.func,
  id: T.string,
  translations: T.object,
  filterTypes: T.object,
  chipClass: T.string,
  hasArrayValues: T.bool,
  omit: T.oneOfType([T.string, T.array])
};

/* Notes:
  PropTypes: omit: specify 'urlParams' that should not be displayed
*/

FilterChips.displayName = 'FilterChips';

export default injectIntl(FilterChips);
