import _ from 'lodash';

import {
  CUSTOM_FIELDS_GET_SUCCESS,
  CUSTOM_FIELDS_BOOKING_PICK,
  CUSTOM_FIELDS_SUBSCRIPTION_PICK,
  CUSTOM_FIELDS_SAVE_SUCCESS,
  CUSTOM_FIELDS_BOOKING_UNPICK,
  CUSTOM_FIELDS_SUBSCRIPTION_UNPICK,
  CUSTOM_FIELDS_UPDATE_SUCCESS,
  CUSTOM_FIELDS_SUBSCRIPTION_GET_SUCCESS,
  CUSTOM_FIELDS_BOOKINGS_GET_SUCCESS,
  MEMBERS_CUSTOM_FIELDS_CLEAR
} from '../constants/actionTypes-constants';

import { CUSTOM_FIELD_FORM_SUBSCRIPTION } from '../constants/backend-constants';

const initialState = {
  subscriptionPicked: {},
  bookingPicked: null,
  customFields: []
};

function sortByAscPosition(a, b) {
  return a.position > b.position;
}

export default function(state = initialState, action) {
  switch (action.type) {
    case CUSTOM_FIELDS_GET_SUCCESS:
      return {
        ...state,
        customFields: _.get(action, 'fields', [])
      };
    case MEMBERS_CUSTOM_FIELDS_CLEAR:
      return {
        ...state,
        customFields: []
      };
    case CUSTOM_FIELDS_SUBSCRIPTION_GET_SUCCESS:
      return {
        ...state,
        customFields: _.get(action, 'fields', [])
      };
    case CUSTOM_FIELDS_BOOKINGS_GET_SUCCESS:
      return {
        ...state,
        customFields: _.get(action, 'fields', [])
      };
    case CUSTOM_FIELDS_SUBSCRIPTION_PICK:
      return {
        ...state,
        subscriptionPicked: action.field
      };
    case CUSTOM_FIELDS_SUBSCRIPTION_UNPICK:
      return {
        ...state,
        subscriptionPicked: {}
      };
    case CUSTOM_FIELDS_BOOKING_PICK:
      return {
        ...state,
        bookingPicked: action.field
      };
    case CUSTOM_FIELDS_BOOKING_UNPICK:
      return {
        ...state,
        bookingPicked: {}
      };
    case CUSTOM_FIELDS_SAVE_SUCCESS:
      const isSubscriptionForm = action.field.form === CUSTOM_FIELD_FORM_SUBSCRIPTION;

      return {
        ...state,
        customFields: {
          ...state.customFields,
          [action.field.form]: _.get(state.customFields, `[${action.field.form}]`, [])
            .concat(action.field)
            .sort(sortByAscPosition)
        },
        subscriptionPicked: isSubscriptionForm ? action.field : state.subscriptionPicked,
        bookingPicked: !isSubscriptionForm ? action.field : state.bookingPicked
      };
    case CUSTOM_FIELDS_UPDATE_SUCCESS:
      return {
        ...state,
        customFields: {
          ...state.customFields,
          [action.field.form]: state.customFields[action.field.form].map(field => (field.id === action.field.id ? action.field : field))
        }
      };
    default:
      return state;
  }
}
