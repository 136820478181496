import React, { Component, PropTypes } from 'react';

import Header from '../../components/Header/Header';
import SideMenu from '../../components/SideMenu/SideMenu';
import FlashMessageDisplayer from '../../components/FlashMessageDisplayer/FlashMessageDisplayer';

class Redirect extends Component {
  render() {
    return (
      <div className="redirectPage mainContainer_content">
        <div className="pageContainer"></div>
      </div>
    );
  }
}

Redirect.displayName = 'Redirect';

Redirect.propTypes = {};

export default Redirect;
