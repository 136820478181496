import React, { Component, PropTypes } from 'react';
import ListDetails from '../../../../components/_v2/ColumnList/Details';
import BoxedInput, { getBoxedInputField } from '../../../../components/BoxedInput/BoxedInput';
import BoxedSelect from '../../../../components/BoxedSelect/BoxedSelect';
import FieldErrorMsg from '../../../../components/FieldErrorMsg/FieldErrorMsg';
import FormRow from '../../../../components/FormRow/FormRow';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';

import { createValidator, notEmpty } from '../../../../validation/sync-validation';
import { scrollToFirstError } from '../../../../utils/utils';

import { RaisedButton } from 'material-ui';
import { FormattedMessage } from 'react-intl';
import { fallbackFunc } from '../../../../utils/utils';
import memoizeOne from 'memoize-one';

const mapHotlinesOptions = memoizeOne(list =>
  list.map(item => ({
    label: item.name,
    value: item.id
  }))
);

const mapConfigsOptions = memoizeOne((allConfigs, selectedConfig) =>
  allConfigs.map(config => {
    const { id: selectedId } = selectedConfig || {};
    const { id: configId, name: configName } = config || {};

    if (selectedId !== configId) {
      return {
        label: configName,
        value: configId
      };
    }
  })
);

export class ConfigurationView extends Component {
  componentWillReceiveProps(props) {
    this.callback = !props.limitedAccess ? props.handleSubmit(this.props.onCallback) : undefined;
  }

  getHotlinesField() {
    const {
      fields: { hotlineId },
      selectedHotline,
      limitedAccess,
      list
    } = this.props;

    if (list.length) {
      return (
        <BoxedSelect
          formRowItemKey="configurationForm_hotline"
          id="hotlinesSelect"
          labelKey="configurationForm_hotline_label"
          options={mapHotlinesOptions(list)}
          field={hotlineId}
          customClass="configurationForm_boxedSelectWrapper"
          disabled={limitedAccess}
        />
      );
    }

    return (
      <BoxedInput
        type="text"
        id="single-hotline"
        formRowItemKey="single-hotline"
        labelKey="configurationForm_hotline_label"
        customClass="configurationForm_boxedInput"
        field={getBoxedInputField(selectedHotline)}
        disabled
      />
    );
  }

  render() {
    const {
      fields: { name, parentConfigurationId },
      inputRef,
      allConfigurations,
      detailConfiguration,
      limitedAccess
    } = this.props;

    return (
      <ListDetails title={this.props.title} onClose={this.props.onClose}>
        <form className="sc-details-form" onSubmit={this.callback}>
          <FormRow customClass="configurationForm_row">
            <BoxedInput
              formRowItemKey="configurationForm_name"
              inputRef={inputRef}
              type="text"
              id="name"
              labelKey="configurationForm_name_label"
              customClass="configurationForm_boxedInput"
              field={name}
              disabled={limitedAccess}
            >
              <FieldErrorMsg field={name} customClass="fieldErrorMsg--configurationForm" />
            </BoxedInput>
          </FormRow>

          <FormRow customClass="configurationForm_row">
            {this.getHotlinesField()}
            <BoxedSelect
              formRowItemKey="configurationForm_parent_configuration"
              id="parentConfigurationSelect"
              labelKey="configurationForm_parent_configuration_label"
              options={mapConfigsOptions(allConfigurations, detailConfiguration)}
              field={parentConfigurationId}
              customClass="configurationForm_boxedSelectWrapper"
              disabled={limitedAccess}
            />
          </FormRow>

          {!limitedAccess && (
            <div className="sc-actions">
              <RaisedButton label={this.props.submitLabel} primary type="button" onClick={this.callback} />
            </div>
          )}
        </form>
      </ListDetails>
    );
  }
}

ConfigurationView.defaultProps = {
  onCallback: fallbackFunc,
  submitLabel: <FormattedMessage id="common_save" />
};

ConfigurationView.propTypes = {
  title: PropTypes.any,
  inputRef: PropTypes.func,
  onClose: PropTypes.func,
  submitLabel: PropTypes.any,
  onCallback: PropTypes.func,
  limitedAccess: PropTypes.bool,
  selectedHotline: PropTypes.string
};

ConfigurationView = reduxForm({
  form: 'ConfigurationView',
  onSubmitFail: scrollToFirstError,
  touchOnBlur: false,
  fields: ['name', 'hotlineId', 'parentConfigurationId'],
  validate: createValidator({
    name: [notEmpty()]
  })
})(ConfigurationView);

export default connect(state => {
  const {
    configuration: { urlParams, detailConfiguration, allConfigurations },
    hotlines: { list }
  } = state;

  return {
    urlParams,
    detailConfiguration,
    allConfigurations,
    list
  };
})(ConfigurationView);
