import React, { Component } from 'react';
import ColumnList from '../../../components/_v2/ColumnList';
import { connect } from 'react-redux';

import {
  createMenuItemsSites,
  resolveActive,
  resolveSelected,
  showNextView,
  showParentView
} from '../../../components/_v2/ColumnList/helpers';
import { renderItem } from './helpers';
import { FormattedMessage } from 'react-intl';
import { sitesListSelector } from '../../../selectors/all-selectors';

class OrganizationSites extends Component {
  componentWillMount() {
    this.setCallbacks();
    this.setVars();
  }

  componentDidUpdate() {
    this.setVars();
  }

  setVars() {
    const { list } = this.props;
    if (list.length > 0) {
      return (this.menuItems = createMenuItemsSites({
        onAdd: this.handleAdd,
        addKey: 'addSite_title',
        onCopy: this.handleCopy,
        copyKey: 'copySite_title'
      }));
    }
    this.menuItems = createMenuItemsSites({
      onAdd: this.handleAdd,
      addKey: 'addSite_title'
    });
  }

  setCallbacks() {
    this.handleSelect = ({ id }, _, { openInNewTab }) => {
      showNextView.call(this, 'organizationParkings', ':siteId', id, openInNewTab);
    };

    this.handleEdit = ({ id } = {}, _, { openInNewTab }) => {
      showNextView.call(this, 'organizationEditSite', ':siteId', id, openInNewTab);
    };

    this.handleAdd = () => {
      showNextView.call(this, 'organizationAddSite');
    };

    this.handleCopy = () => {
      showNextView.call(this, 'organizationCopySite');
    };

    this.resolveSelected = resolveSelected.bind(this);
    this.resolveActive = resolveActive.bind(this);
    this.showParentView = showParentView.bind(this);
  }

  render() {
    return (
      <ColumnList
        title={<FormattedMessage id="autocomplete_company_sites_title" />}
        inputRef={this.props.inputRef}
        items={this.props.list}
        renderItem={renderItem}
        onSelect={this.handleSelect}
        selected={this.resolveSelected}
        active={this.resolveActive}
        onEdit={this.handleEdit}
        menuItems={this.menuItems}
        onBack={this.showParentView}
        localSearchEnabled
      />
    );
  }
}

const mapStateToProps = state => {
  return { list: sitesListSelector(state) };
};

OrganizationSites = connect(mapStateToProps)(OrganizationSites);

export default OrganizationSites;
