import React, { Component, PropTypes } from 'react';
import { FormattedMessage } from 'react-intl';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import FormRow from '../FormRow/FormRow';
import EkButton from '../EkButton/EkButton';
import EkRadio from '../EkRadio/EkRadio';
import BoxedInput from '../BoxedInput/BoxedInput';
import { booleanOptions } from '../../constants/options-constants';
import { boolToString, isTestEnv, toBoolean } from '../../utils/utils';
import { userRoleSelector } from '../../selectors/all-selectors';
import { BACKUSER_ROLE_ROOT, BACKUSER_ROLE_SUPER_ADMIN } from '../../constants/backend-constants';

const settingsRoles = {
  [BACKUSER_ROLE_ROOT]: true,
  [BACKUSER_ROLE_SUPER_ADMIN]: true
};

class SettingsForm extends Component {
  componentWillMount() {
    this.handleEnableQuickSightTestEmailChange = value => {
      const bool = toBoolean(value);
      if (!bool) this.props.fields.quickSightTestEmail.onChange('');
    };
  }

  render() {
    const { fields: f, userRole, handleSubmit, onCallback } = this.props;

    return (
      <form className="settings-form" method="post" action="#" onSubmit={handleSubmit(onCallback)} noValidate>
        <section className="profileForm_section">
          <div className="profileForm_container">
            {settingsRoles[userRole] && (
              <FormRow customClass="profileForm_row">
                <EkRadio
                  customWrapperClass="companyForm_ekRadioWrapper"
                  id="enableNewUi"
                  titleKey="label.enable.new.ui"
                  items={booleanOptions}
                  field={f.enableNewUi}
                />
                <EkRadio
                  customWrapperClass="companyForm_ekRadioWrapper"
                  id="enableMobileView"
                  titleKey="label.enable.mobile.view"
                  items={booleanOptions}
                  field={f.enableMobileView}
                />
              </FormRow>
            )}
            <FormRow customClass="profileForm_row">
              <EkRadio
                customWrapperClass="companyForm_ekRadioWrapper"
                id="enableVpV1"
                titleKey="label.enable.vp.v1"
                items={booleanOptions}
                field={f.enableVpV1}
              />
            </FormRow>
            {isTestEnv() && (
              <FormRow>
                <EkRadio
                  customWrapperClass="companyForm_ekRadioWrapper"
                  id="enableQuickSightTestEmail"
                  title="Use custom QuickSight email (test env only)"
                  items={booleanOptions}
                  onChange={this.handleEnableQuickSightTestEmailChange}
                  field={f.enableQuickSightTestEmail}
                />
                {toBoolean(f.enableQuickSightTestEmail.value) && (
                  <BoxedInput
                    formRowItemKey="profileForm_email"
                    type="text"
                    id="email"
                    label="Custom QuickSight email"
                    customClass="profileForm_boxedInput"
                    field={f.quickSightTestEmail}
                  />
                )}
              </FormRow>
            )}
          </div>
        </section>
        <FormRow customClass="profileForm_row">
          <EkButton
            formRowItemKey="profileForm_submitBtn"
            type="submit"
            customClass="profileForm_actionsButton _is_alone"
            onAction={handleSubmit(onCallback)}
          >
            <FormattedMessage id="account_profile_save" />
          </EkButton>
        </FormRow>
      </form>
    );
  }
}

SettingsForm.displayName = 'SettingsForm';

SettingsForm.propTypes = {
  onCallback: PropTypes.func
};

SettingsForm = reduxForm({
  form: 'settings',
  fields: ['enableNewUi', 'enableMobileView', 'enableVpV1', 'enableQuickSightTestEmail', 'quickSightTestEmail']
})(SettingsForm);

export default connect(state => {
  const { user: u } = state;

  return {
    userRole: userRoleSelector(state),
    initialValues: {
      enableVpV1: boolToString(u.enableVpV1),
      enableNewUi: boolToString(u.enableNewUi),
      enableMobileView: boolToString(u.enableMobileView),
      enableQuickSightTestEmail: boolToString(u.enableQuickSightTestEmail),
      quickSightTestEmail: u.quickSightTestEmail
    }
  };
})(SettingsForm);
