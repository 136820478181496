import React, { Component, PropTypes } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { routeActions } from 'react-router-redux';
import _get from 'lodash/get';
import _partial from 'lodash/partial';
import _map from 'lodash/map';
import { Row, Col } from 'react-bootstrap';
import autoBind from 'react-autobind';
import {
  exportVehiclesListWithSearch,
  getBrandsList,
  getModelsList,
  getFleetList,
  clearFleetListData,
  getCategoriesRequest,
  getColors,
  getVersionsList,
  emptyModelList,
  emptyVersionList
} from '../../actions/all-actions';
import routes, { fleetEditRules } from '../../constants/routes-constants';
import { mapFilterValueFromObject } from '../../utils/utils';
import { BOOKING_TYPE_RIDE_SHARING } from '../../constants/backend-constants';
import { vehicleHeaderParams } from '../../constants/table-constants';
import AdvancedList from '../../components/AdvancedList/AdvancedList';
import BookingListIcon from '../../components/BookingListIcon/BookingListIcon';
import Filters from '../../components/AdvancedList/components/Filters';
import FlatButton from 'material-ui/FlatButton';
import AddIcon from 'material-ui/svg-icons/content/add';
import BlockIcon from 'material-ui/svg-icons/content/block';
import DownloadIcon from 'material-ui/svg-icons/file/file-download';
import FilterChips from '../../components/FilterChips/FilterChips';
import { fleetFilterChips, allSystemTypes } from '../../constants/options-constants';
import Tooltip from '../../components/Tooltip/Tooltip';
import { createVehicleFilterTypes } from '../../constants/filterTypes-constants';
import { ALL } from '../../constants/generic-constants';
import { checkRole } from '../../constants/backuser-role-rules';
import { userRoleSelector } from '../../selectors/all-selectors';
import { vehicleUsages } from '../../constants/filters-constants';

class Fleet extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.setReadOnly(props);
    this.derivedStateFromProps(props);
  }

  componentWillMount() {
    const { pageParams, dispatch } = this.props;
    this.callApi(pageParams);
    dispatch(getBrandsList(false)).then(() => this.getModels(pageParams));
    dispatch(getCategoriesRequest(false));
    dispatch(getColors(false));
  }

  componentWillReceiveProps(props) {
    this.derivedStateFromProps(props);
  }

  componentWillUnmount() {
    this.props.dispatch(clearFleetListData());
  }

  derivedStateFromProps(props) {
    this.updateFilters(props);
  }

  updateFilters(props) {
    const allBrands = mapFilterValueFromObject(props.brandList),
      allModels = mapFilterValueFromObject(props.modelList),
      allVersions = mapFilterValueFromObject(props.versionList),
      allColors = mapFilterValueFromObject(_map(props.colorList, item => ({ id: item.id, name: item.code }))),
      allCategories = mapFilterValueFromObject(props.categoriesList.map(item => ({ id: item.id, name: item.type })));

    this.filterTypes = createVehicleFilterTypes(allBrands, allModels, allCategories, allColors, allVersions, vehicleUsages);
  }

  setReadOnly(props) {
    const { role } = props;
    this.readOnly = !checkRole(fleetEditRules, role);
  }

  getModels(params) {
    const { dispatch, brandList } = this.props;

    if (_get(params, 'brand', false)) {
      let brandId;
      brandList.map(item => {
        if (item.name === params.brand) brandId = item.id;
      });
      if (brandId)
        dispatch(getModelsList(brandId, false)).then(() => {
          if (_get(params, 'model', false)) {
            this.getVersions(params);
          }
        });
    }
  }

  getVersions(params) {
    const { dispatch, modelList } = this.props;

    if (_get(params, 'model', false)) {
      let modelId;
      modelList.map(item => {
        if (item.name === params.model) modelId = item.id;
      });
      if (modelId) dispatch(getVersionsList(modelId, false));
    }
  }

  callApi(params) {
    const { dispatch } = this.props;
    dispatch(getFleetList(params));
  }

  handleChangePage(value) {
    const { dispatch, listMetadata, urlParams } = this.props;
    const params = {
      page: {
        number: value,
        size: listMetadata.paginationInfo.pageSize
      }
    };
    const newParams = { ...urlParams, ...params };
    this.callApi(newParams);
    dispatch(routeActions.push(routes.fleet.path.replace(':search', encodeURIComponent(JSON.stringify(newParams)))));
  }

  handleRefreshList() {
    const { pageParams } = this.props;
    this.callApi(pageParams);
  }

  handleSearchFleet(newFilters) {
    const { dispatch, listMetadata, urlParams } = this.props;

    const newParams = {
      ...urlParams,
      ...newFilters,
      page: {
        number: 1,
        size: listMetadata.paginationInfo.pageSize
      }
    };
    this.callApi(newParams);
    this.getVersions(newParams);
    this.getModels(newParams);

    dispatch(routeActions.push(routes.fleet.path.replace(':search', encodeURIComponent(JSON.stringify(newParams)))));
  }

  handleNumberResultsSelect(value) {
    const { dispatch, urlParams } = this.props;

    let params = {
      page: {
        number: 1,
        size: parseInt(value)
      }
    };
    let newParams = Object.assign({}, urlParams, params);
    this.callApi(newParams);
    dispatch(routeActions.push(routes.fleet.path.replace(':search', encodeURIComponent(JSON.stringify(newParams)))));
  }

  handleFullExport() {
    const { dispatch, urlParams } = this.props;
    dispatch(exportVehiclesListWithSearch(urlParams, true));
  }

  handleGotoDetail(params) {
    this.props.dispatch(routeActions.push(routes[params[0]].path.replace(params[1], params[2])));
  }

  renderItem(item, id, reactKey) {
    const colProps = { xs: 1, md: 1 };

    return (
      <div className="advancedList_rowWrap" key={reactKey}>
        <Row className="advancedList_row" onClick={_partial(this.handleGotoDetail, ['vehicleDetail', ':vehicleId', item.id])} id={id}>
          <Col xs="3" md="3">
            <div className="bookingsPage_firstItemWrap">
              {_get(item, 'pictureUrl', false) ? (
                <img src={item.pictureUrl} alt="vehicle" className="fleetPage_listImage" />
              ) : (
                <BookingListIcon statusIcon={false} type={BOOKING_TYPE_RIDE_SHARING} status={item.status} />
              )}
              <div>
                <div className="bookingsPage_doubleColumnWrap">{item.registrationNumber}</div>
                <div className="firstLetterUppercase">
                  <FormattedMessage id={'vehicle_fuelType_' + item.fuelType} />
                </div>
              </div>
            </div>
          </Col>
          <Col {...colProps}>{<FormattedMessage id={'vehicle_usage_type_' + item.usage.toLowerCase()} />}</Col>
          <Col {...colProps}>
            <div className="bookingsPage_doubleColumnWrap">{item.version.model.brand.name}</div>
            {item.version.model.name}
          </Col>

          <Col {...colProps}>{!!item.systemType && allSystemTypes.filter(op => op.value === item.systemType)[0].label}</Col>
          <Col xs="1" md="1">
            {item.company.name}
          </Col>
          <Col xs="4" md="4">
            <div className="bookingsPage_doubleColumnWrap">{_get(item, 'lastPosition.parking.name', '-')}</div>
            {_get(item, 'lastPosition.parking.site.name', '-')}
          </Col>
          <Col xs="1" md="1">
            {_get(item, 'statusType') && (
              <div className={'fleet_statusTypeCell--' + item.statusType + ' bookingsPage_doubleColumnWrap firstLetterUppercase'}>
                <FormattedMessage id={'vehicle_statusType_' + item.statusType} />
              </div>
            )}
          </Col>
        </Row>
      </div>
    );
  }

  handleAddVehicle() {
    this.props.dispatch(routeActions.push(routes.addVehicle.path));
  }

  handleDeleteChip(item) {
    const { urlParams, dispatch, listMetadata } = this.props;
    const { param, key } = item;

    const newParams = {
      ...urlParams,
      page: {
        number: 1,
        size: listMetadata.paginationInfo.pageSize
      }
    };

    const multiSelect = param && newParams[param].length > 1;
    multiSelect ? newParams[param].splice(key, 1) : delete newParams[item];
    delete newParams.page;

    if (item === 'brand') {
      dispatch(emptyModelList());
    }
    if (item === 'model') {
      dispatch(emptyVersionList());
    }
    this.callApi(newParams);
    dispatch(routeActions.push(routes.fleet.path.replace(':search', encodeURIComponent(JSON.stringify(newParams)))));
  }

  handleSort(sortPropertyName) {
    const { dispatch, urlParams } = this.props;
    const newParams = {
      ...urlParams,
      sort: {
        isDescending: _get(urlParams, 'sort.property', false) === sortPropertyName ? !_get(urlParams, 'sort.isDescending', false) : false,
        property: sortPropertyName
      }
    };
    this.callApi(newParams);
    dispatch(routeActions.push(routes.fleet.path.replace(':search', encodeURIComponent(JSON.stringify(newParams)))));
  }

  render() {
    const { list, listMetadata, exporting, loadingList, intl, urlParams, companyId, loadingError } = this.props;

    const noCompanySelected = companyId === ALL;
    const addVehicleButton = noCompanySelected ? <BlockIcon /> : <AddIcon />;
    const chooseCompanyText = intl.formatMessage(
      { id: 'common_choose_company_to' },
      { doSomething: intl.formatMessage({ id: 'fleet_add_vehicle_button_label' }).toLowerCase() }
    );

    return (
      <div className="fleetPage mainContainer_content">
        <div className="pageContainer">
          <div className="fleetPage_content">
            <section className="fleetPage_management">
              <FilterChips
                id="fleet"
                urlParams={urlParams}
                onDeleteChip={this.handleDeleteChip}
                translations={fleetFilterChips}
                filterTypes={this.filterTypes}
              />

              <div className="advancedList_actions page">
                <Filters filterTypes={this.filterTypes} onFilteredSearchForm={this.handleSearchFleet} id="fleet" />

                <div className="bookingsPage_actionsButtons">
                  <FlatButton
                    id="fleet_export_button"
                    label={exporting ? intl.messages.common_button_exporting : intl.messages.bookings_export_all_button}
                    icon={<DownloadIcon />}
                    disabled={exporting}
                    onClick={this.handleFullExport}
                  />
                  {!this.readOnly && (
                    <Tooltip placement="top" distance={5} content={chooseCompanyText} disabled={!noCompanySelected}>
                      <FlatButton
                        id="fleet_add_button"
                        label={intl.messages.fleet_add_vehicle_button_label}
                        icon={addVehicleButton}
                        onClick={this.handleAddVehicle}
                        disabled={noCompanySelected}
                      />
                    </Tooltip>
                  )}
                </div>
              </div>

              <AdvancedList
                id="fleet"
                data={list}
                listMetadata={listMetadata}
                loading={loadingList}
                renderItem={this.renderItem}
                onChangePage={this.handleChangePage}
                onRefreshList={this.handleRefreshList}
                onChangeRowsPerPage={this.handleNumberResultsSelect}
                onSort={this.handleSort}
                error={loadingError}
                urlParams={urlParams}
                header={vehicleHeaderParams}
              />
            </section>
          </div>
        </div>
      </div>
    );
  }
}

Fleet.displayName = 'Fleet';

Fleet.propTypes = {
  list: PropTypes.array,
  listMetadata: PropTypes.object
};

export default connect(state => {
  const {
    vehicles: { paginatedResults, vehiclesFiltersFormIsOpen, urlParams, exporting, loadingList, loadingError },
    user: { userInfo },
    page: { params },
    brands: { list }
  } = state;

  return {
    list: _get(paginatedResults, 'results', []),
    listMetadata: _get(paginatedResults, 'metadata', {}),
    vehiclesFiltersFormIsOpen,
    urlParams,
    userInfo,
    exporting,
    pageParams: params,
    brandList: list,
    modelList: state.models.list,
    categoriesList: state.categories.list,
    colorList: state.colors.list,
    versionList: state.versions.list,
    loadingList,
    loadingError,
    role: userRoleSelector(state),
    companyId: state.companies.currentCompany.id
  };
})(injectIntl(Fleet));
