import { UPDATE_LOCATION } from 'react-router-redux';
import _get from 'lodash/get';
import * as types from '../constants/actionTypes-constants';

let initialState = {
  openedVehicleCategoryWidgets: new Set(),
  currentCompanyBusinessTimeSlots: new Map(),
  categoriesTransversalPrices: new Map(),
  categoriesTimeSlots: new Map(),
  currentTabIndex: 0,
  memberTypeId: null,
  saveAllTimeSlots: false,
  openCopyMemberType: false,
  timeSlotsToSend: [],
  timeSlotsErrors: {},
  cancellationFees: new Map()
};

export default function pricing(state = initialState, action) {
  switch (action.type) {
    case types.PRICING_TOGGLE_DIALOG_COPY_TIME_SLOTS_OF_MEMBER_TYPE:
      return {
        ...state,
        openCopyMemberType: !state.openCopyMemberType
      };

    case types.PRICING_GET_BUSINESS_TIME_SLOTS_SUCCESS: {
      if (action.data.timeSlots) {
        return {
          ...state,
          categoriesTimeSlots: new Map(state.categoriesTimeSlots).set(action.vehicleCategoryId, action.data.timeSlots)
        };
      } else {
        let newCategoriesTimeSlots = new Map(state.categoriesTimeSlots);
        newCategoriesTimeSlots.delete(action.vehicleCategoryId);
        return {
          ...state,
          categoriesTimeSlots: newCategoriesTimeSlots
        };
      }
    }

    case types.PRICING_GET_CATEGORY_TRANSVERSAL_PRICES_SUCCESS: {
      if (!action.data) {
        return state;
      }
      if (action.data) {
        return {
          ...state,
          categoriesTransversalPrices: new Map(state.categoriesTransversalPrices).set(action.vehicleCategoryId, action.data)
        };
      } else {
        let newCategoriesTransversalPrices = new Map(state.categoriesTransversalPrices);
        newCategoriesTransversalPrices.delete(action.vehicleCategoryId);
        return {
          ...state,
          categoriesTransversalPrices: newCategoriesTransversalPrices
        };
      }
    }

    case types.PRICING_GET_CATEGORY_TIME_SLOTS_SUCCESS: {
      if (action.data.timeSlots) {
        return {
          ...state,
          categoriesTimeSlots: new Map(state.categoriesTimeSlots).set(action.vehicleCategoryId, action.data.timeSlots)
        };
      } else {
        let newCategoriesTimeSlots = new Map(state.categoriesTimeSlots);
        newCategoriesTimeSlots.delete(action.vehicleCategoryId);
        return {
          ...state,
          categoriesTimeSlots: newCategoriesTimeSlots
        };
      }
    }

    case types.PRICING_SET_INITIAL_MEMBER_TYPE_ID:
      return {
        ...state,
        memberTypeId: action.memberTypeId
      };

    case types.PRICING_CATEGORY_WIDGET_OPENED_STATE_TOGGLE:
      if (state.openedVehicleCategoryWidgets.has(action.id)) {
        let newOpenedVehicleCategoryWidgets = new Set(state.openedVehicleCategoryWidgets);
        newOpenedVehicleCategoryWidgets.delete(action.id);

        return {
          ...state,
          openedVehicleCategoryWidgets: newOpenedVehicleCategoryWidgets
        };
      } else {
        return {
          ...state,
          openedVehicleCategoryWidgets: new Set().add(action.id)
        };
      }

    case types.PRICING_RESET_OPENED_CATEGORY_WIDGET:
      return {
        ...state,
        openedVehicleCategoryWidgets: new Set()
      };

    case types.PRICING_SET_CURRENT_INDEX:
      return {
        ...state,
        currentTabIndex: action.index,
        memberTypeId: action.memberTypeId
      };

    case types.PRICING_SAVE_ALL_TIMESLOTS:
      return {
        ...state,
        saveAllTimeSlots: action.bool
      };

    case types.PRICING_PREPARE_TIMESLOTS_TO_SEND:
      let timeSlotsArray = state.timeSlotsToSend;
      timeSlotsArray.push(action.timeSlot);

      return {
        ...state,
        timeSlotsToSend: timeSlotsArray
      };

    case types.PRICING_RESET_TIMESLOTS_TO_SEND: {
      return {
        ...state,
        timeSlotsToSend: []
      };
    }

    case types.PRICING_SET_TIMESLOTS_ERRORS:
      return {
        ...state,
        timeSlotsErrors: action.errors.reduce((obj, error) => {
          if (!obj[error.type]) {
            obj[error.type] = [];
          }
          obj[error.type].push(error.messageLabel);
          return obj;
        }, {})
      };

    case types.PRICING_CLEAR_TIMESLOTS_ERRORS:
      return {
        ...state,
        timeSlotsErrors: {}
      };

    case types.PRICING_RESET_CURRENT_COMPANY_BUSINESS_TIMESLOTS:
      return {
        ...state,
        currentCompanyBusinessTimeSlots: []
      };

    case types.PRICING_RESET_CATEGORIES_TRANSVERSAL_PRICES:
      return {
        ...state,
        categoriesTransversalPrices: new Map()
      };

    case types.PRICING_COPY_TIME_SLOTS_OF_MEMBER_TYPE_REQUEST:
      return {
        ...state
      };

    case types.PRICING_COPY_TIME_SLOTS_OF_MEMBER_TYPE_SUCCESS:
      return {
        ...state
      };

    case types.PRICING_GET_CATEGORY_CANCELLATION_FEES_REQUEST:
      return {
        ...state
      };

    case types.PRICING_GET_CATEGORY_CANCELLATION_FEES_ERROR:
      return {
        ...state,
        cancellationFees: new Map()
      };

    case types.PRICING_GET_CATEGORY_CANCELLATION_FEES_SUCCESS:
      if (_get(action, 'data', false) && action.data.rates.length === 0) {
        const context = {
          vehicleCategoryId: action.categoryId,
          companyId: action.companyId,
          memberTypeId: action.memberTypeId,
          freeFloating: false
        };
        action.data.context = context;
      }
      if (action.categoryId) {
        return {
          ...state,
          cancellationFees: new Map(state.cancellationFees).set(action.categoryId, action.data)
        };
      } else {
        let newCancellationFees = new Map(state.cancellationFees);
        newCancellationFees.delete(action.categoryId);
        return {
          ...state,
          cancellationFees: newCancellationFees
        };
      }

    case types.PRICING_EMPTY_CATEGORY_CANCELLATION_FEES:
      return {
        ...state,
        cancellationFees: new Map()
      };

    case UPDATE_LOCATION:
      return { ...initialState };

    default:
      return state;
  }
}
