import React, { Component } from 'react';
import { getAppObj } from '../../utils/utils';

class ButtonHoverMsg extends Component {
  setContainerRef(ref) {
    getAppObj().buttonHoverRef = ref;
  }

  render() {
    return (
      <div className="button-hover-msg" ref={this.setContainerRef}>
        <span>Use "ctrl (meta) + click" or "middle mouse button" to open in a new tab</span>
      </div>
    );
  }
}

export default ButtonHoverMsg;
