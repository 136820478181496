import React, { Component, PropTypes } from 'react';
import Dropzone from 'react-dropzone';
import classnames from 'classnames';
import RaisedButton from 'material-ui/RaisedButton';
import AddIcon from 'material-ui/svg-icons/content/add-circle-outline';
import Check from 'material-ui/svg-icons/navigation/check';
import RefreshIcon from 'material-ui/svg-icons/navigation/refresh';
import DeleteIcon from 'material-ui/svg-icons/action/delete';
import DetailIcon from 'material-ui/svg-icons/image/picture-as-pdf';
import PhotoIcon from 'material-ui/svg-icons/image/photo-library';
import OpenInNew from 'material-ui/svg-icons/action/open-in-new';
import Error from 'material-ui/svg-icons/alert/error-outline';
import { colorDark } from '../../constants/style-constants';
import LinearProgress from 'material-ui/LinearProgress';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { getLastItem, namedCompose, safe, getExtFromFileName, valueFirstInit, isValidId } from '../../utils/utils';
import FieldErrorMsg from '../FieldErrorMsg/FieldErrorMsg';
import { uploadFile, getPublicFileUrl } from '../../actions/all-actions';
import { fileUploadExtTypes, LOADING } from '../../constants/generic-constants';

class FileUpload extends Component {
  constructor(props) {
    super(props);
    this.bindFunctions();
    this.initState();
  }

  initState() {
    this.state = { fileContent: '', pendingImport: '', remoteFile: '', error: '' };
  }

  componentWillMount() {
    this.derivedStateFromProps(this.props, true);
  }

  bindFunctions() {
    this.dropZone = this.dropZone.bind(this);
    this.handleAddButton = this.handleAddButton.bind(this);
    this.handleRemoveButton = this.handleRemoveButton.bind(this);
    this.handleAddInputChange = this.handleAddInputChange.bind(this);
    this.handleOnDrop = this.handleOnDrop.bind(this);
    this.handleRetry = this.handleRetry.bind(this);
    this.pendingUpload = this.pendingUpload.bind(this);
    this.displayImportResume = this.displayImportResume.bind(this);
    this.handleSetAddInputRef = ref => (this.addRef = ref);
  }

  componentWillReceiveProps(props) {
    this.derivedStateFromProps(props);
  }

  derivedStateFromProps(props, init) {
    if (this.fieldValueUpdated(props, init)) {
      this.setExt(props);
      this.setInitialValue(props);
      this.setFileIdStatus(props);
    }
  }

  componentDidMount() {
    this.didUpdateCycle();
  }

  componentDidUpdate() {
    this.didUpdateCycle();
  }

  didUpdateCycle() {
    valueFirstInit.call(this, this.props.field);

    this.updatePendingStatus(this.props);
  }

  setExt(props) {
    const fileExt = getExtFromFileName(safe(() => props.field.value));
    if (fileUploadExtTypes[fileExt]) this.extType = fileExt;
    else this.extType = '';
  }

  setFileIdStatus(props) {
    this.isFileId = isValidId(safe(() => props.field.value));
  }

  setInitialValue(props) {
    const value = safe(() => props.field.value);

    if (!this.initialValue && value && !isValidId(value) && value !== LOADING) {
      this.initialValue = value;
    }
  }

  updatePendingStatus(props) {
    const value = safe(() => props.field.value);

    if (value === LOADING) {
      if (this.prevValue !== LOADING) {
        this.setState({ pendingImport: true });
        this.prevValue = value;
      }
    } else if (this.prevValue === LOADING) {
      this.setState({ pendingImport: false });
      this.prevValue = '';
    }
  }

  fieldValueUpdated(props, init) {
    const prevValue = safe(() => this.props.field.value);
    const nextValue = safe(() => props.field.value);
    return init || prevValue !== nextValue;
  }

  handleOnDrop(files = []) {
    const { field, disabled, dispatch, apiUrl } = this.props;

    if (!disabled) {
      const reader = new FileReader();
      const firstFile = getLastItem(files);

      reader.onload = function(upload) {};
      reader.readAsDataURL(firstFile);

      this.setState({ pendingImport: true });

      dispatch(uploadFile(firstFile, field, apiUrl, this.props.isPublicUpload)).then(
        data => {
          this.setState({
            fileContent: reader.result,
            remoteFile: data,
            pendingImport: ''
          });
          if (this.props.getFileCb) {
            this.props.dispatch(getPublicFileUrl(this.state.remoteFile.id)).then(data => {
              this.setState({ remoteFile: '' });
              this.props.field.onChange(data);
            });
          }
        },
        error => this.setState({ error, pendingImport: '' })
      );
    }
  }

  handleAddInputChange(e) {
    this.handleOnDrop([getLastItem(e.target.files)]);
    e.target.value = '';
  }

  handleAddButton(e) {
    e.stopPropagation();
    if (this.addRef) this.addRef.click();
  }

  handleRemoveButton(e) {
    e.stopPropagation();
    this.setState({ fileContent: '' });
    this.props.field.onChange('');
    this.props.allowDeletion(this.addRef);
  }

  pendingUpload() {
    const { pendingImport } = this.state;
    return pendingImport && <LinearProgress mode="indeterminate" style={{ margin: '55px auto 0px', width: '90%' }} />;
  }

  getFilename(url) {
    if (url) {
      let m = url.toString().match(/.*\/(.+?)\./);
      if (m && m.length > 1) {
        return m[1] + '.pdf';
      }
    }
  }

  getPdfFileUrl(remoteFile) {
    const pdfFile = this.extType === fileUploadExtTypes.pdf;
    const { pendingImport } = this.state;

    const {
      field: { value },
      disabled
    } = this.props;

    if (pdfFile && !remoteFile && !pendingImport) {
      const fileName = this.getFilename(value);

      return (
        <div className="document-link">
          <a target="_blank" href={value} title={fileName} onClick={e => e.stopPropagation()}>
            <DetailIcon className="pdf" />
            <OpenInNew className="new-window" />
            <span>{fileName}</span>
          </a>
        </div>
      );
    } else if (disabled) {
      return (
        <div className="no-url">
          <FormattedMessage id="common_no_data_available" />
        </div>
      );
    }
  }

  getTextInstruction() {
    const { pendingImport, remoteFile, error } = this.state;

    const {
      field: { value }
    } = this.props;

    const pendingUpload = this.pendingUpload();
    const imageFile = this.extType && this.extType !== fileUploadExtTypes.pdf;
    const importResume = this.displayImportResume();

    if (!pendingUpload && !remoteFile && !error) {
      if (
        (!importResume && imageFile && !pendingImport) ||
        (this.state.fileContent && imageFile) ||
        (!this.state.fileContent && !imageFile && !value)
      )
        return (
          <div className="instruction-text">
            <div style={{ marginBottom: '10px', fontSize: 'larger', display: 'inline-flex' }}>
              <PhotoIcon style={{ marginRight: '4px' }} />
              <FormattedMessage id="upload_file_drop_title" />
            </div>
            <div style={{ marginBottom: '10px', fontSize: 'smaller', ...colorDark }}>
              <FormattedMessage id="upload_file_drop_sub_title" />
            </div>
          </div>
        );
    }
  }

  getImage() {
    const {
      field: { value }
    } = this.props;

    const imgSrc = this.state.fileContent || value;
    if (!imgSrc || this.isFileId || imgSrc === LOADING || this.state.error) return;

    return (
      <div className="preview">
        <img src={imgSrc} alt="" />
      </div>
    );
  }

  dropZone({ getRootProps, isDragActive }) {
    const { remoteFile, error } = this.state;

    const {
      intl: { formatMessage },
      disabled,
      allowDeletion
    } = this.props;

    const imageFile = this.extType && this.extType !== fileUploadExtTypes.pdf;
    const btnKey = this.extType ? 'common_update' : 'upload_file_btn_title';
    const importResume = this.displayImportResume();
    const pendingUpload = this.pendingUpload();
    const onClickHandler = pendingUpload ? undefined : this.handleAddButton;

    return (
      <div
        {...getRootProps()}
        className={classnames('file-upload-dropzone', { 'file-upload-dropzone-active': isDragActive })}
        onClick={!disabled && onClickHandler}
      >
        {pendingUpload}

        <div
          className={classnames('file-upload-dropzone-inner', {
            'update-file': this.state.fileContent || imageFile,
            'create-file': !this.state.fileContent && !imageFile
          })}
        >
          {!importResume && this.getPdfFileUrl(remoteFile)}
          {!pendingUpload && this.getImage()}
          {!pendingUpload && importResume}

          {this.getTextInstruction()}
        </div>

        <input
          id={this.props.id}
          type="file"
          className="file-upload-add-input"
          onChange={this.handleAddInputChange}
          ref={this.handleSetAddInputRef}
        />

        {!pendingUpload && (
          <div className="actions">
            {!remoteFile && !error && !disabled && (
              <RaisedButton
                className={classnames('file-upload-button')}
                id={`file-upload-add-button_${this.props.id}`}
                label={formatMessage({ id: btnKey })}
                icon={<AddIcon />}
                labelStyle={{ textTransform: 'none' }}
                overlayStyle={{ alignItems: 'center' }}
                onClick={this.handleAddButton}
                primary
              />
            )}
            {this.extType && allowDeletion && (
              <RaisedButton
                className={classnames('file-upload-button')}
                id={`file-upload-remove-button_${this.props.id}`}
                label={formatMessage({ id: 'common_delete' })}
                icon={<DeleteIcon />}
                labelStyle={{ textTransform: 'none' }}
                style={{ marginLeft: '3px' }}
                overlayStyle={{ alignItems: 'center' }}
                onClick={this.handleRemoveButton}
                primary
              />
            )}
          </div>
        )}
      </div>
    );
  }

  handleRetry(e) {
    const { error } = this.state;
    const { field } = this.props;

    e.stopPropagation();

    if (error) this.setState({ error: '' });
    this.setState({ fileContent: '', remoteFile: '' });
    safe(() => field.onChange(this.initialValue));
  }

  displayImportResume() {
    const { remoteFile, error } = this.state;

    if (remoteFile) {
      const lineText = '(' + remoteFile.mimeType + ') ' + remoteFile.name;

      return (
        <div className="document-link">
          <div className="new-import">
            <Check style={{ fill: 'green' }} />
            &nbsp;
            <span className="one-line-wrap">
              <span title={lineText}>{lineText}</span>
            </span>
          </div>
          <div className="import-retry">
            <RaisedButton
              onClick={this.handleRetry}
              id={`import-retry-btn ${this.props.id}`}
              icon={<RefreshIcon />}
              label={<FormattedMessage id="common_back" />}
            />
          </div>
        </div>
      );
    }

    if (error) {
      return (
        <div>
          <div className="file-upload-box inner sb-error">
            <Error style={{ fill: 'red' }} />
            &nbsp;
            <FormattedMessage id="file_upload_imported_file_failed" values={{ error: error.name }} />
          </div>
          <div className="import-retry">
            <RaisedButton
              onClick={this.handleRetry}
              id="import-retry-btn"
              icon={<RefreshIcon />}
              label={<FormattedMessage id="booking_detail_retry_button" />}
            />
          </div>
        </div>
      );
    }
  }

  render() {
    const { mandatory, isMandatory, text, label, field, noPadding } = this.props;

    return (
      <div className={classnames('file-upload', { 'no-padding': noPadding })}>
        <div className="file-upload-box">
          <label>
            {text || <FormattedMessage id={label} />}
            {isMandatory && <span className="labelMandatory">*</span>}
            {mandatory && <FieldErrorMsg field={field} customClass="fieldErrorMsg--companyForm" />}
          </label>
          <Dropzone onDrop={this.handleOnDrop}>{this.dropZone}</Dropzone>
        </div>
      </div>
    );
  }
}

FileUpload.defaultProps = {
  isPublicUpload: true
};

FileUpload.propTypes = {
  apiUrl: PropTypes.string, // alt upload API url
  noPadding: PropTypes.bool, // remove button padding
  field: PropTypes.object, // 'url' or 'base64'
  label: PropTypes.string, // labelKey
  text: PropTypes.string, // label
  isMandatory: PropTypes.bool, // remove button padding,
  getFileCb: PropTypes.bool, // fetch file immediately after on success upload
  isPublicUpload: PropTypes.bool // upload file publicly
};

export default namedCompose(
  connect(state => {
    const {
      i18n: { locale }
    } = state;

    return { locale };
  }),
  injectIntl
)(FileUpload);
