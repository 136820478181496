import React, { Component, PropTypes as T } from 'react';
import { injectIntl, intlShape } from 'react-intl';

import { toggleLabelStyle } from '../../constants/style-constants';
import Toggle from 'material-ui/Toggle';
import { toBoolean, valueFirstInit } from '../../utils/utils';

class BoxedToggle extends Component {
  constructor(props) {
    super(props);
    this.handleToggle = this.handleToggle.bind(this);
  }

  componentDidMount() {
    this.didUpdateCycle();
  }

  componentDidUpdate(props) {
    this.didUpdateCycle();
  }

  didUpdateCycle() {
    valueFirstInit.call(this, this.props.field);
  }

  handleToggle(e, bool) {
    this.props.field.onChange(bool);
  }

  render() {
    const { field, intl, labelKey, className, disabled, label } = this.props;
    return (
      <Toggle
        className={className}
        label={intl.messages[labelKey] || label}
        labelStyle={this.props.labelStyle || toggleLabelStyle}
        toggled={toBoolean(field.value)}
        onToggle={this.handleToggle}
        disabled={!!disabled}
      />
    );
  }
}

BoxedToggle.displayName = 'BoxedToggle';

BoxedToggle.propTypes = {
  field: T.object.isRequired,
  labelKey: T.string,
  label: T.string,
  intl: intlShape.isRequired,
  disabled: T.bool
};

export default injectIntl(BoxedToggle);
