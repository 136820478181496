import { getStore } from '../store/all-store';
import _get from 'lodash/get';
import _pick from 'lodash/pick';
import _includes from 'lodash/includes';
import _forEach from 'lodash/forEach';
import _difference from 'lodash/difference';
import _orderBy from 'lodash/orderBy';
import _clone from 'lodash/clone';
import _cloneDeep from 'lodash/cloneDeep';
import _filter from 'lodash/filter';
import _flatten from 'lodash/flatten';
import _compact from 'lodash/compact';
import _find from 'lodash/find';
import _omit from 'lodash/omit';
import moment from 'moment';
import _findIndex from 'lodash/findIndex';
import { isEmpty, momentTimeFormat, createDateGetBookingCost, isValidId, dateToYMD, toBoolean, safe } from '../utils/utils';
import config from '../constants/config-constants';

import {
  SORT_PROPERTY_CREATION_DATE,
  TEMPLATE_MODEL_ITALIAN,
  TEMPLATE_MODEL_AUSTRIAN,
  TEMPLATE_MODEL_BELGIAN,
  ONE_WAY_TRIP,
  VOUCHER_MAXIMUM_USES,
  BOOKING_START_DATE_BETWEEN,
  BOOKING_CREATION_DATE_BETWEEN,
  BOOKING_START_PARKING_IN_LIST,
  BOOKING_SUB_COMPANY_IN_LIST,
  CUSTOM_FIELD_CONDITIONED,
  BOOKING_STATUS_PRE_BOOKED,
  MOBILE_ACTIONS,
  VOUCHER_MAXIMUM_USES_PER_PROFILE
} from '../constants/backend-constants';
import { formatDateLong, append, trySet, checkDate, i18nMobileKeys } from '../utils/utils';
import { apiParams } from '../constants/api-params-constants';
import { ISO_DATE_FLAT, SORT_ASC, SORT_DESC } from '../constants/generic-constants';
import _isEmpty from 'lodash/isEmpty';
import _map from 'lodash/map';
import { isSubCompanyLevelUserSelector, userSubCompaniesSelector } from '../selectors/all-selectors';
import { setFormattedAddress } from '../utils/dataFormatUtils';
import { cleanDeep } from '../utils/cleanDeep';
import querystring from 'querystring';

// TODO: rework pagination enchanters

export function enhancedSortingOptions(params) {
  const isDescending = _get(params, 'sortDirection') !== SORT_DESC;
  const property = _get(params, 'sortProperty', 'emissionDate');

  params.sort = {
    property,
    isDescending: !isDescending
  };

  return params;
}

export function enhanced2SortingOptions(params) {
  const sortDirection = !_get(params, 'sort.isDescending') ? SORT_DESC : SORT_ASC;
  const sortProperty = _get(params, 'sort.property', 'emissionDate');
  params = {
    sortDirection,
    sortProperty
  };
  return params;
}

// will mutate object
export function enhancePaginationInfos(paginatedObj) {
  paginatedObj.metadata.paginationInfo.currentSize = paginatedObj.results.length;
  return paginatedObj;
}

export function enhanceNewPaginationInfos(paginatedObj = {}) {
  const data = {
    metadata: {
      paginationInfo: {
        currentPage: _get(paginatedObj, 'number') || apiParams.default.page.number,
        pageSize: _get(paginatedObj, 'size') || apiParams.default.page.size,
        totalPages: _get(paginatedObj, 'totalPages'),
        totalResults: _get(paginatedObj, 'totalResults')
      }
    },
    results: _get(paginatedObj, 'results', [])
  };

  data.metadata.paginationInfo.currentSize = data.results.length;
  return data;
}

function changePageNumber(data, increase) {
  const number = _get(data, 'number');
  if (!isEmpty(number)) data.number = increase ? data.number + 1 : data.number - 1;
  return data;
}

export function increasePageNumber(data) {
  return changePageNumber(data, true);
}

export function decreasePageNumber(data) {
  return changePageNumber(data, false);
}

export function enhancePaginationInfosWithoutFacets(paginatedObj) {
  paginatedObj.pageInfo.currentSize = paginatedObj.results.length;
  return paginatedObj;
}

export function parseHeader(res, json, text) {
  const contentType = res.headers.get('content-type');
  if (contentType && contentType.indexOf('application/json') !== -1) {
    return res.json().then(data => json(data));
  } else {
    return res.text().then(data => {
      if (text) return text(data);
      else return json(data);
    });
  }
}

const checkResponse = (res, callback) => {
  const contentType = res.headers.get('content-type');
  const jsonType = _includes(contentType, 'application/json');

  if (jsonType) return res.json().then(callback);
  else return callback(res);
};

const parseError = (error, reject) => {
  if (reject) return Promise.reject(error);
  else throw error;
};

export const checkErrorAndConvert = (reject = false) => res => {
  return checkResponse(res, data => {
    if (res.ok) return data;
    else return parseError(data, reject);
  });
};

export function parseApiVoucherParams(params) {
  const page = _get(params, 'page');
  const sort = _get(params, 'sort');
  const sortProperty = _get(sort, 'property');

  const parsedParams = {
    page: {
      number: _get(page, 'number') || apiParams.default.page.number,
      size: _get(page, 'size') || apiParams.default.page.size
    },
    voucherGroupId: _get(params, 'voucherGroupId')
  };

  if (sortProperty) {
    parsedParams.sort = {
      property: sortProperty,
      direction: _get(sort, 'isDescending') ? SORT_DESC : SORT_ASC
    };
  }

  trySet(parsedParams, 'creationDate', checkDate(_get(params, 'creationDate'), ISO_DATE_FLAT));
  trySet(parsedParams, 'revoked', _get(params, 'revoked'));

  return parsedParams;
}

// TODO update when API is ready
export function parseApiPaybackParams(params) {
  const page = _get(params, 'page');
  const sort = _get(params, 'sort');
  const sortProperty = _get(sort, 'property');

  const parsedParams = {
    page: {
      number: _get(page, 'number') || apiParams.default.page.number,
      size: _get(page, 'size') || apiParams.default.page.size
    },
    voucherGroupId: _get(params, 'bankoutId') // TODO: replace with bankoutId
  };

  if (sortProperty) {
    parsedParams.sort = {
      property: sortProperty,
      direction: _get(sort, 'isDescending') ? SORT_DESC : SORT_ASC
    };
  }

  return parsedParams;
}

export function parseActionVoucherParams(params) {
  const sort = _get(params, 'sort');
  const pageNumber = _get(params, 'page.number');
  const pageSize = _get(params, 'page.size');

  return {
    page: {
      number: pageNumber,
      size: pageSize
    },
    creationDate: _get(params, 'creationDate'),
    revoked: _get(params, 'revoked'),
    voucherCode: _get(params, 'voucherCode'),
    voucherGroupId: _get(params, 'voucherGroupId'),
    sort: {
      isDescending: _get(sort, 'isDescending', true),
      property: _get(sort, 'property', SORT_PROPERTY_CREATION_DATE)
    }
  };
}

// noinspection DuplicatedCode
export function parseActionPaybackParams(params) {
  const page = _get(params, 'page');
  const pageNumber = _get(page, 'number');
  const pageSize = _get(page, 'size');
  const sort = _get(params, 'sort');
  const sortProperty = _get(sort, 'property');
  const sortDirection = _get(sort, 'isDescending');
  const { number, size } = apiParams.default.page;

  const validParams = _pick(params, ['bookingId', 'creationDatefrom', 'creationDateUntil']);

  let paramsObj = {
    number: pageNumber || number,
    size: pageSize || size
  };

  if (sortProperty) {
    paramsObj.sortProperty = sortProperty;
    paramsObj.sortDirection = sortDirection ? SORT_DESC : SORT_ASC;
  }

  return { ...paramsObj, ...append(validParams) };
}

const sortedDatesPredicate = (a = {}, b = {}) => {
  const firstDate = moment(a.startDate);
  const nextDate = moment(b.startDate);
  return firstDate.diff(nextDate);
};

const pastDatesPredicate = (a = {}) => {
  const momentDate = moment(a.startDate);
  const today = moment();
  return today.diff(momentDate) > 0;
};

export function getPaybackSettingsParse(data) {
  const { paybackConfig } = data || {};
  const { commissions } = paybackConfig || {};

  if (commissions) {
    let sortedArray = commissions.sort(sortedDatesPredicate);
    const pastDates = sortedArray.filter(pastDatesPredicate);

    const returnObject = { ...data, paybackConfig: { ...paybackConfig } };

    if (pastDates) {
      const currentRateObj = pastDates[pastDates.length - 1];
      const currentDateMoment = moment(currentRateObj.startDate);

      returnObject.paybackConfig.commissionRate = currentRateObj;

      sortedArray = sortedArray.filter((dateObj = {}) => {
        const momentDate = moment(dateObj.startDate);
        return !(momentDate.diff(currentDateMoment) < 0);
      });
    }

    returnObject.paybackConfig.commissions = sortedArray;

    return returnObject;
  }

  return data;
}

export function parseVoucherDetailsRes(res) {
  const parkings = _get(res, 'parkingNames');
  const _rules = _get(res, 'rules');
  const rules = _rules.map(item => {
    item.rule === BOOKING_START_PARKING_IN_LIST ? (item = { rule: item.rule, parameters: parkings }) : item;
    return item;
  });

  return {
    ...res,
    rules
  };
}

export function enhancePaginationInfosMore(paginatedObj) {
  paginatedObj.paginationInfo.currentSize = _get(paginatedObj, 'results.length', 0);
  return paginatedObj;
}

// this is a conversion to handle transition between old and new API
// plus usage of old 'enhanceUserInfo'
// will mutate object
// remove the enhancer when we confirm old API won't be used
// keep 'enhanceUserInfo' during removal ^
export function enhancePostMembersSearchFlatResult(data, toEnhanceUserInfo = true) {
  data.results.forEach(item => {
    item = item || {};
    if (safe(() => item.memberType.name)) {
      item.memberType = item.memberType.name;
    }
    if (safe(() => item.company.name)) {
      item.superCompanyName = item.company.name;
    }
    if (safe(() => item.company.brand.name)) {
      item.brandName = item.company.brand.name;
    }
    if (safe(() => item.company.id)) {
      item.superCompanyId = item.company.id;
    }
    if (safe(() => item.memberType.id)) {
      item.memberTypeId = item.memberType.id;
    }

    if (toEnhanceUserInfo) {
      enhanceUserInfo(item);
    }
  });
}

export function enhanceCollectionOfUserInfos(list) {
  return list.map(item => enhanceUserInfo(item));
}

export function enhanceContract(data) {
  data._contractStartDate = new Date(data.contractStart);
  data._contractEndDate = new Date(data.contractEnd);
  return data;
}

export function filterBankoutList(data = {}) {
  const state = getStore().getState();
  const subCompanyLevelUser = isSubCompanyLevelUserSelector(state);
  const { results = [] } = data;

  if (subCompanyLevelUser && results.length) {
    const userSubCompanies = userSubCompaniesSelector(state);
    const idBelongsToUser = (stateUser = {}, apiResult = {}) => stateUser.id === apiResult.billingEntityId;
    const getOnlyAssignedCompanies = apiResult => _findIndex(userSubCompanies, stateUser => idBelongsToUser(stateUser, apiResult)) !== -1;

    data.results = results.filter(getOnlyAssignedCompanies);
  }

  return data;
}

export function enhanceParkings(data) {
  const oneTimeSlots = _get(data, 'schedule.oneTimeSlots');

  _forEach(oneTimeSlots, slot => {
    const endDate = _get(slot, 'endDate');
    const momentDate = moment(endDate);

    if (momentDate.isValid()) {
      const newDate = momentDate.subtract(1, 'days');
      slot.endDate = newDate.format('YYYY-MM-DD');
    }
  });

  return data;
}

// will mutate object
export function enhanceUserInfo(userInfo) {
  // format sites for AddressAutocomplete
  if (userInfo.company && userInfo.company.sites) {
    userInfo.company.sites.forEach(item => {
      item.label = item.name;
      item._isSite = true;
      // className will be used by GeoSuggest
      item.className = 'addressAutocomplete_suggestion';
      // GeoSuggest doesn't consider we select any suggestion if it hasn't a location
      if (!item.location) {
        item.location = {
          lat: null,
          lng: null
        };
      }
    });
  }

  // provide formattedAddress if not present
  if (userInfo.address && !userInfo.address.formattedAddress) {
    userInfo.address.formattedAddress = formatAddress(userInfo.address);
  }

  return userInfo;
}

export function enhanceBooking(booking) {
  let costs = [];

  if (booking.carSharingInfo && booking.carSharingInfo.cost) {
    if (booking.carSharingInfo.cost.estimatedPriceForDuration) {
      costs = booking.carSharingInfo.cost.estimatedPriceForDuration.toFixed(2).split('.');
    } else if (booking.carSharingInfo.cost.totalPrice) {
      costs = booking.carSharingInfo.cost.totalPrice.toFixed(2).split('.');
    }
    booking.carSharingInfo.cost.integer = costs[0];
    booking.carSharingInfo.cost.decimals = costs[1];
  }

  if (booking.start.address && !booking.start.address.formattedAddress) {
    booking.start.address.formattedAddress = formatAddress(booking.start.address);
  }

  if (booking.end.address && !booking.end.address.formattedAddress) {
    booking.end.address.formattedAddress = formatAddress(booking.end.address);
  }

  return booking;
}

export function enhanceBookingVehicleSearchRequestParams(searchParams) {
  const {
    from,
    to,
    passengers,
    pickupDate,
    pickupDateHour,
    pickupDateMin,
    returnDate,
    returnDateHour,
    returnDateMin,
    oneWayTrip,
    originalBookingId,
    preBooking,
    startParkingId,
    carOptions,
    categories,
    fuelTypes,
    usages,
    gearbox,
    memberTypeId,
    memberProfileId
  } = searchParams;

  const roundTrip = oneWayTrip === false;
  let params = { passengers, memberProfileId };

  trySet(
    params,
    'start.address',
    append({
      formattedAddress: _get(from, 'formattedAddress'),
      coordinates: _get(from, 'coordinates')
    })
  );

  trySet(params, 'start.siteId', _get(from, 'id'));

  trySet(
    params,
    'end.address',
    append({
      formattedAddress: roundTrip ? _get(params, 'start.address.formattedAddress') : _get(to, 'formattedAddress'),
      coordinates: roundTrip ? _get(params, 'start.address.coordinates') : _get(to, 'coordinates')
    })
  );

  trySet(params, 'end.siteId', roundTrip ? _get(params, 'start.siteId') : _get(to, 'id'));

  let startDate = new Date(pickupDate);

  startDate.setHours(pickupDateHour || 0);
  startDate.setMinutes(pickupDateMin || 0);

  trySet(params, 'start.date', formatDateLong(startDate));

  let endDate = new Date(returnDate);

  if (returnDate && roundTrip) {
    endDate.setHours(returnDateHour || 0);
    endDate.setMinutes(returnDateMin || 0);
    trySet(params, 'end.date', formatDateLong(endDate));
  }

  if (originalBookingId) {
    params.originalBookingId = originalBookingId;
  }

  if (preBooking) {
    params.preBooking = preBooking;
  }

  if (memberTypeId) {
    params.memberTypeId = memberTypeId;
  }

  if (carOptions && carOptions.length > 0) {
    params.accessories = carOptions;
  }

  if (categories && categories.length > 0) {
    params.categories = categories;
  }

  if (fuelTypes && fuelTypes.length > 0) {
    params.fuelTypes = fuelTypes;
  }

  if (usages && usages.length > 0) {
    params.usageTypes = usages;
  }

  if (startParkingId) {
    params.startParkingId = startParkingId;
  }

  if (gearbox) {
    params.transmissionTypes = [];
    params.transmissionTypes.push(gearbox);
  }

  return params;
}

export function enhanceBookingVehicleSearchRequestResults(results) {
  const allCompanies = getStore().getState().companies.headerList;
  const newResults = Object.assign({}, results);

  newResults.results.forEach(booking => {
    booking.vehicle.company = allCompanies.filter(company => company.id === booking.vehicle.company.id)[0];
  });

  return newResults;
}

export function enhanceBookingCreationRequestParams(memberDetails, bookingDetails, confirmDetails, bookingToReplaceId) {
  let params = {
    type: bookingDetails.type,
    start: {
      date: bookingDetails.start.date
    },
    end: {
      date: bookingDetails.end.date
    },
    reservedSeats: bookingDetails.reservedSeats
  };

  if (bookingToReplaceId) {
    params.id = bookingToReplaceId;
  }

  if (bookingDetails.vehicle) {
    params.vehicle = {
      id: bookingDetails.vehicle.id
    };
  }

  if (bookingDetails.start.parking) {
    params.start.parking = {
      id: bookingDetails.start.parking.id
    };
  }

  if (bookingDetails.end.parking) {
    params.end.parking = {
      id: bookingDetails.end.parking.id
    };
  }

  if (bookingDetails.start.address) {
    params.start.address = bookingDetails.start.address;
  }

  if (bookingDetails.start.coordinates) {
    params.start.coordinates = bookingDetails.start.coordinates;
  }

  if (bookingDetails.end.address) {
    params.end.address = bookingDetails.end.address;
  }

  if (bookingDetails.end.coordinates) {
    params.end.coordinates = bookingDetails.end.coordinates;
  }

  if (confirmDetails && confirmDetails.comment) {
    params.comment = confirmDetails.comment;
  }
  if (confirmDetails && confirmDetails.newStartLocation) {
    params.start.parking.id = confirmDetails.newStartLocation;
    params.end.parking.id = confirmDetails.newStartLocation;
  }

  if (bookingDetails.status === BOOKING_STATUS_PRE_BOOKED) {
    params.preBooking = bookingDetails.status === BOOKING_STATUS_PRE_BOOKED;
  }

  if (bookingDetails.carSharingInfo) {
    params.replacementVehicle = bookingDetails.carSharingInfo.replacementVehicle;

    params.carSharingInfo = {
      usageType: bookingDetails.carSharingInfo.usageType,
      freeOfCharges: !toBoolean(confirmDetails.paidBooking)
    };

    if (_get(bookingDetails, 'carSharingInfo.cost.voucherCode')) {
      params.carSharingInfo.cost = {
        voucherCode: bookingDetails.carSharingInfo.cost.voucherCode
      };
    }
  }

  if (bookingDetails.shuttleInfo) {
    params.shuttleInfo = bookingDetails.shuttleInfo;
  }

  if (bookingDetails.memberTypeId || bookingDetails.memberType) {
    params.memberTypeId = bookingDetails.memberTypeId || bookingDetails.memberType.id;
  }

  params.memberProfileId = memberDetails.memberProfileId || memberDetails.id;

  return params;
}

export function enhanceBookingPricesRequestParams(
  memberProfileId,
  bookingDetails,
  firstHourSelected,
  secondHourSelected,
  firstDaySelected,
  secondDaySelected
) {
  const pickupSiteId = firstHourSelected.siteId || firstDaySelected.siteId || bookingDetails.start.parking.siteId;
  const returnSiteId = secondHourSelected.siteId || secondDaySelected.siteId || bookingDetails.end.parking.siteId;
  const pickDate = bookingDetails.pickupDate || bookingDetails.start.date;
  const pickHour = bookingDetails.pickupDateHour || moment(bookingDetails.start.date).hours();
  const pickMinute = bookingDetails.pickupDateMin || moment(bookingDetails.start.date).minutes();
  const returnDate = bookingDetails.returnDate || bookingDetails.end.date;
  const returnHour = bookingDetails.returnDateHour || moment(bookingDetails.end.date).hours();
  const returnMinute = bookingDetails.returnDateMin || moment(bookingDetails.end.date).minutes();
  const code = _get(bookingDetails, 'voucherCode', '');
  const voucherCode = !code ? null : code;
  const memberTypeId = _get(bookingDetails, 'memberTypeId');

  let params = {
    usageTypes: [bookingDetails.usageType || bookingDetails.carSharingInfo.usageType],
    start: {
      date: createDateGetBookingCost(pickDate, pickHour, pickMinute),
      siteId: pickupSiteId
    },
    end: {
      date: createDateGetBookingCost(returnDate, returnHour, returnMinute),
      siteId: returnSiteId
    }
  };
  if (!!voucherCode) params.voucherCode = voucherCode;
  if (memberTypeId) params.memberTypeId = memberTypeId;
  bookingDetails.id ? (params.originalBookingId = bookingDetails.id) : '';
  params.passengers = 1;

  params.memberProfileId = memberProfileId;

  return params;
}

export function enhanceBookingPriceRequestParams(bookingDetails) {
  const bookingStart = _get(bookingDetails, 'start.date');
  const bookingEnd = _get(bookingDetails, 'end.date');
  const usageTypes = [_get(bookingDetails, 'usageType.value') || _get(bookingDetails, 'carSharingInfo.usageType')];
  const pickupSiteId = _get(bookingDetails, 'start.parking.siteId') || _get(bookingDetails, 'siteId');
  const returnSiteId = _get(bookingDetails, 'end.parking.siteId') || _get(bookingDetails, 'siteId');
  const pickDate = _get(bookingDetails, 'pickupDate.value') || _get(bookingDetails, 'start.date');
  const pickHour = _get(bookingDetails, 'pickupDateHour.value') || bookingDetails.pickupDateHour || moment(bookingStart).hours();
  const pickMinute = _get(bookingDetails, 'pickupDateMin.value') || bookingDetails.pickupDateMin || moment(bookingStart).minutes();
  const returnDate = _get(bookingDetails, 'returnDate.value') || _get(bookingDetails, 'end.date');
  const returnHour = _get(bookingDetails, 'returnDateHour.value') || bookingDetails.returnDateHour || moment(bookingEnd).hours();
  const returnMinute = _get(bookingDetails, 'returnDateMin.value') || bookingDetails.returnDateMin || moment(bookingEnd).minutes();
  const memberTypeId = _get(bookingDetails, 'memberTypeId');
  const profileId = ((bookingDetails || {}).member || {}).id;

  const params = {
    usageTypes,
    start: {
      date: createDateGetBookingCost(pickDate, pickHour, pickMinute),
      siteId: pickupSiteId
    },
    end: {
      date: createDateGetBookingCost(returnDate, returnHour, returnMinute),
      siteId: returnSiteId
    },
    memberTypeId
  };

  if (_get(bookingDetails, 'voucherCode.value', '') !== null) {
    params.voucherCode = _get(bookingDetails, 'voucherCode.value');
  }

  if (bookingDetails.id) params.originalBookingId = bookingDetails.id;

  params.passengers = 1;
  params.memberProfileId = profileId || bookingDetails.profileIdForCreateBooking;

  return params;
}

export function formatAddress(address) {
  return address.streetNumber + ' ' + address.streetName + ', ' + address.postalCode + ' ' + address.city + ', ' + address.country;
}

export function enhanceSearchVehicleData(values, bookingDetail, selectedMember, editionMode) {
  let newParams = { ...values };

  newParams.oneWayTrip = values.tripType === ONE_WAY_TRIP;
  newParams.memberProfileId = selectedMember.id;

  if (editionMode) {
    newParams.originalBookingId = bookingDetail.id;
  }

  delete newParams.tripType;

  // Avoid IE11 to crash because of too long URL
  // Patch-only, could be removed when IE11 support will be dropped
  // Removes unnecessary data from returned object

  if (newParams.from && newParams.from._isSite) {
    newParams.from = _pick(newParams.from, ['id', '_isSite']);
  }

  if (newParams.to && newParams.to._isSite) {
    newParams.to = _pick(newParams.to, ['id', '_isSite']);
  }

  newParams.passengers = _get(values, 'passengers', 1);

  return newParams;
}

export function deduceClosedStreams(schedule, startDate) {
  const _parkingOpenDays = [];
  const recurringSlots = _get(schedule, 'recurringSlots', false);
  const oneTimeSlots = _get(schedule, 'oneTimeSlots', false);
  const aDay = [];

  const streams = (sd, ed) => {
    for (let i = 0; i < 7; i++) {
      const isBetween = moment(startDate)
        .add(i, 'days')
        .isBetween(sd, ed, null, []);
      aDay[i] = {
        date: moment(startDate).add(i, 'days'),
        isBetween,
        calendarDay: moment(startDate)
          .add(i, 'days')
          .locale('en')
          .format('dddd')
          .toUpperCase(),
        fullCalendarDay: moment(startDate)
          .add(i, 'days')
          .format()
      };
    }
    return aDay;
  };

  oneTimeSlots.map((item, index) => {
    streams(moment(item.startDate), moment(item.endDate).subtract(1, 'days'));

    const isBetweenOpens = aDay.some(day => day.isBetween) && item.open;
    if (isBetweenOpens) {
      let recurringOTopens = {
        id: 'specialOpen-' + index + '-' + item.id,
        open: true,
        days: [],
        timeIntervals: item.timeIntervals
      };
      const daysSpecialHour = aDay.map(weekDay => (weekDay.isBetween ? weekDay.calendarDay : ''));
      _filter(recurringSlots, slot => {
        if (slot.open && _find(slot.days, day => _includes(_compact(daysSpecialHour), day))) {
          slot.days = _difference(slot.days, _compact(daysSpecialHour));
          recurringOTopens.prevTime = slot.timeIntervals;
          return slot;
        }
      });
      recurringOTopens.days = _compact(daysSpecialHour);
      recurringSlots.push(recurringOTopens);
      return item;
    }
    const isBetweenClose = aDay.some(day => day.isBetween) && !item.open;
    if (isBetweenClose) {
      let recurringClose = {
        ...recurringClose,
        id: 'specialClose-' + index + '-' + item.id,
        open: false,
        days: [],
        timeIntervals: [],
        closedHours: [{ start: '00:00', end: '23:59' }]
      };
      const days = aDay.map(weekDay => {
        if (weekDay.isBetween) {
          recurringClose.days.push(weekDay.calendarDay);
          return weekDay.calendarDay;
        }
      });
      _filter(recurringSlots, slot => {
        if (slot.open && _find(slot.days, day => _includes(_compact(days), day))) {
          slot.days = _difference(slot.days, _compact(days));
          return slot;
        }
      });
      recurringSlots.push(recurringClose);
      return item;
    }
    return item;
  });

  recurringSlots.map(item => {
    let streamsClosed = [];
    if (_get(item, 'timeIntervals').length > 0) {
      _orderBy(item.timeIntervals, ['start'], ['asc']).map((item, idx, timeIntervals) => {
        const startH = item.start.split(':')[0];
        const startM = item.start.split(':')[1];
        const endH = item.end.split(':')[0];
        const endM = item.end.split(':')[1];
        if (idx === 0) {
          streamsClosed.push({
            start: momentTimeFormat(0, 0),
            end: momentTimeFormat(startH, startM)
          });

          if (timeIntervals[idx + 1]) {
            const nextStartH = timeIntervals[idx + 1].start.split(':')[0];
            const nextStartM = timeIntervals[idx + 1].start.split(':')[1];
            streamsClosed.push({
              start: momentTimeFormat(endH, endM),
              end: momentTimeFormat(nextStartH, nextStartM)
            });
          } else {
            streamsClosed.push({
              start: momentTimeFormat(endH, endM),
              end: moment()
                .endOf('day')
                .format('HH:mm')
            });
          }
        } else {
          if (timeIntervals[idx + 1]) {
            const nextStartH = timeIntervals[idx + 1].start.split(':')[0];
            const nextStartM = timeIntervals[idx + 1].start.split(':')[1];
            streamsClosed.push({
              start: momentTimeFormat(endH, endM),
              end: momentTimeFormat(nextStartH, nextStartM)
            });
          } else {
            streamsClosed.push({
              start: momentTimeFormat(endH, endM),
              end: moment()
                .endOf('day')
                .format('HH:mm')
            });
          }
        }
        return streamsClosed;
      });
    } else {
      const streamFull = { start: '00:00', end: '23:59' };
      if (item.open && item.timeIntervals.length === 0) {
        item.timeIntervals.push(streamFull);
      } else {
        streamsClosed = [streamFull];
        item.timeIntervals = [];
      }
      return streamsClosed;
    }
    _parkingOpenDays.push(_compact(item.days));
    if (item.timeIntervals.length > 0) item.closedHours = streamsClosed;

    return item;
  });

  return _parkingOpenDays;
}

export function enhancedCloseHoursData(data, startDate) {
  const immutableParkingsData = _cloneDeep(data.sites);
  const enhancedSites = data.sites.map(site => {
    site.parkings.map(parking => {
      const schedule = _get(parking, 'schedule', false);
      if (!schedule) {
        return parking;
      }
      const slots = _get(schedule, 'recurringSlots', false);

      if (slots) {
        deduceClosedStreams(schedule, startDate);
        let cloneWeek = _clone(config.daysOfWeek);
        const weekDays = _flatten(_get(schedule, 'recurringSlots').map(item => item.days));
        let fullCloseDays = _compact(cloneWeek.map(day => (!_includes(weekDays, day.value) ? day : '')));
        if (fullCloseDays.length) {
          fullCloseDays.map(day => {
            if (!_includes(weekDays, day.value) && _filter(slots, { open: false, days: [day] })) {
              parking.schedule.recurringSlots.push({
                id:
                  day.value +
                  Math.random()
                    .toString()
                    .split('.')[1]
                    .substring(0, 8),
                open: false,
                days: [day.value],
                closedHours: [{ start: '00:00', end: '23:59' }]
              });
            }
          });
        }
      }
      return parking;
    });
    return site;
  });

  return {
    sites: enhancedSites,
    company: data.company,
    subCompany: data.subCompany,
    immutableParkings: immutableParkingsData
  };
}

export function invoicingParamsEncode(params) {
  let data = params;
  const templateModel = _get(data, 'templateModel');

  if (templateModel !== TEMPLATE_MODEL_ITALIAN) {
    data = _omit(data, 'italianRea');
  }

  if (templateModel !== TEMPLATE_MODEL_AUSTRIAN) {
    data = _omit(data, ['companyCourt', 'companyRegisterNumber', 'dataProcessingRegistry']);
  }

  if (templateModel === TEMPLATE_MODEL_AUSTRIAN) {
    data = _omit(data, ['companyCapital', 'legalForm']);
  }

  if (templateModel !== TEMPLATE_MODEL_BELGIAN) {
    data = _omit(data, ['paymentConditionsLabel']);
  }

  if (templateModel === TEMPLATE_MODEL_BELGIAN) {
    data = _omit(data, ['companyCapital', 'fiscalNumber']);
  }

  data = _omit(data, ['postalCode', 'city', 'country']);

  return data;
}

export function enhanceVoucherCreateData(data) {
  const rules = [];

  const allowedParkingsData = _get(data, 'allowedParkingsData');
  const allowedCompaniesData = _get(data, 'allowedCompaniesData');
  const parkingsBool = _get(data, 'parkingsBool');
  const companiesBool = _get(data, 'companiesBool');

  if (data.utilisationNumber) {
    rules.push({
      parameters: [data.utilisationNumber],
      rule: VOUCHER_MAXIMUM_USES
    });
  }
  if (data.utilisationNumberPerProfile) {
    rules.push({
      parameters: [data.utilisationNumberPerProfile],
      rule: VOUCHER_MAXIMUM_USES_PER_PROFILE
    });
  }
  if (data.startDate || data.endDate) {
    rules.push({
      parameters: [_get(data, 'startDate', null), _get(data, 'endDate', null)],
      rule: BOOKING_START_DATE_BETWEEN
    });
  }
  if (data.creationStartDate || data.creationEndDate) {
    rules.push({
      parameters: [_get(data, 'creationStartDate', null), _get(data, 'creationEndDate', null)],
      rule: BOOKING_CREATION_DATE_BETWEEN
    });
  }
  if (parkingsBool && !_isEmpty(allowedParkingsData)) {
    rules.push({
      parameters: _map(allowedParkingsData, (data, parkingId) => parkingId),
      rule: BOOKING_START_PARKING_IN_LIST
    });
  }
  if (companiesBool && !_isEmpty(allowedCompaniesData)) {
    rules.push({
      parameters: _map(allowedCompaniesData, (data, companyId) => companyId),
      rule: BOOKING_SUB_COMPANY_IN_LIST
    });
  }

  return {
    ..._pick(data, ['discount', 'maxAmount', 'name', 'prefix', 'superCompanyId', 'type']),
    rules
  };
}

export function conditionedCustomFields(data) {
  let toReturn = data;

  const dependsOnPosition = _get(toReturn, 'mandatoryConditionDependsOnPosition', '');
  const expectedValueForPosition = _get(toReturn, 'mandatoryConditionExpectedValueForPosition', '');
  if (toReturn.mandatory === CUSTOM_FIELD_CONDITIONED) {
    if (dependsOnPosition !== '' && expectedValueForPosition !== '') {
      toReturn.mandatoryCondition = {
        dependsOnPosition,
        expectedValueForPosition
      };
    }
  } else {
    delete toReturn.mandatoryCondition;
    delete toReturn.mandatoryConditionDependsOnPosition;
    delete toReturn.mandatoryConditionExpectedValueForPosition;
  }

  const dependsOnPositionV = _get(toReturn, 'visibleConditionDependsOnPosition', '');
  const expectedValueForPositionV = _get(toReturn, 'visibleConditionExpectedValueForPosition', '');
  if (toReturn.visible === CUSTOM_FIELD_CONDITIONED) {
    if (dependsOnPositionV !== '' && expectedValueForPositionV !== '') {
      toReturn.visibleCondition = {
        dependsOnPosition: dependsOnPositionV,
        expectedValueForPosition: expectedValueForPositionV
      };
    }
  } else {
    delete toReturn.visibleCondition;
    delete toReturn.visibleConditionDependsOnPosition;
    delete toReturn.visibleConditionExpectedValueForPosition;
  }

  return toReturn;
}

export function enhanceAddMemberhRequestParams(memberParams) {
  const {
    login,
    civility,
    firstName,
    lastName,
    phoneNumber,
    locale,
    birthDate,
    address,
    postalCode,
    city,
    companyId,
    subCompanyId,
    country,
    licenceNumber,
    deliveranceDate,
    cityDeliverance,
    expirationDate,
    hasExpirationDate,
    managerEmail,
    italian,
    fiscalCode,
    ...customfields
  } = memberParams;

  let profileCustomValues = [];

  for (const key in customfields) {
    if (isValidId(key) && !isEmpty(customfields[key])) {
      profileCustomValues.push({
        companyCustomFieldId: key,
        value: customfields[key]
      });
    }
  }

  const memberRet = {
    firstName,
    lastName,
    companyId,
    locale,
    login,
    phoneNumber
  };

  trySet(memberRet, 'subCompanyId', subCompanyId);
  trySet(memberRet, 'italianInvoicing.italian', italian);
  trySet(memberRet, 'italianInvoicing.fiscalCode', fiscalCode);

  trySet(memberRet, 'drivingLicence.licenceNumber', licenceNumber);
  trySet(memberRet, 'drivingLicence.cityDeliverance', cityDeliverance);
  trySet(memberRet, 'drivingLicence.deliveranceDate', dateToYMD(deliveranceDate));
  trySet(memberRet, 'drivingLicence.expirationDate', dateToYMD(expirationDate));

  trySet(memberRet, 'birthDate', dateToYMD(birthDate));
  trySet(memberRet, 'managerEmail', managerEmail);
  trySet(memberRet, 'civility', civility);
  trySet(memberRet, 'profileCustomValues', append(profileCustomValues));

  if (address) memberRet.address = { streetName: address.streetName };

  trySet(memberRet, 'address.postalCode', postalCode);
  trySet(memberRet, 'address.city', city);
  trySet(memberRet, 'address.country', country);
  trySet(memberRet, 'address', append(setFormattedAddress(memberRet.address)));

  return cleanDeep(memberRet);
}

export function enhanceHomepageSettingsPayload(service) {
  let retData = { ...service };
  const isAction = retData.hasOwnProperty('action') && retData.action.hasOwnProperty('nameAlias') && !retData.hasOwnProperty('name');

  if (isAction) {
    if (service.action.title && service.action.title.length) {
      let title = i18nMobileKeys(service.action.title);
      retData.action = { title };
    }

    retData.action.type = service.action.type;
    retData.action.nameAlias = service.action.nameAlias;

    if (retData.action.type === MOBILE_ACTIONS.DEEPLINK) {
      retData.action.filters = service.action.filters;
      let urlParams = _omit(retData.action.filters, ['address']);

      if (
        retData.action.filters.address &&
        retData.action.filters.address.coordinates.latitude &&
        retData.action.filters.address.coordinates.longitude
      ) {
        urlParams = {
          ...urlParams,
          lat: retData.action.filters.address.coordinates.latitude,
          lng: retData.action.filters.address.coordinates.longitude
        };
      }
      retData.action.deeplinkurl = querystring.stringify(urlParams);
    }
  } else {
    if (service.serviceNames && service.serviceNames.length) {
      let title = i18nMobileKeys(service.serviceNames);
      retData = { title };
    }
    if (service.descriptions && service.descriptions.length) {
      let description = i18nMobileKeys(service.descriptions);
      retData.description = { ...description };
    }

    retData.imageUrl = service.imageUrl;
    retData.name = service.name;
    retData.id = service.id;
    retData.action = { ...service.action };
    if (service.action.title && service.action.title.length) {
      let title = i18nMobileKeys(service.action.title);
      retData.action = { ...retData.action, title };
    }
    retData.options = { size: service.options.size };
  }
  return retData;
}
