import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import Modal from 'react-modal';
import { editStatus, openVehicleDamageEditStatusModal, closeVehicleDamageEditStatusModal } from '../../actions/vehicleDamages-actions';
import { getAppFormattedDateTime } from '../../utils/utils';
import { removeImage } from '../../actions/all-actions';
import TableView from '../../components/TableView/TableView';
import VehicleDamageEditStatusForm from '../../components/VehicleDamageEditStatusForm/VehicleDamageEditStatusForm';
import routes, { vehicleDamagesEditRules } from '../../constants/routes-constants';
import EkButton from '../../components/EkButton/EkButton';
import BackLink from '../../components/BackLink/BackLink';
import { vehicleDamageDetailTableViewParams } from '../../constants/table-constants';
import { VehicleDamageEditStatusModalStyles } from '../../constants/style-constants';
import { VEHICLE_DAMAGE_DETAIL_IMAGE_REMOVE } from '../../constants/actionTypes-constants';
import { apiParams } from '../../constants/api-params-constants';
import FileImage from '../../components/FileImage/FileImage';
import { userRoleSelector } from '../../selectors/all-selectors';
import { BACKUSER_ROLE_CALL_CENTER_OPERATOR } from '../../constants/backend-constants';
import { checkRole } from '../../constants/backuser-role-rules';

class VehicleDamageDetail extends Component {
  constructor(props) {
    super(props);
    this.bindFunctions();
    this.setReadOnly(props);
  }

  setReadOnly(props) {
    const { role } = props;
    this.readOnly = role === BACKUSER_ROLE_CALL_CENTER_OPERATOR || !checkRole(vehicleDamagesEditRules, role);
  }

  bindFunctions() {
    this.handleEditStatus = this.handleEditStatus.bind(this);
    this.handleConfirmEditStatus = this.handleConfirmEditStatus.bind(this);
    this.handleAbortEditStatus = this.handleAbortEditStatus.bind(this);
  }

  componentWillUnmount() {
    this.props.dispatch(removeImage(VEHICLE_DAMAGE_DETAIL_IMAGE_REMOVE));
  }

  handleEditStatus() {
    const { dispatch } = this.props;
    dispatch(openVehicleDamageEditStatusModal());
  }

  handleConfirmEditStatus(formData) {
    const { dispatch, vehicleDamageDetail } = this.props;

    dispatch(closeVehicleDamageEditStatusModal());
    dispatch(editStatus(vehicleDamageDetail, formData.status));
  }

  handleAbortEditStatus() {
    const { dispatch } = this.props;
    dispatch(closeVehicleDamageEditStatusModal());
  }

  render() {
    const { vehicleDamageDetail, vehicleDamageImage, openedVehicleDamageEditStatusModal, urlParams } = this.props;

    let cancelUrl =
      '/#' + routes.vehicleDamages.path.replace(':search', encodeURIComponent(JSON.stringify(urlParams || apiParams.default)));

    return (
      <div className="vehicleDamageDetailPage mainContainer_content">
        <div className="pageContainer">
          <div className="vehicleDamageDetailPage_content">
            <BackLink link={cancelUrl} labelKey="back_link_damages_list" />
            <div className="vehicleDamageDetailPage_head">
              {!this.readOnly && (
                <EkButton customClass="vehicleDamageDetailPage_actionButton" skinType="variant3" onAction={this.handleEditStatus}>
                  <FormattedMessage id="vehicle_damage_detail_edit_status_button" />
                </EkButton>
              )}
            </div>
            {openedVehicleDamageEditStatusModal && (
              <Modal isOpen={openedVehicleDamageEditStatusModal !== null} style={VehicleDamageEditStatusModalStyles}>
                <VehicleDamageEditStatusForm onCallback={this.handleConfirmEditStatus} onAbort={this.handleAbortEditStatus} />
              </Modal>
            )}
            <section className="vehicleDamageDetail">
              <h4 className="vehicleDamageDetail_title">
                <FormattedMessage id="vehicle_damage_detail_title_information" />
              </h4>
              <div className="vehicleDamageDetail_line">
                <div className="vehicleDamageDetail_item">
                  <span className="vehicleDamageDetail_item_label">
                    <FormattedMessage id="vehicle_damage_detail_vehicle" />
                  </span>
                  <a
                    className="vehicleDamageDetail_item_link"
                    href={`/#${routes.vehicleDetail.path.replace(':vehicleId', vehicleDamageDetail.vehicle.id)}`}
                  >
                    <span className="vehicleDamageDetail_item_result">
                      {vehicleDamageDetail.vehicle.brand + ' ' + vehicleDamageDetail.vehicle.model}
                    </span>
                  </a>
                </div>
                <div className="vehicleDamageDetail_item">
                  <span className="vehicleDamageDetail_item_label">
                    <FormattedMessage id="vehicle_damage_detail_plate_number" />
                  </span>
                  <a
                    className="vehicleDamageDetail_item_link"
                    href={`/#${routes.vehicleDetail.path.replace(':vehicleId', vehicleDamageDetail.vehicle.id)}`}
                  >
                    <span className="vehicleDamageDetail_item_result">{vehicleDamageDetail.vehicle.registrationNumber}</span>
                  </a>
                </div>
                {vehicleDamageDetail.bookingId ? (
                  <div className="vehicleDamageDetail_item">
                    <span className="vehicleDamageDetail_item_label">
                      <FormattedMessage id="vehicle_damage_detail_booking_id" />
                    </span>
                    <a
                      className="vehicleDamageDetail_item_link"
                      href={`/#${routes.bookingDetail.path.replace(':bookingId', vehicleDamageDetail.bookingId)}`}
                    >
                      <span className="vehicleDamageDetail_item_result">{vehicleDamageDetail.bookingId}</span>
                    </a>
                  </div>
                ) : (
                  <div className="vehicleDamageDetail_item" />
                )}
              </div>
              <div className="vehicleDamageDetail_line">
                <div className="vehicleDamageDetail_item">
                  <span className="vehicleDamageDetail_item_label">
                    <FormattedMessage id="vehicle_damage_detail_damage_area" />
                  </span>
                  <span className="vehicleDamageDetail_item_result">
                    <FormattedMessage id={'feedbacks_damage_report_area_' + vehicleDamageDetail.area} />
                  </span>
                </div>
                <div className="vehicleDamageDetail_item">
                  <span className="vehicleDamageDetail_item_label">
                    <FormattedMessage id="vehicle_damage_detail_damage_type" />
                  </span>
                  <span className="vehicleDamageDetail_item_result">
                    <FormattedMessage id={'feedbacks_damage_report_type_' + vehicleDamageDetail.type} />
                  </span>
                </div>

                <div className="vehicleDamageDetail_item" />
              </div>
              <div className="vehicleDamageDetail_line">
                <div className="vehicleDamageDetail_item">
                  <span className="vehicleDamageDetail_item_label">
                    <FormattedMessage id="vehicle_damage_detail_damage_date" />
                  </span>
                  <span className="vehicleDamageDetail_item_result">{getAppFormattedDateTime(vehicleDamageDetail.createdDate)}</span>
                </div>
                <div className="vehicleDamageDetail_item">
                  <span className="vehicleDamageDetail_item_label">
                    <FormattedMessage id="vehicle_damage_detail_damage_status" />
                  </span>
                  <span className="vehicleDamageDetail_item_result">
                    <span
                      className={`vehicleDamageDetail_statusTypeCell
                        vehicleDamageDetail_statusTypeCell--${vehicleDamageDetail.currentStatus.type}`}
                    >
                      <FormattedMessage id={'vehicleDamages_status_' + vehicleDamageDetail.currentStatus.type} />
                    </span>
                  </span>
                </div>
                <div className="vehicleDamageDetail_item">
                  {vehicleDamageImage ? (
                    <FileImage imageStyle={{ maxWidth: '200px', maxHeight: '120px' }} imageObject={vehicleDamageImage} />
                  ) : null}
                </div>
              </div>
            </section>
            <section className="vehicleDamageDetail">
              <h4 className="vehicleDamageDetail_title">
                <FormattedMessage id="vehicle_damage_detail_title_damage_status" />
              </h4>
              <TableView
                customClass="vehicleDamageDetail_tableViewWrapper"
                params={vehicleDamageDetailTableViewParams}
                content={vehicleDamageDetail.statuses}
              />
            </section>
          </div>
        </div>
      </div>
    );
  }
}

VehicleDamageDetail.displayName = 'VehicleDamageDetail';

export default connect(state => {
  const {
    vehicleDamages: { vehicleDamageDetail, vehicleDamageImage, openedVehicleDamageEditStatusModal, urlParams }
  } = state;

  return {
    vehicleDamageDetail,
    vehicleDamageImage,
    openedVehicleDamageEditStatusModal,
    urlParams,
    role: userRoleSelector(state)
  };
})(VehicleDamageDetail);
