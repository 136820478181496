import React, { PropTypes } from 'react';
import { FormattedMessage } from 'react-intl';
import _ from 'lodash';

import EkButton from '../EkButton/EkButton';
import IsGranted from '../IsGranted/IsGranted';

import FileImage from '../FileImage/FileImage';

import {
  BACKUSER_ROLE_ADMIN,
  BACKUSER_ROLE_SUPER_ADMIN,
  BACKUSER_ROLE_ROOT,
  BACKUSER_ROLE_CALL_CENTER_OPERATOR,
  STATUS_APPLIED,
  STATUS_TO_COMPLETE,
  STATUS_APPROVED,
  STATUS_NOT_APPROVED,
  CUSTOM_FIELD_TYPE_FILE
} from '../../constants/backend-constants';

const MemberInformations = props => {
  const {
    detailMember,
    enabledActions,
    onHandleRejectMemberInfo,
    onHandleApproveMemberInfo,
    customFields,
    locale,
    customFieldsFiles
  } = props;

  return (
    <section className="memberDetail">
      <h4 className="memberDetail_title">
        <FormattedMessage id="members_detail_subtitle" />
      </h4>
      <div className="memberDetail_line memberDetail_line--triple">
        <div className="memberDetail_item">
          <span className="memberDetail_item_label">
            <FormattedMessage id="members_detail_first_name" />
          </span>
          <span className="memberDetail_item_result">{detailMember.firstName}</span>
        </div>
        <div className="memberDetail_item">
          <span className="memberDetail_item_label">
            <FormattedMessage id="members_detail_last_name" />
          </span>
          <span className="memberDetail_item_result">{detailMember.lastName}</span>
        </div>
        <div className="memberDetail_item">
          <span className="memberDetail_item_label">
            <FormattedMessage id="members_detail_suspended" />
          </span>
          <span className="memberDetail_item_result">
            <FormattedMessage id={`members_detail_suspended_${detailMember.suspended}`} />
          </span>
        </div>
      </div>

      <div className="memberDetail_line memberDetail_line--triple">
        <div className="memberDetail_item">
          <span className="memberDetail_item_label">
            <FormattedMessage id="members_detail_email" />
          </span>
          <span className="memberDetail_item_result">{detailMember.login}</span>
        </div>

        <div className="memberDetail_item">
          <span className="memberDetail_item_label">
            <FormattedMessage id="members_detail_status" />
          </span>
          <span className={`memberDetail_item_result member_status member_status--${detailMember.profileReviewStatus.toLowerCase()}`}>
            <FormattedMessage id={`members_status_${detailMember.profileReviewStatus.toLowerCase()}`} />
          </span>
        </div>
      </div>

      <div className="memberDetail_line memberDetail_line--triple">
        <div className="memberDetail_item">
          <span className="memberDetail_item_label">
            <FormattedMessage id="members_detail_address" />
          </span>
          <span className="memberDetail_item_result">{detailMember.address.formattedAddress}</span>
        </div>
        <div className="memberDetail_item">
          <span className="memberDetail_item_label">
            <FormattedMessage id="members_detail_civility" />
          </span>
          <span className="memberDetail_item_result">{_.get(detailMember, 'civility', '-')}</span>
        </div>
      </div>

      <div className="memberDetail_line memberDetail_line--triple">
        <div className="memberDetail_item">
          <span className="memberDetail_item_label">
            <FormattedMessage id="members_detail_phone_number" />
          </span>
          <span className="memberDetail_item_result">
            {detailMember.phoneNumber.countryCode} {detailMember.phoneNumber.nationalNumber}
          </span>
        </div>

        {detailMember.secondaryPhoneNumber ? (
          <div className="memberDetail_item">
            <span className="memberDetail_item_label">
              <FormattedMessage id="members_detail_secondary_phone_number" />
            </span>
            <span className="memberDetail_item_result">
              {detailMember.secondaryPhoneNumber.countryCode} {detailMember.secondaryPhoneNumber.nationalNumber}
            </span>
          </div>
        ) : null}
      </div>

      <div className="memberDetail_line memberDetail_line--triple">
        <div className="memberDetail_item">
          <span className="memberDetail_item_label">
            <FormattedMessage id="members_detail_birth_date" />
          </span>
          <span className="memberDetail_item_result">{_.get(detailMember, 'birthDate', '-')}</span>
        </div>
        <div className="memberDetail_item">
          <span className="memberDetail_item_label">
            <FormattedMessage id="members_detail_birth_place" />
          </span>
          <span className="memberDetail_item_result">{_.get(detailMember, 'birthPlace', '-')}</span>
        </div>
      </div>

      {customFields && customFields.length > 0 && (
        <div className="memberDetail_line memberDetail_line--triple">
          {customFields.map(field => {
            const customField = field.companyCustomField;
            return (
              <div className="memberDetail_item" key={customField.id}>
                <span className="memberDetail_item_label">
                  {_.get(
                    _.find(customField.companyCustomFieldLabels, label => label.language === locale),
                    'label'
                  ) || _.get(customField, 'name', <FormattedMessage id="common_unknown" />)}
                </span>
                {_.get(field, 'companyCustomField.fieldType') === CUSTOM_FIELD_TYPE_FILE && field.value && (
                  <div className="memberDetail_customField_Image">
                    <FileImage imageObject={customFieldsFiles[field.value]} />
                  </div>
                )}
                {_.get(field, 'companyCustomField.fieldType') !== CUSTOM_FIELD_TYPE_FILE && field.value && (
                  <span className="memberDetail_item_result">{field.value}</span>
                )}
                {!field.value && <span className="memberDetail_item_result">-</span>}
              </div>
            );
          })}
        </div>
      )}

      {enabledActions && detailMember.profileReviewStatus !== STATUS_APPLIED && (
        <IsGranted allowed={[BACKUSER_ROLE_ADMIN, BACKUSER_ROLE_SUPER_ADMIN, BACKUSER_ROLE_ROOT, BACKUSER_ROLE_CALL_CENTER_OPERATOR]}>
          <div className="memberDetail_line memberDetail_line--alignRight">
            {detailMember.profileReviewStatus !== STATUS_APPROVED && (
              <EkButton customClass="memberDetailPage_actionButton memberDetailPage_approveButton" onAction={onHandleApproveMemberInfo}>
                <FormattedMessage id="members_detail_driver_approve_member_information" />
              </EkButton>
            )}
            {detailMember.profileReviewStatus !== STATUS_NOT_APPROVED && (
              <EkButton customClass="memberDetailPage_actionButton memberDetailPage_approveButton" onAction={onHandleRejectMemberInfo}>
                <FormattedMessage id="members_detail_driver_reject_member_information" />
              </EkButton>
            )}
          </div>
        </IsGranted>
      )}
    </section>
  );
};

MemberInformations.displayName = 'MemberInformations';

MemberInformations.defaultProps = {
  enabledActions: false
};

MemberInformations.propTypes = {
  detailMember: PropTypes.object.isRequired,
  enabledActions: PropTypes.bool,
  onHandleApproveMemberInfo: PropTypes.func,
  onHandleRejectMemberInfo: PropTypes.func,
  customFields: PropTypes.array,
  locale: PropTypes.string,
  customFieldsFiles: PropTypes.object
};

export default MemberInformations;
