import * as types from '../constants/actionTypes-constants';

export default function page(
  state = {
    rootClasses: '',
    route: '',
    group: '',
    params: {}
  },
  action
) {
  switch (action.type) {
    case types.PAGE_UPDATE_ROOT_CLASSES: {
      return { ...state, rootClasses: action.rootClasses };
    }

    case types.STORE_PAGE_PARAMS: {
      return { ...state, params: action.params };
    }

    case types.PAGE_UPDATE_CURRENT_ROUTE: {
      return { ...state, route: action.route };
    }

    case types.PAGE_UPDATE_CURRENT_GROUP: {
      return { ...state, group: action.group };
    }

    default: {
      return state;
    }
  }
}
