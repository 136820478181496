import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { getAddTitle } from '../../../../components/_v2/ColumnList/helpers';
import { showParentView } from '../../../../components/_v2/ColumnList/helpers';
import { showSuccessMsg } from '../../../../actions/flashMessage-actions';
import { getStore } from '../../../../store/all-store';
import { createCategoryRequest } from '../../../../actions/categories-actions';
import CategoryView from './index';
import { connect } from 'react-redux';
import { userRoleSelector } from '../../../../selectors/all-selectors';
import { editCategoriesRules } from '../../../../constants/routes-constants';
import { checkRole } from '../../../../constants/backuser-role-rules';

export class VehicleAddCategory extends Component {
  componentWillMount() {
    this.setVars();
    this.setCallbacks();
  }

  setVars() {
    this.limitedAccess = !checkRole(editCategoriesRules, this.props.role);
  }

  setCallbacks() {
    this.showParentView = showParentView.bind(this);

    this.handleCallback = ({ id, ...params }) => {
      const { dispatch } = getStore();

      dispatch(createCategoryRequest(params)).then(() => {
        dispatch(showSuccessMsg({ key: 'vehiclesCategoryForm_create_category_success' }));
        this.showParentView();
      });
    };
  }

  render() {
    return (
      <CategoryView
        inputRef={this.props.inputRef}
        title={getAddTitle(<FormattedMessage id="vehiclesCategoryForm_title" />)}
        fieldLabel={<FormattedMessage id="vehiclesCategoryForm_name_label" />}
        onClose={this.showParentView}
        onCallback={this.handleCallback}
        limitedAccess={this.limitedAccess}
      />
    );
  }
}

const mapStateToProps = state => ({ role: userRoleSelector(state) });

VehicleAddCategory = connect(mapStateToProps)(VehicleAddCategory);

export default VehicleAddCategory;
