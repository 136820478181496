/* eslint-disable react/jsx-no-bind */
import { FormattedMessage } from 'react-intl';
import { reduxForm, change } from 'redux-form';

// noinspection ES6CheckImport
import React, { Component, PropTypes } from 'react';

import {
  createValidator,
  notEmpty,
  address,
  length,
  number,
  isRateNumber,
  stopValidationIf,
  condition,
  italianFiscNumber
} from '../../validation/sync-validation';

import classNames from 'classnames';
import _map from 'lodash/map';
import _isEmpty from 'lodash/isEmpty';
import { booleanOptions, booleanOptionsLiquidation, booleanOptionsSocioUnico } from '../../constants/options-constants';
import { EXTERNAL_INVOICE, INVOICE_PAYMENT_CONDITIONS } from '../../constants/backend-constants';

import FormRow from '../../components/FormRow/FormRow';
import BoxedInput from '../../components/BoxedInput/BoxedInput';
import EkRadio from '../../components/EkRadio/EkRadio';
import AddressAutocomplete from '../../components/AddressAutocomplete/AddressAutocomplete';
import { RaisedButton } from 'material-ui';
import FieldErrorMsg from '../../components/FieldErrorMsg/FieldErrorMsg';
import BoxedSelect from '../../components/BoxedSelect/BoxedSelect';
import {
  TEMPLATE_MODEL_AUSTRIAN,
  TEMPLATE_MODEL_BELGIAN,
  TEMPLATE_MODEL_DEFAULT,
  TEMPLATE_MODEL_ITALIAN,
  TEMPLATE_MODEL_SPANISH,
  TEMPLATE_MODEL_SWISS,
  TEMPLATE_MODEL_SLOVENIAN
} from '../../constants/backend-constants';
import { callbackNot as not, getFieldValue } from '../../utils/utils';

class CompanyInvoiceParamsForm extends Component {
  constructor(props) {
    super(props);

    this.templateOptions = [
      { labelKey: 'template_type_default', value: TEMPLATE_MODEL_DEFAULT },
      { labelKey: 'template_type_austrian', value: TEMPLATE_MODEL_AUSTRIAN },
      { labelKey: 'template_type_belgian', value: TEMPLATE_MODEL_BELGIAN },
      { labelKey: 'template_type_italian', value: TEMPLATE_MODEL_ITALIAN },
      { labelKey: 'template_type_spanish', value: TEMPLATE_MODEL_SPANISH },
      { labelKey: 'template_type_slovenian', value: TEMPLATE_MODEL_SLOVENIAN },
      { labelKey: 'template_type_swiss', value: TEMPLATE_MODEL_SWISS }
    ];

    this.paymentConditionsOptions = [
      { labelKey: 'invoice_payment_condition_at_once', value: INVOICE_PAYMENT_CONDITIONS.AT_ONES },
      { labelKey: 'invoice_payment_condition_at_vehicle_retrieval', value: INVOICE_PAYMENT_CONDITIONS.AT_VEHICLE_RETRIEVAL }
    ];

    this.derivedStateFromProps(props);
  }

  // noinspection JSCheckFunctionSignatures
  componentWillReceiveProps(props) {
    this.derivedStateFromProps(props);
  }

  derivedStateFromProps(props) {
    const {
      fields: { currency, templateModel, useExternalInvoiceSystem, credentialsProvided },
      currencies
    } = props;
    this.showCurrency = currency.initialValue;

    this.currencyOptions = _map(currencies, item => {
      return { labelKey: 'currency_' + item, value: item };
    });

    this.itTemplate = templateModel.value === TEMPLATE_MODEL_ITALIAN;
    this.austrianTemplate = templateModel.value === TEMPLATE_MODEL_AUSTRIAN;
    this.belgianTemplate = templateModel.value === TEMPLATE_MODEL_BELGIAN;
    this.swissTemplate = templateModel.value === TEMPLATE_MODEL_SWISS;
    this.slovenianTemplate = templateModel.value === TEMPLATE_MODEL_SLOVENIAN;
    this.enableCredentials = this.itTemplate && useExternalInvoiceSystem.value === EXTERNAL_INVOICE.ITALIAN;
    this.hideCredentialsFields = credentialsProvided.value;
    this.handleEdgeCurrency();
  }

  handleEdgeCurrency() {
    if (this.showCurrency && _isEmpty(this.currencyOptions)) {
      this.currencyOptions = [{ labelKey: 'currency_' + this.showCurrency, value: this.showCurrency }];
    }
  }

  handleDisplayCredentials() {
    this.props.dispatch(change('companyInvoiceParamsForm', 'credentialsProvided', false, true));
  }

  saveButton() {
    if (this.props.readOnly) return false;
    return (
      <RaisedButton
        id="invoice-params-save"
        label={<FormattedMessage id="common_save" />}
        primary
        className={classNames('button', 'flatButton')}
        type="submit"
      />
    );
  }

  previewButton() {
    if (this.props.readOnlyPreview) return false;
    return (
      <RaisedButton
        label={<FormattedMessage id="common_preview" />}
        className={classNames('button', 'flatButton')}
        type="button"
        onClick={this.props.onPreview}
        id="invoice-params-preview"
      />
    );
  }

  getCurrencyField() {
    const {
      fields: { currency },
      readOnly,
      readOnlyCurrency
    } = this.props;

    if (this.showCurrency) {
      return (
        <BoxedSelect
          labelKey="invoice_params_label_currency"
          field={currency}
          id="invoice-params-currency"
          options={this.currencyOptions}
          onChange={this.props.handleUpdateCurrency}
          disabled={readOnly || readOnlyCurrency}
          noEmptyValue
        >
          <FieldErrorMsg customClass="fieldErrorMsg--invoiceParams" field={currency} />
        </BoxedSelect>
      );
    }
  }

  getItalianRea() {
    const {
      fields: { italianRea },
      readOnly
    } = this.props;

    if (this.itTemplate) {
      return (
        <BoxedInput
          labelKey="invoice_params_italian_rea"
          type="text"
          maxlength="255"
          id="invoice-params-rea"
          field={italianRea}
          disabled={readOnly}
        >
          <FieldErrorMsg customClass="fieldErrorMsg--invoiceParams" field={italianRea} />
        </BoxedInput>
      );
    }
  }

  getItalianInvoicePrefix() {
    const {
      fields: { invoiceNumberPrefix },
      readOnly
    } = this.props;
    if (this.itTemplate) {
      return (
        <BoxedInput
          labelKey="invoice_params_italian_prefix"
          type="text"
          maxlength="20"
          id="invoice-params-ita-prefix"
          field={invoiceNumberPrefix}
          disabled={readOnly}
        >
          <FieldErrorMsg customClass="fieldErrorMsg--invoiceParams" field={invoiceNumberPrefix} />
        </BoxedInput>
      );
    }
  }

  getItalianInitInvoiceNumber() {
    const {
      fields: { firstInvoiceNumber },
      readOnly
    } = this.props;
    if (this.itTemplate) {
      return (
        <BoxedInput
          labelKey="invoice_params_italian_firstInvoiceNumber"
          type="text"
          maxlength="255"
          id="invoice-params-firstInvoiceNumber"
          field={firstInvoiceNumber}
          disabled={readOnly}
        >
          <FieldErrorMsg customClass="fieldErrorMsg--invoiceParams" field={firstInvoiceNumber} />
        </BoxedInput>
      );
    }
  }

  getEnrjCredentialsFields() {
    const {
      fields: { invoiceParamsLogin, invoiceParamsPassword },
      readOnly
    } = this.props;

    if (this.enableCredentials) {
      if (this.hideCredentialsFields) {
        return (
          <FormRow>
            <div className="credential-status">
              <div className="credential-icon">
                <div className="circle-outline" />
              </div>
              <span>
                <FormattedMessage id="invoice_params_italian_enrj_credentials_set" />
                <div className="edit-credentials" onClick={() => this.handleDisplayCredentials()}>
                  <FormattedMessage id="invoice_params_italian_enrj_credentials_edit" />
                </div>
              </span>
            </div>
          </FormRow>
        );
      } else {
        return (
          <FormRow customClass="companyForm_row">
            <BoxedInput
              labelKey="invoice_params_italian_login"
              type="text"
              id="invoice-params-login"
              field={invoiceParamsLogin}
              disabled={readOnly}
            >
              <FieldErrorMsg customClass="fieldErrorMsg -invoiceParams" field={invoiceParamsLogin} />
            </BoxedInput>

            <BoxedInput
              labelKey="invoice_params_italian_password"
              type="text"
              id="invoice-params-password"
              field={invoiceParamsPassword}
              disabled={readOnly}
            >
              <FieldErrorMsg customClass="fieldErrorMsg--invoiceParams" field={invoiceParamsPassword} />
            </BoxedInput>
          </FormRow>
        );
      }
    }
  }

  getItalianFields() {
    const {
      fields: { taxIdCode, memberCode, fiscalScheme, ufficio, socioUnico, statusLiquidation },
      readOnly
    } = this.props;
    if (this.itTemplate) {
      return (
        <div>
          <FormRow customClass="companyForm_row">
            <div className="dual">
              <BoxedInput
                labelKey="invoice_params_italian_TaxIdCode"
                type="text"
                maxlength="255"
                id="invoice-params-taxIdCode"
                field={taxIdCode}
                disabled={readOnly}
              >
                <FieldErrorMsg customClass="fieldErrorMsg--invoiceParams" field={taxIdCode} />
              </BoxedInput>

              <BoxedInput
                labelKey="invoice_params_italian_MemberCode"
                type="text"
                id="invoice-params-memberCode"
                field={memberCode}
                disabled={readOnly}
              >
                <FieldErrorMsg customClass="fieldErrorMsg--invoiceParams" field={memberCode} />
              </BoxedInput>
            </div>
            <div className="dual">
              <BoxedInput
                labelKey="invoice_params_italian_FiscalScheme"
                type="text"
                id="invoice-params-fiscalScheme"
                field={fiscalScheme}
                disabled={readOnly}
              >
                <FieldErrorMsg customClass="fieldErrorMsg--invoiceParams" field={fiscalScheme} />
              </BoxedInput>
              <BoxedInput
                labelKey="invoice_params_italian_Ufficio"
                type="text"
                id="invoice-params-ufficio"
                field={ufficio}
                disabled={readOnly}
              >
                <FieldErrorMsg customClass="fieldErrorMsg--invoiceParams" field={ufficio} />
              </BoxedInput>
            </div>
          </FormRow>
          <FormRow customClass="companyForm_row">
            <EkRadio
              titleKey="invoice_params_italian_SocioUnico"
              customWrapperClass="companyForm_ekRadioWrapper"
              type="radio"
              items={booleanOptionsSocioUnico}
              id="invoice-params-socioUnico"
              namespace="invoice-params-socioUnico"
              field={socioUnico}
              disabled={readOnly}
            >
              <FieldErrorMsg customClass="fieldErrorMsg--invoiceParams" field={socioUnico} />
            </EkRadio>

            <EkRadio
              titleKey="invoice_params_italian_StatusLiquidation"
              customWrapperClass="companyForm_ekRadioWrapper"
              type="radio"
              items={booleanOptionsLiquidation}
              id="invoice-params-statusLiquidation"
              namespace="invoice-params-statusLiquidation"
              field={statusLiquidation}
              disabled={readOnly}
            >
              <FieldErrorMsg customClass="fieldErrorMsg--invoiceParams" field={statusLiquidation} />
            </EkRadio>
          </FormRow>

          {this.getEnrjCredentialsFields()}
        </div>
      );
    }
  }

  getDvr() {
    const {
      fields: { dataProcessingRegistry },
      readOnly
    } = this.props;

    if (this.austrianTemplate) {
      return (
        <BoxedInput
          maxlength="255"
          labelKey="invoice_params_dvr"
          type="text"
          id="invoice-params-dvr"
          field={dataProcessingRegistry}
          disabled={readOnly}
        >
          <FieldErrorMsg customClass="fieldErrorMsg--invoiceParams" field={dataProcessingRegistry} />
        </BoxedInput>
      );
    }
  }

  getInvoiceTemplateRow() {
    const {
      fields: { templateModel },
      readOnly
    } = this.props;

    return (
      <div className={classNames({ dual: this.itTemplate || this.austrianTemplate })}>
        <BoxedSelect
          id="invoice-params-template"
          labelKey="invoice_params_label_template_model"
          field={templateModel}
          options={this.templateOptions}
          disabled={readOnly}
        >
          <FieldErrorMsg customClass="fieldErrorMsg--invoiceParams" field={templateModel} />
        </BoxedSelect>

        {this.getItalianRea()}
        {this.getDvr()}
      </div>
    );
  }

  getLegalFormField() {
    const {
      fields: { legalForm },
      readOnly
    } = this.props;

    return (
      <BoxedInput
        labelKey="invoice_params_label_legal_form"
        type="text"
        maxlength="255"
        id="invoice-params-legal-form"
        field={legalForm}
        disabled={readOnly}
      >
        <FieldErrorMsg customClass="fieldErrorMsg--invoiceParams" field={legalForm} />
      </BoxedInput>
    );
  }

  getCompanyRegisterNumberField() {
    const {
      fields: { companyRegisterNumber },
      readOnly
    } = this.props;

    return (
      <BoxedInput
        labelKey="invoice_params_company_register_number"
        type="text"
        maxlength="255"
        id="invoice-params-company-register-number"
        field={companyRegisterNumber}
        disabled={readOnly}
      >
        <FieldErrorMsg customClass="fieldErrorMsg--invoiceParams" field={companyRegisterNumber} />
      </BoxedInput>
    );
  }

  getLegalFormRow() {
    return this.austrianTemplate ? this.getCompanyRegisterNumberField() : this.getLegalFormField();
  }

  getLeftToLegalForm() {
    const {
      fields: { paymentConditionsLabel },
      readOnly
    } = this.props;

    if (this.belgianTemplate) {
      return (
        <BoxedSelect
          id="invoice-payment-conditions"
          labelKey="invoice_payment_conditions_label"
          field={paymentConditionsLabel}
          options={this.paymentConditionsOptions}
          disabled={readOnly}
        >
          <FieldErrorMsg customClass="fieldErrorMsg--invoiceParams" field={paymentConditionsLabel} />
        </BoxedSelect>
      );
    }

    if (!this.swissTemplate) {
      return (
        <div className="dual">
          {this.getFiscalNumberField()}
          {this.getCapitalRow()}
        </div>
      );
    }
  }

  getCapitalField() {
    const {
      fields: { companyCapital },
      readOnly
    } = this.props;

    if (!this.slovenianTemplate) {
      return (
        <BoxedInput
          id="invoice-params-capital"
          labelKey="invoice_params_label_company_capital"
          type="text"
          field={companyCapital}
          maxlength="255"
          disabled={readOnly}
        >
          <FieldErrorMsg customClass="fieldErrorMsg--invoiceParams" field={companyCapital} />
        </BoxedInput>
      );
    }
  }

  getFiscalNumberField() {
    const {
      fields: { fiscalNumber },
      readOnly
    } = this.props;
    if (!this.slovenianTemplate) {
      return (
        <BoxedInput
          labelKey="invoice_params_label_fiscal_number"
          type="text"
          maxlength="255"
          id="invoice-params-fiscal-number"
          field={fiscalNumber}
          disabled={readOnly}
        >
          <FieldErrorMsg customClass="fieldErrorMsg--invoiceParams" field={fiscalNumber} />
        </BoxedInput>
      );
    }
  }

  getCompanyCourtField() {
    const {
      fields: { companyCourt },
      readOnly
    } = this.props;

    return (
      <BoxedInput
        id="invoice-params-company-court"
        labelKey="invoice_params_company_court"
        type="text"
        field={companyCourt}
        maxlength="255"
        disabled={readOnly}
      >
        <FieldErrorMsg customClass="fieldErrorMsg--invoiceParams" field={companyCourt} />
      </BoxedInput>
    );
  }

  getCapitalRow() {
    return this.austrianTemplate ? this.getCompanyCourtField() : this.getCapitalField();
  }

  render() {
    const {
      fields: { invoicingDelegation, vatCode, invoiceNumberSuffix, invoicingAddress, companyName, postalCode, city, country, vatRate },
      onFormSubmit,
      handleSubmit,
      readOnly
    } = this.props;

    return (
      <div className="companyInvoiceForm">
        <form action="#" onSubmit={handleSubmit(onFormSubmit)} className="companyForm invoice-params">
          <FormRow customClass="companyForm_row">
            {this.getInvoiceTemplateRow()}
            <div className="dual">
              <EkRadio
                titleKey="invoice_params_label_invoicing_delegation"
                customWrapperClass="companyForm_ekRadioWrapper"
                type="radio"
                namespace="invoiceDelegation"
                items={booleanOptions}
                disabled={readOnly}
                field={invoicingDelegation}
              >
                <FieldErrorMsg customClass="fieldErrorMsg--invoiceParams" field={invoicingDelegation} />
              </EkRadio>

              <BoxedInput
                disabled={readOnly}
                labelKey="common_company"
                type="text"
                maxlength="255"
                field={companyName}
                id="invoice-params-company-name"
              >
                <FieldErrorMsg customClass="fieldErrorMsg--invoiceParams" field={companyName} />
              </BoxedInput>
            </div>
          </FormRow>

          <FormRow customClass="companyForm_row">
            <div className={classNames({ dual: !this.itTemplate, fourth: this.itTemplate })}>
              {this.itTemplate && this.getItalianInvoicePrefix()}
              {this.itTemplate && this.getItalianInitInvoiceNumber()}
              <BoxedInput
                labelKey="invoice_params_label_invoice_number_suffix"
                type="text"
                maxlength="255"
                field={invoiceNumberSuffix}
                disabled={readOnly}
                id="invoice-params-number-suffix"
              >
                <FieldErrorMsg customClass="fieldErrorMsg--invoiceParams" field={invoiceNumberSuffix} />
              </BoxedInput>
              <BoxedInput
                maxlength="255"
                labelKey="invoice_params_label_vat_code"
                type="text"
                id="invoice-params-vat-code"
                field={vatCode}
                disabled={readOnly}
              >
                <FieldErrorMsg customClass="fieldErrorMsg--invoiceParams" field={vatCode} />
              </BoxedInput>
            </div>

            <div className={classNames({ dual: this.showCurrency })}>
              <BoxedInput
                maxlength="255"
                labelKey="invoice_params_label_vat_rate"
                type="text"
                id="invoice-params-vat-rate"
                field={vatRate}
                disabled={readOnly}
              >
                <FieldErrorMsg customClass="fieldErrorMsg--invoiceParams" field={vatRate} />
              </BoxedInput>
              {this.getCurrencyField()}
            </div>
          </FormRow>

          <FormRow customClass="companyForm_row">
            <div className="dual">
              <label className="boxedInput_label auto">
                <span className="boxedInput_labelText">
                  <FormattedMessage id="companyForm_address_label" />
                </span>
                <AddressAutocomplete field={invoicingAddress} disabled={readOnly} form="companyInvoiceParamsForm" spreadExternalFields />
                <FieldErrorMsg field={invoicingAddress} customClass="fieldErrorMsg--companyForm" />
              </label>
              <BoxedInput
                formRowItemKey="companyForm_postalCode"
                type="text"
                id="postalCode"
                labelKey="common_autocomplete_postal_code"
                customClass="companyForm_boxedInput"
                field={postalCode}
                disabled={readOnly}
              >
                <FieldErrorMsg field={postalCode} customClass="fieldErrorMsg--companyForm" />
              </BoxedInput>
            </div>
            <div className="dual">
              <BoxedInput
                formRowItemKey="companyForm_city"
                type="text"
                id="city"
                labelKey="common_autocomplete_city"
                customClass="companyForm_boxedInput"
                field={city}
                disabled={readOnly}
              >
                <FieldErrorMsg field={city} customClass="fieldErrorMsg--companyForm" />
              </BoxedInput>
              <label className="boxedInput_label">
                <span className="boxedInput_labelText">
                  <FormattedMessage id="common_autocomplete_country" />
                </span>
                <AddressAutocomplete field={country} searchType={['country']} disabled={readOnly} translateCountryCode />
                <FieldErrorMsg field={country} customClass="fieldErrorMsg--companyForm" />
              </label>
            </div>
          </FormRow>

          <FormRow customClass="companyForm_row">
            {!this.slovenianTemplate && this.getLeftToLegalForm()}
            {this.getLegalFormRow()}
          </FormRow>

          {this.itTemplate && this.getItalianFields()}

          <div className="actions">
            {this.previewButton()}
            {this.saveButton()}
          </div>
        </form>
      </div>
    );
  }
}

CompanyInvoiceParamsForm.propTypes = {
  onFormSubmit: PropTypes.func,
  onPreview: PropTypes.func,
  handleUpdateCurrency: PropTypes.func,
  readOnly: PropTypes.bool,
  readOnlyCurrency: PropTypes.bool,
  readOnlyPreview: PropTypes.bool
};

// noinspection JSUnusedGlobalSymbols
CompanyInvoiceParamsForm.defaultProps = {
  onFormSubmit: () => false,
  readOnly: false
};

CompanyInvoiceParamsForm.displayName = 'CompanyInvoiceParamsForm';

const companyCapitalRequiredTemplates = [TEMPLATE_MODEL_DEFAULT, TEMPLATE_MODEL_ITALIAN, TEMPLATE_MODEL_SPANISH];

function companyCapitalRequired(props, value, values) {
  const template = getFieldValue(props, values, 'templateModel');
  return companyCapitalRequiredTemplates.includes(template);
}

function isAustrianTemplate(props, value, values) {
  return getFieldValue(props, values, 'templateModel') === TEMPLATE_MODEL_AUSTRIAN;
}

function isBelgianTemplate(props, value, values) {
  return getFieldValue(props, values, 'templateModel') === TEMPLATE_MODEL_BELGIAN;
}

function isItalianTemplate(props, value, values) {
  return getFieldValue(props, values, 'templateModel') === TEMPLATE_MODEL_ITALIAN;
}

function isSwissTemplate(props, value, values) {
  return getFieldValue(props, values, 'templateModel') === TEMPLATE_MODEL_SWISS;
}

function isSlovenianTemplate(props, value, values) {
  return getFieldValue(props, values, 'templateModel') === TEMPLATE_MODEL_SLOVENIAN;
}

function credentialsProvided(props, value, values) {
  return getFieldValue(props, values, 'credentialsProvided');
}

export default reduxForm({
  form: 'companyInvoiceParamsForm',
  fields: [
    'companyCapital',
    'invoicingDelegation',
    'legalForm',
    'fiscalNumber',
    'vatCode',
    'invoiceNumberSuffix',
    'companyName',
    'currency',
    'templateModel',
    'paymentConditionsLabel',
    'invoicingAddress',
    'postalCode',
    'city',
    'country',
    'italianRea',
    'vatRate',
    'companyCourt',
    'companyRegisterNumber',
    'dataProcessingRegistry',
    'invoiceNumberPrefix',
    'taxIdCode',
    'memberCode',
    'fiscalScheme',
    'ufficio',
    'socioUnico',
    'statusLiquidation',
    'firstInvoiceNumber',
    'useExternalInvoiceSystem',
    'invoiceParamsLogin',
    'invoiceParamsPassword',
    'credentialsProvided'
  ],
  validate: createValidator({
    companyCapital: [
      stopValidationIf(condition(not(companyCapitalRequired))),
      notEmpty(),
      stopValidationIf(condition(not(isItalianTemplate))),
      number()
    ],
    companyCourt: [stopValidationIf(condition(not(isAustrianTemplate))), notEmpty()],

    dataProcessingRegistry: [stopValidationIf(condition(not(isAustrianTemplate))), notEmpty()],
    companyRegisterNumber: [stopValidationIf(condition(not(isAustrianTemplate))), notEmpty()],
    invoicingDelegation: [notEmpty()],
    legalForm: [stopValidationIf(condition(isAustrianTemplate)), notEmpty()],
    fiscalNumber: [
      stopValidationIf(condition(isBelgianTemplate)),
      stopValidationIf(condition(isSwissTemplate)),
      stopValidationIf(condition(isSlovenianTemplate)),
      notEmpty()
    ],
    paymentConditionsLabel: [stopValidationIf(condition(not(isBelgianTemplate))), notEmpty()],
    vatCode: [notEmpty()],
    invoiceNumberSuffix: [notEmpty()],
    companyName: [notEmpty()],
    templateModel: [notEmpty()],
    invoicingAddress: [address(), length({ max: 80 }), notEmpty()],
    country: [address()],
    postalCode: [notEmpty()],
    city: [notEmpty()],
    italianRea: [stopValidationIf(condition(not(isItalianTemplate))), notEmpty()],
    vatRate: [notEmpty(), number(), isRateNumber()],
    invoiceNumberPrefix: [stopValidationIf(condition(not(isItalianTemplate))), notEmpty()],
    taxIdCode: [stopValidationIf(condition(not(isItalianTemplate))), notEmpty(), length({ min: 1, max: 28 })],
    memberCode: [stopValidationIf(condition(not(isItalianTemplate))), notEmpty(), length({ min: 6, max: 7 })],
    fiscalScheme: [stopValidationIf(condition(not(isItalianTemplate))), notEmpty(), italianFiscNumber(), length({ max: 4 })],
    ufficio: [stopValidationIf(condition(not(isItalianTemplate))), notEmpty(), length({ min: 2, max: 2 })],
    statusLiquidation: [stopValidationIf(condition(not(isItalianTemplate))), notEmpty()],
    firstInvoiceNumber: [stopValidationIf(condition(not(isItalianTemplate))), notEmpty(), number()],
    invoiceParamsLogin: [stopValidationIf(condition(credentialsProvided)), stopValidationIf(condition(not(isItalianTemplate))), notEmpty()],
    invoiceParamsPassword: [
      stopValidationIf(condition(credentialsProvided)),
      stopValidationIf(condition(not(isItalianTemplate))),
      notEmpty()
    ]
  })
})(CompanyInvoiceParamsForm);
