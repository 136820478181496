import * as types from '../../constants/actionTypes-constants';
import { UPDATE_LOCATION } from 'react-router-redux';
import { ALL } from '../../constants/generic-constants';

export default function header(
  state = {
    companyIsEditing: false,
    searchText: '',
    searchMode: false,
    superCompanyText: '',
    companyText: ''
  },
  action
) {
  switch (action.type) {
    case types.HEADER_SUPER_COMPANY_TOGGLE:
      if (!state.superCompanyIsEditing) {
        return {
          ...state,
          superCompanyIsEditing: !state.superCompanyIsEditing,
          superCompanyText: ''
        };
      }

      return {
        ...state,
        superCompanyIsEditing: !state.superCompanyIsEditing
      };

    case types.HEADER_COMPANY_TOGGLE:
      if (!state.companyIsEditing) {
        return {
          ...state,
          companyIsEditing: !state.companyIsEditing,
          companyText: ''
        };
      }

      return {
        ...state,
        companyIsEditing: !state.companyIsEditing
      };

    case types.HEADER_CHANGE_SEARCH_INPUT_TEXT:
      return {
        ...state,
        searchText: action.searchText
      };

    case types.HEADER_SEARCH_MODE:
      const searchText = action.searchMode ? state.searchText : '';
      return {
        ...state,
        searchMode: action.searchMode,
        searchText
      };

    case types.HEADER_CHANGE_SUPERCOMPANY_INPUT_TEXT:
      return {
        ...state,
        superCompanyText: action.text
      };

    case types.COMPANIES_SET_CURRENT_COMPANY: {
      const { company } = action;
      const { name } = company || {};

      return {
        ...state,
        superCompanyText: name
      };
    }

    case types.HEADER_CHANGE_COMPANY_INPUT_TEXT:
      return {
        ...state,
        companyText: action.text
      };

    case types.SUB_COMPANIES_SET_CURRENT_SUB_COMPANY: {
      const { subCompany } = action;
      let { name } = subCompany || {};

      return {
        ...state,
        companyText: name
      };
    }

    case UPDATE_LOCATION:
      return {
        ...state,
        searchMode: false
      };

    default:
      return state;
  }
}
