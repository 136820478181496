import React, { Component, PropTypes } from 'react';
import { reduxForm } from 'redux-form';
import { FormattedMessage } from 'react-intl';
import _get from 'lodash/get';

import FormRow from '../FormRow/FormRow';
import EkButton from '../EkButton/EkButton';
import LabeledCheckbox from '../LabeledCheckbox/LabeledCheckbox';
import EkTextarea from '../EkTextarea/EkTextarea';
import CustomFieldsBooking from '../CustomFieldsBooking/CustomFieldsBooking';
import { bookingCustomFieldsSelector, bookingCustomFieldsEditValuesSelector } from '../../selectors/all-selectors';
import { createValidator } from '../../validation/all-validation';
import { createCustomFieldsValidators, createCustomFieldsNames, namedCompose, scrollToFirstError, safe } from '../../utils/utils';
import { BOOKING_USAGE_TYPE_PRIVATE } from '../../constants/backend-constants';
import { BACKUSER_ROLE_ADMIN, BACKUSER_ROLE_SUPER_ADMIN, BACKUSER_ROLE_ROOT } from '../../constants/backend-constants';
import IsGranted from '../../components/IsGranted/IsGranted';
import { BOOKING_COMMENT_INIT_VALUE, PAID_BOOKING_INIT_VALUE } from '../../constants/form-constants';
import FormField from '../FormField';

class ConfirmBookingCreation extends Component {
  displayChorusFields() {
    const {
      fields: { chorusAlias, chorusEngagement, chorusMarche, chorusService },
      detailMember
    } = this.props;

    if (safe(() => detailMember.enterprise.chorusData.administration)) {
      return (
        <div className="specificities">
          <div className="label">
            <FormattedMessage id="members_detail_enterprise_chorus_detail" />
          </div>
          <FormField labelKey="members_detail_enterprise_chorus_chorusAlias" type="text" field={chorusAlias} maxlength="255" />
          <FormField labelKey="members_detail_enterprise_chorus_chorusEngagement" type="text" field={chorusEngagement} maxlength="255" />
          <FormField labelKey="members_detail_enterprise_chorus_chorusMarche" type="text" field={chorusMarche} maxlength="255" />
          <FormField labelKey="members_detail_enterprise_chorus_chorusService" type="text" field={chorusService} maxlength="255" />
        </div>
      );
    }
  }

  render() {
    const {
      fields: { paidBooking, comment, ...fields },
      handleSubmit,
      customFields,
      customFieldsEditMode,
      selectedVehicle
    } = this.props;

    return (
      <section className="addBookingConfirmationFormWrapper">
        <form action="#" className="addBookingConfirmationForm" onSubmit={handleSubmit(this.props.onCallback)} noValidate>
          <div className="addBookingConfirmationForm_container">
            <h4 className="addBookingConfirmationForm_title">
              <FormattedMessage id="booking_create_confirmation_title" />
            </h4>
          </div>

          {customFields.length > 0 && <FormattedMessage id="company_detail_tab_booking_custom_fields" />}
          <CustomFieldsBooking
            customFields={customFields}
            customFieldsEditMode={customFieldsEditMode}
            formFields={fields}
            usage={selectedVehicle.vehicle.usage}
          />
          {this.displayChorusFields()}
          {_get(selectedVehicle, 'carSharingInfo.usageType') === BOOKING_USAGE_TYPE_PRIVATE && (
            <IsGranted allowed={[BACKUSER_ROLE_ADMIN, BACKUSER_ROLE_SUPER_ADMIN, BACKUSER_ROLE_ROOT]}>
              <FormRow customClass="addBookingConfirmationForm_row">
                <LabeledCheckbox id="addBooking_freeOfCharge" customClass="addBookingConfirmationForm_option" field={paidBooking}>
                  <FormattedMessage id="booking_create_confirmation_usage" />
                </LabeledCheckbox>
              </FormRow>
            </IsGranted>
          )}

          <FormRow customClass="addBookingConfirmationForm_row">
            <EkTextarea labelKey="booking_create_confirmation_comment" customClass="addBookingConfirmationForm_textarea" field={comment} />
          </FormRow>

          <FormRow customClass="addBookingConfirmationForm_row">
            <EkButton
              type="button"
              skinType="reverse"
              id="addBookingConfirmationForm_abortBtn"
              formRowItemKey="addBookingConfirmationForm_abortBtn"
              customClass="addBookingConfirmationForm_button"
              onAction={this.props.onAbort}
            >
              <FormattedMessage id="booking_create_confirmation_abort_button" />
            </EkButton>

            <EkButton
              type="button"
              id="addBookingConfirmationForm_button"
              formRowItemKey="addBookingConfirmationForm_submitBtn"
              customClass="addBookingConfirmationForm_button"
              onAction={handleSubmit(this.props.onCallback)}
            >
              <FormattedMessage id={this.props.confirmPrebooking ? 'common_confirm' : 'booking_create_confirmation_save_button'} />
            </EkButton>
          </FormRow>
        </form>
      </section>
    );
  }
}

ConfirmBookingCreation.displayName = 'ConfirmBookingCreation';

ConfirmBookingCreation.propTypes = {
  handleSubmit: PropTypes.func,
  onCallback: PropTypes.func,
  onAbort: PropTypes.func,
  fields: PropTypes.object,
  selectedVehicle: PropTypes.object
};

export default namedCompose(
  reduxForm(
    {
      onSubmitFail: scrollToFirstError,
      form: 'createBookingConfirmationForm'
    },
    (state, props) => {
      const customFields = bookingCustomFieldsSelector(state);
      const initialValuesCustomFields = bookingCustomFieldsEditValuesSelector(state);
      const usage = state.booking.selectedVehicle.vehicle.usage || {};
      const { detailMember } = state.members;
      const { chorusData } = detailMember.enterprise || {};

      return {
        customFields,
        detailMember,
        initialValues: {
          paidBooking: _get(props, 'initialValues.paidBooking', PAID_BOOKING_INIT_VALUE),
          comment: _get(props, 'initialValues.comment', BOOKING_COMMENT_INIT_VALUE),
          ...initialValuesCustomFields,
          ...chorusData
        },
        validate: createValidator({
          ...createCustomFieldsValidators(customFields, usage)
        }),
        fields: [
          'paidBooking',
          'comment',
          'chorusAlias',
          'chorusEngagement',
          'chorusMarche',
          'chorusService',
          ...createCustomFieldsNames(customFields)
        ]
      };
    }
  )
)(ConfirmBookingCreation);
