import React, { Component } from 'react';
import autoBind from 'react-autobind';
import AccountCircleIcon from 'material-ui/svg-icons/action/account-circle';
import EmailIcon from 'material-ui/svg-icons/communication/email';
import PhoneIcon from 'material-ui/svg-icons/communication/phone';
import PlaceIcon from 'material-ui/svg-icons/maps/place';
import pure from 'recompose/pure';
import { namedCompose } from '../../utils/utils';
import { iconStyles } from '../../constants/style-constants';
import { FormattedMessage } from 'react-intl';
import { localeSelector } from '../../selectors/all-selectors';
import { connect } from 'react-redux';
import Tooltip from '../Tooltip/Tooltip';

class BackUserInfo extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.horseIcon = <div className="horse-icon" />;
    this.derivedStateFromProps(props);
  }

  componentWillReceiveProps(nextProps) {
    this.derivedStateFromProps(nextProps);
  }

  derivedStateFromProps(nextProps) {
    const { userSuspended, role, firstName, lastName, login } = nextProps;
    this.suspendedClass = userSuspended ? 'status_suspended' : 'status_active';
    this.statusLabelId = userSuspended ? 'backUsers_detail_suspended' : 'groups_status_ACTIVE';
    this.role = role ? <FormattedMessage id={'backUsers_role_' + role} /> : <FormattedMessage id={'common_unknown'} />;
    this.firstName = firstName ? firstName : '';
    this.lastName = lastName ? lastName : '';
    this.login = login ? login : <FormattedMessage id={'common_unknown'} />;
  }

  userName() {
    const { showName } = this.props;
    if (showName) {
      let name = (this.firstName + ' ' + this.lastName).trim();
      name = name || <FormattedMessage id={'common_unknown'} />;
      return (
        <div className="detailView_row">
          <span className="detailView_cell_type_1">
            <Tooltip content={<FormattedMessage id="common_full_name" />} placement="bottom">
              <AccountCircleIcon style={iconStyles.all} />
            </Tooltip>
          </span>
          <span className="detailView_cell_type_1">{name}</span>
        </div>
      );
    }
  }

  userStatus() {
    if (this.props.showStatus)
      return (
        <span>
          <span className="detailView_dash">-</span>
          <Tooltip content={<FormattedMessage id="common_account_status" />} placement="bottom">
            <span className={`detailView_component_headline_status ${this.suspendedClass}`}>
              <FormattedMessage id={this.statusLabelId} />
            </span>
          </Tooltip>
        </span>
      );
  }

  phoneNumber() {
    const { countryCode, nationalNumber } = this.props;
    if (countryCode && nationalNumber)
      return (
        <div className="detailView_row">
          <span className="detailView_cell_type_1">
            <Tooltip content={<FormattedMessage id="members_detail_phone_number" />} placement="bottom">
              <PhoneIcon style={iconStyles.all} />
            </Tooltip>
          </span>
          <span className="detailView_cell_type_1">
            <span>{countryCode}</span>
            <span> </span>
            <span>{nationalNumber}</span>
          </span>
        </div>
      );
  }

  address() {
    const { address } = this.props;
    if (address)
      return (
        <div className="detailView_row">
          <span className="detailView_cell_type_1">
            <Tooltip content={<FormattedMessage id="members_detail_address" />} placement="bottom">
              <PlaceIcon style={iconStyles.all} />
            </Tooltip>
          </span>
          <span className="detailView_cell_type_1">{address}</span>
        </div>
      );
  }

  render() {
    return (
      <div className="backUserInfo">
        <h4>
          <FormattedMessage id="backUsers_detail_title" />
          {this.userStatus()}
        </h4>
        <div className="backUserInfo_body">
          <div className="detailView_table">
            {this.userName()}
            <div className="detailView_row">
              <span className="detailView_cell_type_1">
                <Tooltip content={<FormattedMessage id="members_detail_email" />} placement="bottom">
                  <EmailIcon style={iconStyles.all} />
                </Tooltip>
              </span>
              <span className="detailView_cell_type_1">{this.login}</span>
            </div>
            {this.phoneNumber()}
            {this.address()}
            <div className="detailView_row">
              <span className="detailView_cell_type_1">
                <Tooltip content={<FormattedMessage id="backUsers_detail_role" />} placement="bottom">
                  {this.horseIcon}
                </Tooltip>
              </span>
              <span className="detailView_cell_type_1">{this.role}</span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

BackUserInfo.defaultProps = {
  firstName: undefined,
  lastName: undefined,
  showName: true,
  showStatus: true,
  role: undefined, // back-end string
  login: undefined,
  address: undefined,
  nationalNumber: undefined,
  countryCode: undefined,
  userSuspended: false
};

export default namedCompose(
  connect(state => {
    return {
      locale: localeSelector(state)
    };
  }),
  pure
)(BackUserInfo);
