import React, { Component, PropTypes } from 'react';
import { FormattedMessage } from 'react-intl';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';

import BoxedInput from '../BoxedInput/BoxedInput';
import EkButton from '../EkButton/EkButton';
import FieldErrorMsg from '../FieldErrorMsg/FieldErrorMsg';
import { toggleForgotPasswordMode } from '../../actions/all-actions';
import { createValidator, notEmpty, email, length } from '../../validation/all-validation';
import { scrollToFirstError } from '../../utils/utils';

class ResetPasswordForm extends Component {
  constructor(props) {
    super(props);
    this.handleToggleForgotPasswordMode = this.handleToggleForgotPasswordMode.bind(this);
  }

  handleToggleForgotPasswordMode() {
    const { dispatch } = this.props;
    dispatch(toggleForgotPasswordMode());
  }

  render() {
    const {
      fields: { login },
      handleSubmit,
      serverError,
      resetPasswordSuccess
    } = this.props;

    let serverErrorKey;

    if (serverError) {
      switch (serverError.code) {
        case 401:
          serverErrorKey = 'resetPassword_invalid_email';
          break;
        default:
          serverErrorKey = 'error_server_unknown';
          break;
      }
    }

    return (
      <form action="#" className="resetPasswordForm" onSubmit={handleSubmit(this.props.onCallback)}>
        {serverError ? (
          <div className="resetPasswordForm_mainErrorMsg">
            <FormattedMessage id={serverErrorKey} />
          </div>
        ) : null}

        {resetPasswordSuccess ? (
          <div className="resetPasswordForm_successMsg">
            <FormattedMessage id="resetPasswordForm_successMsg" />
          </div>
        ) : null}

        <FieldErrorMsg field={login} customClass="fieldErrorMsg--resetPasswordForm" />
        <BoxedInput type="email" placeholder="Email" customClass="resetPasswordForm_boxedInput" field={login} />

        <EkButton customClass="resetPasswordForm_submitBtn" onAction={handleSubmit(this.props.onCallback)} fullWidth>
          <FormattedMessage id="resetPassword_forgotPasswordBtn" />
        </EkButton>

        <button type="button" className="resetPasswordForm_rememberedPassword" onClick={this.handleToggleForgotPasswordMode}>
          <FormattedMessage id="resetPassword_backToLogin" />
        </button>
      </form>
    );
  }
}

ResetPasswordForm.displayName = 'ResetPasswordForm';

ResetPasswordForm.propTypes = {
  action: PropTypes.func,
  fields: PropTypes.object,
  onCallback: PropTypes.func,
  handleSubmit: PropTypes.func,
  serverError: PropTypes.object,
  resetPasswordSuccess: PropTypes.bool
};

ResetPasswordForm = reduxForm({
  onSubmitFail: scrollToFirstError,
  form: 'resetPassword',
  fields: ['login'],
  validate: createValidator({
    login: [notEmpty(), email(), length({ max: 75 })]
  })
})(ResetPasswordForm);

export default connect(state => {
  const {
    user: { forgotPasswordMode, resetPasswordSuccess }
  } = state;

  return {
    forgotPasswordMode,
    resetPasswordSuccess,
    initialValues: {
      login: state.user.defaultLogin
    }
  };
})(ResetPasswordForm);
