import React, { PropTypes } from 'react';
import { FormattedMessage } from 'react-intl';
import TodayIcon from 'material-ui/svg-icons/action/today';
import RecordVoiceOverIcon from 'material-ui/svg-icons/action/record-voice-over';
import ChatBubbleOutline from 'material-ui/svg-icons/communication/chat-bubble-outline';
import _ from 'lodash';

import { iconStyles } from '../../constants/style-constants';
import { getAppFormattedDateTime } from '../../utils/utils';
import Tooltip from '../Tooltip/Tooltip';

const MemberCommentsInfo = props => {
  const { comments } = props;
  const unknown = <FormattedMessage id="common_unknown" />;

  return (
    <div className="memberView_commentsInfo">
      <h4>
        <FormattedMessage id="memberDetail_detail_title_remarks" />
      </h4>
      <div className="memberView_commentsInfo_body">
        <div className="detailView_table">
          {_.map(comments, (item, index) => {
            return (
              _.get(item, 'body') && (
                <div key={index} className="detailView_row">
                  <span className="detailView_cell_type_4">
                    <Tooltip content={<FormattedMessage id="member_detail_submissionDate" />} placement="bottom">
                      <TodayIcon style={iconStyles.all} />
                    </Tooltip>
                    <div>{_.get(item, 'date') ? getAppFormattedDateTime(item.date) : unknown}</div>
                  </span>
                  <span className="detailView_cell_type_4">
                    <Tooltip content={<FormattedMessage id="member_detail_submittedBy" />} placement="bottom">
                      <RecordVoiceOverIcon style={iconStyles.all} />
                    </Tooltip>
                    <div>{_.get(item, 'submittedBy', unknown)}</div>
                  </span>
                  <span className="detailView_cell_type_4">
                    <Tooltip content={<FormattedMessage id="member_detail_comment" />} placement="bottom">
                      <ChatBubbleOutline style={iconStyles.all} />
                    </Tooltip>
                    <div>{item.body}</div>
                  </span>
                </div>
              )
            );
          })}
        </div>
      </div>
    </div>
  );
};

MemberCommentsInfo.displayName = 'MemberCommentsInfo';

MemberCommentsInfo.defaultProps = {
  comments: []
};

MemberCommentsInfo.propTypes = {
  comments: PropTypes.array
};

export default MemberCommentsInfo;
