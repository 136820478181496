import React, { Component } from 'react';
import ColumnList from '../../components/_v2/ColumnList';
import { connect } from 'react-redux';
import PeopleIcon from 'material-ui/svg-icons/social/people';
import EventNote from 'material-ui/svg-icons/notification/event-note';
import Filters from './ListFilters';

import { createMenuItems, resolveActive, resolveSelected, showNextView } from '../../components/_v2/ColumnList/helpers';
import { FormattedMessage } from 'react-intl';
import { getMsg } from '../../utils/IntlGlobalProvider';
import { createElement } from '../../utils/component';
import { getFormValues, hasValues, newProp, removeMultiSpace, safe } from '../../utils/utils';
import Fuse from 'fuse.js';
import { scheduledExportFrequencyOptions, scheduledExportTypeOptions } from '../../constants/options-constants';
import {
  addScheduledExportListFilters,
  editScheduledExportListFilters,
  resetScheduledExportListFilters
} from '../../actions/exports-actions';
import { headerCompanyListSelector, scheduledExportListFiltersSelector } from '../../selectors/all-selectors';

const fuseSearchKey = 'search';

const fuseOptions = {
  keys: [fuseSearchKey],
  minMatchCharLength: 2,
  threshold: 0.2
};

const getDatasetTypes = (childNodes, data) => {
  const { matches } = data;
  let indexes = { static: [] };

  for (let i = 0; i < matches.length; i++) {
    const { key, search, group } = matches[i].value;
    const node = childNodes[i];
    const item = group || key;
    if (node) {
      if (search) {
        if (!indexes[item]) indexes[item] = [];
        indexes[item].push(node);
      } else {
        indexes.static.push(node);
      }
    }
  }
  return indexes;
};

const addFuseResults = (results, fuse) => {
  for (let i = 0; i < fuse.length; i++) {
    results.push(fuse[i].item);
  }
};

const addSearchKey = (group, label) => ({
  [fuseSearchKey]: group + ' ' + label,
  label
});

const getCommonResults = (key, options) => {
  const group = getMsg(acLabels[key]);

  return options.map(({ value, labelKey }) => {
    return { key, value, ...addSearchKey(group, getMsg(labelKey)) };
  });
};

const getCompaniesResults = (key, list) => {
  const group = getMsg(acLabels[key]);

  return list.map(({ name, id }) => {
    return { key, value: id, ...addSearchKey(group, name) };
  });
};

const getTypeResults = () => {
  return getCommonResults('type', scheduledExportTypeOptions);
};

const getFreqResults = () => {
  return getCommonResults('frequency', scheduledExportFrequencyOptions);
};

const acLabels = {
  static: 'common_search_by',
  type: 'scheduled.export.type',
  frequency: 'common.frequency',
  companyIds: 'booking_detail_company'
};

const formatSearchSelect = (selection, input) => {
  const { key, value } = selection.value || {};

  if (value) {
    return { [key]: value };
  }

  return { [key]: input };
};

const appendDataset = (list, nodes, title, subTitle) => {
  const dataset = createElement('div', 'sc-dataset');
  const suggestions = createElement('div', 'sc-suggestions');

  if (title) {
    const header = createElement('div', 'sc-header');
    const titleEl = createElement('span', 'sc-title');

    titleEl.textContent = title;
    header.appendChild(titleEl);

    if (subTitle) {
      const subTitleEl = createElement('span', 'sc-value');
      subTitleEl.textContent = ' ' + subTitle;
      header.appendChild(subTitleEl);
    }

    dataset.appendChild(header);
  }

  suggestions.append(...nodes);
  dataset.appendChild(suggestions);
  list.appendChild(dataset);

  return dataset;
};

const filterItemMap = {
  companyIds: (array, item) => item.companies.find(company => array.includes(company.id)),
  frequency: (array, item) => array.includes(item.schedule.frequency),
  type: (array, item) => array.includes(item.content.resourceType)
};

const getFilterListPredicate = filters => {
  return item => {
    for (const prop in filters) {
      const array = filters[prop] || [];
      const passed = safe(() => filterItemMap[prop](array, item));
      if (!passed) return false;
    }
    return true;
  };
};

class ScheduledExportList extends Component {
  componentWillMount() {
    this.setCallbacks();
    this.setVars();
    this.initProps();
  }

  setVars() {
    this.menuItems = createMenuItems({ onAdd: this.handleAdd, addKey: 'scheduled.export.add' });
  }

  initProps() {
    this.componentPropsUpdated(this.props);
    this.propsInit = true;
  }

  componentWillReceiveProps(props) {
    this.componentPropsUpdated(props);
  }

  componentPropsUpdated(props) {
    const newLocale = newProp.call(this, props, 'locale');

    if (newLocale) {
      this.fuseFreq = new Fuse(getFreqResults(), fuseOptions);
      this.fuseType = new Fuse(getTypeResults(), fuseOptions);
    }

    if (newLocale || newProp.call(this, props, 'names')) {
      this.fusecompanyIds = new Fuse(getCompaniesResults('companyIds', props.names), {
        keys: [fuseSearchKey],
        includeMatches: true,
        threshold: 0.2
      });
    }
  }

  setCallbacks() {
    this.handleSelect = ({ id }, _, { openInNewTab }) => {
      showNextView.call(this, 'scheduledExportViewConfig', ':exportId', id, openInNewTab);
    };

    this.handleAdd = () => {
      showNextView.call(this, 'scheduledExportAddConfig');
    };

    this.renderItem = ({ description, companies, schedule, content }) => {
      const cNames = companies.map(c => c['name']).join(', ');
      return (
        <div className="sc-list-item">
          <div className="svg-icon">{content.resourceType === 'MEMBER_PROFILE' ? <PeopleIcon /> : <EventNote />}</div>
          <div className="item-content">
            <div className="item-title">{cNames}</div>
            <div className="item-desc">
              {getMsg('common_' + schedule.frequency.toLowerCase())}
              {' (' + description}
            </div>
          </div>
        </div>
      );
    };

    this.getAcList = (list, data) => {
      const { static: regex, ...types } = getDatasetTypes(list.childNodes, data);
      const labelPrefix = getMsg(acLabels.static);
      for (const type in types) {
        const subTitle = `(${getMsg(acLabels[type])})`;
        appendDataset(list, types[type], labelPrefix, subTitle);
      }

      if (regex.length) {
        appendDataset(list, regex, labelPrefix);
      }
    };

    this.getAcResult = (item, { value: data }) => {
      const { key, label } = data || {};
      const children = [];
      const labelKey = acLabels[key];
      const result = createElement('span', 'sc-label');

      result.innerHTML = label ? label : getMsg(labelKey);
      children.push(result);

      item.replaceChildren(...children);
    };

    this.getAcData = query => {
      return new Promise(resolve => {
        const results = [];

        query = removeMultiSpace(query);
        if (!query) return resolve(results);

        addFuseResults(results, this.fuseFreq.search(query));
        addFuseResults(results, this.fuseType.search(query));
        addFuseResults(results, this.fusecompanyIds.search(query));

        resolve(results);
      });
    };

    this.getFilters = () => {
      const { filters } = this.props;
      return <Filters initialValues={filters} />;
    };

    this.resolveSelected = resolveSelected.bind(this);
    this.resolveActive = resolveActive.bind(this);

    this.handleSearchSelect = (event = {}) => {
      const { query = '', selection = {} } = event.detail || {};
      const data = formatSearchSelect(selection, removeMultiSpace(query));
      this.props.dispatch(addScheduledExportListFilters(data));
    };

    this.handleFiltersReset = () => {
      this.props.dispatch(resetScheduledExportListFilters());
    };

    this.handleFiltersApply = () => {
      let values = getFormValues('scheduledExportListFilters');
      if (!hasValues(values)) values = undefined;
      this.props.dispatch(editScheduledExportListFilters(values));
    };

    this.getItems = () => {
      const { filters, list } = this.props;
      return filters ? list.filter(getFilterListPredicate(filters)) : list;
    };
  }

  render() {
    return (
      <ColumnList
        title={<FormattedMessage id="scheduled.export.list" />}
        inputRef={this.props.inputRef}
        items={this.getItems()}
        renderItem={this.renderItem}
        onSelect={this.handleSelect}
        selected={this.resolveSelected}
        active={this.resolveActive}
        menuItems={this.menuItems}
        filtersTitle={<FormattedMessage id="scheduled.export.list" />}
        filters={this.getFilters()}
        filtersActive={!!this.props.filters}
        onFiltersApply={this.handleFiltersApply}
        onFiltersReset={this.handleFiltersReset}
        autoCompleteEnabled
        autoCompleteData={this.getAcData}
        autoCompleteList={this.getAcList}
        autoCompleteResult={this.getAcResult}
        autoCompleteOnSelect={this.handleSearchSelect}
      />
    );
  }
}

const mapStateToProps = state => {
  return {
    list: state.exports.scheduledExportList,
    filters: scheduledExportListFiltersSelector(state),
    names: headerCompanyListSelector(state)
  };
};

ScheduledExportList = connect(mapStateToProps)(ScheduledExportList);

export default ScheduledExportList;
