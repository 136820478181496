// This component has been made to handle a customField

import { compose, mapProps } from 'recompose';
import { connect } from 'react-redux';
import _get from 'lodash/get';
import _find from 'lodash/find';

import { localeSelector } from '../../selectors/all-selectors';
import FormField from '../FormField';
import {
  CUSTOM_FIELD_TYPE_TEXT,
  CUSTOM_FIELD_TYPE_NUMERIC,
  CUSTOM_FIELD_TYPE_BOOLEAN,
  CUSTOM_FIELD_TYPE_PHONE_NUMBER,
  CUSTOM_FIELD_TYPE_FILE
} from '../../constants/backend-constants';

import { booleanOptions } from '../../constants/options-constants';
import { CUSTOM_FIELD_YES } from '../../constants/backend-constants';

const typeMap = {
  [CUSTOM_FIELD_TYPE_TEXT]: 'text',
  [CUSTOM_FIELD_TYPE_NUMERIC]: 'number',
  [CUSTOM_FIELD_TYPE_BOOLEAN]: 'radio',
  [CUSTOM_FIELD_TYPE_PHONE_NUMBER]: 'text',
  [CUSTOM_FIELD_TYPE_FILE]: 'file'
};

const CustomFieldBooking = compose(
  connect(state => ({
    locale: localeSelector(state)
  })),
  mapProps(({ fieldInfos, locale, ...props }) => {
    const type = typeMap[fieldInfos.fieldType];
    const additionalProps = {};
    const label = _get(_find(fieldInfos.companyCustomFieldLabels, label => label.language === locale), 'label') || _get(fieldInfos, 'name');

    if (type === 'radio') {
      additionalProps.namespace = fieldInfos.id;
      additionalProps.items = booleanOptions;
    }

    if (type === 'file') {
      additionalProps.id = fieldInfos.id;
      additionalProps.buttonLabel = 'subscribeForm_uploadLicence_label';
    }

    return {
      label,
      type,
      mandatory: fieldInfos.mandatory === CUSTOM_FIELD_YES,
      customLabelClass: 'boxedInput_labelText',
      ...additionalProps,
      ...props
    };
  })
)(FormField);

CustomFieldBooking.displayName = 'CustomFieldBooking';

export default CustomFieldBooking;
