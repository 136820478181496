import React, { Component } from 'react';
import { connect } from 'react-redux';

import VehicleForm from '../../components/VehicleForm/VehicleForm';
import { getParkingsList, requestVehicleCreation } from '../../actions/all-actions';
import { VEHICLES_CLEAR_CURRENT } from '../../constants/actionTypes-constants';
import { headerContractVehicleValuesSelector, categoriesListSelector, sitesListSelector } from '../../selectors/all-selectors';
import { isValidId, safe } from '../../utils/utils';

class AddVehicle extends Component {
  constructor(props) {
    super(props);
    this.handleAddVehicle = this.handleAddVehicle.bind(this);
  }

  componentWillMount() {
    this.props.dispatch({ type: VEHICLES_CLEAR_CURRENT });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.sitesList !== this.props.sitesList) {
      if (isValidId(this.props.vehicleCopy.siteLocation)) this.props.dispatch(getParkingsList(this.props.vehicleCopy.siteLocation, false));
      else this.props.dispatch(getParkingsList(this.props.sitesList[0].id, false));
    }
  }

  handleAddVehicle() {
    const { dispatch } = this.props;
    dispatch(requestVehicleCreation({ company: this.props.currentCompany }));
  }

  getDatas() {
    const { vehicleCopy, categoriesList, vehicleRules } = this.props;

    if (!vehicleRules.vehicleCategory) {
      return { vehicleClass: safe(() => categoriesList[0].id), ...vehicleCopy };
    } else {
      return { vehicleRules, ...vehicleCopy };
    }
  }

  render() {
    return (
      <div className="addVehiclePage mainContainer_content">
        <div className="pageContainer">
          <VehicleForm onCallback={this.handleAddVehicle} editMode={false} datas={this.getDatas()} />
        </div>
      </div>
    );
  }
}

AddVehicle.displayName = 'AddVehicle';

export default connect(state => {
  const {
    companies: { currentCompany },
    vehicles: { vehicleCopy }
  } = state;
  return {
    vehicleRules: headerContractVehicleValuesSelector(state),
    categoriesList: categoriesListSelector(state),
    currentCompany,
    sitesList: sitesListSelector(state),
    vehicleCopy
  };
})(AddVehicle);
