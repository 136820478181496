import React from 'react';

import KeyboardArrowDown from 'material-ui/svg-icons/hardware/keyboard-arrow-down';
import KeyboardArrowUp from 'material-ui/svg-icons/hardware/keyboard-arrow-up';
import { FlatButton } from 'material-ui';

class VehiclePlanningCollapsible extends React.Component {
  constructor() {
    super();
    this.state = { isOpen: true };
    this.handleOpen = this.handleOpen.bind(this);
  }

  handleOpen() {
    this.setState(prevState => ({
      isOpen: !prevState.isOpen
    }));
  }

  render() {
    const { title, children } = this.props;
    return (
      <div>
        <div className="collapsible">
          <div className="panel-handle">
            <FlatButton
              labelPosition="after"
              className="btn"
              onClick={this.handleOpen}
              label={title}
              labelStyle={{ paddingLeft: '0', textTransform: 'initial' }}
              style={{ width: '100%', textAlign: 'left', textTransform: 'none' }}
              icon={
                !this.state.isOpen ? (
                  <KeyboardArrowDown style={{ marginLeft: '0', width: '26px', height: '26px' }} />
                ) : (
                  <KeyboardArrowUp style={{ marginLeft: '0', width: '26px', height: '26px' }} />
                )
              }
            />
          </div>

          <div className="panel-content">
            <div>{this.state.isOpen && <div className="parkings">{children}</div>}</div>
          </div>
        </div>
      </div>
    );
  }
}

export default VehiclePlanningCollapsible;
