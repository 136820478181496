/* eslint-disable react/no-did-mount-set-state */
import React, { Component, PropTypes as T } from 'react';
import { connect } from 'react-redux';
import _get from 'lodash/get';
import _partial from 'lodash/partial';
import _times from 'lodash/times';
import _omit from 'lodash/omit';
import _debounce from 'lodash/debounce';
import _upperFirst from 'lodash/upperFirst';
import moment from 'moment';
import { FormattedMessage, injectIntl } from 'react-intl';
import ReactSVG from 'react-svg';
import Badge from 'material-ui/Badge';
import Tooltip from '../../../components/Tooltip/Tooltip';

import VehiclePlanningSetup from './components/VehiclePlanningSetup';
import {
  selectFirstDayForCreatingBooking,
  selectSecondDayForCreatingBooking,
  toggleCreateBookingMemberSelect,
  selectFirstHourForCreatingBooking,
  selectSecondHourForCreatingBooking,
  saveCreateStatusData,
  clearCreateBookingModal,
  unselectVehicle,
  getVehicleDetail,
  vehiclePlanningClearData,
  changeVehiclePlanningSetings,
  getDataVehiclePlanning,
  resetFilters,
  addFilter,
  removeFilter
} from '../../../actions/all-actions';
import { headerCompanyContractSelector } from '../../../selectors/all-selectors';
import Loader from '../../../components/MaterialLoader/MaterialLoader';
import TimeMarker from './components/TimeMarker';
import VehiclePlanningBooking from './components/VehiclePlanningBooking';
import VehiclePlanningCreateBooking from './components/VehiclePlanningCreateBooking';
import VehiclePlanningMemberSelect from './components/VehiclePlanningMemberSelect';
import VehiclePlanningCreateStatus from './components/VehiclePlanningCreateStatus';
import VehiclePlanningOpeningHours from './components/VehiclePlanningOpeningHours';
import VehiclePlanningExtendShortenBooking from './components/VehiclePlanningExtendShortenBooking';
import VehiclePlanningEditBooking from './components/VehiclePlanningEditBooking';
import VehiclePlanningChangeVehicle from './components/VehiclePlanningChangeVehicle';
import VehiclePlanningMemberChange from './components/VehiclePlanningMemberChange';
import VehiclePlanningStatus from './components/VehiclePlanningStatus';
import VehiclePlanningLegend from './components/VehiclePlanningLegend';
import VehiclePlanningVehicleTile from './components/VehiclePlanningVehicleTile';
import VehiclePlanningCollapsible from './components/VehiclePlanningCollapsible';
import { vehiclePlanningEditRules } from '../../../constants/routes-constants';
import Fuse from 'fuse.js';

import classnames from 'classnames';
import { ALL, OS_WINDOWS } from '../../../constants/generic-constants';
import { VEHICLE_USAGE_TYPE_NO_USAGE } from '../../../constants/backend-constants';
import { checkRole } from '../../../constants/backuser-role-rules';
import { getAppDateFormatVP, safe, OS } from '../../../utils/utils';
import KeyboardEventHandler from 'react-keyboard-event-handler';
import FilterChips from '../../../components/FilterChips/FilterChips';
import { getMsg } from '../../../utils/IntlGlobalProvider';
import { vehiclePlanningFilterChips } from '../../../constants/options-constants';
import { createVpFilterTypes } from '../../../constants/filterTypes-constants';
import FilterIcon from 'material-ui/svg-icons/content/filter-list';

const now = new Date();

class VehiclePlanningV2 extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.setReadOnly(props);
    this.setCallbacks();
    this.componentPropsUpdated(this.props, true);
  }

  setCallbacks() {
    this.handleEventsKeyboardsAction = this.handleEventsKeyboardsAction.bind(this);
    this.handleSelectBookingToCreate = this.handleSelectBookingToCreate.bind(this);
    this.handleSelectBookingHourToCreate = this.handleSelectBookingHourToCreate.bind(this);
    this.filtersChips = this.filtersChips.bind(this);
    this.chips = this.chips.bind(this);
    this.removeChip = this.removeChip.bind(this);

    this.fuseSearch = _debounce((rawInput, data) => {
      const input = safe(() => rawInput.trim());
      const { items } = this.state;
      if (input) {
        const result = this.fuse.search(input);
        this.setState({ items: result, input });
      } else if (items) {
        this.setState({ items: null, input: null });
      }
      if (data) this.props.dispatch(addFilter(data));
    }, 300);
  }

  componentPropsUpdated(props, init) {
    this.updateItems(props, init);
  }

  itemsUpdated(props, init) {
    return init || props.data !== this.props.data;
  }

  updateItems(props, init) {
    if (this.itemsUpdated(props, init)) {
      this.fuse = new Fuse(props.data.sites, {
        keys: ['parkings.vehicles.registrationNumber', 'parkings.vehicles.fuelType', 'parkings.vehicles.usage'],
        includeMatches: true,
        threshold: 0.2
      });
      this.fuseSearch(this.state.input);
    }
  }

  setReadOnly(props) {
    const { role } = props;
    this.readOnly = !checkRole(vehiclePlanningEditRules, role);
  }

  componentWillReceiveProps(np) {
    this.componentPropsUpdated(np);
  }

  componentWillUnmount() {
    this.props.dispatch(vehiclePlanningClearData());
  }

  handleSelectBookingToCreate(value) {
    const { firstDaySelected, dispatch, periodInDays, createStatusMode, startDate } = this.props;
    const dayIndex = value[0];
    const vehicle = value[1];
    const parking = value[2];
    const siteId = value[3];
    if (periodInDays === 1) return;

    if (createStatusMode) {
      dispatch(saveCreateStatusData(dayIndex, vehicle));
      dispatch(getVehicleDetail(vehicle.id));
      dispatch(clearCreateBookingModal());
      return;
    }

    if (this.readOnly) return;
    if (Object.keys(firstDaySelected).length > 0) {
      if (firstDaySelected.vehicle.id !== vehicle.id) {
        dispatch(unselectVehicle());
        dispatch(selectFirstDayForCreatingBooking(dayIndex, vehicle, parking, siteId));
      }
      if (firstDaySelected.vehicle.id === vehicle.id && firstDaySelected.value <= dayIndex) {
        dispatch(selectSecondDayForCreatingBooking(dayIndex, vehicle, parking, siteId));
        dispatch(toggleCreateBookingMemberSelect());
      }
    } else {
      if (
        periodInDays !== 1 &&
        !moment(
          moment(startDate)
            .add(dayIndex + 1, 'days')
            .format()
        ).isSameOrBefore(now, 'day')
      ) {
        dispatch(selectFirstDayForCreatingBooking(dayIndex, vehicle, parking, siteId));
      }
    }
  }

  handleSelectBookingHourToCreate(value) {
    const hourIndex = value[0];
    const vehicle = value[1];
    const parking = value[2];
    const siteId = value[3];
    const { firstHourSelected, dispatch, createStatusMode, startDate } = this.props;

    if (createStatusMode) {
      dispatch(saveCreateStatusData(hourIndex, vehicle));
      dispatch(clearCreateBookingModal());
      return;
    }

    if (
      this.readOnly ||
      moment(
        moment(startDate)
          .add(hourIndex + 1, 'hours')
          .format()
      ).isSameOrBefore(now, 'hours')
    )
      return;
    if (Object.keys(firstHourSelected).length > 0) {
      if (firstHourSelected.vehicle.id !== vehicle.id) {
        dispatch(unselectVehicle());
        dispatch(selectFirstHourForCreatingBooking(hourIndex, vehicle, parking, siteId));
      }

      if (firstHourSelected.vehicle.id === value[1].id && firstHourSelected.value <= value[0]) {
        dispatch(selectSecondHourForCreatingBooking(hourIndex, vehicle, parking, siteId));
        dispatch(toggleCreateBookingMemberSelect());
      }
    } else {
      dispatch(selectFirstHourForCreatingBooking(hourIndex, vehicle, parking, siteId));
    }
  }

  displayDate(value) {
    const { periodInDays, startDate } = this.props;
    const date =
      periodInDays === 1
        ? moment(startDate)
            .add(value, 'd')
            .format(getAppDateFormatVP())
        : moment(startDate)
            .add(value, 'd')
            .format('dddd[\n]DD/MM');
    return _upperFirst(date.toLowerCase());
  }

  getItems() {
    const predicate = (data, index) => this.getSitesWrap(data.item, index, data.matches);
    const sorted = () => safe(() => this.state.items.map(predicate));
    const regular = () => safe(() => this.props.data.sites.map((item, i) => this.getSitesWrap(item, i)));
    return sorted() || regular();
  }

  getSitesWrap(site, siteIndex, matches = []) {
    const {
      vehiclesUsagesFiltered,
      vehiclesUsagesInit,
      firstDaySelected,
      firstHourSelected,
      createStatusMode,
      startDate,
      periodInDays,
      filters
    } = this.props;

    let values = matches.map(i => i && i.value);
    const hasSiteFilt = filters.sites.length === 0 || (filters.sites.length > 0 && filters.sites.includes(site.id));

    let _parkings = site.parkings.reduce((results, item) => {
      if (hasFuels(item.vehicles, filters.fuels) && hasUsages(item.vehicles, filters.usages) && hasPlateNumber(values, item.vehicles)) {
        results.push(item);
      }
      return results;
    }, []);

    const hasPlateOnes =
      values.length === 0 ||
      (values.length > 0 && _parkings.some(parking => parking.vehicles.some(v => values.includes(v.registrationNumber))));

    const hasFuelOnes =
      filters.fuels.length === 0 || _parkings.some(parking => parking.vehicles.some(v => filters.fuels.includes(v.fuelType)));

    const hasUsageOnes =
      filters.usages.length === 0 || _parkings.some(parking => parking.vehicles.some(v => filters.usages.includes(v.usage)));

    const isHidden = currentValue => currentValue.visible === false && currentValue.usage !== VEHICLE_USAGE_TYPE_NO_USAGE;

    function hasFuels(vehicles, fuelVals) {
      if (fuelVals.length > 0) {
        return vehicles.some(v => fuelVals.includes(v.fuelType));
      }
      return vehicles;
    }

    function hasUsages(vs, usageVals) {
      if (usageVals.length > 0) {
        return vs.some(v => usageVals.includes(v.usage));
      }
      return vs;
    }

    function hasPlateNumber(values, vehicles) {
      if (values.length > 0) {
        return vehicles.some(v => values.includes(v.registrationNumber));
      }
      return vehicles;
    }

    if (hasFuelOnes && hasUsageOnes && hasPlateOnes && hasSiteFilt)
      return (
        <div key={site.id + '--' + siteIndex + 'end'} className={classnames('new-vehicle-planning_site')}>
          <VehiclePlanningCollapsible
            title={
              <span>
                {site.name} <small> ({site.zoneId})</small>
              </span>
            }
            key={site.id + '_' + siteIndex}
          >
            {_parkings.length > 0 &&
              _parkings.map(parking => {
                if (parking.vehicles.some(v => vehiclesUsagesFiltered.includes(v.usage) || v.usage === VEHICLE_USAGE_TYPE_NO_USAGE)) {
                  const countFiltVehicles = parking.vehicles.filter(isHidden);
                  const vehicles = parking.vehicles.reduce((r, v) => {
                    if (
                      (filters.fuels.length === 0 || (filters.fuels.length > 0 && filters.fuels.includes(v.fuelType))) &&
                      (filters.usages.length === 0 || (filters.usages.length > 0 && filters.usages.includes(v.usage))) &&
                      (filters.registrationNumber === null || (values.length > 0 && values.includes(v.registrationNumber)))
                    ) {
                      r.push(v);
                    }
                    return r;
                  }, []);
                  return (
                    <div className={classnames('new-vehicle-planning_parking')} key={parking.id}>
                      <div
                        className="new-vehicle-planning_parking_name"
                        style={{
                          bottom: parking.vehicles.length === 1 ? '0' : (parking.vehicles.length * 80) / 2
                        }}
                      >
                        <ReactSVG src="/img/parking-icon.svg" svgClassName="icon" className="icon-parking" /> {parking.name}
                      </div>
                      {vehicles.map(vehicle => {
                        let sortedStatuses = vehicle.statuses.sort((a, b) => {
                          return new Date(b.date) > new Date(a.date) ? -1 : 1;
                        });

                        if (!vehicle.hasOwnProperty('visible') || vehicle.visible || vehicle.usage === VEHICLE_USAGE_TYPE_NO_USAGE) {
                          return (
                            <div className={classnames('new-vehicle-planning_vehicleRow')} key={vehicle.id}>
                              <div className="new-vehicle-planning_vehicleRow_firstField">
                                <VehiclePlanningVehicleTile
                                  vehicle={vehicle}
                                  usageLeft
                                  displayUsages={vehiclesUsagesInit.length > 1}
                                  fuelLevelAsText
                                />
                              </div>
                              {_times(periodInDays, value => {
                                const condition =
                                  _get(firstDaySelected, 'value') === value && _get(firstDaySelected, 'vehicle.id') === vehicle.id;
                                return (
                                  <div
                                    key={value}
                                    onClick={_partial(this.handleSelectBookingToCreate, [
                                      value,
                                      vehicle,
                                      _omit(parking, 'vehicles'),
                                      site.id
                                    ])}
                                    className={classnames('new-vehicle-planning_vehicleRow_field', {
                                      mutlidays: periodInDays !== 1,
                                      'new-vehicle-planning_bookingMode_selectedDay': condition,
                                      'new-vehicle-planning_bookingMode':
                                        _get(firstDaySelected, 'vehicle.id') === vehicle.id && _get(firstDaySelected, 'value') <= value
                                    })}
                                  >
                                    {periodInDays === 1 &&
                                      _times(24, value => {
                                        const condition =
                                          _get(firstHourSelected, 'value') === value &&
                                          _get(firstHourSelected, 'vehicle.id') === vehicle.id;
                                        return (
                                          <div
                                            key={value}
                                            className={classnames(
                                              'new-vehicle-planning_vehicleRow_hourField',
                                              condition ? 'new-vehicle-planning_bookingMode_selectedDay' : '',
                                              _get(firstHourSelected, 'vehicle.id') === vehicle.id &&
                                                _get(firstHourSelected, 'value') <= value
                                                ? 'new-vehicle-planning_bookingMode'
                                                : '',
                                              createStatusMode ? 'new-vehicle-planning_bookingMode' : ''
                                            )}
                                            onClick={_partial(this.handleSelectBookingHourToCreate, [
                                              value,
                                              vehicle,
                                              _omit(parking, 'vehicles'),
                                              site.id
                                            ])}
                                          />
                                        );
                                      })}
                                  </div>
                                );
                              })}
                              {vehicle.bookings.map(booking => (
                                <VehiclePlanningBooking
                                  booking={booking}
                                  reservationBuffer={site.reservationBuffer}
                                  key={booking.id}
                                  vehicle={vehicle}
                                  readOnly={this.readOnly}
                                />
                              ))}
                              {sortedStatuses.map((status, statusIndex) => {
                                return (
                                  <VehiclePlanningStatus
                                    status={status}
                                    key={'status-' + vehicle.id + '-' + statusIndex}
                                    vehicle={vehicle}
                                    statusIndex={statusIndex}
                                  />
                                );
                              })}

                              {_get(parking, 'schedule.recurringSlots', false) && (
                                <div>
                                  {parking.schedule.recurringSlots.map((parkingOpenedSlots, i) => (
                                    <VehiclePlanningOpeningHours
                                      open={false}
                                      key={parkingOpenedSlots.id + '-slots-' + i + siteIndex + 'park' + parking.id}
                                      id={'closed-streams-' + parkingOpenedSlots.id + i + siteIndex + parking.id}
                                      parkingOpenedSlots={parkingOpenedSlots}
                                      startDate={startDate}
                                      parkingVehicles={parking}
                                    />
                                  ))}
                                </div>
                              )}
                            </div>
                          );
                        }
                      })}

                      {countFiltVehicles.length !== 0 && (
                        <div className="show-filtered-actions">
                          <button name="reset-filters" className="flatButton" onClick={() => this.props.dispatch(resetFilters())}>
                            <FormattedMessage
                              id="vehiclePlanning_filtered_vehicles_count"
                              values={{ number: countFiltVehicles.length, total: parking.vehicles.length }}
                            />
                          </button>
                        </div>
                      )}
                    </div>
                  );
                }
                if (!parking.vehicles.some(v => vehiclesUsagesFiltered.includes(v.usage) || v.usage === VEHICLE_USAGE_TYPE_NO_USAGE)) {
                  return (
                    <div className="show-filtered-actions">
                      <button name="reset-filters" className="flatButton" onClick={() => this.props.dispatch(resetFilters())}>
                        <FormattedMessage
                          id="vehiclePlanning_filtered_vehicles_count"
                          values={{ number: parking.vehicles.filter(isHidden).length, total: parking.vehicles.length }}
                        />
                      </button>
                    </div>
                  );
                }
              })}
          </VehiclePlanningCollapsible>
        </div>
      );
  }

  removeChip(item, key) {
    this.props.dispatch(removeFilter(item, key));

    if (this.props.filters.registrationNumber && this.state.items) {
      this.setState({ input: null });
    } else {
      this.setState({ input: null, items: null });
    }
  }

  chips() {
    const { filters, data } = this.props;
    const allSites = data.sites.map(item => ({ value: item.id, name: item.name }));
    const filtersType = createVpFilterTypes(allSites);

    if (filters.usages.length > 0 || filters.sites.length > 0 || filters.fuels.length > 0 || filters.registrationNumber !== null) {
      return (
        <FilterChips
          id="bookings-chips"
          chipClass="v2-filter-chip"
          hasArrayValues
          urlParams={filters}
          onDeleteChip={this.removeChip}
          translations={vehiclePlanningFilterChips}
          filterTypes={filtersType}
        />
      );
    }
  }

  filtersChips() {
    const { filters } = this.props;
    const badgeRootCss = { padding: '5px 20px 5px 12px' };
    const badgeCss = { background: '#eaeaea', color: 'black', width: '20px', height: '20px' };
    let res = [];

    if (filters.usages.length > 0) {
      res.push(
        <Badge
          style={badgeRootCss}
          badgeStyle={badgeCss}
          badgeContent={filters.usages.length}
          key="usg"
          children={getMsg('vehiclePlanning_filters_usages')}
        />
      );
    }
    if (filters.sites.length > 0) {
      res.push(
        <Badge
          key="sit"
          style={badgeRootCss}
          badgeStyle={badgeCss}
          badgeContent={filters.sites.length}
          children={getMsg('vehiclePlanning_filters_sites')}
        />
      );
    }
    if (filters.fuels.length > 0) {
      res.push(
        <Badge
          key="fue"
          style={badgeRootCss}
          badgeStyle={badgeCss}
          badgeContent={filters.fuels.length}
          children={getMsg('vehiclePlanning_filters_fuels')}
        />
      );
    }
    if (filters.registrationNumber !== null) {
      res.push(
        <Tooltip key="reg" content={<FormattedMessage id="vehiclesFiltersForm_plateNumber" />} placement="keft">
          <span className="reg-num">{filters.registrationNumber}</span>
        </Tooltip>
      );
    }

    return (
      <div className="filters">
        <div className="count">
          {res.length > 0 && <FilterIcon />}
          {res.map(i => i)}
        </div>
        <div className="chips">{this.chips()}</div>
      </div>
    );
  }

  handleEventsKeyboardsAction(key, e) {
    const { dispatch, periodInDays, startDate, subCompanySelected } = this.props;
    let params = {};
    if (key === '1' || key === '3' || key === '7') {
      params = {
        periodInDays: Number(key),
        startDate: moment(startDate).toISOString(),
        subCompanyId: subCompanySelected.id
      };
    }
    if (key === 'n' || key === 'right') {
      params = {
        periodInDays,
        startDate: moment(startDate)
          .add(periodInDays, 'days')
          .toISOString(),
        subCompanyId: subCompanySelected.id
      };
    }
    if (key === 'p' || key === 'left') {
      params = {
        periodInDays,
        startDate: moment(startDate)
          .subtract(periodInDays, 'days')
          .toISOString(),
        subCompanyId: subCompanySelected.id
      };
    }
    dispatch(changeVehiclePlanningSetings(params));
    dispatch(getDataVehiclePlanning(params));
  }

  render() {
    const {
      startDate,
      periodInDays,
      subCompanySelected,
      currentCompany,
      loading,
      data,
      legendShown,
      loadingError,
      openCreateBookingModal,
      openMemberSelectModal,
      openCreateStatusModal,
      openShortenExtendBooking,
      openShortenExtendBookingHover,
      openedEditBookingModal,
      openedChangeVehicleModal,
      openedMemberChangeModal,
      contract,
      sideMenuOpen
    } = this.props;

    const isWindows = OS(window) === OS_WINDOWS;
    const companySelected = _get(currentCompany, 'id', ALL);
    const _subCompanySelected = _get(subCompanySelected, 'id', ALL);

    const companyNotSelected = companySelected === ALL;
    const subCompanyNotSelected = (_subCompanySelected || ALL) === ALL;

    const noErrorBothSelected = !companyNotSelected && !subCompanyNotSelected && !loadingError;

    if (companyNotSelected || subCompanyNotSelected) {
      return (
        <div className="mainContainer_content">
          <div className="pageContainer">
            <div className="page-info-block">
              <FormattedMessage
                id={_get(currentCompany, 'id', ALL) === ALL ? 'vehiclePlanning_selectSuperCompany' : 'vehiclePlanning_selectCompany'}
              />
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className="new-vehicle-planning mainContainer_content">
        <KeyboardEventHandler handleKeys={['1', '3', '7', 'n', 'right', 'p', 'left']} onKeyEvent={this.handleEventsKeyboardsAction} />

        <div className="pageContainer">
          {loadingError && (
            <div className={classnames('new-vehicle-planning_container error', { 'menu-opened': sideMenuOpen })}>
              <FormattedMessage id="error.vehiclePlannng" />
            </div>
          )}
          {loading && !loadingError && (
            <div className={classnames('new-vehicle-planning_loadingOverlay', { 'menu-opened': sideMenuOpen })}>
              <Loader />
            </div>
          )}
          {noErrorBothSelected && <VehiclePlanningSetup readOnly={this.readOnly} onSelectCallback={this.fuseSearch} />}

          {noErrorBothSelected && <VehiclePlanningLegend contract={contract} visible={legendShown} />}

          {(companyNotSelected || subCompanyNotSelected) && (
            <div className="page-info-block">
              <FormattedMessage
                id={_get(currentCompany, 'id', ALL) === ALL ? 'vehiclePlanning_selectSuperCompany' : 'vehiclePlanning_selectCompany'}
              />
            </div>
          )}
          {noErrorBothSelected && (
            <div className={classnames('new-vehicle-planning_container', { 'legend-visible': legendShown })}>
              <TimeMarker
                startDate={startDate}
                period={periodInDays}
                isMenuOpen={sideMenuOpen}
                isWindowsOs={isWindows}
                legendShown={legendShown}
              />
              <div className={classnames('new-vehicle-planning_firstRow', { 'new-vehicle-planning_firstRow_sideMenuOpen': sideMenuOpen })}>
                <div className="new-vehicle-planning_firstRow_firstField">{this.filtersChips()}</div>

                {/*First row with dates*/}

                {_times(periodInDays, value => {
                  return (
                    <div key={'period' + value} className="new-vehicle-planning_firstRow_field">
                      <div
                        className={classnames('new-vehicle-planning_firstRow_day', { multidays: periodInDays !== 1 })}
                        style={{ whiteSpace: 'pre' }}
                      >
                        {this.displayDate(value)}
                      </div>
                      <div
                        className={classnames('new-vehicle-planning_firstRow_hours', {
                          'extra-padding': isWindows,
                          multidays: periodInDays !== 1
                        })}
                      >
                        {(periodInDays === 1 || periodInDays === 3) &&
                          _times(24, hour => (
                            <div className={`new-vehicle-planning_firstRow_hour`} key={'h' + hour} title={hour + 'h'}>
                              {periodInDays === 1 && hour + 'h '}
                              {/* {periodInDays === 3 && hour} */}
                            </div>
                          ))}
                      </div>
                    </div>
                  );
                })}
              </div>
              {/* <VehiclePlanningCard
                readOnly={this.readOnly}
                opened={openedCard}
                booking={selectedBooking}
                vehicle={selectedVehicle}
                member={detailMember}
              /> */}

              {/*Rows with cars and individual bookings*/}
              {this.getItems()}

              {openCreateBookingModal && <VehiclePlanningCreateBooking />}
              {openMemberSelectModal && <VehiclePlanningMemberSelect />}
              {openCreateStatusModal && <VehiclePlanningCreateStatus />}
              {openShortenExtendBookingHover && <VehiclePlanningExtendShortenBooking hideCard />}
              {openShortenExtendBooking && <VehiclePlanningExtendShortenBooking />}
              {openedEditBookingModal && <VehiclePlanningEditBooking />}
              {openedChangeVehicleModal && <VehiclePlanningChangeVehicle />}
              {openedMemberChangeModal && <VehiclePlanningMemberChange refreshPlanning />}
              {data.sites.length === 0 && (
                <div className="vehiclePlanning_noResults">
                  <FormattedMessage id="common_no_results" />
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
}

VehiclePlanningV2.displayName = 'VehiclePlanningV2';

VehiclePlanningV2.propTypes = {
  startDate: T.string,
  periodInDays: T.number,
  siteId: T.string,
  subCompanySelected: T.object,
  loading: T.bool,
  data: T.object,
  openCreateStatusModal: T.bool
};

export default connect(state => {
  const {
    vehiclePlanning: {
      startDate,
      periodInDays,
      siteId,
      loading,
      data,
      openedCard,
      selectedBooking,
      selectedVehicle,
      createBookingMode,
      firstDaySelected,
      secondDaySelected,
      firstHourSelected,
      secondHourSelected,
      openMemberSelectModal,
      openCreateBookingModal,
      createStatusMode,
      openCreateStatusModal,
      openShortenExtendBooking,
      openShortenExtendBookingHover,
      openedEditBookingModal,
      openedChangeVehicleModal,
      openedMemberChangeModal,
      vehiclesUsagesFiltered,
      vehiclesUsagesInit,
      legendShown,
      loadingError,
      filters
    },
    subCompanies: { subCompanySelected },
    companies: { currentCompany },
    members: { detailMember },
    user: {
      userInfo: { role }
    },
    sideMenu: { isOpen }
  } = state;

  return {
    startDate,
    periodInDays,
    siteId,
    subCompanySelected,
    currentCompany,
    loading,
    data,
    openedCard,
    selectedBooking,
    selectedVehicle,
    createBookingMode,
    firstDaySelected,
    secondDaySelected,
    openMemberSelectModal,
    openCreateBookingModal,
    firstHourSelected,
    secondHourSelected,
    createStatusMode,
    openCreateStatusModal,
    role,
    vehiclesUsagesInit,
    openShortenExtendBookingHover,
    openShortenExtendBooking,
    openedEditBookingModal,
    openedChangeVehicleModal,
    openedMemberChangeModal,
    vehiclesUsagesFiltered,
    sideMenuOpen: isOpen,
    detailMember,
    legendShown,
    loadingError,
    filters,
    contract: headerCompanyContractSelector(state)
  };
})(injectIntl(VehiclePlanningV2));
