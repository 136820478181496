import React, { Component, PropTypes } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { reduxForm } from 'redux-form';
import _partial from 'lodash/partial';
import _get from 'lodash/get';

import FlatButton from 'material-ui/FlatButton';
import EkButton from '../../../components/EkButton/EkButton';
import BoxedInput from '../../../components/BoxedInput/BoxedInput';
import EkRadio from '../../../components/EkRadio/EkRadio';
import FieldErrorMsg from '../../../components/FieldErrorMsg/FieldErrorMsg';
import IconButton from 'material-ui/IconButton';
import AddIcon from 'material-ui/svg-icons/content/add';
import { voucherCodeTypes } from '../../../constants/options-constants';
import { namedCompose } from '../../../utils/utils';
import { createValidator, integer, notEmpty, number, stopValidationIf } from '../../../validation/sync-validation';

class CreateVoucherCodeForm extends Component {
  constructor() {
    super();
    this.state = {
      voucherCodes: [''],
      errorVoucherCodes: [false]
    };
    this.handleAddField = this.handleAddField.bind(this);
    this.updateInput = this.updateInput.bind(this);
    this.handleSubmitIndividual = this.handleSubmitIndividual.bind(this);
  }

  handleAddField() {
    this.setState({ voucherCodes: [...this.state.voucherCodes, ''] }); // eslint-disable-line
  }

  updateInput(index, value) {
    let { voucherCodes, errorVoucherCodes } = this.state;
    voucherCodes[index] = value;

    if (!/^[A-Z0-9]*$/.test(value)) {
      errorVoucherCodes[index] = <FormattedMessage id="validation_numbers_capitals_allowed" />;
    } else {
      if (value.length <= 7) {
        errorVoucherCodes[index] = <FormattedMessage id="validation_length_too_short" values={{ count: 8 }} />;
      } else {
        errorVoucherCodes[index] = false;
      }
    }

    this.setState({ voucherCodes, errorVoucherCodes }); //eslint-disable-line
  }

  handleSubmitIndividual() {
    this.props.onCallback(this.state.voucherCodes);
  }

  render() {
    const {
      fields: { typeBool, numberOfVouchers },
      handleSubmit,
      intl
    } = this.props;

    const { voucherCodes, errorVoucherCodes } = this.state;

    let errors = [];
    errorVoucherCodes.map(item => {
      if (item) {
        errors.push(true);
      }
    });

    let emptyCodes = [];
    voucherCodes.map(item => {
      if (item === '') {
        emptyCodes.push(true);
      }
    });

    return (
      <div className="createVoucherCodeForm">
        <form action="#" onSubmit={handleSubmit(this.props.onCallback)}>
          <div className="createVoucherCodeForm_wrap">
            <EkRadio
              formRowItemKey="createVoucherCodeForm_typeBool"
              namespace="createVoucherCodeForm"
              items={voucherCodeTypes}
              field={typeBool}
            >
              <FieldErrorMsg field={typeBool} customClass="fieldErrorMsg--backUserForm" />
            </EkRadio>

            {typeBool.value === 'individual' && (
              <div className="createVoucherCodeForm_codeWrap">
                {voucherCodes.map((item, index) => (
                  <BoxedInput
                    key={index}
                    formRowItemKey="createVoucherCodeForm_code"
                    type="text"
                    id="createVoucherCodeForm_code"
                    labelKey="voucher_code"
                    value={voucherCodes[index]}
                    onChange={_partial(this.updateInput, index)}
                  >
                    {errorVoucherCodes[index] && <div className="createVoucherCodeForm_codeError">{errorVoucherCodes[index]}</div>}
                  </BoxedInput>
                ))}
                <div className="createVoucherCodeForm_addIcon">
                  <IconButton onClick={this.handleAddField} className="flatButton">
                    <AddIcon />
                  </IconButton>
                </div>
              </div>
            )}

            {typeBool.value === 'multiple' && (
              <BoxedInput
                formRowItemKey="createVoucherCodeForm_numberOfCodes"
                type="text"
                id="createVoucherCodeForm_numberOfCodes"
                labelKey="voucher_number_of_vouchers"
                field={numberOfVouchers}
              >
                <FieldErrorMsg customClass="fieldErrorMsg--createVoucherCodeForm" field={numberOfVouchers} />
              </BoxedInput>
            )}
          </div>

          <div className="createVoucherCodeForm_actions">
            <FlatButton
              id="voucher_code_modal_cancel_button"
              label={intl.messages.members_detail_abort_button}
              className="flatButton"
              primary
              onTouchTap={this.props.onCancel}
            />

            {typeBool.value === 'multiple' ? (
              <EkButton
                id="voucher_code_modal_confirm_multiple_button"
                onAction={handleSubmit(this.props.onCallback)}
                disabled={numberOfVouchers.value === ''}
              >
                <FormattedMessage id="vehicleForm_step_submit" />
              </EkButton>
            ) : (
              <EkButton
                id="voucher_code_modal_confirm_individual_button"
                onAction={this.handleSubmitIndividual}
                disabled={emptyCodes.length > 0 || errors.length > 0}
              >
                <FormattedMessage id="vehicleForm_step_submit" />
              </EkButton>
            )}
          </div>
        </form>
      </div>
    );
  }
}

CreateVoucherCodeForm.propTypes = {
  fields: PropTypes.object,
  handleSubmit: PropTypes.func
};

export default namedCompose(
  reduxForm(
    {
      form: 'CreateVoucherCodeForm',
      validate: createValidator({
        numberOfVouchers: [
          stopValidationIf({
            condition: props => {
              return !(_get(props.form.typeBool, 'value') === 'multiple');
            }
          }),
          notEmpty(),
          number(),
          integer()
        ]
      })
    },
    // eslint-disable-next-line no-unused-vars
    state => {
      return {
        initialValues: {
          typeBool: 'individual'
        },
        fields: ['typeBool', 'numberOfVouchers', 'voucherCodes[].code']
      };
    }
  ),
  injectIntl
)(CreateVoucherCodeForm);
