import * as types from '../constants/actionTypes-constants';

export default function applications(
  state = {
    allApplications: []
  },
  action
) {
  switch (action.type) {
    case types.APPLICATIONS_GET_ALL_SUCCESS:
      return Object.assign({}, state, {
        allApplications: action.applications
      });

    default:
      return state;
  }
}
