export const LOCALE_COUNTRIES = {
  fr: 'fr',
  it: 'it',
  pt: 'pt',
  de: 'de',
  da: 'dk',
  nl: 'nl',
  pl: 'pl',
  sk: 'sk',
  sv: 'se',
  cs: 'cz',
  pt_BR: 'br'
};
