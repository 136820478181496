import { safe } from '../../../utils/utils';

const customAcEngine = (query, record) => {
  if (query.trim()) {
    return record;
  }
};

export const createAc = (ref, { key, data, engine = customAcEngine, result, list } = {}) => {
  let ac = {};

  const config = {
    selector: () => ref,
    data: { src: data },
    searchEngine: engine,
    wrapper: false,
    events: {
      input: {
        focus() {
          if (safe(() => ac.input.value.trim().length)) {
            ac.start();
          }
        }
      }
    }
  };

  if (list) {
    config.resultsList = {
      element: list,
      maxResults: 100
    };
  }

  if (result) {
    config.resultItem = { element: result };
  }

  if (key) {
    config.data.keys = [key];
  }

  ac = new window.autoComplete(config);
  return ac;
};
