import * as types from '../constants/actionTypes-constants';

const sectionsOpen = {
  isGeneralSettingsOpen: false,
  isAccountManagementOpen: false,
  isFleetOpen: false,
  isMembersOpen: false,
  isReportsOpen: false,
  isOpenMobile: false
};

const defaultState = {
  isOpen: true,
  ...sectionsOpen
};

export default function sideMenu(state = defaultState, action) {
  switch (action.type) {
    case types.SIDE_MENU_OPEN:
      return Object.assign({}, state, {
        isOpen: true
      });

    case types.HEADER_MOBILE_MENU_TOGGLE:
      return {
        ...state,
        isOpenMobile: !state.isOpenMobile
      };

    case types.SIDE_MENU_CLOSE:
      return Object.assign({}, state, {
        isOpen: false
      });

    case types.SIDE_MENU_TOGGLE:
      return Object.assign({}, state, {
        isOpen: !state.isOpen
      });

    case types.SIDE_SUB_MENU_OPEN:
      switch (action.target) {
        case 'accountManagement':
          return Object.assign({}, state, {
            isAccountManagementOpen: true
          });
        case 'fleet':
          return Object.assign({}, state, {
            isFleetOpen: true
          });
        case 'members':
          return Object.assign({}, state, {
            isMembersOpen: true
          });
        case 'generalSettings':
          return Object.assign({}, state, {
            isGeneralSettingsOpen: true
          });
        case 'reports':
          return {
            ...state,
            isReportsOpen: true
          };
        default:
          return state;
      }

    case types.SIDE_SUB_MENU_CLOSE:
      switch (action.target) {
        case 'accountManagement':
          return Object.assign({}, state, {
            isAccountManagementOpen: false
          });
        case 'fleet':
          return Object.assign({}, state, {
            isFleetOpen: false
          });
        case 'members':
          return Object.assign({}, state, {
            isMembersOpen: false
          });
        case 'generalSettings':
          return Object.assign({}, state, {
            isGeneralSettingsOpen: false
          });
        case 'reports':
          return {
            ...state,
            isReportsOpen: false
          };
        default:
          return state;
      }

    case types.SIDE_SUB_MENU_TOGGLE:
      switch (action.target) {
        case 'accountManagement':
          return Object.assign({}, state, {
            isAccountManagementOpen: !state.isAccountManagementOpen
          });
        case 'fleet':
          return Object.assign({}, state, {
            isFleetOpen: !state.isFleetOpen
          });
        case 'members':
          return Object.assign({}, state, {
            isMembersOpen: !state.isMembersOpen
          });
        case 'generalSettings':
          return Object.assign({}, state, {
            isGeneralSettingsOpen: !state.isGeneralSettingsOpen
          });
        case 'reports':
          return {
            ...state,
            isReportsOpen: !state.isReportsOpen
          };
        default:
          return state;
      }

    case types.SIDE_MENU_RESET: {
      return { ...state, ...sectionsOpen };
    }

    default:
      return state;
  }
}
