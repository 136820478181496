/*

 Credit to https://github.com/yahoo/react-intl/issues/416#issuecomment-376088833

 This is a provider that should be a direct child of <IntlProvider> from 'react-intl'

 <IntlProvider {...someConfigProps}>
   <IntlGlobalProvider>
     <AppLayout />
   </IntlGlobalProvider>
 </IntlProvider>

 This way we can use import anywhere in the app to use the translations

 import { getMsg } from './IntlGlobalProvider'

*/

import { intlShape } from 'react-intl';

let INTL, instance;

class IntlTranslator {
  constructor() {
    if (!instance) {
      instance = this;
    }
    return instance;
  }

  getMsg(id = 'common_unknown', { def, values } = {}) {
    return INTL.formatMessage({ id, defaultMessage: def || id }, values);
  }

  getHtmlMsg(id = 'common_unknown', { def, values } = {}) {
    return INTL.formatHTMLMessage({ id, defaultMessage: def || id }, values);
  }
}

const IntlGlobalProvider = (props, context) => {
  INTL = context.intl;
  return props.children;
};

IntlGlobalProvider.contextTypes = {
  intl: intlShape.isRequired
};

export const { getMsg, getHtmlMsg } = new IntlTranslator();
export default IntlGlobalProvider;
