import React, { Component, PropTypes } from 'react';
import { FormattedMessage } from 'react-intl';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import autoBind from 'react-autobind';
import _ from 'lodash';

import FormRow from '../FormRow/FormRow';
import BoxedInput from '../BoxedInput/BoxedInput';
import EkButton from '../EkButton/EkButton';
import FieldErrorMsg from '../FieldErrorMsg/FieldErrorMsg';
import { createValidator, notEmpty } from '../../validation/all-validation';
import routes from '../../constants/routes-constants';
import BackLink from '../BackLink/BackLink';
import { scrollToFirstError } from '../../utils/utils';

class ModelForm extends Component {
  constructor() {
    super();
    autoBind(this);
  }

  getBackLink() {
    const { currentBrand, currentModel } = this.props;

    return `/#${routes.editBrand.path.replace(':brandId', _.get(currentModel, 'brand.id') || _.get(currentBrand, 'id'))}`;
  }

  render() {
    const {
      fields: { name },
      handleSubmit
    } = this.props;

    return (
      <section className="modelFormWrapper">
        <BackLink link={this.getBackLink()} labelKey="back_link_models_list" />
        <form action="#" className="modelForm" onSubmit={handleSubmit(this.props.onCallback)}>
          <div className="modelForm_container">
            <h4 className="modelForm_title">
              <FormattedMessage id="modelForm_title" />
            </h4>
            <FormRow customClass="modelForm_row">
              <BoxedInput
                formRowItemKey="modelForm_submitBtn"
                type="text"
                id="name"
                labelKey="modelForm_name_label"
                customClass="modelForm_boxedInput"
                field={name}
              >
                <FieldErrorMsg field={name} customClass="fieldErrorMsg--modelForm" />
              </BoxedInput>
            </FormRow>
          </div>
          <div className="modelForm_actions">
            <a href={this.getBackLink()} className="ekButton ekButton--reverse modelForm_actionsLink">
              <FormattedMessage id="modelForm_cancel_button" />
            </a>
            <EkButton customClass="modelForm_actionsButton" onAction={handleSubmit(this.props.onCallback)}>
              <FormattedMessage id="modelForm_save_button" />
            </EkButton>
          </div>
        </form>
      </section>
    );
  }
}

ModelForm.displayName = 'ModelForm';

ModelForm.propTypes = {
  fields: PropTypes.object,
  handleSubmit: PropTypes.func,
  onCallback: PropTypes.func
};

ModelForm = reduxForm({
  onSubmitFail: scrollToFirstError,
  form: 'modelForm',
  fields: ['name'],
  validate: createValidator({
    name: [notEmpty()]
  })
})(ModelForm);

export default connect(state => {
  const {
    brands: { currentBrand },
    models: { currentModel }
  } = state;

  return {
    currentBrand,
    currentModel
  };
})(ModelForm);
