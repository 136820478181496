import React, { Component } from 'react';
import classNames from 'classnames';
import { safe } from '../../../utils/utils';
import { scrollToSelected, scrollToLastView } from './helpers';

class ListWrap extends Component {
  componentWillMount() {
    this.setInputRef = index => {
      if (!this.inputRef) this.inputRef = new Map();
      return ref => this.inputRef.set(index, ref);
    };
  }

  componentDidMount() {
    scrollToSelected.call(this);
    this.updateScrollEvents();
  }

  componentDidUpdate() {
    this.handlePathUpdate();
  }

  updateScrollEvents() {
    if (window.innerWidth > 767) {
      scrollToLastView();
      this.focusLastInput();
    }
  }

  handlePathUpdate() {
    const { pathname } = this.props.location;
    if (this.pathname !== pathname) {
      this.pathname = pathname;
      this.updateScrollEvents();
    }
  }

  focusLastInput() {
    safe(() => {
      let lastInput;
      this.inputRef.forEach(el => {
        if (el) lastInput = el;
      });
      lastInput.focus();
    });
  }

  getRouteChildren() {
    return safe(() =>
      this.props.routes.reduce((prev, next, index) => {
        const Child = safe(() => next.component.child);
        if (Child) prev.push(<Child key={index} {...this.props} inputRef={this.setInputRef(index)} route={next} />);
        return prev;
      }, [])
    );
  }

  render() {
    return <div className={classNames('list-wrap')}>{this.getRouteChildren()}</div>;
  }
}

export default ListWrap;
