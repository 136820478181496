import callApi from './api-actions';
import * as types from '../constants/actionTypes-constants';
import { addErrorMessage } from '../utils/utils';
import { clearSitesList } from './sites-actions';

export function setBookingFindMemberCurrentSortedIndex(index) {
  return {
    type: types.BOOKING_FIND_MEMBER_SET_CURRENT_SORTED_INDEX,
    index
  };
}

export function setBookingFindMemberSortIsDescending(isDescending) {
  return {
    type: types.BOOKING_FIND_MEMBER_SET_SORT_DIRECTION,
    isDescending
  };
}

export function getBookingFindMemberAssociatedSites(companyId) {
  return dispatch => {
    dispatch({ type: types.SITES_GET_LIST_REQUEST });

    return dispatch(callApi('sites', 'getList', companyId))
      .then(data => {
        dispatch({ type: types.SITES_GET_LIST_SUCCESS, list: data });
        return Promise.resolve(data);
      })
      .catch(error => {
        dispatch(clearSitesList());
        dispatch(addErrorMessage({ error }));
        return Promise.resolve([]);
      });
  };
}
