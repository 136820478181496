import React, { Component } from 'react';
import ColumnList from '../../../components/_v2/ColumnList';
import { connect } from 'react-redux';
import routes from '../../../constants/routes-constants';

import { createMenuItems, resolveActive, resolveSelected, showNextView } from '../../../components/_v2/ColumnList/helpers';
import { FormattedMessage } from 'react-intl';
import { renderItem } from './helpers';
import { appBrandsSelector } from '../../../selectors/all-selectors';
import { getLastItem } from '../../../utils/utils';
import SearchCompaniesIcon from 'material-ui/svg-icons/action/search';
import { cssColors } from '../../../constants/style-constants';
import { URL_ALL } from '../../../constants/backend-constants';

class OrganizationBrands extends Component {
  componentWillMount() {
    this.setCallbacks();
    this.setVars();
  }

  setVars() {
    this.menuItems = createMenuItems({
      onAdd: this.handleAdd,
      addKey: 'addBrand_title'
    });

    this.menuItems.push({
      id: 'all_companies_button',
      labelKey: 'all_super_companies',
      icon: <SearchCompaniesIcon color={cssColors.listItem} />,
      handleClick: this.handleSearchAllSuperCompanies
    });
  }

  isEditView() {
    const { routes: listRoutes } = this.props;
    const { path: currentChildPath } = getLastItem(listRoutes).path || {};
    return currentChildPath === routes.vehicleEditBrand.path;
  }

  setCallbacks() {
    this.resolveSelected = resolveSelected.bind(this);
    this.resolveActive = resolveActive.bind(this);

    this.handleSearchAllSuperCompanies = () => {
      showNextView.call(this, 'organizationSuperCompanies', ':brandId', URL_ALL);
    };

    this.handleSelect = ({ id }, _, { openInNewTab }) => {
      const sameItem = this.resolveActive({ id });

      if (sameItem && !this.isEditView()) {
        if (openInNewTab) {
          return showNextView.call(this, 'organizationSuperCompanies', ':brandId', id, openInNewTab);
        }
        return this.handleSearchAllSuperCompanies();
      }
      showNextView.call(this, 'organizationSuperCompanies', ':brandId', id, openInNewTab);
    };

    this.handleEdit = (data = {}, _, { openInNewTab }) => {
      showNextView.call(this, 'organizationEditBrand', ':brandId', data.id, openInNewTab);
    };

    this.handleAdd = () => {
      showNextView.call(this, 'organizationAddBrand');
    };
  }

  render() {
    return (
      <ColumnList
        title={<FormattedMessage id="common_brands" />}
        inputRef={this.props.inputRef}
        items={this.props.list}
        renderItem={renderItem}
        onSelect={this.handleSelect}
        selected={this.resolveSelected}
        active={this.resolveActive}
        onEdit={this.handleEdit}
        menuItems={this.menuItems}
        localSearchEnabled
      />
    );
  }
}

const mapStateToProps = state => {
  return { list: appBrandsSelector(state) };
};

OrganizationBrands = connect(mapStateToProps)(OrganizationBrands);

export default OrganizationBrands;
