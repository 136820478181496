import React, { Component, PropTypes } from 'react';
import { connect } from 'react-redux';

import LoadingIndicator from '../components/LoadingIndicator/LoadingIndicator';
import Header from '../components/Header/Header';
import FlashMessageDisplayer from '../components/FlashMessageDisplayer/FlashMessageDisplayer';
import SideMenu from '../components/SideMenu/SideMenu';

import { closeHeaderProfileMenu, closeHeaderLanguageMenu } from '../actions/all-actions';
import MessageBanner from '../components/MessageBanner/MessageBanner';
import { bannerMessageSelector, routeLoadedSelector, userInfoSelector } from '../selectors/all-selectors';
import { isObjEmpty } from '../utils/utils';
import ButtonHoverMsg from '../components/ButtonHoverStatic/ButtonHoverMsg';

class Layout extends Component {
  componentWillMount() {
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    const { dispatch, profileMenuIsOpen, languageMenuIsOpen } = this.props;
    if (profileMenuIsOpen) {
      dispatch(closeHeaderProfileMenu());
    }
    if (languageMenuIsOpen) {
      dispatch(closeHeaderLanguageMenu());
    }
  }

  render() {
    const { userInfo } = this.props;
    const dataLoaded = !isObjEmpty(userInfo);

    return (
      <div onClick={this.handleClick}>
        {this.props.banner && <MessageBanner />}
        {dataLoaded && <Header />}
        <div className={this.props.isOpen ? 'pageWrap' : 'pageWrap pageWrap-menuClosed'}>
          {dataLoaded && <SideMenu />}
          {this.props.page}
        </div>
        <FlashMessageDisplayer />
        <LoadingIndicator />
        <ButtonHoverMsg />
      </div>
    );
  }
}

Layout.displayName = 'Layout';

Layout.propTypes = {
  page: PropTypes.element,
  isOpen: PropTypes.bool
};

export default connect(state => {
  const {
    header: { profileMenuIsOpen, languageMenuIsOpen },
    sideMenu: { isOpen }
  } = state;

  return {
    userInfo: userInfoSelector(state),
    banner: bannerMessageSelector(state),
    routeLoaded: routeLoadedSelector(state),
    profileMenuIsOpen,
    languageMenuIsOpen,
    isOpen
  };
})(Layout);
