import React, { Component, PropTypes } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { reduxForm, addArrayValue } from 'redux-form';
import { connect } from 'react-redux';
import shortid from 'shortid';
import classNames from 'classnames';
import _map from 'lodash/map';
import config from '../../constants/config-constants';
import EkButton from '../EkButton/EkButton';
import DeleteIcon from 'material-ui/svg-icons/action/delete';
import ArrowDown from 'material-ui/svg-icons/hardware/keyboard-arrow-down';
import ArrowUp from 'material-ui/svg-icons/hardware/keyboard-arrow-up';
import BoxedSelect from '../BoxedSelect/BoxedSelect';
import BoxedInput from '../BoxedInput/BoxedInput';
import Tooltip from '../../components/Tooltip/Tooltip';
import FieldErrorMsg from '../FieldErrorMsg/FieldErrorMsg';
import { validatePriceSlots } from '../../validation/all-validation';
import { scrollToFirstError } from '../../utils/utils';

import {
  TIME_SLOT_USAGE_BUSINESS,
  TIME_SLOT_USAGE_PRIVATE,
  TIME_SLOT_TYPE_PRICE_PER_HOUR,
  TIME_SLOT_TYPE_PACKAGE_BETWEEN_DATES,
  TIME_SLOT_TYPE_PACKAGE_FOR_DURATION,
  TIME_SLOT_TAG_DAY_HOURLY_RATE,
  TIME_SLOT_TAG_NIGHT_HOURLY_RATE,
  TIME_SLOT_TAG_DAY_PACKAGE,
  TIME_SLOT_TAG_NIGHT_PACKAGE,
  TIME_SLOT_TAG_WEEK_END_PACKAGE,
  TIME_SLOT_TAG_WEEK_PACKAGE,
  TIME_SLOT_TAG_MONTH_PACKAGE
} from '../../constants/backend-constants';

const tagsByType = {
  [TIME_SLOT_TYPE_PRICE_PER_HOUR]: [
    { labelKey: 'timeSlot_tags_hourly_rate_day', value: TIME_SLOT_TAG_DAY_HOURLY_RATE },
    { labelKey: 'timeSlot_tags_hourly_rate_night', value: TIME_SLOT_TAG_NIGHT_HOURLY_RATE }
  ],
  [TIME_SLOT_TYPE_PACKAGE_BETWEEN_DATES]: [
    { labelKey: 'timeSlot_tags_night_package', value: TIME_SLOT_TAG_NIGHT_PACKAGE },
    { labelKey: 'timeSlot_tags_weekend_package', value: TIME_SLOT_TAG_WEEK_END_PACKAGE }
  ],
  [TIME_SLOT_TYPE_PACKAGE_FOR_DURATION]: [
    { labelKey: 'timeSlot_tags_day_package', value: TIME_SLOT_TAG_DAY_PACKAGE },
    { labelKey: 'timeSlot_tags_week_package', value: TIME_SLOT_TAG_WEEK_PACKAGE },
    { labelKey: 'timeSlot_tags_month_package', value: TIME_SLOT_TAG_MONTH_PACKAGE }
  ]
};

const getTagsOptions = formType => tagsByType[formType];

class TimeSlotsForm extends Component {
  constructor(props) {
    super(props);
    const { intl, currency } = props;
    this.handleAddTimeSlot = this.handleAddTimeSlot.bind(this);
    this.handleTimeSlotUp = this.handleTimeSlotUp.bind(this);
    this.handleTimeSlotDown = this.handleTimeSlotDown.bind(this);
    this.symbolCurrency = currency ? intl.formatMessage({ id: `unit_${currency}` }) : '';
  }

  componentWillMount() {
    const { initialTimeSlots } = this.props;

    if (initialTimeSlots && initialTimeSlots.length) {
      initialTimeSlots.forEach(timeSlot => {
        let ts;
        const _uid = shortid.generate();
        const tag = timeSlot.tag || '';

        if (timeSlot.type !== TIME_SLOT_TYPE_PACKAGE_FOR_DURATION) {
          const splitFromTime = timeSlot.interval.fromTime.split(':');
          const splitToTime = timeSlot.interval.toTime.split(':');

          ts = {
            _uid,
            tag,
            fromHour: splitFromTime[0],
            fromMinutes: splitFromTime[1],
            toHour: splitToTime[0],
            toMinutes: splitToTime[1],
            fromDayOfWeek: timeSlot.interval.fromDay,
            toDayOfWeek: timeSlot.interval.toDay,
            price: timeSlot.price,
            type: timeSlot.type
          };
        } else {
          ts = {
            _uid,
            tag,
            price: timeSlot.price,
            duration: timeSlot.duration,
            type: timeSlot.type
          };
        }

        this.props.fields.timeSlots.addField(ts);
      });
    }
  }

  componentDidUpdate() {
    const { saveAllTimeSlots, usage, errors } = this.props;

    if (saveAllTimeSlots === true && usage === TIME_SLOT_USAGE_PRIVATE) {
      const values = this.props.values.timeSlots;
      const key = [];

      for (let error in errors.timeSlots) {
        key.push(Number(error));
      }

      for (let i = 0; i < values.length; i++) {
        if (values[i].duration !== undefined || (values[i].fromDayOfWeek !== undefined && values[i].toDayOfWeek !== undefined)) {
          if (Object.values(key).indexOf(i) > -1) {
            let formSelector = 'button.' + this.props.customClass;
            document.querySelector(formSelector).click();
          }

          this.props.onCallback(values[i]);
        }
      }

      if (this.props.type === TIME_SLOT_TYPE_PACKAGE_FOR_DURATION) {
        this.props.onSaveCallback();
      }
    }
  }

  addTimeSlot() {
    const { type, usage } = this.props;
    let ts = {};
    let _uid = shortid.generate();

    if (usage === TIME_SLOT_USAGE_BUSINESS) {
      ts = {
        _uid,
        type,
        fromDayOfWeek: '-',
        fromHour: '-',
        fromMinutes: '-',
        toDayOfWeek: '-',
        toHour: '-',
        toMinutes: '-'
      };
    } else if (usage === TIME_SLOT_USAGE_PRIVATE) {
      if (type === TIME_SLOT_TYPE_PRICE_PER_HOUR || type === TIME_SLOT_TYPE_PACKAGE_BETWEEN_DATES) {
        ts = {
          _uid,
          type,
          tag: '',
          fromDayOfWeek: '-',
          fromHour: '-',
          fromMinutes: '-',
          toDayOfWeek: '-',
          toHour: '-',
          toMinutes: '-',
          price: ''
        };
      } else if (type === TIME_SLOT_TYPE_PACKAGE_FOR_DURATION) {
        ts = {
          _uid,
          type,
          tag: '',
          duration: '',
          price: ''
        };
      }
    }

    this.props.fields.timeSlots.addField(ts);
  }

  handleAddTimeSlot() {
    this.addTimeSlot();
  }

  handleRemoveTimeSlot(index) {
    this.props.fields.timeSlots.removeField(index);
  }

  handleTimeSlotUp(index) {
    this.props.fields.timeSlots.swapFields(index, index - 1);
  }

  handleTimeSlotDown(index) {
    this.props.fields.timeSlots.swapFields(index, index + 1);
  }

  render() {
    const {
      fields: { timeSlots },
      limitedAccess,
      errors,
      usage,
      type,
      handleSubmit,
      timeSlotsErrors
    } = this.props;

    const submitAction = limitedAccess ? undefined : handleSubmit(this.props.onCallback);

    return (
      <form action="#" className={classNames('timeSlotsForm', this.props.customClass)} onSubmit={submitAction}>
        {!limitedAccess ? (
          <EkButton
            onAction={this.handleAddTimeSlot}
            customClass="ekButton--add timeSlotsForm_addTimeSlot_button"
            skinType="reverse"
            id={`add-timeslot-${this.props.formKey}`}
          >
            {type === TIME_SLOT_TYPE_PRICE_PER_HOUR ? (
              <FormattedMessage id="timeSlot_add_button_label" />
            ) : (
              <FormattedMessage id={'timeSlot_add_' + type + '_button_label'} />
            )}
          </EkButton>
        ) : null}
        {this.props.label && (
          <h4 className={this.props.label.class}>
            <FormattedMessage id={this.props.label.key} />
          </h4>
        )}

        <ul className="timeSlotsForm_timeSlots">
          {!timeSlots.length && <FormattedMessage id="pricing_no_timeslots_defined" />}
          {_map(timeSlots, (item, index) => {
            let boundBusinessTimeSlotRemove = this.handleRemoveTimeSlot.bind(this, index);
            let moveUpItem = this.handleTimeSlotUp.bind(this, index);
            let moveDownItem = this.handleTimeSlotDown.bind(this, index);

            return (
              <li
                className={classNames({
                  timeSlotsForm_timeSlots_item: true,
                  _has_error: errors.timeSlots && errors.timeSlots[index] && errors.timeSlots[index].hasOwnProperty('type')
                })}
                key={item._uid.value}
              >
                <div className="timeSlotsForm_timeSlots_item_content">
                  {errors.timeSlots && errors.timeSlots[index] && errors.timeSlots[index].hasOwnProperty('type') && (
                    <span className="overlap-errors fieldErrorMsg">
                      <FormattedMessage id="validation_timeslots_overlap" />
                    </span>
                  )}

                  {usage === TIME_SLOT_USAGE_PRIVATE ? (
                    <BoxedSelect
                      blockCustomClass="timeSlot_boxedSelectBlock timeSlot_boxedSelectBlock"
                      id={`timeSlot-atg-${this.props.formKey}${index}`}
                      customClass="timeSlot_tags_boxedSelectWrapper"
                      options={getTagsOptions(type)}
                      disabled={limitedAccess}
                      field={item.tag}
                      emptyValueLabel="timeSlot_tags_default_value"
                    >
                      <FieldErrorMsg customClass="fieldErrorMsg--timeSlotsForm" field={item.tag} />
                    </BoxedSelect>
                  ) : null}

                  {type !== TIME_SLOT_TYPE_PACKAGE_FOR_DURATION ? (
                    <div className="timeSlotsForm_timeSlots_group">
                      <BoxedSelect
                        id={`timeSlot-from-${this.props.formKey}${index}`}
                        blockCustomClass="timeSlot_boxedSelectBlock timeSlot_boxedSelectBlock--from"
                        customClass="timeSlot_boxedSelectWrapper"
                        customSelectClass={classNames({
                          _has_error: item.fromDayOfWeek.error && item.fromDayOfWeek.touched
                        })}
                        options={config.daysOfWeek}
                        disabled={limitedAccess}
                        field={item.fromDayOfWeek}
                      >
                        <FieldErrorMsg customClass="fieldErrorMsg--timeSlotsForm" field={item.fromDayOfWeek} />
                      </BoxedSelect>

                      <BoxedSelect
                        id={`timeSlot-fromHour-${this.props.formKey}${index}`}
                        options={config.availableHours}
                        blockCustomClass="timeSlot_boxedSelectBlock timeSlot_boxedSelectBlock--fromHour"
                        customClass="timeSlot_fromHour_boxedSelectWrapper"
                        customSelectClass={classNames({
                          _has_error: item.fromHour.error && item.fromHour.touched
                        })}
                        disabled={limitedAccess}
                        field={item.fromHour}
                      >
                        <FieldErrorMsg customClass="fieldErrorMsg--timeSlotsForm" field={item.fromHour} />
                      </BoxedSelect>

                      <BoxedSelect
                        id={`timeSlot-fromMinutes-${this.props.formKey}${index}`}
                        options={config.availableMinutes}
                        blockCustomClass="timeSlot_boxedSelectBlock timeSlot_boxedSelectBlock--fromMinutes"
                        customClass="timeSlot_fromMinutes_boxedSelectWrapper"
                        customSelectClass={classNames({
                          _has_error: item.fromMinutes.error && item.fromMinutes.touched
                        })}
                        disabled={limitedAccess}
                        field={item.fromMinutes}
                      >
                        <FieldErrorMsg customClass="fieldErrorMsg--timeSlotsForm" field={item.fromMinutes} />
                      </BoxedSelect>

                      <BoxedSelect
                        id={`timeSlot-to-${this.props.formKey}${index}`}
                        blockCustomClass="timeSlot_boxedSelectBlock timeSlot_boxedSelectBlock--to"
                        customClass="timeSlot_boxedSelectWrapper"
                        customLabelClass="timeSlot_to_label"
                        customSelectClass={classNames({
                          _has_error: item.toDayOfWeek.error && item.toDayOfWeek.touched
                        })}
                        options={config.daysOfWeek}
                        disabled={limitedAccess}
                        field={item.toDayOfWeek}
                      >
                        <FieldErrorMsg customClass="fieldErrorMsg--timeSlotsForm" field={item.toDayOfWeek} />
                      </BoxedSelect>

                      <BoxedSelect
                        id={`timeSlot-toHour-${this.props.formKey}${index}`}
                        options={config.availableHours}
                        blockCustomClass="timeSlot_boxedSelectBlock timeSlot_boxedSelectBlock--toHour"
                        customClass="timeSlot_toHour_boxedSelectWrapper"
                        customSelectClass={classNames({
                          _has_error: item.toHour.error && item.toHour.touched
                        })}
                        disabled={limitedAccess}
                        field={item.toHour}
                      >
                        <FieldErrorMsg customClass="fieldErrorMsg--timeSlotsForm" field={item.toHour} />
                      </BoxedSelect>

                      <BoxedSelect
                        id={`timeSlot-toMinutes-${this.props.formKey}${index}`}
                        options={config.availableMinutes}
                        blockCustomClass="timeSlot_boxedSelectBlock timeSlot_boxedSelectBlock--toMinutes"
                        customClass="timeSlot_toMinutes_boxedSelectWrapper"
                        customSelectClass={classNames({
                          _has_error: item.toMinutes.error && item.toMinutes.touched
                        })}
                        disabled={limitedAccess}
                        field={item.toMinutes}
                      >
                        <FieldErrorMsg customClass="fieldErrorMsg--timeSlotsForm" field={item.toMinutes} />
                      </BoxedSelect>
                    </div>
                  ) : (
                    <div className="timeSlotsForm_timeSlots_group">
                      <BoxedInput
                        type="text"
                        id={`timeSlot-duration-${this.props.formKey}${index}`}
                        labelKey="timeSlot_duration_label"
                        customClass="timeSlot_price_boxedInputWrapper"
                        customLabelClass="timeSlot_duration_boxedInputLabel"
                        customLabelTextClass="timeSlot_price_boxedInputLabelText"
                        customInputClass={classNames('timeSlot_price_boxedInputInput', {
                          _has_error: item.duration.error && item.duration.touched
                        })}
                        field={item.duration}
                        disabled={limitedAccess}
                      >
                        {usage === TIME_SLOT_USAGE_PRIVATE && (
                          <i className="timeSlot_duration_boxedInputLabel timeSlot_duration_unit">hours</i>
                        )}
                        <FieldErrorMsg customClass="fieldErrorMsg--timeSlotsForm" field={item.duration} />
                      </BoxedInput>
                    </div>
                  )}

                  {usage !== TIME_SLOT_USAGE_PRIVATE ? null : (
                    <BoxedInput
                      type="text"
                      id={`timeSlot-price-${this.props.formKey}${index}`}
                      labelKey="timeSlot_price_label"
                      customClass="timeSlot_price_boxedInputWrapper"
                      customLabelClass="timeSlot_price_boxedInputLabel"
                      customLabelTextClass="timeSlot_price_boxedInputLabelText"
                      customInputClass={classNames('timeSlot_price_boxedInputInput', {
                        _has_error: item.price.error && item.price.touched
                      })}
                      field={item.price}
                      placeholder="0"
                      disabled={limitedAccess}
                    >
                      {usage !== TIME_SLOT_USAGE_PRIVATE ? null : (
                        <span className="timeSlot_price_boxedInputLabel timeSlot_price_unit">{this.symbolCurrency}</span>
                      )}
                      <FieldErrorMsg customClass="fieldErrorMsg--timeSlotsForm" field={item.price} />
                    </BoxedInput>
                  )}
                </div>
                {!limitedAccess && (
                  <div className="timeSlotsForm_timeSlots_item_actionButton">
                    <Tooltip placement="top" content={<FormattedMessage id="common_move_up" />} disabled={index === 0}>
                      <ArrowUp disabled={index === 0} onClick={moveUpItem} className="moveUp" />
                    </Tooltip>
                    <Tooltip
                      placement="top"
                      content={<FormattedMessage id="common_move_down" />}
                      disabled={index + 1 === timeSlots.length || (index === 0 && timeSlots.length === 0)}
                    >
                      <ArrowDown
                        disabled={index + 1 === timeSlots.length || (index === 0 && timeSlots.length === 0)}
                        onClick={moveDownItem}
                        className="moveDown"
                      />
                    </Tooltip>
                  </div>
                )}
                {!limitedAccess && (
                  <div className="timeSlotsForm_timeSlots_item_actionButton">
                    <Tooltip placement="top" content={<FormattedMessage id="pricing_delete_timeslot" />}>
                      <DeleteIcon onClick={boundBusinessTimeSlotRemove} className="delete" />
                    </Tooltip>
                  </div>
                )}
              </li>
            );
          })}
        </ul>

        {timeSlotsErrors[type] && usage === TIME_SLOT_USAGE_PRIVATE ? (
          <ul className="timeSlotsForm_errors">
            {timeSlotsErrors[type].map((messageId, i) => (
              <li key={i} className="timeSlotsForm_errors_item fieldErrorMsg">
                <FormattedMessage id={messageId} />
              </li>
            ))}
          </ul>
        ) : null}

        {!limitedAccess && (
          <EkButton
            customClass={classNames('timeSlotsForm_submitButton', this.props.customClass)}
            onAction={submitAction}
            id={`submit-timeslots-${this.props.formKey}`}
          >
            <FormattedMessage id="timeSlotsForm_submitButton" />
          </EkButton>
        )}
      </form>
    );
  }
}

TimeSlotsForm.displayName = 'TimeSlotsForm';

TimeSlotsForm.propTypes = {
  fields: PropTypes.object,
  limitedAccess: PropTypes.bool,
  onCallback: PropTypes.func,
  handleSubmit: PropTypes.func,
  onSaveCallback: PropTypes.func,
  currency: PropTypes.string
};

TimeSlotsForm = reduxForm(
  {
    onSubmitFail: scrollToFirstError,
    touchOnBlur: false,
    form: 'TimeSlotsForm',
    fields: [
      'timeSlots[]._uid',
      'timeSlots[].tag',
      'timeSlots[].fromDayOfWeek',
      'timeSlots[].fromHour',
      'timeSlots[].fromMinutes',
      'timeSlots[].toDayOfWeek',
      'timeSlots[].toHour',
      'timeSlots[].toMinutes',
      'timeSlots[].price',
      'timeSlots[].type',
      'timeSlots[].duration'
    ],
    validate: validatePriceSlots()
  },
  undefined,
  {
    addValue: addArrayValue
  }
)(TimeSlotsForm);

export default connect(state => {
  const {
    pricing: { saveAllTimeSlots, timeSlotsErrors }
  } = state;

  return {
    saveAllTimeSlots,
    timeSlotsErrors
  };
})(injectIntl(TimeSlotsForm));
