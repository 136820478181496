import React, { Component, PropTypes } from 'react';
import ListModal from '../ColumnList/Modal';
import { getEditModalTitle } from '../../../components/_v2/ColumnList/helpers';
import HomepageSettingsActionForm from './HomepageSettingsActionForm';
import { FormattedHTMLMessage } from 'react-intl';

export class HomepageActionsModal extends Component {
  getTitle() {
    return getEditModalTitle(<FormattedHTMLMessage id="contract.homesetting.action.edit" values={{ name: this.props.name }} />);
  }

  render() {
    const { initialValues } = this.props;
    return (
      <ListModal title={this.getTitle()} isOpen={this.props.isOpen} onClose={this.props.onClose}>
        <HomepageSettingsActionForm onCallback={this.props.onCallback} onClose={this.props.onClose} initialValues={initialValues} />
      </ListModal>
    );
  }
}

ListModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func
};

export default HomepageActionsModal;
