import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { getEditTitle } from '../../../../components/_v2/ColumnList/helpers';
import { showParentView } from '../../../../components/_v2/ColumnList/helpers';
import { connect } from 'react-redux';
import ColorView from './index';
import { updateColorRequest } from '../../../../actions/colors-actions';
import { checkRole } from '../../../../constants/backuser-role-rules';
import { editColorsRules } from '../../../../constants/routes-constants';
import { userRoleSelector } from '../../../../selectors/all-selectors';

export class VehicleEditColor extends Component {
  componentWillMount() {
    this.setVars();
    this.setCallbacks();
  }

  setVars() {
    this.limitedAccess = !checkRole(editColorsRules, this.props.role);
  }

  setCallbacks() {
    this.showParentView = showParentView.bind(this);

    this.handleCallback = data => {
      this.props.dispatch(updateColorRequest(data));
    };
  }

  render() {
    return (
      <ColorView
        inputRef={this.props.inputRef}
        title={getEditTitle(<FormattedMessage id="vehiclesColorForm_title" />)}
        fieldLabel={<FormattedMessage id="vehiclesColorForm_name_label" />}
        onClose={this.showParentView}
        onCallback={this.handleCallback}
        initialValues={this.props.data}
        limitedAccess={this.limitedAccess}
      />
    );
  }
}

const mapStateToProps = state => {
  return {
    data: state.colors.currentColor,
    role: userRoleSelector(state)
  };
};

VehicleEditColor = connect(mapStateToProps)(VehicleEditColor);

export default VehicleEditColor;
