import { BE_ALL, FIXED_AMOUNT_DISCOUNT, PERCENT_DISCOUNT } from './backend-constants';

import { bankoutStatusKeys, vehicleUsageTypes } from './options-constants';

export const allFeedbacksCleanlinessProblemsStatues = [
  {
    labelKey: 'feedbacks_archive_status_all',
    value: BE_ALL
  },
  {
    labelKey: 'feedbacks_archive_status_with',
    value: 'WITH'
  },
  {
    labelKey: 'feedbacks_archive_status_without',
    value: 'WITHOUT'
  }
];

export const vehicleUsages = vehicleUsageTypes.map(item => ({ value: item.key, labelKey: item.label }));

export const allFeedbacksDamagesDeclaredStatues = [
  {
    labelKey: 'feedbacks_archive_status_all',
    value: BE_ALL
  },
  {
    labelKey: 'feedbacks_archive_status_with',
    value: 'WITH'
  },
  {
    labelKey: 'feedbacks_archive_status_without',
    value: 'WITHOUT'
  }
];

export const allFeedbacksStatues = [
  {
    labelKey: 'feedbacks_detail_feedback_status_NEW',
    value: 'NEW'
  },
  {
    labelKey: 'feedbacks_detail_feedback_status_SEEN',
    value: 'SEEN'
  },
  {
    labelKey: 'feedbacks_detail_feedback_status_HANDLED',
    value: 'HANDLED'
  }
];

export const allGroupsInvoiceStatuses = [
  {
    labelKey: 'groups_invoice_status_SUSPENDED',
    value: true
  },
  {
    labelKey: 'groups_invoice_status_UNSUSPENDED',
    value: false
  }
];

export const allDamagesAreas = [
  {
    labelKey: 'feedbacks_damage_report_area_FRONT_RIGHT_FENDER',
    value: 'FRONT_RIGHT_FENDER'
  },
  {
    labelKey: 'feedbacks_damage_report_area_FRONT_LEFT_FENDER',
    value: 'FRONT_LEFT_FENDER'
  },
  {
    labelKey: 'feedbacks_damage_report_area_REAR_RIGHT_FENDER',
    value: 'REAR_RIGHT_FENDER'
  },
  {
    labelKey: 'feedbacks_damage_report_area_REAR_LEFT_FENDER',
    value: 'REAR_LEFT_FENDER'
  },
  {
    labelKey: 'feedbacks_damage_report_area_RIGHT_SIDE_SKIRT',
    value: 'RIGHT_SIDE_SKIRT'
  },
  {
    labelKey: 'feedbacks_damage_report_area_LEFT_SIDE_SKIRT',
    value: 'LEFT_SIDE_SKIRT'
  },
  {
    labelKey: 'feedbacks_damage_report_area_INTERIOR',
    value: 'INTERIOR'
  },
  {
    labelKey: 'feedbacks_damage_report_area_RIM',
    value: 'RIM'
  },
  {
    labelKey: 'feedbacks_damage_report_area_WINDSHIELD',
    value: 'WINDSHIELD'
  },
  {
    labelKey: 'feedbacks_damage_report_area_FRONT_BUMPER',
    value: 'FRONT_BUMPER'
  },
  {
    labelKey: 'feedbacks_damage_report_area_REAR_BUMPER',
    value: 'REAR_BUMPER'
  },
  {
    labelKey: 'feedbacks_damage_report_area_TIRE',
    value: 'TIRE'
  },
  {
    labelKey: 'feedbacks_damage_report_area_FRONT_RIGHT_DOOR',
    value: 'FRONT_RIGHT_DOOR'
  },
  {
    labelKey: 'feedbacks_damage_report_area_FRONT_LEFT_DOOR',
    value: 'FRONT_LEFT_DOOR'
  },
  {
    labelKey: 'feedbacks_damage_report_area_REAR_RIGHT_DOOR',
    value: 'REAR_RIGHT_DOOR'
  },
  {
    labelKey: 'feedbacks_damage_report_area_REAR_LEFT_DOOR',
    value: 'REAR_LEFT_DOOR'
  },
  {
    labelKey: 'feedbacks_damage_report_area_REARVIEW_MIRROR',
    value: 'REARVIEW_MIRROR'
  },
  {
    labelKey: 'feedbacks_damage_report_area_ROOF',
    value: 'ROOF'
  },
  {
    labelKey: 'feedbacks_damage_report_area_LIGHTS',
    value: 'LIGHTS'
  },
  {
    labelKey: 'feedbacks_damage_report_area_OTHER',
    value: 'OTHER'
  }
];

export const allDamagesTypes = [
  {
    labelKey: 'feedbacks_damage_report_type_CRACKED_MIRROR_OR_WINDOW',
    value: 'CRACKED_MIRROR_OR_WINDOW'
  },
  {
    labelKey: 'feedbacks_damage_report_type_MISSING_ELEMENT',
    value: 'MISSING_ELEMENT'
  },
  {
    labelKey: 'feedbacks_damage_report_type_DENT',
    value: 'DENT'
  },
  {
    labelKey: 'feedbacks_damage_report_type_SCRAPE_ABRASION',
    value: 'SCRAPE_ABRASION'
  },
  {
    labelKey: 'feedbacks_damage_report_type_CHIPPED_PAINT',
    value: 'CHIPPED_PAINT'
  },
  {
    labelKey: 'feedbacks_damage_report_type_SCRATCH_LESS_THAN_2_CM',
    value: 'SCRATCH_LESS_THAN_2_CM'
  },
  {
    labelKey: 'feedbacks_damage_report_type_SCRATCH_MORE_THAN_2_CM',
    value: 'SCRATCH_MORE_THAN_2_CM'
  },
  {
    labelKey: 'feedbacks_damage_report_type_OTHER',
    value: 'OTHER'
  }
];

export const vehicleTypes = [
  {
    labelKey: 'vehicleForm_type_passenger',
    value: 'PASSENGER'
  },
  {
    labelKey: 'vehicleForm_type_commercial',
    value: 'COMMERCIAL'
  }
];

export const vehicleCleanlinessStatuses = [
  {
    labelKey: 'vehicleForm_cleanlinessStatus_clean',
    value: 'CLEAN'
  },
  {
    labelKey: 'vehicleForm_cleanlinessStatus_dirty',
    value: 'DIRTY'
  }
];

export const vehicleStatuses = [
  {
    labelKey: 'vehicle_statusType_REPARATION',
    value: 'REPARATION'
  },
  {
    labelKey: 'vehicle_statusType_READY',
    value: 'READY'
  },
  {
    labelKey: 'vehicle_statusType_INACTIVE',
    value: 'INACTIVE'
  },
  {
    labelKey: 'vehicle_statusType_CLEANING',
    value: 'CLEANING'
  },
  {
    labelKey: 'vehicle_statusType_INTERVENTION',
    value: 'INTERVENTION'
  },
  {
    labelKey: 'vehicle_statusType_MAINTENANCE',
    value: 'MAINTENANCE'
  },
  {
    labelKey: 'vehicle_statusType_RENTED_IN_RRS',
    value: 'RENTED_IN_RRS'
  }
];

export const invoiceStatuses = [
  {
    labelKey: 'payment_status_PAID',
    value: 'PAID'
  },
  {
    labelKey: 'payment_status_UNPAID',
    value: 'UNPAID'
  }
];

export const voucherTypes = [
  {
    value: FIXED_AMOUNT_DISCOUNT,
    labelKey: 'voucher_type_flat_value'
  },
  {
    value: PERCENT_DISCOUNT,
    labelKey: 'voucher_type_percentage'
  }
];

export const booleanTypes = [
  {
    labelKey: 'common_yes',
    value: true
  },
  {
    labelKey: 'common_no',
    value: false
  }
];

export const defaultWeeklySlot = [
  {
    days: [true, true, true, true, true, true, false],
    items: [
      {
        endHours: '12',
        endMinutes: '00',
        startHours: '07',
        startMinutes: '30'
      },
      {
        endHours: '19',
        endMinutes: '00',
        startHours: '14',
        startMinutes: '00'
      }
    ]
  }
];

function createBankoutOptionKeys() {
  const optionKeys = [];

  for (const objKey in bankoutStatusKeys) {
    optionKeys.push({ value: objKey, labelKey: bankoutStatusKeys[objKey] });
  }

  return optionKeys;
}

export const bankoutStatusOptionKeys = createBankoutOptionKeys();
