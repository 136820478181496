import React, { Component } from 'react';
import moment from 'moment';
import autoBind from 'react-autobind';
import classnames from 'classnames';
// import { connect } from 'react-redux';
const firstColumnWidth = '290px';
class TimeMarker extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
    const now = new Date();
    this.period = props.period;
    this.gutter = this.period > 1 ? 20 : 1;
    this.paddWindowsOs = props.isWindowsOs ? '17px' : '0px';
    this.state = {
      now,
      hour: now.getHours(),
      minute: now.getMinutes(),
      offset: now.getHours() * 60 + now.getMinutes() + (this.period > 1 ? this.gutter * this.period : 0),
      period: this.period,
      gutter: this.gutter,
      menuGap: props.isMenuOpen ? '235px' : '0px'
    };
  }

  componentWillMount() {
    window.addEventListener('resize', this.tic);
  }

  componentDidMount() {
    this.interval = setInterval(() => {
      this.tic();
    }, 60000);
  }

  tic() {
    this.now2 = new Date();
    // eslint-disable-next-line react/no-set-state
    this.offset = this.state.hour * 60 + this.state.minute + (this.period > 1 ? this.state.gutter * this.period : 0);
    this.setState({
      now: this.now2,
      hour: this.now2.getHours(),
      minute: this.now2.getMinutes(),
      gutter: this.period > 1 ? 20 : 1,
      offset: this.offset,
      period: this.period,
      menuGap: this.props.isMenuOpen ? '235px' : '0px'
    });
  }

  componentWillReceiveProps(nextProps) {
    let start = new Date();
    // eslint-disable-next-line react/no-set-state
    this.setState({
      now: start,
      hour: start.getHours(),
      minute: start.getMinutes(),
      gutter: nextProps.period > 1 ? 20 : 1,
      offset: this.state.hour * 60 + this.state.minute + (nextProps.period > 1 ? this.state.gutter * nextProps.period : 0),
      period: nextProps.period,
      menuGap: nextProps.isMenuOpen ? '235px' : '0px'
    });
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.tic);
    clearInterval(this.interval);
  }

  render() {
    let widthGap = diff => {
      let daysGap = 0;
      const interfaceDayGap = document.querySelector('div.new-vehicle-planning_firstRow_field');
      const interfaceDayGapWidth = interfaceDayGap && interfaceDayGap.clientWidth;

      if (this.state.period > 1) {
        daysGap = interfaceDayGapWidth * diff;
      }
      daysGap === 0 ? (daysGap = '') : (daysGap = ' + ' + daysGap + 'px');
      return this.state.period === 1 ? '' : `${daysGap}`;
    };

    let marker = width => {
      const isLeftMenuDrawer = document.documentElement.clientWidth < 1200;
      let left = `calc( ((( 100% - ${firstColumnWidth} - ${this.state.menuGap} - ${this.paddWindowsOs} ) ) / 1440 / ${this.state.period}) * ${this.state.offset} ${width} + ( ${firstColumnWidth} + ${this.state.menuGap} ) )`;
      let leftInlineMenu = `calc( ((( 100% - ${firstColumnWidth} - ${this.paddWindowsOs} ) ) / 1440 / ${this.state.period}) * ( ${this.state.offset}  )  ${width} + ${firstColumnWidth} )`;

      return (
        <div
          className={classnames('current-time', { pad: this.props.legendShown })}
          style={isLeftMenuDrawer ? { left: leftInlineMenu } : { left }}
        />
      );
    };

    const nowDate = moment(this.state.now);
    const _startDate = moment(this.props.startDate);
    const __startD = moment(_startDate);

    const sForm = __startD.format('YYYY-MM-DD');
    const eForm = __startD.add(this.props.period, 'd').format('YYYY-MM-DD');

    if (nowDate.isBetween(sForm, eForm, 'h', '[]')) {
      const step = nowDate.diff(_startDate, 'd');
      const width = widthGap(step);
      return marker(width);
    }
    return <div />;
  }
}

TimeMarker.displayName = 'TimeMarker';

export default TimeMarker;
