import React, { Component, PropTypes } from 'react';
import { FormattedMessage } from 'react-intl';

class FieldErrorMsg extends Component {
  render() {
    let { field, customClass } = this.props;
    field = field || {};

    if (!field.touched || !field.error) {
      return null;
    }

    return (
      <div className={`fieldErrorMsg ${customClass || ''}`}>
        <FormattedMessage id={field.error.type.toLowerCase()} values={field.error.data} />
      </div>
    );
  }
}

FieldErrorMsg.displayName = 'FieldErrorMsg';

FieldErrorMsg.propTypes = {
  field: PropTypes.any,
  customClass: PropTypes.string
};

export default FieldErrorMsg;
