import React, { Component } from 'react';
import { connect } from 'react-redux';

import VersionForm from '../../components/VersionForm/VersionForm';
import { updateVersionRequest } from '../../actions/all-actions';
import { safe } from '../../utils/utils';

class EditVersion extends Component {
  componentWillMount() {
    this.handleUpdateVersion = ({ name }) => {
      const params = {
        modelId: safe(() => this.props.version.model.id),
        id: this.props.version.id,
        name
      };

      this.props.dispatch(updateVersionRequest(params));
    };
  }

  render() {
    return (
      <div className="editVersionPage mainContainer_content">
        <div className="pageContainer">
          <div className="editVersionPage_content">
            <section className="editVersionPage_management">
              <VersionForm onCallback={this.handleUpdateVersion} initialValues={{ name: this.props.version.name }} />
            </section>
          </div>
        </div>
      </div>
    );
  }
}

EditVersion.displayName = 'EditVersion';

export default connect(state => {
  const {
    versions: { currentVersion: version }
  } = state;

  return { version };
})(EditVersion);
