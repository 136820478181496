import React, { Component } from 'react';
import { connect } from 'react-redux';
import { routeActions } from 'react-router-redux';
import { getStore } from '../../store/all-store';

import TableView from '../../components/TableView/TableView';
import BrandForm from '../../components/BrandForm/BrandForm';
import PageManagementHeader from '../../components/PageManagementHeader/PageManagementHeader';
import { updateBrandRequest } from '../../actions/all-actions';
import routes from '../../constants/routes-constants';
import { BACKUSER_ROLE_CALL_CENTER_OPERATOR } from '../../constants/backend-constants';
import { safe } from '../../utils/utils';

const tableViewParams = {
  namespace: 'model',
  sortIsDescending: false,
  columns: [
    {
      messageKey: 'model_tableView_label_name',
      content: 'name'
    },
    {
      messageKey: 'model_tableView_label_action',
      content: null,
      contentMessageKey: 'model_tableView_content_action',
      actionCustomClass: 'model_tableView_action',
      actionCallback: function(item) {
        getStore().dispatch(routeActions.push(routes.editModel.path.replace(':modelId', item.id)));
      }
    }
  ]
};

class EditBrand extends Component {
  componentWillMount() {
    this.handleUpdateBrand = this.handleUpdateBrand.bind(this);
  }

  handleUpdateBrand({ name }) {
    const params = { id: this.props.brand.id, name };
    this.props.dispatch(updateBrandRequest(params));
  }

  render() {
    const { brand, list, role } = this.props;

    return (
      <div className="editBrandPage mainContainer_content">
        <div className="pageContainer">
          <div className="editBrandPage_content">
            <section className="editBrandPage_management">
              <PageManagementHeader
                title={brand.name}
                href={`/#${routes.addModel.path.replace(':brandId', brand.id)}`}
                buttonLabelKey="editBrand_add"
                limitedAccess={role === BACKUSER_ROLE_CALL_CENTER_OPERATOR}
              />
              <BrandForm
                onCallback={this.handleUpdateBrand}
                initialValues={{ name: brand.name }}
                limitedAccess={role === BACKUSER_ROLE_CALL_CENTER_OPERATOR}
              />
              {!!safe(() => list.length) && (
                <TableView customClass="editBrandPage_tableViewWrapper" params={tableViewParams} content={list} />
              )}
            </section>
          </div>
        </div>
      </div>
    );
  }
}

EditBrand.displayName = 'EditBrand';

export default connect(state => {
  const {
    user: {
      userInfo: { role }
    },
    brands: { currentBrand: brand },
    models: { list }
  } = state;

  return {
    brand,
    list,
    role
  };
})(EditBrand);
