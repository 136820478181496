import React, { Component } from 'react';
import { createEmbeddingContext } from 'amazon-quicksight-embedding-sdk';

import {
  clearQuickSightError,
  getQuickSightUrl,
  setQuickSightError,
  startDashboardLoadingSpinner,
  stopDashboardLoadingSpinner
} from '../../actions/user-actions';

import {
  localeSelector,
  reportingErrorStatusSelector,
  reportingUserEmailSelector,
  validCompanyIdSelector,
  validSubCompanyIdSelector
} from '../../selectors/all-selectors';
import { connect } from 'react-redux';
import cs from 'classnames';
import { getMsg } from '../../utils/IntlGlobalProvider';
import { fallbackFunc, isTestEnv, testProp } from '../../utils/utils';
import { quickSightLocaleMap } from '../../constants/generic-constants';
import { addErrorMsg } from '../../utils/flashMessage/creator';

const idToFilterAll = '[ALL]';

function getTestingCompanyId(companyId) {
  return isTestEnv() ? '19919ed8-797f-4f0c-8f00-149dab4838d9' : companyId;
}

function getTestingSubCompanyId(subCompanyId) {
  return isTestEnv() ? '191421f8-868b-4a7c-be1f-34d2ddf060ad' : subCompanyId;
}

class QsDashboard extends Component {
  componentWillMount() {
    this.setVars();
    this.initState();
    this.setCallbacks();
  }

  initProps() {
    this.componentPropsUpdated(this.props);
    this.propsInit = true;
  }

  componentWillReceiveProps(props) {
    this.componentPropsUpdated(props);
  }

  // new props
  componentPropsUpdated(props) {
    this.getAwsUrl(props);
  }

  componentDidMount() {
    this.initProps();
    this.createContext();
    this.componentUpdatedCycle();
  }

  componentDidUpdate() {
    this.componentUpdatedCycle();
  }

  // after render
  componentUpdatedCycle() {
    this.updateDashboard();
    this.embedDashboard();
  }

  setVars() {
    this.dashboardEventsMap = {
      CONTENT_LOADED: event => this.handleDashboardLoaded(event),
      ERROR_OCCURRED: event => this.handleDashboardError(event)
    };
  }

  initState() {
    this.state = {
      url: '',
      dashboardLoaded: false,
      toUpdateDashboard: false,
      navigateFallbackMode: false,
      embeddingContext: null
    };
  }

  createContext() {
    createEmbeddingContext().then(embeddingContext => this.setState({ embeddingContext }));
  }

  setCallbacks() {
    // Note: Due to S3 bug 'onMessage' is currently not working
    this.dashboardEventPromise = messageEvent => {
      return new Promise(resolve => {
        const handler = this.dashboardEventsMap[messageEvent.eventName];
        if (handler) handler(messageEvent);
        resolve();
      });
    };

    this.setContainerRef = ref => {
      this.containerRef = ref;
    };

    this.startLoadingSpinner = () => this.props.dispatch(startDashboardLoadingSpinner());
    this.stopLoadingSpinner = () => this.props.dispatch(stopDashboardLoadingSpinner());

    this.handleDashboardLoaded = () => {
      this.stopLoadingSpinner();
    };

    this.qsNavigateBugOccurred = error => {
      return typeof error === 'string' && error.includes('timed out');
    };

    this.clearDashboardContainer = () => this.containerRef.replaceChildren();

    this.handleNavigateError = error => {
      if (this.qsNavigateBugOccurred(error)) {
        this.setState({ toUpdateDashboard: true, navigateFallbackMode: true });
        this.stopLoadingSpinner();
        console.error('handleNavigateError:', error);
        console.warn('QsDashboard: enabling navigate fallback mode');
      } else {
        this.handleDashboardError(error);
      }
    };

    this.handleDashboardError = error => {
      const { dispatch } = this.props;

      if (!this.dashboardInstance) {
        dispatch(setQuickSightError());
        this.setState({ dashboardLoaded: false });
      }
      this.stopLoadingSpinner();
      console.error('handleDashboardError:', error);
      addErrorMsg(error);
    };
  }

  getAwsUrl(props) {
    const { userEmail, dashboardId, companyId, subCompanyId, locale } = props;

    if (testProp.call(this, props, { userEmail, dashboardId, companyId, subCompanyId, locale })) {
      if (userEmail) this.setState({ toUpdateDashboard: true });
    }
  }

  getCompanyId() {
    const { companyId: id } = this.props;
    return id ? getTestingCompanyId(id) : idToFilterAll;
  }

  getSubCompanyId() {
    const { subCompanyId: id } = this.props;
    return id ? getTestingSubCompanyId(id) : idToFilterAll;
  }

  updateDashboard() {
    const { toUpdateDashboard, dashboardLoaded, navigateFallbackMode } = this.state;
    const { userEmail, dashboardId, subCompanyId, dispatch } = this.props;

    if (toUpdateDashboard && dashboardId) {
      this.setState({ toUpdateDashboard: false });

      if (dashboardLoaded && !navigateFallbackMode) {
        const locale = this.getQuickSightLocale();
        const parameters = [];

        parameters.push({
          Name: 'FrequencyControlLabel',
          Values: [getMsg('common.frequency')]
        });
        parameters.push({
          Name: 'DrillDownControlLabel',
          Values: [getMsg('common.level')]
        });
        parameters.push({
          Name: 'DateControlLabel',
          Values: [getMsg('common_date')]
        });

        parameters.push({
          Name: 'SuperCompanyParam',
          Values: [this.getCompanyId()]
        });
        parameters.push({
          Name: 'CompanyParam',
          Values: [this.getSubCompanyId()]
        });

        if (subCompanyId) {
          parameters.push({
            Name: 'DrillDownLevel',
            Values: ['Company']
          });
        } else {
          parameters.push({
            Name: 'DrillDownLevel',
            Values: ['Super company']
          });
        }

        if (locale) {
          parameters.push({
            Name: 'locale',
            Values: [locale]
          });
        }

        dispatch(clearQuickSightError());

        this.startLoadingSpinner();
        this.dashboardInstance
          .navigateToDashboard(dashboardId, { parameters })
          .then(this.stopLoadingSpinner)
          .catch(this.handleNavigateError);
      } else {
        if (navigateFallbackMode) {
          this.clearDashboardContainer();
          this.setState({ dashboardLoaded: false, url: '' });
        }
        dispatch(
          getQuickSightUrl({
            email: userEmail,
            reporting_id: dashboardId,
            companyId: this.getCompanyId(),
            subCompanyId: this.getSubCompanyId()
          })
        ).then(url => {
          this.setState({ url });
        }, fallbackFunc);
      }
    }
  }

  getQuickSightLocale() {
    const { locale } = this.props;
    return quickSightLocaleMap[locale];
  }

  embedDashboard() {
    const { url, dashboardLoaded, embeddingContext } = this.state;

    if (!dashboardLoaded && url && embeddingContext) {
      this.setState({ dashboardLoaded: true });
      this.startLoadingSpinner();

      const frameOptions = { url: this.state.url, container: this.containerRef };
      const contentOptions = { onMessage: this.dashboardEventPromise, locale: this.getQuickSightLocale() };

      embeddingContext
        .embedDashboard(frameOptions, contentOptions)
        .then(instance => {
          this.dashboardInstance = instance;
          this.stopLoadingSpinner();
        })
        .catch(this.handleDashboardError);
    }
  }

  render() {
    const { reportingError, dashboardId } = this.props;
    const error = reportingError || !dashboardId;

    return (
      <div className="qs-dashboard">
        <div className={cs('qs-container', { 'sc-hide': error })} ref={this.setContainerRef} />
        <div className={cs('qs-error', { 'sc-hide': !error })}>
          <div className="sc-msg">
            <h2>{getMsg('reporting.no.access.title')}</h2>
            <p>{getMsg('reporting.no.access.description')}</p>
          </div>
          <div className="sc-bg">
            <img alt="blurred-dasbhoard" src="img/placeholder/QuickSight.png" />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    locale: localeSelector(state),
    userEmail: reportingUserEmailSelector(state),
    reportingError: reportingErrorStatusSelector(state),
    companyId: validCompanyIdSelector(state),
    subCompanyId: validSubCompanyIdSelector(state)
  };
};

QsDashboard = connect(mapStateToProps)(QsDashboard);

export default QsDashboard;
