import React, { Component } from 'react';
import autoBind from 'react-autobind';
import pure from 'recompose/pure';
import { getAppFormattedDateTime, isEmpty, namedCompose, safe } from '../../utils/utils';
import { FormattedMessage, injectIntl } from 'react-intl';
import _forEach from 'lodash/forEach';
import Tooltip from '../Tooltip/Tooltip';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { STATUS_REFUSED } from '../../constants/backend-constants';
import { ORANGE, transactionStatus, transactionType } from '../../constants/options-constants';
import shortid from 'shortid';
import None from '../None';

class BookingTransactionInfo extends Component {
  constructor(props) {
    super(props);
    autoBind(this);

    this.displayProps = new Map();
    this.defaultDisplay = { singleRow: true, multiRow: true };

    this.setDisplayProp('orderCode', { key: 'booking_order_code' });
    this.setDisplayProp('type', { key: 'common_type', parse: this.parseType });
    this.setDisplayProp('date', { key: 'common_date', parse: this.parseDate });
    this.setDisplayProp('amount', { key: 'common_amount', parse: this.parseAmount });

    this.setDisplayProp('status', {
      key: 'common_status',
      parse: this.parseStatus,
      custom: this.customStatus,
      display: { multiRow: true },
      classNames: { td: 'status' }
    });

    this.setDisplayProp('paymentRefusalReason', { key: 'transaction_refusal_reason', display: { singleRow: true } });

    this.derivedStateFromProps(props);
  }

  componentWillReceiveProps(props) {
    this.derivedStateFromProps(props);
  }

  derivedStateFromProps(props) {
    this.bodyClass = 'bookingTransactionInfo_body';
    this.status = null;

    this.header = this.getHeader(props);
    this.rows = this.getRows(props);

    // should not be used in parsers
    this.displaySingleRow = this.rows.length === 1;

    if (this.displaySingleRow) {
      this.rows = this.getSingleRow(props);
    } else {
      this.bodyClass += '_3';
    }

    this.componentCanRender = this.checkComponentRender();
  }

  addDefaultOptions(o) {
    if (!o.parse) o.parse = v => v;
    if (!o.custom) o.custom = () => '';
    if (!o.classNames) o.classNames = {};
    if (!o.display) o.display = this.defaultDisplay;
  }

  setDisplayProp(key, options) {
    this.addDefaultOptions(options);
    this.displayProps.set(key, options);
  }

  getStatus(status) {
    const statusObject = transactionStatus[status];
    const statusKey = safe(() => statusObject.key) || status;
    const colorClass = safe(() => statusObject.class) || ORANGE;

    return (
      <span className={colorClass}>
        <FormattedMessage id={statusKey} />
      </span>
    );
  }

  checkComponentRender() {
    return this.rows.length !== 0 || this.status;
  }

  parseType(value) {
    const key = transactionType[value] || value;
    return <FormattedMessage id={key} />;
  }

  parseDate(value) {
    return getAppFormattedDateTime(value);
  }

  getRefusedStatus(status, transaction) {
    const statusObject = transactionStatus[status];
    const statusKey = safe(() => statusObject.key) || status;
    const colorClass = safe(() => statusObject.class) || ORANGE;
    const reason = safe(() => transaction.paymentRefusalReason);

    return (
      <span className={colorClass}>
        <Tooltip content={reason} placement="bottom">
          <span>
            <FormattedMessage id={statusKey} />
            <span className="refusal-tooltip">?</span>
          </span>
        </Tooltip>
      </span>
    );
  }

  customStatus(value) {
    this.status = value;
  }

  parseStatus(status, transaction) {
    if (status === STATUS_REFUSED) {
      return this.getRefusedStatus(status, transaction);
    } else {
      return this.getStatus(status);
    }
  }

  parseAmount(value, transaction) {
    const currencyType = safe(() => transaction.currency);
    const { formatMessage } = this.props.intl;
    let currencyKey = 'unit_EUR';

    if (currencyType) {
      currencyKey = 'unit_' + currencyType;
    }

    return formatMessage({ id: 'common_price_with_currency' }, { amount: String(value), currency: formatMessage({ id: currencyKey }) });
  }

  showStatus() {
    if (this.displaySingleRow && this.status) {
      return (
        <span>
          <span className="detailView_dash">-</span>
          <span className="detailView_component_headline_status">
            <Tooltip content={<FormattedMessage id="transaction_status" />} placement="bottom">
              {this.getStatus(this.status)}
            </Tooltip>
          </span>
        </span>
      );
    }
  }

  parseHeader(key) {
    return (
      <th key={key}>
        <h5>
          <FormattedMessage id={key} />
        </h5>
      </th>
    );
  }

  canAddMultiRow(transaction, v, k) {
    const tValue = safe(() => transaction[k]);
    return v.display.multiRow && !isEmpty(tValue);
  }

  canAddSingleRow(transaction, v, k) {
    const tValue = safe(() => transaction[k]);
    return v.display.singleRow && !isEmpty(tValue);
  }

  getHeader(props) {
    const headerTable = {};
    const header = [];

    _forEach(props.transactions, transaction => {
      this.displayProps.forEach((v, k) => {
        if (this.canAddMultiRow(transaction, v, k)) headerTable[k] = true;
      });
    });

    this.displayProps.forEach((v, k) => {
      if (headerTable[k]) header.push(this.parseHeader(v.key));
    });

    this.headerTable = headerTable;
    return header;
  }

  parseRow([props, value]) {
    if (props) {
      return (
        <td className={classNames('detailView_cell_type', props.classNames.td)} key={shortid.generate()}>
          <div className="mobile_label">
            <FormattedMessage id={props.key} />
          </div>
          <span>{value}</span>
        </td>
      );
    } else {
      return <td className="empty-td" key={shortid.generate()} />;
    }
  }

  parseSingleRow(key, value) {
    const tdClassName = 'detailView_td_type_1';
    return (
      <tr className="detailView_tr" key={shortid.generate()}>
        <td className={tdClassName}>
          <FormattedMessage id={key} />
        </td>
        <td className={tdClassName}>{value}</td>
      </tr>
    );
  }

  getSingleRow(props) {
    const rows = [];

    _forEach(props.transactions, transaction => {
      this.displayProps.forEach((v, k) => {
        const tValue = safe(() => transaction[k]);

        if (this.canAddSingleRow(transaction, v, k)) {
          rows.push(this.parseSingleRow(v.key, v.parse(tValue, transaction)));
        }

        if (v.custom) v.custom(tValue, transaction);
      });
    });

    return rows;
  }

  getRows(props) {
    const rows = [];

    _forEach(props.transactions, transaction => {
      const row = [];
      let rowEmpty = true;

      this.displayProps.forEach((v, k) => {
        const tValue = safe(() => transaction[k]);

        if (this.canAddMultiRow(transaction, v, k)) {
          row.push([v, v.parse(tValue, transaction)]);
          rowEmpty = false;
        } else if (this.headerTable[k]) row.push('');

        if (v.custom) v.custom(tValue, transaction);
      });

      if (!rowEmpty) {
        const htmlRow = [];

        row.forEach(array => htmlRow.push(this.parseRow(array)));
        rows.push(
          <tr className="detailView_tr_2" key={shortid.generate()}>
            {htmlRow}
          </tr>
        );
      }
    });

    return rows;
  }

  tHead() {
    if (!this.displaySingleRow)
      return (
        <thead>
          <tr>{this.header}</tr>
        </thead>
      );
  }

  render() {
    if (this.componentCanRender)
      return (
        <div
          className={classNames('bookingTransactionInfo', {
            table: !this.displaySingleRow,
            'type-1': !this.displaySingleRow
          })}
        >
          <h4 className={classNames('bookingTransactionInfo_headline', { 'table-headline': !this.displaySingleRow })}>
            <FormattedMessage id="booking_transaction_info" />
            {this.showStatus()}
          </h4>
          <div className={this.bodyClass}>
            <div className="detailView_table">
              <table>
                {this.tHead()}
                <tbody>{this.rows}</tbody>
              </table>
            </div>
          </div>
        </div>
      );
    else return <None />;
  }
}

BookingTransactionInfo.defaultProps = {
  transactions: []
};

// -----

BookingTransactionInfo.propTypes = {
  transactions: PropTypes.array,
  locale: PropTypes.string // locale reload
};

// -----

export default namedCompose(injectIntl, pure)(BookingTransactionInfo);
