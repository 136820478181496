import React, { Component, PropTypes } from 'react';
import routes from '../../../constants/routes-constants';
import SuperCompanyIcon from 'material-ui/svg-icons/communication/business';
import { fillWhite, dimensions20, marginBottom2 } from '../../../constants/style-constants';
import { addOpenNewTabEvents, hideButtonHoverMsg } from '../../../utils/utils';

class CompaniesResultsRow extends Component {
  constructor(props) {
    super(props);
    this.handleGoToDetail = this.handleGoToDetail.bind(this);
  }

  componentWillUnmount() {
    hideButtonHoverMsg();
  }

  handleGoToDetail(openInNewTab) {
    this.props.handleGoToDetail(routes.companyDetail.path, ':companyId', this.props.company.id, openInNewTab);
  }

  render() {
    const { company } = this.props;

    return (
      <div key={company.id} className="search-resultRow" {...addOpenNewTabEvents(this.handleGoToDetail)}>
        <div className="search-resultIcon purpleBg">
          <SuperCompanyIcon style={{ ...fillWhite, ...dimensions20, ...marginBottom2 }} />
        </div>
        <div className="search-resultCol bold">{company.name}</div>
        <div className="search-resultCol">{company.email}</div>
        <div className="search-resultCol">
          {company.phoneNumber.countryCode}
          {company.phoneNumber.nationalNumber}
        </div>
      </div>
    );
  }
}

CompaniesResultsRow.displayName = 'MemberResultsRow';

CompaniesResultsRow.propTypes = {
  company: PropTypes.object,
  handleGoToDetail: PropTypes.func
};

export default CompaniesResultsRow;
