import { UPDATE_LOCATION } from 'react-router-redux';
import _find from 'lodash/find';

import * as types from '../constants/actionTypes-constants';
import { INVOICE_CREATE_TITLE, INVOICE_CREATE_FIND_MEMBER_TITLE, INVOICE_DETAILS_TITLE } from '../constants/generic-constants';
import { safe } from '../utils/utils';

const defaultState = {
  listUrlV2: null,
  paginatedResults: null,
  loadingList: false,
  preview: false,
  invoice: {},
  errorList: false,
  urlParams: null,
  filters: undefined,
  currentSortedIndex: null,
  sortIsDescending: false,
  invoicesFiltersFormIsOpen: false,
  selectedMemberInfosOpened: false,
  useBookingDamageDeposit: false,
  titleID: INVOICE_CREATE_TITLE,
  stepTitleID: INVOICE_CREATE_FIND_MEMBER_TITLE,
  selectedBooking: null,
  exporting: null,
  noVatRateFound: null,
  invoiceItems: [],
  dmsInvoiceErrors: [],
  italianInvoiceError: null,
  italianInvoices: [],
  totalPrices: {
    totalItemPriceWithVat: 0
  }
};

export default function invoices(state = defaultState, action) {
  switch (action.type) {
    case types.INVOICES_UPDATE_FILTERS: {
      return { ...state, filters: action.filters };
    }

    case types.INVOICES_SET_STATUS_V2_CREATE: {
      return { ...state, listUrlV2: action.url };
    }

    case types.INVOICES_SAVE_INVOICE_DETAILS: {
      return { ...state, invoice: action.invoice };
    }

    case types.INVOICES_GET_LIST_REQUEST: {
      return {
        ...state,
        loadingList: true,
        errorList: false
      };
    }

    case types.INVOICES_GET_LIST_SUCCESS: {
      return Object.assign({}, state, {
        paginatedResults: action.paginatedObj,
        urlParams: action.urlParams,
        loadingList: false
      });
    }

    case types.INVOICES_GET_LIST_ERROR: {
      return {
        ...state,
        errorList: true
      };
    }

    case types.INVOICES_FILTERS_FORM_OPENED_STATE_TOGGLE: {
      return Object.assign({}, state, {
        invoicesFiltersFormIsOpen: !state.invoicesFiltersFormIsOpen
      });
    }

    case types.INVOICE_CREATE_CLEAR_CREATION_SESSION: {
      return {
        ...state,
        titleID: INVOICE_CREATE_TITLE,
        stepTitleID: INVOICE_CREATE_FIND_MEMBER_TITLE,
        selectedBooking: null,
        listUrlV2: null,
        noVatRateFound: false,
        invoiceItems: [],
        totalPrices: {
          totalItemPriceWithVat: 0
        }
      };
    }

    case types.INVOICE_GET_ITALIAN_STATUS_SUCCESS: {
      return {
        ...state,
        italianInvoiceError: _find(action.data, (data = {}) => data.errorDetails),
        italianInvoices: action.data
      };
    }

    case types.INVOICE_GET_DMS_STATUS_SUCCESS: {
      return { ...state, dmsInvoiceErrors: action.data };
    }

    case types.INVOICE_CLEAR_ITALIAN_STATUS: {
      return { ...state, italianInvoiceError: null, italianInvoices: [] };
    }

    case types.INVOICE_ADD_SET_SELECTED_BOOKING: {
      return Object.assign({}, state, {
        selectedBooking: action.selectedBooking,
        stepTitleID: INVOICE_DETAILS_TITLE
      });
    }

    case types.INVOICES_EXPORT_REQUEST: {
      return Object.assign({}, state, {
        exporting: true
      });
    }

    case types.INVOICES_EXPORT_FINISHED: {
      return Object.assign({}, state, {
        exporting: null
      });
    }

    case types.INVOICE_ADD_ITEM: {
      let invoiceItems = [...state.invoiceItems];
      invoiceItems.push(action.payload);
      return Object.assign({}, state, {
        invoiceItems,
        totalPrices: Object.assign({}, state.totalPrices, {
          totalItemPriceWithVat: state.totalPrices.totalItemPriceWithVat + action.payload.itemPriceWithVat
        })
      });
    }

    case types.INVOICE_DELETE_ITEM: {
      let invoicesItems = [...state.invoiceItems];
      invoicesItems.splice(invoicesItems.indexOf(action.payload), 1);

      return Object.assign({}, state, {
        invoiceItems: invoicesItems,
        totalPrices: Object.assign({}, state.totalPrices, {
          totalItemPriceWithVat: state.totalPrices.totalItemPriceWithVat - action.payload.itemPriceWithVat
        })
      });
    }

    case types.INVOICE_TOGGLE_DEPOSIT_USAGE: {
      return Object.assign({}, state, {
        useBookingDamageDeposit: !state.useBookingDamageDeposit
      });
    }

    case UPDATE_LOCATION: {
      return Object.assign({}, state, {
        currentSortedIndex: null,
        sortIsDescending: false,
        invoiceItems: [],
        totalPrices: {
          totalItemPriceWithVat: 0
        }
      });
    }

    case types.INVOICE_GET_VAT_RATE_FAILED: {
      return Object.assign({}, state, {
        noVatRateFound: true
      });
    }

    case types.INVOICE_PDF_VIEW_MODAL: {
      return { ...state, preview: !state.preview, invoice: action.invoice || {} };
    }

    case types.INVOICE_PAYMENT_REQUEST: {
      const invoice = state.invoice || {};
      const item = safe(() => state.paginatedResults.results.filter(res => res.id === action.id)[0]) || {};

      invoice.recallmoney = true;
      item.recallmoney = true;

      return {
        ...state,
        invoice: { ...invoice },
        loadingList: true
      };
    }

    case types.INVOICE_PAYMENT_SUCCESS: {
      const listInvoice = safe(() => state.paginatedResults.results.filter(res => res.id === action.invoiceId)[0]) || {};
      const invoice = state.invoice || {};

      delete listInvoice.recallmoney;
      listInvoice.status = 'PAID';

      delete invoice.recallmoney;
      invoice.status = 'PAID';

      return {
        ...state,
        invoice: { ...invoice },
        loadingList: false
      };
    }

    case types.INVOICE_PAYMENT_FAIL: {
      const invoiceFail = safe(() => state.paginatedResults.results.filter(res => res.id === action.invoiceId)[0]) || {};
      const invoice = state.invoice || {};

      delete invoiceFail.recallmoney;
      delete invoice.recallmoney;

      return {
        ...state,
        invoice: { ...invoice },
        loadingList: false
      };
    }

    default: {
      return state;
    }
  }
}
