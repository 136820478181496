import React, { Component } from 'react';
import ColumnList from '../../../components/_v2/ColumnList';
import { connect } from 'react-redux';

import { createMenuItems, resolveActive, resolveSelected, showNextView, showParentView } from '../../../components/_v2/ColumnList/helpers';
import { FormattedMessage } from 'react-intl';
import { renderItem } from './helpers';
import { subCompaniesListSelector } from '../../../selectors/all-selectors';

class OrganizationSubCompanies extends Component {
  componentWillMount() {
    this.setCallbacks();
    this.setVars();
  }

  setVars() {
    this.menuItems = createMenuItems({ onAdd: this.handleAdd, addKey: 'sub_companies_add' });
  }

  setCallbacks() {
    this.handleSelect = ({ id }, _, { openInNewTab }) => {
      showNextView.call(this, 'organizationSites', ':subCompanyId', id, openInNewTab);
    };

    this.handleEdit = ({ id } = {}, _, { openInNewTab }) => {
      showNextView.call(this, 'organizationEditSubCompany', ':subCompanyId', id, openInNewTab);
    };

    this.handleAdd = () => {
      showNextView.call(this, 'organizationAddSubCompany');
    };

    this.resolveSelected = resolveSelected.bind(this);
    this.resolveActive = resolveActive.bind(this);
    this.showParentView = showParentView.bind(this);
  }

  render() {
    return (
      <ColumnList
        title={<FormattedMessage id="common_sub_companies" />}
        inputRef={this.props.inputRef}
        items={this.props.list}
        renderItem={renderItem}
        onSelect={this.handleSelect}
        selected={this.resolveSelected}
        active={this.resolveActive}
        onEdit={this.handleEdit}
        menuItems={this.menuItems}
        onBack={this.showParentView}
        localSearchEnabled
      />
    );
  }
}

const mapStateToProps = state => {
  return { list: subCompaniesListSelector(state) };
};

OrganizationSubCompanies = connect(mapStateToProps)(OrganizationSubCompanies);

export default OrganizationSubCompanies;
