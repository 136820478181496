import routes from '../constants/routes-constants';
import Layout from '../containers/Layout';
import Version from '../containers/Version/Version';
import Redirect from '../containers/Redirect/Redirect';
import Login from '../containers/Login/Login';
import ResetPassword from '../containers/ResetPassword/ResetPassword';
import Fleet from '../containers/Fleet/Fleet';
import AddVehicle from '../containers/AddVehicle/AddVehicle';
import EditVehicle from '../containers/EditVehicle/EditVehicle';
import VehicleDetail from '../containers/VehicleDetail/VehicleDetail';
import Account from '../containers/Account/Account';
import BackUsers from '../containers/BackUsers/BackUsers';
import AddBackUser from '../containers/AddBackUser/AddBackUser';
import AddMember from '../containers/AddMember/AddMember';
import Members from '../containers/Members/Members';
import Smartcards from '../containers/Smartcards';
import SmartcardsDetail from '../containers/SmartcardsDetail';
import SmartcardsEvents from '../containers/SmartcardsEvents';
import MemberTypes from '../containers/MemberTypes/MemberTypes';
import MemberTypeForm from '../containers/MemberTypes/components/MemberTypeForm';
import Bookings from '../containers/Bookings/Bookings';
import AddBooking from '../containers/AddBooking/AddBooking';
import BookingFindMember from '../components/BookingFindMember/BookingFindMember';
import BookingFindVehicle from '../components/BookingFindVehicle/BookingFindVehicle';
import InvoiceFindBooking from '../containers/Invoices/components/InvoiceFindBooking/InvoiceFindBooking';
import MemberView from '../containers/MemberView/MemberView';
import MemberProfileHistory from '../containers/MemberProfileHistory/MemberProfileHistory';
import CreateInvoice from '../components/CreateInvoice/CreateInvoice';
import Companies from '../containers/Companies/Companies';
import AddCompany from '../containers/AddCompany/AddCompany';
import CompanyDetail from '../containers/CompanyDetail/CompanyDetail';
import AddInvoice from '../containers/AddInvoice/AddInvoice';

import SubCompanies from '../modules/SubCompanies/containers/SubCompanies/SubCompanies';
import AddSubCompany from '../modules/SubCompanies/containers/AddSubCompany/AddSubCompany';
import SubCompanyDetail from '../modules/SubCompanies/containers/SubCompanyDetail/SubCompanyDetail';

import Dashboard from '../containers/Dashboard/Dashboard';
import DashboardV2 from '../containers/_v2/Dashboard';
import Pricing from '../containers/Pricing/Pricing';
import Sites from '../containers/Sites/Sites';
import Parkings from '../containers/Parkings/Parkings';
import AddSite from '../containers/AddSite/AddSite';
import SiteDetails from '../containers/SiteDetails/SiteDetails';
import AddParking from '../containers/AddParking/AddParking';
import ParkingDetails from '../containers/ParkingDetails/ParkingDetails';
import Contract from '../containers/Contract/Contract';
import VehiclesColors from '../containers/VehiclesColors/VehiclesColors';
import AddVehiclesColor from '../containers/AddVehiclesColor/AddVehiclesColor';
import EditVehiclesColor from '../containers/EditVehiclesColor/EditVehiclesColor';
import Brands from '../containers/Brands/Brands';
import AddBrand from '../containers/AddBrand/AddBrand';
import EditBrand from '../containers/EditBrand/EditBrand';
import VehiclesCategories from '../containers/VehiclesCategories/VehiclesCategories';
import AddVehiclesCategory from '../containers/AddVehiclesCategory/AddVehiclesCategory';
import EditVehiclesCategory from '../containers/EditVehiclesCategory/EditVehiclesCategory';
import AddModel from '../containers/AddModel/AddModel';
import EditModel from '../containers/EditModel/EditModel';
import AddVersion from '../containers/AddVersion/AddVersion';
import EditVersion from '../containers/EditVersion/EditVersion';
import Feedbacks from '../containers/Feedbacks/Feedbacks';
import FeedbackDetail from '../containers/FeedbackDetail/FeedbackDetail';
import EditBackUser from '../containers/EditBackUser/EditBackUser';

import Invoices from '../containers/Invoices/Invoices';
import Configurations from '../containers/Configurations/Configurations';
import AddConfiguration from '../containers/AddConfiguration/AddConfiguration';
import EditConfiguration from '../containers/EditConfiguration/EditConfiguration';
import Hotlines from '../containers/Hotlines/Hotlines';
import AddHotline from '../containers/AddHotline/AddHotline';
import EditHotline from '../containers/EditHotline/EditHotline';
import VehicleDamages from '../containers/VehicleDamages/VehicleDamages';
import VehicleDamageDetail from '../containers/VehicleDamageDetail/VehicleDamageDetail';
import VoucherList from '../containers/Voucher/VoucherList/VoucherList';
import VoucherDetail from '../containers/Voucher/VoucherDetail/VoucherDetail';
import AddVoucherGroup from '../containers/Voucher/AddVoucherGroup/AddVoucherGroup';
import VehiclePlanningV2 from '../containers/_v2/VehiclePlanning/VehiclePlanning';
import BackUserView from '../containers/BackUserView/BackUserView';
import BookingView from '../containers/BookingView/BookingView';
import BookingViewV2 from '../containers/_v2/BookingView/BookingView';
import VehicleStatuses from '../containers/VehicleStatuses/VehicleStatuses';
import UploadMember from '../containers/UploadMember/UploadMember';
import BookingEventsHistory from '../containers/BookingEventsHistory/BookingEventsHistory';
import BankoutList from '../containers/BankoutList/BankoutList';
import BankoutView from '../containers/BankoutView/BankoutView';

import { onEnterDefault, onEnterNoRoute, onEnterRoute } from './hooks-routing';
import { getOrganizationRedirectPath, getQuickSightRedirectPath } from './utils-routing';
import { apiParams } from '../constants/api-params-constants';

import urlJoin from 'url-join';

import Organization from '../containers/_v2/Organization';

import OrganizationBrands from '../containers/_v2/Organization/Brands';
import OrganizationEditBrand from '../containers/_v2/Organization/Details/Brand/Edit';
import OrganizationAddBrand from '../containers/_v2/Organization/Details/Brand/Add';

import OrganizationSuperCompanies from '../containers/_v2/Organization/SuperCompanies';
import OrganizationAddSuperCompany from '../containers/_v2/Organization/Details/SuperCompany/Add';
import OrganizationEditSuperCompany from '../containers/_v2/Organization/Details/SuperCompany/Edit';

import OrganizationSubCompanies from '../containers/_v2/Organization/SubCompanies';
import OrganizationAddSubCompany from '../containers/_v2/Organization/Details/SubCompany/Add';
import OrganizationEditSubCompany from '../containers/_v2/Organization/Details/SubCompany/Edit';

import OrganizationSites from '../containers/_v2/Organization/Sites';
import OrganizationAddSite from '../containers/_v2/Organization/Details/Site/Add';
import OrganizationEditSite from '../containers/_v2/Organization/Details/Site/Edit';
import OrganizationCopySite from '../containers/_v2/Organization/Details/Site/CopyFrom';

import OrganizationParkings from '../containers/_v2/Organization/Parkings';
import OrganizationAddParking from '../containers/_v2/Organization/Details/Parking/Add';
import OrganizationEditParking from '../containers/_v2/Organization/Details/Parking/Edit';

import VehicleBrands from '../containers/_v2/VehicleBrands';
import BrandsList from '../containers/_v2/VehicleBrands/Brands';
import VehicleModels from '../containers/_v2/VehicleBrands/Models';
import VehicleVersions from '../containers/_v2/VehicleBrands/Versions';
import VehicleEditBrand from '../containers/_v2/VehicleBrands/Details/Brand/Edit';
import VehicleEditModel from '../containers/_v2/VehicleBrands/Details/Model/Edit';
import VehicleEditVersion from '../containers/_v2/VehicleBrands/Details/Version/Edit';
import VehicleAddBrand from '../containers/_v2/VehicleBrands/Details/Brand/Add';
import VehicleAddModel from '../containers/_v2/VehicleBrands/Details/Model/Add';
import VehicleAddVersion from '../containers/_v2/VehicleBrands/Details/Version/Add';
import Support from '../containers/_v2/Support/Support';
import HotlinesList from '../containers/_v2/Support/HotlinesList';
import HotlineEdit from '../containers/_v2/Support/Hotline/Edit';
import HotlineAdd from '../containers/_v2/Support/Hotline/Add';
import ConfigurationsList from '../containers/_v2/Support/ConfigurationsList';
import ConfigurationEdit from '../containers/_v2/Support/Configuration/Edit';
import ConfigurationAdd from '../containers/_v2/Support/Configuration/Add';

import ColorsList from '../containers/_v2/VehicleColors/Colors';
import VehicleColors from '../containers/_v2/VehicleColors';
import VehicleAddColor from '../containers/_v2/VehicleColors/Details/Add';
import VehicleEditColor from '../containers/_v2/VehicleColors/Details/Edit';

import VehicleCategories from '../containers/_v2/VehicleCategories';
import CategoriesList from '../containers/_v2/VehicleCategories/Categories';
import VehicleAddCategory from '../containers/_v2/VehicleCategories/Details/Add';
import VehicleEditCategory from '../containers/_v2/VehicleCategories/Details/Edit';
import EditMemberFiles from '../containers/_v2/EditMemberFiles';

import Vouchers from '../containers/_v2/Vouchers';
import VoucherGroups from '../containers/_v2/Vouchers/Groups';
import VouchersAddGroup from '../containers/_v2/Vouchers/Details/Add';
import VouchersEditGroup from '../containers/_v2/Vouchers/Details/Edit';

import BookingsV2 from '../containers/_v2/Bookings';
import BookingsList from '../containers/_v2/Bookings/List';
import BookingDetails from '../containers/_v2/Bookings/Details';

import InvoicesV2 from '../containers/_v2/Invoices';
import InvoicesList from '../containers/_v2/Invoices/List';
import InvoiceDetails from '../containers/_v2/Invoices/Details';
import ContractV2 from '../containers/_v2/Contract';
import QuickSight from '../containers/QuickSight';
import VehiclePlanning from '../containers/VehiclePlanning/VehiclePlanning';
import QsDashboard from '../containers/QuickSight/Dasbhoard';
import { URL_ALL } from '../constants/backend-constants';
import ScheduledExport from '../containers/ScheduledExport';
import ScheduledExportList from '../containers/ScheduledExport/List';
import ScheduledExportAddConfig from '../containers/ScheduledExport/Details/Add';
import ScheduledExportViewConfig from '../containers/ScheduledExport/Details/View';

const redirectTo = route => (nextState, replace) => {
  replace({ nextPathname: nextState.location.pathname }, urlJoin(nextState.location.pathname, route));
};

const indexRedirect = route => ({ onEnter: redirectTo(route) });

const encodedDefaultParams = encodeURIComponent(JSON.stringify(apiParams.default));
const findMemberRedirectPath = routes.bookingFindMember.path.replace(':search', encodedDefaultParams);

const addPath = path => {
  return { path: routes[path].path, onEnter: onEnterRoute(path) };
};

const routesConfig = [
  {
    path: '/',
    component: Layout,
    indexRoute: {
      onEnter: onEnterNoRoute()
    },
    childRoutes: [
      {
        path: routes.version.path,
        onEnter: onEnterDefault(),
        component: { page: Version }
      },
      {
        ...addPath('redirect'),
        component: { page: Redirect }
      },
      {
        ...addPath('login'),
        component: { page: Login }
      },
      {
        ...addPath('resetPassword'),
        component: { page: ResetPassword }
      },
      {
        ...addPath('vehiclePlanning'),
        component: { page: VehiclePlanning }
      },
      {
        ...addPath('vehiclePlanningV2'),
        component: { page: VehiclePlanningV2 }
      },
      {
        ...addPath('fleet'),
        component: { page: Fleet }
      },
      {
        ...addPath('addVehicle'),
        component: { page: AddVehicle }
      },
      {
        ...addPath('backUsers'),
        component: { page: BackUsers }
      },
      {
        ...addPath('addBackUser'),
        component: { page: AddBackUser }
      },
      {
        ...addPath('members'),
        component: { page: Members }
      },
      {
        ...addPath('smartcards'),
        component: { page: Smartcards }
      },
      {
        ...addPath('uploadMember'),
        component: { page: UploadMember }
      },
      {
        ...addPath('addMember'),
        component: { page: AddMember }
      },
      {
        ...addPath('smartcardsDetail'),
        component: { page: SmartcardsDetail }
      },
      {
        ...addPath('smartcardsEvents'),
        component: { page: SmartcardsEvents }
      },
      {
        ...addPath('account'),
        component: { page: Account }
      },
      {
        ...addPath('pricing'),
        component: { page: Pricing }
      },
      {
        ...addPath('memberTypes'),
        component: { page: MemberTypes }
      },
      {
        ...addPath('bookings'),
        component: { page: Bookings }
      },
      {
        ...addPath('editBooking'),
        component: { page: AddBooking }
      },
      {
        path: routes.addBooking.path,
        component: { page: AddBooking },
        indexRoute: indexRedirect(findMemberRedirectPath),
        childRoutes: [
          {
            ...addPath('bookingFindMember'),
            component: { page: BookingFindMember }
          },
          {
            ...addPath('bookingFindVehicle'),
            component: { page: BookingFindVehicle }
          }
        ]
      },
      {
        ...addPath('bookingEventsHistory'),
        component: { page: BookingEventsHistory }
      },
      {
        ...addPath('companies'),
        component: { page: Companies }
      },
      {
        ...addPath('addCompany'),
        component: { page: AddCompany }
      },
      {
        ...addPath('subCompanies'),
        component: { page: SubCompanies }
      },
      {
        ...addPath('addSubCompany'),
        component: { page: AddSubCompany }
      },
      {
        ...addPath('subCompanyDetails'),
        component: { page: SubCompanyDetail }
      },
      {
        ...addPath('dashboard'),
        component: { page: Dashboard }
      },
      {
        ...addPath('dashboardV2'),
        component: { page: DashboardV2 }
      },
      {
        ...addPath('backUserDetail'),
        component: { page: BackUserView }
      },
      {
        ...addPath('memberDetail'),
        component: { page: MemberView }
      },
      {
        ...addPath('memberProfileHistory'),
        component: { page: MemberProfileHistory }
      },
      {
        ...addPath('vehicleDetail'),
        component: { page: VehicleDetail }
      },
      {
        ...addPath('companyDetail'),
        component: { page: CompanyDetail }
      },
      {
        ...addPath('sites'),
        component: { page: Sites }
      },
      {
        ...addPath('parkings'),
        component: { page: Parkings }
      },
      {
        ...addPath('addSite'),
        component: { page: AddSite }
      },
      {
        ...addPath('siteDetails'),
        component: { page: SiteDetails }
      },
      {
        ...addPath('addParking'),
        component: { page: AddParking }
      },
      {
        ...addPath('parkingDetails'),
        component: { page: ParkingDetails }
      },
      {
        ...addPath('contract'),
        component: { page: Contract }
      },
      {
        ...addPath('contractV2'),
        component: { page: ContractV2 }
      },
      {
        ...addPath('editVehicle'),
        component: { page: EditVehicle }
      },
      {
        ...addPath('vehiclesColors'),
        component: { page: VehiclesColors }
      },
      {
        ...addPath('addVehiclesColor'),
        component: { page: AddVehiclesColor }
      },
      {
        ...addPath('editVehiclesColor'),
        component: { page: EditVehiclesColor }
      },
      {
        ...addPath('brands'),
        component: { page: Brands }
      },
      {
        ...addPath('editMemberFiles'),
        component: { page: EditMemberFiles }
      },
      {
        ...addPath('support'),
        component: { page: Support },
        childRoutes: [
          {
            ...addPath('hotlinesV2'),
            component: { child: HotlinesList },
            params: { tab: 'hotlines' },
            childRoutes: [
              {
                ...addPath('hotlineEdit'),
                component: { child: HotlineEdit }
              },
              {
                ...addPath('hotlineAdd'),
                component: { child: HotlineAdd }
              }
            ]
          },
          {
            ...addPath('configurationsV2'),
            component: { child: ConfigurationsList },
            params: { tab: 'configurations' },
            childRoutes: [
              {
                ...addPath('configurationEdit'),
                component: { child: ConfigurationEdit }
              },
              {
                ...addPath('configurationAdd'),
                component: { child: ConfigurationAdd }
              }
            ]
          }
        ]
      },
      {
        ...addPath('vehicleCategories'),
        component: { page: VehicleCategories },
        indexRoute: indexRedirect(routes.categoriesList.path),
        childRoutes: [
          {
            ...addPath('categoriesList'),
            component: { child: CategoriesList },
            childRoutes: [
              {
                path: routes.vehicleAddCategory.path,
                component: { child: VehicleAddCategory }
              },
              {
                ...addPath('vehicleEditCategory'),
                component: { child: VehicleEditCategory }
              }
            ]
          }
        ]
      },
      {
        ...addPath('vehicleColors'),
        component: { page: VehicleColors },
        indexRoute: indexRedirect(routes.colorsList.path),
        childRoutes: [
          {
            ...addPath('colorsList'),
            component: { child: ColorsList },
            childRoutes: [
              {
                path: routes.vehicleAddColor.path,
                component: { child: VehicleAddColor }
              },
              {
                ...addPath('vehicleEditColor'),
                component: { child: VehicleEditColor }
              }
            ]
          }
        ]
      },
      {
        ...addPath('scheduledExport'),
        component: { page: ScheduledExport },
        indexRoute: indexRedirect(routes.scheduledExportList.path.replace(':exportsFilters', URL_ALL)),
        childRoutes: [
          {
            ...addPath('scheduledExportList'),
            component: { child: ScheduledExportList },
            childRoutes: [
              {
                path: routes.scheduledExportAddConfig.path,
                component: { child: ScheduledExportAddConfig }
              },
              {
                ...addPath('scheduledExportViewConfig'),
                component: { child: ScheduledExportViewConfig }
              }
            ]
          }
        ]
      },
      {
        ...addPath('bookingsV2'),
        component: { page: BookingsV2 },
        indexRoute: indexRedirect(routes.bookingsList.path.replace(':bookingsFilters', URL_ALL)),
        childRoutes: [
          {
            ...addPath('bookingsList'),
            component: { child: BookingsList },
            childRoutes: [
              {
                ...addPath('bookingDetails'),
                component: { child: BookingDetails }
              }
            ]
          }
        ]
      },
      {
        ...addPath('invoicesV2'),
        component: { page: InvoicesV2 },
        indexRoute: indexRedirect(routes.invoicesList.path.replace(':invoicesFilters', URL_ALL)),
        childRoutes: [
          {
            ...addPath('invoicesList'),
            component: { child: InvoicesList },
            childRoutes: [
              {
                ...addPath('invoiceDetails'),
                component: { child: InvoiceDetails }
              }
            ]
          }
        ]
      },
      {
        ...addPath('vouchersV2'),
        component: { page: Vouchers },
        indexRoute: indexRedirect(routes.voucherGroups.path.replace(':groupsFilters', URL_ALL)),
        childRoutes: [
          {
            ...addPath('voucherGroups'),
            component: { child: VoucherGroups },
            childRoutes: [
              {
                ...addPath('vouchersAddGroup'),
                component: { child: VouchersAddGroup }
              },
              {
                ...addPath('vouchersEditGroup'),
                component: { child: VouchersEditGroup }
              }
            ]
          }
        ]
      },
      {
        ...addPath('organization'),
        component: { page: Organization },
        indexRoute: indexRedirect(getOrganizationRedirectPath()),
        childRoutes: [
          {
            ...addPath('organizationBrands'),
            component: { child: OrganizationBrands },
            childRoutes: [
              {
                path: routes.organizationAddBrand.path,
                component: { child: OrganizationAddBrand }
              },
              {
                path: routes.organizationEditBrand.path,
                component: { child: OrganizationEditBrand }
              },
              {
                ...addPath('organizationSuperCompanies'),
                component: { child: OrganizationSuperCompanies },
                childRoutes: [
                  {
                    ...addPath('organizationAddSuperCompany'),
                    component: { child: OrganizationAddSuperCompany }
                  },
                  {
                    ...addPath('organizationEditSuperCompany'),
                    component: { child: OrganizationEditSuperCompany }
                  },
                  {
                    ...addPath('organizationSubCompanies'),
                    component: { child: OrganizationSubCompanies },
                    childRoutes: [
                      {
                        ...addPath('organizationAddSubCompany'),
                        component: { child: OrganizationAddSubCompany }
                      },
                      {
                        ...addPath('organizationEditSubCompany'),
                        component: { child: OrganizationEditSubCompany }
                      },
                      {
                        ...addPath('organizationSites'),
                        component: { child: OrganizationSites },
                        childRoutes: [
                          {
                            ...addPath('organizationAddSite'),
                            component: { child: OrganizationAddSite }
                          },
                          {
                            ...addPath('organizationEditSite'),
                            component: { child: OrganizationEditSite }
                          },
                          {
                            ...addPath('organizationCopySite'),
                            component: { child: OrganizationCopySite }
                          },
                          {
                            ...addPath('organizationParkings'),
                            component: { child: OrganizationParkings },
                            childRoutes: [
                              {
                                ...addPath('organizationAddParking'),
                                component: { child: OrganizationAddParking }
                              },
                              {
                                ...addPath('organizationEditParking'),
                                component: { child: OrganizationEditParking }
                              }
                            ]
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        ...addPath('vehicleBrands'),
        component: { page: VehicleBrands },
        indexRoute: indexRedirect(routes.brandsList.path),
        childRoutes: [
          {
            ...addPath('brandsList'),
            component: { child: BrandsList },
            childRoutes: [
              {
                path: routes.vehicleAddBrand.path,
                component: { child: VehicleAddBrand }
              },
              {
                ...addPath('vehicleEditBrand'),
                component: { child: VehicleEditBrand }
              },
              {
                ...addPath('vehicleModels'),
                component: { child: VehicleModels },
                childRoutes: [
                  {
                    ...addPath('vehicleAddModel'),
                    component: { child: VehicleAddModel }
                  },
                  {
                    ...addPath('vehicleEditModel'),
                    component: { child: VehicleEditModel }
                  },
                  {
                    ...addPath('vehicleVersions'),
                    component: { child: VehicleVersions },
                    childRoutes: [
                      {
                        ...addPath('vehicleAddVersion'),
                        component: { child: VehicleAddVersion }
                      },
                      {
                        ...addPath('vehicleEditVersion'),
                        component: { child: VehicleEditVersion }
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        ...addPath('addBrand'),
        component: { page: AddBrand }
      },
      {
        ...addPath('editBrand'),
        component: { page: EditBrand }
      },
      {
        ...addPath('vehiclesCategories'),
        component: { page: VehiclesCategories }
      },
      {
        ...addPath('addVehiclesCategory'),
        component: { page: AddVehiclesCategory }
      },
      {
        ...addPath('editVehiclesCategory'),
        component: { page: EditVehiclesCategory }
      },
      {
        ...addPath('addModel'),
        component: { page: AddModel }
      },
      {
        ...addPath('editModel'),
        component: { page: EditModel }
      },
      {
        ...addPath('addVersion'),
        component: { page: AddVersion }
      },
      {
        ...addPath('editVersion'),
        component: { page: EditVersion }
      },
      {
        ...addPath('feedbacks'),
        component: { page: Feedbacks }
      },
      {
        ...addPath('feedbackDetail'),
        component: { page: FeedbackDetail }
      },
      {
        ...addPath('editBackUser'),
        component: { page: EditBackUser }
      },
      {
        ...addPath('invoices'),
        component: { page: Invoices }
      },
      {
        ...addPath('bookingDetail'),
        component: { page: BookingView }
      },
      {
        ...addPath('bookingDetailV2'),
        component: { page: BookingViewV2 }
      },
      {
        ...addPath('configurations'),
        component: { page: Configurations }
      },
      {
        ...addPath('addConfiguration'),
        component: { page: AddConfiguration }
      },
      {
        ...addPath('editConfiguration'),
        component: { page: EditConfiguration }
      },
      {
        ...addPath('hotlines'),
        component: { page: Hotlines }
      },
      {
        ...addPath('addHotline'),
        component: { page: AddHotline }
      },
      {
        ...addPath('editHotline'),
        component: { page: EditHotline }
      },
      {
        ...addPath('vehicleStatuses'),
        component: { page: VehicleStatuses }
      },
      {
        ...addPath('quickSight'),
        component: { page: QuickSight },
        indexRoute: indexRedirect(routes.qsCompletedBooking.path),
        childRoutes: [
          {
            path: routes.qsCompletedBooking.path,
            component: { page: QsDashboard }
          },
          {
            path: routes.qsAverageDuration.path,
            component: { page: QsDashboard }
          },
          {
            path: routes.qsAverageDistance.path,
            component: { page: QsDashboard }
          },
          {
            path: routes.qsCanceledBooking.path,
            component: { page: QsDashboard }
          },
          {
            path: routes.qsLateBooking.path,
            component: { page: QsDashboard }
          },
          {
            path: routes.qsLateBookingOvertime.path,
            component: { page: QsDashboard }
          },
          {
            path: routes.qsBookingPerVehicle.path,
            component: { page: QsDashboard }
          },
          {
            path: routes.qsBusinessVsPrivate.path,
            component: { page: QsDashboard }
          },
          {
            path: routes.qsTurnover.path,
            component: { page: QsDashboard }
          },
          {
            path: routes.qsActiveMembers.path,
            component: { page: QsDashboard }
          },
          {
            path: routes.qsNewMembers.path,
            component: { page: QsDashboard }
          }
        ]
      },
      {
        ...addPath('vehicleDamages'),
        component: { page: VehicleDamages }
      },
      {
        ...addPath('vehicleDamageDetail'),
        component: { page: VehicleDamageDetail }
      },
      {
        ...addPath('bankouts'),
        component: { page: BankoutList }
      },
      {
        ...addPath('bankoutDetails'),
        component: { page: BankoutView }
      },
      {
        ...addPath('vouchers'),
        component: { page: VoucherList }
      },
      {
        ...addPath('voucherGroupDetail'),
        component: { page: VoucherDetail }
      },
      {
        ...addPath('addVoucherGroup'),
        component: { page: AddVoucherGroup }
      },
      {
        path: routes.addInvoice.path,
        component: { page: AddInvoice },
        indexRoute: {
          // url /add-invoice is just a wrapper for booking creation steps components
          // as there is nothing to see here, just redirect to first step : finding a member.
          onEnter: (nextState, replace) => {
            replace(
              {
                nextPathname: nextState.location.pathname
              },
              `${routes.addInvoice.path}/${routes.invoiceFindBooking.path.replace(
                ':search',
                encodeURIComponent(JSON.stringify(apiParams.default))
              )}`
            );
          }
        },
        childRoutes: [
          {
            ...addPath('invoiceFindBooking'),
            component: { page: InvoiceFindBooking }
          },
          {
            ...addPath('createInvoice'),
            component: { page: CreateInvoice }
          }
        ]
      },
      {
        path: '*',
        onEnter: onEnterNoRoute()
      }
    ]
  }
];

export function getRoutesConfig() {
  return routesConfig;
}
