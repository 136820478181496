import React, { Component, PropTypes } from 'react';
import { FlatButton } from 'material-ui';
import { FormattedMessage } from 'react-intl';
import cs from 'classnames';

export class ListDetails extends Component {
  render() {
    return (
      <section className={cs('list-details', this.props.className)}>
        <div className="sc-title-wrap">
          <div className="sc-title">{this.props.title}</div>
          <FlatButton className="close-button" label={<FormattedMessage id="common_close" />} onClick={this.props.onClose} primary />
        </div>
        <div className="sc-main-wrap">{this.props.children}</div>
      </section>
    );
  }
}

export default ListDetails;

ListDetails.defaultProps = {
  title: 'Title'
};

ListDetails.propTypes = {
  className: PropTypes.string,
  title: PropTypes.any,
  onClose: PropTypes.func
};
