import React, { Component, PropTypes } from 'react';
import { reduxForm, initialize, change } from 'redux-form';
import BoxedInput from '../../BoxedInput/BoxedInput';
import BoxedSelect from '../../BoxedSelect/BoxedSelect';
import FileUpload from '../../FileUlpoad/FileUpload';
import { enhanceHomepageData, scrollToFirstError } from '../../../utils/utils';
import TranslationsFields from '../TranslationsFields';
import { FlatButton, RaisedButton } from 'material-ui';
import { getMsg, getHtmlMsg } from '../../../utils/IntlGlobalProvider';
import Link from 'material-ui/svg-icons/content/link';
import { sizeOptions, prefillOptions } from '../../../constants/options-constants';
import { template } from '../../../constants/mobilesettings-constants';
import { createValidator, notEmpty, genericImage } from '../../../validation/sync-validation';
import FieldErrorMsg from '../../FieldErrorMsg/FieldErrorMsg';

class HomepageSettingsForm extends Component {
  constructor(props) {
    super(props);
    this.populate = this.populate.bind(this);
    this.prefilled = this.prefilled.bind(this);
    this.state = { prefilled: false };
  }

  populate(e) {
    const { dispatch } = this.props;
    e.preventDefault();
    this.model = enhanceHomepageData(template[this.props.values.prefill]);
    dispatch(
      initialize('HomepageSettingsForm', this.model, [
        'name',
        'imageUrl',
        'serviceNames[]',
        'serviceNames[].language',
        'serviceNames[].translation',
        'descriptions[]',
        'descriptions[].language',
        'descriptions[].translation',
        'options.size',
        'action'
      ])
    );
    this.setState({ prefilled: true });
    this.props.onPopulate(this.model);
  }

  prefilled() {
    this.setState({ prefilled: false });
  }

  render() {
    const {
      fields: { name, options, imageUrl, serviceNames, descriptions, prefill },
      handleSubmit,
      onClose
    } = this.props;
    return (
      <div className="hs-form-wrapper">
        <form action="#" className="HomepageSettings-form" onSubmit={handleSubmit(this.props.onCallback)}>
          <div className="hs-name-action">
            <BoxedInput customClass="col" name="nameAsTitle" mandatory block labelKey="common_name" field={name}>
              <FieldErrorMsg field={name} customClass="fieldErrorMsg--companyForm" />
            </BoxedInput>

            <RaisedButton
              className="actions-wrapper"
              label={getHtmlMsg('contrat.homesetting.define-action-btn-label')}
              onClick={this.props.onOpenActionForm}
              labelPosition="before"
              icon={<Link />}
              primary
            />
          </div>
          <div className="prefill">
            <BoxedSelect
              options={prefillOptions}
              id="prefill-service"
              blockInnerCustomClass="half-row"
              onChange={this.prefilled}
              label={getMsg('contract.homesetting.prefill')}
              noEmptyValue
              field={prefill}
            />
            <FlatButton
              label={
                <span>
                  {getMsg('contract.homesetting.prefill')} {this.state.prefilled && '✓'}
                </span>
              }
              onClick={this.populate}
              secondary
            />
          </div>
          <div>
            <TranslationsFields keyLabel="common_name" serviceNames={serviceNames} />
          </div>
          <div>
            <TranslationsFields serviceNames={descriptions} keyLabel="common_description" fieldType="textarea" />
          </div>
          <div>
            <BoxedSelect
              options={sizeOptions}
              id="size-service"
              blockInnerCustomClass="full-row"
              label={getMsg('common_size')}
              noEmptyValue
              field={options.size}
            />
          </div>
          <div>
            <FileUpload label="common_image" id="image" field={imageUrl} isMandatory mandatory noPadding getFileCb />
          </div>
          <div className="hs-actions">
            <FlatButton label={getMsg('common_cancel')} onClick={onClose} secondary />
            <RaisedButton label={getMsg('common_save')} type="submit" primary />
          </div>
        </form>
      </div>
    );
  }
}

HomepageSettingsForm.displayName = 'HomepageSettingsForm';

HomepageSettingsForm.propTypes = {
  fields: PropTypes.object,
  handleSubmit: PropTypes.func,
  onCallback: PropTypes.func,
  populate: PropTypes.func,
  onAbort: PropTypes.func
};

HomepageSettingsForm = reduxForm({
  onSubmitFail: scrollToFirstError,
  form: 'HomepageSettingsForm',
  fields: [
    'id',
    'name',
    'imageUrl',
    'serviceNames[]',
    'serviceNames[].language',
    'serviceNames[].translation',
    'descriptions[]',
    'descriptions[].language',
    'descriptions[].translation',
    'options.size',
    'action',
    'prefill'
  ],
  validate: createValidator({
    name: [notEmpty()],
    imageUrl: [genericImage(), notEmpty()]
  })
})(HomepageSettingsForm);

export default HomepageSettingsForm;
