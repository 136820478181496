import _compact from 'lodash/compact';
import _get from 'lodash/get';
import _pick from 'lodash/pick';
import config from '../constants/config-constants';
import { dateToYMD, momentTimeFormat, getTimeByType, quickSet, valToObj, toBoolean, isEmpty, safe, isObjEmpty, toNumber } from './utils';
import { EXTERNAL_INVOICE, SCHEDULED_EXPORT } from '../constants/backend-constants';
import _map from 'lodash/map';
import { cleanDeep } from './cleanDeep';
import { localeSelector, regionNamesSelector } from '../selectors/all-selectors';
import { getStore } from '../store/all-store';

export const parkingRequestFormat = parkingFormData => {
  const {
    name,
    latitude,
    longitude,
    radius,
    alwaysOpen,
    privateAccess,
    disabledAccess,
    findable,
    electricCharging,
    gsmConnection,
    pluggedCheck,
    rrsParkingId,
    additionalInformation,
    applyFrenchBankHolidays,
    finishBookingOutsideOpeningHours,
    finishBookingOutsideParkingArea,
    specialClosingDates,
    specialOpeningHours,
    weeklyOpeningHours
  } = parkingFormData;
  let schedule = null,
    oneTimeSlots = [],
    recurringSlots = [];

  if (alwaysOpen === 'false') {
    specialClosingDates.map(closingDate => {
      oneTimeSlots.push({
        comment: closingDate.comment,
        open: false,
        startDate: dateToYMD(closingDate.startDate),
        endDate: dateToYMD(closingDate.endDate)
      });
    });

    specialOpeningHours.map(openingHours => {
      oneTimeSlots.push({
        open: true,
        startDate: dateToYMD(openingHours.startDate),
        endDate: dateToYMD(openingHours.endDate),
        timeIntervals: openingHours.items.map(timeItem => {
          return {
            start: momentTimeFormat(timeItem.startHours, timeItem.startMinutes),
            end: momentTimeFormat(timeItem.endHours, timeItem.endMinutes)
          };
        })
      });
    });

    weeklyOpeningHours.map(weeklyHours => {
      recurringSlots.push({
        open: true,
        days: _compact(
          weeklyHours.days.map((dayItem, dayIndex) => {
            if (dayItem) {
              return config.daysOfWeek[dayIndex].value;
            }
          })
        ),
        timeIntervals: weeklyHours.items.map(timeItem => {
          return {
            start: momentTimeFormat(timeItem.startHours, timeItem.startMinutes),
            end: momentTimeFormat(timeItem.endHours, timeItem.endMinutes)
          };
        })
      });
    });

    schedule = {
      applyFrenchBankHolidays: applyFrenchBankHolidays === 'true',
      finishBookingOutsideOpeningHours: finishBookingOutsideOpeningHours === 'true',
      oneTimeSlots,
      recurringSlots
    };
  }

  return {
    name,
    alwaysOpen: alwaysOpen === 'true',
    privateAccess: privateAccess === 'true',
    disabledAccess: disabledAccess === 'true',
    electricCharging: electricCharging === 'true',
    gsmConnection: gsmConnection === 'true',
    pluggedCheck: pluggedCheck === 'true',
    findable: findable === 'true',
    finishBookingOutsideParkingArea: finishBookingOutsideParkingArea === 'true',
    rrsParkingId,
    coordinates: {
      latitude,
      longitude
    },
    radius,
    additionalInformation,
    schedule
  };
};

export const parkingFormFormat = parkingData => {
  let specialClosingDates = [],
    specialOpeningHours = [],
    weeklyOpeningHours = [];

  if (_get(parkingData, 'schedule.oneTimeSlots')) {
    parkingData.schedule.oneTimeSlots.map(oneTimeSlot => {
      if (oneTimeSlot.open) {
        specialOpeningHours.push({
          startDate: new Date(oneTimeSlot.startDate),
          endDate: new Date(oneTimeSlot.endDate),
          items: oneTimeSlot.timeIntervals.map(timeItem => {
            return {
              endHours: getTimeByType(timeItem.end, 'hours'),
              endMinutes: getTimeByType(timeItem.end, 'minutes'),
              startHours: getTimeByType(timeItem.start, 'hours'),
              startMinutes: getTimeByType(timeItem.start, 'minutes')
            };
          })
        });
      } else {
        specialClosingDates.push({
          comment: oneTimeSlot.comment,
          startDate: new Date(oneTimeSlot.startDate),
          endDate: new Date(oneTimeSlot.endDate)
        });
      }
    });
  }

  if (_get(parkingData, 'schedule.recurringSlots')) {
    parkingData.schedule.recurringSlots.map(recurringSlot => {
      weeklyOpeningHours.push({
        days: config.daysOfWeek.map(dayItem => {
          return recurringSlot.days.indexOf(dayItem.value) !== -1;
        }),
        items: recurringSlot.timeIntervals.map(timeItem => {
          return {
            endHours: getTimeByType(timeItem.end, 'hours'),
            endMinutes: getTimeByType(timeItem.end, 'minutes'),
            startHours: getTimeByType(timeItem.start, 'hours'),
            startMinutes: getTimeByType(timeItem.start, 'minutes')
          };
        })
      });
    });
  }

  return Object.assign({}, parkingData, {
    specialClosingDates,
    specialOpeningHours,
    weeklyOpeningHours
  });
};

export const bankHolidaysFormFormat = bankHolidaysData => {
  let bankHolidays = [];

  bankHolidaysData.map(bankHoliday => {
    bankHolidays.push({
      startDate: new Date(bankHoliday.from),
      endDate: new Date(bankHoliday.to),
      comment: bankHoliday.comment
    });
  });

  return bankHolidays;
};

export function getInvoiceParamsBase(values) {
  return _pick(values, [
    'companyCourt',
    'companyRegisterNumber',
    'dataProcessingRegistry',
    'companyCapital',
    'legalForm',
    'fiscalNumber',
    'vatCode',
    'invoiceNumberSuffix',
    'currency',
    'postalCode',
    'companyName',
    'city',
    'country',
    'templateModel',
    'invoicingDelegation',
    'invoicingAddress',
    'italianRea',
    'paymentConditionsLabel'
  ]);
}

export function getItalianInvoiceParams(values) {
  return _pick(values, [
    'invoiceNumberPrefix',
    'taxIdCode',
    'memberCode',
    'fiscalScheme',
    'ufficio',
    'socioUnico',
    'statusLiquidation',
    'firstInvoiceNumber'
  ]);
}

export function getItalianInvoiceCredentials(values) {
  return _pick(values, ['invoiceParamsLogin', 'invoiceParamsPassword']);
}

function joinAddress(array, sep) {
  return array
    .reduce((acc, val) => {
      if (!isEmpty(val)) acc.push(val);
      return acc;
    }, [])
    .join(sep)
    .trim();
}

export function setFormattedAddress(address) {
  let { streetNumber, streetName, postalCode, city, country } = address || {};
  const j = joinAddress;

  const street = j([streetNumber, streetName], ' ');
  const place = j([postalCode, city], ' ');
  let countryName = '';

  if (typeof country !== 'string') country = '';

  if (country) {
    const regionNames = regionNamesSelector(getStore().getState());
    countryName = safe(() => regionNames.of(country)) || country;
  }

  return {
    ...address,
    country,
    formattedAddress: j([street, place, countryName], ', ')
  };
}

export function fixTypesForSafetyDepositValues(params = {}) {
  const {
    damageSafetyDepositReleaseDelayInDays,
    pricesIncludingTaxes,
    billingSettings,
    billingSettings: {
      billBeforeBooking,
      preBookingBillingOffset,
      rrsSafetyDepositAmount,
      secondPreBillingOffsetMinutes,
      enableBookingDamageSafetyDeposit,
      enableBookingPriceSafetyDepositBuffer,
      bookingPriceSafetyDepositBuffer,
      bookingDamageSafetyDepositAmount
    }
  } = params;

  quickSet(params, valToObj(Number, { damageSafetyDepositReleaseDelayInDays }), 0);
  quickSet(params, valToObj(toBoolean, { pricesIncludingTaxes }), true);

  quickSet(billingSettings, valToObj(toBoolean, { billBeforeBooking }), false);
  quickSet(billingSettings, valToObj(Number, { preBookingBillingOffset }), 0);
  quickSet(billingSettings, valToObj(Number, { rrsSafetyDepositAmount }), 0);
  quickSet(billingSettings, valToObj(Number, { secondPreBillingOffsetMinutes }), 0);
  quickSet(billingSettings, valToObj(toBoolean, { enableBookingDamageSafetyDeposit }), false);
  quickSet(billingSettings, valToObj(toBoolean, { enableBookingPriceSafetyDepositBuffer }), false);
  quickSet(billingSettings, valToObj(Number, { bookingPriceSafetyDepositBuffer }), 0);
  quickSet(billingSettings, valToObj(Number, { bookingDamageSafetyDepositAmount }), 0);
}

export function adjustSafetyDepositValues(params = {}) {
  const { billingSettings, useExternalInvoiceSystem } = params;
  const { billBeforeBooking, enableBookingDamageSafetyDeposit, enableBookingPriceSafetyDepositBuffer } = billingSettings || {};

  if (!billBeforeBooking) {
    params.billingSettings.enableBookingPriceSafetyDepositBuffer = false;
  }

  if (!billBeforeBooking && !enableBookingDamageSafetyDeposit) {
    params.billingSettings.preBookingBillingOffset = 0;
    params.billingSettings.secondPreBillingOffsetMinutes = 0;
  }

  if (!billBeforeBooking || !enableBookingPriceSafetyDepositBuffer) {
    params.billingSettings.bookingPriceSafetyDepositBuffer = 0;
    params.billingSettings.rrsSafetyDepositAmount = 0;
  }

  if (useExternalInvoiceSystem !== EXTERNAL_INVOICE.RRS) {
    params.billingSettings.secondPreBillingOffsetMinutes = 0;
    params.billingSettings.rrsSafetyDepositAmount = 0;
  }

  if (!enableBookingDamageSafetyDeposit) {
    params.billingSettings.bookingDamageSafetyDepositAmount = 0;
    params.damageSafetyDepositReleaseDelayInDays = 0;
  }
}

export function cleanDocumentFile(fileData) {
  const goodFile = { ...fileData };
  const { files } = goodFile;

  goodFile.files = _map(files, (file = {}) => {
    const { fileId, type } = file;
    return { fileId, type };
  });

  delete goodFile.fileId;
  return goodFile;
}

export function cleanFilesForApi(apiData = {}) {
  const newData = { ...apiData };
  const { drivingLicence, identityDocument } = newData;

  if (drivingLicence) newData.drivingLicence = cleanDocumentFile(drivingLicence);
  if (identityDocument) newData.identityDocument = cleanDocumentFile(identityDocument);

  return newData;
}

export function cleanCustomFieldsForApi(apiData = {}, customFields = []) {
  const newData = { ...apiData };

  newData.memberCustomValues = _map(customFields, (item = {}) => {
    const { value, companyCustomField } = item;
    const { id } = companyCustomField || {};
    return { companyCustomFieldId: id, value };
  });

  return newData;
}

export function cleanMemberDataForApi(apiData, customFields) {
  return cleanDeep(cleanFilesForApi(cleanCustomFieldsForApi(apiData, customFields)));
}

export function formatGeocodingApiParams(params) {
  return cleanDeep({
    ...params,
    access_token: config.mapboxApiKey,
    types: safe(() => params.types.join())
  });
}

function formatS3Fields({ accessKey, bucket, path, secretAccessKey } = {}) {
  return {
    accessKey,
    bucket,
    path,
    secretAccessKey
  };
}

function formatSftpFields({ host, password, path, port, privateKeyFileId, username, security } = {}) {
  const { FILE } = SCHEDULED_EXPORT.SECURITY;
  const data = { host, path, port, username };

  if (security === FILE) data.privateKeyFileId = privateKeyFileId;
  else data.password = password;
  return data;
}

function formatBookingFilters(filters = {}) {
  filters = { ...filters };

  const fieldsToConvert = [
    'bookingEndDateMax',
    'bookingEndDateMin',
    'bookingLastUpdateDateMax',
    'bookingLastUpdateDateMin',
    'bookingStartDateMax',
    'bookingStartDateMin'
  ];

  fieldsToConvert.forEach(field => {
    filters[field] = toNumber(filters[field]);
  });

  return filters;
}

function formatMemberFilters(filters) {
  filters = { ...filters };
  filters.subscriptionOrigins = [filters.subscriptionOrigins];
  return filters;
}

function formatExportFilters({ resourceType } = {}, filters) {
  const { TYPE, FILTERS_BY_TYPE } = SCHEDULED_EXPORT;
  const isBookingFilters = TYPE.BOOKING === resourceType;
  return { [FILTERS_BY_TYPE[resourceType]]: isBookingFilters ? formatBookingFilters(filters) : formatMemberFilters(filters) };
}

export function formatScheduledExportFormDataForApi({ fields, filters = {} }) {
  filters = cleanDeep(filters) || {};

  const state = getStore().getState();
  const locale = localeSelector(state);
  const { companyIds, subCompanyIds } = filters;
  const { description, resourceType, frequency, destination, prefix, suffix, dateFormat } = fields || {};
  const { S3 } = SCHEDULED_EXPORT.DESTINATION;

  const data = {
    companyIds,
    subCompanyIds,
    description,
    content: {
      locale,
      resourceType,
      filter: {
        empty: isObjEmpty(filters),
        ...formatExportFilters(fields, filters)
      }
    },
    schedule: {
      frequency
    },
    target: {
      exportFileNameFormat: {
        prefix,
        suffix,
        dateFormat
      },
      [destination]: {
        ...(destination === S3 ? formatS3Fields(fields) : formatSftpFields(fields))
      }
    }
  };

  return cleanDeep(data);
}
