import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { getAddTitle } from '../../../../components/_v2/ColumnList/helpers';
import { showParentView } from '../../../../components/_v2/ColumnList/helpers';
import { showSuccessMsg } from '../../../../actions/flashMessage-actions';
import { getStore } from '../../../../store/all-store';
import { createConfigurationRequest } from '../../../../actions/configuration-actions';
import ConfigurationView from './index';

export class ConfigurationAdd extends Component {
  componentWillMount() {
    this.showParentView = showParentView.bind(this);

    this.handleCallback = () => {
      const { dispatch } = getStore();

      dispatch(createConfigurationRequest()).then(() => {
        dispatch(showSuccessMsg('configurationForm_create_configuration_success'));
        this.showParentView();
      });
    };
  }

  render() {
    return (
      <ConfigurationView
        inputRef={this.props.inputRef}
        title={getAddTitle(<FormattedMessage id="addConfiguration_title" />)}
        onClose={this.showParentView}
        onCallback={this.handleCallback}
      />
    );
  }
}

export default ConfigurationAdd;
