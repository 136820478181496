import { VALIDATION_BACK_USER_ALREADY_EXIST, VALIDATION_EMAIL_ALREADY_EXISTS_IN_SUPER_COMPANY } from '../constants/errors-constants';
import { backUserCheckEmailAvailability, checkMemberEmailAvailability } from '../actions/all-actions';
import { safe } from '../utils/utils';

const unifyAsyncValidators = validators => {
  return params => {
    let returnedPromise;
    const validatorsLength = validators.length;
    let activeValidators = [];

    // apply validators
    for (let i = 0; i < validatorsLength; i++) {
      let validatorPromise = validators[i](params);
      activeValidators.push(validatorPromise);
    }

    returnedPromise = Promise.race(activeValidators).then(
      () => {
        // we don't want anything to return in case of success
        return null;
      },
      err => {
        // we need to return instead of throwing or the calling Promise.all will fail-fast
        return err;
      }
    );

    return returnedPromise;
  };
};

export function createAsyncValidator(rules) {
  return (values = {}, dispatch, props) => {
    let returnedPromise;
    let fieldPromises = [];
    let fieldNames = [];

    Object.keys(rules).forEach(key => {
      // concat enables both functions and arrays of functions
      const unifiedValidator = unifyAsyncValidators([].concat(rules[key]));
      fieldPromises.push(unifiedValidator({ value: values[key], values, props, dispatch }));
      fieldNames.push(key);
    });

    returnedPromise = Promise.all(fieldPromises).then(data => {
      let errors;

      for (let i = 0, dataLength = data.length; i < dataLength; i++) {
        if (data[i]) {
          if (!errors) {
            errors = {};
          }
          errors[fieldNames[i]] = data[i];
        }
      }

      if (errors) {
        throw errors;
      }
    });

    return returnedPromise;
  };
}

// ------------------
// Validators
// ------------------

export function backUserEmailAvailable({ stopValidation }) {
  return ({ value, dispatch, props }) => {
    if (safe(() => stopValidation(props))) return;
    value = safe(() => value.trim()) || value;
    return dispatch(backUserCheckEmailAvailability({ login: value })).then(data => {
      if (safe(() => data.results.length) > 0) {
        throw { type: VALIDATION_BACK_USER_ALREADY_EXIST };
      }
    });
  };
}

export function memberEmailAvailable() {
  return ({ value, dispatch }) => {
    value = safe(() => value.trim()) || value;

    return dispatch(checkMemberEmailAvailability({ login: value })).then(data => {
      if (safe(() => data.results.length) > 0) {
        throw { type: VALIDATION_EMAIL_ALREADY_EXISTS_IN_SUPER_COMPANY };
      }
    });
  };
}
