import React, { Component, PropTypes } from 'react';
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';

import FormRow from '../FormRow/FormRow';
import BoxedInput from '../BoxedInput/BoxedInput';
import LabeledCheckbox from '../LabeledCheckbox/LabeledCheckbox';
import ButtonUpload from '../ButtonUpload/ButtonUpload';
import TableView from '../../components/TableView/TableView';
import EkButton from '../EkButton/EkButton';
import EkRadio from '../EkRadio/EkRadio';
import FieldErrorMsg from '../FieldErrorMsg/FieldErrorMsg';
import { createValidator, notEmpty, checkboxesNotEmpty, endDateAfterNotEqualStartDate } from '../../validation/all-validation';
import { displayVehicleImage, removeImage } from '../../actions/all-actions';

import { vehicleInsuranceContractTableViewParams } from '../../constants/table-constants';
import {
  VEHICLE_FORM_UPDATE_CONTRACT_IMAGE_SUCCESS,
  VEHICLE_FORM_UPDATE_CONTRACT_IMAGE_REMOVE
} from '../../constants/actionTypes-constants';
import { scrollToFirstError } from '../../utils/utils';
import FileImage from '../FileImage/FileImage';

const toolTipMessages = defineMessages({
  options: {
    id: 'insuranceContractForm_options_tooltip_text',
    defaultMessage: ''
  }
});

class InsuranceContractForm extends Component {
  constructor() {
    super();

    this.handleChangeContractImage = this.handleChangeContractImage.bind(this);
  }

  componentWillUnmount() {
    this.props.dispatch(removeImage(VEHICLE_FORM_UPDATE_CONTRACT_IMAGE_REMOVE));
  }

  handleChangeContractImage(fileData) {
    const { dispatch } = this.props;

    if (fileData) {
      dispatch(displayVehicleImage(fileData, VEHICLE_FORM_UPDATE_CONTRACT_IMAGE_SUCCESS));
    }
  }

  render() {
    const {
      fields: { contractNumber, glassBreakage, startDate, expirationDate, options, professionalType, privateType, contractFile },
      intl: { formatMessage },
      currentImage,
      handleSubmit,
      list,
      limitedAccess
    } = this.props;

    let glassBreakageItems = [
      {
        labelKey: 'insuranceContractForm_glassBreakage_yes',
        value: 'true'
      },
      {
        labelKey: 'insuranceContractForm_glassBreakage_no',
        value: 'false'
      }
    ];

    const submitAction = limitedAccess ? undefined : handleSubmit(this.props.onCallback);

    return (
      <div className="insuranceContractForm_container">
        {!limitedAccess && (
          <form action="#" className="insuranceContractForm vehicleDetail_form" onSubmit={submitAction}>
            <h4 className="insuranceContractForm_title">
              <FormattedMessage id="insuranceContractForm_title" />
            </h4>

            <FormRow customWrapperClass="insuranceContractForm_row">
              <BoxedInput
                formRowItemKey="insuranceContractForm_number"
                type="text"
                id="insuranceContractForm_number"
                labelKey="insuranceContractForm_number"
                field={contractNumber}
              >
                <FieldErrorMsg customClass="fieldErrorMsg--insuranceContractForm" field={contractNumber} />
              </BoxedInput>

              <BoxedInput
                formRowItemKey="insuranceContractForm_options"
                type="text"
                id="insuranceContractForm_options"
                labelKey="insuranceContractForm_options"
                toolTipText={formatMessage(toolTipMessages.options)}
                field={options}
              >
                <FieldErrorMsg customClass="fieldErrorMsg--insuranceContractForm" field={options} />
              </BoxedInput>
            </FormRow>

            <FormRow customWrapperClass="insuranceContractForm_row">
              <BoxedInput
                formRowItemKey="insuranceContractForm_startDate"
                type="date"
                id="insuranceContractForm_startDate"
                labelKey="insuranceContractForm_startDate"
                field={startDate}
              >
                <FieldErrorMsg customClass="fieldErrorMsg--insuranceContractForm" field={startDate} />
              </BoxedInput>

              <BoxedInput
                formRowItemKey="insuranceContractForm_endDate"
                type="date"
                id="insuranceContractForm_endDate"
                labelKey="insuranceContractForm_endDate"
                field={expirationDate}
              >
                <FieldErrorMsg customClass="fieldErrorMsg--insuranceContractForm" field={expirationDate} />
              </BoxedInput>
            </FormRow>

            {currentImage ? (
              <FileImage imageObject={currentImage} imageStyle={{ maxWidth: '200px', maxHeight: '120px', margin: '10px 0' }} />
            ) : null}

            <FormRow customClass="insuranceContractForm_row">
              <EkRadio
                formRowItemKey="insuranceContractForm_glassBreakage"
                customContainerClass="parkingForm_ekRadioContainer"
                customWrapperClass="parkingForm_ekRadioWrapper"
                customTitleClass="insuranceContractForm_ekRadioTitle"
                titleKey="insuranceContractForm_glassBreakage"
                items={glassBreakageItems}
                namespace="glassBreakage"
                field={glassBreakage}
              >
                <FieldErrorMsg field={glassBreakage} customClass="fieldErrorMsg--parkingForm" />
              </EkRadio>
            </FormRow>

            <FormRow customClass="insuranceContractForm_row">
              <dl className="insuranceContractForm_definitionList">
                <dt className="insuranceContractForm_definitionList_term">
                  <FormattedMessage id="insuranceContractForm_insuranceType" />
                </dt>
                <dd className="insuranceContractForm_definitionList_definition">
                  <LabeledCheckbox
                    id="professionalInsuranceType"
                    customClass="insuranceContractForm_option"
                    customLabelClass="insuranceContractForm_checkbox"
                    field={professionalType}
                  >
                    <FormattedMessage id="insuranceContractForm_professional" />
                  </LabeledCheckbox>

                  <LabeledCheckbox
                    id="privateInsuranceType"
                    customClass="insuranceContractForm_option"
                    customLabelClass="insuranceContractForm_checkbox"
                    field={privateType}
                  >
                    <FormattedMessage id="insuranceContractForm_private" />
                  </LabeledCheckbox>

                  <br />

                  <FieldErrorMsg customClass="fieldErrorMsg--insuranceContractForm" field={professionalType} />
                </dd>
              </dl>
            </FormRow>

            <FormRow customWrapperClass="insuranceContractForm_row">
              <ButtonUpload
                formRowItemKey="insuranceContractForm_driverLicenceUploadBtn"
                id="contractFile"
                customClass="insuranceContractForm_buttonUpload"
                buttonLabel="insuranceContractForm_fileUpload_label"
                label="insuranceContractForm_contractFile_label"
                field={contractFile}
                onChange={this.handleChangeContractImage}
              >
                <FieldErrorMsg field={contractFile} customClass="fieldErrorMsg--insuranceContractForm" />
              </ButtonUpload>
            </FormRow>

            <EkButton customClass="insuranceContractForm_actionsButton" onAction={submitAction}>
              <FormattedMessage id="contractForm_submit" />
            </EkButton>
          </form>
        )}

        <section className="vehiclesColorsPage_management">
          <TableView
            customClass="vehiclesColorsPage_tableViewWrapper"
            params={vehicleInsuranceContractTableViewParams}
            content={list}
            limitedAccess={limitedAccess}
          />
        </section>
      </div>
    );
  }
}

InsuranceContractForm.displayName = 'InsuranceContractForm';

InsuranceContractForm.propTypes = {
  fields: PropTypes.object,
  handleSubmit: PropTypes.func,
  onCallback: PropTypes.func,
  limitedAccess: PropTypes.bool
};

InsuranceContractForm = reduxForm({
  onSubmitFail: scrollToFirstError,
  form: 'insuranceContractForm',
  fields: ['contractNumber', 'startDate', 'expirationDate', 'glassBreakage', 'options', 'professionalType', 'privateType', 'contractFile'],
  validate: createValidator({
    contractNumber: [notEmpty()],
    expirationDate: [endDateAfterNotEqualStartDate('startDate')],
    professionalType: [checkboxesNotEmpty('privateType')]
  })
})(InsuranceContractForm);

export default connect(state => {
  const {
    vehicles: { insuranceContractsList },
    user: { userInfo }
  } = state;

  return {
    list: insuranceContractsList,
    role: userInfo.role
  };
})(injectIntl(InsuranceContractForm));
