import React, { Component } from 'react';
import { updateModelRequest } from '../../../../../actions/models-actions';
import { FormattedMessage } from 'react-intl';
import { getEditTitle } from '../../../../../components/_v2/ColumnList/helpers';
import { showParentView } from '../../../../../components/_v2/ColumnList/helpers';
import { connect } from 'react-redux';
import VehicleView from '../index';
import { safe } from '../../../../../utils/utils';
import { editVehicleBrandsRules } from '../../../../../constants/routes-constants';
import { checkRole } from '../../../../../constants/backuser-role-rules';
import { userRoleSelector } from '../../../../../selectors/all-selectors';

export class VehicleEditModel extends Component {
  componentWillMount() {
    this.setVars();
    this.setCallbacks();
  }

  setVars() {
    this.limitedAccess = !checkRole(editVehicleBrandsRules, this.props.role);
  }

  setCallbacks() {
    this.showParentView = showParentView.bind(this);

    this.handleCallback = data => {
      const params = { brandId: safe(() => this.props.data.brand.id), ...data };
      this.props.dispatch(updateModelRequest(params));
    };
  }

  render() {
    return (
      <VehicleView
        inputRef={this.props.inputRef}
        title={getEditTitle(<FormattedMessage id="modelForm_title" />)}
        fieldLabel={<FormattedMessage id="modelForm_name_label" />}
        onClose={this.showParentView}
        onCallback={this.handleCallback}
        initialValues={this.props.data}
        limitedAccess={this.limitedAccess}
      />
    );
  }
}

const mapStateToProps = state => {
  const { currentModel } = state.models;

  return {
    data: currentModel,
    role: userRoleSelector(state)
  };
};

VehicleEditModel = connect(mapStateToProps)(VehicleEditModel);

export default VehicleEditModel;
