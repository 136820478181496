import { routeActions } from 'react-router-redux';
import _get from 'lodash/get';

import { getStore } from '../store/all-store';
import { getDamageReportImage, openDeleteStatusModal, deleteInsuranceContract, getSingleBooking, copySite } from '../actions/all-actions';
import { getInsuranceContractImage } from '../actions/vehicles-actions';
import { deleteInvoiceItem, setAddInvoiceSelectedBooking } from '../actions/invoices-actions';
import { getShortId, getAppFormattedDateTime, booleanColumn, roundToTwoDecimals } from '../utils/utils';
import { pickBookingCustomField, pickSubscriptionCustomField } from '../actions/customFields-actions';
import { actionCallback } from '../routing/utils-routing';
import * as sort from './backend-constants';
import routes from './routes-constants';

export const customFieldsTableViewParams = {
  namespace: 'customFields',
  columns: [
    {
      messageKey: 'customFieldLabel_name',
      content: 'name'
    },
    {
      messageKey: 'customFieldLabel_id',
      content: item => getShortId(item.id)
    },
    {
      messageKey: 'customFieldLabel_type',
      content: item => item.fieldType,
      contentMessageKey: 'customFieldType_[content]'
    },
    {
      messageKey: 'customFieldLabel_mandatory',
      ...booleanColumn(item => item.mandatory === sort.CUSTOM_FIELD_YES)
    },
    {
      messageKey: 'customFieldLabel_position',
      content: 'position'
    },
    {
      messageKey: 'customFieldLabel_Unicity',
      ...booleanColumn(item => item.uniqueValues)
    },
    {
      messageKey: 'customFieldLabel_visible',
      ...booleanColumn(item => item.visible === sort.CUSTOM_FIELD_YES)
    },
    {
      contentMessageKey: 'common_edit',
      content: null,
      messageKey: 'hotlines_tableView_label_action',
      actionCustomClass: 'hotlines_tableView_action',
      actionCallback: function(item) {
        getStore().dispatch(
          item.form === sort.CUSTOM_FIELD_FORM_SUBSCRIPTION ? pickSubscriptionCustomField(item) : pickBookingCustomField(item)
        );
      }
    }
  ]
};

export const customFieldsTableBookingViewParams = {
  namespace: 'customFields',
  columns: [
    {
      messageKey: 'customFieldLabel_name',
      content: 'name'
    },
    {
      messageKey: 'customFieldLabel_id',
      content: item => getShortId(item.id)
    },
    {
      messageKey: 'customFieldLabel_type',
      content: item => item.fieldType,
      contentMessageKey: 'customFieldType_[content]'
    },
    {
      messageKey: 'booking_create_find_vehicle_tableView_booking_type',
      content: item => item.usageType,
      contentMessageKey: 'bookingType_[content]'
    },
    {
      messageKey: 'customFieldLabel_mandatory',
      ...booleanColumn(item => item.mandatory === sort.CUSTOM_FIELD_YES)
    },
    {
      messageKey: 'customFieldLabel_position',
      content: 'position'
    },
    {
      messageKey: 'customFieldLabel_visible',
      ...booleanColumn(item => item.visible === sort.CUSTOM_FIELD_YES)
    },
    {
      contentMessageKey: 'common_edit',
      content: null,
      messageKey: 'hotlines_tableView_label_action',
      actionCustomClass: 'hotlines_tableView_action',
      actionCallback: function(item) {
        getStore().dispatch(
          item.form === sort.CUSTOM_FIELD_FORM_SUBSCRIPTION ? pickSubscriptionCustomField(item) : pickBookingCustomField(item)
        );
      }
    }
  ]
};

export const hotlinesTableViewParams = {
  namespace: 'hotlines',
  /*sortByIndex: 0,*/
  sortIsDescending: false,
  columns: [
    {
      messageKey: 'hotlines_tableView_label_name',
      content: 'name'
    },
    {
      messageKey: 'hotlines_tableView_label_email',
      content: 'customerServiceEmail'
    },
    {
      messageKey: 'hotlines_tableView_label_phoneNumber',
      content: item => {
        return _get(item, 'phoneNumbers[0].countryCode', '') + _get(item, 'phoneNumbers[0].nationalNumber', '');
      }
    },
    {
      messageKey: 'hotlines_tableView_label_action',
      content: null,
      contentMessageKey: 'hotlines_tableView_content_action',
      actionCustomClass: 'hotlines_tableView_action',
      actionCallback: function(item) {
        getStore().dispatch(routeActions.push(routes.editHotline.path.replace(':hotlineId', item.id)));
      }
    }
  ]
};

export const damagesTableViewParams = {
  namespace: 'damages',
  sortIsReversed: false,
  columns: [
    {
      messageKey: 'damageReport_area',
      content: 'area',
      contentMessageKey: 'feedbacks_damage_report_area_[content]'
    },
    {
      messageKey: 'damageReport_report_type',
      content: 'type',
      contentMessageKey: 'feedbacks_damage_report_type_[content]'
    },
    {
      messageKey: 'feedbackAddCommentForm_comment',
      content: 'comment'
    },
    {
      messageKey: 'members_filters_download_button',
      content: null,
      contentMessageKey: 'members_filters_download_button',
      actionCustomClass: 'feedbacks_tableView_action',
      actionCallback: function(item) {
        getStore().dispatch(getDamageReportImage(item.id));
      }
    }
  ]
};

export const vehicleDetailTableViewParams = {
  namespace: 'statuses',
  sortIsReversed: false,
  columns: [
    {
      messageKey: 'vehicle_detail_tableView_date',
      content: 'date'
    },
    {
      messageKey: 'vehicle_detail_tableView_statut',
      content: 'type',
      contentMessageKey: 'vehicle_statusType_[content]'
    },
    {
      messageKey: 'booking_create_confirmation_comment',
      content: 'comment'
    },
    {
      messageKey: 'vehicle_detail_tableView_delete',
      content: null,
      contentMessageKey: 'vehicle_detail_tableView_delete',
      actionCustomClass: 'backUsers_tableView_action',
      actionCallback: function(item) {
        getStore().dispatch(openDeleteStatusModal(item.id));
      }
    }
  ]
};

export const feedbackDetailsTableViewParams = {
  namespace: 'comments',
  sortIsReversed: false,
  columns: [
    {
      messageKey: 'feedbacks_detail_submissionDate',
      content: item => {
        return getAppFormattedDateTime(item.createdDate);
      }
    },
    {
      messageKey: 'feedbackAddCommentForm_comment',
      content: 'comment'
    }
  ]
};

export const vehicleInsuranceContractTableViewParams = {
  namespace: 'insuranceContracts',
  sortIsDescending: false,
  columns: [
    {
      messageKey: 'insuranceContracts_tableView_label_number',
      content: 'contractNumber'
    },
    {
      messageKey: 'insuranceContracts_tableView_label_type',
      content: item => {
        return item.types
          .join(', ')
          .toLowerCase()
          .replace(/\b\w/g, l => l.toUpperCase());
      }
    },
    {
      messageKey: 'insuranceContracts_tableView_label_glassBreakage',
      content: item => {
        return item.glassBreakage ? 'TRUE' : 'FALSE';
      },
      contentMessageKey: 'insuranceContracts_tableView_glassBreakage_[content]'
    },
    {
      messageKey: 'insuranceContracts_tableView_label_options',
      content: item => {
        let str = '';
        for (let i = 0; i < item.options.length; i++) {
          if (i !== item.options.length - 1) {
            str += item.options[i] + ', ';
          } else {
            str += item.options[i];
          }
        }
        return str;
      }
    },
    {
      messageKey: 'insuranceContracts_tableView_label_startDate',
      content: 'startDate'
    },
    {
      messageKey: 'insuranceContracts_tableView_label_endDate',
      content: 'expirationDate'
    },
    {
      messageKey: 'insuranceContracts_tableView_label_view_image',
      contentMessageKey: item => {
        return _get(item, 'uploadedFileId') ? 'members_filters_download_button' : '';
      },
      actionCustomClass: 'vehicleDetail_tableView_action',
      actionCallback: function(item) {
        getStore().dispatch(getInsuranceContractImage(item.uploadedFileId));
      }
    },
    {
      messageKey: 'insuranceContracts_tableView_label_action',
      content: null,
      contentMessageKey: 'insuranceContracts_tableView_content_action',
      actionCustomClass: 'vehicleDetail_tableView_action',
      actionCallback: function(item) {
        getStore().dispatch(deleteInsuranceContract(item.id));
      }
    }
  ]
};

export const vehicleDamagesTableViewParams = {
  namespace: 'vehicleDamages',
  contentIsCentered: true,
  columns: [
    {
      messageKey: 'vehicleDamages_tableView_label_plateNumber',
      actionCustomClass: 'vehicleDamages_tableView_action',
      content: 'vehiclePlateNumber',
      actionCallback: function(item) {
        getStore().dispatch(routeActions.push(routes.vehicleDetail.path.replace(':vehicleId', item.vehicleId)));
      },
      isSortable: false
    },
    {
      messageKey: 'common_creation_date',
      actionCustomClass: 'vehicleDamages_tableView_action',
      content: function(item) {
        return getAppFormattedDateTime(item.creationDate);
      },
      actionCallback: function(item) {
        getStore().dispatch(routeActions.push(routes.vehicleDamageDetail.path.replace(':vehicleDamageId', item.id)));
      },
      isSortable: true,
      id: sort.SORT_PROPERTY_CREATION_DATE,
      sortActionCallback: actionCallback
    },
    {
      id: sort.SORT_PROPERTY_VEHICLE_BRAND_NAME,
      messageKey: 'vehicleDamages_tableView_label_vehicle',
      content: 'vehicleBrandModel',
      isSortable: true,
      sortActionCallback: actionCallback
    },
    {
      id: sort.SORT_PROPERTY_COMPANY_NAME,
      messageKey: 'vehicleDamages_tableView_label_company',
      content: 'companyName',
      isSortable: true,
      sortActionCallback: actionCallback
    },
    {
      id: sort.SORT_PROPERTY_BOOKING_ID,
      messageKey: 'vehicleDamages_tableView_label_bookingId',
      content: item => {
        return item.bookingId ? getShortId(item.bookingId) : null;
      },
      actionCustomClass: 'vehicleDamages_tableView_action',
      actionCallback: function(item) {
        getStore().dispatch(routeActions.push(routes.feedbackDetail.path.replace(':feedbackId', item.bookingId)));
      },
      isSortable: true,
      sortActionCallback: actionCallback
    },
    {
      id: sort.SORT_PROPERTY_DAMAGE_AREA,
      messageKey: 'vehicleDamages_tableView_label_damageArea',
      contentMessageKey: 'feedbacks_damage_report_area_[content]',
      content: 'area',
      actionCustomClass: 'vehicleDamages_tableView_action',
      isSortable: true,
      sortActionCallback: actionCallback
    },
    {
      id: sort.SORT_PROPERTY_DAMAGE_TYPE,
      messageKey: 'vehicleDamages_tableView_label_damageType',
      contentMessageKey: 'feedbacks_damage_report_type_[content]',
      content: 'type',
      actionCustomClass: 'vehicleDamages_tableView_action',
      isSortable: true,
      sortActionCallback: actionCallback
    },
    {
      id: sort.SORT_PROPERTY_DAMAGE_STATUS,
      messageKey: 'vehicleDamages_tableView_label_damageStatus',
      contentMessageKey: 'vehicleDamages_status_[content]',
      content: 'status',
      isSortable: true,
      sortActionCallback: actionCallback
    },
    {
      messageKey: 'vehicleDamages_tableView_label_action',
      content: null,
      contentMessageKey: 'vehicleDamages_tableView_content_action',
      actionCustomClass: 'vehicleDamages_tableView_action',
      actionCallback: function(item) {
        getStore().dispatch(routeActions.push(routes.vehicleDamageDetail.path.replace(':vehicleDamageId', item.id)));
      }
    }
  ]
};

export const vehicleDamageDetailTableViewParams = {
  namespace: 'vehicleDamageDetail',
  sortIsReversed: false,
  columns: [
    {
      messageKey: 'vehicle_damage_detail_tableView_status_date',
      content: function(item) {
        return getAppFormattedDateTime(item.date);
      }
    },
    {
      messageKey: 'vehicle_damage_detail_tableView_status',
      contentMessageKey: 'vehicleDamages_status_[content]',
      content: 'type'
    }
  ]
};

export const invoicesListHeader = [
  {
    labelKey: 'invoices_tableView_label_invoiceNumber',
    md: 4,
    xs: 4,
    sortable: true,
    sortId: sort.SORT_INVOICE_EMISSIOM_DATE
  },
  {
    labelKey: 'invoices_tableView_label_memberName',
    md: 3,
    xs: 3,
    sortable: true,
    sortId: sort.SORT_INVOICE_LASTNAME
  },
  {
    labelKey: 'common_amount',
    md: 2,
    xs: 2,
    sortable: true,
    sortId: sort.SORT_PROPERTY_INVOICE_VALUE
  },
  {
    labelKey: 'invoices_tableView_label_vat',
    md: 1,
    xs: 1,
    sortable: true,
    sortId: sort.SORT_PROPERTY_INVOICE_VAT_RATE
  },
  {
    labelKey: 'invoices_tableView_label_status',
    md: 1,
    xs: 1,
    sortable: true,
    sortId: sort.SORT_INVOICE_STATUS
  },
  {
    labelKey: 'invoices_tableView_label_view',
    md: 1,
    xs: 1,
    sortable: false
  }
];

export const tableViewInvoiceBookingsParams = {
  namespace: 'findBooking',
  columns: [
    {
      messageKey: 'bookings_tableView_label_bookingId',
      content: 'id',
      isSortable: true,
      id: sort.SORT_PROPERTY_BOOKING_ID,
      sortActionCallback: actionCallback
    },
    {
      messageKey: 'feedbacks_tableView_label_memberName',
      isSortable: true,
      content: item => {
        return item.memberFirstName + ' ' + item.memberLastName;
      },
      id: sort.SORT_PROPERTY_MEMBER_NAME,
      sortActionCallback: actionCallback
    },
    {
      messageKey: 'feedbacks_tableView_label_vehicle',
      isSortable: true,
      content: item => {
        return item.vehicleBrandName + ' ' + item.vehicleModelName + ' : ' + item.vehicleRegistrationNumber;
      },
      id: sort.SORT_PROPERTY_VEHICLE_BRAND_MODEL,
      sortActionCallback: actionCallback
    },
    {
      messageKey: 'booking_departure_arrival',
      isSortable: true,
      content: item => {
        return getAppFormattedDateTime(item.startDate) + ' / ' + getAppFormattedDateTime(item.endDate);
      },
      id: sort.SORT_PROPERTY_START_DATE,
      sortActionCallback: actionCallback
    },
    {
      messageKey: 'members_tableView_label_action',
      content: null,
      contentMessageKey: 'invoice_create_find_member_tableView_action',
      actionCustomClass: 'members_tableView_action',
      actionCallback: booking => {
        getStore()
          .dispatch(getSingleBooking(booking.id))
          .then(bookingDetail => {
            booking.currency = bookingDetail.currency;
          });
        getStore().dispatch(setAddInvoiceSelectedBooking(booking));
        getStore().dispatch(routeActions.push(`${routes.addInvoice.path}/${routes.createInvoice.path}`));
      }
    }
  ]
};

export const createInvoiceTableViewParams = {
  namespace: 'createInvoice',
  columns: [
    {
      messageKey: 'invoices_tableView_label_description',
      content: 'description',
      isSortable: false
    },
    {
      messageKey: 'invoices_tableView_label_quantity',
      content: 'quantity',
      isSortable: false
    },
    {
      messageKey: 'invoices_tableView_label_priceUnitWithVAT',
      values: () => {
        const state = getStore().getState();
        const currencyTxt = state.invoices.selectedBooking.currency;
        const currency = state.i18n.bundle[`unit_${currencyTxt}`];
        return { currency };
      },
      content: item => {
        return _get(item, 'translateKey') === 'TOTAL' ? item.translateKey : roundToTwoDecimals(item.pricePerUnitWithVat);
      },
      isSortable: false
    },
    {
      messageKey: 'common_amount',
      content: item => {
        return roundToTwoDecimals(item.itemPriceWithVat);
      },
      isSortable: false
    },
    {
      messageKey: 'invoices_tableView_label_delete_action',
      contentMessageKey: item => {
        return _get(item, 'quantity') ? 'invoices_tableView_content_delete_action' : '';
      },
      actionCustomClass: 'invoices_tableView_action',
      actionCallback: item => {
        getStore().dispatch(deleteInvoiceItem(item));
      }
    }
  ]
};

export const configurationsTableViewParams = {
  namespace: 'configurations',
  sortIsDescending: false,
  columns: [
    {
      messageKey: 'configuration_tableView_label_name',
      content: 'name'
    },
    {
      messageKey: 'configuration_tableView_label_action',
      content: null,
      contentMessageKey: 'configuration_tableView_content_action',
      actionCustomClass: 'configuration_tableView_action',
      actionCallback: function(item) {
        getStore().dispatch(routeActions.push(routes.editConfiguration.path.replace(':configurationId', item.id)));
      }
    }
  ]
};

export const sitesTableViewParams = subCompaniesByIdObject => ({
  namespace: 'sites',
  sortIsDescending: false,
  columns: [
    {
      messageKey: 'sites_tableView_label_name',
      content: 'name'
    },
    {
      messageKey: 'sites_tableView_label_address',
      content: 'address.formattedAddress'
    },
    {
      messageKey: 'sites_tableView_label_company',
      content: item => {
        return _get(subCompaniesByIdObject[item.subCompanyId], 'name', '-');
      }
    },
    {
      messageKey: 'sites_tableView_label_action',
      content: (item, props = {}) => (props.viewAccess ? 'common_view' : 'sites_tableView_content_action'),
      contentMessageKey: '[content]',
      actionCustomClass: 'sites_tableView_action',
      actionCallback: function(item) {
        getStore().dispatch(routeActions.push(routes.siteDetails.path.replace(':siteId', item.id)));
      }
    },
    {
      messageKey: 'sites_tableView_label_action',
      content: (item, props = {}) => (props.viewAccess ? 'common_view' : 'companies_detail_duplicate_button'),
      contentMessageKey: '[content]',
      actionCustomClass: 'sites_tableView_action',
      actionCallback: function(site) {
        getStore().dispatch(copySite(site));
        getStore().dispatch(routeActions.push(routes.addSite.path));
      }
    }
  ]
});

export const smartcardsHeaderParams = [
  {
    labelKey: 'smartcards_events_tableView_label_smartcard_id',
    md: 6,
    xs: 6
  },
  {
    labelKey: 'booking_detail_company',
    md: 6,
    xs: 6
  }
];

export const smartcardsEventsHeaderParams = [
  {
    labelKey: 'vehicle_detail_tableView_date',
    md: 3,
    xs: 3
  },
  {
    labelKey: 'configuration_tableView_label_name',
    md: 2,
    xs: 2
  },
  {
    labelKey: 'feedbacks_tableView_label_plateNumber',
    md: 2,
    xs: 2
  },
  {
    labelKey: 'booking_detail_company',
    md: 2,
    xs: 2
  },
  {
    labelKey: 'companies_tableView_label_action',
    md: 1,
    xs: 1
  },
  {
    labelKey: 'smartcards_events_tableView_label_result',
    md: 2,
    xs: 2
  }
];

export const bookingsHeaderParams = [
  {
    labelKey: 'bookings_tableView_label_bookingId',
    md: 2,
    xs: 2
  },
  {
    labelKey: 'vehiclesFiltersForm_usage',
    md: 1,
    xs: 1,
    sortable: true,
    sortId: sort.SORT_PROPERTY_VEHICLE_USAGE_WHY
  },
  {
    labelKey: 'feedbacks_tableView_label_memberName',
    md: 2,
    xs: 2,
    sortable: true,
    sortId: sort.SORT_PROPERTY_MEMBER_NAME
  },
  {
    labelKey: 'feedbacks_tableView_label_vehicle',
    md: 3,
    xs: 3,
    sortable: true,
    sortId: sort.SORT_PROPERTY_VEHICLE_BRAND_MODEL
  },
  {
    labelKey: 'booking_departure_arrival',
    md: 3,
    xs: 3,
    sortable: true,
    sortId: sort.SORT_PROPERTY_START_DATE
  }
];

export const feedbacksHeaderParams = [
  {
    labelKey: 'common_endDate',
    md: 1,
    xs: 1,
    sortable: false
  },
  {
    labelKey: 'bookings_tableView_label_bookingId',
    md: 1,
    xs: 1,
    sortable: true,
    sortId: sort.SORT_PROPERTY_BOOKING_ID
  },
  {
    labelKey: 'searchBookingsForm_title_member',
    md: 2,
    xs: 2,
    sortable: true,
    sortId: sort.SORT_PROPERTY_MEMBER_NAME
  },
  {
    labelKey: 'vehicleDamages_tableView_label_company',
    md: 1,
    xs: 1,
    sortable: true,
    sortId: sort.SORT_PROPERTY_COMPANY_NAME
  },
  {
    labelKey: 'feedbacks_tableView_label_plateNumber',
    md: 2,
    xs: 2,
    sortable: true,
    sortId: sort.SORT_PROPERTY_VEHICLE_PLATE_NUMBER
  },
  {
    labelKey: 'vehicleForm_stepper_step1',
    md: 2,
    xs: 2,
    sortable: true,
    sortId: sort.SORT_PROPERTY_VEHICLE_BRAND_NAME
  },
  {
    labelKey: ['damages_report_rates_start', 'damages_report_rates_end'],
    md: 2,
    xs: 2,
    sortable: true,
    sortId: sort.SORT_PROPERTY_VEHICLE_START_OUTSIDE_CLEANLINESS
  },
  {
    labelKey: 'dashboard_damages_title',
    md: 1,
    xs: 1,
    sortable: true,
    sortId: sort.SORT_PROPERTY_NUMBER_OF_DECLARED_DAMAGES
  }
];

export const companiesHeaderParams = [
  {
    labelKey: 'companies_tableView_label_name',
    md: 4,
    xs: 4,
    sortable: true,
    sortId: sort.SORT_PROPERTY_NAME
  },
  {
    labelKey: 'companies_tableView_label_email',
    md: 6,
    xs: 6
  },
  {
    labelKey: 'companies_tableView_label_phone',
    md: 2,
    xs: 2
  }
];

export const vehicleHeaderParams = [
  {
    labelKey: 'fleet_tableView_label_registrationNumber',
    md: 3,
    xs: 3,
    sortId: sort.SORT_PROPERTY_PLATE_NUMBER,
    sortable: true
  },
  {
    labelKey: 'vehiclesFiltersForm_usage',
    md: 1,
    xs: 1,
    sortId: sort.SORT_PROPERTY_VEHICLE_USAGE,
    sortable: true
  },
  {
    labelKey: 'vehiclesFiltersForm_brand',
    md: 1,
    xs: 1,
    sortId: sort.SORT_PROPERTY_BRAND,
    sortable: true
  },
  {
    labelKey: 'fleet_tableView_label_systemType',
    md: 1,
    xs: 1
  },
  {
    labelKey: 'fleet_tableView_label_companyName',
    md: 1,
    xs: 1
  },
  {
    labelKey: 'side_menu_section_account_sites',
    md: 4,
    xs: 4
  },
  {
    labelKey: 'backUsers_detail_status',
    md: 1,
    xs: 1,
    sortId: sort.SORT_PROPERTY_STATUS,
    sortable: true
  }
];

export const backUsersHeaderParams = [
  {
    labelKey: 'backUsers_tableView_label_lastName',
    md: 6,
    xs: 6,
    sortable: true,
    sortId: sort.SORT_PROPERTY_LASTNAME
  },
  {
    labelKey: 'backUsers_tableView_label_company',
    md: 3,
    xs: 3
  },
  {
    labelKey: 'backUsers_tableView_label_role',
    md: 3,
    xs: 3,
    sortable: true,
    sortId: sort.SORT_PROPERTY_ROLE
  }
];

export const parkingsHeaderParams = [
  {
    labelKey: 'parking_tableView_label_name',
    md: 5,
    xs: 5
  },
  {
    labelKey: 'common_address',
    md: 5,
    xs: 5
  },
  {
    labelKey: 'parkingForm_alwaysOpen',
    md: 2,
    xs: 2
  }
];
