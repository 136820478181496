import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { getAddTitle } from '../../../../../components/_v2/ColumnList/helpers';
import { showParentView } from '../../../../../components/_v2/ColumnList/helpers';
import VehicleView from '../index';
import { showSuccessMsg } from '../../../../../actions/flashMessage-actions';
import { connect } from 'react-redux';
import { createModelRequest } from '../../../../../actions/models-actions';
import { safe } from '../../../../../utils/utils';
import { editVehicleBrandsRules } from '../../../../../constants/routes-constants';
import { checkRole } from '../../../../../constants/backuser-role-rules';
import { userRoleSelector } from '../../../../../selectors/all-selectors';

export class VehicleAddModel extends Component {
  componentWillMount() {
    this.setVars();
    this.setCallbacks();
  }

  setVars() {
    this.limitedAccess = !checkRole(editVehicleBrandsRules, this.props.role);
  }

  setCallbacks() {
    this.showParentView = showParentView.bind(this);

    this.handleCallback = params => {
      const { dispatch } = this.props;

      dispatch(createModelRequest({ ...params, brandId: params.id })).then(() => {
        dispatch(showSuccessMsg({ key: 'modelForm_create_model_success' }));
        this.showParentView();
      });
    };
  }

  render() {
    return (
      <VehicleView
        inputRef={this.props.inputRef}
        title={getAddTitle(<FormattedMessage id="modelForm_title" />)}
        fieldLabel={<FormattedMessage id="modelForm_name_label" />}
        initialValues={{ id: safe(() => this.props.data.id) }}
        onCallback={this.handleCallback}
        onClose={this.showParentView}
        limitedAccess={this.limitedAccess}
      />
    );
  }
}

const mapStateToProps = state => {
  const { currentBrand } = state.brands;

  return {
    data: currentBrand,
    role: userRoleSelector(state)
  };
};

VehicleAddModel = connect(mapStateToProps)(VehicleAddModel);

export default VehicleAddModel;
