import FormRow from '../FormRow/FormRow';
import BoxedSelect from '../BoxedSelect/BoxedSelect';
import FieldErrorMsg from '../FieldErrorMsg/FieldErrorMsg';
import EkRadio from '../EkRadio/EkRadio';
import React, { Component } from 'react';
import { createSubCompaniesOptions, elemFound, getId } from '../../utils/utils';
import { removeBackUserCompanies, setBackUserCompanies, setBackUserSubCompanies } from '../../containers/BackUsers/backUsers-actions';
import { FormattedMessage } from 'react-intl';
import { createCompaniesOptions } from '../../utils/utils';
import _map from 'lodash/map';
import _difference from 'lodash/difference';
import _union from 'lodash/union';
import { connect } from 'react-redux';
import _isEmpty from 'lodash/isEmpty';
import classNames from 'classnames';

import {
  getBackuserRoles,
  getBackuserKeys,
  checkRole,
  superCompanySelectRules,
  subCompanyListRules,
  superCompanyListRules,
  subCompanySelectRules
} from '../../constants/backuser-role-rules';
import { getSubCompaniesList, setSubCompanyIdOptions } from '../../actions/subCompanies-actions';
import _filter from 'lodash/filter';
import { userHeaderCompaniesSelector } from '../../selectors/all-selectors';

class BackUserRoleForm extends Component {
  constructor(props) {
    super(props);
    this.bindFunctions();
    this.setVariables(props);
    this.derivedStateFromProps(props);
  }

  // noinspection DuplicatedCode
  componentWillMount() {
    const {
      dispatch,
      detailBackUser,
      companies: allSuperCompanies,
      fields: { companyId }
    } = this.props;

    const { companies: assignedSuperCompanies, subCompanies: assignedSubCompanies, role } = detailBackUser || {};
    const allSuperCompaniesIds = _map(allSuperCompanies, getId);

    if (!_isEmpty(assignedSuperCompanies)) {
      const superCompaniesIds = _map(assignedSuperCompanies, getId);
      const excludeSelectedSuperCompanies = (company = {}) => !elemFound(superCompaniesIds, company.id);
      const availableComapnies = _filter(allSuperCompanies, excludeSelectedSuperCompanies);
      const availableCompaniesIds = _map(availableComapnies, getId);
      const firstSelectedSuperCompany = assignedSuperCompanies[0];
      const { id: firstSuperCompanyId } = firstSelectedSuperCompany || {};
      const selectedSubCompaniesIds = _map(assignedSubCompanies, getId);

      dispatch(setBackUserCompanies(availableCompaniesIds, superCompaniesIds));

      if (checkRole(subCompanyListRules, role) || checkRole(subCompanySelectRules, role)) {
        const getSubCompaniesListDispatch = dispatch(getSubCompaniesList(firstSuperCompanyId));

        getSubCompaniesListDispatch.then(subList => {
          let availableSubCompaniesIds = [];

          const setSubCompaniesData = (data = {}) => {
            const { name, id } = data;

            if (!elemFound(selectedSubCompaniesIds, id)) {
              availableSubCompaniesIds.push(id);
            }
            return { label: name, value: id };
          };

          const availableSubCompanies = subList.map(setSubCompaniesData);

          dispatch(setSubCompanyIdOptions(availableSubCompanies));
          dispatch(setBackUserSubCompanies(availableSubCompaniesIds, selectedSubCompaniesIds));
        });

        firstSuperCompanyId && companyId.onChange(firstSuperCompanyId);
      }
    } else {
      dispatch(setBackUserCompanies(allSuperCompaniesIds, []));
    }
  }

  // noinspection JSCheckFunctionSignatures
  componentWillReceiveProps(nextProps) {
    this.derivedStateFromProps(nextProps);
  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(removeBackUserCompanies());
  }

  // noinspection DuplicatedCode
  bindFunctions() {
    this.handleSelectSuperCompany = this.handleSelectSuperCompany.bind(this);
    this.handleAssignAvailableCompaniesToSelected = this.handleAssignAvailableCompaniesToSelected.bind(this);
    this.handleAssignAvailableSubCompaniesToSelected = this.handleAssignAvailableSubCompaniesToSelected.bind(this);
    this.handleAssignSelectedCompaniesToAvailable = this.handleAssignSelectedCompaniesToAvailable.bind(this);
    this.handleAssignSelectedSubCompaniesToAvailable = this.handleAssignSelectedSubCompaniesToAvailable.bind(this);
  }

  setVariables(props) {
    const {
      userInfo: { role: currentRole }
    } = props;

    this.sections = getBackuserRoles(currentRole);
    this.sections = getBackuserKeys(this.sections);
  }

  derivedStateFromProps(nextProps) {
    this.checkRoles(nextProps);
    this.createOptions(nextProps);
    if (this.showCompanyList) this.setCompanyOptions(nextProps);
    if (!this.showSuperCompanySelect && this.showSuperCompanyList) this.setSuperCompanyOptions(nextProps);
  }

  checkRoles(props) {
    const {
      fields: {
        userRole: { value: selectedRole }
      }
    } = props;

    if (selectedRole) {
      this.showSubCompanySelect = checkRole(subCompanySelectRules, selectedRole);
      this.showSuperCompanySelect = checkRole(superCompanySelectRules, selectedRole);
      this.showSuperCompanyList = checkRole(superCompanyListRules, selectedRole);
      this.showCompanyList = checkRole(subCompanyListRules, selectedRole);
      this.showListSelect = this.showSuperCompanyList || this.showCompanyList;
    }
  }

  createOptions(props) {
    const {
      fields: {
        companyId: { value: superCompanyValue }
      },
      companies,
      subCompaniesOptions,
      availableCompanies,
      selectedCompanies,
      availableSubCompanies,
      selectedSubCompanies
    } = props;

    const mapLabels = ({ name: label, id: value }) => ({ label, value });
    const superCompanySelected = (superCompanyValue || '').length > 5;

    this.companiesOptions = companies.map(mapLabels);
    this.subCompaniesOptions = superCompanySelected ? subCompaniesOptions : [];
    this.availableCompaniesOptions = createCompaniesOptions(companies, availableCompanies);
    this.selectedCompaniesOptions = createCompaniesOptions(companies, selectedCompanies);
    this.availableSubCompaniesOptions = createSubCompaniesOptions(subCompaniesOptions, availableSubCompanies);
    this.selectedSubCompaniesOptions = createSubCompaniesOptions(subCompaniesOptions, selectedSubCompanies);
  }

  // noinspection DuplicatedCode
  setCompanyOptions(props) {
    const {
      fields: { availableSubCompaniesIds, selectedSubCompaniesIds }
    } = props;

    this.leftKey = 'backUserForm_availableSubCompanies_label';
    this.leftOptions = this.availableSubCompaniesOptions;
    this.leftField = availableSubCompaniesIds;
    this.leftAction = this.handleAssignSelectedSubCompaniesToAvailable;

    this.rightKey = 'backUserForm_selectedSubCompanies_label';
    this.rightOptions = this.selectedSubCompaniesOptions;
    this.rightField = selectedSubCompaniesIds;
    this.rightAction = this.handleAssignAvailableSubCompaniesToSelected;
  }

  // noinspection DuplicatedCode
  setSuperCompanyOptions(props) {
    const {
      fields: { availableCompaniesIds, selectedCompaniesIds }
    } = props;

    this.leftKey = 'backUserForm_availableCompanies_label';
    this.leftOptions = this.availableCompaniesOptions;
    this.leftField = availableCompaniesIds;
    this.leftAction = this.handleAssignSelectedCompaniesToAvailable;

    this.rightKey = 'backUserForm_selectedCompanies_label';
    this.rightOptions = this.selectedCompaniesOptions;
    this.rightField = selectedCompaniesIds;
    this.rightAction = this.handleAssignAvailableCompaniesToSelected;
  }

  handleAssignSelectedSubCompaniesToAvailable() {
    const { dispatch, availableSubCompanies, selectedSubCompanies } = this.props;
    const companiesIds = this.props.fields.selectedSubCompaniesIds.value;

    if (companiesIds) {
      dispatch(setBackUserSubCompanies(_union(availableSubCompanies, companiesIds), _difference(selectedSubCompanies, companiesIds)));
    }
  }

  handleAssignAvailableSubCompaniesToSelected() {
    const {
      dispatch,
      availableSubCompanies,
      selectedSubCompanies,
      fields: { availableSubCompaniesIds }
    } = this.props;
    const companiesIds = availableSubCompaniesIds.value;

    if (companiesIds) {
      dispatch(setBackUserSubCompanies(_difference(availableSubCompanies, companiesIds), _union(selectedSubCompanies, companiesIds)));
    }
  }

  handleAssignSelectedCompaniesToAvailable() {
    const { dispatch, availableCompanies, selectedCompanies } = this.props;
    const companiesIds = this.props.fields.selectedCompaniesIds.value;

    if (companiesIds) {
      dispatch(setBackUserCompanies(_union(availableCompanies, companiesIds), _difference(selectedCompanies, companiesIds)));
    }
  }

  handleAssignAvailableCompaniesToSelected() {
    const {
      dispatch,
      availableCompanies,
      selectedCompanies,
      fields: { availableCompaniesIds }
    } = this.props;
    const companiesIds = availableCompaniesIds.value;

    if (companiesIds) {
      dispatch(setBackUserCompanies(_difference(availableCompanies, companiesIds), _union(selectedCompanies, companiesIds)));
    }
  }

  handleSelectSuperCompany(value) {
    const { dispatch } = this.props;

    dispatch(getSubCompaniesList(value)).then(subList => {
      const subCompanies = _map(subList, getId);
      const availableSubCompanies = [];

      dispatch(setBackUserSubCompanies(subCompanies, []));

      subList.forEach((item = {}) => {
        availableSubCompanies.push({
          label: item.name,
          value: item.id
        });
      });

      dispatch(setSubCompanyIdOptions(availableSubCompanies));
    });
  }

  getRoles() {
    const {
      fields: { userRole }
    } = this.props;

    return this.sections.reduce((data, group, i) => {
      const { labelKey, roles } = group;
      const reactKey = i + '.' + labelKey;

      if (!_isEmpty(roles)) {
        // noinspection JSUnresolvedFunction
        data.push(this.radioButton(reactKey, labelKey, roles, userRole));
      }

      return data;
    }, []);
  }

  displayRoles() {
    const htmlRoles = this.getRoles();
    if (!_isEmpty(htmlRoles)) return this.formRow(htmlRoles, 'backUserForm_roles');
  }

  formRow(child, className) {
    return <FormRow customWrapperClass={classNames('backUserForm_row', className)}>{child}</FormRow>;
  }

  radioButton(key, titleKey, items, field) {
    return (
      <EkRadio
        customWrapperClass="backUserForm_ekRadioWrapper"
        formRowItemKey={key}
        key={key}
        titleKey={titleKey}
        items={items}
        field={field}
      >
        <FieldErrorMsg field={field} customClass="fieldErrorMsg--backUserForm" />
      </EkRadio>
    );
  }

  displaySuperSelect() {
    const {
      fields: { companyId }
    } = this.props;

    if (this.showSuperCompanySelect) {
      return (
        <FormRow customClass="backUserForm_row">
          <BoxedSelect
            formRowItemKey="backUserForm_companieId"
            labelKey="backUserForm_superCompanie_label"
            options={this.companiesOptions}
            customClass="backUserForm_boxedSelectWrapper"
            blockCustomClass="backUserForm_boxedSelectBlock"
            onChange={this.handleSelectSuperCompany}
            field={companyId}
            fullWidth
          >
            <FieldErrorMsg field={companyId} customClass="fieldErrorMsg--backUserForm" />
          </BoxedSelect>
        </FormRow>
      );
    }
  }

  displaySubSelect() {
    const {
      fields: { subCompanyId }
    } = this.props;

    if (this.showSubCompanySelect) {
      return (
        <FormRow customClass="backUserForm_row">
          <BoxedSelect
            formRowItemKey="backUserForm_subCompanyId"
            labelKey="backUserForm_subCompanie_label"
            options={this.subCompaniesOptions}
            customClass="backUserForm_boxedSelectWrapper"
            blockCustomClass="backUserForm_boxedSelectBlock"
            field={subCompanyId}
            fullWidth
          >
            <FieldErrorMsg field={subCompanyId} customClass="fieldErrorMsg--backUserForm" />
          </BoxedSelect>
        </FormRow>
      );
    }
  }

  displayListSelect() {
    if (this.showListSelect) {
      return (
        <FormRow customWrapperClass="backUserForm_row" customClass="backUserRoleForm_rowItem">
          <BoxedSelect
            multiple
            formRowItemKey="backUserForm_availableCompaniesSelect"
            id="availableCompaniesSelect"
            customClass="backUserRoleForm_boxedSelectMultipleWrapper"
            customSelectClass="backUserRoleForm_boxedSelect"
            labelKey={this.leftKey}
            options={this.leftOptions}
            field={this.leftField}
          />
          <div className="backUserRoleForm_actionsAssign">
            <button
              type="button"
              className="backUserRoleForm_assignButton backUserRoleForm_assignButton--left"
              onClick={this.leftAction}
              disabled={_isEmpty(this.rightOptions)}
            >
              <FormattedMessage id="backUserForm_addToAvailable_button" />
            </button>

            <button
              type="button"
              className="backUserRoleForm_assignButton backUserRoleForm_assignButton--right"
              onClick={this.rightAction}
              disabled={_isEmpty(this.leftOptions)}
            >
              <FormattedMessage id="backUserForm_addToSelected_button" />
            </button>
          </div>
          <BoxedSelect
            multiple
            formRowItemKey="backUserForm_selectedCompaniesSelect"
            id="selectedCompaniesSelect"
            customClass="backUserRoleForm_boxedSelectMultipleWrapper"
            customSelectClass="backUserRoleForm_boxedSelect"
            labelKey={this.rightKey}
            options={this.rightOptions}
            field={this.rightField}
          >
            <FieldErrorMsg field={this.rightField} customClass="fieldErrorMsg--backUserForm" />
          </BoxedSelect>
        </FormRow>
      );
    }
  }

  displayCompanySelect() {
    return (
      <div>
        {this.displaySuperSelect()}
        {this.displaySubSelect()}
        {this.displayListSelect()}
      </div>
    );
  }

  render() {
    return (
      <div className="backUserRoles">
        {this.displayRoles()}
        {this.displayCompanySelect()}
      </div>
    );
  }
}

export default connect(state => {
  const {
    backUsers: { detailBackUser, availableCompanies, selectedCompanies, availableSubCompanies, selectedSubCompanies },
    subCompanies: { subCompaniesOptions },
    user: { userInfo }
  } = state;

  return {
    detailBackUser,
    companies: userHeaderCompaniesSelector(state),
    subCompaniesOptions,
    availableCompanies,
    selectedCompanies,
    availableSubCompanies,
    selectedSubCompanies,
    userInfo
  };
})(BackUserRoleForm);
