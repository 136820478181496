// eslint-disable-next-line strict
'use strict';

let path = require('path');
let config = require('./base-paths');

const themeColors = require('./theme-colors');

config.projectName = 'glide';

// main html index location
config.index = path.join(config.src, '/index.html');
config.indexNoContextName = 'index.nocontext.html';

config.robotsTxt = path.join(config.src, '/robots.txt');

config.scripts = {
  // entry point for the browserify bundler
  index: path.join(config.projectRoot, '/app.jsx'),
  // files glob for the static analysis tools/watcher
  src: [path.join(config.projectRoot, '/**/*.{js,jsx}')],
  // destination folder for the generated js
  dest: config.dest + '/js'
};

config.styles = {
  // entry point for the sass compiler
  index: path.join(config.src, '/styles/*.scss'),
  generatedSpecificsDest: path.join(config.src, '/styles/specifics/_generated-specifics.scss'),
  // folders for the static analysis tools
  // cf https://github.com/juanfran/gulp-scss-lint/issues/51
  src: [path.join(config.projectRoot), path.join(config.src, '/styles')],
  // files glob for the watcher
  watchedSrc: path.join(config.src, '/**/*.scss'),
  // destination folder for the generated css
  dest: path.join(config.dest, '/css')
};

config.imgHtml = {
  src: [path.join(config.resources, '/img-html/common/**/*')],
  dest: path.join(config.dest, '/img')
};

config.imgCss = {
  src: [path.join(config.resources, '/img-css/common/**/*')],
  dest: path.join(config.styles.dest, '/img')
};

config.mockups = {
  src: [path.join(config.src, '/mockups/**/*')],
  dest: path.join(config.dest, '/mockups')
};

config.redirect = {
  src: path.join(config.src, '/custom-redirect'),
  dest: path.join(config.dest, '/customredirect')
};

config.fonts = {
  src: [path.join(config.resources, '/fonts/common/**/*')],
  dest: path.join(config.dest, '/fonts')
};

config.i18n = {
  src: path.join(config.resources, '/i18n'),
  dest: path.join(config.dest, '/i18n')
};
config.i18n.common = path.join(config.i18n.src, 'common');

config.modulesStylesSrc = path.join(config.projectRoot, '/**/*.scss');

config.modernizr = path.join(config.resources, '/vendor/modernizr.custom.js');

config.lintSpacesSrc = [
  path.join(config.src, '/**/*'),
  '!' + path.join(config.src, '/**/vendor/**/*'),
  '!' + path.join(config.src, '/**/resources/**/*')
];

config.cleanup = [config.dest, path.join(config.root, '/**/generated-*')];

config.testRunner = {
  karma: path.join(__dirname, '/test-runner-karma.js')
};

config.server = path.join(__dirname, '/server.js');

config.versionFileDest = path.join(config.root, '/generated-version.json');

config.revAssets = [
  path.join(config.dest, '/**/fonts/**/*'),
  // path.join(config.dest, '/**/css/img/**/*'),
  path.join(config.dest, '/**/img/**/*'),
  '!' + path.join(config.dest, '/**/*.ico')
];
config.revReplace = path.join(config.dest, '/**/*.css');
config.rev = [path.join(config.dest, '/**/css/**/*.css'), path.join(config.dest, '/**/js/**/*.js')];

config.context = {
  devMode: true,
  debugMode: true,
  useLogger: false,
  webBaseUrl: 'http://localhost:9000',
  glideWebBaseUrl: 'http://localhost:9000',
  renaultWebBaseUrl: 'http://localhost:9000',
  rrentWebBaseUrl: 'http://localhost:3000',
  nissanWebBaseUrl: 'http://localhost:9000',
  engieWebBaseUrl: 'http://localhost:9000',
  egoWebBaseUrl: 'http://localhost:9000',
  sncfWebBaseUrl: 'http://localhost:9000',
  shareMobilityWebBaseUrl: 'http://localhost:9000',
  agilautoWebBaseUrl: 'http://localhost:9000'
};

config.webUrls = {
  GLIDE_WEB: config.context.glideWebBaseUrl,
  RENAULT_WEB: config.context.renaultWebBaseUrl,
  RRENT_WEB: config.context.rrentWebBaseUrl,
  NISSAN_WEB: config.context.nissanWebBaseUrl,
  ENGIE_WEB: config.context.engieWebBaseUrl,
  EGO_WEB: config.context.egoWebBaseUrl,
  SHARE_MOBILITY_WEB: config.context.shareMobilityWebBaseUrl,
  SNCF_WEB: config.context.sncfWebBaseUrl,
  AGILAUTO_WEB: config.context.agilautoWebBaseUrl
};

config.contextScript = path.join(__dirname, 'set-context.js');
config.contextScriptHelper = path.join(__dirname, 'set-context-helper.js');

// TODO: use constants | check if needed
config.themesDomain = {
  glide: {
    front: 'app.glide.io',
    back: 'admin.glide.io'
  },
  renaultB2B: {
    front: 'share.mobilize.com',
    back: 'admin.renault-mobility.com'
  },
  engie: {
    front: 'carsharing.engie.com',
    back: 'admin.carsharing.engie.com'
  },
  ego: {
    front: 'app.e-go.se',
    back: 'admin.e-go.se'
  },
  'share-mobility': {
    front: 'app.share-mobility.be',
    back: 'admin.share-mobility.be'
  },
  sncf: {
    front: 'resa-auto-service.sncf.fr',
    back: 'admin-resa-auto-service.sncf.fr'
  },
  agilauto: {
    front: 'app.agilautopartage-ca.fr',
    back: 'admin.agilautopartage-ca.fr'
  }
};

config.i18nFilePrefix = 'bo';

// search key-word 'en'

config.defaultLanguages = ['en', 'fr', 'es', 'it', 'pt', 'de', 'da', 'nl', 'pl', 'sv', 'cs', 'pt_BR', 'sk'];

config.themesLanguages = {
  glide: config.defaultLanguages,
  renaultB2B: config.defaultLanguages,
  engie: config.defaultLanguages,
  ego: config.defaultLanguages,
  'share-mobility': config.defaultLanguages,
  nissan: ['en', 'fr'],
  sncf: config.defaultLanguages,
  agilauto: config.defaultLanguages
};

config.themesColors = themeColors;

config.maven = {
  groupId: 'com.glide',
  repository: {
    snapshot: {
      id: 'glide-snapshots',
      url: 'http://maven.fullsix.com/nexus/content/repositories/glide-snapshots'
    },
    release: {
      id: 'glide-releases',
      url: 'http://maven.fullsix.com/nexus/content/repositories/glide-releases'
    }
  }
};

config.gulp = {
  compareAllStrings: false // TODO: 17/07/05: have no effect, to review later
};

module.exports = config;
