/* eslint-disable react/no-danger */
/*eslint-disable react/jsx-no-bind */

import React, { Component, PropTypes as T } from 'react';
import { connect } from 'react-redux';
import _debounce from 'lodash/debounce';
import _values from 'lodash/values';
import _get from 'lodash/get';
import _partial from 'lodash/partial';
import ReactSVG from 'react-svg';
import QRCode from 'qrcode-svg';
import Dialog from 'material-ui/Dialog';
import autoBind from 'react-autobind';
import AutoComplete from 'material-ui/AutoComplete';
import IconButton from 'material-ui/IconButton';
import CloseIcon from 'material-ui/svg-icons/navigation/close';
import { Tabs, Tab } from 'material-ui/Tabs';
import { partialErrorCodes } from '../../../constants/options-constants';
import { VEHICLE_USAGE_TYPE_NO_USAGE, VEHICLE_USAGE_TYPE_PUBLIC_SERVICE } from '../../../constants/backend-constants';

import {
  toggleCreateBookingMemberSelect,
  saveUserLoginForBooking,
  toggleCreateBookingModal,
  clearCreateBookingModal,
  errorWhileSelectingUserForBooking,
  searchMembers,
  searchPreMembers,
  clearFoundUsers,
  createPreSubscribeMember,
  addMemberRequest,
  addMemberSuccess,
  addFlashMessage,
  cleanErrorWhileSelectingUserForBooking,
  getMemberDetail
} from '../../../actions/all-actions';
import { injectIntl, FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { RaisedButton } from 'material-ui';
import PreSubscribeForm from '../../../components/PreSubscribeForm/PreSubscribeForm';
import { FLASH_MESSAGE_TYPE_SUCCESS } from '../../../constants/generic-constants';
import { addErrorMessage, getMainColor, isEmpty, isValidEmail, vehicleUsageFiltered } from '../../../utils/utils';
import {
  bundleSelector,
  localeSelector,
  userRoleSelector,
  registerCustomFieldsSelector,
  selectCustomFieldsNames,
  headerCompanyContractSelector,
  headerCompanyDetailsSelector
} from '../../../selectors/all-selectors';
import { saveUserTypeForBookingCreate } from '../VehiclePlanning.actions';

class VehiclePlanningMemberSelect extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.ajustHeight = false;
    this.handleGetUsers = _debounce(this.handleGetUsers, 150);
    this.handleGetOrSetUsers = _debounce(this.handleGetOrSetUsers, 150);
    this.state = { stepMemberFiles: false, memberName: null, profileId: '' };
  }

  componentWillMount() {
    const { dispatch } = this.props;
    dispatch(clearFoundUsers());
    this.setStyles();
  }

  componentDidMount() {
    this.hasPrebooking() ? document.getElementById('prebooking-member').focus() : '';
  }

  setStyles() {
    this.styles = {
      headline: {
        fontSize: 24,
        paddingTop: 16,
        fontWeight: 400
      },
      tab: {
        backgroundColor: 'white',
        color: getMainColor()
      },
      tabHide: {
        backgroundColor: 'white',
        color: getMainColor(),
        display: 'none !important',
        width: '0px'
      },
      tabPre: {
        backgroundColor: 'white',
        color: getMainColor(),
        width: '100% !important',
        display: 'block !important'
      },
      inkBar: {
        backgroundColor: getMainColor()
      }
    };
  }

  handleClose() {
    this.props.dispatch(toggleCreateBookingMemberSelect());
    this.props.dispatch(clearCreateBookingModal());
  }

  handleGetUsers(input) {
    const { dispatch, data } = this.props;
    const companyId = _get(data, 'company.id');
    dispatch(searchMembers(companyId, input));
  }

  handleGetOrSetUsers(input) {
    const { dispatch, data } = this.props;
    const companyId = _get(data, 'company.id');
    dispatch(searchPreMembers(companyId, input));
  }

  pickMember(isPreBooking = false, user) {
    const { dispatch } = this.props;
    dispatch(saveUserTypeForBookingCreate(user));
    dispatch(saveUserLoginForBooking(user, isPreBooking));
    dispatch(getMemberDetail(user.entity.id));
    if (!this.state.memberName) {
      this.continueToBooking();
    }
  }

  continueToBooking() {
    const { dispatch } = this.props;
    dispatch(toggleCreateBookingModal());
    dispatch(toggleCreateBookingMemberSelect());
  }

  handleSelectUser(isPreBooking = false, user) {
    const { dispatch, foundUsers, allowRegistrationWithoutSso } = this.props;

    if (user.hasOwnProperty('value')) {
      this.pickMember(isPreBooking, user);
    } else if (foundUsers.length > 0) {
      this.pickMember(isPreBooking, foundUsers[0]);
    } else if (isValidEmail(user) && allowRegistrationWithoutSso) {
      this.renderCreateMember();
    } else {
      dispatch(errorWhileSelectingUserForBooking());
    }
  }

  hasPrebooking() {
    const { firstHourSelected, firstDaySelected } = this.props;
    const usage = _get(firstHourSelected, 'vehicle.usage') || _get(firstDaySelected, 'vehicle.usage');

    return vehicleUsageFiltered(usage);
  }

  createQrMarkup() {
    const urlValue = window.location.origin + '/#//member-files/' + this.state.profileId + '?token=' + window.localStorage.getItem('token');
    const qr = new QRCode({ content: urlValue, container: 'svg-viewbox', width: '400', height: '400', join: true });
    return { __html: qr.svg() };
  }

  memberAddFiles() {
    return (
      <div className="modal-qr-code">
        <h4>
          <FormattedMessage id="presubscribe-member-add-documents-for" />
          <span className="member-name">{this.state.memberName}</span>
        </h4>
        <div className="add-member-files">
          <ReactSVG src="/img/qr-scan-icon.svg" svgClassName="svg-icon mobile-qr" />
          <FormattedMessage id="presubscribe-member-add-documents" />
          <div dangerouslySetInnerHTML={this.createQrMarkup()} />
          <FormattedMessage id="presubscribe-member-qr-desc" />
          <div className="small-size-text">
            <FormattedMessage id="presubscribe-member-qr-desc2" />
          </div>

          <div className="presubscribe-fake-actions">
            <RaisedButton
              className="flatButton"
              label={this.props.intl.formatMessage({ id: 'presubscribe-member-skip-action' })}
              onClick={this.continueToBooking}
            />
            <RaisedButton
              primary
              className="flatButton"
              label={this.props.intl.formatMessage({ id: 'presub-member-next-action' })}
              onClick={this.continueToBooking}
            />
          </div>
        </div>
      </div>
    );
  }

  active() {
    const { dispatch } = this.props;
    dispatch(cleanErrorWhileSelectingUserForBooking());

    switch (arguments[0].props.index) {
      case 0:
        document.getElementById('prebooking-member').focus();
        break;
      case 1:
        document.getElementById('booking-member').focus();
        break;
      default:
        return '';
    }
  }

  handleTabsChange() {}

  renderCreateMember() {
    const { dispatch, preSubMember } = this.props;
    this.ajustHeight = true;
    dispatch(createPreSubscribeMember(preSubMember));
  }

  renderTabs() {
    const {
      intl: { formatMessage },
      proceedNew,
      preSubMember,
      locale,
      drivingLicenceRequired,
      sendEmailsToManager,
      useExternalInvoiceSystem
    } = this.props;

    const { firstHourSelected, firstDaySelected } = this.props;
    let usageVehicle = _get(firstDaySelected, 'vehicle.usage');
    if (!usageVehicle) usageVehicle = _get(firstHourSelected, 'vehicle.usage');

    const initialSelectedIndex = usageVehicle === VEHICLE_USAGE_TYPE_PUBLIC_SERVICE ? 1 : 0;

    return (
      <Tabs
        style={{ marginTop: '10px' }}
        className="tabs-rci"
        inkBarStyle={this.styles.inkBar}
        initialSelectedIndex={initialSelectedIndex}
        onChange={this.handleTabsChange}
      >
        <Tab
          label={formatMessage({ id: 'common_preBooking' })}
          buttonStyle={proceedNew ? this.styles.tabPre : this.styles.tab}
          className={proceedNew ? 'tabPre' : 'tab'}
          onActive={this.active}
        >
          <div>
            <h2 style={this.styles.headline}>
              <FormattedMessage
                id={proceedNew ? 'vehiclePlanning_create_member_to_prebook' : 'vehiclePlanning_searchForMember_to_prebook'}
              />
            </h2>
            {!proceedNew && this.renderFindMember(true)}
            {proceedNew && !this.state.stepMemberFiles && (
              <PreSubscribeForm
                onCallback={this.createMember}
                className="modal"
                initialValues={{ login: preSubMember.email, locale }}
                drivingLicenceRequired={drivingLicenceRequired}
                sendEmailsToManager={sendEmailsToManager}
                useExternalInvoiceSystem={useExternalInvoiceSystem}
              />
            )}
            {this.state.stepMemberFiles && this.memberAddFiles()}
            {!proceedNew && this.hintPreBooking()}
          </div>
        </Tab>
        <Tab
          label={formatMessage({ id: 'common_booking' })}
          buttonStyle={proceedNew ? this.styles.tabHide : this.styles.tab}
          className={proceedNew ? 'tabHide' : 'tab'}
          onActive={this.active}
        >
          <div>{this.renderFindMember()}</div>
        </Tab>
      </Tabs>
    );
  }

  hintPreBooking() {
    const { allowRegistrationWithoutSso } = this.props;
    return (
      <div>
        <div className="upload-member-hr" />
        <FormattedMessage id="vehiclePlanning_how_to_prebook" />
        <div className="upload-member-tuto">
          <div className="upload-member-tuto-col">
            <div className="upload-member-tuto-row">
              <div style={{ marginBottom: '5px' }}>
                {this.stepNumber(1)}
                <span> </span>
                <FormattedHTMLMessage id="preBooking_step_1_title" />
              </div>
            </div>
            <div className="upload-member-tuto-row">
              {this.stepNumber(2)}
              <span> </span>
              <FormattedHTMLMessage id="preBooking_step_2_title" />
            </div>
          </div>
          <div className="upload-member-vr" />
          <div className="upload-member-tuto-col">
            <div className="upload-member-tuto-row">
              {this.stepNumber(3)}
              <span> </span>
              <FormattedHTMLMessage id="preBooking_step_3_title" />
            </div>
            {allowRegistrationWithoutSso && (
              <div className="upload-member-tuto-row">
                {this.stepNumber(4)}
                <span> </span>
                <FormattedHTMLMessage id="preBooking_step_4_title" />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }

  stepNumber(n) {
    const {
      intl: { formatMessage }
    } = this.props;

    return (
      <strong>
        <FormattedMessage id="data.with.colon" values={{ data: formatMessage({ id: 'common_step_n' }, { n }) }} />
      </strong>
    );
  }

  createMember() {
    const { dispatch, bundle } = this.props;

    dispatch(addMemberRequest(this.props.data.company.id, this.props.data.subCompany.id, true))
      .then(memberAdded => {
        dispatch(addMemberSuccess(memberAdded));

        const userAdapter = {
          text: memberAdded.firstName + ' ' + memberAdded.lastName + '  -  ' + memberAdded.login,
          shortName: memberAdded.firstName + ' ' + memberAdded.lastName,
          value: memberAdded.login,
          entity: memberAdded
        };
        this.setState({ stepMemberFiles: true, memberName: memberAdded.firstName + ' ' + memberAdded.lastName, profileId: memberAdded.id });
        dispatch(
          addFlashMessage({
            contentKey: 'member_preSubscriptionForm_success',
            type: FLASH_MESSAGE_TYPE_SUCCESS
          })
        );

        this.handleSelectUser(true, userAdapter);
      })
      .catch(error => {
        const partialErrors = _values(partialErrorCodes.members);
        const params = { bundle, error, partialErrors, def: 'common_error' };

        dispatch(addErrorMessage(params));
      });
  }

  renderFindMember(isPreBooking = false) {
    const {
      errorMemberSelection,
      foundUsers,
      proceedNew,
      memberExist,
      preSubMemberReady,
      firstHourSelected,
      firstDaySelected,
      allowRegistrationWithoutSso
    } = this.props;
    const { formatMessage } = this.props.intl;
    const usage = _get(firstHourSelected, 'vehicle.usage') || _get(firstDaySelected, 'vehicle.usage');
    if (usage === VEHICLE_USAGE_TYPE_NO_USAGE)
      return (
        <div>
          <FormattedMessage id="vehiclePlanning_noUsage_noBooking" />
        </div>
      );
    else
      return (
        <div>
          {!isPreBooking && (
            <h2 style={this.styles.headline}>
              <FormattedMessage id="vehiclePlanning_searchForMember" />
            </h2>
          )}
          <AutoComplete
            hintText={formatMessage({ id: 'hint_search_by_name' })}
            dataSource={foundUsers}
            dataSourceConfig={{ text: 'text', value: 'value' }}
            onUpdateInput={isPreBooking ? this.handleGetOrSetUsers : this.handleGetUsers}
            onNewRequest={_partial(this.handleSelectUser, isPreBooking)}
            filter={() => true}
            id={isPreBooking ? 'prebooking-member' : 'booking-member'}
            fullWidth
            autoFocus
            menuStyle={{ maxHeight: '200px', overflowY: 'auto' }}
          />

          {isPreBooking && preSubMemberReady && !proceedNew && !memberExist && allowRegistrationWithoutSso && foundUsers.length === 0 && (
            <RaisedButton
              primary
              style={{ float: 'right' }}
              className="flatButton"
              label={this.props.intl.formatMessage({ id: 'vehiclePlanning_create_member_to_prebook' })}
              onClick={this.renderCreateMember}
            />
          )}
          {errorMemberSelection && (
            <div className="fieldErrorMsg">{this.props.intl.formatMessage({ id: 'member_cannot_be_selected' })}</div>
          )}
          {!!memberExist && <div className="member-exist" children={<FormattedMessage id="presubscribe_member_already_exist" />} />}
        </div>
      );
  }

  render() {
    const { openMemberSelectModal, proceedNew } = this.props;
    return (
      <div className="container">
        {openMemberSelectModal && (
          <Dialog
            bodyStyle={{ overflow: 'scroll' }}
            contentStyle={{ width: proceedNew ? '80%' : '600px', maxWidth: proceedNew ? 'none' : 'auto' }}
            onRequestClose={this.handleClose}
            open={openMemberSelectModal}
            repositionOnUpdate={this.ajustHeight}
          >
            <div className="vehiclePlanning_closeIconAbosuluteWrap">
              <IconButton onClick={this.handleClose}>
                <CloseIcon />
              </IconButton>
            </div>
            <div>
              {this.hasPrebooking() && this.renderTabs()}
              {!this.hasPrebooking() && this.renderFindMember()}
            </div>
          </Dialog>
        )}
      </div>
    );
  }
}

VehiclePlanningMemberSelect.displayName = 'VehiclePlanningBooking';

VehiclePlanningMemberSelect.propTypes = {
  booking: T.object
};

export default connect(state => {
  const contract = headerCompanyContractSelector(state);
  const { preBooking } = contract;
  const customFields = registerCustomFieldsSelector(state);
  const customFieldsNames = selectCustomFieldsNames(customFields);
  const { ssoConfiguration } = headerCompanyDetailsSelector(state);
  const { allowRegistrationWithoutSso } = isEmpty(ssoConfiguration) ? { allowRegistrationWithoutSso: true } : ssoConfiguration;
  const {
    vehiclePlanning: {
      openMemberSelectModal,
      errorMemberSelection,
      firstHourSelected,
      firstDaySelected,
      data,
      foundUsers,
      preSubMember,
      proceedNew,
      memberExist,
      preSubMemberReady
    },
    companies: {
      currentCompanyDetails: { drivingLicenceRequired, sendEmailsToManager, useExternalInvoiceSystem }
    }
  } = state;

  return {
    openMemberSelectModal,
    errorMemberSelection,
    firstHourSelected,
    firstDaySelected,
    data,
    foundUsers,
    preBooking,
    preSubMember,
    proceedNew,
    preSubMemberReady,
    memberExist,
    drivingLicenceRequired,
    sendEmailsToManager,
    useExternalInvoiceSystem,
    allowRegistrationWithoutSso,
    customFields,
    customFieldsNames,
    bundle: bundleSelector(state),
    locale: localeSelector(state),
    userRole: userRoleSelector(state)
  };
})(injectIntl(VehiclePlanningMemberSelect));
