import React, { Component, PropTypes } from 'react';
import { FormattedMessage } from 'react-intl';
import { reduxForm, addArrayValue } from 'redux-form';
import { connect } from 'react-redux';

import IsGranted from '../IsGranted/IsGranted';
import ParkingMap from '../ParkingMap/ParkingMap';
import FormRow from '../FormRow/FormRow';
import BoxedInput from '../BoxedInput/BoxedInput';
import EkRadio from '../EkRadio/EkRadio';
import EkButton from '../EkButton/EkButton';
import FieldErrorMsg from '../FieldErrorMsg/FieldErrorMsg';
import ClosingDatesSlots from '../SlotsTypes/ClosingDatesSlots';
import OpeningHoursSlots from '../SlotsTypes/OpeningHoursSlots';
import WeeklyHoursSlots from '../SlotsTypes/WeeklyHoursSlots';
import { createValidator, notEmpty, length, latitude, longitude, radius, emptySlots } from '../../validation/all-validation';
import { booleanOptions, allowOptions } from '../../constants/options-constants';
import { BACKUSER_ROLE_FLEET_MANAGER } from '../../constants/backend-constants';
import config from '../../constants/config-constants';
import routes from '../../constants/routes-constants';
import BackLink from '../BackLink/BackLink';
import { getCompanyNameUrl, getSubCompanyNameUrl, isObjEmpty, scrollToFirstError } from '../../utils/utils';
import { routeActions } from 'react-router-redux';

class ParkingForm extends Component {
  constructor() {
    super();

    this.handleChange = this.handleChange.bind(this);
    this.handleAddClosingDate = this.handleAddClosingDate.bind(this);
    this.handleRemoveClosingDate = this.handleRemoveClosingDate.bind(this);
    this.handleAddSpecialDate = this.handleAddSpecialDate.bind(this);
    this.handleRemoveSpecialDate = this.handleRemoveSpecialDate.bind(this);
    this.handleAddSpecialDateTime = this.handleAddSpecialDateTime.bind(this);
    this.handleRemoveSpecialDateTime = this.handleRemoveSpecialDateTime.bind(this);
    this.handleAddWeeklyDate = this.handleAddWeeklyDate.bind(this);
    this.handleRemoveWeeklyDate = this.handleRemoveWeeklyDate.bind(this);
    this.handleAddWeeklyDateTime = this.handleAddWeeklyDateTime.bind(this);
    this.handleRemoveWeeklyDateTime = this.handleRemoveWeeklyDateTime.bind(this);
    this.followUrl = this.followUrl.bind(this);
  }

  followUrl(url) {
    return () => {
      if (this.props.onCancel) {
        return this.props.onCancel();
      }
      this.props.dispatch(routeActions.push(url.replace('/#', '')));
    };
  }

  handleAddClosingDate() {
    this.props.fields.specialClosingDates.addField(config.slotsTypes.specialClosingDates);
  }

  handleRemoveClosingDate(index) {
    this.props.fields.specialClosingDates.removeField(index);
  }

  handleAddSpecialDate() {
    this.props.fields.specialOpeningHours.addField(config.slotsTypes.specialOpeningHours);
  }

  handleRemoveSpecialDate(index) {
    this.props.fields.specialOpeningHours.removeField(index);
  }

  handleAddSpecialDateTime(index) {
    this.props.fields.specialOpeningHours[index].items.addField(config.slotsTypes.timeSlot);
  }

  handleRemoveSpecialDateTime(index, hoursIndex) {
    this.props.fields.specialOpeningHours[index].items.removeField(hoursIndex);
  }

  handleAddWeeklyDate() {
    this.props.fields.weeklyOpeningHours.addField(config.slotsTypes.weeklyOpeningHours);
  }

  handleRemoveWeeklyDate(index) {
    this.props.fields.weeklyOpeningHours.removeField(index);
  }

  handleAddWeeklyDateTime(index) {
    this.props.fields.weeklyOpeningHours[index].items.addField(config.slotsTypes.timeSlot);
  }

  handleRemoveWeeklyDateTime(index, hoursIndex) {
    this.props.fields.weeklyOpeningHours[index].items.removeField(hoursIndex);
  }

  handleChange(data) {
    if (this.props.onChangeOpen) {
      this.props.onChangeOpen(data);
    }
  }

  getCancelUrl() {
    const { currentSite, currentParking, userInfo } = this.props;

    return userInfo.role === BACKUSER_ROLE_FLEET_MANAGER
      ? `/#${routes.parkings.path}`
      : `/#${routes.siteDetails.path.replace(':siteId', currentParking && currentParking.site ? currentParking.site.id : currentSite.id)}`;
  }

  getSubCompanyDetails() {
    const { subCompany } = this.props;

    if (!isObjEmpty(subCompany)) {
      return (
        <span>
          <span className="sc-dash">—</span>
          {getSubCompanyNameUrl(subCompany.name, subCompany.id)}
        </span>
      );
    }
  }

  getCompanyDetails() {
    const { company } = this.props;

    if (!isObjEmpty(company)) {
      return (
        <span className="sc-company-info">
          {getCompanyNameUrl(company.name, company.id)}
          {this.getSubCompanyDetails()}
        </span>
      );
    }
  }

  render() {
    const {
      fields: {
        name,
        latitude,
        longitude,
        radius,
        alwaysOpen,
        privateAccess,
        disabledAccess,
        electricCharging,
        additionalInformation,
        rrsParkingId,
        gsmConnection,
        pluggedCheck,
        findable,
        applyFrenchBankHolidays,
        finishBookingOutsideOpeningHours,
        finishBookingOutsideParkingArea,
        specialClosingDates,
        specialOpeningHours,
        weeklyOpeningHours,
        bankHolidays
      },
      handleSubmit,
      limitedAccess
    } = this.props;

    const submitAction = limitedAccess ? undefined : handleSubmit(this.props.onCallback);
    const cancelUrl = this.getCancelUrl();

    return (
      <section className="parkingFormWrapper">
        {!this.props.isModal && <BackLink link={cancelUrl} labelKey="back_link_site_detait" />}
        <form action="#" className="parkingForm" onSubmit={submitAction}>
          <div className="parkingForm_container">
            <h4 className="parkingForm_title">
              <FormattedMessage id="parkingForm_title" />
              {this.getCompanyDetails()}
            </h4>

            <FormRow customClass="parkingForm_row">
              <BoxedInput
                formRowItemKey="parkingForm_name"
                type="text"
                id="name"
                labelKey="parkingForm_name_label"
                customClass="parkingForm_boxedInput"
                field={name}
                readOnly={limitedAccess === 'true'}
              >
                <FieldErrorMsg field={name} customClass="fieldErrorMsg--parkingForm" />
              </BoxedInput>
            </FormRow>

            <span className="boxedInput_labelText">
              <FormattedMessage id="parkingForm_coordinates_label" />
            </span>
            <FormRow customClass="parkingForm_row">
              <BoxedInput
                formRowItemKey="parkingForm_coordinates_latitude"
                type="text"
                id="latitude"
                labelKey="parkingForm_coordinates_latitude"
                customLabelClass="parkingForm_coordinatesLabel"
                customClass="parkingForm_boxedInputWrapper"
                customLabelTextClass="parkingForm_coordinatesLabelText"
                customInputClass="boxedInput--coords"
                field={latitude}
                readOnly={limitedAccess === 'true'}
              >
                <FieldErrorMsg field={latitude} customClass="fieldErrorMsg--parkingForm" />
              </BoxedInput>

              <BoxedInput
                formRowItemKey="parkingForm_coordinates_longitude"
                type="text"
                id="longitude"
                labelKey="parkingForm_coordinates_longitude"
                customLabelClass="parkingForm_coordinatesLabel"
                customClass="parkingForm_boxedInputWrapper"
                customLabelTextClass="parkingForm_coordinatesLabelText"
                customInputClass="boxedInput--coords"
                field={longitude}
                readOnly={limitedAccess === 'true'}
              >
                <FieldErrorMsg field={longitude} customClass="fieldErrorMsg--parkingForm" />
              </BoxedInput>

              <BoxedInput
                formRowItemKey="parkingForm_radius"
                type="text"
                id="radius"
                labelKey="parkingForm_radius"
                customLabelClass="parkingForm_coordinatesLabel"
                customClass="parkingForm_boxedInputWrapper"
                customLabelTextClass="parkingForm_coordinatesLabelText"
                customInputClass="boxedInput--coords"
                field={radius}
                readOnly={limitedAccess === 'true'}
              >
                <FieldErrorMsg field={radius} customClass="fieldErrorMsg--parkingForm" />
              </BoxedInput>
            </FormRow>

            <FormRow customClass="parkingForm_row">
              <ParkingMap limitedAccess={limitedAccess} />
            </FormRow>

            <FormRow customClass="parkingForm_row">
              <BoxedInput
                formRowItemKey="parkingForm_additionalInformation"
                type="text"
                id="additionalInformation"
                labelKey="parkingForm_additionalInformation_label"
                customClass="parkingForm_boxedInput"
                maxlength="255"
                field={additionalInformation}
                readOnly={limitedAccess === 'true'}
              />
            </FormRow>
            <IsGranted disallowed={[BACKUSER_ROLE_FLEET_MANAGER]}>
              <FormRow customClass="parkingForm_row">
                <BoxedInput
                  formRowItemKey="parkingForm_parkingId"
                  type="text"
                  id="rrsParkingId"
                  labelKey="parkingForm_parkingId_label"
                  customClass="parkingForm_boxedInput"
                  maxlength="20"
                  field={rrsParkingId}
                  readOnly={limitedAccess === 'true'}
                />
              </FormRow>
            </IsGranted>
            <FormRow customClass="parkingForm_rowAlt">
              <EkRadio
                formRowItemKey="parkingForm_alwaysOpen"
                customContainerClass="parkingForm_ekRadioContainer"
                customWrapperClass="parkingForm_ekRadioWrapper"
                customTitleClass="parkingForm_ekRadioTitle"
                titleKey="parkingForm_alwaysOpen"
                items={booleanOptions}
                namespace="parkingAccess"
                field={alwaysOpen}
                onChange={this.handleChange}
                disabled={limitedAccess}
              >
                <FieldErrorMsg field={alwaysOpen} customClass="fieldErrorMsg--parkingForm" />
              </EkRadio>

              {alwaysOpen.value !== 'true' ? (
                <EkRadio
                  formRowItemKey="parkingForm_bankHolidays"
                  customContainerClass="parkingForm_ekRadioContainer"
                  customWrapperClass="parkingForm_ekRadioWrapper"
                  customTitleClass="parkingForm_ekRadioTitle"
                  titleKey="parkingForm_bankHolidays"
                  items={booleanOptions}
                  namespace="bankHolidays"
                  field={applyFrenchBankHolidays}
                  disabled={limitedAccess}
                >
                  <FieldErrorMsg field={applyFrenchBankHolidays} customClass="fieldErrorMsg--parkingForm" />
                </EkRadio>
              ) : (
                <div />
              )}
            </FormRow>

            <FormRow customClass="parkingForm_rowAlt">
              <EkRadio
                formRowItemKey="parkingForm_privateAccess"
                customContainerClass="parkingForm_ekRadioContainer"
                customWrapperClass="parkingForm_ekRadioWrapper"
                customTitleClass="parkingForm_ekRadioTitle"
                titleKey="parkingForm_privateAccess"
                items={booleanOptions}
                namespace="privateAccess"
                field={privateAccess}
                disabled={limitedAccess}
              >
                <FieldErrorMsg field={privateAccess} customClass="fieldErrorMsg--parkingForm" />
              </EkRadio>

              {alwaysOpen.value !== 'true' ? (
                <EkRadio
                  formRowItemKey="parkingForm_outsideOpeningHours"
                  customContainerClass="parkingForm_ekRadioContainer"
                  customWrapperClass="parkingForm_ekRadioWrapper"
                  customTitleClass="parkingForm_ekRadioTitle"
                  titleKey="parkingForm_outsideOpeningHours"
                  items={allowOptions}
                  namespace="outsideOpeningHours"
                  field={finishBookingOutsideOpeningHours}
                  disabled={limitedAccess}
                >
                  <FieldErrorMsg field={finishBookingOutsideOpeningHours} customClass="fieldErrorMsg--parkingForm" />
                </EkRadio>
              ) : (
                <div />
              )}
            </FormRow>

            <FormRow customClass="parkingForm_rowAlt">
              <EkRadio
                formRowItemKey="parkingForm_disabledAccess"
                customContainerClass="parkingForm_ekRadioContainer"
                customWrapperClass="parkingForm_ekRadioWrapper"
                customTitleClass="parkingForm_ekRadioTitle"
                titleKey="parkingForm_disabledAccess"
                items={booleanOptions}
                namespace="disabledAccess"
                field={disabledAccess}
                disabled={limitedAccess}
              >
                <FieldErrorMsg field={disabledAccess} customClass="fieldErrorMsg--parkingForm" />
              </EkRadio>
              <EkRadio
                formRowItemKey="parkingForm_findable"
                customContainerClass="parkingForm_ekRadioContainer"
                customWrapperClass="parkingForm_ekRadioWrapper"
                customTitleClass="parkingForm_ekRadioTitle"
                titleKey="parkingForm_findable"
                items={booleanOptions}
                namespace="findable"
                field={findable}
                disabled={limitedAccess}
              >
                <FieldErrorMsg field={findable} customClass="fieldErrorMsg--parkingForm" />
              </EkRadio>
            </FormRow>

            <FormRow customClass="parkingForm_rowAlt">
              <EkRadio
                formRowItemKey="parkingForm_electricCharging"
                customContainerClass="parkingForm_ekRadioContainer"
                customWrapperClass="parkingForm_ekRadioWrapper"
                customTitleClass="parkingForm_ekRadioTitle"
                titleKey="parkingForm_electricCharging"
                items={booleanOptions}
                namespace="electricCharging"
                field={electricCharging}
                disabled={limitedAccess}
              >
                <FieldErrorMsg field={electricCharging} customClass="fieldErrorMsg--parkingForm" />
              </EkRadio>

              <div />
            </FormRow>

            <FormRow customClass="parkingForm_rowAlt">
              <EkRadio
                formRowItemKey="parkingForm_gsmConnection"
                customContainerClass="parkingForm_ekRadioContainer"
                customWrapperClass="parkingForm_ekRadioWrapper"
                customTitleClass="parkingForm_ekRadioTitle"
                titleKey="parkingForm_gsmConnection"
                items={booleanOptions}
                namespace="gsmConnection"
                field={gsmConnection}
                disabled={limitedAccess}
              >
                <FieldErrorMsg field={gsmConnection} customClass="fieldErrorMsg--parkingForm" />
              </EkRadio>

              <div />
            </FormRow>

            <FormRow customClass="parkingForm_rowAlt">
              <EkRadio
                formRowItemKey="parkingForm_pluggedCheck"
                customContainerClass="parkingForm_ekRadioContainer"
                customWrapperClass="parkingForm_ekRadioWrapper"
                customTitleClass="parkingForm_ekRadioTitle"
                titleKey="parkingForm_pluggedCheck"
                items={booleanOptions}
                namespace="pluggedCheck"
                field={pluggedCheck}
                disabled={limitedAccess}
              >
                <FieldErrorMsg field={pluggedCheck} customClass="fieldErrorMsg--parkingForm" />
              </EkRadio>

              <div />
            </FormRow>

            <FormRow customClass="parkingForm_rowAlt">
              <EkRadio
                formRowItemKey="parkingForm_outsideParkingArea"
                customContainerClass="parkingForm_ekRadioContainer"
                customWrapperClass="parkingForm_ekRadioWrapper"
                customTitleClass="parkingForm_ekRadioTitle"
                titleKey="parkingForm_outsideParkingArea"
                items={allowOptions}
                namespace="outsideParkingArea"
                field={finishBookingOutsideParkingArea}
                disabled={limitedAccess}
              >
                <FieldErrorMsg field={finishBookingOutsideParkingArea} customClass="fieldErrorMsg--parkingForm" />
              </EkRadio>
              <div />
            </FormRow>
          </div>

          {alwaysOpen.value !== 'true' && (
            <div>
              <WeeklyHoursSlots
                slotTitle={'parkingForm_weekly_dates'}
                limitedAccess={limitedAccess}
                slotData={weeklyOpeningHours}
                onHandleAddSlot={this.handleAddWeeklyDate}
                onHandleRemoveSlot={this.handleRemoveWeeklyDate}
                onHandleAddSubSlot={this.handleAddWeeklyDateTime}
                onHandleRemoveSubSlot={this.handleRemoveWeeklyDateTime}
              />

              <OpeningHoursSlots
                slotTitle={'parkingForm_special_dates'}
                limitedAccess={limitedAccess}
                slotData={specialOpeningHours}
                onHandleAddSlot={this.handleAddSpecialDate}
                onHandleRemoveSlot={this.handleRemoveSpecialDate}
                onHandleAddSubSlot={this.handleAddSpecialDateTime}
                onHandleRemoveSubSlot={this.handleRemoveSpecialDateTime}
              />

              <ClosingDatesSlots
                slotTitle={'parkingForm_special_closing_dates'}
                limitedAccess={limitedAccess}
                slotData={specialClosingDates}
                onHandleAddSlot={this.handleAddClosingDate}
                onHandleRemoveSlot={this.handleRemoveClosingDate}
              />

              {applyFrenchBankHolidays.value === 'true' && (
                <ClosingDatesSlots
                  slotTitle={'parkingForm_bankHolidays_dates'}
                  limitedAccess={limitedAccess}
                  slotData={bankHolidays}
                  isDisabled
                />
              )}
            </div>
          )}

          {!limitedAccess && (
            <div className="parkingForm_actions">
              <EkButton type="button" skinType="reverse" onAction={this.followUrl(cancelUrl)}>
                <FormattedMessage id="parkingForm_cancel_button" />
              </EkButton>
              <EkButton customClass="parkingForm_actionsButton" onAction={submitAction}>
                <FormattedMessage id="parkingForm_save_button" />
              </EkButton>
            </div>
          )}
        </form>
      </section>
    );
  }
}

ParkingForm.displayName = 'ParkingForm';

ParkingForm.propTypes = {
  onCancel: PropTypes.func,
  isModal: PropTypes.bool,
  fields: PropTypes.object,
  handleSubmit: PropTypes.func,
  limitedAccess: PropTypes.bool,
  onCallback: PropTypes.func,
  addValue: PropTypes.func.isRequired,
  onChangeOpen: PropTypes.func,
  company: PropTypes.object,
  subCompany: PropTypes.object
};

ParkingForm = reduxForm(
  {
    onSubmitFail: scrollToFirstError,
    form: 'parkingForm',
    fields: [
      'name',
      'latitude',
      'longitude',
      'radius',
      'alwaysOpen',
      'privateAccess',
      'rrsParkingId',
      'disabledAccess',
      'electricCharging',
      'additionalInformation',
      'gsmConnection',
      'pluggedCheck',
      'findable',
      'applyFrenchBankHolidays',
      'finishBookingOutsideOpeningHours',
      'finishBookingOutsideParkingArea',
      'specialClosingDates[].startDate',
      'specialClosingDates[].endDate',
      'specialClosingDates[].comment',
      'specialOpeningHours[].startDate',
      'specialOpeningHours[].endDate',
      'specialOpeningHours[].items[].startHours',
      'specialOpeningHours[].items[].startMinutes',
      'specialOpeningHours[].items[].endHours',
      'specialOpeningHours[].items[].endMinutes',
      'weeklyOpeningHours[].days[]',
      'weeklyOpeningHours[].items[].startHours',
      'weeklyOpeningHours[].items[].startMinutes',
      'weeklyOpeningHours[].items[].endHours',
      'weeklyOpeningHours[].items[].endMinutes',
      'bankHolidays[].startDate',
      'bankHolidays[].endDate',
      'bankHolidays[].comment'
    ],
    validate: createValidator({
      name: [notEmpty(), length({ max: 80 })],
      latitude: [notEmpty(), latitude()],
      longitude: [notEmpty(), longitude()],
      radius: [notEmpty(), radius()],
      alwaysOpen: [notEmpty()],
      privateAccess: [notEmpty()],
      disabledAccess: [notEmpty()],
      electricCharging: [notEmpty()],
      gsmConnection: [notEmpty()],
      pluggedCheck: [notEmpty()],
      findable: [notEmpty()],
      specialClosingDates: [emptySlots()],
      specialOpeningHours: [emptySlots()],
      weeklyOpeningHours: [emptySlots()]
    })
  },
  undefined,
  {
    addValue: addArrayValue
  }
)(ParkingForm);

export default connect(state => {
  const {
    sites: { currentSite },
    parkings: { currentParking },
    user: { userInfo }
  } = state;
  return {
    currentSite,
    currentParking,
    userInfo
  };
})(ParkingForm);
