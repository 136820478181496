import React, { Component, PropTypes } from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { FormattedHTMLMessage, injectIntl } from 'react-intl';
import autoBind from 'react-autobind';
import _partial from 'lodash/partial';
import Dialog from 'material-ui/Dialog';
import SelectField from 'material-ui/SelectField';
import MenuItem from 'material-ui/MenuItem';
import FormRow from '../../../components/FormRow/FormRow';
import FlatButton from 'material-ui/FlatButton';

import { requestCopyPriceslotsMemberType, toggleCopyPriceMemberTypeDialog } from '../../../actions/all-actions';

class CopyPriceMemberTypeDialog extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
  }

  componentWillMount() {
    const { list, memberTypeId } = this.props;

    this.setState({
      toMemberTypeId: null,
      nameMemberTypeSource: list.filter(item => {
        return item.id === memberTypeId;
      })[0].name,
      nameMemberTypeTarget: null
    });
  }

  handleChangeField(field, event, index, value) {
    const { list } = this.props;
    let nameMemberTypeTarget = list.filter(item => {
      return item.id === value;
    });

    this.setState({
      toMemberTypeId: value,
      nameMemberTypeTarget: nameMemberTypeTarget[0].name
    });
  }

  handleSubmit() {
    const { dispatch, source } = this.props;
    const payload = {
      fromMemberTypeId: source,
      toMemberTypeId: this.state.toMemberTypeId
    };
    dispatch(requestCopyPriceslotsMemberType(payload)).then(dispatch(toggleCopyPriceMemberTypeDialog()));
  }

  handleClose() {
    const { dispatch } = this.props;
    dispatch(toggleCopyPriceMemberTypeDialog());
  }

  render() {
    const {
      fields: { memberType },
      list,
      handleSubmit,
      memberTypeId,
      intl
    } = this.props;

    let actions = [
      <FlatButton
        key="createMemberTypeForm_abortBtn"
        label={intl.messages['common_cancel']}
        formRowItemKey="createMemberTypeForm_abortBtn"
        primary
        onClick={this.handleClose}
      />,
      <FlatButton
        key="createMemberTypeForm_submitBtn"
        label={intl.messages['common_confirm']}
        type="button"
        secondary
        formRowItemKey="createMemberTypeForm_submitBtn"
        onClick={this.handleSubmit}
      />
    ];

    return (
      <Dialog open title={intl.messages.member_type_copy + ' «' + this.state.nameMemberTypeSource + '» '} actions={actions}>
        <form action="#" name="copyPriceMemberType" className="copyPriceMemberType" onSubmit={handleSubmit(this.props.onCallback)}>
          <FormRow customClass="changeTypeForm_row">
            <SelectField
              onChange={_partial(this.handleChangeField, memberType)}
              floatingLabelText={intl.messages.member_type_copy_target_label}
              fullWidth
              value={this.state.toMemberTypeId}
            >
              {list.map(item => {
                if (item.id !== memberTypeId) {
                  return <MenuItem key={item.id} value={item.id} primaryText={item.name} />;
                }
              })}
            </SelectField>
            {this.state.toMemberTypeId && (
              <FormattedHTMLMessage
                id="member_type_copy_from_to"
                values={{ source: this.state.nameMemberTypeSource, target: this.state.nameMemberTypeTarget }}
              />
            )}
          </FormRow>
        </form>
      </Dialog>
    );
  }
}

CopyPriceMemberTypeDialog.displayName = 'CopyPriceMemberType';

CopyPriceMemberTypeDialog.propTypes = {
  handleSubmit: PropTypes.func,
  onCallback: PropTypes.func
};

CopyPriceMemberTypeDialog = reduxForm({
  form: 'copyPriceMemberType',
  fields: ['memberType']
})(CopyPriceMemberTypeDialog);

export default connect(state => {
  const {
    pricing: { memberTypeId },
    user: { userInfo },
    memberTypes: { list },
    companies: { currentCompany }
  } = state;

  return {
    list,
    userInfo,
    companyId: currentCompany.id,
    memberTypeId
  };
})(injectIntl(CopyPriceMemberTypeDialog));
