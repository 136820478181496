import React, { Component, PropTypes } from 'react';
import { change } from 'redux-form';
import { connect } from 'react-redux';
import { namedCompose, safe, valueFirstInit } from '../../utils/utils';
import { injectIntl } from 'react-intl';
import { getStore } from '../../store/all-store';
import autoBind from 'react-autobind';
import { localeSelector, regionNamesSelector } from '../../selectors/all-selectors';
import MapboxComplete from '../MapboxComplete';
import { FLASH_MESSAGE_TYPE_ERROR, SAFE_LOCALE_MAP } from '../../constants/generic-constants';
import { addFlashMessage } from '../../actions/flashMessage-actions';
import { cleanDeep } from '../../utils/cleanDeep';
import cs from 'classnames';

class AddressAutocomplete extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.dispatch = getStore().dispatch;
  }

  componentDidMount() {
    this.didUpdateCycle();
  }

  componentDidUpdate() {
    this.didUpdateCycle();
  }

  didUpdateCycle() {
    valueFirstInit.call(this, this.props.field);
  }

  setAddressContext({ place_name, context = [] } = {}) {
    const ret = { formattedAddress: place_name, streetName: '' };
    const getPredicate = id => el => safe(() => el.id.includes(id));

    const postcodeObj = context.find(getPredicate('postcode'));
    const placeObj = context.find(getPredicate('place'));
    const countryObj = context.find(getPredicate('country'));

    if (postcodeObj) ret.postalCode = postcodeObj.text;
    if (placeObj) ret.city = placeObj.text;

    if (countryObj) {
      ret.country = safe(() => countryObj.short_code.toUpperCase());
    }

    return ret;
  }

  formatAddress(suggestion = {}) {
    const { address, text, place_type, center } = suggestion;

    const ret = this.setAddressContext(suggestion);
    const street = safe(() => suggestion.properties.address);
    const isAddress = place_type.includes('address');
    const isPoi = place_type.includes('poi');

    if (center) {
      ret.coordinates = { latitude: center[1], longitude: center[0] };
    }

    if (place_type.includes('country')) {
      ret.country = safe(() => suggestion.properties.short_code.toUpperCase());
    }

    if (place_type.includes('place')) {
      ret.city = text;
    }

    if (isAddress && address) {
      ret.streetName = address;
    }

    if (isAddress || isPoi) {
      if (text && ret.streetName) ret.streetName += ' ';
      if (text) ret.streetName += text;
    }

    if (place_type.includes('poi')) {
      if (street && ret.streetName) ret.streetName += ', ';
      if (street) ret.streetName += street;
    }

    return cleanDeep(ret);
  }

  handleSuggestSelected(suggestion = {}) {
    const value = suggestion._isSite ? suggestion : this.formatAddress(suggestion);
    const { country } = value || {};

    if (this.props.translateCountryCode && country) {
      return this.props.field.onChange(country);
    }

    this.handleLocationChange(value);
    this.handleExternalFields(value);
    this.props.field.onChange(value);
  }

  handleLocationChange(value) {
    const { getValuesLocationCallback } = this.props;
    const { coordinates } = value || {};

    if (getValuesLocationCallback && coordinates) {
      getValuesLocationCallback(coordinates);
    }
  }

  updateInputValue(value) {
    if (this.valueSetter) this.valueSetter(value);
  }

  handleExternalFields(addressObject) {
    const { form, spreadExternalFields, spreadFieldsPrefix } = this.props;

    if (spreadExternalFields) {
      const { city, postalCode, streetName, country } = addressObject || {};

      let _postalCode = 'postalCode';
      let _city = 'city';
      let _country = 'country';

      if (spreadFieldsPrefix) {
        _postalCode = spreadFieldsPrefix + _postalCode;
        _city = spreadFieldsPrefix + _city;
        _country = spreadFieldsPrefix + _country;
      }

      if (postalCode) this.dispatch(change(form, _postalCode, postalCode));
      if (city) this.dispatch(change(form, _city, city));
      if (country) this.dispatch(change(form, _country, country));

      if (streetName) {
        addressObject.formattedAddress = streetName;
        this.updateInputValue(streetName);
      }
    }
  }

  handleChange(text) {
    if (this.props.spreadExternalFields) {
      this.props.field.onChange({ streetName: text, formattedAddress: text });
    } else if (this.props.translateCountryCode) {
      this.props.field.onChange({ name: text });
    } else this.props.field.onChange({ formattedAddress: text });
  }

  dispatchTimedError(error) {
    if (!this.limitError) {
      this.dispatch(error);
      this.limitError = setTimeout(() => (this.limitError = false), 2000);
    }
  }

  handleError() {
    this.dispatchTimedError(
      addFlashMessage({
        contentKey: 'error_during_address_search',
        type: FLASH_MESSAGE_TYPE_ERROR
      })
    );
  }

  handleLimit() {
    this.dispatchTimedError(
      addFlashMessage({
        contentKey: 'autocomplete_api_limit_reached',
        type: FLASH_MESSAGE_TYPE_ERROR
      })
    );
  }

  translateRegion(name) {
    return safe(() => this.props.regionNames.of(name)) || name;
  }

  renderValue() {
    const { translateCountryCode } = this.props;
    const { value } = this.props.field || {};

    if (typeof value === 'string') {
      if (translateCountryCode && value) {
        return this.translateRegion(value);
      }
      return value;
    }

    const { formattedAddress, name, label } = value;
    return name || label || formattedAddress;
  }

  getPlaceholder() {
    const {
      intl: { formatMessage },
      placeholderKey
    } = this.props;

    return placeholderKey ? formatMessage({ id: placeholderKey }) : '';
  }

  getAddressesTitle() {
    const {
      intl: { formatMessage }
    } = this.props;
    return formatMessage({ id: 'autocomplete_company_addresses_title' });
  }

  getFixturesTitle() {
    const {
      intl: { formatMessage }
    } = this.props;
    return formatMessage({ id: 'autocomplete_company_sites_title' });
  }

  setValueSetter(setter) {
    this.valueSetter = setter;
  }

  getLanguage() {
    const { locale, language } = this.props;
    const lang = language || locale;
    return SAFE_LOCALE_MAP[lang] || lang;
  }

  getSearchComponent() {
    return (
      <div className={cs('address-autocomplete', { 'country-mode': this.props.translateCountryCode })}>
        <MapboxComplete
          onBlur={this.props.field.onBlur}
          onFocus={this.props.field.onFocus}
          fixtures={this.props.fixtures}
          searchType={this.props.searchType}
          showLocationButton={this.props.showLocationButton}
          searchHistoryEnabled={this.props.searchHistoryEnabled}
          placeholder={this.getPlaceholder()}
          inputValue={this.renderValue()}
          language={this.getLanguage()}
          onSelect={this.handleSuggestSelected}
          onInputChange={this.handleChange}
          getValueSetter={this.setValueSetter}
        />
      </div>
    );
  }

  getDisabledDiv() {
    return (
      <div className="geosuggest geo-container geo-disabled">
        <div className="geosuggest__input-wrapper">
          <input className="geosuggest__input" disabled value={this.renderValue()} />
        </div>
      </div>
    );
  }

  render() {
    const { disabled } = this.props;
    if (disabled) return this.getDisabledDiv();
    else return this.getSearchComponent();
  }
}

AddressAutocomplete.propTypes = {
  /** Spead fields form */
  form: PropTypes.string,
  /** https://docs.mapbox.com/api/search/geocoding/#forward-geocoding */
  language: PropTypes.string,
  /** Redux-form field (required) */
  field: PropTypes.object,
  /** Translation key for input field placeholder */
  placeholderKey: PropTypes.string,
  /** Spread data to external fields */
  spreadExternalFields: PropTypes.bool,
  /** External field prefix */
  spreadFieldsPrefix: PropTypes.string,
  /** Additional data to display and search */
  fixtures: PropTypes.array,
  /** Return previous disabled component (if true) */
  disabled: PropTypes.bool,
  /** https://docs.mapbox.com/api/search/geocoding/#data-types */
  searchType: PropTypes.array,
  /** Show current location suggestion */
  showLocationButton: PropTypes.bool,
  /** Enable search history **/
  searchHistoryEnabled: PropTypes.bool
};

AddressAutocomplete.defaultProps = {
  searchType: ['address', 'poi', 'place'],
  placeholderKey: 'common_search_placeholder',
  translateCountryCode: false,
  spreadExternalFields: false
};

export default namedCompose(
  connect(state => ({
    locale: localeSelector(state),
    regionNames: regionNamesSelector(state)
  })),
  injectIntl
)(AddressAutocomplete);
