import * as types from '../constants/actionTypes-constants';
import { parkingFormFormat, bankHolidaysFormFormat } from '../utils/dataFormatUtils';
import { safe } from '../utils/utils';

export default function parkings(
  state = {
    list: [],
    currentParking: {},
    bankHolidays: [],
    openedParkingConfirmationModal: null,
    isOpen: null,
    loadingList: true,
    errorList: false
  },
  action
) {
  switch (action.type) {
    case types.PARKINGS_GET_LIST_REQUEST:
      return {
        ...state,
        loadingList: true,
        errorList: false
      };

    case types.PARKINGS_GET_LIST_SUCCESS:
      return Object.assign({}, state, {
        list: action.list,
        loadingList: false
      });
    case types.PARKINGS_GET_LIST_ERROR:
      return {
        ...state,
        loadingList: false,
        errorList: true
      };

    case types.PARKINGS_CLEAR_LIST:
      return Object.assign({}, state, {
        list: []
      });

    case types.PARKINGS_CREATE_PARKING_REQUEST:
      return state;

    case types.PARKINGS_CREATE_PARKING_SUCCESS: {
      return { ...state, list: [{ ...action.parking }, ...state.list] };
    }

    case types.PARKINGS_GET_DETAIL_PARKING_REQUEST:
    case types.PARKINGS_GET_BANK_HOLIDAYS_PARKING_REQUEST:
      return state;

    case types.PARKINGS_GET_DETAIL_PARKING_SUCCESS:
      return Object.assign({}, state, {
        currentParking: parkingFormFormat(action.parking)
      });

    case types.PARKINGS_GET_BANK_HOLIDAYS_PARKING_SUCCESS:
      return Object.assign({}, state, {
        bankHolidays: bankHolidaysFormFormat(action.bankHolidays)
      });

    case types.PARKINGS_UPDATE_PARKING_REQUEST:
      return state;

    case types.PARKINGS_UPDATE_PARKING_SUCCESS: {
      const { parking } = action;
      const { list = [] } = state;

      safe(() => (list.find(item => item.id === parking.id).name = parking.name));

      return {
        ...state,
        list: [...list],
        currentParking: parking
      };
    }

    case types.PARKINGS_CONFIRMATION_MODAL_OPEN:
      return Object.assign({}, state, {
        openedParkingConfirmationModal: true
      });

    case types.PARKINGS_CONFIRMATION_MODAL_CLOSE:
      return Object.assign({}, state, {
        openedParkingConfirmationModal: null
      });

    case types.PARKINGS_SET_IS_OPEN:
      return Object.assign({}, state, {
        isOpen: action.isOpen
      });

    default:
      return state;
  }
}
