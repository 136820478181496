import React, { Component, PropTypes } from 'react';
import { FormattedMessage } from 'react-intl';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { getStore } from '../../store/all-store';

import FormRow from '../FormRow/FormRow';
import TableView from '../../components/TableView/TableView';
import BoxedInput from '../BoxedInput/BoxedInput';
import EkButton from '../EkButton/EkButton';
import FieldErrorMsg from '../FieldErrorMsg/FieldErrorMsg';
import { createValidator, notEmpty, number, dateAfterOrEqualField } from '../../validation/all-validation';
import { FLASH_MESSAGE_TYPE_ERROR, FLASH_MESSAGE_TYPE_SUCCESS } from '../../constants/generic-constants';

import { deleteLeaseContract, addFlashMessage, getLeaseContractsList } from '../../actions/all-actions';
import { scrollToFirstError } from '../../utils/utils';

const tableViewParams = {
  namespace: 'leaseContracts',
  sortIsDescending: false,
  columns: [
    {
      messageKey: 'leaseContracts_tableView_label_number',
      content: 'contractNumber'
    },
    {
      messageKey: 'leaseContracts_tableView_label_service_level',
      content: 'serviceLevel'
    },
    {
      messageKey: 'leaseContracts_tableView_label_total_km',
      content: 'totalKm'
    },
    {
      messageKey: 'leaseContracts_tableView_label_amountExVat',
      content: 'amountExVat'
    },
    {
      messageKey: 'leaseContracts_tableView_label_startDate',
      content: 'startDate'
    },
    {
      messageKey: 'leaseContracts_tableView_label_endDate',
      content: 'endDate'
    },
    {
      messageKey: 'leaseContracts_tableView_label_realEndDate',
      content: 'actualEndDate'
    },
    {
      messageKey: 'leaseContracts_tableView_label_action',
      content: null,
      contentMessageKey: 'leaseContracts_tableView_content_action',
      actionCustomClass: 'vehicleDetail_tableView_action',
      actionCallback: function(item) {
        getStore()
          .dispatch(deleteLeaseContract(item.id))
          .then(() => {
            getStore().dispatch(
              addFlashMessage({
                contentKey: 'vehicle_delete_contract_success',
                type: FLASH_MESSAGE_TYPE_SUCCESS
              })
            );
            getStore().dispatch(getLeaseContractsList(getStore().getState().vehicles.detailVehicle.id));
          })
          .catch(error => {
            if (!error.type) {
              // make sure native errors not swallowed
              console.error(error.stack); // eslint-disable-line
            }
            getStore().dispatch(
              addFlashMessage({
                contentKey: 'vehicle_delete_contract_failed',
                type: FLASH_MESSAGE_TYPE_ERROR
              })
            );
          });
      }
    }
  ]
};

class LeaseContractForm extends Component {
  constructor() {
    super();
  }

  render() {
    const {
      fields: { contractNumber, serviceLevel, startDate, endDate, totalKm, actualEndDate, amountExVat },
      handleSubmit,
      list,
      limitedAccess
    } = this.props;

    const submitAction = limitedAccess ? undefined : handleSubmit(this.props.onCallback);

    return (
      <div className="leaseContractForm_container">
        {!limitedAccess && (
          <form action="#" className="leaseContractForm vehicleDetail_form" onSubmit={submitAction}>
            <h4 className="leaseContractForm_title">
              <FormattedMessage id="leaseContractForm_title" />
            </h4>

            <FormRow customWrapperClass="leaseContractForm_row">
              <BoxedInput
                formRowItemKey="leaseContractForm_number"
                type="text"
                id="leaseContractForm_number"
                labelKey="leaseContractForm_number"
                field={contractNumber}
              >
                <FieldErrorMsg customClass="fieldErrorMsg--leaseContractForm" field={contractNumber} />
              </BoxedInput>

              <BoxedInput
                formRowItemKey="leaseContractForm_amountExVat"
                type="text"
                id="leaseContractForm_amountExVat"
                labelKey="leaseContractForm_amountExVat"
                field={amountExVat}
              >
                <FieldErrorMsg customClass="fieldErrorMsg--leaseContractForm" field={amountExVat} />
              </BoxedInput>
            </FormRow>

            <FormRow customWrapperClass="leaseContractForm_row">
              <BoxedInput
                formRowItemKey="leaseContractForm_startDate"
                type="date"
                id="leaseContractForm_startDate"
                labelKey="leaseContractForm_startDate"
                field={startDate}
              >
                <FieldErrorMsg customClass="fieldErrorMsg--leaseContractForm" field={startDate} />
              </BoxedInput>

              <BoxedInput
                formRowItemKey="leaseContractForm_endDate"
                type="date"
                id="leaseContractForm_endDate"
                labelKey="leaseContractForm_endDate"
                field={endDate}
              >
                <FieldErrorMsg customClass="fieldErrorMsg--leaseContractForm" field={endDate} />
              </BoxedInput>
            </FormRow>

            <FormRow customWrapperClass="leaseContractForm_row">
              <BoxedInput
                formRowItemKey="leaseContractForm_actualEndDate"
                type="date"
                id="leaseContractForm_actualEndDate"
                labelKey="leaseContractForm_actualEndDate"
                field={actualEndDate}
              >
                <FieldErrorMsg customClass="fieldErrorMsg--leaseContractForm" field={actualEndDate} />
              </BoxedInput>

              <BoxedInput
                formRowItemKey="leaseContractForm_serviceLevel"
                type="text"
                id="leaseContractForm_serviceLevel"
                labelKey="leaseContractForm_serviceLevel"
                field={serviceLevel}
              >
                <FieldErrorMsg customClass="fieldErrorMsg--leaseContractForm" field={serviceLevel} />
              </BoxedInput>
            </FormRow>

            <FormRow customWrapperClass="leaseContractForm_row">
              <BoxedInput
                formRowItemKey="leaseContractForm_totalKm"
                type="text"
                id="leaseContractForm_totalKm"
                labelKey="leaseContractForm_totalKm"
                field={totalKm}
              >
                <FieldErrorMsg customClass="fieldErrorMsg--leaseContractForm" field={totalKm} />
              </BoxedInput>
            </FormRow>

            <EkButton customClass="leaseContractForm_actionsButton" onAction={submitAction}>
              <FormattedMessage id="contractForm_submit" />
            </EkButton>
          </form>
        )}

        <section className="vehiclesColorsPage_management">
          <TableView
            customClass="vehiclesColorsPage_tableViewWrapper"
            params={tableViewParams}
            content={list}
            limitedAccess={limitedAccess}
          />
        </section>
      </div>
    );
  }
}

LeaseContractForm.displayName = 'LeaseContractForm';

LeaseContractForm.propTypes = {
  fields: PropTypes.object,
  handleSubmit: PropTypes.func,
  onCallback: PropTypes.func,
  limitedAccess: PropTypes.bool
};

LeaseContractForm = reduxForm({
  onSubmitFail: scrollToFirstError,
  form: 'leaseContractForm',
  fields: ['contractNumber', 'serviceLevel', 'startDate', 'endDate', 'totalKm', 'actualEndDate', 'amountExVat'],
  validate: createValidator({
    contractNumber: [notEmpty()],
    amountExVat: [number()],
    totalKm: [number()],
    endDate: [dateAfterOrEqualField('startDate')]
  })
})(LeaseContractForm);

export default connect(state => {
  const {
    vehicles: { leaseContractsList },
    user: { userInfo }
  } = state;

  return {
    list: leaseContractsList,
    role: userInfo.role
  };
})(LeaseContractForm);
