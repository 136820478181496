import React, { Component } from 'react';
import { connect } from 'react-redux';
import { reset } from 'redux-form';
import _get from 'lodash/get';
import _pickBy from 'lodash/pickBy';
import _identity from 'lodash/identity';
import { routeActions } from 'react-router-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import moment from 'moment';

import routes from '../../../../constants/routes-constants';
import config from '../../../../constants/config-constants';
import { tableViewInvoiceBookingsParams } from '../../../../constants/table-constants';

import BookingsSearch from '../BookingsSearch/BookingsSearch';
import NumberResultsSelectForm from '../../../../components/NumberResultsSelectForm/NumberResultsSelectForm';
import TableView from '../../../../components/TableView/TableView';
import PaginationControls from '../../../../components/PaginationControls/PaginationControls';
import FlashMessageDisplayer from '../../../../components/FlashMessageDisplayer/FlashMessageDisplayer';
import { apiParams } from '../../../../constants/api-params-constants';

class InvoiceFindBooking extends Component {
  constructor() {
    super();

    this.handleSearchForm = this.handleSearchForm.bind(this);
    this.handleFilteredResetSearch = this.handleFilteredResetSearch.bind(this);
    this.handleGotoPage = this.handleGotoPage.bind(this);
    this.handleNumberResultsSelect = this.handleNumberResultsSelect.bind(this);
  }

  handleSearchForm(searchParams) {
    const { dispatch } = this.props;

    const newParams = Object.assign({}, apiParams.default, _pickBy(searchParams, _identity));

    dispatch(
      routeActions.push(
        `${routes.addInvoice.path}/${routes.invoiceFindBooking.path.replace(':search', encodeURIComponent(JSON.stringify(newParams)))}`
      )
    );
  }

  handleFilteredResetSearch() {
    const { dispatch, listMetadata } = this.props;

    dispatch(reset('BookingsSearch'));

    let newParams = {
      page: {
        number: 1,
        size: listMetadata.paginationInfo.pageSize
      }
    };

    dispatch(
      routeActions.push(
        `${routes.addInvoice.path}/${routes.invoiceFindBooking.path.replace(':search', encodeURIComponent(JSON.stringify(newParams)))}`
      )
    );
  }

  handleGotoPage(value) {
    const { dispatch, listMetadata, urlParams } = this.props;

    let params = {
      page: {
        number: value,
        size: listMetadata.paginationInfo.pageSize
      }
    };

    let newParams = Object.assign(urlParams, params);

    dispatch(
      routeActions.push(
        `${routes.addInvoice.path}/${routes.invoiceFindBooking.path.replace(':search', encodeURIComponent(JSON.stringify(newParams)))}`
      )
    );
  }

  handleNumberResultsSelect(value) {
    const { dispatch, urlParams } = this.props;

    let params = {
      page: {
        number: 1,
        size: +value
      }
    };

    let newParams = Object.assign(urlParams, params);

    dispatch(
      routeActions.push(
        `${routes.addInvoice.path}/${routes.invoiceFindBooking.path.replace(':search', encodeURIComponent(JSON.stringify(newParams)))}`
      )
    );
  }

  render() {
    const { list, listMetadata, currentSortedIndex, sortIsDescending, urlParams } = this.props;

    const tableViewParams = Object.assign({}, tableViewInvoiceBookingsParams, {
      sortById: currentSortedIndex,
      sortIsDescending
    });

    return (
      <div className="invoiceFindBooking">
        <FlashMessageDisplayer />

        <div className="bookingsFiltersFormWrapper">
          <BookingsSearch
            onCallback={this.handleSearchForm}
            onResetCallBack={this.handleFilteredResetSearch}
            initialValues={{
              memberLastName: urlParams.memberLastName || '',
              memberFirstName: urlParams.memberFirstName || '',
              memberLogin: urlParams.memberLogin || '',
              voucherCode: urlParams.voucherCode || '',
              bookingId: urlParams.bookingId || '',
              startDate: _get(urlParams, 'startDate') ? moment(urlParams.startDate).toDate() : null,
              endDate: _get(urlParams, 'endDate') ? moment(urlParams.endDate).toDate() : null,
              vehicleRegistrationNumber: urlParams.vehicleRegistrationNumber || ''
            }}
          />
        </div>

        <div className="invoiceFindBooking_list">
          {listMetadata.paginationInfo.totalResults > config.numberResults[0] && (
            <div className="invoiceFindBooking_pagination">
              <NumberResultsSelectForm
                onCallback={this.handleNumberResultsSelect}
                customClass="invoiceFindBooking_numberResultsSelectForm"
                customLabelClass="numberResultsSelectForm_label"
                onNumberResultsSelect={this.handleNumberResultsSelect}
                initialValues={{
                  numberResults: listMetadata.paginationInfo.pageSize
                }}
              />

              <PaginationControls
                paginationInfo={listMetadata.paginationInfo}
                onPageChange={this.handleGotoPage}
                customClass="invoiceFindBooking_paginationControls"
              />
            </div>
          )}

          {listMetadata.paginationInfo.totalResults > 0 && (
            <TableView customClass="invoiceFindBooking_tableViewWrapper" params={tableViewParams} content={list} />
          )}

          {listMetadata.paginationInfo.totalResults === 0 && (
            <p className="noResultMessage">
              <FormattedMessage id="bookings_no_results" />
            </p>
          )}
        </div>
      </div>
    );
  }
}

InvoiceFindBooking.displayName = 'InvoiceFindBooking';

export default connect(state => {
  const {
    invoiceFindBooking: { paginatedResults, currentSortedIndex, sortIsDescending, urlParams }
  } = state;

  return {
    list: paginatedResults.results,
    listMetadata: paginatedResults.metadata,
    currentSortedIndex,
    sortIsDescending,
    urlParams
  };
})(injectIntl(InvoiceFindBooking));
