import * as types from '../constants/actionTypes-constants';
import callApi from '../actions/api-actions';
import _get from 'lodash/get';
import { filterBankoutList, parseActionPaybackParams } from '../api/data-enhancer';
import { apiParams } from '../constants/api-params-constants';
import { FLASH_MESSAGE_TYPE_ERROR, FLASH_MESSAGE_TYPE_SUCCESS, SORT_ASC, SORT_DESC } from '../constants/generic-constants';
import _pick from 'lodash/pick';
import { addErrorMessage, append, downloadUrlContent } from '../utils/utils';
import { validCompanyIdSelector, validSubCompanyIdSelector, isSubCompanyLevelUserSelector } from '../selectors/all-selectors';
import { BILLING_ENTITY_TYPE_COMPANY, BILLING_ENTITY_TYPE_SUPER_COMPANY } from '../constants/backend-constants';
import { addFlashMessage } from './flashMessage-actions';

export function getBankoutList(params, loader = false) {
  return (dispatch, getState) => {
    const state = getState();
    const subCompanyLevelUser = isSubCompanyLevelUserSelector(state);
    const page = _get(params, 'page');
    const pageNumber = _get(page, 'number');
    const pageSize = _get(page, 'size');
    const sort = _get(params, 'sort') || _get(state, 'bankouts.bankoutParams.sort');
    const sortProperty = _get(sort, 'property');
    const sortDirection = _get(sort, 'isDescending');
    const { number, size } = apiParams.default.page;
    const currentSuperCompanyId = validCompanyIdSelector(state);
    const currentSubCompanyId = validSubCompanyIdSelector(state);

    const validParams = _pick(params, [
      'billingEntityName',
      'creationDateLowerLimit',
      'creationDateUpperLimit',
      'bankoutStatus',
      'bookingId'
    ]);

    // sequential if blocks = START =

    if (subCompanyLevelUser) {
      validParams.billingEntityType = BILLING_ENTITY_TYPE_COMPANY;
    } else if (currentSuperCompanyId) {
      validParams.billingEntityId = currentSuperCompanyId;
      validParams.billingEntityType = BILLING_ENTITY_TYPE_SUPER_COMPANY;
    }

    if (currentSubCompanyId) {
      validParams.billingEntityId = currentSubCompanyId;
      validParams.billingEntityType = BILLING_ENTITY_TYPE_COMPANY;
    }

    // sequential if blocks = END =

    let paramsObj = {
      number: pageNumber || number,
      size: pageSize || size
    };

    if (sortProperty) {
      paramsObj.sortProperty = sortProperty;
      paramsObj.sortDirection = sortDirection ? SORT_DESC : SORT_ASC;
      params.sort = sort;
    }

    paramsObj = { ...paramsObj, ...append(validParams) };
    dispatch({ type: types.BANKOUT_GET_LIST_REQUEST, urlParams: params });
    return dispatch(callApi('bankouts', 'getList', paramsObj, loader)).then(
      data => {
        dispatch({
          type: types.BANKOUT_GET_LIST_SUCCESS,
          paginatedObj: filterBankoutList(data)
        });
        return Promise.resolve(data);
      },
      error => {
        dispatch({ type: types.BANKOUT_GET_LIST_ERROR, error });
        dispatch(addErrorMessage({ error }));
        return Promise.reject(error);
      }
    );
  };
}

export function getBankoutDetail(id, loader = false) {
  return dispatch => {
    dispatch({
      type: types.BANKOUT_GET_DETAILS_REQUEST
    });

    return dispatch(callApi('bankouts', 'getDetail', id, loader)).then(
      data => {
        dispatch({
          type: types.BANKOUT_GET_DETAILS_SUCCESS,
          data: data
        });
        return Promise.resolve();
      },
      error => {
        dispatch({ type: types.BANKOUT_GET_DETAILS_ERROR, error });
        dispatch(addErrorMessage({ error }));
        return Promise.reject(error);
      }
    );
  };
}

export function getPaybackList(bankoutId, params, loader = false) {
  return dispatch => {
    const paramsObj = parseActionPaybackParams(params);

    dispatch({ type: types.PAYBACK_GET_LIST_REQUEST, urlParams: params });

    return dispatch(callApi('bankouts', 'getPaybackList', { bankoutId, params: paramsObj }, loader)).then(
      data => {
        dispatch({
          type: types.PAYBACK_GET_LIST_SUCCESS,
          paginatedObj: data
        });
        return Promise.resolve(data);
      },
      error => {
        dispatch({ type: types.PAYBACK_GET_LIST_ERROR, error });
        dispatch(addErrorMessage({ error }));
        return Promise.reject(error);
      }
    );
  };
}

export function retryBankoutExecutionAction(id, loader = true) {
  return dispatch => {
    dispatch({
      type: types.BANKOUT_RETRY_REQUEST
    });

    return dispatch(callApi('bankouts', 'retryBankoutExecution', id, loader)).then(
      () => {
        dispatch({ type: types.BANKOUT_RETRY_SUCCESS });
        dispatch(addFlashMessage({ contentKey: 'common_success', type: FLASH_MESSAGE_TYPE_SUCCESS }));
        return Promise.resolve();
      },
      error => {
        dispatch(addErrorMessage({ error, def: 'common_error_ocurred' }));
        return Promise.reject();
      }
    );
  };
}

export function pollingBankoutExport(exportId, bankoutId) {
  return dispatch => {
    const timer = setTimeout(() => {
      clearTimeout(timer);
      dispatch(getUrl(exportId, bankoutId));
    }, 1000);
  };
}

export function getUrl(exportId, bankoutId) {
  return dispatch => {
    return dispatch(callApi('bankouts', 'urlExportId', exportId, false))
      .then(data => {
        // data is file link here
        window.localStorage.removeItem('bankoutIdExport' + bankoutId);
        downloadUrlContent(data, 'useless');
        dispatch(
          addFlashMessage({
            contentKey: 'bankout_download_success',
            type: FLASH_MESSAGE_TYPE_SUCCESS
          })
        );
        dispatch({
          type: types.BANKOUT_EXPORT_FINISHED,
          id: bankoutId
        });
      })
      .catch(error => {
        if (error.status === 404) {
          dispatch(pollingBankoutExport(exportId, bankoutId));
        } else {
          dispatch(
            addFlashMessage({
              contentKey: 'bankout_download_failed',
              type: FLASH_MESSAGE_TYPE_ERROR
            })
          );
          dispatch({
            type: types.BANKOUT_EXPORT_FINISHED,
            id: bankoutId
          });
          window.localStorage.removeItem('bankoutIdExport' + bankoutId);
        }
      });
  };
}

export function exportBankout(id) {
  return dispatch => {
    const isExportProcessing = window.localStorage.getItem('bankoutIdExport' + id);
    if (isExportProcessing) return dispatch(pollingBankoutExport(isExportProcessing, id));

    dispatch(exporting(id));
    return dispatch(callApi('bankouts', 'exportBankout', id, false))
      .then(
        data => {
          // data is export id created
          window.localStorage.setItem('bankoutIdExport' + id, data);
          dispatch({
            type: types.BANKOUT_EXPORT_SUCCESS,
            id
          });
          dispatch(pollingBankoutExport(data, id));
          Promise.resolve(data);
        },
        () => dispatch({ type: types.BANKOUT_EXPORT_FAILED })
      )
      .catch(() => {
        dispatch(
          addFlashMessage({
            contentKey: 'bookings_download_failed',
            type: FLASH_MESSAGE_TYPE_ERROR
          })
        );
        dispatch({ type: types.BANKOUT_EXPORT_FAILED });
        window.localStorage.removeItem('bankoutIdExport' + id);
      });
  };
}

export function clearBankoutResults() {
  return {
    type: types.BANKOUT_CLEAR_LIST
  };
}

export function clearPaybackResults() {
  return {
    type: types.PAYBACK_CLEAR_LIST
  };
}

export function exporting(id) {
  return { type: types.BANKOUT_EXPORT_REQUEST, id };
}

export function clearBankoutDetails() {
  return {
    type: types.BANKOUT_GET_DETAILS_CLEAR
  };
}
