import callApi from './api-actions';
import { enhanceBookingVehicleSearchRequestParams } from '../api/data-enhancer';
import * as types from '../constants/actionTypes-constants';

export function getBookingFindAvailableVehicle(payload, loader = true, skip = false) {
  return dispatch => {
    const enhancedPayload = skip ? payload : enhanceBookingVehicleSearchRequestParams(payload);

    dispatch({
      type: types.BOOKING_FIND_VEHICLE_GET_LIST_REQUEST
    });

    return dispatch(callApi('bookings', 'search', enhancedPayload, loader)).then(function(data) {
      dispatch({
        type: types.BOOKING_FIND_VEHICLE_GET_LIST_REQUEST_SUCCESS,
        list: data,
        urlParams: enhancedPayload
      });

      return data;
    });
  };
}

export function setBookingVehicleToggleSelectedMemberInfos() {
  return {
    type: types.BOOKING_FIND_VEHICLE_TOGGLE_MEMBER_INFOS
  };
}
