import * as types from '../constants/actionTypes-constants';
import callApi from './api-actions';
import { addErrorMessage } from '../utils/utils';
import { addFlashMessage } from './flashMessage-actions';
import { FLASH_MESSAGE_TYPE_SUCCESS } from '../constants/generic-constants';

export function getCategoriesRequest(loader = true) {
  return dispatch => {
    return dispatch(callApi('categories', 'getCategories', null, loader)).then(
      data => {
        data = data.reverse();
        dispatch({ type: types.CATEGORIES_GET_CATEGORIES_SUCCESS, categories: data });
        return data;
      },
      error => {
        dispatch(addErrorMessage({ error }));
        return Promise.reject(error);
      }
    );
  };
}

export function createCategoryRequest(params) {
  return dispatch => {
    return dispatch(callApi('categories', 'createCategory', params)).then(
      data => {
        dispatch(createCategorySuccess(data));
        return Promise.resolve(data);
      },
      error => {
        const params = { error, def: 'vehiclesCategoryForm_create_category_error', errorCodePrefixes: ['vehiclesCategoryForm_'] };
        dispatch(addErrorMessage(params));
        return Promise.reject(error);
      }
    );
  };
}

export function createCategorySuccess(category) {
  return {
    type: types.CATEGORIES_CREATE_CATEGORY_SUCCESS,
    category
  };
}

export function getSingleCategory(categoryId) {
  return dispatch => {
    return dispatch(callApi('categories', 'getCategory', categoryId)).then(
      data => {
        dispatch({ type: types.CATEGORIES_GET_SINGLE_CATEGORY_SUCCESS, category: data });
        return Promise.resolve(data);
      },
      error => {
        dispatch(addErrorMessage({ error }));
        return Promise.reject(error);
      }
    );
  };
}

export function updateCategoryRequest({ id, ...params }) {
  return dispatch => {
    return dispatch(callApi('categories', 'updateCategory', { id, params })).then(
      data => {
        dispatch(updateCategorySuccess(data));
        dispatch(
          addFlashMessage({
            contentKey: 'vehiclesCategoryForm_update_category_success',
            type: FLASH_MESSAGE_TYPE_SUCCESS
          })
        );
        return Promise.resolve(data);
      },
      error => {
        dispatch(addErrorMessage({ error, def: 'vehiclesCategoryForm_update_category_error' }));
        return Promise.reject(error);
      }
    );
  };
}

export function updateCategorySuccess(category) {
  return {
    type: types.CATEGORIES_UPDATE_CATEGORY_SUCCESS,
    category
  };
}
