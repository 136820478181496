import React, { Component, PropTypes } from 'react';
import { FormattedMessage } from 'react-intl';
import TableView from '../../../components/TableView/TableView';
import ImagePreview from '../../../components/ImagePreview/ImagePreview';
import { getAppFormattedDateTime } from '../../../utils/utils';
import { damagesTableViewParams } from '../../../constants/table-constants';

import _get from 'lodash/get';
import _includes from 'lodash/includes';

class DamageReport extends Component {
  constructor(props) {
    super(props);
    this.renderFragment = this.renderFragment.bind(this);
  }

  renderFragment() {
    const { damageReport } = this.props;
    const images = damageReport.damageReportPhotos;
    const type = damageReport.type;
    const keyPrefix = 'vehicleDetail_damagesTab_';
    let photos = [];

    let frontRight = { type, key: keyPrefix + 'frontRight' };
    let frontLeft = { type, key: keyPrefix + 'frontLeft' };
    let backLeft = { type, key: keyPrefix + 'backLeft' };
    let backRight = { type, key: keyPrefix + 'backRight' };
    let trunk = { type, key: 'common_trunk' };
    let other = { type, key: keyPrefix + 'other' };

    for (let k in images) {
      if (images.hasOwnProperty(k) && _includes(k, 'frontRight') && images[k] !== '') {
        const key = k.replace('frontRight', '').toLowerCase();
        frontRight[key] = images[k];
        photos[0] = frontRight;
      }
      if (images.hasOwnProperty(k) && _includes(k, 'frontLeft') && images[k] !== '') {
        const key = k.replace('frontLeft', '').toLowerCase();
        frontLeft[key] = images[k];
        photos[1] = frontLeft;
      }
      if (images.hasOwnProperty(k) && _includes(k, 'backLeft') && images[k] !== '') {
        const key = k.replace('backLeft', '').toLowerCase();
        backLeft[key] = images[k];
        photos[2] = backLeft;
      }
      if (images.hasOwnProperty(k) && _includes(k, 'backRight') && images[k] !== '') {
        const key = k.replace('backRight', '').toLowerCase();
        backRight[key] = images[k];
        photos[3] = backRight;
      }
      if (images.hasOwnProperty(k) && _includes(k, 'trunk') && images[k] !== '') {
        const key = k.replace('trunk', '').toLowerCase();
        trunk[key] = images[k];
        photos[4] = trunk;
      }
      if (images.hasOwnProperty(k) && _includes(k, 'other') && images[k] !== '') {
        const key = k.replace('other', '').toLowerCase();
        other[key] = images[k];
        photos[5] = other;
      }
    }
    return Object.values(photos).map((image, idx) => {
      const key = _get(image, 'key');

      return (
        <div className="report-image" key={image.photoid + idx}>
          <FormattedMessage id={key} />
          <ImagePreview thumbnailSrc={_get(image, 'thumbnailurl')} src={_get(image, 'url')} alt={key} />
        </div>
      );
    });
  }

  render() {
    const { customClass, damageReport, title } = this.props;

    return (
      <section className={customClass}>
        <h4 className="feedbackDetail_title">
          <FormattedMessage id={title} />
        </h4>
        <div className="feedbackDetail_line">
          <div className="feedbackDetail_item">
            <span className="feedbackDetail_item_label">
              <FormattedMessage id="feedbacks_detail_submissionDate" />
            </span>
            <span className="feedbackDetail_item_result">{getAppFormattedDateTime(damageReport.createdDate)}</span>
          </div>
        </div>
        <div className="feedbackDetail_line">{damageReport.damageReportPhotos && this.renderFragment()}</div>
        <div className="feedbackDetail_line">
          <div className="feedbackDetail_item">
            <span className="feedbackDetail_item_label">
              <FormattedMessage id="feedbacks_detail_internalCleanliness" />
            </span>
            <span className="feedbackDetail_item_result">{damageReport.internalCleanliness}</span>
          </div>
          <div className="feedbackDetail_item">
            <span className="feedbackDetail_item_label">
              <FormattedMessage id="feedbacks_detail_externalCleanliness" />
            </span>
            <span className="feedbackDetail_item_result">{damageReport.externalCleanliness}</span>
          </div>
        </div>
        <div className="feedbackDetail_line">
          {damageReport.cleanlinessComment && damageReport.cleanlinessComment.length > 0 && (
            <div className="feedbackDetail_item">
              <span className="feedbackDetail_item_label">
                <FormattedMessage id="feedbacks_detail_comment" />
              </span>
              <span className="feedbackDetail_item_result">{damageReport.cleanlinessComment}</span>
            </div>
          )}
        </div>
        {!damageReport.reports.length ? (
          ''
        ) : (
          <div className="feedbackDetail_line">
            <TableView
              contentMessageKey={title}
              customClass="feedbackDetail_tableViewWrapper"
              params={damagesTableViewParams}
              content={damageReport.reports}
            />
          </div>
        )}
      </section>
    );
  }
}

DamageReport.displayName = 'DamageReport';

DamageReport.propTypes = {
  customClass: PropTypes.string,
  damageReport: PropTypes.object,
  title: PropTypes.string
};

export default DamageReport;
