import React, { Component } from 'react';
import autoBind from 'react-autobind';
import { bundleSelector, currentCompanyIdSelector } from '../../../selectors/all-selectors';
import _get from 'lodash/get';

import { connect } from 'react-redux';
import { createInitVehiclePlanning, getAppFormattedDayMonthTime, getBookingColor } from '../../../utils/utils';
import {
  requestBookingCancel,
  getDataVehiclePlanning,
  addFlashMessage,
  getImpersonateMember,
  toggleVehiclePlanningEditBooking,
  getBookingVehiclePlanning,
  toggleChangeVehicle,
  toggleChangeMember,
  getBookingFindAvailableVehicle,
  requestBookingFinish,
  toggleShortenExtendBooking,
  confirmPreBooking,
  saveUserTypeForBookingEdit
} from '../../../actions/all-actions';
import { bookingTypesObj } from '../../../constants/options-constants';

import {
  BOOKING_STATUS_SCHEDULED,
  BOOKING_STATUS_PRE_BOOKED,
  BOOKING_STATUS_IN_PROGRESS,
  BOOKING_STATUS_COMPLETED,
  STATUS_CANCELED,
  BOOKING_TYPE_GROUP_SHARING,
  BOOKING_TYPE_RRS_SHARING,
  BOOKING_TYPE_RIDE_SHARING,
  BOOKING_TYPE_CAR_SHARING,
  STATUS_TO_REVIEW,
  STATUS_APPROVED
} from '../../../constants/backend-constants';
import { darkBoolean, MEMBER_STATUS_COLOR } from '../../../constants/style-constants';
import routes from '../../../constants/routes-constants';
import { enhanceSearchVehicleData } from '../../../api/data-enhancer';
import Dialog from 'material-ui/Dialog';
import RaisedButton from 'material-ui/RaisedButton';
import FlatButton from 'material-ui/FlatButton';
import Completed from 'material-ui/svg-icons/action/done';
import Canceled from 'material-ui/svg-icons/navigation/close';
import Scheduled from 'material-ui/svg-icons/device/access-time';
import Progress from 'material-ui/svg-icons/action/cached';
import { FLASH_MESSAGE_TYPE_ERROR } from '../../../constants/generic-constants';
import { Card, CardActions, CardHeader, CardText } from 'material-ui/Card';
import ReactSVG from 'react-svg';
import { FormattedMessage } from 'react-intl';

/* eslint-disable react/no-set-state */
class TooltipCard extends Component {
  constructor(props) {
    super(props);
    autoBind(this);

    this.state = {
      dialogOpened: false,
      openFinishConfirm: false,
      actionTriggered: false
    };
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.openedEditBookingModal !== prevProps.openedEditBookingModal ||
      this.props.openedChangeVehicleModal !== prevProps.openedChangeVehicleModal ||
      this.props.openShortenExtendBookingHover !== prevProps.openShortenExtendBookingHover ||
      this.props.openedMemberChangeModal !== prevProps.openedMemberChangeModal
    ) {
      this.handleDialog();
    }
  }

  handleImpersonate() {
    const { dispatch, booking, companyId } = this.props;
    dispatch(getImpersonateMember(booking.member.id, companyId));
  }

  handleToggleDialog() {
    this.setState({ dialogOpened: !this.state.dialogOpened });
    this.handleDialog();
  }

  handleWrapClick(e) {
    e.stopPropagation();
  }

  handleDialog() {
    this.setState({ actionTriggered: !this.state.actionTriggered });
  }

  handleToggleEditBooking() {
    const { dispatch, openedEditBookingModal, booking } = this.props;
    if (!openedEditBookingModal) {
      dispatch(getBookingVehiclePlanning(_get(booking, 'id'))).then(data => dispatch(saveUserTypeForBookingEdit(data)));
    }
    dispatch(toggleVehiclePlanningEditBooking());
  }

  handleToggleChangeVehicle() {
    const { dispatch, openedChangeVehicleModal, booking } = this.props;

    if (!openedChangeVehicleModal) {
      dispatch(getBookingVehiclePlanning(_get(booking, 'id'))).then(data => {
        const selectedMember = { id: data.member.id };
        const values = createInitVehiclePlanning(data, true);
        const newParams = enhanceSearchVehicleData(values, data, selectedMember, true);

        newParams.usages = [data.carSharingInfo.usageType];
        newParams.startParkingId = data.start.parking.id;

        dispatch(getBookingFindAvailableVehicle(newParams, false));
      });
    }

    this.props.dispatch(toggleChangeVehicle());
  }

  handleToggleChangeMember() {
    const { dispatch, openedMemberChangeModal, booking } = this.props;

    if (!openedMemberChangeModal) {
      dispatch(getBookingVehiclePlanning(_get(booking, 'id'))).then(data => {
        dispatch(toggleChangeMember());
      });
    }
  }

  handleCancelBooking() {
    const { dispatch, booking } = this.props;
    this.handleToggleDialog();
    dispatch(requestBookingCancel(_get(booking, 'id'))).then(
      () => {
        setTimeout(() => dispatch(getDataVehiclePlanning()), 1000); // wait server to process data
      },
      () => {
        setTimeout(() => dispatch(getDataVehiclePlanning()), 1000); // wait server to process data
        dispatch(
          addFlashMessage({
            contentKey: 'booking_detail_cancel_failed',
            type: FLASH_MESSAGE_TYPE_ERROR
          })
        );
      }
    );
  }

  handleFinish() {
    const { dispatch, booking } = this.props;
    dispatch(requestBookingFinish(booking.id, false)).then(
      () => {
        this.handleToggleFinishConfirm();
        setTimeout(() => dispatch(getDataVehiclePlanning()), 1000); // wait server to process data
      },
      () => {
        setTimeout(() => dispatch(getDataVehiclePlanning()), 1000); // wait server to process data
        dispatch(
          addFlashMessage({
            contentKey: 'booking_detail_finish_failed',
            type: FLASH_MESSAGE_TYPE_ERROR
          })
        );
      }
    );
  }

  handleConfirm() {
    const { dispatch } = this.props;
    dispatch(confirmPreBooking());
    this.handleToggleEditBooking();
  }

  handleToggleFinishConfirm() {
    this.setState({ openFinishConfirm: !this.state.openFinishConfirm }); //eslint-disable-line
    this.handleDialog();
  }

  hintWorkflow() {
    const {
      booking,
      booking: { member }
    } = this.props;

    if (
      booking.status === BOOKING_STATUS_PRE_BOOKED &&
      (member.memberStatus === STATUS_APPROVED || member.memberStatus === STATUS_TO_REVIEW)
    ) {
      return (
        <div className="hint-workflow">
          {member.memberStatus === STATUS_APPROVED && <FormattedMessage id="hint_prebooking_approved_member" />}
          {member.memberStatus === STATUS_TO_REVIEW && <FormattedMessage id="hint_prebooking_review_member" />}
        </div>
      );
    }
  }

  handleToggleExtendShortenBooking() {
    const { vehicle, booking } = this.props;
    this.props.dispatch(toggleShortenExtendBooking(booking, vehicle, true));
  }

  render() {
    const { readOnly, booking, vehicle, bundle } = this.props;
    const vehicleHref = `#${routes.vehicleDetail.path.replace(':vehicleId', vehicle.id)}`;
    const memberHref = `#${routes.memberDetail.path.replace(':memberId', booking.member.id)}`;
    const memberLink = <a href={memberHref}>{booking.member.firstName + ' ' + booking.member.lastName}</a>;
    const reviewMemberLink = !readOnly && (
      <a href={memberHref} className="actions">
        {bundle['member_review']}
      </a>
    );
    const confirmNBookingLink = !readOnly && (
      <a onClick={this.handleConfirm} className="actions">
        {bundle['confirm_booking']}
      </a>
    );

    let icon = '';
    let startTime = '';
    let endTime = '';
    switch (booking.status) {
      case BOOKING_STATUS_SCHEDULED:
        icon = <Scheduled />;
        startTime = booking.estimatedStartDate;
        endTime = booking.estimatedEndDate;
        break;
      case BOOKING_STATUS_IN_PROGRESS:
        icon = <Progress />;
        if (booking.delayed) icon = <ReactSVG src="/img/clock.svg" className="prebooked-icon" svgClassName="svg-icon tik-tok" />;
        startTime = booking.effectiveStartDate;
        endTime = booking.estimatedEndDate;
        break;
      case BOOKING_STATUS_COMPLETED:
        icon = <Completed />;
        startTime = booking.effectiveStartDate;
        endTime = booking.effectiveEndDate;
        break;
      case STATUS_CANCELED:
        icon = <Canceled />;
        startTime = booking.estimatedStartDate;
        endTime = booking.estimatedEndDate;
        break;
      case BOOKING_STATUS_PRE_BOOKED:
        icon = <ReactSVG src="/img/pre-booked.svg" className="prebooked-icon" svgClassName="svg-icon" />;
        startTime = booking.estimatedStartDate;
        endTime = booking.estimatedEndDate;
        break;
      default:
    }

    return (
      <div className={this.state.actionTriggered ? 'hide' : 'show'}>
        <Card style={{ border: '2px solid', borderColor: getBookingColor(booking.status) }}>
          <CardHeader
            className="card-header"
            style={{ backgroundColor: getBookingColor(booking.status) }}
            titleStyle={{ fontSize: '18px', color: booking.status === BOOKING_STATUS_PRE_BOOKED ? 'black !important' : 'white' }}
            subtitleStyle={{ color: booking.status === BOOKING_STATUS_PRE_BOOKED ? 'black !important' : 'white' }}
            title={bundle['bookings_tableView_status_' + booking.status]}
            subtitle={getAppFormattedDayMonthTime(startTime) + ' - ' + getAppFormattedDayMonthTime(endTime)}
            avatar={icon}
          >
            {booking.delayed ? (
              <subtitle className="delayed">
                <FormattedMessage id="booking_detail_delayed" />
              </subtitle>
            ) : (
              ''
            )}
          </CardHeader>
          <CardHeader
            title={<a href={vehicleHref}>{vehicle.registrationNumber}</a>}
            subtitle={vehicle.brand + ' ' + vehicle.model + ' ' + vehicle.color}
            avatar={vehicle.picture}
          />

          <CardText>
            <div className="details">
              <span>ID</span>
              <a href={'#' + routes.bookingDetail.path.replace(':bookingId', booking.id)}>{booking.functionalId}</a>
            </div>
            <div className="details">
              {bundle['vehicle_update_cleanliness_type_member']}
              {memberLink}
            </div>
            <div className="details">
              {bundle['member_status']}
              <span
                className="member-status"
                style={{
                  backgroundColor: MEMBER_STATUS_COLOR['STATUS_' + booking.member.memberStatus]
                }}
                children={bundle[`members_status_${booking.member.memberStatus.toLowerCase()}`]}
              />
              {booking.member.memberStatus === STATUS_TO_REVIEW && reviewMemberLink}
              {booking.member.memberStatus === STATUS_APPROVED && booking.status === BOOKING_STATUS_PRE_BOOKED && confirmNBookingLink}
            </div>

            {booking.type === BOOKING_TYPE_CAR_SHARING && (
              <div className="details">
                <FormattedMessage id="booking.for.vr" />
                <strong>
                  <span style={darkBoolean}>{String(!!booking.replacementVehicle)}</span>
                </strong>
              </div>
            )}

            {this.hintWorkflow()}

            {_get(booking, 'type', '') !== '' && (
              <div className="details">
                {bundle['common_type']}
                {bundle[bookingTypesObj[booking.type]]}
              </div>
            )}

            {_get(booking, 'usageType', '') !== '' && (
              <div className="details">
                {bundle['booking_create_find_vehicle_tableView_usage']}
                {bundle['siteForm_spontaneousBookingUsage_' + booking.usageType]}
              </div>
            )}

            {_get(booking, 'comment', '') !== '' && (
              <div className="details">
                {bundle['booking_create_confirmation_comment']}
                {booking.comment}
              </div>
            )}
          </CardText>

          {!readOnly && (
            <CardActions className="actions">
              {(booking.status === BOOKING_STATUS_SCHEDULED ||
                booking.status === BOOKING_STATUS_IN_PROGRESS ||
                booking.status === BOOKING_STATUS_PRE_BOOKED) && (
                <RaisedButton primary label={bundle['common_cancel']} onClick={this.handleToggleDialog} />
              )}
              {booking.status === BOOKING_STATUS_IN_PROGRESS && (
                <RaisedButton
                  primary
                  label={bundle['booking_detail_extend_shorten_booking']}
                  onClick={this.handleToggleExtendShortenBooking}
                />
              )}
              {booking.status === BOOKING_STATUS_IN_PROGRESS && (
                <RaisedButton primary label={bundle['booking_detail_finish_booking']} onClick={this.handleToggleFinishConfirm} />
              )}
              {(booking.status === BOOKING_STATUS_SCHEDULED || booking.status === BOOKING_STATUS_PRE_BOOKED) &&
                booking.type !== BOOKING_TYPE_GROUP_SHARING &&
                booking.type !== BOOKING_TYPE_RIDE_SHARING &&
                booking.type !== BOOKING_TYPE_RRS_SHARING && (
                  <RaisedButton primary label={bundle['companies_detail_edit_button']} onClick={this.handleToggleEditBooking} />
                )}
              {(booking.status === BOOKING_STATUS_SCHEDULED || booking.status === BOOKING_STATUS_PRE_BOOKED) &&
                booking.type !== BOOKING_TYPE_GROUP_SHARING &&
                booking.type !== BOOKING_TYPE_RIDE_SHARING &&
                booking.type !== BOOKING_TYPE_RRS_SHARING && (
                  <RaisedButton primary label={bundle['vehiclePlanning_changeVehicle']} onClick={this.handleToggleChangeVehicle} />
                )}
              {booking.status === BOOKING_STATUS_SCHEDULED &&
                booking.type !== BOOKING_TYPE_GROUP_SHARING &&
                booking.type !== BOOKING_TYPE_RIDE_SHARING &&
                booking.type !== BOOKING_TYPE_RRS_SHARING && (
                  <RaisedButton primary label={bundle['vehiclePlanning_changeMember']} onClick={this.handleToggleChangeMember} />
                )}
              <RaisedButton primary label={bundle['members_detail_impersonate_button']} onClick={this.handleImpersonate} />
            </CardActions>
          )}
        </Card>

        <Dialog
          actions={[
            <FlatButton
              key="no"
              label={bundle['booking_create_confirmation_abort_button']}
              onClick={this.handleToggleDialog}
              className="flatButton"
            />,
            <FlatButton key="yes" label="Ok" onClick={this.handleCancelBooking} className="flatButton" />
          ]}
          modal={false}
          contentStyle={{ width: '320px' }}
          open={this.state.dialogOpened}
          onRequestClose={this.handleClose}
        >
          {bundle['booking_detail_cancelBooking_text']}
        </Dialog>

        <Dialog
          actions={[
            <FlatButton
              key="no"
              label={bundle['booking_create_confirmation_abort_button']}
              onClick={this.handleToggleFinishConfirm}
              className="flatButton"
            />,
            <FlatButton key="yes" label="Ok" onClick={this.handleFinish} className="flatButton" />
          ]}
          modal={false}
          contentStyle={{ width: '320px' }}
          open={this.state.openFinishConfirm}
          onRequestClose={this.handleToggleFinishConfirm}
        >
          {bundle['finalizeBookingForm_title']}
        </Dialog>
      </div>
    );
  }
}

TooltipCard.displayName = 'TooltipCard';

export default connect(state => {
  const {
    vehiclePlanning: {
      data,
      openedEditBookingModal,
      selectedBookingId,
      openedChangeVehicleModal,
      openShortenExtendBookingHover,
      openedMemberChangeModal
    }
  } = state;

  return {
    companyId: currentCompanyIdSelector(state),
    data,
    bundle: bundleSelector(state),
    openedEditBookingModal,
    selectedBookingId,
    openedChangeVehicleModal,
    openShortenExtendBookingHover,
    openedMemberChangeModal
  };
})(TooltipCard);
