import React, { Component } from 'react';
import { reduxForm } from 'redux-form';
import { scrollToFirstError } from '../../../../utils/utils';
import LabeledCheckbox from '../../../../components/LabeledCheckbox/LabeledCheckbox';
import { headerContractMemberValuesSelector } from '../../../../selectors/all-selectors';
import { RaisedButton } from 'material-ui';
import { getMsg } from '../../../../utils/IntlGlobalProvider';
import { FormattedMessage } from 'react-intl';

class MemberSettings extends Component {
  render() {
    const {
      fields: { customFields, managerEmail, expediteReview, uploadList, type, sso },
      limitedAccess
    } = this.props;

    return (
      <div className="contract-member-info">
        <div className="sc-option-details">
          <div className="sc-flat-boxes">
            <LabeledCheckbox id="memberCustomFields" field={customFields} disabled={limitedAccess}>
              <FormattedMessage id="company_detail_tab_subscription_custom_fields" />
            </LabeledCheckbox>
            <LabeledCheckbox id="memberManagerEmail" field={managerEmail} disabled={limitedAccess}>
              <FormattedMessage id="members_detail_manager_email" />
            </LabeledCheckbox>
            <LabeledCheckbox id="memberExpediteReview" field={expediteReview} disabled={limitedAccess}>
              <FormattedMessage id="companyForm_expediteReview_label" />
            </LabeledCheckbox>
            <LabeledCheckbox id="memberUploadList" field={uploadList} disabled={limitedAccess}>
              <FormattedMessage id="upload_members_button" />
            </LabeledCheckbox>
            <LabeledCheckbox id="memberType" field={type} disabled={limitedAccess}>
              <FormattedMessage id="common_member_type" />
            </LabeledCheckbox>
            <LabeledCheckbox id="memberSso" field={sso} disabled={limitedAccess}>
              <span>SSO</span>
            </LabeledCheckbox>
          </div>
        </div>
        {!limitedAccess && (
          <div className="sc-actions">
            <RaisedButton label={getMsg('common_save')} primary type="button" onClick={this.props.handleSubmit(this.props.onCallback)} />
          </div>
        )}
      </div>
    );
  }
}

MemberSettings = reduxForm(
  {
    onSubmitFail: scrollToFirstError,
    form: 'contractMemberSettings',
    fields: ['customFields', 'managerEmail', 'expediteReview', 'uploadList', 'type', 'sso']
  },
  state => ({
    initialValues: headerContractMemberValuesSelector(state)
  })
)(MemberSettings);

export default MemberSettings;
