import React, { Component } from 'react';
import FormRow from '../../../components/FormRow/FormRow';
import { reduxForm } from 'redux-form';

import {
  alphanumericPhoneNumber,
  createValidator,
  email,
  emailColor,
  length,
  linkedinLink,
  notEmpty,
  twitterLink
} from '../../../validation/sync-validation';
import BoxedInput from '../../../components/BoxedInput/BoxedInput';
import { RaisedButton } from 'material-ui';
import { FormattedMessage } from 'react-intl';
import FieldErrorMsg from '../../../components/FieldErrorMsg/FieldErrorMsg';
import PropTypes from 'prop-types';
import { modalStyles } from '../../../constants/style-constants';
import Modal from 'react-modal';
import autoBind from 'react-autobind';
import classNames from 'classnames';
import { COMPANY_EMAIL_SETUP_FORM } from '../../../constants/form-constants';
import FileUpload from '../../../components/FileUlpoad/FileUpload';

class CompanyEmailSetupForm extends Component {
  constructor(props) {
    super(props);
    autoBind(this);

    this.state = {
      confirmModalOpen: false
    };
  }

  handleOpenConfirmModal() {
    this.setState({ confirmModalOpen: true });
  }

  handleCancelDelete() {
    this.setState({ confirmModalOpen: false });
  }

  handleConfirmDelete() {
    this.setState({ confirmModalOpen: false });
    this.props.onDelete();
  }

  deleteButton() {
    return (
      <RaisedButton
        label={<FormattedMessage id="common_delete" />}
        className={classNames('button', 'flatButton')}
        onClick={this.handleOpenConfirmModal}
        type="button"
        id="open-delete-modal"
      />
    );
  }

  submitButton() {
    return (
      <RaisedButton
        label={<FormattedMessage id="common_save" />}
        primary
        className={classNames('button', 'flatButton')}
        type="submit"
        id="submit-email-setup"
      />
    );
  }

  confirmModal() {
    return (
      <Modal isOpen={this.state.confirmModalOpen} style={modalStyles}>
        <div className="confirm-container">
          <div className="title">
            <FormattedMessage id="company_email_setup_delete_title" />
          </div>
          <div className="body">
            <FormattedMessage id="company_email_setup_delete_body" />
          </div>
          <div className="buttons">
            <RaisedButton
              className={classNames('button', 'flatButton')}
              id="cancel-modal"
              label={<FormattedMessage id="common_no" />}
              onClick={this.handleCancelDelete}
            />
            <RaisedButton
              className={classNames('button', 'flatButton')}
              primary
              id="confirm-delete"
              label={<FormattedMessage id="common_yes" />}
              onClick={this.handleConfirmDelete}
            />
          </div>
        </div>
      </Modal>
    );
  }

  preventSubmit(e) {
    e.preventDefault();
  }

  setupForm() {
    const {
      fields: { colorPrimary, colorSecondary, linkedinLink, twitterLink, emailLogo, productName, senderEmail, senderSms, homeDeeplink },
      handleSubmit,
      onSave,
      readOnly
    } = this.props;

    const submitAction = readOnly ? this.preventSubmit : handleSubmit(onSave);

    return (
      <form action="#" onSubmit={submitAction} className="email-setup-form">
        <FormRow customClass="companyForm_row">
          <BoxedInput readOnly={readOnly} labelKey="company_email_setup_color_primary" type="color" field={colorPrimary} id="setup-color-1">
            <FieldErrorMsg customClass="field-error" field={colorPrimary} />
          </BoxedInput>

          <BoxedInput
            readOnly={readOnly}
            labelKey="company_email_setup_color_secondary"
            type="color"
            field={colorSecondary}
            id="setup-color-2"
          >
            <FieldErrorMsg customClass="field-error" field={colorSecondary} />
          </BoxedInput>
        </FormRow>

        <FormRow customClass="companyForm_row">
          <BoxedInput readOnly={readOnly} labelKey="company_email_setup_twitter_url" type="text" field={twitterLink} id="setup-twitter-url">
            <FieldErrorMsg customClass="field-error" field={twitterLink} />
          </BoxedInput>

          <BoxedInput
            readOnly={readOnly}
            labelKey="company_email_setup_linkedin_url"
            type="text"
            field={linkedinLink}
            id="setup-linked-url"
          >
            <FieldErrorMsg customClass="field-error" field={linkedinLink} />
          </BoxedInput>
        </FormRow>

        <div className="companyForm_fileUpload_row">
          <FileUpload label="company_email_setup_logo" id="setup-logo-url" field={emailLogo} readOnly={readOnly} mandatory noPadding />
        </div>

        <FormRow customClass="companyForm_row">
          <BoxedInput
            readOnly={readOnly}
            labelKey="company_email_setup_product_name"
            type="text"
            field={productName}
            id="setup-product-name"
          >
            <FieldErrorMsg customClass="field-error" field={productName} />
          </BoxedInput>
        </FormRow>

        <FormRow customClass="companyForm_row">
          <BoxedInput
            readOnly={readOnly}
            labelKey="company_email_setup_sender_email"
            type="text"
            field={senderEmail}
            id="setup-sender-email"
          >
            <FieldErrorMsg customClass="field-error" field={senderEmail} />
          </BoxedInput>

          <BoxedInput readOnly={readOnly} labelKey="company_email_setup_sender_sms" type="text" field={senderSms} id="setup-sender-sms">
            <FieldErrorMsg customClass="field-error" field={senderSms} />
          </BoxedInput>
        </FormRow>
        <FormRow customClass="companyForm_row">
          <BoxedInput
            readOnly={readOnly}
            labelKey="company_email_setup_deep_link_url"
            type="text"
            field={homeDeeplink}
            id="setup-deep-ink"
          />
        </FormRow>

        {!readOnly && (
          <div className="actions">
            {this.deleteButton()}
            {this.submitButton()}
          </div>
        )}
      </form>
    );
  }

  render() {
    return (
      <div className="company-email-setup-form">
        {this.setupForm()}
        {this.confirmModal()}
      </div>
    );
  }
}

CompanyEmailSetupForm.displayName = 'CompanyEmailSetupForm';

CompanyEmailSetupForm.defaultProps = {
  onSave: () => false,
  onDelete: () => false,
  readOnly: false
};

CompanyEmailSetupForm.propTypes = {
  onSave: PropTypes.func,
  onDelete: PropTypes.func,
  readOnly: PropTypes.bool
};

export default reduxForm({
  form: COMPANY_EMAIL_SETUP_FORM,
  fields: [
    'colorPrimary',
    'colorSecondary',
    'linkedinLink',
    'twitterLink',
    'emailLogo',
    'productName',
    'senderEmail',
    'senderSms',
    'homeDeeplink'
  ],
  validate: createValidator({
    emailLogo: [notEmpty()],
    productName: [notEmpty(), length({ max: 255 })],
    colorPrimary: [emailColor()],
    colorSecondary: [emailColor()],
    senderEmail: [email()],
    linkedinLink: [linkedinLink()],
    twitterLink: [twitterLink()],
    senderSms: [alphanumericPhoneNumber()]
  })
})(CompanyEmailSetupForm);
