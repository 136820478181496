import memoizeOne from 'memoize-one';

const createOptionsMem = headerCompanies => {
  return (headerCompanies || []).map(({ name, id } = {}) => ({
    label: name,
    value: id
  }));
};

export const createCompanyOptions = memoizeOne(createOptionsMem);
export const createSubCompanyOptions = memoizeOne(createOptionsMem);
export const createSitesOptions = memoizeOne(createOptionsMem);
export const createMemberTypeOptions = memoizeOne(createOptionsMem);
