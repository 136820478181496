import React, { Component, PropTypes } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import Compressor from 'compressorjs';
import config from '../../constants/config-constants';
import { addErrorMessage } from '../../utils/utils';
import { getStore } from '../../store/all-store';

class ButtonUpload extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(e) {
    e.preventDefault();
    const inputFile = e.target.files[0];

    if (!inputFile) return;

    const setBase64 = file => {
      const reader = new FileReader();

      reader.onload = function(upload) {
        let fileData = {
          name: file.name,
          mimeType: file.type,
          size: file.size,
          lastModifiedDate: file.lastModifiedDate,
          content: upload.target.result,
          rawFile: file
        };

        if (this.props.onChange) {
          this.props.onChange(fileData);
        }

        this.props.field.onChange(fileData);
      }.bind(this);

      reader.readAsDataURL(file);
    };

    if (inputFile.type.includes('image/')) {
      new Compressor(inputFile, {
        ...(this.props.compression || config.fileUploadCompression.default),
        success: reducedFile => {
          setBase64(reducedFile);
        },
        error: error => {
          const props = { error: { developerMessage: error.message } };
          this.props.dispatch(addErrorMessage(props));
        }
      });
    } else if (inputFile.type === 'application/pdf') {
      setBase64(inputFile);
    } else {
      const props = { error: { developerMessage: this.props.intl.messages['file.type.upload.error'] } };
      getStore().dispatch(addErrorMessage(props));
    }
  }

  render() {
    return (
      <div>
        <label className="buttonUploadWrapper" htmlFor={this.props.id} />
        <span className="buttonUpload_label">
          {this.props.label && <FormattedMessage id={this.props.label} />}
          {!this.props.mandatory ? '' : <span className="boxedInput_mandatory">*</span>}
        </span>
        <span className={`buttonUpload ${this.props.customClass || ''}`}>
          <input type="file" accept={this.props.accept} id={this.props.id} className="buttonUpload_input" onChange={this.handleChange} />
          {this.props.buttonLabel ? (
            <span className="buttonUpload_labelButton">
              <FormattedMessage id={this.props.buttonLabel} />
            </span>
          ) : null}
        </span>
        <span className="buttonUpload_name">{!this.props.field.value ? '' : this.props.field.value.name}</span>
        {this.props.children}
      </div>
    );
  }
}

ButtonUpload.displayName = 'ButtonUpload';

ButtonUpload.propTypes = {
  label: PropTypes.string,
  buttonLabel: PropTypes.string,
  customClass: PropTypes.string,
  compression: PropTypes.object,
  id: PropTypes.string,
  onChange: PropTypes.func,
  mandatory: PropTypes.bool,
  accept: PropTypes.string
};

export default injectIntl(ButtonUpload);
