import * as types from '../../constants/actionTypes-constants';

export default function memberTypes(
  state = {
    list: [],
    filteredList: [],
    loadingList: false,
    errorList: false,
    memberType: { name: '', maximumScheduledBookings: '' },
    isOpen: false,
    editMode: false,
    memberTypeDel: null,
    newItem: null
  },
  action
) {
  switch (action.type) {
    case types.MEMBER_TYPES_GET_LIST_REQUEST: {
      return { ...state, loadingList: true, errorList: false };
    }

    case types.MEMBER_TYPES_GET_LIST_SUCCESS: {
      return { ...state, loadingList: false, errorList: false, list: action.list || [] };
    }

    case types.MEMBER_TYPES_GET_LIST_ERROR: {
      return { ...state, loadingList: false, errorList: true };
    }

    case types.MEMBER_TYPES_CLEAR_LIST_DATA: {
      return { ...state, list: [], loadingList: false, errorList: false };
    }

    case types.MEMBER_TYPES_EDIT_MODE:
      return {
        ...state,
        editMode: true,
        memberType: action.memberType
      };

    case types.MEMBER_TYPE_EDIT_OPEN_MODAL:
      return {
        ...state,
        isOpen: true
      };

    case types.MEMBER_TYPE_EDIT_CLOSE_MODAL:
      return {
        ...state,
        isOpen: false,
        editMode: false
      };

    case types.MEMBER_TYPE_EDIT_SUCCEEDED_MODAL:
      const newList = state.list.map(item => {
        if (item.id === action.memberType.id) item = action.memberType;
        return item;
      });
      return {
        ...state,
        list: newList
      };

    case types.MEMBER_TYPE_CREATE_REQUEST:
      return {
        ...state,
        editMode: false
      };

    case types.MEMBER_TYPE_CREATE_OPEN_MODAL:
      return {
        ...state,
        editMode: false,
        isOpen: true,
        newItem: true
      };

    case types.MEMBER_TYPE_CLOSE_MODAL:
      return {
        ...state,
        editMode: false,
        isOpen: false,
        memberTypeDel: null
      };

    case types.MEMBER_TYPE_CREATE_SUCCESS:
      return {
        ...state,
        list: [...state.list, action.newItem]
      };

    case types.MEMBER_TYPE_DELETE_REQUEST:
      return {
        ...state,
        memberTypeDel: action.memberTypeDel
      };

    case types.MEMBER_TYPE_DELETE_SUCCESS:
      const idx = state.list.findIndex(item => {
        return item.id === action.params.memberTypeId;
      });
      const newData = [...state.list.slice(0, idx), ...state.list.slice(idx + 1)];
      return {
        ...state,
        list: newData
      };

    case types.MEMBER_TYPE_DELETE_OPEN_MODAL:
      return {
        ...state,
        editMode: false,
        isOpen: !state.isOpen,
        memberTypeDel: action.memberTypeDel
      };

    default:
      return state;
  }
}
