import _isEmpty from 'lodash/isEmpty';
import _isArray from 'lodash/isArray';
import _get from 'lodash/get';
import _forOwn from 'lodash/forOwn';
import _isString from 'lodash/isString';
import _isObject from 'lodash/isObject';
import _indexOf from 'lodash/indexOf';
import _reject from 'lodash/reject';
import _cloneDeep from 'lodash/cloneDeep';
import _forEach from 'lodash/forEach';
import _every from 'lodash/every';
import _sortBy from 'lodash/sortBy';

import {
  DAY_OF_WEEK_FRIDAY,
  DAY_OF_WEEK_MONDAY,
  DAY_OF_WEEK_SATURDAY,
  DAY_OF_WEEK_SUNDAY,
  DAY_OF_WEEK_THURSDAY,
  DAY_OF_WEEK_TUESDAY,
  DAY_OF_WEEK_WEDNESDAY,
  TIME_SLOT_TYPE_PACKAGE_FOR_DURATION,
  TIME_SLOT_USAGE_PRIVATE,
  URL_ALL
} from '../constants/backend-constants';

import moment from 'moment';
import {
  VALIDATION_ALPHANUMERIC_PHONE_NUMBER,
  VALIDATION_ARRAY_LENGTH,
  VALIDATION_DATE_BEFORE_INVALID,
  VALIDATION_DATE_IN_THE_PAST,
  VALIDATION_DATE_SHOULD_BE_IN_THE_FUTURE,
  VALIDATION_DOMAINS_LIST,
  VALIDATION_EMAIL_HEX_COLOR,
  VALIDATION_EMAIL_INVALID,
  VALIDATION_ENDDATE_BEFORE_STARTDATE,
  VALIDATION_FIELD_MISSING,
  VALIDATION_FIELD_NOT_PERCENTAGE,
  VALIDATION_FIELD_NOT_RATE,
  VALIDATION_FIELD_ONE_REQUIRED,
  VALIDATION_FORBIDDEN_CHARACTERS,
  VALIDATION_FRENCH_PHONE_NUMBER_INVALID,
  VALIDATION_INCORRECT_COUNTRY_CODE,
  VALIDATION_INVALID_RADIUS,
  VALIDATION_LENGTH_TOO_LONG,
  VALIDATION_LENGTH_TOO_SHORT,
  VALIDATION_LOWER,
  VALIDATION_MAX_VALUE,
  VALIDATION_MIN_VALUE,
  VALIDATION_MISMATCH,
  VALIDATION_NOT_INTEGER,
  VALIDATION_NOT_LATITUDE,
  VALIDATION_NOT_LONGITUDE,
  VALIDATION_NOT_NUMBER,
  VALIDATION_NOT_POSITIVE_NUMBER,
  VALIDATION_NOT_PRICE_NUMBER,
  VALIDATION_NOT_RRF_CODE,
  VALIDATION_NOT_VALID_ITLIAN_FISC_NUMBER,
  VALIDATION_NUMBERS_CAPITALS_ALLOWED,
  VALIDATION_PASSWORD_INVALID,
  VALIDATION_REGISTRATION_FILE_INVALID,
  VALIDATION_REGISTRATION_FILE_INVALID_MIME_TYPE,
  VALIDATION_REGISTRATION_FILE_SIZE_TOO_LARGE,
  VALIDATION_TIMESLOTS_OVERLAP,
  VALIDATION_TIMESLOTS_TO_DAY_EARLIER_THAN_START,
  VALIDATION_TIMESLOTS_TO_HOUR_EARLIER_THAN_FROM_HOUR,
  VALIDATION_TIMESLOTS_TO_HOUR_MUST_BE_ZERO,
  VALIDATION_TIMESLOTS_TO_MINUTES_EARLIER_THAN_FROM_MINUTES,
  VALIDATION_TIMESLOTS_TO_MINUTES_EQUAL_FROM_MINUTES,
  VALIDATION_TIMESLOTS_TO_MINUTES_MUST_BE_ZERO,
  VALIDATION_URL_INVALID,
  VALIDATION_UUID_INVALID,
  VALIDATION_VIN_NUMBER_INVALID
} from '../constants/errors-constants';
import config from '../constants/config-constants';
import { isEmpty, isValidId, isValidEmail, isValidPhoneNumber, regexEscape, trimFields, isUpperCase, safe } from '../utils/utils';
import _includes from 'lodash/includes';
import { regexGuid } from '../constants/regex';
import _set from 'lodash/set';

const STOP_VALIDATION = 'STOP_VALIDATION';

const unifyValidators = validators => {
  return params => {
    const validatorsLength = validators.length;
    let errors = [];

    // apply validators
    for (let i = 0; i < validatorsLength; i++) {
      let validatorResult = validators[i](params);
      if (validatorResult === STOP_VALIDATION) break;
      if (validatorResult) errors.push(validatorResult);
    }

    // only return the first error encountered
    return errors[0];
  };
};

export function createValidator(rules) {
  return (values = {}, props) => {
    const errors = {};

    Object.keys(rules).forEach(key => {
      const rawValue = _get(values, key);
      const value = _isString(rawValue) ? rawValue.trim() : trimFields(_cloneDeep(rawValue));
      const unifiedValidator = unifyValidators([].concat(rules[key]));
      const error = unifiedValidator({ value, values, props, rawValue, key });

      if (error) {
        _set(errors, key, error);
      }
    });

    return errors;
  };
}

export function mergeValidators(validators = []) {
  return (values, props) => {
    return validators.reduce((errors, validator) => {
      return { ...errors, ...validator(values, props) };
    }, {});
  };
}

// ------------------
// Validators
// ------------------

export const condition = func => ({ condition: func });

export function stopValidationIf(params) {
  return ({ value, values, props }) => {
    if (params && params.condition(props, value, values)) {
      return STOP_VALIDATION;
    }
  };
}

// fieldName - String or Array of Strings
export function notEmptyExternal(fieldName) {
  return ({ props }) => {
    let status = false;

    function fieldIsEmpty(field) {
      return isEmpty(_get(props, 'form.' + field + '.value'));
    }

    if (typeof fieldName === 'string') if (fieldIsEmpty(fieldName)) status = true;

    if (_isArray(fieldName)) {
      _forEach(fieldName, v => {
        if (fieldIsEmpty(v)) {
          status = true;
          return false;
        }
      });
    }
    if (status) return { type: VALIDATION_FIELD_MISSING };
  };
}

export function notEmpty() {
  return ({ value }) => {
    if (isEmpty(value)) {
      return {
        value,
        type: VALIDATION_FIELD_MISSING
      };
    }
  };
}

export function isCountry(mandatory = true) {
  return ({ value }) => {
    if ((value || mandatory) && !(safe(() => value.length === 2) && isUpperCase(value))) {
      return {
        value,
        type: VALIDATION_INCORRECT_COUNTRY_CODE
      };
    }
  };
}

export function onlyTrue() {
  return ({ value }) => {
    if (!value) {
      return { value, type: VALIDATION_FIELD_MISSING };
    }
  };
}

export function checkPhoneNumber(mandatory = true) {
  return phoneNumberObject => {
    const { value } = phoneNumberObject || {};
    const { phoneSuffix, valid } = value || {};

    if (mandatory && !valid)
      return {
        phoneSuffix,
        type: VALIDATION_FRENCH_PHONE_NUMBER_INVALID
      };
  };
}

export function oneIsRequired(fieldNames) {
  return ({ value, values }) => {
    fieldNames = _isString(fieldNames) ? [fieldNames] : fieldNames;
    const emptyValue = value => notEmpty()({ value });

    const getError = value => ({
      value,
      type: VALIDATION_FIELD_ONE_REQUIRED
    });

    if (_isEmpty(fieldNames) && emptyValue(value)) return getError(value);

    const predicate = fieldName => emptyValue(values[fieldName]);
    const allEmpty = _every(fieldNames, predicate);

    if (allEmpty) return getError(value);
  };
}

export function isRateNumber() {
  return ({ value }) => {
    if (!(0 <= value && value <= 1) && !isNaN(value)) {
      return {
        value,
        type: VALIDATION_FIELD_NOT_RATE
      };
    }
  };
}

export function validId() {
  return ({ value }) => {
    if (!isValidId(value)) {
      return {
        value,
        type: VALIDATION_FIELD_MISSING
      };
    }
  };
}

export function notValueDash() {
  return ({ value }) => {
    if (value === '-') {
      return {
        value,
        type: VALIDATION_FIELD_MISSING
      };
    }
  };
}
export function notValueUrlAll() {
  return ({ value }) => {
    if (value === URL_ALL) {
      return {
        value,
        type: VALIDATION_FIELD_MISSING
      };
    }
  };
}

export function notEmptyWhen(fieldName) {
  return ({ value, values }) => {
    if (values[fieldName]) {
      return notEmpty()({ value });
    }
  };
}

export function notEmptyArrayProps(propKey) {
  return ({ props }) => {
    if (props[propKey].length === 0) {
      return {
        values: [],
        type: VALIDATION_FIELD_MISSING
      };
    }
  };
}

export function email() {
  return ({ value }) => {
    if (!isEmpty(value) && !/^[A-Z0-9._&%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value)) {
      return {
        value,
        type: VALIDATION_EMAIL_INVALID
      };
    }
  };
}

// https://stackoverflow.com/a/54938084 + add prefix ^@
export function checkDomains() {
  return ({ value }) => {
    if (value && value.length) {
      const domains = value && value.split(',');
      if (!domains.every(d => /^@[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9]\.[a-zA-Z]{2,}/.test(d.trim()))) {
        return {
          value,
          type: VALIDATION_DOMAINS_LIST
        };
      }
    }
  };
}

export function length(params) {
  return ({ value }) => {
    if (!isEmpty(value) && !isEmpty(params.min) && value.length < params.min) {
      return {
        value,
        type: VALIDATION_LENGTH_TOO_SHORT,
        data: {
          count: params.min
        }
      };
    }

    if (!isEmpty(value) && !isEmpty(params.max) && value.length > params.max) {
      return {
        value,
        type: VALIDATION_LENGTH_TOO_LONG,
        data: {
          count: params.max
        }
      };
    }
  };
}

export function integer() {
  return ({ value }) => {
    if (value && !(Number(value) % 1 === 0)) return { type: VALIDATION_NOT_INTEGER };
  };
}

export function number() {
  return ({ value }) => {
    if (value && isNaN(Number(value))) {
      return { type: VALIDATION_NOT_NUMBER };
    }
  };
}

export function positiveNumber() {
  return ({ value }) => {
    if (Number(value) < 0) {
      return {
        value,
        type: VALIDATION_NOT_POSITIVE_NUMBER
      };
    }
  };
}

export function allowCapitalLettersAndNubmers() {
  return ({ value }) => {
    if (!/^[A-Z0-9]*$/.test(value)) {
      return {
        value,
        type: VALIDATION_NUMBERS_CAPITALS_ALLOWED
      };
    }
  };
}

export function minimum(min) {
  return ({ value }) => {
    if (value && value < min) {
      return {
        data: { value: String(min) },
        type: VALIDATION_MIN_VALUE
      };
    }
  };
}

export function maximum(max) {
  return ({ value }) => {
    if (value && value > max) {
      return {
        data: { value: String(max) },
        type: VALIDATION_MAX_VALUE
      };
    }
  };
}

export function match(field) {
  return ({ rawValue, values }) => {
    if (values) {
      if (rawValue !== values[field]) {
        return {
          rawValue,
          type: VALIDATION_MISMATCH,
          data: {
            matchedValue: values
          }
        };
      }
    }
  };
}

export function password() {
  return ({ rawValue }) => {
    const regexContainsLetter = /^.*[a-z]+.*/;
    const regexContainsCapitalizedLetter = /^.*[A-Z]+.*/;
    const regexContainsDigits = /^.*\d+.*/;

    if (!(regexContainsLetter.test(rawValue) && regexContainsCapitalizedLetter.test(rawValue) && regexContainsDigits.test(rawValue))) {
      return {
        rawValue,
        type: VALIDATION_PASSWORD_INVALID
      };
    }
  };
}

export function address(mandatory = true) {
  return ({ value, key }) => {
    let address = null;

    if (_isObject(value)) address = _get(value, 'formattedAddress');
    if (_isString(value)) address = value;

    if (mandatory && isEmpty(address)) {
      return {
        key,
        type: VALIDATION_FIELD_MISSING
      };
    }
  };
}

export function addressOrSite() {
  return params => {
    const { value } = params;

    if (value && !value._isSite) {
      let result = address()(params);
      if (result) {
        return result;
      }
    }
  };
}

export function vinNumber() {
  return ({ value }) => {
    if (isEmpty(value)) {
      return {
        value,
        type: VALIDATION_VIN_NUMBER_INVALID
      };
    }

    const regex = /^[a-zA-Z\d]{17}$/;

    if (!regex.test(value)) {
      return {
        value,
        type: VALIDATION_VIN_NUMBER_INVALID
      };
    }
  };
}

export function url() {
  return ({ value }) => {
    const regexContainsUrl = /^https?:\/\/[\da-z.-]+\.[a-z]{2,6}[\/\w.\-#?&+%_~=]*\/?$/;

    if (!isEmpty(value) && !regexContainsUrl.test(value)) {
      return {
        value,
        type: VALIDATION_URL_INVALID
      };
    }
  };
}

export function uuid() {
  return ({ value }) => {
    if (!isEmpty(value) && !regexGuid.test(value)) {
      return {
        value,
        type: VALIDATION_UUID_INVALID
      };
    }
  };
}

export function latitude() {
  return ({ value }) => {
    const regex = /^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?)$/;

    if (!regex.test(value)) {
      return {
        value,
        type: VALIDATION_NOT_LATITUDE
      };
    }
  };
}

export function longitude() {
  return ({ value }) => {
    const regex = /^[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)$/;

    if (!regex.test(value)) {
      return {
        value,
        type: VALIDATION_NOT_LONGITUDE
      };
    }
  };
}

export function radius() {
  return ({ value }) => {
    const intValue = parseInt(value, 10);

    if (isNaN(intValue) || intValue < 1) {
      return {
        value,
        type: VALIDATION_INVALID_RADIUS
      };
    }
  };
}

export function dateAfterOrEqualField(field) {
  return ({ value, values }) => {
    if (values[field] && value) {
      if (
        !moment(value)
          .set({ hour: values['returnDateHour'], minute: values['returnDateMin'] })
          .isAfter(moment(values[field]).set({ hour: values['pickupDateHour'], minute: values['pickupDateMin'] }))
      ) {
        return {
          value,
          type: VALIDATION_DATE_BEFORE_INVALID
        };
      }
    }
  };
}

export function dateAfterNotEqualField(field) {
  return ({ value, values }) => {
    if (values[field] && value) {
      if (value.getTime() <= values[field].getTime()) {
        return {
          value,
          type: VALIDATION_DATE_BEFORE_INVALID
        };
      }
    }
  };
}

export function rrfCode() {
  return ({ value }) => {
    let regex = /^([0-9 \-]+)$/;
    if (!isEmpty(value) && !regex.test(value)) {
      return {
        value,
        type: VALIDATION_NOT_RRF_CODE
      };
    }
  };
}

export function italianFiscNumber() {
  return ({ value }) => {
    let regex = /^RF[0-9]{2}$/;
    if (!isEmpty(value) && !regex.test(value)) {
      return {
        value,
        type: VALIDATION_NOT_VALID_ITLIAN_FISC_NUMBER
      };
    }
  };
}

export function futureDate() {
  return ({ values, props }) => {
    const offset = moment.parseZone(props.currentEndDate).utcOffset();
    const localeOffset = moment.parseZone().utcOffset();

    if (values.newEndDate && values.hour && values.min) {
      if (
        moment
          .utc(values.newEndDate)
          .utcOffset(offset)
          .add(values.hour, 'hours')
          .add(values.min, 'minutes')
          .add(localeOffset - offset, 'minutes')
          .diff(moment.utc().utcOffset(offset)) < 0
      ) {
        return {
          value: values.newEndDate,
          type: VALIDATION_DATE_IN_THE_PAST
        };
      }
    }
  };
}

export function endDateAfterNotEqualStartDate(field) {
  return ({ value, values }) => {
    if (values[field] && value) {
      if (value.getTime() <= values[field].getTime()) {
        return {
          value,
          type: VALIDATION_ENDDATE_BEFORE_STARTDATE
        };
      }
    }
  };
}

export function dateInFuture() {
  return ({ value, values }) => {
    let currentDate = moment(new Date());

    if (!moment(value).isAfter(currentDate)) {
      return {
        value: values.newEndDate,
        type: VALIDATION_DATE_SHOULD_BE_IN_THE_FUTURE
      };
    }
  };
}

export const endMomentBeforeStartMoment = ({ getStartDate, getEndDate }) => ({ values }) => {
  const startDate = getStartDate && getStartDate(values);
  const endDate = getEndDate && getEndDate(values);

  return (
    startDate &&
    endDate &&
    startDate.isAfter(endDate) && {
      type: VALIDATION_ENDDATE_BEFORE_STARTDATE
    }
  );
};

export function futureMoment({ getMoment, withOffsetMinute = 0 }) {
  return ({ values }) => {
    const date = getMoment(values);
    if (!date) return;
    const now = moment();

    return (
      now.minute(now.minute() + withOffsetMinute).isAfter(date) && {
        type: VALIDATION_DATE_SHOULD_BE_IN_THE_FUTURE
      }
    );
  };
}

export function checkboxesNotEmpty(field) {
  return ({ value, values }) => {
    if (!value && !values[field]) {
      return {
        value,
        type: VALIDATION_FIELD_MISSING
      };
    }
  };
}

export function fileValidation() {
  return ({ value }) => {
    if (value) {
      if (typeof value !== 'object') {
        return {
          value,
          type: VALIDATION_REGISTRATION_FILE_INVALID
        };
      }

      const goodMimeTypes = ['image/jpeg', 'image/png', 'application/pdf'];

      if (value.mimeType && !_includes(goodMimeTypes, value.mimeType)) {
        return {
          value,
          type: VALIDATION_REGISTRATION_FILE_INVALID_MIME_TYPE
        };
      }

      const maxSize = 1024 * 1024 * config.maxDocumentSizeInMb;

      if (value.size && value.size > maxSize) {
        return {
          value,
          type: VALIDATION_REGISTRATION_FILE_SIZE_TOO_LARGE,
          data: {
            max: maxSize
          }
        };
      }
    }
  };
}

export function genericPdf() {
  return ({ value }) => {
    const regex = /^http[s]?:\/\/.*\.(pdf|PDF)$/;

    if (!isEmpty(value) && !regex.test(value)) {
      return {
        value,
        type: VALIDATION_URL_INVALID
      };
    }
  };
}

export function genericImage() {
  return ({ value }) => {
    const regex = /^http[s]?:\/\/.*\.(png|PNG|jpg|JPG|jpeg|JPEG)$/;

    if (!isEmpty(value) && !regex.test(value)) {
      return {
        value,
        type: VALIDATION_URL_INVALID
      };
    }
  };
}

export function excludeSpecialChars(charsToExclude) {
  return ({ value }) => {
    const regex = new RegExp('^[^' + regexEscape(charsToExclude) + ']+$');

    if (!regex.test(value)) {
      return {
        value,
        type: VALIDATION_FORBIDDEN_CHARACTERS,
        data: {
          chars: charsToExclude.split('').join(' ')
        }
      };
    }
  };
}

export function priceNumber() {
  return ({ value }) => {
    let regex = /^\d+(\.\d{1,2})?$/;
    if (!isEmpty(value) && !regex.test(value)) {
      return {
        value,
        type: VALIDATION_NOT_PRICE_NUMBER
      };
    }
  };
}

export function twitterLink() {
  return ({ value }) => {
    const regex = /^http[s]?:\/\/(www\.)?twitter\.com\/.*$/;

    if (!isEmpty(value) && !regex.test(value)) {
      return {
        value,
        type: VALIDATION_URL_INVALID
      };
    }
  };
}

export function alphanumericPhoneNumber() {
  return ({ value }) => {
    const regex = /^(?=.*[a-zA-Z])([a-zA-Z0-9 ]{1,11})$/;

    if (!isEmpty(value) && !regex.test(value)) {
      return {
        value,
        type: VALIDATION_ALPHANUMERIC_PHONE_NUMBER
      };
    }
  };
}

export function linkedinLink() {
  return ({ value }) => {
    const regex = /^http[s]?:\/\/(www\.)?linkedin\.com\/.*$/;

    if (!isEmpty(value) && !regex.test(value)) {
      return {
        value,
        type: VALIDATION_URL_INVALID
      };
    }
  };
}

export function emailColor() {
  return ({ value }) => {
    const regex = /^#([A-Fa-f0-9]{6})$/;

    if (!isEmpty(value) && !regex.test(value)) {
      return {
        value,
        type: VALIDATION_EMAIL_HEX_COLOR
      };
    }
  };
}

export function emptySlots() {
  return ({ value }) => {
    if (assignErrorsInNestedArray(value, ['comment'])) {
      return assignErrorsInNestedArray(value, ['comment']);
    }
  };
}

function assignErrorsInNestedArray(value, exludedFields = []) {
  if (value && value.length) {
    value.map((item, index) => {
      _forOwn(item, (objValue, key) => {
        if (key !== 'value' && key !== 'type') {
          if (_isArray(objValue)) {
            if (_indexOf(objValue, true) === -1 && objValue.length && !_isObject(objValue[0]))
              value[index][key][0] = {
                value: null,
                type: VALIDATION_FIELD_MISSING
              };
            else value[index][key] = assignErrorsInNestedArray(objValue);
          } else if (!objValue && objValue !== false)
            value[index][key] = {
              objValue,
              type: VALIDATION_FIELD_MISSING
            };
          else if (!objValue.type || _indexOf(exludedFields, key) !== -1) delete value[index][key];
        }
      });
    });

    if (!_isEmpty(_reject(value, _isEmpty))) return value;
  }

  return false;
}

export function validatePriceSlots() {
  const requireFields = (...names) => (data, props) => {
    return names.reduce((errors, name) => {
      const emptyValue = isEmpty(data[name]);

      const isPrice = name === 'price';
      const isDuration = name === 'duration';

      if (props.usage === TIME_SLOT_USAGE_PRIVATE && isPrice && emptyValue) {
        errors[name] = {
          value: data[name],
          type: VALIDATION_FIELD_MISSING
        };
      } else if (props.type === TIME_SLOT_TYPE_PACKAGE_FOR_DURATION && isDuration && emptyValue) {
        errors[name] = {
          value: data[name],
          type: VALIDATION_FIELD_MISSING
        };
      } else if (data[name] === '-' || data[name] === '') {
        errors[name] = {
          value: data[name],
          type: VALIDATION_FIELD_MISSING
        };
      }

      if (!emptyValue && (isPrice || isDuration) && isNaN(data[name])) {
        errors[name] = {
          value: data[name],
          type: VALIDATION_NOT_NUMBER
        };
      }

      if (!emptyValue && isPrice && Number(data[name]) < 0) {
        errors[name] = {
          value: data[name],
          data: { value: '0' },
          type: VALIDATION_MIN_VALUE
        };
      }

      if (!emptyValue && isDuration && Number(data[name]) <= 0) {
        errors[name] = {
          value: data[name],
          type: VALIDATION_NOT_POSITIVE_NUMBER
        };
      }

      let fromDayInt = convertDay2Number(data['fromDayOfWeek']),
        toDayInt = convertDay2Number(data['toDayOfWeek']),
        fromHour = Number(data['fromHour']),
        fromMinutes = Number(data['fromMinutes']),
        toHour = Number(data['toHour']),
        toMinutes = Number(data['toMinutes']);

      if (fromDayInt > toDayInt && toDayInt !== 0) {
        errors['toDayOfWeek'] = {
          value: data['toDayOfWeek'],
          type: VALIDATION_TIMESLOTS_TO_DAY_EARLIER_THAN_START
        };
      }

      if (fromDayInt > 0 && toDayInt === 0) {
        if (toHour > 0) {
          errors['toHour'] = {
            value: toHour,
            type: VALIDATION_TIMESLOTS_TO_HOUR_MUST_BE_ZERO
          };
        }
        if (toMinutes > 0) {
          errors['toHour'] = {
            value: toHour,
            type: VALIDATION_TIMESLOTS_TO_MINUTES_MUST_BE_ZERO
          };
        }
      }

      if (fromDayInt === toDayInt) {
        //check time
        if (fromHour === toHour && fromMinutes === toMinutes) {
          if (data['toDayOfWeek'] !== DAY_OF_WEEK_MONDAY) {
            errors['toMinutes'] = {
              value: toMinutes,
              type: VALIDATION_TIMESLOTS_TO_MINUTES_EARLIER_THAN_FROM_MINUTES
            };
          }
          if (fromHour >= 0 && fromMinutes >= 0) {
            if (!(data['toDayOfWeek'] === DAY_OF_WEEK_MONDAY && fromHour === 0 && fromMinutes === 0)) {
              errors['toMinutes'] = {
                value: toMinutes,
                type: VALIDATION_TIMESLOTS_TO_MINUTES_EQUAL_FROM_MINUTES
              };
            }
          }
        } else {
          if (fromHour > toHour && fromMinutes === toMinutes) {
            errors['toHour'] = {
              value: toHour,
              type: VALIDATION_TIMESLOTS_TO_HOUR_EARLIER_THAN_FROM_HOUR
            };
          }
          if (fromHour === toHour && fromMinutes > toMinutes) {
            errors['toMinutes'] = {
              value: toMinutes,
              type: VALIDATION_TIMESLOTS_TO_MINUTES_EARLIER_THAN_FROM_MINUTES
            };
          }
          if (fromHour > toHour) {
            errors['toHour'] = {
              value: toHour,
              type: VALIDATION_TIMESLOTS_TO_HOUR_EARLIER_THAN_FROM_HOUR
            };
          }
        }
      }

      return errors;
    }, {});
  };

  function convertDay2Number(day) {
    switch (day) {
      case DAY_OF_WEEK_MONDAY:
        return 0;
      case DAY_OF_WEEK_TUESDAY:
        return 1;
      case DAY_OF_WEEK_WEDNESDAY:
        return 2;
      case DAY_OF_WEEK_THURSDAY:
        return 3;
      case DAY_OF_WEEK_FRIDAY:
        return 4;
      case DAY_OF_WEEK_SATURDAY:
        return 5;
      case DAY_OF_WEEK_SUNDAY:
        return 6;
    }
  }

  const validateFields = requireFields(
    'fromDayOfWeek',
    'fromHour',
    'fromMinutes',
    'toDayOfWeek',
    'toHour',
    'toMinutes',
    'price',
    'duration'
  );

  return (data, props = {}) => {
    let DatesSlots = [];
    let errors = {};

    errors.timeSlots = data.timeSlots.map(data => validateFields(data, props));

    for (let dateRange of data.timeSlots) {
      let startDate = moment({ hour: dateRange.fromHour, minute: dateRange.fromMinutes }),
        endDate = moment({ hour: dateRange.toHour, minute: dateRange.toMinutes }),
        targettedStart = convertDay2Number(dateRange.fromDayOfWeek),
        targettedEnd = convertDay2Number(dateRange.toDayOfWeek);

      startDate.add(targettedStart, 'd');
      endDate.add(targettedEnd, 'd');
      dateRange.start = startDate;
      dateRange.end = endDate;
      dateRange.starttime = startDate.format('x');

      if (endDate.isValid() && startDate.isValid()) DatesSlots.push(dateRange);
    }

    let sortedRanges = _sortBy(DatesSlots, ['type', 'tag', 'starttime']);

    let errorsOverlap = overlap(sortedRanges);

    if (errorsOverlap.ranges.length >= 1) {
      return errorsOverlap.ranges.reduce((errors, range) => {
        if (range.current.tag === '' || range.current.tag === undefined) {
          range.current.tag = '';
        }
        if (range.previous.tag === '' || range.previous.tag === undefined) {
          range.previous.tag = '';
        }

        if (range.current.tag === range.previous.tag && range.current.type === range.previous.type) {
          if (range.current._uid === data.timeSlots[data.timeSlots.indexOf(range.current)]._uid) {
            errors.timeSlots[data.timeSlots.indexOf(range.current)] = {
              error: 'overlap',
              type: VALIDATION_TIMESLOTS_OVERLAP
            };
          }

          if (range.previous._uid === data.timeSlots[data.timeSlots.indexOf(range.previous)]._uid) {
            errors.timeSlots[data.timeSlots.indexOf(range.previous)] = {
              error: 'overlap',
              type: VALIDATION_TIMESLOTS_OVERLAP
            };
          }
        }

        return errors;
      }, errors);
    }

    return errors;
  };
}

function overlap(dateRanges) {
  return dateRanges.reduce(
    (result, current, idx, arr) => {
      // no range controls needed for only one entry
      if (arr.length === 1 || idx === 0) {
        return result;
      }
      //get previous ordered slots to compare to
      let previous = arr[idx - 1];
      // set moment to check for any overlap
      let previousStart = moment(previous.start).format('x');
      let previousEnd = moment(previous.end).format('x');
      let currentStart = moment(current.start).format('x');
      let currentEnd = moment(current.end).format('x');

      if (current.tag === '' || current.tag === undefined) {
        current.tag = '';
      }
      if (previous.tag === '' || previous.tag === undefined) {
        previous.tag = '';
      }

      let overlap =
        !(previousStart >= currentEnd) &&
        !(previousEnd <= currentStart) &&
        !(previous._uid === current._uid) &&
        previous.type === current.type &&
        previous.tag === current.tag;

      // exception : from monday midnight to monday midnight
      if (
        ((previousStart === previousEnd && previous.fromDayOfWeek === DAY_OF_WEEK_MONDAY && previous.toDayOfWeek === DAY_OF_WEEK_MONDAY) ||
          (currentStart === currentEnd && current.fromDayOfWeek === DAY_OF_WEEK_MONDAY && current.toDayOfWeek === DAY_OF_WEEK_MONDAY)) &&
        !(previous._uid === current._uid) &&
        previous.type === current.type &&
        previous.tag === current.tag
      ) {
        overlap = true;
      }

      if (
        (previousEnd > currentStart || (currentStart > currentEnd && current.toDayOfWeek !== DAY_OF_WEEK_MONDAY)) &&
        !(previous._uid === current._uid) &&
        previous.type === current.type &&
        previous.tag === current.tag
      ) {
        overlap = true;
      }

      if (
        previousEnd < currentStart &&
        previous.toDayOfWeek === DAY_OF_WEEK_MONDAY &&
        previousStart > currentStart &&
        !(previous._uid === current._uid) &&
        previous.type === current.type &&
        previous.tag === current.tag
      ) {
        overlap = true;
      }

      if (current.type === 'PACKAGE_BETWEEN_DATES') {
        overlap = false;
      }

      if (overlap) {
        result.overlap = true;
        result.ranges.push({
          previous,
          current
        });
      }

      return result;
    },
    { overlap: false, ranges: [] }
  );
}

export function validateCancellationFees() {
  const requireFields = (...names) => data => {
    return names.reduce((errors, name) => {
      if (Number(data['offsetMin']) > Number(data['offsetMax']) && name === 'offsetMax' && data[name] !== undefined) {
        errors[name] = {
          value: name,
          type: VALIDATION_LOWER
        };
      }
      if (data[name] === '-' || data[name] === '') {
        errors[name] = {
          value: name,
          type: VALIDATION_FIELD_MISSING
        };
      }
      if (
        (name === 'rate' && isNaN(Number(data['rate']))) ||
        (name === 'offsetMin' && isNaN(Number(data['offsetMin']))) ||
        (name === 'offsetMax' && isNaN(Number(data['offsetMax'])) && data[name] !== undefined)
      ) {
        errors[name] = {
          value: name,
          type: VALIDATION_NOT_NUMBER
        };
      }

      if ((name === 'rate' && Number(data['rate']) > 100) || Number(data['rate']) < 0) {
        errors[name] = {
          value: name,
          type: VALIDATION_FIELD_NOT_PERCENTAGE
        };
      }

      return errors;
    }, {});
  };

  const validateFields = requireFields('offsetMin', 'offsetMax', 'rate');

  return data => {
    let errors = {};
    errors.rates = data.rates.map(validateFields);

    let sortedRanges = _sortBy(data.rates, ['offsetMin']);

    let errorsOverlap = FeesScale(sortedRanges);

    if (errorsOverlap.ranges.length >= 1) {
      return errorsOverlap.ranges.reduce((errors, range) => {
        if (range.current._uid === data.rates[data.rates.indexOf(range.current)]._uid) {
          errors.rates[data.rates.indexOf(range.current)] = {
            id: range.current.id
          };
        }

        if (range.previous._uid === data.rates[data.rates.indexOf(range.previous)]._uid) {
          errors.rates[data.rates.indexOf(range.previous)] = {
            id: range.previous.id
          };
        }

        return errors;
      }, errors);
    }

    return errors;
  };
}

function FeesScale(ranges) {
  return ranges.reduce(
    (result, current, idx, arr) => {
      // no range controls needed for only one entry
      if (arr.length === 1 || idx === 0) {
        return result;
      }
      //get previous ordered slots to compare to
      let previous = arr[idx - 1];
      // set moment to check for any overlap
      let previousMin = Number(previous.offsetMin);
      let previousMax = Number(previous.offsetMax);
      let currentMin = Number(current.offsetMin);

      let overlap = previousMax > currentMin && previousMin <= currentMin;

      if (overlap) {
        result.overlap = true;
        result.ranges.push({
          previous,
          current
        });
      }

      return result;
    },
    { overlap: false, ranges: [] }
  );
}

export function validateHotlines() {
  return data => {
    const errors = {};
    if (isEmpty(data['name'])) {
      errors.name = {
        value: data[name],
        type: VALIDATION_FIELD_MISSING
      };
    }

    if (!isValidEmail(data['customerServiceEmail'])) {
      errors['customerServiceEmail'] = {
        value: data['customerServiceEmail'],
        type: VALIDATION_EMAIL_INVALID
      };
    }
    if (!isValidEmail(data['noReplyEmail'])) {
      errors['noReplyEmail'] = {
        value: data['noReplyEmail'],
        type: VALIDATION_EMAIL_INVALID
      };
    }

    if (data['phoneNumbers'].length >= 1) {
      errors.phoneNumbers = [];
      data['phoneNumbers'].reduce((errors, hotline, i) => {
        if (!isValidPhoneNumber(hotline.phoneNumber)) {
          errors.phoneNumbers[i] = {
            phoneNumber: {
              value: hotline.phoneNumber,
              type: VALIDATION_FRENCH_PHONE_NUMBER_INVALID
            }
          };
        }
        return errors;
      }, errors);
    }
    return errors;
  };
}
