import React, { Component } from 'react';
import Tooltip from '../../Tooltip/Tooltip';
import { FormattedMessage } from 'react-intl';
import ContentCopy from 'material-ui/svg-icons/content/content-copy';
import None from '../../None';

class CopyToClipBoard extends Component {
  constructor(props) {
    super(props);
    this.state = { copied: false };
    this.keyCopied = props.keyCopied ? props.keyCopied : 'common_copied';
    this.keyCopy = props.keyCopy ? props.keyCopy : 'commom_copy';
  }

  tooltipContent() {
    return <FormattedMessage id={this.state.copied ? this.keyCopied : this.keyCopy} />;
  }

  render() {
    if (!this.props.stringToCopy) {
      return <None />;
    }

    return (
      <Tooltip content={this.tooltipContent()} placement="right">
        <span
          onClick={() => {
            navigator.clipboard.writeText(this.props.stringToCopy);
            this.setState({ copied: true });
          }}
        >
          <ContentCopy style={{ height: '14px !important', cursor: 'pointer' }} />
        </span>
      </Tooltip>
    );
  }
}

CopyToClipBoard.defaultProps = {
  stringToCopy: undefined, //String
  keyCopy: 'common_copy', // String
  keyCopied: 'common_copied' // String
};

export default CopyToClipBoard;
