import * as types from '../constants/actionTypes-constants';
import callApi from './api-actions';
import { trimValues } from '../utils/utils';

export function getHotlines() {
  return (dispatch, getState) => {
    dispatch({
      type: types.HOTLINES_GET_HOTLINES_REQUEST
    });

    return dispatch(callApi('hotlines', 'getHotlines')).then(function(data) {
      dispatch({
        type: types.HOTLINES_GET_HOTLINES_SUCCESS,
        hotlines: data
      });
      return data;
    });
  };
}

export function getHotlineDetail(hotlineId) {
  return dispatch => {
    dispatch({
      type: types.HOTLINES_GET_DETAIL_HOTLINE_REQUEST
    });

    return dispatch(callApi('hotlines', 'getHotlineDetail', hotlineId)).then(function(data) {
      const _phones = data.phoneNumbers.map(item => {
        const { countryCode, nationalNumber, phoneNumberName } = item || {};
        return { phoneNumber: { phonePrefix: countryCode, phoneSuffix: nationalNumber, valid: true }, phoneNumberName };
      });
      data = {
        ...data,
        phoneNumbers: _phones
      };

      dispatch({
        type: types.HOTLINES_GET_DETAIL_HOTLINE_SUCCESS,
        hotline: data
      });
    });
  };
}

export function createHotlineRequest() {
  return (dispatch, getState) => {
    const state = getState();

    const { name, customerServiceName, phoneNumbers, customerServiceEmail, noReplyEmail } = trimValues(state.form.hotlineForm);
    let _phones = [];

    if (phoneNumbers) {
      _phones = phoneNumbers.map(item => {
        const { phoneNumber, phoneNumberName } = item || {};
        const { phonePrefix, phoneSuffix } = phoneNumber || {};
        return { countryCode: phonePrefix, nationalNumber: phoneSuffix, phoneNumberName };
      });
    }

    let params = {
      name,
      customerServiceName,
      customerServiceEmail,
      noReplyEmail,
      phoneNumbers: _phones
    };

    dispatch({
      type: types.HOTLINES_CREATE_HOTLINE_REQUEST,
      params
    });

    return dispatch(callApi('hotlines', 'createHotline', params));
  };
}

export function createHotlineSuccess(hotline) {
  return {
    type: types.HOTLINES_CREATE_HOTLINE_SUCCESS,
    hotline
  };
}

export function updateHotlineRequest(hotlineId) {
  return (dispatch, getState) => {
    const state = getState();

    const { name, customerServiceName, phoneNumbers, customerServiceEmail, noReplyEmail } = trimValues(state.form.hotlineForm);

    const _phones = phoneNumbers.map(item => {
      const { phoneNumber, phoneNumberName } = item || {};
      const { phonePrefix, phoneSuffix } = phoneNumber || {};
      return { countryCode: phonePrefix, nationalNumber: phoneSuffix, phoneNumberName };
    });

    let params = {
      name,
      customerServiceName,
      customerServiceEmail,
      noReplyEmail,
      phoneNumbers: _phones
    };

    dispatch({
      type: types.HOTLINES_UPDATE_HOTLINE_REQUEST,
      params
    });

    return dispatch(callApi('hotlines', 'updateHotline', { hotlineId, params }));
  };
}

export function updateHotlineSuccess(color) {
  return {
    type: types.HOTLINES_UPDATE_HOTLINE_SUCCESS,
    color
  };
}
