module.exports={
  "cs-i18n.json": "cs-i18n-cf10508b90.json",
  "da-i18n.json": "da-i18n-ee1d0839ae.json",
  "de-i18n.json": "de-i18n-b0d9eae69f.json",
  "en-i18n.json": "en-i18n-3588e3da17.json",
  "es-i18n.json": "es-i18n-6f097b082c.json",
  "fr-i18n.json": "fr-i18n-255810d1ce.json",
  "it-i18n.json": "it-i18n-1a4eb37542.json",
  "nl-i18n.json": "nl-i18n-28cdb63caa.json",
  "pl-i18n.json": "pl-i18n-e70b37903e.json",
  "pt-i18n.json": "pt-i18n-d6c5df586e.json",
  "pt_BR-i18n.json": "pt_BR-i18n-b94b617067.json",
  "sk-i18n.json": "sk-i18n-d2fb7f60a4.json",
  "sv-i18n.json": "sv-i18n-4fb43446c7.json"
}