import React, { Component, PropTypes } from 'react';
import { FormattedMessage } from 'react-intl';
import { reduxForm } from 'redux-form';

import FormRow from '../../../../components/FormRow/FormRow';
import BoxedInput from '../../../../components/BoxedInput/BoxedInput';
import EkButton from '../../../../components/EkButton/EkButton';
import FilterIcon from 'material-ui/svg-icons/content/filter-list';

import { scrollToFirstError } from '../../../../utils/utils';

class BookingsSearch extends Component {
  render() {
    const {
      fields: { memberFirstName, memberLastName, memberLogin, voucherCode, startDate, endDate, bookingId, vehicleRegistrationNumber },
      handleSubmit,
      onResetCallBack
    } = this.props;

    return (
      <section className="searchBookingsWrapper">
        <form action="#" className="searchBookingsForm" onSubmit={handleSubmit(this.props.onCallback)}>
          <div className="filters">
            <FilterIcon />
            <span style={{ verticalAlign: 'super' }} children={<FormattedMessage id="common_bookings" />} />
          </div>
          <FormRow>
            <div>
              {startDate && (
                <BoxedInput
                  customClass="searchBookingsForm_inputWrapper"
                  labelKey="common_start_date"
                  id="startDate"
                  type="date"
                  field={startDate}
                />
              )}
              {endDate && (
                <BoxedInput
                  customClass="searchBookingsForm_inputWrapper"
                  labelKey="common_end_date"
                  id="endDate"
                  type="date"
                  field={endDate}
                />
              )}
              {bookingId && (
                <BoxedInput
                  customClass="searchBookingsForm_inputWrapper"
                  labelKey="bookings_tableView_label_bookingId"
                  placeholderKey="bookings_tableView_label_bookingId"
                  id="bookingId"
                  field={bookingId}
                />
              )}
              {vehicleRegistrationNumber && (
                <BoxedInput
                  customClass="searchBookingsForm_inputWrapper"
                  labelKey="booking_detail_vehicle_registration_number"
                  placeholderKey="booking_detail_vehicle_registration_number"
                  id="vehicleRegistrationNumber"
                  field={vehicleRegistrationNumber}
                />
              )}
            </div>
          </FormRow>
          <FormRow>
            <div>
              {memberFirstName && (
                <BoxedInput
                  type="text"
                  id="memberFirstName"
                  labelKey="common_placeHolder_searchForMemberFirstName"
                  placeholderKey="common_placeHolder_searchForMemberFirstName"
                  customClass="searchBookingsForm_inputWrapper"
                  field={memberFirstName}
                />
              )}
              {memberLastName && (
                <BoxedInput
                  type="text"
                  id="memberLastName"
                  labelKey="common_placeHolder_searchForMemberLastName"
                  placeholderKey="common_placeHolder_searchForMemberLastName"
                  customClass="searchBookingsForm_inputWrapper"
                  field={memberLastName}
                />
              )}
              {memberLogin && (
                <BoxedInput
                  type="text"
                  id="memberLogin"
                  labelKey="common_placeHolder_searchForMemberEmail"
                  placeholderKey="common_placeHolder_searchForMemberEmail"
                  customClass="searchBookingsForm_inputWrapper"
                  field={memberLogin}
                />
              )}
              {voucherCode && (
                <BoxedInput
                  type="text"
                  id="voucherCode"
                  labelKey="common_searchFor_voucherCode"
                  placeholderKey="common_searchFor_voucherCode"
                  customClass="searchBookingsForm_inputWrapper"
                  field={voucherCode}
                />
              )}
            </div>
          </FormRow>

          <div className="searchBookingsForm_actions">
            <EkButton onAction={onResetCallBack} customClass="searchBookingsForm_actionsButton" skinType="reverse" type="button">
              <FormattedMessage id="searchBookingsForm_reset_button" />
            </EkButton>

            <EkButton onAction={handleSubmit(this.props.onCallback)} customClass="searchBookingsForm_actionsButton">
              <FormattedMessage id="searchBookingsForm_search_button" />
            </EkButton>
          </div>
        </form>
      </section>
    );
  }
}

BookingsSearch.displayName = 'BookingsSearch';

BookingsSearch.propTypes = {
  fields: PropTypes.object,
  handleSubmit: PropTypes.func,
  onCallback: PropTypes.func,
  onResetCallBack: PropTypes.func.isRequired
};

const defaultFields = [
  'memberFirstName',
  'memberLastName',
  'memberLogin',
  'voucherCode',
  'startDate',
  'endDate',
  'bookingId',
  'vehicleRegistrationNumber'
];

export default reduxForm({
  onSubmitFail: scrollToFirstError,
  form: 'BookingsSearch',
  fields: defaultFields
})(BookingsSearch);
