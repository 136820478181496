import React, { Component, PropTypes } from 'react';
import ListModal from '../../components/_v2/ColumnList/Modal';
import AddCompany from './AddCompany';
import { getAddModalTitle } from '../../components/_v2/ColumnList/helpers';
import { getMsg } from '../../utils/IntlGlobalProvider';

export class AddCompanyModal extends Component {
  getTitle() {
    return getAddModalTitle(getMsg('common_super_company'));
  }

  render() {
    return (
      <ListModal title={this.getTitle()} isOpen={this.props.isOpen} onClose={this.props.onClose}>
        <AddCompany
          isModal
          onCancel={this.props.onClose}
          onSubmit={this.props.onClose}
          initialValues={this.props.initialValues}
          readOnlyBrands={this.props.readOnlyBrands}
        />
      </ListModal>
    );
  }
}

AddCompanyModal.propTypes = {
  initialValues: PropTypes.object,
  readOnlyBrands: PropTypes.bool,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func
};

export default AddCompanyModal;
