import React, { Component, PropTypes } from 'react';
import { FormattedMessage } from 'react-intl';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';

import FormRow from '../FormRow/FormRow';
import BoxedInput from '../BoxedInput/BoxedInput';
import EkButton from '../EkButton/EkButton';
import AddressAutocomplete from '../AddressAutocomplete/AddressAutocomplete';
import PhoneNumberField from '../PhoneNumberField/PhoneNumberField';
import FieldErrorMsg from '../FieldErrorMsg/FieldErrorMsg';
import { createValidator, notEmpty, checkPhoneNumber, address, length } from '../../validation/all-validation';
import { scrollToFirstError } from '../../utils/utils';
import _get from 'lodash/get';

class ProfileForm extends Component {
  render() {
    const {
      fields: { firstName, lastName, address, phoneNumber, secondaryPhoneNumber, email },
      handleSubmit
    } = this.props;

    return (
      <div className="accountPage_form">
        <form className="profileForm" method="post" action="#" onSubmit={handleSubmit(this.props.onCallback)} noValidate>
          <section className="profileForm_section">
            <div className="profileForm_container">
              <FormRow customClass="profileForm_row">
                <BoxedInput
                  formRowItemKey="profileForm_firstName"
                  type="text"
                  id="firstName"
                  placeholderKey="common_placeHolder_yourFirstName"
                  labelKey="profileForm_firstName_label"
                  customClass="profileForm_boxedInput"
                  field={firstName}
                >
                  <FieldErrorMsg field={firstName} customClass="fieldErrorMsg--profileForm" />
                </BoxedInput>

                <BoxedInput
                  formRowItemKey="profileForm_lastName"
                  type="text"
                  id="lastName"
                  placeholderKey="common_placeHolder_yourLastName"
                  labelKey="profileForm_lastName_label"
                  customClass="profileForm_boxedInput"
                  field={lastName}
                >
                  <FieldErrorMsg field={lastName} customClass="fieldErrorMsg--profileForm" />
                </BoxedInput>
              </FormRow>

              <FormRow customClass="profileForm_row formRow--halfWidth">
                <BoxedInput
                  formRowItemKey="profileForm_email"
                  type="text"
                  id="email"
                  labelKey="profileForm_email_label"
                  customClass="profileForm_boxedInput"
                  field={email}
                  readOnly
                />
              </FormRow>

              <FormRow customClass="profileForm_row">
                <label formRowItemKey="profileForm_address_label" className="boxedInput_label">
                  <span className="boxedInput_labelText">
                    <FormattedMessage id="profileForm_address_label" />
                  </span>
                  <AddressAutocomplete field={address} className="profileForm_AddressAutocomplete" />

                  <FieldErrorMsg field={address} customClass="fieldErrorMsg--profileForm" />
                </label>
              </FormRow>

              <FormRow customClass="profileForm_row">
                <PhoneNumberField
                  id="phoneNumber"
                  className="boxedInputWrapper"
                  label={<FormattedMessage id="profileForm_mobilePhone_label" />}
                  field={phoneNumber}
                />

                <PhoneNumberField
                  id="phoneNumber"
                  className="boxedInputWrapper"
                  label={<FormattedMessage id="profileForm_phone_label" />}
                  field={secondaryPhoneNumber}
                />
              </FormRow>
            </div>
          </section>

          <FormRow customClass="profileForm_row">
            <EkButton
              formRowItemKey="profileForm_submitBtn"
              type="submit"
              customClass="profileForm_actionsButton"
              onAction={handleSubmit(this.props.onCallback)}
            >
              <FormattedMessage id="account_profile_save" />
            </EkButton>
          </FormRow>
        </form>
      </div>
    );
  }
}

ProfileForm.displayName = 'ProfileForm';

ProfileForm.propTypes = {
  action: PropTypes.func,
  fields: PropTypes.object,
  onCallback: PropTypes.func,
  handleSubmit: PropTypes.func
};

ProfileForm = reduxForm(
  {
    onSubmitFail: scrollToFirstError,
    form: 'editProfile',
    fields: ['firstName', 'lastName', 'address', 'phoneNumber', 'secondaryPhoneNumber', 'email'],
    validate: createValidator({
      firstName: [notEmpty(), length({ min: 3, max: 25 })],
      lastName: [notEmpty(), length({ min: 3, max: 25 })],
      address: [address(), length({ max: 80 })],
      phoneNumber: [checkPhoneNumber()]
    })
  },
  state => {
    // mapStateToProps
    return {
      initialValues: {
        firstName: state.user.userInfo.firstName,
        lastName: state.user.userInfo.lastName,
        address: state.user.userInfo.address,
        phoneNumber: {
          phonePrefix: _get(state, 'user.userInfo.phoneNumber.countryCode'),
          phoneSuffix: _get(state, 'user.userInfo.phoneNumber.nationalNumber'),
          valid: true
        },
        secondaryPhoneNumber: {
          phonePrefix: _get(state, 'user.userInfo.secondaryPhoneNumber.countryCode'),
          phoneSuffix: _get(state, 'user.userInfo.secondaryPhoneNumber.nationalNumber'),
          valid: true
        },
        email: state.user.userInfo.login
      }
    };
  }
)(ProfileForm);

export default connect(state => {
  const {
    user: { userInfo },
    i18n: { locale }
  } = state;
  return {
    userInfo,
    locale
  };
})(ProfileForm);
