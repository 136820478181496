import React, { Component, PropTypes } from 'react';
import { FormattedMessage } from 'react-intl';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';

import FormRow from '../FormRow/FormRow';
import FieldErrorMsg from '../FieldErrorMsg/FieldErrorMsg';
import BoxedInput from '../BoxedInput/BoxedInput';
import EkButton from '../EkButton/EkButton';
import BoxedSelect from '../BoxedSelect/BoxedSelect';
import ButtonUpload from '../ButtonUpload/ButtonUpload';
import { createValidator, fileValidation, notEmpty } from '../../validation/all-validation';
import { displayDamageImage, removeImage } from '../../actions/all-actions';
import { allDamagesTypes, allDamagesAreas } from '../../constants/filters-constants';
import {
  VEHICLE_DAMAGE_FORM_UPDATE_DAMAGE_IMAGE_SUCCESS,
  VEHICLE_DAMAGE_FORM_UPDATE_DAMAGE_IMAGE_REMOVE
} from '../../constants/actionTypes-constants';
import { scrollToFirstError } from '../../utils/utils';
import FileImage from '../FileImage/FileImage';

class VehicleAddDamageForm extends Component {
  constructor() {
    super();

    this.handleChangeDamageImage = this.handleChangeDamageImage.bind(this);
  }

  componentWillUnmount() {
    this.props.dispatch(removeImage(VEHICLE_DAMAGE_FORM_UPDATE_DAMAGE_IMAGE_REMOVE));
  }

  handleChangeDamageImage(fileData) {
    const { dispatch } = this.props;

    if (fileData) {
      dispatch(displayDamageImage(fileData, VEHICLE_DAMAGE_FORM_UPDATE_DAMAGE_IMAGE_SUCCESS));
    }
  }

  render() {
    const {
      fields: { vehicleRegistrationNumber, area, type, comment, damageFile },
      currentImage,
      handleSubmit
    } = this.props;

    return (
      <section className="vehicleAddDamageFormWrapper">
        <form action="#" className="vehicleAddDamageForm" onSubmit={handleSubmit(this.props.onCallback)}>
          <div className="vehicleAddDamageForm_container">
            <h4 className="vehicleAddDamageForm_title">
              <FormattedMessage id="vehicleAddDamageForm_title" />
            </h4>

            <FormRow customClass="vehicleAddDamageForm_row">
              <BoxedInput
                formRowItemKey="vehicleAddDamageForm_licencePlate"
                id="licencePlate"
                customClass="boxedInput_inputText"
                labelKey="vehicleAddDamageForm_licencePlate"
                type="text"
                field={vehicleRegistrationNumber}
              >
                <FieldErrorMsg field={vehicleRegistrationNumber} customClass="fieldErrorMsg--vehicleForm" />
              </BoxedInput>
            </FormRow>

            <FormRow customClass="vehicleAddDamageForm_row">
              <BoxedSelect
                customClass="vehicleAddDamageForm_boxedSelect"
                labelKey="vehicleAddDamageForm_damageArea"
                options={allDamagesAreas}
                field={area}
              >
                <FieldErrorMsg field={area} customClass="fieldErrorMsg--vehicleForm" />
              </BoxedSelect>

              <BoxedSelect
                customClass="vehicleAddDamageForm_boxedSelect"
                labelKey="vehicleAddDamageForm_damageType"
                customLabelClass="test"
                options={allDamagesTypes}
                field={type}
              >
                <FieldErrorMsg field={type} customClass="fieldErrorMsg--vehicleForm" />
              </BoxedSelect>
            </FormRow>

            <FormRow customClass="vehicleAddDamageForm_row">
              <BoxedInput
                formRowItemKey="vehicleAddDamageForm_comment"
                id="comment"
                customClass="boxedInput_inputText"
                customInputClass="boxedInput_inputSmallTextArea"
                labelKey="vehicleAddDamageForm_comment"
                type="textarea"
                field={comment}
              />
            </FormRow>

            <FormRow customWrapperClass="vehicleAddDamageForm_form" customClass="vehicleAddDamageForm_row">
              <ButtonUpload
                formRowItemKey="vehicleForm_driverLicenceUploadBtn"
                id="registrationFile"
                customClass="vehicleForm_buttonUpload"
                buttonLabel="vehicleAddDamageForm_fileUpload_label"
                label="vehicleAddDamageForm_damageFile_label"
                accept="image/*"
                field={damageFile}
                onChange={this.handleChangeDamageImage}
              >
                <FieldErrorMsg field={damageFile} customClass="fieldErrorMsg--vehicleForm" />
              </ButtonUpload>
              {currentImage ? <FileImage imageObject={currentImage} imageStyle={{ maxWidth: '200px', maxHeight: '120px' }} /> : null}
            </FormRow>

            <FormRow customClass="vehicleAddDamageForm_row">
              <EkButton
                type="button"
                skinType="reverse"
                formRowItemKey="vehicleAddDamageForm_abortBtn"
                customClass="vehicleAddDamage_button"
                onAction={this.props.onAbort}
              >
                <FormattedMessage id="vehicleAddDamageForm_abort_button" />
              </EkButton>

              <EkButton
                type="button"
                formRowItemKey="vehicleAddDamageForm_submitBtn"
                customClass="vehicleAddDamage_button"
                onAction={handleSubmit(this.props.onCallback)}
              >
                <FormattedMessage id="vehicleAddDamageForm_save_button" />
              </EkButton>
            </FormRow>
          </div>
        </form>
      </section>
    );
  }
}

VehicleAddDamageForm.displayName = 'VehicleAddDamageForm';

VehicleAddDamageForm.propTypes = {
  fields: PropTypes.object,
  currentImage: PropTypes.object,
  handleSubmit: PropTypes.func,
  onCallback: PropTypes.func,
  onAbort: PropTypes.func
};

VehicleAddDamageForm = reduxForm({
  onSubmitFail: scrollToFirstError,
  form: 'vehicleAddDamageForm',
  fields: ['vehicleRegistrationNumber', 'area', 'type', 'comment', 'damageFile'],
  validate: createValidator({
    vehicleRegistrationNumber: [notEmpty()],
    area: [notEmpty()],
    type: [notEmpty()]
  })
})(VehicleAddDamageForm);

export default connect(state => {
  const {} = state;
  return {};
})(VehicleAddDamageForm);
