import React, { Component } from 'react';
import { connect } from 'react-redux';
import { enhanceHomepageData, addErrorMessage, mapOrder, safe, testProp } from '../../../../utils/utils';
import Add from 'material-ui/svg-icons/content/add';
import classnames from 'classnames';
import shortid from 'shortid';
import Delete from 'material-ui/svg-icons/action/delete';
import { showSuccessMsg } from '../../../../actions/flashMessage-actions';
import { IconButton } from 'material-ui';
import _cloneDeep from 'lodash/cloneDeep';
import {
  currentContractReferenceObjectSelector,
  currentCompanyContractSelector,
  currentCompanyIdSelector
} from '../../../../selectors/all-selectors';
import Sortable from 'react-sortablejs';
import { requestSaveCompanyContract } from '../../../../actions/companies-actions';
import HomepageActionsModal from '../../../../components/_v2/HomepageSettings/HomepageActionsModal';
import HomepageSettingsForm from '../../../../components/_v2/HomepageSettings/HomepageSettingsForm';
import { MOBILE_ACTIONS } from '../../../../constants/backend-constants';
import { getMsg } from '../../../../utils/IntlGlobalProvider';
import { FormattedMessage } from 'react-intl';
import memoizeOne from 'memoize-one';

class HomepageSettings extends Component {
  componentWillMount() {
    this.initState();
    this.bindActions();
    this.setCallbacks();
    this.initProps();
  }

  initState() {
    this.state = {
      editService: null,
      hpServices: [],
      openActions: false,
      preview: true
    };
  }

  initProps() {
    this.componentPropsUpdated(this.props);
    this.propsInit = true;
  }

  componentWillReceiveProps(nextProps) {
    this.componentPropsUpdated(nextProps);
  }

  componentPropsUpdated(props) {
    const { services } = props;

    if (testProp.call(this, props, { services })) {
      if (services) {
        this.setState({ hpServices: _cloneDeep(services) });
      } else {
        this.setState({ hpServices: [] });
      }
    }
  }

  bindActions() {
    this.addItem = this.addItem.bind(this);
    this.onChangeOrder = this.onChangeOrder.bind(this);
    this.openActionsForm = this.openActionsForm.bind(this);
    this.handlePopulate = this.handlePopulate.bind(this);
    this.closeActionsForm = this.closeActionsForm.bind(this);
    this.hideForm = this.hideForm.bind(this);
    this.onUpdateAction = this.onUpdateAction.bind(this);
  }

  setCallbacks() {
    this.getPreviewItems = memoizeOne(hpServices => {
      return hpServices.map(item => {
        const title = safe(() => item.title.en) || 'no translation';
        const sized = safe(() => item.options.size) || 'default';
        let imageStyled = item.imageUrl ? { backgroundImage: `url(${item.imageUrl})`, backgroundSize: 'cover' } : { color: '#000' };

        return (
          <div key={'preview-' + item.id} data-id={'preview--' + item.id} className={classnames('plus service', sized)}>
            <div className="d" style={imageStyled}>
              {title}
            </div>
          </div>
        );
      });
    });

    this.getMainItems = memoizeOne(hpServices => {
      return hpServices.map((item, index) => {
        const title = item.title && item.title.en ? item.title.en : 'no translation';
        const sized = item.options && item.options.size ? item.options.size : 'default';

        return (
          <div key={item.id} data-id={item.id} className={classnames('plus service', sized)}>
            <div className={classnames('d hovereffect', sized)} onClick={() => this.editItem(index)}>
              <div className="overlay">
                <h2>{title}</h2>
                <div className="help-tile">
                  <div>
                    <p>{getMsg('mobile.homepage.help-short-drag')}</p>
                    <p>{getMsg('mobile.homepage.help-short-edit')}</p>
                  </div>
                  <div>
                    <IconButton
                      tooltip={
                        <span>
                          {getMsg('common_delete')} {title}
                        </span>
                      }
                      tooltipPosition="top-left"
                      className="delete-handler"
                      onClick={() => this.removeItem(index)}
                    >
                      <Delete />
                    </IconButton>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      });
    });
  }

  removeItem(i) {
    let { dispatch, companyId, contract, reference } = this.props;
    const services = this.state.hpServices.filter((item, j) => i !== j);

    reference = { ...reference, services };
    reference = JSON.stringify(reference);

    const values = { ...contract, reference };

    dispatch(requestSaveCompanyContract({ companyId, values, loader: false })).then(
      () => {
        dispatch(showSuccessMsg({ key: 'common_success', content: getMsg('mobile.homepage.item.removed'), delay: true }));
        this.setState({ hpServices: services });
      },
      error => dispatch(addErrorMessage({ error }))
    );
  }

  editItem(index) {
    const editService = this.state.hpServices.filter((item, j) => index === j);
    this.setState({ editService: enhanceHomepageData(editService[0]) });
  }

  addItem() {
    let { dispatch, contract, companyId, reference } = this.props;

    const newService = {
      title: {},
      description: {},
      options: { size: 'primary' },
      action: { title: {}, type: MOBILE_ACTIONS.DEEPLINK },
      id: shortid()
    };

    const services = this.state.hpServices.concat(newService);

    reference = { ...reference, services };
    reference = JSON.stringify(reference);

    const values = { ...contract, reference };

    dispatch(requestSaveCompanyContract({ companyId, values, loader: false })).then(
      () => {
        dispatch(showSuccessMsg({ key: 'common_success', content: getMsg('mobile.homepage.item.added'), delay: true }));
        this.setState({ hpServices: services });
      },
      error => dispatch(addErrorMessage({ error }))
    );
  }

  onChangeOrder(order) {
    let { dispatch, contract, companyId, reference } = this.props;
    const services = mapOrder(this.state.hpServices, order, 'id');

    this.setState({ hpServices: services });

    reference = { ...reference, services };
    reference = JSON.stringify(reference);

    const values = { ...contract, reference };

    dispatch(requestSaveCompanyContract({ companyId, values, loader: false })).then(
      () => {
        dispatch(showSuccessMsg({ key: 'common_success', content: getMsg('mobile.homepage.item.reordered'), delay: true }));
      },
      error => dispatch(addErrorMessage({ error }))
    );
  }

  openActionsForm() {
    this.setState({ openActions: true });
  }

  handlePopulate(values) {
    this.setState(state => {
      return {
        editService: {
          ...state.editService,
          ...values
        }
      };
    });
  }

  closeActionsForm() {
    this.setState({ openActions: false });
  }

  hideForm() {
    this.setState({ editService: null });
  }

  onUpdateAction(data) {
    this.setState(state => {
      return {
        editService: {
          ...state.editService,
          action: data.action
        }
      };
    });
    this.props.onCallback(data);
  }

  helpMsg() {
    return (
      <div className="plus is-not-draggable help">
        <FormattedMessage
          id="mobile.homepage.help"
          values={{
            sign: <Add key="help" style={{ height: '30px', width: '30px', verticalAlign: ' middle' }} />
          }}
        />
      </div>
    );
  }

  displayPreview() {
    const { logoUrl, isOpen } = this.props;
    const logo = logoUrl ? <img src={logoUrl} alt="" /> : getMsg('mobile.homepage.logo');

    return (
      <div id="preview-tiles" className={classnames('hs-preview-wrapper', { less: isOpen })}>
        <div className="preview-label">{getMsg('mobile.homepage.preview')}</div>
        <div className="iphone-x">
          <div className="heading">
            <i>Speaker</i>
            <b>Camera</b>
          </div>
          <div className="logo">{logo}</div>
          {this.getPreviewItems(this.state.hpServices)}
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className="homepageSettings-page">
        <div className="hs-container">
          {!this.props.limitedAccess && (
            <div id="items">
              <Sortable
                className="hs-wrapper"
                options={{
                  ghostClass: 'ghost',
                  animation: 150,
                  chosenClass: 'chosen',
                  forceFallback: true,
                  fallbackOnBody: false
                }}
                onChange={order => {
                  this.onChangeOrder(order);
                }}
              >
                {this.getMainItems(this.state.hpServices)}
                <div className="plus is-not-draggable" onClick={this.addItem}>
                  <Add style={{ height: '50px', width: '50px' }} />
                </div>
                {this.helpMsg()}
              </Sortable>
            </div>
          )}
          {!this.props.limitedAccess && this.state.editService && (
            <HomepageSettingsForm
              initialValues={this.state.editService}
              onCallback={this.props.onCallback}
              onClose={this.hideForm}
              onOpenActionForm={this.openActionsForm}
              onPopulate={this.handlePopulate}
            />
          )}
          {!this.props.limitedAccess && this.state.editService && (
            <HomepageActionsModal
              name={this.state.editService.name || 'default'}
              isOpen={this.state.openActions}
              onClose={this.closeActionsForm}
              initialValues={this.state.editService}
              onCallback={this.onUpdateAction}
            />
          )}
          {this.state.preview && !this.state.editService && this.state.hpServices.length > 0 && this.displayPreview()}
        </div>
      </div>
    );
  }
}

HomepageSettings = connect(state => {
  const headerContractReference = currentContractReferenceObjectSelector(state);
  const { logoUrl } = state.companies.currentCompanyDetails;
  const { isOpen } = state.sideMenu;

  return {
    companyId: currentCompanyIdSelector(state),
    contract: currentCompanyContractSelector(state),
    reference: headerContractReference,
    services: headerContractReference.services,
    logoUrl,
    isOpen
  };
})(HomepageSettings);

export default HomepageSettings;
