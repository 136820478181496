import React, { Component } from 'react';

import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { getAppFormattedDateTime, getErrorMsg, getKey, namedCompose, newProps } from '../../utils/utils';

import BackLink from '../../components/BackLink/BackLink';
import routes from '../../constants/routes-constants';
import { bookingEventStatuses, bookingEventTypes } from '../../constants/options-constants';
import { bundleSelector, localeSelector } from '../../selectors/all-selectors';
import { BOOKING_EVENT_STATUSES } from '../../constants/backend-constants';

class BookingEventsHistory extends Component {
  constructor(props) {
    super(props);
    this.setMemberLink = this.setMemberLink.bind(this);
    this.setBackLink(props.params.bookingId);
    this.includeErrorMsgCol = false;

    this.statusClassNames = {
      good: 'event-status-good',
      error: 'event-status-error',
      other: 'event-status-other'
    };

    this.eventStatusClasses = {
      [BOOKING_EVENT_STATUSES.TECHNICAL_ERROR]: this.statusClassNames.error,
      [BOOKING_EVENT_STATUSES.BUSINESS_ERROR]: this.statusClassNames.error,
      [BOOKING_EVENT_STATUSES.SUCCESS]: this.statusClassNames.good
    };

    this.derivedStateFromProps(props, true);
  }

  componentWillReceiveProps(nextProps) {
    this.derivedStateFromProps(nextProps);
  }

  derivedStateFromProps(nextProps, init) {
    this.showNoDataMsg = nextProps.bookingEvents.length === 0;
    this.setBookingEvents(nextProps, init);
  }

  setBackLink(bookingId) {
    this.backToListURL = '/#' + routes.bookingDetail.path.replace(':bookingId', bookingId);
  }

  setMemberLink(memberId, memberEmail) {
    return <a href={'/#' + routes.editBackUser.path.replace(':backUserId', memberId)}>{memberEmail}</a>;
  }

  getErrorMsg(event = {}, nextProps) {
    const { errorCode, errorMessage } = event;
    const { bundle } = nextProps;
    const errorParams = { error: { developerMessage: errorMessage, errorCode }, bundle, def: '' };
    let msg = getErrorMsg(errorParams);
    msg = msg || errorMessage;

    if (msg) {
      this.includeErrorMsgCol = true;
      return <td>{msg}</td>;
    } else return <td />;
  }

  getErrorClass(eventStatus) {
    return this.eventStatusClasses[eventStatus] || this.statusClassNames.other;
  }

  eventsPredicate(event, index, nextProps) {
    const { eventDate, eventType, eventSource, eventCreatorEmail, eventStatus, eventId } = event;
    const key = eventId || index;

    return (
      <tr key={key}>
        <td>
          <div className="td-inner">{getAppFormattedDateTime(eventDate)}</div>
        </td>
        <td>
          <div className="td-inner">
            <FormattedMessage id={getKey(bookingEventTypes, eventType)} />
          </div>
        </td>
        <td>
          <div className="td-inner">{eventSource}</div>
        </td>
        <td>
          <div className="td-inner">{eventCreatorEmail}</div>
        </td>
        <td className={this.getErrorClass(eventStatus)}>
          <div className="td-inner">
            <FormattedMessage id={getKey(bookingEventStatuses, eventStatus)} />
          </div>
        </td>
        {this.getErrorMsg(event, nextProps)}
      </tr>
    );
  }

  setBookingEvents(nextProps, init) {
    const eventsName = 'bookingEvents';
    const propsChanged = newProps(this.props, nextProps, init, [eventsName, 'locale']);

    if (propsChanged) {
      const eventsPredicate = (event = {}, index) => this.eventsPredicate(event, index, nextProps);
      this.bookingEvents = nextProps[eventsName].map(eventsPredicate);
    }
  }

  getBookingEvents() {
    return this.bookingEvents;
  }

  getTableBody() {
    return <tbody>{this.getBookingEvents()}</tbody>;
  }

  getErrorMsgCell(msg) {
    if (this.includeErrorMsgCol) {
      return this.getHeadCell(msg);
    }
  }

  getHeadCell(msg) {
    return <th>{msg}</th>;
  }

  getTableHead() {
    return (
      <thead>
        <tr>
          {this.getHeadCell(<FormattedMessage id="common_date" />)}
          {this.getHeadCell(<FormattedMessage id="common_action" />)}
          {this.getHeadCell(<FormattedMessage id="booking_status_origin" />)}
          {this.getHeadCell(<FormattedMessage id="members_detail_enterprise_email" />)}
          {this.getHeadCell(<FormattedMessage id="smartcards_events_tableView_label_result" />)}
          {this.getErrorMsgCell(<FormattedMessage id="common_description" />)}
        </tr>
      </thead>
    );
  }

  showInnerPage() {
    if (this.showNoDataMsg) {
      return (
        <div className="page-info-block">
          <FormattedMessage id="common_no_data_available" />
        </div>
      );
    }

    return (
      <div className="booking-events-list">
        <div className="table-shadow">
          <table>
            {this.getTableHead()}
            {this.getTableBody()}
          </table>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className="pageContainer">
        <BackLink link={this.backToListURL} labelKey="back_link_booking_details" />
        {this.showInnerPage()}
      </div>
    );
  }
}

export default namedCompose(
  connect(state => {
    const {
      bookings: { bookingEvents }
    } = state;

    return { bookingEvents, bundle: bundleSelector(state), locale: localeSelector(state) };
  }),
  injectIntl
)(BookingEventsHistory);
