/* eslint-disable react/no-set-state */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import routes, { companyRules, subCompanyRules } from '../../constants/routes-constants';
import { namedCompose } from '../../utils/utils';
import autoBind from 'react-autobind';
import { apiParams } from '../../constants/api-params-constants';
import {
  getBankoutDetail,
  retryBankoutExecutionAction,
  exportBankout,
  clearBankoutDetails,
  pollingBankoutExport,
  exporting
} from '../../actions/bankout-actions';
import BankoutDetails from '../../components/BankoutDetails/BankoutDetails';
import BackLink from '../../components/BackLink/BackLink';
import PaybackList from '../../components/PaybackList/PaybackList';
import Measure from 'react-measure';
import { checkRole } from '../../constants/backuser-role-rules';
import { userRoleSelector } from '../../selectors/all-selectors';

class BankoutView extends Component {
  constructor(props) {
    super(props);
    autoBind(this);

    this.state = {
      detailsSize: {
        height: 0,
        width: 0
      }
    };
    const encodedUrlParams = encodeURIComponent(JSON.stringify(props.bankoutParams || apiParams.default));
    this.backToListUrl = '/#' + routes.bankouts.path.replace(':search', encodedUrlParams);

    this.canSeeSubCompany = checkRole(subCompanyRules, props.role);
    this.canSeeSuperCompany = checkRole(companyRules, props.role);
  }

  componentWillMount() {
    const { dispatch, params } = this.props;
    const { bankoutId } = params || {};

    dispatch(getBankoutDetail(bankoutId)).then(() => {
      this.exportBankoutId = window.localStorage.getItem('bankoutIdExport' + bankoutId);
      if (this.exportBankoutId) {
        dispatch(exporting(bankoutId));
        dispatch(pollingBankoutExport(this.exportBankoutId));
      }
    });
  }

  componentWillUnmount() {
    this.props.dispatch(clearBankoutDetails());
  }

  handleBankoutRetry() {
    const { dispatch, params } = this.props;
    const { bankoutId } = params || {};

    dispatch(retryBankoutExecutionAction(bankoutId, true)).then(
      () => dispatch(getBankoutDetail(bankoutId, true)),
      () => ''
    );
  }

  handleBankoutExport() {
    const { dispatch, params } = this.props;
    const { bankoutId } = params || {};
    dispatch(exportBankout(bankoutId));
  }

  displayDetails({ measureRef }) {
    return (
      <div ref={measureRef}>
        <BankoutDetails
          bankout={this.props.bankoutDetails}
          retryAction={this.handleBankoutRetry}
          exportAction={this.handleBankoutExport}
          formatMessage={this.props.intl.formatMessage}
          canSeeSuperCompany={this.canSeeSuperCompany}
          canSeeSubCompany={this.canSeeSubCompany}
        />
      </div>
    );
  }

  handleDetailsResize(contentRect) {
    this.setState({ detailsSize: contentRect.scroll });
  }

  render() {
    return (
      <div className="pageContainer">
        <div className="bankoutView">
          <BackLink link={this.backToListUrl} labelKey="back_link_bankouts_list" />
          <Measure scroll onResize={this.handleDetailsResize}>
            {this.displayDetails}
          </Measure>
          <div style={{ height: `calc(100% - ${this.state.detailsSize.height}px)` }}>
            <PaybackList params={this.props.params} />
          </div>
        </div>
      </div>
    );
  }
}

export default namedCompose(
  connect(state => {
    const {
      bankouts: { bankoutDetails, bankoutParams, exporting }
    } = state;
    return {
      exporting,
      bankoutDetails,
      bankoutParams,
      role: userRoleSelector(state)
    };
  }),
  injectIntl
)(BankoutView);
