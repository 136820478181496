import React, { Component, PropTypes } from 'react';
import { FormattedMessage } from 'react-intl';
import BoxedSelect from '../../components/BoxedSelect/BoxedSelect';
import { smartcardsUserSearchOptions } from '../../constants/options-constants';
import AutoComplete from 'material-ui/AutoComplete';
import { allowFunc } from '../../utils/utils';

class SearchForUser extends Component {
  componentDidMount() {
    this.handleDidMount();
  }

  handleDidMount() {
    const { onDidMount } = this.props;
    onDidMount && onDidMount();
  }

  render() {
    return (
      <div>
        <div className="smartcardsDetail_searchSelect">
          <BoxedSelect
            labelKey={this.props.selectTitleKey}
            field={this.props.selectField}
            id="smartcards-searchType-select"
            options={smartcardsUserSearchOptions}
            noEmptyValue
            fullWidth
          />
        </div>
        <AutoComplete
          hintText={<FormattedMessage id="label.search.for.user" />}
          menuStyle={{ maxHeight: '200px', overflowY: 'auto' }}
          dataSourceConfig={{ text: 'text', value: 'value' }}
          dataSource={this.props.foundUsers}
          onUpdateInput={this.props.onUpdate}
          onNewRequest={this.props.onRequest}
          searchText={this.props.searchText}
          id={this.props.inputId}
          filter={allowFunc}
          openOnFocus
          fullWidth
        />
        {this.props.errorDuringSelection && (
          <div className="fieldErrorMsg">
            <FormattedMessage id="user.cannot.be.selected" />
          </div>
        )}
      </div>
    );
  }
}

SearchForUser.propTypes = {
  errorDuringSelection: PropTypes.bool,
  selectTitleKey: PropTypes.string,
  selectField: PropTypes.object,
  hintText: PropTypes.string,
  onUpdate: PropTypes.func,
  onRequest: PropTypes.func,
  onDidMount: PropTypes.func,
  inputId: PropTypes.string,
  searchText: PropTypes.string,
  foundUsers: PropTypes.array
};

export default SearchForUser;
