import React, { Component, PropTypes } from 'react';
import cs from 'classnames';
import ReactSVG from 'react-svg';
import Car from 'material-ui/svg-icons/maps/directions-car';
import Paid from 'material-ui/svg-icons/action/done';
import Unpaid from 'material-ui/svg-icons/navigation/close';
import { INVOICE_TYPE, PAYMENT_STATUS_UNPAID, PAYMENT_STATUS_PAID } from '../../../../constants/backend-constants';
import { getMsg } from '../../../../utils/IntlGlobalProvider';
import { withCol } from '../../../../utils/utils';

const typeData = {
  [INVOICE_TYPE.INVOICE]: { icon: <ReactSVG className="align-svg" src="/img/calendar.svg" />, bgClass: 'bg-invoice' },
  [INVOICE_TYPE.CREDIT_NOTE]: { icon: <ReactSVG className={cs('align-svg', 'refund-icon')} src="/img/refund.svg" />, bgClass: 'bg-credit' },
  [INVOICE_TYPE.MISC_INVOICE]: { icon: <ReactSVG className="align-svg" src="/img/broken-car.svg" />, bgClass: 'bg-misc' },
  [INVOICE_TYPE.COMMISSION]: { icon: <ReactSVG className="align-svg" src="/img/pantheon.svg" />, bgClass: 'bg-comm' }
};

const statusData = {
  [PAYMENT_STATUS_PAID]: { icon: <Paid />, bgClass: 'bg-paid' },
  [PAYMENT_STATUS_UNPAID]: { icon: <Unpaid />, bgClass: 'bg-unpaid' }
};

class InvoiceListIcon extends Component {
  constructor() {
    super();
    this.state = { hovered: false };
    this.handleHover = this.handleHover.bind(this);
  }

  getStatusData() {
    const { type, status } = this.props.invoice;

    if (type === INVOICE_TYPE.MISC_INVOICE && status === PAYMENT_STATUS_UNPAID) {
      return { icon: <Unpaid />, bgClass: 'bg-unpaid' };
    }
    return statusData[status];
  }

  handleHover() {
    this.setState(prevState => ({
      hovered: !prevState.hovered
    }));
  }

  render() {
    const { type, status } = this.props.invoice;
    const statusData = this.getStatusData();

    return (
      <div className={cs(typeData[type].bgClass, 'column-list-icon')} onMouseEnter={this.handleHover} onMouseLeave={this.handleHover}>
        {typeData[type].icon || <Car />}
        <div className={cs(statusData.bgClass, 'sc-status-icon')}>{statusData.icon || <Unpaid />}</div>

        {this.state.hovered && (
          <div className="column-list-tooltip">
            <span className="inline">
              <span className="sc-name">{withCol('common_type')}</span>
              <span className="sc-value">{getMsg(`invoice.type.${type}`)}</span>
            </span>
            <span className="inline">
              <span className="sc-name">{withCol('common_status')}</span>
              <span className="sc-value">{getMsg(`payment_status_${status}`)}</span>
            </span>
          </div>
        )}
      </div>
    );
  }
}

InvoiceListIcon.propTypes = {
  invoice: PropTypes.any
};

export default InvoiceListIcon;
