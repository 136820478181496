import { cleanCompanyApiParams, dateToStringFormat, guessTimeZone, trimValues } from '../utils/utils';
import { COMPANY_PAYBACK_SETTINGS_FORM } from '../constants/form-constants';
import _pick from 'lodash/pick';
import callApi from './api-actions';
import { EUR_CURRENCY } from '../constants/backend-constants';

/** Specific reducer variables have to be the same across both company and sub-company */

export function sharedCompanyUpdateBankoutConfigAction(companyDetails) {
  return (dispatch, getState) => {
    const state = getState();
    const { id: billingEntityId } = companyDetails || {};
    const data = trimValues(state.form[COMPANY_PAYBACK_SETTINGS_FORM]) || {};
    const { invoicingCountry, timeBeforePayment, commissionRateFromDate, commissionVatRate, commissionRate } = data || {};
    const commissionRateNumber = +commissionRate;
    const commissionVatRateNumber = +commissionVatRate;
    const delayNumber = +timeBeforePayment;

    let params = _pick(data, 'bankoutFrequency', 'bankoutExecutionDay', 'bankoutLabel');

    params = {
      ...params,
      invoicingCountry: (invoicingCountry || '').toUpperCase(),
      billingEntityId,
      commissionsVatRate: commissionVatRateNumber,
      delay: delayNumber
    };

    if (commissionRateFromDate) {
      params = {
        ...params,
        commissions: [
          {
            rate: commissionRateNumber,
            startDate: dateToStringFormat(commissionRateFromDate)
          }
        ]
      };
    }

    params = cleanCompanyApiParams(params);

    return dispatch(callApi('bankouts', 'updateConfig', params));
  };
}

export function sharedCompanyCreateBankoutConfigAction(companyState, companyDetails, entityType) {
  return (dispatch, getState) => {
    const state = getState();
    const { bankAccountDetails: bankDetails } = companyState;
    const { id: billingEntityId, name: billingEntityName } = companyDetails || {};
    const data = trimValues(state.form[COMPANY_PAYBACK_SETTINGS_FORM]) || {};
    const { invoicingCountry, timeBeforePayment, commissionVatRate, commissionRate, commissionCurrency } = data || {};
    const countryLowercase = (invoicingCountry || '').toLowerCase();
    const currencyCode = commissionCurrency || EUR_CURRENCY;
    const commissionRateNumber = +commissionRate;
    const commissionVatRateNumber = +commissionVatRate;

    let params = _pick(data, 'bankoutFrequency', 'bankoutExecutionDay', 'bankoutLabel');

    params = {
      ...params,
      bankAccountDetails: bankDetails,
      currency: currencyCode,
      invoicingCountry: countryLowercase.toUpperCase(),
      billingEntityId,
      billingEntityName,
      commissionRate: commissionRateNumber,
      commissionVatRate: commissionVatRateNumber,
      paymentProvider: 'worldpay',
      timeZoneId: guessTimeZone(),
      delay: +timeBeforePayment,
      billingEntityType: entityType
    };

    params = cleanCompanyApiParams(params);

    return dispatch(callApi('bankouts', 'createConfig', params));
  };
}

export function sharedCompanyUpdateBankDetailsAction(companyState, companyDetails) {
  return dispatch => {
    const { bankAccountDetails: bankDetails } = companyState;
    const { id: billingEntityId } = companyDetails || {};

    if (!bankDetails) return Promise.resolve();

    let params = {
      bankAccountDetails: bankDetails,
      billingEntityId,
      paymentProvider: 'worldpay'
    };

    return dispatch(callApi('bankouts', 'updateBankDetails', params));
  };
}
