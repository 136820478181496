import React, { Component } from 'react';
import { reduxForm, change } from 'redux-form';
import { FormattedMessage } from 'react-intl';
import BoxedInput from '../../../../components/BoxedInput/BoxedInput';
import SlimSelect from 'slim-select';
import { vehicleFuelTypes } from '../../../../constants/options-constants';
import { vehicleUsages } from '../../../../constants/filters-constants';
import { isEmpty, safe } from '../../../../utils/utils';
import cs from 'classnames';

import { vpFiltersSelector, vpSitesSelector } from '../../../../selectors/all-selectors';
import { getMsg } from '../../../../utils/IntlGlobalProvider';
import { FlatButton, RaisedButton } from 'material-ui';

class Filters extends Component {
  setCallbacks() {
    this.getUsageRef = ref => {
      this.usageRef = ref;
    };
    this.getFuelRef = ref => {
      this.fuelRef = ref;
    };
    this.getSiteRef = ref => {
      this.siteRef = ref;
    };
    this.updateFuelField = () => {
      this.props.dispatch(change('vehiclesFilters', 'fuels', this.fuelSelect.getSelected()));
    };
    this.updateUsageField = () => {
      this.props.dispatch(change('vehiclesFilters', 'usages', this.usageSelect.getSelected()));
    };
    this.updateSiteField = () => {
      this.props.dispatch(change('vehiclesFilters', 'sites', this.siteSelect.getSelected()));
    };

    this.handleKeyDown = event => {
      if (event.key === 'Enter') {
        event.preventDeault();
      }
    };

    this.handleFiltersReset = () => {
      this.props.dispatch(change('vehiclesFilters', 'registrationNumber', null));
      this.props.dispatch(change('vehiclesFilters', 'usages', []));
      this.props.dispatch(change('vehiclesFilters', 'fuels', []));
      this.props.dispatch(change('vehiclesFilters', 'sites', []));
      this.usageSelect.setSelected([]);
      this.fuelSelect.setSelected([]);
      this.siteSelect.setSelected([]);
    };

  }

  setActiveBlocks() {
    const { fields: f } = this.props;
    this.vehicleBlockActive = this.fieldsActive(f.fuels, f.registrationNumber, f.usages);
    this.locationBlockActive = this.fieldsActive(f.sites);
  }

  componentWillMount() {
    this.setCallbacks();
    this.setActiveBlocks();
    this.componentPropsUpdated(this.props);
  }

  componentDidMount() {
    this.componentUpdatedCycle();
  }

  componentWillReceiveProps(props) {
    this.componentPropsUpdated(props);
  }

  componentPropsUpdated(props) {
    this.prevShowFilters = this.showFilters;
    this.showFilters = props.filters.fuels.length > 0 || 
    props.filters.usages.length > 0 || 
    props.filters.sites.length > 0 || 
    props.filters.registrationNumber !== null;
  }

  fieldsActive(...fields) {

    for (let i = 0; i < fields.length; i++) {      
      if (Array.isArray(fields[i].initialValue) ) {
        if ( fields[i].initialValue.length > 0 ) return true;
        else return false;
      }
      if (!isEmpty(fields[i].initialValue && !Array.isArray(fields[i].initialValue))) {
        return true;
      } 
    }
  }

  componentUpdatedCycle() {
    this.usageSelect && this.usageSelect.destroy();
    this.fuelSelect && this.fuelSelect.destroy();
    this.siteSelect && this.siteSelect.destroy();

    this.usageSelect = new SlimSelect({
      select: this.usageRef,
      settings: { closeOnSelect: false, showSearch: false, placeholderText: getMsg('contract.homesetting.select-filter.placehoder') },
      events: {
        afterChange: this.updateUsageField
      }
    });

    this.fuelSelect = new SlimSelect({
      select: this.fuelRef,
      settings: { closeOnSelect: false, showSearch: false, placeholderText: getMsg('contract.homesetting.select-filter.placehoder') },
      events: {
        afterChange: this.updateFuelField
      }
    });

    this.siteSelect = new SlimSelect({
      select: this.siteRef,
      settings: {
        closeOnSelect: false,
        showSearch: safe(() => this.props.sites.length > 5), 
        placeholderText: getMsg('contract.homesetting.select-filter.placehoder')
      },
      events: {
        afterChange: this.updateSiteField
      }
    });

    if (safe(() => this.props.filters.usages)) this.usageSelect.setSelected(this.props.filters.usages);
    if (safe(() => this.props.filters.fuels)) this.fuelSelect.setSelected(this.props.filters.fuels);
    if (safe(() => this.props.filters.sites)) this.siteSelect.setSelected(this.props.filters.sites);
  }

  render() {
    const { props: p } = this;
    const { fields: f, handleSubmit, sites } = p;

    return (
      <form className="vehicles-filters list-filters" action="#" onSubmit={handleSubmit(this.props.onFiltersApply)} >
        <div className={cs('sc-block', { active: this.vehicleBlockActive })}>
          <div className="sc-title">
            <FormattedMessage id="vehicle_tab_vehicle" />
          </div>
          <div className="sc-items">
            <div className="col">
              <label className="boxedSelect_label">{getMsg('vehiclesFiltersForm_usage')}</label>
              <select ref={this.getUsageRef} multiple field={f.usages} className="vp-filters">
                {vehicleUsages.map(opt => (
                  <option key={opt.value} value={opt.value} className="vp">
                    {getMsg(opt.labelKey)}
                  </option>
                ))}
              </select>
            </div>
            <div className="col">
              <label className="boxedSelect_label">{getMsg('vehicleForm_fuelType')}</label>
              <select ref={this.getFuelRef} multiple field={f.fuels} className="vp-filters">
                {vehicleFuelTypes.map(opt => (
                  <option key={opt.value} value={opt.value}>
                    {getMsg(opt.labelKey)}
                  </option>
                ))}
              </select>
            </div>

            <BoxedInput type="text" labelKey="vehiclesFiltersForm_plateNumber" field={f.registrationNumber} />
          </div>
        </div>

        <div className={cs('sc-block', { active: this.locationBlockActive })}>
          <div className="sc-title">
            <FormattedMessage id="vehicleForm_siteLocation" />
          </div>
          <div className="sc-items">
            <div className="col">
              <label className="boxedSelect_label">{getMsg('autocomplete_company_sites_title')}</label>
              <select ref={this.getSiteRef} multiple field={f.sites} className="vp-filters">
                {sites.map(opt => (
                  <option key={opt.id} value={opt.id} className="vp">
                    {opt.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>

        <div className="sc-actions">
          <FlatButton primary className="sc-flat" onClick={this.handleFiltersReset}>
            <FormattedMessage id="common_reset" />
          </FlatButton>
          <RaisedButton primary className="sc-raised" type="submit">
            <FormattedMessage id="common_apply" />
          </RaisedButton>
        </div>
      </form>
    );
  }
}

Filters = reduxForm(
  {
    form: 'vehiclesFilters',
    fields: ['registrationNumber', 'usages', 'fuels', 'sites']
  },
  state => ({
    filters: vpFiltersSelector(state),
    sites: vpSitesSelector(state)
  })
)(Filters);

export default Filters;
