export const number = value => {
  if (!value) {
    return value;
  }

  return Number(value);
};

export const day = value => {
  if (!value) {
    return value;
  }

  return value;
};
