import React, { Component, PropTypes } from 'react';
import { FormattedMessage } from 'react-intl';

class BaseTitle extends Component {
  render() {
    return (
      <h1
        className={`baseTitle
        ${!this.props.skinType ? '' : `baseTitle--${this.props.skinType}`}
        ${this.props.customClass || ''} `}
      >
        <FormattedMessage id={this.props.title} />
        {this.props.children}
      </h1>
    );
  }
}

BaseTitle.displayName = 'BaseTitle';

BaseTitle.propTypes = {
  customClass: PropTypes.string,
  title: PropTypes.string,
  skinType: PropTypes.string
};

export default BaseTitle;
