import React, { Component, PropTypes } from 'react';
import { FormattedMessage } from 'react-intl';
import { reduxForm } from 'redux-form';

import FormRow from '../FormRow/FormRow';
import BoxedInput from '../BoxedInput/BoxedInput';
import EkButton from '../EkButton/EkButton';
import FieldErrorMsg from '../FieldErrorMsg/FieldErrorMsg';
import { createValidator, notEmpty } from '../../validation/all-validation';
import routes from '../../constants/routes-constants';
import BackLink from '../BackLink/BackLink';
import { scrollToFirstError } from '../../utils/utils';

class BrandForm extends Component {
  render() {
    const {
      fields: { name },
      handleSubmit,
      limitedAccess
    } = this.props;

    return (
      <section className="brandFormWrapper">
        <BackLink link={`/#${routes.brands.path}`} labelKey="back_link_brands_list" />
        <form action="#" className="brandForm" onSubmit={handleSubmit(this.props.onCallback)}>
          <div className="brandForm_container">
            <h4 className="brandForm_title">
              <FormattedMessage id="brandForm_title" />
            </h4>
            <FormRow customClass="brandForm_row">
              <BoxedInput
                formRowItemKey="brandForm_submitBtn"
                type="text"
                id="name"
                labelKey="brandForm_name_label"
                customClass="brandForm_boxedInput"
                field={name}
                maxlength="255"
                disabled={limitedAccess}
              >
                <FieldErrorMsg field={name} customClass="fieldErrorMsg--brandForm" />
              </BoxedInput>
            </FormRow>
          </div>
          <div className="brandForm_actions">
            <a href={`/#${routes.brands.path}`} className="ekButton ekButton--reverse brandForm_actionsLink">
              <FormattedMessage id="brandForm_cancel_button" />
            </a>
            <EkButton customClass="brandForm_actionsButton" onAction={handleSubmit(this.props.onCallback)} disabled={limitedAccess}>
              <FormattedMessage id="brandForm_save_button" />
            </EkButton>
          </div>
        </form>
      </section>
    );
  }
}

BrandForm.displayName = 'BrandForm';

BrandForm.propTypes = {
  fields: PropTypes.object,
  handleSubmit: PropTypes.func,
  limitedAccess: PropTypes.bool,
  onCallback: PropTypes.func
};

BrandForm = reduxForm({
  onSubmitFail: scrollToFirstError,
  form: 'brandForm',
  fields: ['name'],
  validate: createValidator({
    name: [notEmpty()]
  })
})(BrandForm);

export default BrandForm;
