import * as types from '../constants/actionTypes-constants';
import { apiParams } from '../constants/api-params-constants';

export default function header(
  state = {
    loadingList: true,
    loadingError: false,
    exporting: [],
    bankoutResults: {},
    paybackResults: {},
    bankoutParams: apiParams.default,
    paybackParams: apiParams.default
  },
  action
) {
  switch (action.type) {
    case types.BANKOUT_CLEAR_LIST:
      return {
        ...state,
        bankoutResults: {},
        loadingList: false,
        loadingError: false,
        bankoutParams: apiParams.default
      };

    case types.BANKOUT_GET_LIST_REQUEST:
      return {
        ...state,
        loadingList: true,
        loadingError: false,
        bankoutParams: action.urlParams
      };

    case types.BANKOUT_GET_LIST_SUCCESS:
      return {
        ...state,
        bankoutResults: action.paginatedObj,
        loadingList: false,
        loadingError: false
      };

    case types.BANKOUT_GET_LIST_ERROR:
      return {
        ...state,
        loadingError: true,
        loadingList: false,
        bankoutResults: {}
      };

    case types.BANKOUT_GET_DETAILS_SUCCESS:
      return {
        ...state,
        bankoutDetails: action.data
      };

    case types.BANKOUT_GET_DETAILS_CLEAR:
      return {
        ...state,
        bankoutDetails: {}
      };

    case types.BANKOUT_EXPORT_REQUEST: {
      let _exporting = state.exporting;
      const id = action.id;
      _exporting.push(id);

      return {
        ...state,
        exporting: [..._exporting]
      };
    }

    case types.BANKOUT_EXPORT_FINISHED: {
      let _exporting = state.exporting;
      const index = _exporting.indexOf(action.id);
      if (index > -1) {
        _exporting.splice(index, 1);
      }
      return {
        ...state,
        exporting: [..._exporting]
      };
    }
    case types.BANKOUT_EXPORT_FAILED:
      return {
        ...state,
        exporting: []
      };

    case types.PAYBACK_GET_LIST_REQUEST:
      return {
        ...state,
        loadingList: true,
        loadingError: false,
        paybackParams: action.urlParams
      };

    case types.PAYBACK_GET_LIST_SUCCESS:
      return {
        ...state,
        paybackResults: action.paginatedObj,
        loadingList: false,
        loadingError: false
      };

    case types.PAYBACK_GET_LIST_ERROR:
      return {
        ...state,
        loadingError: true,
        loadingList: false,
        paybackResults: {}
      };

    case types.PAYBACK_CLEAR_LIST:
      return {
        ...state,
        loadingError: false,
        loadingList: false,
        paybackResults: {},
        paybackParams: apiParams.default
      };

    default:
      return state;
  }
}
