import * as types from '../constants/actionTypes-constants';
import callApi from './api-actions';
import _sortBy from 'lodash/sortBy';
import { addErrorMessage } from '../utils/utils';
import { addFlashMessage } from './flashMessage-actions';
import { FLASH_MESSAGE_TYPE_SUCCESS } from '../constants/generic-constants';

export function getVersionsList(modelId, loader = true) {
  return dispatch => {
    return dispatch(callApi('versions', 'getList', modelId, loader)).then(
      data => {
        data = _sortBy(data, 'name');
        dispatch({ type: types.VERSIONS_GET_LIST_SUCCESS, list: data });
        return Promise.resolve(data);
      },
      error => {
        dispatch(addErrorMessage({ error }));
        return Promise.reject(error);
      }
    );
  };
}

export function createVersionRequest({ id, ...params }) {
  return dispatch => {
    return dispatch(callApi('versions', 'createVersion', { id, params })).then(
      data => {
        dispatch(createVersionSuccess(data));
        return Promise.resolve(data);
      },
      error => {
        const params = { error, def: 'versionForm_create_version_error', errorCodePrefixes: ['versionForm_'] };
        dispatch(addErrorMessage(params));
        return Promise.reject(error);
      }
    );
  };
}

export function createVersionSuccess(version) {
  return {
    type: types.VERSIONS_CREATE_VERSION_SUCCESS,
    version
  };
}

export function emptyVersionList(version) {
  return {
    type: types.VERSIONS_EMPTY_LIST,
    version
  };
}

export function getSingleVersion(versionId) {
  return dispatch => {
    return dispatch(callApi('versions', 'getVersion', versionId)).then(
      data => {
        dispatch({ type: types.VERSIONS_GET_SINGLE_VERSION_SUCCESS, version: data });
        return Promise.resolve(data);
      },
      error => {
        dispatch(addErrorMessage({ error }));
        return Promise.reject(error);
      }
    );
  };
}

export function updateVersionRequest({ id, ...params }) {
  return dispatch => {
    return dispatch(callApi('versions', 'updateVersion', { id, params })).then(
      data => {
        dispatch(updateVersionSuccess(data));
        dispatch(
          addFlashMessage({
            contentKey: 'versionForm_update_version_success',
            type: FLASH_MESSAGE_TYPE_SUCCESS
          })
        );
        return Promise.resolve(data);
      },
      error => {
        dispatch(addErrorMessage({ error, def: 'versionForm_update_version_error' }));
        return Promise.reject(error);
      }
    );
  };
}

export function updateVersionSuccess(version) {
  return {
    type: types.VERSIONS_UPDATE_VERSION_SUCCESS,
    version
  };
}
