import React, { Component, PropTypes } from 'react';
import routes from '../../../constants/routes-constants';
import BookingIcon from 'material-ui/svg-icons/maps/directions-car';
import autoBind from 'react-autobind';
import _get from 'lodash/get';
import { connect } from 'react-redux';
import pure from 'recompose/pure';

import { fillWhite, dimensions20, marginBottom2 } from '../../../constants/style-constants';
import { addOpenNewTabEvents, getAppFormattedDateTime, hideButtonHoverMsg, namedCompose } from '../../../utils/utils';
import { FormattedMessage } from 'react-intl';

class BookingsResultsRow extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
  }

  componentWillUnmount() {
    hideButtonHoverMsg();
  }

  handleGoToDetail(openInNewTab) {
    this.props.handleGoToDetail(routes.bookingDetail.path, ':bookingId', this.props.booking.id, openInNewTab);
  }

  getUnknown() {
    return <FormattedMessage id="common_unknown" />;
  }

  getDate(date) {
    return date ? getAppFormattedDateTime(date) : this.getUnknown();
  }

  render() {
    const { booking } = this.props;

    return (
      <div key={booking.id} className="search-resultRow" {...addOpenNewTabEvents(this.handleGoToDetail)}>
        <div className="search-resultIcon">
          <BookingIcon style={{ ...fillWhite, ...dimensions20, ...marginBottom2 }} />
        </div>
        <div className="search-resultCol bold">
          {_get(booking, 'vehicleBrandName', this.getUnknown())}
          <span className="search-spacer"> </span>
          {_get(booking, 'vehicleModelName', this.getUnknown())}
        </div>
        <div className="search-resultCol">{_get(booking, 'vehicleRegistrationNumber', this.getUnknown())}</div>
        <div className="search-resultCol">
          {_get(booking, 'memberFirstName', this.getUnknown())}
          <span className="search-spacer"> </span>
          {_get(booking, 'memberLastName', this.getUnknown())}
        </div>
        <div className="search-resultCol flexCol fontSize14">
          <span className="green">{this.getDate(_get(booking, 'startDate'))}</span>
          <span className="search-spacer"> </span>
          <span>-</span>
          <span className="search-spacer"> </span>
          <span className="red paddingTop5">{this.getDate(_get(booking, 'endDate'))}</span>
        </div>
      </div>
    );
  }
}

BookingsResultsRow.propTypes = {
  booking: PropTypes.object,
  handleGoToDetail: PropTypes.func
};

export default namedCompose(
  connect(state => {
    return {
      locale: _get(state, 'i18n.locale')
    };
  }),
  pure
)(BookingsResultsRow);
