/* eslint-disable react/no-set-state */

import React, { Component } from 'react';
import Dialog from 'material-ui/Dialog';

import autoBind from 'react-autobind';
import { reduxForm, getValues, change } from 'redux-form';
import Tooltip from '../../../../components/Tooltip/Tooltip';
import TimeIcon from 'material-ui/svg-icons/device/access-time';
// import UserIcon from 'material-ui/svg-icons/social/person';
import GreenSwitch from '../../../../components/_v2/GreenSwitch/GreenSwitch';
import { FormattedMessage, FormattedHTMLMessage, injectIntl } from 'react-intl';
import _get from 'lodash/get';
import _merge from 'lodash/merge';
import _map from 'lodash/map';
import _find from 'lodash/find';
import _filter from 'lodash/filter';
import _includes from 'lodash/includes';
import _isEmpty from 'lodash/isEmpty';
import _flatten from 'lodash/flatten';
import _flattenDeep from 'lodash/flattenDeep';
import _compact from 'lodash/compact';
import _difference from 'lodash/difference';
import _padStart from 'lodash/padStart';
import _range from 'lodash/range';
// import _debounce from 'lodash/debounce';
import moment from 'moment';
import CloseIcon from 'material-ui/svg-icons/navigation/close';
import IconButton from 'material-ui/IconButton';
import pure from 'recompose/pure';
import classnames from 'classnames';

import {
  clearCreateBookingModal,
  createBookingVehiclePlanning,
  toggleCreateBookingModal,
  getEstimatedPricesBooking,
  displayBookingPrice,
  cleanBookingPriceEstimation
} from '../../../../actions/all-actions';
import { createValidator, notEmpty, stopValidationIf, addressOrSite, dateAfterOrEqualField } from '../../../../validation/all-validation';
import {
  headerContractMemberValuesSelector,
  headerContractPaymentValuesSelector,
  headerCompanyContractSelector,
  localeSelector,
  planningBookingCustomFieldsSelector,
  vpAddBookingUsageOptionsSelector
} from '../../../../selectors/all-selectors';
import {
  BOOKING_USAGE_TYPE_BUSINESS,
  BOOKING_USAGE_TYPE_PRIVATE,
  BOOKING_TYPE_CAR_SHARING,
  ONE_WAY_TRIP,
  ROUND_TRIP,
  VEHICLE_USAGE_TYPE_IMMEDIATE,
  VEHICLE_USAGE_TYPE_PLANNED,
  VEHICLE_USAGE_TYPE_TEST_DRIVE,
  VEHICLE_USAGE_TYPE_RV,
  VEHICLE_USAGE_TYPE_PUBLIC_SERVICE,
  STATUS_APPROVED,
  VEHICLE_USAGE_TYPE_DELIVERY,
  BACKUSER_ROLE_ZONE_MANAGER,
  BACKUSER_ROLE_ADMIN_DEALER,
  BACKUSER_ROLE_ADMIN,
  BACKUSER_ROLE_SUPER_ADMIN,
  BACKUSER_ROLE_ROOT,
  STATUS_TO_COMPLETE
} from '../../../../constants/backend-constants';
import {
  enhanceSiteList,
  createInitValuesCreateBooking,
  createDateForCreateBooking,
  createInitValuesCreateBookingHourMode,
  createCustomFieldsNames,
  createCustomFieldsValidators,
  namedCompose,
  scrollToFirstErrorDialog,
  daysListOfDatesRange,
  createSharedInitValuesCreateBooking,
  vehicleUsageSwitch,
  toBoolean,
  safe,
  addErrorMessage
} from '../../../../utils/utils';
import { BOOKING, PREBOOKING } from '../../../../constants/form-constants';
import { enhanceBookingCreationRequestParams, enhanceBookingPricesRequestParams } from '../../../../api/data-enhancer';
import EkButton from '../../../../components/EkButton/EkButton';
import EkTextarea from '../../../../components/EkTextarea/EkTextarea';
import FormRow from '../../../../components/FormRow/FormRow';
import config from '../../../../constants/config-constants';
import BoxedSelect from '../../../../components/BoxedSelect/BoxedSelect';
import BoxedInput from '../../../../components/BoxedInput/BoxedInput';
import AddressAutocomplete from '../../../../components/AddressAutocomplete/AddressAutocomplete';
import EkRadio from '../../../../components/EkRadio/EkRadio';
import FieldErrorMsg from '../../../../components/FieldErrorMsg/FieldErrorMsg';
import CustomFieldsBooking from '../../../../components/CustomFieldsBooking/CustomFieldsBooking';
import IsGranted from '../../../../components/IsGranted/IsGranted';
import VehiclePlanningVehicleTile from './VehiclePlanningVehicleTile';
import FormField from '../../../../components/FormField';

class VehiclePlanningCreateBooking extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = { memberTypeSelectDisabled: false, openVoucher: false };
    this.hoursOptions = [];
    this.pickupMinutesOptions = config.allAvailableMinutes;
    this.returnMinutesOptions = config.allAvailableMinutes;
    this.setHoursOptions('pickupDate');
    this.setHoursOptions('returnDate');
    // this.__debounce = _debounce(this.getPrice, 2000);
    this.derivedStateFromProps(props, true);
  }

  // noinspection JSCheckFunctionSignatures
  componentWillReceiveProps(props) {
    this.derivedStateFromProps(props);
  }

  derivedStateFromProps(props, init) {
    this.updateMemberTypeOptions(props, init);
  }

  updateMemberTypeOptions(props, init) {
    const { memberTypes: newProp } = props;
    const { memberTypes: oldProp } = props;
    if (init || newProp !== oldProp) this.createMemberTypeOptions(newProp);
  }

  createMemberTypeOptions(memberTypes = []) {
    this.memberTypeOptions = memberTypes.map(type => ({
      label: type.name,
      value: type.id
    }));
  }

  handleClose() {
    this.props.dispatch(toggleCreateBookingModal());
    this.props.dispatch(clearCreateBookingModal());
  }

  renderSuggestFiltered(list) {
    const { fields } = this.props;
    let suggestList;
    if (fields.from.value) {
      suggestList = list.filter(obj => obj.id !== fields.from.value.id);
    }
    return suggestList;
  }

  componentDidUpdate(prevProps) {
    if (prevProps.values.usageType && prevProps.values.usageType !== this.props.values.usageType) {
      if (prevProps.values.usageType === BOOKING_USAGE_TYPE_PRIVATE) {
        this.getPrice();
      }
    }
    if (
      (prevProps.values.pickupDate && prevProps.values.pickupDate !== this.props.values.pickupDate) ||
      (prevProps.values.pickupDateHour && prevProps.values.pickupDateHour !== this.props.values.pickupDateHour) ||
      (prevProps.values.pickupDateMin && prevProps.values.pickupDateMin !== this.props.values.pickupDateMin) ||
      (prevProps.values.returnDate && prevProps.values.returnDate !== this.props.values.returnDate) ||
      (prevProps.values.returnDateHour && prevProps.values.returnDateHour !== this.props.values.returnDateHour) ||
      (prevProps.values.returnDateMin && prevProps.values.returnDateMin !== this.props.values.returnDateMin) ||
      (prevProps.values.memberTypeId && prevProps.values.memberTypeId !== this.props.values.memberTypeId)
    ) {
      this.getPrice();
    }

    if (prevProps.values.usageOverride !== this.props.values.usageOverride) {
      this.initPrebookingRules();
    }
  }

  initPrebookingRules() {
    if (
      [VEHICLE_USAGE_TYPE_TEST_DRIVE, VEHICLE_USAGE_TYPE_RV, VEHICLE_USAGE_TYPE_DELIVERY, VEHICLE_USAGE_TYPE_PUBLIC_SERVICE].includes(
        this.props.values.usageOverride
      ) &&
      (this.props.profileReviewStatus !== STATUS_APPROVED || this.props.preSubMemberStatus !== STATUS_APPROVED)
    ) {
      this.props.dispatch(change('VehiclePlanningCreateBooking', 'prebook', PREBOOKING));
    }

    if (this.props.profileReviewStatus === STATUS_APPROVED || this.props.preSubMemberStatus === STATUS_APPROVED) {
      if ([VEHICLE_USAGE_TYPE_TEST_DRIVE, VEHICLE_USAGE_TYPE_RV].includes(this.props.values.usageOverride)) {
        this.props.dispatch(change('VehiclePlanningCreateBooking', 'prebook', PREBOOKING));
      }
      if ([VEHICLE_USAGE_TYPE_DELIVERY, VEHICLE_USAGE_TYPE_PUBLIC_SERVICE].includes(this.props.values.usageOverride)) {
        this.props.dispatch(change('VehiclePlanningCreateBooking', 'prebook', BOOKING));
      }
    }
  }

  hoursFiltered(streams, date) {
    const { fields } = this.props;
    const _streams = _filter(_get(streams, 'recurringSlots', false), { open: true });
    const isPickDate = date === 'pickupDate';
    const dateH = isPickDate ? fields.pickupDate.value : fields.returnDate.value;
    const hourH = isPickDate ? fields.pickupDateHour.value : fields.returnDateHour.value;
    const _streamsOpenSpecial = _filter(_get(streams, 'oneTimeSlots', false), { open: true });
    const d = moment(dateH).isoWeekday() - 1;
    const day = config.daysOfWeek[d];
    const now = moment();
    if (_streamsOpenSpecial) {
      _streamsOpenSpecial.map(specialDaysHoursStream => {
        if (
          moment(dateH).isBetween(
            moment(specialDaysHoursStream.startDate),
            moment(specialDaysHoursStream.endDate).subtract(1, 'days'),
            null,
            []
          )
        ) {
          specialDaysHoursStream.days = [];
          const daysList = daysListOfDatesRange(specialDaysHoursStream.startDate, specialDaysHoursStream.endDate);
          daysList.map(day =>
            specialDaysHoursStream.days.push(
              moment(day)
                .locale('en')
                .format('dddd')
                .toUpperCase()
            )
          );
          return specialDaysHoursStream;
        }
      });
    }
    if (_streams) {
      if (day) {
        const validStreams = _find(_streams, { days: [day.value] });
        const validSpecialStreams = _find(_streamsOpenSpecial, { days: [day.value] });
        let goodTimes = [];
        if (
          validSpecialStreams &&
          moment(dateH).isBetween(moment(validSpecialStreams.startDate), moment(validSpecialStreams.endDate).subtract(1, 'days'), null, [])
        ) {
          validSpecialStreams.timeIntervals.map(time => {
            const range = _range(Number(time.start.split(':')[0]), Number(time.end.split(':')[0]) + 1); //+ 1 as fn range exclude end
            const ranges = range.map(it => {
              return _padStart(it, 2, '0');
            });
            goodTimes.push(ranges);
          });
          return _flatten(goodTimes);
        }

        if (validStreams) {
          if (validStreams.prevTime) {
            validStreams.prevTime.map(time => {
              const range = _range(Number(time.start.split(':')[0]), Number(time.end.split(':')[0]) + 1); //+ 1 as fn range exclude end
              const ranges = range.map(it => {
                return _padStart(it, 2, '0');
              });
              goodTimes.push(ranges);
            });
          } else {
            validStreams.timeIntervals.map(time => {
              const range = _range(Number(time.start.split(':')[0]), Number(time.end.split(':')[0]) + 1); //+ 1 as fn range exclude end
              const ranges = range.map(it => {
                return _padStart(it, 2, '0');
              });
              goodTimes.push(ranges);
            });
          }

          if (now.isSame(dateH, 'day')) {
            if (now.isSame(moment(dateH).hour(hourH), 'hour')) {
              if (isPickDate) {
                this.pickupMinutesOptions = this.pickupMinutesOptions.reduce((acc, minutes) => {
                  if (Number(minutes) >= now.minute()) acc.push(minutes);
                  return acc;
                }, []);
              } else {
                this.returnMinutesOptions = this.returnMinutesOptions.reduce((acc, minutes) => {
                  if (Number(minutes) >= now.minute()) acc.push(minutes);
                  return acc;
                }, []);
              }
            }

            if (!now.isSame(moment(dateH).hour(hourH), 'hour')) {
              if (isPickDate) this.pickupMinutesOptions = config.allAvailableMinutes;
              else this.returnMinutesOptions = config.allAvailableMinutes;
            }

            return _flatten(goodTimes).reduce((acc, hour) => {
              if (hour >= now.hour()) acc.push(hour);
              return acc;
            }, []);
          }

          return _flatten(goodTimes);
        }
      }
    }
  }

  setHoursOptions(date) {
    const { firstHourSelected, firstDaySelected, secondDaySelected, fields } = this.props;
    const now = moment();
    const isPickDate = date === 'pickupDate';
    let hours = _get(firstHourSelected, 'parking.schedule', false);
    if (!hours) hours = _get(isPickDate ? firstDaySelected : secondDaySelected, 'parking.schedule', false);
    const dateH = isPickDate ? fields.pickupDate.value : fields.returnDate.value;
    const hourH = isPickDate ? fields.pickupDateHour.value : fields.returnDateHour.value;

    if (now.isSame(dateH, 'day')) {
      if (now.isSame(moment(dateH).hour(hourH), 'hour')) {
        if (isPickDate) {
          this.pickupMinutesOptions = this.pickupMinutesOptions.reduce((acc, minutes) => {
            if (Number(minutes) >= now.minute()) acc.push(minutes);
            return acc;
          }, []);
        } else {
          this.returnMinutesOptions = this.returnMinutesOptions.reduce((acc, minutes) => {
            if (Number(minutes) >= now.minute()) acc.push(minutes);
            return acc;
          }, []);
        }
      }
      if (!now.isSame(moment(dateH).hour(hourH), 'hour')) {
        if (isPickDate) this.pickupMinutesOptions = config.allAvailableMinutes;
        else this.returnMinutesOptions = config.allAvailableMinutes;
      }

      this.hoursOptions[date] = _flatten(config.availableHours).reduce((acc, hour) => {
        if (hour >= now.hour()) acc.push(hour);
        return acc;
      }, []);
    } else {
      this.hoursOptions[date] = config.availableHours;
    }

    this.hoursOptions[date] = hours ? this.hoursFiltered(hours, date) : this.hoursOptions[date];
  }

  findById(sites, id) {
    const schedule = sites.map(site => site.parkings.map(parking => (parking.id === id ? parking.schedule : '')));
    return _compact(_flattenDeep(schedule));
  }

  getClosedDays(date) {
    const { firstHourSelected, firstDaySelected, bankHolidays, data } = this.props;
    const parkingSchedule = _get(firstDaySelected, 'parking.schedule', _get(firstHourSelected, 'parking.schedule'));
    let closedDays = [];
    let openDays = [];
    let bankClosedDays = [];
    let closedRecurringDays = [];
    if (parkingSchedule) {
      if (parkingSchedule.applyFrenchBankHolidays) {
        bankClosedDays = _map(bankHolidays, day => {
          if (moment(date).isSame(moment(day.startDate), 'day')) {
            return moment(day.startDate).format('YYYY-MM-DD');
          }
        });
      }
      let oneTimeSlots = _filter(_get(parkingSchedule, 'oneTimeSlots', false), { open: false });
      if (oneTimeSlots) {
        closedDays = _map(oneTimeSlots, day => {
          if (moment(date).isBetween(moment(day.startDate), moment(day.endDate).subtract(1, 'days'), null, [])) {
            return daysListOfDatesRange(day.startDate, day.endDate);
          }
        });
      }

      let oneTimeSlotsOpen = _filter(_get(parkingSchedule, 'oneTimeSlots', false), { open: true });
      if (oneTimeSlotsOpen) {
        openDays = _map(oneTimeSlotsOpen, slot => {
          if (moment(date).isBetween(moment(slot.startDate), moment(slot.endDate).subtract(1, 'days'), null, [])) {
            return daysListOfDatesRange(slot.startDate, slot.endDate);
          }
        });
      }

      let initialRecurringSlots = _get(data, 'immutableParkings', false);
      let initialRecurringSlotsFiltered = this.findById(initialRecurringSlots, _get(firstHourSelected, 'parking.id', false));

      if (initialRecurringSlotsFiltered.length) {
        const weekDays = _flatten(initialRecurringSlotsFiltered[0].recurringSlots.map(item => item.days));
        let fullCloseDays = _compact(config.daysOfWeek.map(day => (!_includes(weekDays, day.value) ? day.value : '')));
        _map(fullCloseDays, day => {
          if (
            day ===
            moment(date)
              .locale('en')
              .format('dddd')
              .toUpperCase()
          ) {
            closedRecurringDays.push(moment(date).format('YYYY-MM-DD'));
          }
        });
      }

      let final = _merge(bankClosedDays, closedDays, closedRecurringDays);
      const finalClosed = _difference(_flatten(_compact(final)), _flatten(_compact(openDays)));
      return _includes(_flatten(_compact(finalClosed)), moment(date).format('YYYY-MM-DD'));
    }
  }

  activeCreditCard() {
    const { dispatch, VehiclePlanningCreateBooking, selectedMemberType } = this.props;
    const { usageType } = getValues(VehiclePlanningCreateBooking) || {};

    if (usageType === BOOKING_USAGE_TYPE_BUSINESS) {
      dispatch(change('VehiclePlanningCreateBooking', 'usageType', BOOKING_USAGE_TYPE_PRIVATE));
      dispatch(change('VehiclePlanningCreateBooking', 'memberTypeId', selectedMemberType));

      this.getPrice(BOOKING_USAGE_TYPE_PRIVATE);
    } else if (usageType === BOOKING_USAGE_TYPE_PRIVATE)
      dispatch(change('VehiclePlanningCreateBooking', 'usageType', BOOKING_USAGE_TYPE_BUSINESS));
  }

  getPrice(value, removeVoucher = false) {
    const {
      fields: { usageType, memberTypeId, voucherCode },
      firstDaySelected,
      firstHourSelected,
      secondDaySelected,
      secondHourSelected,
      profileIdForCreateBooking,
      dispatch
    } = this.props;

    value = value || usageType.value;
    const vehicleId = _get(firstHourSelected, 'vehicle.id') || _get(firstDaySelected, 'vehicle.id');

    if (value === BOOKING_USAGE_TYPE_PRIVATE) {
      let values = { ...this.props.values, usageType: value, memberTypeId: memberTypeId.value };

      let newParams = enhanceBookingPricesRequestParams(
        profileIdForCreateBooking,
        values,
        firstHourSelected,
        secondHourSelected,
        firstDaySelected,
        secondDaySelected
      );

      if (removeVoucher) delete newParams.voucherCode;

      return dispatch(getEstimatedPricesBooking(newParams)).then(data => {
        const item = _find(data.results, { vehicle: { id: vehicleId } });
        if (item) {
          dispatch(displayBookingPrice(item));
        }

        if (item && !item.carSharingInfo.cost.estimatedPriceWithoutVoucher && voucherCode.value !== '' && !removeVoucher) {
          dispatch(addErrorMessage({ def: 'unknown_voucher_code' }));
          this.props.dispatch(change('VehiclePlanningCreateBooking', 'voucherCode', null));
        }
        // this.removeVoucher();
        this.setState({ openVoucher: false });
      });
    } else if (value === BOOKING_USAGE_TYPE_BUSINESS) {
      dispatch(cleanBookingPriceEstimation());
    }
  }

  // updatePriceAuto() {
  //   this.__debounce();
  // }

  removeVoucher() {
    this.props.dispatch(change('VehiclePlanningCreateBooking', 'voucherCode', ''));
    this.getPrice(BOOKING_USAGE_TYPE_PRIVATE, true);
  }

  showVoucherButtons() {
    const {
      bookingPrice,
      paymentRules,
      fields: { paidBooking }
    } = this.props;

    if (!paymentRules.voucher) return;

    const priceObj = bookingPrice || {};
    const carSharingInfo = priceObj.carSharingInfo || {};
    const cost = carSharingInfo.cost || {};
    const voucher = cost.voucherCode || null;

    return (
      <div>
        {toBoolean(paidBooking.value) && !voucher && (
          <span
            className="apply-voucher"
            onClick={e => {
              e.preventDefault();
              this.setState({ openVoucher: true });
            }}
          >
            <Tooltip content={<FormattedMessage id="voucher-apply" />} placement="bottom" key={'tooltipvoucher'}>
              <FormattedMessage id="voucher-add" />
            </Tooltip>
          </span>
        )}

        {toBoolean(paidBooking.value) && voucher && (
          <span
            className="apply-voucher"
            onClick={e => {
              e.preventDefault();
              this.removeVoucher();
            }}
          >
            <Tooltip content={<FormattedMessage id="voucher-remove" />} placement="bottom" key={'tooltipvoucher'}>
              <FormattedMessage id="voucher-remover" />
            </Tooltip>
          </span>
        )}
      </div>
    );
  }

  displayPrice() {
    const {
      bookingPrice,
      loadingBookingPrice,
      fields: { usageType, paidBooking },
      intl: { formatMessage }
    } = this.props;

    const priceObj = bookingPrice || {};
    const carSharingInfo = priceObj.carSharingInfo || {};
    const cost = carSharingInfo.cost || {};
    const hasPrice = !_isEmpty(cost);
    const voucher = cost.voucherCode || null;
    const currency = cost.currency || 'EUR';
    const price_cost = _get(cost, voucher ? 'estimatedPriceWithoutVoucher' : 'estimatedPriceForDuration');
    const _amount = !toBoolean(paidBooking.value) ? formatMessage({ id: 'booking_view_free' }) : String(price_cost);
    const loader = (
      <div className="loading-price">
        <span>.</span>
        <span>.</span>
        <span>.</span>
      </div>
    );
    const currencyVal = !toBoolean(paidBooking.value) ? '' : formatMessage({ id: `unit_${currency}` });

    if (usageType.value !== BOOKING_USAGE_TYPE_BUSINESS) {
      if (hasPrice) {
        return (
          <div className="price">
            {voucher && !loadingBookingPrice && (
              <span>
                <FormattedHTMLMessage
                  id="vp_price_with_currency"
                  values={{
                    amount: !toBoolean(paidBooking.value) ? '' : String(_get(cost, 'estimatedPriceForDuration')),
                    currency: currencyVal
                  }}
                />
              </span>
            )}
            {loadingBookingPrice ? (
              loader
            ) : (
              <span className={voucher && toBoolean(paidBooking.value) ? 'line-through' : ''}>
                <FormattedHTMLMessage
                  id={voucher ? 'common_price_with_currency' : 'vp_price_with_currency'}
                  values={{
                    amount: _amount,
                    currency: currencyVal
                  }}
                />
              </span>
            )}
            {this.showVoucherButtons()}
          </div>
        );
      }
      return (
        <div className="price">
          {loadingBookingPrice && loader}
          {priceObj && !loadingBookingPrice && <FormattedMessage id="common_status_ERROR" />}
        </div>
      );
    }
  }

  handleSave() {
    const {
      loginForCreateBooking,
      VehiclePlanningCreateBooking,
      firstDaySelected,
      firstHourSelected,
      isPreBookingCreation,
      selectedMemberId,
      detailMember
    } = this.props;
    const memberDetails = { login: loginForCreateBooking, memberProfileId: selectedMemberId };
    const usageOverride = VehiclePlanningCreateBooking.usageOverride.value;

    const bookingDetails = {
      vehicle: Object.keys(firstDaySelected).length > 0 ? firstDaySelected.vehicle : firstHourSelected.vehicle,
      start: {
        date: createDateForCreateBooking(
          VehiclePlanningCreateBooking.pickupDate.value,
          VehiclePlanningCreateBooking.pickupDateHour.value,
          VehiclePlanningCreateBooking.pickupDateMin.value
        )
      },
      end: {
        date: createDateForCreateBooking(
          VehiclePlanningCreateBooking.returnDate.value,
          VehiclePlanningCreateBooking.returnDateHour.value,
          VehiclePlanningCreateBooking.returnDateMin.value
        )
      },
      carSharingInfo: {
        usageType: VehiclePlanningCreateBooking.usageType.value
      }
    };

    const confirmDetails = {
      paidBooking: VehiclePlanningCreateBooking.paidBooking.value,
      comment: VehiclePlanningCreateBooking.comment.value
    };

    const dataEnhanced = enhanceBookingCreationRequestParams(memberDetails, bookingDetails, confirmDetails, false);

    dataEnhanced.usageOverride = usageOverride;
    dataEnhanced.replacementVehicle = vehicleUsageSwitch(usageOverride);
    dataEnhanced.reservedSeats = 1;
    dataEnhanced.type = BOOKING_TYPE_CAR_SHARING;

    if (!!safe(() => detailMember.enterprise.chorusData)) {
      const chorusData = {
        administration: true,
        chorusAlias: VehiclePlanningCreateBooking.chorusAlias.value,
        chorusEngagement: VehiclePlanningCreateBooking.chorusEngagement.value,
        chorusMarche: VehiclePlanningCreateBooking.chorusMarche.value,
        chorusService: VehiclePlanningCreateBooking.chorusService.value
      };
      dataEnhanced.chorusData = { ...chorusData };
    }
    dataEnhanced.preBooking = isPreBookingCreation && VehiclePlanningCreateBooking.prebook.value === PREBOOKING;

    if (_get(VehiclePlanningCreateBooking.from.value, '_isSite')) {
      dataEnhanced.start.parking = {
        id: VehiclePlanningCreateBooking.from.value.address.id
      };
    } else {
      dataEnhanced.start.coordinates = VehiclePlanningCreateBooking.from.value.coordinates;
      dataEnhanced.start.address = {
        country: VehiclePlanningCreateBooking.from.value.country,
        formatAddress: VehiclePlanningCreateBooking.from.value.formatAddress,
        postalCode: VehiclePlanningCreateBooking.from.value.postalCode
      };
    }

    if (VehiclePlanningCreateBooking.tripType.value === ONE_WAY_TRIP) {
      delete dataEnhanced.end;
    } else {
      if (_get(VehiclePlanningCreateBooking.to.value, '_isSite')) {
        dataEnhanced.end.parking = {
          id: VehiclePlanningCreateBooking.to.value.address.id
        };
      } else {
        dataEnhanced.end.coordinates = VehiclePlanningCreateBooking.to.value.coordinates;
        dataEnhanced.end.address = {
          country: VehiclePlanningCreateBooking.to.value.country,
          formatAddress: VehiclePlanningCreateBooking.to.value.formatAddress,
          postalCode: VehiclePlanningCreateBooking.to.value.postalCode
        };
      }
    }
    if (VehiclePlanningCreateBooking.voucherCode.value) {
      dataEnhanced.voucherCode = VehiclePlanningCreateBooking.voucherCode.value;
    }

    if (VehiclePlanningCreateBooking.memberTypeId.value) {
      dataEnhanced.memberTypeId = VehiclePlanningCreateBooking.memberTypeId.value;
    }

    this.props.dispatch(createBookingVehiclePlanning(dataEnhanced));
  }

  getTripTypeField() {
    const {
      fields: {
        tripType,
        usageOverride: { value: usage }
      },
      currentContract: { interfaceConfigDto }
    } = this.props;

    const { oneWayDisplay, returnTripDisplay } = interfaceConfigDto || {};

    if (oneWayDisplay && returnTripDisplay && (usage === VEHICLE_USAGE_TYPE_PLANNED || usage === VEHICLE_USAGE_TYPE_IMMEDIATE)) {
      return (
        <div className="trip-type">
          <EkRadio
            formRowItemKey="backUserForm_userRole"
            items={[
              {
                labelKey: 'search_vehicle_oneWay',
                value: ONE_WAY_TRIP
              },
              {
                labelKey: 'search_vehicle_return',
                value: ROUND_TRIP
              }
            ]}
            namespace="tripType"
            field={tripType}
          >
            <FieldErrorMsg field={tripType} customClass="fieldErrorMsg--backUserForm" />
          </EkRadio>
        </div>
      );
    }
  }

  getChangeMemberTypeField() {
    const {
      fields: { memberTypeId },
      memberRules
    } = this.props;

    if (memberRules.type) {
      return (
        <BoxedSelect
          noEmptyValue
          options={this.memberTypeOptions}
          disabled={this.state.memberTypeSelectDisabled}
          labelKey="member_type_association_label"
          id="memberType_select"
          field={memberTypeId}
          sort={false}
          fullWidth
        />
      );
    }
  }

  prebookSelect() {
    const {
      fields: { prebook },
      preSubMemberStatus,
      VehiclePlanningCreateBooking
    } = this.props;
    const { usageOverride } = getValues(VehiclePlanningCreateBooking) || {};

    if (
      usageOverride === VEHICLE_USAGE_TYPE_IMMEDIATE ||
      usageOverride === VEHICLE_USAGE_TYPE_PLANNED ||
      preSubMemberStatus === STATUS_TO_COMPLETE
    )
      return '';
    else {
      if (preSubMemberStatus === STATUS_APPROVED) {
        return (
          <div className="frow prebook">
            <div className="frow-label">
              <FormattedMessage id="booking-prebooking-selector-label" />
            </div>
            <BoxedSelect
              formRowItemKey="searchVehicle_from_pickupDateHour"
              options={[
                { value: BOOKING, labelKey: 'common_booking' },
                { value: PREBOOKING, labelKey: 'common_preBooking' }
              ]}
              customClass="searchVehicle_boxedSelectWrapper"
              hideLabel
              noEmptyValue
              field={prebook}
            />
          </div>
        );
      }
    }
  }

  displayChorusFields() {
    const {
      fields: { chorusAlias, chorusEngagement, chorusMarche, chorusService },
      detailMember
    } = this.props;
    if (safe(() => detailMember.enterprise.chorusData.administration)) {
      return (
        <div className="specificities">
          <div className="label">
            <FormattedMessage id="members_detail_enterprise_chorus_detail" />
          </div>
          <FormField labelKey="members_detail_enterprise_chorus_chorusAlias" type="text" field={chorusAlias} maxlength="255" />
          <FormField labelKey="members_detail_enterprise_chorus_chorusEngagement" type="text" field={chorusEngagement} maxlength="255" />
          <FormField labelKey="members_detail_enterprise_chorus_chorusMarche" type="text" field={chorusMarche} maxlength="255" />
          <FormField labelKey="members_detail_enterprise_chorus_chorusService" type="text" field={chorusService} maxlength="255" />
        </div>
      );
    }
  }

  render() {
    const {
      sitesList,
      secondDaySelected,
      openCreateBookingModal,
      secondHourSelected,
      customFields,
      handleSubmit,
      fields,
      loginForCreateBooking,
      preSubMemberStatus,
      currentContract: { businessCarSharing, privateCarSharing },
      userDetailCreateBooking,
      visibleUsageOptions,
      intl,
      fields: {
        from,
        to,
        pickupDate,
        pickupDateHour,
        pickupDateMin,
        returnDate,
        returnDateHour,
        returnDateMin,
        tripType,
        comment,
        paidBooking,
        usageType,
        voucherCode,
        usageOverride,
        isPrivate,
        prebook
      }
    } = this.props;

    const sitesListEnhanced = enhanceSiteList(sitesList);
    const now = new Date();
    const minReturnDate = pickupDate && typeof pickupDate.value === 'object' ? pickupDate.value : now;
    const vehicle = Object.keys(secondDaySelected).length > 0 ? secondDaySelected.vehicle : secondHourSelected.vehicle;
    // const vehicleUsage = _get(vehicle, 'usage');
    this.setHoursOptions('pickupDate');
    this.setHoursOptions('returnDate');

    return (
      <Dialog
        modal={false}
        open={openCreateBookingModal}
        onRequestClose={this.handleClose}
        className="new-vehicle-planning-booking-create"
        contentStyle={{ width: '80%', padding: 0, maxWidth: 'none' }}
        title={
          <div className="header-title">
            <FormattedMessage id={prebook.value === PREBOOKING ? 'create_prebooking_for' : 'create_booking_for'} />
            <span className="member-name">{userDetailCreateBooking}</span>
            <div className="new-vehicle-planning_closeIconWrap">
              <IconButton onClick={this.handleClose}>
                <CloseIcon />
              </IconButton>
            </div>
          </div>
        }
        bodyStyle={{ padding: 0 }}
        style={{ padding: 0 }}
      >
        <form action="#" className="new-vehicle-planning-edit-booking" onSubmit={handleSubmit(this.handleSave)}>
          <div className="new-vehicle-planning-edit-booking_container">
            <div className="new-vehicle-planning-edit-booking_col1">
              {this.prebookSelect()}

              <div className="frow user-detail">
                <div className="frow-label">
                  <FormattedMessage id="booking-member" />
                </div>

                {/* <ReactSVG src="/img/edit.svg" className="align-svg  edit-svg" /> */}

                <Tooltip
                  content={intl.formatMessage({ id: 'members_status_' + preSubMemberStatus.toLowerCase() })}
                  placement="right"
                  key={'memberStatus'}
                >
                  <div className="detail">
                    <span className="user-name">{userDetailCreateBooking}</span>
                    <span className="user-mail" children={loginForCreateBooking}></span>
                  </div>
                </Tooltip>
              </div>
              <div className="frow trip">
                <div className="frow-label">
                  <FormattedMessage id="booking-address" />
                </div>
                <ul className={classnames('trip-info', { loop: tripType.value !== ONE_WAY_TRIP })}>
                  <li className="item">
                    <span className="item">
                      <AddressAutocomplete field={from} fixtures={sitesListEnhanced} disabled />
                    </span>
                  </li>
                  {tripType.value !== ONE_WAY_TRIP && (
                    <li className="item">
                      <span className="item">
                        <AddressAutocomplete
                          field={tripType.value !== ONE_WAY_TRIP ? to : { value: '-' }}
                          fixtures={this.renderSuggestFiltered(sitesListEnhanced)}
                          disabled
                        />
                      </span>
                    </li>
                  )}
                </ul>

                {this.getTripTypeField()}
              </div>
              <div className="frow datetime">
                <div className="frow-label">
                  <FormattedMessage id="booking-dates" />
                </div>
                <div className="new-vehicle-planning-edit-booking_col1Row2 paddingTop0">
                  <BoxedInput
                    formRowItemKey="searchVehicle_from_pickupDate"
                    skinType="date"
                    type="date"
                    customClass="boxedInputWrapper--label _is_year_hidden new-vehicle-planning-edit-booking_col1Row2_pickupDate"
                    labelKey="search_vehicle_pickup"
                    field={pickupDate}
                    minDate={now}
                    disableYearSelection
                    shouldDisableDate={this.getClosedDays}
                  >
                    <FieldErrorMsg field={pickupDate} customClass="fieldErrorMsg--searchVehicleForm" />
                  </BoxedInput>

                  <div className="new-vehicle-planning-edit-booking_col1Row2_dateWrap">
                    <BoxedSelect
                      formRowItemKey="searchVehicle_from_pickupDateHour"
                      labelKey="search_vehicle_pickup_hour"
                      options={this.hoursOptions['pickupDate']}
                      customClass="searchVehicle_boxedSelectWrapper"
                      hideLabel
                      field={pickupDateHour}
                    />
                    <span className="new-vehicle-planning-edit-booking_col1Row2_dvojtecka">:</span>
                    <BoxedSelect
                      formRowItemKey="searchVehicle_from_pickupDateMin"
                      labelKey="search_vehicle_pickup_min"
                      options={this.pickupMinutesOptions}
                      customClass="searchVehicle_boxedSelectWrapper"
                      hideLabel
                      field={pickupDateMin}
                    />
                    <TimeIcon />
                  </div>
                </div>
                {tripType.value !== ONE_WAY_TRIP && (
                  <div className="new-vehicle-planning-edit-booking_col1Row2">
                    <BoxedInput
                      formRowItemKey="searchVehicle_to_returnDate"
                      skinType="date"
                      type="date"
                      customClass="boxedInputWrapper--label new-vehicle-planning-edit-booking_col1Row2_pickupDate"
                      labelKey="search_vehicle_return_date"
                      field={returnDate}
                      minDate={minReturnDate}
                      mandatory={!(tripType.value === ONE_WAY_TRIP)}
                      disableYearSelection
                      disabled={tripType.value === ONE_WAY_TRIP}
                      shouldDisableDate={this.getClosedDays}
                    >
                      <FieldErrorMsg field={returnDate} customClass="fieldErrorMsg--searchVehicleForm" />
                    </BoxedInput>

                    <div className="new-vehicle-planning-edit-booking_col1Row2_dateWrap">
                      <BoxedSelect
                        formRowItemKey="searchVehicle_from_pickupDateHour"
                        labelKey="search_vehicle_pickup_hour"
                        options={this.hoursOptions['returnDate']}
                        customClass="searchVehicle_boxedSelectWrapper"
                        hideLabel
                        field={returnDateHour}
                        disabled={tripType.value === ONE_WAY_TRIP}
                      />
                      <span className="new-vehicle-planning-edit-booking_col1Row2_dvojtecka">:</span>
                      <BoxedSelect
                        formRowItemKey="searchVehicle_from_pickupDateMin"
                        labelKey="search_vehicle_pickup_min"
                        options={this.returnMinutesOptions}
                        customClass="searchVehicle_boxedSelectWrapper"
                        hideLabel
                        field={returnDateMin}
                        disabled={tripType.value === ONE_WAY_TRIP}
                      />
                      <TimeIcon />
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="new-vehicle-planning-edit-booking_col2">
              <div className="box" id="box">
                <div className="new-vehicle-planning-edit-booking_col2CarWrap">
                  <VehiclePlanningVehicleTile vehicle={vehicle} displayUsages={false} nolink>
                    <div className="price-wrapper">{this.displayPrice()}</div>
                  </VehiclePlanningVehicleTile>
                </div>

                <div className="specificities">
                  <div className="label">
                    <FormattedMessage id="booking-specificities" />
                  </div>
                  <div className={classnames('fields', { business: usageType.value === BOOKING_USAGE_TYPE_BUSINESS })}>
                    {visibleUsageOptions.length && (
                      <div id="vehicleUsage" className={classnames('new-vehicle-planning_usageSelector', 'vehicle-usage-selector')}>
                        <BoxedSelect
                          labelKey="label_booking_usage"
                          noEmptyValue
                          options={visibleUsageOptions}
                          field={usageOverride}
                          fullWidth
                        />
                      </div>
                    )}

                    <div className="new-vehicle-planning_usageSelector">
                      {businessCarSharing && privateCarSharing && (
                        <div className="realm-label">
                          <FormattedMessage id="credit-card-required" />
                        </div>
                      )}

                      <div className="private-realm">
                        {businessCarSharing && privateCarSharing && (
                          <GreenSwitch name="isPrivate" field={isPrivate} onClick={this.activeCreditCard} />
                        )}
                        {!businessCarSharing && privateCarSharing && (
                          <GreenSwitch name="isPrivate" disabled field={isPrivate} onClick={this.activeCreditCard} />
                        )}
                        {usageType.value !== BOOKING_USAGE_TYPE_BUSINESS && (
                          <IsGranted
                            allowed={[
                              BACKUSER_ROLE_ADMIN,
                              BACKUSER_ROLE_SUPER_ADMIN,
                              BACKUSER_ROLE_ROOT,
                              BACKUSER_ROLE_ZONE_MANAGER,
                              BACKUSER_ROLE_ADMIN_DEALER
                            ]}
                          >
                            <BoxedSelect
                              options={[
                                { labelKey: 'booking_free_of_charge', value: 'false' },
                                { labelKey: 'booking_create_confirmation_usage', value: 'true' }
                              ]}
                              namespace="freeofcharges"
                              className="new-vehicle-planning_usageSelector"
                              field={paidBooking}
                              noEmptyValue
                            />
                          </IsGranted>
                        )}
                      </div>
                    </div>
                    <div className="new-vehicle-planning_usageSelector">
                      {usageType.value !== BOOKING_USAGE_TYPE_BUSINESS && this.getChangeMemberTypeField()}
                    </div>
                  </div>
                </div>
                {this.displayChorusFields()}

                {usageType.value !== BOOKING_USAGE_TYPE_BUSINESS && (
                  <Dialog
                    open={this.state.openVoucher}
                    modal={false}
                    contentClassName="voucher-dialog"
                    contentStyle={{ width: '400px', padding: 0, maxWidth: 'none' }}
                    onRequestClose={() => this.setState({ openVoucher: false })}
                    title={
                      <div className="header-dialog">
                        <FormattedMessage id="apply-voucher-header" />

                        <div className="new-vehicle-planning_closeIconWrap">
                          <IconButton onClick={() => this.setState({ openVoucher: false })}>
                            <CloseIcon />
                          </IconButton>
                        </div>
                      </div>
                    }
                  >
                    <div className="content-dialog">
                      <BoxedInput
                        formRowItemKey="searchVehicle_voucherCode"
                        type="text"
                        customClass="boxedInputWrapper"
                        labelKey="common_searchFor_voucherCode"
                        field={voucherCode}
                        autoFocus
                        // onChange={this.updatePriceAuto}
                      />
                      <EkButton type="button" formRowItemKey="voucher-trigger" customClass="voucher_button" onAction={this.getPrice}>
                        <FormattedMessage id="common_apply" />
                      </EkButton>
                    </div>
                  </Dialog>
                )}

                <div className="label">
                  <FormattedMessage id="booking-additional-infos" />
                </div>
                <CustomFieldsBooking customFields={customFields} customFieldsEditMode formFields={fields} usage={usageOverride.value} />

                <FormRow customClass="addBookingConfirmationForm_row">
                  <EkTextarea labelKey="booking_create_confirmation_comment" customClass="createBooking_textarea" field={comment} />
                </FormRow>

                <div className="addBookingConfirmationForm_buttonRow">
                  <EkButton
                    type="button"
                    skinType="reverse"
                    formRowItemKey="vehicleForm_step_next"
                    customClass="addBookingConfirmationForm_button spacer"
                    onAction={this.handleClose}
                  >
                    <FormattedMessage id="booking_create_confirmation_abort_button" />
                  </EkButton>

                  <EkButton
                    type="button"
                    formRowItemKey="vehicleForm_step_next"
                    customClass="addBookingConfirmationForm_button main-bg"
                    onAction={handleSubmit(this.handleSave)}
                  >
                    <FormattedMessage id="booking_create_confirmation_save_button" />
                  </EkButton>
                </div>
              </div>
            </div>
          </div>
        </form>
      </Dialog>
    );
  }
}

export default namedCompose(
  reduxForm(
    {
      onSubmitFail: scrollToFirstErrorDialog,
      form: 'VehiclePlanningCreateBooking',
      overwriteOnInitialValuesChange: false
    },
    state => {
      const customFields = planningBookingCustomFieldsSelector(state);
      const currentContract = headerCompanyContractSelector(state);

      const {
        vehiclePlanning: {
          secondDaySelected,
          firstDaySelected,
          firstHourSelected,
          secondHourSelected,
          startDate,
          data,
          openCreateBookingModal,
          loginForCreateBooking,
          profileIdForCreateBooking,
          userDetailCreateBooking,
          preSubMemberStatus,
          profileReviewStatus,
          isPreBookingCreation,
          selectedMemberId,
          selectedMemberType,
          bookingPrice,
          loadingBookingPrice
        },
        sites: { list },
        parkings: { bankHolidays },
        members: { detailMember },
        form: { VehiclePlanningCreateBooking },
        memberTypes: { list: memberTypes }
      } = state;

      const { usageOverride, prebook } = getValues(VehiclePlanningCreateBooking) || {};
      const { chorusData } = detailMember.enterprise || {};
      const isPrivate = currentContract.privateCarSharing && !currentContract.businessCarSharing;
      const dynamicInitialValues = _get(firstDaySelected, 'parking')
        ? createInitValuesCreateBooking(secondDaySelected.parking, firstDaySelected, secondDaySelected, startDate)
        : createInitValuesCreateBookingHourMode(secondHourSelected.parking, firstHourSelected, secondHourSelected, startDate);
      const sharedInitialValues = createSharedInitValuesCreateBooking(state);

      return {
        fields: [
          'memberTypeId',
          'from',
          'to',
          'pickupDate',
          'pickupDateHour',
          'pickupDateMin',
          'returnDate',
          'returnDateHour',
          'returnDateMin',
          'passengers',
          'tripType',
          'comment',
          'voucherCode',
          'usageOverride',
          'paidBooking',
          'usageType',
          'isPrivate',
          'prebook',
          'chorusAlias',
          'chorusEngagement',
          'chorusMarche',
          'chorusService',
          ...createCustomFieldsNames(customFields)
        ],
        validate: createValidator({
          ...createCustomFieldsValidators(customFields, usageOverride),
          from: [notEmpty(), addressOrSite()],
          to: [
            stopValidationIf({
              condition: (props, value) => {
                return !value;
              }
            }),
            addressOrSite()
          ],
          pickupDate: [notEmpty()],
          tripType: [notEmpty()],
          returnDate: [
            stopValidationIf({
              condition: props => {
                return props.form.tripType && props.form.tripType.value === ONE_WAY_TRIP ? props.form.tripType.value : false;
              }
            }),
            notEmpty(),
            dateAfterOrEqualField('pickupDate')
          ]
        }),
        customFields,
        data,
        secondDaySelected,
        sitesList: list,
        VehiclePlanningCreateBooking,
        bankHolidays,
        openCreateBookingModal,
        loginForCreateBooking,
        profileIdForCreateBooking,
        profileReviewStatus,
        userDetailCreateBooking,
        preSubMemberStatus,
        isPreBookingCreation,
        firstDaySelected,
        secondHourSelected,
        firstHourSelected,
        memberTypes,
        detailMember,
        selectedMemberId,
        selectedMemberType,
        bookingPrice,
        loadingBookingPrice,
        currentContract,
        memberRules: headerContractMemberValuesSelector(state),
        paymentRules: headerContractPaymentValuesSelector(state),
        visibleUsageOptions: vpAddBookingUsageOptionsSelector(state),
        initialValues: { ...dynamicInitialValues, ...sharedInitialValues, isPrivate, prebook, ...chorusData },
        locale: localeSelector(state) // pure exception
      };
    }
  ),
  injectIntl,
  pure
)(VehiclePlanningCreateBooking);
