import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import cs from 'classnames';
import DashboardItemV2 from './Item';
import RefreshIcon from 'material-ui/svg-icons/navigation/refresh';
import { BACKUSER_ROLE_ROOT, BACKUSER_ROLE_SUPER_ADMIN } from '../../../constants/backend-constants';
import { connect } from 'react-redux';
import IsGranted from '../../../components/IsGranted/IsGranted';
import { Tooltip } from 'react-tippy';
import { getMsg } from '../../../utils/IntlGlobalProvider';
import { dashboardLinks as links } from '../../../constants/api-params-constants';
import { headerContractMemberValuesSelector } from '../../../selectors/all-selectors';
import { refreshPage } from '../../../routing/helpers-routing';
import { addTippyContent } from '../../../utils/utils';

class DashboardV2 extends Component {
  updateDashboard() {
    refreshPage();
  }

  getTooltipMsg() {
    return <div className="dashboard-update-tooltip">{getMsg('common_update') + ': ' + getMsg('side_menu_section_dashboard')}</div>;
  }

  render() {
    const props = this.props;

    return (
      <div className={cs('dashboard-page', 'mainContainer_content', 'pageContainer')}>
        <div className="sc-main">
          <div className="sc-title">
            <FormattedMessage id="side_menu_section_dashboard" />
            <div className="sc-update-wrap">
              <button onClick={this.updateDashboard}>
                <Tooltip position="left" distance={15} delay={100} {...addTippyContent(this.getTooltipMsg())}>
                  <RefreshIcon />
                </Tooltip>
              </button>
            </div>
          </div>
          <div className="sc-content">
            <DashboardItemV2
              id="dashboard-bookings"
              label={getMsg('dashboard_last_bookings_label')}
              count={props.lastBookings.count}
              apiCompanyId={props.lastBookings.companyId}
              link={links.lastBookings}
              icon="/img/dashboard/in-progress.svg"
              invertColors
            />
            <DashboardItemV2
              id="dashboard-delayed-bookings"
              label={getMsg('dashboard_delayed_bookings_label')}
              count={props.delayedBookings.count}
              apiCompanyId={props.delayedBookings.companyId}
              link={links.delayedBookings}
              icon="/img/dashboard/delayed.svg"
            />
            <DashboardItemV2
              id="dashboard-damages"
              label={getMsg('dashboard_damages_label')}
              count={props.unmanagedDamages.count}
              apiCompanyId={props.unmanagedDamages.companyId}
              link={links.unmanagedDamages}
              icon="/img/dashboard/damages.svg"
            />
            <DashboardItemV2
              id="dashboard-cleanliness"
              label={getMsg('dashboard_cleanliness_label')}
              count={props.cleanlinessProblems.count}
              apiCompanyId={props.cleanlinessProblems.companyId}
              link={links.cleanlinessProblems}
              icon="/img/dashboard/dirty.svg"
            />
            <DashboardItemV2
              id="dashboard-new-members"
              label={getMsg('dashboard_member_applicants_label')}
              count={props.nonValidatedMembers.count}
              apiCompanyId={props.nonValidatedMembers.companyId}
              link={links.nonValidatedMembers}
              icon="/img/dashboard/candidates.svg"
            />
            {this.props.memberRules.expediteReview && (
              <DashboardItemV2
                id="dashboard-expedite-members"
                label={getMsg('dashboard_member_expedite_label')}
                count={props.expediteMembers.count}
                apiCompanyId={props.expediteMembers.companyId}
                link={links.expediteMembers}
                icon="/img/dashboard/prioritized-candidates.svg"
              />
            )}
            <DashboardItemV2
              id="dashboard-low-battery"
              label={getMsg('dashboard_low_battery_vehicles_label')}
              count={props.lowAccessoryBatVehicles.count}
              apiCompanyId={props.lowAccessoryBatVehicles.companyId}
              link={links.lowAccessoryBatVehicles}
              icon="/img/dashboard/accessory-battery.svg"
            />
            <DashboardItemV2
              id="dashboard-low-fuel"
              label={getMsg('dashboard.fuelCharge.Level')}
              count={props.lowFuelLevel.count}
              apiCompanyId={props.lowFuelLevel.companyId}
              link={links.lowFuelLevel}
              icon="/img/dashboard/low-fuel-charge.svg"
            />
            <IsGranted allowed={[BACKUSER_ROLE_ROOT, BACKUSER_ROLE_SUPER_ADMIN]}>
              <DashboardItemV2
                id="dashboard-failed-bookings"
                label={getMsg('dashboard_failed_bookings_label')}
                count={props.failedBookings.count}
                apiCompanyId={props.failedBookings.companyId}
                link={links.failedBookings}
                icon="/img/dashboard/error.svg"
              />
            </IsGranted>
            <IsGranted allowed={[BACKUSER_ROLE_ROOT, BACKUSER_ROLE_SUPER_ADMIN]}>
              <DashboardItemV2
                id="dashboard-failed-rrs-bookings"
                label={getMsg('dashboard_failed_rrs_bookings_label')}
                count={props.rrsFailedBookings.count}
                apiCompanyId={props.rrsFailedBookings.companyId}
                link={links.rrsFailedBookings}
                icon="/img/dashboard/error.svg"
              />
            </IsGranted>
            <DashboardItemV2
              id="non-operating-vehicles"
              label={getMsg('label.non.operating.vehicles')}
              count={props.nonOperatingVehicles.count}
              apiCompanyId={props.nonOperatingVehicles.companyId}
              link={links.nonOperatingVehicles}
              icon="/img/dashboard/vehicle-warning.svg"
            />
          </div>
        </div>
      </div>
    );
  }
}

export default connect(state => {
  const {
    dashboard: {
      lastBookings,
      delayedBookings,
      nonValidatedMembers,
      unmanagedDamages,
      cleanlinessProblems,
      failedBookings,
      expediteMembers,
      rrsFailedBookings,
      lowAccessoryBatVehicles,
      nonOperatingVehicles,
      lowFuelLevel
    }
  } = state;

  return {
    lastBookings,
    delayedBookings,
    nonValidatedMembers,
    unmanagedDamages,
    cleanlinessProblems,
    failedBookings,
    expediteMembers,
    rrsFailedBookings,
    lowAccessoryBatVehicles,
    nonOperatingVehicles,
    lowFuelLevel,
    memberRules: headerContractMemberValuesSelector(state)
  };
})(DashboardV2);
