import { routeActions } from 'react-router-redux';
import _identity from 'lodash/identity';
import _pickBy from 'lodash/pickBy';
import _get from 'lodash/get';
import memoizeOne from 'memoize-one';

import {
  getBookingsList,
  getFleetList,
  getSmartcardsEventsList,
  getSmartcardsList,
  getFeedbacksList,
  getBackUsersList,
  getInvoicesList,
  getCompaniesListWithSearch,
  getVoucherGroups,
  getBankoutList,
  getMembersList,
  getList
} from '../actions/all-actions';
import { getStore } from '../store/all-store';
import { apiParams } from '../constants/api-params-constants';

export function refreshPage() {
  const path = window.location.hash.slice(1);
  // this is a workaround to be able to reload the current page and replay its onEnter hooks
  getStore().dispatch(routeActions.replace('/redirect/' + encodeURIComponent(path)));
}

const parseUrl = memoizeOne((url, { urlParams = {} } = {}) => {
  let params = {};
  try {
    params = JSON.parse(decodeURIComponent(url[1]));
  } catch (e) {
    params = urlParams;
  }
  return params;
});

function getPageParams(url) {
  const params = parseUrl(url); // get url params
  return { number: 1, size: _get(params, 'page.size', apiParams.default.page.size) };
}

export function refreshPageWithDefaultPagination(parseParamsFromUrl) {
  const path = window.location.hash.slice(1); // get current url without first char wich is #
  const url = path.split('/').filter(path => !!path.length); // split URL by slash char and remove empty strings
  const store = getStore();
  const state = store.getState();
  const urlParams = parseParamsFromUrl ? url : null;

  // for pages that use AdvancedList component... they work on different routing principle
  // this is used also to update page after all-companies select

  if (url[0] === 'vouchers') {
    store.dispatch(getVoucherGroups({ ...parseUrl(urlParams, state.vouchers), page: getPageParams(url) }));
  } else if (url[0] === 'bookings') {
    store.dispatch(getBookingsList({ ...parseUrl(urlParams, state.bookings), page: getPageParams(url) }));
  } else if (url[0] === 'bankouts') {
    store.dispatch(getBankoutList({ ...parseUrl(urlParams, state.bankouts), page: getPageParams(url) }));
  } else if (url[0] === 'fleet') {
    store.dispatch(getFleetList({ ...parseUrl(urlParams, state.vehicles), page: getPageParams(url) }));
  } else if (url[0] === 'smartcardsEvents') {
    store.dispatch(
      getSmartcardsEventsList(_pickBy({ ...parseUrl(urlParams, state.smartcardsEvents), page: getPageParams(url) }, _identity))
    );
  } else if (url[0] === 'back-users') {
    store.dispatch(getBackUsersList(_pickBy({ ...parseUrl(urlParams, state.backUsers), page: getPageParams(url) }, _identity)));
  } else if (url[0] === 'smartcards') {
    store.dispatch(getSmartcardsList(_pickBy({ ...parseUrl(urlParams, state.smartcards), page: getPageParams(url) }, _identity)));
  } else if (url[0] === 'feedbacks') {
    store.dispatch(getFeedbacksList(_pickBy({ ...parseUrl(urlParams, state.feedbacks), page: getPageParams(url) }, _identity)));
  } else if (url[0] === 'companies') {
    store.dispatch(getCompaniesListWithSearch(_pickBy({ ...parseUrl(urlParams, state.companies), page: getPageParams(url) }, _identity)));
  } else if (url[0] === 'invoices')
    store.dispatch(getInvoicesList(_pickBy({ ...parseUrl(urlParams, state.invoices), page: getPageParams(url) }, _identity)));
  else if (url[0] === 'members') {
    store.dispatch(getMembersList(_pickBy({ ...parseUrl(urlParams, state.members), page: getPageParams(url) }, _identity)));
  } else if (url[0] === 'parkings') {
    store.dispatch(getList());
  } else if (url[0] !== 'quicksight') {
    // this is a workaround to be able to reload the current page and replay its onEnter hooks
    store.dispatch(routeActions.replace('/redirect/' + encodeURIComponent('/' + url.join('/'))));
  }
}
