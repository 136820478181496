import { getAppObj } from '../utils/utils';
import localTheme from './local-theme-name';
import config from '../../../config/config';

const { themeName } = getAppObj();
let currentTheme = localTheme;

if (themeName && themeName !== '{{themeName}}') {
  currentTheme = themeName;
}

const colors = config.themesColors[currentTheme];
const availableLanguages = config.themesLanguages[currentTheme];

export default {
  colors,
  currentTheme,
  availableLanguages
};
