import * as types from '../constants/actionTypes-constants';
import callApi from './api-actions';
import _sortBy from 'lodash/sortBy';
import { addErrorMessage } from '../utils/utils';
import { addFlashMessage } from './flashMessage-actions';
import { FLASH_MESSAGE_TYPE_SUCCESS } from '../constants/generic-constants';

export function getModelsList(brandId, loader = true) {
  return dispatch => {
    dispatch({ type: types.MODELS_GET_LIST_REQUEST });

    return dispatch(callApi('models', 'getList', brandId, loader)).then(
      data => {
        data = _sortBy(data, 'name');
        dispatch({ type: types.MODELS_GET_LIST_SUCCESS, list: data });
        return Promise.resolve(data);
      },
      error => {
        dispatch(addErrorMessage({ error }));
        dispatch({ type: types.MODELS_GET_LIST_ERROR });
        return Promise.reject(error);
      }
    );
  };
}

export function createModelRequest({ id, ...params }) {
  return dispatch => {
    return dispatch(callApi('models', 'createModel', { id, params })).then(
      data => {
        dispatch(createModelSuccess(data));
        return Promise.resolve(data);
      },
      error => {
        const params = { error, def: 'modelForm_create_model_error', errorCodePrefixes: ['modelForm_'] };
        dispatch(addErrorMessage(params));
        return Promise.reject(error);
      }
    );
  };
}

export function createModelSuccess(model) {
  return {
    type: types.MODELS_CREATE_MODEL_SUCCESS,
    model
  };
}

export function emptyModelList(model) {
  return {
    type: types.MODELS_GET_LIST_EMPTY,
    model
  };
}

export function getSingleModel(modelId) {
  return dispatch => {
    return dispatch(callApi('models', 'getModel', modelId)).then(
      data => {
        dispatch({ type: types.MODELS_GET_SINGLE_MODEL_SUCCESS, model: data });
        return Promise.resolve(data);
      },
      error => {
        dispatch(addErrorMessage({ error }));
        return Promise.reject(error);
      }
    );
  };
}

export function updateModelRequest({ id, ...params }) {
  return dispatch => {
    return dispatch(callApi('models', 'updateModel', { id, params })).then(
      data => {
        dispatch(updateModelSuccess(data));
        dispatch(
          addFlashMessage({
            contentKey: 'modelForm_update_model_success',
            type: FLASH_MESSAGE_TYPE_SUCCESS
          })
        );
        return Promise.resolve(data);
      },
      error => {
        dispatch(addErrorMessage({ error, def: 'modelForm_update_model_error' }));
        return Promise.reject(error);
      }
    );
  };
}

export function updateModelSuccess(model) {
  return {
    type: types.MODELS_UPDATE_MODEL_SUCCESS,
    model
  };
}
