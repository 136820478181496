import React, { Component, PropTypes } from 'react';
import { FormattedMessage } from 'react-intl';
import { reduxForm } from 'redux-form';

import FormRow from '../FormRow/FormRow';
import BoxedInput from '../BoxedInput/BoxedInput';
import EkButton from '../EkButton/EkButton';
import FieldErrorMsg from '../FieldErrorMsg/FieldErrorMsg';
import { createValidator, notEmpty, email } from '../../validation/all-validation';
import { scrollToFirstError } from '../../utils/utils';

class ChangeEmailForm extends Component {
  render() {
    const {
      fields: { login },
      handleSubmit
    } = this.props;

    return (
      <section className="changeEmailFormWrapper">
        <form action="#" className="changeEmailForm" onSubmit={handleSubmit(this.props.onCallback)}>
          <div className="changeEmailForm_container">
            <h4 className="changeEmailForm_title">
              <FormattedMessage id="changeEmailForm_title" />
            </h4>

            <FormRow customClass="changeEmailForm_row">
              <BoxedInput
                formRowItemKey="changeEmailForm_login"
                id="login"
                type="email"
                placeholder="Email"
                customClass="resetPasswordForm_boxedInput"
                field={login}
              >
                <FieldErrorMsg field={login} customClass="fieldErrorMsg--companyForm" />
              </BoxedInput>
            </FormRow>

            <FormRow customClass="changeEmailForm_row">
              <EkButton
                type="button"
                skinType="reverse"
                formRowItemKey="changeEmailForm_abortBtn"
                customClass="changeEmail_button"
                onAction={this.props.onAbort}
              >
                <FormattedMessage id="changeEmailForm_abort_button" />
              </EkButton>

              <EkButton
                type="button"
                formRowItemKey="changeEmailForm_submitBtn"
                customClass="changeEmail_button"
                onAction={handleSubmit(this.props.onCallback)}
              >
                <FormattedMessage id="changeEmailForm_save_button" />
              </EkButton>
            </FormRow>
          </div>
        </form>
      </section>
    );
  }
}

ChangeEmailForm.displayName = 'ChangeEmailForm';

ChangeEmailForm.propTypes = {
  fields: PropTypes.object,
  handleSubmit: PropTypes.func,
  onCallback: PropTypes.func,
  onAbort: PropTypes.func
};

ChangeEmailForm = reduxForm({
  onSubmitFail: scrollToFirstError,
  form: 'changeEmailForm',
  fields: ['login'],
  validate: createValidator({
    login: [notEmpty(), email()]
  })
})(ChangeEmailForm);

export default ChangeEmailForm;
