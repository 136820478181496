import React, { Component, PropTypes } from 'react';
import config from '../../constants/config-constants';
import { RaisedButton } from 'material-ui';
import { injectIntl } from 'react-intl';
import FormRow from '../FormRow/FormRow';
import { serialize } from '../../api/ApiCaller';
import { formPost, namedCompose, valueFirstInit } from '../../utils/utils';
import { FormattedMessage } from 'react-intl';
import UploadIcon from 'material-ui/svg-icons/file/cloud-upload';
import { iconStyles } from '../../constants/style-constants';
import PanoramaFishEyeIcon from 'material-ui/svg-icons/image/panorama-fish-eye';
import { reduxForm } from 'redux-form';
import { localeSelector } from '../../selectors/all-selectors';
import { COMPANY_PAYBACK_SETTINGS_FORM } from '../../constants/form-constants';
import FieldErrorMsg from '../FieldErrorMsg/FieldErrorMsg';
import { ADD_BANK_DETAILS_FORM_FIELDS, BANK_ACCOUNT_DETAILS_FIELD } from '../../constants/generic-constants';
import classNames from 'classnames';
import AddressAutocomplete from '../AddressAutocomplete/AddressAutocomplete';
import BoxedSelect from '../BoxedSelect/BoxedSelect';
import { createValidator, isCountry } from '../../validation/sync-validation';

let worldpayRespUrl = 'https://admin.renault-mobility.com';
let worldpayUrl = 'https://www.envoytransfers.com/';
let worldpayMerchantId = '001144RSM';

if (config.devMode) {
  worldpayRespUrl = 'https://admin-valid-glide.tech.rcimobility.com';
  worldpayUrl = 'https://test.envoytransfers.com';
  worldpayMerchantId = '001265RSM';
}

const worldpayConfig = {
  merchantId: worldpayMerchantId,
  country: 'FR',
  service: 'BANKDETAILSCAPTURE',
  receiveCurrency: 'EUR'
};

class AddBankDetailsForm extends Component {
  constructor(props) {
    super(props);
    this.bindFunctions();
    this.constants();
    this.derivedStateFromProps(props);
  }

  // noinspection JSCheckFunctionSignatures
  componentWillReceiveProps(props) {
    this.derivedStateFromProps(props);
  }

  derivedStateFromProps(props) {
    this.handlePaymentVariables(props);
  }

  componentDidMount() {
    this.didUpdateCycle();
  }

  // noinspection JSCheckFunctionSignatures
  componentDidUpdate() {
    this.didUpdateCycle();
  }

  didUpdateCycle() {
    this.initField(this.props);
  }

  initField({ fields }) {
    const { [BANK_ACCOUNT_DETAILS_FIELD]: field } = fields;
    valueFirstInit.call(this, field);
  }

  bindFunctions() {
    this.handleAction = this.handleAction.bind(this);
  }

  constants() {
    this.searchType = ['country'];
  }

  paymentText() {
    const { detailsUploaded } = this.props;
    return (
      <div className="payment-status">
        {<div className="payment-icon">{this.paymentTextIcon}</div>}
        <span className={classNames({ 'ready-for-upload': detailsUploaded })}>
          <FormattedMessage id={this.paymentTextId} />
        </span>
      </div>
    );
  }

  handleAction() {
    const {
      callback,
      locale,
      companyId,
      fields: {
        bankCountry: { value: countryIso },
        bankCurrency: { value: currency }
      }
    } = this.props;

    const updatedConfig = { ...worldpayConfig };

    if (countryIso) updatedConfig.country = countryIso;
    if (currency) updatedConfig.receiveCurrency = currency;

    const data = { ...updatedConfig, language: locale, customerRef: companyId };
    const origin = window.location.origin;

    callback && callback();

    if (worldpayRespUrl !== origin && config.devMode) {
      const paybackSavePage = 'paybackSave';
      const params = { ...data, origin, url: worldpayUrl };
      window.location.href = worldpayRespUrl + '/#/' + paybackSavePage + '?' + serialize(params);
    } else formPost(worldpayUrl, data);
  }

  handlePaymentVariables(props) {
    const {
      bankDetailsPresent,
      detailsUploaded,
      intl: { formatMessage }
    } = props;

    if (detailsUploaded) {
      this.paymentTextId = 'bank_details_ready_for_upload';
      this.paymentTextIcon = <UploadIcon style={iconStyles.all} />;
      this.paymentDetailsButtonLabel = formatMessage({ id: 'subCompanies_paymentDetails_replace' });
    } else if (bankDetailsPresent) {
      this.paymentTextId = 'subCompanies_paymentDetails_existing';
      this.paymentTextIcon = <div className="circle-outline completed" />;
      this.paymentDetailsButtonLabel = formatMessage({ id: 'subCompanies_paymentDetails_replace' });
    } else {
      this.paymentTextId = 'subCompanies_paymentDetails_empty';
      this.paymentTextIcon = <PanoramaFishEyeIcon style={iconStyles.all} />;
      this.paymentDetailsButtonLabel = formatMessage({ id: 'subCompanies_paymentDetails_add' });
    }
  }

  uploadButton() {
    const { bankDetailsReadOnly, handleSubmit } = this.props;

    if (!bankDetailsReadOnly) {
      return (
        <div className="submit-container">
          <RaisedButton
            label={this.paymentDetailsButtonLabel}
            onClick={handleSubmit(this.handleAction)}
            primary
            className={classNames('button', 'flatButton')}
            type="submit"
            id="submit-bank-details"
          />
        </div>
      );
    }
  }

  getFormFields() {
    const {
      fields: { bankCurrency, bankCountry },
      currencyOptions,
      bankDetailsReadOnly
    } = this.props;

    if (!bankDetailsReadOnly) {
      return (
        <div className="bank-details-form-fields">
          <FormRow customClass="companyForm_row">
            <label className="boxedInput_label">
              <span className="boxedInput_labelText">
                <FormattedMessage id="common_autocomplete_country" />
              </span>
              <AddressAutocomplete
                id="bank-details-country-field"
                searchType={this.searchType}
                className="companyForm_AddressAutocomplete"
                field={bankCountry}
                translateCountryCode
              />
              <FieldErrorMsg field={bankCountry} customClass={classNames('fieldErrorMsg--companyForm', 'scope-error-field')} />
            </label>
            <BoxedSelect
              id="bank-details-currency"
              labelKey="invoice_params_label_currency"
              disabled
              noEmptyValue
              field={bankCurrency}
              options={currencyOptions}
            >
              <FieldErrorMsg customClass={classNames('fieldErrorMsg--companyForm', 'scope-error-field')} field={bankCurrency} />
            </BoxedSelect>
          </FormRow>
        </div>
      );
    }
  }

  render() {
    const {
      fields: { bankAccountDetails }
    } = this.props;

    return (
      <form className="bank-details-form">
        <h4 className="companyDetail_title">
          <FormattedMessage id="subCompanies_paymentDetails_title" />
        </h4>
        {this.paymentText()}
        <FieldErrorMsg customClass={classNames('fieldErrorMsg--companyForm', 'fieldErrorMsg--bankDetails')} field={bankAccountDetails} />
        {this.getFormFields()}
        {this.uploadButton()}
      </form>
    );
  }
}

AddBankDetailsForm.propTypes = {
  callback: PropTypes.func,
  bankDetailsReadOnly: PropTypes.bool,
  detailsUploaded: PropTypes.bool,
  companyId: PropTypes.string,
  bankDetailsPresent: PropTypes.bool,
  currencyOptions: PropTypes.array
};

export default namedCompose(
  reduxForm(
    {
      form: COMPANY_PAYBACK_SETTINGS_FORM,
      fields: ADD_BANK_DETAILS_FORM_FIELDS,
      validate: createValidator({
        bankCountry: [isCountry()]
      })
    },
    state => ({ locale: localeSelector(state).toUpperCase() })
  ),
  injectIntl
)(AddBankDetailsForm);
