import React, { Component } from 'react';
import ScheduledExportForm from './Form';
import { showParentView } from '../../../components/_v2/ColumnList/helpers';
import ListDetails from '../../../components/_v2/ColumnList/Details';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import {
  scheduledExportItemFiltersSelector,
  scheduledExportFormValuesSelector,
  scheduledExportIdSelector
} from '../../../selectors/all-selectors';
import { deleteScheduledExport } from '../../../actions/exports-actions';
import { getStore } from '../../../store/all-store';
import { addErrorMessage, fallbackFunc, intlMsg } from '../../../utils/utils';

export class ScheduledExportViewConfig extends Component {
  componentWillMount() {
    this.showParentView = showParentView.bind(this);

    this.handleDelete = () => {
      const { dispatch } = getStore();
      const { exportId } = this.props;

      if (exportId) {
        return dispatch(deleteScheduledExport(exportId)).then(this.showParentView, fallbackFunc);
      }
      dispatch(addErrorMessage({ error: { developerMessage: 'Delete export: no ID found' } }));
    };
  }

  render() {
    const { props: p } = this;

    return (
      <ListDetails title={intlMsg(this, 'scheduled.export.view')} onClose={this.showParentView}>
        <ScheduledExportForm initialValues={p.details} filtersInitialValues={p.filters} onDelete={this.handleDelete} isView />
      </ListDetails>
    );
  }
}

const mapStateToProps = state => {
  return {
    exportId: scheduledExportIdSelector(state),
    details: scheduledExportFormValuesSelector(state),
    filters: scheduledExportItemFiltersSelector(state)
  };
};

ScheduledExportViewConfig = connect(mapStateToProps)(ScheduledExportViewConfig);
ScheduledExportViewConfig = injectIntl(ScheduledExportViewConfig);

export default ScheduledExportViewConfig;
