import * as types from '../constants/actionTypes-constants';
import { safe } from '../utils/utils';

export default function brands(
  state = {
    list: [],
    loading: false,
    latestCreatedBrand: null,
    currentBrand: {}
  },
  action
) {
  switch (action.type) {
    case types.BRANDS_GET_LIST_REQUEST: {
      return { ...state, loading: true };
    }

    case types.BRANDS_GET_LIST_SUCCESS: {
      return { ...state, list: action.list, loading: false };
    }

    case types.BRANDS_GET_LIST_ERROR: {
      return { ...state, loading: false };
    }

    case types.BRANDS_CREATE_BRAND_SUCCESS: {
      return {
        ...state,
        latestCreatedBrand: action.brand,
        list: [{ ...action.brand }, ...state.list]
      };
    }

    case types.BRANDS_GET_SINGLE_BRAND_SUCCESS: {
      return { ...state, currentBrand: action.brand };
    }

    case types.BRANDS_UPDATE_BRAND_SUCCESS: {
      const { brand } = action;
      const { list } = state;

      safe(() => (list.find(item => item.id === brand.id).name = brand.name));

      return {
        ...state,
        currentBrand: brand,
        list: safe(() => [...list]) || []
      };
    }

    default: {
      return state;
    }
  }
}
