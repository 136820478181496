import React, { Component, PropTypes } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { reduxForm } from 'redux-form';
import _isUndefined from 'lodash/isUndefined';
import FormRow from '../FormRow/FormRow';
import IsGranted from '../IsGranted/IsGranted';
import BoxedInput from '../BoxedInput/BoxedInput';
import EkButton from '../EkButton/EkButton';
import FieldErrorMsg from '../FieldErrorMsg/FieldErrorMsg';
import { createValidator, notEmpty, length, priceNumber, number } from '../../validation/all-validation';
import { scrollToFirstError } from '../../utils/utils';
import { invoiceEditRules } from '../../constants/routes-constants';

class CreateInvoiceForm extends Component {
  render() {
    const {
      fields: { description, quantity, pricePerUnitWithVat },
      handleSubmit,
      intl: { formatMessage }
    } = this.props;
    const currencySymbol = _isUndefined(this.props.currency) ? '' : formatMessage({ id: `unit_${this.props.currency}` });
    return (
      <div className="createInvoiceForm_container">
        <IsGranted allowed={invoiceEditRules.include} disallowed={invoiceEditRules.exclude}>
          <form action="#" className="createInvoiceForm" onSubmit={handleSubmit(this.props.onCallback)}>
            <FormRow customWrapperClass="createInvoiceForm_row">
              <BoxedInput
                formRowItemKey="createInvoiceForm_number"
                type="text"
                id="createInvoiceForm_description"
                labelKey="createInvoiceForm_description"
                field={description}
                autoFocus
              >
                <FieldErrorMsg customClass="fieldErrorMsg--createInvoiceForm" field={description} />
              </BoxedInput>
            </FormRow>

            <FormRow customWrapperClass="createInvoiceForm_row">
              <BoxedInput
                formRowItemKey="createInvoiceForm_quantity"
                type="text"
                id="createInvoiceForm_quantity"
                labelKey="createInvoiceForm_quantity"
                field={quantity}
              >
                <FieldErrorMsg customClass="fieldErrorMsg--createInvoiceForm" field={quantity} />
              </BoxedInput>

              <BoxedInput
                formRowItemKey="createInvoiceForm_priceWithVAT"
                type="text"
                id="createInvoiceForm_priceWithVAT"
                label={
                  _isUndefined(this.props.currency)
                    ? formatMessage({ id: 'createInvoiceForm_priceWithVAT_noCurrency' })
                    : formatMessage({ id: 'createInvoiceForm_priceWithVAT' }, { currency: currencySymbol })
                }
                field={pricePerUnitWithVat}
              >
                <FieldErrorMsg customClass="fieldErrorMsg--createInvoiceForm" field={pricePerUnitWithVat} />
              </BoxedInput>
              <EkButton customClass="createInvoiceForm_actionsButton" onAction={handleSubmit(this.props.onCallback)}>
                <FormattedMessage id="createInvoiceForm_submit" />
              </EkButton>
            </FormRow>
          </form>
        </IsGranted>
      </div>
    );
  }
}

CreateInvoiceForm.displayName = 'CreateInvoiceForm';

CreateInvoiceForm.propTypes = {
  fields: PropTypes.object,
  handleSubmit: PropTypes.func,
  onCallback: PropTypes.func,
  limitedAccess: PropTypes.bool,
  currency: PropTypes.string
};

CreateInvoiceForm = reduxForm({
  onSubmitFail: scrollToFirstError,
  form: 'createInvoiceForm',
  touchOnBlur: false,
  fields: ['description', 'quantity', 'pricePerUnitWithVat'],
  validate: createValidator({
    description: [notEmpty(), length({ max: 500 })],
    quantity: [notEmpty(), number()],
    pricePerUnitWithVat: [notEmpty(), priceNumber()]
  })
})(CreateInvoiceForm);

export default injectIntl(CreateInvoiceForm);
