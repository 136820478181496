import React, { Component, PropTypes } from 'react';
import { FormattedMessage } from 'react-intl';
import { valueFirstInit } from '../../utils/utils';
import Tooltip from '../../components/Tooltip/Tooltip';

class EkRadio extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    this.didUpdateCycle();
  }

  // noinspection JSCheckFunctionSignatures
  componentDidUpdate() {
    this.didUpdateCycle();
  }

  didUpdateCycle() {
    valueFirstInit.call(this, this.props.field);
  }

  handleChange(e) {
    if (this.props.onChange) {
      this.props.onChange(e.target.value);
    }
    this.props.field.onChange(e.target.value);
  }

  render() {
    const {
      title,
      mandatory,
      titleKey,
      items,
      disabled,
      field,
      children,
      customWrapperClass,
      customTitleClass,
      customContainerClass,
      defaultValue,
      field: { name }
    } = this.props;

    const _mandatory = mandatory ? <span className="labelMandatory">*</span> : '';
    const options = items.map(item => {
      const id = name + '_option_' + item.value;

      let itemLabel;

      if (!item.label && !item.labelKey) {
        itemLabel = '';
      } else {
        itemLabel = item.labelKey ? <FormattedMessage id={item.labelKey} /> : item.label;
      }

      const info = item.infoKey ? <FormattedMessage id={item.infoKey} /> : item.info;

      let toolTip = '';

      if (item.tooltipKey) {
        toolTip = (
          <div className="tip-container">
            <Tooltip content={<FormattedMessage id={item.tooltipKey} />} placement="bottom" key={'tooltip' + item.value}>
              <span className="tip">?</span>
            </Tooltip>
          </div>
        );
      }

      const input = (
        <span className="ekRadio-input-container">
          <input
            disabled={disabled}
            type="radio"
            className={`ekRadio ${disabled ? 'ekRadio_disabled' : ''}`}
            {...field}
            value={item.value}
            checked={field.value === item.value || (field.value === '' && defaultValue === item.value)}
            id={id}
            onChange={this.handleChange}
          />
          <label className="ekRadio_label" htmlFor={id}>
            {itemLabel}
          </label>
          {info && <span className="ekRadio_info">({info})</span>}
          {toolTip}
        </span>
      );

      return (
        <div key={item.value} className={`ekRadio_wrapper ${customWrapperClass || ''} ${disabled ? 'ekRadio_wrapper_disabled' : ''}`}>
          {input}
        </div>
      );
    });

    let mainTitle;
    if (!title && !titleKey) {
      mainTitle = '';
    } else {
      mainTitle = titleKey ? (
        <span className={`ekRadio_title ${customTitleClass || ''}`}>
          <FormattedMessage id={titleKey} />
          {_mandatory}
        </span>
      ) : (
        <span className={`ekRadio_title ${customTitleClass || ''}`}>
          {title}
          {_mandatory}
        </span>
      );
    }

    return (
      <div className="ekRadio_block">
        {mainTitle}
        <div className={`ekRadio_container ${customContainerClass || ''}`}>{options}</div>
        {children}
      </div>
    );
  }
}

EkRadio.displayName = 'EkRadio';

EkRadio.propTypes = {
  field: PropTypes.object,
  namespace: PropTypes.string,
  onChange: PropTypes.func,
  titleKey: PropTypes.string,
  title: PropTypes.string,
  customContainerClass: PropTypes.string,
  customWrapperClass: PropTypes.string,
  customTitleClass: PropTypes.string,
  disabled: PropTypes.bool,
  defaultValue: PropTypes.any
};

export default EkRadio;
