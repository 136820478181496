import React, { Component } from 'react';
import autoBind from 'react-autobind';
import pure from 'recompose/pure';
import { getAppFormattedDateTime, namedCompose } from '../../utils/utils';
import { FormattedMessage, injectIntl } from 'react-intl';
import _get from 'lodash/get';
import _forEach from 'lodash/forEach';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import shortid from 'shortid';
import { BOOKING_STATUS_ORIGIN_OTHER, BOOKING_STATUS_REASON_OTHER } from '../../constants/backend-constants';
import None from '../None';

class BookingStatusHistory extends Component {
  constructor(props) {
    super(props);
    autoBind(this);

    this.reasonProp = 'statusReason';
    this.originProp = 'statusOrigin';

    this.displayProps = new Map();
    this.displayProps.set('date', { key: 'common_date', parse: this.parseDate });
    this.displayProps.set('type', { key: 'common_type', parse: this.parseType });
    this.displayProps.set(this.reasonProp, { key: 'common_reason', parse: v => v });
    this.displayProps.set(this.originProp, { key: 'booking_status_origin', parse: v => v });

    this.derivedStateFromProps(props);
  }

  componentWillReceiveProps(props) {
    this.derivedStateFromProps(props);
  }

  derivedStateFromProps(props) {
    this.header = this.getHeader(props);
    this.rows = this.getRows(props);
    this.componentCanRender = this.checkComponentRender();
  }

  checkComponentRender() {
    return this.rows.length !== 0;
  }

  parseType(value) {
    const statusClass = 'booking_status_' + String(value).toLowerCase();
    const statusKey = 'common_booking_status_' + value;

    return (
      <span className={statusClass}>
        <FormattedMessage id={statusKey} />
      </span>
    );
  }

  parseDate(value) {
    return getAppFormattedDateTime(value);
  }

  parseHeader(key) {
    return (
      <th key={key}>
        <h5>
          <FormattedMessage id={key} />
        </h5>
      </th>
    );
  }

  otherOrigin(prop, value) {
    return prop === this.originProp && value === BOOKING_STATUS_ORIGIN_OTHER;
  }

  otherReason(prop, value) {
    return prop === this.reasonProp && value === BOOKING_STATUS_REASON_OTHER;
  }

  otherValue(prop, value) {
    return this.otherOrigin(prop, value) || this.otherReason(prop, value);
  }

  validValue(prop, value) {
    return value && !this.otherValue(prop, value);
  }

  getHeader(props) {
    const headerTable = {};
    const header = [];

    _forEach(props.statusHistory, status => {
      this.displayProps.forEach((v, k) => {
        const value = _get(status, k);
        if (this.validValue(k, value)) headerTable[k] = true;
      });
    });

    this.displayProps.forEach((v, k) => {
      if (headerTable[k]) header.push(this.parseHeader(v.key));
    });

    this.headerTable = headerTable;
    return header;
  }

  parseRow([key, value]) {
    if (key)
      return (
        <td className="detailView_cell_type_1" key={shortid.generate()}>
          <div className="mobile_label">
            <FormattedMessage id={key} />
          </div>
          <span>{value}</span>
        </td>
      );
    else return <td className="empty-td" key={shortid.generate()} />;
  }

  getRows(props) {
    const rows = [];

    _forEach(props.statusHistory, status => {
      const row = [];
      let rowEmpty = true;

      this.displayProps.forEach((v, k) => {
        const value = _get(status, k);

        if (this.validValue(k, value)) {
          row.push([v.key, v.parse(value)]);
          rowEmpty = false;
        } else if (this.headerTable[k]) row.push('');
      });

      if (!rowEmpty) {
        const htmlRow = [];
        row.forEach(array => htmlRow.push(this.parseRow(array)));
        rows.push(
          <tr className="detailView_tr_2" key={shortid.generate()}>
            {htmlRow}
          </tr>
        );
      }
    });

    return rows;
  }

  tHead() {
    return (
      <thead>
        <tr>{this.header}</tr>
      </thead>
    );
  }

  render() {
    if (this.componentCanRender)
      return (
        <div className={classNames('bookingStatusHistory', 'table', 'type-1')}>
          <h4 className={classNames('bookingStatusHistory_headline', 'table-headline')}>
            <FormattedMessage id="booking_status_history" />
          </h4>
          <div className="bookingStatusHistory_body_3">
            <div className="detailView_table">
              <table>
                {this.tHead()}
                <tbody>{this.rows}</tbody>
              </table>
            </div>
          </div>
        </div>
      );
    else return <None />;
  }
}

// -----

BookingStatusHistory.propTypes = {
  statusHistory: PropTypes.array,
  locale: PropTypes.string // locale reload
};

// -----

export default namedCompose(injectIntl, pure)(BookingStatusHistory);
