import React, { Component, PropTypes } from 'react';
import { connect } from 'react-redux';
import { routeActions } from 'react-router-redux';

import ConfigurationForm from '../../components/ConfigurationForm/ConfigurationForm';
import { createConfigurationRequest, createConfigurationSuccess, addFlashMessage } from '../../actions/all-actions';
import { FLASH_MESSAGE_TYPE_SUCCESS, FLASH_MESSAGE_TYPE_ERROR } from '../../constants/generic-constants';
import routes from '../../constants/routes-constants';
import { apiParams } from '../../constants/api-params-constants';

class AddConfiguration extends Component {
  constructor() {
    super();
    this.handleCreateConfiguration = this.handleCreateConfiguration.bind(this);
  }

  handleCreateConfiguration() {
    const { dispatch, urlParams } = this.props;
    dispatch(createConfigurationRequest())
      .then(configuration => {
        dispatch(createConfigurationSuccess(configuration));
        dispatch(
          addFlashMessage({
            contentKey: 'configurationForm_create_configuration_success',
            type: FLASH_MESSAGE_TYPE_SUCCESS,
            delayToNextRoute: true
          })
        );
        let params = (urlParams && urlParams.page) || apiParams.default;
        dispatch(routeActions.push(routes.configurations.path.replace(':search', encodeURIComponent(JSON.stringify(params)))));
      })
      .catch(error => {
        if (!error.type) {
          // make sure native errors not swallowed
          console.error(error.stack); // eslint-disable-line
        }
        dispatch(
          addFlashMessage({
            contentKey: 'configurationForm_create_configuration_error',
            type: FLASH_MESSAGE_TYPE_ERROR
          })
        );
      });
  }

  render() {
    return (
      <div className="addConfigurationPage mainContainer_content">
        <div className="pageContainer">
          <div className="addConfiguration_content">
            <section className="addConfiguration_management">
              <ConfigurationForm onCallback={this.handleCreateConfiguration} />
            </section>
          </div>
        </div>
      </div>
    );
  }
}

AddConfiguration.displayName = 'AddConfiguration';

AddConfiguration.propTypes = {};

export default connect(state => {
  const {
    configuration: { urlParams }
  } = state;

  return {
    urlParams
  };
})(AddConfiguration);
