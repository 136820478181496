import React, { Component, PropTypes } from 'react';
import cs from 'classnames';
import shortid from 'shortid';
import { injectIntl } from 'react-intl';
import { intlMsg, namedCompose, safe } from '../../../utils/utils';
import { createAc } from './autoComplete';
import ListModal from './Modal';
import { ListFilters } from './Filters';
import { getFiltersTitle } from './helpers';
import IconList from '../../IconList/IconList';
import CloseIcon from 'material-ui/svg-icons/navigation/close';
import SearchIcon from 'material-ui/svg-icons/action/search';
import FilterIcon from 'material-ui/svg-icons/content/filter-list';
import BackIcon from 'material-ui/svg-icons/navigation/arrow-back';
import scrollIntoView from 'scroll-into-view-if-needed';
import pure from 'recompose/pure';

const scrollOptions = {
  scrollMode: 'if-needed',
  block: 'nearest'
};

class ListHead extends Component {
  constructor(p) {
    super(p);
    this.setVars();
    this.setCallbacks();
  }

  setVars() {
    this.state = { filtersOpen: false, input: '' };
    this.boundEvents = [];
    this.listId = shortid();
  }

  componentDidMount() {
    this.createAc();
    this.bindEvents();
  }

  componentWillUnmount() {
    this.clearEvents();
    this.destroyAc();
  }

  destroyAc() {
    safe(() => this.ac.unInit());
  }

  clearEvents() {
    this.boundEvents.forEach(({ el, name, func }) => {
      el.removeEventListener(name, func);
    });
  }

  createAc() {
    if (this.props.autoCompleteEnabled) {
      this.ac = createAc(this.inputRef, {
        key: this.props.autoCompleteKey,
        data: this.props.autoCompleteData,
        list: this.props.autoCompleteList,
        engine: this.props.autoCompleteEngine,
        result: this.props.autoCompleteResult
      });
    }
  }

  bindEvent(el, name, func) {
    el.addEventListener(name, func);
    this.boundEvents.push({ el, name, func });
  }

  bindEvents() {
    this.bindEvent(this.inputRef, 'keydown', this.handleKeyDown);

    if (this.props.autoCompleteEnabled) {
      this.bindEvent(this.inputRef, 'selection', this.autoCompleteOnSelect);
      this.bindEvent(this.inputRef, 'navigate', this.autoCompleteOnNavigate);
    }
  }

  setCallbacks() {
    this.showFilters = () => {
      this.setState({ filtersOpen: true });
    };

    this.closeFilters = () => {
      this.setState({ filtersOpen: false });
    };

    this.handleFiltersApply = () => {
      safe(() => this.props.onFiltersApply());
      this.closeFilters();
    };

    this.handleFiltersReset = () => {
      safe(() => this.props.onFiltersReset());
      this.closeFilters();
    };

    this.updateInput = e => {
      const { value } = e.target;
      this.setState({ input: value });
      const { onChange } = this.props;
      if (onChange) onChange(value);
    };

    this.clearInput = () => {
      const { onClear } = this.props;
      this.setState({ input: '' });
      if (onClear) onClear();
    };

    this.autoCompleteOnSelect = event => {
      safe(() => this.props.autoCompleteOnSelect(event));
      this.clearInput();
    };

    this.handleKeyDown = event => {
      if (event.key === 'Escape') {
        this.clearInput();
      }
    };

    this.setInputRef = ref => {
      const { inputRef } = this.props;
      this.inputRef = ref;
      inputRef && inputRef(ref);
    };

    this.autoCompleteOnNavigate = () => {
      const id = safe(() => this.ac.resultItem.id + '_' + this.ac.cursor);
      const item = document.getElementById(id);
      if (item) scrollIntoView(item, scrollOptions);
    };
  }

  getSearchIcon() {
    return this.state.input ? <CloseIcon onClick={this.clearInput} /> : <SearchIcon />;
  }

  getBackButton() {
    const { onBack } = this.props;

    if (onBack) {
      return (
        <button className="sc-back" onClick={onBack}>
          <BackIcon />
        </button>
      );
    }
  }

  getFilterChips() {
    const { filterChips } = this.props;
    if (filterChips) return <div className="sc-chips-wrap">{filterChips}</div>;
  }

  getFilters() {
    const { filters } = this.props;

    if (filters) {
      return (
        <ListModal
          title={getFiltersTitle(this.props.filtersTitle)}
          isOpen={this.state.filtersOpen}
          onClose={this.closeFilters}
          className="list-filters-modal"
        >
          <ListFilters onFiltersApply={this.handleFiltersApply} onFiltersReset={this.handleFiltersReset}>
            {filters}
          </ListFilters>
        </ListModal>
      );
    }
  }

  render() {
    return (
      <div className="sc-head">
        {this.getBackButton()}
        <div className={cs('dropdown-search-wrap', { 'auto-complete': this.props.autoCompleteEnabled })}>
          <input
            onChange={this.updateInput}
            value={this.state.input}
            ref={this.setInputRef}
            type="text"
            className="sc-search"
            placeholder={this.props.placeholder || intlMsg(this, 'common_search')}
          />
          <div className={cs('sc-search-icon', { close: this.state.input })}>{this.getSearchIcon()}</div>
        </div>
        {this.props.filters && (
          <div className={cs('sc-filters-wrap', { 'sc-active': this.props.filtersActive })}>
            <span onClick={this.showFilters}>
              <FilterIcon />
            </span>
          </div>
        )}
        <div className="sc-menu-wrap">
          <IconList animated={false} items={this.props.menuItems} listName={this.listId} itemClass="list-menu-item" />
        </div>
        {this.getFilterChips()}
        {this.getFilters()}
      </div>
    );
  }
}

ListHead = injectIntl(ListHead);

/*
  menuItems: Look <IconList />
 */

ListHead.propTypes = {
  autoCompleteEnabled: PropTypes.bool,
  autoCompleteData: PropTypes.any,
  autoCompleteKey: PropTypes.string,
  autoCompleteOnSelect: PropTypes.func,
  autoCompleteEngine: PropTypes.func,
  autoCompleteList: PropTypes.func,
  autoCompleteResult: PropTypes.func,
  onFiltersApply: PropTypes.func,
  onFiltersReset: PropTypes.func,
  filtersActive: PropTypes.bool,
  placeholder: PropTypes.string,
  menuItems: PropTypes.array,
  filterChips: PropTypes.any,
  filtersTitle: PropTypes.any,
  filters: PropTypes.any,
  onClear: PropTypes.func,
  onChange: PropTypes.func,
  inputRef: PropTypes.func,
  onBack: PropTypes.func
};

ListHead = namedCompose(injectIntl, pure)(ListHead);

export default ListHead;
