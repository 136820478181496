import React, { Component } from 'react';
import { connect } from 'react-redux';
import { routeActions } from 'react-router-redux';

import VersionForm from '../../components/VersionForm/VersionForm';
import { createVersionRequest, showSuccessMsg } from '../../actions/all-actions';
import routes from '../../constants/routes-constants';

class AddVersion extends Component {
  componentWillMount() {
    this.handleCreateVersion = ({ name }) => {
      const {
        dispatch,
        currentModel: { id }
      } = this.props;

      dispatch(createVersionRequest({ id, name, modelId: id })).then(() => {
        dispatch(showSuccessMsg({ key: 'versionForm_create_version_success', delay: true }));
        dispatch(routeActions.push(routes.editModel.path.replace(':modelId', id)));
      });
    };
  }

  render() {
    return (
      <div className="addVersionPage mainContainer_content">
        <div className="pageContainer">
          <div className="addVersionPage_content">
            <section className="addVersionPage_management">
              <VersionForm onCallback={this.handleCreateVersion} />
            </section>
          </div>
        </div>
      </div>
    );
  }
}

AddVersion.displayName = 'AddVersion';

export default connect(state => {
  const {
    models: { currentModel }
  } = state;

  return { currentModel };
})(AddVersion);
