import React, { Component, PropTypes } from 'react';
import FileUpload from '../../../components/FileUlpoad/FileUpload';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import EkButton from '../../../components/EkButton/EkButton';
import { reduxForm } from 'redux-form';
import _memoize from 'lodash/memoize';
import classNames from 'classnames';

import {
  bundleSelector,
  customFieldsFilesSelector,
  localeSelector,
  profileCustomFieldsSelector,
  selectCustomFieldsInitialValues,
  selectCustomFieldsNames,
  selectFieldIdsByPosition
} from '../../../selectors/all-selectors';

import { customFieldIsVisible, selectCurrentLabel } from '../../../utils/component';
import { CUSTOM_FIELD_TYPE_FILE } from '../../../constants/backend-constants';
import { safe } from '../../../utils/utils';

class EditMemberFilesForm extends Component {
  componentDidMount() {
    this.didUpdateCycle();
  }

  componentDidUpdate() {
    this.didUpdateCycle();
  }

  didUpdateCycle() {
    this.handleError();
    this.updateFileStatus();
  }

  handleError() {
    this.noFiles = this.props.noFiles && !this.customFilesCount;
  }

  updateFileStatus() {
    if (this.customFilesCount !== this.showCustomFiles) {
      this.showCustomFiles = this.customFilesCount;
      this.forceUpdate();
    }
  }

  showTitle() {
    if (this.props.firstName || this.props.lastName) {
      return (
        <div className="sc-title">
          <FormattedHTMLMessage id="member.upload.files.title" values={{ memberName: `${this.props.firstName} ${this.props.lastName}` }} />
        </div>
      );
    }
  }

  getActions() {
    return (
      <div className="sc-actions">
        <EkButton type="button" primary onAction={this.props.handleSubmit(this.props.callback)}>
          <FormattedMessage id="common_save" />
        </EkButton>
      </div>
    );
  }

  displayLicence() {
    const {
      fields: { licenceFront, licenceBack },
      apiUrl
    } = this.props;

    if (licenceFront || licenceBack) {
      return (
        <div className="sc-section">
          <div className="sc-sub-title">
            <FormattedMessage id="members_detail_driver_subtitle" />
          </div>
          <div className="sc-content">
            {licenceFront && <FileUpload label="member.upload.licence.front" id="company-logo-url" field={licenceFront} apiUrl={apiUrl} />}
            {licenceBack && <FileUpload label="member.upload.licence.back" id="company-logo-url" field={licenceBack} apiUrl={apiUrl} />}
          </div>
        </div>
      );
    }
  }

  displayDocuments() {
    const {
      fields: { identityFront, identityBack },
      apiUrl
    } = this.props;

    if (identityFront || identityBack) {
      return (
        <div className="sc-section">
          <div className="sc-sub-title">
            <FormattedMessage id="member.identity.document" />
          </div>
          <div className="sc-content">
            {identityFront && (
              <FileUpload label="member.upload.identity.front" id="company-logo-url" field={identityFront} apiUrl={apiUrl} />
            )}
            {identityBack && <FileUpload label="member.upload.identity.back" id="company-logo-url" field={identityBack} apiUrl={apiUrl} />}
          </div>
        </div>
      );
    }
  }

  displaySelfie() {
    const {
      fields: { selfie },
      apiUrl
    } = this.props;

    if (selfie) {
      return (
        <div className="sc-section">
          <div className="sc-sub-title">
            <FormattedMessage id="member.selfie" />
          </div>
          <div className="sc-content">
            <FileUpload label="member.upload.selfie" id="company-logo-url" field={selfie} apiUrl={apiUrl} />
          </div>
        </div>
      );
    }
  }

  selectFieldType(field) {
    const { fields, apiUrl } = this.props;
    const { companyCustomField } = field || {};
    const { fieldType, id } = companyCustomField || {};
    const label = selectCurrentLabel.call(this, companyCustomField);
    const reduxField = fields[id];

    if (reduxField && fieldType === CUSTOM_FIELD_TYPE_FILE) {
      return <FileUpload key={id} text={label} id={id} field={reduxField} apiUrl={apiUrl} />;
    }
  }

  customField(field) {
    const { values, fieldIdsByPosition } = this.props;

    if (customFieldIsVisible(field, values, fieldIdsByPosition)) {
      return this.selectFieldType(field);
    }
  }

  getCustomFields() {
    return safe(() =>
      this.props.customFields.reduce((fields, field) => {
        const parsed = this.customField(field);
        if (parsed) fields.push(parsed);
        return fields;
      }, [])
    );
  }

  countCustomFiles() {
    const files = this.getCustomFields();
    this.customFilesCount = safe(() => files.length);
    return files;
  }

  displayCustomFiles() {
    return (
      <div className={classNames('sc-section', { 'sc-hide': !this.showCustomFiles })}>
        <div className="sc-sub-title">
          <FormattedMessage id="company_detail_tab_custom_fields" />
        </div>
        <div className="sc-content">{this.countCustomFiles()}</div>
      </div>
    );
  }

  showError() {
    if (this.noFiles) {
      return (
        <div className="sc-error">
          <FormattedMessage id="member.file.upload.error" />
        </div>
      );
    }
  }

  render() {
    return (
      <div className="sc-main">
        {this.showError()}
        {this.showTitle()}
        <div className="sc-sections">
          {this.displayLicence()}
          {this.displayDocuments()}
          {this.displaySelfie()}
          {this.displayCustomFiles()}
        </div>
        {this.getActions()}
      </div>
    );
  }
}

EditMemberFilesForm.displayName = 'EditMemberFilesForm';

EditMemberFilesForm.propTypes = {
  stateData: PropTypes.object,
  callback: PropTypes.func // form callback
};

/*
  stateData: {
    apiUrl: alt upload API url
    member: object (member)
    ...rest: object (custom fields)
  }
 */

const parseState = _memoize(data => {
  const { apiUrl, member, ...initialFiles } = data;
  const fileNames = Object.keys(initialFiles);
  const { firstName = '', lastName = '' } = member || {};

  return { apiUrl, firstName, lastName, initialFiles, fileNames, noFiles: !fileNames.length };
});

export default reduxForm(
  {
    form: 'editMemberFiles'
  },
  (state, props) => {
    const { initialFiles, fileNames, ...parsedState } = parseState(props.stateData);

    const customFields = profileCustomFieldsSelector(state);
    const customFiles = customFieldsFilesSelector(state);
    const customNames = selectCustomFieldsNames(customFields);
    const fieldIdsByPosition = selectFieldIdsByPosition(customFields);
    const initialFields = selectCustomFieldsInitialValues(customFields, customFiles);

    // 'bundle' and 'locale' required for 'selectCurrentLabel'
    return {
      ...parsedState,
      customFields,
      fieldIdsByPosition,
      bundle: bundleSelector(state),
      locale: localeSelector(state),
      fetchDone: !state.api.callsCount,
      initialValues: { ...initialFiles, ...initialFields },
      fields: [...fileNames, ...customNames]
    };
  }
)(EditMemberFilesForm);
