import * as types from '../constants/actionTypes-constants';

export function openSideMenu() {
  return {
    type: types.SIDE_MENU_OPEN
  };
}

export function closeSideMenu() {
  return {
    type: types.SIDE_MENU_CLOSE
  };
}

export function handleToggleMobileMenu() {
  return {
    type: types.HEADER_MOBILE_MENU_TOGGLE
  };
}

export function toggleSideMenu() {
  return {
    type: types.SIDE_MENU_TOGGLE
  };
}

export function openSideSubMenu(target) {
  return dispatch => {
    dispatch(resetSideMenu());
    dispatch({ type: types.SIDE_SUB_MENU_OPEN, target });
  };
}

export function closeSideSubMenu(target) {
  return {
    type: types.SIDE_SUB_MENU_CLOSE,
    target
  };
}

export function toggleSideSubMenu(target) {
  return {
    type: types.SIDE_SUB_MENU_TOGGLE,
    target
  };
}

export function resetSideMenu() {
  return {
    type: types.SIDE_MENU_RESET
  };
}
