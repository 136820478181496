import React, { Component, PropTypes } from 'react';
import { FlatButton, RaisedButton } from 'material-ui';
import { injectIntl } from 'react-intl';
import FormRow from '../FormRow/FormRow';
import { namedCompose, safe, scrollToFirstError, valueFirstInit } from '../../utils/utils';
import { FormattedMessage } from 'react-intl';
import { iconStyles } from '../../constants/style-constants';
import PanoramaFishEyeIcon from 'material-ui/svg-icons/image/panorama-fish-eye';
import ReportProblem from 'material-ui/svg-icons/action/report-problem';
import { reduxForm } from 'redux-form';
import { localeSelector } from '../../selectors/all-selectors';
import { COMPANY_STRIPE_SETTINGS_FORM } from '../../constants/form-constants';
import FieldErrorMsg from '../FieldErrorMsg/FieldErrorMsg';
import { ADD_CONNECTED_ACCOUNT_FORM_FIELDS, BANK_ACCOUNT_DETAILS_FIELD } from '../../constants/generic-constants';
import classNames from 'classnames';
import AddressAutocomplete from '../AddressAutocomplete/AddressAutocomplete';
import BoxedSelect from '../BoxedSelect/BoxedSelect';
import BoxedInput from '../BoxedInput/BoxedInput';
import {
  COMPANIES_GET_CONNECTED_ACCOUNT_ERROR,
  COMPANIES_GET_CONNECTED_ACCOUNT_SUCCESS,
  SUB_COMPANY_GET_CONNECTED_ACCOUNT_ERROR,
  SUB_COMPANY_GET_CONNECTED_ACCOUNT_SUCCESS
} from '../../constants/actionTypes-constants';
import { createValidator, isCountry, notEmpty, stopValidationIf } from '../../validation/sync-validation';
import { STRIPE_STATUS } from '../../constants/backend-constants';

const isStarted = {
  condition: props => {
    return !(
      props.connectedAccount.type === COMPANIES_GET_CONNECTED_ACCOUNT_ERROR ||
      props.connectedAccount.type === SUB_COMPANY_GET_CONNECTED_ACCOUNT_ERROR
    );
  }
};

class AddConnectedAccountForm extends Component {
  constructor(props) {
    super(props);
    this.bindFunctions();
    this.constants();
    this.derivedStateFromProps(props);
  }

  // noinspection JSCheckFunctionSignatures
  componentWillReceiveProps(props) {
    this.derivedStateFromProps(props);
  }

  derivedStateFromProps(props) {
    this.handlePaymentVariables(props);
  }

  componentDidMount() {
    this.didUpdateCycle();
  }

  // noinspection JSCheckFunctionSignatures
  componentDidUpdate() {
    this.didUpdateCycle();
  }

  didUpdateCycle() {
    this.initField(this.props);
  }

  initField({ fields }) {
    const { [BANK_ACCOUNT_DETAILS_FIELD]: field } = fields;
    valueFirstInit.call(this, field);
  }

  bindFunctions() {
    this.handleAction = this.handleAction.bind(this);
  }

  constants() {
    this.searchType = ['country'];
  }

  paymentText() {
    const { detailsUploaded } = this.props;

    return (
      <div className="payment-status">
        {<div className="payment-icon">{this.paymentTextIcon}</div>}
        <span className={classNames({ 'ready-for-upload': detailsUploaded })}>
          <FormattedMessage id={this.paymentTextId} />
        </span>
      </div>
    );
  }

  handleAction() {
    const { create, update, connectedAccount, parentId } = this.props;
    const { status } = connectedAccount.error || {};

    if (
      status === 404 &&
      (connectedAccount.type === COMPANIES_GET_CONNECTED_ACCOUNT_ERROR ||
        (connectedAccount.type === SUB_COMPANY_GET_CONNECTED_ACCOUNT_ERROR && parentId))
    ) {
      return create && create();
    }
    if (
      connectedAccount.type === COMPANIES_GET_CONNECTED_ACCOUNT_SUCCESS ||
      (connectedAccount.type === SUB_COMPANY_GET_CONNECTED_ACCOUNT_SUCCESS && parentId)
    ) {
      if (connectedAccount.data.stripeAccount.status === STRIPE_STATUS.COMPLETED) {
        return (window.location.href = connectedAccount.data.stripeAccount.loginLink);
      }
      if (connectedAccount.data.stripeAccount.status === STRIPE_STATUS.INCOMPLETE) {
        return update && update();
      }
      if (connectedAccount.data.stripeAccount.status === STRIPE_STATUS.DISABLED) {
        return create && create();
      }
    }
  }

  handlePaymentVariables(props) {
    const {
      connectedAccount,
      intl: { formatMessage }
    } = props;

    if (
      connectedAccount.type === COMPANIES_GET_CONNECTED_ACCOUNT_ERROR ||
      connectedAccount.type === SUB_COMPANY_GET_CONNECTED_ACCOUNT_ERROR
    ) {
      this.paymentTextId = 'system_error';
      this.paymentTextIcon = <ReportProblem style={iconStyles.error} />;
      this.paymentDetailsButtonLabel = formatMessage({ id: 'paymentDetails_error_contact_us' });

      if (connectedAccount.error.status === 404) {
        this.paymentTextId = 'subCompanies_paymentDetails_empty';
        this.paymentTextIcon = <PanoramaFishEyeIcon style={iconStyles.all} />;
        this.paymentDetailsButtonLabel = formatMessage({ id: 'subCompanies_paymentDetails_add' });
      }
    }

    if (
      connectedAccount.type === COMPANIES_GET_CONNECTED_ACCOUNT_SUCCESS ||
      connectedAccount.type === SUB_COMPANY_GET_CONNECTED_ACCOUNT_SUCCESS
    ) {
      if (connectedAccount.data.stripeAccount.status === STRIPE_STATUS.COMPLETED) {
        this.paymentTextId = 'subCompanies_paymentDetails_existing';
        this.paymentTextIcon = <div className="circle-outline completed" />;
        this.paymentDetailsButtonLabel = formatMessage({ id: 'common_view' });
      } else if (connectedAccount.data.stripeAccount.status === STRIPE_STATUS.DISABLED) {
        this.paymentTextId = 'subCompanies_paymentDetails_disabled';
        this.paymentTextIcon = <div className="info disabled" />;
        this.paymentDetailsButtonLabel = formatMessage({ id: 'subCompanies_paymentDetails_disabled' });
      } else {
        this.paymentTextId = 'subCompanies_paymentDetails_replace';
        this.paymentTextIcon = <div className="pending incomplete" />;
        this.paymentDetailsButtonLabel = formatMessage({ id: 'subCompanies_paymentDetails_replace' });
      }
    }
  }

  uploadButton() {
    const {
      bankDetailsReadOnly,
      connectedAccount: cA,
      handleSubmit,
      supress,
      migratebtn,
      intl: { formatMessage }
    } = this.props;

    const isComplete = safe(() => cA.data.stripeAccount.status === STRIPE_STATUS.COMPLETED);
    const isIncomplete = safe(() => cA.data.stripeAccount.status === STRIPE_STATUS.INCOMPLETE);
    let wrapBtn = {};
    if (cA.error && cA.error.status !== 404) return '';

    if (!migratebtn) {
      wrapBtn = (
        <RaisedButton
          label={this.paymentDetailsButtonLabel}
          onClick={handleSubmit(this.handleAction)}
          primary
          className={classNames('cta-payment', { pending: isIncomplete, completed: isComplete })}
          type="button"
          id="submit-bank-details"
        />
      );
    } else {
      wrapBtn = (
        <FlatButton
          label={this.paymentDetailsButtonLabel}
          onClick={handleSubmit(this.handleAction)}
          primary
          className={classNames('cta-payment', { pending: isIncomplete, completed: isComplete })}
          type="button"
          id="submit-bank-details"
        />
      );
    }
    if (!bankDetailsReadOnly) {
      return (
        <div className="submit-container">
          {(isComplete || isIncomplete) && (
            <FlatButton className="remove-account" label={formatMessage({ id: 'common_delete' })} onClick={() => supress()} />
          )}
          {wrapBtn}

          {isComplete && migratebtn}
        </div>
      );
    }
  }

  countryField() {
    const {
      fields: { bankCountry },
      connectedAccount: { type }
    } = this.props;

    if (type === COMPANIES_GET_CONNECTED_ACCOUNT_ERROR || type === SUB_COMPANY_GET_CONNECTED_ACCOUNT_ERROR) {
      return (
        <label className="boxedInput_label">
          <span className="boxedInput_labelText">
            <FormattedMessage id="common_autocomplete_country" />
          </span>
          <AddressAutocomplete
            id="bank-details-country-field"
            searchType={this.searchType}
            placeholderKey="common_search"
            className="companyForm_AddressAutocomplete"
            field={bankCountry}
            translateCountryCode
          />
          <FieldErrorMsg field={bankCountry} customClass="fieldErrorMsg--companyForm" />
        </label>
      );
    }
  }

  getFormFields() {
    const {
      fields: { bankCurrency, email },
      currencyOptions,
      connectedAccount,
      bankDetailsReadOnly
    } = this.props;
    const { error } = connectedAccount || {};
    const statusError = error && error.status === 500;

    if (!bankDetailsReadOnly) {
      return (
        <div className={classNames('bank-details-form-fields', { disabled: statusError })}>
          <FormRow customClass="companyForm_row">
            {this.countryField()}
            <BoxedSelect
              id="bank-details-currency"
              labelKey="invoice_params_label_currency"
              disabled
              noEmptyValue
              field={bankCurrency}
              options={currencyOptions}
            >
              <FieldErrorMsg customClass="fieldErrorMsg--companyForm" field={bankCurrency} />
            </BoxedSelect>
            <BoxedInput labelKey="companyForm_email_label" customClass="boxedInputWrapper--name text" field={email} />
          </FormRow>
        </div>
      );
    }
  }

  render() {
    const {
      fields: { bankAccountDetails },
      handleSubmit
    } = this.props;

    return (
      <form className="connected-account-form" action="#" onSubmit={handleSubmit(this.handleAction)}>
        <h4 className="companyDetail_title">
          <FormattedMessage id="subCompanies_paymentDetails_title" />
        </h4>
        {this.paymentText()}
        <FieldErrorMsg customClass={classNames('fieldErrorMsg--companyForm', 'fieldErrorMsg--bankDetails')} field={bankAccountDetails} />
        {this.getFormFields()}
        {this.uploadButton()}
      </form>
    );
  }
}

AddConnectedAccountForm.propTypes = {
  currencyOptions: PropTypes.array,
  connectedAccount: PropTypes.object,
  handleSubmit: PropTypes.func
};

export default namedCompose(
  reduxForm(
    {
      onSubmitFail: scrollToFirstError,
      form: COMPANY_STRIPE_SETTINGS_FORM,
      fields: ADD_CONNECTED_ACCOUNT_FORM_FIELDS,
      validate: createValidator({
        bankCountry: [stopValidationIf(isStarted), isCountry()],
        email: [notEmpty()]
      })
    },
    state => ({ locale: localeSelector(state).toUpperCase() })
  ),
  injectIntl
)(AddConnectedAccountForm);
