import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import _isEmpty from 'lodash/isEmpty';
import _get from 'lodash/get';
import _reduce from 'lodash/reduce';
import _isObject from 'lodash/isObject';
import autoBind from 'react-autobind';
import pure from 'recompose/pure';

import IconMenu from 'material-ui/IconMenu';
import MenuItem from 'material-ui/MenuItem';
import IconButton from 'material-ui/IconButton';
import MoreVertIcon from 'material-ui/svg-icons/navigation/more-vert';

import { IconListStyles } from '../../constants/style-constants';
import { isGranted } from '../../actions/all-actions';
import { namedCompose, newProps } from '../../utils/utils';
import { localeSelector } from '../../selectors/all-selectors';

class IconList extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.derivedStateFromProps(props, true);
  }

  componentWillReceiveProps(nextProps) {
    this.derivedStateFromProps(nextProps);
  }

  derivedStateFromProps(props, init) {
    if (newProps(this.props, props, init, 'buttonIcon')) {
      this.iconButton = <IconButton>{props.buttonIcon}</IconButton>;
    }

    this.createMenuItems(props);

    this.mainElement = _isEmpty(this.menuItems) ? <div /> : this.renderList();
  }

  createMenuItems(props) {
    this.menuItems = _reduce(
      props.items,
      (result, item, index) => {
        if (_isObject(item) && !_isEmpty(item) && isGranted({ allowed: item.allowed, disallowed: item.disallowed })) {
          const text = item.labelKey ? <FormattedMessage id={item.labelKey} /> : '-';
          const itemRawId = _get(item, 'id');
          const itemId = `${props.listName}_${itemRawId || `item_${index}`}`;

          const menuItemProps = {
            key: itemRawId || index,
            primaryText: text,
            leftIcon: item.icon,
            onClick: item.handleClick,
            innerDivStyle: IconListStyles.item.innerDiv,
            className: props.itemClass,
            disabled: item.disabled,
            id: itemId
          };

          result.push(<MenuItem {...menuItemProps} />);
        }
        return result;
      },
      []
    );
  }

  renderList() {
    return (
      <IconMenu
        animated={this.props.animated}
        iconButtonElement={this.iconButton}
        anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
        targetOrigin={{ horizontal: 'right', vertical: 'top' }}
        className={this.props.className}
      >
        {this.menuItems}
      </IconMenu>
    );
  }

  render() {
    return this.mainElement;
  }
}

// other props :
//  items.forEach(item => {
//    item.allowed (Array) - user roles that can view current button
//    item.disallowed (Array) - user roles that can NOT view current button
//    item.labelKey (String) - translation key
//    item.icon (ReactElement) - material UI icon
//    item.handleClick (Function) - callback
//    item.id (String) - used to generate item IDs
//    item.disabled - used to 'enable' or 'disable' button
//  }

IconList.defaultProps = {
  items: [],
  buttonIcon: <MoreVertIcon />,
  listName: 'iconList', // used to generate item IDs
  animated: true, // add transitions to menu
  itemClass: '',
  className: ''
};

export default namedCompose(
  connect(state => {
    return {
      // add 'locale' to trigger translation change
      locale: localeSelector(state)
    };
  }),
  pure
)(IconList);
