// noinspection ES6CheckImport
import React, { PropTypes } from 'react';
import AccountCircleIcon from 'material-ui/svg-icons/action/account-circle';
import { FormattedMessage } from 'react-intl';
import _ from 'lodash';

import EmailIcon from 'material-ui/svg-icons/communication/email';
import PhoneIcon from 'material-ui/svg-icons/communication/phone';
import PlaceIcon from 'material-ui/svg-icons/maps/place';
import CakeIcon from 'material-ui/svg-icons/social/cake';
import FlatButton from 'material-ui/FlatButton';
import ManagersEmailIcon from 'material-ui/svg-icons/social/people';

import IsGranted from '../IsGranted/IsGranted';
import { iconStyles, MemberBasicInfoStyles } from '../../constants/style-constants';

import { STATUS_APPROVED, STATUS_NOT_APPROVED, BACKUSER_ROLE_FLEET_MANAGER } from '../../constants/backend-constants';
import { getAppFormattedDate } from '../../utils/utils';
import Tooltip from '../Tooltip/Tooltip';
import { memberDetailEditRules } from '../../constants/routes-constants';

const MemberBasicInfo = props => {
  const { detailMember, enabledActions, handleRejectInfo, handleApproveInfo, showName, showStatus } = props;
  const { anonymizationEffectiveDate } = detailMember || {};

  const unknown = <FormattedMessage id="common_unknown" />;

  return (
    <div className="memberView_basicInfo">
      <h4>
        <FormattedMessage id="members_detail_subtitle" />
        {showStatus && _.get(detailMember, 'profileReviewStatus') && (
          <span>
            <span className="detailView_dash">-</span>
            <span className="detailView_component_headline_status">
              <Tooltip content={<FormattedMessage id="members_detail_status" />} placement="bottom">
                <span className={`member_status--${detailMember.profileReviewStatus.toLowerCase()}`}>
                  <FormattedMessage id={`members_status_${detailMember.profileReviewStatus.toLowerCase()}`} />
                </span>
              </Tooltip>
            </span>
          </span>
        )}
        {showStatus && _.get(detailMember, 'profileReviewStatus') && _.get(detailMember, 'lastApprovedDate') && (
          <Tooltip content={<FormattedMessage id="members_detail_lastApprovedDate" />} placement="bottom">
            <span className="detailView_component_headline_date">{getAppFormattedDate(detailMember.lastApprovedDate)}</span>
          </Tooltip>
        )}
      </h4>
      <div className="memberView_basicInfo_body">
        <div className="detailView_table">
          {showName && (
            <div className="detailView_row">
              <span className="detailView_cell_type_1">
                <Tooltip content={<FormattedMessage id="common_full_name" />} placement="bottom">
                  <AccountCircleIcon style={iconStyles.all} />
                </Tooltip>
              </span>
              <span className="detailView_cell_type_1">
                <span>{_.get(detailMember, 'firstName', unknown)}</span>
                <span> </span>
                <span>{_.get(detailMember, 'lastName', unknown)}</span>
              </span>
            </div>
          )}
          <div className="detailView_row">
            <span className="detailView_cell_type_1">
              <Tooltip content={<FormattedMessage id="members_detail_email" />} placement="bottom">
                <EmailIcon style={iconStyles.all} />
              </Tooltip>
            </span>
            <span className="detailView_cell_type_1">{_.get(detailMember, 'login', unknown)}</span>
          </div>
          {_.get(detailMember, 'managerEmail') && (
            <div className="detailView_row">
              <span className="detailView_cell_type_1">
                <Tooltip content={<FormattedMessage id="members_detail_manager_email" />} placement="bottom">
                  <ManagersEmailIcon style={iconStyles.all} />
                </Tooltip>
              </span>
              <span className="detailView_cell_type_1">{detailMember.managerEmail}</span>
            </div>
          )}
          <div className="detailView_row">
            <span className="detailView_cell_type_1">
              <Tooltip content={<FormattedMessage id="members_detail_phone_number" />} placement="bottom">
                <PhoneIcon style={iconStyles.all} />
              </Tooltip>
            </span>
            <span className="detailView_cell_type_1">
              <span>{_.get(detailMember, 'phoneNumber.countryCode', unknown)}</span>
              <span> </span>
              <span>{_.get(detailMember, 'phoneNumber.nationalNumber', unknown)}</span>
            </span>
          </div>
          <div className="detailView_row">
            <span className="detailView_cell_type_1">
              <Tooltip content={<FormattedMessage id="members_detail_address" />} placement="bottom">
                <PlaceIcon style={iconStyles.all} />
              </Tooltip>
            </span>
            <span className="detailView_cell_type_1">{_.get(detailMember, 'address.formattedAddress', unknown)}</span>
          </div>
          {(_.get(detailMember, 'birthDate') || _.get(detailMember, 'birthPlace')) && (
            <div className="detailView_row">
              <span className="detailView_cell_type_1">
                <Tooltip
                  content={
                    <span>
                      {_.get(detailMember, 'birthDate') && <FormattedMessage id="members_detail_birth_date" />}
                      {_.get(detailMember, 'birthDate') && _.get(detailMember, 'birthPlace') && <span className="detailView_dash">-</span>}
                      {_.get(detailMember, 'birthPlace') && <FormattedMessage id="members_detail_birth_place" />}
                    </span>
                  }
                  placement="bottom"
                >
                  <CakeIcon style={iconStyles.all} />
                </Tooltip>
              </span>
              <span className="detailView_cell_type_1">
                {_.get(detailMember, 'birthDate') && getAppFormattedDate(detailMember.birthDate)}
                {_.get(detailMember, 'birthDate') && _.get(detailMember, 'birthPlace') && <span className="detailView_dash">-</span>}
                {_.get(detailMember, 'birthPlace') && detailMember.birthPlace}
              </span>
            </div>
          )}
        </div>
        {enabledActions && (
          <IsGranted disallowed={[BACKUSER_ROLE_FLEET_MANAGER, ...memberDetailEditRules.exclude]} allowed={memberDetailEditRules.include}>
            <div className="memberView_reviewButtonsContainer">
              {detailMember.profileReviewStatus !== STATUS_NOT_APPROVED && !anonymizationEffectiveDate && (
                <FlatButton
                  id="memberView_basicInfo_reject"
                  label={<FormattedMessage id="common_reject" />}
                  onClick={handleRejectInfo}
                  style={MemberBasicInfoStyles.buttons.flat}
                />
              )}
              {detailMember.profileReviewStatus !== STATUS_APPROVED && !anonymizationEffectiveDate && (
                <FlatButton
                  id="memberView_basicInfo_approve"
                  label={<FormattedMessage id="common_approve" />}
                  onClick={handleApproveInfo}
                  style={MemberBasicInfoStyles.buttons.flat}
                />
              )}
            </div>
          </IsGranted>
        )}
      </div>
    </div>
  );
};

MemberBasicInfo.displayName = 'MemberBasicInfo';

MemberBasicInfo.defaultProps = {
  enabledActions: false,
  showStatus: true,
  showName: true
};

MemberBasicInfo.propTypes = {
  detailMember: PropTypes.object.isRequired,
  anonymizationEffectiveDate: PropTypes.any,
  enabledActions: PropTypes.bool,
  handleApproveInfo: PropTypes.func,
  handleRejectInfo: PropTypes.func,
  showStatus: PropTypes.bool,
  showName: PropTypes.bool
};

export default MemberBasicInfo;
