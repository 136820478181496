import React, { Component, PropTypes } from 'react';

import { FormattedMessage, injectIntl, defineMessages } from 'react-intl';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import _get from 'lodash/get';
import _map from 'lodash/map';
import _isEmpty from 'lodash/isEmpty';

import FormRow from '../FormRow/FormRow';
import BoxedInput from '../BoxedInput/BoxedInput';
import BoxedSelect from '../BoxedSelect/BoxedSelect';
import EkRadio from '../EkRadio/EkRadio';
import EkButton from '../EkButton/EkButton';
import TranslationsFields from '../../components/_v2/TranslationsFields';

import AddressAutocomplete from '../AddressAutocomplete/AddressAutocomplete';
import PhoneNumberField from '../PhoneNumberField/PhoneNumberField';
import FieldErrorMsg from '../FieldErrorMsg/FieldErrorMsg';
import {
  createValidator,
  notEmpty,
  address,
  length,
  integer,
  stopValidationIf,
  email,
  url,
  genericPdf,
  genericImage,
  positiveNumber,
  notValueDash,
  checkPhoneNumber,
  condition
} from '../../validation/all-validation';
import { booleanOptions, externalInvoiceOptions, accessOptions, paymentProviders } from '../../constants/options-constants';

import {
  safe,
  radioFieldEnabled,
  fieldEnabled,
  scrollToFirstError,
  radioValueTrue,
  fieldValue,
  toBoolean,
  fieldValueSafe,
  getCurrentEnv,
  createCompanyDevUrls,
  getThemeDomains,
  callbackNot as not,
  isEmpty,
  getFieldValue
} from '../../utils/utils';

import { checkDomains, mergeValidators, minimum, notValueUrlAll } from '../../validation/sync-validation';
import FileUpload from '../FileUlpoad/FileUpload';
import { EXTERNAL_INVOICE, CUSTOM_FIELD_TYPE_FILE } from '../../constants/backend-constants';
import {
  userRoleSelector,
  customFieldsSubscriptionSelector,
  carSharingCustomFieldsSelector,
  headerContractBookingValuesSelector,
  headerContractMemberValuesSelector,
  headerContractPaymentValuesSelector,
  headerContractVehicleValuesSelector
} from '../../selectors/all-selectors';
import { brandIdToThemeMap, SSO_FORMM_FIELDS, VEHICLE_REPORT_ADDITIONAL_FIELDS } from '../../constants/generic-constants';
import { COMPANY_FORM } from '../../constants/form-constants';
import { removeCompanyFile } from '../../actions/companies-actions';
import memoizeOne from 'memoize-one';
import { VALIDATION_ARRAY_LENGTH, VALIDATION_FIELD_MISSING } from '../../constants/errors-constants';

const toolTipMessages = defineMessages({
  websiteConfirmSubscriptionUrl: {
    id: 'companyForm_confirmSubscriptionUrl_tooltip_text',
    defaultMessage: ''
  },
  websiteUrl: {
    id: 'companyForm_websiteResetPasswordUrl_tooltip_text',
    defaultMessage: ''
  },
  backofficeUrl: {
    id: 'companyForm_backofficeResetPasswordUrl_tooltip_text',
    defaultMessage: ''
  },
  vehicleReassignmentDelayInMin: {
    id: 'companyForm_vehicleReassignmentDelayInMin_tooltip_text',
    defaultMessage: ''
  },
  damageDepositAutoRelease: {
    id: 'damage.deposit.auto.release.tooltip',
    defaultMessage: ''
  },
  domains: {
    id: 'companyForm_domains_tooltip_text',
    defaultMessage: ''
  }
});

const validate = values => {
  const errors = {};

  if (toBoolean(values.displayAdditionalLegalDoc)) {
    if (values.additionalLegalDocTranslations.length === 0) {
      errors.additionalLegalDocTranslations = {
        value: 0,
        type: VALIDATION_ARRAY_LENGTH
      };
    }

    if (values.additionalLegalDocTranslations.length >= 1) {
      errors.additionalLegalDocTranslations = [];
      values.additionalLegalDocTranslations.reduce((errors, trad, i) => {
        if (isEmpty(trad.translation)) {
          errors.additionalLegalDocTranslations[i] = {
            translation: {
              value: trad.translation,
              type: VALIDATION_FIELD_MISSING
            }
          };
        }
        return errors;
      }, errors);
    }
    return errors;
  }
};

export const getLinkTypeFieldsOptions = memoizeOne(fields => {
  return (
    fields &&
    fields.reduce((returnOptions, field) => {
      let { id, name, fieldType } = field || {};

      if (fieldType !== CUSTOM_FIELD_TYPE_FILE) {
        returnOptions.push({
          label: name,
          value: id
        });
      }

      return returnOptions;
    }, [])
  );
});

const mapOptions = memoizeOne(data =>
  data.map(item => ({
    label: item.name,
    value: item.id
  }))
);

class CompanyForm extends Component {
  constructor(props) {
    super(props);
    this.bindCallbacks();
    this.setVars();
    this.derivedStateFromProps(props, true);
  }

  setVars() {
    this.currentEnv = getCurrentEnv();
  }

  componentDidMount() {
    this.handleInitialBrandId();
  }

  handleInitialBrandId() {
    const { initialValue } = this.props.fields.appBrandId;
    if (initialValue) this.handleAppBrandChange(initialValue);
  }

  bindCallbacks() {
    this.handleConfigurationSelect = this.handleConfigurationSelect.bind(this);
    this.deleteFile = this.deleteFile.bind(this);
    this.handleAppBrandChange = this.handleAppBrandChange.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    this.derivedStateFromProps(nextProps);
  }

  derivedStateFromProps(nextProps, init) {
    this.generateAppBrandsOptions(nextProps, init);
    this.generateCFExternalIDOptions(nextProps, init);
    this.generateInversFleetsOptions(nextProps, init);
    this.setLinkTypeBookingCustomFieldsOptions(nextProps, init);
    this.setLinkTypeSubscriptionCustomFieldsOptions(nextProps, init);
  }

  appBrandOptionsUpdated(nextProps, init) {
    const { appBrands: prevValue } = this.props;
    const { appBrands: nextValue } = nextProps;
    return init || prevValue !== nextValue;
  }

  inversFleetsOptionsUpdated(nextProps, init) {
    const { inversFleets: prevValue } = this.props;
    const { inversFleets: nextValue } = nextProps;
    return init || prevValue !== nextValue;
  }

  generateAppBrandsOptions(nextProps, init) {
    if (this.appBrandOptionsUpdated(nextProps, init)) {
      const { appBrands } = nextProps;

      this.appBrandsOptions = appBrands.map(appBrand => ({
        label: appBrand.name,
        value: appBrand.id
      }));
    }
  }

  generateInversFleetsOptions(nextProps, init) {
    if (this.inversFleetsOptionsUpdated(nextProps, init)) {
      const { inversFleets } = nextProps;

      this.inversFleetsOptions = inversFleets.map(f => ({
        label: f.name,
        value: f.id
      }));
    }
  }

  subscriptionCustomFieldsUpdated(nextProps, init) {
    const { subscriptionCustomFields: prevValue } = this.props;
    const { subscriptionCustomFields: nextValue } = nextProps;
    return init || prevValue !== nextValue;
  }

  bookingCustomFieldsUpdated(nextProps, init) {
    const { bookingCustomFields: prevValue } = this.props;
    const { bookingCustomFields: nextValue } = nextProps;
    return init || prevValue !== nextValue;
  }

  generateCFExternalIDOptions(nextProps, init) {
    if (this.subscriptionCustomFieldsUpdated(nextProps, init)) {
      const { subscriptionCustomFields } = nextProps;

      this.CFExternalIDsOptions =
        subscriptionCustomFields &&
        subscriptionCustomFields.reduce((returnOptions, customField) => {
          let { id, name, uniqueValues } = customField || {};

          if (uniqueValues) {
            returnOptions.push({
              label: name,
              value: id
            });
          }

          return returnOptions;
        }, []);
    }
  }

  setLinkTypeBookingCustomFieldsOptions(nextProps, init) {
    if (this.bookingCustomFieldsUpdated(nextProps, init)) {
      const { bookingCustomFields } = nextProps;
      this.linkTypeBookingCustomFieldsOptions = getLinkTypeFieldsOptions(bookingCustomFields);
    }
  }

  setLinkTypeSubscriptionCustomFieldsOptions(nextProps, init) {
    if (this.subscriptionCustomFieldsUpdated(nextProps, init)) {
      const { subscriptionCustomFields } = nextProps;
      this.linkTypeSubscriptionCustomFieldsOptions = getLinkTypeFieldsOptions(subscriptionCustomFields);
    }
  }

  deleteFile(value) {
    const id = value.id;
    this.props.dispatch(removeCompanyFile(id));
  }

  handleConfigurationSelect(value) {
    if (this.props.onConfigurationSelect) {
      this.props.onConfigurationSelect(value);
    }
  }

  handleAppBrandChange(data) {
    if (this.props.isDisplayForm) return;

    const theme = safe(() => brandIdToThemeMap[this.currentEnv][data]);
    const newUrls = createCompanyDevUrls(getThemeDomains(this.currentEnv, theme));

    if (newUrls) {
      this.props.fields.websiteConfirmSubscriptionUrl.onChange(newUrls.frontConfirmSubscription);
      this.props.fields.websiteResetPasswordUrl.onChange(newUrls.frontResetPassword);
      this.props.fields.backofficeResetPasswordUrl.onChange(newUrls.backResetPassword);
    }
  }

  showCarwiseField() {
    const {
      fields: { carWiseOrganizationId },
      readOnly
    } = this.props;

    return (
      <FormRow customClass="companyForm_row">
        <BoxedInput
          formRowItemKey="companyForm_carWiseOrganizationId"
          type="text"
          id="carWiseOrganizationId"
          labelKey="company.field.carWiseOrganizationId"
          customClass="companyForm_boxedInput"
          disabled={readOnly}
          field={carWiseOrganizationId}
          mandatory
        >
          <FieldErrorMsg field={carWiseOrganizationId} customClass="fieldErrorMsg--companyForm" />
        </BoxedInput>
      </FormRow>
    );
  }

  showDmsFields() {
    const {
      fields: { useExternalInvoiceSystem, externalId, fiscalCode },
      readOnly,
      isDisplayForm
    } = this.props;

    if (fieldValue(useExternalInvoiceSystem) === EXTERNAL_INVOICE.DMS) {
      return (
        <FormRow customClass="companyForm_row">
          <BoxedInput
            formRowItemKey="companyForm_dmsId"
            type="text"
            id="dmsId"
            labelKey="company.field.external.id"
            customClass="companyForm_boxedInput"
            disabled={readOnly}
            field={externalId}
            mandatory
          >
            <FieldErrorMsg field={externalId} customClass="fieldErrorMsg--companyForm" />
          </BoxedInput>
          {isDisplayForm && (
            <BoxedSelect
              formRowItemKey="companyForm_dmsFiscalCode"
              id="dmsFiscalCode"
              labelKey="company.field.dms.fiscalcode"
              options={this.linkTypeSubscriptionCustomFieldsOptions}
              customClass="companyForm_fullWidthBoxedSelectWrapper"
              disabled={readOnly}
              field={fiscalCode}
            >
              <FieldErrorMsg field={fiscalCode} customClass="fieldErrorMsg--companyForm" />
            </BoxedSelect>
          )}
        </FormRow>
      );
    }
  }

  showRrsField() {
    const {
      fields: {
        useExternalInvoiceSystem,
        clientProfileType,
        taxiClientCostCustomFieldId,
        taxiClientIpnCustomFieldId,
        taxiMissionOrderCustomFieldId
      },
      readOnly,
      isDisplayForm
    } = this.props;

    if (fieldValue(useExternalInvoiceSystem) === EXTERNAL_INVOICE.RRS) {
      return (
        <FormRow customClass="companyForm_row">
          <BoxedInput
            formRowItemKey="companyForm_dmsId"
            type="text"
            id="dmsId"
            labelKey="company.field.clientProfileType"
            customClass="companyForm_boxedInput"
            disabled={readOnly}
            field={clientProfileType}
            mandatory
          >
            <FieldErrorMsg field={clientProfileType} customClass="fieldErrorMsg--companyForm" />
          </BoxedInput>
          {isDisplayForm && (
            <BoxedSelect
              formRowItemKey="companyForm_rrscostcenter"
              id="costCenter"
              labelKey="company.field.rrs.cost"
              options={this.linkTypeSubscriptionCustomFieldsOptions}
              customClass="companyForm_fullWidthBoxedSelectWrapper"
              disabled={readOnly}
              field={taxiClientCostCustomFieldId}
            >
              <FieldErrorMsg field={taxiClientCostCustomFieldId} customClass="fieldErrorMsg--companyForm" />
            </BoxedSelect>
          )}
          {isDisplayForm && (
            <BoxedSelect
              formRowItemKey="companyForm_rrsipn"
              id="ipn"
              labelKey="company.field.rrs.ipn"
              options={this.linkTypeSubscriptionCustomFieldsOptions}
              customClass="companyForm_fullWidthBoxedSelectWrapper"
              disabled={readOnly}
              field={taxiClientIpnCustomFieldId}
            >
              <FieldErrorMsg field={taxiClientIpnCustomFieldId} customClass="fieldErrorMsg--companyForm" />
            </BoxedSelect>
          )}
          {isDisplayForm && (
            <BoxedSelect
              formRowItemKey="companyForm_rrsmissionOder"
              id="missionOrder"
              labelKey="company.field.rrs.order"
              options={this.linkTypeBookingCustomFieldsOptions}
              customClass="companyForm_fullWidthBoxedSelectWrapper"
              disabled={readOnly}
              field={taxiMissionOrderCustomFieldId}
            >
              <FieldErrorMsg field={taxiMissionOrderCustomFieldId} customClass="fieldErrorMsg--companyForm" />
            </BoxedSelect>
          )}
        </FormRow>
      );
    }
  }

  showItalianField() {
    const {
      fields: { useExternalInvoiceSystem, italianMemberProfileCustomFieldId, fiscalCode },
      readOnly,
      isDisplayForm
    } = this.props;

    if (isDisplayForm && fieldValue(useExternalInvoiceSystem) === EXTERNAL_INVOICE.ITALIAN) {
      return (
        <FormRow customClass="companyForm_row">
          <BoxedSelect
            formRowItemKey="companyForm_italianBool"
            id="italianBool"
            labelKey="company.invoice.italian.bool"
            options={this.linkTypeSubscriptionCustomFieldsOptions}
            customClass="companyForm_fullWidthBoxedSelectWrapper"
            disabled={readOnly}
            field={italianMemberProfileCustomFieldId}
          >
            <FieldErrorMsg field={italianMemberProfileCustomFieldId} customClass="fieldErrorMsg--companyForm" />
          </BoxedSelect>
          <BoxedSelect
            formRowItemKey="companyForm_dmsFiscalCode"
            id="dmsFiscalCode"
            labelKey="label.italian.fiscal.code"
            options={this.linkTypeSubscriptionCustomFieldsOptions}
            customClass="companyForm_fullWidthBoxedSelectWrapper"
            disabled={readOnly}
            field={fiscalCode}
          >
            <FieldErrorMsg field={fiscalCode} customClass="fieldErrorMsg--companyForm" />
          </BoxedSelect>
        </FormRow>
      );
    }
  }

  displayBrandsSelector() {
    const {
      fields: { appBrandId },
      readOnly,
      isDisplayForm,
      readOnlyBrands
    } = this.props;

    if (this.appBrandsOptions.length > 0) {
      return (
        <BoxedSelect
          formRowItemKey="companyForm_appBrands"
          id="appBrandId"
          labelKey="companyForm_appBrand_label"
          options={this.appBrandsOptions}
          field={appBrandId}
          onChange={this.handleAppBrandChange}
          disabled={readOnly || isDisplayForm || readOnlyBrands}
          customClass="companyForm_fullWidthBoxedSelectWrapper"
          mandatory
        >
          <FieldErrorMsg field={appBrandId} customClass="fieldErrorMsg--companyForm" />
        </BoxedSelect>
      );
    }
  }

  displayMainInfoBlock() {
    const {
      fields: {
        name,
        address,
        postalCode,
        city,
        country,
        email,
        secondaryEmail,
        phoneNumber,
        secondaryPhoneNumber,
        configurationId,
        useExternalInvoiceSystem,
        companyLogo,
        access
      },
      allConfigurations,
      readOnly,
      vehicleRules,
      isDisplayForm
    } = this.props;

    return (
      <section className="companyFormWrapper">
        <div className="companyForm_container">
          <h4 className="siteForm_title">
            <FormattedMessage id="companyForm_title" />
          </h4>
          <FormRow customClass="companyForm_row">
            <BoxedInput
              disabled={readOnly}
              formRowItemKey="companyForm_name"
              type="text"
              id="name"
              labelKey="companyForm_name_label"
              customClass="companyForm_boxedInput"
              field={name}
              mandatory
            >
              <FieldErrorMsg field={name} customClass="fieldErrorMsg--companyForm" />
            </BoxedInput>
          </FormRow>
          <FormRow customClass="companyForm_row">
            <label formRowItemKey="companyForm_address_label" className="boxedInput_label">
              <span className="boxedInput_labelText">
                <FormattedMessage id="companyForm_address_label" /> <span className="labelMandatory">*</span>
              </span>
              <AddressAutocomplete
                disabled={readOnly}
                field={address}
                className="companyForm_AddressAutocomplete"
                form={COMPANY_FORM}
                spreadExternalFields
              />
              <FieldErrorMsg field={address} customClass="fieldErrorMsg--companyForm" />
            </label>
            <BoxedInput
              formRowItemKey="companyForm_postalCode"
              type="text"
              id="postalCode"
              labelKey="common_autocomplete_postal_code"
              customClass="companyForm_boxedInput"
              disabled={readOnly}
              field={postalCode}
              mandatory
            >
              <FieldErrorMsg field={postalCode} customClass="fieldErrorMsg--companyForm" />
            </BoxedInput>
            <BoxedInput
              formRowItemKey="companyForm_city"
              type="text"
              id="city"
              labelKey="common_autocomplete_city"
              customClass="companyForm_boxedInput"
              disabled={readOnly}
              field={city}
              mandatory
            >
              <FieldErrorMsg field={city} customClass="fieldErrorMsg--companyForm" />
            </BoxedInput>
            <label className="boxedInput_label">
              <span className="boxedInput_labelText">
                <FormattedMessage id="common_autocomplete_country" />
                <span className="labelMandatory">*</span>
              </span>
              <AddressAutocomplete field={country} searchType={['country']} disabled={readOnly} translateCountryCode />
              <FieldErrorMsg field={country} customClass="fieldErrorMsg--companyForm" />
            </label>
          </FormRow>
          <FormRow customClass="companyForm_row">
            <BoxedInput
              formRowItemKey="companyForm_email"
              type="text"
              id="email"
              labelKey="companyForm_email_label"
              customClass="companyForm_boxedInput"
              disabled={readOnly}
              field={email}
              mandatory
            >
              <FieldErrorMsg field={email} customClass="fieldErrorMsg--companyForm" />
            </BoxedInput>
            <BoxedInput
              formRowItemKey="companyForm_email"
              type="text"
              id="email_secondary"
              labelKey="companyForm_secondary_email_label"
              customClass="companyForm_boxedInput"
              disabled={readOnly}
              field={secondaryEmail}
            >
              <FieldErrorMsg field={secondaryEmail} customClass="fieldErrorMsg--companyForm" />
            </BoxedInput>
          </FormRow>
          <FormRow customClass="companyForm_row">
            <PhoneNumberField
              id="phoneNumber"
              label={<FormattedMessage id="companyForm_mobilePhone_label" />}
              field={phoneNumber}
              disabled={readOnly}
              mandatory
            />
            <PhoneNumberField
              label={<FormattedMessage id="companyForm_secondary_mobilePhone_label" />}
              id="secondary_mobilePhone"
              field={secondaryPhoneNumber}
              disabled={readOnly}
            />
          </FormRow>
          <FormRow customClass="companyForm_row">
            <BoxedSelect
              formRowItemKey="companyForm_parent_configuration"
              id="configurationId"
              labelKey="companyForm_configuration_label"
              options={mapOptions(allConfigurations)}
              field={configurationId}
              customClass="companyForm_fullWidthBoxedSelectWrapper"
              disabled={readOnly}
              onChange={this.handleConfigurationSelect}
              mandatory
            >
              <FieldErrorMsg field={configurationId} customClass="fieldErrorMsg--companyForm" />
            </BoxedSelect>
            <BoxedSelect
              formRowItemKey="companyForm_access"
              customClass="companyForm_fullWidthBoxedSelectWrapper"
              labelKey="common_access"
              options={accessOptions}
              noEmptyValue
              disabled={readOnly}
              field={access}
            >
              <FieldErrorMsg field={access} customClass="fieldErrorMsg--companyForm" />
            </BoxedSelect>
          </FormRow>
          {isDisplayForm && vehicleRules.carwise && this.showCarwiseField()}
          <FormRow customClass="companyForm_row">
            <BoxedSelect
              formRowItemKey="companyForm_useExternalInvoiceSystem"
              customClass="companyForm_fullWidthBoxedSelectWrapper"
              labelKey="companyForm_useExternalInvoiceSystem_label"
              options={externalInvoiceOptions}
              noEmptyValue
              disabled={readOnly}
              field={useExternalInvoiceSystem}
            >
              <FieldErrorMsg field={useExternalInvoiceSystem} customClass="fieldErrorMsg--companyForm" />
            </BoxedSelect>
            {this.displayBrandsSelector()}
          </FormRow>
          {this.showDmsFields()}
          {this.showRrsField()}
          {this.showItalianField()}
          <div className="companyForm_fileUpload_row">
            <FileUpload label="company_email_setup_logo" id="company-logo-url" field={companyLogo} disabled={readOnly} noPadding />
          </div>
        </div>
      </section>
    );
  }

  displayMemberSubscribeBlock() {
    const {
      fields: {
        expediteReview,
        drivingLicenceRequired,
        deleteMemberDocumentsOnApproval,
        identityDocumentRequired,
        employerCertificateRequired,
        employerCertificateUrl,
        subscriptionUrl,
        sendEmailsToManager,
        inviteCode,
        memberExternalIdForImportCustomFieldId
      },
      readOnly
    } = this.props;

    return (
      <section className="companyFormWrapper">
        <div className="companyForm_container">
          <h4 className="siteForm_title">
            <FormattedMessage id="title.member.subscribe.config" />
          </h4>
          <FormRow customClass="companyForm_row">
            {(!this.props.isDisplayForm || this.props.memberRules.expediteReview) && (
              <EkRadio
                disabled={readOnly}
                formRowItemKey="companyForm_expediteReview"
                customContainerClass="companyForm_ekRadioContainer"
                customWrapperClass="companyForm_ekRadioWrapper"
                titleKey="companyForm_expediteReview_label"
                items={booleanOptions}
                namespace="expediteReview"
                field={expediteReview}
              />
            )}
            {(!this.props.isDisplayForm || this.props.memberRules.managerEmail) && (
              <EkRadio
                customContainerClass="companyForm_ekRadioContainer"
                customWrapperClass="companyForm_ekRadioWrapper"
                titleKey="companyForm_sendEmailToManagers_label"
                items={booleanOptions}
                namespace="sendEmailToManagers"
                field={sendEmailsToManager}
                disabled={readOnly}
              />
            )}
          </FormRow>
          <FormRow customClass="companyForm_row">
            <EkRadio
              formRowItemKey="companyForm_drivingLicenceRequired"
              customContainerClass="companyForm_ekRadioContainer"
              customWrapperClass="companyForm_ekRadioWrapper"
              titleKey="companyForm_drivingLicenceRequired_label"
              items={booleanOptions}
              namespace="drivingLicenceRequired"
              field={drivingLicenceRequired}
              disabled={readOnly}
            />
            <EkRadio
              formRowItemKey="companyForm_deleteDocumentsOnceApproved"
              customContainerClass="companyForm_ekRadioContainer"
              customWrapperClass="companyForm_ekRadioWrapper"
              titleKey="companyForm_deleteDocumentsOnceApproved_label"
              items={booleanOptions}
              namespace="deleteMemberDocumentsOnApproval"
              field={deleteMemberDocumentsOnApproval}
              disabled={readOnly}
            />
          </FormRow>
          <FormRow customClass="companyForm_row">
            <EkRadio
              formRowItemKey="companyForm_identityDocumentRequired"
              customContainerClass="companyForm_ekRadioContainer"
              customWrapperClass="companyForm_ekRadioWrapper"
              titleKey="companyForm_identityDocumentRequired_label"
              items={booleanOptions}
              namespace="identityDocumentRequired"
              field={identityDocumentRequired}
              disabled={readOnly}
            />
            <EkRadio
              formRowItemKey="companyForm_employerCertificateRequired"
              customContainerClass="companyForm_ekRadioContainer"
              customWrapperClass="companyForm_ekRadioWrapper"
              titleKey="companyForm_employerCertificateRequired_label"
              items={booleanOptions}
              namespace="employerCertificateRequired"
              field={employerCertificateRequired}
              disabled={readOnly}
            />
          </FormRow>
          <FormRow customClass="companyForm_row">
            <BoxedInput
              formRowItemKey="companyForm_employerCertificateUrl"
              type="text"
              id="employerCertificateUrl"
              labelKey="companyForm_employerCertificateUrl_label"
              customClass="companyForm_boxedInput"
              field={employerCertificateUrl}
              disabled={readOnly}
            >
              <FieldErrorMsg field={employerCertificateUrl} customClass="fieldErrorMsg--companyForm" />
            </BoxedInput>
          </FormRow>
          {!_isEmpty(this.CFExternalIDsOptions) && (
            <FormRow>
              <BoxedSelect
                labelKey="company_details_label_custom_field_externalID"
                field={memberExternalIdForImportCustomFieldId}
                id="company-params-cf-externalId"
                options={this.CFExternalIDsOptions}
              >
                <FieldErrorMsg customClass="fieldErrorMsg--companyForm" field={memberExternalIdForImportCustomFieldId} />
              </BoxedSelect>
            </FormRow>
          )}
          {fieldValueSafe(subscriptionUrl) && (
            <FormRow customClass="companyForm_row">
              <BoxedInput
                formRowItemKey="subscriptionUrl"
                customClass="companyForm_boxedInput subscriptionUrl"
                labelKey="companies_detail_subscriptionUrl"
                disabled
                field={subscriptionUrl}
                type="text"
                id="subscriptionUrl"
              />
              <BoxedInput
                formRowItemKey="inviteCode"
                customClass="companyForm_boxedInput subscriptionUrl"
                labelKey="companies_detail_subscriptionInviteCode"
                disabled
                field={inviteCode}
                type="text"
                id="inviteCode"
              />
            </FormRow>
          )}
        </div>
      </section>
    );
  }

  displayLegalDocsBlock() {
    const {
      fields: {
        sendDrivingAuthorisation,
        genericPdfDocumentUrl,
        logoUrl,
        signatureImageUrl,
        vehicleOwner,
        drivingAuthCompanyName,
        drivingAuthAddress,
        drivingAuth_postalCode,
        drivingAuth_city,
        drivingAuthPhone,
        termsOfUse,
        termsOfSubscription,
        privacyPolicy,
        displayAdditionalLegalDoc,
        additionalLegalDocFileId,
        additionalLegalDocTranslations,
        additionalLegalDocName
      },
      submitFailed,
      readOnly
    } = this.props;
    const isTranslationsEmpty = additionalLegalDocTranslations.length === 0;
    return (
      <section className="companyFormWrapper">
        <div className="companyForm_container">
          <h4 className="siteForm_title">
            <FormattedMessage id="companyForm_Legal_Documents_block" />
          </h4>

          <div className="companyForm_fileUpload_row multiple">
            <FileUpload
              label="companyForm_termsOfUseUrl_label"
              id="termsOfUse"
              field={termsOfUse}
              mandatory
              isMandatory
              disabled={readOnly}
              noPadding
            />
            <FileUpload
              label="companyForm_termsOfSubscriptionUrl_label"
              id="termsOfSubscription"
              field={termsOfSubscription}
              disabled={readOnly}
              allowDeletion={this.deleteFile}
              noPadding
            />
            <FileUpload
              id="privacyPolicy"
              label="companyForm_privacyPolicyUrl_label"
              field={privacyPolicy}
              disabled={readOnly}
              allowDeletion={this.deleteFile}
              noPadding
            />
          </div>
          <FormRow customClass="companyForm_row companyForm_row_dricingLicence">
            <EkRadio
              formRowItemKey="companyForm_sendDrivingAuthorisation"
              customContainerClass="companyForm_ekRadioContainer"
              customWrapperClass="companyForm_ekRadioWrapper"
              titleKey="companyForm_sendDrivingAuthorisation_label"
              items={booleanOptions}
              namespace="sendDrivingAuthorisation"
              field={sendDrivingAuthorisation}
              disabled={readOnly}
            />
          </FormRow>
          {toBoolean(sendDrivingAuthorisation.value) && (
            <div>
              <FormRow customClass="companyForm_row companyForm_row_dricingLicence">
                <BoxedInput
                  formRowItemKey="companyForm_genericPdfDocumentUrl"
                  type="text"
                  id="genericPdfDocumentUrl"
                  labelKey="companyForm_genericPdfDocumentUrl_label"
                  customClass="companyForm_boxedInput"
                  field={genericPdfDocumentUrl}
                  disabled={readOnly}
                  mandatory
                >
                  <FieldErrorMsg field={genericPdfDocumentUrl} customClass="fieldErrorMsg--companyForm" />
                </BoxedInput>

                <BoxedInput
                  formRowItemKey="companyForm_logoUrl"
                  type="text"
                  id="logoUrl"
                  labelKey="companyForm_logoUrl_label"
                  customClass="companyForm_boxedInput"
                  field={logoUrl}
                  disabled={readOnly}
                  mandatory
                >
                  <FieldErrorMsg field={logoUrl} customClass="fieldErrorMsg--companyForm" />
                </BoxedInput>

                <BoxedInput
                  formRowItemKey="companyForm_signatureImageUrl"
                  type="text"
                  id="signatureImageUrl"
                  labelKey="companyForm_signatureImageUrl_label"
                  customClass="companyForm_boxedInput"
                  field={signatureImageUrl}
                  disabled={readOnly}
                  mandatory
                >
                  <FieldErrorMsg field={signatureImageUrl} customClass="fieldErrorMsg--companyForm" />
                </BoxedInput>
              </FormRow>
              <FormRow customClass="companyForm_row companyForm_row_dricingLicence">
                <BoxedInput
                  formRowItemKey="companyForm_vehicleOwner"
                  type="text"
                  id="vehicleOwner"
                  labelKey="companyForm_vehicleOwner_label"
                  customClass="companyForm_boxedInput"
                  field={vehicleOwner}
                  disabled={readOnly}
                  mandatory
                >
                  <FieldErrorMsg field={vehicleOwner} customClass="fieldErrorMsg--companyForm" />
                </BoxedInput>

                <BoxedInput
                  formRowItemKey="companyForm_drivingAuthCompanyName"
                  type="text"
                  id="drivingAuthCompanyName"
                  labelKey="companyForm_drivingAuthCompanyName_label"
                  customClass="companyForm_boxedInput"
                  field={drivingAuthCompanyName}
                  disabled={readOnly}
                  mandatory
                >
                  <FieldErrorMsg field={drivingAuthCompanyName} customClass="fieldErrorMsg--companyForm" />
                </BoxedInput>

                <div>
                  <PhoneNumberField
                    id="drivingAuthPhoneNumber"
                    label={<FormattedMessage id="companyForm_mobilePhone_label" />}
                    field={drivingAuthPhone}
                    disabled={readOnly}
                    mandatory
                  />
                </div>
              </FormRow>
              <FormRow customClass="companyForm_row companyForm_row_dricingLicence">
                <label formRowItemKey="companyForm_address_label" className="boxedInput_label">
                  <span className="boxedInput_labelText">
                    <FormattedMessage id="companyForm_address_label" /> <span className="labelMandatory">*</span>
                  </span>
                  <AddressAutocomplete
                    form={COMPANY_FORM}
                    className="companyForm_AddressAutocomplete"
                    field={drivingAuthAddress}
                    spreadFieldsPrefix="drivingAuth_"
                    spreadExternalFields
                    disabled={readOnly}
                  />
                  <FieldErrorMsg field={drivingAuthAddress} customClass="fieldErrorMsg--companyForm" />
                </label>
                <BoxedInput
                  formRowItemKey="companyForm_drivingAuthPostalCode"
                  type="text"
                  id="drivingAuthPostalCode"
                  labelKey="common_autocomplete_postal_code"
                  customClass="companyForm_boxedInput"
                  field={drivingAuth_postalCode}
                  disabled={readOnly}
                  mandatory
                >
                  <FieldErrorMsg field={drivingAuth_postalCode} customClass="fieldErrorMsg--companyForm" />
                </BoxedInput>
                <BoxedInput
                  formRowItemKey="companyForm_drivingAuthCity"
                  type="text"
                  id="drivingAuthCity"
                  labelKey="common_autocomplete_city"
                  customClass="companyForm_boxedInput"
                  field={drivingAuth_city}
                  disabled={readOnly}
                  mandatory
                >
                  <FieldErrorMsg field={drivingAuth_city} customClass="fieldErrorMsg--companyForm" />
                </BoxedInput>
              </FormRow>
            </div>
          )}
          <FormRow customClass="companyForm_row companyForm_row_dricingLicence">
            <EkRadio
              formRowItemKey="companyForm_displayAdditionalLegalDoc"
              customContainerClass="companyForm_ekRadioContainer"
              customWrapperClass="companyForm_ekRadioWrapper"
              titleKey="companyForm_sdisplay_additionnal_doc_label"
              items={booleanOptions}
              namespace="displayAdditionalLegalDoc"
              field={displayAdditionalLegalDoc}
              disabled={readOnly}
            />
          </FormRow>
          {toBoolean(displayAdditionalLegalDoc.value) && (
            <div style={{ display: 'flex', marginBottom: '20px' }}>
              <div style={{ width: '33%' }}>
                <FileUpload
                  label="companyForm_additionalLegalDoc_label"
                  id="additionalLegalDoc"
                  field={additionalLegalDocFileId}
                  mandatory
                  isMandatory
                  disabled={readOnly}
                  noPadding
                />
              </div>
              <div style={{ width: '67%', padding: '0 10px' }}>
                <BoxedInput
                  formRowItemKey="companyForm_additionalLegalDoc_name"
                  type="text"
                  id="additionalLegalDocName"
                  labelKey="common_name"
                  customClass="companyForm_boxedInput"
                  field={additionalLegalDocName}
                  disabled={readOnly}
                  mandatory
                >
                  <FieldErrorMsg field={additionalLegalDocName} customClass="fieldErrorMsg--companyForm" />
                </BoxedInput>
                <TranslationsFields keyLabel="common_name" serviceNames={additionalLegalDocTranslations} mandatory />
                {isTranslationsEmpty && submitFailed && (
                  <span className="fieldErrorMsg">
                    <FormattedMessage id="validation_array_length" />
                  </span>
                )}
              </div>
            </div>
          )}
        </div>
      </section>
    );
  }

  getDamageReportFields() {
    const { isDisplayForm, bookingRules } = this.props;
    if (isDisplayForm && !bookingRules.damageReport) return;

    const { damageWithPhoto, damageExtraPhoto, dekra } = bookingRules;
    const showDamageWithPhoto = isDisplayForm ? damageWithPhoto : true;
    const showDamageExtraPhoto = isDisplayForm ? damageExtraPhoto : true;
    const showDekra = isDisplayForm ? dekra : true;

    const {
      fields: {
        startBookingDamageReportMandatory,
        damageReportPhotos,
        endBookingDamageReportMandatory,
        enableDekraService,
        dekraCheckService,
        dekraExpertiseService,
        enabledReportAdditionalPhoto,
        nameForElectricVehicle,
        nameForHybridVehicle,
        nameForThermalVehicle,
        startBooking,
        endBooking
      },
      readOnly
    } = this.props;

    return (
      <div>
        <FormRow customClass="companyForm_row">
          <EkRadio
            formRowItemKey="companyForm_startBookingDamageReportMandatory"
            customContainerClass="companyForm_ekRadioContainer"
            customWrapperClass="companyForm_ekRadioWrapper"
            titleKey="companyForm_startBookingDamageReportMandatory_label"
            items={booleanOptions}
            namespace="startBookingDamageReportMandatory"
            field={startBookingDamageReportMandatory}
            disabled={readOnly}
          />
          {showDamageWithPhoto && (
            <EkRadio
              formRowItemKey="companyForm_damageReportPhotos"
              customContainerClass="companyForm_ekRadioContainer"
              customWrapperClass="companyForm_ekRadioWrapper"
              titleKey="companyForm_damageReportPhotos_label"
              items={booleanOptions}
              namespace="damageReportPhotos"
              field={damageReportPhotos}
              disabled={readOnly}
            />
          )}
        </FormRow>
        <FormRow customClass="companyForm_row">
          <EkRadio
            formRowItemKey="companyForm_endBookingDamageReportMandatory"
            customContainerClass="companyForm_ekRadioContainer"
            customWrapperClass="companyForm_ekRadioWrapper"
            titleKey="companyForm_endBookingDamageReportMandatory_label"
            items={booleanOptions}
            namespace="endBookingDamageReportMandatory"
            field={endBookingDamageReportMandatory}
            disabled={readOnly}
          />
          {showDamageExtraPhoto && (
            <EkRadio
              formRowItemKey="companyForm_enabledReportAdditionalPhoto"
              customContainerClass="companyForm_ekRadioContainer"
              customWrapperClass="companyForm_ekRadioWrapper"
              titleKey="companyForm_vehicleReportAdditionalPhoto_label"
              items={booleanOptions}
              namespace="enabledReportAdditionalPhoto"
              field={enabledReportAdditionalPhoto}
              disabled={readOnly}
            />
          )}
        </FormRow>
        {showDamageExtraPhoto && radioFieldEnabled(enabledReportAdditionalPhoto) && (
          <div>
            <FormRow customClass="companyForm_row companyForm_row--multiple">
              <BoxedInput
                formRowItemKey="companyForm_nameForElectricVehicle"
                type="text"
                id="reportAdditionalPhotoNameForElectricVehicle"
                labelKey="reportAdditionalPhotoNameForElectricVehicle_label"
                customClass="companyForm_boxedInput"
                field={nameForElectricVehicle}
                disabled={readOnly}
              >
                <FieldErrorMsg field={nameForElectricVehicle} customClass="fieldErrorMsg--companyForm" />
              </BoxedInput>

              <BoxedInput
                formRowItemKey="companyForm_nameForHybridVehicle"
                type="text"
                id="reportAdditionalPhotoNameForHydribVehicle"
                labelKey="reportAdditionalPhotoNameForHybridVehicle_label"
                customClass="companyForm_boxedInput"
                field={nameForHybridVehicle}
                disabled={readOnly}
              >
                <FieldErrorMsg field={nameForHybridVehicle} customClass="fieldErrorMsg--companyForm" />
              </BoxedInput>

              <BoxedInput
                formRowItemKey="companyForm_nameForThermalVehicle"
                type="text"
                id="reportAdditionalPhotoNameForThermalVehicle"
                labelKey="reportAdditionalPhotoNameForThermalVehicle_label"
                customClass="companyForm_boxedInput"
                field={nameForThermalVehicle}
                disabled={readOnly}
              >
                <FieldErrorMsg field={nameForThermalVehicle} customClass="fieldErrorMsg--companyForm" />
              </BoxedInput>
            </FormRow>

            <FormRow customClass="companyForm_row">
              <EkRadio
                formRowItemKey="companyForm_vehicleInspectionPhotoAtBokkingStart"
                customContainerClass="companyForm_ekRadioContainer"
                customWrapperClass="companyForm_ekRadioWrapper"
                titleKey="companyForm_startBookingReportAdditionalPhoto_label"
                items={booleanOptions}
                namespace="vehicleInspectionPhotoAtBookingStart"
                field={startBooking}
                disabled={readOnly}
              />
              <EkRadio
                formRowItemKey="companyForm_vehicleInspectionPhotoAtBokkingEnd"
                customContainerClass="companyForm_ekRadioContainer"
                customWrapperClass="companyForm_ekRadioWrapper"
                titleKey="companyForm_endBookingReportAdditionalPhoto_label"
                items={booleanOptions}
                namespace="vehicleInspectionPhotoAtBokoingEnd"
                field={endBooking}
                disabled={readOnly}
              />
            </FormRow>
          </div>
        )}
        {showDekra && (
          <div style={{ paddingTop: '10px' }}>
            <FormRow customClass="companyForm_row">
              <EkRadio
                formRowItemKey="companyForm_dekraService"
                customContainerClass="companyForm_ekRadioContainer"
                customWrapperClass="companyForm_ekRadioWrapper"
                titleKey="enable.booking.dekra"
                items={booleanOptions}
                namespace="DekraSettings"
                field={enableDekraService}
                disabled={readOnly}
              >
                <FieldErrorMsg field={enableDekraService} customClass="fieldErrorMsg--companyForm" />
              </EkRadio>
            </FormRow>
          </div>
        )}
        {showDekra && radioFieldEnabled(enableDekraService) && (
          <FormRow customClass="companyForm_row">
            <BoxedInput
              formRowItemKey="companyForm_dekraCheckService"
              type="text"
              id="dekraCheckService"
              labelKey="booking.dekra.check"
              mandatory
              customClass="companyForm_boxedInput"
              field={dekraCheckService}
              disabled={readOnly}
            >
              <FieldErrorMsg field={dekraCheckService} customClass="fieldErrorMsg--companyForm" />
            </BoxedInput>
            <BoxedInput
              formRowItemKey="companyForm_dekraExpertiseService"
              type="text"
              id="dekraExpertiseService"
              mandatory
              labelKey="booking.dekra.expertise"
              customClass="companyForm_boxedInput"
              field={dekraExpertiseService}
              disabled={readOnly}
            >
              <FieldErrorMsg field={dekraExpertiseService} customClass="fieldErrorMsg--companyForm" />
            </BoxedInput>
          </FormRow>
        )}
      </div>
    );
  }

  displayBookingConfigBlock() {
    const {
      fields: { durationAfterTripToAllowLockUnlock, vehicleReassignmentDelayInMin },
      readOnly,
      intl: { formatMessage }
    } = this.props;

    return (
      <section className="companyFormWrapper">
        <div className="companyForm_container">
          <h4 className="siteForm_title">
            <FormattedMessage id="title.booking.config" />
          </h4>
          <FormRow customClass="companyForm_row">
            <BoxedInput
              formRowItemKey="companyForm_duration"
              type="text"
              id="durationAfterTripToAllowLockUnlock"
              labelKey="companyForm_durationLockUnlock_label"
              customClass="companyForm_boxedInput"
              field={durationAfterTripToAllowLockUnlock}
              maxlength="2"
              disabled={readOnly}
              mandatory
            >
              <FieldErrorMsg field={durationAfterTripToAllowLockUnlock} customClass="fieldErrorMsg--companyForm" />
            </BoxedInput>
            <BoxedInput
              formRowItemKey="companyForm_vehicleReassignmentDelayInMin"
              type="text"
              id="vehicleReassignmentDelayInMin"
              labelKey="companyForm_vehicleReassignmentDelayInMin_label"
              customClass="companyForm_boxedInput"
              field={vehicleReassignmentDelayInMin}
              toolTipText={formatMessage(toolTipMessages.vehicleReassignmentDelayInMin)}
              disabled={readOnly}
            >
              <FieldErrorMsg field={vehicleReassignmentDelayInMin} customClass="fieldErrorMsg--companyForm" />
            </BoxedInput>
          </FormRow>

          {this.getDamageReportFields()}
        </div>
      </section>
    );
  }

  getSsoFields() {
    const {
      fields: {
        ssoEnabled,
        identityProvider,
        ssoDomains,
        allowRegistrationWithoutSso,
        emailAutomaticallyVerified,
        profileAutomaticallyApproved
      },
      intl: { formatMessage },
      readOnly,
      companyId,
      memberRules,
      isDisplayForm
    } = this.props;

    if (isDisplayForm && !memberRules.sso) return;

    return (
      <div>
        {companyId && (
          <FormRow customClass="companyForm_row">
            <EkRadio
              formRowItemKey="companyForm_ssoEnabled"
              customContainerClass="companyForm_ekRadioContainer"
              customWrapperClass="companyForm_ekRadioWrapper"
              titleKey="companyForm_ssoEnabled_label"
              items={booleanOptions}
              namespace="ssoEnabled"
              field={ssoEnabled}
              disabled={readOnly}
            />
          </FormRow>
        )}
        {radioFieldEnabled(ssoEnabled) && (
          <div>
            <FormRow customClass="companyForm_row">
              <BoxedInput
                formRowItemKey="companyForm_identityProvider"
                type="text"
                id="identityProvider"
                labelKey="companyForm_identityProvider_label"
                customClass="companyForm_boxedInput"
                field={identityProvider}
                disabled={readOnly}
              >
                <FieldErrorMsg field={identityProvider} customClass="fieldErrorMsg--companyForm" />
              </BoxedInput>
            </FormRow>
            <FormRow>
              <BoxedInput
                formRowItemKey="companyForm_domains"
                type="text"
                id="domains"
                labelKey="companyForm_domains_label"
                customClass="companyForm_boxedInput"
                field={ssoDomains}
                toolTipText={formatMessage(toolTipMessages.domains)}
                disabled={readOnly}
              >
                <FieldErrorMsg field={ssoDomains} customClass="fieldErrorMsg--companyForm" />
              </BoxedInput>
            </FormRow>
            <FormRow>
              <EkRadio
                formRowItemKey="companyForm_allowRegistrationWithoutSso"
                customContainerClass="companyForm_ekRadioContainer"
                customWrapperClass="companyForm_ekRadioWrapper"
                titleKey="companyForm_allowRegistrationWithoutSso_label"
                items={booleanOptions}
                namespace="ssoEnabled"
                field={allowRegistrationWithoutSso}
                disabled={readOnly}
              />
            </FormRow>
            <FormRow>
              <EkRadio
                formRowItemKey="companyForm_emailAutomaticallyVerified"
                customContainerClass="companyForm_ekRadioContainer"
                customWrapperClass="companyForm_ekRadioWrapper"
                titleKey="companyForm_emailAutomaticallyVerified_label"
                items={booleanOptions}
                namespace="ssoEnabled"
                field={emailAutomaticallyVerified}
                disabled={readOnly}
              />
            </FormRow>
            <FormRow>
              <EkRadio
                formRowItemKey="companyForm_profileAutomaticallyApproved"
                customContainerClass="companyForm_ekRadioContainer"
                customWrapperClass="companyForm_ekRadioWrapper"
                titleKey="companyForm_profileAutomaticallyApproved_label"
                items={booleanOptions}
                namespace="ssoEnabled"
                field={profileAutomaticallyApproved}
                disabled={readOnly}
              />
            </FormRow>
          </div>
        )}
      </div>
    );
  }

  displayDevBlock() {
    const {
      fields: { websiteConfirmSubscriptionUrl, websiteResetPasswordUrl, backofficeResetPasswordUrl },
      intl: { formatMessage },
      readOnly
    } = this.props;

    return (
      <section className="companyFormWrapper">
        <div className="companyForm_container">
          <h4 className="siteForm_title">
            <FormattedMessage id="company.section.dev.options" />
          </h4>
          <FormRow customClass="companyForm_row">
            <BoxedInput
              formRowItemKey="companyForm_confirmSubscriptionUrl"
              type="text"
              id="websiteConfirmSubscriptionUrl"
              labelKey="companyForm_confirmSubscriptionUrl_label"
              customClass="companyForm_boxedInput"
              field={websiteConfirmSubscriptionUrl}
              toolTipText={formatMessage(toolTipMessages.websiteConfirmSubscriptionUrl)}
              disabled={readOnly}
            >
              <FieldErrorMsg field={websiteConfirmSubscriptionUrl} customClass="fieldErrorMsg--companyForm" />
            </BoxedInput>
          </FormRow>
          <FormRow customClass="companyForm_row">
            <BoxedInput
              formRowItemKey="companyForm_websiteResetPasswordUrl"
              type="text"
              id="websiteResetPasswordUrl"
              labelKey="companyForm_websiteResetPasswordUrl_label"
              customClass="companyForm_boxedInput"
              field={websiteResetPasswordUrl}
              toolTipText={formatMessage(toolTipMessages.websiteUrl)}
              disabled={readOnly}
            >
              <FieldErrorMsg field={websiteResetPasswordUrl} customClass="fieldErrorMsg--companyForm" />
            </BoxedInput>
          </FormRow>
          <FormRow customClass="companyForm_row">
            <BoxedInput
              formRowItemKey="companyForm_backofficeResetPasswordUrl"
              type="text"
              id="backofficeResetPasswordUrl"
              labelKey="companyForm_backofficeResetPasswordUrl_label"
              customClass="companyForm_boxedInput"
              field={backofficeResetPasswordUrl}
              toolTipText={formatMessage(toolTipMessages.backofficeUrl)}
              disabled={readOnly}
            >
              <FieldErrorMsg field={backofficeResetPasswordUrl} customClass="fieldErrorMsg--companyForm" />
            </BoxedInput>
          </FormRow>
          {this.getSsoFields()}
        </div>
      </section>
    );
  }

  showDamageDepositFields() {
    const { isDisplayForm, paymentRules } = this.props;
    if (isDisplayForm && !paymentRules.damageSafetyDeposit) return;

    const {
      fields: { enableBookingDamageSafetyDeposit, bookingDamageSafetyDepositAmount, damageSafetyDepositReleaseDelayInDays, currency },
      intl: { formatMessage },
      readOnly
    } = this.props;

    let currencyKey = 'unit_EUR';
    const currencyType = fieldValue(currency);

    if (currencyType) {
      currencyKey = 'unit_' + currencyType;
    }

    const currencySymbol = formatMessage({ id: currencyKey });
    const labelling_deposit_amount = formatMessage({ id: 'damage.safety.deposit.amount' }, { currency: currencySymbol });

    return (
      <div>
        <FormRow customClass="companyForm_row">
          <EkRadio
            formRowItemKey="companyForm_damageDepositBeforeBooking"
            customContainerClass="companyForm_ekRadioContainer"
            customWrapperClass="companyForm_ekRadioWrapper"
            titleKey="charge.damage.safety.deposit"
            items={booleanOptions}
            namespace="damageDepositBeforeBooking"
            field={enableBookingDamageSafetyDeposit}
            disabled={readOnly}
          >
            <FieldErrorMsg field={enableBookingDamageSafetyDeposit} customClass="fieldErrorMsg--companyForm" />
          </EkRadio>
        </FormRow>
        {radioFieldEnabled(enableBookingDamageSafetyDeposit) && (
          <FormRow customClass="companyForm_row">
            <BoxedInput
              formRowItemKey="companyForm_damageDepositAmount"
              type="text"
              id="damageDepositAmount"
              label={labelling_deposit_amount}
              customClass="companyForm_boxedInput"
              field={bookingDamageSafetyDepositAmount}
              disabled={readOnly}
              mandatory
            >
              <FieldErrorMsg field={bookingDamageSafetyDepositAmount} customClass="fieldErrorMsg--companyForm" />
            </BoxedInput>
          </FormRow>
        )}
        {radioFieldEnabled(enableBookingDamageSafetyDeposit) && (
          <FormRow customClass="companyForm_row">
            <BoxedInput
              formRowItemKey="companyForm_damageDepositRelease"
              type="text"
              id="damageDepositRelease"
              labelKey="damage.deposit.auto.release"
              customClass="companyForm_boxedInput"
              field={damageSafetyDepositReleaseDelayInDays}
              toolTipText={formatMessage(toolTipMessages.damageDepositAutoRelease)}
              disabled={readOnly}
              mandatory
            >
              <FieldErrorMsg field={damageSafetyDepositReleaseDelayInDays} customClass="fieldErrorMsg--companyForm" />
            </BoxedInput>
          </FormRow>
        )}
      </div>
    );
  }

  showSafetyDepositFields() {
    const { isDisplayForm, paymentRules: { damageSafetyDeposit, bookingSafetyDeposit } = {} } = this.props;
    if (isDisplayForm && !damageSafetyDeposit && !bookingSafetyDeposit) return;

    const {
      fields: {
        billBeforeBooking,
        enableBookingDamageSafetyDeposit,
        preBookingBillingOffset,
        useExternalInvoiceSystem,
        secondPreBillingOffsetMinutes
      },
      readOnly
    } = this.props;

    return (
      <div>
        {(radioFieldEnabled(billBeforeBooking) || radioFieldEnabled(enableBookingDamageSafetyDeposit)) && (
          <FormRow customClass="companyForm_row">
            <BoxedInput
              formRowItemKey="companyForm_preBookingBillingOffset"
              type="text"
              id="preBookingBillingOffset"
              labelKey="companyForm_preBookingBillingOffset_label"
              customClass="companyForm_boxedInput"
              field={preBookingBillingOffset}
              disabled={readOnly}
              mandatory
            >
              <FieldErrorMsg field={preBookingBillingOffset} customClass="fieldErrorMsg--companyForm" />
            </BoxedInput>
            {fieldValue(useExternalInvoiceSystem) === EXTERNAL_INVOICE.RRS && (
              <BoxedInput
                formRowItemKey="companyForm_secondPreBillingOffsetMinutes"
                type="text"
                id="secondPreBillingOffsetMinutes"
                labelKey="companyForm_secondPreBillingOffsetMinutes_label"
                customClass="companyForm_boxedInput"
                field={secondPreBillingOffsetMinutes}
                disabled={readOnly}
                mandatory
              >
                <FieldErrorMsg field={secondPreBillingOffsetMinutes} customClass="fieldErrorMsg--companyForm" />
              </BoxedInput>
            )}
          </FormRow>
        )}
      </div>
    );
  }

  showBookingDepositFields() {
    const { isDisplayForm, paymentRules } = this.props;
    if (isDisplayForm && !paymentRules.bookingSafetyDeposit) return;

    const {
      fields: {
        billBeforeBooking,
        enableBookingPriceSafetyDepositBuffer,
        bookingPriceSafetyDepositBuffer,
        immediatePaymentForBookingExtension,
        rrsSafetyDepositAmount,
        useExternalInvoiceSystem,
        currency
      },
      intl: { formatMessage },
      readOnly
    } = this.props;

    const currencyType = fieldValue(currency);
    let currencyKey = 'unit_EUR';

    if (currencyType) {
      currencyKey = 'unit_' + currencyType;
    }

    const currencySymbol = formatMessage({ id: currencyKey });
    const labelling_buffer_amount = formatMessage({ id: 'booking.safety.buffer.amount' }, { currency: currencySymbol });

    return (
      <div>
        <FormRow customClass="companyForm_row">
          <EkRadio
            formRowItemKey="companyForm_billBeforeBooking"
            customContainerClass="companyForm_ekRadioContainer"
            customWrapperClass="companyForm_ekRadioWrapper"
            titleKey="companyForm_billBeforeBooking_label"
            items={booleanOptions}
            namespace="billBeforeBooking"
            field={billBeforeBooking}
            disabled={readOnly}
          >
            <FieldErrorMsg field={billBeforeBooking} customClass="fieldErrorMsg--companyForm" />
          </EkRadio>
        </FormRow>
        {radioFieldEnabled(billBeforeBooking) && (
          <FormRow customClass="companyForm_row">
            <EkRadio
              formRowItemKey="companyForm_depositSafetyBuffer"
              customContainerClass="companyForm_ekRadioContainer"
              customWrapperClass="companyForm_ekRadioWrapper"
              titleKey="enable.booking.safety.buffer"
              items={booleanOptions}
              namespace="depositSafetyBuffer"
              field={enableBookingPriceSafetyDepositBuffer}
              disabled={readOnly}
            >
              <FieldErrorMsg field={enableBookingPriceSafetyDepositBuffer} customClass="fieldErrorMsg--companyForm" />
            </EkRadio>
            <EkRadio
              formRowItemKey="companyForm_immediatePaymentBookingExtension"
              customContainerClass="companyForm_ekRadioContainer"
              customWrapperClass="companyForm_ekRadioWrapper"
              titleKey="enable.booking.extension.immediate.payment"
              items={booleanOptions}
              namespace="immediatePaymentBookingExtension"
              field={immediatePaymentForBookingExtension}
              disabled={readOnly}
            >
              <FieldErrorMsg field={immediatePaymentForBookingExtension} customClass="fieldErrorMsg--companyForm" />
            </EkRadio>
          </FormRow>
        )}
        {radioFieldEnabled(enableBookingPriceSafetyDepositBuffer) && radioFieldEnabled(billBeforeBooking) && (
          <FormRow customClass="companyForm_row">
            <BoxedInput
              formRowItemKey="companyForm_depositBufferAmount"
              type="text"
              id="depositBufferAmount"
              label={labelling_buffer_amount}
              customClass="companyForm_boxedInput"
              field={bookingPriceSafetyDepositBuffer}
              disabled={readOnly}
              mandatory
            >
              <FieldErrorMsg field={bookingPriceSafetyDepositBuffer} customClass="fieldErrorMsg--companyForm" />
            </BoxedInput>
            {fieldValue(useExternalInvoiceSystem) === EXTERNAL_INVOICE.RRS && (
              <BoxedInput
                formRowItemKey="companyForm_rrsSafetyDepositAmount"
                type="text"
                id="rrsSafetyDepositAmount"
                labelKey="companyForm_rrsSafetyDepositAmount_label"
                customClass="companyForm_boxedInput"
                field={rrsSafetyDepositAmount}
                disabled={readOnly}
                mandatory
              >
                <FieldErrorMsg field={rrsSafetyDepositAmount} customClass="fieldErrorMsg--companyForm" />
              </BoxedInput>
            )}
          </FormRow>
        )}
      </div>
    );
  }

  displayBillingConfigBlock() {
    const {
      fields: { currency, pricesIncludingTaxes, paymentProvider },
      readOnly,
      currencies,
      isDisplayForm
    } = this.props;

    const currencyOptions = _map(currencies, item => {
      return { labelKey: 'currency_' + item, value: item };
    });

    return (
      <section className="companyFormWrapper">
        <div className="companyForm_container">
          <h4 className="siteForm_title">
            <FormattedMessage id="title.private.billing.config" />
          </h4>
          {!isDisplayForm && (
            <FormRow customClass="companyForm_row">
              <BoxedSelect
                labelKey="invoice_params_label_currency"
                field={currency}
                id="invoice-params-currency"
                options={currencyOptions}
                noEmptyValue
              >
                <FieldErrorMsg customClass="fieldErrorMsg--companyForm" field={currency} />
              </BoxedSelect>
              <BoxedSelect
                labelKey="CompanyForm_payment_provider_label"
                field={paymentProvider}
                id="paymentProvider"
                options={paymentProviders}
                noEmptyValue
              >
                <FieldErrorMsg customClass="fieldErrorMsg--companyForm" field={paymentProvider} />
              </BoxedSelect>
            </FormRow>
          )}
          {this.showBookingDepositFields()}
          {this.showDamageDepositFields()}
          {this.showSafetyDepositFields()}
          <FormRow customClass="companyForm_row">
            <EkRadio
              formRowItemKey="companyForm_pricesIncludingTaxes"
              customContainerClass="companyForm_ekRadioContainer"
              customWrapperClass="companyForm_ekRadioWrapper"
              titleKey="define.prices.with.vat"
              items={booleanOptions}
              namespace="pricesIncludingTaxes"
              field={pricesIncludingTaxes}
              disabled={readOnly}
            >
              <FieldErrorMsg field={pricesIncludingTaxes} customClass="fieldErrorMsg--companyForm" />
            </EkRadio>
          </FormRow>
        </div>
      </section>
    );
  }

  formUnchanged() {
    const initialValueTranslations = getFieldValue(this.props, this.props.values, 'additionalLegalDocTranslationsLength');

    if (toBoolean(this.props.values.displayAdditionalLegalDoc)) {
      return this.props.pristine && initialValueTranslations === this.props.fields.additionalLegalDocTranslations.length;
    }
    return this.props.pristine;
  }

  displayActionButtons() {
    const { handleSubmit, isDisplayForm, onCancel } = this.props;

    return (
      <div className="companyForm_actions">
        {!isDisplayForm && onCancel && (
          <span onClick={onCancel} className="ekButton ekButton--reverse companyForm_actionsLink">
            <FormattedMessage id="companyForm_cancel_button" />
          </span>
        )}
        <EkButton customClass="companyForm_actionsButton" onAction={handleSubmit(this.props.onCallback)} disabled={this.formUnchanged()}>
          <FormattedMessage id="companyForm_save_button" />
        </EkButton>
      </div>
    );
  }

  displayOtherBlock() {
    const {
      fields: { inversFleetId }
    } = this.props;

    return (
      <section className="companyFormWrapper">
        <div className="companyForm_container">
          <h4 className="siteForm_title">
            <FormattedMessage id="common_other" />
          </h4>

          <FormRow customClass="companyForm_row">
            <BoxedSelect
              id="fleet"
              labelKey="companyForm_inversFleet"
              noEmptyValue
              field={inversFleetId}
              options={this.inversFleetsOptions}
            />
          </FormRow>
        </div>
      </section>
    );
  }

  render() {
    const { handleSubmit, readOnly } = this.props;
    const onSubmit = readOnly ? undefined : handleSubmit(this.props.onCallback);

    return (
      <form action="#" className="companyForm" onSubmit={onSubmit}>
        {this.displayMainInfoBlock()}
        {this.displayMemberSubscribeBlock()}
        {this.displayLegalDocsBlock()}
        {this.displayBookingConfigBlock()}
        {this.displayBillingConfigBlock()}
        {this.displayOtherBlock()}
        {this.displayDevBlock()}
        {!readOnly && this.displayActionButtons()}
      </form>
    );
  }
}

CompanyForm.displayName = 'CompanyForm';

CompanyForm.propTypes = {
  readOnlyBrands: PropTypes.bool,
  onCancel: PropTypes.func,
  isDisplayForm: PropTypes.bool,
  readOnly: PropTypes.bool,
  fields: PropTypes.object,
  handleSubmit: PropTypes.func,
  onCallback: PropTypes.func,
  companyId: PropTypes.string,
  onConfigurationSelect: PropTypes.func
};

const sendDrivingAuthorisationDisabled = {
  condition: (props, value, values) => !fieldEnabled(_get(values, 'sendDrivingAuthorisation'))
};

const displayAdditionalLegalDocDisabled = {
  condition: (props, value, values) => !fieldEnabled(_get(values, 'displayAdditionalLegalDoc'))
};

const preBookingBillingOffsetDisabled = {
  condition: (props, value, values) => {
    return !radioValueTrue(values.billBeforeBooking) && !radioValueTrue(values.enableBookingDamageSafetyDeposit);
  }
};

const secondPreBillingOffsetMinutesDisabled = {
  condition: (props, value, values) => {
    return (
      (!radioValueTrue(values.billBeforeBooking) && !radioValueTrue(values.enableBookingDamageSafetyDeposit)) ||
      values.useExternalInvoiceSystem !== EXTERNAL_INVOICE.RRS
    );
  }
};

const bookingPriceSafetyDepositBufferDisabled = {
  condition: (props, value, values) => {
    return !radioValueTrue(values.billBeforeBooking) || !radioValueTrue(values.enableBookingPriceSafetyDepositBuffer);
  }
};

const bookingDekraServiceDisabled = {
  condition: (props, value, values) => {
    return !radioValueTrue(values.enableDekraService);
  }
};

const carWiseDisabled = {
  condition: props => {
    return !props.vehicleRules.carwise || !props.isDisplayForm;
  }
};

const rrsSafetyDepositAmountDisabled = {
  condition: (props, value, values) => {
    return (
      !radioValueTrue(values.billBeforeBooking) ||
      !radioValueTrue(values.enableBookingPriceSafetyDepositBuffer) ||
      values.useExternalInvoiceSystem !== EXTERNAL_INVOICE.RRS
    );
  }
};

const bookingDamageSafetyDepositDisabled = {
  condition: (props, value, values) => {
    return !radioValueTrue(values.enableBookingDamageSafetyDeposit);
  }
};

const ssoEnabledDisabled = {
  condition: (props, value, values) => {
    return !radioValueTrue(values.ssoEnabled);
  }
};

const brandsExist = props => {
  return safe(() => props.appBrands.length > 0);
};

const externalInvoiceNotDms = {
  condition: (props, value, values) => {
    return values.useExternalInvoiceSystem !== EXTERNAL_INVOICE.DMS;
  }
};
const externalInvoiceNotRrs = {
  condition: (props, value, values) => {
    return values.useExternalInvoiceSystem !== EXTERNAL_INVOICE.RRS;
  }
};

CompanyForm = reduxForm({
  onSubmitFail: scrollToFirstError,
  form: COMPANY_FORM,
  fields: [
    'access',
    'additionalLegalDocFileId',
    'additionalLegalDocName',
    'additionalLegalDocTranslations[]',
    'additionalLegalDocTranslations[].language',
    'additionalLegalDocTranslations[].translation',
    'additionalLegalDocTranslationsLength',
    'address',
    'appBrandId',
    'backofficeResetPasswordUrl',
    'billBeforeBooking',
    'bookingDamageSafetyDepositAmount',
    'bookingPriceSafetyDepositBuffer',
    'carWiseOrganizationId',
    'city',
    'clientProfileType',
    'companyLogo',
    'configurationId',
    'country',
    'currency',
    'damageReportPhotos',
    'damageSafetyDepositReleaseDelayInDays',
    'dekraCheckService',
    'dekraExpertiseService',
    'deleteMemberDocumentsOnApproval',
    'displayAdditionalLegalDoc',
    'drivingAuth_city',
    'drivingAuth_postalCode',
    'drivingAuthAddress',
    'drivingAuthCompanyName',
    'drivingAuthPhone',
    'drivingAuthPhonePrefix',
    'drivingLicenceRequired',
    'durationAfterTripToAllowLockUnlock',
    'email',
    'employerCertificateRequired',
    'employerCertificateUrl',
    'enableBookingDamageSafetyDeposit',
    'enableBookingPriceSafetyDepositBuffer',
    'enableDekraService',
    'enabledReportAdditionalPhoto',
    'endBookingDamageReportMandatory',
    'expediteReview',
    'externalId',
    'fiscalCode',
    'genericPdfDocumentUrl',
    'identityDocumentRequired',
    'immediatePaymentForBookingExtension',
    'inversFleetId',
    'inviteCode',
    'italianMemberProfileCustomFieldId',
    'logoUrl',
    'memberExternalIdForImportCustomFieldId',
    'name',
    'paymentProvider',
    'phoneNumber',
    'postalCode',
    'preBookingBillingOffset',
    'pricesIncludingTaxes',
    'privacyPolicy',
    'rrsSafetyDepositAmount',
    'secondaryEmail',
    'secondaryPhoneNumber',
    'secondPreBillingOffsetMinutes',
    'sendDrivingAuthorisation',
    'sendEmailsToManager',
    'signatureImageUrl',
    ...SSO_FORMM_FIELDS,
    'ssoEnabled',
    'startBookingDamageReportMandatory',
    'subscriptionUrl',
    'taxiClientCostCustomFieldId',
    'taxiClientIpnCustomFieldId',
    'taxiMissionOrderCustomFieldId',
    'templateGroup',
    'termsOfSubscription',
    'termsOfUse',
    'useExternalInvoiceSystem',
    'vehicleOwner',
    'vehicleReassignmentDelayInMin',
    ...VEHICLE_REPORT_ADDITIONAL_FIELDS,
    'websiteConfirmSubscriptionUrl',
    'websiteResetPasswordUrl'
  ],
  validate: mergeValidators([
    createValidator({
      appBrandId: [stopValidationIf(condition(not(brandsExist))), notEmpty(), notValueUrlAll(), notValueDash()],
      externalId: [stopValidationIf(externalInvoiceNotDms), notEmpty()],
      name: [notEmpty()],
      address: [address(), length({ max: 80 })],
      email: [notEmpty(), length({ max: 75 }), email()],
      secondaryEmail: [length({ max: 75 }), email()],
      phoneNumber: [checkPhoneNumber()],
      secondaryPhoneNumber: [checkPhoneNumber(false)],
      termsOfUse: [notEmpty()],
      genericPdfDocumentUrl: [stopValidationIf(sendDrivingAuthorisationDisabled), genericPdf(), notEmpty()],
      logoUrl: [stopValidationIf(sendDrivingAuthorisationDisabled), genericImage(), notEmpty()],
      signatureImageUrl: [stopValidationIf(sendDrivingAuthorisationDisabled), genericImage(), notEmpty()],
      vehicleOwner: [stopValidationIf(sendDrivingAuthorisationDisabled), notEmpty()],
      drivingAuthCompanyName: [stopValidationIf(sendDrivingAuthorisationDisabled), notEmpty()],
      drivingAuthAddress: [stopValidationIf(sendDrivingAuthorisationDisabled), address(), length({ max: 80 })],
      drivingAuth_postalCode: [stopValidationIf(sendDrivingAuthorisationDisabled), notEmpty()],
      drivingAuth_city: [stopValidationIf(sendDrivingAuthorisationDisabled), notEmpty()],
      drivingAuthPhone: [stopValidationIf(sendDrivingAuthorisationDisabled), checkPhoneNumber()],
      durationAfterTripToAllowLockUnlock: [notEmpty(), integer()],
      websiteConfirmSubscriptionUrl: [notEmpty()],
      websiteResetPasswordUrl: [notEmpty()],
      backofficeResetPasswordUrl: [notEmpty()],
      configurationId: [notEmpty(), notValueDash()],
      employerCertificateUrl: [url()],
      preBookingBillingOffset: [stopValidationIf(preBookingBillingOffsetDisabled), notEmpty(), integer(), minimum(1)],
      secondPreBillingOffsetMinutes: [stopValidationIf(secondPreBillingOffsetMinutesDisabled), notEmpty(), integer(), minimum(1)],
      enableDekraService: [stopValidationIf(bookingDekraServiceDisabled), notEmpty()],
      dekraCheckService: [stopValidationIf(bookingDekraServiceDisabled), notEmpty()],
      dekraExpertiseService: [stopValidationIf(bookingDekraServiceDisabled), notEmpty()],
      bookingPriceSafetyDepositBuffer: [stopValidationIf(bookingPriceSafetyDepositBufferDisabled), notEmpty(), integer(), minimum(1)],
      bookingDamageSafetyDepositAmount: [stopValidationIf(bookingDamageSafetyDepositDisabled), notEmpty(), integer(), minimum(1)],
      damageSafetyDepositReleaseDelayInDays: [stopValidationIf(bookingDamageSafetyDepositDisabled), notEmpty(), integer(), minimum(1)],
      rrsSafetyDepositAmount: [stopValidationIf(rrsSafetyDepositAmountDisabled), notEmpty(), integer(), minimum(1)],
      vehicleReassignmentDelayInMin: [integer(), positiveNumber()],
      identityProvider: [stopValidationIf(ssoEnabledDisabled), notEmpty()],
      ssoDomains: [stopValidationIf(ssoEnabledDisabled), notEmpty(), checkDomains()],
      allowRegistrationWithoutSso: [stopValidationIf(ssoEnabledDisabled), notEmpty()],
      emailAutomaticallyVerified: [stopValidationIf(ssoEnabledDisabled), notEmpty()],
      profileAutomaticallyApproved: [stopValidationIf(ssoEnabledDisabled), notEmpty()],
      clientProfileType: [stopValidationIf(externalInvoiceNotRrs), notEmpty()],
      carWiseOrganizationId: [stopValidationIf(carWiseDisabled), notEmpty()],
      additionalLegalDocName: [stopValidationIf(displayAdditionalLegalDocDisabled), notEmpty()],
      additionalLegalDocFileId: [stopValidationIf(displayAdditionalLegalDocDisabled), notEmpty()]
    }),
    validate
  ])
})(CompanyForm);

export default connect(state => {
  const {
    companies: { urlParams, currencies, inversFleets },
    appBrands: { list: appBrands },
    configuration: { allConfigurations }
  } = state;

  return {
    userRole: userRoleSelector(state),
    urlParams,
    allConfigurations,
    inversFleets,
    appBrands,
    currencies,
    bookingRules: headerContractBookingValuesSelector(state),
    vehicleRules: headerContractVehicleValuesSelector(state),
    memberRules: headerContractMemberValuesSelector(state),
    paymentRules: headerContractPaymentValuesSelector(state),
    subscriptionCustomFields: customFieldsSubscriptionSelector(state),
    bookingCustomFields: carSharingCustomFieldsSelector(state)
  };
})(injectIntl(CompanyForm));
