import React, { Component } from 'react';
import { updateVersionRequest } from '../../../../../actions/versions-actions';
import { FormattedMessage } from 'react-intl';
import { getEditTitle } from '../../../../../components/_v2/ColumnList/helpers';
import { showParentView } from '../../../../../components/_v2/ColumnList/helpers';
import { connect } from 'react-redux';
import VehicleView from '../index';
import { safe } from '../../../../../utils/utils';
import { editVehicleBrandsRules } from '../../../../../constants/routes-constants';
import { checkRole } from '../../../../../constants/backuser-role-rules';
import { userRoleSelector } from '../../../../../selectors/all-selectors';

export class VehicleEditVersion extends Component {
  componentWillMount() {
    this.setVars();
    this.setCallbacks();
  }

  setVars() {
    this.limitedAccess = !checkRole(editVehicleBrandsRules, this.props.role);
  }

  setCallbacks() {
    this.showParentView = showParentView.bind(this);

    this.handleCallback = data => {
      const params = { modelId: safe(() => this.props.data.model.id), ...data };
      this.props.dispatch(updateVersionRequest(params));
    };
  }

  render() {
    return (
      <VehicleView
        inputRef={this.props.inputRef}
        title={getEditTitle(<FormattedMessage id="versionForm_title" />)}
        fieldLabel={<FormattedMessage id="versionForm_name_label" />}
        onClose={this.showParentView}
        onCallback={this.handleCallback}
        initialValues={this.props.data}
        limitedAccess={this.limitedAccess}
      />
    );
  }
}

const mapStateToProps = state => {
  const { currentVersion } = state.versions;

  return {
    data: currentVersion,
    role: userRoleSelector(state)
  };
};

VehicleEditVersion = connect(mapStateToProps)(VehicleEditVersion);

export default VehicleEditVersion;
