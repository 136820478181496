import React, { Component, PropTypes } from 'react';
import { FormattedMessage } from 'react-intl';
import _ from 'lodash';

class PaginationControls extends Component {
  constructor() {
    super();
    this.handleGotoPrev = this.handleGotoPrev.bind(this);
    this.handleGotoNext = this.handleGotoNext.bind(this);
    this.handleGotoFirst = this.handleGotoFirst.bind(this);
    this.handleGotoLast = this.handleGotoLast.bind(this);
  }

  handleGotoPrev(e) {
    e.preventDefault();

    this.props.onPageChange(this.props.paginationInfo.currentPage - 1);
    if (this.props.advancedList) {
      this.props.advancedList.scrollTo(0, 0);
    }
  }

  handleGotoNext(e) {
    e.preventDefault();

    this.props.onPageChange(this.props.paginationInfo.currentPage + 1);
    if (this.props.advancedList) {
      this.props.advancedList.scrollTo(0, 0);
    }
  }

  handleGotoFirst(e) {
    e.preventDefault();

    this.props.onPageChange(1);
    if (this.props.advancedList) {
      this.props.advancedList.scrollTo(0, 0);
    }
  }

  handleGotoLast(e) {
    e.preventDefault();

    this.props.onPageChange(this.props.paginationInfo.totalPages);
    if (this.props.advancedList) {
      this.props.advancedList.scrollTo(0, 0);
    }
  }

  render() {
    const { paginationInfo } = this.props;

    let firstItemNumber = (paginationInfo.currentPage - 1) * paginationInfo.pageSize + 1;
    let lastItemNumber = firstItemNumber + _.get(paginationInfo, 'currentSize', paginationInfo.pageSize) - 1;

    return (
      <div className={`paginationControls ${this.props.customClass || ''}`}>
        {firstItemNumber}-{lastItemNumber} <FormattedMessage id="paginationControls_outOf" /> {paginationInfo.totalResults}
        {paginationInfo.currentPage === 1 ? (
          ''
        ) : (
          <button className="paginationControls_button paginationControls_button--first" onClick={this.handleGotoFirst}>
            <FormattedMessage id="paginationControls_firstPage" />
          </button>
        )}
        {paginationInfo.currentPage === 1 ? (
          ''
        ) : (
          <button className="paginationControls_button paginationControls_button--prev" onClick={this.handleGotoPrev}>
            <FormattedMessage id="paginationControls_previousPage" />
          </button>
        )}
        {paginationInfo.currentPage === paginationInfo.totalPages || !paginationInfo.totalResults ? (
          ''
        ) : (
          <button className="paginationControls_button paginationControls_button--next" onClick={this.handleGotoNext}>
            <FormattedMessage id="paginationControls_nextPage" />
          </button>
        )}
        {paginationInfo.currentPage === paginationInfo.totalPages || !paginationInfo.totalResults ? (
          ''
        ) : (
          <button className="paginationControls_button paginationControls_button--last" onClick={this.handleGotoLast}>
            <FormattedMessage id="paginationControls_lastPage" />
          </button>
        )}
      </div>
    );
  }
}

PaginationControls.displayName = 'PaginationControls';

PaginationControls.propTypes = {
  onPageChange: PropTypes.func,
  paginationInfo: PropTypes.object,
  customClass: PropTypes.string,
  advancedList: PropTypes.object
};

export default PaginationControls;
