import merge from 'merge-deep';

import {
  BACKUSER_ROLE_ADMIN,
  BACKUSER_ROLE_ADMIN_DEALER,
  BACKUSER_ROLE_CALL_CENTER_OPERATOR,
  BACKUSER_ROLE_CRM,
  BACKUSER_ROLE_EXTERNAL_FINE_COMPANY,
  BACKUSER_ROLE_FIELD_AGENT,
  BACKUSER_ROLE_FLEET_MANAGER,
  BACKUSER_ROLE_ROOT,
  BACKUSER_ROLE_SERVICE_ADVISOR,
  BACKUSER_ROLE_SUPER_ADMIN,
  BACKUSER_ROLE_ZONE_MANAGER
} from './backend-constants';

import { renaultItalyRoles } from './backuser-role-rules';

// Routing rules -start-

export const highTierRoles = Object.freeze([BACKUSER_ROLE_ROOT, BACKUSER_ROLE_SUPER_ADMIN]);

export const blockedRoles = {
  exclude: [BACKUSER_ROLE_FIELD_AGENT]
};

const settingsRules = { exclude: renaultItalyRoles };

export const editColorsRules = {
  include: highTierRoles
};

export const editVehicleBrandsRules = {
  include: highTierRoles
};

export const editCategoriesRules = {
  include: highTierRoles
};

export const companyRules = {
  exclude: [BACKUSER_ROLE_FLEET_MANAGER, BACKUSER_ROLE_SERVICE_ADVISOR, BACKUSER_ROLE_CRM]
};

export const editCompanyRules = {
  exclude: [BACKUSER_ROLE_ADMIN_DEALER, BACKUSER_ROLE_ZONE_MANAGER, BACKUSER_ROLE_EXTERNAL_FINE_COMPANY]
};

export const subCompanyRules = {
  exclude: [BACKUSER_ROLE_SERVICE_ADVISOR]
};

export const editSubCompanyRules = {
  exclude: [BACKUSER_ROLE_CRM, BACKUSER_ROLE_EXTERNAL_FINE_COMPANY, BACKUSER_ROLE_FLEET_MANAGER]
};

export const configurationsEditRules = { include: highTierRoles };
export const configurationsRules = { ...editSubCompanyRules };

export const supportRules = { ...configurationsRules };

export const hotlineRules = {
  include: highTierRoles
};

export const contractRules = { exclude: [BACKUSER_ROLE_SERVICE_ADVISOR, BACKUSER_ROLE_CRM] };
export const contractEditRules = { include: highTierRoles };

export const siteRules = { exclude: [BACKUSER_ROLE_SERVICE_ADVISOR] };
export const siteEditRules = { exclude: [BACKUSER_ROLE_CRM, BACKUSER_ROLE_EXTERNAL_FINE_COMPANY] };

const memberTypeRules = { exclude: [BACKUSER_ROLE_SERVICE_ADVISOR] };
export const memberTypeEditRules = { exclude: [BACKUSER_ROLE_CRM, BACKUSER_ROLE_ADMIN_DEALER, BACKUSER_ROLE_EXTERNAL_FINE_COMPANY] };

const pricingRules = { exclude: [BACKUSER_ROLE_SERVICE_ADVISOR] };
export const pricingEditRules = { exclude: [BACKUSER_ROLE_CRM, BACKUSER_ROLE_ADMIN_DEALER, BACKUSER_ROLE_EXTERNAL_FINE_COMPANY] };

export const fleetEditRules = { exclude: [BACKUSER_ROLE_SERVICE_ADVISOR, BACKUSER_ROLE_CRM, BACKUSER_ROLE_EXTERNAL_FINE_COMPANY] };

export const feedbackEditRules = { exclude: [BACKUSER_ROLE_EXTERNAL_FINE_COMPANY] };

export const vehicleDamagesEditRules = { exclude: [BACKUSER_ROLE_EXTERNAL_FINE_COMPANY] };

export const memberDetailEditRules = { exclude: [BACKUSER_ROLE_EXTERNAL_FINE_COMPANY] };

export const backuserRules = { exclude: [BACKUSER_ROLE_FLEET_MANAGER, BACKUSER_ROLE_EXTERNAL_FINE_COMPANY] };
export const backuserEditRules = { exclude: [BACKUSER_ROLE_SERVICE_ADVISOR, BACKUSER_ROLE_CRM] };

export const smartcardRules = { exclude: [BACKUSER_ROLE_SERVICE_ADVISOR, BACKUSER_ROLE_CRM, BACKUSER_ROLE_ADMIN_DEALER] };
export const smartcardEditRules = { exclude: [BACKUSER_ROLE_EXTERNAL_FINE_COMPANY] };

export const smartcardEventRules = smartcardRules;
export const smartcardEventEditRules = smartcardEditRules;

export const bookingEditRules = { exclude: [BACKUSER_ROLE_FLEET_MANAGER, BACKUSER_ROLE_EXTERNAL_FINE_COMPANY] };

export const vehiclePlanningEditRules = { exclude: [BACKUSER_ROLE_FLEET_MANAGER, BACKUSER_ROLE_EXTERNAL_FINE_COMPANY] };

export const invoiceRules = { exclude: [BACKUSER_ROLE_CALL_CENTER_OPERATOR, BACKUSER_ROLE_FLEET_MANAGER] };
export const invoiceEditRules = { exclude: [BACKUSER_ROLE_EXTERNAL_FINE_COMPANY] };

export const voucherRules = { include: [BACKUSER_ROLE_SUPER_ADMIN, BACKUSER_ROLE_ROOT, BACKUSER_ROLE_ADMIN] };

export const bankoutRules = {
  include: [BACKUSER_ROLE_ROOT, BACKUSER_ROLE_SUPER_ADMIN, BACKUSER_ROLE_ADMIN, BACKUSER_ROLE_ZONE_MANAGER, BACKUSER_ROLE_ADMIN_DEALER]
};

// Routing rules -end-

// Add 'prev' prop for multi-child routes
// Add 'group' prop for multi-child routes
// 'rules' will work only when 'onEnterRoute' called

export default {
  version: {
    path: '/version',
    needsAuthentication: false
  },
  redirect: {
    path: '/redirect/:path',
    needsAuthentication: false
  },
  login: {
    path: '/login',
    needsAuthentication: false
  },
  resetPassword: {
    path: '/reset-password/:resetPasswordToken',
    needsAuthentication: false
  },
  vehiclePlanning: {
    path: '/vehicle-planning',
    needsAuthentication: true
  },
  vehiclePlanningV2: {
    path: '/v2/vehicle-planning',
    needsAuthentication: true
  },
  fleet: {
    path: '/fleet/:search',
    needsAuthentication: true
  },
  addVehicle: {
    path: '/add-vehicle',
    needsAuthentication: true
  },
  account: {
    path: '/account',
    needsAuthentication: true
  },
  pricing: {
    path: '/pricing',
    needsAuthentication: true,
    rules: pricingRules
  },
  backUsers: {
    path: '/back-users/:search',
    needsAuthentication: true,
    rules: backuserRules
  },
  addBackUser: {
    path: '/add-back-user',
    needsAuthentication: true,
    rules: merge(backuserRules, backuserEditRules)
  },
  editBackUser: {
    path: '/edit-back-user/:backUserId',
    needsAuthentication: true,
    rules: merge(backuserRules, backuserEditRules)
  },
  members: {
    path: '/members/:search',
    needsAuthentication: true
  },
  vehicleStatuses: {
    path: '/vehicleStatuses/:search',
    needsAuthentication: true
  },
  memberTypes: {
    path: '/member-types',
    needsAuthentication: true,
    rules: memberTypeRules
  },
  smartcards: {
    path: '/smartcards/:search',
    needsAuthentication: true,
    rules: smartcardRules
  },
  addMember: {
    path: '/pre-subscription',
    needsAuthentication: true
  },
  smartcardsDetail: {
    path: '/smartcardsDetail/:id',
    needsAuthentication: true,
    rules: smartcardRules
  },
  uploadMember: {
    path: '/uploadMember',
    needsAuthentication: true
  },
  smartcardsEvents: {
    path: '/smartcardsEvents/:search',
    needsAuthentication: true,
    rules: smartcardEventRules
  },
  bookings: {
    path: '/bookings/:search',
    needsAuthentication: true
  },
  bookingsV2: {
    needsAuthentication: true,
    path: '/v2/bookings',
    group: 'bookings'
  },
  bookingsList: {
    path: ':bookingsFilters/list',
    group: 'bookings',
    prev: 'bookingsV2'
  },
  bookingDetails: {
    path: ':bookingId/view',
    group: 'bookings',
    prev: 'bookingsList'
  },
  scheduledExport: {
    needsAuthentication: true,
    path: '/scheduled-export',
    group: 'scheduled-export',
    rules: { include: highTierRoles }
  },
  scheduledExportList: {
    path: ':exportsFilters/list',
    group: 'scheduled-export',
    prev: 'scheduledExport'
  },
  scheduledExportAddConfig: {
    path: 'add',
    group: 'scheduled-export',
    prev: 'scheduledExportList'
  },
  scheduledExportViewConfig: {
    path: ':exportId/view',
    group: 'scheduled-export',
    prev: 'scheduledExportList'
  },
  invoicesV2: {
    needsAuthentication: true,
    path: '/v2/invoices',
    group: 'invoices'
  },
  invoicesList: {
    path: ':invoicesFilters/list',
    group: 'invoices',
    prev: 'invoicesV2'
  },
  invoiceDetails: {
    path: ':invoiceRef/view',
    group: 'invoices',
    prev: 'invoicesList'
  },
  editBooking: {
    path: '/edit-booking/:bookingId',
    needsAuthentication: true,
    rules: bookingEditRules
  },
  addBooking: {
    path: '/add-booking',
    needsAuthentication: true,
    rules: bookingEditRules
  },
  bookingFindMember: {
    path: 'find-member/:search',
    needsAuthentication: true,
    rules: bookingEditRules
  },
  bookingFindVehicle: {
    path: 'find-vehicle(/:search)',
    needsAuthentication: true
  },
  bookingEventsHistory: {
    path: '/booking-events-history/:bookingId',
    needsAuthentication: true
  },
  companies: {
    path: '/companies/:search',
    needsAuthentication: true,
    rules: companyRules
  },
  companyDetail: {
    path: '/company-detail/:companyId',
    needsAuthentication: true,
    rules: companyRules
  },
  addCompany: {
    path: '/add-company',
    needsAuthentication: true,
    rules: merge(companyRules, editCompanyRules)
  },
  editCompany: {
    path: '/edit-company/:companyId',
    needsAuthentication: true,
    rules: merge(companyRules, editCompanyRules)
  },
  subCompanies: {
    path: '/sub-companies',
    needsAuthentication: true,
    rules: subCompanyRules
  },
  subCompanyDetails: {
    path: '/sub-company-details/:subCompanyId',
    needsAuthentication: true,
    rules: subCompanyRules
  },
  addSubCompany: {
    path: '/add-sub-company',
    needsAuthentication: true,
    rules: merge(subCompanyRules, editSubCompanyRules)
  },
  dashboard: {
    path: '/dashboard',
    needsAuthentication: true
  },
  dashboardV2: {
    path: '/v2/dashboard',
    needsAuthentication: true
  },
  quickSight: {
    path: '/quicksight',
    group: 'quickSight',
    needsAuthentication: true
  },
  qsCompletedBooking: {
    path: 'completed-booking',
    prev: 'quickSight',
    group: 'quickSight'
  },
  qsAverageDuration: {
    path: 'average-duration',
    prev: 'quickSight',
    group: 'quickSight'
  },
  qsAverageDistance: {
    path: 'average-distance',
    prev: 'quickSight',
    group: 'quickSight'
  },
  qsCanceledBooking: {
    path: 'canceled-booking',
    prev: 'quickSight',
    group: 'quickSight'
  },
  qsLateBooking: {
    path: 'late-booking',
    prev: 'quickSight',
    group: 'quickSight'
  },
  qsLateBookingOvertime: {
    path: 'late-booking-overtime',
    prev: 'quickSight',
    group: 'quickSight'
  },
  qsBookingPerVehicle: {
    path: 'booking-per-vehicle',
    prev: 'quickSight',
    group: 'quickSight'
  },
  qsBusinessVsPrivate: {
    path: 'business-vs-private',
    prev: 'quickSight',
    group: 'quickSight'
  },
  qsTurnover: {
    path: 'turnover',
    prev: 'quickSight',
    group: 'quickSight'
  },
  qsActiveMembers: {
    path: 'active-members',
    prev: 'quickSight',
    group: 'quickSight'
  },
  qsNewMembers: {
    path: 'new-members',
    prev: 'quickSight',
    group: 'quickSight'
  },
  backUserDetail: {
    path: '/back-user-detail/:backUserId',
    needsAuthentication: true
  },
  memberDetail: {
    path: '/member-detail/:memberId',
    needsAuthentication: true
  },
  memberProfileHistory: {
    path: '/member-profile-history/:memberId',
    needsAuthentication: true
  },
  vehicleDetail: {
    path: '/vehicle-detail/:vehicleId',
    needsAuthentication: true
  },
  sites: {
    path: '/sites',
    needsAuthentication: true,
    rules: siteRules
  },
  parkings: {
    path: '/parkings',
    needsAuthentication: true,
    rules: siteRules
  },
  addSite: {
    path: '/add-site',
    needsAuthentication: true,
    rules: merge(siteRules, siteEditRules)
  },
  siteDetails: {
    path: '/site-details/:siteId',
    needsAuthentication: true,
    rules: siteRules
  },
  addParking: {
    path: '/add-parking/:siteId',
    needsAuthentication: true,
    rules: merge(siteRules, siteEditRules)
  },
  parkingDetails: {
    path: '/parking-details/:parkingId',
    needsAuthentication: true,
    rules: siteRules
  },
  contract: {
    path: '/contract',
    needsAuthentication: true,
    rules: contractRules
  },
  contractV2: {
    path: '/v2/contract',
    needsAuthentication: true,
    rules: contractRules
  },
  editVehicle: {
    path: '/edit-vehicle/:vehicleId',
    needsAuthentication: true
  },
  editMemberFiles: {
    path: '/member-files/:memberId',
    needsAuthentication: true
  },
  vehiclesColors: {
    path: '/vehicles-colors',
    needsAuthentication: true,
    rules: settingsRules
  },
  addVehiclesColor: {
    path: '/add-vehicles-color',
    needsAuthentication: true,
    rules: settingsRules
  },
  editVehiclesColor: {
    path: '/edit-vehicles-color/:colorId',
    needsAuthentication: true,
    rules: settingsRules
  },
  vehiclesCategories: {
    path: '/vehicles-categories',
    needsAuthentication: true,
    rules: settingsRules
  },
  addVehiclesCategory: {
    path: '/add-vehicles-category',
    needsAuthentication: true,
    rules: settingsRules
  },
  editVehiclesCategory: {
    path: '/edit-vehicles-categories/:categoryId',
    needsAuthentication: true,
    rules: settingsRules
  },
  brands: {
    path: '/brands',
    needsAuthentication: true,
    rules: settingsRules
  },
  vehicleCategories: {
    needsAuthentication: true,
    rules: settingsRules,
    group: 'categories',
    path: '/v2/categories'
  },
  categoriesList: {
    group: 'categories',
    prev: 'vehicleCategories',
    path: 'list'
  },
  vehicleAddCategory: {
    group: 'categories',
    prev: 'categoriesList',
    path: 'add'
  },
  vehicleEditCategory: {
    group: 'categories',
    prev: 'categoriesList',
    path: ':categoryId/edit'
  },
  vehicleColors: {
    needsAuthentication: true,
    rules: settingsRules,
    group: 'colors',
    path: '/v2/colors'
  },
  colorsList: {
    group: 'colors',
    prev: 'vehicleColors',
    path: 'list'
  },
  vehicleAddColor: {
    group: 'colors',
    prev: 'colorsList',
    path: 'add'
  },
  vehicleEditColor: {
    group: 'colors',
    prev: 'colorsList',
    path: ':colorId/edit'
  },
  vouchersV2: {
    needsAuthentication: true,
    rules: voucherRules,
    group: 'vouchers',
    path: '/v2/vouchers'
  },
  voucherGroups: {
    group: 'vouchers',
    prev: 'vouchersV2',
    path: ':groupsFilters/groups'
  },
  vouchersAddGroup: {
    group: 'vouchers',
    prev: 'voucherGroups',
    path: 'add'
  },
  vouchersEditGroup: {
    group: 'vouchers',
    prev: 'voucherGroups',
    path: ':voucherGroupId/edit'
  },
  organization: {
    needsAuthentication: true,
    rules: settingsRules,
    group: 'organization',
    path: '/v2/organization'
  },
  organizationBrands: {
    group: 'organization',
    prev: 'organization',
    path: 'brands'
  },
  organizationAddBrand: {
    group: 'organization',
    prev: 'organizationBrands',
    path: 'add'
  },
  organizationEditBrand: {
    group: 'organization',
    prev: 'organizationBrands',
    path: ':brandId/edit'
  },
  organizationSuperCompanies: {
    group: 'organization',
    prev: 'organizationBrands',
    path: ':brandId/super-companies'
  },
  organizationAddSuperCompany: {
    group: 'organization',
    prev: 'organizationSuperCompanies',
    path: 'add'
  },
  organizationEditSuperCompany: {
    group: 'organization',
    prev: 'organizationSuperCompanies',
    path: ':companyId/edit'
  },
  organizationSubCompanies: {
    group: 'organization',
    prev: 'organizationSuperCompanies',
    path: ':companyId/sub-companies'
  },
  organizationAddSubCompany: {
    group: 'organization',
    prev: 'organizationSubCompanies',
    path: 'add'
  },
  organizationEditSubCompany: {
    group: 'organization',
    prev: 'organizationSubCompanies',
    path: ':subCompanyId/edit'
  },
  organizationSites: {
    group: 'organization',
    prev: 'organizationSubCompanies',
    path: ':subCompanyId/sites'
  },
  organizationAddSite: {
    group: 'organization',
    prev: 'organizationSites',
    path: 'add'
  },
  organizationCopySite: {
    group: 'organization',
    prev: 'organizationSites',
    path: 'copy'
  },
  organizationEditSite: {
    group: 'organization',
    prev: 'organizationSites',
    path: ':siteId/edit'
  },
  organizationParkings: {
    group: 'organization',
    prev: 'organizationSites',
    path: ':siteId/parkings'
  },
  organizationAddParking: {
    group: 'organization',
    prev: 'organizationParkings',
    path: 'add'
  },
  organizationEditParking: {
    group: 'organization',
    prev: 'organizationParkings',
    path: ':parkingId/edit'
  },
  vehicleBrands: {
    needsAuthentication: true,
    rules: settingsRules,
    group: 'brands',
    path: '/v2/brands'
  },
  brandsList: {
    group: 'brands',
    prev: 'vehicleBrands',
    path: 'list'
  },
  vehicleAddBrand: {
    group: 'brands',
    prev: 'brandsList',
    path: 'add'
  },
  vehicleEditBrand: {
    group: 'brands',
    prev: 'brandsList',
    path: ':brandId/edit'
  },
  vehicleModels: {
    group: 'brands',
    prev: 'brandsList',
    path: ':brandId/models'
  },
  vehicleAddModel: {
    group: 'brands',
    prev: 'vehicleModels',
    path: 'add'
  },
  vehicleEditModel: {
    group: 'brands',
    prev: 'vehicleModels',
    path: ':modelId/edit'
  },
  vehicleVersions: {
    group: 'brands',
    prev: 'vehicleModels',
    path: ':modelId/versions'
  },
  vehicleAddVersion: {
    group: 'brands',
    prev: 'vehicleVersions',
    path: 'add'
  },
  vehicleEditVersion: {
    group: 'brands',
    prev: 'vehicleVersions',
    path: ':versionId/edit'
  },
  addBrand: {
    path: '/add-brand',
    needsAuthentication: true,
    rules: settingsRules
  },
  editBrand: {
    path: '/edit-brand/:brandId',
    needsAuthentication: true,
    rules: settingsRules
  },
  addModel: {
    path: '/add-model/:brandId',
    needsAuthentication: true,
    rules: settingsRules
  },
  editModel: {
    path: '/edit-model/:modelId',
    needsAuthentication: true,
    rules: settingsRules
  },
  addVersion: {
    path: '/add-version/:modelId',
    needsAuthentication: true,
    rules: settingsRules
  },
  editVersion: {
    path: '/edit-version/:versionId',
    needsAuthentication: true,
    rules: settingsRules
  },
  feedbacks: {
    path: '/feedbacks/:search',
    needsAuthentication: true
  },
  feedbackDetail: {
    path: '/feedback-detail/:feedbackId',
    needsAuthentication: true
  },
  bookingDetail: {
    path: '/booking-detail/:bookingId',
    needsAuthentication: true
  },
  bookingDetailV2: {
    path: '/v2/booking-detail/:bookingId',
    needsAuthentication: true
  },
  support: {
    path: '/v2/support',
    needsAuthentication: true,
    group: 'support'
  },
  hotlinesV2: {
    path: 'hotlines',
    group: 'support',
    prev: 'support',
    rules: hotlineRules
  },
  hotlineAdd: {
    group: 'support',
    path: 'add',
    prev: 'hotlinesV2'
  },
  hotlineEdit: {
    group: 'support',
    path: ':hotlineId/edit',
    prev: 'hotlinesV2'
  },
  configurationsV2: {
    path: 'configurations',
    group: 'support',
    prev: 'support'
  },
  configurationEdit: {
    group: 'support',
    path: ':configurationId/edit',
    prev: 'configurationsV2',
    rules: configurationsRules
  },
  configurationAdd: {
    group: 'support',
    path: ':configurationId/add',
    prev: 'configurationsV2',
    rules: configurationsEditRules
  },
  configurations: {
    path: '/configurations/:search',
    needsAuthentication: true,
    rules: configurationsRules
  },
  addConfiguration: {
    path: '/add-configuration',
    needsAuthentication: true,
    rules: configurationsRules
  },
  editConfiguration: {
    path: '/edit-configuration/:configurationId',
    needsAuthentication: true,
    rules: configurationsRules
  },
  hotlines: {
    path: '/hotlines',
    needsAuthentication: true,
    rules: hotlineRules
  },
  addHotline: {
    path: '/add-hotline',
    needsAuthentication: true,
    rules: hotlineRules
  },
  editHotline: {
    path: '/edit-hotline/:hotlineId',
    needsAuthentication: true,
    rules: hotlineRules
  },
  vehicleDamages: {
    path: '/vehicle-damages/:search',
    needsAuthentication: true
  },
  vehicleDamageDetail: {
    path: '/vehicle-damage-detail/:vehicleDamageId',
    needsAuthentication: true
  },
  invoices: {
    path: '/invoices/:search',
    needsAuthentication: true,
    rules: invoiceRules
  },
  addInvoice: {
    path: '/add-invoice',
    needsAuthentication: true,
    rules: invoiceEditRules
  },
  invoiceFindBooking: {
    path: 'find-booking/:search',
    needsAuthentication: true,
    rules: invoiceEditRules
  },
  createInvoice: {
    path: 'create-invoice',
    needsAuthentication: true,
    rules: invoiceEditRules
  },
  bankouts: {
    path: '/bankouts/:search',
    needsAuthentication: true,
    rules: bankoutRules
  },
  bankoutDetails: {
    path: '/bankout-detail/:bankoutId/paybacks/:search',
    needsAuthentication: true,
    rules: bankoutRules
  },
  vouchers: {
    path: '/vouchers/:search',
    needsAuthentication: true,
    rules: voucherRules
  },
  voucherGroupDetail: {
    path: 'voucherGroupDetail/:voucherGroupId/:search',
    needsAuthentication: true,
    rules: voucherRules
  },
  addVoucherGroup: {
    path: 'addVoucherGroup',
    needsAuthentication: true,
    rules: voucherRules
  }
};
