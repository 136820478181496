import React, { Component } from 'react';
import { connect } from 'react-redux';
import { routeActions } from 'react-router-redux';

import BackUserForm from '../../components/BackUserForm/BackUserForm';
import { updateBackUserRequest, updateBackUserSuccess, addFlashMessage } from '../../actions/all-actions';
import { FLASH_MESSAGE_TYPE_SUCCESS } from '../../constants/generic-constants';
import routes from '../../constants/routes-constants';
import _get from 'lodash/get';
import _values from 'lodash/values';

import autoBind from 'react-autobind';
import { addErrorMessage } from '../../utils/utils';
import { bundleSelector } from '../../selectors/all-selectors';
import { partialErrorCodes } from '../../constants/options-constants';

class EditBackUser extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
  }

  handleUpdateBackUser() {
    const { dispatch, detailBackUser, bundle } = this.props;

    dispatch(updateBackUserRequest(detailBackUser))
      .then(backUser => {
        dispatch(updateBackUserSuccess(backUser));
        dispatch(
          addFlashMessage({
            contentKey: 'backUserForm_update_backUser_success',
            type: FLASH_MESSAGE_TYPE_SUCCESS,
            delayToNextRoute: true
          })
        );
        dispatch(routeActions.push(routes.backUserDetail.path.replace(':backUserId', detailBackUser.id)));
      })
      .catch(error => {
        const partialErrors = _values(partialErrorCodes.backUsers);
        const params = { bundle, error, partialErrors, def: 'backUserForm_create_backUser_error' };
        dispatch(addErrorMessage(params));
      });
  }

  render() {
    const { detailBackUser } = this.props;
    return (
      <div className="editBackUserPage mainContainer_content">
        <div className="pageContainer">
          <div className="editBackUserPage_content">
            <div className="editBackUserPage_head" />
            <section className="editBackUserPage_management">
              <BackUserForm
                onCallback={this.handleUpdateBackUser}
                emailReadOnly
                initialValues={{
                  login: _get(detailBackUser, 'login', ''),
                  phoneNumber: {
                    phonePrefix: _get(detailBackUser, 'phoneNumber.countryCode', ''),
                    phoneSuffix: _get(detailBackUser, 'phoneNumber.nationalNumber', ''),
                    valid: true
                  },
                  firstName: _get(detailBackUser, 'firstName', ''),
                  lastName: _get(detailBackUser, 'lastName', ''),
                  address: _get(detailBackUser, 'address', ''),
                  userRole: _get(detailBackUser, 'role', ''),
                  companyId: _get(detailBackUser, 'companies[0].id', ''),
                  subCompanyId: _get(detailBackUser, 'subCompanies[0].id')
                }}
              />
            </section>
          </div>
        </div>
      </div>
    );
  }
}

EditBackUser.displayName = 'EditBackUser';

EditBackUser.propTypes = {};

export default connect(state => {
  const {
    backUsers: { detailBackUser }
  } = state;

  return {
    bundle: bundleSelector(state),
    detailBackUser
  };
})(EditBackUser);
