import React, { Component } from 'react';
import autoBind from 'react-autobind';
import pure from 'recompose/pure';
import _isArray from 'lodash/isArray';
import _get from 'lodash/get';
import _isObject from 'lodash/isObject';
import _isEmpty from 'lodash/isEmpty';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { fallbackFunc, namedCompose, newProps } from '../../utils/utils';
import { localeSelector } from '../../selectors/all-selectors';
import PropTypes from 'prop-types';
import None from '../None';

class BackUserCompanies extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.derivedStateFromProps(props, true);
  }

  componentWillReceiveProps(nextProps) {
    this.derivedStateFromProps(nextProps);
  }

  derivedStateFromProps(nextProps, init) {
    if (newProps(this.props, nextProps, init, 'companies')) {
      const { companies } = nextProps;
      this.mainElement = <None />;
      if (_isArray(companies)) this.parseCompanies(companies);
      else if (_isObject(companies)) this.parseCompany(companies);
    }
  }

  parseCompanies(companies) {
    this.companies = companies.reduce((result, company) => {
      const { id, name } = company || {};
      if (name) result.push(this.listItem(name, id));
      return result;
    }, []);
    if (!_isEmpty(this.companies)) this.mainElement = this.mainBox();
  }

  parseCompany(company) {
    const companyName = _get(company, 'name');
    if (companyName) {
      this.companies = this.singleItem(companyName);
      this.mainElement = this.mainBox();
    }
  }

  listItem(companyName, companyId) {
    return (
      <li key={companyName} className="detailView_cell_type_5">
        <a href={this.props.routeGetter(companyId)}>
          <span>{companyName}</span>
        </a>
      </li>
    );
  }

  singleItem(companyName) {
    return (
      <li className="detailView_cell_type_5">
        <span>{companyName}</span>
      </li>
    );
  }

  mainBox() {
    return (
      <div className="backUserCompanies">
        <h4>
          <FormattedMessage id={this.props.headerId} />
        </h4>
        <div className="backUserCompanies_body">
          <ul>{this.companies}</ul>
        </div>
      </div>
    );
  }

  render() {
    return this.mainElement;
  }
}

BackUserCompanies.defaultProps = {
  headerId: 'common_unknown', // header translation id
  routeGetter: fallbackFunc
};

BackUserCompanies.propTypes = {
  routeGetter: PropTypes.func,
  companies: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  headerId: PropTypes.string.isRequired
};

export default namedCompose(
  connect(state => {
    return { locale: localeSelector(state) };
  }),
  pure
)(BackUserCompanies);
