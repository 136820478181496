/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/no-set-state */
/* eslint-disable react/no-multi-comp */

import React, { PropTypes, Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

import { formatMonetaryAmount, getAppFormattedDateTime, getFileNameFromUrl } from '../../utils/utils';
import {
  BANKOUT_STATUS_EXECUTED,
  BANKOUT_STATUS_SUCCESS,
  BANKOUT_STATUS_ERROR,
  BACKUSER_ROLE_ROOT,
  BILLING_ENTITY_TYPE_SUPER_COMPANY,
  BILLING_ENTITY_TYPE_SUPER_COMPANY_ALT
} from '../../constants/backend-constants';
import IsGranted from '../IsGranted/IsGranted';
import EkButton from '../EkButton/EkButton';
import routes from '../../constants/routes-constants';
import classNames from 'classnames';
import { FlatButton } from 'material-ui';
import DownloadIcon from 'material-ui/svg-icons/file/file-download';
import reactAutobind from 'react-autobind';
import _includes from 'lodash/includes';

class BankoutDetails extends Component {
  constructor(props) {
    super(props);
    reactAutobind(this);
    this.state = { expand: false };
  }

  showCompanyUrl() {
    const { canSeeSubCompany, canSeeSuperCompany, bankout, formatMessage } = this.props;
    const { billingEntityId, billingEntityType, billingEntityName } = bankout || {};

    const superCompanyTypes = [BILLING_ENTITY_TYPE_SUPER_COMPANY, BILLING_ENTITY_TYPE_SUPER_COMPANY_ALT];
    const isSuperCompany = superCompanyTypes.indexOf(billingEntityType) !== -1;
    const canFollowUrl = isSuperCompany ? canSeeSuperCompany : canSeeSubCompany;
    const companyName = billingEntityName || formatMessage({ id: 'common_unknown' });

    if (billingEntityId && canFollowUrl) {
      const companyRoute = isSuperCompany ? 'companyDetail' : 'subCompanyDetails';
      const replaceKey = isSuperCompany ? ':companyId' : ':subCompanyId';
      const companyPath = routes[companyRoute].path;
      const href = `/#${companyPath.replace(replaceKey, billingEntityId)}`;

      return (
        <a href={href}>
          <span>{companyName}</span>
        </a>
      );
    } else return <span>{companyName}</span>;
  }

  getInvoiceUrlRow() {
    const { formatMessage, bankout } = this.props;
    const { status, commissionInvoiceUrl } = bankout || {};

    if (status === BANKOUT_STATUS_SUCCESS && commissionInvoiceUrl) {
      return (
        <tr>
          <td>
            <FormattedMessage id="data.with.colon" values={{ data: formatMessage({ id: 'commission_invoice' }) }} />
          </td>
          <td>
            <a className="pdf-url" href={commissionInvoiceUrl} title="PDF" target="_blank">
              {getFileNameFromUrl(commissionInvoiceUrl)}
            </a>
          </td>
        </tr>
      );
    }
  }

  getPaymentReferenceRow() {
    const { bankout, formatMessage } = this.props;
    const { paymentProviderReference } = bankout || {};

    return (
      <tr>
        <td>
          <FormattedMessage id="data.with.colon" values={{ data: formatMessage({ id: 'payment_service_reference' }) }} />
        </td>
        <td>{paymentProviderReference}</td>
      </tr>
    );
  }

  getPaymentServiceReponseRow() {
    const { formatMessage, bankout } = this.props;
    const { status, paymentProviderResponse } = bankout || {};

    if (BANKOUT_STATUS_ERROR === status && paymentProviderResponse)
      return (
        <tr>
          <td>
            <FormattedMessage id="data.with.colon" values={{ data: formatMessage({ id: 'payment_service_response' }) }} />
          </td>
          <td onClick={this.toggleErrorDetail} className={this.state.expand ? 'viewable' : 'collapsed'}>
            <span className="json"> {paymentProviderResponse} </span>
            <span className="more-details">
              <FormattedMessage id={this.state.expand ? 'common_view_less' : 'common_view_more'} />
            </span>
          </td>
        </tr>
      );
  }

  getCommissionAmount() {
    const { formatMessage, bankout } = this.props;
    const { commissionsAmount, currency } = bankout || {};

    if (commissionsAmount)
      return (
        <tr>
          <td>
            <FormattedMessage id="data.with.colon" values={{ data: formatMessage({ id: 'bankout_commission_amount' }) }} />
          </td>
          <td>{formatMonetaryAmount(commissionsAmount, currency)}</td>
        </tr>
      );
  }

  getRetryBankoutButton() {
    const { bankout, retryAction } = this.props;
    const { status } = bankout || {};

    if (BANKOUT_STATUS_ERROR === status) {
      return (
        <IsGranted allowed={[BACKUSER_ROLE_ROOT]}>
          {BANKOUT_STATUS_ERROR === status && (
            <EkButton customClass="modelForm_actionsButton" onAction={retryAction}>
              <FormattedMessage id="bankout_retry" />
            </EkButton>
          )}
        </IsGranted>
      );
    }
  }

  toggleErrorDetail() {
    this.state.expand ? this.setState({ expand: false }) : this.setState({ expand: true });
  }

  getBankoutReferences() {
    const { bankout } = this.props;
    const { paymentProviderReference, status } = bankout || {};
    const correctStatus = [BANKOUT_STATUS_EXECUTED, BANKOUT_STATUS_SUCCESS, BANKOUT_STATUS_ERROR].indexOf(status) !== -1;

    if (paymentProviderReference && correctStatus) {
      return (
        <section className="bankoutReferences">
          <h3>
            <FormattedMessage id="bankout_references" />
          </h3>
          <table>
            <tbody>
              {this.getPaymentReferenceRow()}
              {this.getPaymentServiceReponseRow()}
              {this.getInvoiceUrlRow()}
              {this.getCommissionAmount()}
            </tbody>
          </table>
          {this.getRetryBankoutButton()}
        </section>
      );
    }
  }

  getExportButton() {
    const { exportAction, exporting, formatMessage, bankout } = this.props;
    const bool = _includes(exporting, bankout.paymentProviderReference);
    const label = bool ? formatMessage({ id: 'common_button_exporting' }) : formatMessage({ id: 'common_export' });

    return <FlatButton id="bookings_export_button red" label={label} icon={<DownloadIcon />} disabled={bool} onClick={exportAction} />;
  }

  getExecutionDay() {
    const { bankout, formatMessage } = this.props;
    const { executionDate } = bankout || {};

    if (executionDate) {
      return (
        <tr>
          <td>
            <FormattedMessage id="data.with.colon" values={{ data: formatMessage({ id: 'common_execution_date' }) }} />
          </td>
          <td>{getAppFormattedDateTime(executionDate)}</td>
        </tr>
      );
    }
  }

  getBankoutDetails() {
    const { bankout, formatMessage } = this.props;
    const { amount, currency, status } = bankout || {};

    return (
      <section className="bankoutDetails">
        <h3>
          <FormattedMessage id="bankout_detail" />
          {(bankout.status === BANKOUT_STATUS_SUCCESS || bankout.status === BANKOUT_STATUS_ERROR) && this.getExportButton()}
        </h3>
        <table>
          <tbody>
            <tr>
              <td>
                <FormattedMessage id="data.with.colon" values={{ data: formatMessage({ id: 'common_super_company' }) }} />
              </td>
              <td>{this.showCompanyUrl()}</td>
            </tr>
            <tr>
              <td>
                <FormattedMessage id="data.with.colon" values={{ data: formatMessage({ id: 'common_amount' }) }} />
              </td>
              <td>{formatMonetaryAmount(amount, currency)}</td>
            </tr>
            {this.getExecutionDay()}
            <tr>
              <td>
                <FormattedMessage id="data.with.colon" values={{ data: formatMessage({ id: 'common_status' }) }} />
              </td>
              <td>
                <span className={classNames('bankout-status', 'bankout-status-' + status)}>
                  <FormattedMessage id={status ? 'common_status_' + status : 'common_unknown'} />
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </section>
    );
  }

  render() {
    const { bankout } = this.props;
    if (bankout) {
      return (
        <section className="bankout">
          {this.getBankoutDetails()}
          {this.getBankoutReferences()}
        </section>
      );
    } else {
      return <div>bankoutDetails error</div>;
    }
  }
}

BankoutDetails.displayName = 'BankoutDetails';

BankoutDetails.propTypes = {
  bankout: PropTypes.object,
  retryAction: PropTypes.func,
  exportAction: PropTypes.func,
  formatMessage: PropTypes.func,
  exporting: PropTypes.array,
  canSeeSuperCompany: PropTypes.bool,
  canSeeSubCompany: PropTypes.bool
};

export default connect(state => {
  return {
    exporting: state.bankouts.exporting,
    bankout: state.bankouts.bankoutDetails
  };
})(BankoutDetails);
