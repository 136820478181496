import * as types from '../constants/actionTypes-constants';
import callApi from './api-actions';
import _sortBy from 'lodash/sortBy';
import { addErrorMessage } from '../utils/utils';
import { addFlashMessage } from './flashMessage-actions';
import { FLASH_MESSAGE_TYPE_SUCCESS } from '../constants/generic-constants';

export function getBrandsList(loader = true) {
  return dispatch => {
    dispatch({ type: types.BRANDS_GET_LIST_REQUEST });

    return dispatch(callApi('brands', 'getList', null, loader)).then(
      data => {
        data = _sortBy(data, 'name');
        dispatch({ type: types.BRANDS_GET_LIST_SUCCESS, list: data });
        return Promise.resolve(data);
      },
      error => {
        dispatch(addErrorMessage({ error }));
        dispatch({ type: types.BRANDS_GET_LIST_ERROR });
        return Promise.reject(error);
      }
    );
  };
}

export function createBrandRequest(params) {
  return dispatch => {
    return dispatch(callApi('brands', 'createBrand', params)).then(
      data => {
        dispatch(createBrandSuccess(data));
        return Promise.resolve(data);
      },
      error => {
        const params = { error, def: 'brandForm_create_brand_error', errorCodePrefixes: ['brandForm_'] };
        dispatch(addErrorMessage(params));
        return Promise.reject(error);
      }
    );
  };
}

export function createBrandSuccess(brand) {
  return {
    type: types.BRANDS_CREATE_BRAND_SUCCESS,
    brand
  };
}

export function getSingleBrand(brandId) {
  return dispatch => {
    return dispatch(callApi('brands', 'getBrand', brandId)).then(
      data => {
        dispatch({ type: types.BRANDS_GET_SINGLE_BRAND_SUCCESS, brand: data });
        return Promise.resolve(data);
      },
      error => {
        dispatch(addErrorMessage({ error }));
        return Promise.reject(error);
      }
    );
  };
}

export function updateBrandRequest({ id, ...params }) {
  return dispatch => {
    return dispatch(callApi('brands', 'updateBrand', { id, params })).then(
      data => {
        dispatch(updateBrandSuccess(data));
        dispatch(
          addFlashMessage({
            contentKey: 'brandForm_update_brand_success',
            type: FLASH_MESSAGE_TYPE_SUCCESS
          })
        );
        return Promise.resolve(data);
      },
      error => {
        dispatch(addErrorMessage({ error, def: 'brandForm_update_brand_error' }));
        return Promise.reject(error);
      }
    );
  };
}

export function updateBrandSuccess(brand) {
  return {
    type: types.BRANDS_UPDATE_BRAND_SUCCESS,
    brand
  };
}
