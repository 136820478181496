import React, { Component, PropTypes } from 'react';
import { connect } from 'react-redux';
import { routeActions } from 'react-router-redux';
import SiteForm from '../../components/SiteForm/SiteForm';

import {
  createSiteRequest,
  createSiteSuccess,
  addFlashMessage,
  getTimezoneIdFromLocation,
  updateTimeZone,
  cleanDup
} from '../../actions/all-actions';
import { FLASH_MESSAGE_TYPE_SUCCESS } from '../../constants/generic-constants';
import routes from '../../constants/routes-constants';
import { addErrorMessage } from '../../utils/utils';
import { bundleSelector } from '../../selectors/all-selectors';
import { createAddSiteInitialValues } from '../../utils/parsers';

class AddSite extends Component {
  constructor(props) {
    super(props);

    this.handleCreateSite = this.handleCreateSite.bind(this);
    this.handleTimeZoneId = this.handleTimeZoneId.bind(this);
  }

  componentWillUnmount() {
    this.props.dispatch(cleanDup());
  }

  handleCreateSite() {
    const { dispatch, bundle } = this.props;

    dispatch(createSiteRequest())
      .then(site => {
        dispatch(createSiteSuccess(site));

        dispatch(
          addFlashMessage({
            contentKey: 'siteForm_create_site_success',
            type: FLASH_MESSAGE_TYPE_SUCCESS,
            delayToNextRoute: true
          })
        );

        if (!this.props.isModal) {
          dispatch(routeActions.push(routes.sites.path));
        }

        // should be called last
        if (this.props.onSubmit) {
          this.props.onSubmit();
        }
      })
      .catch(error => {
        const msgParams = { bundle, error, errorCodePrefixes: ['addSite_'], def: 'siteForm_update_site_error' };
        dispatch(addErrorMessage(msgParams));
      });
  }

  handleTimeZoneId(position) {
    const { dispatch } = this.props;

    dispatch(getTimezoneIdFromLocation(position, updateTimeZone));
  }

  render() {
    const {
      initialValues,
      initialValues: { sendReportToDekra }
    } = this.props;

    return (
      <div className="addSitePage mainContainer_content">
        <div className="pageContainer">
          <div className="addSitePage_content">
            <section className="addSitePage_management">
              <SiteForm
                company={this.props.company || this.props.detailCompany}
                isModal={this.props.isModal}
                readOnlyCompany={this.props.readOnlyCompany}
                onCancel={this.props.onCancel}
                displayDekra={sendReportToDekra}
                onCallback={this.handleCreateSite}
                onGetCoordinatesLocationCallback={this.handleTimeZoneId}
                initialValues={initialValues}
              />
            </section>
          </div>
        </div>
      </div>
    );
  }
}

AddSite.propTypes = {
  readOnlyCompany: PropTypes.bool,
  initialValues: PropTypes.object,
  isModal: PropTypes.bool,
  company: PropTypes.object,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func
};

AddSite.displayName = 'AddSite';

export default connect((state, props) => {
  const {
    sites: { dupSite },
    companies: { currentCompanyDetails }
  } = state;

  const { initialValues: outerValues, company: outerCompany } = props;
  const company = outerCompany || currentCompanyDetails;
  const siteValues = dupSite || outerValues;

  return {
    company,
    initialValues: createAddSiteInitialValues(siteValues, company),
    bundle: bundleSelector(state)
  };
})(AddSite);
