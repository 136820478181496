import { routeActions } from 'react-router-redux';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { fillRed } from '../../constants/style-constants';
import { searchMode, clearResults } from '../../actions/all-actions';
import ErrorIcon from 'material-ui/svg-icons/alert/error-outline';

import MemberResults from './components/MemberResults';
import CompaniesResults from './components/CompaniesResults';
import BookingsResults from './components/BookingsResults';
import VehiclesResults from './components/VehiclesResults';
import { apiParams } from '../../constants/api-params-constants';
import { memberResultsTotalSelector } from '../../selectors/all-selectors';

class SearchResults extends Component {
  constructor(props) {
    super(props);

    this.handleOverlayClick = this.handleOverlayClick.bind(this);
    this.handleCancelSearchMode = this.handleCancelSearchMode.bind(this);
  }

  handleOverlayClick() {
    if (!this.props.searched) {
      this.handleCancelSearchMode();
    }
  }

  handleCancelSearchMode() {
    this.props.searchMode(false);
    this.props.clearResults();
  }

  render() {
    const {
      searchModeState,
      memberResultsTotal,
      bookingsResultsTotal,
      vehiclesResultsTotal,
      companiesResultsTotal,
      companiesError,
      bookingsError,
      memberNameError,
      memberEmailError,
      vehiclesError,
      searched,
      isOpen
    } = this.props;

    return (
      <div>
        {searchModeState && (
          <div
            className={
              isOpen ? 'header-serchModeContentOverlay' : 'header-serchModeContentOverlay header-serchModeContentOverlay-menuClosed'
            }
            onClick={this.handleOverlayClick}
          >
            {(companiesError || bookingsError || memberNameError || memberEmailError || vehiclesError) && (
              <div className="search-errors">
                <ErrorIcon style={fillRed} />
                <FormattedMessage id="error_searching_api" />
                {bookingsError && <FormattedMessage id="common_bookings" />}
                {vehiclesError && <FormattedMessage id="common_vehicles" />}
                {(memberEmailError || memberNameError) && <FormattedMessage id="side_menu_section_users_members" />}
                {companiesError && <FormattedMessage id="side_menu_section_account_companies" />}
              </div>
            )}

            {searched && !memberResultsTotal && !bookingsResultsTotal && !vehiclesResultsTotal && !companiesResultsTotal && (
              <div className="search-noResults">
                <FormattedMessage id="common_no_results" />
              </div>
            )}

            <MemberResults />
            <BookingsResults />
            <VehiclesResults />
            <CompaniesResults />
          </div>
        )}
      </div>
    );
  }
}

SearchResults.displayName = 'SearchResults';

const mapStateToProps = state => {
  const {
    header: { searchMode },
    sideMenu: { isOpen },
    searchResults: {
      bookingsResultsTotal,
      vehiclesResultsTotal,
      companiesResultsTotal,
      searched,
      companiesError,
      bookingsError,
      memberNameError,
      memberEmailError,
      vehiclesError
    }
  } = state;

  return {
    searchModeState: searchMode,
    memberResultsTotal: memberResultsTotalSelector(state),
    bookingsResultsTotal,
    vehiclesResultsTotal,
    companiesResultsTotal,
    companiesError,
    bookingsError,
    memberNameError,
    memberEmailError,
    vehiclesError,
    searched,
    isOpen
  };
};

const mapDispatchToProps = dispatch => {
  return {
    searchMode(bool) {
      dispatch(searchMode(bool));
    },
    clearResults() {
      dispatch(clearResults());
    },
    goToBookings(location, toAdd) {
      dispatch(routeActions.push(location.replace(':search', encodeURIComponent(JSON.stringify({ ...apiParams.default, ...toAdd })))));
      dispatch(clearResults());
      dispatch(searchMode(false));
    },
    goToDetail(location, toReplace, id) {
      dispatch(routeActions.push(location.replace(toReplace, id)));
      dispatch(clearResults());
      dispatch(searchMode(false));
    },
    goToAll(location, toAdd) {
      dispatch(routeActions.push(location.replace(':search', encodeURIComponent(JSON.stringify({ ...apiParams.default, ...toAdd })))));
      dispatch(clearResults());
      dispatch(searchMode(false));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchResults);
