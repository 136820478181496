import React, { PropTypes, Component } from 'react';
import _ from 'lodash';
import { FormattedMessage } from 'react-intl';

import IconButton from 'material-ui/IconButton';
import IconMenu from 'material-ui/IconMenu';
import config from '../../../../constants/config-constants';
import { dimensions52, dropdownAnchorOrigin, paddingLeft63 } from '../../../../constants/style-constants';
import MenuItem from 'material-ui/MenuItem';

class Language extends Component {
  constructor() {
    super();
    this.handleChangeLanguage = this.handleChangeLanguage.bind(this);
  }

  handleChangeLanguage(language) {
    this.props.onChangeLanguage(language);
  }

  render() {
    const { userLocale } = this.props;

    return (
      <div className="header-actionIcons-icon">
        <IconMenu
          iconButtonElement={
            <IconButton style={dimensions52}>
              <img src={`/img/flags/${userLocale}.svg`} alt={userLocale} className="header-language-mainFlag" />
            </IconButton>
          }
          anchorOrigin={dropdownAnchorOrigin}
          targetOrigin={dropdownAnchorOrigin}
        >
          {config.theme.availableLanguages.map(lang => (
            <MenuItem
              key={lang}
              primaryText={<FormattedMessage id={`languageName_${lang}`} />}
              onClick={_.partial(this.handleChangeLanguage, lang)}
              leftIcon={<img src={`/img/flags/${lang}.svg`} className="header-language-flag" />}
              innerDivStyle={paddingLeft63}
            />
          ))}
        </IconMenu>
      </div>
    );
  }
}

Language.displayName = 'Language';

Language.propTypes = {
  userLocale: PropTypes.string,
  onChangeLanguage: PropTypes.func
};

export default Language;

// flags (.svg) - https://github.com/lipis/flag-icon-css/tree/master/flags/4x3
