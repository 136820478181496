import React from 'react';
import classnames from 'classnames';
import ListWrap from '../../../components/_v2/ColumnList/Wrap';
import { safe } from '../../../utils/utils';

function detailsOpen(props) {
  return safe(() => props.params.bookingId);
}

export default function BookingsV2(props) {
  return (
    <div className={classnames('pageContainer', 'bookings-v2', 'column-list-flat-details', { 'sc-wide': !detailsOpen(props) })}>
      <ListWrap {...props} />
    </div>
  );
}
