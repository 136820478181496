import React, { Component, PropTypes } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { reduxForm } from 'redux-form';
import _get from 'lodash/get';

import FormRow from '../FormRow/FormRow';
import BoxedInput from '../BoxedInput/BoxedInput';
import LabeledCheckbox from '../LabeledCheckbox/LabeledCheckbox';
import EkButton from '../EkButton/EkButton';
import FieldErrorMsg from '../FieldErrorMsg/FieldErrorMsg';
import { createValidator, number, positiveNumber } from '../../validation/all-validation';
import { updatePriceResponseRemove } from '../../actions/all-actions';
import { BOOKING_STATUS_COMPLETED } from '../../constants/backend-constants';
import { isEmpty, namedCompose, scrollToFirstError } from '../../utils/utils';
import { bookingEndMileageSelector, bookingStartMileageSelector } from '../../selectors/all-selectors';

class UpdateBookingPrice extends Component {
  constructor(props) {
    super(props);
    this.handleUpdatePriceResponseRemove = this.handleUpdatePriceResponseRemove.bind(this);
  }

  handleUpdatePriceResponseRemove() {
    this.props.dispatch(updatePriceResponseRemove());
  }

  formatPrice(price) {
    return isEmpty(price) ? '?' : String(price);
  }

  render() {
    const {
      fields: {
        applyDiscount,
        discardCancellationFees,
        discardDistanceFees,
        discardLateFees,
        discountAmount,
        discountLabel,
        effectiveEndDate,
        effectiveEndTime,
        endMileage,
        startMileage,
        reasonForUpdate,
        refundBooking
      },
      intl: { formatMessage },
      handleSubmit,
      status,
      updatePriceResponse
    } = this.props;

    return (
      <section className="updateBookingPriceWrapper">
        <form action="#" className="bookingPriceForm" onSubmit={handleSubmit(this.props.onCallback)}>
          <div className="updateBookingPrice_container">
            <h4 className="updateBookingPrice_title">
              <FormattedMessage id="update_booking_price_title" />
            </h4>

            {status === BOOKING_STATUS_COMPLETED && (
              <div>
                <FormRow customClass="updateBookingPrice_row">
                  <BoxedInput
                    id="effectiveEndDate"
                    skinType="date"
                    type="date"
                    customClass="updateBookingPrice_boxedInputDate"
                    customLabelClass="updateBookingPrice_boxedInputDateLabel"
                    labelKey="update_booking_price_booking_effective_end_date"
                    field={effectiveEndDate}
                    formRowItemKey="updateBookingPrice_effectiveEndDate"
                  >
                    <FieldErrorMsg field={effectiveEndDate} customClass="fieldErrorMsg--updateBookingPrice" />
                  </BoxedInput>
                  <BoxedInput
                    id="effectiveEndTime"
                    type="time"
                    customClass="updateBookingPrice_boxedInputTime"
                    customLabelClass="updateBookingPrice_boxedInputDateLabel"
                    labelKey="update_booking_price_booking_effective_end_time"
                    field={effectiveEndTime}
                    formRowItemKey="updateBookingPrice_effectiveEndTime"
                  >
                    <FieldErrorMsg field={effectiveEndTime} customClass="fieldErrorMsg--updateBookingPrice" />
                  </BoxedInput>
                </FormRow>

                <FormRow customClass="updateBookingPrice_row">
                  <BoxedInput
                    formRowItemKey="updateBookingPrice_startMileage"
                    type="text"
                    id="startMileage"
                    labelKey="update_booking_price_start_mileage"
                    customClass="updateBookingPrice_boxedInput"
                    field={startMileage}
                  >
                    <FieldErrorMsg field={startMileage} customClass="fieldErrorMsg--updateBookingPrice" />
                  </BoxedInput>

                  <BoxedInput
                    formRowItemKey="updateBookingPrice_endMileage"
                    type="text"
                    id="endMileage"
                    labelKey="update_booking_price_end_mileage"
                    customClass="updateBookingPrice_boxedInput"
                    field={endMileage}
                  >
                    <FieldErrorMsg field={endMileage} customClass="fieldErrorMsg--updateBookingPrice" />
                  </BoxedInput>
                </FormRow>

                <LabeledCheckbox
                  customClass="updateBookingPrice_labeledCheckbox"
                  customLabelClass="updateBookingPrice_labeledCheckboxLabel"
                  id={'refundBooking'}
                  field={refundBooking}
                >
                  <FormattedMessage id="update_booking_price_refund_booking" />
                </LabeledCheckbox>

                <LabeledCheckbox
                  customClass="updateBookingPrice_labeledCheckbox"
                  customLabelClass="updateBookingPrice_labeledCheckboxLabel"
                  id={'discardDistanceFees'}
                  field={discardDistanceFees}
                >
                  <FormattedMessage id="update_booking_price_cancel_distance_fees" />
                </LabeledCheckbox>

                <LabeledCheckbox
                  customClass="updateBookingPrice_labeledCheckbox"
                  customLabelClass="updateBookingPrice_labeledCheckboxLabel"
                  id={'discardLateFees'}
                  field={discardLateFees}
                >
                  <FormattedMessage id="update_booking_price_cancel_late_fees" />
                </LabeledCheckbox>
              </div>
            )}

            <LabeledCheckbox
              customClass="updateBookingPrice_labeledCheckbox"
              customLabelClass="updateBookingPrice_labeledCheckboxLabel"
              id={'discardCancellationFees'}
              field={discardCancellationFees}
            >
              <FormattedMessage id="update_booking_price_remove_cancel_fees" />
            </LabeledCheckbox>

            <LabeledCheckbox id={'applyDiscount'} field={applyDiscount}>
              <FormattedMessage id="update_booking_price_apply_discount" />
            </LabeledCheckbox>

            {applyDiscount.value && (
              <FormRow customClass="updateBookingPrice_row marginTop10">
                <BoxedInput
                  formRowItemKey="updateBookingPrice_discountAmount"
                  type="text"
                  id="discountAmount"
                  labelKey="booking_discount_amount"
                  customClass="updateBookingPrice_boxedInput"
                  field={discountAmount}
                  placeholder={formatMessage({ id: `unit_${this.props.currency}` })}
                >
                  <FieldErrorMsg field={discountAmount} customClass="fieldErrorMsg--updateBookingPrice" />
                </BoxedInput>
                <BoxedInput
                  formRowItemKey="updateBookingPrice_discountLabel"
                  type="text"
                  id="applyDiscount"
                  labelKey="update_booking_price_discount_label"
                  customClass="updateBookingPrice_boxedInput"
                  field={discountLabel}
                >
                  <FieldErrorMsg field={discountLabel} customClass="fieldErrorMsg--updateBookingPrice" />
                </BoxedInput>
              </FormRow>
            )}

            <FormRow customClass={applyDiscount.value ? 'discountLabel' : 'marginTop10 discountLabel'}>
              <BoxedInput
                formRowItemKey="updateBookingPrice_row"
                type="text"
                id="reasonForUpdate"
                labelKey="update_booking_price_update_reason"
                customClass="updateBookingPrice_boxedInput"
                field={reasonForUpdate}
              >
                <FieldErrorMsg field={reasonForUpdate} customClass="fieldErrorMsg--updateBookingPrice" />
              </BoxedInput>
            </FormRow>
          </div>

          {updatePriceResponse && (
            <div className="updateBookingPrice_response">
              <div>
                <FormattedMessage id="data.with.colon" values={{ data: formatMessage({ id: 'update_booking_price_new_price' }) }} />
                <span> </span>
                <FormattedMessage
                  id="common_price_with_currency"
                  values={{
                    amount: this.formatPrice(_get(updatePriceResponse, 'newPrice')),
                    currency: formatMessage({ id: `unit_${updatePriceResponse.currency}` })
                  }}
                />
              </div>
              <div>
                <FormattedMessage id="data.with.colon" values={{ data: formatMessage({ id: 'update_booking_price_amount_to_charge' }) }} />
                <span> </span>
                <FormattedMessage
                  id="common_price_with_currency"
                  values={{
                    amount: this.formatPrice(_get(updatePriceResponse, 'remainingAmountToCharge')),
                    currency: formatMessage({ id: `unit_${updatePriceResponse.currency}` })
                  }}
                />
              </div>
            </div>
          )}

          <div className="updateBookingPrice_actions">
            <EkButton type="button" skinType="reverse" customClass="updateBookingPrice_abort_button" onAction={this.props.onAbort}>
              <FormattedMessage id="booking_detail_cancelBooking_abort_button" />
            </EkButton>

            {!updatePriceResponse ? (
              <EkButton type="button" customClass="updateBookingPrice_confirm_button" onAction={handleSubmit(this.props.onCallbackCheck)}>
                <FormattedMessage id="common_preview" />
              </EkButton>
            ) : (
              <EkButton type="button" customClass="updateBookingPrice_confirm_button" onAction={handleSubmit(this.props.onCallback)}>
                <FormattedMessage id="booking_create_find_vehicle_tableView_action" />
              </EkButton>
            )}
          </div>
        </form>
      </section>
    );
  }
}

UpdateBookingPrice.displayName = 'UpdateBookingPrice';

UpdateBookingPrice.propTypes = {
  fields: PropTypes.object,
  handleSubmit: PropTypes.func,
  onCallback: PropTypes.func,
  fieldReadOnly: PropTypes.string,
  fieldsDisabled: PropTypes.bool
};

export default namedCompose(
  reduxForm(
    {
      onSubmitFail: scrollToFirstError,
      form: 'updateBookingPriceForm',
      fields: [
        'applyDiscount',
        'discardCancellationFees',
        'discardDistanceFees',
        'discardLateFees',
        'discountAmount',
        'discountLabel',
        'effectiveEndDate',
        'effectiveEndTime',
        'endMileage',
        'startMileage',
        'reasonForUpdate',
        'refundBooking'
      ],
      validate: createValidator({
        discountAmount: [positiveNumber()],
        startMileage: [number()],
        endMileage: [number()]
      })
    },
    state => {
      const {
        bookings: {
          bookingDetail: { id, status }
        },
        booking: { updatePriceResponse }
      } = state;

      return {
        initialValues: {
          startMileage: bookingStartMileageSelector(state),
          endMileage: bookingEndMileageSelector(state)
        },
        id,
        status,
        updatePriceResponse
      };
    }
  ),
  injectIntl
)(UpdateBookingPrice);
