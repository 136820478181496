import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import Header from '../../components/Header/Header';
import TableView from '../../components/TableView/TableView';
import SideMenu from '../../components/SideMenu/SideMenu';
import PageManagementHeader from '../../components/PageManagementHeader/PageManagementHeader';
import FlashMessageDisplayer from '../../components/FlashMessageDisplayer/FlashMessageDisplayer';
import routes from '../../constants/routes-constants';
import { hotlinesTableViewParams } from '../../constants/table-constants';
import { BACKUSER_ROLE_CALL_CENTER_OPERATOR } from '../../constants/backend-constants';

class Hotlines extends Component {
  render() {
    const { list, role } = this.props;

    return (
      <div className="hotlinesPage mainContainer_content">
        <div className="pageContainer">
          <div className="hotlinesPage_content">
            <section className="hotlinesPage_management">
              <PageManagementHeader
                titleKey="hotlines_subtitle"
                href={`/#${routes.addHotline.path}`}
                buttonLabelKey="hotlines_add_button"
                customClass="hotlinesPage_managementHeader"
                limitedAccess={role === BACKUSER_ROLE_CALL_CENTER_OPERATOR}
              />
              <div className="hotlinesPage_list">
                <TableView
                  customClass="hotlinesPage_tableViewWrapper"
                  params={hotlinesTableViewParams}
                  content={list}
                  limitedAccess={role === BACKUSER_ROLE_CALL_CENTER_OPERATOR}
                />
              </div>
            </section>
          </div>
        </div>
      </div>
    );
  }
}

Hotlines.displayName = 'Hotlines';
Hotlines.propTypes = {};

export default connect(state => {
  const {
    hotlines: { list },
    user: {
      userInfo: { role }
    }
  } = state;

  return {
    list,
    role
  };
})(Hotlines);
