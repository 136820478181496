import React, { Component } from 'react';
import autoBind from 'react-autobind';
import { namedCompose, newProps } from '../../utils/utils';
import { apiParams } from '../../constants/api-params-constants';
import routes, { backuserEditRules } from '../../constants/routes-constants';
import BackLink from '../../components/BackLink/BackLink';
import { connect } from 'react-redux';
import _get from 'lodash/get';
import { BackUsersModalStyles, DetailViewStyles, cssColors, AddCommentModalStyles } from '../../constants/style-constants';
import { FormattedMessage } from 'react-intl';
import BlockIcon from 'material-ui/svg-icons/content/block';
import IconList from '../../components/IconList/IconList';
import DoneIcon from 'material-ui/svg-icons/action/done';
import DoneAllIcon from 'material-ui/svg-icons/action/done-all';
import EditIcon from 'material-ui/svg-icons/image/edit';
import LockIcon from 'material-ui/svg-icons/action/lock';
import EkButton from '../../components/EkButton/EkButton';
import Modal from 'react-modal';

import {
  changeBackUserEmailRequest,
  closeBackUserEmailModal,
  closeStatusBackUserModal,
  openBackUserEmailModal,
  openStatusBackUserModal,
  requestChangeStatusBackUser,
  requestEnableUser,
  toggleStatusBackUser,
  toggleModalResetPasswordBackUser,
  requestResetPasswordBackUser
} from '../../actions/all-actions';

import { routeActions } from 'react-router-redux';
import BackUserInfo from '../../components/BackUserInfo/BackUserInfo';
import BackUserCompanies from '../../components/BackUserCompanies/BackUserCompanies';
import Tooltip from '../../components/Tooltip/Tooltip';

import BackUserIcon from 'material-ui/svg-icons/action/verified-user';
import { backUserPageRoleRules, checkRole } from '../../constants/backuser-role-rules';
import ChangeEmailForm from '../../components/ChangeEmailForm/ChangeEmailForm';
import MailOutlineIcon from 'material-ui/svg-icons/communication/mail-outline';

class BackUserView extends Component {
  constructor(props) {
    super(props);
    autoBind(this);

    this.setBackUrl(props);
    this.setEditRules(props);

    this.derivedStateFromProps(props, true);
  }

  setBackUrl(props) {
    this.backToListURL =
      '/#' + routes.backUsers.path.replace(':search', encodeURIComponent(JSON.stringify(props.urlParams || apiParams.default)));
  }

  setEditRules(props) {
    const { role } = props;
    const { detailBackUser } = props;
    const { role: backUserRole } = detailBackUser || {};

    this.canEdit = !!(checkRole(backuserEditRules, role) && checkRole(backUserPageRoleRules[role], backUserRole));
  }

  // eslint-disable-next-line no-unused-vars
  componentWillReceiveProps(nextProps, nextContext) {
    this.derivedStateFromProps(nextProps);
  }

  handleConfirmChangeStatusBackUser() {
    const { dispatch, detailBackUser } = this.props;

    dispatch(toggleStatusBackUser());
    dispatch(closeStatusBackUserModal());
    dispatch(requestChangeStatusBackUser(detailBackUser));
  }

  handleAbortChangeStatusBackUser() {
    const { dispatch } = this.props;
    dispatch(closeStatusBackUserModal());
  }

  handleEnable() {
    if (this.login) this.props.dispatch(requestEnableUser({ login: this.login }));
  }

  handleChangeStatusBackUser() {
    const { dispatch, detailBackUser } = this.props;
    dispatch(openStatusBackUserModal(detailBackUser));
  }

  goToEditPage() {
    if (this.userId) {
      const url = routes.editBackUser.path.replace(':backUserId', this.userId);
      this.props.dispatch(routeActions.push(url));
    }
  }

  toggleModalResetPasswordBackUser() {
    const { dispatch } = this.props;
    dispatch(toggleModalResetPasswordBackUser());
  }

  handleChangeResetPassword() {
    const {
      dispatch,
      detailBackUser: { id }
    } = this.props;
    dispatch(requestResetPasswordBackUser(id));
  }

  derivedStateFromProps(props, init) {
    this.unknown = <FormattedMessage id="common_unknown" />;
    this.suspendModalIsOpen = this.userId && props.backUserSelectedToSuspendId === this.userId;

    if (newProps(this.props, props, init, 'detailBackUser')) {
      this.superCompanies = _get(props.detailBackUser, 'companies');
      this.subCompanies = _get(props.detailBackUser, 'subCompanies');
      this.login = _get(props.detailBackUser, 'login');
      this.nationalNumber = _get(props.detailBackUser, 'phoneNumber.nationalNumber');
      this.countryCode = _get(props.detailBackUser, 'phoneNumber.countryCode');
      this.userRole = _get(props.detailBackUser, 'role');
      this.address = _get(props.detailBackUser, 'address.formattedAddress');
      this.firstName = _get(props.detailBackUser, 'firstName') || this.unknown;
      this.lastName = _get(props.detailBackUser, 'lastName') || this.unknown;
      this.userId = _get(props.detailBackUser, 'id');

      if (newProps(this.props.detailBackUser, props.detailBackUser, init, 'suspended')) {
        this.userSuspended = _get(props.detailBackUser, 'suspended');
        this.suspendedClass = this.userSuspended ? 'status_suspended' : 'status_active';
        this.statusLabelId = this.userSuspended ? 'backUsers_detail_suspended' : 'common_active';
        if (this.userSuspended) this.modalLabelId = 'backUsers_detail_activate_confirm';
        else this.modalLabelId = 'backUsers_detail_suspend_confirm';
        if (this.userSuspended) this.modalActionId = 'backUsers_detail_activate_confirm_button';
        else this.modalActionId = 'backUsers_detail_suspend_confirm_button';
      }

      if (newProps(this.props.detailBackUser, props.detailBackUser, init, ['suspended', 'enabled'])) {
        this.userEnabled = _get(props.detailBackUser, 'enabled', true);
        this.createIconListItems();
      }
    }
  }

  createIconListItems() {
    this.iconListItems = [];

    if (this.canEdit) {
      this.iconListItems.push({
        handleClick: this.goToEditPage,
        labelKey: 'backUsers_detail_edit_button',
        id: 'edit_button',
        icon: <EditIcon color={cssColors.listItem} />
      });

      this.iconListItems.push({
        handleClick: this.openEmailModal,
        id: 'change_email_button',
        labelKey: 'resetEmail_button_title',
        icon: <MailOutlineIcon color={cssColors.listItem} />
      });

      if (!this.userEnabled) {
        this.iconListItems.push({
          handleClick: this.handleEnable,
          labelKey: 'members_detail_enable_button',
          id: 'enable_button',
          icon: <DoneAllIcon color={cssColors.listItem} />
        });
      }
      this.iconListItems.push({
        handleClick: this.toggleModalResetPasswordBackUser,
        labelKey: 'resetPassword_new_title',
        id: 'reset_password_button',
        icon: <LockIcon color={cssColors.listItem} />
      });
      this.iconListItems.push({
        handleClick: this.handleChangeStatusBackUser,
        labelKey: this.userSuspended ? 'backUsers_detail_activate_button' : 'backUsers_detail_suspend_button',
        id: this.userSuspended ? 'unsuspend_button' : 'suspend_button',
        icon: this.userSuspended ? <DoneIcon color={cssColors.listItem} /> : <BlockIcon color={cssColors.listItem} />
      });
    }
  }

  renderConfirmModal() {
    return (
      <Modal isOpen={this.suspendModalIsOpen} style={BackUsersModalStyles}>
        <div className="backUserView_modal_label">
          <FormattedMessage id={this.modalLabelId} />
        </div>
        <div className="backUserView_modal_buttons_container">
          <div className="backUserView_modal_left_container">
            <EkButton
              type="button"
              skinType="reverse"
              customClass="backUserView_abort_button"
              onAction={this.handleAbortChangeStatusBackUser}
            >
              <FormattedMessage id="backUsers_detail_abort_button" />
            </EkButton>
          </div>
          <div className="backUserView_modal_right_container">
            <EkButton type="button" customClass="backUserView_confirm_button" onAction={this.handleConfirmChangeStatusBackUser}>
              <FormattedMessage id={this.modalActionId} />
            </EkButton>
          </div>
        </div>
      </Modal>
    );
  }

  handleConfirmEamilChange() {
    if (this.userId) {
      this.props.dispatch(changeBackUserEmailRequest(this.userId));
    }
  }

  handleCancelEmailChange() {
    this.props.dispatch(closeBackUserEmailModal());
  }

  openEmailModal() {
    this.props.dispatch(openBackUserEmailModal());
  }

  companyRouteGetter(id) {
    return '/#' + routes.companyDetail.path.replace(':companyId', id);
  }

  subCompanyRouteGetter(id) {
    return '/#' + routes.subCompanyDetails.path.replace(':subCompanyId', id);
  }

  renderChangeEmailModal() {
    return (
      <Modal isOpen={this.props.emailModalOpened} style={AddCommentModalStyles}>
        <ChangeEmailForm onCallback={this.handleConfirmEamilChange} onAbort={this.handleCancelEmailChange} />
      </Modal>
    );
  }

  renderResetPasswordModal() {
    return (
      <Modal isOpen={this.props.toggleModalBackUserResetPassword} style={BackUsersModalStyles}>
        <div className="backUserView_modal_label">
          <FormattedMessage id="backuser-reset-password-label" />
        </div>
        <div className="backUserView_modal_buttons_container">
          <div className="backUserView_modal_left_container">
            <EkButton
              type="button"
              skinType="reverse"
              customClass="backUserView_abort_button"
              onAction={this.toggleModalResetPasswordBackUser}
            >
              <FormattedMessage id="backUsers_detail_abort_button" />
            </EkButton>
          </div>
          <div className="backUserView_modal_right_container">
            <EkButton type="button" customClass="backUserView_confirm_button" onAction={this.handleChangeResetPassword}>
              <FormattedMessage id="common_confirm" />
            </EkButton>
          </div>
        </div>
      </Modal>
    );
  }

  render() {
    return (
      <div className="mainContainer_content">
        <div className="pageContainer">
          <BackLink link={this.backToListURL} labelKey="back_link_backusers_list" />
          <div className="backUserView detailView">
            <div className="detailView_header detailView_header">
              <div className="detailView_header_left">
                <Tooltip content={<FormattedMessage id="common_back_user" />} placement="bottom">
                  <BackUserIcon style={DetailViewStyles.icons.headerSmall} />
                </Tooltip>
                <h3 className="detailView_header_text">
                  <Tooltip placement="bottom" content={<FormattedMessage id="backUsers_detail_first_name" />}>
                    {this.firstName}
                  </Tooltip>
                  <span> </span>
                  <Tooltip content={<FormattedMessage id="backUsers_detail_last_name" />} placement="bottom">
                    {this.lastName}
                  </Tooltip>
                  <span className={`detailView_header_status ${this.suspendedClass}`}>
                    <Tooltip content={<FormattedMessage id="common_account_status" />} placement="bottom">
                      <FormattedMessage id={this.statusLabelId} />
                    </Tooltip>
                  </span>
                </h3>
              </div>
              <div className="detailView_header_right">
                <IconList items={this.iconListItems} />
              </div>
            </div>
            <div className="detailView_body">
              <BackUserInfo
                showName={false}
                showStatus={false}
                login={this.login}
                address={this.address}
                nationalNumber={this.nationalNumber}
                countryCode={this.countryCode}
                role={this.userRole}
                firstName={this.firstName}
                lastName={this.lastName}
                userSuspended={this.userSuspended}
              />
              <BackUserCompanies headerId="common_super_companies" companies={this.superCompanies} routeGetter={this.companyRouteGetter} />
              <BackUserCompanies headerId="common_sub_companies" companies={this.subCompanies} routeGetter={this.subCompanyRouteGetter} />
            </div>
            {this.renderConfirmModal()}
            {this.renderChangeEmailModal()}
            {this.renderResetPasswordModal()}
          </div>
        </div>
      </div>
    );
  }
}

export default namedCompose(
  connect(state => {
    const {
      backUsers: {
        detailBackUser,
        backUserSelectedToSuspendId,
        isSuspendedBackUser,
        urlParams,
        emailModalOpened,
        toggleModalBackUserResetPassword
      },
      user: {
        userInfo: { role }
      }
    } = state;

    return {
      detailBackUser,
      emailModalOpened,
      backUserSelectedToSuspendId,
      toggleModalBackUserResetPassword,
      isSuspendedBackUser,
      role,
      urlParams
    };
  })
)(BackUserView);
