import * as types from '../constants/actionTypes-constants';
import callApi from './api-actions';

export function getAllApplicationsListIfNeeded() {
  return (dispatch, getState) => {
    let { allApplications } = getState().applications;

    if (!allApplications.length) {
      return dispatch(getAllApplicationsList());
    } else {
      return Promise.resolve(allApplications);
    }
  };
}

export function getAllApplicationsList() {
  return dispatch => {
    dispatch({
      type: types.APPLICATIONS_GET_ALL_REQUEST
    });

    return dispatch(callApi('applications', 'getAll')).then(function(data) {
      dispatch({
        type: types.APPLICATIONS_GET_ALL_SUCCESS,
        applications: data
      });
      return data;
    });
  };
}
