/* eslint-disable react/no-set-state */

import classNames from 'classnames';
import { FormattedMessage, injectIntl } from 'react-intl';
import { reduxForm } from 'redux-form';
import FlatButton from 'material-ui/FlatButton';
import FormRow from '../../../components/FormRow/FormRow';
import BoxedInput from '../../../components/BoxedInput/BoxedInput';
import BoxedSelect from '../../../components/BoxedSelect/BoxedSelect';
import FieldErrorMsg from '../../../components/FieldErrorMsg/FieldErrorMsg';
import BoxedToggle from '../../../components/BoxedToggle/BoxedToggle';
import {
  createValidator,
  notEmpty,
  positiveNumber,
  number,
  allowCapitalLettersAndNubmers,
  stopValidationIf,
  condition,
  oneIsRequired,
  integer,
  minimum
} from '../../../validation/all-validation';
import { PERCENT_DISCOUNT } from '../../../constants/backend-constants';
import { voucherTypes } from '../../../constants/filters-constants';
import BackLink from '../../../components/BackLink/BackLink';
import { getMainColor, namedCompose, scrollToFirstError } from '../../../utils/utils';
import autoBind from 'react-autobind';
import _get from 'lodash/get';
import EditIcon from 'material-ui/svg-icons/image/edit';
import Modal from 'react-modal';
import { voucherParkingsRestrictionsModalStyles } from '../../../constants/style-constants';
import ListSelect, { listSelectItem } from '../../../components/ListSelect/ListSelect';
import RaisedButton from 'material-ui/RaisedButton';

// noinspection ES6CheckImport
import React, { Component, PropTypes } from 'react';

class AddVoucherGroupForm extends Component {
  constructor(props) {
    super(props);
    autoBind(this);

    this.state = { showParkingsModal: false, showCompaniesModal: false };
    this.derivedStateFromProps(props);
  }

  // noinspection JSCheckFunctionSignatures
  componentWillReceiveProps(props) {
    this.derivedStateFromProps(props);
  }

  derivedStateFromProps(props) {
    this.setAllowedParkingsLength(props);
    this.setAllowedCompaniesLength(props);
    this.setAllowedParkingsLabel(props);
    this.setAllowedCompaniesLabel(props);
    this.openParkingsListAutomatically(props);
    this.openCompaniesListAutomatically(props);
  }

  setAllowedParkingsLength(props) {
    this.allowedParkingsLength = _get(props.allowedParkings, 'length') || 0;
  }

  setAllowedCompaniesLength(props) {
    this.allowedCompaniesLength = _get(props.allowedCompanies, 'length') || 0;
  }

  setAllowedParkingsLabel(props) {
    let label = this.allowedParkingsLength;
    if (label === 0) label = props.intl.formatMessage({ id: 'common_all' });
    this.allowedParkingsLabel = label;
  }

  setAllowedCompaniesLabel(props) {
    let label = this.allowedCompaniesLength;
    if (label === 0) label = props.intl.formatMessage({ id: 'common_all' });
    this.allowedCompaniesLabel = label;
  }

  openParkingsListAutomatically(props) {
    const newBool = _get(props.fields, 'parkingsBool.value');
    const oldBool = _get(this.props.fields, 'parkingsBool.value');

    if (newBool && oldBool !== newBool) this.handleOpenParkingsModal();
  }

  openCompaniesListAutomatically(props) {
    const newBool = _get(props.fields, 'companiesBool.value');
    const oldBool = _get(this.props.fields, 'companiesBool.value');

    if (newBool && oldBool !== newBool) this.handleOpenCompaniesModal();
  }

  closeParkingSelectionAutomatically() {
    const { parkingsBool } = this.props.fields;
    if (this.allowedParkingsLength === 0) parkingsBool.onChange(false);
  }

  closeCompaniesSelectionAutomatically() {
    const { companiesBool } = this.props.fields;
    if (this.allowedCompaniesLength === 0) companiesBool.onChange(false);
  }

  handleCancel() {
    this.props.onCancel();
  }

  handleOpenParkingsModal() {
    // noinspection JSUnresolvedFunction
    this.setState({ showParkingsModal: true });
  }

  handleCloseParkingsModal() {
    // noinspection JSUnresolvedFunction
    this.setState({ showParkingsModal: false });
    this.closeParkingSelectionAutomatically();
  }

  handleOpenCompaniesModal() {
    // noinspection JSUnresolvedFunction
    this.setState({ showCompaniesModal: true });
  }

  handleCloseCompaniesModal() {
    // noinspection JSUnresolvedFunction
    this.setState({ showCompaniesModal: false });
    this.closeCompaniesSelectionAutomatically();
  }

  displayParkingsBlock(parkingsEnabled) {
    const { intl } = this.props;
    const buttonLabel = intl.formatMessage({ id: 'voucher_restrict_modify' });

    if (parkingsEnabled) {
      return (
        <div>
          <div className="voucher-restrict-parking-container">
            <div className="voucher-restrict-parking-number-prefix">
              <FormattedMessage id="voucher_restrict_number_of_parkings" />
            </div>
            <div className="voucher-restrict-parking-number">{this.allowedParkingsLabel}</div>
          </div>
          <FlatButton
            id="voucher_restrict_modify"
            className={classNames('voucher-restrict-edit-button', 'flatButton')}
            label={buttonLabel}
            icon={<EditIcon />}
            onClick={this.handleOpenParkingsModal}
            primary
          />
        </div>
      );
    }
  }

  displayCompaniesBlock(companiesEnabled) {
    const { intl } = this.props;
    const buttonLabel = intl.formatMessage({ id: 'voucher_restrict_modify_companies' });

    if (companiesEnabled) {
      return (
        <div>
          <div className="voucher-restrict-parking-container">
            <div className="voucher-restrict-parking-number-prefix">
              <FormattedMessage id="voucher_restrict_number_of_companies" />
            </div>
            <div className="voucher-restrict-parking-number">{this.allowedCompaniesLabel}</div>
          </div>
          <FlatButton
            id="voucher_restrict_modify"
            className={classNames('voucher-restrict-edit-button', 'flatButton')}
            label={buttonLabel}
            icon={<EditIcon />}
            onClick={this.handleOpenCompaniesModal}
            primary
          />
        </div>
      );
    }
  }

  handleCloseButton() {
    this.handleCloseParkingsModal();
  }

  displayParkingsModal() {
    const { intl, availableParkings, allowedParkings, addToAvailableParkings, addToAllowedParkings } = this.props;
    const listOneTitle = intl.formatMessage({ id: 'voucher_restrict_available_parkings' }) + ' 🚫';
    const listTwoTitle = intl.formatMessage({ id: 'voucher_restrict_number_of_parkings' }) + ' ✅️';
    const closeLabel = intl.formatMessage({ id: 'common_close' });

    return (
      <Modal
        isOpen={this.state.showParkingsModal}
        style={voucherParkingsRestrictionsModalStyles}
        onRequestClose={this.handleCloseParkingsModal}
      >
        <div className="voucher-restrict-modal-inner">
          <ListSelect
            listOneLabel={listOneTitle}
            listOneOptions={availableParkings}
            onAddToListOne={addToAvailableParkings}
            listTwoOptions={allowedParkings}
            listTwoLabel={listTwoTitle}
            onAddToListTwo={addToAllowedParkings}
          />
          <div className="button-container">
            <FlatButton
              className={classNames('parkings-modal-close', 'flatButton')}
              onClick={this.handleCloseButton}
              label={closeLabel}
              primary
            />
          </div>
        </div>
      </Modal>
    );
  }

  displayCompaniesModal() {
    const { intl, availableCompanies, allowedCompanies, addToAvailableCompanies, addToAllowedCompanies } = this.props;
    const listOneTitle = intl.formatMessage({ id: 'voucher_restrict_available_companies' }) + ' 🚫';
    const listTwoTitle = intl.formatMessage({ id: 'voucher_restrict_number_of_companies' }) + ' ✅️';
    const closeLabel = intl.formatMessage({ id: 'common_close' });

    return (
      <Modal
        isOpen={this.state.showCompaniesModal}
        style={voucherParkingsRestrictionsModalStyles}
        onRequestClose={this.handleCloseCompaniesModal}
      >
        <div className="voucher-restrict-modal-inner">
          <ListSelect
            listOneLabel={listOneTitle}
            listOneOptions={availableCompanies}
            onAddToListOne={addToAvailableCompanies}
            listTwoOptions={allowedCompanies}
            listTwoLabel={listTwoTitle}
            onAddToListTwo={addToAllowedCompanies}
          />
          <div className="button-container">
            <FlatButton
              className={classNames('parkings-modal-close', 'flatButton')}
              onClick={this.handleCloseCompaniesModal}
              label={closeLabel}
              primary
            />
          </div>
        </div>
      </Modal>
    );
  }

  displayParkingsRow() {
    const {
      fields: { parkingsBool, companiesBool }
    } = this.props;

    const parkingsEnabled = _get(parkingsBool, 'value');
    const disabled = this.allowedCompaniesLength > 0 && _get(companiesBool, 'value');
    const divClass = parkingsEnabled ? 'addVoucherGroupForm_toggleRowOn' : 'addVoucherGroupForm_toggleRow';

    return (
      <FormRow customClass="addVoucherGroupForm_row">
        <div className={divClass}>
          <BoxedToggle labelKey="voucher_restrict_parkings" field={parkingsBool} disabled={disabled} />
          {this.displayParkingsBlock(parkingsEnabled)}
        </div>
      </FormRow>
    );
  }

  displayCompaniesRow() {
    const {
      fields: { companiesBool, parkingsBool }
    } = this.props;

    const companiesEnabled = _get(companiesBool, 'value');
    const disabled = this.allowedParkingsLength > 0 && _get(parkingsBool, 'value');
    const divClass = companiesEnabled ? 'addVoucherGroupForm_toggleRowOn' : 'addVoucherGroupForm_toggleRow';

    return (
      <FormRow customClass="addVoucherGroupForm_row">
        <div className={divClass}>
          <BoxedToggle labelKey="voucher_restrict_companies" field={companiesBool} disabled={disabled} />
          {this.displayCompaniesBlock(companiesEnabled)}
        </div>
      </FormRow>
    );
  }

  render() {
    const {
      fields: {
        discount,
        maxAmount,
        name,
        startDateBool,
        startDate,
        endDate,
        utilisationNumber,
        utilisationNumberBool,
        utilisationNumberPerProfile,
        utilisationNumberPerProfileBool,
        creationDateBool,
        creationStartDate,
        creationEndDate,
        prefix,
        type
      },
      handleSubmit,
      intl,
      currency
    } = this.props;

    const currencySymbol = intl.formatMessage({ id: `unit_${currency}` });
    const saveLabel = intl.formatMessage({ id: 'vehicleForm_step_submit' });
    const cancelLabel = intl.formatMessage({ id: 'members_detail_abort_button' });

    return (
      <div className="addVoucherGroupForm_container">
        <BackLink onClickBack={this.handleCancel} labelKey="back_link_vouchers_list" />
        <form action="#" onSubmit={handleSubmit(this.props.onCallback)}>
          <div className="addVoucherGroupForm_wrap">
            <div className="addVoucherGroupForm_col1">
              <div className="addVoucherGroupForm_colTitle">
                <FormattedMessage id="voucher_group_information" />
              </div>
              <FormRow customWrapperClass="addVoucherGroupForm_row">
                <BoxedInput
                  formRowItemKey="addVoucherGroupForm_name"
                  type="text"
                  id="addVoucherGroupForm_name"
                  labelKey="voucher_group_name"
                  field={name}
                  inputRef={this.props.inputRef}
                  mandatory
                >
                  <FieldErrorMsg customClass="fieldErrorMsg--addVoucherGroupForm" field={name} />
                </BoxedInput>
              </FormRow>

              <FormRow customWrapperClass="addVoucherGroupForm_row">
                <BoxedInput
                  formRowItemKey="addVoucherGroupForm_prefix"
                  type="text"
                  id="addVoucherGroupForm_prefix"
                  labelKey="common_prefix"
                  field={prefix}
                  mandatory
                >
                  <FieldErrorMsg customClass="fieldErrorMsg--prefix" field={prefix} />
                </BoxedInput>
              </FormRow>

              <FormRow customClass="addVoucherGroupForm_row">
                <BoxedSelect
                  formRowItemKey="addVoucherGroupForm_type"
                  labelKey="members_detail_professional"
                  mandatory
                  options={voucherTypes}
                  customClass="addVoucherGroupForm_type"
                  field={type}
                >
                  <FieldErrorMsg customClass="fieldErrorMsg--type" field={type} />
                </BoxedSelect>

                <BoxedInput
                  formRowItemKey="addVoucherGroupForm_discount"
                  type="text"
                  id="addVoucherGroupForm_discount"
                  labelKey="invoices_tableView_label_value"
                  placeholder={type.value !== '' ? (type.value === PERCENT_DISCOUNT ? '%' : currencySymbol) : ''}
                  field={discount}
                  mandatory
                >
                  <FieldErrorMsg customClass="fieldErrorMsg--addVoucherGroupForm" field={discount} />
                </BoxedInput>
              </FormRow>

              {type.value === PERCENT_DISCOUNT && (
                <FormRow customClass="addVoucherGroupForm_row">
                  <BoxedInput
                    formRowItemKey="addVoucherGroupForm_maxAmount"
                    type="text"
                    labelKey="voucher_maximum_discount"
                    placeholder={currencySymbol}
                    id="addVoucherGroupForm_maxAmount"
                    field={maxAmount}
                  >
                    <FieldErrorMsg customClass="fieldErrorMsg--utilisationNumber" field={maxAmount} />
                  </BoxedInput>
                </FormRow>
              )}
            </div>
            <div className="addVoucherGroupForm_col2">
              <div className="addVoucherGroupForm_colTitle addVoucherGroupForm_col2Title">
                <FormattedMessage id="voucher_rules" />
              </div>
              <FormRow customClass="addVoucherGroupForm_row">
                <div className={utilisationNumberBool.value ? 'addVoucherGroupForm_toggleRowOn' : 'addVoucherGroupForm_toggleRow'}>
                  <BoxedToggle labelKey="voucher_limit_usage" field={utilisationNumberBool} />
                  {utilisationNumberBool.value && (
                    <BoxedInput
                      formRowItemKey="addVoucherGroupForm_utilisationNumber"
                      type="number"
                      id="addVoucherGroupForm_useNumber"
                      field={utilisationNumber}
                    >
                      <FieldErrorMsg customClass="fieldErrorMsg--utilisationNumber" field={utilisationNumber} />
                    </BoxedInput>
                  )}
                </div>
              </FormRow>
              <FormRow customClass="addVoucherGroupForm_row">
                <div
                  className={utilisationNumberPerProfileBool.value ? 'addVoucherGroupForm_toggleRowOn' : 'addVoucherGroupForm_toggleRow'}
                >
                  <BoxedToggle labelKey="voucher_limit_usage_per_profile" field={utilisationNumberPerProfileBool} />
                  {utilisationNumberPerProfileBool.value && (
                    <BoxedInput
                      formRowItemKey="addVoucherGroupForm_utilisationNumberPerProfile"
                      type="number"
                      id="addVoucherGroupForm_usePerProfile"
                      field={utilisationNumberPerProfile}
                    >
                      <FieldErrorMsg customClass="fieldErrorMsg--utilisationNumberPerProfile" field={utilisationNumberPerProfile} />
                    </BoxedInput>
                  )}
                </div>
              </FormRow>

              <FormRow customClass="addVoucherGroupForm_row">
                <div className={creationDateBool.value ? 'addVoucherGroupForm_toggleRowOn' : 'addVoucherGroupForm_toggleRow'}>
                  <BoxedToggle labelKey="voucher_restrict_creation_date" field={creationDateBool} />
                  {creationDateBool.value && (
                    <div>
                      <BoxedInput
                        formRowItemKey="addVoucherGroupForm_creationStartDate"
                        type="date"
                        id="addVoucherGroupForm_creationStartDate"
                        labelKey="voucher_start_after"
                        field={creationStartDate}
                      >
                        <FieldErrorMsg customClass="fieldErrorMsg--addVoucherGroupForm" field={creationStartDate} />
                      </BoxedInput>

                      <BoxedInput
                        formRowItemKey="addVoucherGroupForm_creationEndDate"
                        type="date"
                        id="addVoucherGroupForm_creationEndDate"
                        labelKey="voucher_end_before"
                        field={creationEndDate}
                      >
                        <FieldErrorMsg customClass="fieldErrorMsg--addVoucherGroupForm" field={creationEndDate} />
                      </BoxedInput>
                    </div>
                  )}
                </div>
              </FormRow>

              <FormRow customClass="addVoucherGroupForm_row">
                <div className={startDateBool.value ? 'addVoucherGroupForm_toggleRowOn' : 'addVoucherGroupForm_toggleRow'}>
                  <BoxedToggle labelKey="voucher_restrict_start_date" field={startDateBool} />
                  {startDateBool.value && (
                    <div>
                      <BoxedInput
                        formRowItemKey="addVoucherGroupForm_startDate"
                        type="date"
                        id="addVoucherGroupForm_startDate"
                        labelKey="voucher_start_after"
                        field={startDate}
                      >
                        <FieldErrorMsg customClass="fieldErrorMsg--addVoucherGroupForm" field={startDate} />
                      </BoxedInput>

                      <BoxedInput
                        formRowItemKey="addVoucherGroupForm_endDate"
                        type="date"
                        id="addVoucherGroupForm_endDate"
                        labelKey="voucher_end_before"
                        field={endDate}
                      >
                        <FieldErrorMsg customClass="fieldErrorMsg--addVoucherGroupForm" field={endDate} />
                      </BoxedInput>
                    </div>
                  )}
                </div>
              </FormRow>

              {this.displayParkingsRow()}
              {this.displayCompaniesRow()}
            </div>
          </div>

          <div className="addVoucherGroupForm_actions">
            <RaisedButton
              className="flatButton"
              id="voucher_create_group_cancel_button"
              label={cancelLabel}
              style={{ marginRight: 15 + 'px' }}
              labelStyle={{ color: getMainColor() }}
              onTouchTap={this.handleCancel}
            />
            <RaisedButton
              className="flatButton"
              id="voucher_create_group_confirm_button"
              onTouchTap={handleSubmit(this.props.onCallback)}
              label={saveLabel}
              primary
            />
          </div>
        </form>

        {this.displayParkingsModal()}
        {this.displayCompaniesModal()}
      </div>
    );
  }
}

AddVoucherGroupForm.propTypes = {
  fields: PropTypes.object,
  handleSubmit: PropTypes.func,
  currency: PropTypes.string,
  availableParkings: PropTypes.arrayOf(listSelectItem),
  allowedParkings: PropTypes.arrayOf(listSelectItem),
  addToAllowedParkings: PropTypes.func,
  addToAvailableParkings: PropTypes.func,
  availableCompanies: PropTypes.arrayOf(listSelectItem),
  allowedCompanies: PropTypes.arrayOf(listSelectItem),
  addToAllowedCompanies: PropTypes.func,
  addToAvailableCompanies: PropTypes.func,
  inputRef: PropTypes.func
};

function isMaxUsagesFieldClosed(props, value, values) {
  return !values['utilisationNumberBool'];
}

function isMaxUsagesPerProfileFieldClosed(props, value, values) {
  return !values['utilisationNumberPerProfileBool'];
}

function isCreationDateFieldClosed(props, value, values) {
  return !values['creationDateBool'];
}

function isStartDateFieldClosed(props, value, values) {
  return !values['startDateBool'];
}

export default namedCompose(
  reduxForm({
    onSubmitFail: scrollToFirstError,
    form: 'AddVoucherGroupForm',
    fields: [
      'discount',
      'maxAmount',
      'name',
      'creationDateBool',
      'creationStartDate',
      'creationEndDate',
      'startDateBool',
      'startDate',
      'endDate',
      'prefix',
      'type',
      'utilisationNumber',
      'utilisationNumberBool',
      'utilisationNumberPerProfile',
      'utilisationNumberPerProfileBool',
      'parkingsBool',
      'companiesBool'
    ],
    validate: createValidator({
      name: [notEmpty()],
      discount: [notEmpty(), positiveNumber(), number()],
      type: [notEmpty()],
      prefix: [allowCapitalLettersAndNubmers(), notEmpty()],
      maxAmount: [positiveNumber()],
      creationStartDate: [stopValidationIf(condition(isCreationDateFieldClosed)), oneIsRequired(['creationStartDate', 'creationEndDate'])],
      creationEndDate: [stopValidationIf(condition(isCreationDateFieldClosed)), oneIsRequired(['creationStartDate', 'creationEndDate'])],
      startDate: [stopValidationIf(condition(isStartDateFieldClosed)), oneIsRequired(['startDate', 'endDate'])],
      endDate: [stopValidationIf(condition(isStartDateFieldClosed)), oneIsRequired(['startDate', 'endDate'])],
      utilisationNumber: [stopValidationIf(condition(isMaxUsagesFieldClosed)), integer(), minimum(1), notEmpty()],
      utilisationNumberPerProfile: [stopValidationIf(condition(isMaxUsagesPerProfileFieldClosed)), integer(), minimum(1), notEmpty()]
    })
  }),
  injectIntl
)(AddVoucherGroupForm);
