// noinspection ES6CheckImport
import React, { Component, PropTypes } from 'react';
import classNames from 'classnames';

import { FormattedMessage } from 'react-intl';
import AddBankDetailsForm from '../../components/AddBankDetailsForm/AddBankDetailsForm';
import CommissionParamsForm from '../../components/PaybackForms/CommissionParamsForm';
import BankTransferForm from '../../components/PaybackForms/BankTransferForm';
import SubmitForm from '../../components/PaybackForms/SubmitForm';
import { formatAppDate, isEmpty, safe } from '../../utils/utils';
import { migratePaymentCompany, onMigratePaymentProvider } from '../../actions/all-actions';
import moment from 'moment';
import _isDate from 'lodash/isDate';
import _pick from 'lodash/pick';
import { ADD_BANK_DETAILS_FORM_FIELDS, BANK_TRANSFER_FORM_FIELDS, COMMISSION_FORM_FIELDS } from '../../constants/generic-constants';
import { connect } from 'react-redux';
import {
  accountStripeCompanySelector,
  detailedCompanySelector,
  userRoleSelector,
  currentContractPaymentValuesSelector,
  currentSubCompanySelector,
  accountStripeSubCompanySelector
} from '../../selectors/all-selectors';
import _map from 'lodash/map';
import { EUR_CURRENCY, PAYMENT_PROVIDERS, STRIPE_STATUS } from '../../constants/backend-constants';
import { FlatButton, RaisedButton } from 'material-ui';
import { HardwareKeyboardArrowDown, HardwareKeyboardArrowUp } from 'material-ui/svg-icons';
import Close from 'material-ui/svg-icons/navigation/close';
import AddConnectedAccountForm from '../AddConnectedAccountForm/AddConnectedAccountForm';
import {
  COMPANIES_GET_CONNECTED_ACCOUNT_ERROR,
  COMPANIES_GET_CONNECTED_ACCOUNT_SUCCESS,
  SUB_COMPANY_GET_CONNECTED_ACCOUNT_SUCCESS
} from '../../constants/actionTypes-constants';
import Dialog from 'material-ui/Dialog';

class PaybackSettingsTab extends Component {
  constructor(props) {
    super(props);
    this.setStaticData();
    this.state = { isOpen: false, migrateModal: false, loading: false, success: false, error: false };
    this.derivedStateFromProps(props, true);
  }

  // noinspection JSCheckFunctionSignatures
  componentWillReceiveProps(nextProps) {
    this.derivedStateFromProps(nextProps);
  }

  derivedStateFromProps(nextProps, init) {
    this.setCommissionInfo(nextProps);
    this.createCurrencyOptions(nextProps, init);
  }

  currenciesUpdated(nextProps, init) {
    const { currencies: prevValue } = this.props;
    const { currencies: nextValue } = nextProps;
    return init || prevValue !== nextValue;
  }

  createCurrencyOptions(nextProps, init) {
    if (this.currenciesUpdated(nextProps, init)) {
      const { currencies } = nextProps;

      this.currencyOptions = _map(currencies, item => {
        return { labelKey: 'currency_' + item, value: item };
      });
    }
  }

  setStaticData() {
    this.commissionInfoContainerStyle = { padding: '6px 10px' };
    this.commissionInfoDateFormat = 'DD.MM.YYYY (HH:mm)';
    this.zeroMargin = { margin: 0 };
  }

  setCommissionInfo(props) {
    const { paybackData } = props;
    const { paybackConfig } = paybackData || {};
    const { commissions } = paybackConfig || {};

    if (commissions) {
      const commissionsHtml = commissions.reduce((returnArray, commissionItem) => {
        let { rate, startDate } = commissionItem || {};

        if (!isEmpty(rate) && startDate) {
          const formattedDate = formatAppDate(startDate, this.commissionInfoDateFormat, true);

          returnArray.push(
            <tr key={rate + startDate}>
              <td className="td-rate">{rate}</td>
              <td>&nbsp;-&nbsp;</td>
              <td className="td-date">{formattedDate}</td>
            </tr>
          );
        }

        return returnArray;
      }, []);

      this.commissionInfo = (
        <div style={this.commissionInfoContainerStyle}>
          <table>
            <tbody>{commissionsHtml}</tbody>
          </table>
        </div>
      );
    } else this.commissionInfo = undefined;
  }

  bankDetails() {
    const {
      companyId,
      bankAccountDetails,
      sectionClassName,
      addBankDetailsCallback,
      paybackData,
      bankDetailsReadOnly,
      createAccountCB,
      refreshLinkCB,
      deleteAccountCB,
      paybackFormValues,
      paymentProvider,
      currency,
      connectedAccount,
      subCompConnectedAccount,
      detailCompany,
      detailSubCompany,
      parentId
    } = this.props;

    const storedValues = _pick(paybackFormValues, ADD_BANK_DETAILS_FORM_FIELDS);
    const { bankDetailsCreated } = paybackData || {};
    const bankCountry = safe(() => (parentId ? detailSubCompany.address.country : detailCompany.address.country));
    const email = safe(() => (parentId ? detailSubCompany.email : detailCompany.email));
    const connectedAccounts = parentId ? subCompConnectedAccount : connectedAccount;

    return (
      <section className={sectionClassName}>
        {paymentProvider === PAYMENT_PROVIDERS.STRIPE ? (
          <AddConnectedAccountForm
            initialValues={{
              bankCurrency: currency,
              email,
              bankCountry
            }}
            handleSubmit={createAccountCB}
            connectedAccount={connectedAccounts}
            supress={deleteAccountCB}
            create={createAccountCB}
            update={refreshLinkCB}
            parentId={parentId}
            currencyOptions={this.currencyOptions}
          />
        ) : (
          <AddBankDetailsForm
            initialValues={{
              bankCurrency: currency,
              bankAccountDetails,
              bankCountry,
              ...storedValues
            }}
            currencyOptions={this.currencyOptions}
            detailsUploaded={!!bankAccountDetails}
            bankDetailsPresent={bankDetailsCreated}
            companyId={companyId}
            callback={addBankDetailsCallback}
            bankDetailsReadOnly={bankDetailsReadOnly}
          />
        )}
      </section>
    );
  }

  convertToDate(date) {
    if (date) return _isDate(date) ? date : moment(date).toDate();
    return '';
  }

  commissionParams() {
    const { paybackData, paybackFormValues, firstPayback, sectionClassName, paybackSettingsReadOnly, currency } = this.props;
    const { paybackConfig } = paybackData || {};
    const { commissions, commissionRate: actualRate, commisionsVatRate: commissionVatRate, invoicingCountry } = paybackConfig || {};
    const commissionItem = actualRate || (commissions && commissions[commissions.length - 1]);
    const { rate: commissionRate, startDate: commissionRateFromDate } = commissionItem || {};
    const storedValues = _pick(paybackFormValues, COMMISSION_FORM_FIELDS);
    const { commissionRateFromDate: storedDate } = storedValues || {};
    const formDate = storedDate || (!actualRate && commissionRateFromDate);
    const commisionRateDate = this.convertToDate(formDate);

    return (
      <section className={sectionClassName}>
        <CommissionParamsForm
          readOnly={paybackSettingsReadOnly}
          commissionInfo={this.commissionInfo}
          currencyOptions={this.currencyOptions}
          firstPayback={firstPayback}
          initialValues={{
            commissionVatRate,
            commissionRate,
            invoicingCountry,
            commissionCurrency: currency,
            ...storedValues,
            commissionRateFromDate: commisionRateDate
          }}
        />
      </section>
    );
  }

  bankTransferParams() {
    const { paybackData, paybackFormValues, sectionClassName, paybackSettingsReadOnly } = this.props;
    const { bankoutConfig } = paybackData || {};
    const storedValues = _pick(paybackFormValues, BANK_TRANSFER_FORM_FIELDS);

    const { label: bankoutLabel, delay: timeBeforePayment, frequency: bankoutFrequency, executionDay: bankoutExecutionDay } =
      bankoutConfig || {};

    return (
      <section className={sectionClassName}>
        <BankTransferForm
          readOnly={paybackSettingsReadOnly}
          initialValues={{
            bankoutLabel,
            timeBeforePayment,
            bankoutFrequency,
            bankoutExecutionDay,
            ...storedValues
          }}
        />
      </section>
    );
  }

  handleClickexpand() {
    this.setState({ isOpen: !this.state.isOpen });
  }

  handleConfirm() {
    const { dispatch, companyId } = this.props;
    this.setState({ loading: true, success: false });

    dispatch(migratePaymentCompany(companyId)).then(data => {
      if (data.ok) {
        this.setState({ loading: false, success: true, isOpen: false });
        dispatch(onMigratePaymentProvider());
      } else {
        this.setState({ error: true, loading: false });
      }
    });
  }

  getKeyBtn() {
    const { connectedAccount, subCompConnectedAccount, parentId } = this.props;
    const cAccount = parentId ? subCompConnectedAccount : connectedAccount;
    if (cAccount.type === COMPANIES_GET_CONNECTED_ACCOUNT_ERROR) return 'migrate-stripe-collapsible-title-create';

    if (this.isCompletedConnectedAccount(cAccount)) {
      return 'migrate-stripe-collapsible-title-view';
    }
    return 'migrate-stripe-collapsible-title-update';
  }

  migrateStripModal() {
    return (
      <Dialog
        contentStyle={{ width: '500px' }}
        className="test"
        open={this.state.migrateModal}
        onRequestClose={() => this.setState({ migrateModal: false })}
        actions={[
          <FlatButton key="no" label={<FormattedMessage id="common_cancel" />} onClick={() => this.setState({ migrateModal: false })} />,
          this.getMigrateBtn()
        ]}
        title={
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <FormattedMessage id="migrate-stripe-finalize" />
            <Close
              onClick={() => this.setState({ migrateModal: false })}
              style={{ cursor: 'pointer', marginLeft: 'auto' }}
              color={'rgba(0, 0, 0, .54)'}
              hoverColor={'rgba(0, 0, 0, .84)'}
            />
          </div>
        }
      >
        <p>
          <FormattedMessage id="migrate-stripe-finalize-info" />
        </p>
      </Dialog>
    );
  }

  isCompletedConnectedAccount(connectedAccount) {
    return (
      (connectedAccount.type === COMPANIES_GET_CONNECTED_ACCOUNT_SUCCESS ||
        connectedAccount.type === SUB_COMPANY_GET_CONNECTED_ACCOUNT_SUCCESS) &&
      safe(() => connectedAccount.data.stripeAccount.status === STRIPE_STATUS.COMPLETED)
    );
  }

  getButton(wide = false) {
    const { connectedAccount, subCompConnectedAccount, parentId } = this.props;
    const cAccount = parentId ? subCompConnectedAccount : connectedAccount;
    const { error, success, loading } = this.state;
    if (success)
      return (
        <div className="migrate">
          <FormattedMessage id="migration_stripe_done" />
        </div>
      );
    if (error)
      return (
        <div className="migrate">
          <FormattedMessage id="migration_stripe_error" />
          <RaisedButton
            key="yes"
            className="migrate"
            label={<FormattedMessage id="common.retry" />}
            primary
            onClick={() => this.setState({ migrateModal: true })}
          />
        </div>
      );
    if (loading)
      return (
        <div className="migrate">
          <FormattedMessage id="migration_stripe_processing" />
        </div>
      );
    if (this.isCompletedConnectedAccount(cAccount))
      return wide ? (
        <RaisedButton
          key="yes"
          label={<FormattedMessage id="migrate-ready-start" />}
          fullWidth
          primary
          onClick={() => this.setState({ migrateModal: true })}
        />
      ) : (
        <RaisedButton
          key="yes"
          className="migrate"
          label={<FormattedMessage id="migrate-ready-start" />}
          primary
          onClick={() => this.setState({ migrateModal: true })}
        />
      );
  }

  getMigrateBtn() {
    const { connectedAccount, subCompConnectedAccount, parentId } = this.props;
    const cAccount = parentId ? subCompConnectedAccount : connectedAccount;
    const { error, success, loading } = this.state;
    const labelKey = loading ? (
      <FormattedMessage id="migration_stripe_processing" />
    ) : success ? (
      <FormattedMessage id="migration_stripe_done" />
    ) : error ? (
      <FormattedMessage id="migration_stripe_error" />
    ) : (
      <FormattedMessage id="migrate_stripe_confirm_execute" />
    );

    if (this.isCompletedConnectedAccount(cAccount)) {
      return (
        <RaisedButton
          primary
          className={classNames('migrate-payment-action', 'migrate', { pending: loading, ismigrated: success, error: error })}
          key="confirm-migration"
          label={labelKey}
          onClick={() => this.handleConfirm()}
        />
      );
    }
  }

  migrateStripe() {
    const {
      sectionClassName,
      createAccountCB,
      refreshLinkCB,
      deleteAccountCB,
      currency,
      connectedAccount,
      subCompConnectedAccount,
      detailCompany,
      detailSubCompany,
      parentId
    } = this.props;

    const keyLabel = this.getKeyBtn();

    const title = (
      <span style={{ textTransform: 'uppercase' }}>
        <FormattedMessage id={keyLabel} />
      </span>
    );
    const bankCountry = parentId ? detailSubCompany.address.country : detailCompany.address.country;
    const email = parentId ? detailSubCompany.email : detailCompany.email;
    const cAcc = parentId ? subCompConnectedAccount : connectedAccount;

    return (
      <section className={sectionClassName + '--migrateStripeBlock stripe-migrate-block '}>
        <div className="panel-handle">
          <FlatButton
            labelPosition="before"
            fullWidth
            className="btn flat-button "
            onClick={() => this.handleClickexpand()}
            label={title}
            labelStyle={{ textTransform: 'initial' }}
            style={{ width: '100%', textAlign: 'left', textTransform: 'none' }}
            icon={
              !this.state.isOpen ? (
                <HardwareKeyboardArrowDown style={{ width: '30px', height: '30px' }} />
              ) : (
                <HardwareKeyboardArrowUp style={{ width: '30px', height: '30px' }} />
              )
            }
          />
        </div>

        {this.state.isOpen && (
          <div className="panel-content">
            <div className="bank-details">
              <AddConnectedAccountForm
                initialValues={{
                  bankCurrency: currency,
                  email,
                  bankCountry
                }}
                currencyOptions={this.currencyOptions}
                connectedAccount={cAcc}
                handleSubmit={createAccountCB}
                supress={deleteAccountCB}
                create={createAccountCB}
                update={refreshLinkCB}
                parentId={parentId}
                migratebtn={this.getButton()}
              />
            </div>
          </div>
        )}
        {!this.state.isOpen && this.getButton(true)}
        {this.migrateStripModal()}
      </section>
    );
  }

  submitForm() {
    const { firstPayback, submitPayback, showSubmit, paymentProvider } = this.props;
    if (!showSubmit) return;

    if (paymentProvider === PAYMENT_PROVIDERS.WORLDPAY) {
      const label = <FormattedMessage id={firstPayback ? 'common_create' : 'common_update'} />;
      return <SubmitForm label={label} submitCallback={submitPayback} />;
    }
  }

  render() {
    const {
      showInfoMessage,
      sectionClassName,
      paymentProvider,
      paymentRules: { stripeMigrate }
    } = this.props;

    if (showInfoMessage)
      return (
        <section style={this.zeroMargin} className={classNames(sectionClassName, 'page-info-block')}>
          <div className="linkh" onClick={this.props.onTabChangeRequest}>
            {showInfoMessage}
          </div>
        </section>
      );

    return (
      <div className="paybackSettings">
        {this.bankDetails()}
        {paymentProvider === PAYMENT_PROVIDERS.WORLDPAY && this.commissionParams()}
        {paymentProvider === PAYMENT_PROVIDERS.WORLDPAY && this.bankTransferParams()}
        {paymentProvider === PAYMENT_PROVIDERS.WORLDPAY && stripeMigrate && this.migrateStripe()}
        {this.submitForm()}
      </div>
    );
  }
}

PaybackSettingsTab.propTypes = {
  onTabChangeRequest: PropTypes.func,
  sectionClassName: PropTypes.string,
  addBankDetailsCallback: PropTypes.any, // function
  submitPayback: PropTypes.any, // function
  companyId: PropTypes.string,
  paybackData: PropTypes.object,
  paymentRules: PropTypes.object,
  bankAccountDetails: PropTypes.string,
  firstPayback: PropTypes.bool,
  paybackFormValues: PropTypes.object,
  showSubmit: PropTypes.bool,
  bankDetailsReadOnly: PropTypes.bool,
  paybackSettingsReadOnly: PropTypes.bool,
  currencies: PropTypes.array,
  currency: PropTypes.string,
  showInfoMessage: PropTypes.any // show info message preventing browsing the form
};

// noinspection JSUnusedGlobalSymbols
PaybackSettingsTab.defaultProps = {
  currency: EUR_CURRENCY,
  sectionClassName: 'companyDetail',
  addBankDetailsCallback: () => '',
  submitPayback: () => '',
  showSubmit: true,
  bankDetailsReadOnly: false,
  paybackSettingsReadOnly: false
};

PaybackSettingsTab.displayName = 'PaybackSettingsTab';

export default connect(state => ({
  userRole: userRoleSelector(state),
  detailCompany: detailedCompanySelector(state),
  detailSubCompany: currentSubCompanySelector(state),
  paymentRules: currentContractPaymentValuesSelector(state),
  connectedAccount: accountStripeCompanySelector(state),
  subCompConnectedAccount: accountStripeSubCompanySelector(state)
}))(PaybackSettingsTab);
