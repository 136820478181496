import { routeActions } from 'react-router-redux';

import { getStore } from '../store/all-store';
import routes from '../constants/routes-constants';
import { URL_ALL } from '../constants/backend-constants';

export function actionCallback(sortById, isDescending, namespace) {
  const state = getStore().getState();
  let newParams;
  let params = {
    sort: {
      property: sortById,
      isDescending: isDescending
    }
  };
  switch (namespace) {
    case 'feedbacks':
    case 'bookings':
    case 'vehicleDamages':
    case 'members':
    case 'backUsers':
    case 'smartcards':
    case 'smartcardsEvents':
    case 'invoices':
      newParams = Object.assign({}, state[namespace].urlParams, params);
      getStore().dispatch(routeActions.push(routes[namespace].path.replace(':search', encodeURIComponent(JSON.stringify(newParams)))));
      break;

    case 'findBooking':
      newParams = Object.assign({}, state.invoiceFindBooking.urlParams, params);
      getStore().dispatch(
        routeActions.push(
          `${routes.addInvoice.path}/${routes.invoiceFindBooking.path.replace(':search', encodeURIComponent(JSON.stringify(newParams)))}`
        )
      );
      break;

    case 'fleet':
      newParams = Object.assign({}, state.vehicles.urlParams, params);
      getStore().dispatch(routeActions.push(routes[namespace].path.replace(':search', encodeURIComponent(JSON.stringify(newParams)))));
      break;

    default:
      break;
  }
}

export function redirectToRoute(route) {
  getStore().dispatch(routeActions.push(route));
}

export function getOrganizationRedirectPath() {
  return routes.organizationBrands.path + '/' + routes.organizationSuperCompanies.path.replace(':brandId', URL_ALL);
}

export function getQuickSightRedirectPath() {
  return routes.quickSight.path + '/' + routes.qsCompletedBooking.path;
}

export function safeRouteChange(route) {
  window.location.hash = route;
}
