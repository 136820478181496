import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { goToSearchLocation, goToDetailsLocation } from '../../../actions/all-actions';
import routes from '../../../constants/routes-constants';
import CompaniesResultsRow from './CompaniesResultsRow';
import { addOpenNewTabEvents, hideButtonHoverMsg } from '../../../utils/utils';

class CompaniesResults extends Component {
  constructor(props) {
    super(props);
    this.onGoToDetail = this.onGoToDetail.bind(this);
    this.handleGoToAll = this.handleGoToAll.bind(this);
  }

  componentWillUnmount() {
    hideButtonHoverMsg();
  }

  onGoToDetail(...props) {
    this.props.goToDetail(...props);
  }

  handleGoToAll(openInNewTab) {
    this.props.goToAll(routes.companies.path, { name: this.props.searchText }, openInNewTab);
  }

  render() {
    const { companiesResults, companiesResultsTotal } = this.props;

    return (
      <div>
        {companiesResults.length > 0 && (
          <div>
            <div className="search-resultTitle">
              <FormattedMessage id="side_menu_section_account_companies" />
              {companiesResultsTotal <= 5 && (
                <div>
                  <FormattedMessage id="common_results" />:{' ' + companiesResultsTotal}
                </div>
              )}
              {companiesResultsTotal > 5 && (
                <a {...addOpenNewTabEvents(this.handleGoToAll)} className="link">
                  <FormattedMessage id="common_show_all" />
                  {' ' + companiesResultsTotal + ' '}
                </a>
              )}
            </div>
            <div className="search-resultsWrap">
              {companiesResults.map(company => {
                return <CompaniesResultsRow key={company.id} company={company} handleGoToDetail={this.onGoToDetail} />;
              })}
            </div>
          </div>
        )}
      </div>
    );
  }
}

CompaniesResults.displayName = 'CompaniesResults';

const mapStateToProps = state => {
  const {
    header: { searchText },
    searchResults: { companiesResults, companiesResultsTotal }
  } = state;

  return {
    companiesResults,
    companiesResultsTotal,
    searchText
  };
};

const mapDispatchToProps = dispatch => {
  return {
    goToDetail(...props) {
      goToDetailsLocation(...props)(dispatch);
    },
    goToAll(...props) {
      goToSearchLocation(...props)(dispatch);
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CompaniesResults);
