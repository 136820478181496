import React, { Component, PropTypes } from 'react';
import BoxedInput from '../BoxedInput/BoxedInput';

import FormRow from '../FormRow/FormRow';
import { FormattedMessage, injectIntl } from 'react-intl';

import autoBind from 'react-autobind';
import FieldErrorMsg from '../FieldErrorMsg/FieldErrorMsg';
import BoxedSelect from '../BoxedSelect/BoxedSelect';
import BoxedToggle from '../BoxedToggle/BoxedToggle';
import { safe, namedCompose, isEmpty, trimFields, isValidEmail } from '../../utils/utils';

import PhoneNumberField from '../PhoneNumberField/PhoneNumberField';
import AddressAutocomplete from '../AddressAutocomplete/AddressAutocomplete';
import pure from 'recompose/pure';

import classnames from 'classnames';
import {
  CUSTOM_FIELD_TYPE_BOOLEAN,
  CUSTOM_FIELD_TYPE_NUMERIC,
  CUSTOM_FIELD_TYPE_PHONE_NUMBER,
  CUSTOM_FIELD_TYPE_TEXT,
  EXTERNAL_INVOICE,
  CUSTOM_FIELD_TYPE_FILE
} from '../../constants/backend-constants';

import { reduxForm } from 'redux-form';

import {
  createValidator,
  notEmpty,
  email,
  length,
  checkPhoneNumber,
  createAsyncValidator,
  memberEmailAvailable,
  mergeValidators
} from '../../validation/all-validation';

import config from '../../constants/config-constants';

import {
  registerCustomFieldsSelector,
  selectFieldIdsByPosition,
  selectCustomFieldsNames,
  bundleSelector,
  localeSelector
} from '../../selectors/all-selectors';
import { customFieldIsVisible, selectCurrentLabel } from '../../utils/component';
import { VALIDATION_EMAIL_INVALID } from '../../constants/errors-constants';
import _cloneDeep from 'lodash/cloneDeep';

function getFieldId(field) {
  const { companyCustomField } = field || {};
  const { id } = companyCustomField || {};
  return id;
}

function setRangeErrors(field, values, errors) {
  const fieldId = getFieldId(field);
  const fieldValue = values[fieldId];
  const textLength = fieldValue.length;

  const { companyCustomField } = field || {};
  const { max, min, fieldType } = companyCustomField || {};

  if (fieldType === CUSTOM_FIELD_TYPE_TEXT) {
    if (textLength < min) {
      errors[fieldId] = { type: 'validation_length_too_short', data: { count: min } };
    } else if (textLength > max) {
      errors[fieldId] = { type: 'validation_length_too_long', data: { count: max } };
    }
  } else if (fieldType === CUSTOM_FIELD_TYPE_NUMERIC) {
    const number = Number(fieldValue);

    if (number < min) {
      errors[fieldId] = { type: 'validation_min_value', data: { value: min } };
    } else if (number > max) {
      errors[fieldId] = { type: 'validation_max_value', data: { value: max } };
    }
  }
}

const validate = (values, props) => {
  const { customFields, fieldIdsByPosition, sendEmailsToManager } = props;
  const errors = {};

  values = trimFields(_cloneDeep(values));

  customFields.forEach(field => {
    const { companyCustomField } = field || {};
    const { fieldType } = companyCustomField || {};

    if (fieldType !== CUSTOM_FIELD_TYPE_FILE) {
      const visible = customFieldIsVisible(field, values, fieldIdsByPosition);

      if (visible) {
        const fieldId = getFieldId(field);
        const fieldValue = values[fieldId];
        const emptyField = isEmpty(fieldValue);

        if (!emptyField) setRangeErrors(field, values, errors);
      }
    }
  });

  if (sendEmailsToManager && values.managerEmail) {
    if (!isValidEmail(values.managerEmail)) {
      errors['managerEmail'] = {
        value: values.managerEmail,
        type: VALIDATION_EMAIL_INVALID
      };
    }
  }

  return errors;
};

class PreSubscribeForm extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
  }

  generateLocaleList() {
    return config.theme.availableLanguages.map(locale => {
      return {
        value: locale,
        label: this.props.intl.formatMessage({ id: 'languageName_' + locale })
      };
    });
  }

  addressFields() {
    const {
      fields: { address, postalCode, city, country }
    } = this.props;

    return (
      <div>
        <h4>
          <FormattedMessage id="common_address" />
        </h4>

        <FormRow customClass="addMemberForm_row">
          <label className="boxedInput_label">
            <span className="boxedInput_labelText">
              <FormattedMessage id="companyForm_address_label" />
            </span>
            <AddressAutocomplete field={address} className="addMember_AddressAutocomplete" form="addMemberForm" spreadExternalFields />
            <FieldErrorMsg field={address} />
          </label>
          <BoxedInput
            formRowItemKey="addMember_postalCode"
            type="text"
            id="postalCode"
            labelKey="common_autocomplete_postal_code"
            customClass="addMember_boxedInput"
            field={postalCode}
          >
            <FieldErrorMsg field={postalCode} />
          </BoxedInput>
          <BoxedInput
            formRowItemKey="addMember_city"
            type="text"
            id="city"
            labelKey="common_autocomplete_city"
            customClass="addMember_boxedInput"
            field={city}
          >
            <FieldErrorMsg field={city} />
          </BoxedInput>
          <label className="boxedInput_label">
            <span className="boxedInput_labelText">
              <FormattedMessage id="common_autocomplete_country" />
            </span>
            <AddressAutocomplete field={country} searchType={['country']} translateCountryCode />
            <FieldErrorMsg field={country} />
          </label>
        </FormRow>
      </div>
    );
  }

  dirvingLienceExpires() {
    const {
      fields: { hasExpirationDate, expirationDate }
    } = this.props;

    if (!safe(() => hasExpirationDate.value)) return;

    return (
      <BoxedInput
        formRowItemKey="addMember_DVexpirationDate"
        type="date"
        id="expirationDate"
        customClass="addMember_boxedInputFlex"
        field={expirationDate}
      />
    );
  }

  drivingLicenceFields() {
    const {
      drivingLicenceRequired,
      fields: { licenceNumber, deliveranceDate, cityDeliverance, hasExpirationDate }
    } = this.props;

    if (drivingLicenceRequired)
      return (
        <div>
          <h4>
            <FormattedMessage id="members_detail_driver_subtitle" />
          </h4>

          <FormRow customClass="addMemberForm_row">
            <BoxedInput
              formRowItemKey="addMemberForm_drivingLicenceNumber"
              type="text"
              id="licenceNumber"
              labelKey="members_detail_driver_licence_number"
              customClass="addMember_boxedInput"
              field={licenceNumber}
            >
              <FieldErrorMsg field={licenceNumber} />
            </BoxedInput>
            <BoxedInput
              formRowItemKey="addMemberForm_deliverDate"
              type="date"
              id="deliveranceDate"
              labelKey="members_detail_driver_deliverance_date"
              customClass="addMember_boxedInput"
              field={deliveranceDate}
            >
              <FieldErrorMsg field={deliveranceDate} />
            </BoxedInput>
            <BoxedInput
              formRowItemKey="addMemberForm_cityDeliver"
              type="text"
              id="cityDeliverance"
              labelKey="members_detail_driver_city_delivrance"
              customClass="addMember_boxedInput"
              field={cityDeliverance}
            >
              <FieldErrorMsg field={cityDeliverance} />
            </BoxedInput>
            <div className="container-toggle">
              <div className="label boxedInput_labelText">
                <FormattedMessage id="members_detail_driver_expiration_date" />
              </div>
              <div className="inline-field-toggled">
                <BoxedToggle field={hasExpirationDate} className="override-toggle" />
                {this.dirvingLienceExpires()}
              </div>
            </div>
          </FormRow>
        </div>
      );
  }

  managerEmailField() {
    const {
      fields: { managerEmail }
    } = this.props;

    return (
      <FormRow>
        <BoxedInput formRowItemKey="searchVehicle_from_pickupDate" type="text" labelKey="members_detail_manager_email" field={managerEmail}>
          <FieldErrorMsg field={managerEmail} />
        </BoxedInput>
      </FormRow>
    );
  }

  specialFields() {
    const { sendEmailsToManager } = this.props;
    return <div>{sendEmailsToManager && this.managerEmailField()}</div>;
  }

  additionalInfoRow() {
    const { customFields, sendEmailsToManager } = this.props;
    if (customFields.length || sendEmailsToManager)
      return (
        <div>
          <h4>
            <FormattedMessage id="add-member-additional-infos" />
          </h4>
          {this.specialFields()}
          {this.customFieldsRow()}
        </div>
      );
  }

  selectFieldType(field) {
    const { fields } = this.props;
    const { companyCustomField } = field || {};
    const { fieldType, id } = companyCustomField || {};
    const label = selectCurrentLabel.call(this, companyCustomField);

    if (fieldType === CUSTOM_FIELD_TYPE_NUMERIC) {
      return (
        <BoxedInput key={id} type="number" field={fields[id]} label={label}>
          <FieldErrorMsg field={fields[id]} />
        </BoxedInput>
      );
    } else if (fieldType === CUSTOM_FIELD_TYPE_TEXT || fieldType === CUSTOM_FIELD_TYPE_PHONE_NUMBER) {
      return (
        <BoxedInput key={id} label={label} field={fields[id]}>
          <FieldErrorMsg field={fields[id]} />
        </BoxedInput>
      );
    } else if (fieldType === CUSTOM_FIELD_TYPE_BOOLEAN) {
      return (
        <BoxedToggle key={id} label={label} field={fields[id]}>
          <FieldErrorMsg field={fields[id]} />
        </BoxedToggle>
      );
    }
  }

  customField(field) {
    const { values, fieldIdsByPosition } = this.props;

    if (customFieldIsVisible(field, values, fieldIdsByPosition)) {
      return this.selectFieldType(field);
    }
  }

  customFieldsRow() {
    const { customFields } = this.props;

    if (customFields.length) {
      return <div className="custom-fields-wrapper">{customFields.map(field => this.customField(field))}</div>;
    }
  }

  basicFields() {
    const {
      fields: { login, phoneNumber, locale, firstName, lastName, civility, birthDate }
    } = this.props;

    const localeList = this.generateLocaleList(config.theme.availableLanguages);

    return (
      <div>
        <h4>
          <FormattedMessage id="add-member-identity" />
        </h4>
        <FormRow customClass="addMemberForm_row">
          <BoxedSelect
            formRowItemKey="addMember_civiilty"
            id="civility"
            labelKey="members_detail_civility"
            options={[
              { labelKey: 'gender-mr', value: 'MR' },
              { labelKey: 'gender-mrs', value: 'MRS' }
            ]}
            customClass="addMember_fullWidthBoxedSelectWrapper"
            field={civility}
          >
            <FieldErrorMsg field={civility} />
          </BoxedSelect>
          <BoxedInput
            formRowItemKey="addMemberForm_firstName"
            type="text"
            id="firstName"
            labelKey="common_first_name"
            customClass="addMemberForm_boxedInput"
            field={firstName}
            mandatory
          >
            <FieldErrorMsg field={firstName} />
          </BoxedInput>

          <BoxedInput
            formRowItemKey="addMemberForm_lastName"
            type="text"
            id="lastName"
            labelKey="common_last_name"
            customClass="addMemberForm_boxedInput"
            field={lastName}
            mandatory
          >
            <FieldErrorMsg field={lastName} />
          </BoxedInput>

          <BoxedSelect
            formRowItemKey="addMemberForm_locale"
            id="locale"
            customClass="addMemberForm_boxedSelectWrapper"
            blockInnerCustomClass="boxedSelectBlock_locale"
            labelKey="common_language"
            options={localeList}
            noEmptyValue
            initialValue={this.props.locale}
            field={locale}
            mandatory
          >
            <FieldErrorMsg field={locale} />
          </BoxedSelect>
        </FormRow>
        <FormRow customClass="addMemberForm_row">
          <BoxedInput
            formRowItemKey="addMemberForm_email"
            type="text"
            id="email"
            labelKey="common_email_address"
            customClass="addMemberForm_boxedInput"
            field={login}
            maxlength="255"
            mandatory
            autoFocus
          >
            <FieldErrorMsg field={login} />
          </BoxedInput>

          <PhoneNumberField
            id="phoneNumber"
            className="boxedInputWrapper"
            label={<FormattedMessage id="hotlineForm_phone_number_label" />}
            field={phoneNumber}
            mandatory
          />
          <BoxedInput
            formRowItemKey="searchVehicle_from_pickupDate"
            skinType="date"
            type="date"
            customClass="test"
            labelKey="members_detail_birth_date"
            field={birthDate}
          >
            <FieldErrorMsg field={birthDate} />
          </BoxedInput>
        </FormRow>
      </div>
    );
  }

  render() {
    const { handleSubmit } = this.props;
    return (
      <form onSubmit={handleSubmit(this.props.onCallback)} className={classnames('addMemberForm', this.props.className)} noValidate>
        <div className={this.props.actions ? 'backUserForm_container' : ''}>
          {this.props.actions && (
            <h3 className="addMemberForm_title">
              <FormattedMessage id="add_member_button" />
            </h3>
          )}

          {this.basicFields()}
          {this.addressFields()}
          {this.drivingLicenceFields()}
          {this.additionalInfoRow()}

          <div className="addMemberForm_actions">
            {this.props.actions && (
              <a href={this.props.onAbort} className="ekButton ekButton--reverse addMemberForm_actionsLink">
                <FormattedMessage id="common_cancel" />
              </a>
            )}
            <input className="ekButton" type="submit" value={this.props.intl.formatMessage({ id: 'common_save' })} />
            {this.props.actions && (
              <input
                type="button"
                value={this.props.intl.formatMessage({ id: 'common_save_and_add_another_one' })}
                onClick={handleSubmit(this.props.onCallbackRetry)}
                className="ekButton"
              />
            )}
          </div>
        </div>
      </form>
    );
  }
}

PreSubscribeForm.displayName = 'PreSubscribeForm';

PreSubscribeForm.propTypes = {
  fields: PropTypes.object,
  handleSubmit: PropTypes.func,
  onCallback: PropTypes.func,
  onAbort: PropTypes.string
};

const staticFields = [
  'login',
  'phoneNumber',
  'locale',
  'firstName',
  'civility',
  'lastName',
  'birthDate',
  'address',
  'postalCode',
  'city',
  'country',
  'licenceNumber',
  'deliveranceDate',
  'cityDeliverance',
  'expirationDate',
  'hasExpirationDate',
  'managerEmail'
];

export default namedCompose(
  injectIntl,
  reduxForm(
    {
      form: 'addMemberForm',
      validate: mergeValidators([
        createValidator({
          login: [notEmpty(), length({ max: 255 }), email()],
          phoneNumber: [checkPhoneNumber()],
          firstName: [notEmpty(), length({ max: 255 })],
          lastName: [notEmpty(), length({ max: 255 })]
        }),
        validate
      ]),
      asyncBlurFields: ['login'],
      asyncValidate: createAsyncValidator({
        login: [memberEmailAvailable()]
      })
    },
    state => {
      const customFields = registerCustomFieldsSelector(state);
      const customFieldsNames = selectCustomFieldsNames(customFields);
      const fieldIdsByPosition = selectFieldIdsByPosition(customFields);

      // 'bundle' and 'locale' required for 'selectCurrentLabel'
      return {
        bundle: bundleSelector(state),
        locale: localeSelector(state),
        fields: [...staticFields, ...customFieldsNames],
        fieldIdsByPosition,
        customFields
      };
    }
  ),
  pure
)(PreSubscribeForm);
