import * as types from '../constants/actionTypes-constants';
import { invoicePreviewDefault } from '../constants/generic-constants';
import { safe } from '../utils/utils';
import { PAYMENT_PROVIDERS } from '../constants/backend-constants';

function addContractDefaults(contract) {
  if (!contract) {
    contract = {};
    contract.interfaceConfigDto = { oneWayDisplay: true, replacementVehicleDisplay: false, returnTripDisplay: true };
    contract.businessCarSharing = true;
  }
  return contract;
}

export default function companies(
  state = {
    paginatedResults: [],
    headerList: [],
    headerListLoading: false,
    currentCompany: null, // must be NULL
    currentCompanyDetails: null, // must be NULL
    detailCompany: {},
    detailInvoiceParams: {},
    currencies: [],
    invoiceVatRateParam: {},
    connectedAccount: null,
    currentTabIndex: 0,
    currentContract: null,
    companyAuthorizedApplications: [],
    companyUsedRoutes: [],
    urlParams: null,
    loadingList: true,
    errorList: false,
    customizations: null,
    invoicePreview: invoicePreviewDefault,
    pdfPreview: false,
    urlPdf: '',
    inversFleets: [],
    legacyPaymentDetails: null,
    paybackData: null,
    paybackFormValues: null,
    bankAccountDetails: null,
    deleteTermsOfSubscriptionFile: null,
    deletePrivacyPolicyFile: null
  },
  action
) {
  switch (action.type) {
    case types.COMPANIES_GET_LIST_REQUEST: {
      return { ...state, loadingList: true };
    }

    case types.COMPANIES_CREATE_COMPANY_REQUEST:
    case types.COMPANIES_UPDATE_COMPANY_REQUEST:
    case types.COMPANIES_GET_CURRENT_INVOICE_PARAMS_REQUEST:
    case types.COMPANIES_GET_INVOICE_VAT_RATE_PARAM_REQUEST:
    case types.COMPANIES_UPDATE_EMAIL_SETUP_ERROR:
    case types.COMPANIES_DELETE_EMAIL_SETUP_REQUEST:
    case types.COMPANIES_DELETE_EMAIL_SETUP_ERROR:
    case types.COMPANIES_DELETE_EMAIL_SETUP_SUCCESS:
    case types.COMPANIES_PULL_EMAIL_SETUP_REQUEST:
    case types.COMPANIES_PULL_EMAIL_SETUP_ERROR:
    case types.COMPANIES_UPDATE_EMAIL_SETUP_REQUEST:
    case types.COMPANIES_CURRENCIES_LIST_REQUEST:
    case types.COMPANIES_GET_CONNECTED_ACCOUNT_REQUEST:

    case types.COMPANIES_PAYMENT_DETAILS_REQUEST:
      return Object.assign({}, state, {
        legacyPaymentDetails: null
      });

    case types.COMPANIES_GET_CONNECTED_ACCOUNT_SUCCESS:
      return Object.assign({}, state, {
        connectedAccount: action
      });
    case types.COMPANIES_GET_CONNECTED_ACCOUNT_ERROR:
      return Object.assign({}, state, {
        connectedAccount: action
      });

    case types.COMPANIES_CREATE_CONNECTED_ACCOUNT_REQUEST:
      return Object.assign({}, state, {
        connectedAccount: action
      });
    case types.COMPANIES_CREATE_CONNECTED_ACCOUNT_SUCCESS:
      return Object.assign({}, state, {
        connectedAccount: action
      });
    case types.COMPANIES_CREATE_CONNECTED_ACCOUNT_ERROR:
      return Object.assign({}, state, {
        connectedAccount: action
      });

    case types.COMPANIES_PAYMENT_DETAILS_SUCCESS:
      return Object.assign({}, state, {
        legacyPaymentDetails: action.companyPaymentDetails
      });

    case types.COMPANIES_SET_CURRENT_INDEX:
      return Object.assign({}, state, {
        currentTabIndex: action.index
      });

    case types.COMPANIES_SORT_DIRECTION_SET:
      return Object.assign({}, state, {
        sortIsDescending: action.isDescending
      });

    case types.COMPANIES_GET_HEADER_LIST_REQUEST: {
      return { ...state, headerListLoading: true };
    }

    case types.COMPANIES_GET_HEADER_LIST_SUCCESS: {
      return { ...state, headerList: action.list, headerListLoading: false };
    }

    case types.COMPANIES_CLEAR_HEADER_LIST_DATA: {
      return { ...state, headerList: [], headerListLoading: false };
    }

    case types.COMPANIES_GET_LIST_WITH_SEARCH_SUCCESS: {
      return { ...state, loadingList: false, paginatedResults: action.paginatedObj, urlParams: action.urlParams };
    }

    case types.COMPANIES_GET_LIST_WITH_SEARCH_ERROR: {
      return { ...state, loadingList: false, paginatedResults: [], urlParams: action.urlParams };
    }

    case types.COMPANIES_SET_CURRENT_COMPANY:
      return {
        ...state,
        currentCompany: action.company
      };

    case types.USER_LOGOUT:
      return {
        ...state,
        paginatedResults: [],
        headerList: [],
        headerListLoading: false,
        currentCompany: null
      };

    case types.COMPANIES_SET_CURRENT_COMPANY_DETAILS: {
      return { ...state, currentCompanyDetails: action.companyDetails };
    }

    case types.COMPANIES_CREATE_COMPANY_MODAL_SUCCESS: {
      const { company } = action;
      const { paginatedResults } = state;

      const { results = [] } = paginatedResults || {};
      const newCompany = {
        id: company.id,
        name: company.name,
        email: company.email,
        phoneNumber: safe(() => company.phoneNumber.countryCode + company.phoneNumber.nationalNumber)
      };
      return {
        ...state,
        paginatedResults: { ...paginatedResults, results: [{ ...newCompany }, ...results] }
      };
    }

    case types.COMPANIES_GET_DETAIL_COMPANY_SUCCESS:
      return Object.assign({}, state, {
        detailCompany: action.company,
        companyAuthorizedApplications: action.company.authorizedApplications
      });

    case types.COMPANIES_GET_DETAIL_COMPANY_CLEAR:
      return Object.assign({}, state, {
        detailCompany: {}
      });

    case types.COMPANIES_GET_CURRENT_INVOICE_PARAMS_SUCCESS:
    case types.COMPANIES_SET_CURRENT_INVOICE_PARAMS_SUCCESS:
      return Object.assign({}, state, {
        detailInvoiceParams: action.invoiceParams
      });

    case types.COMPANIES_GET_CURRENT_INVOICE_PARAMS_NO_CONTENT:
      return Object.assign({}, state, {
        detailInvoiceParams: {}
      });

    case types.COMPANIES_GET_INVOICE_VAT_RATE_PARAM_SUCCESS:
      return Object.assign({}, state, {
        invoiceVatRateParam: action.invoiceVatRateParam
      });

    case types.COMPANIES_GET_INVOICE_VAT_RATE_PARAM_NO_CONTENT:
      return Object.assign({}, state, {
        invoiceVatRateParam: {}
      });

    case types.COMPANIES_UPDATE_COMPANY_SUCCESS: {
      const { company } = action;
      const { paginatedResults } = state;
      const { results = [] } = paginatedResults || {};

      safe(() => (results.find(item => item.id === company.id).name = company.name));

      return {
        ...state,
        detailCompany: company,
        paginatedResults: { ...paginatedResults },
        companyAuthorizedApplications: action.company.authorizedApplications
      };
    }

    case types.COMPANIES_FILE_IMPORTED_REMOVE: {
      if (action.field === 'privacyPolicy') {
        return {
          ...state,
          deletePrivacyPolicyFile: true
        };
      }
      if (action.field === 'termsOfSubscription') {
        return {
          ...state,
          deleteTermsOfSubscriptionFile: true
        };
      }
      return state;
    }

    case types.COMPANIES_GET_CURRENT_CONTRACT_SUCCESS: {
      return { ...state, currentContract: addContractDefaults(action.contract) };
    }

    case types.COMPANIES_CLEAR_CURRENT_CONTRACT: {
      return { ...state, currentContract: null };
    }

    case types.COMPANIES_AUTHORIZE_APPLICATION_LIST_SUCCESS:
    case types.COMPANIES_UNAUTHORIZE_APPLICATION_LIST_SUCCESS:
      return Object.assign({}, state, {
        companyAuthorizedApplications: action.applications
      });

    case types.COMPANIES_GET_EMAIL_SETUP_REQUEST:
    case types.COMPANIES_GET_EMAIL_SETUP_ERROR:
      return {
        ...state,
        customizations: null
      };

    case types.COMPANIES_GET_EMAIL_SETUP_SUCCESS:
      return {
        ...state,
        customizations: action.data
      };

    case types.COMPANIES_UPDATE_EMAIL_SETUP_SUCCESS:
      return {
        ...state,
        customizations: action.data
      };

    case types.COMPANIES_PREVIEW_INVOICE_REQUEST:
    case types.COMPANIES_PREVIEW_INVOICE_ERROR:
    case types.COMPANIES_PREVIEW_INVOICE_CLEAR:
      return {
        ...state,
        invoicePreview: invoicePreviewDefault
      };

    case types.COMPANIES_PREVIEW_INVOICE_SUCCESS:
      return {
        ...state,
        invoicePreview: {
          blob: action.blob,
          base64: action.base64
        }
      };

    case types.COMPANY_GET_PAYBACK_CONFIG_SUCCESS:
      return {
        ...state,
        paybackData: action.data
      };

    case types.COMPANY_GET_PAYBACK_CONFIG_ERROR:
      return {
        ...state,
        paybackData: null
      };

    case types.COMPANY_CLEAR_TEMPORARY_BANK_DETAILS:
      return { ...state, bankAccountDetails: null };

    case types.COMPANY_CLEAR_PAYBACK_SETTINGS_DATA:
      return {
        ...state,
        paybackData: null,
        paybackFormValues: null,
        bankAccountDetails: null,
        legacyPaymentDetails: null,
        connectedAccount: null
      };

    case types.COMPANIES_DRIVING_AUTHORISATION_PDF_PREVIEW_TOGGLE:
      return {
        ...state,
        pdfPreview: !state.pdfPreview
      };

    case types.COMPANIES_CURRENCIES_LIST_SUCCESS:
      return {
        ...state,
        currencies: action.data
      };

    case types.COMPANY_SAVE_PAYBACK_REDIRECT_DATA:
      return {
        ...state,
        currentTabIndex: action.currentTabIndex || state.currentTabIndex,
        paybackFormValues: action.values,
        bankAccountDetails: action.bankAccountDetails
      };

    case types.COMPANY_GET_INVERS_FLEET_SUCCESS: {
      return Object.assign({}, state, {
        inversFleets: action.data
      });
    }

    case types.COMPANY_GET_INVERS_FLEET_ERROR:
      return Object.assign({}, state, {
        inversFleets: []
      });

    case types.COMPANY_PAYMENT_PROVIDER_STRIPE_MIGRATION:
      const detailCompany = state.detailCompany;
      return {
        ...state,
        detailCompany: {
          ...detailCompany,
          paymentProvider: PAYMENT_PROVIDERS.STRIPE
        }
      };

    default:
      return state;
  }
}
