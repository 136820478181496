import i18n from './i18n-reducers';
import flashMessage from './flashMessage-reducers';
import api from './api-reducers';
import user from './user-reducers';
import header from '../components/Header/Header.reducers';
import searchResults from '../components/SearchResults/SearchResults.reducers';
import advancedList from '../components/AdvancedList/AdvancedList.reducers';
import vouchers from '../containers/Voucher/Voucher.reducers';
import bankouts from './bankout-reducers';
import vehiclePlanning from '../containers/VehiclePlanning/VehiclePlanning.reducers';
import sideMenu from './sideMenu-reducers';
import vehicles from './vehicles-reducers';
import vehicleDamages from './vehicleDamages-reducers';
import backUsers from '../containers/BackUsers/backUsers-reducers';
import members from './members-reducers';
import memberTypes from '../containers/MemberTypes/memberTypes-reducers';
import bookings from './bookings-reducers';
import booking from './booking-reducers';
import bookingFindMember from './booking-find-member-reducers';
import bookingFindVehicle from './booking-find-vehicle-reducer';
import companies from './companies-reducers';
import sites from './sites-reducers';
import pricing from './pricing-reducers';
import parkings from './parkings-reducers';
import colors from './colors-reducers';
import brands from './brands-reducers';
import categories from './categories-reducers';
import models from './models-reducers';
import versions from './versions-reducers';
import feedbacks from './feedbacks-reducers';
import invoices from './invoices-reducers';
import dashboard from './dashboard-reducers';
import applications from './applications-reducer';
import page from './page-reducer';
import configuration from './configuration-reducers';
import hotlines from './hotlines-reducers';
import subCompanies from './subCompanies-reducers';
import customFields from './customFields-reducers';
import smartcards from './smartcards-reducers';
import smartcardsEvents from './smartcardsEvents-reducers';
import invoiceFindBooking from './invoice-find-booking-reducers';
import exports from './exports-reducers';
import vehicleStatuses from './vehicleStatuses-reducers';
import appBrands from './appBrands-reducers';

export default {
  advancedList,
  api,
  appBrands,
  applications,
  backUsers,
  bankouts,
  booking,
  bookingFindMember,
  bookingFindVehicle,
  bookings,
  brands,
  categories,
  colors,
  companies,
  configuration,
  customFields,
  dashboard,
  exports,
  feedbacks,
  flashMessage,
  header,
  hotlines,
  i18n,
  invoiceFindBooking,
  invoices,
  members,
  memberTypes,
  models,
  page,
  parkings,
  pricing,
  searchResults,
  sideMenu,
  sites,
  smartcards,
  smartcardsEvents,
  subCompanies,
  user,
  vehicleDamages,
  vehiclePlanning,
  vehicles,
  vehicleStatuses,
  versions,
  vouchers
};
