import React from 'react';

const Loader = ({ props }) => {
  return (
    <div className="advancedList_loader">
      <div className="lds-rolling">
        <div />
      </div>
    </div>
  );
};

Loader.displayName = 'Loader';

export default Loader;
