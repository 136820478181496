import { notEmpty, notEmptyArrayProps, stopValidationIf, validId } from '../../validation/sync-validation';
import {
  checkRole,
  subCompanyListRules,
  subCompanySelectRules,
  superCompanyListRules,
  superCompanySelectRules
} from '../../constants/backuser-role-rules';
import { BACKUSER_ROLE_CALL_CENTER_OPERATOR } from '../../constants/backend-constants';

export const backuserValidators = {
  userRole: [notEmpty()],
  companyId: [
    stopValidationIf({
      condition: props => {
        const { userRole } = props.form;
        const { value, initialValue } = userRole || {};
        const role = value || initialValue;

        if (role) return !checkRole(superCompanySelectRules, role);
      }
    }),
    validId()
  ],
  subCompanyId: [
    stopValidationIf({
      condition: props => {
        const { userRole } = props.form;
        const { value, initialValue } = userRole || {};
        const role = value || initialValue;

        if (role) return !checkRole(subCompanySelectRules, role);
      }
    }),
    validId()
  ],
  selectedCompaniesIds: [
    stopValidationIf({
      condition: props => {
        const { userRole } = props.form;
        const { value, initialValue } = userRole || {};
        const role = value || initialValue;

        if (role === BACKUSER_ROLE_CALL_CENTER_OPERATOR) return true;
        if (role) return !checkRole(superCompanyListRules, role) || props.selectedCompanies.length;
      }
    }),
    notEmptyArrayProps('selectedCompanies')
  ],
  selectedSubCompaniesIds: [
    stopValidationIf({
      condition: props => {
        const { userRole } = props.form;
        const { value, initialValue } = userRole || {};
        const role = value || initialValue;

        if (role) return !checkRole(subCompanyListRules, role) || props.selectedSubCompanies.length;
      }
    }),
    notEmptyArrayProps('selectedSubCompanies')
  ]
};
