import _get from 'lodash/get';

import * as types from '../constants/actionTypes-constants';
import callApi from './api-actions';
import { safe, setApiCompanyIds } from '../utils/utils';
import { apiParams } from '../constants/api-params-constants';
import { headerLastLevelCompanyIdSelector } from '../selectors/all-selectors';

export function getLastBookings() {
  return (dispatch, getState) => {
    const state = getState();
    const lastCompanyId = headerLastLevelCompanyIdSelector(state);

    const paramsObj = {
      page: {
        number: 1,
        size: 1
      },
      ...apiParams.lastBookings
    };

    setApiCompanyIds(paramsObj, state);

    return dispatch(callApi('bookings', 'getList', { params: paramsObj })).then(data => {
      dispatch({
        type: types.DASHBOARD_GET_LAST_BOOKINGS_SUCCESS,
        lastBookings: _get(data, 'metadata.paginationInfo.totalResults'),
        lastCompanyId
      });
    });
  };
}

export function getDelayedBookings() {
  return (dispatch, getState) => {
    const state = getState();
    const lastCompanyId = headerLastLevelCompanyIdSelector(state);

    let paramsObj = {
      page: {
        number: 1,
        size: 1
      },
      ...apiParams.delayedBookings
    };

    setApiCompanyIds(paramsObj, state);

    return dispatch(callApi('bookings', 'getList', { params: paramsObj })).then(function(data) {
      dispatch({
        type: types.DASHBOARD_GET_DELAYED_BOOKINGS_SUCCESS,
        delayedBookings: _get(data, 'metadata.paginationInfo.totalResults'),
        lastCompanyId
      });
    });
  };
}

export function getNonValidatedMembers() {
  return (dispatch, getState) => {
    const state = getState();
    const lastCompanyId = headerLastLevelCompanyIdSelector(state);

    let paramsObj = {
      page: {
        number: 1,
        size: 1
      },
      ...apiParams.newMembers
    };

    setApiCompanyIds(paramsObj, state);

    return dispatch(callApi('members', 'getList', paramsObj)).then(function(data) {
      dispatch({
        type: types.DASHBOARD_GET_NON_VALIDATED_USER_SUCCESS,
        nonValidatedMembers: _get(data, 'metadata.paginationInfo.totalResults'),
        lastCompanyId
      });
    });
  };
}

export function getUnmanagedDamages() {
  return (dispatch, getState) => {
    const state = getState();
    const lastCompanyId = headerLastLevelCompanyIdSelector(state);

    let paramsObj = {
      page: {
        number: 1,
        size: 1
      },
      ...apiParams.unmanagedDamages
    };

    setApiCompanyIds(paramsObj, state);

    return dispatch(callApi('vehicleDamages', 'getList', { params: paramsObj })).then(function(data) {
      dispatch({
        type: types.DASHBOARD_GET_UNMANAGED_DAMAGES_SUCCESS,
        unmanagedDamages: _get(data, 'metadata.paginationInfo.totalResults'),
        lastCompanyId
      });
    });
  };
}

export function getCleanlinessProblems() {
  return (dispatch, getState) => {
    const state = getState();
    const lastCompanyId = headerLastLevelCompanyIdSelector(state);

    let paramsObj = {
      page: {
        number: 1,
        size: 1
      },
      ...apiParams.cleanlinessProblems
    };

    setApiCompanyIds(paramsObj, state);

    return dispatch(callApi('vehicles', 'getFleet', { params: paramsObj })).then(function(data) {
      dispatch({
        type: types.DASHBOARD_GET_CLEANLINESS_PROBLEMS_SUCCESS,
        cleanlinessProblems: _get(data, 'metadata.paginationInfo.totalResults'),
        lastCompanyId
      });
    });
  };
}

export function getFailedBookings() {
  return (dispatch, getState) => {
    const state = getState();
    const lastCompanyId = headerLastLevelCompanyIdSelector(state);

    let paramsObj = {
      page: {
        number: 1,
        size: 1
      },
      ...apiParams.failedBookings
    };

    setApiCompanyIds(paramsObj, state);

    return dispatch(callApi('bookings', 'getList', { params: paramsObj })).then(function(data) {
      dispatch({
        type: types.DASHBOARD_GET_FAILED_BOOKINGS_SUCCESS,
        failedBookings: _get(data, 'metadata.paginationInfo.totalResults'),
        lastCompanyId
      });
    });
  };
}

export function getExpediteMembers() {
  return (dispatch, getState) => {
    const state = getState();
    const lastCompanyId = headerLastLevelCompanyIdSelector(state);

    let paramsObj = {
      page: {
        number: 1,
        size: 1
      },
      ...apiParams.expediteReview
    };

    setApiCompanyIds(paramsObj, state);

    return dispatch(callApi('members', 'getList', paramsObj)).then(function(data) {
      dispatch({
        type: types.DASHBOARD_GET_EXPEDITE_MEMBERS_SUCCESS,
        expediteMembers: _get(data, 'metadata.paginationInfo.totalResults'),
        lastCompanyId
      });
    });
  };
}

export function getFailedRrsBookings() {
  return (dispatch, getState) => {
    const state = getState();
    const lastCompanyId = headerLastLevelCompanyIdSelector(state);

    let paramsObj = {
      page: {
        number: 1,
        size: 1
      },
      ...apiParams.rrsFailedBookings
    };

    setApiCompanyIds(paramsObj, state);

    return dispatch(callApi('bookings', 'getList', { params: paramsObj })).then(function(data) {
      dispatch({
        type: types.DASHBOARD_GET_FAILED_RRS_BOOKINGS_SUCCESS,
        rrsFailedBookings: _get(data, 'metadata.paginationInfo.totalResults'),
        lastCompanyId
      });
    });
  };
}

export function getlowAccessoryBatVehicles() {
  return (dispatch, getState) => {
    const state = getState();
    const lastCompanyId = headerLastLevelCompanyIdSelector(state);

    let paramsObj = {
      ...apiParams.default,
      ...apiParams.lowAccessoryBatVehicles
    };

    setApiCompanyIds(paramsObj, state);

    return dispatch(callApi('vehicles', 'getFleet', { params: paramsObj })).then(function(data) {
      dispatch({
        type: types.DASHBOARD_GET_LOW_ACCESSORY_BATTERIE_VEHICLES_SUCCESS,
        lowAccessoryBatVehicles: _get(data, 'metadata.paginationInfo.totalResults'),
        lastCompanyId
      });
    });
  };
}

export function getNonOperatingVehicles() {
  return (dispatch, getState) => {
    const state = getState();
    const lastCompanyId = headerLastLevelCompanyIdSelector(state);

    let paramsObj = {
      ...apiParams.default,
      ...apiParams.nonOperatingVehicles
    };

    setApiCompanyIds(paramsObj, state);

    return dispatch(callApi('vehicles', 'getFleet', { params: paramsObj })).then(data => {
      dispatch({
        type: types.DASHBOARD_GET_NON_OPERATING_VEHICLES_SUCCESS,
        nonOperatingVehicles: safe(() => data.metadata.paginationInfo.totalResults),
        lastCompanyId
      });
    });
  };
}

export function getLowFuelLevelVehicles() {
  return (dispatch, getState) => {
    const state = getState();
    const lastCompanyId = headerLastLevelCompanyIdSelector(state);

    let paramsObj = {
      ...apiParams.default,
      ...apiParams.lowFuelLevel
    };

    setApiCompanyIds(paramsObj, state);

    return dispatch(callApi('vehicles', 'getFleet', { params: paramsObj })).then(data => {
      dispatch({
        type: types.DASHBOARD_GET_LOW_FUEL_CHARGE_LEVEL_SUCCESS,
        lowFuelLevel: safe(() => data.metadata.paginationInfo.totalResults),
        lastCompanyId
      });
    });
  };
}

export function dashboardResetItemsCount() {
  return {
    type: types.DASHBOARD_RESET_ITEMS_COUNT
  };
}
