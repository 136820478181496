import React, { Component, PropTypes } from 'react';
import ListDetails from '../../../../components/_v2/ColumnList/Details';
import BoxedInput from '../../../../components/BoxedInput/BoxedInput';
import FieldErrorMsg from '../../../../components/FieldErrorMsg/FieldErrorMsg';
import FormRow from '../../../../components/FormRow/FormRow';
import { reduxForm } from 'redux-form';
import { createValidator, notEmpty } from '../../../../validation/sync-validation';
import { RaisedButton } from 'material-ui';
import { FormattedMessage } from 'react-intl';
import { fallbackFunc } from '../../../../utils/utils';

export class CategoryView extends Component {
  componentWillReceiveProps(props) {
    this.callback = !props.limitedAccess ? props.handleSubmit(this.props.onCallback) : undefined;
  }

  render() {
    const {
      fields: { type, description },
      limitedAccess
    } = this.props;

    return (
      <ListDetails title={this.props.title} onClose={this.props.onClose}>
        <form className="sc-details-form" onSubmit={this.callback}>
          <FormRow>
            <BoxedInput
              disabled={limitedAccess}
              inputRef={this.props.inputRef}
              label={this.props.fieldLabel}
              field={type}
              maxlength="255"
              type="text"
            >
              <FieldErrorMsg field={type} />
            </BoxedInput>
          </FormRow>
          <FormRow>
            <BoxedInput disabled={limitedAccess} labelKey="common_description" field={description} maxlength="255" type="text">
              <FieldErrorMsg field={description} />
            </BoxedInput>
          </FormRow>
          {!limitedAccess && (
            <div className="sc-actions">
              <RaisedButton label={this.props.submitLabel} primary type="button" onClick={this.callback} />
            </div>
          )}
        </form>
      </ListDetails>
    );
  }
}

CategoryView.defaultProps = {
  fieldLabel: 'Field label',
  onCallback: fallbackFunc,
  submitLabel: <FormattedMessage id="common_save" />,
  limitedAccess: PropTypes.bool
};

CategoryView.propTypes = {
  title: PropTypes.any,
  inputRef: PropTypes.func,
  onClose: PropTypes.func,
  fieldLabel: PropTypes.any,
  submitLabel: PropTypes.any,
  onCallback: PropTypes.func
};

CategoryView = reduxForm({
  form: 'categoryView',
  fields: ['type', 'description', 'id'],
  touchOnBlur: false,
  validate: createValidator({
    type: [notEmpty()]
  })
})(CategoryView);

export default CategoryView;
