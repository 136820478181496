import moment from 'moment';
import _get from 'lodash/get';
import _find from 'lodash/find';
import _trim from 'lodash/trim';
import _map from 'lodash/map';
import _isEmpty from 'lodash/isEmpty';

import * as types from '../../constants/actionTypes-constants';
import callApi from '../../actions/api-actions';
import {
  addFlashMessage,
  getSingleBooking,
  bookingViewGetInvoices,
  getBookingTransactions,
  getDmsInvoiceErrors,
  getVehicleCompany,
  getBookingLicenseImage,
  getItalianInvoiceStatuses,
  getMemberDetail,
  getMemberComments,
  getCustomFieldsMember,
  getSubscriptionCustomFields,
  getMemberTypesList,
  getMemberDrivingLicenseImage,
  getMemberIdentityDocumentImage,
  getMemberEmployerCertificateImage,
  getCompaniesTechnicianList
} from '../../actions/all-actions';

import { ALL, FLASH_MESSAGE_TYPE_SUCCESS } from '../../constants/generic-constants';
import { STATUS_APPROVED, BOOKING_STATUS_PRE_BOOKED, EXTERNAL_INVOICE } from '../../constants/backend-constants';
import {
  addErrorMessage,
  createCustomFieldsValues,
  trimValues,
  isValidEmail,
  hideDriverImagesForCustomCompanyId,
  safe
} from '../../utils/utils';
import { bundleSelector, planningBookingCustomFieldsSelector } from '../../selectors/all-selectors';
import { enhanceBookingPriceRequestParams } from '../../api/data-enhancer';

export function getDataVehiclePlanning(values = false) {
  return (dispatch, getState) => {
    const state = getState();

    let localStoragePeriodInDays =
      Number(window.localStorage.getItem('planningPeriodInDays')) === 0 ? 1 : Number(window.localStorage.getItem('planningPeriodInDays'));
    let params = {
      subCompanyId: _get(state.subCompanies, 'subCompanySelected.id'),
      startDate: moment(_get(state.vehiclePlanning, 'startDate')).toISOString(),
      periodInDays: localStoragePeriodInDays || _get(state.vehiclePlanning, 'periodInDays')
    };

    if (_get(state.vehiclePlanning, 'siteId') !== ALL) params.siteId = _get(state.vehiclePlanning, 'siteId');

    if (_get(values, 'siteId') === ALL) delete values.siteId;

    dispatch({
      type: types.VEHICLE_PLANNING_GET_DATA_REQUEST
    });

    return dispatch(callApi('vehiclePlanning', 'getData', values ? values : params, false))
      .then(function(data) {
        dispatch({
          type: types.VEHICLE_PLANNING_GET_DATA_SUCCESS,
          data
        });
        return Promise.resolve(data);
      })
      .catch(err => {
        dispatch({
          type: types.VEHICLE_PLANNING_GET_DATA_ERROR,
          err
        });
        return Promise.reject(err);
      });
  };
}

export function toggleShortenExtendBooking(booking, vehicle, openShortenExtendBookingHover) {
  return {
    type: types.VEHICLE_PLANNING_TOGGLE_SHORTEN_EXTEND_MODAL,
    selectedVehicle: vehicle,
    selectedBooking: booking,
    openShortenExtendBookingHover
  };
}

export function vehiclePlanningClearData() {
  return {
    type: types.VEHICLE_PLANNING_CLEAR_DATA
  };
}

export function addUsageFilter(usage) {
  return {
    type: types.VEHICLE_PLANNING_TOGGLE_FILTER,
    usage
  };
}

export function saveCreateStatusData(dayIndex, vehicle) {
  return {
    type: types.VEHICLE_PLANNING_SAVE_CREATE_STATUS_DATA,
    dayIndex,
    vehicle
  };
}

export function emptyCreateStatusData() {
  return {
    type: types.VEHICLE_PLANNING_EMPTY_CREATE_STATUS_DATA
  };
}

export function toggleCreateStatusMode() {
  return {
    type: types.VEHICLE_PLANNING_TOGGLE_CREATE_STATUS_MODE
  };
}

export function toggleLegend() {
  return {
    type: types.VEHICLE_PLANNING_TOGGLE_LEGEND
  };
}

export function openConfirmAddStatusModal() {
  return {
    type: types.VEHICLE_PLANNING_ADD_STATUS_CONFIRM_OPEN
  };
}

export function closeConfirmAddStatusModal() {
  return {
    type: types.VEHICLE_PLANNING_ADD_STATUS_CONFIRM_CLOSE
  };
}

export function toggleCreateBookingModal() {
  return {
    type: types.VEHICLE_PLANNING_TOGGLE_CREATE_BOOKING_MODAL
  };
}

export function saveUserLoginForBooking(user, isPreBooking) {
  return {
    type: types.VEHICLE_PLANNING_SAVE_LOGIN_CREATE_BOOKING,
    memberProfileId: user.entity.id,
    profileReviewStatus: user.entity.profileReviewStatus,
    preSubMemberStatus: user.entity.status,
    login: user.value,
    user: user.shortName,
    isPreBooking
  };
}

export function saveUserTypeForBookingCreate(user = {}) {
  const { entity } = user || {};
  const { memberTypeId, id } = entity || {};
  return {
    type: types.VEHICLE_PLANNING_SAVE_MEMBER_TYPE_CREATE_BOOKING,
    memberTypeId,
    memberId: id
  };
}

export function saveUserTypeForBookingEdit(data) {
  const { member } = data || {};
  const { memberType, id } = member || {};
  return {
    type: types.VEHICLE_PLANNING_SAVE_MEMBER_TYPE_CREATE_BOOKING,
    memberType,
    memberId: id
  };
}

export function updateUserLoginForBooking(user) {
  return {
    type: types.VEHICLE_PLANNING_SAVE_LOGIN_EDIT_BOOKING,
    login: _get(user, 'value'),
    user: _get(user, 'shortName'),
    userDetail: _get(user, 'entity')
  };
}

export function changeMemberForBooking(user) {
  return {
    type: types.VEHICLE_PLANNING_CHANGE_MEMBER_EDIT_BOOKING,
    login: _get(user, 'value'),
    user: _get(user, 'shortName'),
    userDetail: _get(user, 'entity'),
    persist: true
  };
}

export function errorWhileSelectingUserForBooking() {
  return {
    type: types.VEHICLE_PLANNING_ERROR_SELECTED_USER
  };
}

export function cleanErrorWhileSelectingUserForBooking() {
  return {
    type: types.VEHICLE_PLANNING_CLEAN_ERROR_SELECTED_USER
  };
}

export function clearCreateBookingModal() {
  return {
    type: types.VEHICLE_PLANNING_CLEAR_CREATE_BOOKING_MODAL
  };
}

export function toggleCreateBookingMemberSelect() {
  return {
    type: types.VEHICLE_PLANNING_TOGGLE_MEMBER_SELECT
  };
}

export function selectSecondHourForCreatingBooking(value, vehicle, parking, siteId) {
  return {
    type: types.VEHICLE_PLANNING_SELECT_SECOND_HOUR_FOR_CREATING_BBOKING,
    value,
    vehicle,
    parking,
    siteId
  };
}

export function selectFirstHourForCreatingBooking(value, vehicle, parking, siteId) {
  return {
    type: types.VEHICLE_PLANNING_SELECT_FIRST_HOUR_FOR_CREATING_BBOKING,
    value,
    vehicle,
    parking,
    siteId
  };
}

export function selectFirstDayForCreatingBooking(value, vehicle, parking, siteId) {
  return {
    type: types.VEHICLE_PLANNING_SELECT_FIRST_DAY_FOR_CREATING_BBOKING,
    value,
    vehicle,
    parking,
    siteId
  };
}

export function selectSecondDayForCreatingBooking(value, vehicle, parking, siteId) {
  return {
    type: types.VEHICLE_PLANNING_SELECT_SECOND_DAY_FOR_CREATING_BBOKING,
    value,
    vehicle,
    parking,
    siteId
  };
}

export function unselectVehicle() {
  return {
    type: types.VEHICLE_PLANNING_CLEAR_SELECTED_VEHICLE
  };
}

export function changeVehiclePlanningSetings(data) {
  return {
    type: types.VEHICLE_PLANNING_CHANGE_SETTINGS,
    data
  };
}
export function toggleVehiclePlanningCard(booking, vehicle) {
  return {
    type: types.VEHICLE_PLANNING_TOGGLE_CARD,
    booking,
    vehicle
  };
}

export function toggleVehiclePlanningCardV2(booking, vehicle) {
  return dispatch => {
    return dispatch(getSingleBooking(booking.id)).then(
      data => {
        const actionsToWait = [];
        const vehicleCompanyId = _get(data, 'vehicle.lastPosition.parking.site.subCompanyId');
        const bookingId = _get(data, 'id');
        const files = _get(data, 'drivingLicenceMetaData.files');
        const externalInvoice = _get(data, 'member.company.useExternalInvoiceSystem');

        actionsToWait.push(dispatch(bookingViewGetInvoices(bookingId)));
        actionsToWait.push(dispatch(getBookingTransactions(bookingId, true)));

        if (externalInvoice === EXTERNAL_INVOICE.DMS) {
          actionsToWait.push(dispatch(getDmsInvoiceErrors(bookingId)));
        }

        if (externalInvoice === EXTERNAL_INVOICE.ITALIAN) {
          actionsToWait.push(dispatch(getItalianInvoiceStatuses(bookingId)));
        }

        if (vehicleCompanyId) actionsToWait.push(dispatch(getVehicleCompany(vehicleCompanyId, true)));

        if (!hideDriverImagesForCustomCompanyId(vehicleCompanyId, STATUS_APPROVED)) {
          actionsToWait.push(dispatch(getBookingLicenseImage(files)));
        }

        Promise.all(actionsToWait)
          .catch(err => {
            console.error('error', err);
          })
          .then(() => {
            dispatch({ type: types.VEHICLE_PLANNING_TOGGLE_CARD, booking, vehicle });
          });
      },
      err => {
        console.error('booking not found', err);
      }
    );
  };
}

export function toggleVehiclePlanningCardV2Member(memberId, booking, vehicle) {
  return dispatch => {
    return dispatch(getMemberDetail(memberId)).then(
      data => {
        const actionsToWait = [];
        const { member } = data || {};
        const companyId = _get(member, 'company.id');
        const drivingLicenceStatus = _get(member, 'drivingLicence.status');

        actionsToWait.push(dispatch(getMemberComments(memberId)));
        actionsToWait.push(dispatch(getCustomFieldsMember(memberId)));

        if (companyId) {
          actionsToWait.push(dispatch(getSubscriptionCustomFields(companyId)));
          actionsToWait.push(dispatch(getMemberTypesList({ companyId: companyId })));
        }

        const driverFiles = _get(member, 'drivingLicence.files');
        if (_get(driverFiles, 'length', 0) > 0 && !hideDriverImagesForCustomCompanyId(companyId, drivingLicenceStatus)) {
          actionsToWait.push(dispatch(getMemberDrivingLicenseImage(driverFiles)));
        }

        const identityFiles = _get(member, 'identityDocument.files');
        if (_get(identityFiles, 'length', 0) > 0) {
          actionsToWait.push(dispatch(getMemberIdentityDocumentImage(identityFiles)));
        }

        const employerFiles = _get(member, 'employerCertificate.files');
        if (_get(employerFiles, 'length', 0) > 0) {
          actionsToWait.push(dispatch(getMemberEmployerCertificateImage(employerFiles)));
        }

        if (_get(member, 'technician')) {
          actionsToWait.push(dispatch(getCompaniesTechnicianList(memberId)));
        }

        Promise.all(actionsToWait)
          .catch(e => {
            console.error('error', e);
          })
          .then(() => {
            dispatch({ type: types.VEHICLE_PLANNING_TOGGLE_CARD, member: data, booking, vehicle });
          });
      },
      err => {
        console.error('member not found', err);
      }
    );
  };
}

export function toggleVehiclePlanningEditBooking() {
  return {
    type: types.VEHICLE_PLANNING_TOGGLE_EDIT_BOOKING_MODAL
  };
}

export function toggleChangeVehicle() {
  return {
    type: types.VEHICLE_PLANNING_TOGGLE_CHANGE_VEHICLE_MODAL
  };
}

export function toggleChangeMember(data) {
  return {
    type: types.VEHICLE_PLANNING_TOGGLE_CHANGE_MEMBER_MODAL,
    ...data
  };
}

export function toggleEditChangeMember() {
  return {
    type: types.VEHICLE_PLANNING_TOGGLE_EDIT_CHANGE_MEMBER
  };
}

export function confirmPreBooking() {
  return {
    type: types.VEHICLE_PLANNING_CONFIRM_PREBOOKING
  };
}

export function abortConfirmPreBooking() {
  return {
    type: types.VEHICLE_PLANNING_CONFIRM_PREBOOKING_CANCEL
  };
}

export function getBookingVehiclePlanning(id) {
  return (dispatch, getState) => {
    dispatch({
      type: types.VEHICLE_PLANNING_GET_BOOKING_REQUEST
    });
    return dispatch(callApi('bookings', 'getSingleBooking', id, false)).then(
      function(data) {
        const state = getState();
        const siteId = _get(data, 'start.parking.siteId');
        const parkingId = _get(data, 'start.parking.id');
        const site = _find(state.vehiclePlanning.data.sites, { id: siteId });
        if (site) {
          const parking = _find(site.parkings, { id: parkingId });
          const schedule = _get(parking, 'schedule', false);
          if (schedule) data.schedule = schedule;
        }
        dispatch({
          type: types.VEHICLE_PLANNING_GET_BOOKING_SUCCESS,
          data
        });
        return Promise.resolve(data);
      },
      err => {
        dispatch({
          type: types.VEHICLE_PLANNING_GET_BOOKING_ERROR,
          err
        });
        return Promise.reject(err);
      }
    );
  };
}

export function getEstimatedPriceBooking() {
  return (dispatch, getState) => {
    const state = getState();
    const vehiclePlanning = _get(state, 'vehiclePlanning');
    const bookingDetail = _get(vehiclePlanning, 'bookingDetail');
    const vehicleId = _get(bookingDetail, 'vehicle.id');
    const siteId = _get(vehiclePlanning, 'firstHourSelected.siteId') || _get(vehiclePlanning, 'firstDaySelected.siteId');
    const formBooking = _get(state, 'form.VehiclePlanningEditBooking') || _get(state, 'form.VehiclePlanningCreateBooking');
    const memberTypeId = _get(formBooking, 'memberTypeId.value');
    const profileIdForCreateBooking = state.vehiclePlanning.profileIdForCreateBooking;

    const editBookingValues = {
      ...formBooking,
      ...bookingDetail,
      profileIdForCreateBooking,
      memberTypeId,
      siteId
    };

    const payload = enhanceBookingPriceRequestParams(editBookingValues);

    dispatch({ type: types.VEHICLE_PLANNING_GET_BOOKING_PRICE_REQUEST });

    return dispatch(callApi('bookings', 'search', payload, false)).then(
      (data = {}) => {
        const item = _find(data.results, { vehicle: { id: vehicleId } });

        const type = _isEmpty(data.results)
          ? 'VEHICLE_PLANNING_GET_BOOKING_PRICE_SUCCESS_EMPTY'
          : 'VEHICLE_PLANNING_GET_BOOKING_PRICE_SUCCESS';

        dispatch({ type, data });
        dispatch(displayEditBookingPrice(item));

        return Promise.resolve(data);
      },
      err => {
        dispatch({ type: types.VEHICLE_PLANNING_GET_BOOKING_PRICE_ERROR, err });
        return Promise.reject(err);
      }
    );
  };
}

export function getEstimatedPricesBooking(payload) {
  return dispatch => {
    dispatch({
      type: types.VEHICLE_PLANNING_GET_BOOKING_PRICE_REQUEST
    });
    return dispatch(callApi('bookings', 'search', payload, false)).then(
      data => {
        dispatch({
          type: types.VEHICLE_PLANNING_GET_BOOKING_PRICE_SUCCESS,
          data
        });
        return Promise.resolve(data);
      },
      err => {
        dispatch({
          type: types.VEHICLE_PLANNING_GET_BOOKING_PRICE_ERROR,
          err
        });
        return Promise.reject(err);
      }
    );
  };
}

export function cleanBookingPriceEstimation() {
  return {
    type: types.VEHICLE_PLANNING_GET_BOOKING_PRICE_CLEAN
  };
}

export function displayBookingPrice(bookingPrice) {
  return {
    type: types.VEHICLE_PLANNING_CREATE_BOOKING_GET_PRICE,
    bookingPrice
  };
}

export function displayEditBookingPrice(bookingPrice) {
  return {
    type: types.VEHICLE_PLANNING_EDIT_BOOKING_GET_PRICE,
    bookingPrice
  };
}

export function createBookingVehiclePlanning(payload) {
  return (dispatch, getState) => {
    const state = getState();
    const customFields = planningBookingCustomFieldsSelector(state);
    const bundle = bundleSelector(state);
    const isPreSubMember = _get(state, 'vehiclePlanning.proceedNew');
    const memberFullName = _get(state, 'vehiclePlanning.userDetailCreateBooking');
    const memberEmail = _get(state, 'vehiclePlanning.loginForCreateBooking');

    if (_get(customFields, 'length')) {
      payload.bookingCustomValues = createCustomFieldsValues(customFields, trimValues(state.form.VehiclePlanningCreateBooking));
    }

    isPreSubMember ? (payload.withSubscription = '?withSubscription=true') : '';

    return dispatch(callApi('bookings', 'create', payload))
      .then(
        function(data) {
          dispatch({
            type: types.VEHICLE_PLANNING_CREATE_BOOKING_SUCCESS,
            data
          });
          let resp;
          if (_get(data, 'status') === BOOKING_STATUS_PRE_BOOKED) {
            resp = {
              contentKey: 'pre_booking_create_success',
              type: FLASH_MESSAGE_TYPE_SUCCESS,
              contentData: { member: `${memberFullName} ${memberEmail}` }
            };
          } else {
            resp = {
              contentKey: 'booking_create_success',
              type: FLASH_MESSAGE_TYPE_SUCCESS
            };
          }
          dispatch(addFlashMessage(resp));
          dispatch(clearCreateBookingModal());
          setTimeout(() => dispatch(getDataVehiclePlanning()), 1000); // wait server to process data
          return data;
        },
        error => dispatch(addErrorMessage({ error, bundle, errorCodePrefixes: ['bookingResult_'] }))
      )
      .catch(error => {
        dispatch(addErrorMessage({ error, bundle, errorCodePrefixes: ['bookingResult_'] }));
      });
  };
}

function getMemberSearchParams(companyId, input, email) {
  return {
    page: { number: 1, size: 25 },
    markedForAnonymization: false,
    status: STATUS_APPROVED,
    [email ? 'email' : 'memberName']: input,
    companyIds: [companyId]
  };
}

function getPreMemberSearchParams(companyId, input, email) {
  return {
    page: { number: 1, size: 25 },
    markedForAnonymization: false,
    [email ? 'email' : 'memberName']: input,
    companyIds: [companyId]
  };
}

function filterSearchMembersResults(item) {
  const firstName = _get(item, 'firstName', '');
  const lastName = _get(item, 'lastName', '');
  const login = _get(item, 'login', '');

  return {
    text: firstName + ' ' + lastName + '  -  ' + login,
    shortName: firstName + ' ' + lastName,
    value: login,
    entity: item
  };
}

function handleSearchMembersResp(data) {
  return {
    type: types.VEHICLE_PLANNING_SET_FOUND_USERS,
    data: _map(
      safe(() => data.results),
      filterSearchMembersResults
    )
  };
}

export function vpSearchMemberName({ params = false } = {}) {
  return dispatch => {
    return dispatch(callApi('search', 'memberName', params, false))
      .then(data => {
        return data;
      })
      .catch(() => ({ results: [] }));
  };
}

export function vpSearchMemberEmail({ params = false, searchAll = false } = {}) {
  return dispatch => {
    const method = searchAll ? 'memberEmailAll' : 'memberEmail';
    return dispatch(callApi('search', method, params, false))
      .then(data => {
        return data;
      })
      .catch(() => ({ results: [] }));
  };
}

function displayActionButton(data) {
  return {
    type: types.VEHICLE_PLANNING_START_NEW_PRESUB,
    data
  };
}

export function searchMembers(companyId, input) {
  return dispatch => {
    input = _trim(input);
    const _isValidEmail = isValidEmail(input);
    const searchAction = _isValidEmail ? vpSearchMemberEmail : vpSearchMemberName;

    if (input.length > 2) {
      const params = getMemberSearchParams(companyId, input, _isValidEmail);
      if (params) {
        dispatch(searchAction({ params })).then(data => dispatch(handleSearchMembersResp(data)));
      }
    }

    if (input.length === 0) {
      dispatch(clearFoundUsers());
    }
  };
}

export function searchPreMembers(companyId, input) {
  return dispatch => {
    input = _trim(input);
    const _isValidEmail = isValidEmail(input);
    const searchAction = _isValidEmail ? vpSearchMemberEmail : vpSearchMemberName;
    const searchAll = _isValidEmail ? true : undefined;

    if (input && input.length > 2) {
      const params = getPreMemberSearchParams(companyId, input, _isValidEmail);
      if (params) {
        dispatch(searchAction({ params, searchAll })).then(data => {
          if (!_isValidEmail) {
            dispatch(hideActionMemberPresub());
          }

          if (data.results.length === 0) {
            if (_isValidEmail) {
              dispatch(displayActionButton(input));
            } else dispatch(hideActionMemberPresub());
          }

          dispatch(handleSearchMembersResp(data));
        });
      }
    }

    if (input.length === 0) {
      dispatch(hideActionMemberPresub());
      dispatch(clearFoundUsers());
      dispatch(cleanErrorWhileSelectingUserForBooking());
    }
  };
}

export function confirmPrebooking(id) {
  return dispatch => {
    return dispatch(callApi('bookings', 'confirm', id, false)).then(
      () => dispatch(addFlashMessage({ contentKey: 'booking_create_success', type: FLASH_MESSAGE_TYPE_SUCCESS })),
      error => dispatch(addErrorMessage({ error, errorCodePrefixes: ['bookingResult_'] }))
    );
  };
}

export function clearFoundUsers() {
  return {
    type: types.VEHICLE_PLANNING_CLEAR_FOUND_USERS
  };
}

export function resetFilters() {
  return {
    type: types.VEHICLE_PLANNING_RESET_FILTERS
  };
}

export function hideActionMemberPresub() {
  return {
    type: types.VEHICLE_PLANNING_CLEAR_EXIST_MEMBER_MESSAGE
  };
}

export function addFilter(filter) {
  return {
    type: types.VEHICLE_PLANNING_ADD_FILTER,
    filter
  };
}

export function removeFilter(item) {
  return {
    type: types.VEHICLE_PLANNING_REMOVE_FILTER,
    item
  };
}
export function editFilters(values) {
  return {
    type: types.VEHICLE_PLANNING_EDIT_FILTERS,
    values
  };
}

export function resetFilter() {
  return {
    type: types.VEHICLE_PLANNING_RESET_FILTERSv2
  };
}

export function createPreSubscribeMember(data) {
  return {
    type: types.VEHICLE_PLANNING_PROCEED_NEW_PRESUB,
    data,
    create: true
  };
}
