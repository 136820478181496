import React, { Component } from 'react';
import Dropzone from 'react-dropzone';
import classNames from 'classnames';
import RaisedButton from 'material-ui/RaisedButton';
import AddIcon from 'material-ui/svg-icons/content/add-circle-outline';
import { colorDark, cssColors } from '../../constants/style-constants';
import { ExcelIcon } from '../../constants/svg-icons';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { downloadUrlContent, namedCompose } from '../../utils/utils';
import { getMemberImportTemplateUrl } from '../../constants/links';
import { uploadMember } from '../../actions/members-actions';
import BackLink from '../../components/BackLink/BackLink';
import routes from '../../constants/routes-constants';
import { apiParams } from '../../constants/api-params-constants';
import RefreshIcon from 'material-ui/svg-icons/navigation/refresh';
import { emptyMembersImportResume } from '../../actions/all-actions';
import { ALL } from '../../constants/generic-constants';

class UploadMember extends Component {
  constructor(props) {
    super(props);

    this.dropZone = this.dropZone.bind(this);
    this.handleAddButton = this.handleAddButton.bind(this);
    this.handleAddInputChange = this.handleAddInputChange.bind(this);
    this.handleDownloadExcel = this.handleDownloadExcel.bind(this);
    this.handleOnDrop = this.handleOnDrop.bind(this);
    this.handleRetry = this.handleRetry.bind(this);
    this.displayImportResume = this.displayImportResume.bind(this);
    this.handleSetAddInputRef = ref => (this.addRef = ref);
  }

  getBackLink() {
    const params = this.props.urlParams || apiParams.default;
    return '/#' + routes.members.path.replace(':search', encodeURIComponent(JSON.stringify(params)));
  }

  handleOnDrop(files = []) {
    this.props.dispatch(uploadMember(files[0]));
  }

  handleAddInputChange(e) {
    const file = e.target.files[0];
    this.handleOnDrop([file]);
  }

  handleAddButton() {
    if (this.addRef) this.addRef.click();
  }

  handleDownloadExcel() {
    downloadUrlContent(getMemberImportTemplateUrl(this.props.locale));
  }

  dropZone({ getRootProps, isDragActive }) {
    const {
      intl: { formatMessage }
    } = this.props;

    return (
      <div {...getRootProps()} className={classNames('upload-member-dropzone', { 'upload-member-dropzone-active': isDragActive })}>
        <input
          id="upload-member-add-input"
          type="file"
          className="upload-member-add-input"
          onChange={this.handleAddInputChange}
          ref={this.handleSetAddInputRef}
        />
        <div className="upload-member-dropzone-inner">
          <div style={{ marginBottom: '10px', fontSize: 'larger' }}>
            <FormattedMessage id="upload_members_drop_title" />
          </div>
          <div style={{ marginBottom: '10px', fontSize: 'smaller', ...colorDark }}>
            <FormattedMessage id="upload_members_drop_sub_title" />
          </div>
          <RaisedButton
            className={classNames('flatButton', 'upload-member-button')}
            id="upload-member-add-button"
            label={formatMessage({ id: 'upload_members_upload_button_title' })}
            icon={<AddIcon />}
            labelStyle={{ textTransform: 'none' }}
            overlayStyle={{ display: 'inline-flex', alignItems: 'center' }}
            onClick={this.handleAddButton}
            primary
          />
        </div>
      </div>
    );
  }

  handleRetry() {
    this.props.dispatch(emptyMembersImportResume());
  }

  displayImportResume() {
    const { membersImportResume } = this.props;

    if (
      Object.keys(membersImportResume).length > 0 &&
      membersImportResume.constructor === Object &&
      !membersImportResume.hasOwnProperty('status')
    ) {
      const nbMembersImported = membersImportResume.nbMembersImported + ' / ' + membersImportResume.nbProcessedLines;
      return (
        <div className="upload-member-box">
          <h3 style={{ fontSize: 'larger' }}>
            <FormattedMessage id="upload_members_page_resume_title" />
          </h3>
          <div className="resume-figures">
            <FormattedMessage id="upload_members_nb_processed_lines" values={{ nbProcessedLines: membersImportResume.nbProcessedLines }} />
            <FormattedMessage id="upload_members_nb_members_imported" values={{ nbMembersImported }} />
          </div>
          {membersImportResume.rejectedMembers && membersImportResume.rejectedMembers.length > 0 && (
            <div className="members-rejected-box">
              <FormattedMessage id="upload_members_nb_members_rejected" values={{ count: membersImportResume.rejectedMembers.length }} />
              <div className="rejected-members-list">
                {membersImportResume.rejectedMembers.map((item, index) => {
                  return (
                    <div className="error" key={'rejectedMember' + index}>
                      {item}
                    </div>
                  );
                })}
              </div>
            </div>
          )}
          <div className="import-retry">
            <RaisedButton
              onClick={this.handleRetry}
              id="import-retry-btn"
              icon={<RefreshIcon />}
              label={<FormattedMessage id="booking_detail_retry_button" />}
            />
          </div>
        </div>
      );
    }

    if (membersImportResume.status === 503 || membersImportResume.recapMail) {
      return (
        <div className="upload-member-box">
          <h3 style={{ fontSize: 'larger' }}>
            <FormattedMessage id="upload_members_page_resume_title_pending" />
          </h3>
          <div className="resume-figures">
            <FormattedMessage id="upload_members_page_resume_email" />
          </div>
        </div>
      );
    }
  }

  stepNumber(n) {
    const {
      intl: { formatMessage }
    } = this.props;

    return (
      <strong>
        <FormattedMessage id="data.with.colon" values={{ data: formatMessage({ id: 'common_step_n' }, { n }) }} />
      </strong>
    );
  }

  render() {
    const backLink = this.getBackLink();

    const {
      intl: { formatMessage }
    } = this.props;

    if (this.props.companySelected) {
      return (
        <div className="pageContainer">
          <BackLink link={backLink} labelKey="back_link_members_list" />
          <div className="upload-member">
            {this.displayImportResume()}
            {Object.keys(this.props.membersImportResume).length === 0 && (
              <div className="upload-member-box">
                <h3 style={{ fontSize: 'larger' }}>
                  <FormattedMessage id="upload_members_page_title" />
                </h3>
                <Dropzone onDrop={this.handleOnDrop}>{this.dropZone}</Dropzone>
                <div className="upload-member-hr" />
                <h4>
                  <FormattedMessage id="upload_members_tutorial_title" />
                </h4>
                <div className="upload-member-tuto">
                  <div className="upload-member-tuto-col">
                    <div className="upload-member-tuto-row">
                      <div style={{ marginBottom: '5px' }}>
                        {this.stepNumber(1)}
                        <span> </span>
                        <FormattedMessage id="upload_members_step_1_title" />
                      </div>
                      <RaisedButton
                        className="upload-member-button"
                        id="upload-member-add-button"
                        label={formatMessage({ id: 'upload_members_download_button_title' })}
                        icon={<ExcelIcon />}
                        labelStyle={{ textTransform: 'none' }}
                        onClick={this.handleDownloadExcel}
                        backgroundColor={cssColors.backgroundDark}
                        labelColor={cssColors.textWhite}
                        overlayStyle={{ display: 'inline-flex', alignItems: 'center' }}
                      />
                    </div>
                    <div className="upload-member-tuto-row">
                      {this.stepNumber(2)}
                      <span> </span>
                      <FormattedMessage id="upload_members_step_2_title" />
                      <div className="upload-member-tuto-info">
                        <FormattedMessage id="upload_members_step_2_info" />
                      </div>
                    </div>
                  </div>
                  <div className="upload-member-vr" />
                  <div className="upload-member-tuto-col">
                    <div className="upload-member-tuto-row">
                      {this.stepNumber(3)}
                      <span> </span>
                      <FormattedMessage id="upload_members_step_3_title" />
                      <div className="upload-member-tuto-info">
                        <FormattedMessage id="upload_members_step_3_info" />
                      </div>
                    </div>
                    <div className="upload-member-tuto-row">
                      {this.stepNumber(4)}
                      <span> </span>
                      <FormattedMessage id="upload_members_step_4_title" />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      );
    } else
      return (
        <div className="pageContainer">
          <BackLink link={backLink} labelKey="back_link_members_list" />
          <div className="page-info-block">
            <FormattedMessage id="common_select_super_company" />
          </div>
        </div>
      );
  }
}

export default namedCompose(
  connect(state => {
    const {
      companies: { currentCompany },
      members: { urlParams, membersImportResume },
      i18n: { locale }
    } = state;
    const companySelected = currentCompany && currentCompany.id !== ALL;
    return { companySelected, urlParams, membersImportResume, locale };
  }),
  injectIntl
)(UploadMember);
