/* eslint-disable react/no-set-state */

import React, { Component, PropTypes } from 'react';
import autoBind from 'react-autobind';
import { connect } from 'react-redux';
import { getMaterialLocale, namedCompose } from '../../../utils/utils';

import { injectIntl } from 'react-intl';
import DatePicker from 'material-ui/DatePicker';

import { setVehicleStatusesStartDate, setVehicleStatusesEndDate, getStatusesReport } from '../../../actions/vehicleStatuses-actions';
import { localeSelector } from '../../../selectors/all-selectors';

class DatesRange extends Component {
  constructor(props) {
    super(props);
    this.dateStart = new Date('May 13, 2019 00:00:00 UTC');
    autoBind(this);
  }

  handleStartDateChange(event, date) {
    const { selectedPieData } = this.props;
    this.props.dispatch(setVehicleStatusesStartDate(date));
    const body = this.props.vehicleStatusesDates;
    const vehicleId = selectedPieData.id;
    this.props.dispatch(getStatusesReport({ vehicleId, body }));
  }

  handleEndDateChange(event, date) {
    const { selectedPieData } = this.props;
    this.props.dispatch(setVehicleStatusesEndDate(date));
    const body = this.props.vehicleStatusesDates;
    const vehicleId = selectedPieData.id;
    this.props.dispatch(getStatusesReport({ vehicleId, body }));
  }

  render() {
    const {
      intl: { formatMessage }
    } = this.props;

    const { startDate, endDate } = this.props.vehicleStatusesDates;
    const startDateLabel = formatMessage({ id: 'common_start_date' });
    const endDateLabel = formatMessage({ id: 'common_end_date' });

    return (
      <div className="vehicle-statuses-dates-range">
        <DatePicker
          hintText={startDateLabel}
          className="dates-range"
          floatingLabelText={startDateLabel}
          defaultDate={startDate}
          maxDate={endDate}
          locale={getMaterialLocale(this.props.locale)}
          DateTimeFormat={global.Intl.DateTimeFormat}
          minDate={this.dateStart}
          onChange={this.handleStartDateChange}
          autoOk
        />
        <DatePicker
          hintText={endDateLabel}
          className="dates-range"
          floatingLabelText={endDateLabel}
          defaultDate={endDate}
          locale={getMaterialLocale(this.props.locale)}
          DateTimeFormat={global.Intl.DateTimeFormat}
          minDate={startDate}
          onChange={this.handleEndDateChange}
          autoOk
        />
      </div>
    );
  }
}

DatesRange.propTypes = {
  vehicleStatusesDates: PropTypes.object
};

DatesRange.displayName = 'DatesRange';

export default namedCompose(
  connect(state => {
    const {
      vehicleStatuses: { vehicleStatusesDates, selectedPieData }
    } = state;

    return {
      vehicleStatusesDates,
      selectedPieData,
      locale: localeSelector(state)
    };
  }),
  injectIntl
)(DatesRange);
