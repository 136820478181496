import React, { Component, PropTypes } from 'react';
import Color from 'color';
import ReactSVG from 'react-svg';

import {
  getAppFormattedDateTime,
  getAppFormattedTime,
  getMainColor,
  getPercentDiff,
  getStorageData,
  round2decimal,
  setStorageData,
  testProp,
  safe
} from '../../../utils/utils';

import Tooltip from '../../../components/Tooltip/Tooltip';
import cs from 'classnames';
import moment from 'moment';
import memoizeOne from 'memoize-one';
import { getMsg } from '../../../utils/IntlGlobalProvider';
import { connect } from 'react-redux';
import { userIdSelector } from '../../../selectors/all-selectors';

const addOpacityToColor = memoizeOne(color => {
  const arr = Color(color)
    .fade(0.7)
    .array();

  arr[3] = round2decimal(arr[3]);

  return { background: `rgba(${arr.join(', ').trim()})` };
});

const getSvgWrapStyles = () => addOpacityToColor(getMainColor());

class DashboardItemV2 extends Component {
  componentWillMount() {
    this.initProps();
  }

  initProps() {
    this.componentPropsUpdated(this.props);
    this.propsInit = true;
  }

  componentWillReceiveProps(props) {
    this.componentPropsUpdated(props);
  }

  componentPropsUpdated(props) {
    const { count, apiCompanyId } = props;

    if (testProp.call(this, props, { count, apiCompanyId })) {
      const { id, userId } = props;
      const storedUserIds = getStorageData(id) || {};
      const storedCompanyIds = safe(() => storedUserIds[userId]) || {};
      const { oldCount = count, oldTime } = safe(() => storedCompanyIds[apiCompanyId]) || {};

      if (count >= 0) {
        if (oldCount !== count) {
          const momentDate = moment(oldTime);
          const isToday = momentDate.diff(new Date(), 'days') === 0;

          this.date = getMsg('prefix.compared.to') + ' ';

          if (isToday) {
            this.date += getMsg('time.today.at').toLowerCase() + ' ' + getAppFormattedTime(momentDate, { local: true, v2: true });
          } else {
            this.date += getAppFormattedDateTime(momentDate, { local: true, v2: true });
          }

          this.diffNumber = count - oldCount;
          this.isRise = this.diffNumber > 0;
          this.absDiff = Math.abs(this.diffNumber);
          this.diffPercent = round2decimal(getPercentDiff(this.absDiff, oldCount));
        }

        storedCompanyIds[apiCompanyId] = { oldCount: count, oldTime: new Date().getTime() };
        storedUserIds[userId] = storedCompanyIds;

        setStorageData(id, storedUserIds);
      }
    }
  }

  showCount() {
    const { count } = this.props;
    return count >= 0 ? count : '-';
  }

  getUpdates() {
    if (this.diffNumber) {
      return (
        <Tooltip content={this.date} placement="top">
          <div className={cs('sc-updates', { 'sc-plus': this.isRise, 'sc-minus': !this.isRise, 'sc-invert': this.props.invertColors })}>
            <span className="sc-symbol" />
            <span className="sc-diff">{this.diffPercent}%</span>
            <span className="sc-num">({(this.isRise ? '+' : '-') + this.absDiff})</span>
          </div>
        </Tooltip>
      );
    }
  }

  render() {
    return (
      <a className="dashboard-item" href={this.props.link} id={this.props.id}>
        <ReactSVG src={this.props.icon} className="sc-icon-wrap" style={getSvgWrapStyles()} svgClassName="main-color" />
        <div className="sc-info">
          <div className="sc-count">{this.showCount()}</div>
          <div className="sc-details">{this.props.label}</div>
          {this.getUpdates()}
        </div>
      </a>
    );
  }
}

DashboardItemV2.propTypes = {
  invertColors: false
};

DashboardItemV2.propTypes = {
  id: PropTypes.string.isRequired,
  apiCompanyId: PropTypes.string,
  invertColors: PropTypes.bool,
  label: PropTypes.string,
  count: PropTypes.number,
  link: PropTypes.string,
  icon: PropTypes.string
};

DashboardItemV2 = connect(state => {
  return { userId: userIdSelector(state) };
})(DashboardItemV2);

export default DashboardItemV2;
