import React, { Component } from 'react';
import { connect } from 'react-redux';
import { routeActions } from 'react-router-redux';
import _get from 'lodash/get';
import moment from 'moment';

import {
  requestVehicleUpdate,
  callUpdateVehicleSuccess,
  addFlashMessage,
  getVehicleDetail,
  getSitesList,
  getModelsList,
  getVersionsList,
  getParkingsList,
  setDeviceRemovalReminder,
  getCompanyCurrentContract
} from '../../actions/all-actions';

import VehicleForm from '../../components/VehicleForm/VehicleForm';
import { FLASH_MESSAGE_TYPE_SUCCESS } from '../../constants/generic-constants';
import config from '../../constants/config-constants';
import routes from '../../constants/routes-constants';
import { vehicleAccessories } from '../../constants/options-constants';
import { VEHICLE_STATUSES } from '../../constants/backend-constants';
import { boolToString } from '../../utils/utils';

class EditVehicle extends Component {
  componentWillMount() {
    this.handleEditVehicle = this.handleEditVehicle.bind(this);
  }

  componentDidMount() {
    const { dispatch, pageParams } = this.props;

    dispatch(getVehicleDetail(pageParams, false)).then(vehicle => {
      this.saveCurrentRemovalDate(vehicle);

      dispatch(getCompanyCurrentContract(vehicle.company.id, false));
      dispatch(getSitesList(vehicle.company.id, false)).then(sitesList => {
        if (vehicle.lastPosition && vehicle.lastPosition.parking) {
          dispatch(getParkingsList(vehicle.lastPosition.parking.site.id, false));
        } else {
          if (sitesList.length) {
            dispatch(getParkingsList(sitesList[0].id, false));
          }
        }
      });
      dispatch(getModelsList(vehicle.version.model.brand.id, false));
      dispatch(getVersionsList(vehicle.version.model.id, false));
    });
  }

  saveCurrentRemovalDate(vehicle) {
    try {
      this.currentRemovalDate = vehicle.device.removalDate;
    } catch (e) {}
  }

  handleRemovalDateReminder(vehicle) {
    try {
      const newDate = vehicle.device.removalDate;

      if (newDate !== this.currentRemovalDate && vehicle.statusType !== VEHICLE_STATUSES['INACTIVE']) {
        this.props.dispatch(setDeviceRemovalReminder(vehicle));
      }
    } catch (e) {}
  }

  handleEditVehicle() {
    const { dispatch, detailVehicle } = this.props;

    dispatch(requestVehicleUpdate()).then(vehicle => {
      this.handleRemovalDateReminder(vehicle);
      dispatch(callUpdateVehicleSuccess(vehicle));
      dispatch(
        addFlashMessage({
          contentKey: 'vehicleForm_update_vehicle_success',
          type: FLASH_MESSAGE_TYPE_SUCCESS,
          delayToNextRoute: true
        })
      );
      dispatch(routeActions.push(routes.vehicleDetail.path.replace(':vehicleId', detailVehicle.id)));
    });
  }

  render() {
    const { detailVehicle, registrationFileImage } = this.props;
    const { content, ...fileData } = registrationFileImage || {};

    if (!Object.keys(detailVehicle).length) {
      return <div>loading</div>;
    }

    let carOptions = {};
    vehicleAccessories.forEach((item, index) => {
      carOptions[index] = detailVehicle.accessories.indexOf(item.value) !== -1;
    });

    const initialValues = {
      transmissionType: detailVehicle.transmissionType,
      fuelType: detailVehicle.fuelType,
      carBrand: detailVehicle.version.model.brand.id,
      carModel: detailVehicle.version.model.id,
      carVersion: detailVehicle.version.id,
      numberOfSeats: detailVehicle.seats,
      vehicleClass: detailVehicle.category.id,
      vehicleColor: detailVehicle.color ? detailVehicle.color.id : '',
      pictureData: { image: detailVehicle.pictureUrl },
      vinNumber: detailVehicle.vin,
      licencePlate: detailVehicle.registrationNumber,
      registrationDate: _get(detailVehicle, 'registrationDate') && moment(detailVehicle.registrationDate).toDate(),
      vehicleOwner: detailVehicle.ownedByRci ? config.vehicleOwnerChoicesValues.default : config.vehicleOwnerChoicesValues.currentCompany,
      usage: detailVehicle.usage,
      siteLocation: detailVehicle.lastPosition && detailVehicle.lastPosition.parking ? [detailVehicle.lastPosition.parking.site.id] : '',
      parkingLocation: detailVehicle.lastPosition && detailVehicle.lastPosition.parking ? [detailVehicle.lastPosition.parking.id] : '',
      serviceLevel: detailVehicle.serviceLevelType,
      usedSystems: detailVehicle.systemType,
      registrationFile: { id: detailVehicle.registrationDocumentId, ...fileData },
      deviceSerialNumber: detailVehicle.device ? detailVehicle.device.serialNumber : '',
      deviceInstallation: _get(detailVehicle, 'device.installationDate') && moment(detailVehicle.device.installationDate).toDate(),
      deviceRemoval: _get(detailVehicle, 'device.removalDate') && moment(detailVehicle.device.removalDate).toDate(),
      vehicleOptions: carOptions,
      type: detailVehicle.type || '',
      doorsNumber: detailVehicle.doorsNumber || '',
      description: detailVehicle.description || '',
      deviceWithKey: detailVehicle.systemType === 'VIRTUALKEY' ? 'false' : boolToString(_get(detailVehicle, 'device.withKey')) || 'true'
    };

    return (
      <div className="editVehiclePage mainContainer_content">
        <div className="pageContainer">
          <VehicleForm onCallback={this.handleEditVehicle} datas={initialValues} editMode />
        </div>
      </div>
    );
  }
}

EditVehicle.displayName = 'EditVehicle';

export default connect(state => {
  const {
    vehicles: { detailVehicle, registrationFileImage },
    page: { params }
  } = state;

  return {
    detailVehicle,
    registrationFileImage,
    pageParams: params
  };
})(EditVehicle);
