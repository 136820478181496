import React, { Component, PropTypes } from 'react';
import { FormattedMessage } from 'react-intl';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';

import FormRow from '../FormRow/FormRow';
import BoxedInput from '../BoxedInput/BoxedInput';
import BoxedSelect from '../BoxedSelect/BoxedSelect';
import EkButton from '../EkButton/EkButton';
import FieldErrorMsg from '../FieldErrorMsg/FieldErrorMsg';
import { createValidator, notEmpty, futureDate } from '../../validation/all-validation';
import { getAppFormattedDateTime, scrollToFirstError } from '../../utils/utils';
import { extendShortenPickerStyles } from '../../constants/style-constants';

class ExtendShortenBookingForm extends Component {
  render() {
    const {
      fields: { newEndDate, hour, min },
      handleSubmit
    } = this.props;

    return (
      <section className="extendShortenBookingFormWrapper">
        <form action="#" className="extendShortenBookingForm" onSubmit={handleSubmit(this.props.onCallback)}>
          <div className="extendShortenBookingForm_container">
            <h4 className="extendShortenBookingForm_title">
              <FormattedMessage id="extendShortenBookingForm_title" />
            </h4>

            <FormRow
              customWrapperClass="extendShortenBookingForm_currentDateRow_item"
              customClass="extendShortenBookingForm_row extendShortenBookingForm_currentDateRow"
            >
              <span className="boxedInput_labelText_extendShortenBookingForm float-right">
                <FormattedMessage id="extendShortenBookingForm_currentEndDate" />
              </span>
              <span className="boxedInput_labelText_extendShortenBookingForm">
                <span>{getAppFormattedDateTime(this.props.currentEndDate)}</span>
              </span>
            </FormRow>

            <FormRow customClass="extendShortenBookingForm_row">
              <span className="boxedInput_labelText_extendShortenBookingForm extendShortenBookingForm_newDateRow_label">
                <FormattedMessage id="extendShortenBookingForm_newEndDate" />
              </span>

              <BoxedInput
                formRowItemKey="extendShortenBookingForm_newEndDate"
                id="newEndDate"
                skinType="date"
                type="date"
                customClass="boxedInputWrapper--label"
                field={newEndDate}
                datePickerTextFieldStyle={extendShortenPickerStyles.textField}
              >
                <FieldErrorMsg field={newEndDate} customClass="fieldErrorMsg--extshortBooking extendShortenBookingForm_customError" />
              </BoxedInput>

              <div className="extendShortenBookingForm_time" formRowItemKey="extendShortenBookingForm_time">
                <BoxedSelect
                  formRowItemKey="extendShortenBookingForm_hour"
                  options={[
                    '00',
                    '01',
                    '02',
                    '03',
                    '04',
                    '05',
                    '06',
                    '07',
                    '08',
                    '09',
                    '10',
                    '11',
                    '12',
                    '13',
                    '14',
                    '15',
                    '16',
                    '17',
                    '18',
                    '19',
                    '20',
                    '21',
                    '22',
                    '23'
                  ]}
                  customClass="extendShortenBookingForm_boxedSelectWrapper"
                  field={hour}
                >
                  <FieldErrorMsg field={hour} customClass="fieldErrorMsg--extshortBooking" />
                </BoxedSelect>

                <BoxedSelect
                  formRowItemKey="extendShortenBookingForm_min"
                  options={['00', '15', '30', '45']}
                  customClass="extendShortenBookingForm_boxedSelectWrapper"
                  field={min}
                >
                  <FieldErrorMsg field={min} customClass="fieldErrorMsg--extshortBooking" />
                </BoxedSelect>
              </div>
            </FormRow>

            <FormRow customClass="extendShortenBookingForm_row extendShortenBookingForm_button_mt">
              <EkButton
                type="button"
                skinType="reverse"
                formRowItemKey="extendShortenBookingForm_abortBtn"
                customClass="extendShortenBookingForm_button float-right"
                onAction={this.props.onAbort}
              >
                <FormattedMessage id="extendShortenBookingForm_abort_button" />
              </EkButton>

              <EkButton
                type="button"
                formRowItemKey="extendShortenBookingForm_submitBtn"
                customClass="extendShortenBookingForm_button float-left"
                onAction={handleSubmit(this.props.onCallback)}
              >
                <FormattedMessage id="extendShortenBookingForm_save_button" />
              </EkButton>
            </FormRow>
          </div>
        </form>
      </section>
    );
  }
}

ExtendShortenBookingForm.displayName = 'ExtendShortenBookingForm';

ExtendShortenBookingForm.propTypes = {
  fields: PropTypes.object,
  handleSubmit: PropTypes.func,
  onCallback: PropTypes.func,
  onAbort: PropTypes.func
};

ExtendShortenBookingForm = reduxForm({
  onSubmitFail: scrollToFirstError,
  form: 'extendShortenBookingForm',
  fields: ['newEndDate', 'hour', 'min'],
  validate: createValidator({
    newEndDate: [notEmpty(), futureDate()],
    hour: [notEmpty()],
    min: [notEmpty()]
  })
})(ExtendShortenBookingForm);

export default connect(state => {
  const {} = state;
  return {};
})(ExtendShortenBookingForm);
