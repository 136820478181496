import React, { Component } from 'react';
import autoBind from 'react-autobind';
import pure from 'recompose/pure';
import { getAppFormattedDateTime, namedCompose } from '../../utils/utils';
import { FormattedMessage, injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { localeSelector } from '../../selectors/all-selectors';
import shortid from 'shortid';
import None from '../None';

class BookingTripInfo extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.table = new Map();
    this.derivedStateFromProps(props);
  }

  // eslint-disable-next-line no-unused-vars
  componentWillReceiveProps(nextProps, nextContext) {
    this.derivedStateFromProps(nextProps);
  }

  derivedStateFromProps(nextProps) {
    const {
      estimatedStart,
      effectiveStart,
      estimatedEnd,
      effectiveEnd,
      bookingStartParking,
      bookingStartSite,
      bookingStartAddress,
      bookingEndParking,
      bookingEndSite,
      bookingEndAddress,
      bookingStartMileage,
      bookingEndMileage,
      startFuel,
      endFuel,
      intl
    } = nextProps;

    this.estimatedStart = getAppFormattedDateTime(estimatedStart);
    this.effectiveStart = getAppFormattedDateTime(effectiveStart);
    this.estimatedEnd = getAppFormattedDateTime(estimatedEnd);
    this.effectiveEnd = getAppFormattedDateTime(effectiveEnd);

    this.headStart = false;
    this.headEnd = false;
    this.startMileage = bookingStartMileage ? bookingStartMileage.toFixed(1) : bookingStartMileage;
    this.endMileage = bookingEndMileage ? bookingEndMileage.toFixed(1) : bookingEndMileage;
    this.addTableRow('common_site', bookingStartSite, bookingEndSite);
    this.addTableRow('common_parking', bookingStartParking, bookingEndParking);
    this.addTableRow('common_address', bookingStartAddress, bookingEndAddress);
    this.addTableRow('booking_detail_trip_start', this.estimatedStart, this.estimatedEnd);
    this.addTableRow('booking_detail_trip_real_start', this.effectiveStart, this.effectiveEnd);
    this.addTableRow('booking_detail_trip_mileage', this.startMileage, this.endMileage);
    this.addTableRow('vehicle_detail_device_status_fuelLevel', startFuel, endFuel);

    if (!this.checkRenderStatus()) return false;

    this.startString = intl.formatMessage({ id: 'booking_detail_trip_departure' });
    this.endString = intl.formatMessage({ id: 'booking_detail_trip_arrival' });
    this.preHeader = intl.formatMessage({ id: 'booking_detail_title_trip_information' });

    this.checkTableHead();
    this.prepareRender();
  }

  checkTableHead() {
    this.colspan = this.headStart && this.headEnd ? 1 : 2;
    this.startHeader = this.headStart ? this.getStartHeader() : undefined;
    this.endHeader = this.headEnd ? this.getEndHeader() : undefined;
  }

  checkRenderStatus() {
    this.componentCanRender = this.table.size > 0;
    return this.componentCanRender;
  }

  addTableRow(key, startValue, endValue) {
    const row = this.table.get(key);

    if (startValue || endValue) {
      if (!this.headStart && startValue) this.headStart = !!startValue;
      if (!this.headEnd && endValue) this.headEnd = !!endValue;
      if (row) {
        row['start'] = startValue;
        row['end'] = endValue;
      } else {
        this.table.set(key, {
          start: startValue,
          end: endValue
        });
      }
    } else if (row) this.table.delete(key);
  }

  prepareRender() {
    this.rows = [];
    this.table.forEach((v, k) => {
      this.rows.push(this.displayRow(k, v.start, v.end, shortid.generate()));
    });
  }

  getPreHeader() {
    return (
      <th>
        <h4>{this.preHeader}</h4>
      </th>
    );
  }

  getStartHeader() {
    return (
      <th colSpan={this.colspan}>
        <h5>{this.startString}</h5>
      </th>
    );
  }

  getEndHeader() {
    return (
      <th colSpan={this.colspan}>
        <h5>{this.endString}</h5>
      </th>
    );
  }

  displayRow(label, startValue, endValue, key) {
    if (key && (startValue || endValue))
      return (
        <tr className="detailView_tr_2" key={key}>
          <td className="detailView_cell_type_1">
            <FormattedMessage id={label} />
          </td>
          {this.displayCell(startValue, this.startString)}
          {this.displayCell(endValue, this.endString)}
        </tr>
      );
  }

  displayCell(value, headData) {
    const cellType = value ? '' : 'empty-cell';
    const mobileLabel = value ? headData : '';

    return (
      <td className={`detailView_cell_type ${cellType}`}>
        <div className="mobile_label">{mobileLabel}</div>
        <span>{value}</span>
      </td>
    );
  }

  render() {
    if (this.componentCanRender)
      return (
        <div className="bookingTripInfo table type-2">
          <h4 className="bookingTripInfo_headline table-headline">{this.preHeader}</h4>
          <div className="bookingTripInfo_body_3">
            <table>
              <thead>
                <tr>
                  {this.getPreHeader()}
                  {this.startHeader}
                  {this.endHeader}
                </tr>
              </thead>
              <tbody>{this.rows}</tbody>
            </table>
          </div>
        </div>
      );
    else return <None />;
  }
}

BookingTripInfo.defaultProps = {
  freeOfCharges: false
};

// -----

BookingTripInfo.propTypes = {
  statusPayment: PropTypes.string,
  bookingUsageType: PropTypes.string,
  freeOfCharges: PropTypes.bool,
  invoiceNumber: PropTypes.string,
  estimatedPriceForDuration: PropTypes.number,
  totalPriceIncludingTaxes: PropTypes.number,
  voucherDiscountAmount: PropTypes.number,
  estimatedStart: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object // date
  ]),
  effectiveStart: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object // date
  ]),
  estimatedEnd: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object // date
  ]),
  effectiveEnd: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object // date
  ]),
  bookingStartParking: PropTypes.string,
  bookingStartSite: PropTypes.string,
  bookingStartAddress: PropTypes.string,
  bookingEndParking: PropTypes.string,
  bookingEndSite: PropTypes.any,
  bookingEndAddress: PropTypes.string,
  bookingStartMileage: PropTypes.number,
  bookingEndMileage: PropTypes.number
};

// -----

export default namedCompose(
  connect(state => {
    return {
      locale: localeSelector(state)
    };
  }),
  injectIntl,
  pure
)(BookingTripInfo);
