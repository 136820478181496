import { change } from 'redux-form';

import { objectToMomentFactory, addZeroForSelect } from '../utils/utils';

const changeType = change().type;
const pickupDateToMoment = objectToMomentFactory({
  getDate: o => o.pickupDate.value,
  getHour: o => o.pickupDateHour.value,
  getMinute: o => o.pickupDateMin.value
});

const returnDateToMoment = objectToMomentFactory({
  getDate: o => o.returnDate.value,
  getHour: o => o.returnDateHour.value,
  getMinute: o => o.returnDateMin.value
});

function updateObjectProperty(target, propertyName, value) {
  return {
    ...target,
    [propertyName]: value
  };
}

export const searchBooking = (state, action) => {
  if (!state._initialized) return;

  const { type, field } = action || {};
  const { returnDate, returnDateHour, returnDateMin } = state || {};

  const isDateField = field === 'pickupDateMin' || field === 'pickupDateHour' || field === 'pickupDate';

  if (type === changeType && isDateField) {
    const nextHourMoment = pickupDateToMoment(state);
    const returnMoment = returnDateToMoment(state);

    if (nextHourMoment && returnMoment) {
      nextHourMoment.add(1, 'hours');

      const newReturnDate = nextHourMoment.toDate();
      const newReturnHour = addZeroForSelect(nextHourMoment.hour());
      const newReturnMin = addZeroForSelect(nextHourMoment.minute());

      if (nextHourMoment.isAfter(returnMoment)) {
        return {
          ...state,
          returnDate: updateObjectProperty(returnDate, 'value', newReturnDate),
          returnDateHour: updateObjectProperty(returnDateHour, 'value', newReturnHour),
          returnDateMin: updateObjectProperty(returnDateMin, 'value', newReturnMin)
        };
      }
    }
  }

  return state;
};
