import {
  BOOKING_STATUS_IN_PROGRESS,
  CLEANLINESS_STATUS_DIRTY,
  SORT_PROPERTY_BANKOUT_EXECUTION_DATE,
  SORT_PROPERTY_NAME,
  SORT_PROPERTY_PAYBACK_CREATION_DATE,
  SORT_PROPERTY_PLATE_NUMBER,
  SORT_PROPERTY_START_DATE,
  SORT_PROPERTY_VOUCHER_GROUP_NAME,
  STATUS_TO_REVIEW,
  VEHICLE_DAMAGE_STATUS_LIST_ACTIVE,
  VEHICLE_STATUSES
} from './backend-constants';

import routes from './routes-constants';

export const apiParams = {
  default: {
    page: {
      number: 1,
      size: 50
    }
  },
  flat: {
    number: 1,
    size: 50
  }
};

apiParams.expediteReview = {
  expediteReview: true,
  status: STATUS_TO_REVIEW
};

apiParams.lastBookings = {
  status: BOOKING_STATUS_IN_PROGRESS
};

apiParams.delayedBookings = {
  delayed: true,
  status: BOOKING_STATUS_IN_PROGRESS
};

apiParams.delayedBooking = {
  delayed: true,
  page: { number: 1, size: 1 }
};

apiParams.unmanagedDamages = {
  status: VEHICLE_DAMAGE_STATUS_LIST_ACTIVE
};

apiParams.cleanlinessProblems = {
  cleanliness: CLEANLINESS_STATUS_DIRTY,
  isFiltered: false
};

apiParams.nonOperatingVehicles = {
  vehicleStatus: [VEHICLE_STATUSES.MAINTENANCE, VEHICLE_STATUSES.CLEANING, VEHICLE_STATUSES.REPARATION, VEHICLE_STATUSES.INTERVENTION]
};

apiParams.newMembers = {
  status: STATUS_TO_REVIEW
};

apiParams.failedBookings = {
  failed: true
};

apiParams.rrsFailedBookings = {
  rrsUpdateFailed: true
};

apiParams.lowAccessoryBatVehicles = {
  maximumBoardVoltage: 11.5,
  minimumBoardVoltage: 1
};

apiParams.fleet = {
  sort: {
    property: SORT_PROPERTY_PLATE_NUMBER,
    isDescending: false
  }
};

apiParams.bankoutList = {
  sort: {
    property: SORT_PROPERTY_BANKOUT_EXECUTION_DATE,
    isDescending: true
  }
};

apiParams.lowFuelLevel = {
  lowFuelLevel: true
};

apiParams.paybackList = {
  sort: {
    property: SORT_PROPERTY_PAYBACK_CREATION_DATE,
    isDescending: true
  }
};

apiParams.smartcardsUserSearch = {
  page: { number: 1, size: 25 }
};

apiParams.voucherGroups = {
  page: { number: 1, size: 50 },
  sort: {
    property: SORT_PROPERTY_VOUCHER_GROUP_NAME,
    isDescending: false
  }
};

apiParams.bookingsList = {
  page: { number: 1, size: 50 },
  sort: {
    property: SORT_PROPERTY_START_DATE,
    isDescending: true
  }
};

apiParams.companiesList = {
  page: { number: 1, size: 50 },
  sort: {
    property: SORT_PROPERTY_NAME,
    isDescending: false
  }
};

function setDashboardUrl(route, param) {
  return `#${routes[route].path.replace(':search', encodeURIComponent(JSON.stringify({ ...apiParams.default, ...apiParams[param] })))}`;
}

export const dashboardLinks = {
  lastBookings: setDashboardUrl('bookings', 'lastBookings'),
  delayedBookings: setDashboardUrl('bookings', 'delayedBookings'),
  unmanagedDamages: setDashboardUrl('vehicleDamages', 'unmanagedDamages'),
  cleanlinessProblems: setDashboardUrl('fleet', 'cleanlinessProblems'),
  nonValidatedMembers: setDashboardUrl('members', 'newMembers'),
  expediteMembers: setDashboardUrl('members', 'expediteReview'),
  lowAccessoryBatVehicles: setDashboardUrl('fleet', 'lowAccessoryBatVehicles'),
  failedBookings: setDashboardUrl('bookings', 'failedBookings'),
  rrsFailedBookings: setDashboardUrl('bookings', 'rrsFailedBookings'),
  nonOperatingVehicles: setDashboardUrl('fleet', 'nonOperatingVehicles'),
  lowFuelLevel: setDashboardUrl('fleet', 'lowFuelLevel')
};
