import { getValues } from 'redux-form';
import _isArray from 'lodash/isArray';
import _reduce from 'lodash/reduce';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _includes from 'lodash/includes';

import { getStore } from '../store/all-store';
import * as types from '../constants/actionTypes-constants';
import callApi, { addApiCall, subApiCall } from './api-actions';
import { LOGIN_MISSING_ARGUMENT, USER_RESET_PASSWORD_MISSING_ARGUMENT } from '../constants/errors-constants';
import { unsetToken } from './api-actions';
import { refreshPage } from '../routing/all-routing';
import { addFlashMessage } from '../actions/all-actions';
import { COMPANY_TYPE_SUB, COMPANY_TYPE_SUPER, FLASH_MESSAGE_TYPE_ERROR, FLASH_MESSAGE_TYPE_SUCCESS } from '../constants/generic-constants';
import { isTestEnv, safe, toBoolean, trimValues, trySet, userCompanyType } from '../utils/utils';
import { PASSWORD_FIELD_NAME } from '../constants/generic-constants';
import { reportingSpinnerStatusSelector, userRoleSelector } from '../selectors/all-selectors';
import { backuserAllRoles } from '../constants/backuser-role-rules';
import { cleanDeep } from '../utils/cleanDeep';

//---------------------------------------------
// Login
//---------------------------------------------

export function requestLogin() {
  return (dispatch, getState) => {
    const { login, password, rememberMe } = trimValues(getState().form.login, PASSWORD_FIELD_NAME);

    dispatch({
      type: types.USER_LOGIN_REQUEST,
      params: {
        login,
        password,
        rememberMe
      }
    });

    if (typeof login !== 'string' || typeof password !== 'string') {
      return Promise.reject({
        name: LOGIN_MISSING_ARGUMENT
      });
    }

    if (rememberMe === true) {
      window.localStorage.setItem('login', login);
    } else {
      window.localStorage.removeItem('login');
    }

    return dispatch(callApi('user', 'login', { login, password })).then(userInfo => {
      dispatch(callLoginSuccess(userInfo));
      return userInfo;
    });
  };
}

export function exposeLoginIfStored() {
  return dispatch => {
    const login = window.localStorage.getItem('login');

    if (login) {
      dispatch({
        type: types.USER_STORED_LOGIN_EXPOSE,
        login
      });
    }
  };
}

export function callLoginSuccess(userInfo) {
  return {
    type: types.USER_LOGIN_SUCCESS,
    userInfo
  };
}

export function requestTokenRefresh() {
  return dispatch => {
    dispatch({
      type: types.USER_TOKEN_REFRESH_REQUEST
    });

    return dispatch(callApi('user', 'refreshToken')).then(userInfo => {
      dispatch(callTokenRefreshSuccess(userInfo));
      return userInfo;
    });
  };
}

export function callTokenRefreshSuccess(userInfo) {
  return {
    type: types.USER_TOKEN_REFRESH_SUCCESS,
    userInfo
  };
}

export function requestResetPassword() {
  return (dispatch, getState) => {
    const { login } = trimValues(getState().form.resetPassword);

    dispatch({
      type: types.USER_RESET_PASSWORD_REQUEST,
      params: {
        login
      }
    });

    if (typeof login !== 'string') {
      return Promise.reject({
        name: USER_RESET_PASSWORD_MISSING_ARGUMENT
      });
    }

    return dispatch(callApi('user', 'resetPassword', { login }));
  };
}

export function toggleForgotPasswordMode() {
  return {
    type: types.USER_FORGOT_PASSWORD_MODE_TOGGLE
  };
}

export function callResetPasswordSuccess() {
  return {
    type: types.USER_RESET_PASSWORD_SUCCESS
  };
}

export function loginError(error) {
  return {
    type: types.USER_LOGIN_ERROR,
    error
  };
}

export function callResetPasswordError(error) {
  return {
    type: types.USER_RESET_PASSWORD_ERROR,
    error
  };
}

export function logout() {
  return dispatch => {
    dispatch({
      type: types.HEADER_PROFILE_MENU_CLOSE
    });

    dispatch(unsetToken());

    return dispatch({
      type: types.USER_LOGOUT
    });
  };
}

//---------------------------------------------
// EDIT PROFILE
//---------------------------------------------

export function requestEditProfile() {
  function getCompanyIds(companies) {
    return _reduce(
      companies,
      (result, company) => {
        const companyId = _get(company, 'id');
        if (companyId) result.push(companyId);
        return result;
      },
      []
    );
  }

  return (dispatch, getState) => {
    const state = getState();
    const { id, role, companies, subCompanies } = state.user.userInfo;

    const { firstName, lastName, address, phoneNumber, secondaryPhoneNumber } = trimValues(state.form.editProfile);
    const { phonePrefix, phoneSuffix } = phoneNumber || {};
    const { phonePrefix: secondPhonePrefix, phoneSuffix: secondPhoneSuffix } = secondaryPhoneNumber || {};

    let formParams = {
      firstName,
      lastName,
      address,
      id,
      role,
      phoneNumber: {
        countryCode: phonePrefix,
        nationalNumber: phoneSuffix
      },
      locale: state.i18n.locale
    };

    let chosenCompanies = null;
    if (userCompanyType(role) === COMPANY_TYPE_SUPER) chosenCompanies = companies;
    else if (userCompanyType(role) === COMPANY_TYPE_SUB) chosenCompanies = subCompanies;
    trySet(formParams, 'companyIds', getCompanyIds(chosenCompanies));

    if (secondPhonePrefix && secondPhoneSuffix) {
      formParams = Object.assign(formParams, {
        secondaryPhoneNumber: {
          countryCode: secondPhonePrefix,
          nationalNumber: secondPhoneSuffix
        }
      });
    }

    dispatch({
      type: types.USER_EDIT_PROFILE_REQUEST,
      formParams
    });
    return dispatch(callApi('user', 'updateProfile', formParams));
  };
}

export function callEditProfileSuccess(userInfo) {
  return {
    type: types.USER_EDIT_PROFILE_SUCCESS,
    userInfo
  };
}

//---------------------------------------------
// ACCOUNT TABS
//---------------------------------------------

export function userAccountActiveTabIndex(index) {
  return {
    type: types.USER_ACCOUNT_ACTIVE_TAB_INDEX_UPDATE,
    index
  };
}

//---------------------------------------------
// RESET PASSWORD
//---------------------------------------------
export function checkResetPasswordTokenRequest(token) {
  return dispatch => {
    dispatch({
      type: types.USER_RESET_PASSWORD_CHECK_TOKEN_REQUEST
    });

    return dispatch(callApi('user', 'resetPasswordGET', token)).then(() => {
      dispatch(checkResetPasswordTokenSuccess(token));
    });
  };
}

export function checkResetPasswordTokenSuccess(validToken) {
  return {
    type: types.USER_RESET_PASSWORD_CHECK_TOKEN_SUCCESS,
    validToken
  };
}

export function storeResetPasswordToken(validToken) {
  return {
    type: types.USER_RESET_PASSWORD_STORE_TOKEN,
    validToken
  };
}

export function callResetPasswordApplyRequest() {
  return (dispatch, getState) => {
    const state = getState();

    const { password, confirmationPassword } = getValues(state.form.resetPassword);

    let params = {
      password,
      confirmationPassword,
      token: state.user.resetPasswordToken
    };

    dispatch({
      type: types.USER_RESET_PASSWORD_APPLY_REQUEST
    });

    return dispatch(callApi('user', 'resetPasswordPUT', params));
  };
}

//---------------------------------------------
// EDIT PASSWORD
//---------------------------------------------

export function requestEditPassword() {
  return (dispatch, getState) => {
    const state = getState();

    const { newPassword, oldPassword } = getValues(state.form.editPassword);

    let formParams = {
      newPassword,
      oldPassword
    };

    dispatch({
      type: types.USER_EDIT_PASSWORD_REQUEST,
      formParams
    });

    return dispatch(callApi('user', 'updatePassword', formParams));
  };
}

export function callEditPasswordSuccess() {
  return {
    type: types.USER_EDIT_PASSWORD_SUCCESS
  };
}

export function requestEnableUser(params) {
  return dispatch => {
    dispatch({
      type: types.USER_ENABLE_REQUEST,
      params
    });

    return dispatch(callApi('user', 'enable', params))
      .then(() => {
        refreshPage();
        dispatch(
          addFlashMessage({
            contentKey: 'members_detail_enable_success',
            type: FLASH_MESSAGE_TYPE_SUCCESS
          })
        );
      })
      .catch(() => {
        dispatch(
          addFlashMessage({
            contentKey: 'members_detail_enable_failed',
            type: FLASH_MESSAGE_TYPE_ERROR
          })
        );
      });
  };
}

export function saveProfileSettings({ enableNewUi, enableMobileView, enableVpV1, quickSightTestEmail }) {
  return dispatch => {
    dispatch(updateUiStatus(toBoolean(enableNewUi)));
    dispatch(updateMobileViewStatus(toBoolean(enableMobileView)));
    dispatch(updateVpStatus(toBoolean(enableVpV1)));

    if (isTestEnv()) {
      const qsEmail = safe(() => quickSightTestEmail.trim()) || '';
      dispatch(updateQuickSightTestEmail(qsEmail));
    }

    return Promise.resolve();
  };
}

export function updateVpStatus(status) {
  return {
    type: types.USER_UPDATE_VP_STATUS,
    status
  };
}

export function updateQuickSightTestEmail(email) {
  return {
    type: types.USER_UPDATE_QUICK_SIGHT_TEST_EMAIL,
    email
  };
}

export function updateUiStatus(status) {
  return {
    type: types.USER_UPDATE_UI_STATUS,
    status
  };
}

export function updateMobileViewStatus(status) {
  return {
    type: types.USER_UPDATE_MOBILE_VIEW_STATUS,
    status
  };
}

export function isGranted({ allowed = [], disallowed = [] }) {
  const state = getStore().getState();
  const role = userRoleSelector(state);

  if (!_isArray(allowed)) allowed = [allowed];
  if (!_isArray(disallowed)) disallowed = [disallowed];

  if (_isEmpty(allowed)) {
    allowed = backuserAllRoles;
  }
  if (_isEmpty(disallowed)) disallowed = [];

  return _includes(allowed, role) && !_includes(disallowed, role);
}

export function getQuickSightUrl(payload, loader) {
  return dispatch => {
    dispatch(clearQuickSightError());

    return dispatch(callApi('user', 'getQuickSightUrlApi', cleanDeep(payload), loader)).catch(() => {
      dispatch(setQuickSightError());
      return Promise.reject();
    });
  };
}

export function setQuickSightError() {
  return { type: types.USER_REPORTING_SET_ERROR };
}

export function clearQuickSightError() {
  return { type: types.USER_REPORTING_CLEAR_ERROR };
}

export function startDashboardLoadingSpinner() {
  return (dispatch, getState) => {
    const status = reportingSpinnerStatusSelector(getState());

    if (!status) {
      dispatch(addApiCall());
      dispatch({ type: types.USER_REPORTING_SPINNER_START });
    }
  };
}

export function stopDashboardLoadingSpinner() {
  return (dispatch, getState) => {
    const status = reportingSpinnerStatusSelector(getState());

    if (status) {
      dispatch(subApiCall());
      dispatch({ type: types.USER_REPORTING_SPINNER_STOP });
    }
  };
}
