import { Manager, Reference, Popper } from 'react-popper';
import React from 'react';
import cs from 'classnames';
import PropTypes from 'prop-types';
import { safe } from '../../utils/utils';

const classNames = {
  wrapper: 'popper-wrapper',
  ref: 'popper-ref'
};

class Tooltip extends React.Component {
  componentWillMount() {
    this.setInnerRef = node => {
      this.refNode = node;
    };

    this.fixPosition = () => {
      if (this.refNode) {
        const rect = this.getOffset(this.refNode);

        safe(() => {
          if (rect.left !== this.rect.left || rect.top !== rect.top) {
            this.updatePosition();
          }
        });

        this.rect = rect;
      }
    };

    this.getRefElement = ({ ref }) => {
      return (
        <div className={cs(classNames.ref, this.props.refClass)} ref={ref} onMouseEnter={this.fixPosition}>
          {this.props.children}
        </div>
      );
    };

    this.renderPopup = ({ ref, style, placement, scheduleUpdate }) => {
      this.updatePosition = scheduleUpdate;
      return (
        <div
          ref={ref}
          data-placement={placement}
          className={cs(classNames.wrapper, this.props.themeClass)}
          style={Object.assign(style, this.props.style)}
        >
          {this.props.content}
        </div>
      );
    };
  }

  componentDidMount() {
    this.fixPosition();
  }

  getOffset(el) {
    return safe(() => el.getBoundingClientRect()) || { left: 0, top: 0 };
  }

  render() {
    if (this.props.disabled) {
      return <div>{this.props.children}</div>;
    }

    return (
      <Manager>
        <div className="tooltip-container">
          <Reference innerRef={this.setInnerRef}>{this.getRefElement}</Reference>
          <Popper
            placement={this.props.placement}
            modifiers={Object.assign(
              {
                preventOverflow: {
                  boundariesElement: 'scrollParent',
                  padding: 0
                },
                offset: {
                  offset: `${this.props.offset}, ${this.props.distance}`
                }
              },
              this.props.modifiers
            )}
          >
            {this.renderPopup}
          </Popper>
        </div>
      </Manager>
    );
  }
}

Tooltip.propTypes = {
  disabled: PropTypes.bool, // disable tooltip
  content: PropTypes.any, // tooltip's content
  placement: PropTypes.string, // https://popper.js.org/popper-documentation.html#Popper.placements
  distance: PropTypes.number, // distance between tooltip and reference (pixels)
  offset: PropTypes.number, // shift tooltip left or right (pixels)
  themeClass: PropTypes.string, // class will be applied to content wrapper
  refClass: PropTypes.string, // class will be applied to child wrapper
  style: PropTypes.object, // style object will be applied to content wrapper
  modifiers: PropTypes.object // https://popper.js.org/popper-documentation.html#modifiers
};

Tooltip.defaultProps = {
  themeClass: 'tooltip-grey',
  distance: 10,
  offset: 0
};

export default Tooltip;
