// noinspection ES6CheckImport
import React, { PropTypes } from 'react';
import { FormattedMessage } from 'react-intl';

const MemberAddressInfo = props => {
  const { address } = props;
  const { streetNumber, streetName, city, postalCode, country } = address || {};

  return (
    <div className="memberView_ProfileInfo">
      <h4>
        <FormattedMessage id="member_full_address_info" />
      </h4>
      <div className="memberView_ProfileInfo_body">
        <div className="detailView_table">
          {streetNumber && (
            <div className="detailView_row">
              <span className="detailView_cell_type_2">
                <FormattedMessage id="common_street_number" />
              </span>
              <span className="detailView_cell_type_2">{streetNumber}</span>
            </div>
          )}
          {streetName && (
            <div className="detailView_row">
              <span className="detailView_cell_type_2">
                <FormattedMessage id="common_street_name" />
              </span>
              <span className="detailView_cell_type_2">{streetName}</span>
            </div>
          )}
          {city && (
            <div className="detailView_row">
              <span className="detailView_cell_type_2">
                <FormattedMessage id="common_autocomplete_city" />
              </span>
              <span className="detailView_cell_type_2">{city}</span>
            </div>
          )}
          {postalCode && (
            <div className="detailView_row">
              <span className="detailView_cell_type_2">
                <FormattedMessage id="common_autocomplete_postal_code" />
              </span>
              <span className="detailView_cell_type_2">{postalCode}</span>
            </div>
          )}
          {country && (
            <div className="detailView_row">
              <span className="detailView_cell_type_2">
                <FormattedMessage id="common_autocomplete_country" />
              </span>
              <span className="detailView_cell_type_2">{country}</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

MemberAddressInfo.propTypes = {
  address: PropTypes.object
};

export default MemberAddressInfo;
