/* eslint-disable react/jsx-handler-names */
import React, { Component, PropTypes as T } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import DropDownMenu from 'material-ui/DropDownMenu';
import MenuItem from 'material-ui/MenuItem';
import FilterIcon from 'material-ui/svg-icons/content/filter-list';
import DoneIcon from 'material-ui/svg-icons/action/done';
import Clear from 'material-ui/svg-icons/content/clear';
import DatePicker from 'material-ui/DatePicker';
import { changeFilterType, changeFilterTextInput, textFilterEmpty } from '../AdvancedList.actions';
import autoBind from 'react-autobind';
import _get from 'lodash/get';
import _isFunction from 'lodash/isFunction';
import _partial from 'lodash/partial';
import { getMaterialLocale, namedCompose } from '../../../utils/utils';
import classNames from 'classnames';
import { localeSelector } from '../../../selectors/all-selectors';

class AdvancedListFilters extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.textRefs = {};
  }

  componentDidMount() {
    const CountFilters = Object.keys(this.props.filterTypes);
    if (CountFilters.length === 2) {
      this.props.dispatch(changeFilterType(CountFilters[1]));
    }
  }

  componentWillUnmount() {
    this.props.dispatch(changeFilterType('select_filter'));
  }

  handleSelectOption(value) {
    const { onFilteredSearchForm } = this.props;
    if (_isFunction(onFilteredSearchForm)) {
      onFilteredSearchForm({ [value]: true });
    }
  }

  handleChangleFilterType(event, index, value) {
    const { dispatch, filterTypes } = this.props;

    if (filterTypes[value].type === 'option') {
      this.handleSelectOption(value);
      return;
    }

    dispatch(changeFilterType(value));

    if (filterTypes[value].type === 'text') {
      setTimeout(() => {
        const textRef = this.textRefs[value];
        if (textRef && textRef.focus) textRef.focus();
      }, 50);
    }

    if (filterTypes[value].type === 'date') this.handleOpenPicker();
  }

  handleChangeTextInput(ev) {
    this.props.dispatch(changeFilterTextInput(ev.target.value));
  }

  handleSubmitFilter(e) {
    e.preventDefault();
    if (this.props.filterInputText.trim() === '') {
      this.props.dispatch(textFilterEmpty());
    } else this.filteredSearchForm(this.props.filterInputText);
  }

  handleChangeSelect(event, index, value) {
    this.filteredSearchForm(value);
  }

  handleDateChange(e, value) {
    const { filterTypes, filterValue } = this.props;
    const dateType = _get(filterTypes, `${filterValue}.subtype`);
    let date = value;

    if (e) e.preventDefault();

    date.setMinutes(0);
    date.setSeconds(0);

    if (dateType === 'start') date.setHours(0);
    else if (dateType === 'end') {
      date.setHours(23);
      date.setMinutes(59);
      date.setSeconds(59);
    } else date.setHours(12);
    this.filteredSearchForm(date);
  }

  filteredSearchForm(value) {
    const { onFilteredSearchForm, filterValue, filterTypes, dispatch } = this.props;
    const CountFilters = Object.keys(filterTypes);
    if (filterValue === 'select_filter') return false;
    onFilteredSearchForm({ [filterValue]: value });
    dispatch(changeFilterTextInput(''));
    CountFilters.length === 2 ? dispatch(changeFilterType(CountFilters[1])) : dispatch(changeFilterType('select_filter'));
  }

  setDateRef(el) {
    this.dateRef = el;
  }

  setTextRefs(item, el) {
    this.textRefs[item] = el;
  }

  handleOpenPicker() {
    if (this.dateRef && this.dateRef.openDialog) this.dateRef.openDialog();
  }

  render() {
    const { filterValue, filterTypes, intl, filterInputText, filterSelectValue, id } = this.props;
    const currentType = filterTypes[filterValue].type;
    const style = { marginBottom: '8px' };
    const isDateType = currentType === 'date';

    return (
      <div className="advancedList_filters">
        <div className="advancedList_filtersIcon">
          <FilterIcon />
        </div>
        <DropDownMenu
          value={filterValue}
          onChange={this.handleChangleFilterType}
          underlineStyle={{ border: 0 }}
          style={filterValue === 'select_filter' ? { ...style, width: '100%' } : style}
          labelStyle={{ color: 'rgba(0,0,0,.6)', fontSize: '14px' }}
          id={id + '_filter_type_wrap'}
        >
          {Object.keys(filterTypes).map(item => (
            <MenuItem
              id={id + '_filter_type_' + item}
              value={item}
              primaryText={intl.messages[filterTypes[item].labelKey]}
              key={item}
              disabled={filterTypes[item].disabled}
            />
          ))}
        </DropDownMenu>
        <DatePicker
          id={id + '_filter_value_datePicker' + filterValue}
          ref={this.setDateRef}
          onChange={this.handleDateChange}
          autoOk
          locale={getMaterialLocale(this.props.locale)}
          DateTimeFormat={global.Intl.DateTimeFormat}
          style={{ display: 'none' }}
          name="rci-datepicker"
        />
        <div
          className={classNames(currentType === 'default' ? 'width0' : '', 'advancedList_filtersInput')}
          onClick={isDateType ? this.handleOpenPicker : undefined}
          style={{ cursor: isDateType ? 'pointer' : 'default' }}
        >
          {Object.keys(filterTypes).map(item => {
            if (filterTypes[item].type === 'text') {
              return (
                <form
                  action="#"
                  onSubmit={this.handleSubmitFilter}
                  key={id + 'form' + item}
                  className={filterValue === item ? 'advancedList_filtersForm' : 'advancedList_filtersForm width0'}
                >
                  <input
                    id={id + '_filter_value_input_' + item}
                    type="text"
                    placeholder={filterTypes[item].placeholder}
                    ref={_partial(this.setTextRefs, item)}
                    onChange={this.handleChangeTextInput}
                    value={filterInputText}
                  />
                </form>
              );
            }
          })}
          {currentType === 'select' && (
            <DropDownMenu
              value={filterSelectValue}
              onChange={this.handleChangeSelect}
              underlineStyle={{ border: 0 }}
              style={{ ...style, width: '100%' }}
              labelStyle={{ color: 'rgba(0,0,0,.6)', fontSize: '14px' }}
            >
              {filterTypes[filterValue].options.map(item => {
                const label = item.labelKey ? intl.messages[item.labelKey] : item.value;
                return <MenuItem id={id + '_filter_value_' + item.value} value={item.value} primaryText={label} key={item.value} />;
              })}
            </DropDownMenu>
          )}
          {currentType === 'text' && (
            <div
              id={id + '_filter_confirm_button'}
              className={classNames('advancedList_filtersButton', { disabled: !filterInputText || filterInputText.trim() === '' })}
              onClick={this.handleSubmitFilter}
            >
              {filterInputText.trim() === '' ? <Clear /> : <DoneIcon />}
            </div>
          )}
        </div>
      </div>
    );
  }
}

AdvancedListFilters.propTypes = {
  filterValue: T.string,
  filterTypes: T.object,
  onFilteredSearchForm: T.func,
  id: T.string
};

export default namedCompose(
  connect(state => {
    const {
      advancedList: { filterValue, filterInputText, filterSelectValue }
    } = state;

    return {
      filterValue,
      filterInputText,
      filterSelectValue,
      locale: localeSelector(state)
    };
  }),
  injectIntl
)(AdvancedListFilters);
