import React, { Component, PropTypes } from 'react';
import { FormattedMessage } from 'react-intl';

import EkButton from '../EkButton/EkButton';

class PageManagementHeader extends Component {
  render() {
    const { title, titleKey } = this.props;

    let mainTitle = titleKey ? (
      <span className="pageManagementHeader_title">
        <FormattedMessage id={titleKey} />
      </span>
    ) : (
      <span className="pageManagementHeader_title">{title}</span>
    );

    return (
      <header className={`pageManagementHeader ${this.props.customClass || ''}`}>
        <div className="pageManagementHeader_titleWrapper">{mainTitle}</div>

        {(!this.props.href && !this.props.onActionCallback) || this.props.limitedAccess ? (
          ''
        ) : (
          <div className="pageManagementHeader_actionButton">
            <EkButton
              href={this.props.href}
              skinType="variant3"
              customClass="ekButton--add pageManagementHeader_button"
              onAction={this.props.onActionCallback}
              fullWidth
            >
              <FormattedMessage id={this.props.buttonLabelKey} />
            </EkButton>
          </div>
        )}
      </header>
    );
  }
}

PageManagementHeader.displayName = 'PageManagementHeader';

PageManagementHeader.propTypes = {
  customClass: PropTypes.string,
  title: PropTypes.string,
  titleKey: PropTypes.string,
  buttonLabelKey: PropTypes.string,
  limitedAccess: PropTypes.bool
};

export default PageManagementHeader;
