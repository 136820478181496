import { enhanceBooking } from '../api/data-enhancer';
import * as types from '../constants/actionTypes-constants';
import { creationSteps } from '../containers/AddBooking/AddBooking';
import { BOOKING_COMMENT_INIT_VALUE, PAID_BOOKING_INIT_VALUE } from '../constants/form-constants';

const creationMod = {
  titleID: 'booking_create_title'
};

const editMod = {
  titleID: 'booking_edit_title'
};

const defaultState = {
  editionMode: false,
  titleID: creationMod.titleID,
  stepTitleID: 'booking_create_find_member_subtitle',
  currentStep: creationSteps.findMember,
  selectedMember: null,
  selectedVehicle: null,
  updatePriceResponse: null,
  confirmationDetails: {
    paidBooking: PAID_BOOKING_INIT_VALUE,
    comment: BOOKING_COMMENT_INIT_VALUE
  },
  creationFinalized: false
};

export default function booking(state = defaultState, action) {
  switch (action.type) {
    case types.BOOKING_CREATE_SET_SELECTED_MEMBER:
      return Object.assign({}, state, {
        selectedMember: action.member,
        stepTitleID: 'booking_create_find_vehicle_subtitle',
        currentStep: creationSteps.findVehicle
      });

    case types.BOOKING_CREATE_SET_SELECTED_VEHICLE:
      return Object.assign({}, state, {
        selectedVehicle: enhanceBooking(action.vehicle),
        currentStep: creationSteps.confirmBooking
      });

    case types.BOOKING_CREATE_CLOSE_CONFIRMATION_MODAL:
    case types.BOOKING_CREATE_CONFIRM_CREATION_REQUEST_ERROR:
      return Object.assign({}, state, {
        currentStep: creationSteps.findVehicle,
        creationFinalized: false
      });

    case types.BOOKING_CREATE_SET_CONFIRMATION_DETAILS:
      return Object.assign({}, state, {
        confirmationDetails: action.details
      });

    case types.BOOKING_CREATE_CONFIRM_CREATION_REQUEST_SUCCESS:
      return Object.assign({}, state, {
        creationFinalized: true
      });

    case types.BOOKING_CREATE_SET_EDIT_MODE:
      return Object.assign({}, state, {
        editionMode: true,
        titleID: editMod.titleID
      });

    case types.BOOKING_UPDATE_PRICE_CHECK_SUCCESS:
      return {
        ...state,
        updatePriceResponse: action.data
      };

    case types.BOOKING_UPDATE_PRICE_RESPONSE_REMOVE:
      return {
        ...state,
        updatePriceResponse: null
      };

    case 'redux-form/CHANGE':
      if (action.form === 'updateBookingPriceForm') {
        return {
          ...state,
          updatePriceResponse: null
        };
      }
      return state;

    case types.BOOKING_UPDATE_PRICE_MODAL_TOGGLE:
      return {
        ...state,
        updatePriceResponse: null
      };

    case types.BOOKING_CREATE_CLEAR_CREATION_SESSION:
      return defaultState;

    default:
      return state;
  }
}
