import React from 'react';
import classnames from 'classnames';
import ListWrap from '../../../components/_v2/ColumnList/Wrap';

export default function Vouchers(props) {
  return (
    <div className={classnames('pageContainer', 'vouchers-v2')}>
      <ListWrap {...props} />
    </div>
  );
}
