import React, { Component } from 'react';
import { connect } from 'react-redux';

import VehiclesCategoryForm from '../../components/VehiclesCategoryForm/VehiclesCategoryForm';
import { updateCategoryRequest } from '../../actions/all-actions';
import { BACKUSER_ROLE_CALL_CENTER_OPERATOR } from '../../constants/backend-constants';

class EditVehiclesCategory extends Component {
  componentWillMount() {
    this.handleUpdateCategory = data => {
      this.props.dispatch(updateCategoryRequest({ id: this.props.currentCategory.id, ...data }));
    };
  }

  render() {
    const { currentCategory, role } = this.props;

    return (
      <div className="editVehiclesCategoryPage mainContainer_content">
        <div className="pageContainer">
          <div className="editVehiclesCategoryPage_content">
            <section className="editVehiclesCategoryPage_management">
              <VehiclesCategoryForm
                onCallback={this.handleUpdateCategory}
                limitedAccess={role === BACKUSER_ROLE_CALL_CENTER_OPERATOR}
                initialValues={{
                  type: currentCategory.type,
                  description: currentCategory.description
                }}
              />
            </section>
          </div>
        </div>
      </div>
    );
  }
}

EditVehiclesCategory.displayName = 'EditVehiclesCategory';

export default connect(state => {
  const {
    user: {
      userInfo: { role }
    },
    categories: { currentCategory }
  } = state;

  return {
    currentCategory,
    role
  };
})(EditVehiclesCategory);
