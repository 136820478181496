import React from 'react';

const style = {
  display: 'flex',
  justifyContent: 'flex-end'
};

function ButtonWrapper({ children }) {
  return <div style={style}>{children}</div>;
}

ButtonWrapper.displayName = 'ButtonWrapper';

export default ButtonWrapper;
