import callApi from './api-actions';
import { enhanceBookingCreationRequestParams, parseHeader } from '../api/data-enhancer';
import * as types from '../constants/actionTypes-constants';
import { addFlashMessage } from './all-actions';
import { bookingCustomFieldsSelector, planningBookingEditCustomFieldsSelector } from '../selectors/all-selectors';
import { createCustomFieldsValues, safe, setNewEndDate, trimValues, vehicleUsageSwitch } from '../utils/utils';
import { FLASH_MESSAGE_TYPE_ERROR, FLASH_MESSAGE_TYPE_SUCCESS } from '../constants/generic-constants';
import _get from 'lodash/get';

export function setBookingSelectedMember(member) {
  return {
    type: types.BOOKING_CREATE_SET_SELECTED_MEMBER,
    member
  };
}

export function setBookingSelectedVehicle(vehicle) {
  return {
    type: types.BOOKING_CREATE_SET_SELECTED_VEHICLE,
    vehicle
  };
}

export function closeConfirmationModal() {
  return {
    type: types.BOOKING_CREATE_CLOSE_CONFIRMATION_MODAL
  };
}

export function setBookingConfirmationDetails(details) {
  return {
    type: types.BOOKING_CREATE_SET_CONFIRMATION_DETAILS,
    details
  };
}

export function clearBookingListData(details) {
  return {
    type: types.BOOKING_CLEAR_LIST_DATA,
    details
  };
}

export function clearAllBookingData() {
  return {
    type: types.BOOKING_CLEAR_ALL_DATA
  };
}

export function confirmBookingCreation(...payload) {
  return (dispatch, getState) => {
    const state = getState();

    const enhancedPayload = enhanceBookingCreationRequestParams(...payload);
    const customFields = bookingCustomFieldsSelector(state);
    const { detailMember } = state.members;
    const fo = state.form.createBookingConfirmationForm;

    if (!!safe(() => detailMember.enterprise.chorusData)) {
      const chorusData = {
        administration: true,
        chorusAlias: fo.chorusAlias.value,
        chorusEngagement: fo.chorusEngagement.value,
        chorusMarche: fo.chorusMarche.value,
        chorusService: fo.chorusService.value
      };
      enhancedPayload.chorusData = { ...chorusData };
    }
    if (_get(customFields, 'length')) {
      enhancedPayload.bookingCustomValues = createCustomFieldsValues(customFields, trimValues(state.form.createBookingConfirmationForm));
    }

    dispatch({
      type: types.BOOKING_CREATE_CONFIRM_CREATION_REQUEST
    });

    return dispatch(callApi('bookings', 'create', enhancedPayload)).then(function(data) {
      dispatch({
        type: types.BOOKING_CREATE_CONFIRM_CREATION_REQUEST_SUCCESS
      });

      return data;
    });
  };
}

export function confirmBookingEdition(...payload) {
  return (dispatch, getState) => {
    const state = getState();
    const enhancedPayload = enhanceBookingCreationRequestParams(...payload);
    const customFields = planningBookingEditCustomFieldsSelector(state);
    const formValues = trimValues(state.form.VehiclePlanningEditBooking) || trimValues(state.form.VehiclePlanningChangeVehicle);

    if (_get(customFields, 'length')) {
      const bookingCustomValues = createCustomFieldsValues(customFields, formValues);
      if (bookingCustomValues) enhancedPayload.bookingCustomValues = bookingCustomValues;
    }

    if (formValues && formValues.usageOverride) {
      enhancedPayload.usageOverride = formValues.usageOverride;
      enhancedPayload.replacementVehicle = vehicleUsageSwitch(formValues.usageOverride);
    }

    dispatch({
      type: types.BOOKING_CREATE_CONFIRM_EDITION_REQUEST
    });

    return dispatch(callApi('bookings', 'replace', enhancedPayload)).then(function(data) {
      dispatch({
        type: types.BOOKING_CREATE_CONFIRM_EDITION_REQUEST_SUCCESS
      });

      return data;
    });
  };
}

export function bookingCreationErrored() {
  return {
    type: types.BOOKING_CREATE_CONFIRM_CREATION_REQUEST_ERROR
  };
}

export function clearBookingCreationSession() {
  return {
    type: types.BOOKING_CREATE_CLEAR_CREATION_SESSION
  };
}

export function setBookingEdtionMode() {
  return {
    type: types.BOOKING_CREATE_SET_EDIT_MODE
  };
}

export function toggleUpdatePriceModal() {
  return (dispatch, getState) => {
    const state = getState();
    const data = {
      bookingDetail: state.bookings.bookingDetail,
      ...trimValues(state.form.updateBookingPriceForm)
    };
    return dispatch({
      type: types.BOOKING_UPDATE_PRICE_MODAL_TOGGLE,
      data
    });
  };
}

export function bookingUpdatePriceCheck() {
  return (dispatch, getState) => {
    const state = getState();

    let params = {
      ...trimValues(state.form.updateBookingPriceForm),
      bookingId: state.bookings.bookingDetail.id
    };
    const newDate = params.effectiveEndDate;
    const newTime = params.effectiveEndTime;
    if (newDate !== undefined && newTime !== undefined) {
      params.effectiveEndDate = setNewEndDate(newDate, newTime);
      delete params.effectiveEndTime;
    }

    return dispatch(callApi('bookings', 'updatePriceCheck', params))
      .then(function(data) {
        dispatch({
          type: types.BOOKING_UPDATE_PRICE_CHECK_SUCCESS,
          data
        });

        return data;
      })
      .catch(error => {
        parseHeader(error.response, resp => {
          dispatch(
            addFlashMessage({
              contentKey: resp.errorCode || 'error_server_unknown',
              type: FLASH_MESSAGE_TYPE_ERROR
            })
          );
        });
      });
  };
}

export function bookingUpdatePrice() {
  return (dispatch, getState) => {
    const state = getState();

    const params = {
      ...trimValues(state.form.updateBookingPriceForm),
      bookingId: state.bookings.bookingDetail.id
    };

    const newDate = params.effectiveEndDate;
    const newTime = params.effectiveEndTime;

    if (newDate !== undefined && newTime !== undefined) {
      params.effectiveEndDate = setNewEndDate(newDate, newTime);
      delete params.effectiveEndTime;
    }

    return dispatch(callApi('bookings', 'updatePrice', params))
      .then(data => {
        dispatch(
          addFlashMessage({
            contentKey: 'booking_price_update_success',
            type: FLASH_MESSAGE_TYPE_SUCCESS
          })
        );
        return data;
      })
      .catch(error => {
        error.response.json().then(res => {
          dispatch(
            addFlashMessage({
              contentKey: _get(res, 'errorCode') || 'error_server_unknown',
              type: FLASH_MESSAGE_TYPE_ERROR
            })
          );
        });
      });
  };
}

export function updatePriceResponseRemove() {
  return {
    type: types.BOOKING_UPDATE_PRICE_RESPONSE_REMOVE
  };
}
