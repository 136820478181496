import React, { Component, PropTypes } from 'react';
import { connect } from 'react-redux';
import { routeActions } from 'react-router-redux';
import _ from 'lodash';
import { Row, Col } from 'react-bootstrap';
import FlatButton from 'material-ui/FlatButton';
import { injectIntl } from 'react-intl';
import { safe } from '../../utils/utils';
import AddIcon from 'material-ui/svg-icons/content/add';

import AdvancedList from '../../components/AdvancedList/AdvancedList';
import routes, { editCompanyRules } from '../../constants/routes-constants';
import { getCompaniesListWithSearch } from '../../actions/all-actions';
import { companiesHeaderParams } from '../../constants/table-constants';
import FilterChips from '../../components/FilterChips/FilterChips';
import Filters from '../../components/AdvancedList/components/Filters';
import { companiesFilterTypes } from '../../constants/filterTypes-constants';
import { companiesFilterChips } from '../../constants/options-constants';
import IsGranted from '../../components/IsGranted/IsGranted';

class Companies extends Component {
  constructor(props) {
    super(props);
    this.bindFunctions();
  }

  bindFunctions() {
    this.handleNumberResultsSelect = this.handleNumberResultsSelect.bind(this);
    this.renderItem = this.renderItem.bind(this);
    this.handleGotoDetail = this.handleGotoDetail.bind(this);
    this.handleChangePage = this.handleChangePage.bind(this);
    this.handleGoToAddCompany = this.handleGoToAddCompany.bind(this);
    this.handleSearchForm = this.handleSearchForm.bind(this);
    this.handleDeleteChip = this.handleDeleteChip.bind(this);
    this.handleSort = this.handleSort.bind(this);
  }

  componentWillMount() {
    const { pageParams } = this.props;
    this.callApi(pageParams);
  }

  callApi(params) {
    const { dispatch } = this.props;
    dispatch(getCompaniesListWithSearch(params));
  }

  renderItem(item, id, reactKey) {
    const colProps = { xs: 4, md: 4 };

    return (
      <div className="advancedList_rowWrap" key={reactKey}>
        <Row
          id={id}
          className="advancedList_row bookingsPage_listRow"
          onClick={_.partial(this.handleGotoDetail, ['companyDetail', ':companyId', item.id])}
        >
          <Col {...colProps}>{item.name}</Col>
          <Col xs={6} md={6}>
            {item.email}
          </Col>
          <Col xs={2} md={2}>
            {item.phoneNumber}
          </Col>
        </Row>
      </div>
    );
  }

  handleChangePage(value) {
    const { dispatch, listMetadata, urlParams } = this.props;
    const params = {
      page: {
        number: value,
        size: listMetadata.paginationInfo.pageSize
      }
    };
    const newParams = { ...urlParams, ...params };
    this.callApi(newParams);
    dispatch(routeActions.push(routes.companies.path.replace(':search', encodeURIComponent(JSON.stringify(newParams)))));
  }

  handleNumberResultsSelect(value) {
    const { dispatch, urlParams } = this.props;
    let params = {
      page: {
        number: 1,
        size: parseInt(value)
      }
    };
    let newParams = Object.assign({}, urlParams, params);
    this.callApi(newParams);
    dispatch(routeActions.push(routes.companies.path.replace(':search', encodeURIComponent(JSON.stringify(newParams)))));
  }

  handleGotoDetail(params) {
    this.props.dispatch(routeActions.push(routes[params[0]].path.replace(params[1], params[2])));
  }

  handleGoToAddCompany() {
    this.props.dispatch(routeActions.push(routes.addCompany.path));
  }

  handleSearchForm(params) {
    const { dispatch, listMetadata, urlParams } = this.props;

    params.page = {
      number: 1,
      size: safe(() => listMetadata.paginationInfo.pageSize) || 50
    };

    let newParams = {
      ...urlParams,
      ...params
    };

    this.callApi(newParams);

    dispatch(routeActions.push(routes.companies.path.replace(':search', encodeURIComponent(JSON.stringify(newParams)))));
  }

  handleDeleteChip(item) {
    let { urlParams, dispatch } = this.props;
    delete urlParams[item];
    this.callApi(urlParams);
    dispatch(routeActions.push(routes.companies.path.replace(':search', encodeURIComponent(JSON.stringify(urlParams)))));
  }

  handleSort(sortPropertyName) {
    const { dispatch, urlParams } = this.props;
    const newParams = {
      ...urlParams,
      sort: {
        isDescending: _.get(urlParams, 'sort.property', false) === sortPropertyName ? !urlParams.sort.isDescending : false,
        property: sortPropertyName
      }
    };
    this.callApi(newParams);
    dispatch(routeActions.push(routes.companies.path.replace(':search', encodeURIComponent(JSON.stringify(newParams)))));
  }

  render() {
    const { list, listMetadata, urlParams, loadingList, errorList, intl } = this.props;

    return (
      <div className="companiesPage mainContainer_content">
        <div className="pageContainer">
          <div className="companiesPage_content">
            <section className="companiesPage_management">
              <FilterChips
                urlParams={urlParams}
                onDeleteChip={this.handleDeleteChip}
                translations={companiesFilterChips}
                filterTypes={companiesFilterTypes}
                id="companies"
              />

              <div className="advancedList_actions page">
                <Filters filterTypes={companiesFilterTypes} onFilteredSearchForm={this.handleSearchForm} id="companies" />

                <div className="bookingsPage_actionsButtons">
                  <IsGranted allowed={editCompanyRules.include} disallowed={editCompanyRules.exclude}>
                    <FlatButton
                      id="companies_add_company_button"
                      label={intl.messages.addCompany_title}
                      icon={<AddIcon />}
                      onClick={this.handleGoToAddCompany}
                    />
                  </IsGranted>
                </div>
              </div>

              <AdvancedList
                id="companies"
                data={list}
                listMetadata={listMetadata}
                loading={loadingList}
                renderItem={this.renderItem}
                error={errorList}
                urlParams={urlParams}
                header={companiesHeaderParams}
                onChangeRowsPerPage={this.handleNumberResultsSelect}
                onChangePage={this.handleChangePage}
                onSort={this.handleSort}
              />
            </section>
          </div>
        </div>
      </div>
    );
  }
}

Companies.displayName = 'Companies';

Companies.propTypes = {
  list: PropTypes.array,
  listMetadata: PropTypes.object
};

export default connect(state => {
  const {
    user: {
      userInfo: { role }
    },
    companies: { paginatedResults, urlParams, loadingList, errorList },
    page: { params }
  } = state;

  return {
    list: _.get(paginatedResults, 'results', []),
    listMetadata: _.get(paginatedResults, 'metadata', {}),
    role,
    urlParams,
    pageParams: params,
    loadingList,
    errorList
  };
})(injectIntl(Companies));
