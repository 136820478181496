import { UPDATE_LOCATION } from 'react-router-redux';
import * as types from '../constants/actionTypes-constants';

const defaultState = {
  paginatedResults: [],
  currentSortedIndex: null,
  sortIsDescending: false,
  urlParams: {}
};

export default function(state = defaultState, action) {
  switch (action.type) {
    case types.MEMBERS_GET_LIST_SUCCESS:
      return Object.assign({}, state, {
        paginatedResults: action.paginatedObj,
        urlParams: action.urlParams
      });

    case types.BOOKING_FIND_MEMBER_SET_SORT_DIRECTION:
      return Object.assign({}, state, {
        currentSortedIndex: action.index
      });

    case types.BOOKING_FIND_MEMBER_SET_CURRENT_SORTED_INDEX:
      return Object.assign({}, state, {
        sortIsDescending: action.isDescending
      });

    case UPDATE_LOCATION:
      return Object.assign({}, state, {
        currentSortedIndex: null,
        sortIsDescending: false
      });

    case types.BOOKING_CREATE_CLEAR_CREATION_SESSION:
      return defaultState;

    default:
      return state;
  }
}
