export const BACKUSER_ROLE_ADMIN = 'ROLE_ADMIN';
export const BACKUSER_ROLE_FLEET_MANAGER = 'ROLE_FLEET_MANAGER';
export const BACKUSER_ROLE_CALL_CENTER_OPERATOR = 'ROLE_CALL_CENTER_OPERATOR';
export const BACKUSER_ROLE_SUPER_ADMIN = 'ROLE_SUPER_ADMIN';
export const BACKUSER_ROLE_ROOT = 'ROLE_ROOT';
export const BACKUSER_ROLE_ZONE_MANAGER = 'ROLE_ZONE_MANAGER';
export const BACKUSER_ROLE_ADMIN_DEALER = 'ROLE_ADMIN_DEALER';
export const BACKUSER_ROLE_CRM = 'ROLE_CRM';
export const BACKUSER_ROLE_SERVICE_ADVISOR = 'ROLE_SERVICE_ADVISOR';
export const BACKUSER_ROLE_EXTERNAL_FINE_COMPANY = 'ROLE_EXTERNAL_FINE_COMPANY';
export const BACKUSER_ROLE_FIELD_AGENT = 'ROLE_FIELD_AGENT';
export const COMPANY_ACCESS_PRIVATE = 'PRIVATE';
export const COMPANY_ACCESS_PUBLIC = 'PUBLIC';
export const ONE_WAY_TRIP = 'oneWayTrip';
export const ROUND_TRIP = 'roundTrip';

export const DAY_OF_WEEK_MONDAY = 'MONDAY';
export const DAY_OF_WEEK_TUESDAY = 'TUESDAY';
export const DAY_OF_WEEK_WEDNESDAY = 'WEDNESDAY';
export const DAY_OF_WEEK_THURSDAY = 'THURSDAY';
export const DAY_OF_WEEK_FRIDAY = 'FRIDAY';
export const DAY_OF_WEEK_SATURDAY = 'SATURDAY';
export const DAY_OF_WEEK_SUNDAY = 'SUNDAY';

export const FREQUENCY_DAILY = 'DAILY';
export const FREQUENCY_MONTHLY = 'MONTHLY';
export const FREQUENCY_WEEKLY = 'WEEKLY';

export const TIME_SLOT_USAGE_BUSINESS = 'BUSINESS';
export const TIME_SLOT_USAGE_PRIVATE = 'PRIVATE';
export const TIME_SLOT_TYPE_PRICE_PER_HOUR = 'PRICE_PER_HOUR';
export const TIME_SLOT_TYPE_PACKAGE_BETWEEN_DATES = 'PACKAGE_BETWEEN_DATES';
export const TIME_SLOT_TYPE_PACKAGE_FOR_DURATION = 'PACKAGE_FOR_DURATION';

export const MEMBER_TYPE_CATEGORY_PROFESSIONNAL = 'PROFESSIONAL';
export const MEMBER_TYPE_CATEGORY_CUSTOM = 'CUSTOM';

export const TIME_SLOT_TAG_DAY_HOURLY_RATE = 'DAY_HOURLY_RATE';
export const TIME_SLOT_TAG_NIGHT_HOURLY_RATE = 'NIGHT_HOURLY_RATE';
export const TIME_SLOT_TAG_DAY_PACKAGE = 'DAY_PACKAGE';
export const TIME_SLOT_TAG_NIGHT_PACKAGE = 'NIGHT_PACKAGE';
export const TIME_SLOT_TAG_WEEK_END_PACKAGE = 'WEEK_END_PACKAGE';
export const TIME_SLOT_TAG_WEEK_PACKAGE = 'WEEK_PACKAGE';
export const TIME_SLOT_TAG_MONTH_PACKAGE = 'MONTH_PACKAGE';

export const BOOKING_STATUS_SCHEDULED = 'SCHEDULED';
export const BOOKING_STATUS_IN_PROGRESS = 'IN_PROGRESS';
export const BOOKING_STATUS_PRE_BOOKED = 'PRE_BOOKED';
export const BOOKING_STATUS_COMPLETED = 'COMPLETED';
export const STATUS_CANCELED = 'CANCELED';

export const BOOKING_TYPE_CAR_SHARING = 'CAR_SHARING';
export const BOOKING_TYPE_RIDE_SHARING = 'RIDE_SHARING';
export const BOOKING_TYPE_GROUP_SHARING = 'GROUP_SHARING';
export const BOOKING_TYPE_RRS_SHARING = 'RRS_SHARING';
export const BOOKING_RETRY_RRS_COMPLETE = 'COMPLETE';
export const BOOKING_RETRY_RRS_CANCEL_WITH_FEES = 'CANCEL_WITH_FEES';
export const BOOKING_RETRY_RRS_CANCEL_WITHOUT_FEES = 'CANCEL_WITHOUT_FEES';

export const BOOKING_USAGE_TYPE_BUSINESS = 'BUSINESS';
export const BOOKING_USAGE_TYPE_PRIVATE = 'PRIVATE';
export const BE_ALL = 'ALL';
export const URL_ALL = 'all';

export const BOOKING_STATUS_REASON_OTHER = 'OTHER';
export const BOOKING_STATUS_ORIGIN_OTHER = 'OTHER';

export const SORT_PROPERTY_NAME = 'NAME';
export const SORT_PROPERTY_CODE = 'CODE';
export const SORT_PROPERTY_LASTNAME = 'LASTNAME';
export const SORT_PROPERTY_FIRSTNAME = 'FIRSTNAME';
export const SORT_PROPERTY_EMAIL = 'EMAIL';
export const SORT_PROPERTY_STATUS_ENABLED = 'ENABLED';
export const SORT_PROPERTY_SUSPENDED = 'SUSPENDED';
export const SORT_PROPERTY_ROLE = 'ROLE';
export const SORT_PROPERTY_COMPANY_NAME = 'COMPANY_NAME';

export const SORT_PROPERTY_MEMBER_TYPE = 'MEMBER_TYPE';
export const SORT_PROPERTY_BOOKING_ID = 'BOOKING_ID';
export const SORT_PROPERTY_MEMBER_NAME = 'MEMBER_NAME';
export const SORT_PROPERTY_START_DATE = 'START_DATE';
export const SORT_PROPERTY_END_DATE = 'END_DATE';
export const SORT_PROPERTY_VEHICLE_BRAND_MODEL = 'VEHICLE_BRAND_MODEL';
export const SORT_PROPERTY_VEHICLE_BRAND_NAME = 'VEHICLE_BRAND_NAME';
export const SORT_PROPERTY_BRAND = 'BRAND';
export const SORT_PROPERTY_VEHICLE_PLATE_NUMBER = 'VEHICLE_PLATE_NUMBER';
export const SORT_PROPERTY_VEHICLE_USAGE = 'USAGE';
export const SORT_PROPERTY_VEHICLE_USAGE_WHY = 'VEHICLE_USAGE_AT_BOOKING_CREATION';
export const SORT_PROPERTY_PLATE_NUMBER = 'PLATE_NUMBER';
export const SORT_PROPERTY_VOUCHER_GROUP_NAME = 'VOUCHER_GROUP_NAME';
export const SORT_PROPERTY_CREATION_DATE = 'CREATION_DATE';
export const SORT_PROPERTY_REVOKED = 'REVOKED';
export const SORT_PROPERTY_VOUCHER_TYPE = 'VOUCHER_TYPE';
export const SORT_PROPERTY_USES = 'USES';
export const SORT_PROPERTY_USAGE = 'USAGE';

export const SORT_PROPERTY_STATUS = 'STATUS';
export const SORT_PROPERTY_TYPE = 'TYPE';
export const SORT_PROPERTY_CREATED_DATE = 'CREATED_DATE';
export const SORT_PROPERTY_ARCHIVED = 'ARCHIVED';
export const SORT_PROPERTY_FUEL_TYPE = 'FUEL_TYPE';
export const SORT_PROPERTY_MODEL = 'MODEL';

export const SORT_PROPERTY_VEHICLE_START_OUTSIDE_CLEANLINESS = 'START_OUTSIDE_CLEANLINESS';
export const SORT_PROPERTY_VEHICLE_START_INSIDE_CLEANLINESS = 'START_INSIDE_CLEANLINESS';
export const SORT_PROPERTY_VEHICLE_END_OUTSIDE_CLEANLINESS = 'END_OUTSIDE_CLEANLINESS';
export const SORT_PROPERTY_VEHICLE_END_INSIDE_CLEANLINESS = 'END_INSIDE_CLEANLINESS';
export const SORT_PROPERTY_NUMBER_OF_DECLARED_DAMAGES = 'NUMBER_OF_DAMAGES_DECLARED';
export const SORT_PROPERTY_GROUP_ID = 'PUBLIC_ID';
export const SORT_PROPERTY_GROUP_SIZE = 'GROUP_SIZE';
export const SORT_PROPERTY_GROUP_SITE = 'SITE_NAME';
export const SORT_PROPERTY_GROUP_STATUS = 'GROUP_STATE';
export const SORT_PROPERTY_DAMAGE_AREA = 'DAMAGE_AREA';
export const SORT_PROPERTY_DAMAGE_TYPE = 'DAMAGE_TYPE';
export const SORT_PROPERTY_DAMAGE_STATUS = 'STATUS';

export const PAYMENT_STATUS_PAID = 'PAID';
export const PAYMENT_STATUS_UNPAID = 'UNPAID';
export const STATUS_CANCELLED = 'CANCELLED';

export const BANKOUT_STATUS_PENDING = 'PENDING';
export const BANKOUT_STATUS_PROCESSING = 'PROCESSING';
export const BANKOUT_STATUS_EXECUTED = 'EXECUTED';
export const BANKOUT_STATUS_SUCCESS = 'SUCCESS';
export const BANKOUT_STATUS_ERROR = 'ERROR';

export const BILLING_ENTITY_TYPE_SUPER_COMPANY_ALT = 'SUPER COMPANY';
export const BILLING_ENTITY_TYPE_SUPER_COMPANY = 'SUPER_COMPANY';
export const BILLING_ENTITY_TYPE_COMPANY = 'COMPANY';

export const SORT_PROPERTY_BILLING_ENTITY_TYPE = 'BILLING_ENTITY_TYPE';
export const SORT_PROPERTY_BILLING_ENTITY_NAME = 'BILLING_ENTITY_NAME';
export const SORT_PROPERTY_BANKOUT_STATUS = 'BANKOUT_STATUS';
export const SORT_PROPERTY_BANKOUT_EXECUTION_DATE = 'BANKOUT_EXECUTION_DATE';

export const SORT_PROPERTY_PAYBACK_AMOUNT = 'amount';
export const SORT_PROPERTY_PAYBACK_CREATION_DATE = 'creationDate';
export const SORT_PROPERTY_PAYBACK_STATUS = 'SORT_PROPERTY_PAYBACK_STATUS';

export const STATUS_ACTIVE = 'ACTIVE';
export const STATUS_PENDING = 'PENDING';
export const CLEANLINESS_STATUS_CLEAN = 'CLEAN';
export const CLEANLINESS_STATUS_DIRTY = 'DIRTY';

export const BACK_USER = 'BACK_USER';
export const FEEDBACK_STATUS_NEW = 'NEW';
export const FEEDBACK_STATUS_SEEN = 'SEEN';
export const FEEDBACK_STATUS_HANDLED = 'HANDLED';
export const INVOICE_STATUS_SUSPENDED = 'SUSPENDED';
export const INVOICE_STATUS_UNSUSPENDED = 'UNSUSPENDED';

export const INVOICE_TYPE = {
  INVOICE: 'INVOICE',
  MISC_INVOICE: 'MISC_INVOICE',
  CREDIT_NOTE: 'CREDIT_NOTE',
  COMMISSION: 'COMMISSION'
};

export const SITE_SMARTCARD_SPONTANEOUS_USAGE_BUSINESS = 'BUSINESS';
export const SITE_SMARTCARD_SPONTANEOUS_USAGE_PRIVATE = 'PRIVATE';

export const STATUS_ERROR = 'ERROR';
export const WORLDPAY_STATUS_SUCCESS = 'SUCCESS';

export const SMARTCARDS_EVENTS_RESULT_SUCCESS = 'SUCCESS';
export const SMARTCARDS_EVENTS_RESULT_FAILURE_EVENT_TIME_OUT = 'FAILURE_EVENT_TIME_OUT';
export const SMARTCARDS_EVENTS_RESULT_FAILURE_DUPLICATED_DEVICE_ID = 'FAILURE_DUPLICATED_DEVICE_ID';
export const SMARTCARDS_EVENTS_RESULT_FAILURE_LOCK_STATUS_UNKNOWN = 'FAILURE_LOCK_STATUS_UNKNOWN';
export const SMARTCARDS_EVENTS_RESULT_FAILURE_SMARTCARD_NOT_REGISTERED = 'FAILURE_SMARTCARD_NOT_REGISTERED';
export const SMARTCARDS_EVENTS_RESULT_FAILURE_USER_NOT_ACTIVATED_OR_SUSPENDED = 'FAILURE_USER_NOT_ACTIVATED_OR_SUSPENDED';
export const SMARTCARDS_EVENTS_RESULT_FAILURE_DIFFERENT_COMPANIES = 'FAILURE_DIFFERENT_COMPANIES';
export const SMARTCARDS_EVENTS_RESULT_FAILURE_VEHICLE_NOT_REGISTERED = 'FAILURE_VEHICLE_NOT_REGISTERED';
export const SMARTCARDS_EVENTS_RESULT_FAILURE_NO_VEHICLE_SITE = 'FAILURE_NO_VEHICLE_SITE';
export const SMARTCARDS_EVENTS_RESULT_FAILURE_SMARTCARD_NOT_ALLOWED = 'FAILURE_SMARTCARD_NOT_ALLOWED';
export const SMARTCARDS_EVENTS_RESULT_FAILURE_SMARTCARD_DEACTIVATED = 'FAILURE_SMARTCARD_DEACTIVATED';
export const SMARTCARDS_EVENTS_RESULT_FAILURE_NO_BOOKING = 'FAILURE_NO_BOOKING';
export const SMARTCARDS_EVENTS_RESULT_FAILURE_UNLOCK_ERROR = 'FAILURE_UNLOCK_ERROR';
export const SMARTCARDS_EVENTS_RESULT_FAILURE_LOCK_ERROR = 'FAILURE_LOCK_ERROR';
export const SMARTCARDS_EVENTS_RESULT_FAILURE_NO_SPONTANEOUS_BOOKING_AVAILABILITY = 'FAILURE_NO_SPONTANEOUS_BOOKING_AVAILABILITY';
export const SMARTCARDS_EVENTS_RESULT_FAILURE_NO_USER_ASSIGNED = 'FAILURE_NO_USER_ASSIGNED';
export const SMARTCARDS_EVENTS_RESULT_FAILURE_FISHING_DENIED = 'FAILURE_FISHING_DENIED';
export const SMARTCARDS_EVENTS_RESULT_FAILURE_FISHING_NO_ANSWER = 'FAILURE_FISHING_NO_ANSWER';
export const SMARTCARDS_EVENTS_RESULT_FAILURE_FISHING_ANOTHER_SMARTCARD_ASSIGNED = 'FAILURE_FISHING_ANOTHER_SMARTCARD_ASSIGNED';
export const SMARTCARDS_EVENTS_RESULT_FAILURE_FISHING_TIME_OUT = 'FAILURE_FISHING_TIME_OUT';
export const SMARTCARDS_EVENTS_RESULT_FAILURE_UNKNOWN_ERROR = 'FAILURE_UNKNOWN_ERROR';
export const SMARTCARDS_EVENTS_RESULT_FISHING_CALL_DONE = 'FISHING_CALL_DONE';

export const SMARTCARDS_EVENTS_ACTION_OPEN = 'OPEN';
export const SMARTCARDS_EVENTS_ACTION_CLOSE = 'CLOSE';
export const SMARTCARDS_EVENTS_ACTION_UNKNOWN = 'UNKNOWN';

export const SORT_PROPERTY_INVOICE_NUMBER = 'REFERENCE';
export const SORT_PROPERTY_INVOICE_TYPE = 'TYPE';
export const SORT_INVOICE_STATUS = 'status';
export const SORT_INVOICE_CREATED_DATE = 'CREATED_DATE';
export const SORT_INVOICE_VEHICLE_REGISTRATION_NUMBER = 'VEHICLE_REGISTRATION_NUMBER';
export const SORT_INVOICE_EMISSIOM_DATE = 'emissionDate';
export const SORT_INVOICE_LASTNAME = 'lastName';
export const SORT_PROPERTY_INVOICE_VALUE = 'priceIncludingTaxes';
export const SORT_PROPERTY_INVOICE_VAT_RATE = 'vatRate';

export const VEHICLE_SYSTEM_INVERS = 'INVERSE';
export const VEHICLE_SYSTEM_MOVINBLUE = 'MOVINBLUE';
export const VEHICLE_SYSTEM_VIRTUALKEY = 'VIRTUALKEY';

export const MEMBER_ADD_COMMENT = 'ADD_COMMENT';
export const MEMBER_SUSPEND_MEMBER = 'SUSPEND_MEMBER';
export const MEMBER_INFORMATION = 'MEMBER_INFORMATION';
export const MEMBER_DRIVING_LICENCE = 'MEMBER_DRIVING_LICENCE';
export const MEMBER_ID_DOCUMENT = 'MEMBER_ID_DOCUMENT';
export const MEMBER_EMPLOYER_CERTIFICATE = 'MEMBER_EMPLOYER_CERTIFICATE';

export const STATUS_APPLIED = 'APPLIED';
export const STATUS_TO_COMPLETE = 'TO_COMPLETE';
export const STATUS_TO_REVIEW = 'TO_REVIEW';
export const STATUS_APPROVED = 'APPROVED';
export const STATUS_NOT_APPROVED = 'NOT_APPROVED';

export const CUSTOM_FIELD_TYPE_TEXT = 'TEXT';
export const CUSTOM_FIELD_TYPE_NUMERIC = 'NUMERIC';
export const CUSTOM_FIELD_TYPE_BOOLEAN = 'BOOLEAN';
export const CUSTOM_FIELD_TYPE_PHONE_NUMBER = 'PHONE_NUMBER';
export const CUSTOM_FIELD_TYPE_FILE = 'FILE';
export const CUSTOM_FIELD_CONDITIONED = 'CONDITIONED';
export const CUSTOM_FIELD_YES = 'YES';
export const CUSTOM_FIELD_EXTERNAL_KEY = 'EXTERNALID';

export const CUSTOM_FIELD_FORM_SUBSCRIPTION = 'SUBSCRIPTION';
export const CUSTOM_FIELD_FORM_BOOKING = 'CAR_SHARING_BOOKING';

export const MEMBER_SUBSCRIPTION_ORIGIN_WEB_FORM = 'WEB_FORM';
export const MEMBER_SUBSCRIPTION_ORIGIN_MOBILE_FORM = 'MOBILE_FORM';
export const MEMBER_SUBSCRIPTION_ORIGIN_IMPORT = 'IMPORT';
export const MEMBER_SUBSCRIPTION_ORIGIN_RRS = 'RRS';
export const MEMBER_SUBSCRIPTION_ORIGIN_PUBLIC_API = 'PUBLIC_API';

export const VEHICLE_DAMAGE_STATUS_LIST_ACTIVE = 'ACTIVE';
export const VEHICLE_DAMAGE_STATUS_LIST_REPAIRED = 'REPAIRED';
export const VEHICLE_DAMAGE_STATUS_LIST_DUPLICATED = 'DUPLICATED';
export const VEHICLE_DAMAGE_STATUS_LIST_WONT_REPAIR = 'WONT_REPAIR';

export const FIXED_AMOUNT_DISCOUNT = 'FIXED_AMOUNT_DISCOUNT';
export const PERCENT_DISCOUNT = 'PERCENT_DISCOUNT';
export const VOUCHER_MAXIMUM_USES = 'VOUCHER_MAXIMUM_USES';
export const VOUCHER_MAXIMUM_USES_PER_PROFILE = 'VOUCHER_MAXIMUM_USES_PER_PROFILE';
export const BOOKING_START_PARKING_IN_LIST = 'BOOKING_START_PARKING_IN_LIST';
export const BOOKING_SUB_COMPANY_IN_LIST = 'BOOKING_SUB_COMPANY_IN_LIST';
export const BOOKING_CREATION_DATE_BETWEEN = 'BOOKING_CREATION_DATE_BETWEEN';
export const BOOKING_START_DATE_BETWEEN = 'BOOKING_START_DATE_BETWEEN';
export const BOOKING_STATUS_PARTIALY_PAID = 'PARTIALLY PAID';
export const BOOKING_PAYMENT_STATUS_NA = 'N/A';
export const STATUS_REFUSED = 'REFUSED';
export const STATUS_CAPTURED = 'CAPTURED';
export const STATUS_SETTLED = 'SETTLED';
export const STATUS_SENT_FOR_REFUND = 'SENT_FOR_REFUND';
export const STATUS_REFUNDED = 'REFUNDED';
export const STATUS_REVOKED = 'REVOKED';
export const STATUS_AUTHORISED = 'AUTHORISED';
export const STATUS_EXPIRED = 'EXPIRED';

export const TRANSACTION_CAPTURED_FAILED = 'CAPTURED_FAILED';
export const TRANSACTION_CHARGEBACK_REVERSED = 'CHARGEBACK_REVERSED';
export const TRANSACTION_CHARGED_BACK = 'CHARGED_BACK';
export const TRANSACTION_INFORMATION_REQUESTED = 'INFORMATION_REQUESTED';
export const TRANSACTION_INFORMATION_SUPPLIED = 'INFORMATION_SUPPLIED';
export const TRANSACTION_REFUND_FAILED = 'REFUND_FAILED';
export const TRANSACTION_REVOKE_FAILED = 'REVOKE_FAILED';
export const TRANSACTION_REVOKE_REQUESTED = 'REVOKE_REQUESTED';
export const TRANSACTION_CAPTURE_REQUESTED = 'CAPTURE_REQUESTED';
export const TRANSACTION_REFUND_REQUESTED = 'REFUND_REQUESTED';

export const TRANSACTION_TYPE_DEPOSIT = 'DEPOSIT';
export const TRANSACTION_TYPE_BILLING = 'BILLING';
export const TRANSACTION_TYPE_REFUND = 'REFUND';

export const VEHICLE_AREA_FRONT_LEFT = 'FRONT_LEFT';
export const VEHICLE_AREA_FRONT_RIGHT = 'FRONT_RIGHT';
export const VEHICLE_AREA_BACK_LEFT = 'BACK_LEFT';
export const VEHICLE_AREA_BACK_RIGHT = 'BACK_RIGHT';
export const VEHICLE_AREA_TRUNK = 'TRUNK';
export const VEHICLE_AREA_OTHER = 'OTHER';

export const VEHICLE_USAGE_TYPE_NO_USAGE = 'NO_USAGE';
export const VEHICLE_USAGE_TYPE_IMMEDIATE = 'B2B_IMMEDIATE_USAGE';
export const VEHICLE_USAGE_TYPE_PLANNED = 'B2B_PLANNED_USAGE';
export const VEHICLE_USAGE_TYPE_PUBLIC_SERVICE = 'PUBLIC_SERVICE';
export const VEHICLE_USAGE_TYPE_RV = 'RV';
export const VEHICLE_USAGE_TYPE_TEST_DRIVE = 'TEST_DRIVE';
export const VEHICLE_USAGE_TYPE_DELIVERY = 'DELIVERY';

export const VEHICLE_FUEL_TYPE_ELECTRIC = 'ELECTRIC';
export const VEHICLE_FUEL_TYPE_PETROL = 'PETROL';
export const VEHICLE_FUEL_TYPE_DIESEL = 'DIESEL';
export const VEHICLE_FUEL_TYPE_HYBRID = 'HYBRID';
export const VEHICLE_FUEL_TYPE_LPG = 'LPG';

export const VEHICLE_DAMAGE_TYPE_START = 'START_BOOKING';
export const VEHICLE_DAMAGE_TYPE_END = 'END_BOOKING';

export const VEHICLE_TASK_TYPE_MAINTENANCE_SERVICE = 'MAINTENANCE_SERVICE';
export const VEHICLE_TASK_TYPE_REPAIR = 'REPAIR';
export const VEHICLE_TASK_TYPE_MOVE_VEHICLE = 'MOVE_VEHICLE';
export const VEHICLE_TASK_TYPE_CLEANING = 'CLEANING';
export const VEHICLE_TASK_TYPE_CHARGING = 'CHARGING';

export const VEHICLE_TASK_STATUS_WAITING_FOR_FIELD_AGENT = 'WAITING_FOR_FIELD_AGENT';
export const VEHICLE_TASK_STATUS_IN_PROGRESS = 'IN_PROGRESS';
export const VEHICLE_TASK_STATUS_DONE = 'DONE';

export const VEHICLE_STATUS_OVERLAP_CODE = 'vehicle.status.already.exists.between.dates';
export const VEHICLE_STATUS_IN_THE_PAST_MSG = 'The status should take effect in the future.';
export const MEMBER_TYPE_DUPLICATE_NAME_MSG = 'Company already has a member type with that name';
export const PRICING_SLOTS_INVALID_DURATION_MSG = 'This timeslots package type need a valid duration';
export const PRICING_SLOTS_OVERLAP_MSG = 'Time slot is invalid because there are overlaps with others';
export const VEHICLE_STATUS_15_MINUTES_MSG = 'The new status should take effect in more than 15 minutes from an existing one.';
export const FORMATTED_ADDRESS_MISSING = 'address.formattedAddress: may not be empty';
export const TEMPLATE_MODEL_ITALIAN = 'ITALIAN';
export const TEMPLATE_MODEL_AUSTRIAN = 'AUSTRIAN';
export const TEMPLATE_MODEL_BELGIAN = 'BELGIAN';
export const TEMPLATE_MODEL_SPANISH = 'SPANISH';
export const TEMPLATE_MODEL_SWISS = 'SWISS';
export const TEMPLATE_MODEL_SLOVENIAN = 'SLOVENIAN';
export const TEMPLATE_MODEL_DEFAULT = 'DEFAULT';
export const INVOICE_PARAMS_SOCIO_UNIQUE = 'SU';
export const INVOICE_PARAMS_SOCIO_MULTIPLE = 'SM';
export const INVOICE_PARAMS_IN_LIQUIDATION = 'LS';
export const INVOICE_PARAMS_NOT_IN_LIQUIDATION = 'LN';
export const TEMPLATE_EMAIL_DEFAULT = 'customizable';

export const INVOICE_PAYMENT_CONDITIONS = {
  AT_ONES: 'AT_ONCE',
  AT_VEHICLE_RETRIEVAL: 'AT_VEHICLE_RETRIEVAL'
};

export const EXTERNAL_INVOICE = {
  RRS: 'RRS',
  DMS: 'DMS',
  ITALIAN: 'ITALIAN',
  NO: 'NO'
};

export const MOBILE_ACTIONS = {
  DEEPLINK: 'DEEPLINK',
  INFORMATION: 'INFORMATION',
  INVITATION: 'INVITATION'
};

export const VEHICLE_STATUSES = {
  READY: 'READY',
  INACTIVE: 'INACTIVE',
  REPARATION: 'REPARATION',
  CLEANING: 'CLEANING',
  INTERVENTION: 'INTERVENTION',
  MAINTENANCE: 'MAINTENANCE',
  RENTED_IN_RRS: 'RENTED_IN_RRS'
};

export const VEHICLE_STATUSES_REPORT_DATE_FORMAT = 'YYYY-MM-DD HH:mm';

export const BOOKING_EVENT_STATUSES = {
  SUCCESS: 'SUCCESS',
  BUSINESS_ERROR: 'BUSINESS_ERROR',
  TECHNICAL_ERROR: 'TECHNICAL_ERROR'
};

export const MEMBER_EVENT_RESULT = {
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR'
};

export const BOOKING_EVENT_TYPES = {
  CREATE: 'CREATE',
  REASSIGN_VEHICLE: 'REASSIGN_VEHICLE',
  EXTEND_END_DATE: 'EXTEND_END_DATE',
  CANCEL: 'CANCEL',
  START: 'START',
  SCORE: 'SCORE',
  DAMAGE_REPORT: 'DAMAGE_REPORT',
  FINISH: 'FINISH',
  UPDATE: 'UPDATE',
  PRE_BOOKING_CONFIRMATION: 'PRE_BOOKING_CONFIRMATION'
};

export const MEMBER_PROFILE_ACTIONS = {
  CREATE: 'CREATE',
  UPDATE: 'UPDATE',
  MIGRATE: 'MIGRATE',
  APPROVE: 'APPROVE',
  REJECT: 'REJECT',
  ANONYMIZE: 'ANONYMIZE',
  CANCEL_ANONYMIZATION: 'CANCEL_ANONYMIZATION',
  SUSPEND: 'SUSPEND',
  UNSUSPEND: 'UNSUSPEND',
  RESET_PASSWORD: 'RESET_PASSWORD',
  UPDATE_PASSWORD: 'UPDATE_PASSWORD'
};

export const PAYBACK_COUNTRIES = {
  FRANCE: 'FRANCE',
  SWISS: 'SWISS',
  UE: 'UE',
  OTHER: 'OTHER'
};

export const EUR_CURRENCY = 'EUR';

export const BE_CURRENCIES_FALLBACK = ['BRL', 'CHF', 'DKK', 'EUR', 'GBP', 'SEK'];

// TODO: check if used
export const REALMS = [
  'ego',
  'engie',
  'engie',
  'garda-uno',
  'glide',
  'join-me',
  'kenis',
  'loczen',
  'master',
  'mec',
  'nissan',
  'permistar',
  'renault-mobility',
  'share-mobility',
  'sncf',
  'agilauto',
  'test',
  'test-realm'
];

export const SCHEDULED_EXPORT = {
  FILTERS_BY_TYPE: {
    BOOKING: 'bookingQuery',
    MEMBER_PROFILE: 'memberQuery'
  },
  TYPE: {
    BOOKING: 'BOOKING',
    MEMBER_PROFILE: 'MEMBER_PROFILE'
  },
  DESTINATION: {
    SFTP: 'sftp',
    S3: 's3'
  },
  SECURITY: {
    PASSWORD: 'password',
    FILE: 'privateKeyFileId'
  }
};

export const PAYMENT_PROVIDERS = {
  STRIPE: 'STRIPE',
  WORLDPAY: 'WORLDPAY'
};

export const STRIPE_STATUS = { COMPLETED: 'COMPLETED', INCOMPLETE: 'INCOMPLETE', DISABLED: 'DISABLED' };
