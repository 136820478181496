import React, { Component, PropTypes } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { routeActions } from 'react-router-redux';
import _get from 'lodash/get';
import _partial from 'lodash/partial';
import _reduce from 'lodash/reduce';
import { Row, Col } from 'react-bootstrap';
import AdvancedList from '../../components/AdvancedList/AdvancedList';
import { backUsersHeaderParams } from '../../constants/table-constants';
import { getBackUsersList, clearBackUsersListData } from './backUsers-actions';
import { injectIntl } from 'react-intl';
import routes, { backuserEditRules } from '../../constants/routes-constants';
import Avatar from 'material-ui/Avatar';
import Clear from 'material-ui/svg-icons/content/clear';
import AddIcon from 'material-ui/svg-icons/content/add';
import Check from 'material-ui/svg-icons/navigation/check';
import FlatButton from 'material-ui/FlatButton';
import DetailIcon from 'material-ui/svg-icons/image/remove-red-eye';
import IconButton from 'material-ui/IconButton';
import IsGranted from '../../components/IsGranted/IsGranted';
import { namedCompose } from '../../utils/utils';
import Filters from '../../components/AdvancedList/components/Filters';
import FilterChips from '../../components/FilterChips/FilterChips';
import { backUsersFilterChips } from '../../constants/options-constants';
import autoBind from 'react-autobind';
import Dotdotdot from 'react-dotdotdot';
import { backUsersFilterTypes } from '../../constants/filterTypes-constants';
import { localeSelector } from '../../selectors/all-selectors';

class BackUsers extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
  }

  componentWillMount() {
    const { pageParams } = this.props;
    this.callApi(pageParams);
  }

  componentWillUnmount() {
    this.props.dispatch(clearBackUsersListData());
  }

  callApi(params) {
    const { dispatch } = this.props;
    dispatch(getBackUsersList(params));
  }

  handleRefreshList() {
    const { pageParams } = this.props;
    this.callApi(pageParams);
  }

  handleChangePage(value) {
    const { dispatch, listMetadata, urlParams } = this.props;
    const params = {
      page: {
        number: value,
        size: listMetadata.paginationInfo.pageSize
      }
    };
    const newParams = { ...urlParams, ...params };
    this.callApi(newParams);
    dispatch(routeActions.push(routes.backUsers.path.replace(':search', encodeURIComponent(JSON.stringify(newParams)))));
  }

  handleNumberResultsSelect(value) {
    const { dispatch, urlParams } = this.props;
    let params = {
      page: {
        number: 1,
        size: parseInt(value)
      }
    };
    let newParams = Object.assign(urlParams, params);
    dispatch(routeActions.push(routes.backUsers.path.replace(':search', encodeURIComponent(JSON.stringify(newParams)))));
  }

  handleSearchBackUsersForm(params) {
    const { dispatch, listMetadata, urlParams } = this.props;
    params.page = {
      number: 1,
      size: listMetadata.paginationInfo.pageSize
    };
    let newParams = {
      ...urlParams,
      ...params
    };
    this.callApi(newParams);
    dispatch(routeActions.push(routes.backUsers.path.replace(':search', encodeURIComponent(JSON.stringify(newParams)))));
  }

  handleDeleteChip(item) {
    let { urlParams, dispatch } = this.props;
    delete urlParams[item];
    this.callApi(urlParams);
    dispatch(routeActions.push(routes.backUsers.path.replace(':search', encodeURIComponent(JSON.stringify(urlParams)))));
  }

  handleCreateBackUser() {
    const { dispatch } = this.props;
    dispatch(routeActions.push(routes.addBackUser.path));
  }

  handleGotoDetail(params) {
    const { dispatch } = this.props;
    dispatch(routeActions.push(routes[params[0]].path.replace(params[1], params[2])));
  }

  handleSort(sortPropertyName) {
    const { dispatch, urlParams } = this.props;
    const newParams = {
      ...urlParams,
      sort: {
        isDescending: _get(urlParams, 'sort.property', false) === sortPropertyName ? !urlParams.sort.isDescending : false,
        property: sortPropertyName
      }
    };
    this.callApi(newParams);
    dispatch(routeActions.push(routes.backUsers.path.replace(':search', encodeURIComponent(JSON.stringify(newParams)))));
  }

  renderAssignedCompanies(item) {
    const content = _reduce(
      _get(item, 'companies'),
      (result, currentValue) => {
        const name = _get(currentValue, 'name');
        if (name) result.push(name);
        return result;
      },
      []
    ).join(', ');
    return <Dotdotdot clamp={2}>{content}</Dotdotdot>;
  }

  renderItem(item, id, reactKey) {
    const { intl } = this.props;
    const userValid = `avatar ${item.suspended ? 'suspended' : 'active'}`;
    const keyTranslation = 'backUsers_detail_suspended_' + !!item.suspended;
    return (
      <div className="advancedList_rowWrap" key={reactKey}>
        <Row id={id} className="advancedList_row backUsersPage_row">
          <Col xs={6} md={6}>
            <div className={'contentUser'}>
              <span>{item.firstName + ' ' + item.lastName}</span>
              <span>{item.login}</span>
            </div>
            <Avatar className={userValid}>
              {item.firstName.split('')[0]}
              <span className={'status'}>{item.suspended ? <Clear /> : <Check />}</span>
              <span className="tooltip">
                {intl.formatMessage({ id: 'data.with.colon' }, { data: intl.formatMessage({ id: 'backUsers_detail_suspended' }) }) +
                  ' ' +
                  intl.formatMessage({ id: keyTranslation }).toLowerCase()}
              </span>
            </Avatar>
          </Col>
          <Col xs={3} md={3}>
            {this.renderAssignedCompanies(item)}
          </Col>
          <Col xs={3} md={3}>
            {item.role && <FormattedMessage id={'backUsers_role_' + item.role} />}
          </Col>
          <div className="advancedList_detailIcon">
            <IconButton onClick={_partial(this.handleGotoDetail, ['backUserDetail', ':backUserId', item.id])}>
              <DetailIcon />
            </IconButton>
          </div>
        </Row>
      </div>
    );
  }

  render() {
    const { list, listMetadata, loadingList, urlParams, errorList, intl } = this.props;

    return (
      <div className="backUsersPage mainContainer_content">
        <div className="pageContainer">
          <div className="backUsersPage_content">
            <section className="backUsersPage_management">
              <FilterChips
                urlParams={urlParams}
                onDeleteChip={this.handleDeleteChip}
                translations={backUsersFilterChips}
                id="backusers_chips"
                chipClass="v2-filter-chip"
                filterTypes={backUsersFilterTypes}
              />

              <div className="advancedList_actions page">
                <Filters filterTypes={backUsersFilterTypes} onFilteredSearchForm={this.handleSearchBackUsersForm} id="backusers_filters" />

                <IsGranted allowed={backuserEditRules.include} disallowed={backuserEditRules.exclude}>
                  <div className="backUsersPage_actionsButtons">
                    <FlatButton
                      id="backUsers_create_button"
                      label={intl.messages.backUsers_add}
                      icon={<AddIcon />}
                      onClick={this.handleCreateBackUser}
                    />
                  </div>
                </IsGranted>
              </div>
              <AdvancedList
                id="backUsers"
                data={list}
                listMetadata={listMetadata}
                loading={loadingList}
                onChangePage={this.handleChangePage}
                onChangeRowsPerPage={this.handleNumberResultsSelect}
                renderItem={this.renderItem}
                header={backUsersHeaderParams}
                onRefreshList={this.handleRefreshList}
                urlParams={urlParams}
                error={errorList}
                onSort={this.handleSort}
                renderAnchor={this.props.locale}
              />
            </section>
          </div>
        </div>
      </div>
    );
  }
}

BackUsers.displayName = 'BackUsers';

BackUsers.propTypes = {
  list: PropTypes.array,
  listMetadata: PropTypes.object
};

export default namedCompose(
  injectIntl,
  connect(state => {
    const {
      backUsers: { paginatedResults, urlParams, backUsersFiltersFormIsOpen, loadingList, errorList },
      page: { params },
      routing: {
        location: { pathname }
      }
    } = state;

    return {
      locale: localeSelector(state),
      list: _get(paginatedResults, 'results', []),
      listMetadata: _get(paginatedResults, 'metadata', {}),
      backUsersFiltersFormIsOpen,
      pageParams: params,
      loadingList,
      urlParams,
      errorList,
      pathname
    };
  })
)(BackUsers);
