import React, { Component, PropTypes as T } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import autoBind from 'react-autobind';
import { Tooltip } from 'react-tippy';
import { addTippyContent } from '../../../../utils/utils';
import { FormattedMessage } from 'react-intl';

const firstColunWidth = '290px';
class VehiclePlanningOpeningHours extends Component {
  constructor(props) {
    super(props);
    const { startDate, periodInDays } = props;
    this.currDay = moment(startDate)
      .locale('en')
      .add(periodInDays - 1, 'd')
      .format('dddd')
      .toUpperCase();
    this.nextDay = undefined;
    this.nextDay2 = undefined;
    this.nextDay3 = undefined;
    this.nextDay4 = undefined;
    this.nextDay5 = undefined;
    this.nextDay6 = undefined;
    this.marginLeft = '0px';
    autoBind(this);
  }

  componentWillMount() {
    const { startDate, periodInDays } = this.props;
    if (periodInDays === 1 || periodInDays === 3 || periodInDays === 7) {
      this.currentDate = moment(startDate).locale('en');
      this.currDay = this.currentDate.format('dddd').toUpperCase();
      this.nextDay = this.currentDate
        .add(1, 'd')
        .format('dddd')
        .toUpperCase();
      this.nextDay2 = this.currentDate
        .add(1, 'd')
        .format('dddd')
        .toUpperCase();
      this.nextDay3 = this.currentDate
        .add(1, 'd')
        .format('dddd')
        .toUpperCase();
      this.nextDay4 = this.currentDate
        .add(1, 'd')
        .format('dddd')
        .toUpperCase();
      this.nextDay5 = this.currentDate
        .add(1, 'd')
        .format('dddd')
        .toUpperCase();
      this.nextDay6 = this.currentDate
        .add(1, 'd')
        .format('dddd')
        .toUpperCase();
      this.marginLeft3dayscol = `(( 100% - ${firstColunWidth} ) / 3)`;
      this.marginLeft3days2col = `${this.marginLeft3dayscol}`;
      this.marginLeft3days3col = ` ${this.marginLeft3dayscol} * 2 `;
      this.marginLeft7dayscol = `(( 100% - ${firstColunWidth} ) / 7)`;
      this.marginLeft7days2col = ` ${this.marginLeft7dayscol} * 2 `;
      this.marginLeft7days3col = ` ${this.marginLeft7dayscol} * 3 `;
      this.marginLeft7days4col = ` ${this.marginLeft7dayscol} * 4 `;
      this.marginLeft7days5col = ` ${this.marginLeft7dayscol} * 5 `;
      this.marginLeft7days6col = ` ${this.marginLeft7dayscol} * 6 `;
    }
  }

  componentWillReceiveProps() {
    const { startDate, periodInDays } = this.props;

    if (periodInDays === 1 || periodInDays === 3 || periodInDays === 7) {
      this.currentDate = moment(startDate).locale('en');
      this.currDay = this.currentDate.format('dddd').toUpperCase();
      this.nextDay = this.currentDate
        .add(1, 'd')
        .format('dddd')
        .toUpperCase();
      this.nextDay2 = this.currentDate
        .add(1, 'd')
        .format('dddd')
        .toUpperCase();
      this.nextDay3 = this.currentDate
        .add(1, 'd')
        .format('dddd')
        .toUpperCase();
      this.nextDay4 = this.currentDate
        .add(1, 'd')
        .format('dddd')
        .toUpperCase();
      this.nextDay5 = this.currentDate
        .add(1, 'd')
        .format('dddd')
        .toUpperCase();
      this.nextDay6 = this.currentDate
        .add(1, 'd')
        .format('dddd')
        .toUpperCase();
      this.marginLeft3dayscol = `(( 100% - ${firstColunWidth} ) / 3)`;
      this.marginLeft3days2col = `${this.marginLeft3dayscol}`;
      this.marginLeft3days3col = ` ${this.marginLeft3dayscol} * 2 `;
      this.marginLeft7dayscol = `(( 100% - ${firstColunWidth} ) / 7)`;
      this.marginLeft7days2col = ` ${this.marginLeft7dayscol} * 2 `;
      this.marginLeft7days3col = ` ${this.marginLeft7dayscol} * 3 `;
      this.marginLeft7days4col = ` ${this.marginLeft7dayscol} * 4 `;
      this.marginLeft7days5col = ` ${this.marginLeft7dayscol} * 5 `;
      this.marginLeft7days6col = ` ${this.marginLeft7dayscol} * 6 `;
    }
  }

  closedHoursContent(start, end) {
    return (
      <div className="detail-tooltip-closedHours">
        <FormattedMessage id="closed_hours_label" />
        <FormattedMessage id="common_stream" values={{ start, end }} />
      </div>
    );
  }

  render() {
    const { parkingOpenedSlots, startDate, periodInDays, open } = this.props;
    let divs = [];
    const theKey = 'scheduleHours--' + (Math.random() * 17000).toFixed(0) + '__' + open;

    return (
      <div className="scheduleHours" key={theKey}>
        {parkingOpenedSlots.days &&
          parkingOpenedSlots.days.map(day => {
            const provideStreams = open ? parkingOpenedSlots.timeIntervals : parkingOpenedSlots.closedHours;
            if (provideStreams) {
              divs[day] = provideStreams.map((item, idx) => {
                const startHour = item.start.split(':')[0];
                const startMinutes = item.start.split(':')[1];
                const endHour = item.end.split(':')[0];
                const endMinutes = item.end.split(':')[1];
                const h1 = moment(startDate)
                  .hour(startHour)
                  .minutes(startMinutes);
                const h2 = moment(startDate)
                  .hour(endHour)
                  .minutes(endMinutes);
                const openingHoursDuration = moment.duration(h2.diff(h1)).as('minutes');
                const openHoursWidth = openingHoursDuration <= 0 ? 1440 : 1440 / openingHoursDuration;
                const startingInPx = moment.duration(h1.diff(moment(startDate))).as('minutes');
                const end = item.end === '23:59' ? '00:00' : item.end;

                if (periodInDays === 1) {
                  const __key = day + '_' + idx + (Math.random() * 10000).toFixed(0);
                  return (
                    <Tooltip
                      className="detail-tooltip-closedHours"
                      useContext
                      followCursor
                      key={__key}
                      {...addTippyContent(this.closedHoursContent(item.start, end))}
                      delay="100"
                    >
                      <div
                        className={open ? 'vehiclePlanning_openhours' : 'new-vehicle-planning_closedhours'}
                        style={{
                          width: `calc( ((100% - ${firstColunWidth}) / ${periodInDays}) / ${openHoursWidth} )`,
                          left: `calc( (((100% - ${firstColunWidth}) / ${periodInDays}) / 1440 * ${startingInPx}) + ${firstColunWidth}) `
                        }}
                      ></div>
                    </Tooltip>
                  );
                }

                if (periodInDays === 3) {
                  let left =
                    this.currDay === day
                      ? `calc( (((100% - ${firstColunWidth}) / ${periodInDays}) / 1440 * ${startingInPx}) + ${firstColunWidth}) `
                      : this.nextDay === day
                      ? `calc( (((100% - ${firstColunWidth}) / ${periodInDays}) / 1440 * ${startingInPx}) + ${firstColunWidth} + ${this.marginLeft3days2col} )`
                      : this.nextDay2 === day
                      ? `calc( (((100% - ${firstColunWidth}) / ${periodInDays}) / 1440 * ${startingInPx}) + ${firstColunWidth}  + ${this.marginLeft3days3col} )`
                      : '150px';
                  const ___key = day + '+_' + idx + (Math.random() * 2000).toFixed(0);

                  return (
                    <Tooltip
                      className="detail-tooltip-closedHours"
                      useContext
                      key={___key}
                      followCursor
                      {...addTippyContent(this.closedHoursContent(item.start, end))}
                      delay="100"
                    >
                      <div
                        className={open ? 'vehiclePlanning_openhours' : 'new-vehicle-planning_closedhours'}
                        style={{
                          width: `calc( ((100% - ${firstColunWidth}) / ${periodInDays}) / ${openHoursWidth} )`,
                          left
                        }}
                      ></div>
                    </Tooltip>
                  );
                }

                if (periodInDays === 7) {
                  let left =
                    this.currDay === day
                      ? `calc( (((100% - ${firstColunWidth}) / ${periodInDays}) / 1440 * ${startingInPx}) + ${firstColunWidth}) `
                      : this.nextDay === day
                      ? `calc( (((100% - ${firstColunWidth}) / ${periodInDays}) / 1440 * ${startingInPx}) + ${firstColunWidth} + ${this.marginLeft7dayscol} )`
                      : this.nextDay2 === day
                      ? `calc( (((100% - ${firstColunWidth}) / ${periodInDays}) / 1440 * ${startingInPx}) + ${firstColunWidth}  + ${this.marginLeft7days2col} )`
                      : this.nextDay3 === day
                      ? `calc( (((100% - ${firstColunWidth}) / ${periodInDays}) / 1440 * ${startingInPx}) + ${firstColunWidth}  + ${this.marginLeft7days3col} )`
                      : this.nextDay4 === day
                      ? `calc( (((100% - ${firstColunWidth}) / ${periodInDays}) / 1440 * ${startingInPx}) + ${firstColunWidth}  + ${this.marginLeft7days4col} )`
                      : this.nextDay5 === day
                      ? `calc( (((100% - ${firstColunWidth}) / ${periodInDays}) / 1440 * ${startingInPx}) + ${firstColunWidth}  + ${this.marginLeft7days5col} )`
                      : this.nextDay6 === day
                      ? `calc( (((100% - ${firstColunWidth}) / ${periodInDays}) / 1440 * ${startingInPx}) + ${firstColunWidth}  + ${this.marginLeft7days6col} )`
                      : '';
                  const ___2key = day + '_' + idx + (Math.random() * 5000).toFixed(0);
                  return (
                    <Tooltip
                      className="detail-tooltip-closedHours"
                      useContext
                      followCursor
                      key={___2key}
                      {...addTippyContent(this.closedHoursContent(item.start, end))}
                      delay="100"
                    >
                      <div
                        className={open ? 'vehiclePlanning_openhours' : 'new-vehicle-planning_closedhours'}
                        style={{
                          width: `calc( ((100% - ${firstColunWidth}) / ${periodInDays}) / ${openHoursWidth} )`,
                          left
                        }}
                      ></div>
                    </Tooltip>
                  );
                }
              });
            }
          })}

        {divs && divs[this.currDay] && divs[this.currDay]}

        {divs && divs[this.nextDay] && (periodInDays === 3 || periodInDays === 7) && divs[this.nextDay]}
        {divs && divs[this.nextDay2] && (periodInDays === 3 || periodInDays === 7) && divs[this.nextDay2]}
        {divs && divs[this.nextDay3] && periodInDays === 7 && divs[this.nextDay3]}
        {divs && divs[this.nextDay4] && periodInDays === 7 && divs[this.nextDay4]}
        {divs && divs[this.nextDay5] && periodInDays === 7 && divs[this.nextDay5]}
        {divs && divs[this.nextDay6] && periodInDays === 7 && divs[this.nextDay6]}
      </div>
    );
  }
}

VehiclePlanningOpeningHours.displayName = 'VehiclePlanningOpeningHours';

VehiclePlanningOpeningHours.propTypes = {
  parkingOpenedSlots: T.object,
  parkingVehicles: T.object
};

export default connect(state => {
  const {
    vehiclePlanning: { startDate, periodInDays, siteId, loading, data },
    subCompanies: { subCompanySelected }
  } = state;

  return {
    startDate,
    periodInDays,
    siteId,
    subCompanySelected,
    loading,
    data
  };
})(VehiclePlanningOpeningHours);
