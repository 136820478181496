import moment from 'moment';

import * as types from '../constants/actionTypes-constants';
import callApi from './api-actions';

import {
  selectCompanyId,
  selectSubCompanyId,
  append,
  getAppFormattedDate,
  getAppFormattedTime,
  trySet,
  trimValues,
  pickTruthy,
  renameKey,
  addErrorMessage,
  setApiCompanyIds,
  fallbackFunc,
  safe,
  getFormValues
} from '../utils/utils';

import _get from 'lodash/get';
import _pick from 'lodash/pick';
import _has from 'lodash/has';
import _forEach from 'lodash/forEach';
import { addFlashMessage, pollingExport, handleGetFileError, getHeaderSubCompaniesList, openDeleteStatusModal } from './all-actions';
import { FLASH_MESSAGE_TYPE_ERROR, FLASH_MESSAGE_TYPE_SUCCESS, ISO_DATE_FLAT } from '../constants/generic-constants';

import {
  BACKUSER_ROLE_ADMIN,
  BACKUSER_ROLE_CALL_CENTER_OPERATOR,
  BACKUSER_ROLE_ROOT,
  BACKUSER_ROLE_SUPER_ADMIN,
  SORT_PROPERTY_START_DATE,
  BOOKING_STATUS_PRE_BOOKED,
  BACKUSER_ROLE_ZONE_MANAGER,
  BACKUSER_ROLE_ADMIN_DEALER,
  BOOKING_STATUS_COMPLETED,
  BOOKING_RETRY_RRS_COMPLETE,
  STATUS_CANCELED,
  BOOKING_RETRY_RRS_CANCEL_WITH_FEES,
  BOOKING_RETRY_RRS_CANCEL_WITHOUT_FEES
} from '../constants/backend-constants';
import { localeSelector } from '../selectors/all-selectors';
import { apiParams } from '../constants/api-params-constants';

export function getBookingsList(params, loader = false) {
  return (dispatch, getState) => {
    const state = getState();

    let paramsObj;

    if (params) {
      let pageNumber = params.page ? params.page.number : safe(() => state.bookings.paginatedResults.metadata.paginationInfo.currentPage);
      let pageSize = params.page ? params.page.size : safe(() => state.bookings.paginatedResults.metadata.paginationInfo.pageSize);

      paramsObj = {
        page: {
          number: pageNumber,
          size: pageSize
        }
      };

      let sort = {
        property: _get(params, 'sort.property', SORT_PROPERTY_START_DATE),
        isDescending: _get(params, 'sort.isDescending', true)
      };

      paramsObj = Object.assign(paramsObj, { sort });

      setApiCompanyIds(paramsObj, state);

      paramsObj = Object.assign(
        {},
        paramsObj,
        _pick(params, [
          'memberFirstName',
          'memberLastName',
          'memberLogin',
          'status',
          'states',
          'vehicleBrand',
          'vehicleModel',
          'vehicleRegistrationNumber',
          'vehicleUsageAtBookingCreation',
          'endDateMax',
          'endDateMin',
          'startDateMax',
          'startDateMin',
          'delayed',
          'failed',
          'rrsUpdateFailed',
          'type',
          'voucherCode',
          'voucherGroupName',
          'voucherGroupIds'
        ])
      );

      if (paramsObj.endDateMax) {
        paramsObj.endDateMax = moment(paramsObj.endDateMax).format(ISO_DATE_FLAT);
      }
      if (paramsObj.endDateMin) {
        paramsObj.endDateMin = moment(paramsObj.endDateMin).format(ISO_DATE_FLAT);
      }
      if (paramsObj.startDateMax) {
        paramsObj.startDateMax = moment(paramsObj.startDateMax).format(ISO_DATE_FLAT);
      }
      if (paramsObj.startDateMin) {
        paramsObj.startDateMin = moment(paramsObj.startDateMin).format(ISO_DATE_FLAT);
      }

      if (params.bookingId) {
        paramsObj.bookingId = params.bookingId;
      }

      if (params.states) {
        paramsObj.states = params.states;
      }

      if (params.voucherGroupIds) {
        paramsObj.voucherGroupIds = typeof params.voucherGroupIds === 'string' ? [params.voucherGroupIds] : params.voucherGroupIds;
      }
    } else {
      paramsObj = null;
    }

    const companyIds = safe(() => paramsObj.companyIds);

    if (companyIds) {
      dispatch(getHeaderSubCompaniesList(companyIds[0]));
    }

    dispatch({
      type: types.BOOKINGS_GET_LIST_REQUEST
    });

    return dispatch(callApi('bookings', 'getList', { params: paramsObj }, loader))
      .then(function(data) {
        dispatch({
          type: types.BOOKINGS_GET_LIST_SUCCESS,
          paginatedObj: data,
          urlParams: paramsObj
        });
        return data;
      })
      .catch(() => {
        dispatch({
          type: types.BOOKINGS_GET_LIST_ERROR
        });
      });
  };
}

export function setBookingsFilters(filters) {
  return {
    type: types.BOOKINGS_UPDATE_FILTERS,
    filters
  };
}

export function openCancelBookingModal() {
  return {
    type: types.BOOKINGS_CANCEL_BOOKING_MODAL_OPEN
  };
}

export function openStartBookingModal() {
  return {
    type: types.BOOKING_START_MODAL_OPEN
  };
}

export function openChangeVehicleBookingModal() {
  return {
    type: types.BOOKING_UPDATE_CHANGE_VEHICLE
  };
}

export function closeCancelBookingModal() {
  return {
    type: types.BOOKINGS_CANCEL_BOOKING_MODAL_CLOSE
  };
}

export function closeStartBookingModal() {
  return {
    type: types.BOOKING_START_MODAL_CLOSE
  };
}

export function setBookingListItemStatus(id, status) {
  return {
    type: types.BOOKINGS_LIST_SET_ITEM_STATUS,
    status,
    id
  };
}

export function setBookingDetailUpdateVehicle(data) {
  return {
    type: types.BOOKINGS_DETAIL_UPDATE_VEHICLE,
    data
  };
}

export function setBookingsListUpdateVehicle(id, vehicle) {
  return {
    type: types.BOOKINGS_LIST_UPDATE_VEHICLE,
    vehicle,
    id
  };
}

export function toggleCreateInvoiceBookingModal() {
  return {
    type: types.BOOKINGS_CREATE_INVOICE_BOOKING_MODAL_TOGGLE
  };
}

export function requestHistoryEventsBooking(bookingId) {
  return dispatch => {
    dispatch({ type: types.BOOKING_EVENTS_HISTORY_REQUEST });

    function errorDispatch() {
      dispatch({ type: types.BOOKING_EVENTS_HISTORY_ERROR });
    }

    function successDispatch(data) {
      dispatch({
        type: types.BOOKING_EVENTS_HISTORY_SUCCESS,
        bookingEvents: data
      });
    }

    return dispatch(callApi('bookings', 'getBookingEventsHistory', bookingId, true)).then(successDispatch, errorDispatch);
  };
}

export function startBooking() {
  return (dispatch, getState) => {
    const state = getState();
    const values = getFormValues('startBookingForm');
    const bookingId = state.bookings.bookingDetail.id;
    let payload = { bookingId };
    trySet(payload, 'mileage', values.mileage);
    dispatch({ type: types.BOOKING_START_REQUEST });
    return dispatch(callApi('bookings', 'startBooking', payload, true));
  };
}

export function requestBookingCancel(id) {
  return dispatch => {
    dispatch({ type: types.BOOKINGS_CANCEL_BOOKING_REQUEST });
    return dispatch(callApi('bookings', 'cancelBooking', id));
  };
}

export function setBookingsCurrentSortedIndex(index) {
  return {
    type: types.BOOKINGS_SET_CURRENT_SORTED_INDEX,
    index
  };
}

export function setBookingsSortIsDescending(isDescending) {
  return {
    type: types.BOOKINGS_SORT_DIRECTION_SET,
    isDescending
  };
}

export function storeBookingsFacets(facets) {
  return {
    type: types.BOOKINGS_FACETS_SET,
    facets
  };
}

export function toggleBookingsFilters() {
  return {
    type: types.BOOKINGS_FILTERS_FORM_OPENED_STATE_TOGGLE
  };
}

export function getSingleBooking(bookingId, loader = true) {
  return dispatch => {
    dispatch({
      type: types.BOOKINGS_GET_DETAILS_REQUEST
    });

    return dispatch(callApi('bookings', 'getSingleBooking', bookingId, loader)).then(function(data) {
      dispatch({
        type: types.BOOKINGS_GET_DETAILS_SUCCESS,
        booking: data
      });
      return data;
    });
  };
}

export function openExtendShortenBookingModal() {
  return {
    type: types.BOOKINGS_EXTEND_SHORTEN_BOOKING_MODAL_OPEN
  };
}

export function closeExtendShortenBookingModal() {
  return {
    type: types.BOOKINGS_EXTEND_SHORTEN_BOOKING_MODAL_CLOSE
  };
}

export function getVehicleCompany(subCompanyId, loader = false) {
  return dispatch => {
    dispatch({ type: types.BOOKINGS_GET_VEHICLE_COMPANY_REQUEST });

    return dispatch(callApi('subCompanies', 'getSubCompanyDetails', subCompanyId, loader))
      .then(function(data) {
        dispatch({
          type: types.BOOKINGS_GET_VEHICLE_COMPANY_SUCCESS,
          companyDetail: data
        });

        return data;
      })
      .catch(() => {
        dispatch({ type: types.BOOKINGS_GET_VEHICLE_COMPANY_ERROR });
      });
  };
}

export function getBookingTransactions(bookingId, loader = false) {
  return dispatch => {
    dispatch({ type: types.BOOKINGS_GET_TRANSACTIONS_FOR_BOOKING_REQUEST });
    return dispatch(callApi('transactions', 'booking', bookingId, loader))
      .then(data => {
        dispatch({
          type: types.BOOKINGS_GET_TRANSACTIONS_FOR_BOOKING_SUCCESS,
          data
        });
        return data;
      })
      .catch(() => dispatch({ type: types.BOOKINGS_GET_TRANSACTIONS_FOR_BOOKING_ERROR }));
  };
}

export function getBookingInvoices(bookingId, loader = false) {
  if (!bookingId) {
    return dispatch =>
      dispatch({
        type: types.BOOKINGS_GET_INVOICES_FOR_BOOKING_SUCCESS,
        data: []
      });
  }
  return dispatch => {
    dispatch({ type: types.BOOKINGS_GET_INVOICES_FOR_BOOKING_REQUEST });
    return dispatch(callApi('invoices', 'getListForBooking', bookingId, loader))
      .then(data => {
        dispatch({
          type: types.BOOKINGS_GET_INVOICES_FOR_BOOKING_SUCCESS,
          data
        });
        return data;
      })
      .catch(() => dispatch({ type: types.BOOKINGS_GET_INVOICES_FOR_BOOKING_ERROR }));
  };
}

export function bookingViewGetInvoices(bookingId) {
  return (dispatch, getState) => {
    const state = getState();
    const userRole = _get(state.user, 'userInfo.role');

    if (
      userRole === BACKUSER_ROLE_ROOT ||
      userRole === BACKUSER_ROLE_SUPER_ADMIN ||
      userRole === BACKUSER_ROLE_ADMIN ||
      userRole === BACKUSER_ROLE_ADMIN_DEALER ||
      userRole === BACKUSER_ROLE_CALL_CENTER_OPERATOR ||
      userRole === BACKUSER_ROLE_ZONE_MANAGER
    ) {
      return dispatch(getBookingInvoices(bookingId));
    } else {
      return dispatch(getBookingInvoices());
    }
  };
}

export function requestBookingExtendShorten(bookingId, registrationNumber, newEndDate) {
  return dispatch => {
    return dispatch(callApi('bookings', 'extendShortenBooking', { bookingId, newEndDate }))
      .then(data => {
        let resultEndDate = moment(data.end.date).diff(newEndDate);

        dispatch({
          type: types.BOOKINGS_EXTEND_SHORTEN_BOOKING_SUCCESS,
          booking: data
        });

        dispatch(
          addFlashMessage({
            contentKey:
              'booking_detail_extend_shorten_' +
              (_has(data, 'carSharingInfo.freeOfCharges') ? 'free_' : '') +
              'booking_' +
              (resultEndDate !== 0 ? 'warning' : 'success'),
            contentData: {
              date: getAppFormattedDate(data.end.date),
              hour: getAppFormattedTime(data.end.date),
              price:
                _get(data, 'carSharingInfo.cost.totalPriceIncludingTaxes', null) ||
                _get(data, 'carSharingInfo.cost.estimatedPriceForDuration', null) ||
                '-'
            },
            type: FLASH_MESSAGE_TYPE_SUCCESS
          })
        );
      })
      .catch(error => {
        if (_get(error, 'errorCode')) {
          if (_get(error, 'errorParams')) {
            error.errorParams.plateNumber = registrationNumber;
          }
          dispatch(
            addFlashMessage({
              contentKey: `booking_detail_extendShorten_error_${error.errorCode}`,
              contentData: error.errorParams ? error.errorParams : {},
              type: FLASH_MESSAGE_TYPE_ERROR
            })
          );
        } else {
          dispatch(
            addFlashMessage({
              contentKey: 'booking_detail_extendShorten_failed',
              type: FLASH_MESSAGE_TYPE_ERROR
            })
          );
        }
      });
  };
}

export function openFinishBookingModal() {
  return {
    type: types.BOOKINGS_FINISH_BOOKING_MODAL_OPEN
  };
}

export function closeFinishBookingModal() {
  return {
    type: types.BOOKINGS_FINISH_BOOKING_MODAL_CLOSE
  };
}

export function requestBookingFinish(bookingId, comment = true) {
  return (dispatch, getState) => {
    const state = getState();
    const { technicalComment } = trimValues(state.form.finalizeBookingForm) || {};
    const query = comment ? { technicalComment } : {};

    dispatch({
      type: types.BOOKINGS_FINISH_BOOKING_REQUEST
    });

    return dispatch(callApi('bookings', 'finishBooking', { bookingId, query }));
  };
}

export function callCancelFinishSuccess(booking) {
  return {
    type: types.BOOKINGS_FINISH_BOOKING_SUCCESS,
    booking
  };
}

export function getBookingLicenseImage(files, loader = false) {
  return dispatch => {
    dispatch({ type: types.BOOKING_DRIVING_LICENSE_DETAILS_REQUEST });

    return Promise.all(
      _forEach(files, file => {
        if (!_get(file, 'fileId')) {
          handleGetFileError(dispatch, file, types.BOOKING_DRIVING_LICENSE_DETAILS_ERROR);
        } else {
          dispatch(callApi('file', 'getFileUrl', file.fileId, loader))
            .then(data => {
              dispatch({
                type: types.BOOKING_DRIVING_LICENSE_DETAILS_SUCCESS,
                file: data
              });
            })
            .catch(() => {
              handleGetFileError(dispatch, file, types.BOOKING_DRIVING_LICENSE_DETAILS_ERROR);
            });
        }
      })
    );
  };
}

export function emptyDriverLicenseFiles() {
  return {
    type: types.BOOKING_DRIVING_LICENSE_FILES_EMPTY
  };
}

export function exportBookingsListWithSearchV3(params) {
  return (dispatch, getState) => {
    const state = getState();
    const isExportProcessing = window.localStorage.getItem('idExportBookings');
    let paramsObj = {};

    if (isExportProcessing) {
      return dispatch(pollingExport(isExportProcessing));
    }

    if (params) {
      paramsObj = Object.assign(
        paramsObj,
        pickTruthy(params, [
          'memberFirstName',
          'memberLastName',
          'memberLogin',
          'vehicleUsageAtBookingCreation',
          'vehicleBrand',
          'vehicleModel',
          'vehicleRegistrationNumber',
          'endDateMax',
          'endDateMin',
          'status',
          'startDateMax',
          'startDateMin',
          'delayed',
          'failed',
          'type',
          'bookingId',
          'voucherCode',
          'voucherGroupName'
        ])
      );

      if (_get(paramsObj, 'endDateMax')) renameKey(paramsObj, 'bookingEndDateMax', 'endDateMax');
      if (_get(paramsObj, 'endDateMin')) renameKey(paramsObj, 'bookingEndDateMin', 'endDateMin');
      if (_get(paramsObj, 'startDateMax')) renameKey(paramsObj, 'bookingStartDateMax', 'startDateMax');
      if (_get(paramsObj, 'startDateMin')) renameKey(paramsObj, 'bookingStartDateMin', 'startDateMin');
      if (_get(paramsObj, 'status')) renameKey(paramsObj, 'bookingStatus', 'status');
      if (_get(paramsObj, 'type')) renameKey(paramsObj, 'bookingType', 'type');
      if (_get(paramsObj, 'delayed')) renameKey(paramsObj, 'delayedBooking', 'delayed');
      if (_get(paramsObj, 'memberLogin')) renameKey(paramsObj, 'memberEmail', 'memberLogin');

      if (_get(paramsObj, 'bookingStatus') === BOOKING_STATUS_PRE_BOOKED) {
        renameKey(paramsObj, 'bookingStatus', 'preBooked');
        trySet(paramsObj, 'preBooked', true);
      }
    }

    paramsObj.locale = localeSelector(state);

    trySet(paramsObj, 'companyIds', append([selectCompanyId(state)]));
    trySet(paramsObj, 'subCompanyIds', append([selectSubCompanyId(state)]));

    return dispatch(callApi('bookings', 'exportBookingsCSVV3', paramsObj, false))
      .then(data => {
        window.localStorage.setItem('idExportBookings', data);
        dispatch({ type: types.BOOKINGS_EXPORTV3_REQUEST });
        dispatch(pollingExport(data));
        return data;
      })
      .catch(() => {
        dispatch(
          addFlashMessage({
            contentKey: 'bookings_download_failed',
            type: FLASH_MESSAGE_TYPE_ERROR
          })
        );
        window.localStorage.removeItem('idExportBookings');
      });
  };
}

export function retryUnitRequest(bookingId, status) {
  return (dispatch, getState) => {
    const state = getState();
    const price = safe(() => state.bookings.bookingDetail.carSharingInfo.cost.totalPriceIncludingTaxes) || 0;
    const params = {
      bookingId
    };

    if (status === BOOKING_STATUS_COMPLETED) {
      trySet(params, 'operation', BOOKING_RETRY_RRS_COMPLETE);
    }
    if (status === STATUS_CANCELED) {
      if (price === 0) trySet(params, 'operation', BOOKING_RETRY_RRS_CANCEL_WITHOUT_FEES);
      else trySet(params, 'operation', BOOKING_RETRY_RRS_CANCEL_WITH_FEES);
    }

    dispatch({
      type: types.BOOKINGS_RETRY_BOOKING_REQUEST
    });

    return dispatch(callApi('bookings', 'retry', params))
      .then(() => {
        dispatch(
          addFlashMessage({
            contentKey: 'booking_detail_retry_success',
            type: FLASH_MESSAGE_TYPE_SUCCESS
          })
        );
      })
      .catch(() => {
        dispatch(
          addFlashMessage({
            contentKey: 'booking_detail_retry_failed',
            type: FLASH_MESSAGE_TYPE_ERROR
          })
        );
      });
  };
}

export function retryRrsBooking(bookingId) {
  return dispatch => {
    dispatch({
      type: types.BOOKINGS_RETRY_BOOKING_REQUEST
    });

    return dispatch(callApi('bookings', 'retryRrsBooking', bookingId))
      .then(() => {
        dispatch(
          addFlashMessage({
            contentKey: 'booking_detail_retry_success',
            type: FLASH_MESSAGE_TYPE_SUCCESS
          })
        );
      })
      .catch(() => {
        dispatch(
          addFlashMessage({
            contentKey: 'booking_detail_retry_failed',
            type: FLASH_MESSAGE_TYPE_ERROR
          })
        );
      });
  };
}

export function settleBooking(bookingId) {
  return dispatch => {
    return dispatch(callApi('bookings', 'settle', bookingId))
      .then(() => {
        dispatch({
          type: types.BOOKING_SETTLE_SUCCESS
        });
        dispatch(
          addFlashMessage({
            contentKey: 'booking_settle_success',
            type: FLASH_MESSAGE_TYPE_SUCCESS
          })
        );
      })
      .catch(() => {
        dispatch(
          addFlashMessage({
            contentKey: 'common_error_ocurred',
            type: FLASH_MESSAGE_TYPE_ERROR
          })
        );
      });
  };
}

export function retryItalianInvoice(italianInvoiceNumber) {
  return dispatch => {
    return dispatch(callApi('bookings', 'retryItalianInvoice', italianInvoiceNumber)).then(
      () => {
        dispatch({
          type: types.BOOKING_RETRY_ITALIAN_INVOICE
        });
        dispatch(
          addFlashMessage({
            contentKey: 'booking_retry_italian_invoice_success',
            type: FLASH_MESSAGE_TYPE_SUCCESS
          })
        );
      },
      error => dispatch(addErrorMessage({ error }))
    );
  };
}

export function changeAutomaticPaymentStatus(bookingId, newStatus) {
  return dispatch => {
    const allParams = { bookingId, params: { automaticPaymentRetry: newStatus } };
    return dispatch(callApi('bookings', 'changeAutomaticPayment', allParams)).then(
      () => {
        dispatch(
          addFlashMessage({
            contentKey: 'common_success',
            type: FLASH_MESSAGE_TYPE_SUCCESS
          })
        );
        dispatch({
          type: types.BOOKING_AUTOMATIC_PAYMENT_CHANGE_STATUS_SUCCESS,
          newStatus
        });
      },
      error => dispatch(addErrorMessage({ error }))
    );
  };
}

export function retryDmsPayment(bookingId) {
  return dispatch => {
    return dispatch(callApi('bookings', 'retryDmsPayment', bookingId)).then(
      () => {
        dispatch(
          addFlashMessage({
            contentKey: 'common_success',
            type: FLASH_MESSAGE_TYPE_SUCCESS
          })
        );
      },
      error => dispatch(addErrorMessage({ error }))
    );
  };
}

export function updateMemberSuccess(member) {
  return {
    type: types.BOOKINGS_MEMBER_UPDATE_SUCCESS,
    member
  };
}

export function getBookingDelayedStatus(id) {
  return dispatch => {
    return dispatch(callApi('search', 'bookings', { ...apiParams.delayedBooking, id }, false)).then(data => {
      return dispatch({ type: types.BOOKINGS_SET_CURRENT_DELAYED_STATUS, delayed: safe(() => data.results[0].id) });
    }, fallbackFunc);
  };
}
