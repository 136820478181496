import { FormattedMessage } from 'react-intl';
import EkButton from '../EkButton/EkButton';
import React from 'react';

export default function submitButton(submitAction, cancelAction) {
  return (
    <div className="backUserForm_actions">
      <EkButton id="cancel-button" type="button" customClass="backUserForm_actionsLink" skinType="reverse" onAction={cancelAction}>
        <FormattedMessage id="backUserForm_cancel_button" />
      </EkButton>
      <EkButton id="save-button" type="submit" customClass="backUserForm_actionsButton" onAction={submitAction}>
        <FormattedMessage id="backUserForm_save_button" />
      </EkButton>
    </div>
  );
}
