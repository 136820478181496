import React, { Component } from 'react';
import intlTelInput from 'intl-tel-input';
import utils from 'intl-tel-input/build/js/utils';
import PropTypes from 'prop-types';
import debounce from 'lodash/debounce';
import { fallbackFunc, safe } from '../../utils/utils';
import { NUMBER_FORMAT } from '../../constants/generic-constants';
import { getUserCountry } from '../../utils/api';

class PhoneNumber extends Component {
  componentWillMount() {
    this.refSetter = input => {
      this.inputRef = input;
    };

    this.updatePadding = debounce(() => {
      safe(() => {
        const country = this.telInstance.getSelectedCountryData().iso2;
        const trigger = country === 'gb' ? 'us' : 'gb';

        if (country) {
          this.telInstance.setCountry(trigger);
          this.telInstance.setCountry(country);
        }
      });
    }, 150);

    this.handleOnChange = debounce(() => {
      safe(() => {
        const number = this.telInstance.getNumber(NUMBER_FORMAT.E164);
        const country = this.telInstance.getSelectedCountryData();
        const valid = this.telInstance.isValidNumber();
        const phoneSuffix = number.substring(country.dialCode.length + 1);
        const suffixFallback = this.inputRef.value;

        this.currentValue = {
          phonePrefix: '+' + country.dialCode,
          phoneSuffix: phoneSuffix || suffixFallback,
          valid: valid === null ? true : valid
        };
        this.props.onChange(this.currentValue);
      });
    }, 300);

    this.handleOnBlur = () => {
      this.handleOnChange();
      this.props.onBlur();
    };
  }

  componentWillReceiveProps(nextProps) {
    this.componentPropsUpdated(nextProps);
  }

  componentPropsUpdated(nextProps, init) {
    if (this.valueUpdated(nextProps, init)) {
      this.updateCurrentValue(nextProps.value);
    }
  }

  componentDidMount() {
    const options = {
      utilsScript: utils,
      separateDialCode: true
    };

    const { value } = this.props;
    const { phoneSuffix, phonePrefix } = value || {};
    const noValue = !(phonePrefix && phoneSuffix);

    if (noValue) {
      options.initialCountry = 'auto';
      options.geoIpLookup = cb => getUserCountry(cb);
    }

    this.telInstance = intlTelInput(this.inputRef, options);
    this.componentPropsUpdated(this.props, true);
    window.addEventListener('resize', this.updatePadding);
  }

  componentWillUnmount() {
    this.telInstance.destroy();
    window.removeEventListener('resize', this.updatePadding);
  }

  numberIsEqual(aNumber = {}, bNumber = {}) {
    const { phonePrefix: aPrefix, phoneSuffix: aSuffix } = aNumber;
    const { phonePrefix: bPrefix, phoneSuffix: bSuffix } = bNumber;
    return aPrefix === bPrefix && aSuffix === bSuffix;
  }

  valueUpdated(nextProps, init) {
    const { value: nextValue } = nextProps;
    const { value: prevValue } = this.props;
    return init || !this.numberIsEqual(nextValue, prevValue);
  }

  updateCurrentValue(value = {}) {
    if (!this.numberIsEqual(value, this.currentValue)) {
      const phonePrefix = value.phonePrefix || '';
      const phoneSuffix = value.phoneSuffix || '';

      this.telInstance.setNumber(phonePrefix + phoneSuffix);
      this.updatePadding();
    }
  }

  render() {
    return (
      <input className={this.props.inputClass} ref={this.refSetter} onChange={this.handleOnChange} onBlur={this.handleOnBlur} type="tel" />
    );
  }
}

PhoneNumber.defaultProps = {
  onChange: fallbackFunc,
  onBlur: fallbackFunc
};

const phoneNumberType = PropTypes.shape({
  phonePrefix: PropTypes.string,
  phoneSuffix: PropTypes.string,
  valid: PropTypes.bool
});

// empty string is provided by redux-form when there is no value
const valueTypes = [phoneNumberType, PropTypes.string];

PhoneNumber.propTypes = {
  inputClass: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  value: PropTypes.oneOfType(valueTypes)
};

export default PhoneNumber;
