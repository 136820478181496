import React, { Component } from 'react';
import { updateAppBrand } from '../../../../../actions/appBrands-actions';
import { FormattedMessage } from 'react-intl';
import { getEditTitle } from '../../../../../components/_v2/ColumnList/helpers';
import { showParentView } from '../../../../../components/_v2/ColumnList/helpers';
import { appBrandsSelector, selectBrandEditData } from '../../../../../selectors/all-selectors';
import { connect } from 'react-redux';
import BrandView from './index';
import { safe } from '../../../../../utils/utils';
import { cleanDeep } from '../../../../../utils/cleanDeep';
import pick from 'lodash/pick';

export class OrganizationEditBrand extends Component {
  componentWillMount() {
    this.showParentView = showParentView.bind(this);

    this.handleCallback = data => {
      const payload = cleanDeep(pick(data, ['id', 'name', 'publiclyAccessible']));
      this.props.dispatch(updateAppBrand(payload));
    };
  }

  render() {
    return (
      <BrandView
        inputRef={this.props.inputRef}
        title={getEditTitle(<FormattedMessage id="brandForm_title" />)}
        fieldLabel={<FormattedMessage id="brandForm_name_label" />}
        onClose={this.showParentView}
        onCallback={this.handleCallback}
        initialValues={this.props.data}
        editView
      />
    );
  }
}

const mapStateToProps = (state, props) => {
  const { brandId } = safe(() => props.params) || {};
  const brandList = appBrandsSelector(state);

  return { data: selectBrandEditData(brandId, brandList) };
};

OrganizationEditBrand = connect(mapStateToProps)(OrganizationEditBrand);

export default OrganizationEditBrand;
