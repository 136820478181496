import fetch from 'isomorphic-fetch';

import config from '../constants/config-constants';

export const getTimezoneIdFromLocation = (position, successAction) => {
  return dispatch => {
    const location = `${position.latitude},${position.longitude}`,
      timestamp = Date.now() / 1000,
      key = config.gMapsApiKey;

    return fetch(`${config.gMapsTimeZoneApiUrl}?location=${location}&timestamp=${timestamp}&key=${key}`, {
      method: 'POST'
    })
      .then(res => res.json())
      .then((data = {}) => data.timeZoneId && dispatch(successAction(data.timeZoneId)));
  };
};
