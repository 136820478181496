import React, { Component, PropTypes } from 'react';
import { reduxForm } from 'redux-form';
import { FormattedMessage } from 'react-intl';
import BoxedSelect from '../../../components/BoxedSelect/BoxedSelect';
import SlimerSelect from '../../../components/SlimerSelect';
import { booleanOptions, memberStatusOptions, memberSubscriptionOriginOptions } from '../../../constants/options-constants';

import {
  headerCompanyListLoadingSelector,
  headerCompanyListSelector,
  memberTypeListLoadingSeletor,
  memberTypeListSeletor,
  subCompaniesListSelector,
  subCompanyListLoadingSelector
} from '../../../selectors/all-selectors';
import { createCompanyOptions, createMemberTypeOptions, createSubCompanyOptions } from '../utils';
import { getSubCompaniesList, subCompaniesListClear } from '../../../actions/subCompanies-actions';
import { clearMemberTypesListData, getMemberTypesList } from '../../../containers/MemberTypes/memberTypes-actions';
import { safe, scrollToFirstError } from '../../../utils/utils';
import { createValidator, notEmpty } from '../../../validation/sync-validation';

class MemberFilters extends Component {
  constructor(p) {
    super(p);
    this.setCallbacks();
  }

  oneSuperCompanySelected(companyIds = this.props.fields.companyIds.value) {
    return safe(() => companyIds.length === 1);
  }

  setCallbacks() {
    this.onSuperCompanySelect = data => {
      const { props: p } = this;

      if (this.oneSuperCompanySelected(data)) {
        p.dispatch(getSubCompaniesList(data[0], false));
        p.dispatch(getMemberTypesList({ companyId: data[0] }), false);
      } else {
        p.dispatch(subCompaniesListClear());
        p.dispatch(clearMemberTypesListData());
      }
    };
  }

  setSubmitHandler() {
    const { onSubmitSet: onSet } = this.props;
    if (onSet) onSet(this.props.handleSubmit);
  }

  getCompanyDependentData() {
    const { initialValue, value } = this.props.fields.companyIds;
    const data = initialValue || value;
    this.onSuperCompanySelect(data);
  }

  componentDidMount() {
    this.setSubmitHandler();
    this.getCompanyDependentData();
  }

  render() {
    const { props: p } = this;
    const { fields: f } = p;

    return (
      <form className="scheduled-export-filters">
        <div className="sc-block">
          <div className="sc-title">
            <FormattedMessage id="common_company" />
          </div>
          <div className="sc-items">
            <SlimerSelect
              id="filter-super-company"
              field={f.companyIds}
              options={p.companyOptions}
              onChange={this.onSuperCompanySelect}
              disabled={p.isView || p.companyOptionsLoading}
              labelKey="common_super_company"
            />
            <SlimerSelect
              id="filter-sub-company"
              field={f.subCompanyIds}
              options={p.subCompanyOptions}
              disabled={p.isView || p.subCompanyOptionsLoading || !this.oneSuperCompanySelected()}
              labelKey="common_company"
            />
          </div>
        </div>
        <div className="sc-block">
          <div className="sc-title">
            <FormattedMessage id="user_type_member" />
          </div>
          <div className="sc-items">
            <BoxedSelect
              id="filter-member-type"
              labelKey="common_member_type"
              options={p.memberTypeOptions}
              field={f.memberTypeId}
              disabled={p.isView || p.memberTypeOptionsLoading || !this.oneSuperCompanySelected()}
            />
            <BoxedSelect
              id="filter-member-status"
              labelKey="common_status"
              options={memberStatusOptions}
              field={f.status}
              disabled={p.isView}
            />
            <BoxedSelect
              id="filter-subscription-origin"
              labelKey="member_subscription_origin"
              options={memberSubscriptionOriginOptions}
              field={f.subscriptionOrigins}
              disabled={p.isView}
            />
          </div>
          <div className="sc-items">
            <BoxedSelect
              id="filter-member-suspended"
              labelKey="members_detail_suspended"
              options={booleanOptions}
              field={f.suspended}
              disabled={p.isView}
            />
            <BoxedSelect
              id="filter-member-technician"
              labelKey="members_detail_technician"
              options={booleanOptions}
              field={f.technician}
              disabled={p.isView}
            />
            <BoxedSelect
              id="filter-member-validated"
              labelKey="members_detail_validated"
              options={booleanOptions}
              field={f.validated}
              disabled={p.isView}
            />
          </div>
        </div>
      </form>
    );
  }
}

MemberFilters.propTypes = {
  onSubmitSet: PropTypes.func,
  isView: PropTypes.bool
};

MemberFilters = reduxForm(
  {
    onSubmitFail: scrollToFirstError,
    form: 'memberFilters',
    fields: ['companyIds', 'subCompanyIds', 'memberTypeId', 'status', 'subscriptionOrigins', 'suspended', 'technician', 'validated'],
    validate: createValidator({
      companyIds: [notEmpty()]
    })
  },
  state => ({
    companyOptions: createCompanyOptions(headerCompanyListSelector(state)),
    companyOptionsLoading: headerCompanyListLoadingSelector(state),
    subCompanyOptions: createSubCompanyOptions(subCompaniesListSelector(state)),
    subCompanyOptionsLoading: subCompanyListLoadingSelector(state),
    memberTypeOptions: createMemberTypeOptions(memberTypeListSeletor(state)),
    memberTypeOptionsLoading: memberTypeListLoadingSeletor(state)
  })
)(MemberFilters);

export default MemberFilters;
