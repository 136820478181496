import React, { PropTypes } from 'react';
import { FormattedMessage } from 'react-intl';
import _map from 'lodash/map';
import _partial from 'lodash/partial';

import LabeledCheckbox from '../LabeledCheckbox/LabeledCheckbox';
import EkButton from '../EkButton/EkButton';
import FieldErrorMsg from '../FieldErrorMsg/FieldErrorMsg';
import TimeSlots from '../SlotsTypes/TimeSlots';

import config from '../../constants/config-constants';

const WeeklyHoursSlots = ({
  slotTitle,
  limitedAccess,
  slotData,
  onHandleAddSlot,
  onHandleRemoveSlot,
  onHandleAddSubSlot,
  onHandleRemoveSubSlot
}) => {
  return (
    <div className="parkingForm_container">
      <h4 className="parkingForm_title">
        <FormattedMessage id={slotTitle} />
      </h4>

      {!limitedAccess && (
        <EkButton id="whs-add" onAction={onHandleAddSlot} customClass="ekButton--add parkingForm_addTimeSlot_button" skinType="reverse">
          <FormattedMessage id="parkingForm_addWeeklyDates" />
        </EkButton>
      )}

      <ul className="parkingForm_timeSlots">
        {_map(slotData, (item, index) => {
          return (
            <li className="parkingForm_timeSlots_item" key={index}>
              <div className="parkingForm_timeSlots_item_content">
                <div className="parkingForm_timeSlots_group">
                  <div className="parkingForm_weekly_title">
                    <FormattedMessage id="timeSlot_apply_label" />
                  </div>

                  <ul className="parkingForm_optionItems">
                    {config.daysOfWeek.map((daysItem, daysIndex) => {
                      return (
                        <li className="parkingForm_dayItem" key={`daysSlot_${daysItem.value}_${index}`}>
                          <LabeledCheckbox
                            customClass="vehiclesFiltersForm_labeledCheckbox"
                            customLabelClass="vehiclesFiltersForm_checkboxLabel"
                            id={`daysSlot_${daysItem.value}_${index}`}
                            field={item.days[daysIndex]}
                            disabled={limitedAccess}
                          >
                            <FormattedMessage id={`dayOfWeek_${daysItem.value}`} />
                          </LabeledCheckbox>
                          <FieldErrorMsg field={item.days[daysIndex]} customClass="fieldErrorMsg--parkingForm" />
                        </li>
                      );
                    })}
                  </ul>

                  {!limitedAccess && (
                    <EkButton
                      onAction={_partial(onHandleRemoveSlot, index)}
                      id={'whs-add-time' + index}
                      customClass="ekButton--remove parkingForm_addTime_button"
                      skinType="reverse"
                    >
                      <FormattedMessage id="parkingForm_deleteWeeklyDates" />
                    </EkButton>
                  )}

                  {!limitedAccess && (
                    <EkButton
                      id={'whs-add-time-frame' + index}
                      onAction={_partial(onHandleAddSubSlot, index)}
                      customClass="ekButton--add parkingForm_addTime_button"
                      skinType="reverse"
                    >
                      <FormattedMessage id="parkingForm_addTimeFrame" />
                    </EkButton>
                  )}
                </div>
              </div>

              <TimeSlots
                prefix="weekly"
                limitedAccess={limitedAccess}
                slotData={item}
                slotIndex={index}
                onHandleRemoveSlot={onHandleRemoveSubSlot}
              />
            </li>
          );
        })}
      </ul>
    </div>
  );
};

WeeklyHoursSlots.displayName = 'WeeklyHoursSlots';

WeeklyHoursSlots.propTypes = {
  slotTitle: PropTypes.string.isRequired,
  limitedAccess: PropTypes.bool,
  slotData: PropTypes.array.isRequired,
  onHandleAddSlot: PropTypes.func,
  onHandleRemoveSlot: PropTypes.func,
  onHandleAddSubSlot: PropTypes.func,
  onHandleRemoveSubSlot: PropTypes.func
};

export default WeeklyHoursSlots;
