import React, { Component, PropTypes } from 'react';
import GoogleMapReact from 'google-map-react';
import { meters2ScreenPixels } from 'google-map-react/utils';
import { reduxForm } from 'redux-form';
import _ from 'lodash';

import MapMarker from '../MapMarker/MapMarker';
import { createValidator, notEmpty, latitude, longitude, radius } from '../../validation/all-validation';
import MapCircle from '../MapCircle/MapCircle';
import config from '../../constants/config-constants';
import { scrollToFirstError } from '../../utils/utils';

/* eslint-disable react/no-set-state */

class ParkingMap extends Component {
  // init all the stuff
  constructor(props) {
    super(props);

    // function bindings
    this.handleOnClick = this.handleOnClick.bind(this);
    this.handleOnChange = this.handleOnChange.bind(this);
    this.handleZoomChange = this.handleZoomChange.bind(this);
    this.handleLocationChange = this.handleLocationChange.bind(this);
    this.validateProps = this.validateProps.bind(this);
    this.handleRadiusChange = this.handleRadiusChange.bind(this);
    this.renderCoordsAfterDelay = this.renderCoordsAfterDelay.bind(this);

    // pseudo const variables
    this.mapCursorType = 'crosshair';
    this.CssClassName = 'parkingMap';
    this.customRenderDelayTime = 500;

    // extract data from props
    const { latitude, longitude, radius } = this.props.fields;

    const { defaultZoom, defaultCenter, parkingZoom } = this.props;

    this.defaultCenter = {
      // Paris
      lat: _.get(defaultCenter, 'lat', 48.853425),
      lng: _.get(defaultCenter, 'lng', 2.3486)
    };

    // zoom level variables
    this.radius = radius.valid ? +radius.value : 0;
    const zoomForRadius = Math.round(-1.443 * Math.log(this.radius) + 24.644);
    this.defaultZoom = !latitude.valid || !longitude.valid ? defaultZoom : zoomForRadius + parkingZoom;
    this.currentZoom = _.clone(this.defaultZoom);

    // set state variables

    const validLocation = {
      lat: latitude.valid ? +latitude.value : this.defaultCenter.lat,
      lng: longitude.valid ? +longitude.value : this.defaultCenter.lng
    };

    this.state = {
      location: validLocation,
      circleSize: meters2ScreenPixels(this.radius, validLocation, this.currentZoom)
    };

    // init other helper variables
    this.nextRadius = _.clone(this.radius);
    this.nextLocation = _.clone(validLocation);
  }

  componentWillReceiveProps(nextProps) {
    this.validateProps(nextProps);
    this.handleRadiusChange();
    this.handleLocationChange();
  }

  componentWillUnmount() {
    if (this.timer) clearTimeout(this.timer);
  }

  // validate and update class props
  validateProps(nextProps) {
    const { latitude, longitude, radius } = nextProps.fields;

    // if received (lat, lng) is valid, update the map, else use previous values
    this.nextRadius = radius.valid ? +radius.value : this.radius;
    this.nextLocation = {
      lat: latitude.valid ? +latitude.value : this.state.location.lat,
      lng: longitude.valid ? +longitude.value : this.state.location.lng
    };
  }

  handleRadiusChange() {
    // if radius, or location is changed, recalculate circle size
    if (this.nextRadius !== this.radius || !_.isEqual(this.state.location, this.nextLocation)) {
      this.radius = _.clone(this.nextRadius);
      this.setState({ circleSize: meters2ScreenPixels(this.radius, this.nextLocation, this.currentZoom) });
    }
  }

  handleLocationChange() {
    // if location of parking changed, render coordinates after brief delay (user finish typing)
    if (!_.isEqual(this.state.location, this.nextLocation)) {
      if (this.timer) clearTimeout(this.timer);
      this.timer = setTimeout(this.renderCoordsAfterDelay, this.customRenderDelayTime);
    }
  }

  // update coordinates, render
  renderCoordsAfterDelay() {
    this.setState({ location: _.clone(this.nextLocation) });
  }

  handleOnChange(data) {
    this.handleZoomChange(data);
  }

  handleZoomChange(data) {
    // check if zoom on the map is changed, if so, update radius size
    if (this.currentZoom !== data.zoom) {
      this.currentZoom = data.zoom;
      this.setState({ circleSize: meters2ScreenPixels(this.radius, this.state.location, this.currentZoom) });
    }
  }

  // set clicked position to text box
  handleOnClick(data) {
    this.props.fields.latitude.onChange(Number(data.lat).toFixed(6));
    this.props.fields.longitude.onChange(Number(data.lng).toFixed(6));
    this.setState({
      location: {
        lat: data.lat,
        lng: data.lng
      }
    });
  }

  render() {
    return (
      <div className={this.CssClassName}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: config.gMapsApiKey }}
          center={this.state.location}
          defaultCenter={this.defaultCenter}
          defaultZoom={this.defaultZoom}
          onClick={!this.props.limitedAccess && this.handleOnClick}
          onChange={this.handleOnChange}
          options={{
            disableDoubleClickZoom: true,
            clickableIcons: false,
            draggableCursor: this.mapCursorType,
            fullscreenControl: false
          }}
        >
          <MapCircle
            width={this.state.circleSize.w}
            height={this.state.circleSize.h}
            lat={this.state.location.lat}
            lng={this.state.location.lng}
          />
          <MapMarker lat={this.state.location.lat} lng={this.state.location.lng} />
        </GoogleMapReact>
      </div>
    );
  }
}

ParkingMap.defaultProps = {
  parkingZoom: 0, // relative to parking | can be negative
  defaultZoom: 10, // absolute zoom | 0 = no zoom
  limitedAccess: false
};

ParkingMap.propTypes = {
  limitedAccess: PropTypes.bool,
  fields: PropTypes.object,
  parkingZoom: PropTypes.number,
  defaultZoom: PropTypes.number,
  defaultCenter: PropTypes.object // {lat: 0, lng: 0}
};

ParkingMap.displayName = 'ParkingMap';

export default reduxForm({
  onSubmitFail: scrollToFirstError,
  form: 'parkingForm',
  fields: ['latitude', 'longitude', 'radius'],
  validate: createValidator({
    latitude: [notEmpty(), latitude()],
    longitude: [notEmpty(), longitude()],
    radius: [notEmpty(), radius()]
  })
})(ParkingMap);
