import React, { Component, PropTypes } from 'react';
import { FormattedMessage } from 'react-intl';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';

import FormRow from '../../../../components/FormRow/FormRow';
import BoxedInput from '../../../../components/BoxedInput/BoxedInput';
import BoxedSelect from '../../../../components/BoxedSelect/BoxedSelect';
import EkButton from '../../../../components/EkButton/EkButton';
import AddressAutocomplete from '../../../../components/AddressAutocomplete/AddressAutocomplete';
import PhoneNumberField from '../../../../components/PhoneNumberField/PhoneNumberField';
import FieldErrorMsg from '../../../../components/FieldErrorMsg/FieldErrorMsg';
import { createValidator, notEmpty, checkPhoneNumber, address, length, email, url, rrfCode } from '../../../../validation/all-validation';
import { scrollToFirstError } from '../../../../utils/utils';
import { checkRole } from '../../../../constants/backuser-role-rules';
import { BACKUSER_ROLE_ADMIN_DEALER, BACKUSER_ROLE_CRM, BACKUSER_ROLE_SERVICE_ADVISOR } from '../../../../constants/backend-constants';
import memoizeOne from 'memoize-one';

const editSubCompanyNameRules = {
  exclude: [BACKUSER_ROLE_ADMIN_DEALER, BACKUSER_ROLE_CRM, BACKUSER_ROLE_SERVICE_ADVISOR]
};

const mapOptions = memoizeOne(data =>
  data.map(item => ({
    label: item.name,
    value: item.id
  }))
);

class SubCompanyForm extends Component {
  constructor(props) {
    super(props);
    this.bindCallbacks();
  }

  bindCallbacks() {
    this.handleConfigurationSelect = this.handleConfigurationSelect.bind(this);
  }

  handleConfigurationSelect(value) {
    if (this.props.onConfigurationSelect) {
      this.props.onConfigurationSelect(value);
    }
  }

  displayActionButtons() {
    const { handleSubmit, isDisplayForm, onCancel } = this.props;

    return (
      <div className="subCompanyForm_actions">
        {!isDisplayForm && onCancel && (
          <span onClick={onCancel} className="ekButton ekButton--reverse subCompanyForm_actionsLink">
            <FormattedMessage id="subCompanyForm_cancel_button" />
          </span>
        )}
        <EkButton customClass="subCompanyForm_actionsButton" onAction={handleSubmit(this.props.onCallback)} disabled={this.props.pristine}>
          <FormattedMessage id="subCompanyForm_save_button" />
        </EkButton>
      </div>
    );
  }

  render() {
    const {
      fields: {
        name,
        address,
        postalCode,
        city,
        country,
        email,
        secondaryEmail,
        phoneNumber,
        secondaryPhoneNumber,
        agencyCode,
        configurationId,
        logoUrl,
        externalId
      },
      readOnly,
      handleSubmit,
      allConfigurations,
      role
    } = this.props;

    return (
      <section className="subCompanyFormWrapper">
        <form action="#" className="subCompanyForm" onSubmit={handleSubmit(this.props.onCallback)}>
          <div className="subCompanyForm_container">
            <FormRow customClass="subCompanyForm_row">
              <BoxedInput
                formRowItemKey="subCompanyForm_name"
                type="text"
                id="name"
                labelKey="subCompanyForm_name_label"
                customClass="subCompanyForm_boxedInput"
                disabled={readOnly || !checkRole(editSubCompanyNameRules, role)}
                field={name}
                mandatory
              >
                <FieldErrorMsg field={name} customClass="fieldErrorMsg--subCompanyForm" />
              </BoxedInput>
            </FormRow>

            <FormRow customClass="subCompanyForm_row">
              <label formRowItemKey="subCompanyForm_address_label" className="boxedInput_label">
                <span className="boxedInput_labelText">
                  <FormattedMessage id="subCompanyForm_address_label" /> <span className="labelMandatory">*</span>
                </span>
                <AddressAutocomplete
                  field={address}
                  className="subCompanyForm_AddressAutocomplete"
                  form="subCompanyForm"
                  spreadExternalFields
                  disabled={readOnly}
                />
                <FieldErrorMsg field={address} customClass="fieldErrorMsg--subCompanyForm" />
              </label>
              <BoxedInput
                formRowItemKey="companyForm_postalCode"
                type="text"
                id="postalCode"
                labelKey="common_autocomplete_postal_code"
                customClass="companyForm_boxedInput"
                field={postalCode}
                mandatory
                disabled={readOnly}
              >
                <FieldErrorMsg field={postalCode} customClass="fieldErrorMsg--companyForm" />
              </BoxedInput>
              <BoxedInput
                formRowItemKey="companyForm_city"
                type="text"
                id="city"
                labelKey="common_autocomplete_city"
                customClass="companyForm_boxedInput"
                field={city}
                mandatory
                disabled={readOnly}
              >
                <FieldErrorMsg field={city} customClass="fieldErrorMsg--companyForm" />
              </BoxedInput>
              <label className="boxedInput_label">
                <span className="boxedInput_labelText">
                  <FormattedMessage id="common_autocomplete_country" />
                  <span className="labelMandatory">*</span>
                </span>
                <AddressAutocomplete field={country} searchType={['country']} disabled={readOnly} translateCountryCode />
                <FieldErrorMsg field={country} customClass="fieldErrorMsg--companyForm" />
              </label>
            </FormRow>

            <FormRow customClass="subCompanyForm_row">
              <BoxedInput
                formRowItemKey="subCompanyForm_email"
                type="text"
                id="email"
                labelKey="subCompanyForm_email_label"
                customClass="subCompanyForm_boxedInput"
                field={email}
                disabled={readOnly}
                mandatory
              >
                <FieldErrorMsg field={email} customClass="fieldErrorMsg--subCompanyForm" />
              </BoxedInput>

              <BoxedInput
                formRowItemKey="subCompanyForm_email"
                type="text"
                id="secondaryEmail"
                labelKey="companyForm_secondary_email_label"
                customClass="subCompanyForm_boxedInput"
                field={secondaryEmail}
                disabled={readOnly}
              >
                <FieldErrorMsg field={secondaryEmail} customClass="fieldErrorMsg--companyForm" />
              </BoxedInput>
            </FormRow>

            <FormRow customClass="subCompanyForm_row">
              <PhoneNumberField
                id="phoneNumber"
                className="boxedInputWrapper"
                label={<FormattedMessage id="subCompanyForm_mobilePhone_label" />}
                field={phoneNumber}
                disabled={readOnly}
                mandatory
              />
              <PhoneNumberField
                id="secondaryPhoneNumber"
                className="boxedInputWrapper"
                label={<FormattedMessage id="subCompanyForm_secondary_mobilePhone_label" />}
                field={secondaryPhoneNumber}
                disabled={readOnly}
              />
            </FormRow>

            <FormRow customClass="subCompanyForm_row">
              <BoxedInput
                formRowItemKey="subCompanyForm_agencyCode"
                type="text"
                id="agencyCode"
                labelKey="subCompanyForm_agencyCode_label"
                customClass="subCompanyForm_boxedInput"
                field={agencyCode}
                disabled={readOnly}
              >
                <FieldErrorMsg field={agencyCode} customClass="fieldErrorMsg--subCompanyForm" />
              </BoxedInput>
              <BoxedInput
                formRowItemKey="subCompanyForm_agencyCode"
                type="text"
                id="externalId"
                labelKey="company.field.external.id"
                customClass="subCompanyForm_boxedInput"
                field={externalId}
                disabled={readOnly}
              >
                <FieldErrorMsg field={externalId} customClass="fieldErrorMsg--subCompanyForm" />
              </BoxedInput>
            </FormRow>

            <FormRow customClass="subCompanyForm_row">
              <BoxedInput
                formRowItemKey="subCompanyForm_logoUrl"
                type="text"
                id="termsOfUse"
                labelKey="subCompanyForm_logoUrl_label"
                customClass="subCompanyForm_boxedInput"
                field={logoUrl}
                disabled={readOnly}
              >
                <FieldErrorMsg field={logoUrl} customClass="fieldErrorMsg--companyForm" />
              </BoxedInput>

              <BoxedSelect
                formRowItemKey="subCompanyForm_parent_configuration"
                id="configurationId"
                labelKey="subCompanyForm_configuration_label"
                options={mapOptions(allConfigurations)}
                field={configurationId}
                customClass="subCompanyForm_fullWidthBoxedSelectWrapper"
                disabled={readOnly}
                onChange={this.handleConfigurationSelect}
              />
            </FormRow>
          </div>

          {!readOnly && this.displayActionButtons()}
        </form>
      </section>
    );
  }
}

SubCompanyForm.displayName = 'SubCompanyForm';

SubCompanyForm.propTypes = {
  onCancel: PropTypes.func,
  isDisplayForm: PropTypes.bool,
  readOnly: PropTypes.bool,
  fields: PropTypes.object,
  handleSubmit: PropTypes.func,
  onCallback: PropTypes.func,
  subCompanyId: PropTypes.string,
  onConfigurationSelect: PropTypes.func
};

SubCompanyForm = reduxForm({
  onSubmitFail: scrollToFirstError,
  form: 'subCompanyForm',
  fields: [
    'name',
    'address',
    'postalCode',
    'city',
    'country',
    'email',
    'secondaryEmail',
    'phoneNumber',
    'secondaryPhoneNumber',
    'agencyCode',
    'configurationId',
    'logoUrl',
    'externalId'
  ],
  validate: createValidator({
    name: [notEmpty()],
    address: [address(), length({ max: 80 })],
    email: [length({ max: 75 }), email(), notEmpty()],
    secondaryEmail: [length({ max: 75 }), email()],
    phoneNumber: [checkPhoneNumber()],
    agencyCode: [rrfCode()],
    logoUrl: [url()]
  })
})(SubCompanyForm);

export default connect(state => {
  const {
    subCompanies: { urlParams },
    user: {
      userInfo: { role }
    },
    configuration: { allConfigurations }
  } = state;

  return {
    role,
    urlParams,
    allConfigurations
  };
})(SubCompanyForm);
