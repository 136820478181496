import React, { Component, PropTypes } from 'react';
import Header from './Header';
import ListWrap from '../../../../components/_v2/ColumnList/Wrap';
import cs from 'classnames';

class Tabs extends Component {
  isOneTab() {
    const { tabs } = this.props;
    return tabs.length < 2;
  }

  render() {
    const oneTab = this.isOneTab();

    return (
      <div className={cs('tabs-rci', { oneTab })}>
        {!oneTab && (
          <ol className="tab-list">
            {this.props.tabs.map((t, n) => (
              <Header key={n} id={t.id} label={t.getLabel()} onClick={t.onClick} active={this.props.isActive(t.childRoute)} />
            ))}
          </ol>
        )}
        <div className="tabs-content">
          <ListWrap {...this.props} />
        </div>
      </div>
    );
  }
}

/*
  id: string
  getLabel: func
  onClick: func
  childRoute: string
 */

Header.propTypes = {
  tabs: PropTypes.array,
  isActive: PropTypes.func
};

export default Tabs;
