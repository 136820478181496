import React, { PropTypes } from 'react';
import _map from 'lodash/map';
import _partial from 'lodash/partial';

import BoxedSelect from '../BoxedSelect/BoxedSelect';
import FieldErrorMsg from '../FieldErrorMsg/FieldErrorMsg';

import config from '../../constants/config-constants';

const TimeSlots = ({ limitedAccess, slotData, slotIndex, onHandleRemoveSlot, prefix }) => {
  return (
    <ul className="parkingForm_timeSlots parkingForm_subTimeSlots">
      {_map(slotData.items, (hoursItem, hoursIndex) => {
        return (
          <li className="parkingForm_timeSlots_item" key={hoursIndex}>
            <div className="parkingForm_timeSlots_item_content">
              <div className="parkingForm_timeSlots_group parkingForm_timeSlots_subGroup">
                <BoxedSelect
                  labelKey="timeSlot_from_label"
                  options={config.availableHours}
                  blockCustomClass="timeSlot_boxedSelectBlock timeSlot_boxedSelectBlock--toHour v-top"
                  customClass="timeSlot_toHour_boxedSelectWrapper"
                  disabled={limitedAccess}
                  field={hoursItem.startHours}
                >
                  <FieldErrorMsg field={hoursItem.startHours} customClass="fieldErrorMsg--parkingForm" />
                </BoxedSelect>

                <BoxedSelect
                  labelKey="timeSlot_from_label"
                  options={config.availableMinutes}
                  blockCustomClass="timeSlot_boxedSelectBlock timeSlot_boxedSelectBlock--toMinutes v-top"
                  customClass="timeSlot_toMinutes_boxedSelectWrapper"
                  disabled={limitedAccess}
                  notVisibleLabel
                  field={hoursItem.startMinutes}
                >
                  <FieldErrorMsg field={hoursItem.startMinutes} customClass="fieldErrorMsg--parkingForm" />
                </BoxedSelect>

                <BoxedSelect
                  labelKey="timeSlot_to_label"
                  options={config.availableHours}
                  blockCustomClass="timeSlot_boxedSelectBlock timeSlot_boxedSelectBlock--toHour
                                    parkingForm_marginTimeBox v-top"
                  customClass="timeSlot_toHour_boxedSelectWrapper"
                  disabled={limitedAccess}
                  field={hoursItem.endHours}
                >
                  <FieldErrorMsg field={hoursItem.endHours} customClass="fieldErrorMsg--parkingForm" />
                </BoxedSelect>

                <BoxedSelect
                  labelKey="timeSlot_to_label"
                  options={config.availableMinutes}
                  blockCustomClass="timeSlot_boxedSelectBlock timeSlot_boxedSelectBlock--toMinutes v-top"
                  customClass="timeSlot_toMinutes_boxedSelectWrapper"
                  disabled={limitedAccess}
                  notVisibleLabel
                  field={hoursItem.endMinutes}
                >
                  <FieldErrorMsg field={hoursItem.endMinutes} customClass="fieldErrorMsg--parkingForm" />
                </BoxedSelect>
              </div>
            </div>

            {!limitedAccess && (
              <div className="parkingForm_timeSlots_item_actionButton">
                <button
                  type="button"
                  id={prefix + '-remove-' + hoursIndex}
                  className="parkingForm_timeSlots_item_removeButton"
                  onClick={_partial(onHandleRemoveSlot, slotIndex, hoursIndex)}
                />
              </div>
            )}
          </li>
        );
      })}
    </ul>
  );
};

TimeSlots.displayName = 'TimeSlots';

TimeSlots.propTypes = {
  limitedAccess: PropTypes.bool,
  slotData: PropTypes.object.isRequired,
  slotIndex: PropTypes.number.isRequired,
  onHandleRemoveSlot: PropTypes.func
};

export default TimeSlots;
