import {
  SMARTCARDS_GET_LIST_SUCCESS,
  SMARTCARDS_FILTERS_FORM_OPENED_STATE_TOGGLE,
  SMARTCARDS_EXPORT_REQUEST,
  SMARTCARDS_GET_DETAIL_SUCCESS,
  SMARTCARDS_REMOVE_USER_SUCCESS,
  SMARTCARDS_CHANGE_STATUS_SUCCESS,
  SMARTCARD_USER_ASSIGN_SUCCESS,
  SMARTCARD_USER_ASSIGN_ERROR,
  SMARTCARDS_GET_LIST_REQUEST,
  SMARTCARDS_GET_DETAIL_REQUEST,
  SMARTCARDS_EMPTY_LIST,
  SMARTCARDS_EMPTY_DETAIL
} from '../constants/actionTypes-constants';
import { UPDATE_LOCATION } from 'react-router-redux';

const initialState = {
  paginatedResults: [],
  detailSmartcard: {},
  currentSortedIndex: null,
  sortIsDescending: false,
  urlParams: null,
  errorMemberSelection: false,
  smartcardsFiltersFormIsOpen: false,
  exportQuery: null,
  loadingList: true,
  loadingDetail: true
};

function smartcardsReducer(state = initialState, action) {
  switch (action.type) {
    case SMARTCARDS_EMPTY_DETAIL:
      return {
        ...state,
        detailSmartcard: {}
      };

    case SMARTCARDS_EMPTY_LIST:
      return {
        ...state,
        paginatedResults: []
      };

    case SMARTCARDS_GET_DETAIL_REQUEST:
      return {
        ...state,
        loadingDetail: true
      };

    case SMARTCARDS_GET_LIST_REQUEST:
      return {
        ...state,
        loadingList: true
      };

    case SMARTCARDS_GET_LIST_SUCCESS:
      return {
        ...state,
        paginatedResults: action.paginatedObj,
        urlParams: action.urlParams,
        loadingList: false
      };

    case UPDATE_LOCATION:
      return {
        ...state,
        currentSortedIndex: null,
        sortIsDescending: false
      };

    case SMARTCARDS_FILTERS_FORM_OPENED_STATE_TOGGLE:
      return {
        ...state,
        smartcardsFiltersFormIsOpen: !state.smartcardsFiltersFormIsOpen
      };

    case SMARTCARDS_EXPORT_REQUEST:
      return {
        ...state,
        exportQuery: action.query
      };
    case SMARTCARDS_GET_DETAIL_SUCCESS:
      return {
        ...state,
        detailSmartcard: action.smartcard,
        loadingDetail: false
      };
    case SMARTCARDS_REMOVE_USER_SUCCESS:
      return {
        ...state,
        detailSmartcard: action.smartcard
      };
    case SMARTCARD_USER_ASSIGN_SUCCESS:
      return {
        ...state,
        detailSmartcard: action.smartcard,
        errorMemberSelection: false
      };
    case SMARTCARD_USER_ASSIGN_ERROR:
      return {
        ...state,
        errorMemberSelection: true
      };
    case SMARTCARDS_CHANGE_STATUS_SUCCESS:
      return {
        ...state,
        detailSmartcard: {
          ...state.detailSmartcard,
          activationDate: action.smartcard.activationDate,
          deactivationDate: action.smartcard.deactivationDate
        }
      };

    default:
      return state;
  }
}

export default smartcardsReducer;
