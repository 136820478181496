import 'intl';
import React, { Component } from 'react';
import { Router } from 'react-router';
import { addLocaleData, IntlProvider } from 'react-intl';
import { connect } from 'react-redux';

// search key-word 'en'
import fr from 'react-intl/locale-data/fr';
import es from 'react-intl/locale-data/es';
import it from 'react-intl/locale-data/it';
import pt from 'react-intl/locale-data/pt';
import da from 'react-intl/locale-data/da';
import nl from 'react-intl/locale-data/nl';
import pl from 'react-intl/locale-data/pl';
import sv from 'react-intl/locale-data/sv';
import de from 'react-intl/locale-data/de';
import cs from 'react-intl/locale-data/cs';
import br from 'react-intl/locale-data/br';
import sk from 'react-intl/locale-data/sk';

// also check the i18n-actions.js
import { getStore } from '../store/all-store';
import { getRoutesConfig, history } from '../routing/all-routing';
import { setToken } from '../actions/api-actions';
import { initTokenHandlers } from '../api/ApiCaller';
import getMuiTheme from 'material-ui/styles/getMuiTheme';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import UITheme from '../constants/material-ui-theme-constants';

import {
  brandThemeSelector,
  configErrorSelector,
  configFetchedSelector,
  rootClassesSelector,
  routeLoadedSelector,
  useNewHeaderSelector,
  userCompaniesSelector,
  userEmailSelector,
  userLoggedInSelector
} from '../selectors/all-selectors';

import { isValidId, newProp, safe } from '../utils/utils';
import preset from 'jss-preset-default';
import jss from 'jss';
import styles from '../constants/brand-styles';
import ReactSVG from 'react-svg';
import { SITE_ERROR } from '../constants/generic-constants';
import classNames from 'classnames';
import IntlGlobalProvider from '../utils/IntlGlobalProvider';
import * as types from '../constants/actionTypes-constants';
import { getAwsBanner, getAwsBrand, pollingExport, pollingMembersExport, setLanguage, updateRootClasses } from '../actions/all-actions';

jss.setup(preset());

initTokenHandlers(
  function(token) {
    getStore().dispatch(setToken(token));
  },
  function() {
    return getStore().getState().api.token;
  }
);

// search key-word 'en'
// add imported locales here | excluding english

addLocaleData([...fr, ...es, ...it, ...pt, ...de, ...da, ...nl, ...pl, ...sv, ...cs, ...br, ...sk]);

const reactIntlMap = {
  pt_BR: 'br'
};

const defaultTheme = {
  fontFamily: 'inherit'
};

class Root extends Component {
  updateData() {
    const { dispatch, bundle } = this.props;
    if (!bundle) dispatch(setLanguage());

    dispatch(getAwsBrand());
    dispatch(getAwsBanner());
  }

  componentWillMount() {
    this.setCallbacks();
    this.setClassUpdater();
    this.updateData();
    this.initProps();
  }

  componentWillUnmount() {
    this.removeClassUpdater();
  }

  initProps() {
    this.componentPropsUpdated(this.props);
    this.propsInit = true;
    this.state = {};
  }

  componentWillReceiveProps(props) {
    this.componentPropsUpdated(props);
  }

  componentPropsUpdated(props) {
    this.applyTheme(props);
    this.updateExports(props);
  }

  componentDidUpdate() {
    this.hideLoader();
  }

  updateExports() {
    if (this.props.userLoggedIn) {
      const id = window.localStorage.getItem('idExportBookings');
      const idMembers = window.localStorage.getItem('idExportMembers');
      const { dispatch } = this.props;

      if (id) {
        dispatch({ type: types.BOOKINGS_EXPORTV3_REQUEST });
        dispatch(pollingExport(id));
      }
      if (idMembers) {
        dispatch({ type: types.MEMBERS_EXPORT_REQUEST });
        dispatch(pollingMembersExport(idMembers));
      }
    }
  }

  setCallbacks() {
    this.setRootClasses = () => {
      let splitData = safe(() => window.location.hash.split('?')[0]); // split query
      splitData = safe(() => splitData.split('/')) || []; // split path
      let rootClasses = []; // declare

      // support multiple path classes
      for (let i = 1; i < splitData.length; i++) {
        const path = splitData[i]; // select path value
        const isObj = typeof safe(() => JSON.parse(decodeURIComponent(path))) === 'object'; // check if obj
        const isUid = isValidId(path); // check if UID
        if (!isObj && !isUid && path) rootClasses.push('_' + path); // add _ prefix
      }

      this.props.dispatch(updateRootClasses(rootClasses));
    };
  }

  setClassUpdater() {
    this.setRootClasses();
    window.addEventListener('hashchange', this.setRootClasses);
  }

  removeClassUpdater() {
    window.removeEventListener('hashchange', this.setRootClasses);
  }

  hideLoader() {
    if (!this.loaded && this.props.routeLoaded) {
      setTimeout(() => this.setState({ loaded: true }), 500);
      this.loaded = true;
    }
  }

  applyTheme(props) {
    if (!props.theme) return;

    if (newProp.call(this, props, 'theme')) {
      this.updateMuiTheme(props);
      this.handleStyles(props);
    }
  }

  handleStyles({ theme }) {
    if (this.sheet) jss.removeStyleSheet(this.sheet);
    this.sheet = jss.createStyleSheet(styles(theme)).attach();
  }

  updateMuiTheme({ theme }) {
    const { color, materialPalette } = theme || {};

    this.muiTheme = {
      ...defaultTheme,
      palette: {
        primary1Color: color,
        primary2Color: color,
        accent1Color: color,
        pickerHeaderColor: color,
        ...materialPalette
      }
    };
  }

  showLoader() {
    return (
      <span className="app-loader">
        <span />
        <span />
        <span />
        <span />
      </span>
    );
  }

  delayLoader() {
    if (!this.state.loaded) {
      return this.showLoader();
    }
  }

  showError() {
    return (
      <span className="app-error">
        <div className="sc-content">
          <ReactSVG src="/img/site-error.svg" />
          <span className="sc-title">{this.props.bundle['site.config.error'] || SITE_ERROR}</span>
        </div>
      </span>
    );
  }

  render() {
    const { locale, bundle, useNewHeader } = this.props;
    const reactIntlLocale = reactIntlMap[locale] || locale;

    if (!bundle || !locale) return this.showLoader();
    if (this.props.configError) return this.showError();

    return (
      <MuiThemeProvider muiTheme={getMuiTheme(this.muiTheme || UITheme)}>
        <IntlProvider locale={reactIntlLocale} messages={bundle}>
          <IntlGlobalProvider>
            <div
              className={classNames('ui-wrap', { 'new-ui': this.props.enableNewUi, 'new-header': useNewHeader }, this.props.rootClasses)}
            >
              {this.delayLoader()}
              {this.props.configFetched && <Router history={history} routes={getRoutesConfig()} />}
            </div>
          </IntlGlobalProvider>
        </IntlProvider>
      </MuiThemeProvider>
    );
  }
}

Root.displayName = 'Root';

export default connect(state => {
  const {
    i18n: { locale, bundle },
    user: { enableNewUi }
  } = state;

  return {
    userEmail: userEmailSelector(state),
    configFetched: configFetchedSelector(state),
    userLoggedIn: userLoggedInSelector(state),
    userCompanies: userCompaniesSelector(state),
    rootClasses: rootClassesSelector(state),
    configError: configErrorSelector(state),
    routeLoaded: routeLoadedSelector(state),
    useNewHeader: useNewHeaderSelector(state),
    theme: brandThemeSelector(state),
    enableNewUi,
    locale,
    bundle
  };
})(Root);
