// noinspection ES6CheckImport
import React, { Component, PropTypes } from 'react';

import { FormattedMessage } from 'react-intl';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';

import FormRow from '../FormRow/FormRow';
import BoxedInput from '../BoxedInput/BoxedInput';
import PhoneNumberField from '../PhoneNumberField/PhoneNumberField';
import AddressAutocomplete from '../AddressAutocomplete/AddressAutocomplete';
import FieldErrorMsg from '../FieldErrorMsg/FieldErrorMsg';
import {
  createValidator,
  createAsyncValidator,
  notEmpty,
  address,
  length,
  email,
  backUserEmailAvailable,
  checkPhoneNumber
} from '../../validation/all-validation';

import { scrollToFirstError } from '../../utils/utils';
import routes from '../../constants/routes-constants';
import BackLink from '../BackLink/BackLink';
import { apiParams } from '../../constants/api-params-constants';
import BackUserRoleForm from '../BackUserRoleForm/BackUserRoleForm';
import submitButton from './submitButton';
import { routeActions } from 'react-router-redux';
import { backuserValidators } from '../../containers/BackUsers/backuser-validators';

class BackUserForm extends Component {
  constructor(props) {
    super(props);
    this.bindFunctions();
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  bindFunctions() {
    this.cancelAction = this.cancelAction.bind(this);
  }

  cancelAction() {
    const { urlParams, detailBackUser } = this.props;
    const cancelPath = detailBackUser
      ? routes.backUserDetail.path.replace(':backUserId', detailBackUser.id)
      : routes.backUsers.path.replace(':search', encodeURIComponent(JSON.stringify(urlParams ? urlParams : apiParams.default)));
    this.props.dispatch(routeActions.push(cancelPath));
  }

  handleSubmit(e) {
    const { handleSubmit } = this.props;
    const submitPromise = handleSubmit(e);

    if (submitPromise) {
      if (submitPromise.catch) submitPromise.catch(() => null);
      if (submitPromise.then) submitPromise.then(this.props.onCallback);
    }
  }

  render() {
    const {
      fields,
      fields: { login, phoneNumber, firstName, lastName, address },
      detailBackUser
    } = this.props;

    // noinspection HtmlUnknownAttribute
    return (
      <section className="backUserFormWrapper">
        <BackLink onClickBack={this.cancelAction} labelKey={detailBackUser ? 'back_link_backuser_detail' : 'back_link_backusers_list'} />
        <form action="#" className="backUserForm" onSubmit={this.handleSubmit} noValidate>
          <div className="backUserForm_container">
            <FormRow customClass="backUserForm_row">
              <BoxedInput
                formRowItemKey="backUserForm_email"
                type="text"
                id="email"
                labelKey="backUserForm_email_label"
                customClass="backUserForm_boxedInput"
                field={login}
                readOnly={this.props.emailReadOnly}
              >
                <FieldErrorMsg field={login} customClass="fieldErrorMsg--backUserForm" />
              </BoxedInput>
              <PhoneNumberField label={<FormattedMessage id="backUsers_detail_phone_number" />} field={phoneNumber} />
            </FormRow>

            <FormRow customClass="backUserForm_row">
              <BoxedInput
                formRowItemKey="backUserForm_firstName"
                type="text"
                id="firstName"
                labelKey="backUserForm_firstName_label"
                customClass="backUserForm_boxedInput"
                field={firstName}
              >
                <FieldErrorMsg field={firstName} customClass="fieldErrorMsg--backUserForm" />
              </BoxedInput>

              <BoxedInput
                formRowItemKey="backUserForm_lastName"
                type="text"
                id="lastName"
                labelKey="backUserForm_lastName_label"
                customClass="backUserForm_boxedInput"
                field={lastName}
              >
                <FieldErrorMsg field={lastName} customClass="fieldErrorMsg--backUserForm" />
              </BoxedInput>
            </FormRow>

            <FormRow customClass="backUserForm_row">
              <label formRowItemKey="backUserForm_address_label" className="boxedInput_label">
                <span className="boxedInput_labelText">
                  <FormattedMessage id="backUserForm_address_label" />
                </span>
                <AddressAutocomplete field={address} className="backUserForm_AddressAutocomplete" />

                <FieldErrorMsg field={address} customClass="fieldErrorMsg--backUserForm" />
              </label>
            </FormRow>

            <BackUserRoleForm fields={fields} />

            {submitButton(this.handleSubmit, this.cancelAction)}
          </div>
        </form>
      </section>
    );
  }
}

BackUserForm.displayName = 'BackUserForm';

BackUserForm.propTypes = {
  fields: PropTypes.object,
  handleSubmit: PropTypes.func,
  onCallback: PropTypes.func,
  emailReadOnly: PropTypes.bool
};

// noinspection DuplicatedCode
BackUserForm = reduxForm({
  onSubmitFail: scrollToFirstError,
  onSubmit: () => '', // fix returnRejectedSubmitPromise throw
  returnRejectedSubmitPromise: true,
  form: 'backUserForm',
  fields: [
    'login',
    'phoneNumber',
    'firstName',
    'lastName',
    'address',
    'userRole',
    'availableCompaniesIds',
    'selectedCompaniesIds',
    'availableSubCompaniesIds',
    'selectedSubCompaniesIds',
    'companyId',
    'subCompanyId'
  ],
  validate: createValidator({
    login: [notEmpty(), length({ max: 75 }), email()],
    phoneNumber: [checkPhoneNumber()],
    firstName: [notEmpty(), length({ min: 2, max: 25 })],
    lastName: [notEmpty(), length({ min: 2, max: 25 })],
    address: [address(false), length({ max: 80 })],
    ...backuserValidators
  }),
  asyncBlurFields: ['login'],
  asyncValidate: createAsyncValidator({
    login: [backUserEmailAvailable({ stopValidation: props => props.emailReadOnly })]
  })
})(BackUserForm);

const mapStateToProps = state => {
  const {
    backUsers: { detailBackUser, urlParams, availableCompanies, selectedCompanies, availableSubCompanies, selectedSubCompanies },
    subCompanies: { subCompaniesOptions },
    user: { userInfo }
  } = state;

  return {
    detailBackUser,
    urlParams,
    subCompaniesOptions,
    availableCompanies,
    selectedCompanies,
    availableSubCompanies,
    selectedSubCompanies,
    userInfo
  };
};

export default connect(mapStateToProps)(BackUserForm);
