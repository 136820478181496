/*eslint-disable react/jsx-no-bind */
/*eslint-disable react/no-set-state */

import React, { Component, PropTypes as T } from 'react';
import { connect } from 'react-redux';
import _get from 'lodash/get';
import _debounce from 'lodash/debounce';
import Dialog from 'material-ui/Dialog';
import autoBind from 'react-autobind';
import AutoComplete from 'material-ui/AutoComplete';
import IconButton from 'material-ui/IconButton';
import CloseIcon from 'material-ui/svg-icons/navigation/close';
import FlatButton from 'material-ui/FlatButton';
import { BOOKING_COMMENT_INIT_VALUE, PAID_BOOKING_INIT_VALUE } from '../../../constants/form-constants';
import { FLASH_MESSAGE_TYPE_SUCCESS } from '../../../constants/generic-constants';

import {
  toggleChangeMember,
  updateUserLoginForBooking,
  errorWhileSelectingUserForBooking,
  confirmBookingEdition,
  getDataVehiclePlanning,
  addFlashMessage,
  searchMembers,
  clearFoundUsers
} from '../../../actions/all-actions';
import { FormattedMessage, injectIntl } from 'react-intl';
import { addErrorMessage } from '../../../utils/utils';
import { bundleSelector } from '../../../selectors/all-selectors';

class VehiclePlanningMemberChange extends Component {
  constructor() {
    super();
    autoBind(this);
    this.state = {
      openConfirmDialog: false,
      confirmDialogData: {}
    };
    this.handleGetUsers = _debounce(this.handleGetUsers, 150);
  }

  componentWillMount() {
    const { dispatch } = this.props;
    dispatch(clearFoundUsers());
  }

  handleClose() {
    this.props.dispatch(toggleChangeMember());
  }

  handleGetUsers(input) {
    const { dispatch, data } = this.props;
    const companyId = _get(data, 'company.id');
    dispatch(searchMembers(companyId, input));
  }

  handleSelectUser(user) {
    const { dispatch, bookingDetail } = this.props;
    if (user.hasOwnProperty('value')) {
      dispatch(updateUserLoginForBooking(user));
      this.setState({ openConfirmDialog: true, confirmDialogData: { user, prevUser: bookingDetail.member } });
    } else dispatch(errorWhileSelectingUserForBooking());
  }

  handleToggleConfirmDialog(member) {
    this.setState({
      openConfirmDialog: !this.state.openConfirmDialog,
      confirmDialogData: this.state.openConfirmDialog ? {} : member
    });
  }

  handleConfirm() {
    const { dispatch, bookingDetail, userDetailEditBooking, bundle } = this.props;

    const confirmationDetails = {
      comment: _get(bookingDetail, 'comment', BOOKING_COMMENT_INIT_VALUE),
      paidBooking: !_get(bookingDetail, 'carSharingInfo.freeOfCharges', !PAID_BOOKING_INIT_VALUE)
    };

    dispatch(confirmBookingEdition(userDetailEditBooking, bookingDetail, confirmationDetails, bookingDetail.id))
      .then(() => {
        setTimeout(() => dispatch(getDataVehiclePlanning()), 1000); // wait server to process data
        this.handleClose();
        dispatch(
          addFlashMessage({
            contentKey: 'booking_edit_success',
            type: FLASH_MESSAGE_TYPE_SUCCESS
          })
        );
      })
      .catch(error => {
        dispatch(addErrorMessage({ error, bundle, errorCodePrefixes: ['bookingResult_'] }));
      });
  }

  render() {
    const { openedMemberChangeModal, errorMemberSelection, intl, foundUsers } = this.props;
    const { formatMessage } = this.props.intl;

    return (
      <Dialog
        title={intl.messages.vehiclePlanning_searchForMember}
        bodyStyle={{ overflow: 'inherit' }}
        contentStyle={{ width: '600px' }}
        onRequestClose={this.handleClose}
        open={openedMemberChangeModal}
      >
        <div className="vehiclePlanning_closeIconAbosuluteWrap">
          <IconButton onClick={this.handleClose}>
            <CloseIcon />
          </IconButton>
        </div>
        <AutoComplete
          hintText={formatMessage({ id: 'hint_search_by_name' })}
          dataSource={foundUsers}
          onUpdateInput={this.handleGetUsers}
          onNewRequest={this.handleSelectUser}
          filter={() => true}
          fullWidth
          autoFocus
          menuStyle={{ maxHeight: '200px', overflowY: 'auto' }}
        />
        {errorMemberSelection && <div className="fieldErrorMsg">{this.props.intl.formatMessage({ id: 'member_cannot_be_selected' })}</div>}

        {this.state.openConfirmDialog && (
          <Dialog
            actions={[
              <FlatButton key="no" label={<FormattedMessage id="common_cancel" />} onClick={this.handleToggleConfirmDialog} />,
              <FlatButton key="yes" label={<FormattedMessage id="common_confirm" />} primary onClick={this.handleConfirm} />
            ]}
            title={intl.messages.vehiclePlanning_changeMember}
            modal={false}
            contentStyle={{ width: '520px' }}
            open={this.state.openConfirmDialog}
            onRequestClose={this.handleToggleConfirmDialog}
          >
            <dl>
              <dt>{intl.messages.vehiclePlanning_changeMember_from}</dt>
              <dd>
                {this.state.confirmDialogData.prevUser.firstName +
                  ' ' +
                  this.state.confirmDialogData.prevUser.lastName +
                  ' <' +
                  this.state.confirmDialogData.prevUser.login +
                  '>'}
              </dd>
              <dt>{intl.messages.vehiclePlanning_changeMember_to}</dt>
              <dd>{this.state.confirmDialogData.user.shortName + ' <' + this.state.confirmDialogData.user.value + '>'}</dd>
            </dl>
          </Dialog>
        )}
      </Dialog>
    );
  }
}

VehiclePlanningMemberChange.displayName = 'VehiclePlanningBooking';

VehiclePlanningMemberChange.propTypes = {
  booking: T.object
};

export default connect(state => {
  const {
    vehiclePlanning: { openedMemberChangeModal, errorMemberSelection, data, bookingDetail, userDetailEditBooking, foundUsers }
  } = state;

  return {
    openedMemberChangeModal,
    errorMemberSelection,
    data,
    bookingDetail,
    userDetailEditBooking,
    foundUsers,
    bundle: bundleSelector(state)
  };
})(injectIntl(VehiclePlanningMemberChange));
