import React, { Component, PropTypes } from 'react';
import { FormattedMessage } from 'react-intl';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import moment from 'moment';

import { hourOptions, minuteOptions } from '../../constants/options-constants';
import BoxedInput from '../BoxedInput/BoxedInput';
import BoxedSelect from '../BoxedSelect/BoxedSelect';
import FieldErrorMsg from '../FieldErrorMsg/FieldErrorMsg';
import { createValidator, notEmptyWhen, futureMoment, endMomentBeforeStartMoment, notEmpty, length } from '../../validation/all-validation';
import { getAppFormattedDate, getAppFormattedTime, nextValidDate, nextValidHour, nextValidMinute, valuesToMoment } from '../../utils/utils';
import { MINUTES_BEFORE_CHANGE_STATUS } from '../../constants/business-constants';
import { statusListLabels } from '../../constants/options-constants';
import { bundleSelector } from '../../selectors/all-selectors';
import { IconButton } from 'material-ui';
import CalendarIcon from 'material-ui/svg-icons/action/date-range';
import TimeIcon from 'material-ui/svg-icons/device/access-time';
import DropDownMenu from 'material-ui/DropDownMenu';
import MenuItem from 'material-ui/MenuItem';
import FlatButton from 'material-ui/FlatButton';

import CloseIcon from 'material-ui/svg-icons/navigation/close';
import autoBind from 'react-autobind/lib/autoBind';
import { setDeviceRemovalReminder } from '../../actions/vehicles-actions';
import { VEHICLE_STATUSES } from '../../constants/backend-constants';

class EditStatusForm extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
  }

  handleChangeDropDown(ev, key, value) {
    this.props.fields.status.onChange(value);
  }

  componentDidMount() {
    this.displayDeviceRemovalReminder();
  }

  displayDeviceRemovalReminder() {
    const {
      dispatch,
      deviceRemovalReminder,
      bundle: { vehicle_device_removal_date },
      intl: { formatMessage },
      fields: { statusDate, status, hour, minute, comment }
    } = this.props;

    if (deviceRemovalReminder) {
      const date = new Date();
      const updatedMsg = formatMessage(
        { id: 'updated.on.date.at.time' },
        { date: getAppFormattedDate(date), time: getAppFormattedTime(date) }
      );

      hour.onChange('0');
      minute.onChange('0');
      status.onChange(VEHICLE_STATUSES.INACTIVE);
      comment.onChange(vehicle_device_removal_date + ` (${updatedMsg})`);
      statusDate.onChange(moment(deviceRemovalReminder).toDate());
      dispatch(setDeviceRemovalReminder());
    }
  }

  render() {
    const {
      fields: { statusDate, endStatusDate, status, hour, endHour, endMinute, minute, comment },
      handleSubmit,
      bundle: { editStatusForm_comment }
    } = this.props;

    const today = new Date();
    const submitHandler = handleSubmit(this.props.onCallback);

    return (
      <section className="editStatusFormWrapper">
        <div className="editStatusForm_container">
          <h3 className="editStatusForm_title">
            <FormattedMessage id="editStatusForm_title" />
          </h3>
          <div className="vehiclePlanning_closeIconAbosuluteWrap">
            <IconButton onClick={this.props.onAbort}>
              <CloseIcon />
            </IconButton>
          </div>
          <section className="vehiclePlanningCreateStatus">
            <form action="#" className="editStatusForm" onSubmit={submitHandler}>
              <div className="vehiclePlanningCreateStatus_container">
                <div className="VehiclePlanningEditBooking_col1Row2">
                  <CalendarIcon className="vehiclePlanningCreateStatus_calendarIcon" />
                  <BoxedInput
                    formRowItemKey="editStatusForm_date"
                    id="date"
                    skinType="date"
                    type="date"
                    minDate={today}
                    customClass={'boxedInputWrapper--label vehiclePlanning_date_block'}
                    labelKey="editStatusForm_date"
                    field={statusDate}
                  />

                  <div className="VehiclePlanningEditBooking_col1Row2_dateWrap">
                    <TimeIcon />
                    <BoxedSelect
                      formRowItemKey="editStatusForm_hour"
                      labelKey="editStatusForm_hour"
                      options={hourOptions}
                      customClass="searchVehicle_boxedSelectWrapper"
                      field={hour}
                      fullWidth
                      hideLabel
                    />
                    <span className="VehiclePlanningEditBooking_col1Row2_dvojtecka">:</span>
                    <BoxedSelect
                      formRowItemKey="editStatusForm_min"
                      labelKey="editStatusForm_min"
                      options={minuteOptions}
                      customClass="searchVehicle_boxedSelectWrapper"
                      field={minute}
                      hideLabel
                    />
                  </div>
                </div>

                <FieldErrorMsg field={statusDate} customClass="vps-error-field" />
                <FieldErrorMsg field={hour} customClass="vps-error-field" />
                <FieldErrorMsg field={minute} customClass="vps-error-field" />

                <div className="VehiclePlanningEditBooking_col1Row2">
                  <CalendarIcon className="vehiclePlanningCreateStatus_calendarIcon" />
                  <BoxedInput
                    formRowItemKey="editStatusForm_endDate"
                    id="endDate"
                    skinType="date"
                    minDate={today}
                    type="date"
                    customClass={'boxedInputWrapper--label vehiclePlanning_date_block'}
                    labelKey="editStatusForm_end_date"
                    field={endStatusDate}
                  />
                  <div className="VehiclePlanningEditBooking_col1Row2_dateWrap">
                    <TimeIcon />
                    <BoxedSelect
                      formRowItemKey="editStatusForm_hour"
                      labelKey="editStatusForm_hour"
                      options={hourOptions}
                      customClass="searchVehicle_boxedSelectWrapper"
                      field={endHour}
                      hideLabel
                    />
                    <span className="VehiclePlanningEditBooking_col1Row2_dvojtecka">:</span>
                    <BoxedSelect
                      formRowItemKey="editStatusForm_min"
                      labelKey="editStatusForm_min"
                      options={minuteOptions}
                      customClass="searchVehicle_boxedSelectWrapper"
                      field={endMinute}
                      hideLabel
                    />
                  </div>
                </div>

                <FieldErrorMsg field={endStatusDate} customClass="vps-error-field" />
                <FieldErrorMsg field={endHour} customClass="vps-error-field" />
                <FieldErrorMsg field={endMinute} customClass="vps-error-field" />
              </div>

              <DropDownMenu
                value={status.value}
                onChange={this.handleChangeDropDown}
                style={{ width: 'calc(100% + 15px)', marginLeft: '-15px' }}
                underlineStyle={{ borderTop: 'rgba(0, 0, 0, .7)' }}
              >
                {statusListLabels.map(item => (
                  <MenuItem value={item.value} primaryText={<FormattedMessage id={item.labelKey} />} key={item.value} />
                ))}
              </DropDownMenu>

              <BoxedInput
                formRowItemKey="editStatusForm_comment"
                id="status-comment"
                customClass="boxedInput_inputText"
                customInputClass="boxedInput_inputTextArea"
                type="textarea"
                placeholder={editStatusForm_comment}
                field={comment}
              >
                <FieldErrorMsg field={comment} customClass="fieldErrorMsg--editStatusForm_comment" />
              </BoxedInput>

              <div className="vehiclePlanningCreateStatus_buttonWrap">
                <FlatButton
                  key="no"
                  label={<FormattedMessage id="booking_create_confirmation_abort_button" />}
                  onClick={this.props.onAbort}
                  className="flatButton"
                />
                <FlatButton
                  key="yes"
                  label={<FormattedMessage id="backUserForm_save_button" />}
                  onClick={submitHandler}
                  className="flatButton"
                />
              </div>
            </form>
          </section>
        </div>
      </section>
    );
  }
}

EditStatusForm.displayName = 'EditStatusForm';

EditStatusForm.propTypes = {
  fields: PropTypes.object,
  handleSubmit: PropTypes.func,
  onCallback: PropTypes.func,
  onAbort: PropTypes.func
};

const getStartDate = valuesToMoment({
  dateName: 'statusDate'
});

const getEndDate = valuesToMoment({
  dateName: 'endStatusDate',
  hourName: 'endHour',
  minuteName: 'endMinute'
});

EditStatusForm = reduxForm({
  form: 'editStatusForm',
  destroyOnUnmount: false,
  fields: ['statusDate', 'endStatusDate', 'status', 'hour', 'endHour', 'endMinute', 'minute', 'comment'],
  initialValues: {
    statusDate: nextValidDate(),
    hour: nextValidHour(),
    minute: nextValidMinute(),
    status: 'REPARATION'
  },
  validate: createValidator({
    status: [notEmpty()],
    statusDate: [
      futureMoment({
        getMoment: getStartDate,
        withOffsetMinute: MINUTES_BEFORE_CHANGE_STATUS
      }),
      notEmpty()
    ],
    hour: [notEmpty()],
    minute: [notEmpty()],
    endStatusDate: [
      endMomentBeforeStartMoment({
        getStartDate,
        getEndDate
      }),
      futureMoment({
        getMoment: getEndDate,
        withOffsetMinute: MINUTES_BEFORE_CHANGE_STATUS
      }),
      notEmptyWhen('endHour'),
      notEmptyWhen('endMinute')
    ],
    endHour: [notEmptyWhen('endStatusDate'), notEmptyWhen('endMinute')],
    endMinute: [notEmptyWhen('endStatusDate'), notEmptyWhen('endHour')],
    comment: [length({ max: 255 })]
  })
})(EditStatusForm);

EditStatusForm = connect(state => {
  const { deviceRemovalReminder } = state.vehicles;
  return { bundle: bundleSelector(state), deviceRemovalReminder };
})(EditStatusForm);

export default injectIntl(EditStatusForm);
