import React, { Component } from 'react';
import { routeActions } from 'react-router-redux';
import { connect } from 'react-redux';
import { reset } from 'redux-form';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Grid, Row, Col } from 'react-bootstrap';
import _get from 'lodash/get';

import { addInvoiceItem, createInvoice, toggleDepositUsage } from '../../actions/all-actions';
import { addInvoiceItemsTotal } from '../../utils/utils';
import { createInvoiceTableViewParams } from '../../constants/table-constants';
import routes from '../../constants/routes-constants';

import BookingDetail from '../../containers/Invoices/components/BookingDetail/BookingDetail';
import TableView from '../TableView/TableView';
import CreateInvoiceForm from '../CreateInvoiceForm/CreateInvoiceForm';
import EkButton from '../../components/EkButton/EkButton';
import { apiParams } from '../../constants/api-params-constants';
import Checkbox from 'material-ui/Checkbox';
import { currentV2InvoicesUrlSelector } from '../../selectors/all-selectors';

class CreateInvoice extends Component {
  constructor() {
    super();

    this.handleAddInvoice = this.handleAddInvoice.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.toggleDepositUsage = this.toggleDepositUsage.bind(this);
    this.handleAddInvoiceDetail = this.handleAddInvoiceDetail.bind(this);
  }

  handleAddInvoiceDetail(formData) {
    const { dispatch } = this.props;
    dispatch(addInvoiceItem(formData));
    dispatch(reset('createInvoiceForm'));
  }

  handleAddInvoice() {
    const { dispatch, selectedBooking, listUrlV2 } = this.props;

    dispatch(createInvoice(selectedBooking.id)).then(() => {
      if (listUrlV2) {
        return dispatch(routeActions.push(listUrlV2));
      }
      dispatch(routeActions.push(routes.invoices.path.replace(':search', encodeURIComponent(JSON.stringify(apiParams.flat)))));
    });
  }

  toggleDepositUsage() {
    const { dispatch } = this.props;
    dispatch(toggleDepositUsage());
  }

  handleCancel() {
    const { dispatch, listUrlV2 } = this.props;

    if (listUrlV2) {
      return dispatch(routeActions.push(listUrlV2));
    }

    dispatch(routeActions.push(routes.invoices.path.replace(':search', encodeURIComponent(JSON.stringify(apiParams.flat)))));
  }

  render() {
    const { selectedBooking, invoiceItems, totalPrices, currency, useBookingDamageDeposit } = this.props;

    const invoiceItemsWithTotal = invoiceItems.length ? addInvoiceItemsTotal(invoiceItems, totalPrices) : [];

    return (
      <div className="createInvoice">
        <div className="createInvoice_wrapper">
          <Grid>
            <Row>
              <Col md="6">
                <h4 className="bookingDetail_title">
                  <FormattedMessage id="booking_detail_title" />
                </h4>
                <BookingDetail bookingDetail={selectedBooking} />
              </Col>
              <Col md="6">
                <h4 className="bookingDetail_title">
                  <FormattedMessage id="booking_detail_settle_invoice" />
                </h4>
                <CreateInvoiceForm onCallback={this.handleAddInvoiceDetail} currency={currency} />
              </Col>
            </Row>
          </Grid>
        </div>

        {invoiceItems.length > 0 && (
          <div className="createInvoice_list">
            <TableView
              customClass="createInvoice_list_tableViewWrapper"
              params={createInvoiceTableViewParams}
              content={invoiceItemsWithTotal}
            />
            <div style={{ background: 'white', padding: '10px' }}>
              <Checkbox
                label={<FormattedMessage id="invoice_booking_deposit_usage" />}
                id="useBookingDamageDeposit"
                checked={useBookingDamageDeposit}
                onCheck={this.toggleDepositUsage}
                customClass="addBookingConfirmationForm_option"
              />
            </div>
            <EkButton
              type="button"
              formRowItemKey="createInvoiceForm_submitBtn"
              customClass="createInvoiceForm_actionsButton"
              onAction={this.handleAddInvoice}
            >
              <FormattedMessage id="invoice_create_generate_button" />
            </EkButton>

            <EkButton
              type="button"
              skinType="reverse"
              formRowItemKey="createInvoiceForm_abortBtn"
              customClass="createInvoiceForm_actionsButton"
              onAction={this.handleCancel}
            >
              <FormattedMessage id="invoice_create_abort_button" />
            </EkButton>
          </div>
        )}
      </div>
    );
  }
}

CreateInvoice.displayName = 'CreateInvoice';

export default connect(state => {
  const bookingDetail = _get(state.bookings, 'bookingDetail');
  const {
    invoices: { selectedBooking, invoiceItems, totalPrices, useBookingDamageDeposit }
  } = state;

  return {
    selectedBooking,
    invoiceItems,
    totalPrices,
    useBookingDamageDeposit,
    currency: _get(bookingDetail, 'currency'),
    listUrlV2: currentV2InvoicesUrlSelector(state)
  };
})(injectIntl(CreateInvoice));
