import React, { PropTypes } from 'react';
import { FormattedMessage } from 'react-intl';
import _map from 'lodash/map';
import _partial from 'lodash/partial';
import BoxedInput from '../BoxedInput/BoxedInput';
import EkButton from '../EkButton/EkButton';
import FieldErrorMsg from '../FieldErrorMsg/FieldErrorMsg';
import { getAppDateFormat } from '../../utils/utils';

const ClosingDatesSlots = ({ slotTitle, isDisabled, limitedAccess, slotData, onHandleAddSlot, onHandleRemoveSlot }) => {
  return (
    <div className="parkingForm_container">
      <h4 className="parkingForm_title">
        <FormattedMessage id={slotTitle} />
      </h4>

      {!limitedAccess && !isDisabled && (
        <EkButton
          id="closing-dates-add"
          onAction={onHandleAddSlot}
          customClass="ekButton--add parkingForm_addTimeSlot_button"
          skinType="reverse"
        >
          <FormattedMessage id="parkingForm_addSpecialClosingDates" />
        </EkButton>
      )}

      <ul className="parkingForm_timeSlots">
        {_map(slotData, (item, index) => {
          return (
            <li className="parkingForm_timeSlots_item" key={index}>
              <div className="parkingForm_timeSlots_item_content">
                <div className="parkingForm_timeSlots_group">
                  <BoxedInput
                    id={`${slotTitle}-dateApply-${index}`}
                    type="date"
                    skinType="date"
                    blockCustomClass="timeSlot_boxedSelectBlock timeSlot_boxedSelectBlock--from"
                    customClass="parkingTimeSlot parkingTimeSlot_dateWrapper"
                    labelKey="timeSlot_apply_label"
                    customLabelClass="parkingTimeSlot parkingTimeSlot_from_label"
                    field={item.startDate}
                    dateFormat={'dddd ' + getAppDateFormat()}
                    readOnly={(limitedAccess || isDisabled) === 'true'}
                  >
                    <FieldErrorMsg field={item.startDate} customClass="fieldErrorMsg--parkingForm" />
                  </BoxedInput>

                  <BoxedInput
                    id={`${slotTitle}-dateTo-${index}`}
                    type="date"
                    skinType="date"
                    blockCustomClass="timeSlot_boxedSelectBlock timeSlot_boxedSelectBlock--from"
                    customClass="parkingTimeSlot parkingTimeSlot_dateWrapper"
                    labelKey="timeSlot_to_label"
                    customLabelClass="parkingTimeSlot parkingTimeSlot_from_label"
                    field={item.endDate}
                    dateFormat={'dddd ' + getAppDateFormat()}
                    readOnly={(limitedAccess || isDisabled) === 'true'}
                  >
                    <FieldErrorMsg field={item.endDate} customClass="fieldErrorMsg--parkingForm" />
                  </BoxedInput>

                  <BoxedInput
                    type="text"
                    id={`${slotTitle}-comment-${index}}`}
                    labelKey="timeSlot_comment_label"
                    blockCustomClass="timeSlot_boxedSelectBlock timeSlot_boxedSelectBlock--from"
                    customClass="parkingTimeSlot parkingTimeSlot_CommentWrapper"
                    customLabelClass="parkingTimeSlot parkingTimeSlot_from_label"
                    field={item.comment}
                    readOnly={(limitedAccess || isDisabled) === 'true'}
                  >
                    <FieldErrorMsg field={item.comment} customClass="fieldErrorMsg--parkingForm" />
                  </BoxedInput>
                </div>
              </div>

              {!limitedAccess && !isDisabled && (
                <div className="parkingForm_timeSlots_item_actionButton">
                  <button
                    type="button"
                    id={slotTitle + '-remove-' + index}
                    className="parkingForm_timeSlots_item_removeButton"
                    onClick={_partial(onHandleRemoveSlot, index)}
                  />
                </div>
              )}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

ClosingDatesSlots.displayName = 'ClosingDatesSlots';

ClosingDatesSlots.propTypes = {
  slotTitle: PropTypes.string.isRequired,
  limitedAccess: PropTypes.bool,
  isDisabled: PropTypes.bool,
  slotData: PropTypes.array.isRequired,
  onHandleAddSlot: PropTypes.func,
  onHandleRemoveSlot: PropTypes.func
};

ClosingDatesSlots.defaultProps = {
  isDisabled: false
};

export default ClosingDatesSlots;
