import React, { PropTypes } from 'react';
import { FormattedMessage } from 'react-intl';
import _get from 'lodash/get';
import moment from 'moment/moment';
import { getAppFormattedDateTime, getAppFormattedDate, getErrorMsg, safe } from '../../utils/utils';
import { STATUS_NOT_APPROVED } from '../../constants/backend-constants';
import { bundleSelector } from '../../selectors/all-selectors';
import { getStore } from '../../store/all-store';

function getDrivingLicenceRejectionReason(detailMember) {
  const errorCode = safe(() => detailMember.drivingLicence.rejectionReason);
  const params = {
    error: { errorCode },
    bundle: bundleSelector(getStore().getState()),
    def: 'profile.rejected.driving.licence.invalid'
  };
  return getErrorMsg(params);
}

const MemberProfileInfo = props => {
  const { detailMember, showStatus, showApprovalDate } = props;
  const anonymeDate = effective => {
    if (effective) return getAppFormattedDate(moment(effective));
    else return getAppFormattedDate(moment(detailMember.anonymizationEffectiveDate).add(6, 'y'));
  };

  if (
    _get(detailMember, 'profileSubscriptionDate') ||
    _get(detailMember, 'activationDate') ||
    _get(detailMember, 'lastProfileUpdateDate') ||
    _get(detailMember, 'lastReviewDate') ||
    _get(detailMember, 'lastSuccessfulLoginDate') ||
    _get(detailMember, 'lastLoginAttemptDate') ||
    _get(detailMember, 'lastLoginAttemptDate') ||
    _get(detailMember, 'lastSuspensionDate') ||
    _get(detailMember, 'lastSuspensionReason') ||
    !_get(detailMember, 'validated') || //profilInfo
    !_get(detailMember, 'dricingLicence.validated') ||
    !_get(detailMember, 'employerCertificate.validated') ||
    !_get(detailMember, 'identityDocument.validated') ||
    _get(detailMember, 'anonymizationEffectiveDate')
  )
    return (
      <div className="memberView_ProfileInfo">
        <h4>
          <FormattedMessage id="members_detail_profile_subtitle" />
          {showStatus && _get(detailMember, 'status') && (
            <span>
              <span className="detailView_dash">-</span>
              <span className="detailView_component_headline_status">
                <span className={`member_status--${detailMember.status.toLowerCase()}`}>
                  <FormattedMessage id={`members_status_${detailMember.status.toLowerCase()}`} />
                </span>
              </span>
            </span>
          )}
        </h4>
        <div className="memberView_ProfileInfo_body">
          <div className="detailView_table">
            {_get(detailMember, 'profileSubscriptionDate') && (
              <div className="detailView_row">
                <span className="detailView_cell_type_3">
                  <FormattedMessage id="members_detail_subscriptionDate" />
                </span>
                <span className="detailView_cell_type_3">{getAppFormattedDateTime(detailMember.profileSubscriptionDate)}</span>
              </div>
            )}
            {_get(detailMember, 'activationDate') && (
              <div className="detailView_row">
                <span className="detailView_cell_type_3">
                  <FormattedMessage id="members_detail_activationDate" />
                </span>
                <span className="detailView_cell_type_3">{getAppFormattedDateTime(detailMember.activationDate)}</span>
              </div>
            )}
            {showApprovalDate && _get(detailMember, 'lastApprovedDate') && (
              <div className="detailView_row">
                <span className="detailView_cell_type_3">
                  <FormattedMessage id="members_detail_lastApprovedDate" />
                </span>
                <span className="detailView_cell_type_3">{getAppFormattedDateTime(detailMember.lastApprovedDate)}</span>
              </div>
            )}
            {_get(detailMember, 'lastProfileUpdateDate') && (
              <div className="detailView_row">
                <span className="detailView_cell_type_3">
                  <FormattedMessage id="members_detail_lastProfileUpdateDate" />
                </span>
                <span className="detailView_cell_type_3">{getAppFormattedDateTime(detailMember.lastProfileUpdateDate)}</span>
              </div>
            )}
            {_get(detailMember, 'lastReviewDate') && (
              <div className="detailView_row">
                <span className="detailView_cell_type_3">
                  <FormattedMessage id="members_detail_lastReviewDate" />
                </span>
                <span className="detailView_cell_type_3">{getAppFormattedDateTime(detailMember.lastReviewDate)}</span>
              </div>
            )}
            {_get(detailMember, 'lastSuccessfulLoginDate') && (
              <div className="detailView_row">
                <span className="detailView_cell_type_3">
                  <FormattedMessage id="members_detail_lastSuccessfulLoginDate" />
                </span>
                <span className="detailView_cell_type_3">{getAppFormattedDateTime(detailMember.lastSuccessfulLoginDate)}</span>
              </div>
            )}
            {_get(detailMember, 'lastLoginAttemptDate') && (
              <div className="detailView_row">
                <span className="detailView_cell_type_3">
                  <FormattedMessage id="members_detail_lastLoginAttempt" />
                </span>
                <span className="detailView_cell_type_3">{getAppFormattedDateTime(detailMember.lastLoginAttemptDate)}</span>
              </div>
            )}
            {_get(detailMember, 'profileReviewStatus') === STATUS_NOT_APPROVED && (
              <div className="detailView_row">
                <span className="detailView_cell_type_3">
                  <FormattedMessage id="members_detail_basic_profil_info_rejected" />
                </span>
                <span className="detailView_cell_type_3">{detailMember.profileReviewRejectionReason}</span>
              </div>
            )}
            {_get(detailMember, 'drivingLicence.status') === STATUS_NOT_APPROVED && (
              <div className="detailView_row">
                <span className="detailView_cell_type_3">
                  <FormattedMessage id="members_detail_driving_license_rejected" />
                </span>
                <span className="detailView_cell_type_3">{getDrivingLicenceRejectionReason(detailMember)}</span>
              </div>
            )}
            {_get(detailMember, 'identityDocument.status') === STATUS_NOT_APPROVED && (
              <div className="detailView_row">
                <span className="detailView_cell_type_3">
                  <FormattedMessage id="members_detail_identity_document_rejected" />
                </span>
                <span className="detailView_cell_type_3">{_get(detailMember, 'identityDocument.rejectionReason')}</span>
              </div>
            )}
            {_get(detailMember, 'employerCertificate.status') === STATUS_NOT_APPROVED && (
              <div className="detailView_row">
                <span className="detailView_cell_type_3">
                  <FormattedMessage id="members_detail_employer_certificate_rejected" />
                </span>
                <span className="detailView_cell_type_3">{_get(detailMember, 'employerCertificate.rejectionReason')}</span>
              </div>
            )}
            {_get(detailMember, 'lastSuspensionDate') && (
              <div className="detailView_row">
                <span className="detailView_cell_type_3">
                  <FormattedMessage id="members_detail_lastSuspensionDate" />
                </span>
                <span className="detailView_cell_type_3">{getAppFormattedDateTime(detailMember.lastSuspensionDate)}</span>
              </div>
            )}
            {_get(detailMember, 'lastSuspensionReason') && (
              <div className="detailView_row">
                <span className="detailView_cell_type_3">
                  <FormattedMessage id="members_detail_lastSuspensionReason" />
                </span>
                <span className="detailView_cell_type_3">{detailMember.lastSuspensionReason}</span>
              </div>
            )}
            {_get(detailMember, 'anonymizationEffectiveDate') && (
              <div className="detailView_row red">
                <span className="detailView_cell_type_3">
                  <FormattedMessage id="members_detail_isAnonymiwed" />
                </span>
                <span className="detailView_cell_type_3">{anonymeDate(_get(detailMember, 'anonymizationEffectiveDate'))}</span>
              </div>
            )}
          </div>
        </div>
      </div>
    );
};

MemberProfileInfo.defaultProps = {
  showStatus: true,
  showApprovalDate: false
};

MemberProfileInfo.displayName = 'MemberProfileInfo';

MemberProfileInfo.propTypes = {
  detailMember: PropTypes.object.isRequired,
  showStatus: PropTypes.bool,
  showApprovalDate: PropTypes.bool
};

export default MemberProfileInfo;
