import _findIndex from 'lodash/findIndex';

import * as types from '../constants/actionTypes-constants';
import callApi from './api-actions';
import { addFlashMessage, setCurrentCompany } from '../actions/all-actions';
import _get from 'lodash/get';
import _includes from 'lodash/includes';
import _forEach from 'lodash/forEach';
import _isString from 'lodash/isString';
import _matches from 'lodash/matches';

// noinspection ES6CheckImport
import { saveAs } from 'file-saver';

import moment from 'moment';

import { FLASH_MESSAGE_TYPE_ERROR, FLASH_MESSAGE_TYPE_SUCCESS } from '../constants/generic-constants';
import { isInternetExplorer11, trimValues, trySet, append, setIfString, isValidId, addErrorMessage, renameKey, safe } from '../utils/utils';
import { getItalianInvoiceCredentials, getItalianInvoiceParams, setFormattedAddress } from '../utils/dataFormatUtils';
import { invoicePreviewDefault } from '../constants/generic-constants';
import { errorCodes } from '../constants/options-constants';
import { getInvoiceParamsBase } from '../utils/dataFormatUtils';
import {
  currentSubCompanySelector,
  validCompanyIdSelector,
  userRoleSelector,
  userSubCompaniesSelector,
  isSubCompanyLevelUserSelector
} from '../selectors/all-selectors';
import { getPaybackSettingsParse } from '../api/data-enhancer';
import _isEmpty from 'lodash/isEmpty';
import {
  sharedCompanyCreateBankoutConfigAction,
  sharedCompanyUpdateBankDetailsAction,
  sharedCompanyUpdateBankoutConfigAction
} from './sharedCompany-actions';
import { BILLING_ENTITY_TYPE_COMPANY, TEMPLATE_MODEL_ITALIAN } from '../constants/backend-constants';
import { checkRole, subCompanyPaybackBankDetailsEditRules } from '../constants/backuser-role-rules';
import { COMPANY_EMAIL_SETUP_FORM, COMPANY_STRIPE_SETTINGS_FORM } from '../constants/form-constants';

// ensure to return only subcompanies that assigned to current user
// user info contains list of company IDs
// filtering is done on a list of company objects with extended data
// this function may be changed when adding new back office roles

function filterListOfBackUserSubCompanies(state, data) {
  const userSubCompanies = userSubCompaniesSelector(state);

  if (isSubCompanyLevelUserSelector(state)) {
    const getMatchesPredicate = item => _matches({ id: item.id });
    const companyIdBelongsToUser = item => _findIndex(userSubCompanies, getMatchesPredicate(item)) !== -1;

    return data.filter(companyIdBelongsToUser);
  }

  return data;
}

export function getSubCompaniesList(companyId, loader = true) {
  return (dispatch, getState) => {
    const state = getState();
    const superCompanyId = companyId || validCompanyIdSelector(state);

    dispatch({ type: types.SUB_COMPANIES_GET_LIST_REQUEST });

    return dispatch(callApi('subCompanies', 'getList', superCompanyId, loader))
      .then(data => {
        const filteredData = filterListOfBackUserSubCompanies(state, data);

        dispatch(getSubCompaniesListSuccess(filteredData));
        return Promise.resolve(filteredData);
      })
      .catch(error => {
        dispatch(subCompaniesListClear());
        dispatch(addErrorMessage({ error }));
        return Promise.resolve([]);
      });
  };
}

export function getSubCompaniesListSuccess(companies) {
  return {
    type: types.SUB_COMPANIES_GET_LIST_SUCCESS,
    list: companies
  };
}

export function subCompaniesListClear() {
  return {
    type: types.SUB_COMPANIES_GET_LIST_CLEAR
  };
}

export function getHeaderSubCompaniesList(companyId) {
  return (dispatch, getState) => {
    const state = getState();
    const superCompanyId = companyId || validCompanyIdSelector(state);

    dispatch({ type: types.SUB_COMPANIES_HEADER_GET_LIST_REQUEST });

    return dispatch(callApi('subCompanies', 'getList', superCompanyId))
      .then(data => {
        const filteredData = filterListOfBackUserSubCompanies(state, data);

        dispatch(getHeaderSubCompaniesListSuccess(filteredData));
        return filteredData;
      })
      .catch(error => {
        dispatch(addErrorMessage({ error }));
        return Promise.resolve([]);
      });
  };
}

export function getHeaderSubCompaniesListSuccess(companies) {
  return {
    type: types.SUB_COMPANIES_HEADER_GET_LIST_SUCCESS,
    list: companies
  };
}

export function getAllSubCompaniesList(companyIds) {
  return (dispatch, getState) => {
    const superCompanyIds = companyIds || [getState().companies.currentCompany.id];

    dispatch({ type: types.SUB_COMPANIES_GET_LIST_REQUEST });

    return dispatch(callApi('subCompanies', 'getAllList', superCompanyIds))
      .then(data => {
        dispatch(getSubCompaniesListSuccess(data));
        return Promise.resolve(data);
      })
      .catch(error => {
        dispatch(subCompaniesListClear());
        dispatch(addErrorMessage({ error }));
        return Promise.resolve([]);
      });
  };
}

export function getSubCompanyDetails(subCompanyId) {
  return dispatch => {
    dispatch({
      type: types.SUB_COMPANIES_DETAIL_SUB_COMPANY_REQUEST
    });

    return dispatch(callApi('subCompanies', 'getSubCompanyDetails', subCompanyId)).then(function(data) {
      dispatch({
        type: types.SUB_COMPANIES_DETAIL_SUB_COMPANY_SUCCESS,
        subCompany: data
      });
      return data;
    });
  };
}

export function getSubCompanyPaymentDetails(subCompanyId) {
  return dispatch => {
    dispatch({ type: types.SUB_COMPANIES_PAYMENT_DETAILS_REQUEST });

    return dispatch(callApi('bankouts', 'getCompanyPaymentDetails', subCompanyId)).then(function(data) {
      if (_isEmpty(data)) return null;

      dispatch({
        type: types.SUB_COMPANIES_PAYMENT_DETAILS_SUCCESS,
        subCompanyPaymentDetails: data
      });

      return data;
    });
  };
}

export function createSubCompanyRequest(companyId) {
  return (dispatch, getState) => {
    const state = getState();

    const {
      name,
      address,
      postalCode,
      city,
      country,
      email,
      secondaryEmail,
      phoneNumber,
      termsOfUse,
      secondaryPhoneNumber,
      configurationId,
      agencyCode,
      logoUrl,
      externalId
    } = trimValues(state.form.subCompanyForm);

    const { phonePrefix, phoneSuffix } = phoneNumber || {};

    let params = {
      name,
      address,
      email,
      phoneNumber: {
        countryCode: phonePrefix,
        nationalNumber: phoneSuffix
      },
      termsOfUse
    };

    const { phonePrefix: secondaryPhonePrefix, phoneSuffix: secondaryPhoneSuffix } = secondaryPhoneNumber || {};
    if (secondaryPhonePrefix && secondaryPhoneSuffix) {
      params.secondaryPhoneNumber = {
        countryCode: secondaryPhonePrefix,
        nationalNumber: secondaryPhoneSuffix
      };
    }

    if (secondaryEmail) {
      params.secondaryEmail = secondaryEmail;
    }

    if (externalId) params.externalId = externalId;

    if (configurationId) {
      params.configurationId = configurationId;
    }

    if (agencyCode) {
      params.agencyCode = agencyCode;
    }

    if (logoUrl) {
      params.logoUrl = logoUrl;
    }

    if (_isString(address)) {
      params.address = {
        streetName: address
      };
    }

    if (postalCode) {
      trySet(params.address, 'postalCode', postalCode);
    }

    if (city) {
      trySet(params.address, 'city', city);
    }

    if (country) {
      trySet(params.address, 'country', country);
    }

    trySet(params, 'address', append(setFormattedAddress(params.address)));

    dispatch({
      type: types.SUB_COMPANIES_CREATE_SUB_COMPANY_REQUEST,
      params
    });

    return dispatch(callApi('subCompanies', 'createSubCompany', { companyId, params }));
  };
}

export function createSubCompanyModalSuccess(subCompany) {
  return {
    type: types.SUB_COMPANIES_CREATE_COMPANY_MODAL_SUCCESS,
    subCompany
  };
}

export function updateSubCompanyRequest(subCompanyId) {
  return (dispatch, getState) => {
    const state = getState();

    const {
      name,
      address,
      postalCode,
      city,
      country,
      email,
      secondaryEmail,
      phoneNumber,
      secondaryPhoneNumber,
      configurationId,
      agencyCode,
      logoUrl,
      externalId
    } = trimValues(state.form.subCompanyForm);

    const { phonePrefix, phoneSuffix } = phoneNumber || {};
    const { phonePrefix: secondaryPhonePrefix, phoneSuffix: secondaryPhoneSuffix } = secondaryPhoneNumber || {};

    let params = {
      name,
      address,
      email,
      phoneNumber: {
        countryCode: phonePrefix,
        nationalNumber: phoneSuffix
      }
    };

    if (externalId) params.externalId = externalId;

    if (secondaryPhonePrefix && secondaryPhoneSuffix) {
      params.secondaryPhoneNumber = {
        countryCode: secondaryPhonePrefix,
        nationalNumber: secondaryPhoneSuffix
      };
    }

    if (secondaryEmail) {
      params.secondaryEmail = secondaryEmail;
    }

    if (configurationId) {
      params.configurationId = configurationId;
    }

    if (agencyCode) {
      params.agencyCode = agencyCode;
    }

    if (logoUrl) {
      params.logoUrl = logoUrl;
    }

    setIfString(params.address, 'streetName', params.address);

    if (postalCode) {
      trySet(params.address, 'postalCode', postalCode);
    }

    if (city) {
      trySet(params.address, 'city', city);
    }

    if (country) {
      trySet(params.address, 'country', country);
    }

    trySet(params, 'address', append(setFormattedAddress(params.address)));

    dispatch({
      type: types.SUB_COMPANIES_UPDATE_SUB_COMPANY_REQUEST,
      params
    });
    return dispatch(callApi('subCompanies', 'updateSubCompany', { subCompanyId, params }));
  };
}

export function updateSubCompanySuccess(subCompany) {
  return {
    type: types.SUB_COMPANIES_UPDATE_SUB_COMPANY_SUCCESS,
    subCompany
  };
}

export function setSubCompanyIdOptions(options) {
  return {
    type: types.SUB_COMPANIES_SET_SUB_COMPANY_OPTIONS,
    options
  };
}

export function setSubCompanySelected(subCompany) {
  return {
    type: types.SUB_COMPANIES_SET_CURRENT_SUB_COMPANY,
    subCompany
  };
}

export function getSubCompanyInvoiceVatRates(subCompanyId) {
  return dispatch => {
    dispatch({
      type: types.SUB_COMPANIES_GET_INVOICE_VAT_RATE_REQUEST
    });

    return dispatch(callApi('invoices', 'getVatRateParam', subCompanyId))
      .then(data =>
        dispatch({
          type: types.SUB_COMPANIES_GET_INVOICE_VAT_RATE_SUCCESS,
          data
        })
      )
      .catch(error =>
        dispatch({
          type: types.SUB_COMPANIES_GET_INVOICE_VAT_RATE_ERROR,
          error
        })
      );
  };
}

export function getSubCompanyInvoiceParams(subCompanyId) {
  return dispatch => {
    dispatch({
      type: types.SUB_COMPANIES_GET_INVOICE_PARAMS_REQUEST
    });

    return dispatch(callApi('invoices', 'getParams', subCompanyId))
      .then(data =>
        dispatch({
          type: types.SUB_COMPANIES_GET_INVOICE_PARAMS_SUCCESS,
          data
        })
      )
      .catch(error =>
        dispatch({
          type: types.SUB_COMPANIES_GET_INVOICE_PARAMS_ERROR,
          error
        })
      );
  };
}

export function getSubCompanyConnectedAccount(companyGlideId) {
  return dispatch => {
    dispatch({
      type: types.SUB_COMPANY_GET_CONNECTED_ACCOUNT_REQUEST
    });

    return dispatch(callApi('bankouts', 'getStripeAccount', companyGlideId, false))
      .then(data => {
        return dispatch({
          type: types.SUB_COMPANY_GET_CONNECTED_ACCOUNT_SUCCESS,
          data
        });
      })
      .catch(error => {
        return dispatch({
          type: types.SUB_COMPANY_GET_CONNECTED_ACCOUNT_ERROR,
          error
        });
      });
  };
}

export function createSubConnectedAccount() {
  return (dispatch, getState) => {
    const state = getState();
    const detailCompany = state.subCompanies.currentSubCompany;
    const companyGlideId = detailCompany.id;
    const { [COMPANY_STRIPE_SETTINGS_FORM]: stripeForm } = state.form;
    const formValues = trimValues(stripeForm);

    const params = {
      companyGlideId,
      countryCode: formValues.bankCountry,
      email: formValues.email,
      refreshUrl: window.location.href,
      returnUrl: window.location.href
    };

    dispatch({
      type: types.SUB_COMPANY_SET_CONNECTED_ACCOUNT_REQUEST,
      params
    });

    return dispatch(callApi('bankouts', 'createStripeAccountLink', params, false))
      .then(data => {
        dispatch({
          type: types.SUB_COMPANY_SET_CONNECTED_ACCOUNT_SUCCESS,
          data
        });

        return (window.location.href = data.stripeAccount.registrationLink);
      })
      .catch(error =>
        dispatch({
          type: types.SUB_COMPANY_SET_CONNECTED_ACCOUNT_ERROR,
          error
        })
      );
  };
}

export function stripeSubAccountLinkRefresh() {
  return (dispatch, getState) => {
    const state = getState();
    const detailCompany = state.subCompanies.currentSubCompany;

    const params = {
      companyGlideId: detailCompany.id,
      refreshUrl: window.location.href,
      returnUrl: window.location.href,
      type: 'ACCOUNT_ONBOARDING'
    };

    return dispatch(callApi('bankouts', 'stripeAccountLinkRefresh', params, false))
      .then(data => {
        return (window.location.href = data.url);
      })
      .catch(error => {
        dispatch(addErrorMessage({ error }));
      });
  };
}

export function supressSubConnectedAccount(companyGlideId) {
  return dispatch => {
    dispatch({
      type: types.SUB_COMPANY_REMOVE_CONNECTED_ACCOUNT_REQUEST
    });
    return dispatch(callApi('bankouts', 'supressAccount', companyGlideId, false))
      .then(data => {
        return dispatch({
          type: types.SUB_COMPANY_REMOVE_CONNECTED_ACCOUNT_SUCCESS,
          data
        });
      })
      .catch(error => {
        return dispatch({
          type: types.SUB_COMPANY_REMOVE_CONNECTED_ACCOUNT_ERROR,
          error
        });
      });
  };
}

export function setSubCompaniesTabIndex(index) {
  return {
    type: types.SUB_COMPANIES_SET_TAB_INDEX,
    index
  };
}

export function updateSubCompanyInvoiceData() {
  return (dispatch, getState) => {
    const state = getState();
    const currentEditCompanyId = _get(state.subCompanies.currentSubCompany, 'id');
    const invoiceParamsCompanyId = _get(state.subCompanies.detailInvoiceParams, 'companyId');
    const vatRateCompanyId = _get(state.subCompanies.invoiceVatRateParam, 'companyId');
    const updateInvoiceParams = currentEditCompanyId === invoiceParamsCompanyId;
    const updateVatRate = currentEditCompanyId === vatRateCompanyId;
    const actions = [];

    if (updateInvoiceParams) actions.push(dispatch(updateSubCompanyInvoiceParams()));
    else actions.push(dispatch(createSubCompanyInvoiceParams()));
    if (updateVatRate) actions.push(dispatch(updateInvoiceVatRates()));
    else actions.push(dispatch(createInvoiceVatRates()));

    return Promise.all(actions).then(
      () =>
        dispatch(
          addFlashMessage({
            contentKey: 'COMPANIES_SET_CURRENT_INVOICE_PARAMS_SUCCESS',
            type: FLASH_MESSAGE_TYPE_SUCCESS
          })
        ),
      error => {
        const promise = validateInvoiceErrors(dispatch, error);
        if (promise) return promise;

        return dispatch(
          addFlashMessage({
            contentKey: 'COMPANIES_SET_CURRENT_INVOICE_PARAMS_FAILED',
            type: FLASH_MESSAGE_TYPE_ERROR
          })
        );
      }
    );
  };
}

function formSubCompanyInvoiceParams(getState) {
  const state = getState();
  const currentEditCompanyId = _get(state.subCompanies.currentSubCompany, 'id');
  const form = _get(state.form, 'companyInvoiceParamsForm');
  const formValues = trimValues(form);
  const template = _get(form, 'templateModel.value');

  let params = getInvoiceParamsBase(formValues);

  if (template === TEMPLATE_MODEL_ITALIAN) {
    let italianParams = getItalianInvoiceParams(trimValues(form));
    renameKey(italianParams, 'numberPrefix', 'invoiceNumberPrefix');
    renameKey(italianParams, 'taxIdentificationCode', 'taxIdCode');
    renameKey(italianParams, 'numberCounterInit', 'firstInvoiceNumber');
    params.italianInvoicingParameters = italianParams;
    let italianCredentials = getItalianInvoiceCredentials(formValues);
    renameKey(italianCredentials, 'login', 'invoiceParamsLogin');
    renameKey(italianCredentials, 'password', 'invoiceParamsPassword');
    if (!!italianCredentials.login && !!italianCredentials.password) params.italianInvoicingParameters.credentials = italianCredentials;
  }

  return {
    ...params,
    companyId: currentEditCompanyId
  };
}

export function validateInvoiceErrors(dispatch, error) {
  const errors = _get(error, 'validationErrors');
  const errorCode = _get(error, 'errorCode');
  let addressError = false;

  if (errorCode) {
    const code = errorCodes[errorCode] || errorCode;
    return dispatch(
      addFlashMessage({
        contentKey: code,
        type: FLASH_MESSAGE_TYPE_ERROR
      })
    );
  }

  _forEach(errors, err => {
    addressError = _includes(err, 'invoicingAddress');
    if (addressError) return false; // exit iter
  });

  if (addressError) {
    return dispatch(
      addFlashMessage({
        contentKey: 'validation_address_invalid',
        type: FLASH_MESSAGE_TYPE_ERROR
      })
    );
  }
}

// fix BE missing response
function setMissingItalianInvoicingParameters(state, params) {
  return {
    ...params.params.italianInvoicingParameters,
    credentialsProvided: safe(() => state.subCompanies.detailInvoiceParams.italianInvoicingParameters.credentialsProvided)
  };
}

function updateSubCompanyInvoiceParams() {
  return (dispatch, getState) => {
    const state = getState();
    const currentEditCompanyId = _get(state.subCompanies.currentSubCompany, 'id');

    let params = {
      companyId: currentEditCompanyId,
      params: formSubCompanyInvoiceParams(getState)
    };

    if (_isString(params.params.invoicingAddress)) {
      params.params.invoicingAddress = {
        streetName: params.params.invoicingAddress
      };
    }

    trySet(params.params.invoicingAddress, 'postalCode', params.params.postalCode);
    trySet(params.params.invoicingAddress, 'city', params.params.city);
    trySet(params.params.invoicingAddress, 'country', params.params.country);
    trySet(params.params, 'invoicingAddress', setFormattedAddress(params.params.invoicingAddress));

    dispatch({
      type: types.SUB_COMPANIES_UPDATE_INVOICE_PARAMS_REQUEST
    });

    return dispatch(callApi('invoices', 'updateParams', params))
      .then(data =>
        dispatch({
          type: types.SUB_COMPANIES_UPDATE_INVOICE_PARAMS_SUCCESS,
          italianInvoicingParameters: setMissingItalianInvoicingParameters(state, params),
          data
        })
      )
      .catch(error => {
        dispatch({
          type: types.SUB_COMPANIES_UPDATE_INVOICE_PARAMS_ERROR,
          error
        });
        return Promise.reject(error);
      });
  };
}

function createSubCompanyInvoiceParams() {
  return (dispatch, getState) => {
    const params = {
      params: formSubCompanyInvoiceParams(getState)
    };

    dispatch({
      type: types.SUB_COMPANIES_CREATE_INVOICE_PARAMS_REQUEST
    });

    return dispatch(callApi('invoices', 'createParams', params))
      .then(data =>
        dispatch({
          type: types.SUB_COMPANIES_CREATE_INVOICE_PARAMS_SUCCESS,
          data
        })
      )
      .catch(error => {
        dispatch({
          type: types.SUB_COMPANIES_CREATE_INVOICE_PARAMS_ERROR,
          error
        });
        return Promise.reject(error);
      });
  };
}

function formInvoiceVatRatesParams(getState) {
  const state = getState();
  const form = _get(state.form, 'companyInvoiceParamsForm');
  const vatRate = _get(form, 'vatRate.value');
  const currentEditCompanyId = _get(state.subCompanies.currentSubCompany, 'id');

  return { vatRate, companyId: currentEditCompanyId };
}

function updateInvoiceVatRates() {
  return (dispatch, getState) => {
    const state = getState();
    const currentEditCompanyId = _get(state.subCompanies.currentSubCompany, 'id');

    const params = {
      companyId: currentEditCompanyId,
      param: formInvoiceVatRatesParams(getState)
    };

    dispatch({
      type: types.SUB_COMPANIES_UPDATE_INVOICE_VAT_RATE_REQUEST
    });

    return dispatch(callApi('invoices', 'updateVatRateParam', params))
      .then(data =>
        dispatch({
          type: types.SUB_COMPANIES_UPDATE_INVOICE_VAT_RATE_SUCCESS,
          data
        })
      )
      .catch(error => {
        dispatch({
          type: types.SUB_COMPANIES_UPDATE_INVOICE_VAT_RATE_ERROR,
          error
        });
        return Promise.reject(error);
      });
  };
}

function createInvoiceVatRates() {
  return (dispatch, getState) => {
    const params = {
      param: formInvoiceVatRatesParams(getState)
    };

    dispatch({
      type: types.SUB_COMPANIES_CREATE_INVOICE_VAT_RATE_REQUEST
    });

    return dispatch(callApi('invoices', 'createVatRateParam', params))
      .then(data =>
        dispatch({
          type: types.SUB_COMPANIES_CREATE_INVOICE_VAT_RATE_SUCCESS,
          data
        })
      )
      .catch(error => {
        dispatch({
          type: types.SUB_COMPANIES_CREATE_INVOICE_VAT_RATE_ERROR,
          error
        });
        return Promise.reject(error);
      });
  };
}

export function storeLegacySubCompanyBankDetails(params, numberOfRoutes = 2) {
  return dispatch => {
    dispatch({
      type: types.SUB_COMPANIES_DETAIL_STORE_PAYMENT_REQUEST
    });

    return dispatch(callApi('bankouts', 'storeBankDetails', params))
      .then(() => {
        dispatch({
          type: types.SUB_COMPANIES_DETAIL_STORE_PAYMENT_SUCCESS
        });

        dispatch(
          addFlashMessage({
            contentKey: 'subCompanies_paymentDetails_success',
            type: FLASH_MESSAGE_TYPE_SUCCESS,
            delayToNextRoute: true,
            numberOfRoutes
          })
        );

        return Promise.resolve();
      })
      .catch(error => {
        if (!error.type) {
          console.error(error.stack); // eslint-disable-line
        }

        dispatch(
          addFlashMessage({
            contentKey: 'subCompanies_paymentDetails_error',
            type: FLASH_MESSAGE_TYPE_ERROR,
            delayToNextRoute: true,
            numberOfRoutes
          })
        );

        return Promise.resolve();
      });
  };
}

export function previewSubCompanyInvoice() {
  return (dispatch, getState) => {
    const state = getState();
    const subCompanyDetail = state.subCompanies.currentSubCompany;
    const subCompanyId = _get(subCompanyDetail, 'id');
    const companyId = _get(subCompanyDetail, 'parentCompanyId');
    const language = _get(state, 'i18n.locale');

    const params = {
      companyId,
      subCompanyId,
      language
    };

    dispatch({
      type: types.SUB_COMPANIES_PREVIEW_INVOICE_REQUEST
    });

    return dispatch(callApi('invoices', 'preview', params)).then(
      data =>
        data.blob().then(
          blob =>
            new Promise(resolve => {
              if (isInternetExplorer11()) {
                dispatch({
                  type: types.SUB_COMPANIES_PREVIEW_INVOICE_SUCCESS,
                  ...invoicePreviewDefault
                });

                const bundle = state.i18n.bundle || {};
                const name = bundle.company_invoice_preview || 'Invoice_preview';
                const at = bundle.common_at || 'at';
                const now = moment();
                const date = now.format('DD-MM-YYYY');
                const time = now.format('HH.mm.ss');

                saveAs(blob, `${name}_${date}_${at}_${time}.pdf`);
                resolve();
              } else {
                const reader = new FileReader();
                reader.onloadend = () => {
                  dispatch({
                    type: types.SUB_COMPANIES_PREVIEW_INVOICE_SUCCESS,
                    blob,
                    base64: reader.result
                  });
                  resolve();
                };
                reader.readAsDataURL(blob);
              }
            })
        ),
      error =>
        dispatch({
          type: types.SUB_COMPANIES_PREVIEW_INVOICE_ERROR,
          error
        })
    );
  };
}

export function clearSubCompanyInvoice() {
  return {
    type: types.SUB_COMPANIES_PREVIEW_INVOICE_CLEAR
  };
}

const successMessage = addFlashMessage({
  contentKey: 'common_success',
  type: FLASH_MESSAGE_TYPE_SUCCESS
});

export function subCompanyUpdateBankoutConfigAction(showSuccess = true) {
  return (dispatch, getState) => {
    const state = getState();
    const companyDetails = currentSubCompanySelector(state);

    return dispatch(sharedCompanyUpdateBankoutConfigAction(companyDetails)).then(
      data => {
        data = getPaybackSettingsParse(data);
        dispatch(subCompanyClearPaybackSettingsData());
        dispatch({ type: types.SUB_COMPANY_GET_PAYBACK_CONFIG_SUCCESS, data });
        if (showSuccess) dispatch(successMessage);
        return Promise.resolve(data);
      },
      error => {
        dispatch(addErrorMessage({ error }));
        return Promise.reject(error);
      }
    );
  };
}

export function subCompanyCreateBankoutConfigAction() {
  return (dispatch, getState) => {
    const state = getState();
    const companyState = state.subCompanies;
    const companyDetails = currentSubCompanySelector(state);

    return dispatch(sharedCompanyCreateBankoutConfigAction(companyState, companyDetails, BILLING_ENTITY_TYPE_COMPANY)).then(
      data => {
        dispatch(successMessage);
        return Promise.resolve(data);
      },
      error => {
        dispatch(addErrorMessage({ error }));
        return Promise.reject(error);
      }
    );
  };
}

export function subCompanyGetPaybackSettingsAction(companyId, displayError = false) {
  return dispatch => {
    return dispatch(callApi('bankouts', 'getConfig', companyId)).then(
      data => {
        data = getPaybackSettingsParse(data);
        dispatch({ type: types.SUB_COMPANY_GET_PAYBACK_CONFIG_SUCCESS, data });
        return Promise.resolve(data);
      },
      error => {
        dispatch({ type: types.SUB_COMPANY_GET_PAYBACK_CONFIG_ERROR, error });
        if (displayError) dispatch(addErrorMessage({ error }));
        return Promise.reject(error);
      }
    );
  };
}

export function subCompanyClearPaybackSettingsData() {
  return { type: types.SUB_COMPANY_CLEAR_PAYBACK_SETTINGS_DATA };
}

export function subCompanyClearTemporaryBankDetails() {
  return { type: types.SUB_COMPANY_CLEAR_TEMPORARY_BANK_DETAILS };
}

export function subCompanySavePaybackRedirectData(data) {
  return {
    type: types.SUB_COMPANY_SAVE_PAYBACK_REDIRECT_DATA,
    ...data
  };
}

export function subCompanyUpdateBankDetails() {
  return (dispatch, getState) => {
    const state = getState();
    const companyState = state.subCompanies;
    const companyDetails = currentSubCompanySelector(state);
    const userRole = userRoleSelector(state);
    const canUpdate = checkRole(subCompanyPaybackBankDetailsEditRules, userRole);

    if (!canUpdate) return Promise.resolve();

    return dispatch(sharedCompanyUpdateBankDetailsAction(companyState, companyDetails)).then(
      data => Promise.resolve(data),
      error => {
        dispatch(addErrorMessage({ error }));
        return Promise.reject(error);
      }
    );
  };
}

export function getSubCompanyEmailSetup(companyId) {
  return dispatch => {
    const params = {
      companyId,
      defaultTemplate: true
    };

    dispatch({
      type: types.SUB_COMPANY_GET_EMAIL_SETUP_REQUEST
    });

    if (!isValidId(companyId)) return Promise.resolve();

    return dispatch(callApi('customizations', 'getAssets', params)).then(
      data =>
        dispatch({
          type: types.SUB_COMPANY_GET_EMAIL_SETUP_SUCCESS,
          data,
          companyId
        }),
      error =>
        dispatch({
          type: types.SUB_COMPANY_GET_EMAIL_SETUP_ERROR,
          error
        })
    );
  };
}

export function setSubCompanyEmailSetup() {
  return (dispatch, getState) => {
    const state = getState();
    const detailCompanyId = _get(state.subCompanies, 'currentSubCompany.id');
    const form = _get(state.form, COMPANY_EMAIL_SETUP_FORM);
    const {
      colorPrimary,
      colorSecondary,
      linkedinLink,
      twitterLink,
      emailLogo,
      productName,
      senderEmail,
      senderSms,
      homeDeeplink
    } = trimValues(form);

    let subParams = {};

    if (isValidId(emailLogo)) {
      subParams.logoFileId = emailLogo;
    }

    trySet(subParams, 'colour1', colorPrimary);
    trySet(subParams, 'colour2', colorSecondary);
    trySet(subParams, 'linkedinLink', linkedinLink);
    trySet(subParams, 'twitterLink', twitterLink);
    trySet(subParams, 'productName', productName);
    trySet(subParams, 'senderEmail', senderEmail);
    trySet(subParams, 'senderSms', senderSms);
    trySet(subParams, 'homeDeeplink', homeDeeplink);

    const params = {
      companyId: detailCompanyId,
      params: subParams
    };

    dispatch({
      type: types.SUB_COMPANY_UPDATE_EMAIL_SETUP_REQUEST
    });

    function dispatchErrorMsg() {
      return dispatch(
        addFlashMessage({
          contentKey: 'company_email_setup_update_error',
          type: FLASH_MESSAGE_TYPE_ERROR
        })
      );
    }

    if (!isValidId(detailCompanyId)) {
      dispatchErrorMsg();
      return Promise.reject('company ID is not valid');
    }

    return dispatch(callApi('customizations', 'setAssets', params)).then(
      data => {
        dispatch({
          type: types.SUB_COMPANY_UPDATE_EMAIL_SETUP_SUCCESS,
          data
        });

        dispatch(
          addFlashMessage({
            contentKey: 'company_email_setup_update_success',
            type: FLASH_MESSAGE_TYPE_SUCCESS
          })
        );

        return Promise.resolve();
      },
      error => {
        dispatch({
          type: types.SUB_COMPANY_UPDATE_EMAIL_SETUP_ERROR,
          error
        });

        dispatchErrorMsg();
        return Promise.reject(error);
      }
    );
  };
}

export function deleteSubCompanyEmailSetup() {
  return (dispatch, getState) => {
    dispatch({
      type: types.SUB_COMPANY_DELETE_EMAIL_SETUP_REQUEST
    });

    const state = getState();
    const detailCompanyId = _get(state.subCompanies, 'currentSubCompany.id');

    if (!isValidId(detailCompanyId)) return Promise.reject('company ID is not valid');

    return dispatch(callApi('customizations', 'deleteAssets', detailCompanyId)).then(
      () => {
        dispatch({
          type: types.SUB_COMPANY_DELETE_EMAIL_SETUP_SUCCESS
        });

        return Promise.resolve();
      },
      error => {
        dispatch({
          type: types.SUB_COMPANY_DELETE_EMAIL_SETUP_ERROR
        });

        return Promise.reject(error);
      }
    );
  };
}

export function pullSubCompanyEmailSetup(companyId) {
  return dispatch => {
    const params = {
      companyId,
      defaultTemplate: true
    };

    dispatch({
      type: types.SUB_COMPANY_PULL_EMAIL_SETUP_REQUEST
    });

    if (!isValidId(companyId)) return Promise.reject('company ID is not valid');

    return dispatch(callApi('customizations', 'getAssets', params)).then(
      data =>
        dispatch({
          type: types.SUB_COMPANY_GET_EMAIL_SETUP_SUCCESS,
          data,
          companyId
        }),
      error =>
        Promise.reject(
          dispatch({
            type: types.SUB_COMPANY_PULL_EMAIL_SETUP_ERROR,
            error
          })
        )
    );
  };
}

export function setParentSuperCompanyInHeader(subCompanyDetails) {
  return (dispatch, getState) => {
    const state = getState();

    const { parentCompanyId, parentCompanyName } = subCompanyDetails || {};
    const { id: currentParentId } = state.companies.currentCompany;

    if (parentCompanyId !== currentParentId) {
      return dispatch(
        setCurrentCompany({
          id: parentCompanyId,
          name: parentCompanyName
        })
      );
    } else return Promise.resolve();
  };
}
