import { UPDATE_LOCATION } from 'react-router-redux';
import moment from 'moment';
import _sortBy from 'lodash/sortBy';

import * as types from '../constants/actionTypes-constants';

export default function vehicleDamages(
  state = {
    paginatedResults: null,
    urlParams: null,
    currentSortedIndex: null,
    sortIsDescending: false,
    vehicleDamagesFiltersFormIsOpen: false,
    openedAddDamageModal: null,
    damageCurrentImage: null,
    vehicleDamageDetail: null,
    openedVehicleDamageEditStatusModal: null,
    vehicleDamageImage: null
  },
  action
) {
  switch (action.type) {
    case types.VEHICLE_DAMAGES_GET_LIST_REQUEST:
      return state;

    case types.VEHICLE_DAMAGES_GET_LIST_SUCCESS:
      return Object.assign({}, state, {
        paginatedResults: action.paginatedObj,
        urlParams: action.urlParams
      });

    case types.VEHICLE_DAMAGES_SET_CURRENT_SORTED_INDEX:
      return Object.assign({}, state, {
        currentSortedIndex: action.index
      });

    case types.VEHICLE_DAMAGES_SORT_DIRECTION_SET:
      return Object.assign({}, state, {
        sortIsDescending: action.isDescending
      });

    case types.VEHICLE_DAMAGES_FILTERS_FORM_OPENED_STATE_TOGGLE:
      return Object.assign({}, state, {
        vehicleDamagesFiltersFormIsOpen: !state.vehicleDamagesFiltersFormIsOpen
      });

    case types.VEHICLE_DAMAGES_ADD_DAMAGE_MODAL_OPEN:
      return Object.assign({}, state, {
        openedAddDamageModal: true
      });

    case types.VEHICLE_DAMAGES_ADD_DAMAGE_MODAL_CLOSE:
      return Object.assign({}, state, {
        openedAddDamageModal: null
      });

    case types.VEHICLE_DAMAGE_FORM_UPDATE_DAMAGE_IMAGE_SUCCESS:
      return Object.assign({}, state, {
        damageCurrentImage: action.fileData
      });

    case types.VEHICLE_DAMAGE_FORM_UPDATE_DAMAGE_IMAGE_REMOVE:
      return Object.assign({}, state, {
        damageCurrentImage: null
      });

    case UPDATE_LOCATION:
      return Object.assign({}, state, {
        currentSortedIndex: null,
        sortIsDescending: false,
        damageCurrentImage: null
      });

    case types.VEHICLE_DAMAGES_GET_DETAIL_DAMAGE_REQUEST:
      return state;

    case types.VEHICLE_DAMAGES_GET_DETAIL_DAMAGE_SUCCESS:
      action.vehicleDamage.statuses = _sortBy(action.vehicleDamage.statuses, function(item) {
        return moment(item.date);
      }).reverse();

      return Object.assign({}, state, {
        vehicleDamageDetail: action.vehicleDamage
      });

    case types.VEHICLE_DAMAGES_EDIT_STATUS_MODAL_OPEN:
      return Object.assign({}, state, {
        openedVehicleDamageEditStatusModal: true
      });

    case types.VEHICLE_DAMAGES_EDIT_STATUS_MODAL_CLOSE:
      return Object.assign({}, state, {
        openedVehicleDamageEditStatusModal: null
      });

    case types.VEHICLE_DAMAGES_GET_DETAIL_IMAGE_SUCCESS:
      return Object.assign({}, state, {
        vehicleDamageImage: action.vehicleDamageImage
      });

    case types.VEHICLE_DAMAGE_DETAIL_IMAGE_REMOVE:
      return Object.assign({}, state, {
        vehicleDamageImage: null
      });

    default:
      return state;
  }
}
