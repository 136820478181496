/* eslint-disable react/no-set-state */

import { injectIntl } from 'react-intl';
import FlatButton from 'material-ui/FlatButton';
import DatesRange from './DatesRange';
import classNames from 'classnames';

import { pollingStatusesExport } from '../../../actions/all-actions';
// noinspection ES6CheckImport
import React, { Component, PropTypes } from 'react';
import autoBind from 'react-autobind';
import DownloadIcon from 'material-ui/svg-icons/file/file-download';

class VehicleStatusesReportForm extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.exportIdUser = window.localStorage.getItem('idExportStatuses');
  }

  componentWillMount() {
    this.exportIdUser = window.localStorage.getItem('idExportStatuses');
    if (this.exportIdUser) {
      this.props.dispatch(pollingStatusesExport(this.exportIdUser));
    }
  }

  handleExport() {
    const { handleSubmit, vehicleStatusesDates } = this.props;
    if (handleSubmit) handleSubmit({ ...vehicleStatusesDates });
  }

  render() {
    const {
      intl: { formatMessage },
      reportStarted
    } = this.props;

    const exporting = this.exportIdUser || reportStarted;
    const labelKey = exporting ? 'common_button_exporting' : 'vehicle_statuses_generate_report';

    return (
      <div className="vehicle-statuses-report-form">
        <DatesRange vehicleStatusesDates={this.props.vehicleStatusesDates} selectedPieData={this.props.selectedPieData} />
        <div className="actions">
          <FlatButton
            id="fleet_export_button"
            label={formatMessage({ id: labelKey })}
            icon={<DownloadIcon />}
            disabled={exporting}
            className={classNames('flatButton', 'vehicle-statuses-export-button')}
            onClick={this.handleExport}
          />
        </div>
      </div>
    );
  }
}

VehicleStatusesReportForm.propTypes = {
  handleSubmit: PropTypes.func,
  vehicleStatusesDates: PropTypes.object
};

VehicleStatusesReportForm.displayName = 'VehicleStatusesReportForm';

export default injectIntl(VehicleStatusesReportForm);
