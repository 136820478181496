import React, { Component, PropTypes as T } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { Tooltip } from 'react-tippy';
import _get from 'lodash/get';

import { addTippyContent, getAppFormattedDayMonthTime, localTime } from '../../../../utils/utils';
import autoBind from 'react-autobind/lib/autoBind';
import cs from 'classnames';

const leftColumnWidth = '290px';

class VehiclePlanningStatus extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
  }

  getWidthStatus({ containerWidth, periodInDays, statusDuration }) {
    return Math.abs(((containerWidth - 295) / periodInDays / 1440) * statusDuration);
  }

  render() {
    const { status, statusIndex, startDate, vehicle, periodInDays, intl } = this.props;

    const sortedStatuses = vehicle.statuses.sort((a, b) => {
      return new Date(b.date) > new Date(a.date) ? -1 : 1;
    });

    if (status.type !== 'READY') {
      const isFinalStatus = sortedStatuses.length - 1 < statusIndex + 1;
      const hasEndDate = _get(sortedStatuses[statusIndex + 1], 'date', false);
      const isSameDay = moment(startDate).isSame(moment(status.date), 'day');
      const isStartingInPeriod = moment(status.date).isBetween(moment(startDate), moment(startDate).add(periodInDays, 'days'), null, '[]');

      const statusDuration = hasEndDate && moment(localTime(hasEndDate)).diff(moment(localTime(status.date)), 'minutes');

      const statusWidth =
        isStartingBefore && isEndingBetween ? statusDuration - startingInPx - 75 / 2 : hasEndDate && Math.abs(1440 / statusDuration);
      // here we need to add an extra hour to startDate
      // because backend startDate is beginning 1 hour earlier (utc time)
      const startingInPx = moment(status.date).diff(moment(startDate), 'minutes');
      const isStartingBefore = moment(status.date).isBefore(moment(startDate));
      const isEndingAfter = hasEndDate ? moment(hasEndDate).isAfter(moment(startDate).add(periodInDays, 'days')) : true;

      const isEndingBetween = hasEndDate
        ? moment(hasEndDate).isBetween(moment(startDate), moment(startDate).add(periodInDays, 'days'), null, '[]')
        : false;

      const alignment = isStartingBefore && isEndingAfter ? 'center' : isEndingBetween ? (isStartingInPeriod ? 'center' : 'right') : 'left';

      const width =
        hasEndDate ||
          (isStartingInPeriod && periodInDays === 3 && isEndingBetween) ||
          (isFinalStatus && periodInDays === 7 && isEndingBetween) ||
          (isStartingBefore && isEndingBetween)
          ? (isStartingBefore && isEndingAfter && !isEndingBetween) || (isStartingInPeriod && isEndingAfter && !isEndingBetween)
            ? `calc( 100% - ${leftColumnWidth} )`
            : `calc( ((100% -  ${leftColumnWidth}) / ${periodInDays}) / ${statusWidth} )`
          : `calc( 100% - ${leftColumnWidth} )`;

      const left =
        hasEndDate ||
          (isSameDay && periodInDays === 1) ||
          (isStartingInPeriod && periodInDays === 3) ||
          (isFinalStatus && periodInDays === 7 && startingInPx > 0)
          ? (isStartingBefore && isFinalStatus) || (isStartingBefore && isEndingAfter && !isEndingBetween)
            ? `${leftColumnWidth}`
            : `calc( (((100% -  ${leftColumnWidth}) / ${periodInDays}) / 1440 * ${startingInPx}) +  ${leftColumnWidth})`
          : `${leftColumnWidth}`;

      const inlineStatusText = shortStatus => {
        return (
          <div className={cs('status-detail', { 'padd-right': isEndingBetween, 'padd-left': isStartingInPeriod })}>
            <span className="type" children={intl.messages['vehiclesFiltersForm_vehicleStatus_' + status.type]} />
            <span className="start-date" children={getAppFormattedDayMonthTime(status.date, localTime(status.date))} />
            <span
              className="end-date"
              children={
                hasEndDate
                  ? getAppFormattedDayMonthTime(hasEndDate, localTime(hasEndDate))
                  : intl.messages['vehiclePlanning_status_without_endDate']
              }
            />
            {shortStatus && <span children={status.comment} />}
          </div>
        );
      };

      const getTooltipContent = shortStatus => {
        if (shortStatus) {
          return inlineStatusText(shortStatus);
        } else {
          if (status.comment) {
            return (
              <div className="status-detail">
                <span className="type" children={intl.messages.booking_create_confirmation_comment} />
                <span children={status.comment} />
              </div>
            );
          }
          return <span />;
        }
      };

      const buildToolTip = shortStatus => {
        return (
          <Tooltip
            followCursor
            className={shortStatus ? 'detail-tooltip pad' : 'detail-tooltip'}
            {...addTippyContent(getTooltipContent(shortStatus))}
          >
            {shortStatus ? intl.messages['vehiclesFiltersForm_vehicleStatus_' + status.type] : inlineStatusText(false)}
          </Tooltip>
        );
      };

      const containerRow = document.querySelector('div.vehiclePlanning_vehicleRow');
      const containerWidth = containerRow && containerRow.clientWidth;
      const evalwidth = this.getWidthStatus({ containerWidth, periodInDays, statusDuration });

      if (isStartingInPeriod || isEndingBetween || (isStartingBefore && isEndingAfter)) {
        return (
          <div
            key={'status_' + status.date + vehicle.id}
            className={cs("new-vehicle-planning_status")}
            style={{
              width,
              left,
              textAlign: alignment
            }}
          >
            {evalwidth < 250 && evalwidth !== 0 && buildToolTip(true)}

            {(evalwidth > 250 || evalwidth === 0) && buildToolTip(false)}
          </div >
        );
      }
    }
    return <span />;
  }
}

VehiclePlanningStatus.displayName = 'VehiclePlanningStatus';

VehiclePlanningStatus.propTypes = {
  status: T.object,
  vehicle: T.object,
  startDate: T.string
};

export default connect(state => {
  const {
    vehiclePlanning: { startDate, periodInDays }
  } = state;
  return { startDate, periodInDays };
})(injectIntl(VehiclePlanningStatus));
