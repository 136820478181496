import React from 'react';
import ListWrap from '../../../components/_v2/ColumnList/Wrap';

export default function VehicleColors(props) {
  return (
    <div className="pageContainer">
      <ListWrap {...props} />
    </div>
  );
}
