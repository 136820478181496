import React, { Component, PropTypes } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import cs from 'classnames';
import CopyPriceMemberTypeDialog from './components/CopyPriceMemberTypeDialog';
import FlatButton from 'material-ui/FlatButton';
import IconCopy from 'material-ui/svg-icons/content/content-copy';

import {
  setCurrentPricingTabIndex,
  resetOpenedCategoryWidget,
  resetCurrentCompanyBusinessTimeSlots,
  resetCategoriesTransversalPrices,
  initMemberTypeId,
  toggleCopyPriceMemberTypeDialog,
  requestCopyPriceslotsMemberType
} from '../../actions/all-actions';

import VehicleCategoryPricingWidget from '../../components/VehicleCategoryPricingWidget/VehicleCategoryPricingWidget';
import { BACKUSER_ROLE_CALL_CENTER_OPERATOR } from '../../constants/backend-constants';
import { checkRole } from '../../constants/backuser-role-rules';
import { pricingEditRules } from '../../constants/routes-constants';
import {
  headerContractMemberValuesSelector,
  headerContractVehicleValuesSelector,
  currentCompanyIdSelector,
  pricingCategoriesSelector
} from '../../selectors/all-selectors';
import { isValidId } from '../../utils/utils';

class Pricing extends Component {
  constructor(props) {
    super(props);
    this.bindFunctions();
    this.setReadOnly(props);
  }

  setReadOnly(props) {
    const { role } = props;
    this.readOnly = role === BACKUSER_ROLE_CALL_CENTER_OPERATOR || !checkRole(pricingEditRules, role);
  }

  bindFunctions() {
    this.handleCopyPrice = this.handleCopyPrice.bind(this);
    this.handleCopyMemberTypePrice = this.handleCopyMemberTypePrice.bind(this);
  }

  componentWillMount() {
    const { dispatch, list, companyId } = this.props;
    if (isValidId(companyId)) {
      dispatch(initMemberTypeId(list[0].id));
    }
  }

  handleCopyPrice() {
    this.props.dispatch(toggleCopyPriceMemberTypeDialog());
  }

  handleCopyMemberTypePrice() {
    this.props.dispatch(requestCopyPriceslotsMemberType());
  }

  handleChangeTabIndex(index, memberTypeId, e) {
    const { dispatch } = this.props;

    e.preventDefault();
    dispatch(setCurrentPricingTabIndex(index, memberTypeId));
    dispatch(resetOpenedCategoryWidget());
    dispatch(resetCurrentCompanyBusinessTimeSlots());
    dispatch(resetCategoriesTransversalPrices());
  }

  getMemberTypesHeader() {
    const { list, currentTabIndex, memberRules } = this.props;

    if (memberRules.type) {
      return (
        <div className="pricingPage_tabs">
          {list.map((item, index) => {
            return (
              <div
                key={'tab-' + index}
                onClick={this.handleChangeTabIndex.bind(this, index, item.id)}
                className={cs({
                  pricingPage_tabItem: true,
                  __is_selected: currentTabIndex === index
                })}
              >
                {item.name}
              </div>
            );
          })}
        </div>
      );
    }
  }

  render() {
    const { list, categories, memberTypeId, openCopyMemberType, intl, currency, companyId, vehicleRules } = this.props;

    if (!isValidId(companyId)) {
      return (
        <div className="mainContainer_content ">
          <div className="pageContainer pricing-page">
            <div className="page-info-block">
              <FormattedMessage id="common_select_super_company" />
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className="mainContainer_content ">
        <div className="pageContainer pricing-page">
          <div className="pricingPage_content">
            {this.getMemberTypesHeader()}

            <section className={cs('pricingPage_management', { pricingPage_noCategories: !vehicleRules.vehicleCategory })}>
              <section className="pricingPage_management_section pricingPage_management_section--business">
                <h4 className="pricingPage_management_sectionTitle">
                  <FormattedMessage id="vehiclesCategory_subtitle" />
                </h4>

                {!this.readOnly && this.props.memberRules.type && (
                  <div className="actions">
                    <FlatButton
                      id="member-type-prices-create-copy"
                      label={intl.messages.member_type_create_copy}
                      onClick={this.handleCopyPrice}
                      icon={<IconCopy />}
                    />
                  </div>
                )}
                {categories.map(category => {
                  return (
                    <VehicleCategoryPricingWidget key={category.id} category={category} limitedAccess={this.readOnly} currency={currency} />
                  );
                })}
              </section>
            </section>
            {openCopyMemberType && (
              <CopyPriceMemberTypeDialog list={list} source={memberTypeId} onCallback={this.handleCopyMemberTypePrice} />
            )}
          </div>
        </div>
      </div>
    );
  }
}

Pricing.displayName = 'Pricing';

Pricing.propTypes = {
  list: PropTypes.array,
  categories: PropTypes.array,
  currentTabIndex: PropTypes.number,
  openCopyMemberType: PropTypes.bool,
  role: PropTypes.string
};

export default connect(state => {
  const {
    pricing: { currentTabIndex, memberTypeId, openCopyMemberType },
    memberTypes: { list },
    companies: {
      currentCompanyDetails: { currency }
    },
    user: {
      userInfo: { role }
    }
  } = state;

  return {
    list,
    currentTabIndex,
    memberTypeId,
    role,
    openCopyMemberType,
    currency: currency || {},
    vehicleRules: headerContractVehicleValuesSelector(state),
    categories: pricingCategoriesSelector(state),
    companyId: currentCompanyIdSelector(state),
    memberRules: headerContractMemberValuesSelector(state)
  };
})(injectIntl(Pricing));
