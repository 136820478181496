import React, { Component } from 'react';
import { connect } from 'react-redux';
import { routeActions } from 'react-router-redux';
import HotlineForm from '../../components/HotlineForm/HotlineForm';
import { createHotlineRequest, createHotlineSuccess, addFlashMessage } from '../../actions/all-actions';
import { FLASH_MESSAGE_TYPE_SUCCESS } from '../../constants/generic-constants';
import routes from '../../constants/routes-constants';
import { addErrorMessage } from '../../utils/utils';
import _values from 'lodash/values';
import { partialErrorCodes } from '../../constants/options-constants';

class AddHotline extends Component {
  constructor() {
    super();
    this.handleAddHotline = this.handleAddHotline.bind(this);
  }

  handleAddHotline() {
    const { dispatch } = this.props;
    dispatch(createHotlineRequest())
      .then(hotline => {
        dispatch(createHotlineSuccess(hotline));
        dispatch(
          addFlashMessage({
            contentKey: 'addHotline_create_hotline_success',
            type: FLASH_MESSAGE_TYPE_SUCCESS,
            delayToNextRoute: true
          })
        );
        dispatch(routeActions.push(routes.hotlines.path));
      })
      .catch(error => {
        const partialErrors = _values(partialErrorCodes.generic);
        const msgParams = { error, partialErrors, def: 'addHotline_create_hotline_error' };
        dispatch(addErrorMessage(msgParams));
      });
  }

  render() {
    return (
      <div className="addHotlinePage mainContainer_content">
        <div className="pageContainer">
          <div className="addHotlinePage_content">
            <div className="addHotlinePage_head"></div>

            <section className="addHotlinePage_management">
              <HotlineForm
                onCallback={this.handleAddHotline}
                initialValues={{
                  name: ''
                }}
              />
            </section>
          </div>
        </div>
      </div>
    );
  }
}

AddHotline.displayName = 'AddHotline';

AddHotline.propTypes = {};

export default connect(state => {
  const {} = state;

  return {};
})(AddHotline);
