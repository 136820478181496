import _get from 'lodash/get';

import * as types from '../constants/actionTypes-constants';
import callApi from './api-actions';
import { addFlashMessage } from './all-actions';
import {
  FLASH_MESSAGE_TYPE_ERROR,
  FLASH_MESSAGE_TYPE_SUCCESS,
  SEARCH_INVOICES_DATE_FORMAT,
  SORT_DESC
} from '../constants/generic-constants';
import { SORT_INVOICE_EMISSIOM_DATE } from '../constants/backend-constants';

import {
  addErrorMessage,
  append,
  invoiceItemFormat,
  maxDateFormat,
  minDateFormat,
  safe,
  selectCompanyId,
  selectSubCompanyId,
  trySet
} from '../utils/utils';

import moment from 'moment';
import { saveAs } from 'file-saver';
import { addErrorMsg } from '../utils/flashMessage/creator';
import { getMsg } from '../utils/IntlGlobalProvider';

export function getInvoicesList(params = {}, { loader = false, withPdf = true } = {}) {
  return (dispatch, getState) => {
    const state = getState();
    const { size, number, sortDirection, sortProperty, startDate, endDate, reference, status, bookingId } = params || {};
    const { paginatedResults } = state.invoices || {};
    const { number: fallbackNumber, size: fallbackSize } = paginatedResults || {};

    const paramsObj = {
      number: number || fallbackNumber || 1,
      size: size || fallbackSize || 50,
      sortProperty: sortProperty || SORT_INVOICE_EMISSIOM_DATE,
      sortDirection: sortDirection || SORT_DESC,
      withPdfLink: withPdf
    };

    if (startDate) paramsObj.startDate = minDateFormat(params.startDate, SEARCH_INVOICES_DATE_FORMAT);
    if (endDate) paramsObj.endDate = maxDateFormat(params.endDate, SEARCH_INVOICES_DATE_FORMAT);
    if (reference) paramsObj.reference = reference;
    if (status) paramsObj.status = status;
    if (bookingId) paramsObj.bookingId = bookingId;

    trySet(paramsObj, 'companyId', append([selectCompanyId(state)]));
    trySet(paramsObj, 'subCompanyId', append([selectSubCompanyId(state)]));

    dispatch({
      type: types.INVOICES_GET_LIST_REQUEST
    });

    return dispatch(callApi('invoices', 'getList', { params: paramsObj }, loader))
      .then(data => {
        dispatch({
          type: types.INVOICES_GET_LIST_SUCCESS,
          paginatedObj: data,
          urlParams: paramsObj
        });
      })
      .catch(() => {
        dispatch({
          type: types.INVOICES_GET_LIST_ERROR
        });
      });
  };
}

export function getSingleInvoice(reference) {
  return dispatch => {
    return dispatch(
      callApi('invoices', 'getList', {
        params: {
          number: 1,
          size: 1,
          reference,
          withPdfLink: true
        }
      })
    )
      .then(data => {
        const invoice = safe(() => data.results[0]);

        if (!invoice) {
          addErrorMsg(getMsg('company_invoice_preview') + ': ' + getMsg('common_error'));
        }
        dispatch({ type: types.INVOICES_SAVE_INVOICE_DETAILS, invoice });

        return invoice;
      })
      .catch(error => {
        dispatch(addErrorMessage({ error }));
      });
  };
}

export function payRequest(id) {
  return {
    type: types.INVOICE_PAYMENT_REQUEST,
    id
  };
}

export function retryPayment(invoiceId, loader = false) {
  return dispatch => {
    dispatch(payRequest(invoiceId));

    return dispatch(callApi('invoices', 'payment', invoiceId, loader))
      .then(() => {
        dispatch({ type: types.INVOICE_PAYMENT_SUCCESS, invoiceId });
        dispatch(
          addFlashMessage({
            contentKey: 'invoice_payment_success',
            type: FLASH_MESSAGE_TYPE_SUCCESS
          })
        );
      })
      .catch(err => {
        dispatch({ type: types.INVOICE_PAYMENT_FAIL, err, invoiceId });
        try {
          err.body.json().then(parsedError => {
            return dispatch(
              addFlashMessage({
                content: parsedError.developerMessage || parsedError.message,
                type: FLASH_MESSAGE_TYPE_ERROR
              })
            );
          });
        } catch (e) {
          dispatch(
            addFlashMessage({
              contentKey: 'common_error_ocurred',
              type: FLASH_MESSAGE_TYPE_ERROR
            })
          );
        }
      });
  };
}

export function toggleInvoicePreview(invoice) {
  return {
    type: types.INVOICE_PDF_VIEW_MODAL,
    invoice
  };
}

export function toggleInvoicesFilters() {
  return {
    type: types.INVOICES_FILTERS_FORM_OPENED_STATE_TOGGLE
  };
}

export function clearInvoiceCreationSession() {
  return {
    type: types.INVOICE_CREATE_CLEAR_CREATION_SESSION
  };
}

export function setAddInvoiceSelectedBooking(selectedBooking) {
  return {
    type: types.INVOICE_ADD_SET_SELECTED_BOOKING,
    selectedBooking
  };
}

export function addInvoiceItem(item) {
  return {
    type: types.INVOICE_ADD_ITEM,
    payload: invoiceItemFormat(item)
  };
}

export function deleteInvoiceItem(payload) {
  return {
    type: types.INVOICE_DELETE_ITEM,
    payload
  };
}

export function toggleDepositUsage() {
  return {
    type: types.INVOICE_TOGGLE_DEPOSIT_USAGE
  };
}

export function getVatRateFailed() {
  return {
    type: types.INVOICE_GET_VAT_RATE_FAILED
  };
}

export function createInvoice(bookingId) {
  return (dispatch, getState) => {
    const state = getState();

    return dispatch(
      callApi('invoices', 'createInvoice', {
        invoiceItems: state.invoices.invoiceItems,
        useBookingDamageDeposit: state.invoices.useBookingDamageDeposit,
        bookingId
      })
    )
      .then(() => {
        dispatch(
          addFlashMessage({
            contentKey: 'invoice_create_success',
            type: FLASH_MESSAGE_TYPE_SUCCESS,
            delayToNextRoute: true
          })
        );
        return Promise.resolve();
      })
      .catch(() => {
        dispatch(
          addFlashMessage({
            contentKey: 'invoice_create_failed',
            type: FLASH_MESSAGE_TYPE_ERROR
          })
        );
        return Promise.reject();
      });
  };
}

export function exportInvoicesListWithSearch(params = {}) {
  return (dispatch, getState) => {
    const state = getState();

    let paramsObj = {};

    if (params) {
      const sortProperty = params.sortProperty ? params.sortProperty : 'emissionDate';
      const sortDirection = params.sortDirection ? params.sortDirection : 'DESC';

      paramsObj = {
        sortProperty,
        sortDirection
      };

      params.startDate ? (paramsObj.startDate = minDateFormat(params.startDate, SEARCH_INVOICES_DATE_FORMAT)) : null;
      params.endDate ? (paramsObj.endDate = maxDateFormat(params.endDate, SEARCH_INVOICES_DATE_FORMAT)) : null;
      params.type ? (paramsObj.type = _get(params, 'type').toLowerCase()) : null;
      params.status ? (paramsObj.status = _get(params, 'status')) : null;
      params.reference ? (paramsObj.reference = _get(params, 'reference')) : null;
    }

    trySet(paramsObj, 'companyId', selectCompanyId(state));
    trySet(paramsObj, 'subCompanyId', selectSubCompanyId(state));

    dispatch({
      type: types.INVOICES_EXPORT_REQUEST
    });

    return dispatch(callApi('invoices', 'exportCSV', { params: paramsObj }, false))
      .then(data => {
        let fileName = 'invoices';
        const fileBlob = new Blob([data], { type: 'text/csv' });

        fileName = fileName + '_' + moment().format('DD-MMM-YYYY_HH:mm:ss') + '.csv';
        saveAs(fileBlob, fileName);

        dispatch({
          type: types.INVOICES_EXPORT_FINISHED
        });
        dispatch(
          addFlashMessage({
            contentKey: 'invoices_download_success',
            type: FLASH_MESSAGE_TYPE_SUCCESS
          })
        );
      })
      .catch(() => {
        dispatch(
          addFlashMessage({
            contentKey: 'invoices_download_failed',
            type: FLASH_MESSAGE_TYPE_ERROR
          })
        );
      });
  };
}

export function getItalianInvoiceStatuses(bookingId) {
  return dispatch => {
    dispatch(clearItalianInvoiceStatus());

    return dispatch(callApi('invoices', 'getItalianStatus', bookingId)).then(
      data =>
        dispatch({
          type: types.INVOICE_GET_ITALIAN_STATUS_SUCCESS,
          data
        }),
      error => {
        dispatch(addErrorMessage({ error }));
        return Promise.resolve();
      }
    );
  };
}

export function getDmsInvoiceErrors(bookingId) {
  return dispatch => {
    dispatch(clearDmsInvoiceErrors());

    return dispatch(callApi('invoices', 'getDmsErrors', bookingId)).then(
      data => dispatch({ type: types.INVOICE_GET_DMS_STATUS_SUCCESS, data }),
      error => {
        dispatch(addErrorMessage({ error }));
        return Promise.resolve();
      }
    );
  };
}

export function clearDmsInvoiceErrors() {
  return {
    type: types.INVOICE_CLEAR_DMS_ERRORS
  };
}

export function clearItalianInvoiceStatus() {
  return {
    type: types.INVOICE_CLEAR_ITALIAN_STATUS
  };
}

export function setInvoicesFilters(filters) {
  return {
    type: types.INVOICES_UPDATE_FILTERS,
    filters
  };
}

export function setStatusCreateInvoiceV2(url) {
  return {
    type: types.INVOICES_SET_STATUS_V2_CREATE,
    url
  };
}
