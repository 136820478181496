import React, { Component, PropTypes as T } from 'react';
import moment from 'moment';
// import moment from 'moment-timezone';
import { connect } from 'react-redux';
import _partial from 'lodash/partial';
import { injectIntl } from 'react-intl';
import { Tooltip } from 'react-tippy';
import TooltipCard from './TooltipCard';
import classNames from 'classnames';

import {
  BOOKING_STATUS_COMPLETED,
  BOOKING_STATUS_SCHEDULED,
  STATUS_CANCELED,
  BOOKING_STATUS_IN_PROGRESS,
  BOOKING_STATUS_PRE_BOOKED
} from '../../../constants/backend-constants';
import { addTippyContent, getAppFormattedDayMonthTime, getBookingColor, localTime } from '../../../utils/utils';
import { toggleVehiclePlanningCard } from '../../../actions/all-actions';

class VehiclePlanningBooking extends Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(booking, vehicle) {
    this.props.dispatch(toggleVehiclePlanningCard(booking, vehicle));
  }

  bookingContentTooltip() {
    const { booking, vehicle, readOnly } = this.props;
    return (
      <div className="card">
        <TooltipCard readOnly={readOnly} vehicle={vehicle} booking={booking} />
      </div>
    );
  }

  bookingContent() {
    return (
      <Tooltip
        className="detail-tooltip"
        useContext
        {...addTippyContent(this.bookingContentTooltip())}
        delay="100"
        interactive
        interactiveBorder="100"
      >
        {this.bookingTiming()}
      </Tooltip>
    );
  }

  bookingTiming() {
    const {
      booking,
      intl: { formatMessage },
      locale
    } = this.props;
    let startTime = null;
    let endTime = null;
    let strTiming = '';
    const status = booking.status;
    const isFinished = status === BOOKING_STATUS_COMPLETED;
    const isStarted = status === BOOKING_STATUS_IN_PROGRESS;

    switch (status) {
      case BOOKING_STATUS_COMPLETED:
        startTime = booking.effectiveStartDate;
        endTime = booking.effectiveEndDate;
        break;
      case BOOKING_STATUS_IN_PROGRESS:
        startTime = booking.effectiveStartDate;
        endTime = booking.estimatedEndDate;
        break;
      case STATUS_CANCELED:
      case BOOKING_STATUS_SCHEDULED:
      case BOOKING_STATUS_PRE_BOOKED:
        startTime = booking.estimatedStartDate;
        endTime = booking.estimatedEndDate;
        break;
      default:
    }

    if (startTime && endTime) strTiming = getAppFormattedDayMonthTime(startTime) + ' - ' + getAppFormattedDayMonthTime(endTime);

    const delayStart = isStarted || isFinished ? localTime(booking.estimatedEndDate) : moment();
    const delayEnd = booking.status === BOOKING_STATUS_COMPLETED ? localTime(booking.effectiveEndDate) : moment();
    const delay = moment
      .duration(delayStart.diff(delayEnd, 'minutes'), 'minutes')
      .locale(locale)
      .humanize({ m: 60, h: 24, d: 7, w: 4 });

    return (
      <span
        className={classNames({ vehiclePlanning_booking_name: !booking.delayed, vehiclePlanning_booking_name_delayed: booking.delayed })}
      >
        {booking.member.firstName + ' ' + booking.member.lastName}
        {booking.delayed && ' ' + formatMessage({ id: 'booking_details_late' }, { duration: delay })}
        {!booking.delayed && ' '}
        {strTiming}
      </span>
    );
  }

  render() {
    const { startDate, periodInDays, booking, vehicle, reservationBuffer, intl } = this.props;
    const status = booking.status;
    const isFinished = status === BOOKING_STATUS_COMPLETED;
    const isStarted = status === BOOKING_STATUS_IN_PROGRESS;
    const bookingDuration = moment(
      localTime(isFinished ? booking.effectiveEndDate : !booking.effectivEndDate && booking.delayed ? moment() : booking.estimatedEndDate)
    ).diff(moment(localTime(isStarted ? booking.effectiveStartDate : booking.estimatedStartDate)), 'minutes');

    const reservationBufferWidth = 1440 / reservationBuffer;
    const startingInPx = moment(localTime(isFinished || isStarted ? booking.effectiveStartDate : booking.estimatedStartDate)).diff(
      moment(startDate),
      'minutes'
    );

    // const hasDiffZone = moment.parseZone(isFinished || isStarted ? booking.effectiveStartDate : booking.estimatedStartDate).utcOffset();

    const bookingWidth = bookingDuration <= 0 ? 1440 : 1440 / bookingDuration;
    const isStartingBefore = moment(isFinished || isStarted ? booking.effectiveStartDate : booking.estimatedStartDate).isBefore(
      moment(startDate)
    );
    const isEndingAfter = moment(isFinished ? booking.effectiveEndDate : booking.estimatedEndDate).isAfter(
      moment(startDate).add(periodInDays, 'days')
    );

    const isEndingInPeriod = moment(isFinished ? booking.effectiveEndDate : booking.estimatedEndDate).isBetween(
      moment(startDate),
      moment(startDate).add(periodInDays, 'days'),
      null,
      '[]'
    );
    const isStartingInPeriod = moment(isFinished || isStarted ? booking.effectiveStartDate : booking.estimatedStartDate).isBetween(
      moment(startDate),
      moment(startDate).add(periodInDays, 'days'),
      null,
      '[]'
    );

    const width = isStartingInPeriod
      ? `calc( ((100% - 140px) / ${periodInDays}) / ${bookingWidth} )`
      : isStartingBefore && isEndingAfter
      ? 'calc(100% - 150px)'
      : isStartingBefore
      ? `calc( ((100% - 150px) / ${periodInDays}) / 1440 * ${bookingDuration + startingInPx} )`
      : `calc( ((100% - 150px) / ${periodInDays}) / ${bookingWidth} )`;

    const left = isStartingInPeriod
      ? `calc( (((100% - 150px) / ${periodInDays}) / 1440 * ${startingInPx}) + 150px)`
      : isStartingBefore && isEndingAfter
      ? 'calc(150px)'
      : isEndingInPeriod
      ? `calc(150px)`
      : `calc( (((100% - 140px) / ${periodInDays}) / 1440 * ${startingInPx}) + 140px)`;

    const delayedWidth =
      isStarted && booking.delayed ? 1440 / moment(moment()).diff(localTime(booking.estimatedEndDate), 'minutes') : false;
    return (
      <div className="vehiclePlanning_booking_wrap">
        {!isFinished && (
          <div
            className="vehiclePlanning_booking_reservationBuffer"
            style={{
              borderRadius: '3px 0 0 3px',
              width: `calc( ((100% - 150px) / ${periodInDays}) / ${reservationBufferWidth})`,
              left: `
            calc( (((100% - 150px) / ${periodInDays}) / 1440 * ${startingInPx}) + 150px -
            (((100% - 150px) / ${periodInDays}) / ${reservationBufferWidth}))`
            }}
          />
        )}

        <div
          key={booking.id}
          data={booking.member.completedBookings === 0 ? intl.messages.member_first_booking_label : ''}
          className={classNames('vehiclePlanning_booking', { counter0: booking.member.completedBookings === 0, borderR: isFinished })}
          onClick={_partial(this.handleClick, booking, vehicle)}
          style={{
            width,
            left,
            backgroundColor: isStarted && booking.delayed ? 'red' : getBookingColor(booking.status),
            color: booking.status === BOOKING_STATUS_PRE_BOOKED ? 'black !important' : 'white'
          }}
        >
          {this.bookingContent()}
        </div>

        {booking.delayed && !isFinished && !isStartingInPeriod && (
          <div
            className="vehiclePlanning_booking_delayed"
            onClick={_partial(this.handleClick, booking, vehicle)}
            style={{
              width: isStartingBefore
                ? `calc( ((100% - 150px) / ${periodInDays}) / 1440 * ${bookingDuration + startingInPx} )`
                : `calc( ((100% - 150px) / ${periodInDays}) / ${bookingWidth} )`,
              left: isStartingInPeriod
                ? `
                calc( (((100% - 140px) / ${periodInDays}) / 1440 * ${startingInPx}) + 140px +
                (((100% - 140px) / ${periodInDays}) / ${bookingWidth}))`
                : `150px`
            }}
          >
            {this.bookingContent()}
          </div>
        )}
        {!isFinished && (
          <div
            className="vehiclePlanning_booking_reservationBuffer"
            style={{
              borderRadius: '0 3px 3px 0',
              width: `calc( ((100% - 150px) / ${periodInDays}) / ${reservationBufferWidth})`,
              left:
                delayedWidth !== false
                  ? `calc( (((100% - 150px) / ${periodInDays}) / 1440 * ${startingInPx}) + 150px +
              (((100% - 140px) / ${periodInDays}) / ${bookingWidth})) + calc( ((100% - 140px) / ${periodInDays}) / ${delayedWidth})`
                  : `calc( (((100% - 150px) / ${periodInDays}) / 1440 * ${startingInPx}) + 150px +
              (((100% - 150px) / ${periodInDays}) / ${bookingWidth}))`
            }}
          />
        )}
      </div>
    );
  }
}

VehiclePlanningBooking.displayName = 'VehiclePlanningBooking';

VehiclePlanningBooking.propTypes = {
  booking: T.object,
  startDate: T.string,
  periodInDays: T.number,
  siteId: T.string,
  sitesList: T.array,
  resetParkingIndex: T.func,
  reservationBuffer: T.number
};

export default connect(state => {
  const {
    vehiclePlanning: { startDate, periodInDays, siteId, loading, data },
    subCompanies: { subCompanySelected },
    i18n: { locale }
  } = state;

  return {
    startDate,
    periodInDays,
    siteId,
    subCompanySelected,
    loading,
    data,
    locale
  };
})(injectIntl(VehiclePlanningBooking));
