// noinspection ES6CheckImport
import React, { Component, PropTypes } from 'react';
import { reduxForm } from 'redux-form';
import { COMPANY_PAYBACK_SETTINGS_FORM } from '../../constants/form-constants';
import { RaisedButton } from 'material-ui';
import { FormattedMessage } from 'react-intl';
import { createValidator } from '../../validation/sync-validation';
import classNames from 'classnames';
import { PAYBACK_FIELDS_TO_VALIDATE, paybackConfigValidators } from '../../validation/paybackConfigValidators';

class SubmitForm extends Component {
  render() {
    const { handleSubmit, submitCallback, label } = this.props;
    const submitAction = handleSubmit(submitCallback);

    return (
      <div className="payback-forms">
        <form action="#" className="submit-form">
          <div className="submit-container">
            <RaisedButton
              label={label}
              primary
              className={classNames('button', 'flatButton')}
              type="submit"
              onClick={submitAction}
              id="submit-company-payback-settings"
            />
          </div>
        </form>
      </div>
    );
  }
}

SubmitForm.displayName = 'SubmitForm';

// noinspection JSUnusedGlobalSymbols
SubmitForm.defaultProps = {
  submitCallback: () => false,
  label: <FormattedMessage id="common_update" />
};

SubmitForm.propTypes = {
  submitCallback: PropTypes.func,
  label: PropTypes.any
};

export default reduxForm({
  form: COMPANY_PAYBACK_SETTINGS_FORM,
  fields: PAYBACK_FIELDS_TO_VALIDATE, // must have at least 1 field
  validate: createValidator(paybackConfigValidators)
})(SubmitForm);
