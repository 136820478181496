import React, { Component, PropTypes } from 'react';
import ListModal from '../../../../components/_v2/ColumnList/Modal';
import { getAddModalTitle } from '../../../../components/_v2/ColumnList/helpers';
import AddSubCompany from './AddSubCompany';
import { getMsg } from '../../../../utils/IntlGlobalProvider';

export class AddSubCompanyModal extends Component {
  getTitle() {
    return getAddModalTitle(getMsg('common_company'));
  }

  render() {
    return (
      <ListModal title={this.getTitle()} isOpen={this.props.isOpen} onClose={this.props.onClose}>
        <AddSubCompany isModal onCancel={this.props.onClose} onSubmit={this.props.onClose} companyId={this.props.companyId} />
      </ListModal>
    );
  }
}

AddSubCompanyModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  companyId: PropTypes.string
};

export default AddSubCompanyModal;
