import { VEHICLE_STATUSES } from './backend-constants';

export const siteColors = [
  '#827622',
  '#085155',
  '#c62828',
  '#AD1457',
  '#6A1B9A',
  '#1565C0',
  '#00838F',
  '#37474F',
  '#2E7D32',
  '#9E9D24',
  '#FF8F00',
  '#D84315',
  '#424242',
  '#827622',
  '#085155',
  '#c62828',
  '#AD1457',
  '#6A1B9A',
  '#1565C0',
  '#00838F',
  '#37474F',
  '#2E7D32',
  '#9E9D24',
  '#FF8F00',
  '#D84315',
  '#424242'
];

export const parkingColors = [
  '#A14EBB',
  '#5D6DBE',
  '#0A0A0A',
  '#616161',
  '#F4511E',
  '#FFB300',
  '#43A047',
  '#00ACC1',
  '#3949AB',
  '#8E24AA',
  '#e53935',
  '#A14EBB',
  '#5D6DBE',
  '#0A0A0A',
  '#616161',
  '#F4511E',
  '#FFB300',
  '#43A047',
  '#00ACC1',
  '#3949AB',
  '#8E24AA',
  '#e53935',
  '#A14EBB',
  '#5D6DBE',
  '#0A0A0A',
  '#616161',
  '#F4511E',
  '#FFB300',
  '#43A047',
  '#00ACC1',
  '#3949AB',
  '#8E24AA',
  '#e53935'
];

export const MEMBER_STATUS_COLOR = {
  STATUS_APPLIED: '#929292',
  STATUS_TO_COMPLETE: '#929292',
  STATUS_TO_REVIEW: '#ffa000',
  STATUS_APPROVED: '#24b282',
  STATUS_NOT_APPROVED: '#f00'
};

export const modalStyles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.55)',
    zIndex: 999
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '400px',
    boxShadow: '0 27px 24px 0 rgba(0, 0, 0, 0.2), 0 40px 77px 0 rgba(0, 0, 0, 0.22)',
    borderRadius: '2px'
  }
};

export const startBookingModalStyles = {
  overlay: { ...modalStyles.overlay },
  content: {
    ...modalStyles.content,
    width: '400px'
  }
};

export const voucherParkingsRestrictionsModalStyles = {
  overlay: {
    ...modalStyles.overlay
  },
  content: {
    ...modalStyles.content,
    maxHeight: 100 + 'vh',
    maxWidth: 100 + 'vw'
  }
};

export const updateBookingPriceFormStyle = {
  overlay: {
    ...modalStyles.overlay
  },
  content: {
    ...modalStyles.content,
    minWidth: '350px'
  }
};

export const BackUsersModalStyles = {
  overlay: {
    ...modalStyles.overlay
  },
  content: {
    ...modalStyles.content,
    padding: '25px'
  }
};

export const cssColors = {
  buttonText: '#0090ff',
  detailInfo: '#7f7f7f', // $color-grey-light
  listItem: '#757575',
  background: '#fff',
  chipBackground: '#e0e0e0',
  textWhite: '#fff',
  backgroundDark: '#333',
  lighterDarkTextBg: '#414546'
};

export const darkBoolean = {
  color: cssColors.backgroundDark,
  textTransform: 'capitalize'
};

export const vehicleStatusColors = {
  [VEHICLE_STATUSES.READY]: '#24B282',
  [VEHICLE_STATUSES.CLEANING]: '#17B7C4',
  [VEHICLE_STATUSES.INACTIVE]: '#929292',
  [VEHICLE_STATUSES.INTERVENTION]: '#FE5001',
  [VEHICLE_STATUSES.MAINTENANCE]: '#781A04',
  [VEHICLE_STATUSES.REPARATION]: '#FFA000',
  [VEHICLE_STATUSES.RENTED_IN_RRS]: '#FF0000'
};

export const iconStyles = {
  all: {
    fill: cssColors.detailInfo,
    display: 'inline',
    verticalAlign: 'middle'
  },
  listItem: {
    fill: cssColors.listItem
  },
  error: {
    fill: '#FFA000'
  }
};

export const VehicleDetailsModalStyles = {
  overlay: {
    ...modalStyles.overlay
  },
  content: {
    ...modalStyles.content,
    width: '400px'
  }
};

export const AddInvoiceModalStyles = {
  overlay: {
    ...modalStyles.overlay
  },
  content: {
    ...modalStyles.content,
    width: '500px'
  }
};

export const drivingLicenseModalStyles = {
  overlay: {
    ...modalStyles.overlay
  },
  content: {
    ...modalStyles.content,
    width: '80%',
    backgroundColor: 'transparent !important',
    border: '0',
    padding: '0'
  }
};

export const extendShortenModalStyles = {
  overlay: {
    ...modalStyles.overlay
  },
  content: {
    ...modalStyles.content,
    padding: '0 55px',
    width: '600px'
  }
};

export const extendShortenPickerStyles = {
  textField: {
    width: 'auto'
  }
};

export const AddCommentModalStyles = {
  overlay: {
    ...modalStyles.overlay
  },
  content: {
    ...modalStyles.content,
    width: '400px'
  }
};

export const AddDamageModalStyles = {
  overlay: {
    ...modalStyles.overlay
  },
  content: {
    ...modalStyles.content,
    width: '500px'
  }
};

export const GroupStartServiceModalStyles = {
  overlay: {
    ...modalStyles.overlay
  },
  content: {
    ...modalStyles.content,
    padding: '0 30px',
    width: '450px'
  }
};

export const VehicleDamageEditStatusModalStyles = {
  overlay: {
    ...modalStyles.overlay
  },
  content: {
    ...modalStyles.content,
    width: '300px'
  }
};

export const FileImageStyles = {
  icons: {
    pdf: {
      fill: '#DB4437',
      height: 2.5 + 'em',
      width: 'auto',
      cursor: 'pointer'
    }
  }
};

export const IconListStyles = {
  item: {
    innerDiv: {
      paddingLeft: 65
    }
  }
};

export const MemberBasicInfoStyles = {
  buttons: {
    flat: {
      color: cssColors.buttonText
    }
  }
};

export const autoCompleteStyle = {
  maxHeight: '300px',
  overflowY: 'auto'
};

export const autoCompleteListStyle = {
  width: 'auto',
  minWidth: '256px'
};

export const autoCompletePopoverProps = {
  canAutoPosition: true
};

export const autoCompleteAnchorOrigin = { horizontal: 'right', vertical: 'bottom' };
export const autoCompleteTargetOrigin = { horizontal: 'right', vertical: 'top' };

export const borderWhite = {
  borderColor: 'white'
};

export const borderDark = {
  borderColor: '#4A4A4A'
};

export const lightGrayColor = {
  color: '#e6e6e6'
};

export const colorWhite = {
  color: '#fff'
};

export const colorBlack = {
  color: '#000'
};

export const colorDark = {
  color: '#333'
};

export const fillCurrentColorInherit = {
  color: 'inherit',
  fill: 'currentColor'
};

export const fillWhite = {
  fill: '#fff'
};

export const fillRed = {
  fill: 'red'
};

export const fillDark = {
  fill: '#4A4A4A'
};

export const closeIconSuperCompanyAutoComplete = {
  position: 'absolute',
  right: '-10px'
};

export const allIconSuperCompanyAutoComplete = {
  position: 'absolute',
  right: '20px'
};

export const dimensions45 = {
  height: '45px',
  width: '45px'
};

export const dimensions52 = {
  height: '52px',
  width: '52px'
};

export const dropdownAnchorOrigin = {
  horizontal: 'right',
  vertical: 'top'
};

export const paddingLeft75 = {
  paddingLeft: '75px'
};

export const paddingLeft63 = {
  paddingLeft: '63px'
};

export const dimensions30 = {
  height: '35px',
  width: '35px'
};

export const marginBottom2 = {
  marginBottom: '2px'
};

export const dimensions20 = {
  height: '20px',
  width: '20px'
};

export const headerAccountIconWhite = {
  ...dimensions30,
  ...fillWhite
};

export const headerAccountIconDark = {
  ...dimensions30,
  ...fillDark
};

export const marginLeftMinus16 = {
  marginLeft: '-16px'
};

export const toggleLabelStyle = {
  color: '#414546',
  fontSize: '14px'
};

export const chipStyles = {
  margin: '4px',
  backgroundColor: cssColors.chipBackground
};

export const colorTextGrey2 = 'rgba(0, 0, 0, .74)';

const headerMain = {
  height: 'auto',
  color: colorTextGrey2,
  display: 'block'
};

export const DetailViewStyles = {
  icons: {
    header: {
      ...headerMain,
      width: 3 + 'em'
    },
    headerSmall: {
      ...headerMain,
      width: 2.3 + 'em'
    }
  }
};

export const hrStyle1 = {
  width: '90%',
  border: '0',
  height: '1px',
  background: '#333',
  backgroundImage: 'linear-gradient(to right, #ccc, #333, #ccc)'
};
