import React, { Component, PropTypes as T } from 'react';
import { FormattedMessage } from 'react-intl';
import routes from '../../../../constants/routes-constants';
import { VEHICLE_USAGE_TYPE_NO_USAGE } from '../../../../constants/backend-constants';
import _get from 'lodash/get';
import _isObject from 'lodash/isObject';
import FuelGauge from '../../../../components/FuelGauge/FuelGauge';
import Tooltip from '../../../../components/Tooltip/Tooltip';
import autoBind from 'react-autobind';
import { connect } from 'react-redux';
import { headerContractVehicleValuesSelector } from '../../../../selectors/all-selectors';

class VehiclePlanningVehicleTile extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.derivedStateFromProps(props, true);
  }

  // eslint-disable-next-line no-unused-vars
  componentWillReceiveProps(nextProps, nextContext) {
    this.derivedStateFromProps(nextProps);
  }

  derivedStateFromProps(nextProps) {
    const { vehicle, categoryName, companyName, companyHref, children } = nextProps;

    this.vehicle = vehicle;
    this.usageKey = 'vehicle_usage_type_' + this.vehicle.usage.toLowerCase();
    this.isNoUsage = this.vehicle.usage === VEHICLE_USAGE_TYPE_NO_USAGE;
    this.fuelLevel = _isObject(this.vehicle.fuelLevel) ? _get(this.vehicle, 'fuelLevel.percentage') : _get(this.vehicle, 'fuelLevel');

    this.fuelValue = this.fuelLevel ? <span>{this.fuelLevel}%</span> : null;

    this.categoryName = categoryName;
    this.categoryPage = '/#/pricing';
    this.companyName = companyName;
    this.companyHref = companyHref;
    this.children = children;
    this.href = `#${routes.vehicleDetail.path.replace(':vehicleId', this.vehicle.id)}`;
  }

  fuelInfo() {
    const { fuelLevelAsText } = this.props;
    if (this.vehicle.fuelType && this.fuelValue) {
      return (
        <div className="vehicleInfo fuel">
          <FormattedMessage id={`vehicle_fuelType_${this.vehicle.fuelType}`} />

          {!fuelLevelAsText && (
            <Tooltip content={this.fuelValue} placement="top">
              <FuelGauge percentage={this.fuelLevel} height={10} />
            </Tooltip>
          )}
          {fuelLevelAsText && this.fuelValue}
        </div>
      );
    }
  }

  wrapLink(doLink, children, href) {
    if (doLink) return <a href={href}>{children}</a>;
    return children;
  }

  pic() {
    const { bookingViewMoreDetails, displayUsages, usageLeft } = this.props;
    return (
      <span className="pic">
        <img
          style={{ display: 'none' }}
          src={this.vehicle.picture}
          onLoad={e => {
            e.currentTarget.style.display = 'block';
          }}
          onError={e => {
            e.currentTarget.src = '/img/placeholder.jpeg';
            e.currentTarget.style.display = 'block';
            e.currentTarget.style.opacity = '0.5';
            e.currentTarget.style.height = '70%';
            e.currentTarget.style.margin = '5px 0';
          }}
        />
        {(bookingViewMoreDetails || (usageLeft && displayUsages)) && (
          <div className="vehicle-usage-in-pic" style={{ backgroundColor: this.isNoUsage ? '#B2B2B2' : '#000000' }}>
            <FormattedMessage id={this.usageKey} />
          </div>
        )}
      </span>
    );
  }

  content(withLink = false) {
    const { vehicleRules, bookingViewMoreDetails, displayUsages, usageLeft } = this.props;

    return (
      <div className="vehicle-tile">
        <div className="p">{this.wrapLink(withLink, this.pic(), this.href)}</div>
        <div className="content">
          {this.wrapLink(
            withLink,
            <div className="vehicleInfo b">
              {this.vehicle.brand} {this.vehicle.model}
            </div>,
            this.href
          )}

          {this.wrapLink(withLink, <div className="vehicleInfo">{this.vehicle.registrationNumber}</div>, this.href)}

          {displayUsages && !usageLeft && (
            <div className="vehicleInfo vehicle-usage" style={{ backgroundColor: this.isNoUsage ? '#B2B2B2' : '#000000' }}>
              <FormattedMessage id={this.usageKey} />
            </div>
          )}
          {this.fuelInfo()}

          {bookingViewMoreDetails && (
            <div className="vehicle-tile-children">
              <div className="row-info">
                <span className="label">
                  <FormattedMessage id="booking_view_company_name" />
                </span>
                <span className="value">
                  <a href={this.companyHref}>{this.companyName}</a>
                </span>
              </div>
              {vehicleRules.vehicleCategory && (
                <div className="row-info">
                  <span className="label">
                    <FormattedMessage id="booking_view_category_name" />
                  </span>

                  <span className="value">
                    <a href={this.categoryPage}>{this.categoryName}</a>
                  </span>
                </div>
              )}
            </div>
          )}
          {this.children && <div className="vehicleInfo cost">{this.children}</div>}
        </div>
      </div>
    );
  }

  render() {
    if (this.props.nolink) {
      return this.content(false);
    } else return <span className="vp-vt">{this.content(true)}</span>;
  }
}

VehiclePlanningVehicleTile.displayName = 'VehiclePlanningVehicleTile';

VehiclePlanningVehicleTile.propTypes = {
  vehicle: T.object,
  displayUsages: T.bool,
  usageLeft: T.bool,
  displayGauge: T.bool,
  bookingViewMoreDetails: T.bool
};

VehiclePlanningVehicleTile = connect(state => ({
  vehicleRules: headerContractVehicleValuesSelector(state)
}))(VehiclePlanningVehicleTile);

export default VehiclePlanningVehicleTile;
