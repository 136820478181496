module.exports={
  "productName": "glide.io",
  "siteTitle": "glide.io",
  "siteIcon": "s3://brands/glide/resources/favicon.ico",
  "apiBaseUrl": "https://gwadmin-valid-glide.tech.rcimobility.com/api",
  "font": {
    "light": "s3://resources/fonts/roboto-light.woff",
    "regular": "s3://resources/fonts/roboto-regular.woff",
    "medium": "s3://resources/fonts/roboto-medium.woff",
    "bold": "s3://resources/fonts/roboto-bold.woff"
  },
  "theme": {
    "color": "#0871b9",
    "logo": {
      "header": "s3://brands/glide/resources/logo/light.svg",
      "sideMenu": "s3://brands/glide/resources/logo/dark.svg"
    },
    "classes": {
      ".loginBox": {
        "background-image": "url('s3://brands/glide/resources/logo/login.svg')",
        "background-size": "65px"
      }
    }
  }
}
