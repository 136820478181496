import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { getAddTitle } from '../../../../components/_v2/ColumnList/helpers';
import { showParentView } from '../../../../components/_v2/ColumnList/helpers';
import { showSuccessMsg } from '../../../../actions/flashMessage-actions';
import { getStore } from '../../../../store/all-store';
import { createHotlineRequest } from '../../../../actions/hotlines-actions';
import HotlineView from './index';

export class HotlineAdd extends Component {
  componentWillMount() {
    this.showParentView = showParentView.bind(this);

    this.handleCallback = () => {
      const { dispatch } = getStore();

      dispatch(createHotlineRequest()).then(() => {
        dispatch(showSuccessMsg('addHotline_create_hotline_success'));
        this.showParentView();
      });
    };
  }

  render() {
    return (
      <HotlineView
        inputRef={this.props.inputRef}
        title={getAddTitle(<FormattedMessage id="hotlines_add_button" />)}
        onClose={this.showParentView}
        onCallback={this.handleCallback}
      />
    );
  }
}

export default HotlineAdd;
