import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { customFieldsSelector, customFieldsType, customFieldsProps } from '../../selectors/all-selectors';
import { customFieldsTableViewParams, customFieldsTableBookingViewParams } from '../../constants/table-constants';
import TableView from '../TableView/TableView';
import { CUSTOM_FIELD_FORM_SUBSCRIPTION, CUSTOM_FIELD_FORM_BOOKING } from '../../constants/backend-constants';

function CustomFields({ fields, type, limitedAccess }) {
  return (
    <div>
      <div className="customFieldTableTitle">
        <FormattedMessage id="customField_lists_title" />
      </div>
      {type === CUSTOM_FIELD_FORM_BOOKING && fields[CUSTOM_FIELD_FORM_BOOKING] && (
        <div>
          <TableView
            limitedAccess={limitedAccess}
            content={fields[CUSTOM_FIELD_FORM_BOOKING]}
            params={customFieldsTableBookingViewParams}
          />
        </div>
      )}
      {type === CUSTOM_FIELD_FORM_SUBSCRIPTION && fields[CUSTOM_FIELD_FORM_SUBSCRIPTION] && (
        <div>
          <TableView limitedAccess={limitedAccess} content={fields[CUSTOM_FIELD_FORM_SUBSCRIPTION]} params={customFieldsTableViewParams} />
        </div>
      )}
    </div>
  );
}

CustomFields.displayName = 'CustomFields';

const ConnectedCustomFields = connect(
  state => ({
    fields: customFieldsSelector(state)
  }),
  null,
  (propsFromState, propsFromDispatch, ownProps) => ({
    limitedAccess: ownProps.limitedAccess,
    fields: customFieldsProps(propsFromState),
    type: customFieldsType(ownProps)
  })
)(CustomFields);

export default ConnectedCustomFields;
