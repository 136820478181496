// TODO: implement actions functionality, that is commented after voucher realease
import React, { Component, PropTypes } from 'react';
import cs from 'classnames';
import _get from 'lodash/get';
import IconEvent from 'material-ui/svg-icons/notification/event-note';
import routes from '../../../constants/routes-constants';
import { addOpenNewTabEvents, hideButtonHoverMsg } from '../../../utils/utils';

class MemberResultsRow extends Component {
  constructor(props) {
    super(props);
    this.handleGoToDetail = this.handleGoToDetail.bind(this);
    this.handleGoToBookings = this.handleGoToBookings.bind(this);
  }

  componentWillUnmount() {
    hideButtonHoverMsg();
  }

  handleGoToDetail(openInNewTab) {
    this.props.handleGoToDetail(routes.memberDetail.path, ':memberId', this.props.member.id, openInNewTab);
  }

  handleGoToBookings(openInNewTab) {
    const { member } = this.props;
    const linkParams = {
      page: {
        number: 1,
        size: 50
      },
      sort: {
        property: 'START_DATE',
        direction: 'DESC'
      },
      memberLogin: _get(member, 'login')
    };
    this.props.handleGoToBookings(routes.bookings.path, linkParams, openInNewTab);
  }

  render() {
    const { member } = this.props;

    return (
      <div key={member.id} className={cs('search-resultRow', 'member-row')}>
        <div {...addOpenNewTabEvents(this.handleGoToDetail)} className="search-resultIcon">
          {member.firstName.charAt(0).toUpperCase()}
        </div>
        <div {...addOpenNewTabEvents(this.handleGoToDetail)} className="search-resultCol bold">
          {member.firstName}
          <span className="search-spacer"> </span>
          {member.lastName}
        </div>
        <div className="search-resultCol" {...addOpenNewTabEvents(this.handleGoToDetail)}>
          {member.login}
        </div>
        <div className="search-resultCol" {...addOpenNewTabEvents(this.handleGoToDetail)}>
          {member.superCompanyName}
        </div>
        <div className={cs('search-resultIcon', 'uncolor', 'icon-button')} {...addOpenNewTabEvents(this.handleGoToBookings)}>
          <IconEvent />
        </div>
      </div>
    );
  }
}

MemberResultsRow.displayName = 'MemberResultsRow';

MemberResultsRow.propTypes = {
  member: PropTypes.object,
  handleGoToDetail: PropTypes.func,
  handleGoToBookings: PropTypes.func
};

export default MemberResultsRow;
