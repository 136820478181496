import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { getEditTitle } from '../../../../components/_v2/ColumnList/helpers';
import { showParentView } from '../../../../components/_v2/ColumnList/helpers';
import { connect } from 'react-redux';
import CategoryView from './index';
import { updateCategoryRequest } from '../../../../actions/categories-actions';
import { userRoleSelector } from '../../../../selectors/all-selectors';
import { checkRole } from '../../../../constants/backuser-role-rules';
import { editCategoriesRules } from '../../../../constants/routes-constants';

export class VehicleEditCategory extends Component {
  componentWillMount() {
    this.setVars();
    this.setCallbacks();
  }

  setVars() {
    this.limitedAccess = !checkRole(editCategoriesRules, this.props.role);
  }

  setCallbacks() {
    this.showParentView = showParentView.bind(this);

    this.handleCallback = data => {
      this.props.dispatch(updateCategoryRequest(data));
    };
  }

  render() {
    return (
      <CategoryView
        inputRef={this.props.inputRef}
        title={getEditTitle(<FormattedMessage id="editVehiclesCategory_title" />)}
        fieldLabel={<FormattedMessage id="vehiclesCategoryForm_name_label" />}
        onClose={this.showParentView}
        onCallback={this.handleCallback}
        initialValues={this.props.data}
        limitedAccess={this.limitedAccess}
      />
    );
  }
}

const mapStateToProps = state => {
  const { currentCategory } = state.categories;
  return { data: currentCategory, role: userRoleSelector(state) };
};

VehicleEditCategory = connect(mapStateToProps)(VehicleEditCategory);

export default VehicleEditCategory;
