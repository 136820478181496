import { UPDATE_LOCATION } from 'react-router-redux';
import { SMARTCARDS_EVENT_USER_ASSIGN_ERROR } from '../constants/actionTypes-constants';
import * as types from '../constants/actionTypes-constants';

const initialState = {
  paginatedResults: {},
  currentSortedIndex: null,
  sortIsDescending: false,
  urlParams: null,
  filtersFormIsOpen: false,
  errorMemberSelection: false,
  loadingList: true
};

const reducerType = 'smartcardsEvents';

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case types[reducerType].GET_LIST_SUCCESS:
      return {
        ...state,
        paginatedResults: action.paginatedObj,
        urlParams: action.urlParams,
        loadingList: false
      };

    case types[reducerType].GET_LIST_REQUEST:
      return {
        ...state,
        loadingList: true
      };

    case UPDATE_LOCATION:
      return {
        ...state,
        currentSortedIndex: null,
        sortIsDescending: false
      };

    case types[reducerType].FILTERS_FORM_OPENED_STATE_TOGGLE:
      return {
        ...state,
        filtersFormIsOpen: !state.filtersFormIsOpen
      };

    case types[reducerType].SET_CURRENT_SORTED_INDEX:
      return {
        ...state,
        currentSortedIndex: action.index
      };

    case types[reducerType].SET_SORT_DIRECTION:
      return {
        ...state,
        sortIsDescending: action.isDescending
      };

    case SMARTCARDS_EVENT_USER_ASSIGN_ERROR:
      return {
        ...state,
        errorMemberSelection: true
      };
    default:
      return state;
  }
}
