import React, { Component, PropTypes } from 'react';
import { connect } from 'react-redux';
import { routeActions } from 'react-router-redux';
import BackLink from '../../components/BackLink/BackLink';

import CompanyForm from '../../components/CompanyForm/CompanyForm';
import { createCompanyRequest, addFlashMessage, clearDetailCompany, createCompanyModalSuccess } from '../../actions/all-actions';
import { FLASH_MESSAGE_TYPE_SUCCESS } from '../../constants/generic-constants';
import routes from '../../constants/routes-constants';
import { apiParams } from '../../constants/api-params-constants';
import { bundleSelector } from '../../selectors/all-selectors';
import { addErrorMessage, createCompanyDevUrls } from '../../utils/utils';
import _values from 'lodash/values';
import { partialErrorCodes } from '../../constants/options-constants';
import { COMPANY_ACCESS_PRIVATE, TEMPLATE_EMAIL_DEFAULT } from '../../constants/backend-constants';

class AddCompany extends Component {
  constructor(props) {
    super(props);
    this.setVars();
    this.bindCallbacks();
  }

  bindCallbacks() {
    this.handleCreateCompany = this.handleCreateCompany.bind(this);
    this.cancelAction = this.cancelAction.bind(this);
  }

  setVars() {
    this.companyDevUrls = createCompanyDevUrls() || {};
  }

  componentWillMount() {
    this.props.dispatch(clearDetailCompany());
  }

  handleCreateCompany() {
    const { dispatch, urlParams, bundle } = this.props;
    let params = urlParams ? { page: urlParams.page } : apiParams.default;

    dispatch(createCompanyRequest()).then(
      company => {
        dispatch(
          addFlashMessage({
            contentKey: 'companyForm_create_company_success',
            type: FLASH_MESSAGE_TYPE_SUCCESS,
            delayToNextRoute: true
          })
        );

        if (this.props.isModal) {
          dispatch(createCompanyModalSuccess(company));
        } else {
          dispatch(routeActions.push(routes.companies.path.replace(':search', encodeURIComponent(JSON.stringify(params)))));
        }

        // should be called last
        if (this.props.onSubmit) {
          this.props.onSubmit();
        }
      },
      error => {
        const partialErrors = _values(partialErrorCodes.phoneNumbers);
        const msgParams = { bundle, error, partialErrors };
        dispatch(addErrorMessage(msgParams));
      }
    );
  }

  getBackLink() {
    const params = this.props.urlParams || apiParams.default;
    return `/#${routes.companies.path.replace(':search', encodeURIComponent(JSON.stringify(params)))}`;
  }

  cancelAction() {
    if (this.props.onCancel) {
      return this.props.onCancel();
    }

    const params = this.props.urlParams || apiParams.default;
    const cancelPath = routes.companies.path.replace(':search', encodeURIComponent(JSON.stringify(params)));

    this.props.dispatch(routeActions.push(cancelPath));
  }

  render() {
    return (
      <div className="addCompanyPage mainContainer_content">
        <div className="pageContainer">
          {!this.props.isModal && <BackLink link={this.getBackLink()} labelKey="back_link_super-companies_list" />}
          <div className="addCompanyPage_content">
            <section className="addCompanyPage_management">
              <CompanyForm
                readOnlyBrands={this.props.readOnlyBrands}
                onCallback={this.handleCreateCompany}
                onCancel={this.cancelAction}
                initialValues={{
                  invoiceDelegate: 'false',
                  identityDocumentRequired: 'false',
                  employerCertificateRequired: 'false',
                  startBookingDamageReportMandatory: 'true',
                  endBookingDamageReportMandatory: 'false',
                  damageReportPhotos: 'false',
                  billBeforeBooking: 'false',
                  pricesIncludingTaxes: 'true',
                  vehicleReassignmentDelayInMin: '0',
                  websiteConfirmSubscriptionUrl: this.companyDevUrls.frontConfirmSubscription,
                  websiteResetPasswordUrl: this.companyDevUrls.frontResetPassword,
                  backofficeResetPasswordUrl: this.companyDevUrls.backResetPassword,
                  drivingLicenceRequired: 'true',
                  sendDrivingAuthorisation: 'false',
                  deleteMemberDocumentsOnApproval: 'false',
                  expediteReview: 'false',
                  sendEmailsToManager: 'false',
                  templateGroup: TEMPLATE_EMAIL_DEFAULT,
                  enableBookingDamageSafetyDeposit: 'false',
                  enableBookingPriceSafetyDepositBuffer: 'false',
                  currency: 'EUR',
                  paymentProvider: 'WORLDPAY',
                  access: COMPANY_ACCESS_PRIVATE,
                  ...this.props.initialValues
                }}
              />
            </section>
          </div>
        </div>
      </div>
    );
  }
}

AddCompany.displayName = 'AddCompany';

AddCompany.propTypes = {
  isModal: PropTypes.bool,
  initialValues: PropTypes.object,
  readOnlyBrands: PropTypes.bool,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func
};

export default connect(state => {
  return {
    urlParams: state.companies.urlParams,
    bundle: bundleSelector(state)
  };
})(AddCompany);
