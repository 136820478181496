import React, { Component, PropTypes } from 'react';
import ListDetails from '../../../../components/_v2/ColumnList/Details';
import BoxedInput from '../../../../components/BoxedInput/BoxedInput';
import FieldErrorMsg from '../../../../components/FieldErrorMsg/FieldErrorMsg';
import FormRow from '../../../../components/FormRow/FormRow';
import { reduxForm } from 'redux-form';
import { validateHotlines } from '../../../../validation/sync-validation';

import { RaisedButton } from 'material-ui';
import { FormattedMessage } from 'react-intl';
import { fallbackFunc } from '../../../../utils/utils';
import _partial from 'lodash/partial';
import FlatButton from 'material-ui/FlatButton';
import PhoneNumberField from '../../../../components/PhoneNumberField/PhoneNumberField';

export class HotlineView extends Component {
  constructor(props) {
    super(props);
    this.handleAddHotlineNumber = this.handleAddHotlineNumber.bind(this);
    this.handleRemoveHotlineNumber = this.handleRemoveHotlineNumber.bind(this);
  }

  componentWillReceiveProps(props) {
    this.callback = props.handleSubmit(this.props.onCallback);
  }

  handleAddHotlineNumber(event) {
    event.preventDefault();
    this.props.fields.phoneNumbers.addField({});
  }

  handleRemoveHotlineNumber(index, event) {
    event.preventDefault();
    this.props.fields.phoneNumbers.removeField(index);
  }

  render() {
    const {
      fields: { name, customerServiceName, customerServiceEmail, noReplyEmail, phoneNumbers }
    } = this.props;

    return (
      <ListDetails title={this.props.title} onClose={this.props.onClose}>
        <form className="sc-details-form" onSubmit={this.callback}>
          <div>
            <BoxedInput inputRef={this.props.inputRef} labelKey="hotlineForm_name_label" field={name} maxlength="255" type="text">
              <FieldErrorMsg field={name} />
            </BoxedInput>
          </div>
          <div>
            <BoxedInput labelKey="hotlineForm_customer_service_name_label" field={customerServiceName} maxlength="255" type="text">
              <FieldErrorMsg field={customerServiceName} />
            </BoxedInput>
          </div>
          <div>
            <BoxedInput labelKey="hotlineForm_customer_service_email_label" field={customerServiceEmail} maxlength="255" type="text">
              <FieldErrorMsg field={customerServiceEmail} />
            </BoxedInput>
          </div>
          <div>
            <BoxedInput labelKey="hotlineForm_no_reply_email_label" field={noReplyEmail} maxlength="255" type="text">
              <FieldErrorMsg field={noReplyEmail} />
            </BoxedInput>
          </div>
          <div className="phone-numbers">
            {phoneNumbers.map((item, index) => {
              const key = 'line' + index;
              return (
                <div key={key} className="hotline-entry">
                  <PhoneNumberField
                    id="phoneNumber"
                    className="boxedInputWrapper"
                    label={<FormattedMessage id="hotlineForm_phone_number_label" />}
                    field={item.phoneNumber}
                  />

                  <BoxedInput
                    type="text"
                    id="phoneNumberName"
                    placeholderKey="hotlineForm_phone_number_name_label"
                    maxlength="255"
                    customClass="boxedInputWrapper--name extra"
                    field={item.phoneNumberName}
                  />

                  <FlatButton primary className="main-bg white-text" onClick={_partial(this.handleRemoveHotlineNumber, index)}>
                    ✕
                  </FlatButton>
                </div>
              );
            })}
          </div>
          <FormRow>
            <button className="ekButton extra" onClick={this.handleAddHotlineNumber}>
              <FormattedMessage id="hotlineForm_phone_number_add" />
            </button>
          </FormRow>
          <div className="sc-actions">
            <RaisedButton label={this.props.submitLabel} primary type="button" onClick={this.callback} />
          </div>
        </form>
      </ListDetails>
    );
  }
}

HotlineView.defaultProps = {
  onCallback: fallbackFunc,
  submitLabel: <FormattedMessage id="common_save" />
};

HotlineView.propTypes = {
  title: PropTypes.any,
  inputRef: PropTypes.func,
  onClose: PropTypes.func,
  submitLabel: PropTypes.any,
  onCallback: PropTypes.func
};

HotlineView = reduxForm({
  form: 'hotlineForm',
  touchOnBlur: false,
  fields: [
    'name',
    'customerServiceName',
    'customerServiceEmail',
    'noReplyEmail',
    'phoneNumbers[]',
    'phoneNumbers[].phoneNumber',
    'phoneNumbers[].phoneNumberName'
  ],
  validate: validateHotlines()
})(HotlineView);

export default HotlineView;
