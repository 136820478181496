import { createSelector } from 'reselect';
import _get from 'lodash/get';
import _map from 'lodash/map';
import _filter from 'lodash/filter';
import _forEach from 'lodash/forEach';
import _cloneDeep from 'lodash/cloneDeep';
import memoizeOne from 'memoize-one';
import _isEmpty from 'lodash/isEmpty';
import { bankoutFrequencyOptions, otherReportingFields, quickSightTabFields, vehicleUsageTypes } from '../constants/options-constants';
import _memoize from 'lodash/memoize';
import { vehicleUsages } from '../constants/filters-constants';
import { createBookingFilterTypes } from '../constants/filterTypes-constants';

import {
  BE_ALL,
  BOOKING_TYPE_RIDE_SHARING,
  BOOKING_USAGE_TYPE_PRIVATE,
  CUSTOM_FIELD_CONDITIONED,
  CUSTOM_FIELD_FORM_BOOKING,
  CUSTOM_FIELD_FORM_SUBSCRIPTION,
  CUSTOM_FIELD_TYPE_FILE,
  CUSTOM_FIELD_YES,
  FREQUENCY_DAILY,
  FREQUENCY_WEEKLY,
  SCHEDULED_EXPORT,
  SORT_PROPERTY_EMAIL,
  SORT_PROPERTY_FIRSTNAME,
  SORT_PROPERTY_LASTNAME,
  SORT_PROPERTY_MEMBER_TYPE,
  VEHICLE_USAGE_TYPE_DELIVERY,
  VEHICLE_USAGE_TYPE_IMMEDIATE,
  VEHICLE_USAGE_TYPE_PLANNED,
  VEHICLE_USAGE_TYPE_PUBLIC_SERVICE,
  VEHICLE_USAGE_TYPE_RV,
  VEHICLE_USAGE_TYPE_TEST_DRIVE
} from '../constants/backend-constants';

import {
  createCompanyDevUrls,
  getCurrentEnv,
  getThemeDomains,
  isValidId,
  round1decimal,
  safe,
  setBase64,
  streetNameFormat,
  trySet,
  mapFilterValueFromObject,
  jsonParseSafe,
  isTestEnv,
  fallbackObj,
  fallbackArray,
  getBookingInfo
} from '../utils/utils';

import {
  ALL,
  brandIdToThemeMap,
  contractInitialValues,
  delayedBookingStatuses,
  MEMBER_SOURCE_EMAIL,
  MEMBER_SOURCE_NAME,
  SAFE_LOCALE_MAP,
  vpBusinessUsageGroup,
  vpDealerUsageGroup
} from '../constants/generic-constants';

import { checkRole, quickSightAllAccessRoles, subCompanyListRules, subCompanySelectRules } from '../constants/backuser-role-rules';
import merge from 'merge-deep';

// end with '...Selector' when selecting from 'state'
// do not use {} or [] on selectors return
// it recreates an object everytime and triggers re-render

export const subCompaniesListSelector = state => state.subCompanies.subList || fallbackObj;
export const subCompanyListLoadingSelector = state => state.subCompanies.subListLoading;

export const subCompaniesByIdObjectSelector = createSelector(subCompaniesListSelector, subList =>
  subList.reduce((allSubcompanies, current) => {
    allSubcompanies[current.id] = current;
    return allSubcompanies;
  }, {})
);

const companySubscriptionCustomFieldsSelector = state => {
  return safe(() => state.customFields.customFields[CUSTOM_FIELD_FORM_SUBSCRIPTION]);
};

const memberRawCustomFieldsSelector = state => safe(() => state.members.customFields);

export const memberCustomFieldsSelector = createSelector(
  companySubscriptionCustomFieldsSelector,
  memberRawCustomFieldsSelector,
  (companyFields, memberFields) => {
    if (!companyFields) return null;
    if (!memberFields) memberFields = [];
    const visibleCompanyFields = companyFields.filter(field => {
      return field.visible;
    });
    const visibleMemberFields = memberFields.filter(field => {
      return _get(field, 'companyCustomField.visible');
    });
    const selectedCustomFields = visibleCompanyFields.map(companyField => {
      const newField = {};

      newField.companyCustomField = companyField;
      visibleMemberFields.forEach((memberField, index, object) => {
        if (companyField.id === _get(memberField, 'companyCustomField.id')) {
          if (memberField.value) newField.value = memberField.value;
          object.splice(index, 1); // delete item when found
        }
      });
      return newField;
    });

    return selectedCustomFields.sort((a, b) => {
      return _get(a, 'companyCustomField.position', 0) - _get(b, 'companyCustomField.position', 0);
    });
  }
);

export const localeSelector = state => state.i18n.locale || 'en';
export const vehicleStatusSelector = state => safe(() => state.vehicles.deviceStatus) || fallbackObj;
export const bookingDetailsSelector = state => state.bookings.bookingDetail || fallbackObj;
export const serialNumberSelector = state => safe(() => state.bookings.bookingDetail.vehicle.device.serialNumber) || fallbackObj;
export const automaticPaymentRetrySekector = state =>
  safe(() => state.bookings.bookingDetail.carSharingInfo.automaticPaymentRetry) || fallbackObj;
export const detailedCompanySelector = state => safe(() => state.companies.detailCompany) || fallbackObj;
export const accountStripeCompanySelector = state => safe(() => state.companies.connectedAccount) || fallbackObj;
export const accountStripeSubCompanySelector = state => safe(() => state.subCompanies.connectedAccount) || fallbackObj;

export const userInfoSelector = state => state.user.userInfo || fallbackObj;
export const userRoleSelector = createSelector(userInfoSelector, info => info.role);
export const userCompaniesSelector = createSelector(userInfoSelector, info => info.companies || fallbackArray);
export const userIdSelector = createSelector(userInfoSelector, info => info.id);
export const userLoggedInSelector = userIdSelector;
export const userEmailSelector = createSelector(userInfoSelector, info => info.login);

export const currentCompanySelector = state => state.companies.currentCompany || fallbackObj;

export const currentCompanyIdSelector = state => {
  return currentCompanySelector(state).id || ALL;
};

export const currentSubCompanyIdSelector = state => {
  const { id } = state.subCompanies.subCompanySelected || {};
  return id || ALL;
};

export const validCompanyIdSelector = createSelector(currentCompanyIdSelector, id => {
  if (isValidId(id)) return id;
});

export const validSubCompanyIdSelector = createSelector(currentSubCompanyIdSelector, id => {
  if (isValidId(id)) return id;
});

export const userSubCompaniesSelector = state => {
  const { subCompanies } = state.user.userInfo || [];
  return subCompanies;
};

export const headerCompanyListSelector = state => state.companies.headerList || fallbackArray;
export const headerCompanyListLoadingSelector = state => state.companies.headerListLoading;

export const headerSubCompanyListSelector = state => state.subCompanies.headerSubList || fallbackArray;

export const userCompanyIdsSelector = createSelector(userCompaniesSelector, (userCompanies = []) =>
  userCompanies.map((item = {}) => item.id)
);

export const userHeaderCompaniesSelector = createSelector(
  userCompaniesSelector,
  headerCompanyListSelector,
  (userCompanies = [], headerCompanies = []) => {
    return _isEmpty(headerCompanies) ? userCompanies : headerCompanies;
  }
);

export const userHeaderSubCompaniesSelector = createSelector(
  userSubCompaniesSelector,
  validCompanyIdSelector,
  headerSubCompanyListSelector,
  (userSubCompanies, currentSuperCompanyId, headerSubCompanies) => {
    if (currentSuperCompanyId) return headerSubCompanies;
    else return userSubCompanies;
  }
);

export const userHeaderSubCompanyIdsSelector = createSelector(userHeaderSubCompaniesSelector, (subCompanies = []) =>
  subCompanies.map((item = {}) => item.id)
);

export const customFieldsSelector = state => state.customFields.customFields;
export const customFieldsSubscriptionSelector = state => companySubscriptionCustomFieldsSelector(state);
export const pickedFieldSelector = (state, props) =>
  props.formType === CUSTOM_FIELD_FORM_SUBSCRIPTION ? state.customFields.subscriptionPicked : state.customFields.bookingPicked;
export const customFieldsType = props => props.type;
export const customFieldsProps = props => props.fields;

export const carSharingCustomFieldsSelector = state => _get(state, `customFields.customFields.${CUSTOM_FIELD_FORM_BOOKING}`);
export const currentBookingSelector = state => _get(state, 'booking.selectedVehicle');
export const planningCurrentBookingSelector = state => _get(state, 'form.VehiclePlanningCreateBooking');
export const filterVisible = field => _get(field, 'visible', '') === CUSTOM_FIELD_YES;

export const selectFieldIdsByPosition = _memoize((customFields = []) => {
  const ret = {};

  customFields.forEach(field => {
    const { companyCustomField } = field || {};
    const { id, position } = companyCustomField || {};
    ret[position] = id;
  });

  return ret;
});

export const selectCustomFieldsNames = _memoize((customFields = []) => {
  return customFields.map(field => {
    const { companyCustomField } = field || {};
    const { id } = companyCustomField || {};
    return id;
  });
});

export const customFieldsFilesSelector = state => state.members.customFieldsFiles;

export const selectCustomFieldsInitialValues = memoizeOne((fields = [], files = {}) => {
  const ret = {};

  fields.forEach(field => {
    const { value, companyCustomField } = field || {};
    const { id, fieldType } = companyCustomField || {};

    if (value) {
      if (fieldType === CUSTOM_FIELD_TYPE_FILE) {
        const fileValue = files[value];

        const base64 = setBase64(fileValue);
        if (base64) return (ret[id] = base64);

        return (ret[id] = fileValue);
      }
      ret[id] = value;
    }
  });
  return ret;
});

const fieldStatusVisible = field => {
  const { visible: visibility } = field || {};

  return visibility === CUSTOM_FIELD_YES || visibility === CUSTOM_FIELD_CONDITIONED;
};

const fieldUsageVisible = (field, usageType) => {
  const { usageType: fieldType } = field || {};

  if (!usageType || !fieldType) return true;
  if (fieldType === BE_ALL) return true;
  if (fieldType === usageType) return true;
};

const parseCustomFields = (companyFields = [], fieldsValues = [], usageType) => {
  const visibleCompanyFields = companyFields.filter(field => {
    return fieldStatusVisible(field) && fieldUsageVisible(field, usageType);
  });

  const flatCopyMemberValues = [...fieldsValues];

  const selectedCustomFields = visibleCompanyFields.map(companyField => {
    const newField = { companyCustomField: companyField };

    flatCopyMemberValues.forEach((memberField, index, object) => {
      const { id: companyFieldId } = companyField || {};
      const { companyCustomField, value: memberValue } = memberField || {};
      const { id: memberFieldId } = companyCustomField || {};

      if (companyFieldId === memberFieldId) {
        newField.value = memberValue;
        object.splice(index, 1); // delete item when found
      }
    });

    return newField;
  });

  const getFieldPosition = field => {
    const { companyCustomField } = field || {};
    const { position } = companyCustomField || {};
    return position || 0;
  };

  return selectedCustomFields.sort((a, b) => getFieldPosition(a) - getFieldPosition(b));
};

export const profileCustomFieldsSelector = createSelector(
  companySubscriptionCustomFieldsSelector,
  memberRawCustomFieldsSelector,
  parseCustomFields
);

export const registerCustomFieldsSelector = createSelector(companySubscriptionCustomFieldsSelector, parseCustomFields);
export const planningEditBookingSelector = state => state.vehiclePlanning.bookingDetail;

export const bookingCustomFieldsEditValuesSelector = state => {
  let customFieldsValues = {};

  _get(state, 'booking.editionMode') &&
    _map(_get(state, 'bookings.bookingDetail.bookingCustomValues'), values => {
      if (_get(values, 'companyCustomField.id') && _get(values, 'value')) customFieldsValues[values.companyCustomField.id] = values.value;
    });
  return customFieldsValues;
};

export const planningBookingCustomFieldsEditValuesSelector = state => {
  let customFieldsValues = {};

  _get(state, 'vehiclePlanning.openedEditBookingModal') &&
    _map(_get(state, 'vehiclePlanning.bookingDetail.bookingCustomValues'), values => {
      if (_get(values, 'companyCustomField.id') && _get(values, 'value')) customFieldsValues[values.companyCustomField.id] = values.value;
    });
  return customFieldsValues;
};

export const bookingCustomFieldsSelector = createSelector(carSharingCustomFieldsSelector, currentBookingSelector, (fields, booking) => {
  const visibleFields = _filter(fields, filterVisible);
  const bookingUsageType = _get(booking, 'carSharingInfo.usageType');
  const bookingType = _get(booking, 'type');

  if (bookingType === BOOKING_TYPE_RIDE_SHARING || !bookingUsageType) return [];

  return _filter(visibleFields, field => {
    const fieldUsageType = _get(field, 'usageType') === BE_ALL ? bookingUsageType : _get(field, 'usageType');
    return fieldUsageType === bookingUsageType;
  });
});

export const planningBookingCustomFieldsSelector = createSelector(
  carSharingCustomFieldsSelector,
  planningCurrentBookingSelector,
  (fields, booking) => {
    const visibleFields = _filter(fields, filterVisible);
    const bookingUsageType = _get(booking, 'usageType.value');

    if (!bookingUsageType) return [];

    return _filter(visibleFields, field => {
      const fieldUsageType = _get(field, 'usageType') === BE_ALL ? bookingUsageType : _get(field, 'usageType');
      return fieldUsageType === bookingUsageType;
    });
  }
);

export const planningBookingEditCustomFieldsSelector = createSelector(
  carSharingCustomFieldsSelector,
  planningEditBookingSelector,
  (fields, booking) => {
    const visibleFields = _filter(fields, filterVisible);
    const bookingUsageType = _get(booking, 'carSharingInfo.usageType');

    if (!bookingUsageType) return [];

    return _filter(visibleFields, field => {
      const fieldUsageType = _get(field, 'usageType') === BE_ALL ? bookingUsageType : _get(field, 'usageType');
      return fieldUsageType === bookingUsageType;
    });
  }
);

function changeParkingHours(slot) {
  const timeIntervals = _get(slot, 'items');
  const nightMinutes = '59';
  const nightHours = '23';
  const replace = '00';

  _forEach(timeIntervals, interval => {
    const endHours = _get(interval, 'endHours');
    const endMinutes = _get(interval, 'endMinutes');

    if (endHours === nightHours && endMinutes === nightMinutes) {
      interval.endHours = replace;
      interval.endMinutes = replace;
    }
  });
}

function adjustParkingTime(params) {
  const newParams = { ...params }; // create new object | preserv pointers
  const specialName = 'specialOpeningHours';
  const weeklyName = 'weeklyOpeningHours';
  const specialHours = _cloneDeep(_get(params, specialName)); // clone hours data
  const weeklyHours = _cloneDeep(_get(params, weeklyName)); // clone hours data

  _forEach(specialHours, changeParkingHours);
  _forEach(weeklyHours, changeParkingHours);

  trySet(newParams, specialName, specialHours); // re-assign pointers for newParams
  trySet(newParams, weeklyName, weeklyHours); // re-assign pointers for newParams

  return newParams;
}

export const currentParkingSelector = createSelector(state => state.parkings.currentParking, adjustParkingTime);

export const bundleSelector = state => state.i18n.bundle || fallbackObj;

export const redirectLocationSelector = state => state.api.redirectLocation;

export const adjustAddressFormatted = createSelector(
  company => company,
  company => {
    let address = _get(company, 'address');
    const streetNumber = _get(address, 'streetNumber');
    const streetName = _get(address, 'streetName');

    if (streetName) {
      return {
        ...company,
        address: {
          ...address,
          formattedAddress: streetNameFormat(streetNumber, streetName)
        }
      };
    }

    return company;
  }
);

export const currentSubCompanySelector = state => state.subCompanies.currentSubCompany || fallbackObj;
export const currentCompanyContractSelector = state => state.companies.currentContract || fallbackObj;

const currentInvoiceParamsSubCompanySelector = state => _get(state, 'subCompanies.detailInvoiceParams');
export const adjustInvoiceAddressFormatted = createSelector(
  currentSubCompanySelector,
  currentInvoiceParamsSubCompanySelector,
  (subCompany, invoiceParams) => {
    let address = _get(invoiceParams, 'invoicingAddress');
    if (!address) address = _get(subCompany, 'address');
    const streetNumber = _get(address, 'streetNumber');
    const streetName = _get(address, 'streetName');

    return {
      ...invoiceParams,
      invoicingAddress: {
        ...address,
        formattedAddress: streetNameFormat(streetNumber, streetName)
      }
    };
  }
);

const createSubscribeUrl = (otherUrl, companyId) => {
  return safe(() => {
    const url = new URL(otherUrl);
    return `https://${url.hostname}/creation/${companyId}`;
  });
};

export const subscriptionUrlSelector = createSelector(detailedCompanySelector, ({ id, websiteConfirmSubscriptionUrl, brand } = {}) => {
  let url;

  if (id && websiteConfirmSubscriptionUrl) {
    url = createSubscribeUrl(websiteConfirmSubscriptionUrl, id);
  }

  if (!url && safe(() => brand.id) && id) {
    const theme = safe(() => brandIdToThemeMap[this.currentEnv][brand.id]);
    const devUrls = createCompanyDevUrls(getThemeDomains(getCurrentEnv(), theme));

    url = createSubscribeUrl(
      safe(() => devUrls.frontConfirmSubscription),
      id
    );
  }

  return url;
});

export const vpFirstHourSelector = state => state.vehiclePlanning.firstHourSelected || fallbackObj;
export const vpFirstDaySelector = state => state.vehiclePlanning.firstDaySelected || fallbackObj;

export const vpFirstDataSelector = createSelector(vpFirstHourSelector, vpFirstDaySelector, (hour, day) => {
  return Object.keys(hour).length ? hour : day;
});

export const vpAddBookingVehicleUsageSelector = createSelector(vpFirstDataSelector, firstData => {
  const { vehicle } = firstData || {};
  const { usage } = vehicle || {};
  return usage;
});

const vpGetOptionsFromUsage = vehicleUsage => {
  const visibleUsages = vpDealerUsageGroup.includes(vehicleUsage) ? vpDealerUsageGroup : vpBusinessUsageGroup;

  return vehicleUsageTypes.reduce((arrayOfUsageObjs, usageObj) => {
    if (visibleUsages.includes(usageObj.key)) {
      arrayOfUsageObjs.push({
        labelKey: usageObj.label,
        value: usageObj.key
      });
    }
    return arrayOfUsageObjs;
  }, []);
};

export const vpAddBookingUsageOptionsSelector = createSelector(vpAddBookingVehicleUsageSelector, usage => vpGetOptionsFromUsage(usage));

export const vpEditBookingUsageOptionsSelector = createSelector(planningEditBookingSelector, bookingDetail => {
  const { vehicleUsageAtBookingCreation } = bookingDetail || {};
  return vpGetOptionsFromUsage(vehicleUsageAtBookingCreation);
});

const memberNameResultsSelector = state => state.searchResults.memberNameResults;
const memberEmailResultsSelector = state => state.searchResults.memberEmailResults;
const memberNameResultsTotalSelector = state => state.searchResults.memberNameResultsTotal;
const memberEmailResultsTotalSelector = state => state.searchResults.memberEmailResultsTotal;

export const memberResultsSelector = createSelector(
  memberEmailResultsSelector,
  memberNameResultsSelector,
  (memberEmailResults, memberNameResults) => (memberEmailResults.length ? memberEmailResults : memberNameResults)
);
export const memberResultsTotalSelector = createSelector(
  memberEmailResultsTotalSelector,
  memberNameResultsTotalSelector,
  (memberEmailResultsTotal, memberNameResultsTotal) => memberEmailResultsTotal || memberNameResultsTotal
);

export const membersSourceSelector = createSelector(memberEmailResultsSelector, memberEmailResults =>
  memberEmailResults.length ? MEMBER_SOURCE_EMAIL : MEMBER_SOURCE_NAME
);

export const memberTypeListSeletor = state => state.memberTypes.list || fallbackArray;
export const memberTypeListLoadingSeletor = state => state.memberTypes.loadingList;

export const selectBrandEditData = memoizeOne((brandId, brandList) => {
  return safe(() => brandList.find((brand = {}) => brand.id === brandId));
});

export const brandConfigSelector = state => state.appBrands.config;
export const brandThemeSelector = state => safe(() => brandConfigSelector(state).theme);
export const useNewHeaderSelector = state => safe(() => brandConfigSelector(state).theme.useNewHeader);
export const configFetchedSelector = state => state.appBrands.configFetched;
export const configErrorSelector = state => state.appBrands.configError;
export const rootClassesSelector = state => state.page.rootClasses;
export const routeLoadedSelector = state => state.appBrands.routeLoaded;
export const appBrandsSelector = state => state.appBrands.list;
export const vehicleBrandsSelector = state => state.brands.list;
export const vehicleBrandsLoadingSelector = state => state.brands.loading;
export const vehicleModelsSelector = state => state.models.list;
export const vehicleModelsLoadingSelector = state => state.models.loading;
export const voucherGroupsSelector = state => safe(() => state.vouchers.paginatedResults.results);
export const voucherGroupsFiltersSelector = state => safe(() => state.vouchers.filters);

export const vpFiltersSelector = state => safe(() => state.vehiclePlanning.filters);
export const vpSitesSelector = state => safe(() => state.vehiclePlanning.data.sites);
export const bookingsFiltersSelector = state => safe(() => state.bookings.filters);
export const scheduledExportListFiltersSelector = state => safe(() => state.exports.scheduledExportListFilters);
export const bookingsResultsSelector = state => safe(() => state.bookings.paginatedResults.results);

export const invoiceDetailsSelector = state => state.invoices.invoice || fallbackObj;
export const invoicesFiltersSelector = state => safe(() => state.invoices.filters);
export const invoicesResultsSelector = state => safe(() => state.invoices.paginatedResults.results);
export const invoicesTotalResultsSelector = state => safe(() => state.invoices.paginatedResults.metadata.paginationInfo.totalResults);
export const companiesTotalResultsSelector = state => safe(() => state.companies.paginatedResults.metadata.paginationInfo.totalResults);
export const configurationsTotalResultsSelector = state =>
  safe(() => state.configuration.paginatedResults.metadata.paginationInfo.totalResults);
export const bookingsTotalResultsSelector = state => safe(() => state.bookings.paginatedResults.metadata.paginationInfo.totalResults);
export const bookingsCurrentSizeSelector = state => state.bookings.currentSize;
export const bookingsLoadingSelector = state => state.bookings.loadingList;
export const invoicesLoadingSelector = state => state.invoices.loadingList;
export const companiesLoadingSelector = state => state.companies.loadingList;
export const companyResultsSelector = state => safe(() => state.companies.paginatedResults.results);

export const sitesListSelector = state => state.sites.list;
export const siteListLoadingSelector = state => state.sites.listLoading;

export const parkingsListSelector = state => state.parkings.list;

export const bookingStartMileageSelector = createSelector(bookingDetailsSelector, details => {
  const mileage = safe(() => details.carSharingInfo.startVehicleStatusData.mileage);
  if (mileage) return round1decimal(mileage);
});

export const bookingEndMileageSelector = createSelector(bookingDetailsSelector, details => {
  const mileage = safe(() => details.carSharingInfo.endVehicleStatusData.mileage);
  if (mileage) return round1decimal(mileage);
});

export const selectOptionsFromNames = memoizeOne((data = []) => {
  return data.map(item => ({ label: item.name, value: item.name }));
});

export const selectVehicleBrandsOptions = createSelector(vehicleBrandsSelector, data => selectOptionsFromNames(data));
export const selectVehicleModelsOptions = createSelector(vehicleModelsSelector, data => selectOptionsFromNames(data));

export const createNameToIdMap = memoizeOne((data = []) => {
  return data.reduce((r, v) => {
    safe(() => (r[v.name] = v.id));
    return r;
  }, {});
});

export const bookingsFilterTypesSelector = createSelector(vehicleBrandsSelector, vehicleModelsSelector, (brands, models) => {
  return createBookingFilterTypes(mapFilterValueFromObject(brands), mapFilterValueFromObject(models), vehicleUsages);
});

export const bookingsExportingSelector = state => !!state.bookings.exportingV3;
export const invoicesExportingSelector = state => !!state.invoices.exporting;

export const regionNamesSelector = createSelector(localeSelector, locale => {
  const lang = SAFE_LOCALE_MAP[locale] || locale;
  return safe(() => new Intl.DisplayNames([lang], { type: 'region' }));
});

export const bannerMessageSelector = state => state.appBrands.bannerMessage;
export const bannerStyleSelector = state => state.appBrands.bannerStyle || fallbackObj;
export const displayBannerSelector = state => state.appBrands.displayBanner;

const contractInitialValuesPredicate = initialValues => actualValues => {
  const values = { ...actualValues };

  for (const property in initialValues) {
    const value = values[property];
    const initialValue = initialValues[property];

    if (typeof value !== 'boolean') {
      values[property] = initialValue;
    }
  }

  return values;
};

export const detailMemberSelector = state => state.members.detailMember || fallbackObj;
export const detailMemberCompanySelector = createSelector(detailMemberSelector, m => m.company || fallbackObj);
export const detailMemberContractSelector = createSelector(detailMemberCompanySelector, c => c.contract || fallbackObj);
export const headerCompanyDetailsSelector = state => state.companies.currentCompanyDetails || fallbackObj;
export const headerCompanyContractSelector = createSelector(headerCompanyDetailsSelector, c => c.contract || fallbackObj);

// Do not use to filter UI elements (use 'initialValues' selectors below)
export const detailMemberContractReferenceObjectSelector = createSelector(detailMemberContractSelector, ({ reference }) => {
  return jsonParseSafe(reference) || fallbackObj;
});

// Do not use to filter UI elements (use 'initialValues' selectors below)
export const headerContractReferenceObjectSelector = createSelector(headerCompanyContractSelector, ({ reference }) => {
  return jsonParseSafe(reference) || fallbackObj;
});

// Do not use to filter UI elements (use 'initialValues' selectors below)
export const currentContractReferenceObjectSelector = createSelector(currentCompanyContractSelector, ({ reference }) => {
  return jsonParseSafe(reference) || fallbackObj;
});

const contractBookingRawValuesSelector = createSelector(headerContractReferenceObjectSelector, o => o.booking || fallbackObj);
const currentContractBookingRawValuesSelector = createSelector(currentContractReferenceObjectSelector, o => o.booking || fallbackObj);
const currentContractPaymentRawValuesSelector = createSelector(currentContractReferenceObjectSelector, o => o.payment || fallbackObj);

export const headerContractBookingValuesSelector = createSelector(
  contractBookingRawValuesSelector,
  contractInitialValuesPredicate(contractInitialValues.booking)
);

export const currentContractBookingValuesSelector = createSelector(
  currentContractBookingRawValuesSelector,
  contractInitialValuesPredicate(contractInitialValues.booking)
);

export const currentContractPaymentValuesSelector = createSelector(
  currentContractPaymentRawValuesSelector,
  contractInitialValuesPredicate(contractInitialValues.payment)
);

export const isSubCompanyLevelUserSelector = createSelector(userRoleSelector, role => {
  return checkRole(merge(subCompanyListRules, subCompanySelectRules), role);
});

export const userHaveOnlyOneSuperCompanySelector = createSelector(userCompaniesSelector, (userCompanies = []) => {
  return userCompanies.length === 1;
});

const contractMemberRawValuesSelector = createSelector(headerContractReferenceObjectSelector, o => o.member || fallbackObj);

export const headerContractMemberValuesSelector = createSelector(
  contractMemberRawValuesSelector,
  contractInitialValuesPredicate(contractInitialValues.member)
);

const contractPaymentRawValuesSelector = createSelector(headerContractReferenceObjectSelector, o => o.payment || fallbackObj);

export const headerContractPaymentValuesSelector = createSelector(
  contractPaymentRawValuesSelector,
  contractInitialValuesPredicate(contractInitialValues.payment)
);

const currentContractVehicleRawValuesSelector = createSelector(currentContractReferenceObjectSelector, o => o.vehicle || fallbackObj);
const contractVehicleRawValuesSelector = createSelector(headerContractReferenceObjectSelector, o => o.vehicle || fallbackObj);

export const currentContractVehicleValuesSelector = createSelector(
  currentContractVehicleRawValuesSelector,
  contractInitialValuesPredicate(contractInitialValues.vehicle)
);

export const headerContractVehicleValuesSelector = createSelector(
  contractVehicleRawValuesSelector,
  contractInitialValuesPredicate(contractInitialValues.vehicle)
);

const contractReportingRawValuesSelector = createSelector(headerContractReferenceObjectSelector, o => o.reporting || fallbackObj);

export const contractReportingSettingsValuesSelector = createSelector(
  contractReportingRawValuesSelector,
  contractInitialValuesPredicate(contractInitialValues.reporting)
);

export const headerContractReportingValuesSelector = createSelector(
  userRoleSelector,
  validCompanyIdSelector,
  contractReportingRawValuesSelector,
  (role, companyId, rawValues) => {
    if (!quickSightAllAccessRoles.includes(role) && companyId) {
      return contractInitialValuesPredicate(contractInitialValues.reporting)(rawValues);
    }
    return [...otherReportingFields, ...quickSightTabFields].reduce((acc, item) => {
      acc[item] = true;
      return acc;
    }, {});
  }
);

const detailMemberContractMemberRawValuesSelector = createSelector(
  detailMemberContractReferenceObjectSelector,
  o => o.member || fallbackObj
);

export const memberCompanyContractMemberValuesSelector = createSelector(
  detailMemberContractMemberRawValuesSelector,
  contractInitialValuesPredicate(contractInitialValues.member)
);

const legacyBookingVehicleResultsSelector = state => safe(() => state.bookingFindVehicle.paginatedResults.results) || fallbackArray;

export const selectLegacyBookingVehiclesList = createSelector(
  currentCompanyContractSelector,
  legacyBookingVehicleResultsSelector,
  ({ privateCarSharing, businessCarSharing }, results = []) => {
    if (!(privateCarSharing && businessCarSharing)) {
      return results.reduce((acc, result) => {
        const privateUsage = result.carSharingInfo.usageType === BOOKING_USAGE_TYPE_PRIVATE;

        if (privateCarSharing && privateUsage) {
          acc.push(result);
        }
        if (businessCarSharing && !privateUsage) {
          acc.push(result);
        }
        return acc;
      }, []);
    }
    return results;
  }
);

export const vehicleUsageTypesSelector = createSelector(
  headerContractVehicleValuesSelector,
  ({ usageImmediate, usagePlanned, testDrive, publicService, delivery, usageRV } = {}) => {
    return vehicleUsageTypes.reduce((result, current) => {
      if (current.key === VEHICLE_USAGE_TYPE_IMMEDIATE) {
        if (usageImmediate) {
          result.push(current);
        }
      } else if (current.key === VEHICLE_USAGE_TYPE_PLANNED) {
        if (usagePlanned) {
          result.push(current);
        }
      } else if (current.key === VEHICLE_USAGE_TYPE_RV) {
        if (usageRV) {
          result.push(current);
        }
      } else if (current.key === VEHICLE_USAGE_TYPE_TEST_DRIVE) {
        if (testDrive) {
          result.push(current);
        }
      } else if (current.key === VEHICLE_USAGE_TYPE_PUBLIC_SERVICE) {
        if (publicService) {
          result.push(current);
        }
      } else if (current.key === VEHICLE_USAGE_TYPE_DELIVERY) {
        if (delivery) {
          result.push(current);
        }
      } else {
        result.push(current);
      }
      return result;
    }, []);
  }
);

export const categoriesListSelector = state => state.categories.list || fallbackArray;
export const vehicleCategoriesSelector = state => state.vehicles.categories || fallbackArray;

export const pricingCategoriesSelector = createSelector(
  vehicleCategoriesSelector,
  headerContractVehicleValuesSelector,
  (categories, { vehicleCategory }) => {
    return vehicleCategory ? categories : [categories[0]];
  }
);

export const bankoutFrequencyOptionsSelector = createSelector(headerContractPaymentValuesSelector, ({ weeklyBankout, dailyBankout }) => {
  return bankoutFrequencyOptions.reduce((result, current) => {
    if (current.value === FREQUENCY_DAILY) {
      if (dailyBankout) {
        result.push(current);
      }
    } else if (current.value === FREQUENCY_WEEKLY) {
      if (weeklyBankout) {
        result.push(current);
      }
    } else {
      result.push(current);
    }
    return result;
  }, []);
});

export const currentV2InvoicesUrlSelector = state => state.invoices.listUrlV2;

export const membersAdvancedListHeaderSelector = createSelector(headerContractMemberValuesSelector, rules => {
  const header = [];

  header.push({
    labelKey: 'members_tableView_label_firstName',
    md: 2,
    xs: 2,
    sortable: true,
    sortId: SORT_PROPERTY_FIRSTNAME
  });
  header.push({
    labelKey: 'members_tableView_label_lastName',
    md: 2,
    xs: 2,
    sortable: true,
    sortId: SORT_PROPERTY_LASTNAME
  });
  header.push({
    labelKey: 'members_tableView_label_email',
    md: 2,
    xs: 2,
    sortable: true,
    sortId: SORT_PROPERTY_EMAIL
  });
  header.push({
    labelKey: 'members_tableView_label_companyName',
    md: 2,
    xs: 2,
    sortable: false
  });
  header.push({
    labelKey: 'member_subscription_origin',
    md: 2,
    xs: 2,
    sortable: false
  });
  if (rules.type) {
    header.push({
      labelKey: 'common_member_type',
      md: 1,
      xs: 1,
      sortable: true,
      sortId: SORT_PROPERTY_MEMBER_TYPE
    });
  }
  header.push({
    labelKey: 'members_tableView_label_status',
    md: 1,
    xs: 1,
    sortable: false
  });

  return header;
});

export const reportingSpinnerStatusSelector = state => state.user.reportingSpinner;
export const reportingErrorStatusSelector = state => state.user.reportingError;

const quickSightEmailSelector = state => state.user.quickSightTestEmail;

export const reportingUserEmailSelector = createSelector(userEmailSelector, quickSightEmailSelector, (userEmail, testEmail) => {
  if (isTestEnv()) {
    return testEmail || userEmail;
  }
  return userEmail;
});

export const abnormalBookingDelayedSelector = state => state.bookings.bookingDelayed;

export const bookingDelayedSelector = createSelector(bookingDetailsSelector, abnormalBookingDelayedSelector, (booking, delayed) => {
  if (delayedBookingStatuses.includes(booking.status)) {
    const status = getBookingInfo(booking, 'delayed');
    return status === undefined ? delayed : status;
  }
});

export const headerLastLevelCompanyIdSelector = createSelector(validCompanyIdSelector, validSubCompanyIdSelector, (superId, subId) => {
  return subId || superId || ALL;
});

export const scheduledExportMapSelector = state => state.exports.scheduledExportMap || fallbackObj;
export const scheduledExportListSelector = state => state.exports.scheduledExportList || fallbackArray;
export const scheduledExportIdSelector = state => state.exports.scheduledExportId || '';

export const scheduledExportItemSelector = createSelector(
  scheduledExportListSelector,
  scheduledExportMapSelector,
  scheduledExportIdSelector,
  (arr, obj, id) => arr[obj[id]]
);

function formatS3Fields({ bucket, path, accessKey } = {}) {
  return { bucket, path, accessKey };
}

function formatSftpFields({ host, path, port, username } = {}) {
  return { host, path, port, username };
}

export const scheduledExportItemFiltersSelector = createSelector(scheduledExportItemSelector, item => {
  if (item) {
    const { FILTERS_BY_TYPE } = SCHEDULED_EXPORT;
    const { filter = {}, resourceType } = item.content || {};
    const { empty } = filter;

    if (!empty) {
      const filters = filter[FILTERS_BY_TYPE[resourceType]];
      return { ...filters, subscriptionOrigins: safe(() => filters.subscriptionOrigins[0]) };
    }
  }
});

export const scheduledExportFormValuesSelector = createSelector(scheduledExportItemSelector, item => {
  if (item) {
    const { content, description, target, schedule } = item || {};
    const { resourceType } = content || {};
    const { frequency } = schedule || {};
    const { s3, sftp, exportFileNameFormat } = target || {};
    const { prefix, suffix, dateFormat } = exportFileNameFormat || {};
    const { S3, SFTP } = SCHEDULED_EXPORT.DESTINATION;
    const destination = s3 ? S3 : SFTP;

    return {
      resourceType,
      description,
      creator: safe(() => item.owner.name),
      destination,
      frequency,
      ...(s3 ? formatS3Fields(s3) : formatSftpFields(sftp)),
      prefix,
      suffix,
      dateFormat
    };
  }
});
