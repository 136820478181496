import React, { Component } from 'react';
import PropTypes from 'prop-types';
import pure from 'recompose/pure';
import { FormattedMessage } from 'react-intl';
import { namedCompose } from '../../utils/utils';
import { connect } from 'react-redux';
import { localeSelector } from '../../selectors/all-selectors';
import DownloadIcon from 'material-ui/svg-icons/action/get-app';
import CloseIcon from 'material-ui/svg-icons/navigation/close';
import None from '../None';

class PdfModal extends Component {
  constructor(props) {
    super(props);
    this.svgStyle = { display: 'block' };
  }

  render() {
    if (this.props.show) {
      return (
        <div className="pdf-modal">
          <div className="pdf-overlay" onClick={this.props.onClose} />
          <div className="pdf-container">
            <div className="pdf-title">
              <FormattedMessage id={this.props.titleKey || 'company_invoice_preview'} />
              <div className="button-container">
                {this.props.onDownload && (
                  <div className="pdf-button" onClick={this.props.onDownload} id="pdf-modal-download">
                    <DownloadIcon style={this.svgStyle} />
                  </div>
                )}
                <div className="pdf-button" onClick={this.props.onClose} id="pdf-modal-close">
                  <CloseIcon style={this.svgStyle} />
                </div>
              </div>
            </div>
            <div className="pdf-iframe-container">
              <iframe src={this.props.src} />
            </div>
          </div>
        </div>
      );
    } else {
      return <None />;
    }
  }
}

PdfModal.propTypes = {
  show: PropTypes.bool,
  src: PropTypes.string,
  onDownload: PropTypes.func,
  onClose: PropTypes.func
};

PdfModal.displayName = 'PdfModal';

export default namedCompose(
  connect(state => {
    return { locale: localeSelector(state) }; // pure exception
  }),
  pure
)(PdfModal);
