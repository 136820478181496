import React, { Component } from 'react';
import ColumnList from '../../../components/_v2/ColumnList';
import { connect } from 'react-redux';

import { createMenuItems, resolveActive, resolveSelected, showNextView } from '../../../components/_v2/ColumnList/helpers';
import { FormattedMessage } from 'react-intl';
import { checkRole } from '../../../constants/backuser-role-rules';
import { editColorsRules } from '../../../constants/routes-constants';
import { userRoleSelector } from '../../../selectors/all-selectors';

class ColorsList extends Component {
  componentWillMount() {
    this.setCallbacks();
    this.setVars();
  }

  setVars() {
    if (checkRole(editColorsRules, this.props.role)) {
      this.menuItems = createMenuItems({ onAdd: this.handleAdd, addKey: 'addVehiclesColor_title' });
    }
  }

  setCallbacks() {
    this.handleSelect = ({ id }, _, { openInNewTab }) => {
      showNextView.call(this, 'vehicleEditColor', ':colorId', id, openInNewTab);
    };

    this.handleAdd = () => {
      showNextView.call(this, 'vehicleAddColor');
    };

    this.renderItem = ({ code }) => {
      return <div className="sc-list-item">{code}</div>;
    };

    this.resolveSelected = resolveSelected.bind(this);
    this.resolveActive = resolveActive.bind(this);
  }

  render() {
    return (
      <ColumnList
        title={<FormattedMessage id="common_colors" />}
        inputRef={this.props.inputRef}
        items={this.props.list}
        renderItem={this.renderItem}
        onSelect={this.handleSelect}
        selected={this.resolveSelected}
        active={this.resolveActive}
        menuItems={this.menuItems}
        localSearchEnabled
        localSearchKey="code"
      />
    );
  }
}

const mapStateToProps = state => {
  return {
    list: state.colors.list,
    role: userRoleSelector(state)
  };
};

ColorsList = connect(mapStateToProps)(ColorsList);

export default ColorsList;
