import * as types from '../constants/actionTypes-constants';
import routes from '../constants/routes-constants';
import callApi from './api-actions';
import _sortBy from 'lodash/sortBy';
import _get from 'lodash/get';
import _forEach from 'lodash/forEach';

import { getDaysByLocale, trimValues } from '../utils/utils';
import { parkingRequestFormat } from '../utils/dataFormatUtils';
import { addFlashMessage } from '../actions/all-actions';
import { redirectToRoute } from '../routing/utils-routing';
import { FLASH_MESSAGE_TYPE_SUCCESS, FLASH_MESSAGE_TYPE_ERROR } from '../constants/generic-constants';
import { BACKUSER_ROLE_FLEET_MANAGER } from '../constants/backend-constants';
import { selectSubCompanyId } from '../utils/utils';

export function getParkingsList(siteId, loader = true) {
  return dispatch => {
    dispatch({
      type: types.PARKINGS_GET_LIST_REQUEST
    });

    return dispatch(callApi('parkings', 'getList', siteId, loader)).then(function(data) {
      dispatch({
        type: types.PARKINGS_GET_LIST_SUCCESS,
        list: data
      });
      return data;
    });
  };
}

export function getParkingsNoState({ siteId, loader = true }) {
  return dispatch => {
    const handleError = error => {
      dispatch({ type: types.GET_PARKINGS_NO_STATE_ERROR });
      return Promise.reject(error);
    };

    const handleSuccess = data => {
      dispatch({ type: types.GET_PARKINGS_NO_STATE_SUCCESS });
      return data;
    };

    dispatch({ type: types.GET_PARKINGS_NO_STATE_REQUEST });

    if (siteId) {
      const action = callApi('parkings', 'getList', siteId, loader);
      return dispatch(action).then(handleSuccess, handleError);
    }

    return handleError({ errorCode: 'error_local_unknown' });
  };
}

export function getList(loader = false) {
  return (dispatch, getState) => {
    const state = getState();
    const subCompanyId = selectSubCompanyId(state);

    dispatch({
      type: types.PARKINGS_GET_LIST_REQUEST
    });

    return dispatch(callApi('parkings', 'getEntities', subCompanyId, loader)).then(
      function(data) {
        data = _sortBy(data, item => {
          return item.name.toLowerCase();
        });
        dispatch({
          type: types.PARKINGS_GET_LIST_SUCCESS,
          list: data
        });
        return data;
      },
      () => {
        dispatch({
          type: types.PARKINGS_GET_LIST_ERROR
        });
      }
    );
  };
}

export function clearParkingsList() {
  return {
    type: types.PARKINGS_CLEAR_LIST
  };
}

export function createParkingRequest(siteId, isModal) {
  return (dispatch, getState) => {
    const state = getState();

    const params = parkingRequestFormat(trimValues(state.form.parkingForm));

    dispatch({
      type: types.PARKINGS_CREATE_PARKING_REQUEST,
      params
    });

    return dispatch(callApi('parkings', 'createParking', { siteId, params }))
      .then(parking => {
        dispatch(createParkingSuccess(parking));

        dispatch(
          addFlashMessage({
            contentKey: 'parkingForm_create_parking_success',
            type: FLASH_MESSAGE_TYPE_SUCCESS,
            delayToNextRoute: true
          })
        );

        if (!isModal) {
          redirectToRoute(routes.siteDetails.path.replace(':siteId', siteId));
        }

        return Promise.resolve(parking);
      })
      .catch(error => {
        if (error.code === 422) {
          dispatch(
            addFlashMessage({
              contentKey: 'parkingForm_update_parking_error_' + error.body.errorCode,
              type: FLASH_MESSAGE_TYPE_ERROR,
              contentData: {
                days: error.body.errorParams
                  ? getDaysByLocale(
                      error.body.errorParams.daysWithOverlap.substring(1, error.body.errorParams.daysWithOverlap.length - 1),
                      state.i18n.bundle
                    )
                  : null
              }
            })
          );
        } else {
          dispatch(
            addFlashMessage({
              contentKey: 'parkingForm_create_parking_error',
              type: FLASH_MESSAGE_TYPE_ERROR
            })
          );
        }
        return Promise.reject(error);
      });
  };
}

export function createParkingSuccess(parking) {
  return {
    type: types.PARKINGS_CREATE_PARKING_SUCCESS,
    parking
  };
}

export function getParkingDetails(parkingId) {
  return dispatch => {
    dispatch({
      type: types.PARKINGS_GET_DETAIL_PARKING_REQUEST
    });

    return dispatch(callApi('parkings', 'getParkingDetails', parkingId)).then(function(data) {
      dispatch({
        type: types.PARKINGS_GET_DETAIL_PARKING_SUCCESS,
        parking: data
      });
      return data;
    });
  };
}

export function getBankHolidays(dates) {
  return dispatch => {
    dispatch({
      type: types.PARKINGS_GET_BANK_HOLIDAYS_PARKING_REQUEST
    });

    return dispatch(callApi('parkings', 'getBankHolidays', dates)).then(function(data) {
      dispatch({
        type: types.PARKINGS_GET_BANK_HOLIDAYS_PARKING_SUCCESS,
        bankHolidays: data
      });
      return data;
    });
  };
}

function changeMidnightSlot(slot) {
  const timeIntervals = _get(slot, 'timeIntervals');
  const midnight = '00:00';
  const replace = '23:59';

  _forEach(timeIntervals, interval => {
    const endDate = _get(interval, 'end');
    if (endDate === midnight) interval.end = replace;
  });
}

function adjustMidnightTime(params) {
  const schedule = _get(params, 'schedule');
  const oneTimeSlots = _get(schedule, 'oneTimeSlots');
  const recurringSlots = _get(schedule, 'recurringSlots');

  _forEach(oneTimeSlots, changeMidnightSlot);
  _forEach(recurringSlots, changeMidnightSlot);
}

export function updateParkingRequest(parking, isModal) {
  return (dispatch, getState) => {
    const state = getState();
    const params = parkingRequestFormat(trimValues(state.form.parkingForm));
    const userInfo = state.user.userInfo;

    // remove unnecessary precision
    params.coordinates.latitude = Number(params.coordinates.latitude).toFixed(6);
    params.coordinates.longitude = Number(params.coordinates.longitude).toFixed(6);

    if (params.rrsParkingId === '') delete params.rrsParkingId;

    adjustMidnightTime(params);

    return dispatch(callApi('parkings', 'updateParking', { parkingId: parking.id, params }))
      .then(parking => {
        dispatch(updateParkingSuccess(parking));
        dispatch(
          addFlashMessage({
            contentKey: 'parkingForm_update_parking_success',
            type: FLASH_MESSAGE_TYPE_SUCCESS,
            delayToNextRoute: true
          })
        );

        if (!isModal) {
          userInfo.role === BACKUSER_ROLE_FLEET_MANAGER
            ? redirectToRoute(routes.parkings.path)
            : redirectToRoute(routes.siteDetails.path.replace(':siteId', parking.siteId));
        }

        return Promise.resolve(parking);
      })
      .catch(error => {
        if (error.code === 422) {
          dispatch(
            addFlashMessage({
              contentKey: 'parkingForm_update_parking_error_' + error.body.errorCode,
              type: FLASH_MESSAGE_TYPE_ERROR,
              contentData: {
                days: error.body.errorParams
                  ? getDaysByLocale(
                      error.body.errorParams.daysWithOverlap.substring(1, error.body.errorParams.daysWithOverlap.length - 1),
                      state.i18n.bundle
                    )
                  : null
              }
            })
          );
        } else {
          dispatch(
            addFlashMessage({
              contentKey: 'parkingForm_update_parking_error',
              type: FLASH_MESSAGE_TYPE_ERROR
            })
          );
        }
        return Promise.reject(error);
      });
  };
}

export function updateParkingSuccess(parking) {
  return {
    type: types.PARKINGS_UPDATE_PARKING_SUCCESS,
    parking
  };
}

export function openParkingConfirmationModal() {
  return {
    type: types.PARKINGS_CONFIRMATION_MODAL_OPEN
  };
}

export function closeParkingConfirmationModal() {
  return {
    type: types.PARKINGS_CONFIRMATION_MODAL_CLOSE
  };
}

export function setIsOpen(isOpen) {
  return {
    type: types.PARKINGS_SET_IS_OPEN,
    isOpen
  };
}
