import React, { Component } from 'react';
import { reduxForm } from 'redux-form';
import { FormattedMessage } from 'react-intl';
import cs from 'classnames';
import BoxedInput from '../../../../components/BoxedInput/BoxedInput';
import CheckCircle from 'material-ui/svg-icons/action/check-circle';
import Circle from 'material-ui/svg-icons/image/lens';
import { FIXED_AMOUNT_DISCOUNT, PERCENT_DISCOUNT } from '../../../../constants/backend-constants';

class Filters extends Component {
  componentWillMount() {
    this.setCallbacks();
  }

  setCallbacks() {
    this.handleAmountButton = () => {
      const field = this.props.fields.voucherType;
      const toApply = !field.value || field.value === PERCENT_DISCOUNT;
      field.onChange(toApply ? FIXED_AMOUNT_DISCOUNT : '');
    };

    this.handlePercentageButton = () => {
      const field = this.props.fields.voucherType;
      const toApply = !field.value || field.value === FIXED_AMOUNT_DISCOUNT;
      field.onChange(toApply ? PERCENT_DISCOUNT : '');
    };
  }

  render() {
    const {
      fields,
      fields: {
        voucherType: { value: voucherType }
      }
    } = this.props;

    const fixedAmount = voucherType === FIXED_AMOUNT_DISCOUNT;
    const percentAmount = voucherType === PERCENT_DISCOUNT;

    return (
      <form className="voucher-group-filters">
        <div className="sc-block">
          <div className="sc-title">
            <FormattedMessage id="common_name" />
          </div>
          <div className={cs('sc-items')}>
            <BoxedInput
              placeholderKey="voucher_group_name"
              type="text"
              field={fields.name}
              labelKey="common_searchFor_VoucherGroupName"
              customClass="group-name"
            />
          </div>
        </div>
        <div className="sc-block">
          <div className="sc-title">
            <FormattedMessage id="common_type" />
          </div>
          <div className={cs('sc-items', 'sc-type-wrap')}>
            <button type="button" onClick={this.handleAmountButton} className={cs('sc-type-button', { 'sc-active': fixedAmount })}>
              <span className="sc-type-button-icon-wrap">{fixedAmount ? <CheckCircle /> : <Circle />}</span>
              <FormattedMessage id="common_amount" />
              <span>&nbsp;</span>
              <span>(€)</span>
            </button>
            <button type="button" onClick={this.handlePercentageButton} className={cs('sc-type-button', { 'sc-active': percentAmount })}>
              <span className="sc-type-button-icon-wrap">{percentAmount ? <CheckCircle /> : <Circle />}</span>
              <FormattedMessage id="common_percentage" />
              <span>&nbsp;</span>
              <span>(%)</span>
            </button>
          </div>
        </div>
        <div className="sc-block">
          <div className="sc-title">
            <FormattedMessage id="vouchers.filters.date.title" />
          </div>
          <div className="sc-items">
            <BoxedInput
              placeholderKey="placeholder.select.date"
              type="date"
              skinType="date"
              field={fields.fromCreationDate}
              labelKey="pricing_cancellation_fees_offsetMin_label"
            />
            <BoxedInput
              placeholderKey="placeholder.select.date"
              type="date"
              skinType="date"
              field={fields.toCreationDate}
              labelKey="pricing_cancellation_fees_offsetMax_label"
            />
          </div>
        </div>
        <div className="sc-block">
          <div className="sc-title">
            <FormattedMessage id="common_searchFor_voucherCode" />
          </div>
          <div className={cs('sc-items')}>
            <BoxedInput
              placeholderKey="voucher_group_name"
              type="text"
              field={fields.voucherCode}
              labelKey="common_searchFor_voucherCode"
              customClass="group-name"
            />
          </div>
        </div>
      </form>
    );
  }
}

Filters = reduxForm({
  form: 'voucherGroupFilters',
  fields: ['voucherType', 'fromCreationDate', 'toCreationDate', 'name', 'voucherCode']
})(Filters);

export default Filters;
