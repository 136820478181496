import * as types from '../constants/actionTypes-constants';
import { safe } from '../utils/utils';

export default function sites(
  state = {
    list: [],
    listLoading: false,
    currentSite: {},
    dupSite: null
  },
  action
) {
  switch (action.type) {
    case types.SITES_UPDATE_SITE_REQUEST: {
      return state;
    }

    case types.SITES_GET_LIST_REQUEST: {
      return { ...state, listLoading: true };
    }

    case types.SITES_GET_LIST_SUCCESS: {
      return { ...state, list: action.list, listLoading: false };
    }

    case types.SITES_GET_LIST_CLEAR: {
      return { ...state, list: [], listLoading: false };
    }

    case types.SITES_CREATE_SITE_SUCCESS: {
      return { ...state, list: [{ ...action.site }, ...state.list] };
    }

    case types.SITES_GET_DETAIL_SITE_REQUEST:
      return state;

    case types.SITES_GET_DETAIL_SITE_SUCCESS:
      return Object.assign({}, state, {
        currentSite: action.site
      });

    case types.SITES_UPDATE_SITE_SUCCESS: {
      const { site } = action;
      const { list = [] } = state;

      safe(() => (list.find(item => item.id === site.id).name = site.name));

      return {
        ...state,
        list: [...list],
        currentSite: action.site
      };
    }

    case types.SITE_COPY_TO_NEW: {
      return Object.assign({}, state, {
        dupSite: action.site
      });
    }

    case types.SITE_CLEAN_DUP: {
      return Object.assign({}, state, {
        dupSite: null
      });
    }

    default: {
      return state;
    }
  }
}
