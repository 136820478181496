import React, { Component, PropTypes } from 'react';
import { reduxForm } from 'redux-form';
import { FormattedMessage, injectIntl } from 'react-intl';
import BoxedInput from '../../../components/BoxedInput/BoxedInput';
import FieldErrorMsg from '../../../components/FieldErrorMsg/FieldErrorMsg';
import BoxedSelect from '../../../components/BoxedSelect/BoxedSelect';
import SlimerSelect from '../../../components/SlimerSelect';
import { bookingStatus, bookingTypes, booleanOptions } from '../../../constants/options-constants';
import { vehicleUsages } from '../../../constants/filters-constants';
import { fallbackFunc, intlMsg, isEmpty, safe, scrollToFirstError } from '../../../utils/utils';
import { getModelsList } from '../../../actions/models-actions';
import { createValidator, integer, notEmpty } from '../../../validation/sync-validation';
import cs from 'classnames';

import {
  createNameToIdMap,
  headerCompanyListLoadingSelector,
  headerCompanyListSelector,
  selectVehicleBrandsOptions,
  selectVehicleModelsOptions,
  siteListLoadingSelector,
  sitesListSelector,
  subCompaniesListSelector,
  subCompanyListLoadingSelector,
  vehicleBrandsLoadingSelector,
  vehicleBrandsSelector,
  vehicleModelsLoadingSelector
} from '../../../selectors/all-selectors';
import { createCompanyOptions, createSitesOptions, createSubCompanyOptions } from '../utils';
import { clearSitesList, getSitesListFromSubCompany } from '../../../actions/sites-actions';
import { getSubCompaniesList, subCompaniesListClear } from '../../../actions/subCompanies-actions';

class BookingFilters extends Component {
  oneSuperCompanySelected(companyIds = this.props.fields.companyIds.value) {
    return safe(() => companyIds.length === 1);
  }

  oneSubCompanySelected(companyIds = this.props.fields.subCompanyIds.value) {
    return safe(() => companyIds.length === 1);
  }

  setCallbacks() {
    this.updateModels = name => {
      const { props: p } = this;
      const brandId = safe(() => createNameToIdMap(p.vehicleBrands)[name]);

      if (brandId) {
        p.dispatch(getModelsList(brandId, false)).catch(fallbackFunc);
      }
    };

    this.onSuperCompanySelect = data => {
      const { props: p } = this;

      if (this.oneSuperCompanySelected(data)) {
        p.dispatch(getSubCompaniesList(data[0], false));
      } else {
        p.dispatch(subCompaniesListClear());
      }
    };

    this.onSubCompanySelect = data => {
      const { props: p } = this;

      if (this.oneSubCompanySelected(data)) {
        p.dispatch(getSitesListFromSubCompany(data[0]));
      } else {
        p.dispatch(clearSitesList());
      }
    };
  }

  setActiveBlocks() {
    const { fields: f } = this.props;

    this.bookingBlockActive = this.fieldsActive(f.bookingStatus, f.vehicleUsage, f.bookingType);

    this.dateBlockActive = this.fieldsActive(
      f.bookingStartDateMin,
      f.bookingStartDateMax,
      f.bookingEndDateMin,
      f.bookingEndDateMax,
      f.bookingLastUpdateDateMin,
      f.bookingLastUpdateDateMax
    );

    this.companyBlockActive = this.fieldsActive(f.companyIds, f.subCompanyIds, f.siteIds);
    this.vehicleBlockActive = this.fieldsActive(f.vehicleBrand, f.vehicleModel);
    this.voucherBlockActive = this.fieldsActive(f.voucherCode, f.voucherGroupName);
    this.errorBlockActive = this.fieldsActive(f.delayedBooking);
  }

  componentWillMount() {
    this.setCallbacks();
    this.setActiveBlocks();
  }

  setSubmitHandler() {
    const { onSubmitSet: onSet } = this.props;
    if (onSet) onSet(this.props.handleSubmit);
  }

  getInitialModels() {
    const { initialValue, value } = this.props.fields.vehicleBrand;
    const data = initialValue || value;
    if (data) this.updateModels(data);
  }

  getInitialSites() {
    const { initialValue, value } = this.props.fields.subCompanyIds;
    const data = initialValue || value;
    this.onSubCompanySelect(data);
  }

  getInitialSubCompanies() {
    const { initialValue, value } = this.props.fields.companyIds;
    const data = initialValue || value;
    this.onSuperCompanySelect(data);
  }

  componentDidMount() {
    this.setSubmitHandler();
    this.getInitialModels();
    this.getInitialSubCompanies();
    this.getInitialSites();
  }

  fieldsActive(...fields) {
    for (let i = 0; i < fields.length; i++) {
      if (!isEmpty(fields[i].initialValue)) {
        return true;
      }
    }
  }

  addDaysHint(key) {
    return intlMsg(this, key) + ` (${intlMsg(this, 'common_days')})`;
  }

  render() {
    const { props: p } = this;
    const { fields: f } = p;

    return (
      <form className="scheduled-export-filters">
        <div className={cs('sc-block', { active: this.companyBlockActive })}>
          <div className="sc-title">
            <FormattedMessage id="common_company" />
          </div>
          <div className="sc-items">
            <SlimerSelect
              id="filter-super-company"
              field={f.companyIds}
              options={p.companyOptions}
              onChange={this.onSuperCompanySelect}
              disabled={p.isView || p.companyOptionsLoading}
              labelKey="common_super_company"
            />
            <SlimerSelect
              id="filter-sub-company"
              field={f.subCompanyIds}
              options={p.subCompanyOptions}
              onChange={this.onSubCompanySelect}
              disabled={p.isView || p.subCompanyOptionsLoading || !this.oneSuperCompanySelected()}
              labelKey="common_company"
            />
            <SlimerSelect
              id="filter-site"
              field={f.siteIds}
              options={p.siteOptions}
              labelKey="common_site"
              disabled={p.isView || p.siteOptionsLoading || !this.oneSubCompanySelected()}
            />
          </div>
        </div>
        <div className={cs('sc-block', { active: this.vehicleBlockActive })}>
          <div className="sc-title">
            <FormattedMessage id="vehicle_tab_vehicle" />
          </div>
          <div className="sc-items">
            <BoxedSelect
              id="filter-vehicle-brand"
              labelKey="vehiclesFiltersForm_brand"
              options={p.vehicleBrandsOptions}
              field={f.vehicleBrand}
              onChange={this.updateModels}
              disabled={p.isView || p.vehicleBrandsLoading}
            />
            <BoxedSelect
              id="filter-vehicle-model"
              labelKey="vehiclesFiltersForm_model"
              options={p.vehicleModelsOptions}
              field={f.vehicleModel}
              disabled={p.isView || p.vehicleModelsLoading}
            />
          </div>
        </div>
        <div className={cs('sc-block', { active: this.bookingBlockActive })}>
          <div className="sc-title">
            <FormattedMessage id="common_booking" />
          </div>
          <div className="sc-items">
            <BoxedSelect
              id="filter-booking-status"
              labelKey="bookings_tableView_label_status"
              options={bookingStatus}
              field={f.bookingStatus}
              disabled={p.isView}
            />
            <BoxedSelect
              id="filter-vehicle-usage"
              labelKey="vehiclesFiltersForm_usage"
              options={vehicleUsages}
              field={f.vehicleUsage}
              disabled={p.isView}
            />
            <BoxedSelect id="filter-booking-type" labelKey="common_type" options={bookingTypes} field={f.bookingType} disabled={p.isView} />
          </div>
        </div>
        <div className={cs('sc-block', { active: this.dateBlockActive })}>
          <div className="sc-title">
            <FormattedMessage id="common_date" />
          </div>
          <div className="sc-items">
            <BoxedInput
              id="filter-start-date-after"
              type="text"
              field={f.bookingStartDateMin}
              label={this.addDaysHint('booking_start_date_after')}
              disabled={p.isView}
            >
              <FieldErrorMsg field={f.bookingStartDateMin} />
            </BoxedInput>
            <BoxedInput
              id="filter-start-date-before"
              type="text"
              field={f.bookingStartDateMax}
              label={this.addDaysHint('booking_start_date_before')}
              disabled={p.isView}
            >
              <FieldErrorMsg field={f.bookingStartDateMax} />
            </BoxedInput>
          </div>
          <div className="sc-items">
            <BoxedInput
              id="filter-end-date-after"
              type="text"
              field={f.bookingEndDateMin}
              label={this.addDaysHint('booking_end_date_after')}
              disabled={p.isView}
            >
              <FieldErrorMsg field={f.bookingEndDateMin} />
            </BoxedInput>
            <BoxedInput
              id="filter-end-date-before"
              type="text"
              field={f.bookingEndDateMax}
              label={this.addDaysHint('booking_end_date_before')}
              disabled={p.isView}
            >
              <FieldErrorMsg field={f.bookingEndDateMax} />
            </BoxedInput>
          </div>
          <div className="sc-items">
            <BoxedInput
              id="filter-last-update-after"
              type="text"
              field={f.bookingLastUpdateDateMin}
              label={this.addDaysHint('booking.filter.last.update.after')}
              disabled={p.isView}
            >
              <FieldErrorMsg field={f.bookingLastUpdateDateMin} />
            </BoxedInput>
            <BoxedInput
              id="filter-last-update-before"
              type="text"
              field={f.bookingLastUpdateDateMax}
              label={this.addDaysHint('booking.filter.last.update.before')}
              disabled={p.isView}
            >
              <FieldErrorMsg field={f.bookingLastUpdateDateMax} />
            </BoxedInput>
          </div>
        </div>
        <div className={cs('sc-block', { active: this.voucherBlockActive })}>
          <div className="sc-title">
            <FormattedMessage id="side_menu_section_vouchers" />
          </div>
          <div className="sc-items">
            <BoxedInput
              id="filter-voucher-code"
              type="text"
              labelKey="common_searchFor_voucherCode"
              field={f.voucherCode}
              disabled={p.isView}
            />
            <BoxedInput
              id="filter-voucher-group"
              type="text"
              labelKey="common_searchFor_VoucherGroupName"
              field={f.voucherGroupName}
              disabled={p.isView}
            />
          </div>
        </div>
        <div className={cs('sc-block', { active: this.errorBlockActive })}>
          <div className="sc-title">
            <FormattedMessage id="common_error" />
          </div>
          <div className="sc-items">
            <BoxedSelect
              id="filter-delayed-booking"
              labelKey="bookings_delayed_status"
              options={booleanOptions}
              field={f.delayedBooking}
              disabled={p.isView}
            />
          </div>
        </div>
      </form>
    );
  }
}

BookingFilters.propTypes = {
  onSubmitSet: PropTypes.func,
  isView: PropTypes.bool
};

BookingFilters = reduxForm(
  {
    onSubmitFail: scrollToFirstError,
    form: 'bookingFilters',
    fields: [
      'companyIds',
      'subCompanyIds',
      'siteIds',
      'voucherCode',
      'voucherGroupName',
      'bookingStartDateMin',
      'bookingStartDateMax',
      'bookingEndDateMin',
      'bookingEndDateMax',
      'bookingLastUpdateDateMin',
      'bookingLastUpdateDateMax',
      'bookingStatus',
      'vehicleUsage',
      'bookingType',
      'vehicleBrand',
      'vehicleModel',
      'delayedBooking'
    ],
    validate: createValidator({
      companyIds: [notEmpty()],
      bookingStartDateMin: [integer()],
      bookingStartDateMax: [integer()],
      bookingEndDateMin: [integer()],
      bookingEndDateMax: [integer()],
      bookingLastUpdateDateMin: [integer()],
      bookingLastUpdateDateMax: [integer()]
    })
  },
  state => ({
    companyOptions: createCompanyOptions(headerCompanyListSelector(state)),
    companyOptionsLoading: headerCompanyListLoadingSelector(state),
    subCompanyOptions: createSubCompanyOptions(subCompaniesListSelector(state)),
    subCompanyOptionsLoading: subCompanyListLoadingSelector(state),
    siteOptions: createSitesOptions(sitesListSelector(state)),
    siteOptionsLoading: siteListLoadingSelector(state),
    vehicleBrandsOptions: selectVehicleBrandsOptions(state),
    vehicleModelsOptions: selectVehicleModelsOptions(state),
    vehicleBrandsLoading: vehicleBrandsLoadingSelector(state),
    vehicleModelsLoading: vehicleModelsLoadingSelector(state),
    vehicleBrands: vehicleBrandsSelector(state)
  })
)(BookingFilters);

BookingFilters = injectIntl(BookingFilters);

export default BookingFilters;
