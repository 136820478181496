/* eslint-disable react/jsx-handler-names */
import React, { Component, PropTypes as T } from 'react';
import { Grid, Row, Col } from 'react-bootstrap';
import { FormattedMessage, FormattedHTMLMessage, injectIntl } from 'react-intl';
import _get from 'lodash/get';
import _reduce from 'lodash/reduce';
import _partial from 'lodash/partial';
import _isEqual from 'lodash/isEqual';
import _cloneDeep from 'lodash/cloneDeep';
import pure from 'recompose/pure';
import ErrorIcon from 'material-ui/svg-icons/alert/error-outline';
import PaginationControls from '../PaginationControls/PaginationControls';
import NumberResultsSelectForm from '../NumberResultsSelectForm/NumberResultsSelectForm';
import RefreshIcon from 'material-ui/svg-icons/navigation/refresh';
import IconButton from 'material-ui/IconButton';
import { connect } from 'react-redux';
import SortDownIcon from 'material-ui/svg-icons/navigation/arrow-downward';
import SortUpIcon from 'material-ui/svg-icons/navigation/arrow-upward';
import { namedCompose } from '../../utils/utils';
import autoBind from 'react-autobind';
import { localeSelector } from '../../selectors/all-selectors';
import classNames from 'classnames';

class AdvancedList extends Component {
  constructor(props) {
    super(props);
    autoBind(this);

    this.setListHtmlNode = element => {
      this.listHtmlNode = element;
    };

    this.derivedStateFromProps(props, true);
  }

  // eslint-disable-next-line no-unused-vars
  componentWillReceiveProps(props, context) {
    this.derivedStateFromProps(props);
  }

  forcedToRender(props) {
    return props.renderAnchor !== this.props.renderAnchor;
  }

  derivedStateFromProps(props, init) {
    if (init || this.forcedToRender(props) || !_isEqual(this.data, props.data)) {
      this.data = _cloneDeep(props.data);
      this.listItems = _reduce(
        props.data,
        (result, value, key) => {
          const rawValueId = _get(value, 'id');
          const valueId = props.id + '_list_row_' + (rawValueId || key);
          const reactKey = rawValueId || key;
          if (value) result.push(props.renderItem(value, valueId, reactKey));
          return result;
        },
        []
      );
    }
  }

  handleSortClick(headerParam) {
    if (_get(headerParam, 'sortable', false)) this.props.onSort(headerParam.sortId);
  }

  i18nHelper(arr) {
    let toReturn = [];
    for (let [k, id] of Object.entries(arr)) toReturn.push(this.props.intl.messages[id]);
    return toReturn;
  }

  parseHeaderItem(item) {
    const tradsKey = _get(item, 'labelKey', false);
    if (typeof tradsKey === 'string') {
      const text = this.props.intl.messages[item.labelKey];

      return (
        <span className="single-item" title={text}>
          {text}
        </span>
      );
    } else return <div className="multiple">{this.i18nHelper(tradsKey)}</div>;
  }

  render() {
    const {
      header,
      footer,
      data,
      loading,
      error,
      wrapStyle,
      onChangeRowsPerPage,
      listMetadata,
      onChangePage,
      onRefreshList,
      urlParams,
      id,
      intl
    } = this.props;

    return (
      <div className="advancedList_wrap" style={{ ...wrapStyle }}>
        <Grid className="advancedList_container">
          {/*RenderHeader*/}
          <Row className="advancedList_header">
            {header.map((item, index) => {
              const condition = _get(urlParams, 'sort', false) && urlParams.sort.property === item.sortId;
              const content = this.parseHeaderItem(item);

              return (
                <Col key={'header' + index} {...item}>
                  <div
                    onClick={_partial(this.handleSortClick, item)}
                    className={classNames('advancedList_header_sortIcon', { cursorPointer: item.sortable })}
                    style={{ color: condition ? 'black' : 'inherit' }}
                  >
                    {content}
                    {condition ? (
                      <div className="sortIcon sorted">{urlParams.sort.isDescending ? <SortUpIcon /> : <SortDownIcon />}</div>
                    ) : (
                      <div className="sortIcon iconHoverable">{item.sortable && <SortDownIcon />}</div>
                    )}
                  </div>
                </Col>
              );
            })}
          </Row>
          {/*Render list rows*/}
          {!error && (
            <div className="advancedList_data row" ref={this.setListHtmlNode}>
              {this.listItems}
              {/*Render loader*/}
              {loading && !error && (
                <div className={loading && data.length ? 'advancedList_loader advancedList_loaderWithData' : 'advancedList_loader'}>
                  <div className="lds-rolling">
                    <div />
                  </div>
                </div>
              )}
            </div>
          )}
          {/*Render Error*/}
          {error && (
            <div className="advancedList_error">
              <ErrorIcon />
              <FormattedMessage id="advancedList_error" />
            </div>
          )}
          {/*Render no results*/}
          {!data.length && !loading && !error && (
            <div className="advancedList_noResults">
              <FormattedMessage id="common_no_results" />
            </div>
          )}
          {/*Render footer with pagination*/}
          <div className="row">
            <div className="advancedList_footer">
              {onRefreshList && (
                <div className="advancedList_footer_actions">
                  <IconButton
                    tooltip={intl.formatMessage({ id: 'common_refresh' })}
                    tooltipPosition="bottom-center"
                    onClick={onRefreshList}
                    id={id + '_list_refresh_button'}
                  >
                    <RefreshIcon />
                  </IconButton>
                </div>
              )}
              {listMetadata && data.length > 0 && (
                <div className="advancedList_pagination">
                  <NumberResultsSelectForm
                    onCallback={onChangeRowsPerPage}
                    customClass="advancedList_numberResultsSelectForm"
                    customLabelClass="numberResultsSelectForm_label"
                    onNumberResultsSelect={onChangeRowsPerPage}
                    initialValues={{
                      numberResults: listMetadata.paginationInfo ? listMetadata.paginationInfo.pageSize : '10'
                    }}
                  />
                  <PaginationControls
                    paginationInfo={listMetadata.paginationInfo}
                    onPageChange={onChangePage}
                    advancedList={this.listHtmlNode}
                  />
                </div>
              )}
            </div>
          </div>
          {footer && (
            <Row className="advancedList_footer advanced">
              {footer.map((item, index) => {
                return (
                  <Col key={'footer' + index} {...item}>
                    <FormattedHTMLMessage id={item.labelKey} values={{ count: item.count }} />
                  </Col>
                );
              })}
            </Row>
          )}
        </Grid>
      </div>
    );
  }
}

AdvancedList.propTypes = {
  id: T.string,
  header: T.array,
  footer: T.array,
  data: T.array,
  renderItem: T.func,
  onFilteredSearchForm: T.oneOfType([T.bool, T.func]),
  onRefreshList: T.oneOfType([T.bool, T.func]),
  onChangeRowsPerPage: T.func,
  onChangePage: T.func,
  listMetadata: T.oneOfType([T.bool, T.object]),
  loading: T.bool,
  error: T.bool,
  wrapStyle: T.object,
  headerActions: T.bool,
  filters: T.bool,
  urlParams: T.object,
  renderAnchor: T.any // if changed: re-render list
};

AdvancedList.defaultProps = {
  loading: false,
  error: false,
  listMetadata: false,
  urlParams: {},
  onRefreshList: false,
  alwaysRender: false
};

export default namedCompose(
  connect(state => ({ locale: localeSelector(state) })), // pure exception
  injectIntl,
  pure
)(AdvancedList);
