import React from 'react';

export default function FuelGauge(props) {
  let classname = 'no-data-tank';
  const _height = props.height ? props.height + 'px' : '20px';
  const level = props.percentage ? Number(props.percentage) : -1;
  const key = 'gauge' + Math.random() * 1000;

  if (level <= 25 && level >= 0) {
    classname = 'quarter-tank';
  } else if (level <= 50 && level > 25) {
    classname = 'half-tank';
  } else if (level <= 75 && level > 50) {
    classname = 'three-quarter-tank';
  } else if (level > 75) {
    classname = 'full-tank';
  }

  return (
    <div className="fuel-gauge">
      <svg
        key={key}
        className={classname}
        viewBox="0 0 280 100"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        style={{ height: _height }}
      >
        <g id={'key-gauge' + key} className="gauge">
          <rect id={'Rect-0' + key} x="0" y="0" width="63" height="100" rx="18"></rect>
          <rect id={'Rect-1' + key} x="72" y="0" width="63" height="100" rx="18"></rect>
          <rect id={'Rect-2' + key} x="144" y="0" width="63" height="100" rx="18"></rect>
          <rect id={'Rect-3' + key} x="216" y="0" width="63" height="100" rx="18"></rect>
        </g>
      </svg>
    </div>
  );
}
