// noinspection ES6CheckImport
import React, { Component, PropTypes } from 'react';
import { FormattedMessage } from 'react-intl';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import _ from 'lodash';

import BoxedSelect from '../BoxedSelect/BoxedSelect';
import EkButton from '../EkButton/EkButton';
import { closeMigrateMemberModal, migrateMember } from '../../actions/all-actions';
import { scrollToFirstError } from '../../utils/utils';

class MemberMigrate extends Component {
  constructor() {
    // noinspection JSCheckFunctionSignatures
    super();

    this.handleCancel = this.handleCancel.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleCancel() {
    const { dispatch } = this.props;
    dispatch(closeMigrateMemberModal());
  }

  handleSubmit() {
    const {
      id,
      fields: { companySelect },
      dispatch
    } = this.props;
    dispatch(migrateMember(id, companySelect.value));
  }

  render() {
    const {
      list,
      fields: { companySelect },
      company
    } = this.props;

    const preparedSuperCompanyList = list.map(obj => {
      return {
        value: obj.id,
        label: obj.name
      };
    });
    const defaultFormName = 'member_migrateForm';

    return (
      <div>
        <BoxedSelect
          id={_.get(this.props, 'formName', defaultFormName) + '_companyList'}
          formRowItemKey="memberMigrate_status"
          labelKey="side_menu_section_account_companies"
          field={companySelect}
          options={preparedSuperCompanyList}
          customClass="memberMigrate_boxedSelectWrapper"
          blockCustomClass="memberMigrate_boxedSelectBlock"
          fullWidth
        />
        <div className="memberMigrate_actionWrap">
          <button
            id={_.get(this.props, 'formName', defaultFormName) + '_cancel_button'}
            type="button"
            className="memberMigrate_cancel ekButton ekButton--reverse"
            onClick={this.handleCancel}
          >
            <FormattedMessage id="backUsers_detail_abort_button" />
          </button>
          <EkButton
            id={_.get(this.props, 'formName', defaultFormName) + '_push_button'}
            customClass="memberMigrate_submitBtn"
            onAction={this.handleSubmit}
            disabled={!companySelect.value || company.id === companySelect.value}
          >
            <FormattedMessage id="boxedInput_defaultActionMsg" />
          </EkButton>
        </div>
      </div>
    );
  }
}

MemberMigrate.displayName = 'MemberMigrate';

MemberMigrate.propTypes = {
  fields: PropTypes.object,
  list: PropTypes.array,
  id: PropTypes.string,
  formName: PropTypes.string,
  company: PropTypes.object
};

MemberMigrate = reduxForm({
  onSubmitFail: scrollToFirstError,
  form: 'migrateUser',
  fields: ['companySelect']
})(MemberMigrate);

export default connect(state => {
  const {
    members: {
      detailMember: { id, company }
    },
    companies: { headerList: list }
  } = state;

  return {
    list,
    id,
    company
  };
})(MemberMigrate);
