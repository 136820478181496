import React from 'react';
import classnames from 'classnames';
import ListWrap from '../../components/_v2/ColumnList/Wrap';

export default function ScheduledExport(props) {
  return (
    <div className={classnames('pageContainer', 'scheduled-export', 'column-list-flat-details')}>
      <ListWrap {...props} />
    </div>
  );
}
