import React, { Component, PropTypes } from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import autoBind from 'react-autobind';

import { FormattedHTMLMessage, injectIntl } from 'react-intl';
import Dialog from 'material-ui/Dialog';
import FlatButton from 'material-ui/FlatButton';
import RaisedButton from 'material-ui/RaisedButton';
import FormRow from '../../../components/FormRow/FormRow';
import BoxedInput from '../../../components/BoxedInput/BoxedInput';
import FieldErrorMsg from '../../../components/FieldErrorMsg/FieldErrorMsg';
import { createValidator, notEmpty, integer, positiveNumber, stopValidationIf } from '../../../validation/all-validation';
import { MEMBER_TYPE_CATEGORY_CUSTOM } from '../../../constants/backend-constants';

import {
  createMemberTypeRequest,
  closeMemberTypeModal,
  saveEditMemberType,
  closeEditMemberTypeModal,
  removeMemberTypeRequest
} from '../memberTypes-actions';

class MemberTypeForm extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
  }

  handleSubmit() {
    const { dispatch, memberType, memberTypeDel, editMode, resetForm } = this.props;
    if (memberTypeDel) {
      this.handleRemove();
    } else {
      dispatch(editMode ? saveEditMemberType(memberType) : createMemberTypeRequest()).then(resetForm);
    }
  }

  handleClose() {
    const { dispatch, editMode, resetForm } = this.props;
    resetForm();
    dispatch(editMode ? closeEditMemberTypeModal() : closeMemberTypeModal());
  }

  handleRemove() {
    const { dispatch, memberTypeDel } = this.props;
    dispatch(removeMemberTypeRequest(memberTypeDel));
  }

  render() {
    const {
      fields: { name, maximumScheduledBookings },
      handleSubmit,
      isOpen,
      editMode,
      memberType,
      memberTypeDel,
      titleKey,
      intl: { messages }
    } = this.props;

    let actions = [
      <FlatButton
        key="createMemberTypeForm_abortBtn"
        label={messages['common_cancel']}
        formRowItemKey="createMemberTypeForm_abortBtn"
        primary
        onClick={this.handleClose}
      />,
      <RaisedButton
        key="createMemberTypeForm_submitBtn"
        primary
        label={messages[memberTypeDel ? 'common_confirm' : 'common_save']}
        formRowItemKey="createMemberTypeForm_submitBtn"
        onClick={handleSubmit(this.handleSubmit)}
      />
    ];

    return (
      <Dialog open={isOpen} title={messages[titleKey]} actions={actions} actionsContainerClassName="dialog-actions">
        {memberType && !memberTypeDel && (
          <form action="#" className="memberTypeForm" onSubmit={handleSubmit(this.handleSubmit)}>
            <FormRow customWrapperClass="memberTypeForm_row">
              <BoxedInput
                formRowItemKey="memberTypeForm_name"
                type="text"
                id="memberTypeForm_name"
                labelKey="member_types_name"
                field={name}
                placeholder={messages.member_types_placeholder}
                disabled={editMode ? memberType.category !== MEMBER_TYPE_CATEGORY_CUSTOM : editMode}
              >
                <FieldErrorMsg customClass="fieldErrorMsg--memberTypeForm" field={name} />
              </BoxedInput>

              <BoxedInput
                formRowItemKey="memberTypeForm_maximumScheduledBookings"
                type="text"
                id="memberTypeForm_maximumScheduledBookings"
                labelKey="member_types_maximum_scheduled_bookings"
                field={maximumScheduledBookings}
                placeholder="100"
              >
                <FieldErrorMsg customClass="fieldErrorMsg--memberTypeForm" field={maximumScheduledBookings} />
              </BoxedInput>
            </FormRow>
          </form>
        )}
        {!editMode && memberTypeDel && (
          <div>
            <FormattedHTMLMessage id="member_types_delete_labeled_message" values={{ name: memberTypeDel.name }} />
          </div>
        )}
      </Dialog>
    );
  }
}

MemberTypeForm.displayName = 'MemberTypeForm';

MemberTypeForm.propTypes = {
  memberType: PropTypes.object,
  fields: PropTypes.object,
  handleSubmit: PropTypes.func,
  resetForm: PropTypes.func
};

MemberTypeForm = reduxForm({
  form: 'memberTypeForm',
  fields: ['name', 'maximumScheduledBookings'],
  validate: createValidator({
    name: [
      stopValidationIf({
        condition: props => {
          return !!props.memberTypeDel;
        }
      }),
      notEmpty()
    ],
    maximumScheduledBookings: [
      stopValidationIf({
        condition: props => {
          return !!props.memberTypeDel;
        }
      }),
      notEmpty(),
      integer(),
      positiveNumber()
    ]
  })
})(MemberTypeForm);

export default connect(state => {
  const {
    memberTypes: { memberType, isOpen, editMode, memberTypeDel },
    user: { userInfo },
    companies: { currentCompany }
  } = state;

  return {
    editMode,
    isOpen,
    memberType,
    userInfo,
    currentCompany,
    memberTypeDel
  };
})(injectIntl(MemberTypeForm));
