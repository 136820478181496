import routes from '../../../constants/routes-constants';
import { routeActions } from 'react-router-redux';
import _includes from 'lodash/includes';
import { isValidId, openUrlBackgroundTab, safe } from '../../../utils/utils';
import urlJoin from 'url-join';
import React from 'react';
import AddIcon from 'material-ui/svg-icons/content/add';
import ContentCopy from 'material-ui/svg-icons/content/content-copy';
import { cssColors } from '../../../constants/style-constants';
import { getStore } from '../../../store/all-store';
import { FormattedMessage } from 'react-intl';
import { getMsg } from '../../../utils/IntlGlobalProvider';

export function getNextListPath(routeName, idName, newId) {
  return routes[routeName].path.replace(idName, encodeURIComponent(newId));
}

export function getCurrentPath() {
  const pathType = this.props.route.path.split('/').pop();
  return this.props.location.pathname.split(pathType).shift() + pathType;
}

export function showNextView(routeName, idName, newId, openInNewTab) {
  const currentPath = getCurrentPath.call(this);
  const childPath = getNextListPath(routeName, idName, newId);

  if (openInNewTab) {
    return openUrlBackgroundTab(urlJoin(window.location.origin, '#', currentPath, childPath));
  }
  this.props.dispatch(routeActions.push(urlJoin(currentPath, childPath)));
}

export function updateColumnFilters(filters) {
  const pathType = this.props.route.path.split('/').pop();

  const partial = this.props.location.pathname
    .split(pathType)
    .shift()
    .split('/')
    .slice(0, -2)
    .join('/');

  if (typeof filters === 'object') {
    filters = JSON.stringify(filters);
  }

  if (filters) {
    filters = encodeURIComponent(filters);
  }

  this.props.dispatch(routeActions.push(urlJoin(partial, filters, pathType)));
}

export function getParentPath() {
  const { routes } = this.props;
  const pathType = routes[routes.length - 2].path.split('/').pop();
  return this.props.location.pathname.split(pathType).shift() + pathType;
}

export function showParentView() {
  const { dispatch = getStore().dispatch } = this.props;
  const currentPath = getParentPath.call(this);
  dispatch(routeActions.push(currentPath));
}

export function getSelectedIds() {
  return this.props.location.pathname.split('/');
}

export function scrollToSelected() {
  safe(() =>
    getSelectedIds
      .call(this)
      .filter(isValidId)
      .forEach(id => document.getElementById(id).scrollIntoView())
  );
}

export function scrollToLastView() {
  document.querySelector('.list-wrap').scrollIntoView({ inline: 'end' });
}

export function resolveSelected({ id }) {
  return id && _includes(getSelectedIds.call(this), id);
}

export function resolveActive({ id }) {
  const paths = this.props.location.pathname.split('/');
  return id && id === paths.reverse().find(isValidId);
}

export function createMenuItems({ onAdd, addKey = 'common_add' }) {
  return [
    {
      id: 'add_button',
      labelKey: addKey,
      icon: <AddIcon color={cssColors.listItem} />,
      handleClick: onAdd
    }
  ];
}

export function createMenuItemsSites({ onAdd, addKey = 'common_add', onCopy, copyKey = 'common_copy' }) {
  if (onCopy && onAdd)
    return [
      {
        id: 'add_button',
        labelKey: addKey,
        icon: <AddIcon color={cssColors.listItem} />,
        handleClick: onAdd
      },
      {
        id: 'copy_button',
        labelKey: copyKey,
        icon: <ContentCopy color={cssColors.listItem} />,
        handleClick: onCopy
      }
    ];
  return [
    {
      id: 'add_button',
      labelKey: addKey,
      icon: <AddIcon color={cssColors.listItem} />,
      handleClick: onAdd
    }
  ];
}
export function getEditTitle(title) {
  return (
    <span>
      <FormattedMessage id="common_edit" /> - {title}
    </span>
  );
}

export function getAddTitle(title) {
  return (
    <span>
      <FormattedMessage id="common_add" /> - {title}
    </span>
  );
}

function getModalTitle(prefix, title) {
  return (
    <span>
      <strong>{prefix}</strong>
      <span> - </span>
      <span style={{ fontWeight: 300 }}>{title}</span>
    </span>
  );
}

export function getEditModalTitle(title) {
  return getModalTitle(getMsg('common_edit'), title);
}

export function getAddModalTitle(title) {
  return getModalTitle(getMsg('common_add'), title);
}

export function getFiltersTitle(title = getMsg('common_search')) {
  return (
    <span>
      <strong>{getMsg('common_filters_for')}</strong>
      <span> </span>
      {title}
    </span>
  );
}
