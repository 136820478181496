import React, { Component } from 'react';
import { connect } from 'react-redux';

import ContractForm from '../../components/ContractForm/ContractForm';
import { requestSaveCompanyContract, addFlashMessage } from '../../actions/all-actions';
import { FLASH_MESSAGE_TYPE_SUCCESS } from '../../constants/generic-constants';
import {
  BACKUSER_ROLE_ADMIN_DEALER,
  BACKUSER_ROLE_CALL_CENTER_OPERATOR,
  BACKUSER_ROLE_EXTERNAL_FINE_COMPANY,
  BACKUSER_ROLE_ZONE_MANAGER
} from '../../constants/backend-constants';
import { bundleSelector } from '../../selectors/all-selectors';
import { addErrorMessage } from '../../utils/utils';

import _get from 'lodash/get';

class Contract extends Component {
  constructor() {
    super();
    this.handleSaveContract = this.handleSaveContract.bind(this);
  }

  handleSaveContract(values) {
    const { dispatch, bundle, currentCompany: { id: companyId } = {} } = this.props;

    if (this.props.currentContract) {
      values.id = this.props.currentContract.id;
    }

    dispatch(requestSaveCompanyContract({ companyId, values })).then(
      () => {
        dispatch(
          addFlashMessage({
            contentKey: 'contract_save_success',
            type: FLASH_MESSAGE_TYPE_SUCCESS
          })
        );
      },
      error => {
        const msgParams = { bundle, error };
        dispatch(addErrorMessage(msgParams));
      }
    );
  }

  render() {
    const { currentCompany, currentContract, role } = this.props;

    let initialValues = !currentContract
      ? {}
      : {
          name: currentContract.name,
          reference: currentContract.reference,
          startDate: currentContract._contractStartDate,
          endDate: currentContract._contractEndDate,
          businessCarSharing: currentContract.businessCarSharing,
          privateCarSharing: currentContract.privateCarSharing,
          preBooking: currentContract.preBooking,
          rideSharing: currentContract.rideSharing,
          flexibleSearch: currentContract.flexibleSearch,
          interfaceConfigDto: _get(currentContract, 'interfaceConfigDto') || {}
        };

    return (
      <div className="contractPage mainContainer_content">
        <div className="pageContainer">
          <div className="contractPage_content">
            <section className="contractPage_management">
              <div className="contractPage_form">
                <ContractForm
                  company={currentCompany}
                  onCallback={this.handleSaveContract}
                  initialValues={initialValues}
                  limitedAccess={
                    role === BACKUSER_ROLE_CALL_CENTER_OPERATOR ||
                    role === BACKUSER_ROLE_ADMIN_DEALER ||
                    role === BACKUSER_ROLE_ZONE_MANAGER ||
                    role === BACKUSER_ROLE_EXTERNAL_FINE_COMPANY
                  }
                />
              </div>
            </section>
          </div>
        </div>
      </div>
    );
  }
}

Contract.displayName = 'Contract';

export default connect(state => {
  const {
    user: {
      userInfo: { role }
    },
    companies: { currentCompany, currentContract }
  } = state;

  return {
    currentCompany,
    currentContract,
    role,
    bundle: bundleSelector(state)
  };
})(Contract);
