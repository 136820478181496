import * as types from '../constants/actionTypes-constants';
import defaultBrand from '../../mocks/defaultBrand.json';
import _isArray from 'lodash/isArray';
import { addStrPart, getAppObj, getFileExt, getStorageData, safe, setStorageData } from '../utils/utils';
import { s3 } from '../constants/brand-styles';
import { PRODUCT_NAME, STORAGE_KEY } from '../constants/generic-constants';
import config from '../constants/config-constants';
import _isEqual from 'lodash/isEqual';

function setHeadTags({ siteIcon, siteTitle, productName }) {
  const titleFallback = addStrPart(productName);
  const titleText = siteTitle || titleFallback || PRODUCT_NAME;
  const iconUrl = s3(siteIcon || config.siteIcon);

  const faviconEl = document.getElementById('favicon');
  const titleEl = document.getElementById('head-title');

  if (faviconEl) faviconEl.href = iconUrl;
  if (titleEl) titleEl.innerHTML = titleText;

  sessionStorage.setItem('head-title', titleText);
  sessionStorage.setItem('favicon', iconUrl);
}

function addFont(fontUrl, fontWeight) {
  const fontExt = getFileExt(fontUrl);

  const fontFamilyStyle = "font-family: 'MainFont';";
  const fontUrlStyle = `src: url('${fontUrl}') format('${fontExt}');`;
  const fontWeightStyle = `font-weight: ${fontWeight};`;

  if (fontExt) {
    return `@font-face { ${fontFamilyStyle} ${fontUrlStyle} ${fontWeightStyle} } \n`;
  } else return '';
}

function removeOldFont() {
  const oldFontStyle = document.getElementById('main-font');

  if (oldFontStyle) {
    document.head.removeChild(oldFontStyle);
  }
}

function addNewFont({ light, regular, medium, bold }) {
  const newFontStyle = document.createElement('style');

  newFontStyle.id = 'main-font';
  newFontStyle.textContent = addFont(s3(light), 300) + addFont(s3(regular), 400) + addFont(s3(medium), 500) + addFont(s3(bold), 700);
  document.head.appendChild(newFontStyle);
}

function setFontData(config) {
  const { font } = config || {};
  const { regular } = font || {};

  if (regular) {
    removeOldFont();
    addNewFont(font);
  }
}

function setApiUrl(config) {
  safe(() => {
    window.sessionStorage.setItem(STORAGE_KEY.API_URL, config.apiBaseUrl);
    getAppObj().apiUrl = config.apiBaseUrl;
  });
}

function setDynamicData(config = {}) {
  setHeadTags(config);
  setFontData(config);
  setApiUrl(config);
}

export default function appBrands(
  state = {
    list: [],
    config: defaultBrand,
    configFetched: false,
    configError: false,
    routeLoaded: false,
    displayBanner: getStorageData(STORAGE_KEY.BANNER_DISPLAY),
    bannerStyle: {},
    bannerMessage: ''
  },
  action
) {
  switch (action.type) {
    case types.APP_BRANDS_UPDATE_BANNER_MESSAGE: {
      const data = { ...state, bannerMessage: action.message };

      if (!state.displayBanner) {
        const msg = getStorageData(STORAGE_KEY.BANNER_MSG);

        if (!_isEqual(msg, action.message)) {
          setStorageData(STORAGE_KEY.BANNER_DISPLAY, true);
          data.displayBanner = true;
        }
      }

      setStorageData(STORAGE_KEY.BANNER_MSG, action.message);
      return data;
    }

    case types.APP_BRANDS_UPDATE_BANNER_STYLE: {
      return { ...state, bannerStyle: action.style };
    }

    case types.APP_BRANDS_UPDATE_BANNER_DISPLAY: {
      setStorageData(STORAGE_KEY.BANNER_DISPLAY, action.display);
      return { ...state, displayBanner: action.display };
    }

    case types.APP_BRANDS_GET_LIST_SUCCESS: {
      const { list: apiData } = action;
      const list = _isArray(apiData) ? apiData.filter(item => item.id !== '00000000-0000-0000-0000-000000000000') : [];
      return { ...state, list };
    }

    case types.ORGANIZATION_CREATE_BRAND_SUCCESS: {
      return {
        ...state,
        list: [{ ...action.data }, ...state.list]
      };
    }

    case types.APP_BRANDS_CLEAR_LIST_SUCCESS: {
      return { ...state, list: [] };
    }

    case types.APP_BRANDS_UPDATE_ITEM_SUCCESS: {
      const { data } = action || {};
      const { list } = state || [];

      safe(() =>
        list.find((v, i, a) => {
          if (v.id === data.id) {
            return (a[i] = data);
          }
        })
      );

      return {
        ...state,
        list: safe(() => [...list]) || []
      };
    }

    case types.APP_BRANDS_ROUTE_LOADED: {
      return { ...state, routeLoaded: true };
    }

    case types.APP_BRANDS_UPDATE_CONFIG: {
      setDynamicData(action.config);
      return { ...state, config: action.config };
    }

    case types.APP_BRANDS_SET_CONFIG_FETCHED_STATE: {
      return { ...state, configFetched: action.state };
    }

    case types.APP_BRANDS_SHOW_ERROR: {
      return { ...state, configError: true };
    }

    default: {
      return state;
    }
  }
}
