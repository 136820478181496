import { routeActions } from 'react-router-redux';

import * as types from '../constants/actionTypes-constants';
import ApiCaller from '../api/ApiCaller';
import routes from '../constants/routes-constants';
import { INVALID_TOKEN } from '../constants/errors-constants';

import { getStore } from '../store/all-store';
import { logout } from './user-actions';

function redirectToLogin() {
  // for some reason, we need to use the dispatch function directly from the store or
  // the redirection will never happen.
  getStore().dispatch(routeActions.push(routes.login.path));
}

export default function callApi(resource, method, params = undefined, loader = true) {
  return dispatch => {
    if (loader) {
      dispatch({
        type: types.API_CALL_START,
        resource,
        method,
        params
      });
    }

    return ApiCaller(resource, method, params).then(
      result => {
        if (loader) endCall(dispatch, resource, method, params);
        return result;
      },
      (error = {}) => {
        if (loader) endCall(dispatch, resource, method, params);

        const loginPage = resource === 'user' && method === 'login';
        const errorCode = error.status || error.code;

        if (errorCode === 401 && !loginPage) {
          error = {
            type: INVALID_TOKEN,
            code: error.code
          };

          dispatch(logout());
          dispatch(saveRedirectLocation());

          redirectToLogin();
        }

        throw error;
      }
    );
  };
}

export function saveRedirectLocation() {
  return { type: types.SAVE_REDIRECT_LOCATION };
}

export function clearRedirectLocation() {
  return { type: types.CLEAR_REDIRECT_LOCATION };
}

function endCall(dispatch, resource, method, params) {
  dispatch({
    type: types.API_CALL_END,
    resource,
    method,
    params
  });
}

export function setToken(token) {
  return dispatch => {
    dispatch({
      type: types.API_SET_TOKEN_REQUEST,
      token
    });
    window.localStorage.setItem('token', token);
  };
}

export function unsetToken() {
  return dispatch => {
    dispatch({
      type: types.API_UNSET_TOKEN_REQUEST
    });
    window.localStorage.removeItem('token');
  };
}

export function startRedirectSpin() {
  return { type: types.START_PAYBACK_REDIRECT_SPIN };
}

export function stopRedirectSpin() {
  return { type: types.STOP_PAYBACK_REDIRECT_SPIN };
}

export function afterPaybackRedirectStart() {
  return dispatch => {
    dispatch({ type: types.AFTER_PAYBACK_REDIRECT_TRUE });
    dispatch(startRedirectSpin());
  };
}

export function afterPaybackRedirectFinish() {
  return dispatch => {
    dispatch({ type: types.AFTER_PAYBACK_REDIRECT_FALSE });
    dispatch(stopRedirectSpin());
  };
}

export function addApiCall() {
  return { type: types.API_CALL_START };
}

export function subApiCall() {
  return { type: types.API_CALL_END };
}
