import React, { Component } from 'react';
import { showParentView } from '../../../../../components/_v2/ColumnList/helpers';
import AddSubCompanyModal from '../../../../../modules/SubCompanies/containers/AddSubCompany/Modal';
import { safe } from '../../../../../utils/utils';

export class OrganizationAddSubCompany extends Component {
  componentWillMount() {
    this.showParentView = showParentView.bind(this);
  }

  render() {
    return <AddSubCompanyModal isOpen onClose={this.showParentView} companyId={safe(() => this.props.params.companyId)} />;
  }
}

export default OrganizationAddSubCompany;
