import React, { Component } from 'react';
import ColumnList from '../../../components/_v2/ColumnList';
import { connect } from 'react-redux';

import { createMenuItems, resolveActive, resolveSelected, showNextView, showParentView } from '../../../components/_v2/ColumnList/helpers';
import { FormattedMessage } from 'react-intl';
import { renderItem } from './helpers';
import { checkRole } from '../../../constants/backuser-role-rules';
import { editVehicleBrandsRules } from '../../../constants/routes-constants';
import { userRoleSelector } from '../../../selectors/all-selectors';

class VehicleModels extends Component {
  componentWillMount() {
    this.setCallbacks();
    this.setVars();
  }

  setVars() {
    if (checkRole(editVehicleBrandsRules, this.props.role)) {
      this.menuItems = createMenuItems({ onAdd: this.handleAdd, addKey: 'addModel_title' });
    }
  }

  setCallbacks() {
    this.handleSelect = ({ id }, _, { openInNewTab }) => {
      showNextView.call(this, 'vehicleVersions', ':modelId', id, openInNewTab);
    };

    this.handleEdit = ({ id }, _, { openInNewTab }) => {
      showNextView.call(this, 'vehicleEditModel', ':modelId', id, openInNewTab);
    };

    this.handleAdd = () => {
      showNextView.call(this, 'vehicleAddModel');
    };

    this.resolveSelected = resolveSelected.bind(this);
    this.resolveActive = resolveActive.bind(this);
    this.showParentView = showParentView.bind(this);
  }

  render() {
    return (
      <ColumnList
        title={<FormattedMessage id="common_models" />}
        inputRef={this.props.inputRef}
        items={this.props.list}
        renderItem={renderItem}
        onSelect={this.handleSelect}
        selected={this.resolveSelected}
        active={this.resolveActive}
        onEdit={this.handleEdit}
        menuItems={this.menuItems}
        onBack={this.showParentView}
        localSearchEnabled
      />
    );
  }
}

const mapStateToProps = state => {
  return {
    list: state.models.list,
    role: userRoleSelector(state)
  };
};

VehicleModels = connect(mapStateToProps)(VehicleModels);

export default VehicleModels;
