import * as types from '../constants/actionTypes-constants';

export default function hotlines(
  state = {
    list: [],
    currentHotline: {}
  },
  action
) {
  switch (action.type) {
    case types.HOTLINES_GET_HOTLINES_SUCCESS: {
      return Object.assign({}, state, {
        list: action.hotlines || []
      });
    }

    case types.HOTLINES_GET_DETAIL_HOTLINE_SUCCESS: {
      return Object.assign({}, state, {
        currentHotline: action.hotline || {}
      });
    }

    default: {
      return state;
    }
  }
}
