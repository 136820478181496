import React, { Component, PropTypes } from 'react';
import { FormattedMessage } from 'react-intl';
import _get from 'lodash/get';
import cs from 'classnames';
import { getAppFormattedDateTime, safe } from '../../utils/utils';
import Loader from '../MaterialLoader/MaterialLoader';
import CloseIcon from 'material-ui/svg-icons/navigation/close';
import RefreshIcon from 'material-ui/svg-icons/navigation/refresh';
import ExpandMore from 'material-ui/svg-icons/navigation/expand-more';
import ExpandLess from 'material-ui/svg-icons/navigation/expand-less';
import IconButton from 'material-ui/IconButton';
import autoBind from 'react-autobind';

import CopyToClipBoard from '../_v2/CopyToClipBoard/CopyToClipBoard';
import { getMsg } from '../../utils/IntlGlobalProvider';
import { MEMBER_EVENT_RESULT, MEMBER_PROFILE_ACTIONS } from '../../constants/backend-constants';

function rowDiff(event, index) {
  if (safe(() => event.diff.length)) {
    return [
      <tr key={'diff-' + _get(event, 'id') + '-' + index} className={cs('diff-detail', { 'show-diff': event.show })}>
        <td colSpan={5}>
          <table>
            <thead>
              <tr>
                <th> {getMsg('member-profile-history-diff-field')} </th>
                <th> {getMsg('member-profile-history-diff-old')}</th>
                <th> {getMsg('member-profile-history-diff-new')}</th>
              </tr>
            </thead>
            <tbody>
              {event.diff.map((d, i) => {
                return (
                  <tr key={'diff' + i + 'row' + i}>
                    <td>{d.field}</td>
                    <td>{d.oldValue}</td>
                    <td>{d.newValue}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </td>
      </tr>
    ];
  }
}

class MemberProfileHistoryList extends Component {
  constructor(props) {
    super(props);
    autoBind(this);

    this.statusClassNames = {
      good: 'event-status-good',
      error: 'event-status-error',
      other: 'event-status-other'
    };

    this.actionLabelKey = {
      create: 'member-create',
      update: 'member-update',
      migrate: 'member-migrate',
      approve: 'member-approve',
      reject: 'member-reject',
      anonymize: 'member-anonymize',
      cancel_anomymize: 'member-cancel_anomymize',
      suspend: 'member-suspend',
      unsuspend: 'member-unsuspend',
      reset_password: 'member-reset_password',
      update_password: 'member-update-password'
    };

    this.eventStatusClasses = {
      [MEMBER_EVENT_RESULT.SUCCESS]: this.statusClassNames.good,
      [MEMBER_EVENT_RESULT.ERROR]: this.statusClassNames.error
    };

    this.i18nKeyAction = {
      [MEMBER_PROFILE_ACTIONS.CREATE]: this.actionLabelKey.create,
      [MEMBER_PROFILE_ACTIONS.UPDATE]: this.actionLabelKey.update,
      [MEMBER_PROFILE_ACTIONS.MIGRATE]: this.actionLabelKey.migrate,
      [MEMBER_PROFILE_ACTIONS.APPROVE]: this.actionLabelKey.approve,
      [MEMBER_PROFILE_ACTIONS.REJECT]: this.actionLabelKey.reject,
      [MEMBER_PROFILE_ACTIONS.ANONYMIZE]: this.actionLabelKey.anonymize,
      [MEMBER_PROFILE_ACTIONS.CANCEL_ANONYMIZATION]: this.actionLabelKey.cancel_anomymize,
      [MEMBER_PROFILE_ACTIONS.SUSPEND]: this.actionLabelKey.suspend,
      [MEMBER_PROFILE_ACTIONS.UNSUSPEND]: this.actionLabelKey.unsuspend,
      [MEMBER_PROFILE_ACTIONS.RESET_PASSWORD]: this.actionLabelKey.reset_password,
      [MEMBER_PROFILE_ACTIONS.UPDATE_PASSWORD]: this.actionLabelKey.update_password
    };

    this.resultLabel = {
      [MEMBER_EVENT_RESULT.SUCCESS]: 'common_status_SUCCESS',
      [MEMBER_EVENT_RESULT.ERROR]: 'common_status_ERROR'
    };
  }

  componentWillMount() {
    const { historyList } = this.props;
    this.setState({ historyList: historyList });
  }

  componentWillReceiveProps(props) {
    const { historyList } = this.props;
    if (props.historyList !== this.props.historyList) this.setState({ historyList: historyList });
  }

  toggle(event, index) {
    event.show = !event.show;
    this.setState({ [index]: event.show });
  }

  action(hasDiff, index, event) {
    const label = getMsg(this.i18nKeyAction[event.action]);
    return (
      <span onClick={hasDiff ? () => this.toggle(event, index) : ''} className="expand-handler">
        {hasDiff && safe(() => label + ' (' + event.diff.length + ')')}
        {!hasDiff && label}
        {hasDiff && event.show && <ExpandLess />}
        {hasDiff && !event.show && <ExpandMore />}
      </span>
    );
  }

  hasErrorOrEmpty() {
    const { error, loading } = this.props;
    if (this.state.historyList.length === 0) {
      if (!loading) {
        return (
          <tr>
            <td colSpan="5">{error ? getMsg('member-profile-history-error') : getMsg('no-events')}</td>
          </tr>
        );
      }
    }
  }

  getErrorClass(eventStatus) {
    return this.eventStatusClasses[eventStatus] || this.statusClassNames.other;
  }

  render() {
    const { loading, label, closeMemberHistory, refresh } = this.props;
    let ret = [];

    return (
      <div className="memberView_profileHistory">
        {label ||
          refresh ||
          (closeMemberHistory && (
            <div className="memberView_header">
              <h4>
                {label && <FormattedMessage id={label} />}
                {refresh && (
                  <IconButton onClick={refresh} tooltip="refresh">
                    <RefreshIcon />
                  </IconButton>
                )}
                {closeMemberHistory && (
                  <IconButton onClick={closeMemberHistory}>
                    <CloseIcon className="closeView" />
                  </IconButton>
                )}
              </h4>
            </div>
          ))}

        <div className="events-list" id="shadow">
          <div className="table-shadow">
            <table>
              {this.state.historyList.length > 0 && (
                <thead>
                  <tr>
                    <th>{getMsg('common_date')}</th>
                    <th>{getMsg('common_action')}</th>
                    <th>{getMsg('member-profile-history-header-result')}</th>
                    <th>{getMsg('member-profile-history-header-source')}</th>
                    <th>{getMsg('member-profile-history-header-creator')}</th>
                  </tr>
                </thead>
              )}
              <tbody>
                {loading && (
                  <tr>
                    <td colSpan="5">
                      <Loader />
                    </td>
                  </tr>
                )}
                {this.hasErrorOrEmpty()}
                {this.state.historyList.map((event, index) => {
                  const hasDiff = safe(() => event.diff.length > 0);
                  const row = [
                    <tr key={_get(event, 'id', index)} className={cs({ diff: hasDiff, opened: event.show })} style={{ zIndex: index + 3 }}>
                      <td>
                        <span>{getAppFormattedDateTime(_get(event, 'date'), { local: true, longTime: true })}</span>
                      </td>
                      <td>{this.action(hasDiff, index, event)}</td>
                      <td className={this.getErrorClass(event.result)}>
                        <span>{getMsg(this.resultLabel[event.result])}</span>
                      </td>

                      <td>
                        <span>{event.source}</span>
                      </td>
                      <td>
                        <span className="clipboardHover">
                          {event.creatorEmail || '-'}
                          <CopyToClipBoard stringToCopy={event.creatorEmail} />
                        </span>
                      </td>
                    </tr>
                  ];

                  ret = [row];
                  if (safe(() => event.diff.length > 0)) {
                    const rowListDiff = rowDiff(event, index);
                    ret.push(rowListDiff);
                  }
                  return ret;
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}

MemberProfileHistoryList.displayName = 'MemberProfileHistoryList';

MemberProfileHistoryList.defaultProps = {
  historyList: [],
  loading: false,
  error: false
};

MemberProfileHistoryList.propTypes = {
  historyList: PropTypes.array,
  closeMemberHistory: PropTypes.func,
  error: PropTypes.bool,
  loading: PropTypes.bool
};

export default MemberProfileHistoryList;
