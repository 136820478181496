import React, { Component } from 'react';
import { reduxForm } from 'redux-form';
import { scrollToFirstError } from '../../../../utils/utils';
import LabeledCheckbox from '../../../../components/LabeledCheckbox/LabeledCheckbox';
import { headerContractPaymentValuesSelector } from '../../../../selectors/all-selectors';
import { RaisedButton } from 'material-ui';
import { getMsg } from '../../../../utils/IntlGlobalProvider';
import { FormattedMessage } from 'react-intl';

class PaymentSettings extends Component {
  componentWillMount() {
    this.onBankoutUncheck = value => {
      const { weeklyBankout, dailyBankout } = this.props.fields;

      if (!value) {
        if (weeklyBankout.value) weeklyBankout.onChange(false);
        if (dailyBankout.value) dailyBankout.onChange(false);
      }
    };
  }

  render() {
    const {
      fields: { bankout, weeklyBankout, dailyBankout, bookingSafetyDeposit, damageSafetyDeposit, voucher, stripeMigrate },
      limitedAccess
    } = this.props;

    return (
      <div className="contract-payment-info">
        <div className="sc-option-details">
          <div className="sc-flat-boxes">
            <LabeledCheckbox id="paymentBankout" onCheck={this.onBankoutUncheck} field={bankout} disabled={limitedAccess}>
              <FormattedMessage id="contract.payment.bankout" />
            </LabeledCheckbox>
            {bankout.value && (
              <div className="sc-option-tab">
                <LabeledCheckbox id="paymentBankoutDaily" field={dailyBankout} disabled={limitedAccess}>
                  <FormattedMessage id="contract.payment.bankout.daily" />
                </LabeledCheckbox>
                <LabeledCheckbox id="paymentBankoutweekly" field={weeklyBankout} disabled={limitedAccess}>
                  <FormattedMessage id="contract.payment.bankout.weekly" />
                </LabeledCheckbox>
              </div>
            )}
            <LabeledCheckbox id="paymentUploadList" field={bookingSafetyDeposit} disabled={limitedAccess}>
              <FormattedMessage id="contract.payment.safety.deposit.booking" />
            </LabeledCheckbox>
            <LabeledCheckbox id="paymentType" field={damageSafetyDeposit} disabled={limitedAccess}>
              <FormattedMessage id="contract.payment.safety.deposit.damage" />
            </LabeledCheckbox>
            <LabeledCheckbox id="paymentSso" field={voucher} disabled={limitedAccess}>
              <FormattedMessage id="side_menu_section_vouchers" />
            </LabeledCheckbox>
            <LabeledCheckbox id="paymentStripe" field={stripeMigrate} disabled={limitedAccess}>
              <FormattedMessage id="contract.payment.stripe" />
            </LabeledCheckbox>
          </div>
        </div>
        {!limitedAccess && (
          <div className="sc-actions">
            <RaisedButton label={getMsg('common_save')} primary type="button" onClick={this.props.handleSubmit(this.props.onCallback)} />
          </div>
        )}
      </div>
    );
  }
}

PaymentSettings = reduxForm(
  {
    onSubmitFail: scrollToFirstError,
    form: 'contractPaymentSettings',
    fields: ['bankout', 'weeklyBankout', 'dailyBankout', 'bookingSafetyDeposit', 'damageSafetyDeposit', 'voucher', 'stripeMigrate']
  },
  state => ({
    initialValues: headerContractPaymentValuesSelector(state)
  })
)(PaymentSettings);

export default PaymentSettings;
