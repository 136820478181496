import React, { Component, PropTypes } from 'react';
import { reduxForm } from 'redux-form';
import { COMPANY_PAYBACK_SETTINGS_FORM } from '../../constants/form-constants';
import { FormattedMessage } from 'react-intl';
import FormRow from '../FormRow/FormRow';
import BoxedInput from '../BoxedInput/BoxedInput';
import FieldErrorMsg from '../FieldErrorMsg/FieldErrorMsg';
import EkRadio from '../EkRadio/EkRadio';
import config from '../../constants/config-constants';
import { FREQUENCY_DAILY, FREQUENCY_MONTHLY, FREQUENCY_WEEKLY } from '../../constants/backend-constants';
import { BANK_TRANSFER_FORM_FIELDS } from '../../constants/generic-constants';
import { fieldValueSafe } from '../../utils/utils';
import { createValidator } from '../../validation/sync-validation';
import { bankTransferValidators } from '../../validation/paybackConfigValidators';
import { bankoutFrequencyOptionsSelector } from '../../selectors/all-selectors';

class BankTransferForm extends Component {
  getExecutionFieldStatus() {
    const { fields } = this.props;
    const { bankoutFrequency } = fields;
    return fieldValueSafe(bankoutFrequency) === FREQUENCY_WEEKLY;
  }

  getExecutionFieldRow() {
    if (this.getExecutionFieldStatus()) {
      const { fields, readOnly } = this.props;
      const { bankoutExecutionDay } = fields;

      return (
        <FormRow customClass="companyForm_row">
          <EkRadio
            disabled={readOnly}
            customContainerClass="radio-button-container"
            titleKey="bank_transfer_frequency_day"
            items={config.daysOfWeek}
            field={bankoutExecutionDay}
            namespace="bank-transfer-frequency"
          >
            <FieldErrorMsg field={bankoutExecutionDay} customClass="fieldErrorMsg--companyForm" />
          </EkRadio>
        </FormRow>
      );
    }
  }

  render() {
    const {
      fields: { bankoutLabel, timeBeforePayment, bankoutFrequency },
      frequencyOptions,
      handleSubmit,
      readOnly
    } = this.props;

    return (
      <div className="payback-forms">
        <form action="#" className="commission-params-form" onSubmit={handleSubmit}>
          <h4 className="companyDetail_title">
            <FormattedMessage id="payback_bank_transfer_parameters" />
          </h4>
          <FormRow customClass="companyForm_row">
            <BoxedInput readOnly={readOnly} labelKey="bank_transfer_label" type="text" field={bankoutLabel} id="bank-transfer-label">
              <FieldErrorMsg customClass="fieldErrorMsg--companyForm" field={bankoutLabel} />
            </BoxedInput>
            <BoxedInput
              readOnly={readOnly}
              labelKey="bank_transfer_time_before_payment"
              type="text"
              field={timeBeforePayment}
              id="bank-transfer-time-before-payment"
            >
              <FieldErrorMsg customClass="fieldErrorMsg--companyForm" field={timeBeforePayment} />
            </BoxedInput>
          </FormRow>
          <FormRow customClass="companyForm_row">
            <EkRadio
              disabled={readOnly}
              customContainerClass="radio-button-container"
              titleKey="bank_transfer_frequency"
              items={frequencyOptions}
              field={bankoutFrequency}
              namespace="bank-transfer-frequency"
            >
              <FieldErrorMsg field={bankoutFrequency} customClass="fieldErrorMsg--companyForm" />
            </EkRadio>
          </FormRow>
          {this.getExecutionFieldRow()}
        </form>
      </div>
    );
  }
}

BankTransferForm.propTypes = {
  readOnly: PropTypes.bool
};

export default reduxForm(
  {
    form: COMPANY_PAYBACK_SETTINGS_FORM,
    fields: BANK_TRANSFER_FORM_FIELDS,
    validate: createValidator(bankTransferValidators)
  },
  state => ({ frequencyOptions: bankoutFrequencyOptionsSelector(state) })
)(BankTransferForm);
