import React, { Component, PropTypes } from 'react';
import { FormattedMessage } from 'react-intl';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';

import FormRow from '../FormRow/FormRow';
import BoxedInput from '../BoxedInput/BoxedInput';
import EkButton from '../EkButton/EkButton';
import { scrollToFirstError } from '../../utils/utils';

class FinalizeBookingForm extends Component {
  constructor() {
    super();
  }

  render() {
    const {
      fields: { technicalComment },
      handleSubmit
    } = this.props;

    return (
      <section className="finalizeBookingFormWrapper">
        <form action="#" className="finalizeBookingForm" onSubmit={handleSubmit(this.props.onCallback)}>
          <div className="finalizeBookingForm_container">
            <h4 className="finalizeBookingForm_title">
              <FormattedMessage id="finalizeBookingForm_title" />
            </h4>

            <FormRow customClass="finalizeBookingForm_row">
              <BoxedInput
                formRowItemKey="finalizeBookingForm_technicalComment"
                id="technicalComment"
                customClass="boxedInput_inputText"
                labelKey="finalizeBookingForm_technicalComment"
                field={technicalComment}
              />
            </FormRow>

            <FormRow customClass="finalizeBookingForm_row">
              <EkButton
                type="button"
                skinType="reverse"
                formRowItemKey="finalizeBookingForm_abortBtn"
                customClass="finalizeBookingForm_button"
                onAction={this.props.onAbort}
              >
                <FormattedMessage id="finalizeBookingForm_abort_button" />
              </EkButton>

              <EkButton
                type="button"
                formRowItemKey="finalizeBookingForm_submitBtn"
                customClass="finalizeBookingForm_button"
                onAction={handleSubmit(this.props.onCallback)}
              >
                <FormattedMessage id="finalizeBookingForm_save_button" />
              </EkButton>
            </FormRow>
          </div>
        </form>
      </section>
    );
  }
}

FinalizeBookingForm.displayName = 'FinalizeBookingForm';

FinalizeBookingForm.propTypes = {
  fields: PropTypes.object,
  handleSubmit: PropTypes.func,
  onCallback: PropTypes.func,
  onAbort: PropTypes.func
};

FinalizeBookingForm = reduxForm({
  onSubmitFail: scrollToFirstError,
  form: 'finalizeBookingForm',
  fields: ['technicalComment']
})(FinalizeBookingForm);

export default connect(state => {
  const {} = state;
  return {};
})(FinalizeBookingForm);
