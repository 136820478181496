import callApi from './api-actions';
import { validCompanyIdSelector } from '../selectors/all-selectors';

export function backUserCheckEmailAvailability({ login }) {
  return dispatch => {
    const params = { email: login, page: { number: 1, size: 1 } };

    return dispatch(callApi('backUsers', 'search', params, false)).then(data => {
      return data;
    });
  };
}

export function checkMemberEmailAvailability({ login }) {
  return (dispatch, getState) => {
    const companyId = validCompanyIdSelector(getState());
    const params = { email: login, page: { number: 1, size: 1 }, companyIds: [companyId] };

    return dispatch(callApi('members', 'search', params, false)).then(data => {
      return data;
    });
  };
}
