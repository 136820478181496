/* eslint-disable react/no-set-state */

import React, { Component } from 'react';
import Dialog from 'material-ui/Dialog';

import autoBind from 'react-autobind';
import { reduxForm, getValues } from 'redux-form';
import LocationIcon from 'material-ui/svg-icons/communication/location-on';
import CalendarIcon from 'material-ui/svg-icons/action/date-range';
import TimeIcon from 'material-ui/svg-icons/device/access-time';
import UserIcon from 'material-ui/svg-icons/social/person';
import { FormattedHTMLMessage, FormattedMessage, injectIntl } from 'react-intl';
import _get from 'lodash/get';
import _merge from 'lodash/merge';
import _map from 'lodash/map';
import _find from 'lodash/find';
import _filter from 'lodash/filter';
import _includes from 'lodash/includes';
import _isEmpty from 'lodash/isEmpty';
import _flatten from 'lodash/flatten';
import _flattenDeep from 'lodash/flattenDeep';
import _compact from 'lodash/compact';
import _difference from 'lodash/difference';
import _padStart from 'lodash/padStart';
import _range from 'lodash/range';
// import _debounce from 'lodash/debounce';
import moment from 'moment';
import FlatButton from 'material-ui/FlatButton';
import CloseIcon from 'material-ui/svg-icons/navigation/close';
import IconButton from 'material-ui/IconButton';
import pure from 'recompose/pure';
import classnames from 'classnames';

import {
  clearCreateBookingModal,
  createBookingVehiclePlanning,
  toggleCreateBookingModal,
  getEstimatedPricesBooking,
  displayBookingPrice,
  cleanBookingPriceEstimation
} from '../VehiclePlanning.actions';
import { createValidator, notEmpty, stopValidationIf, addressOrSite, dateAfterOrEqualField } from '../../../validation/all-validation';
import {
  headerContractPaymentValuesSelector,
  localeSelector,
  planningBookingCustomFieldsSelector,
  vpAddBookingUsageOptionsSelector
} from '../../../selectors/all-selectors';
import {
  BOOKING_USAGE_TYPE_BUSINESS,
  BOOKING_USAGE_TYPE_PRIVATE,
  BOOKING_TYPE_CAR_SHARING,
  ONE_WAY_TRIP,
  ROUND_TRIP,
  VEHICLE_USAGE_TYPE_IMMEDIATE,
  VEHICLE_USAGE_TYPE_PLANNED,
  BACKUSER_ROLE_ZONE_MANAGER,
  BACKUSER_ROLE_ADMIN_DEALER
} from '../../../constants/backend-constants';
import {
  enhanceSiteList,
  createInitValuesCreateBooking,
  createDateForCreateBooking,
  createInitValuesCreateBookingHourMode,
  createCustomFieldsNames,
  createCustomFieldsValidators,
  namedCompose,
  scrollToFirstErrorDialog,
  daysListOfDatesRange,
  createSharedInitValuesCreateBooking,
  vehicleUsageSwitch,
  vehicleUsageFiltered,
  safe
} from '../../../utils/utils';
import { BACKUSER_ROLE_ADMIN, BACKUSER_ROLE_SUPER_ADMIN, BACKUSER_ROLE_ROOT } from '../../../constants/backend-constants';
import { enhanceBookingCreationRequestParams, enhanceBookingPricesRequestParams } from '../../../api/data-enhancer';
import EkButton from '../../../components/EkButton/EkButton';
import EkTextarea from '../../../components/EkTextarea/EkTextarea';
import FormRow from '../../../components/FormRow/FormRow';
import LabeledCheckbox from '../../../components/LabeledCheckbox/LabeledCheckbox';
import config from '../../../constants/config-constants';
import BoxedSelect from '../../../components/BoxedSelect/BoxedSelect';
import BoxedInput from '../../../components/BoxedInput/BoxedInput';
import AddressAutocomplete from '../../../components/AddressAutocomplete/AddressAutocomplete';
import EkRadio from '../../../components/EkRadio/EkRadio';
import FieldErrorMsg from '../../../components/FieldErrorMsg/FieldErrorMsg';
import CustomFieldsBooking from '../../../components/CustomFieldsBooking/CustomFieldsBooking';
import IsGranted from '../../../components/IsGranted/IsGranted';
import FormField from '../../../components/FormField';

class VehiclePlanningCreateBooking extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = { memberTypeSelectDisabled: false };
    this.hoursOptions = [];
    this.pickupMinutesOptions = config.allAvailableMinutes;
    this.returnMinutesOptions = config.allAvailableMinutes;
    this.setHoursOptions('pickupDate');
    this.setHoursOptions('returnDate');
    // this.__debounce = _debounce(this.getPrice, 2000);
    this.derivedStateFromProps(props, true);
  }

  // noinspection JSCheckFunctionSignatures
  componentWillReceiveProps(props) {
    this.derivedStateFromProps(props);
  }

  derivedStateFromProps(props, init) {
    this.updateMemberTypeOptions(props, init);
  }

  updateMemberTypeOptions(props, init) {
    const { memberTypes: newProp } = props;
    const { memberTypes: oldProp } = props;
    if (init || newProp !== oldProp) this.createMemberTypeOptions(newProp);
  }

  createMemberTypeOptions(memberTypes = []) {
    this.memberTypeOptions = memberTypes.map(type => ({
      label: type.name,
      value: type.id
    }));
  }

  handleClose() {
    this.props.dispatch(toggleCreateBookingModal());
    this.props.dispatch(clearCreateBookingModal());
  }

  renderSuggestFiltered(list) {
    const { fields } = this.props;
    let suggestList;
    if (fields.from.value) {
      suggestList = list.filter(obj => obj.id !== fields.from.value.id);
    }
    return suggestList;
  }

  componentDidUpdate(prevProps) {
    if (prevProps.values && prevProps.values.usageType !== this.props.values.usageType) {
      prevProps.values && prevProps.values.usageType === BOOKING_USAGE_TYPE_PRIVATE ? this.getPrice : '';
    }
    if (
      prevProps.values.pickupDate !== this.props.values.pickupDate ||
      prevProps.values.pickupDateHour !== this.props.values.pickupDateHour ||
      prevProps.values.pickupDateMin !== this.props.values.pickupDateMin ||
      prevProps.values.returnDate !== this.props.values.returnDate ||
      prevProps.values.returnDateHour !== this.props.values.returnDateHour ||
      prevProps.values.returnDateMin !== this.props.values.returnDateMin ||
      prevProps.values.memberTypeId !== this.props.values.memberTypeId
    ) {
      this.getPrice();
    }
  }

  hoursFiltered(streams, date) {
    const { fields } = this.props;
    const _streams = _filter(_get(streams, 'recurringSlots', false), { open: true });
    const isPickDate = date === 'pickupDate';
    const dateH = isPickDate ? fields.pickupDate.value : fields.returnDate.value;
    const hourH = isPickDate ? fields.pickupDateHour.value : fields.returnDateHour.value;
    const _streamsOpenSpecial = _filter(_get(streams, 'oneTimeSlots', false), { open: true });
    const d = moment(dateH).isoWeekday() - 1;
    const day = config.daysOfWeek[d];
    const now = moment();
    if (_streamsOpenSpecial) {
      _streamsOpenSpecial.map(specialDaysHoursStream => {
        if (
          moment(dateH).isBetween(
            moment(specialDaysHoursStream.startDate),
            moment(specialDaysHoursStream.endDate).subtract(1, 'days'),
            null,
            []
          )
        ) {
          specialDaysHoursStream.days = [];
          const daysList = daysListOfDatesRange(specialDaysHoursStream.startDate, specialDaysHoursStream.endDate);
          daysList.map(day =>
            specialDaysHoursStream.days.push(
              moment(day)
                .locale('en')
                .format('dddd')
                .toUpperCase()
            )
          );
          return specialDaysHoursStream;
        }
      });
    }
    if (_streams) {
      if (day) {
        const validStreams = _find(_streams, { days: [day.value] });
        const validSpecialStreams = _find(_streamsOpenSpecial, { days: [day.value] });
        let goodTimes = [];
        if (
          validSpecialStreams &&
          moment(dateH).isBetween(moment(validSpecialStreams.startDate), moment(validSpecialStreams.endDate).subtract(1, 'days'), null, [])
        ) {
          validSpecialStreams.timeIntervals.map(time => {
            const range = _range(Number(time.start.split(':')[0]), Number(time.end.split(':')[0]) + 1); //+ 1 as fn range exclude end
            const ranges = range.map(it => {
              return _padStart(it, 2, '0');
            });
            goodTimes.push(ranges);
          });
          return _flatten(goodTimes);
        }

        if (validStreams) {
          if (validStreams.prevTime) {
            validStreams.prevTime.map(time => {
              const range = _range(Number(time.start.split(':')[0]), Number(time.end.split(':')[0]) + 1); //+ 1 as fn range exclude end
              const ranges = range.map(it => {
                return _padStart(it, 2, '0');
              });
              goodTimes.push(ranges);
            });
          } else {
            validStreams.timeIntervals.map(time => {
              const range = _range(Number(time.start.split(':')[0]), Number(time.end.split(':')[0]) + 1); //+ 1 as fn range exclude end
              const ranges = range.map(it => {
                return _padStart(it, 2, '0');
              });
              goodTimes.push(ranges);
            });
          }

          if (now.isSame(dateH, 'day')) {
            if (now.isSame(moment(dateH).hour(hourH), 'hour')) {
              if (isPickDate) {
                this.pickupMinutesOptions = this.pickupMinutesOptions.reduce((acc, minutes) => {
                  if (Number(minutes) >= now.minute()) acc.push(minutes);
                  return acc;
                }, []);
              } else {
                this.returnMinutesOptions = this.returnMinutesOptions.reduce((acc, minutes) => {
                  if (Number(minutes) >= now.minute()) acc.push(minutes);
                  return acc;
                }, []);
              }
            }

            if (!now.isSame(moment(dateH).hour(hourH), 'hour')) {
              if (isPickDate) this.pickupMinutesOptions = config.allAvailableMinutes;
              else this.returnMinutesOptions = config.allAvailableMinutes;
            }

            return _flatten(goodTimes).reduce((acc, hour) => {
              if (hour >= now.hour()) acc.push(hour);
              return acc;
            }, []);
          }

          return _flatten(goodTimes);
        }
      }
    }
  }

  setHoursOptions(date) {
    const { firstHourSelected, firstDaySelected, secondDaySelected, fields } = this.props;
    const now = moment();
    const isPickDate = date === 'pickupDate';
    let hours = _get(firstHourSelected, 'parking.schedule', false);
    if (!hours) hours = _get(isPickDate ? firstDaySelected : secondDaySelected, 'parking.schedule', false);
    const dateH = isPickDate ? fields.pickupDate.value : fields.returnDate.value;
    const hourH = isPickDate ? fields.pickupDateHour.value : fields.returnDateHour.value;

    if (now.isSame(dateH, 'day')) {
      if (now.isSame(moment(dateH).hour(hourH), 'hour')) {
        if (isPickDate) {
          this.pickupMinutesOptions = this.pickupMinutesOptions.reduce((acc, minutes) => {
            if (Number(minutes) >= now.minute()) acc.push(minutes);
            return acc;
          }, []);
        } else {
          this.returnMinutesOptions = this.returnMinutesOptions.reduce((acc, minutes) => {
            if (Number(minutes) >= now.minute()) acc.push(minutes);
            return acc;
          }, []);
        }
      }
      if (!now.isSame(moment(dateH).hour(hourH), 'hour')) {
        if (isPickDate) this.pickupMinutesOptions = config.allAvailableMinutes;
        else this.returnMinutesOptions = config.allAvailableMinutes;
      }

      this.hoursOptions[date] = _flatten(config.availableHours).reduce((acc, hour) => {
        if (hour >= now.hour()) acc.push(hour);
        return acc;
      }, []);
    } else {
      this.hoursOptions[date] = config.availableHours;
    }

    this.hoursOptions[date] = hours ? this.hoursFiltered(hours, date) : this.hoursOptions[date];
  }

  findById(sites, id) {
    const schedule = sites.map(site => site.parkings.map(parking => (parking.id === id ? parking.schedule : '')));
    return _compact(_flattenDeep(schedule));
  }

  getClosedDays(date) {
    const { firstHourSelected, firstDaySelected, bankHolidays, data } = this.props;
    const parkingSchedule = _get(firstDaySelected, 'parking.schedule', _get(firstHourSelected, 'parking.schedule'));
    let closedDays = [];
    let openDays = [];
    let bankClosedDays = [];
    let closedRecurringDays = [];
    if (parkingSchedule) {
      if (parkingSchedule.applyFrenchBankHolidays) {
        bankClosedDays = _map(bankHolidays, day => {
          if (moment(date).isSame(moment(day.startDate), 'day')) {
            return moment(day.startDate).format('YYYY-MM-DD');
          }
        });
      }
      let oneTimeSlots = _filter(_get(parkingSchedule, 'oneTimeSlots', false), { open: false });
      if (oneTimeSlots) {
        closedDays = _map(oneTimeSlots, day => {
          if (moment(date).isBetween(moment(day.startDate), moment(day.endDate).subtract(1, 'days'), null, [])) {
            return daysListOfDatesRange(day.startDate, day.endDate);
          }
        });
      }

      let oneTimeSlotsOpen = _filter(_get(parkingSchedule, 'oneTimeSlots', false), { open: true });
      if (oneTimeSlotsOpen) {
        openDays = _map(oneTimeSlotsOpen, slot => {
          if (moment(date).isBetween(moment(slot.startDate), moment(slot.endDate).subtract(1, 'days'), null, [])) {
            return daysListOfDatesRange(slot.startDate, slot.endDate);
          }
        });
      }

      let initialRecurringSlots = _get(data, 'immutableParkings', false);
      let initialRecurringSlotsFiltered = this.findById(initialRecurringSlots, _get(firstHourSelected, 'parking.id', false));

      if (initialRecurringSlotsFiltered.length) {
        const weekDays = _flatten(initialRecurringSlotsFiltered[0].recurringSlots.map(item => item.days));
        let fullCloseDays = _compact(config.daysOfWeek.map(day => (!_includes(weekDays, day.value) ? day.value : '')));
        _map(fullCloseDays, day => {
          if (
            day ===
            moment(date)
              .locale('en')
              .format('dddd')
              .toUpperCase()
          ) {
            closedRecurringDays.push(moment(date).format('YYYY-MM-DD'));
          }
        });
      }

      let final = _merge(bankClosedDays, closedDays, closedRecurringDays);
      const finalClosed = _difference(_flatten(_compact(final)), _flatten(_compact(openDays)));
      return _includes(_flatten(_compact(finalClosed)), moment(date).format('YYYY-MM-DD'));
    }
  }

  getPrice(value) {
    const {
      fields: { usageType, memberTypeId },
      firstDaySelected,
      firstHourSelected,
      secondDaySelected,
      secondHourSelected,
      profileIdForCreateBooking
    } = this.props;

    value = value || usageType.value;

    const vehicleId = _get(firstHourSelected, 'vehicle.id') || _get(firstDaySelected, 'vehicle.id');

    if (value === BOOKING_USAGE_TYPE_PRIVATE) {
      let values = { ...this.props.values, usageType: value, memberTypeId: memberTypeId.value };

      let newParams = enhanceBookingPricesRequestParams(
        profileIdForCreateBooking,
        values,
        firstHourSelected,
        secondHourSelected,
        firstDaySelected,
        secondDaySelected
      );

      this.props.dispatch(getEstimatedPricesBooking(newParams)).then(data => {
        const item = _find(data.results, { vehicle: { id: vehicleId } });
        this.props.dispatch(displayBookingPrice(item));
      });
    } else if (value === BOOKING_USAGE_TYPE_BUSINESS) {
      this.props.dispatch(cleanBookingPriceEstimation());
    }
  }

  // updatePriceAuto() {
  //   this.__debounce();
  // }

  showVoucherButton() {
    const {
      paymentRules,
      fields: { usageType, voucherCode }
    } = this.props;

    if (paymentRules.voucher && usageType.value !== BOOKING_USAGE_TYPE_BUSINESS) {
      return (
        <FormRow>
          <BoxedInput
            formRowItemKey="searchVehicle_voucherCode"
            type="text"
            customClass="boxedInputWrapper"
            labelKey="common_searchFor_voucherCode"
            field={voucherCode}
          />
          <EkButton type="button" formRowItemKey="voucher-trigger" customClass="voucher_button" onAction={this.getPrice}>
            <FormattedMessage id="common_apply" />
          </EkButton>
        </FormRow>
      );
    }
  }

  displayPrice() {
    const {
      bookingPrice,
      loadingBookingPrice,
      fields: { usageType, paidBooking },
      intl: { formatMessage }
    } = this.props;

    const priceObj = bookingPrice || {};
    const carSharingInfo = priceObj.carSharingInfo || {};
    const cost = carSharingInfo.cost || {};
    const hasPrice = !_isEmpty(cost);
    const voucher = cost.voucherCode || null;
    const currency = cost.currency || 'EUR';
    const zeroTrick = !paidBooking.value ? 'neverbe' : 'estimatedPriceForDuration';
    const price_cost = _get(cost, voucher ? 'estimatedPriceWithoutVoucher' : zeroTrick, '0');
    const _amount = String(price_cost);
    const loader = (
      <div className="loading-price">
        <span>.</span>
        <span>.</span>
        <span>.</span>
      </div>
    );

    if (usageType.value !== BOOKING_USAGE_TYPE_BUSINESS) {
      if (hasPrice) {
        return (
          <FormRow>
            <div className="price">
              {loadingBookingPrice ? (
                loader
              ) : (
                <span className={voucher ? 'line-through' : ''}>
                  <FormattedHTMLMessage
                    id="common_price_with_currency"
                    values={{
                      amount: _amount,
                      currency: formatMessage({ id: `unit_${currency}` })
                    }}
                  />
                </span>
              )}
              {voucher && !loadingBookingPrice && (
                <FormattedHTMLMessage
                  className={voucher || !paidBooking.value ? 'line-through' : ''}
                  id="common_price_with_currency"
                  values={{
                    amount: !paidBooking.value ? '0' : String(_get(cost, 'estimatedPriceForDuration')),
                    currency: formatMessage({ id: `unit_${currency}` })
                  }}
                />
              )}
              {this.getChangeMemberTypeField()}
            </div>
          </FormRow>
        );
      }
      return (
        <FormRow>
          <div className="price">
            {loadingBookingPrice && loader}
            {priceObj && !loadingBookingPrice && <FormattedMessage id="common_status_ERROR" />}
            {this.getChangeMemberTypeField()}
          </div>
        </FormRow>
      );
    }
  }

  handleVehicleUsageTypeOptions() {
    const { firstDaySelected, firstHourSelected } = this.props;
    const usage = _get(firstDaySelected, 'vehicle.usage') || _get(firstHourSelected, 'vehicle.usage');

    if (vehicleUsageFiltered(usage)) {
      return [
        {
          labelKey: 'booking_usage_hint_business',
          value: BOOKING_USAGE_TYPE_BUSINESS
        },
        {
          labelKey: 'booking_usage_hint_private',
          value: BOOKING_USAGE_TYPE_PRIVATE
        }
      ];
    }

    return [
      {
        labelKey: 'insuranceContractForm_professional',
        value: BOOKING_USAGE_TYPE_BUSINESS,
        tooltipKey: 'booking_usage_hint_business'
      },
      {
        labelKey: 'insuranceContractForm_private',
        value: BOOKING_USAGE_TYPE_PRIVATE,
        tooltipKey: 'booking_usage_hint_private'
      }
    ];
  }

  handleSave() {
    const {
      loginForCreateBooking,
      VehiclePlanningCreateBooking,
      firstDaySelected,
      firstHourSelected,
      isPreBookingCreation,
      selectedMemberId,
      detailMember
    } = this.props;
    const memberDetails = { login: loginForCreateBooking, memberProfileId: selectedMemberId };
    const usageOverride = VehiclePlanningCreateBooking.usageOverride.value;

    const bookingDetails = {
      vehicle: Object.keys(firstDaySelected).length > 0 ? firstDaySelected.vehicle : firstHourSelected.vehicle,
      start: {
        date: createDateForCreateBooking(
          VehiclePlanningCreateBooking.pickupDate.value,
          VehiclePlanningCreateBooking.pickupDateHour.value,
          VehiclePlanningCreateBooking.pickupDateMin.value
        )
      },
      end: {
        date: createDateForCreateBooking(
          VehiclePlanningCreateBooking.returnDate.value,
          VehiclePlanningCreateBooking.returnDateHour.value,
          VehiclePlanningCreateBooking.returnDateMin.value
        )
      },
      carSharingInfo: {
        usageType: VehiclePlanningCreateBooking.usageType.value
      }
    };

    const confirmDetails = {
      paidBooking: VehiclePlanningCreateBooking.paidBooking.value,
      comment: VehiclePlanningCreateBooking.comment.value
    };

    const dataEnhanced = enhanceBookingCreationRequestParams(memberDetails, bookingDetails, confirmDetails, false);

    dataEnhanced.usageOverride = usageOverride;
    dataEnhanced.replacementVehicle = vehicleUsageSwitch(usageOverride);
    dataEnhanced.reservedSeats = 1;
    dataEnhanced.type = BOOKING_TYPE_CAR_SHARING;
    dataEnhanced.preBooking = isPreBookingCreation;
    if (!!safe(() => detailMember.enterprise.chorusData)) {
      const chorusData = {
        administration: true,
        chorusAlias: VehiclePlanningCreateBooking.chorusAlias.value,
        chorusEngagement: VehiclePlanningCreateBooking.chorusEngagement.value,
        chorusMarche: VehiclePlanningCreateBooking.chorusMarche.value,
        chorusService: VehiclePlanningCreateBooking.chorusService.value
      };
      dataEnhanced.chorusData = { ...chorusData };
    }
    if (_get(VehiclePlanningCreateBooking.from.value, '_isSite')) {
      dataEnhanced.start.parking = {
        id: VehiclePlanningCreateBooking.from.value.address.id
      };
    } else {
      dataEnhanced.start.coordinates = VehiclePlanningCreateBooking.from.value.coordinates;
      dataEnhanced.start.address = {
        country: VehiclePlanningCreateBooking.from.value.country,
        formatAddress: VehiclePlanningCreateBooking.from.value.formatAddress,
        postalCode: VehiclePlanningCreateBooking.from.value.postalCode
      };
    }

    if (VehiclePlanningCreateBooking.tripType.value === ONE_WAY_TRIP) {
      delete dataEnhanced.end;
    } else {
      if (_get(VehiclePlanningCreateBooking.to.value, '_isSite')) {
        dataEnhanced.end.parking = {
          id: VehiclePlanningCreateBooking.to.value.address.id
        };
      } else {
        dataEnhanced.end.coordinates = VehiclePlanningCreateBooking.to.value.coordinates;
        dataEnhanced.end.address = {
          country: VehiclePlanningCreateBooking.to.value.country,
          formatAddress: VehiclePlanningCreateBooking.to.value.formatAddress,
          postalCode: VehiclePlanningCreateBooking.to.value.postalCode
        };
      }
    }
    if (VehiclePlanningCreateBooking.voucherCode.value) {
      dataEnhanced.voucherCode = VehiclePlanningCreateBooking.voucherCode.value;
    }

    if (VehiclePlanningCreateBooking.memberTypeId.value) {
      dataEnhanced.memberTypeId = VehiclePlanningCreateBooking.memberTypeId.value;
    }

    this.props.dispatch(createBookingVehiclePlanning(dataEnhanced));
  }

  getTripTypeField() {
    const {
      fields: { tripType, usageOverride }
    } = this.props;
    if (usageOverride.value === VEHICLE_USAGE_TYPE_PLANNED || usageOverride.value === VEHICLE_USAGE_TYPE_IMMEDIATE)
      return (
        <div className="searchVehicle_tripSelection clearfix">
          <EkRadio
            formRowItemKey="backUserForm_userRole"
            items={[
              {
                labelKey: 'search_vehicle_oneWay',
                value: ONE_WAY_TRIP
              },
              {
                labelKey: 'search_vehicle_return',
                value: ROUND_TRIP
              }
            ]}
            namespace="tripType"
            field={tripType}
          >
            <FieldErrorMsg field={tripType} customClass="fieldErrorMsg--backUserForm" />
          </EkRadio>
        </div>
      );
  }

  getChangeMemberTypeField() {
    const {
      fields: { memberTypeId }
    } = this.props;

    return (
      <BoxedSelect
        blockCustomClass="vp_memberType_boxedSelectBlock"
        customClass="vp_memberType_boxedSelectWrapper"
        noEmptyValue
        options={this.memberTypeOptions}
        disabled={this.state.memberTypeSelectDisabled}
        labelKey="member_type_association_label"
        id="memberType_select"
        field={memberTypeId}
      />
    );
  }

  displayChorusFields() {
    const {
      fields: { chorusAlias, chorusEngagement, chorusMarche, chorusService },
      detailMember
    } = this.props;
    if (safe(() => detailMember.enterprise.chorusData.administration)) {
      return (
        <div className="specificities">
          <div className="label">
            <FormattedMessage id="members_detail_enterprise_chorus_detail" />
          </div>
          <FormField labelKey="members_detail_enterprise_chorus_chorusAlias" type="text" field={chorusAlias} maxlength="255" />
          <FormField labelKey="members_detail_enterprise_chorus_chorusEngagement" type="text" field={chorusEngagement} maxlength="255" />
          <FormField labelKey="members_detail_enterprise_chorus_chorusMarche" type="text" field={chorusMarche} maxlength="255" />
          <FormField labelKey="members_detail_enterprise_chorus_chorusService" type="text" field={chorusService} maxlength="255" />
        </div>
      );
    }
  }

  render() {
    const {
      sitesList,
      secondDaySelected,
      currentContract,
      openCreateBookingModal,
      secondHourSelected,
      customFields,
      handleSubmit,
      fields,
      loginForCreateBooking,
      userDetailCreateBooking,
      isPreBookingCreation,
      visibleUsageOptions,
      intl,
      fields: {
        from,
        to,
        pickupDate,
        pickupDateHour,
        pickupDateMin,
        returnDate,
        returnDateHour,
        returnDateMin,
        tripType,
        comment,
        paidBooking,
        usageType,
        voucherCode,
        usageOverride
      }
    } = this.props;

    const sitesListEnhanced = enhanceSiteList(sitesList);
    const now = new Date();
    const minReturnDate = pickupDate && typeof pickupDate.value === 'object' ? pickupDate.value : now;
    const vehicle = Object.keys(secondDaySelected).length > 0 ? secondDaySelected.vehicle : secondHourSelected.vehicle;
    const vehicleUsage = _get(vehicle, 'usage');
    this.setHoursOptions('pickupDate');
    this.setHoursOptions('returnDate');

    return (
      <Dialog
        modal={false}
        open={openCreateBookingModal}
        onRequestClose={this.handleClose}
        contentStyle={{ width: '600px', padding: 0 }}
        bodyStyle={{ padding: 0 }}
        style={{ padding: 0 }}
      >
        <form action="#" className="VehiclePlanningEditBooking" onSubmit={handleSubmit(this.handleSave)}>
          <div className="VehiclePlanningEditBooking_container VehiclePlanningCreateBooking">
            <div className="VehiclePlanningEditBooking_col1">
              <div className="title">
                <FormattedMessage id={isPreBookingCreation ? 'create_prebooking' : 'create_booking'} />
              </div>
              <div className="user-detail">
                <span className="user-name">
                  <UserIcon />
                  {userDetailCreateBooking}
                </span>
                <span className="user-mail" children={loginForCreateBooking} />
              </div>
              <div className="VehiclePlanningEditBooking_col1Row1">
                <div className="VehiclePlanningEditBooking_col1Row1Icons">
                  <div className="VehiclePlanningEditBooking_col1Row1Icons_bigDot" />
                  <div className="VehiclePlanningEditBooking_col1Row1Icons_smallDot" />
                  <div className="VehiclePlanningEditBooking_col1Row1Icons_smallDot" />
                  <div className="VehiclePlanningEditBooking_col1Row1Icons_smallDot" />
                  <LocationIcon />
                </div>
                <AddressAutocomplete field={from} fixtures={sitesListEnhanced} disabled />
                <AddressAutocomplete
                  field={tripType.value !== ONE_WAY_TRIP ? to : { value: '-' }}
                  fixtures={this.renderSuggestFiltered(sitesListEnhanced)}
                  disabled
                />

                {this.getTripTypeField()}
              </div>
              <div className="VehiclePlanningEditBooking_col1Row2 paddingTop0">
                <CalendarIcon />
                <BoxedInput
                  formRowItemKey="searchVehicle_from_pickupDate"
                  skinType="date"
                  type="date"
                  customClass="boxedInputWrapper--label _is_year_hidden VehiclePlanningEditBooking_col1Row2_pickupDate"
                  labelKey="search_vehicle_pickup"
                  field={pickupDate}
                  minDate={now}
                  disableYearSelection
                  shouldDisableDate={this.getClosedDays}
                >
                  <FieldErrorMsg field={pickupDate} customClass="fieldErrorMsg--searchVehicleForm" />
                </BoxedInput>

                <div className="VehiclePlanningEditBooking_col1Row2_dateWrap">
                  <TimeIcon />
                  <BoxedSelect
                    formRowItemKey="searchVehicle_from_pickupDateHour"
                    labelKey="search_vehicle_pickup_hour"
                    options={this.hoursOptions['pickupDate']}
                    customClass="searchVehicle_boxedSelectWrapper"
                    hideLabel
                    field={pickupDateHour}
                  />
                  <span className="VehiclePlanningEditBooking_col1Row2_dvojtecka">:</span>
                  <BoxedSelect
                    formRowItemKey="searchVehicle_from_pickupDateMin"
                    labelKey="search_vehicle_pickup_min"
                    options={this.pickupMinutesOptions}
                    customClass="searchVehicle_boxedSelectWrapper"
                    hideLabel
                    field={pickupDateMin}
                  />
                </div>
              </div>
              {tripType.value !== ONE_WAY_TRIP && (
                <div className="VehiclePlanningEditBooking_col1Row2">
                  <CalendarIcon />
                  <BoxedInput
                    formRowItemKey="searchVehicle_to_returnDate"
                    skinType="date"
                    type="date"
                    customClass="boxedInputWrapper--label VehiclePlanningEditBooking_col1Row2_pickupDate"
                    labelKey="search_vehicle_return_date"
                    field={returnDate}
                    minDate={minReturnDate}
                    mandatory={!(tripType.value === ONE_WAY_TRIP)}
                    disableYearSelection
                    disabled={tripType.value === ONE_WAY_TRIP}
                    shouldDisableDate={this.getClosedDays}
                  >
                    <FieldErrorMsg field={returnDate} customClass="fieldErrorMsg--searchVehicleForm" />
                  </BoxedInput>

                  <div className="VehiclePlanningEditBooking_col1Row2_dateWrap">
                    <TimeIcon />
                    <BoxedSelect
                      formRowItemKey="searchVehicle_from_pickupDateHour"
                      labelKey="search_vehicle_pickup_hour"
                      options={this.hoursOptions['returnDate']}
                      customClass="searchVehicle_boxedSelectWrapper"
                      hideLabel
                      field={returnDateHour}
                      disabled={tripType.value === ONE_WAY_TRIP}
                    />
                    <span className="VehiclePlanningEditBooking_col1Row2_dvojtecka">:</span>
                    <BoxedSelect
                      formRowItemKey="searchVehicle_from_pickupDateMin"
                      labelKey="search_vehicle_pickup_min"
                      options={this.returnMinutesOptions}
                      customClass="searchVehicle_boxedSelectWrapper"
                      hideLabel
                      field={returnDateMin}
                      disabled={tripType.value === ONE_WAY_TRIP}
                    />
                  </div>
                </div>
              )}
            </div>
            <div className="VehiclePlanningEditBooking_col2">
              <div className="vehiclePlanning_closeIconWrap">
                <IconButton onClick={this.handleClose}>
                  <CloseIcon />
                </IconButton>
              </div>
              <div className="box" id="box">
                <div className="VehiclePlanningEditBooking_col2CarWrap">
                  <img alt="" src={vehicle.picture} />
                  <div>
                    {vehicle.registrationNumber}
                    <br />
                    <span>
                      {vehicle.brand} {vehicle.model} ({vehicle.color})
                    </span>
                  </div>
                </div>

                {visibleUsageOptions.length && (
                  <div className="vehiclePlanning_usageSelector">
                    <BoxedSelect
                      labelKey="label_booking_usage"
                      noEmptyValue
                      options={visibleUsageOptions}
                      field={usageOverride}
                      fullWidth
                    />
                  </div>
                )}

                <div className={classnames('vehiclePlanning_typeWrapper', { fullrow: true })}>
                  {_get(currentContract, 'businessCarSharing') && _get(currentContract, 'privateCarSharing') && (
                    <EkRadio
                      formRowItemKey="createBooking_usageType"
                      items={this.handleVehicleUsageTypeOptions(vehicleUsage)}
                      onChange={this.getPrice}
                      namespace="usageType"
                      field={usageType}
                    >
                      <FieldErrorMsg field={usageType} customClass="fieldErrorMsg--backUserForm" />
                    </EkRadio>
                  )}

                  {usageType.value !== BOOKING_USAGE_TYPE_BUSINESS && (
                    <IsGranted
                      allowed={[
                        BACKUSER_ROLE_ADMIN,
                        BACKUSER_ROLE_SUPER_ADMIN,
                        BACKUSER_ROLE_ROOT,
                        BACKUSER_ROLE_ZONE_MANAGER,
                        BACKUSER_ROLE_ADMIN_DEALER
                      ]}
                    >
                      <LabeledCheckbox
                        id="addBooking_freeOfCharge"
                        customClass={classnames('BookingConfirmationForm_option', {
                          addspace: vehicleUsageFiltered(vehicleUsage)
                        })}
                        field={paidBooking}
                        containerStyle={{ width: '100%' }}
                      >
                        <FormattedMessage id="booking_create_confirmation_usage" />
                      </LabeledCheckbox>
                    </IsGranted>
                  )}
                </div>
                {this.displayPrice()}
                {this.showVoucherButton()}

                <CustomFieldsBooking customFields={customFields} customFieldsEditMode formFields={fields} usage={usageOverride.value} />
                {this.displayChorusFields()}
                <FormRow customClass="addBookingConfirmationForm_row">
                  <EkTextarea labelKey="booking_create_confirmation_comment" customClass="createBooking_textarea" field={comment} />
                </FormRow>

                <div className="addBookingConfirmationForm_buttonRow">
                  <FlatButton
                    key="yes"
                    onClick={this.handleClose}
                    className="flatButton"
                    label={intl.messages.booking_create_confirmation_abort_button}
                  />

                  <EkButton
                    type="button"
                    formRowItemKey="vehicleForm_step_next"
                    customClass="addBookingConfirmationForm_button"
                    onAction={handleSubmit(this.handleSave)}
                  >
                    <FormattedMessage id="booking_create_confirmation_save_button" />
                  </EkButton>
                </div>
              </div>
            </div>
          </div>
        </form>
      </Dialog>
    );
  }
}

export default namedCompose(
  reduxForm(
    {
      onSubmitFail: scrollToFirstErrorDialog,
      form: 'VehiclePlanningCreateBooking'
    },
    state => {
      const customFields = planningBookingCustomFieldsSelector(state);

      const {
        vehiclePlanning: {
          secondDaySelected,
          firstDaySelected,
          firstHourSelected,
          secondHourSelected,
          startDate,
          data,
          openCreateBookingModal,
          loginForCreateBooking,
          profileIdForCreateBooking,
          userDetailCreateBooking,
          isPreBookingCreation,
          selectedMemberId,
          bookingPrice,
          loadingBookingPrice
        },
        sites: { list },
        parkings: { bankHolidays },
        companies: { currentContract },
        members: { detailMember },
        form: { VehiclePlanningCreateBooking },
        memberTypes: { list: memberTypes }
      } = state;

      const dynamicInitialValues = _get(firstDaySelected, 'parking')
        ? createInitValuesCreateBooking(secondDaySelected.parking, firstDaySelected, secondDaySelected, startDate)
        : createInitValuesCreateBookingHourMode(secondHourSelected.parking, firstHourSelected, secondHourSelected, startDate);

      const sharedInitialValues = createSharedInitValuesCreateBooking(state);
      const { usageOverride } = getValues(VehiclePlanningCreateBooking) || {};
      const { chorusData } = detailMember.enterprise || {};

      return {
        fields: [
          'memberTypeId',
          'from',
          'to',
          'pickupDate',
          'pickupDateHour',
          'pickupDateMin',
          'returnDate',
          'returnDateHour',
          'returnDateMin',
          'passengers',
          'tripType',
          'comment',
          'voucherCode',
          'usageOverride',
          'paidBooking',
          'usageType',
          'chorusAlias',
          'chorusEngagement',
          'chorusMarche',
          'chorusService',
          ...createCustomFieldsNames(customFields)
        ],
        validate: createValidator({
          ...createCustomFieldsValidators(customFields, usageOverride),
          from: [notEmpty(), addressOrSite()],
          to: [
            stopValidationIf({
              condition: (props, value) => {
                return !value;
              }
            }),
            addressOrSite()
          ],
          pickupDate: [notEmpty()],
          tripType: [notEmpty()],
          returnDate: [
            stopValidationIf({
              condition: props => {
                return props.form.tripType && props.form.tripType.value === ONE_WAY_TRIP ? props.form.tripType.value : false;
              }
            }),
            notEmpty(),
            dateAfterOrEqualField('pickupDate')
          ]
        }),
        customFields,
        data,
        secondDaySelected,
        sitesList: list,
        currentContract,
        VehiclePlanningCreateBooking,
        bankHolidays,
        openCreateBookingModal,
        loginForCreateBooking,
        profileIdForCreateBooking,
        userDetailCreateBooking,
        isPreBookingCreation,
        firstDaySelected,
        secondHourSelected,
        firstHourSelected,
        memberTypes,
        selectedMemberId,
        bookingPrice,
        detailMember,
        loadingBookingPrice,
        paymentRules: headerContractPaymentValuesSelector(state),
        visibleUsageOptions: vpAddBookingUsageOptionsSelector(state),
        initialValues: {
          ...dynamicInitialValues,
          ...sharedInitialValues,
          ...chorusData
        },
        locale: localeSelector(state) // pure exception
      };
    }
  ),
  injectIntl,
  pure
)(VehiclePlanningCreateBooking);
