import * as types from '../../constants/actionTypes-constants';
import callApi from '../../actions/api-actions';

import { selectCompanyId, append, trySet, trimValues, addErrorMessage } from '../../utils/utils';
import { FLASH_MESSAGE_TYPE_SUCCESS } from '../../constants/generic-constants';
import { addFlashMessage } from '../../actions/all-actions';
import { bundleSelector } from '../../selectors/all-selectors';

export function getMemberTypesList(values = false, loader = false) {
  return (dispatch, getState) => {
    const state = getState();
    const params = { ...values };

    if (!params.companyId) {
      trySet(params, 'companyId', append([selectCompanyId(state)]));
    }
    if (!params.companyId) {
      dispatch(addErrorMessage({ error: { developerMessage: 'getMemberTypesList: no company ID selected' } }));
      return Promise.resolve([]);
    }

    dispatch({ type: types.MEMBER_TYPES_GET_LIST_REQUEST });

    return dispatch(callApi('memberTypes', 'getList', params, loader))
      .then(function(res) {
        const first = 'DEFAULT';

        res.sort((x, y) => {
          return x.category === first ? -1 : y === first ? 1 : 0;
        });

        dispatch({ type: types.MEMBER_TYPES_GET_LIST_SUCCESS, list: res });
        return Promise.resolve(res);
      })
      .catch(error => {
        dispatch({ type: types.MEMBER_TYPES_GET_LIST_ERROR, error });
        dispatch(addErrorMessage({ error }));
        return Promise.resolve([]);
      });
  };
}

export function clearMemberTypesListData() {
  return {
    type: types.MEMBER_TYPES_CLEAR_LIST_DATA
  };
}

export function openEditMemberTypeModal() {
  return {
    type: types.MEMBER_TYPE_EDIT_OPEN_MODAL
  };
}

export function closeEditMemberTypeModal() {
  return {
    type: types.MEMBER_TYPE_EDIT_CLOSE_MODAL
  };
}

export function editMemberType(memberType) {
  return {
    type: types.MEMBER_TYPES_EDIT_MODE,
    memberType
  };
}

export function saveEditMemberType(MemberType, loader = false) {
  return (dispatch, getState) => {
    const state = getState();
    const id = state.memberTypes.memberType.id;
    const bundle = bundleSelector(state);

    const { name, maximumScheduledBookings } = trimValues(state.form.memberTypeForm);

    let params = {
      id,
      name,
      maximumScheduledBookings
    };
    return dispatch(callApi('memberTypes', 'update', params, loader)).then(
      function(res) {
        dispatch({
          type: types.MEMBER_TYPE_EDIT_SUCCEEDED_MODAL,
          memberType: res
        });
        dispatch({
          type: types.MEMBER_TYPE_EDIT_CLOSE_MODAL
        });
        dispatch(
          addFlashMessage({
            contentKey: 'member_type_edition_success',
            type: FLASH_MESSAGE_TYPE_SUCCESS
          })
        );
      },
      error => {
        const msgParams = { bundle, error };
        dispatch(addErrorMessage(msgParams));
      }
    );
  };
}

export function openCreateMemberTypeModal() {
  return dispatch => {
    dispatch({
      type: types.MEMBER_TYPE_CREATE_OPEN_MODAL,
      newItem: true
    });
  };
}

export function closeMemberTypeModal() {
  return {
    type: types.MEMBER_TYPE_CLOSE_MODAL
  };
}

export function createMemberTypeRequest() {
  return (dispatch, getState) => {
    const state = getState();

    const { name, maximumScheduledBookings } = trimValues(state.form.memberTypeForm);

    const { id } = state.companies.currentCompany;
    const bundle = bundleSelector(state);

    let params = {
      name,
      maximumScheduledBookings,
      companyId: id
    };

    dispatch({
      type: types.MEMBER_TYPE_CREATE_REQUEST,
      params
    });

    return dispatch(callApi('memberTypes', 'create', params, false)).then(
      res => {
        dispatch(createMemberTypeSuccess(res));
        dispatch(closeMemberTypeModal());
        dispatch(
          addFlashMessage({
            contentKey: 'member_type_creation_success',
            type: FLASH_MESSAGE_TYPE_SUCCESS
          })
        );
      },
      error => {
        const msgParams = { bundle, error, def: 'member_type_creation_failed' };
        dispatch(addErrorMessage(msgParams));
      }
    );
  };
}

export function createMemberTypeSuccess(memberType) {
  return {
    type: types.MEMBER_TYPE_CREATE_SUCCESS,
    newItem: memberType
  };
}

export function confirmMemberTypeDeletionModal(memberType) {
  return {
    type: types.MEMBER_TYPE_DELETE_OPEN_MODAL,
    memberTypeDel: memberType
  };
}

export function removeMemberTypeRequest(memberType, loader = false) {
  return dispatch => {
    const params = {
      memberTypeId: memberType.id,
      memberTypeName: memberType.name
    };

    dispatch({
      type: types.MEMBER_TYPE_CLOSE_MODAL
    });
    dispatch({
      type: types.MEMBER_TYPE_DELETE_REQUEST,
      params
    });

    return dispatch(callApi('memberTypes', 'remove', params.memberTypeId, loader)).then(function() {
      dispatch({
        type: types.MEMBER_TYPE_DELETE_SUCCESS,
        params
      });
    });
  };
}
