import React, { Component } from 'react';
import autoBind from 'react-autobind';
import pure from 'recompose/pure';
import { floatPrecision, isEmpty, namedCompose } from '../../utils/utils';
import { FormattedMessage, injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { currentContractVehicleValuesSelector, localeSelector } from '../../selectors/all-selectors';
import _get from 'lodash/get';
import routes, { subCompanyRules } from '../../constants/routes-constants';
import Tooltip from '../Tooltip/Tooltip';
import _isNumber from 'lodash/isNumber';
import shortid from 'shortid';
import { isGranted } from '../../actions/user-actions';
import None from '../None';

class BookingVehicleInfo extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.table = new Map();
    this.derivedStateFromProps(props, true);
  }

  // eslint-disable-next-line no-unused-vars
  componentWillReceiveProps(nextProps, nextContext) {
    this.derivedStateFromProps(nextProps);
  }

  derivedStateFromProps(nextProps) {
    const {
      vehicleId,
      vehicleCompanyId,
      vehicleCompanyName,
      registrationNumber,
      vehicleCategoryType,
      startMileage,
      endMileage,
      startFuel,
      endFuel,
      vehicleRules
    } = nextProps;

    let vehicleName = (_get(nextProps, 'vehicleBrand', '') + ' ' + _get(nextProps, 'vehicleModel', '')).trim();
    const vehicleHref = vehicleId ? `#${routes.vehicleDetail.path.replace(':vehicleId', vehicleId)}` : '';
    const milage = this.computeMilageRange(startMileage, endMileage);
    const fuelLevel = this.getFuelRange(startFuel, endFuel);

    this.companyHref =
      vehicleCompanyId &&
      isGranted({
        disallowed: subCompanyRules.exclude,
        allowed: subCompanyRules.include
      })
        ? `#${routes.subCompanyDetails.path.replace(':subCompanyId', vehicleCompanyId)}`
        : '';

    this.companyName = vehicleCompanyName || vehicleCompanyId;

    this.addTableRow('booking_detail_vehicle_model', vehicleName);
    this.addTableRow('common_company', this.companyName, this.companyHref);
    this.addTableRow('booking_detail_vehicle_registration_number', registrationNumber, vehicleHref);

    if (vehicleRules.vehicleCategory) {
      this.addTableRow('vehiclesCategories_tableView_label_name', vehicleCategoryType);
    }

    this.addTableRow('vehicle_trip_milage', milage);
    this.addTableRow('vehicle_detail_device_status_fuelLevel', fuelLevel);

    if (!this.checkRender()) return false;
    this.prepareRender();
  }

  computeValue(value, label) {
    if (value)
      return (
        <Tooltip content={<FormattedMessage id={label} />} placement="bottom">
          <span>{value}</span>
        </Tooltip>
      );
  }

  getDash(value1, value2) {
    if (value1 && value2) return <span className="detailView_dash">-</span>;
  }

  computeMilageRange(value1, value2) {
    if (_isNumber(value1) && _isNumber(value2)) return floatPrecision(value2 - value1, 2);
  }

  getFuelRange(value1, value2) {
    if (value1 || value2)
      return (
        <div>
          {this.computeValue(value1, 'common_start')}
          {this.getDash(value1, value2)}
          {this.computeValue(value2, 'common_end')}
        </div>
      );
  }

  prepareRender() {
    this.rows = [];
    this.table.forEach((v, k) => {
      this.rows.push(this.displayRow(k, v.value, v.href, shortid.generate()));
    });
  }

  addTableRow(key, value, href) {
    const row = this.table.get(key);

    if (!isEmpty(value)) {
      if (row) {
        row['value'] = value;
        row['href'] = href;
      } else {
        this.table.set(key, {
          value: value,
          href: href
        });
      }
    } else if (row) this.table.delete(key);
  }

  checkRender() {
    this.componentCanRender = this.table.size > 0;
    return this.componentCanRender;
  }

  addUrl(child, href) {
    if (href) return <a href={href}>{child}</a>;
    else return child;
  }

  displayRow(label, value, href, key) {
    if (!isEmpty(value))
      return (
        <tr className="detailView_tr" key={key}>
          <td className="detailView_td_type_1">
            <span className="detailView_cell_type_1">
              <FormattedMessage id={label} />
            </span>
          </td>
          <td className="detailView_td_type_1">{this.addUrl(<span className="detailView_cell_type_1">{value}</span>, href)}</td>
        </tr>
      );
  }

  render() {
    if (this.componentCanRender)
      return (
        <div className={`bookingVehicleInfo`}>
          <h4 className="bookingVehicleInfo_headline">
            <FormattedMessage id="booking_detail_title_vehicle_information" />
          </h4>
          <div className="bookingVehicleInfo_body">
            <table>
              <tbody>{this.rows}</tbody>
            </table>
          </div>
        </div>
      );
    else return <None />;
  }
}

// -----

BookingVehicleInfo.propTypes = {
  vehicleModel: PropTypes.string,
  vehicleBrand: PropTypes.string,
  vehicleId: PropTypes.string,
  vehicleCompanyId: PropTypes.string,
  vehicleCompanyName: PropTypes.string,
  registrationNumber: PropTypes.string,
  vehicleCategoryType: PropTypes.string,
  startMileage: PropTypes.number,
  endMileage: PropTypes.number,
  startFuel: PropTypes.string,
  endFuel: PropTypes.string
};

// -----

export default namedCompose(
  connect(state => {
    return {
      locale: localeSelector(state),
      vehicleRules: currentContractVehicleValuesSelector(state)
    };
  }),
  injectIntl,
  pure
)(BookingVehicleInfo);
