import themeConstants from './theme-constants';

const { colors } = themeConstants;

export default {
  fontFamily: 'inherit',
  palette: {
    primary1Color: colors.primary1Color,
    primary2Color: colors.primary2Color,
    accent1Color: colors.accent1Color,
    pickerHeaderColor: colors.pickerHeaderColor
  }
};
