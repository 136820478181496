import * as types from '../constants/actionTypes-constants';

const defaultState = {
  lastBookings: { count: -1, companyId: '' },
  delayedBookings: { count: -1, companyId: '' },
  nonValidatedMembers: { count: -1, companyId: '' },
  lowFuelLevel: { count: -1, companyId: '' },
  unmanagedDamages: { count: -1, companyId: '' },
  cleanlinessProblems: { count: -1, companyId: '' },
  failedBookings: { count: -1, companyId: '' },
  expediteMembers: { count: -1, companyId: '' },
  rrsFailedBookings: { count: -1, companyId: '' },
  lowAccessoryBatVehicles: { count: -1, companyId: '' },
  nonOperatingVehicles: { count: -1, companyId: '' }
};

export default function dashboard(state = defaultState, action) {
  switch (action.type) {
    case types.DASHBOARD_RESET_ITEMS_COUNT: {
      return defaultState;
    }

    case types.DASHBOARD_GET_LAST_BOOKINGS_SUCCESS: {
      return { ...state, lastBookings: { count: action.lastBookings, companyId: action.lastCompanyId } };
    }
    case types.DASHBOARD_GET_DELAYED_BOOKINGS_SUCCESS: {
      return { ...state, delayedBookings: { count: action.delayedBookings, companyId: action.lastCompanyId } };
    }
    case types.DASHBOARD_GET_NON_VALIDATED_USER_SUCCESS: {
      return { ...state, nonValidatedMembers: { count: action.nonValidatedMembers, companyId: action.lastCompanyId } };
    }
    case types.DASHBOARD_GET_UNMANAGED_DAMAGES_SUCCESS: {
      return { ...state, unmanagedDamages: { count: action.unmanagedDamages, companyId: action.lastCompanyId } };
    }
    case types.DASHBOARD_GET_CLEANLINESS_PROBLEMS_SUCCESS: {
      return { ...state, cleanlinessProblems: { count: action.cleanlinessProblems, companyId: action.lastCompanyId } };
    }
    case types.DASHBOARD_GET_FAILED_BOOKINGS_SUCCESS: {
      return { ...state, failedBookings: { count: action.failedBookings, companyId: action.lastCompanyId } };
    }
    case types.DASHBOARD_GET_EXPEDITE_MEMBERS_SUCCESS: {
      return { ...state, expediteMembers: { count: action.expediteMembers, companyId: action.lastCompanyId } };
    }
    case types.DASHBOARD_GET_FAILED_RRS_BOOKINGS_SUCCESS: {
      return { ...state, rrsFailedBookings: { count: action.rrsFailedBookings, companyId: action.lastCompanyId } };
    }
    case types.DASHBOARD_GET_LOW_ACCESSORY_BATTERIE_VEHICLES_SUCCESS: {
      return { ...state, lowAccessoryBatVehicles: { count: action.lowAccessoryBatVehicles, companyId: action.lastCompanyId } };
    }
    case types.DASHBOARD_GET_NON_OPERATING_VEHICLES_SUCCESS: {
      return { ...state, nonOperatingVehicles: { count: action.nonOperatingVehicles, companyId: action.lastCompanyId } };
    }
    case types.DASHBOARD_GET_LOW_FUEL_CHARGE_LEVEL_SUCCESS: {
      return { ...state, lowFuelLevel: { count: action.lowFuelLevel, companyId: action.lastCompanyId } };
    }
    default:
      return state;
  }
}
