import _pickBy from 'lodash/pickBy';
import * as types from '../constants/actionTypes-constants';
import callApi from './api-actions';
import { trimValues } from '../utils/utils';

export function getConfigurationList(params, loader = false) {
  return (dispatch, getState) => {
    const state = getState();

    let paramsObj;

    if (params) {
      let pageNumber = params.page ? params.page.number : state.configuration.paginatedResults.metadata.paginationInfo.currentPage;
      let pageSize = params.page ? params.page.size : state.configuration.paginatedResults.metadata.paginationInfo.pageSize;

      paramsObj = {
        page: {
          number: pageNumber,
          size: pageSize
        }
      };
    } else {
      paramsObj = null;
    }

    dispatch({
      type: types.CONFIGURATION_GET_LIST_REQUEST
    });

    return dispatch(callApi('configuration', 'getList', paramsObj, loader)).then(function(data) {
      dispatch({
        type: types.CONFIGURATION_GET_LIST_SUCCESS,
        paginatedObj: data,
        urlParams: paramsObj
      });
      return data;
    });
  };
}

export function getAllConfigurationList() {
  return dispatch => {
    let paramsObj = {
      page: {
        number: 1,
        size: 1000
      }
    };

    dispatch({
      type: types.CONFIGURATION_GET_ALL_LIST_REQUEST
    });

    return dispatch(callApi('configuration', 'getList', paramsObj)).then(function(data) {
      dispatch({
        type: types.CONFIGURATION_GET_ALL_LIST_SUCCESS,
        configurations: data.results
      });
      return data;
    });
  };
}

export function createConfigurationRequest() {
  return (dispatch, getState) => {
    const state = getState();

    const params = Object.assign(
      {},
      _pickBy(trimValues(state.form.ConfigurationView), function(value) {
        return value !== '' && value !== null && typeof value !== 'undefined';
      })
    );

    dispatch({
      type: types.CONFIGURATION_CREATE_CONFIGURATION_REQUEST,
      params
    });

    return dispatch(callApi('configuration', 'createConfiguration', params));
  };
}

export function createConfigurationSuccess(configuration) {
  return {
    type: types.CONFIGURATION_CREATE_CONFIGURATION_SUCCESS,
    configuration
  };
}

export function getConfigurationDetail(id) {
  return dispatch => {
    dispatch({
      type: types.CONFIGURATION_GET_DETAIL_CONFIGURATION_REQUEST
    });

    return dispatch(callApi('configuration', 'getConfigurationDetail', id)).then(function(data) {
      dispatch({
        type: types.CONFIGURATION_GET_DETAIL_CONFIGURATION_SUCCESS,
        configuration: data
      });
      return data;
    });
  };
}

export function updateConfigurationRequest(configuration) {
  return (dispatch, getState) => {
    const state = getState();

    const params = Object.assign(
      {},
      _pickBy(trimValues(state.form.ConfigurationView), function(value) {
        return value !== '' && value !== null && typeof value !== 'undefined';
      })
    );

    dispatch({
      type: types.CONFIGURATION_UPDATE_CONFIGURATION_REQUEST,
      params
    });

    return dispatch(callApi('configuration', 'updateConfiguration', { configurationId: configuration.id, params }));
  };
}

export function updateConfigurationSuccess(configuration) {
  return {
    type: types.CONFIGURATION_UPDATE_CONFIGURATION_SUCCESS,
    configuration
  };
}
