// This component tries to create an abstracton of a field in a form regardless of its type
// It tries to reuse what already exists by mapping their API, this way it can offer a consistent API regardless of the type of input

import React, { PropTypes as T } from 'react';
import cn from 'classnames';
import { compose, mapProps } from 'recompose';

import BoxedSelect from '../BoxedSelect/BoxedSelect';
import BoxedInput from '../BoxedInput/BoxedInput';
import FieldErrorMsg from '../FieldErrorMsg/FieldErrorMsg';
import EkRadio from '../EkRadio/EkRadio';

// handle API differences
const EkRadioMapper = compose(
  mapProps(({ label, customLabelClass, mandatory, ...props }) => {
    return {
      customTitleClass: customLabelClass,
      title: label,
      mandatory,
      ...props
    };
  })
)(EkRadio);

const BoxedSelectMapper = compose(mapProps(props => props))(BoxedSelect);

const BoxedInputMapper = compose(
  mapProps(({ customLabelClass, ...props }) => ({
    customLabelTextClass: customLabelClass,
    ...props
  }))
)(BoxedInput);

function FormField({ wrapperClass, type, ...props }) {
  let input = null;
  const errorField = <FieldErrorMsg field={props.field} customClass="fieldErrorMsg--vehicleForm" />;

  switch (type) {
    case 'select':
      input = <BoxedSelectMapper {...props} children={errorField} />;
      break;
    case 'number':
    case 'date':
    case 'text':
      input = <BoxedInputMapper type={type} children={errorField} {...props} />;
      break;
    case 'radio':
      input = <EkRadioMapper {...props} children={errorField} />;
      break;
    default:
      return null;
  }

  return <div className={cn(wrapperClass || 'formFieldWrapper')}>{input}</div>;
}

FormField.displayName = 'FormField';
FormField.propTypes = {
  wrapperClass: T.string,
  field: T.object,
  type: T.oneOf(['select', 'text', 'date', 'number', 'radio']).isRequired
};

export default FormField;
