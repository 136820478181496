import React, { Component } from 'react';
import { connect } from 'react-redux';

import TabsItems from '../../components/TabsItems/TabsItems';
import ProfileForm from '../../components/ProfileForm/ProfileForm';
import PasswordForm from '../../components/PasswordForm/PasswordForm';
import {
  requestEditProfile,
  callEditProfileSuccess,
  userAccountActiveTabIndex,
  addFlashMessage,
  requestEditPassword,
  callEditPasswordSuccess,
  saveProfileSettings,
  showSuccessMsg
} from '../../actions/all-actions';
import { FLASH_MESSAGE_TYPE_SUCCESS, FLASH_MESSAGE_TYPE_ERROR } from '../../constants/generic-constants';
import autoBind from 'react-autobind';
import { addErrorMessage } from '../../utils/utils';
import _values from 'lodash/values';
import { partialErrorCodes } from '../../constants/options-constants';
import SettingsForm from '../../components/SettingsForm';

class Account extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
  }

  handleSaveProfile() {
    const { dispatch } = this.props;
    dispatch(requestEditProfile())
      .then(userInfo => {
        dispatch(callEditProfileSuccess(userInfo));
        dispatch(
          addFlashMessage({
            contentKey: 'account_update_member_succeeded',
            type: FLASH_MESSAGE_TYPE_SUCCESS
          })
        );
      })
      .catch(error => {
        const partialErrors = _values(partialErrorCodes.phoneNumbers);
        const msgParams = { error, partialErrors, def: 'account_update_member_error' };
        dispatch(addErrorMessage(msgParams));
      });
  }

  handleSavePassword() {
    const { dispatch } = this.props;

    dispatch(requestEditPassword())
      .then(() => {
        dispatch(callEditPasswordSuccess());
        dispatch(
          addFlashMessage({
            contentKey: 'account_password_success',
            type: FLASH_MESSAGE_TYPE_SUCCESS
          })
        );
      })
      .catch(error => {
        if (!error.type) {
          // make sure native errors not swallowed
          console.error(error.stack); // eslint-disable-line
        }
        dispatch(
          addFlashMessage({
            contentKey: 'account_password_failed',
            type: FLASH_MESSAGE_TYPE_ERROR
          })
        );
      });
  }

  handleSaveSettings(data) {
    const { dispatch } = this.props;
    dispatch(saveProfileSettings(data)).then(() => dispatch(showSuccessMsg({ key: 'common_success' })));
  }

  handleChangeTab(index) {
    this.props.dispatch(userAccountActiveTabIndex(index));
  }

  render() {
    const { accountActiveTabIndex } = this.props;

    const tabList = [
      { name: 'account_stepper_profile', component: <ProfileForm onCallback={this.handleSaveProfile} /> },
      { name: 'account_stepper_password', component: <PasswordForm onCallback={this.handleSavePassword} /> },
      { name: 'common_settings', component: <SettingsForm onCallback={this.handleSaveSettings} /> }
    ];

    return (
      <div className="accountPage mainContainer_content">
        <div className="pageContainer">
          <div className="mainContent">
            <div className="mainContent_inner">
              <TabsItems tabList={tabList} onChangeTab={this.handleChangeTab} currentTab={accountActiveTabIndex} actionButtons />
              {tabList[accountActiveTabIndex].component}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Account.displayName = 'Account';

export default connect(state => {
  const {
    user: { editProfileError, accountActiveTabIndex }
  } = state;

  return {
    editProfileError,
    accountActiveTabIndex
  };
})(Account);
