import React, { Component, PropTypes } from 'react';
import ReactSVG from 'react-svg';

class VehicleCircleMarker extends Component {
  constructor(props) {
    super(props);
    this.styleW = this.props.width;
    this.styleH = this.props.height;
    this.styleMarginTop = -(this.props.height / 2);
    this.styleMarginLeft = -(this.props.width / 2);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.width !== this.props.width || nextProps.height !== this.props.height) {
      this.styleW = nextProps.width;
      this.styleH = nextProps.height;
      this.styleMarginTop = -(nextProps.height / 2);
      this.styleMarginLeft = -(nextProps.width / 2);
    }
  }

  render() {
    return (
      <div
        style={{
          width: this.styleW,
          height: this.styleH,
          marginTop: this.styleMarginTop,
          marginLeft: this.styleMarginLeft
        }}
      >
        <ReactSVG src="/img/vehicle-circle.svg" className="align-svg" />
      </div>
    );
  }
}

VehicleCircleMarker.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number
};

VehicleCircleMarker.defaultProps = {
  width: 44,
  height: 44
};

VehicleCircleMarker.displayName = 'VehicleCircleMarker';

export default VehicleCircleMarker;
