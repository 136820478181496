import React, { Component } from 'react';
import { connect } from 'react-redux';
import Modal from 'react-modal';
import _get from 'lodash/get';
import _partial from 'lodash/partial';
import { FormattedMessage } from 'react-intl';
import { getAppFormattedDateTime, getShortId } from '../../utils/utils';

import TableView from '../../components/TableView/TableView';
import routes, { companyRules, feedbackEditRules } from '../../constants/routes-constants';
import EkButton from '../../components/EkButton/EkButton';
import DamageReport from './DamageReport/DamageReport';
import BackLink from '../../components/BackLink/BackLink';
import FeedbackAddCommentForm from '../../components/FeedbackAddCommentForm/FeedbackAddCommentForm';
import {
  openAddCommentModal,
  closeAddCommentModal,
  addFeedbackComment,
  updateFeedbackStatus,
  updateCurrentlyStatus
} from '../../actions/feedbacks-actions';
import { feedbackDetailsTableViewParams } from '../../constants/table-constants';
import { AddCommentModalStyles } from '../../constants/style-constants';
import { FEEDBACK_STATUS_NEW, FEEDBACK_STATUS_SEEN, FEEDBACK_STATUS_HANDLED } from '../../constants/backend-constants';
import { apiParams } from '../../constants/api-params-constants';
import { checkRole } from '../../constants/backuser-role-rules';
import { userRoleSelector } from '../../selectors/all-selectors';

class FeedbackDetail extends Component {
  constructor(props) {
    super(props);
    this.bindFunctions();
    this.setReadOnly(props);
  }

  setReadOnly(props) {
    const { role } = props;
    this.readOnly = !checkRole(feedbackEditRules, role);
    this.canSeeCompany = checkRole(companyRules, role);
  }

  bindFunctions() {
    this.handleAddComment = this.handleAddComment.bind(this);
    this.handleAbortAddComment = this.handleAbortAddComment.bind(this);
    this.handleConfirmAddComment = this.handleConfirmAddComment.bind(this);
    this.handleUpdateFeedbackStatus = this.handleUpdateFeedbackStatus.bind(this);
  }

  componentWillUnmount() {
    const { dispatch, feedbackDetail, statusCurrentlyUpdated } = this.props;
    const feedbackStatus = _get(feedbackDetail, 'startDamageReport.status', null) || _get(feedbackDetail, 'endDamageReport.status', null);

    feedbackStatus === FEEDBACK_STATUS_NEW
      ? dispatch(!statusCurrentlyUpdated ? updateFeedbackStatus(feedbackDetail, FEEDBACK_STATUS_SEEN) : updateCurrentlyStatus(false))
      : null;
  }

  handleAddComment() {
    const { dispatch } = this.props;
    dispatch(openAddCommentModal());
  }

  handleUpdateFeedbackStatus(status) {
    const { dispatch, feedbackDetail } = this.props;
    dispatch(updateFeedbackStatus(feedbackDetail, status, true));
  }

  handleAbortAddComment() {
    const { dispatch } = this.props;
    dispatch(closeAddCommentModal());
  }

  handleConfirmAddComment(formData) {
    const { dispatch, feedbackDetail } = this.props;

    dispatch(closeAddCommentModal());

    dispatch(addFeedbackComment(feedbackDetail, formData.comment));
  }

  render() {
    const { openedAddCommentModalFeedback, feedbackDetail, urlParams } = this.props;

    let backToListURL =
      '/#' + routes.feedbacks.path.replace(':search', encodeURIComponent(JSON.stringify(urlParams ? urlParams : apiParams.default)));

    const feedbackStatus = _get(feedbackDetail, 'startDamageReport.status', null) || _get(feedbackDetail, 'endDamageReport.status', null);

    return (
      <div className="feedbackDetailPage mainContainer_content">
        <div className="pageContainer">
          <div className="feedbackDetailPage_content">
            <BackLink link={backToListURL} labelKey="back_link_feedbacks_list" />

            <div className="feedbackDetailPage_head">
              {!this.readOnly && (
                <EkButton customClass="feedbackDetail_actionsButton" skinType="variant3" onAction={this.handleAddComment}>
                  <FormattedMessage id="feedback_detail_add_comment" />
                </EkButton>
              )}

              {!this.readOnly && feedbackStatus !== FEEDBACK_STATUS_HANDLED && (
                <EkButton
                  customClass="feedbackDetail_actionsButton"
                  skinType="variant3"
                  onAction={_partial(this.handleUpdateFeedbackStatus, FEEDBACK_STATUS_HANDLED)}
                >
                  <FormattedMessage id="feedback_detail_update_status_handled" />
                </EkButton>
              )}

              {!this.readOnly && feedbackStatus === FEEDBACK_STATUS_SEEN && (
                <EkButton
                  customClass="feedbackDetail_actionsButton"
                  skinType="variant3"
                  onAction={_partial(this.handleUpdateFeedbackStatus, FEEDBACK_STATUS_NEW)}
                >
                  <FormattedMessage id="feedback_detail_update_status_new" />
                </EkButton>
              )}
            </div>

            {openedAddCommentModalFeedback && (
              <Modal isOpen={openedAddCommentModalFeedback !== null} style={AddCommentModalStyles}>
                <FeedbackAddCommentForm onCallback={this.handleConfirmAddComment} onAbort={this.handleAbortAddComment} />
              </Modal>
            )}

            <section className="feedbackDetail">
              <h4 className="feedbackDetail_title">
                <FormattedMessage id="feedbacks_detail_title_reporter" />
              </h4>

              <div className="feedbackDetail_line">
                <div className="feedbackDetail_item">
                  <span className="feedbackDetail_item_label">
                    <FormattedMessage id="feedbacks_detail_bookingId" />
                  </span>
                  <a
                    className="feedbackDetail_item_link"
                    href={`/#${routes.bookingDetail.path.replace(':bookingId', feedbackDetail.booking.id)}`}
                  >
                    <span className="feedbackDetail_item_result">{getShortId(feedbackDetail.booking.id)}</span>
                  </a>
                </div>
                <div className="feedbackDetail_item">
                  <span className="feedbackDetail_item_label">
                    <FormattedMessage id="feedbacks_detail_memberName" />
                  </span>
                  <a
                    className="feedbackDetail_item_link"
                    href={`/#${routes.memberDetail.path.replace(':memberId', feedbackDetail.booking.member.id)}`}
                  >
                    <span className="feedbackDetail_item_result">
                      {feedbackDetail.booking.member.firstName} {feedbackDetail.booking.member.lastName}
                    </span>
                  </a>
                </div>
                <div className="feedbackDetail_item">
                  <span className="feedbackDetail_item_label">
                    <FormattedMessage id="feedbacks_detail_companyName" />
                  </span>
                  {this.canSeeCompany ? (
                    <a
                      className="feedbackDetail_item_link"
                      href={`/#${routes.companyDetail.path.replace(':companyId', feedbackDetail.booking.member.company.id)}`}
                    >
                      <span className="feedbackDetail_item_result">{feedbackDetail.booking.member.company.name}</span>
                    </a>
                  ) : (
                    <span className="feedbackDetail_item_result">{feedbackDetail.booking.member.company.name}</span>
                  )}
                </div>
              </div>

              <div className="feedbackDetail_line">
                <div className="feedbackDetail_item">
                  <span className="feedbackDetail_item_label">
                    <FormattedMessage id="feedbacks_detail_vehicle" />
                  </span>
                  <a
                    className="feedbackDetail_item_link"
                    href={`/#${routes.vehicleDetail.path.replace(':vehicleId', feedbackDetail.booking.vehicle.id)}`}
                  >
                    <span className="feedbackDetail_item_result">
                      {feedbackDetail.booking.vehicle.version.model.brand.name + ' '}
                      {feedbackDetail.booking.vehicle.version.model.name}
                    </span>
                  </a>
                </div>
                <div className="feedbackDetail_item">
                  <span className="feedbackDetail_item_label">
                    <FormattedMessage id="feedbacks_detail_plateNumber" />
                  </span>
                  <a
                    className="feedbackDetail_item_link"
                    href={`/#${routes.vehicleDetail.path.replace(':vehicleId', feedbackDetail.booking.vehicle.id)}`}
                  >
                    <span className="feedbackDetail_item_result">{feedbackDetail.booking.vehicle.registrationNumber}</span>
                  </a>
                </div>
                <div className="feedbackDetail_item">
                  <span className="feedbackDetail_item_label">
                    <FormattedMessage id="feedbacks_detail_feedback_status" />
                  </span>
                  <span className="feedbackDetail_item_result">
                    <FormattedMessage id={`feedbacks_detail_feedback_status_${feedbackStatus}`} />
                  </span>
                </div>
              </div>

              <div className="feedbackDetail_line">
                <div className="feedbackDetail_item">
                  <span className="feedbackDetail_item_label">
                    <FormattedMessage id="feedbacks_detail_start_booking_date" />
                  </span>
                  <span className="feedbackDetail_item_result">{getAppFormattedDateTime(feedbackDetail.booking.start.date)}</span>
                </div>
                {feedbackDetail.booking.end && feedbackDetail.booking.end.date ? (
                  <div className="feedbackDetail_item">
                    <span className="feedbackDetail_item_label">
                      <FormattedMessage id="feedbacks_detail_end_booking_date" />
                    </span>
                    <span className="feedbackDetail_item_result">{getAppFormattedDateTime(feedbackDetail.booking.end.date)}</span>
                  </div>
                ) : (
                  ''
                )}
                <div className="feedbackDetail_item" />
              </div>
            </section>

            {feedbackDetail.startDamageReport ? (
              <DamageReport
                customClass={`feedbackDetail feedbackDetail-height1`}
                damageReport={feedbackDetail.startDamageReport}
                title="feedbacks_detail_first_damage_report_title"
              />
            ) : null}

            {feedbackDetail.endDamageReport ? (
              <DamageReport
                customClass={`feedbackDetail feedbackDetail-height2`}
                damageReport={feedbackDetail.endDamageReport}
                title="feedbacks_detail_second_damage_report_title"
              />
            ) : null}

            {feedbackDetail.reportRemarks.length ? (
              <section className="feedbackDetail">
                <h4 className="feedbackDetail_title">
                  <FormattedMessage id="feedbacks_detail_title_remarks" />
                </h4>

                <TableView
                  customClass="feedbackDetail_tableViewWrapper"
                  params={feedbackDetailsTableViewParams}
                  content={feedbackDetail.reportRemarks}
                />
              </section>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}

FeedbackDetail.displayName = 'FeedbackDetail';

FeedbackDetail.propTypes = {};

export default connect(state => {
  const {
    feedbacks: { openedAddCommentModalFeedback, feedbackDetail, statusCurrentlyUpdated, urlParams }
  } = state;

  return {
    openedAddCommentModalFeedback,
    feedbackDetail,
    statusCurrentlyUpdated,
    urlParams,
    role: userRoleSelector(state)
  };
})(FeedbackDetail);
