import React, { Component, PropTypes } from 'react';
import { connect } from 'react-redux';
import config from '../../constants/config-constants';

import ParkingForm from '../../components/ParkingForm/ParkingForm';
import { createParkingRequest } from '../../actions/all-actions';
import { defaultWeeklySlot } from '../../constants/filters-constants';
import { fallbackFunc, safe } from '../../utils/utils';

class AddParking extends Component {
  constructor(props) {
    super(props);
    this.handleCreateParking = this.handleCreateParking.bind(this);
    this.handleGeo = this.handleGeo.bind(this);
    this.state = { lat: 0, lng: 0, ready: false };
  }

  componentWillMount() {
    this.handleGeo();
  }

  handleCreateParking() {
    const siteId = this.props.siteId || safe(() => this.props.currentSite.id);
    this.props.dispatch(createParkingRequest(siteId, this.props.isModal)).then(this.props.onSubmit, fallbackFunc);
  }

  handleGeo() {
    const geoApi =
      'https://maps.googleapis.com/maps/api/geocode/json?address=' +
      encodeURI(this.props.currentSite.address.formattedAddress) +
      '&key=' +
      config.gMapsApiKey;

    return fetch(geoApi).then(
      data => {
        const resp = data.json();
        resp.then(json => {
          if (json.results.length > 0) {
            const geometry = { ...json.results[0].geometry.location };
            this.setState({ ...geometry, ready: true });
          } else this.setState({ lat: '48.856614', lng: '2.3522219', ready: true });
        });
      },
      error => console.error('error ', error)
    );
  }

  render() {
    const { bankHolidays } = this.props;

    return (
      <div className="addParkingPage mainContainer_content">
        <div className="pageContainer">
          <div className="addParkingPage_content">
            <section className="addParkingPage_management">
              {!this.state.ready && 'loading'}
              {this.state.ready && (
                <ParkingForm
                  company={this.props.detailCompany}
                  subCompany={this.props.currentSubCompany}
                  isModal={this.props.isModal}
                  onCancel={this.props.onCancel}
                  onCallback={this.handleCreateParking}
                  initialValues={{
                    bankHolidays: bankHolidays,
                    weeklyOpeningHours: defaultWeeklySlot,
                    latitude: this.state.lat,
                    longitude: this.state.lng,
                    radius: 100,
                    alwaysOpen: 'true',
                    applyFrenchBankHolidays: 'true',
                    finishBookingOutsideOpeningHours: 'true',
                    finishBookingOutsideParkingArea: 'true'
                  }}
                />
              )}
            </section>
          </div>
        </div>
      </div>
    );
  }
}

AddParking.displayName = 'AddParking';

AddParking.propTypes = {
  siteId: PropTypes.string,
  isModal: PropTypes.bool,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func
};

export default connect(state => {
  const {
    sites: { currentSite },
    parkings: { bankHolidays },
    companies: { detailCompany },
    subCompanies: { currentSubCompany }
  } = state;

  return {
    currentSubCompany,
    detailCompany,
    currentSite,
    bankHolidays
  };
})(AddParking);
