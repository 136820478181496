import React, { Component } from 'react';
import ColumnList from '../../../components/_v2/ColumnList';
import { connect } from 'react-redux';
import { createMenuItems, resolveActive, resolveSelected, showNextView, showParentView } from '../../../components/_v2/ColumnList/helpers';
import { FormattedMessage } from 'react-intl';
import { renderItem } from './helpers';
import { parkingsListSelector } from '../../../selectors/all-selectors';

class OrganizationParkings extends Component {
  componentWillMount() {
    this.setCallbacks();
    this.setVars();
  }

  setVars() {
    this.menuItems = createMenuItems({ onAdd: this.handleAdd, addKey: 'addParking_title' });
  }

  setCallbacks() {
    this.handleEdit = ({ id } = {}, _, { openInNewTab }) => {
      showNextView.call(this, 'organizationEditParking', ':parkingId', id, openInNewTab);
    };

    this.handleAdd = () => {
      showNextView.call(this, 'organizationAddParking');
    };

    this.resolveSelected = resolveSelected.bind(this);
    this.resolveActive = resolveActive.bind(this);
    this.showParentView = showParentView.bind(this);
  }

  render() {
    return (
      <ColumnList
        title={<FormattedMessage id="side_menu_section_account_parkings" />}
        inputRef={this.props.inputRef}
        items={this.props.list}
        renderItem={renderItem}
        onSelect={this.handleEdit}
        selected={this.resolveSelected}
        active={this.resolveActive}
        menuItems={this.menuItems}
        onBack={this.showParentView}
        localSearchEnabled
      />
    );
  }
}

const mapStateToProps = state => {
  return { list: parkingsListSelector(state) };
};

OrganizationParkings = connect(mapStateToProps)(OrganizationParkings);

export default OrganizationParkings;
