import { FormattedMessage } from 'react-intl';
import React from 'react';
import { CUSTOM_FIELD_CONDITIONED } from '../constants/backend-constants';
import { safe, toBoolean } from './utils';

export function boolMap(bool) {
  if (bool) {
    return <FormattedMessage id="common_yes" />;
  }
  return <FormattedMessage id="common_no" />;
}

export function customFieldIsVisible(field, fieldValues, fieldIdsByPosition) {
  const { companyCustomField } = field || {};
  const { visible, visibleCondition } = companyCustomField || {};
  const { dependsOnPosition, expectedValueForPosition } = visibleCondition || {};

  if (visible === CUSTOM_FIELD_CONDITIONED) {
    const parentId = fieldIdsByPosition[dependsOnPosition];
    const parentValue = !!fieldValues[parentId];
    return parentValue === toBoolean(expectedValueForPosition);
  } else return true;
}

// needs to be called with .call sending 'locale' and 'bundle' from props
export function selectCurrentLabel(companyCustomField = {}) {
  const { locale } = this.props;
  const { companyCustomFieldLabels, name } = companyCustomField;
  const labelSelector = label => safe(() => label.language === locale);
  let { label } = safe(() => companyCustomFieldLabels.find(labelSelector)) || {};

  if (!label) {
    const engSelector = label => safe(() => label.language === 'en');
    const eng = safe(() => companyCustomFieldLabels.find(engSelector));
    const { label: engLabel } = eng || {};

    label = engLabel;
  }

  return label || name || 'Field';
}

export function createElement(type, ...classList) {
  const element = document.createElement(type);
  if (classList.length) element.classList.add(...classList);
  return element;
}
