import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { getEditTitle } from '../../../../components/_v2/ColumnList/helpers';
import { showParentView } from '../../../../components/_v2/ColumnList/helpers';
import { connect } from 'react-redux';
import HotlineView from './index';
import { routeActions } from 'react-router-redux';

import { updateHotlineRequest, updateHotlineSuccess, addFlashMessage } from '../../../../actions/all-actions';
import { FLASH_MESSAGE_TYPE_SUCCESS } from '../../../../constants/generic-constants';

// import routes from '../../../../constants/routes-constants';
import _values from 'lodash/values';

import { partialErrorCodes } from '../../../../constants/options-constants';
import { addErrorMessage } from '../../../../utils/utils';

export class HotlineEdit extends Component {
  componentWillMount() {
    this.showParentView = showParentView.bind(this);

    this.handleCallback = form => {
      const { dispatch, data } = this.props;
      dispatch(updateHotlineRequest(data.id))
        .then(hotline => {
          dispatch(updateHotlineSuccess(hotline));
          dispatch(
            addFlashMessage({
              contentKey: 'editHotline_update_hotline_success',
              type: FLASH_MESSAGE_TYPE_SUCCESS
            })
          );
        })
        .catch(error => {
          const partialErrors = _values(partialErrorCodes.generic);
          const msgParams = { error, partialErrors, def: 'editHotline_update_hotline_error' };
          dispatch(addErrorMessage(msgParams));
        });
    };
  }

  render() {
    return (
      <HotlineView
        inputRef={this.props.inputRef}
        title={getEditTitle(<FormattedMessage id="editHotline_title" />)}
        onClose={this.showParentView}
        onCallback={this.handleCallback}
        initialValues={this.props.data}
      />
    );
  }
}

const mapStateToProps = state => {
  const { currentHotline } = state.hotlines;
  return { data: currentHotline };
};

HotlineEdit = connect(mapStateToProps)(HotlineEdit);

export default HotlineEdit;
