import * as types from '../constants/actionTypes-constants';
import callApi from './api-actions';
import { addErrorMessage, isValidId, toBoolean, trimValues, trySet, boolToString, boolToStringStrict } from '../utils/utils';
import { setFormattedAddress } from '../utils/dataFormatUtils';
import { bundleSelector, validCompanyIdSelector } from '../selectors/all-selectors';
import { change } from 'redux-form';
import _omit from 'lodash/omit';

export function getSitesList(companyId, loader = true) {
  return (dispatch, getState) => {
    const state = getState();
    const bundle = bundleSelector(state);
    const id = companyId || validCompanyIdSelector(state);

    const handleError = error => {
      dispatch(clearSitesList());

      const msgParams = { bundle, error };
      dispatch(addErrorMessage(msgParams));

      return Promise.resolve([]);
    };

    const handleSuccess = data => {
      dispatch({
        type: types.SITES_GET_LIST_SUCCESS,
        list: data
      });
      return Promise.resolve(data);
    };

    dispatch({ type: types.SITES_GET_LIST_REQUEST });

    if (id) {
      const action = callApi('sites', 'getList', id, loader);
      return dispatch(action)
        .then(handleSuccess)
        .catch(handleError);
    }

    return handleError({ error: { developerMessage: 'getSitesList: id not found' } });
  };
}

export function getSitesNoState({ companyId, loader = true } = {}) {
  return (dispatch, getState) => {
    const state = getState();
    const id = companyId || validCompanyIdSelector(state);

    const handleError = error => {
      dispatch({ type: types.GET_SITES_NO_STATE_ERROR });
      return Promise.reject(error);
    };

    const handleSuccess = data => {
      dispatch({ type: types.GET_SITES_NO_STATE_SUCCESS });
      return data;
    };

    dispatch({ type: types.GET_SITES_NO_STATE_REQUEST });

    if (id) {
      const action = callApi('sites', 'getList', id, loader);
      return dispatch(action).then(handleSuccess, handleError);
    }

    return handleError({ errorCode: 'error_local_unknown' });
  };
}

export function getSitesListFromSubCompany(subCompanyId) {
  return dispatch => {
    dispatch({ type: types.SITES_GET_LIST_REQUEST });

    if (!isValidId(subCompanyId)) return Promise.resolve();

    return dispatch(callApi('sites', 'getSubCompanyList', subCompanyId, false))
      .then(data => {
        dispatch({
          type: types.SITES_GET_LIST_SUCCESS,
          list: data
        });
        return Promise.resolve(data);
      })
      .catch(error => {
        dispatch(clearSitesList());
        dispatch(addErrorMessage({ error }));
        return Promise.resolve([]);
      });
  };
}

export function clearSitesList() {
  return { type: types.SITES_GET_LIST_CLEAR };
}

export function setOverrideParams(params) {
  const {
    overrideSuperCompanyReportConfig,
    startBookingReportMandatory,
    endBookingReportMandatory,
    reportPhotos,
    enablePhotoOverride,
    photoStartBooking,
    photoEndBooking,
    nameForElectricVehicle,
    nameForHybridVehicle,
    nameForThermalVehicle,
    ...final
  } = params;

  trySet(final, 'vehicleInspectionReportConfig.overrideSuperCompanyReportConfig', overrideSuperCompanyReportConfig);

  if (overrideSuperCompanyReportConfig) {
    trySet(final, 'vehicleInspectionReportConfig.startBookingReportMandatory', toBoolean(startBookingReportMandatory));
    trySet(final, 'vehicleInspectionReportConfig.endBookingReportMandatory', toBoolean(endBookingReportMandatory));
    trySet(final, 'vehicleInspectionReportConfig.reportPhotos', toBoolean(reportPhotos));

    if (enablePhotoOverride) {
      trySet(final, 'vehicleInspectionReportConfig.vehicleInspectionPhotos.startBooking', toBoolean(photoStartBooking));
      trySet(final, 'vehicleInspectionReportConfig.vehicleInspectionPhotos.endBooking', toBoolean(photoEndBooking));
      trySet(final, 'vehicleInspectionReportConfig.vehicleInspectionPhotos.nameForElectricVehicle', nameForElectricVehicle);
      trySet(final, 'vehicleInspectionReportConfig.vehicleInspectionPhotos.nameForHybridVehicle', nameForHybridVehicle);
      trySet(final, 'vehicleInspectionReportConfig.vehicleInspectionPhotos.nameForThermalVehicle', nameForThermalVehicle);
    }
  }

  return final;
}

export function createSiteRequest() {
  return (dispatch, getState) => {
    const state = getState();

    const formValues = trimValues(state.form.siteForm);
    const { subCompanyId } = formValues;

    const params = setOverrideParams({
      ...formValues,
      automaticExtension: formValues.automaticExtension === 'true',
      automaticShortening: formValues.automaticShortening === 'true'
    });

    if (typeof params.address === 'string') params.address = { streetName: params.address };

    if (params.postalCode) {
      trySet(params.address, 'postalCode', params.postalCode);
    }

    if (params.city) {
      trySet(params.address, 'city', params.city);
    }

    if (params.country)
      if (params.country.hasOwnProperty('name')) {
        //case country is not picked from suggestions list
        trySet(params.address, 'country', params.country.name);
      } else {
        trySet(params.address, 'country', params.country);
      }

    delete params.postalCode;
    delete params.city;
    delete params.country;

    if (!params.address.hasOwnProperty('formattedAddress')) {
      trySet(params, 'address', setFormattedAddress(params.address));
    }

    dispatch({
      type: types.SITES_CREATE_SITE_REQUEST,
      params
    });

    return dispatch(callApi('sites', 'createSite', { subCompanyId, params }));
  };
}

export function createSiteSuccess(site) {
  return {
    type: types.SITES_CREATE_SITE_SUCCESS,
    site
  };
}

export function getSiteDetails(siteId) {
  return dispatch => {
    dispatch({
      type: types.SITES_GET_DETAIL_SITE_REQUEST
    });

    return dispatch(callApi('sites', 'getSiteDetails', siteId)).then(function(data) {
      dispatch({
        type: types.SITES_GET_DETAIL_SITE_SUCCESS,
        site: data
      });
      return data;
    });
  };
}

export function updateSiteRequest(site) {
  return (dispatch, getState) => {
    const state = getState();
    const siteId = site.id;

    let { address, city, country, postalCode, ...formValues } = trimValues(state.form.siteForm);
    if (typeof address === 'string') address = { streetName: address };

    trySet(address, 'postalCode', postalCode);
    trySet(address, 'city', city);
    trySet(address, 'country', country);
    trySet(formValues, 'address', setFormattedAddress(address));

    let params = setOverrideParams({
      ...formValues,
      automaticExtension: formValues.automaticExtension === 'true',
      automaticShortening: formValues.automaticShortening === 'true'
    });

    dispatch({
      type: types.SITES_UPDATE_SITE_REQUEST,
      params
    });

    return dispatch(callApi('sites', 'updateSite', { siteId, params }));
  };
}

export function updateSiteSuccess(site) {
  return {
    type: types.SITES_UPDATE_SITE_SUCCESS,
    site
  };
}

export function updateTimeZone(timeZoneValue) {
  return dispatch => {
    dispatch(change('siteForm', 'zoneId', timeZoneValue));
  };
}

export function copySite(site) {
  return dispatch => {
    const siteExclude = ['name', 'address', 'zoneId'];
    const _site = _omit(site, siteExclude);

    const {
      automaticShortening,
      automaticExtension,
      chargeExpiredBooking,
      smartcardEnabled,
      smartcardFishingEnabled,
      spontaneousBookingEnabled,
      sendReportToDekra,
      vehicleInspectionReportConfig
    } = _site || {};

    const {
      overrideSuperCompanyReportConfig,
      reportPhotos,
      startBookingReportMandatory,
      endBookingReportMandatory,
      vehicleInspectionPhotos
    } = vehicleInspectionReportConfig || {};

    const { startBooking, endBooking, nameForElectricVehicle, nameForHybridVehicle, nameForThermalVehicle } = vehicleInspectionPhotos || {};

    const valuesToRet = {
      ..._site,
      nameForElectricVehicle,
      nameForHybridVehicle,
      nameForThermalVehicle,
      overrideSuperCompanyReportConfig,
      enablePhotoOverride: vehicleInspectionPhotos,
      automaticShortening: boolToString(automaticShortening),
      automaticExtension: boolToString(automaticExtension),
      chargeExpiredBooking: boolToString(chargeExpiredBooking),
      smartcardEnabled: boolToString(smartcardEnabled),
      smartcardFishingEnabled: boolToString(smartcardFishingEnabled),
      spontaneousBookingEnabled: boolToString(spontaneousBookingEnabled),
      sendReportToDekra: boolToString(sendReportToDekra),
      startBookingReportMandatory: boolToStringStrict(startBookingReportMandatory),
      endBookingReportMandatory: boolToStringStrict(endBookingReportMandatory),
      reportPhotos: boolToStringStrict(reportPhotos),
      photoStartBooking: boolToStringStrict(startBooking),
      photoEndBooking: boolToStringStrict(endBooking)
    };

    dispatch({
      type: types.SITE_COPY_TO_NEW,
      site: valuesToRet
    });
  };
}

export function cleanDup() {
  return {
    type: types.SITE_CLEAN_DUP
  };
}
