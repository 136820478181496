import React, { Component, PropTypes } from 'react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

class EkTextarea extends Component {
  render() {
    const ekTextareaLabel = this.props.labelKey ? (
      <span
        className={classNames('ekTextarea_labelText', this.props.customLabelClass, {
          _is_hidden: this.props.hideLabel
        })}
      >
        <FormattedMessage id={this.props.labelKey} />
      </span>
    ) : (
      ''
    );

    return (
      <div className="ekTextareaWrapper">
        <label htmlFor={this.props.id}>
          {ekTextareaLabel}

          <textarea
            className={`ekTextarea ${this.props.customClass || ''} `}
            name={this.props.name}
            id={this.props.id}
            rows={this.props.rows}
            placeholder={this.props.placeholder}
            {...this.props.field}
          />
        </label>

        {this.props.children}
      </div>
    );
  }
}

EkTextarea.displayName = 'EkTextarea';

EkTextarea.propTypes = {
  customClass: PropTypes.string,
  placeholder: PropTypes.string,
  name: PropTypes.string,
  id: PropTypes.string,
  type: PropTypes.string,
  rows: PropTypes.string,
  labelKey: PropTypes.string,
  hideLabel: PropTypes.bool,
  customLabelClass: PropTypes.string
};

export default EkTextarea;
