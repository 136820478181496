import React, { Component, PropTypes } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { routeActions } from 'react-router-redux';
import { getStore } from '../../store/all-store';

import Header from '../../components/Header/Header';
import TableView from '../../components/TableView/TableView';
import SideMenu from '../../components/SideMenu/SideMenu';
import PageManagementHeader from '../../components/PageManagementHeader/PageManagementHeader';
import FlashMessageDisplayer from '../../components/FlashMessageDisplayer/FlashMessageDisplayer';
import routes from '../../constants/routes-constants';
import { BACKUSER_ROLE_CALL_CENTER_OPERATOR } from '../../constants/backend-constants';

const tableViewParams = {
  namespace: 'categories',
  /*sortByIndex: 0,*/
  sortIsDescending: false,
  columns: [
    {
      messageKey: 'vehiclesCategories_tableView_label_name',
      content: 'type' /*,
      isSortable: true*/
    },
    {
      messageKey: 'vehiclesCategories_tableView_label_action',
      content: null,
      contentMessageKey: 'vehiclesCategories_tableView_content_action',
      actionCustomClass: 'vehiclesCategories_tableView_action',
      actionCallback: function(item) {
        getStore().dispatch(routeActions.push(routes.editVehiclesCategory.path.replace(':categoryId', item.id)));
      }
    }
  ]
};

class VehiclesCategories extends Component {
  render() {
    const { list, role } = this.props;

    return (
      <div className="vehiclesCategoriesPage mainContainer_content">
        <div className="pageContainer">
          <div className="vehiclesCategoriesPage_content">
            <section className="vehiclesCategoriesPage_management">
              <PageManagementHeader
                titleKey="vehiclesCategory_subtitle"
                href={`/#${routes.addVehiclesCategory.path}`}
                buttonLabelKey="vehiclesCategory_add_button"
                customClass="vehiclesCategoriesPage_managementHeader"
                limitedAccess={role === BACKUSER_ROLE_CALL_CENTER_OPERATOR}
              />

              <div className="vehiclesCategories_list">
                <TableView customClass="vehiclesCategoriesPage_tableViewWrapper" params={tableViewParams} content={list} />
              </div>
            </section>
          </div>
        </div>
      </div>
    );
  }
}

VehiclesCategories.displayName = 'VehiclesCategories';

VehiclesCategories.propTypes = {};

export default connect(state => {
  const {
    user: {
      userInfo: { role }
    },
    categories: { list }
  } = state;

  return {
    list,
    role
  };
})(VehiclesCategories);
