import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { routeActions } from 'react-router-redux';

import LoginForm from '../../components/LoginForm/LoginForm';
import ResetPasswordForm from '../../components/ResetPasswordForm/ResetPasswordForm';
import FlashMessageDisplayer from '../../components/FlashMessageDisplayer/FlashMessageDisplayer';
import {
  requestLogin,
  loginError,
  requestResetPassword,
  callResetPasswordSuccess,
  callResetPasswordError,
  logout,
  clearRedirectLocation,
  addFlashMessage
} from '../../actions/all-actions';
import routes from '../../constants/routes-constants';
import { brandConfigSelector, bundleSelector, redirectLocationSelector } from '../../selectors/all-selectors';
import classNames from 'classnames';
import { FLASH_MESSAGE_TYPE_WARNING, PRODUCT_NAME, STORAGE_KEY } from '../../constants/generic-constants';
import { safe } from '../../utils/utils';

class Login extends Component {
  constructor(props) {
    super(props);

    this.handleLogin = this.handleLogin.bind(this);
    this.handleForgotPassword = this.handleForgotPassword.bind(this);
  }

  componentWillMount() {
    this.props.dispatch(logout());
  }

  componentDidMount() {
    const userNotified = sessionStorage.getItem(STORAGE_KEY.REDIRECT_NOTIFIED);

    if (this.props.redirectLocation && !userNotified) {
      sessionStorage.setItem(STORAGE_KEY.REDIRECT_NOTIFIED, 'YES');
      this.props.dispatch(
        addFlashMessage({
          contentKey: 'login_redirectNotification',
          type: FLASH_MESSAGE_TYPE_WARNING
        })
      );
    }
  }

  handleLogin({ redirectBack }) {
    const { dispatch, redirectLocation, enableNewUi } = this.props;

    dispatch(requestLogin())
      .then(() => {
        if (redirectLocation && redirectBack) {
          dispatch(routeActions.push(redirectLocation));
        } else {
          if (enableNewUi) {
            dispatch(routeActions.push(routes.dashboardV2.path));
          } else {
            dispatch(routeActions.push(routes.dashboard.path));
          }
        }
        dispatch(clearRedirectLocation());
      })
      .catch(error => {
        dispatch(loginError(error));
      });
  }

  handleForgotPassword() {
    const { dispatch } = this.props;

    dispatch(requestResetPassword())
      .then(() => {
        dispatch(callResetPasswordSuccess());
      })
      .catch(error => {
        dispatch(callResetPasswordError(error));
      });
  }

  // using 'hide' to preserve form data when changing forms
  displayLoginForms() {
    const { forgotPasswordMode, loginServerError } = this.props;
    return (
      <div>
        <div className={classNames('loginPage_form', { hide: forgotPasswordMode })}>
          <LoginForm onCallback={this.handleLogin} serverError={loginServerError} />
        </div>
        <div className={classNames('loginPage_form', { hide: !forgotPasswordMode })}>
          <ResetPasswordForm onCallback={this.handleForgotPassword} serverError={loginServerError} />
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className="loginPage">
        <FlashMessageDisplayer />
        <section className="loginPage_content">
          <div className="loginBox">
            <h2 className="loginPage_title">
              <FormattedMessage
                id={safe(() => this.props.brandConfig.loginTitleKey) || 'loginPage_title'}
                values={{ productName: safe(() => this.props.brandConfig.productName) || PRODUCT_NAME }}
              />
            </h2>
            {this.displayLoginForms()}
          </div>
        </section>
      </div>
    );
  }
}

Login.displayName = 'Login';

export default connect(state => {
  const {
    user: { loginServerError, forgotPasswordMode, enableNewUi }
  } = state;

  return {
    redirectLocation: redirectLocationSelector(state),
    brandConfig: brandConfigSelector(state),
    bundle: bundleSelector(state),
    forgotPasswordMode,
    loginServerError,
    enableNewUi
  };
})(Login);
