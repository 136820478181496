import callApi from './api-actions';
import { detailedCompanySelector, bundleSelector } from '../selectors/all-selectors';
import { conditionedCustomFields } from '../api/data-enhancer';

import { addFlashMessage } from './flashMessage-actions';
import {
  CUSTOM_FIELDS_GET_SUCCESS,
  CUSTOM_FIELDS_BOOKING_PICK,
  CUSTOM_FIELDS_SUBSCRIPTION_PICK,
  CUSTOM_FIELDS_UPDATE_SUCCESS,
  CUSTOM_FIELDS_SAVE_SUCCESS,
  CUSTOM_FIELDS_BOOKING_UNPICK,
  CUSTOM_FIELDS_SUBSCRIPTION_UNPICK,
  CUSTOM_FIELDS_SUBSCRIPTION_GET_SUCCESS,
  CUSTOM_FIELDS_BOOKINGS_GET_SUCCESS
} from '../constants/actionTypes-constants';

import { FLASH_MESSAGE_TYPE_ERROR, FLASH_MESSAGE_TYPE_SUCCESS } from '../constants/generic-constants';

import {
  CUSTOM_FIELD_FORM_BOOKING,
  CUSTOM_FIELD_FORM_SUBSCRIPTION,
  CUSTOM_FIELD_TYPE_FILE,
  CUSTOM_FIELD_CONDITIONED
} from '../constants/backend-constants';
import { isValidId, addErrorMessage } from '../utils/utils';

export function saveCustomField(data) {
  return (dispatch, getState) => {
    const state = getState();
    const companyId = detailedCompanySelector(state).id;
    const fileInBookingForm = checkForFileInBookingForm(data);
    const bundle = bundleSelector(state);

    if (fileInBookingForm) {
      return dispatch(fileInBookingForm);
    }

    if (data.position) {
      data.position = parseInt(data.position);
    }

    if (
      (data.mandatoryConditionDependsOnPosition && data.mandatoryConditionExpectedValueForPosition) ||
      (data.visibleConditionDependsOnPosition && data.visibleConditionExpectedValueForPosition)
    ) {
      conditionedCustomFields(data);
    }

    if (!isValidId(companyId)) {
      dispatch(
        addFlashMessage({
          contentKey: 'vehiclePlanning_selectCompany',
          type: FLASH_MESSAGE_TYPE_ERROR
        })
      );
      return Promise.resolve();
    }

    return dispatch(callApi('customFields', 'save', { field: data, companyId }))
      .then(savedField => {
        dispatch({
          type: CUSTOM_FIELDS_SAVE_SUCCESS,
          field: savedField[0]
        });
        dispatch(
          addFlashMessage({
            contentKey: 'custom_field_create_success',
            type: FLASH_MESSAGE_TYPE_SUCCESS
          })
        );

        return savedField[0];
      })
      .catch(error => {
        const msgParams = { bundle, error };
        dispatch(addErrorMessage(msgParams));
      });
  };
}

export function updateCustomField(data) {
  return (dispatch, getState) => {
    const state = getState();
    const bundle = bundleSelector(state);
    const fileInBookingForm = checkForFileInBookingForm(data);
    if (fileInBookingForm) {
      return dispatch(fileInBookingForm);
    }
    if (data.position) {
      data.position = parseInt(data.position);
    }

    if (
      (data.mandatoryConditionDependsOnPosition && data.mandatoryConditionExpectedValueForPosition) ||
      (data.visibleConditionDependsOnPosition && data.visibleConditionExpectedValueForPosition)
    ) {
      conditionedCustomFields(data);
    }

    return dispatch(callApi('customFields', 'update', { field: data }))
      .then(updatedField => {
        dispatch({
          type: CUSTOM_FIELDS_UPDATE_SUCCESS,
          field: updatedField
        });
        dispatch(
          addFlashMessage({
            contentKey: 'custom_field_update_success',
            type: FLASH_MESSAGE_TYPE_SUCCESS
          })
        );

        return updatedField;
      })
      .catch(error => {
        const msgParams = { bundle, error };
        dispatch(addErrorMessage(msgParams));
      });
  };
}

function checkForFileInBookingForm(field) {
  if (field.form === CUSTOM_FIELD_FORM_BOOKING && field.fieldType === CUSTOM_FIELD_TYPE_FILE) {
    return addFlashMessage({
      contentKey: 'custom_field_booking_and_file',
      type: FLASH_MESSAGE_TYPE_ERROR
    });
  }
  return false;
}

export const getCompanyCustomFields = companyId => dispatch => {
  if (!isValidId(companyId)) return Promise.resolve();
  return dispatch(callApi('companies', 'getCustomFields', { companyId }, false)).then(fields => {
    dispatch({
      type: CUSTOM_FIELDS_GET_SUCCESS,
      fields
    });
    return fields;
  });
};

export const getSubscriptionCustomFields = companyId => dispatch => {
  if (!isValidId(companyId)) return Promise.resolve();

  return dispatch(callApi('companies', 'getCustomFields', { companyId, form: CUSTOM_FIELD_FORM_SUBSCRIPTION })).then(fields => {
    dispatch({ type: CUSTOM_FIELDS_SUBSCRIPTION_GET_SUCCESS, fields });
    return fields;
  });
};

export const getBookingsCustomFields = companyId => dispatch => {
  if (!isValidId(companyId)) return Promise.resolve();
  return dispatch(callApi('companies', 'getCustomFields', { companyId, form: CUSTOM_FIELD_FORM_BOOKING })).then(fields => {
    dispatch({
      type: CUSTOM_FIELDS_BOOKINGS_GET_SUCCESS,
      fields
    });
    return fields;
  });
};

export function pickBookingCustomField(field) {
  return {
    type: CUSTOM_FIELDS_BOOKING_PICK,
    field
  };
}

export function unpickBookingCustomField() {
  return dispatch => {
    return dispatch({
      type: CUSTOM_FIELDS_BOOKING_UNPICK
    });
  };
}

export function pickSubscriptionCustomField(field) {
  if (field.mandatory === CUSTOM_FIELD_CONDITIONED) {
    field.mandatoryConditionDependsOnPosition = field.mandatoryCondition.dependsOnPosition;
    field.mandatoryConditionExpectedValueForPosition = field.mandatoryCondition.expectedValueForPosition;
  }
  if (field.visible === CUSTOM_FIELD_CONDITIONED) {
    field.visibleConditionDependsOnPosition = field.visibleCondition.dependsOnPosition;
    field.visibleConditionExpectedValueForPosition = field.visibleCondition.expectedValueForPosition;
  }
  return {
    type: CUSTOM_FIELDS_SUBSCRIPTION_PICK,
    field
  };
}

export function unpickSubscriptionCustomField() {
  return {
    type: CUSTOM_FIELDS_SUBSCRIPTION_UNPICK
  };
}
