// noinspection ES6CheckImport
import React, { Component, PropTypes } from 'react';

import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { routeActions } from 'react-router-redux';
import classNames from 'classnames';
import _pickBy from 'lodash/pickBy';
import _get from 'lodash/get';
import _partial from 'lodash/partial';

import AdvancedList from '../../components/AdvancedList/AdvancedList';
import { Row, Col } from 'react-bootstrap';
import { MEMBER_STATUS_COLOR } from '../../constants/style-constants';
import DetailIcon from 'material-ui/svg-icons/image/remove-red-eye';

import { exportMembersListWithSearch, getMembersList } from '../../actions/all-actions';
import routes from '../../constants/routes-constants';
import IsGranted from '../../components/IsGranted/IsGranted';
import {
  BACKUSER_ROLE_FLEET_MANAGER,
  BACKUSER_ROLE_CALL_CENTER_OPERATOR,
  BACKUSER_ROLE_EXTERNAL_FINE_COMPANY
} from '../../constants/backend-constants';
import { IconButton } from 'material-ui';
import Filters from '../../components/AdvancedList/components/Filters';
import { membersListFilterTypes } from '../../constants/filterTypes-constants';
import { membersFilterChips, memberSubscriptionOriginLabel } from '../../constants/options-constants';
import FilterChips from '../../components/FilterChips/FilterChips';
import { headerContractMemberValuesSelector, membersAdvancedListHeaderSelector } from '../../selectors/all-selectors';
import { isEmpty, namedCompose } from '../../utils/utils';

class Members extends Component {
  constructor(props) {
    super(props);
    this.handleNumberResultsSelect = this.handleNumberResultsSelect.bind(this);
    this.handleSearchMembersForm = this.handleSearchMembersForm.bind(this);
    this.handleFullExport = this.handleFullExport.bind(this);
    this.renderItem = this.renderItem.bind(this);
    this.handleChangePage = this.handleChangePage.bind(this);
    this.handleRefreshList = this.handleRefreshList.bind(this);
    this.handleSort = this.handleSort.bind(this);
    this.handleGotoDetail = this.handleGotoDetail.bind(this);
    this.handleDeleteChip = this.handleDeleteChip.bind(this);
    this.callApi = this.callApi.bind(this);
  }

  componentWillMount() {
    const { pageParams } = this.props;
    this.callApi(pageParams);
  }

  callApi(params) {
    const { dispatch } = this.props;
    dispatch(getMembersList(params));
  }

  handleChangePage(value) {
    const { dispatch, listMetadata, urlParams } = this.props;
    const params = {
      page: {
        number: value,
        size: listMetadata.paginationInfo.pageSize
      }
    };
    const newParams = { ...urlParams, ...params };
    this.callApi(newParams);
    dispatch(routeActions.push(routes.members.path.replace(':search', encodeURIComponent(JSON.stringify(newParams)))));
  }

  handleRefreshList() {
    const { pageParams } = this.props;
    this.callApi(pageParams);
  }

  handleSort(sortPropertyName) {
    const { dispatch, urlParams } = this.props;
    const newParams = {
      ...urlParams,
      sort: {
        isDescending: _get(urlParams, 'sort.property', false) === sortPropertyName ? !urlParams.sort.isDescending : false,
        property: sortPropertyName
      }
    };
    this.callApi(newParams);
    dispatch(routeActions.push(routes.members.path.replace(':search', encodeURIComponent(JSON.stringify(newParams)))));
  }

  handleDeleteChip(item) {
    let { urlParams, dispatch } = this.props;
    delete urlParams[item];
    this.callApi(urlParams);
    dispatch(routeActions.push(routes.members.path.replace(':search', encodeURIComponent(JSON.stringify(urlParams)))));
  }

  handleNumberResultsSelect(value) {
    const { dispatch, urlParams } = this.props;
    let params = {
      page: {
        number: 1,
        size: parseInt(value)
      }
    };
    let newParams = Object.assign(urlParams, params);
    this.callApi(newParams);
    dispatch(routeActions.push(routes.members.path.replace(':search', encodeURIComponent(JSON.stringify(newParams)))));
  }

  handleSearchMembersForm(params) {
    const { dispatch, listMetadata, urlParams } = this.props;
    params.page = {
      number: 1,
      size: listMetadata.paginationInfo.pageSize
    };
    let newParams = _pickBy(
      {
        ...urlParams,
        ...params
      },
      v => !isEmpty(v)
    );

    this.callApi(newParams);
    dispatch(routeActions.push(routes.members.path.replace(':search', encodeURIComponent(JSON.stringify(newParams)))));
  }

  handleFullExport() {
    const { dispatch, urlParams } = this.props;
    dispatch(exportMembersListWithSearch(urlParams, true));
  }

  addMembersManagementButtons() {
    const { currentCompanyDetails, memberRules } = this.props;

    const enableRegisterWithoutSso = _get(currentCompanyDetails, 'ssoConfiguration.allowRegistrationWithoutSso', true);
    if (enableRegisterWithoutSso)
      return (
        <span>
          {memberRules.uploadList && (
            <a className={classNames('membersPage_actionButton ', 'ekButton', 'ekButton--variant3')} href={'/#' + routes.uploadMember.path}>
              <FormattedMessage id="upload_members_button" />
            </a>
          )}
          <a className={classNames('membersPage_actionButton', 'ekButton', 'ekButton--variant3')} href={'/#' + routes.addMember.path}>
            <FormattedMessage id="add_member_button" />
          </a>
        </span>
      );
  }

  exportMembersManagementButtons() {
    const { exporting } = this.props;

    return (
      <a className={`membersPage_actionButton ekButton ${exporting ? 'disabled' : ''} ekButton--variant3`} onClick={this.handleFullExport}>
        {exporting ? <FormattedMessage id="common_button_exporting" /> : <FormattedMessage id="members_export_all_button" />}
      </a>
    );
  }

  handleGotoDetail(params) {
    this.props.dispatch(routeActions.push(routes[params[0]].path.replace(params[1], params[2])));
  }

  renderItem(item, id, reactKey) {
    const {
      intl,
      memberRules: { type }
    } = this.props;

    const { messages } = intl;
    const { firstName, lastName, login, superCompanyName, memberType, subscriptionOrigin, status } = item || {};
    const origin = subscriptionOrigin ? messages[memberSubscriptionOriginLabel[subscriptionOrigin]] : '';

    return (
      <div className="advancedList_rowWrap" key={reactKey}>
        <Row id={id} className="advancedList_row">
          <Col xs={2} md={2} children={firstName} />
          <Col xs={2} md={2} children={lastName} />
          <Col xs={3} md={3} children={login} />
          <Col xs={2} md={2} children={superCompanyName} />
          <Col xs={2} md={2} children={origin} className="bw" />
          {type && <Col xs={1} md={1} children={memberType} />}
          <Col xs={1} md={1}>
            <span style={{ color: MEMBER_STATUS_COLOR['STATUS_' + status] }}>{messages[`members_status_${status.toLowerCase()}`]}</span>
          </Col>
          <div className="advancedList_detailIcon">
            <IconButton onClick={_partial(this.handleGotoDetail, ['memberDetail', ':memberId', item.id])}>
              <DetailIcon />
            </IconButton>
          </div>
        </Row>
      </div>
    );
  }

  getFilterTypes(filterTypes) {
    if (!this.props.memberRules.expediteReview) {
      const { expediteReview, ...omit } = filterTypes;
      return omit;
    }
    return filterTypes;
  }

  render() {
    const { list, listMetadata, urlParams, loadingList, errorList, membersAdvancedListHeader } = this.props;
    const filterTypes = this.getFilterTypes(membersListFilterTypes);

    return (
      <div className="pageContainer">
        <div className="membersList">
          <FilterChips
            id="membersChips"
            urlParams={urlParams}
            onDeleteChip={this.handleDeleteChip}
            translations={membersFilterChips}
            filterTypes={filterTypes}
          />

          <div className="advancedList_actions page">
            <Filters filterTypes={filterTypes} onFilteredSearchForm={this.handleSearchMembersForm} id="members" />
            <div className="membersList_actionsButtons">
              {this.exportMembersManagementButtons()}
              <IsGranted
                disallowed={[BACKUSER_ROLE_EXTERNAL_FINE_COMPANY, BACKUSER_ROLE_FLEET_MANAGER, BACKUSER_ROLE_CALL_CENTER_OPERATOR]}
              >
                {this.addMembersManagementButtons()}
              </IsGranted>
            </div>
          </div>

          <AdvancedList
            id="members"
            data={list}
            listMetadata={listMetadata}
            loading={loadingList}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleNumberResultsSelect}
            renderItem={this.renderItem}
            header={membersAdvancedListHeader}
            onRefreshList={this.handleRefreshList}
            urlParams={urlParams}
            error={errorList}
            onSort={this.handleSort}
            renderAnchor={this.props.locale}
          />
        </div>
      </div>
    );
  }
}

Members.propTypes = {
  list: PropTypes.array,
  listMetadata: PropTypes.object
};

export default namedCompose(
  injectIntl,
  connect(state => {
    const {
      members: { paginatedResults, urlParams, exporting, loadingList, errorList },
      page: { params },
      companies: { currentCompany, currentCompanyDetails },
      memberTypes: { list }
    } = state;

    return {
      list: _get(paginatedResults, 'results', []),
      listMetadata: _get(paginatedResults, 'metadata', {}),
      memberRules: headerContractMemberValuesSelector(state),
      membersAdvancedListHeader: membersAdvancedListHeaderSelector(state),
      loadingList,
      errorList,
      urlParams,
      pageParams: params,
      exporting,
      currentCompany,
      currentCompanyDetails,
      memberTypesList: list
    };
  })
)(Members);
