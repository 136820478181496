import * as types from '../constants/actionTypes-constants';

export function setInvoiceFindBookingCurrentSortedIndex(index) {
  return {
    type: types.INVOICE_FIND_BOOKING_SET_CURRENT_SORTED_INDEX,
    index
  };
}

export function setInvoiceFindBookingSortIsDescending(isDescending) {
  return {
    type: types.INVOICE_FIND_BOOKING_SET_SORT_DIRECTION,
    isDescending
  };
}
