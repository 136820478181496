import React, { Component } from 'react';
import { connect } from 'react-redux';
import { routeActions } from 'react-router-redux';

import HotlineForm from '../../components/HotlineForm/HotlineForm';
import { updateHotlineRequest, updateHotlineSuccess, addFlashMessage } from '../../actions/all-actions';
import { FLASH_MESSAGE_TYPE_SUCCESS } from '../../constants/generic-constants';
import routes from '../../constants/routes-constants';
import _values from 'lodash/values';
import { partialErrorCodes } from '../../constants/options-constants';
import { addErrorMessage } from '../../utils/utils';

class EditHotline extends Component {
  constructor() {
    super();
    this.handleUpdateHotline = this.handleUpdateHotline.bind(this);
  }

  handleUpdateHotline() {
    const { dispatch, currentHotline } = this.props;
    dispatch(updateHotlineRequest(currentHotline.id))
      .then(hotline => {
        dispatch(updateHotlineSuccess(hotline));
        dispatch(
          addFlashMessage({
            contentKey: 'editHotline_update_hotline_success',
            type: FLASH_MESSAGE_TYPE_SUCCESS,
            delayToNextRoute: true
          })
        );
        dispatch(routeActions.push(routes.hotlines.path));
      })
      .catch(error => {
        const partialErrors = _values(partialErrorCodes.generic);
        const msgParams = { error, partialErrors, def: 'editHotline_update_hotline_error' };
        dispatch(addErrorMessage(msgParams));
      });
  }

  render() {
    const { currentHotline } = this.props;

    return (
      <div className="editHotlinePage mainContainer_content">
        <div className="pageContainer">
          <div className="editHotlinePage_content">
            <section className="editHotlinePage_management">
              <HotlineForm
                onCallback={this.handleUpdateHotline}
                initialValues={{
                  name: currentHotline.name,
                  customerServiceName: currentHotline.customerServiceName || '',
                  customerServiceEmail: currentHotline.customerServiceEmail,
                  noReplyEmail: currentHotline.noReplyEmail || '',
                  phoneNumbers: currentHotline.phoneNumbers || []
                }}
              />
            </section>
          </div>
        </div>
      </div>
    );
  }
}

EditHotline.displayName = 'EditHotline';

EditHotline.propTypes = {};

export default connect(state => {
  const {
    hotlines: { currentHotline }
  } = state;

  return {
    currentHotline
  };
})(EditHotline);
