import * as types from '../constants/actionTypes-constants';

const defaultState = {
  selectedMemberInfosOpened: false,
  paginatedResults: {
    results: [],
    metadata: {
      paginationInfo: {
        currentPage: 1,
        currentSize: 0,
        pageSize: 10,
        totalPages: 0,
        totalResults: 0
      }
    }
  }
};

export default function(state = defaultState, action) {
  switch (action.type) {
    case types.BOOKING_FIND_VEHICLE_GET_LIST_REQUEST_SUCCESS:
      return Object.assign({}, state, {
        paginatedResults: action.list,
        urlParams: action.urlParams
      });

    case types.BOOKING_FIND_VEHICLE_TOGGLE_MEMBER_INFOS:
      return Object.assign({}, state, {
        selectedMemberInfosOpened: !state.selectedMemberInfosOpened
      });

    case types.BOOKING_CREATE_CLEAR_CREATION_SESSION:
      return defaultState;

    default:
      return state;
  }
}
