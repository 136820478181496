import React, { Component } from 'react';
import autoBind from 'react-autobind';
import pure from 'recompose/pure';
import { addOpenNewTabEvents, hideButtonHoverMsg, hideDriverImagesForCustomCompanyId, namedCompose } from '../../../utils/utils';
import { FormattedMessage } from 'react-intl';
import routes, { bookingEditRules } from '../../../constants/routes-constants';
import { drivingLicenseModalStyles, FileImageStyles } from '../../../constants/style-constants';
import { connect } from 'react-redux';
import { localeSelector } from '../../../selectors/all-selectors';
import Modal from 'react-modal';
import { RaisedButton } from 'material-ui';
import cs from 'classnames';

import DetailIcon from 'material-ui/svg-icons/image/picture-as-pdf';
import _capitalize from 'lodash/capitalize';
import _map from 'lodash/map';
import _get from 'lodash/get';
import FileImage from '../../FileImage/FileImage';
import { IconButton } from 'material-ui';
import CloseIcon from 'material-ui/svg-icons/navigation/close';
import ReactSVG from 'react-svg';
import RotateRightIcon from 'material-ui/svg-icons/image/rotate-right';
import RotateLeftIcon from 'material-ui/svg-icons/image/rotate-left';
import ZoomInIcon from 'material-ui/svg-icons/content/add';
import ZoomOutIcon from 'material-ui/svg-icons/content/remove';
import AvatarEditor from 'react-avatar-editor';
import Measure from 'react-measure';
import {
  BOOKING_STATUS_COMPLETED,
  BOOKING_STATUS_IN_PROGRESS,
  BOOKING_STATUS_SCHEDULED,
  BOOKING_STATUS_PRE_BOOKED
} from '../../../constants/backend-constants';
import IsGranted from '../../IsGranted/IsGranted';

class BookingMember extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.setDefaultVars();
    this.state = {
      openDrivingModal: false,
      image: null,
      dimensions: {
        width: 0,
        height: 0
      },
      scale: 1,
      rotate: 0
    };

    this.derivedStateFromProps(props);
  }

  setDefaultVars() {
    this.zoomStep = 0.2;
    this.scale = 1;
    this.rotate = 0;
  }

  // eslint-disable-next-line no-unused-vars
  componentWillReceiveProps(nextProps, nextContext) {
    this.derivedStateFromProps(nextProps);
  }

  derivedStateFromProps(nextProps) {
    const { memberId, firstName, lastName, drivingLicenceFiles, companyId, drivingLicenceStatus, bookingStatus } = nextProps;
    this.firstName = firstName ? _capitalize(firstName) : '';
    this.lastName = lastName ? _capitalize(lastName) : '';
    this.initPic = drivingLicenceFiles.length ? drivingLicenceFiles[0].url : '';
    this.memberHref = memberId ? `#${routes.memberDetail.path.replace(':memberId', memberId)}` : undefined;
    this.hideDriverPhotos = hideDriverImagesForCustomCompanyId(companyId, drivingLicenceStatus);
    this.bookingStatus = bookingStatus;
    this.isScheduledBooking = this.bookingStatus === BOOKING_STATUS_SCHEDULED || this.bookingStatus === BOOKING_STATUS_PRE_BOOKED;
  }

  componentWillUnmount() {
    hideButtonHoverMsg();
  }

  addUrl(child, href) {
    if (href) return <a href={href}>{child}</a>;
    else return child;
  }

  memberName() {
    const memberName = (this.firstName + ' ' + this.lastName).trim();

    if (this.firstName || this.lastName)
      return (
        <div className="row-info">
          <span className="label">
            <FormattedMessage id="booking_view_member_name" />
          </span>
          <span className="value">{this.addUrl(memberName, this.memberHref)}</span>
        </div>
      );
  }

  memberStatus() {
    const { memberStatus } = this.props;
    this.memberStatusClass = 'member-status member-status_' + memberStatus.toLowerCase();
    if (memberStatus)
      return (
        <div className="row-info">
          <span className="label">
            <FormattedMessage id="booking_view_member_status" />
          </span>
          <span className="value">
            <span className={this.memberStatusClass}>
              <FormattedMessage id={`members_status_${memberStatus.toLowerCase()}`} />
            </span>
          </span>
        </div>
      );
  }

  drivingLicence() {
    const { drivingLicenceFiles } = this.props;
    const { length: globalFilesLength } = drivingLicenceFiles || {};
    if (globalFilesLength > 0 && !this.hideDriverPhotos)
      return (
        <div className="row-info">
          <span className="label">
            <FormattedMessage id="booking_view_driving_files_at_creation" />
          </span>
          <span className="value">
            <span className="link" onClick={() => this.setState({ openDrivingModal: true })}>
              <ReactSVG src="/img/driving-licence-icon.svg" className="svg-inline" svgClassName="svg-icon member-files" />
              <ReactSVG src="/img/open-in-new.svg" className="svg-inline" svgClassName="svg-icon" />
            </span>
          </span>
        </div>
      );
  }

  handleClose() {
    this.setState({ openDrivingModal: false });
  }

  displayActionButtons() {
    if (!(this.state.image || this.initPic).includes('.pdf'))
      return (
        <div className="img-actions">
          <div className="component-icon-container">
            <button className="component-icon-button" onClick={this.handleRotateRight} type="button">
              <RotateRightIcon />
            </button>
          </div>
          <div className="component-icon-container">
            <button className="component-icon-button big" onClick={this.handleZoomOut} type="button">
              <ZoomOutIcon />
            </button>
          </div>
          <div className="component-icon-container">
            <button className="component-icon-button big " onClick={this.handleZoomIn} type="button">
              <ZoomInIcon />
            </button>
          </div>
          <div className="component-icon-container">
            <button className="component-icon-button" onClick={this.handleRotateLeft} type="button">
              <RotateLeftIcon />
            </button>
          </div>
        </div>
      );
  }

  handleZoomIn() {
    this.setState({ scale: this.scaleUp() });
  }

  handleZoomOut() {
    this.setState({ scale: this.scaleDowm() });
  }

  handleRotateRight() {
    let rotate = this.rotate;

    rotate += 90;
    if (Math.abs(rotate) > 270) rotate = 0;

    this.rotate = rotate;
    this.setState({ rotate: rotate });
  }

  handleRotateLeft() {
    let rotate = this.rotate;

    rotate -= 90;
    if (Math.abs(rotate) > 270) rotate = 0;

    this.rotate = rotate;
    this.setState({ rotate: rotate });
  }

  setTransform() {
    return `rotate(${this.rotate}deg)`;
  }

  scaleUp() {
    return (this.scale += this.zoomStep);
  }

  scaleDowm() {
    if (this.scale < 0.4) return 0.2;
    return (this.scale -= this.zoomStep);
  }

  seeFile(url) {
    this.setState({ image: url });
    this.setDefaultVars();
  }

  renderMainImage(url) {
    if (url.includes('.pdf')) {
      return (
        <div className="wrap-img">
          <iframe width="100%" height="100%" border="0" src={url} />
        </div>
      );
    }
    return (
      <Measure bounds onResize={this.handleWindowResize}>
        {this.imageWrapper}
      </Measure>
    );
  }

  drivingFilesModal() {
    const { drivingLicenceFiles, drivingLicenceMetaData } = this.props;
    const { licenceNumber, cityDeliverance, deliveranceDate, expirationDate } = drivingLicenceMetaData || {};
    const memberName = (this.firstName + ' ' + this.lastName).trim();
    return (
      <Modal
        isOpen={this.state.openDrivingModal}
        style={drivingLicenseModalStyles}
        className="driving-files-modal"
        onRequestClose={this.handleClose}
        shouldCloseOnOverlayClick={false}
      >
        <div className="modal-container">
          <div className="info">
            <div className="title">
              <FormattedMessage id="members_detail_driver_subtitle" />
            </div>
            <div className="label">
              <FormattedMessage id="searchBookingsForm_title_member" />
            </div>
            <div className="value">{memberName}</div>
            <div className="label">
              <FormattedMessage id="booking_view_driver_license" />{' '}
            </div>
            <div className="value"> {licenceNumber}</div>
            <div className="label">
              <FormattedMessage id="members_detail_driver_deliverance_date" />
            </div>
            <div className="value"> {deliveranceDate}</div>
            <div className="label">
              <FormattedMessage id="members_detail_driver_city_delivrance" />{' '}
            </div>
            <div className="value"> {cityDeliverance}</div>
            {expirationDate && (
              <div>
                <div className="label">
                  <FormattedMessage id="members_detail_driver_expiration_date" />
                </div>
                <div className="value">{expirationDate}</div>
              </div>
            )}
          </div>
          <div className="files">
            <div className="close-btn">
              <IconButton onClick={this.handleClose}>
                <CloseIcon />
              </IconButton>
            </div>
            <div className="wide-pic">{this.renderMainImage(this.state.image || this.initPic)}</div>
            {this.displayActionButtons()}
            <div className="wrapper-thumbs">
              {_map(drivingLicenceFiles, (item = {}, index) => {
                return item.url ? (
                  <div key={item.id || index} className="wrap-img" onClick={() => this.seeFile(item.url)}>
                    {item.url.includes('.pdf') ? (
                      <DetailIcon style={FileImageStyles.icons.pdf} />
                    ) : (
                      <img src={item.url} alt={_get(item, 'id')} />
                    )}
                  </div>
                ) : (
                  <FileImage key={item.id || index} imageObject={item} />
                );
              })}
            </div>
          </div>
        </div>
      </Modal>
    );
  }

  handleWindowResize(contentRect) {
    this.setState({ dimensions: contentRect.bounds });
  }

  imageWrapper({ measureRef }) {
    const {
      dimensions: { width, height },
      rotate,
      scale
    } = this.state;

    return (
      <div className="wrap-img" ref={measureRef}>
        <AvatarEditor
          image={this.state.image || this.initPic}
          border={0}
          color={[255, 255, 255, 0.6]} // RGBA
          scale={scale}
          width={width}
          height={height}
          rotate={rotate}
          disableBoundaryChecks
        />
      </div>
    );
  }

  render() {
    return (
      <div className="booking-member">
        <h4 className="booking-member_headline">
          <FormattedMessage id="booking_view_member_tile_header" />
        </h4>
        <div className="booking-member_wrap">
          {this.memberName()}
          {this.memberStatus()}
          {this.drivingLicence()}
        </div>
        <div className={cs('booking-member_actions', { air: this.hideDriverPhotos })}>
          <IsGranted disallowed={bookingEditRules.exclude}>
            <RaisedButton
              label={<FormattedMessage id="booking_detail_impersonate_button" />}
              primary
              type="button"
              {...addOpenNewTabEvents(this.props.impersonate)}
            />
          </IsGranted>
          {this.isScheduledBooking && (
            <IsGranted disallowed={bookingEditRules.exclude}>
              <RaisedButton
                label={<FormattedMessage id="vehiclePlanning_changeMember" />}
                primary
                type="button"
                onClick={this.props.changeMember}
              />
            </IsGranted>
          )}
        </div>
        {this.drivingFilesModal()}
      </div>
    );
  }
}

BookingMember.defaultProps = {
  memberStatus: undefined, //String
  firstName: undefined, // String
  lastName: undefined, // String
  memberId: undefined, // String
  companyId: undefined, // String
  drivingLicenceStatus: undefined, //string
  drivingLicenceFiles: [],
  drivingLicenceMetaData: undefined,
  impersonate: undefined, // fn
  changeMember: undefined, // fn
  bookingStatus: undefined // string
};

export default namedCompose(
  connect(state => {
    return {
      locale: localeSelector(state) // pure exception
    };
  }),
  pure
)(BookingMember);
