import { FLASH_MESSAGE_TYPE } from '../../constants/generic-constants';
import { getAppObj, safe } from '../utils';

const calcMsgTimeOptions = {
  characterReadTimeMs: 100,
  staticBufferMs: 1000,
  minReadTimeMs: 4000,
  fallbackTime: 8000
};

export function calcMsgTime(message, options) {
  if (options) options = { ...calcMsgTimeOptions, ...options };
  else options = calcMsgTimeOptions;
  const { characterReadTimeMs, staticBufferMs, minReadTimeMs, fallbackTime } = options;

  if (typeof message === 'string') {
    const onlyChars = message.replace(/\s/g, '');
    const msgLength = onlyChars.length * characterReadTimeMs + staticBufferMs;
    return Math.max(minReadTimeMs, msgLength);
  } else return fallbackTime;
}

export function addFlashMsg(message, type = FLASH_MESSAGE_TYPE.INFO, options = {}) {
  safe(() =>
    getAppObj().FlashMessage[type](message, {
      progress: true,
      interactive: true,
      timeout: calcMsgTime(message),
      theme: 'dark',
      ...options
    })
  );
}

export function addSuccessMsg(message, options) {
  addFlashMsg(message, FLASH_MESSAGE_TYPE.SUCCESS, options);
}

export function addErrorMsg(message, options) {
  addFlashMsg(message, FLASH_MESSAGE_TYPE.ERROR, options);
}

export function addInfoMsg(message, options) {
  addFlashMsg(message, FLASH_MESSAGE_TYPE.INFO, options);
}
