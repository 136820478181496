import React, { Component, PropTypes } from 'react';
import classnames from 'classnames';
import FieldErrorMsg from '../FieldErrorMsg/FieldErrorMsg';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import SlimSelect from 'slim-select';
import cs from 'classnames';
import { localeSelector } from '../../selectors/all-selectors';
import memoizeOne from 'memoize-one';
import { getMsg } from '../../utils/IntlGlobalProvider';
import { newProp, safe, testProp } from '../../utils/utils';
import deepEqual from 'lodash/isEqual';

const convertOptionsToData = memoizeOne(os =>
  os.map(o => ({
    text: o.labelKey ? getMsg(o.labelKey) : o.label || o.value,
    value: o.value
  }))
);

class SlimerSelect extends Component {
  constructor(props) {
    super(props);
    this.setCallbacks();
    this.propsInit = true;
  }

  callOnChange(data) {
    const { props: p } = this;
    const { onChange } = p;
    p.field.onChange(data);
    if (onChange) onChange(data);
  }

  setSelectValue(value) {
    this.slimSelect.setSelected(value);
    value = this.slimSelect.getSelected();
    if (!value.length) {
      value = null;
      this.callOnChange(null);
    }
    return value;
  }

  setInitialValue(value) {
    if (!this.initialValueSet) {
      const setValue = this.setSelectValue(value);
      if (setValue) this.initialValueSet = true;
    }
    this.initialValue = value;
  }

  optionsPresent() {
    return safe(() => this.props.options.length);
  }

  setFieldValue(f = this.props.field) {
    if (f.value) this.setSelectValue(f.value);
    else if (f.initialValue) this.setInitialValue(f.initialValue);
  }

  initSelect() {
    const { props: p } = this;
    const { field: f } = p;
    const { showSearch, hideSelected, maxValuesShown, closeOnSelect, allowDeselect, disabled } = this.props;

    this.slimSelect = new SlimSelect({
      select: this.selectRef,
      settings: {
        placeholderText: getMsg('common_select'),
        searchText: getMsg('common_no_results'),
        searchPlaceholder: getMsg('common_search'),
        showSearch,
        hideSelected,
        maxValuesShown,
        closeOnSelect,
        allowDeselect,
        disabled
      },
      data: convertOptionsToData(p.options),
      events: {
        beforeChange: data => {
          data = data.map(i => i.value);
          if (!data.length) data = null;
          this.callOnChange(data);
          return true;
        }
      }
    });
    if (this.optionsPresent()) {
      this.setFieldValue(f);
    }
  }

  componentDidMount() {
    this.initSelect();
  }

  needUpdateInitialValue(initialValue) {
    return initialValue && this.optionsPresent() && initialValue !== this.initialValue && !deepEqual(initialValue, this.initialValue);
  }

  componentDidUpdate(pp) {
    const { options, disabled, field: f } = this.props;

    if (testProp.call(this, pp, { options }) && !deepEqual(options, pp.options)) {
      this.slimSelect.setData(convertOptionsToData(options));
      this.slimSelect.close();
      if (this.optionsPresent()) this.setFieldValue(f);
      else this.callOnChange(null);
    }
    if (testProp.call(this, pp, { disabled })) {
      disabled ? this.slimSelect.disable() : this.slimSelect.enable();
      this.slimSelect.close();
    }
    if (this.needUpdateInitialValue(f.initialValue)) {
      this.initialValueSet = false;
      this.setInitialValue(f.initialValue);
      this.slimSelect.close();
    }
    if (newProp.call(this, pp, 'locale')) {
      this.slimSelect.destroy();
      this.initSelect();
    }
  }

  componentWillUnmount() {
    this.slimSelect.destroy();
  }

  setCallbacks() {
    this.setSelectRef = ref => {
      this.selectRef = ref;
    };
  }

  getTitle() {
    const p = this.props;
    return p.labelKey ? <FormattedMessage id={p.labelKey} /> : p.label;
  }

  render() {
    const p = this.props;

    return (
      <div className={cs('slim-select', p.className)} id={p.id}>
        <label className={classnames('boxedSelect_label')}>{this.getTitle()}</label>
        <select ref={this.setSelectRef} multiple={p.multiple} />
        <FieldErrorMsg field={p.field} customClass="fieldErrorMsg" />
      </div>
    );
  }
}

SlimerSelect.defaultProps = {
  title: 'Selector',
  multiple: true,
  showSearch: true,
  closeOnSelect: true,
  allowDeselect: true,
  maxValuesShown: 5,
  options: []
};

export const optionItem = PropTypes.shape({
  label: PropTypes.string,
  labelKey: PropTypes.string,
  value: PropTypes.string
});

SlimerSelect.propTypes = {
  id: PropTypes.string, // select id
  className: PropTypes.string, // container className
  disabled: PropTypes.bool, // select disabled
  multiple: PropTypes.bool, // enable multi-select
  options: PropTypes.arrayOf(optionItem), // array of options
  label: PropTypes.string, // field label
  labelKey: PropTypes.string, // field labelKey
  field: PropTypes.object, // field
  onChange: PropTypes.func, // selected fields changed callback
  showSearch: PropTypes.bool, // enable search
  closeOnSelect: PropTypes.bool, // close dropdown on select
  hideSelected: PropTypes.bool, // hide selected options from the dropdown
  maxValuesShown: PropTypes.number, // number of options shown before grouping
  allowDeselect: PropTypes.bool // enable de-select functionality
};

SlimerSelect = connect(state => ({ locale: localeSelector(state) }))(SlimerSelect);

export default SlimerSelect;
