import React, { Component, PropTypes } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { routeActions } from 'react-router-redux';
import { getStore } from '../../store/all-store';

import Header from '../../components/Header/Header';
import TableView from '../../components/TableView/TableView';
import SideMenu from '../../components/SideMenu/SideMenu';
import PageManagementHeader from '../../components/PageManagementHeader/PageManagementHeader';
import PaginationControls from '../../components/PaginationControls/PaginationControls';
import NumberResultsSelectForm from '../../components/NumberResultsSelectForm/NumberResultsSelectForm';
import FlashMessageDisplayer from '../../components/FlashMessageDisplayer/FlashMessageDisplayer';
import routes from '../../constants/routes-constants';
import { configurationsTableViewParams } from '../../constants/table-constants';
import { BACKUSER_ROLE_CALL_CENTER_OPERATOR } from '../../constants/backend-constants';

class Configurations extends Component {
  constructor() {
    super();
    this.handleGotoPage = this.handleGotoPage.bind(this);
    this.handleNumberResultsSelect = this.handleNumberResultsSelect.bind(this);
  }

  handleGotoPage(value) {
    const { dispatch, listMetadata, urlParams } = this.props;
    let params = {
      page: {
        number: value,
        size: listMetadata.paginationInfo.pageSize
      }
    };
    let newParams = Object.assign(urlParams, params);
    dispatch(routeActions.push(routes.configurations.path.replace(':search', encodeURIComponent(JSON.stringify(newParams)))));
  }

  handleNumberResultsSelect(value) {
    const { dispatch, urlParams } = this.props;
    let params = {
      page: {
        number: 1,
        size: parseInt(value)
      }
    };
    let newParams = Object.assign(urlParams, params);
    dispatch(routeActions.push(routes.configurations.path.replace(':search', encodeURIComponent(JSON.stringify(newParams)))));
  }

  render() {
    const { list, listMetadata, role } = this.props;

    return (
      <div className="configurationPage mainContainer_content">
        <div className="pageContainer">
          <div className="configurationPage_content">
            <section className="configurationPage_management">
              <PageManagementHeader
                titleKey="configuration_subtitle"
                href={`/#${routes.addConfiguration.path}`}
                buttonLabelKey="configuration_add"
                customClass="configurationPage_managementHeader"
                limitedAccess={role === BACKUSER_ROLE_CALL_CENTER_OPERATOR}
              />

              {list && list.length > 0 ? (
                <div className="configurationPage_list">
                  <div className="membersPage_pagination">
                    <NumberResultsSelectForm
                      onCallback={this.handleNumberResultsSelect}
                      customClass="membersPage_numberResultsSelectForm"
                      customLabelClass="numberResultsSelectForm_label"
                      onNumberResultsSelect={this.handleNumberResultsSelect}
                      initialValues={{
                        numberResults: listMetadata && listMetadata.paginationInfo ? listMetadata.paginationInfo.pageSize : '10'
                      }}
                    />

                    <PaginationControls
                      paginationInfo={listMetadata.paginationInfo}
                      onPageChange={this.handleGotoPage}
                      customClass="membersPage_paginationControls"
                    />
                  </div>

                  <TableView customClass="configurationPage_tableViewWrapper" params={configurationsTableViewParams} content={list} />
                </div>
              ) : (
                ''
              )}
            </section>
          </div>
        </div>
      </div>
    );
  }
}

Configurations.displayName = 'Configurations';

Configurations.propTypes = {};

export default connect(state => {
  const {
    user: {
      userInfo: { role }
    },
    configuration: { paginatedResults, urlParams }
  } = state;

  return {
    list: paginatedResults.results,
    listMetadata: paginatedResults.metadata,
    urlParams,
    role
  };
})(Configurations);
