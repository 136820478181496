import { UPDATE_LOCATION } from 'react-router-redux';

import * as types from '../constants/actionTypes-constants';

export default function feedbacks(
  state = {
    paginatedResults: null,
    feedbackDetail: null,
    damageImage: null,
    urlParams: null,
    currentSortedIndex: null,
    sortIsDescending: false,
    feedbacksFiltersFormIsOpen: false,
    openedAddCommentModalFeedback: null,
    statusCurrentlyUpdated: false,
    loadingList: true
  },
  action
) {
  switch (action.type) {
    case types.FEEDBACKS_GET_LIST_REQUEST:
      return {
        ...state,
        loadingList: true
      };

    case types.FEEDBACKS_GET_LIST_SUCCESS:
      return Object.assign({}, state, {
        paginatedResults: action.paginatedObj,
        urlParams: action.urlParams,
        loadingList: false
      });

    case types.FEEDBACKS_GET_DETAIL_FEEDBACK_REQUEST:
      return state;

    case types.FEEDBACKS_GET_DETAIL_FEEDBACK_SUCCESS:
      return Object.assign({}, state, {
        feedbackDetail: action.feedback
      });

    case types.FEEDBACKS_GET_DETAIL_REPORT_IMAGE_REQUEST:
      if (!state.feedbackDetail.reportImage) {
        return state;
      }
      return Object.assign({}, state, {
        feedbackDetail: Object.assign({}, state.feedbackDetail, {
          reportImage: null
        })
      });

    case types.FEEDBACKS_GET_DETAIL_REPORT_IMAGE_SUCCESS:
      return Object.assign({}, state, {
        feedbackDetail: Object.assign({}, state.feedbackDetail, {
          reportImage: action.reportImage
        })
      });

    case types.FEEDBACKS_GET_DAMAGE_REPORT_IMAGE_REQUEST:
      if (!state.feedbackDetail.damageImage) {
        return state;
      }
      return Object.assign({}, state, {
        feedbackDetail: Object.assign({}, state.feedbackDetail, {
          damageImage: null
        })
      });

    case types.FEEDBACKS_GET_DAMAGE_REPORT_IMAGE_SUCCESS:
      return Object.assign({}, state, {
        feedbackDetail: Object.assign({}, state.feedbackDetail, {
          damageImage: action.damageImage
        })
      });

    case types.FEEDBACKS_SET_CURRENT_SORTED_INDEX:
      return Object.assign({}, state, {
        currentSortedIndex: action.index
      });

    case types.FEEDBACKS_SORT_DIRECTION_SET:
      return Object.assign({}, state, {
        sortIsDescending: action.isDescending
      });

    case types.FEEDBACKS_FILTERS_FORM_OPENED_STATE_TOGGLE:
      return Object.assign({}, state, {
        feedbacksFiltersFormIsOpen: !state.feedbacksFiltersFormIsOpen
      });

    case types.FEEDBACKS_STATUS_CURRENTLY_UPDATED:
      return Object.assign({}, state, {
        statusCurrentlyUpdated: action.statusCurrentlyUpdated
      });

    case UPDATE_LOCATION:
      return Object.assign({}, state, {
        currentSortedIndex: null,
        sortIsDescending: false
      });

    case types.FEEDBACKS_ADD_COMMENT_MODAL_OPEN:
      return Object.assign({}, state, {
        openedAddCommentModalFeedback: true
      });

    case types.FEEDBACKS_ADD_COMMENT_MODAL_CLOSE:
      return Object.assign({}, state, {
        openedAddCommentModalFeedback: null
      });

    default:
      return state;
  }
}
