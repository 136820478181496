import React, { Component } from 'react';
import autoBind from 'react-autobind';
import pure from 'recompose/pure';
import { getAppFormattedDate, namedCompose, safe, formatCustomDate, withCol } from '../../../utils/utils';
import { FormattedMessage, injectIntl } from 'react-intl';

// noinspection ES6CheckImport
import PropTypes from 'prop-types';

import _get from 'lodash/get';
import { connect } from 'react-redux';
import {
  BOOKING_PAYMENT_STATUS_NA,
  BOOKING_USAGE_TYPE_PRIVATE,
  BACKUSER_ROLE_ROOT,
  BACKUSER_ROLE_SUPER_ADMIN,
  BACKUSER_ROLE_ADMIN,
  BACKUSER_ROLE_ZONE_MANAGER,
  BACKUSER_ROLE_ADMIN_DEALER,
  BOOKING_STATUS_COMPLETED,
  STATUS_CANCELED
} from '../../../constants/backend-constants';
import { bookingPaymentStatus } from '../../../constants/options-constants';
import { localeSelector } from '../../../selectors/all-selectors';
import { isEmpty } from '../../../utils/utils';
import _map from 'lodash/map';
import None from '../../None';
import { RaisedButton } from 'material-ui';
import ReactSVG from 'react-svg';
import { propTypes } from 'react-dotdotdot';
import IsGranted from '../../IsGranted/IsGranted';
import { isGranted } from '../../../actions/user-actions';
import { bookingEditRules } from '../../../constants/routes-constants';
import CopyToClipBoard from '../CopyToClipBoard/CopyToClipBoard';

class BookingBilling extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.derivedStateFromProps(props);
  }

  // eslint-disable-next-line no-unused-vars
  componentWillReceiveProps(nextProps, nextContext) {
    this.derivedStateFromProps(nextProps);
  }

  derivedStateFromProps(props) {
    if (!this.checkDisplayStatus(props)) return false;
    const { statusPayment } = props;
    this.setPriceWithVat(props);
    this.setUpdatePriceLink(props);
    this.headlineStatusClass =
      'payment_status_' +
      String(statusPayment)
        .split(' ')[0]
        .toLowerCase();
    this.paymentStatusKey = bookingPaymentStatus[statusPayment] || 'common_unknown';
  }

  setPriceWithVat(props) {
    const { freeOfCharges, totalPriceIncludingTaxes, estimatedPriceForDuration, currency, intl } = props;

    const currencySymbol = intl.formatMessage({ id: `unit_${currency}` });
    const priceWithVat = freeOfCharges ? 0 : totalPriceIncludingTaxes;

    this.estimatedPriceForDuration = isEmpty(estimatedPriceForDuration)
      ? ''
      : intl.formatMessage({ id: 'common_price_with_currency' }, { amount: String(estimatedPriceForDuration), currency: currencySymbol });
    this.priceWithCurrency = isEmpty(priceWithVat)
      ? ''
      : intl.formatMessage({ id: 'common_price_with_currency' }, { amount: String(priceWithVat), currency: currencySymbol });
  }

  setUpdatePriceLink(props) {
    if (props.updatePrice)
      this.updatePriceLink = (
        <span onClick={props.updatePrice}>
          <ReactSVG src="/img/edit.svg" className="align-svg" />
        </span>
      );
  }

  checkDisplayStatus(nextProps) {
    const { statusPayment, freeOfCharges, bookingUsageType, bookingInvoices } = nextProps;

    this.invoicesPresent = bookingInvoices && bookingInvoices.length > 0;

    this.componentCanRender =
      bookingUsageType === BOOKING_USAGE_TYPE_PRIVATE && !(!freeOfCharges && !statusPayment && !this.invoicesPresent);

    return this.componentCanRender;
  }

  headlineStatus() {
    const { statusPayment } = this.props;
    if (statusPayment && statusPayment !== BOOKING_PAYMENT_STATUS_NA)
      return (
        <span className="detailView_component_headline_status">
          <span className={this.headlineStatusClass}>
            <FormattedMessage id={this.paymentStatusKey} />
          </span>
        </span>
      );
  }

  displayRow(key, value, extra = null) {
    if (key && !isEmpty(value))
      return (
        <div className="row-info">
          <span className="label">
            <FormattedMessage id={key} />
          </span>

          <span className="value">
            {value}
            <IsGranted
              allow={[
                BACKUSER_ROLE_ROOT,
                BACKUSER_ROLE_SUPER_ADMIN,
                BACKUSER_ROLE_ADMIN,
                BACKUSER_ROLE_ZONE_MANAGER,
                BACKUSER_ROLE_ADMIN_DEALER
              ]}
            >
              {extra}
            </IsGranted>
          </span>
        </div>
      );
  }

  getInvoiceList() {
    const {
      bookingInvoices,
      intl: { formatMessage }
    } = this.props;

    return _map(bookingInvoices, (invoice, index) => {
      let price = _get(invoice, 'priceIncludingTaxes');
      price = isEmpty(price) ? '?' : String(price);

      return (
        <li key={invoice.id + '' + index}>
          <a href={invoice.downloadUrl} title="PDF" target="_blank">
            <FormattedMessage
              id="booking_detail_accounting_document_item"
              values={{
                type: withCol(`invoice.type.${invoice.type}`),
                reference: invoice.reference,
                price,
                currency: formatMessage({ id: `unit_${invoice.currency}` }),
                date: getAppFormattedDate(invoice.emissionDate)
              }}
            />
          </a>
        </li>
      );
    });
  }

  dmsAction() {
    const { dmsInvoiceErrors, bookingStatus, bookingUsageType } = this.props;
    if (
      bookingUsageType === BOOKING_USAGE_TYPE_PRIVATE &&
      (bookingStatus === BOOKING_STATUS_COMPLETED || bookingStatus === STATUS_CANCELED) &&
      safe(() => dmsInvoiceErrors.length)
    ) {
      return (
        <RaisedButton
          label={<FormattedMessage id="booking.enable.dms.retry.payment" />}
          primary
          type="button"
          onClick={this.props.dmsRetry}
        />
      );
    }
  }

  italianInvoiceAction() {
    const { italianInvoiceError, bookingStatus, bookingUsageType } = this.props;

    if (
      bookingUsageType === BOOKING_USAGE_TYPE_PRIVATE &&
      (bookingStatus === BOOKING_STATUS_COMPLETED || bookingStatus === STATUS_CANCELED) &&
      italianInvoiceError
    ) {
      return (
        <RaisedButton
          label={<FormattedMessage id="booking_detail_italian_invoice_retry_button" />}
          primary
          type="button"
          onClick={this.props.italianInvoiceRetry}
        />
      );
    }
  }

  italianInvoicesList() {
    const { italianInvoices } = this.props;
    if (italianInvoices.length) {
      const hasRemSta = italianInvoices[0].hasOwnProperty('remoteStatus');
      const hasDesc = italianInvoices[0].hasOwnProperty('errorDetails');
      return (
        <table>
          <thead>
            <tr>
              <th>
                <FormattedMessage id="common_date" />
              </th>
              <th>
                <FormattedMessage id="bookings_tableView_label_invoice_number" />
              </th>
              <th>
                <FormattedMessage id="common_status" />
              </th>
              {hasRemSta && (
                <th>
                  <FormattedMessage id="italian-invoice-remote-status" />
                </th>
              )}
              {hasDesc && (
                <th>
                  <FormattedMessage id="common_details" />
                </th>
              )}
            </tr>
          </thead>
          {italianInvoices.map(invoice => (
            <tr key={invoice.italianInvoiceNumber}>
              <td>{formatCustomDate(invoice.emissionDate, 'DD/MM/YYYY')}</td>
              <td>
                {invoice.italianInvoiceNumber}
                <CopyToClipBoard
                  stringToCopy={invoice.italianInvoiceNumber}
                  keyCopy="booking_view_copy_invoice_number"
                  keyCopied="booking_view_copied_invoice_number"
                />
              </td>
              <td>{invoice.sendingStatus}</td>
              {hasRemSta && <td>{invoice.remoteStatus}</td>}
              {hasDesc && <td className="desc_wide">{invoice.errorDetails}</td>}
            </tr>
          ))}
        </table>
      );
    }
  }
  rrsRetry() {
    const { rrsFailed, carSharingFailed, bookingStatus, bookingUsageType } = this.props;

    if (
      ((bookingUsageType === BOOKING_USAGE_TYPE_PRIVATE && carSharingFailed) || rrsFailed) &&
      (bookingStatus === STATUS_CANCELED || bookingStatus === BOOKING_STATUS_COMPLETED) &&
      isGranted({ disallowed: bookingEditRules.exclude, allowed: bookingEditRules.include })
    ) {
      return (
        <RaisedButton label={<FormattedMessage id="booking_retry_rrs_button" />} primary type="button" onClick={this.props.errorRrs} />
      );
    }
  }

  render() {
    if (this.componentCanRender)
      return (
        <div id="booking-billing" className="booking-tile">
          <h4 className="booking-tile_headline">
            <FormattedMessage id="booking_view_billing_infos_tile_header" />
          </h4>
          <div className="booking-tile_wrap">
            {/* {this.displayRow('booking_detail_billing_identifier', invoiceNumber)} */}
            {this.displayRow('booking_estimated_price', this.estimatedPriceForDuration)}
            {this.displayRow('common_price_with_vat', this.priceWithCurrency, this.updatePriceLink)}
            {this.displayRow('booking_view_payment_status', this.headlineStatus())}
            {this.invoicesPresent && (
              <div className="full-row-info">
                <span className="label">
                  <FormattedMessage id="booking_view_invoices" />{' '}
                </span>
                <span className="value">
                  <ul className="bookingBilling_invoiceList">{this.getInvoiceList()}</ul>
                </span>
              </div>
            )}
            {this.italianInvoicesList()}
          </div>
          <div className="booking-tile_actions">
            <RaisedButton
              label={<FormattedMessage id="invoice_create_find_member_title" />}
              primary
              type="button"
              onClick={this.props.addInvoice}
            />
            {this.props.paymentResettlingAllowed && (
              <IsGranted
                allowed={[
                  BACKUSER_ROLE_ROOT,
                  BACKUSER_ROLE_SUPER_ADMIN,
                  BACKUSER_ROLE_ADMIN,
                  BACKUSER_ROLE_ZONE_MANAGER,
                  BACKUSER_ROLE_ADMIN_DEALER
                ]}
              >
                <RaisedButton
                  label={<FormattedMessage id="booking_detail_settle_booking" />}
                  primary
                  type="button"
                  onClick={this.props.handleSettleBooking}
                />
              </IsGranted>
            )}
            {this.dmsAction()}
            {this.italianInvoiceAction()}
            {this.rrsRetry()}
          </div>
        </div>
      );
    else return <None />;
  }
}

// -----

BookingBilling.propTypes = {
  statusPayment: PropTypes.string,
  bookingUsageType: PropTypes.string,
  freeOfCharges: PropTypes.bool,
  invoiceNumber: PropTypes.string,
  currency: PropTypes.string,
  estimatedPriceForDuration: PropTypes.number,
  totalPriceIncludingTaxes: PropTypes.number,
  voucherDiscountAmount: PropTypes.number,
  bookingInvoices: PropTypes.array,
  italianInvoices: PropTypes.array,
  addInvoice: PropTypes.func,
  updatePrice: PropTypes.func,
  handleSettleBooking: PropTypes.func,
  dmsRetry: PropTypes.func,
  italianInvoiceRetry: PropTypes.func,
  paymentResettlingAllowed: PropTypes.bool,
  italianInvoiceError: PropTypes.bool
};

// -----

export default namedCompose(
  connect(state => {
    return {
      locale: localeSelector(state)
    };
  }),
  injectIntl,
  pure
)(BookingBilling);
