import React, { PropTypes } from 'react';
import { connect } from 'react-redux';

import TableView from '../../../../components/TableView/TableView';
import PageManagementHeader from '../../../../components/PageManagementHeader/PageManagementHeader';
import routes, { editSubCompanyRules } from '../../../../constants/routes-constants';
import tableViewParams from './SubCompaniesTableViewParams';
import { BACKUSER_ROLE_CALL_CENTER_OPERATOR, BACKUSER_ROLE_FLEET_MANAGER } from '../../../../constants/backend-constants';
import { checkRole } from '../../../../constants/backuser-role-rules';

const SubCompanies = ({ subList, role }) => {
  return (
    <div className="subCompaniesPage mainContainer_content">
      <div className="pageContainer">
        <div className="subCompaniesPage_content">
          <section className="subCompaniesPage_management">
            <PageManagementHeader
              titleKey="sub_companies_subtitle"
              href={`/#${routes.addSubCompany.path}`}
              buttonLabelKey="sub_companies_add"
              customClass="subCompaniesPage_managementHeader"
              limitedAccess={
                role === BACKUSER_ROLE_CALL_CENTER_OPERATOR || role === BACKUSER_ROLE_FLEET_MANAGER || !checkRole(editSubCompanyRules, role)
              }
            />
            <div className="subCompaniesPage_list">
              <TableView customClass="subCompaniesPage_tableViewWrapper" params={tableViewParams} content={subList} />
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

SubCompanies.displayName = 'Companies';

SubCompanies.propTypes = {
  list: PropTypes.array,
  listMetadata: PropTypes.object
};

export default connect(state => {
  const {
    user: {
      userInfo: { role }
    },
    subCompanies: { subList, urlParams }
  } = state;

  return {
    subList,
    urlParams,
    role
  };
})(SubCompanies);
