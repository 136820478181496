import fetch from 'isomorphic-fetch';
import _pick from 'lodash/pick';
import _pickBy from 'lodash/pickBy';
import _assign from 'lodash/assign';
import _map from 'lodash/map';
import _get from 'lodash/get';
import _omit from 'lodash/omit';
import _isEmpty from 'lodash/isEmpty';

import config from '../constants/config-constants';
import { ALL, AUTH_TOKEN_HEADER, ISO_DATE_FLAT, SORT_ASC, SORT_DESC, VEHICLES_GET_REPORTS_ID } from '../constants/generic-constants';

import {
  CONTRACTS_GET_CURRENT_CONTRACT_FAILED,
  HOTLINES_CREATE_HOTLINE_FAILED,
  HOTLINES_UPDATE_HOTLINE_FAILED,
  LOGIN_FAILED,
  MEMBER_ANONYMIZE_FAILED,
  MEMBER_ANONYMIZE_FAILED_USER_IS_ACTIVE,
  MEMBER_ANONYMIZE_FAILED_USER_UNKNOWN,
  MEMBER_ANONYMIZE_SUCCESS,
  MEMBER_DEANONYMIZE_FAILED,
  MEMBER_DEANONYMIZE_SUCCESS,
  UNHANDLED_SERVER_ERROR,
  USER_ENABLE_FAILED,
  USER_RESET_PASSWORD_FAILED,
  VEHICLES_SEARCH_VEHICLE_FAILED
} from '../constants/errors-constants';

import {
  checkErrorAndConvert,
  enhanceBookingVehicleSearchRequestResults,
  enhanceCollectionOfUserInfos,
  enhanceContract,
  enhanced2SortingOptions,
  enhancedCloseHoursData,
  enhanceNewPaginationInfos,
  enhancePaginationInfos,
  enhancePaginationInfosMore,
  enhancePaginationInfosWithoutFacets,
  enhanceParkings,
  enhancePostMembersSearchFlatResult,
  enhanceUserInfo,
  parseApiVoucherParams,
  parseHeader
} from './data-enhancer.js';

import { TIME_SLOT_TYPE_PACKAGE_FOR_DURATION, TIME_SLOT_USAGE_PRIVATE } from '../constants/backend-constants';
import { apiParams } from '../constants/api-params-constants';

import {
  checkDate,
  formatDate,
  getApiUrl,
  pickArrayValues,
  pickValidValues,
  toLocalDate,
  trySet,
  toBoolean,
  isEmpty,
  safe
} from '../utils/utils';

import { decreasePageNumber, increasePageNumber, invoicingParamsEncode } from './data-enhancer';
import { cleanDeep } from '../utils/cleanDeep';

const JSON_HEADERS = {
  Accept: 'application/json',
  'Content-Type': 'application/json'
};

const FORM_DATA_HEADERS = {
  Accept: 'application/json'
};

let _setToken;
let _getToken;

export function serialize(data) {
  let query = '';

  for (const param in data || {}) {
    const value = data[param];

    if (!isEmpty(value)) {
      if (query) query += '&';
      query += encodeURIComponent(param) + '=' + encodeURIComponent(value);
    }
  }
  return query;
}

export function addQuery(data = {}) {
  data = serialize(data);
  return data ? '?' + data : '';
}

function getTokenizedHeaders(headers) {
  headers = headers || {};
  headers[AUTH_TOKEN_HEADER] = _getToken();
  return headers;
}

function handleToken(res) {
  const token = res.headers.get('X-AUTH-TOKEN');
  if (config.debugMode) {
    console.log('Token received from refresh:', token); // eslint-disable-line
  }
  _setToken(token);
}

const Api = {
  user: {
    login({ login, password }) {
      const payload = {
        login,
        password
      };

      return fetch(`${getApiUrl()}/v2/users/authenticate`, {
        method: 'POST',
        headers: JSON_HEADERS,
        body: JSON.stringify(payload)
      }).then(res => {
        if (!res.ok) {
          throw {
            type: LOGIN_FAILED,
            code: res.status
          };
        }

        handleToken(res);

        return res.json().then(enhanceUserInfo);
      });
    },

    getQuickSightUrlApi(payload) {
      return fetch('https://223zhbdwm6ecidtgppfavheena0hqxpi.lambda-url.eu-west-1.on.aws', {
        method: 'POST',
        body: JSON.stringify(payload)
      }).then(res => {
        if (!res.ok) throw res;
        return res.text();
      });
    },

    refreshToken() {
      return fetch(`${getApiUrl()}/v2/users/auth/refresh`, {
        method: 'POST',
        headers: getTokenizedHeaders()
      }).then(res => {
        if (!res.ok) {
          throw {
            type: UNHANDLED_SERVER_ERROR,
            code: res.status
          };
        }

        handleToken(res);

        return res.json().then(enhanceUserInfo);
      });
    },

    resetPassword({ login }) {
      const payload = {
        login
      };

      const params = serialize(payload);

      return fetch(`${getApiUrl()}/v2/users/password/reset?${params}`, {
        method: 'POST',
        headers: getTokenizedHeaders(JSON_HEADERS)
      }).then(res => {
        if (!res.ok) {
          throw {
            type: USER_RESET_PASSWORD_FAILED,
            code: res.status
          };
        }
      });
    },

    resetPasswordGET(token) {
      const payload = {
        token: token
      };

      const params = serialize(payload);

      return fetch(`${getApiUrl()}/v2/users/password/reset?${params}`, {
        method: 'GET',
        headers: getTokenizedHeaders(JSON_HEADERS)
      }).then(res => {
        if (!res.ok) {
          throw {
            type: USER_RESET_PASSWORD_FAILED,
            code: res.status
          };
        }
      });
    },

    resetPasswordPUT(params) {
      const body = {
        confirmationPassword: params.confirmationPassword,
        password: params.password
      };

      let payload = {
        token: params.token
      };

      payload = serialize(payload);

      return fetch(`${getApiUrl()}/v2/users/password/reset?${payload}`, {
        method: 'PUT',
        headers: getTokenizedHeaders(JSON_HEADERS),
        body: JSON.stringify(body)
      }).then(res => {
        if (!res.ok) {
          throw {
            type: USER_RESET_PASSWORD_FAILED,
            code: res.status
          };
        }
      });
    },

    updateProfile(payload) {
      return fetch(`${getApiUrl()}/v2/backusers/${payload.id}`, {
        method: 'PUT',
        headers: getTokenizedHeaders(JSON_HEADERS),
        body: JSON.stringify(payload)
      }).then(res => {
        return checkErrorAndConvert(false)(res).then(enhanceUserInfo);
      });
    },

    updatePassword(params) {
      const body = {
        newPassword: params.newPassword,
        oldPassword: params.oldPassword
      };

      return fetch(`${getApiUrl()}/v2/users/password`, {
        method: 'PUT',
        headers: getTokenizedHeaders(JSON_HEADERS),
        body: JSON.stringify(body)
      }).then(res => {
        if (!res.ok) {
          throw {
            type: USER_RESET_PASSWORD_FAILED,
            code: res.status
          };
        }
      });
    },

    enable(data) {
      const payload = { ...data, enable: true };
      const params = serialize(payload);

      return fetch(`${getApiUrl()}/v2/users/enable?${params}`, {
        method: 'POST',
        headers: getTokenizedHeaders(JSON_HEADERS)
      }).then(res => {
        if (!res.ok) {
          throw {
            type: USER_ENABLE_FAILED,
            code: res.status
          };
        }
      });
    }
  },

  customizations: {
    getAssets({ companyId, defaultTemplate }) {
      const params = serialize({
        defaultTemplate: !!defaultTemplate
      });

      return fetch(`${getApiUrl()}/v2/customizations/${companyId}/look?${params}`, {
        method: 'GET',
        headers: getTokenizedHeaders(JSON_HEADERS)
      }).then(checkErrorAndConvert(true));
    },

    setAssets({ companyId, params }) {
      return fetch(`${getApiUrl()}/v2/customizations/${companyId}/look`, {
        method: 'POST',
        headers: getTokenizedHeaders(JSON_HEADERS),
        body: JSON.stringify(params)
      }).then(checkErrorAndConvert(true));
    },

    deleteAssets(companyId) {
      return fetch(`${getApiUrl()}/v2/customizations/${companyId}/look`, {
        method: 'DELETE',
        headers: getTokenizedHeaders(JSON_HEADERS)
      }).then(checkErrorAndConvert(true));
    }
  },

  customFields: {
    save({ field, companyId }) {
      const payload = [field];

      return fetch(`${getApiUrl()}/v2/customizations/${companyId}/fields`, {
        method: 'POST',
        headers: getTokenizedHeaders(JSON_HEADERS),
        body: JSON.stringify(payload)
      }).then(checkErrorAndConvert());
    },
    update({ field }) {
      return fetch(`${getApiUrl()}/v2/customizations/fields/${field.id}`, {
        method: 'PUT',
        headers: getTokenizedHeaders(JSON_HEADERS),
        body: JSON.stringify(field)
      }).then(checkErrorAndConvert());
    }
  },
  invers: {
    getFleets() {
      return fetch(`${getApiUrl()}/v2/invers/fleets`, {
        method: 'GET',
        headers: getTokenizedHeaders(JSON_HEADERS)
      }).then(res => {
        if (res.ok) return res.json();
        else throw res;
      });
    }
  },
  vehicles: {
    getReports(params) {
      const name = VEHICLES_GET_REPORTS_ID;
      const id = _get(params, name);
      const query = _omit(params, name);
      const options = _isEmpty(query) ? '' : '?' + serialize(query);

      return fetch(`${getApiUrl()}/v2/reports/vehicles/${id}${options}`, {
        method: 'GET',
        headers: getTokenizedHeaders(JSON_HEADERS)
      }).then(checkErrorAndConvert(true));
    },

    getReportStatuses({ vehicleId, body }) {
      return fetch(`${getApiUrl()}/v3/vehicle-status-report/vehicles/${vehicleId}/statuses`, {
        method: 'POST',
        headers: getTokenizedHeaders(JSON_HEADERS),
        body: JSON.stringify(body)
      }).then(checkErrorAndConvert(true));
    },

    startExportStatuses({ params }) {
      return fetch(`${getApiUrl()}/v3/vehicle-status-report/start`, {
        method: 'POST',
        headers: getTokenizedHeaders(JSON_HEADERS),
        body: JSON.stringify(params)
      }).then(res => {
        if (!res.ok) {
          throw {
            type: UNHANDLED_SERVER_ERROR,
            code: res.status
          };
        }
        return res.text();
      });
    },

    getExportUrl(id) {
      return fetch(`${getApiUrl()}/v3/vehicle-status-report/${id}/url`, {
        method: 'GET',
        headers: getTokenizedHeaders(JSON_HEADERS)
      }).then(res => {
        if (res.ok) return res.text();
        else throw res;
      });
    },

    getFleet({ params }) {
      let payload;

      if (params) {
        if (params.page) {
          payload = {
            page: {
              number: params.page.number,
              size: params.page.size
            }
          };
        }

        if (params.sort) {
          payload.sort = {
            property: params.sort.property,
            direction: params.sort.isDescending ? SORT_DESC : SORT_ASC
          };
        }

        _assign(payload, pickArrayValues(params, ['vehicleStatus', 'systemType', 'category', 'cleanliness', 'type']));
        _assign(
          payload,
          pickValidValues(params, [
            'transmissionType',
            'fuelType',
            'accessories',
            'brand',
            'model',
            'color',
            'usage',
            'version',
            'plateNumber',
            'companyIds',
            'subCompanyIds',
            'fuelTypes',
            'vin',
            'telematicDeviceId',
            'maximumBoardVoltage',
            'minimumBoardVoltage',
            'transmissionType',
            'includeUninstalledDevice',
            'lowFuelLevel'
          ])
        );
      } else payload = apiParams.default;

      return fetch(`${getApiUrl()}/v2/vehicles/search`, {
        method: 'POST',
        headers: getTokenizedHeaders(JSON_HEADERS),
        body: JSON.stringify(payload)
      }).then(res => {
        if (!res.ok) {
          throw {
            type: UNHANDLED_SERVER_ERROR,
            code: res.status
          };
        }
        return res.json().then(enhancePaginationInfos);
      });
    },

    createVehicle(params) {
      let lastDateTime = new Date(Date.now()).toISOString();

      let payload = {
        transmissionType: params.transmissionType,
        fuelType: params.fuelType,
        versionId: params.carVersion,
        seats: params.numberOfSeats,
        categoryId: params.vehicleClass,
        colorId: params.vehicleColor,
        pictureUrl: params.pictureUrl,
        vin: params.vinNumber,
        usage: params.usage,
        registrationNumber: params.licencePlate,
        serviceLevelType: 'BASIC',
        systemType: params.usedSystems,
        registrationDocumentId: params.registrationDocumentId,
        device: {
          serialNumber: params.deviceSerialNumber
        },
        accessories: params.accessories,
        description: params.description,
        companyId: params.company.id
      };

      payload.ownedByRci = params.vehicleOwner === config.vehicleOwnerChoicesValues.default;

      if (params.parkingLocation) {
        const parking = { id: params.parkingLocation[0] };
        payload.lastPosition = {
          date: lastDateTime,
          parking
        };
      }

      if (params.type) {
        payload.type = params.type;
      }

      if (params.doorsNumber) {
        payload.doorsNumber = params.doorsNumber;
      }

      if (params.registrationDate) {
        payload.registrationDate = formatDate(params.registrationDate);
      }

      if (params.deviceInstallation) {
        payload.device.installationDate = formatDate(params.deviceInstallation);
      }

      if (params.deviceRemoval) {
        payload.device.removalDate = formatDate(params.deviceRemoval);
      }

      if (params.deviceWithKey) {
        payload.device.withKey = toBoolean(params.deviceWithKey);
      }

      return fetch(`${getApiUrl()}/v2/vehicles`, {
        method: 'POST',
        headers: getTokenizedHeaders(JSON_HEADERS),
        body: JSON.stringify(payload)
      }).then(checkErrorAndConvert());
    },

    updateVehicle(params) {
      let lastDateTime = new Date(Date.now()).toISOString();

      let id = params.vehicleId;

      let payload = {
        transmissionType: params.transmissionType,
        fuelType: params.fuelType,
        versionId: params.carVersion,
        seats: params.numberOfSeats,
        categoryId: params.vehicleClass,
        colorId: params.vehicleColor,
        pictureUrl: params.pictureUrl,
        vin: params.vinNumber,
        registrationNumber: params.licencePlate,
        usage: params.usage,
        serviceLevelType: 'BASIC',
        systemType: params.usedSystems,
        description: params.description,
        registrationDocumentId: params.registrationDocumentId,
        device: {
          serialNumber: params.deviceSerialNumber
        },
        accessories: params.accessories,
        companyId: params.company.id
      };

      payload.ownedByRci = params.vehicleOwner === config.vehicleOwnerChoicesValues.default;

      if (params.parkingLocation) {
        payload.lastPosition = {
          date: lastDateTime,
          parking: {
            id: params.parkingLocation
          }
        };
      }

      if (params.type) {
        payload.type = params.type;
      }

      if (params.doorsNumber) {
        payload.doorsNumber = params.doorsNumber;
      }

      if (params.registrationDate) {
        payload.registrationDate = params.registrationDate.length === 10 ? params.registrationDate : formatDate(params.registrationDate);
      }

      if (params.deviceInstallation) {
        payload.device.installationDate =
          params.deviceInstallation.length === 10 ? params.deviceInstallation : formatDate(params.deviceInstallation);
      }

      if (params.deviceRemoval) {
        payload.device.removalDate = params.deviceRemoval.length === 10 ? params.deviceRemoval : formatDate(params.deviceRemoval);
      }

      if (params.deviceWithKey) {
        payload.device.withKey = toBoolean(params.deviceWithKey);
      }

      return fetch(`${getApiUrl()}/v2/vehicles/${id}`, {
        method: 'PUT',
        headers: getTokenizedHeaders(JSON_HEADERS),
        body: JSON.stringify(payload)
      }).then(checkErrorAndConvert(true));
    },

    updateMIBVinVehicle(params) {
      let id = params.vehicleId;
      return fetch(`${getApiUrl()}/v2/vehicles/${id}`, {
        method: 'PUT',
        headers: getTokenizedHeaders(JSON_HEADERS),
        body: JSON.stringify(params)
      }).then(checkErrorAndConvert(true));
    },

    getCategoriesList() {
      return fetch(`${getApiUrl()}/v2/categories`, {
        method: 'GET',
        headers: getTokenizedHeaders()
      }).then(res => {
        if (!res.ok) {
          throw {
            type: UNHANDLED_SERVER_ERROR,
            code: res.status
          };
        }
        return res.json();
      });
    },

    getVehicleDetail(id) {
      return fetch(`${getApiUrl()}/v2/vehicles/${id}`, {
        method: 'GET',
        headers: getTokenizedHeaders()
      }).then(res => {
        if (!res.ok) {
          throw {
            name: UNHANDLED_SERVER_ERROR,
            code: res.status
          };
        }
        return res.json();
      });
    },

    getVehicleDeviceId(vin) {
      return fetch(`${getApiUrl()}/v2/vehicles/${vin}/deviceId`, {
        method: 'GET',
        headers: getTokenizedHeaders()
      }).then(checkErrorAndConvert(true));
    },

    searchFleet({ companyId, params }) {
      let payload = {
        brand: params.brand,
        model: params.model,
        color: params.color,
        version: params.version,
        page: params.page,
        plateNumber: params.plateNumber
      };

      if (params.type) {
        payload.type = [params.type];
      }

      if (params.cleanliness) {
        payload.cleanliness = [params.cleanliness];
      }

      if (params.fuelTypes.length) {
        payload.fuelType = params.fuelTypes;
      }

      if (params.transmissionTypes.length) {
        payload.transmissionType = params.transmissionTypes;
      }

      if (params.accessories.length) {
        payload.accessories = params.accessories;
      }

      if (params.systemType) {
        payload.systemType = [params.systemType];
      }

      if (params.vehicleStatus) {
        payload.vehicleStatus = [params.vehicleStatus];
      }

      if (params.category) {
        payload.category = [params.category];
      }

      if (params.subCompanyId) {
        payload.subCompanyId = params.subCompanyId;
      }

      if (companyId !== ALL) {
        payload.companyId = companyId;
      }

      return fetch(`${getApiUrl()}/v2/vehicles/search`, {
        method: 'POST',
        headers: getTokenizedHeaders(JSON_HEADERS),
        body: JSON.stringify(payload)
      }).then(res => {
        if (!res.ok) {
          throw {
            type: VEHICLES_SEARCH_VEHICLE_FAILED,
            code: res.status
          };
        }
        return res.json().then(enhancePaginationInfos);
      });
    },

    getStatuses(vehicleId) {
      return fetch(`${getApiUrl()}/v2/vehicles/${vehicleId}/statuses`, {
        method: 'GET',
        headers: getTokenizedHeaders()
      }).then(res => {
        if (!res.ok) {
          throw {
            type: UNHANDLED_SERVER_ERROR,
            code: res.status
          };
        }
        return res.json();
      });
    },

    setNewStatus({ vehicleId, params }) {
      return fetch(`${getApiUrl()}/v2/vehicles/${vehicleId}/statuses`, {
        method: 'POST',
        headers: getTokenizedHeaders(JSON_HEADERS),
        body: JSON.stringify(params)
      }).then(res => {
        if (!res.ok) {
          return Promise.reject({
            type: UNHANDLED_SERVER_ERROR,
            code: res.status,
            body: res
          });
        }
        return res.json();
      });
    },

    deleteStatus(statusId) {
      return fetch(`${getApiUrl()}/v2/statuses/${statusId}`, {
        method: 'DELETE',
        headers: getTokenizedHeaders(JSON_HEADERS)
      }).then(res => {
        if (!res.ok) {
          throw {
            type: UNHANDLED_SERVER_ERROR,
            code: res.status
          };
        }
      });
    },

    updateCleanlinessStatus({ vehicleId, cleanliness }) {
      return fetch(`${getApiUrl()}/v2/vehicles/${vehicleId}/cleanliness`, {
        method: 'PUT',
        headers: getTokenizedHeaders(JSON_HEADERS),
        body: JSON.stringify(cleanliness)
      }).then(res => {
        if (!res.ok) {
          throw {
            type: UNHANDLED_SERVER_ERROR,
            code: res.status
          };
        }
      });
    },

    unlockVehicle(vehicleId) {
      return fetch(`${getApiUrl()}/v2/vehicles/${vehicleId}/unlock`, {
        method: 'POST',
        headers: getTokenizedHeaders(JSON_HEADERS)
      }).then(res => {
        if (!res.ok) {
          throw {
            type: UNHANDLED_SERVER_ERROR,
            code: res.status
          };
        }
      });
    },

    lockVehicle(vehicleId) {
      return fetch(`${getApiUrl()}/v2/vehicles/${vehicleId}/lock`, {
        method: 'POST',
        headers: getTokenizedHeaders(JSON_HEADERS)
      }).then(res => {
        if (!res.ok) {
          throw {
            type: UNHANDLED_SERVER_ERROR,
            code: res.status
          };
        }
      });
    },

    getDeviceStatus(vehicleId) {
      return fetch(`${getApiUrl()}/v2/vehicles/${vehicleId}/device-status`, {
        method: 'GET',
        headers: getTokenizedHeaders()
      }).then(res => {
        if (!res.ok) {
          throw {
            type: UNHANDLED_SERVER_ERROR,
            code: res.status
          };
        }
        return res.json();
      });
    },

    createLeaseContract({ vehicleId, params }) {
      let payload = params;

      if (payload.actualEndDate) {
        payload.actualEndDate = formatDate(payload.actualEndDate);
      }

      if (payload.endDate) {
        payload.endDate = formatDate(payload.endDate);
      }

      if (payload.startDate) {
        payload.startDate = formatDate(payload.startDate);
      }

      return fetch(`${getApiUrl()}/v2/vehicles/${vehicleId}/lease-contracts`, {
        method: 'POST',
        headers: getTokenizedHeaders(JSON_HEADERS),
        body: JSON.stringify(payload)
      }).then(checkErrorAndConvert(true));
    },

    createInsuranceContract({ vehicleId, params }) {
      let payload = params;

      if (payload.options) {
        payload.options = payload.options.replace(/\s+/g, '').split(',');
      }

      if (payload.startDate) {
        payload.startDate = formatDate(payload.startDate);
      }

      if (payload.expirationDate) {
        payload.expirationDate = formatDate(payload.expirationDate);
      }

      return fetch(`${getApiUrl()}/v2/vehicles/${vehicleId}/insurance-contracts`, {
        method: 'POST',
        headers: getTokenizedHeaders(JSON_HEADERS),
        body: JSON.stringify(payload)
      }).then(checkErrorAndConvert(true));
    },

    createFuelCard({ vehicleId, params }) {
      let payload = params;

      if (payload.endDate) {
        payload.endDate = formatDate(payload.endDate);
      }

      if (payload.startDate) {
        payload.startDate = formatDate(payload.startDate);
      }

      if (payload.expirationDate) {
        payload.expirationDate = formatDate(payload.expirationDate);
      }

      return fetch(`${getApiUrl()}/v2/vehicles/${vehicleId}/fuel-cards`, {
        method: 'POST',
        headers: getTokenizedHeaders(JSON_HEADERS),
        body: JSON.stringify(payload)
      }).then(checkErrorAndConvert(true));
    },

    createAutolibCard({ vehicleId, params }) {
      let payload = params;

      if (payload.endDate) {
        payload.endDate = formatDate(payload.endDate);
      }

      if (payload.startDate) {
        payload.startDate = formatDate(payload.startDate);
      }

      if (payload.expirationDate) {
        payload.expirationDate = formatDate(payload.expirationDate);
      }

      return fetch(`${getApiUrl()}/v2/vehicles/${vehicleId}/autolib-cards`, {
        method: 'POST',
        headers: getTokenizedHeaders(JSON_HEADERS),
        body: JSON.stringify(payload)
      }).then(res => {
        if (!res.ok) {
          throw {
            type: UNHANDLED_SERVER_ERROR,
            code: res.status
          };
        }
        return res.json();
      });
    },

    getLeaseContractsList(vehicleId) {
      return fetch(`${getApiUrl()}/v2/vehicles/${vehicleId}/lease-contracts`, {
        method: 'GET',
        headers: getTokenizedHeaders()
      }).then(res => {
        if (!res.ok) {
          throw {
            type: UNHANDLED_SERVER_ERROR,
            code: res.status
          };
        }
        return res.json();
      });
    },

    getInsuranceContractsList(vehicleId) {
      return fetch(`${getApiUrl()}/v2/vehicles/${vehicleId}/insurance-contracts`, {
        method: 'GET',
        headers: getTokenizedHeaders()
      }).then(res => {
        if (!res.ok) {
          throw {
            type: UNHANDLED_SERVER_ERROR,
            code: res.status
          };
        }
        return res.json();
      });
    },

    exportVehiclesCSV({ query, token }) {
      return fetch(`${getApiUrl()}/v2/vehicles/export?token=${token}&request=${query}`, {
        method: 'GET',
        headers: getTokenizedHeaders()
      }).then(res => {
        if (!res.ok) {
          throw {
            type: UNHANDLED_SERVER_ERROR,
            code: res.status
          };
        }
        return res.json();
      });
    },

    getFuelCardsList(vehicleId) {
      return fetch(`${getApiUrl()}/v2/vehicles/${vehicleId}/fuel-cards`, {
        method: 'GET',
        headers: getTokenizedHeaders()
      }).then(res => {
        if (!res.ok) {
          throw {
            type: UNHANDLED_SERVER_ERROR,
            code: res.status
          };
        }
        return res.json();
      });
    },

    getAutolibCardsList(vehicleId) {
      return fetch(`${getApiUrl()}/v2/vehicles/${vehicleId}/autolib-cards`, {
        method: 'GET',
        headers: getTokenizedHeaders()
      }).then(res => {
        if (!res.ok) {
          throw {
            type: UNHANDLED_SERVER_ERROR,
            code: res.status
          };
        }
        return res.json();
      });
    },

    deleteLeaseContract(id) {
      return fetch(`${getApiUrl()}/v2/lease-contracts/${id}`, {
        method: 'DELETE',
        headers: getTokenizedHeaders(JSON_HEADERS)
      }).then(res => {
        if (!res.ok) {
          throw {
            type: UNHANDLED_SERVER_ERROR,
            code: res.status
          };
        }
      });
    },

    deleteInsuranceContract(id) {
      return fetch(`${getApiUrl()}/v2/insurance-contracts/${id}`, {
        method: 'DELETE',
        headers: getTokenizedHeaders(JSON_HEADERS)
      }).then(res => {
        if (!res.ok) {
          throw {
            type: UNHANDLED_SERVER_ERROR,
            code: res.status
          };
        }
      });
    },

    deleteFuelCard(id) {
      return fetch(`${getApiUrl()}/v2/fuel-cards/${id}`, {
        method: 'DELETE',
        headers: getTokenizedHeaders(JSON_HEADERS)
      }).then(res => {
        if (!res.ok) {
          throw {
            type: UNHANDLED_SERVER_ERROR,
            code: res.status
          };
        }
      });
    },

    deleteAutolibCard(id) {
      return fetch(`${getApiUrl()}/v2/autolib-cards/${id}`, {
        method: 'DELETE',
        headers: getTokenizedHeaders(JSON_HEADERS)
      }).then(res => {
        if (!res.ok) {
          throw {
            type: UNHANDLED_SERVER_ERROR,
            code: res.status
          };
        }
      });
    }
  },

  vehicleDamages: {
    getList({ params }) {
      let payload;

      if (!params) {
        payload = apiParams.default;
      } else {
        if (params.page) {
          payload = {
            page: {
              number: params.page.number,
              size: params.page.size
            }
          };
        }

        if (params.sort) {
          payload.sort = {
            property: params.sort.property,
            direction: params.sort.isDescending ? SORT_DESC : SORT_ASC
          };
        }

        payload = Object.assign(
          {},
          payload,
          _pick(params, ['bookingId', 'plateNumber', 'damageType', 'status', 'startDate', 'endDate', 'companyIds', 'subCompanyIds'])
        );
      }

      return fetch(`${getApiUrl()}/v2/reports/damages/search`, {
        method: 'POST',
        headers: getTokenizedHeaders(JSON_HEADERS),
        body: JSON.stringify(payload)
      }).then(res => {
        if (!res.ok) {
          throw {
            type: UNHANDLED_SERVER_ERROR,
            code: res.status
          };
        }
        return res.json().then(enhancePaginationInfos);
      });
    },

    getVehicleDamageDetail(id) {
      return fetch(`${getApiUrl()}/v2/reports/damage/${id}/details`, {
        method: 'GET',
        headers: getTokenizedHeaders()
      }).then(res => {
        if (!res.ok) {
          throw {
            name: UNHANDLED_SERVER_ERROR,
            code: res.status
          };
        }
        return res.json();
      });
    },

    editStatus({ vehicleDamageId, status }) {
      return fetch(`${getApiUrl()}/v2/reports/damage/${vehicleDamageId}/status/${status}`, {
        method: 'PUT',
        headers: getTokenizedHeaders(JSON_HEADERS)
      }).then(res => {
        if (!res.ok) {
          throw {
            type: UNHANDLED_SERVER_ERROR,
            code: res.status
          };
        }
        return res.json();
      });
    },

    addDamage(params) {
      const payload = Object.assign(
        {},
        _pickBy(params, function(value) {
          return value !== '' && value !== null && typeof value !== 'undefined';
        })
      );

      return fetch(`${getApiUrl()}/v2/reports/damage`, {
        method: 'POST',
        headers: getTokenizedHeaders(JSON_HEADERS),
        body: JSON.stringify(payload)
      }).then(res => {
        if (!res.ok) {
          throw {
            type: UNHANDLED_SERVER_ERROR,
            code: res.status
          };
        }
        return res.json();
      });
    }
  },

  vehicleTasks: {
    getList(vehicleId) {
      return fetch(`${getApiUrl()}/v2/vehicles/${vehicleId}/tasks`, {
        method: 'GET',
        headers: getTokenizedHeaders(JSON_HEADERS)
      }).then(checkErrorAndConvert(true));
    },

    addTask(params) {
      const { vehicleId, ...payload } = params;

      return fetch(`${getApiUrl()}/v2/vehicles/${vehicleId}/task`, {
        method: 'POST',
        headers: getTokenizedHeaders(JSON_HEADERS),
        body: JSON.stringify(payload)
      }).then(checkErrorAndConvert(true));
    }
  },

  backUsers: {
    search(params) {
      return fetch(`${getApiUrl()}/v2/backusers/search`, {
        method: 'POST',
        headers: getTokenizedHeaders(JSON_HEADERS),
        body: JSON.stringify(params)
      }).then(checkErrorAndConvert(true));
    },

    changeEmail({ userId, login }) {
      return fetch(`${getApiUrl()}/v2/backusers/${userId}/email`, {
        method: 'PUT',
        headers: getTokenizedHeaders(JSON_HEADERS),
        body: JSON.stringify({ login })
      }).then(checkErrorAndConvert(true));
    },

    getList(params) {
      let payload;
      if (!params) {
        payload = apiParams.default;
      } else {
        if (params.page) {
          payload = {
            page: {
              number: params.page.number,
              size: params.page.size
            }
          };
        }

        if (params.sort) {
          payload.sort = {
            property: params.sort.property,
            direction: params.sort.isDescending ? SORT_DESC : SORT_ASC
          };
        }

        if (params.companyIds) {
          payload.companyIds = params.companyIds;
        }

        if (params.subCompanyIds) {
          payload.subCompanyIds = params.subCompanyIds;
        }

        payload = Object.assign({}, payload, _pick(params, ['firstName', 'lastName', 'email', 'role']));
      }

      return fetch(`${getApiUrl()}/v2/backusers/search`, {
        method: 'POST',
        headers: getTokenizedHeaders(JSON_HEADERS),
        body: JSON.stringify(payload)
      }).then(res => {
        if (!res.ok) {
          throw {
            type: UNHANDLED_SERVER_ERROR,
            code: res.status
          };
        }
        return res.json().then(enhancePaginationInfos);
      });
    },

    createBackUser(params) {
      let payload = {
        firstName: params.firstName,
        lastName: params.lastName,
        login: params.login,
        phoneNumber: params.phoneNumber,
        address: params.address,
        role: params.userRole,
        locale: params.locale
      };

      if (params.companyIds) {
        payload.companyIds = params.companyIds;
      }

      return fetch(`${getApiUrl()}/v2/backusers`, {
        method: 'POST',
        headers: getTokenizedHeaders(JSON_HEADERS),
        body: JSON.stringify(payload)
      }).then(checkErrorAndConvert(false));
    },

    getBackUserDetail(id) {
      return fetch(`${getApiUrl()}/v2/backusers/${id}`, {
        method: 'GET',
        headers: getTokenizedHeaders()
      }).then(res => {
        if (!res.ok) {
          throw {
            name: UNHANDLED_SERVER_ERROR,
            code: res.status
          };
        }
        return res.json();
      });
    },

    updateBackUser({ backUserId, params }) {
      return fetch(`${getApiUrl()}/v2/backusers/${backUserId}`, {
        method: 'PUT',
        headers: getTokenizedHeaders(JSON_HEADERS),
        body: JSON.stringify(params)
      }).then(res => {
        return checkErrorAndConvert(false)(res).then(enhanceUserInfo);
      });
    },

    changeStatusBackUser({ backUserId, suspendBackUser }) {
      let payload = {
        suspended: suspendBackUser
      };

      return fetch(`${getApiUrl()}/v2/backusers/${backUserId}/suspend?suspended=${suspendBackUser}`, {
        method: 'PUT',
        headers: getTokenizedHeaders(JSON_HEADERS),
        body: JSON.stringify(payload)
      }).then(res => {
        if (!res.ok) {
          throw {
            type: UNHANDLED_SERVER_ERROR,
            code: res.status
          };
        }
        return res.json();
      });
    },

    resetPasswordBackUser({ id }) {
      return fetch(`${getApiUrl()}/v2/backusers/${id}/password/reset`, {
        method: 'PUT',
        headers: getTokenizedHeaders(JSON_HEADERS)
      }).then(res => {
        if (!res.ok) {
          throw {
            type: UNHANDLED_SERVER_ERROR,
            code: res.status
          };
        }
        return res.text();
      });
    }
  },

  exports: {
    getConfigurations() {
      return fetch(`${getApiUrl()}/v3/export-configurations`, {
        method: 'GET',
        headers: getTokenizedHeaders(JSON_HEADERS)
      }).then(checkErrorAndConvert(true));
    },

    createConfiguration(params) {
      return fetch(`${getApiUrl()}/v3/export-configurations`, {
        method: 'POST',
        headers: getTokenizedHeaders(JSON_HEADERS),
        body: JSON.stringify(params)
      }).then(checkErrorAndConvert(true));
    },

    deleteConfigurations(id) {
      return fetch(`${getApiUrl()}/v3/export-configurations/${id}`, {
        method: 'DELETE',
        headers: getTokenizedHeaders(JSON_HEADERS)
      }).then(checkErrorAndConvert(true));
    }
  },

  members: {
    search(params) {
      return fetch(`${getApiUrl()}/v2/members/search`, {
        method: 'POST',
        headers: getTokenizedHeaders(JSON_HEADERS),
        body: JSON.stringify(params)
      })
        .then(checkErrorAndConvert(true))
        .then(x => {
          enhancePostMembersSearchFlatResult(x, false);
          return enhancePaginationInfos(x);
        });
    },

    upload({ file, companyId }) {
      const formData = new FormData();
      formData.append('file', file);

      return fetch(`${getApiUrl()}/v2/members/import?companyId=${companyId}`, {
        method: 'POST',
        contentType: 'multipart/form-data',
        headers: getTokenizedHeaders(FORM_DATA_HEADERS),
        body: formData
      }).then(checkErrorAndConvert(true));
    },

    getList(params) {
      let payload;
      if (!params) {
        payload = apiParams.default;
      } else {
        if (params.page) {
          payload = {
            page: {
              number: params.page.number,
              size: params.page.size
            }
          };
        }

        if (params.sort) {
          payload.sort = {
            property: params.sort.property,
            direction: params.sort.isDescending ? SORT_DESC : SORT_ASC
          };
        }

        if (params.companyIds) {
          payload.companyIds = params.companyIds;
        }

        if (params.subCompanyIds) {
          payload.subCompanyIds = params.subCompanyIds;
        }

        payload = Object.assign(
          {},
          payload,
          _pick(params, [
            'memberName',
            'email',
            'status',
            'memberTypeId',
            'technician',
            'expediteReview',
            'subscriptionOrigins',
            'markedForAnonymization'
          ])
        );
      }

      return fetch(`${getApiUrl()}/v2/members/search`, {
        method: 'POST',
        headers: getTokenizedHeaders(JSON_HEADERS),
        body: JSON.stringify(payload)
      })
        .then(checkErrorAndConvert(false))
        .then(x => {
          enhancePostMembersSearchFlatResult(x);
          return enhancePaginationInfos(x);
        });
    },

    getMemberCompleteDetail(id) {
      return fetch(`${getApiUrl()}/v2/members/${id}/complete`, {
        method: 'GET',
        headers: getTokenizedHeaders()
      }).then(checkErrorAndConvert(true));
    },

    getMemberProfileHistory(id) {
      return fetch(`${getApiUrl()}/v2/members/profile-history/${id}`, {
        method: 'GET',
        headers: getTokenizedHeaders()
      }).then(checkErrorAndConvert(true));
    },

    updateChorusInfo(enterpriseId) {
      return fetch(`${getApiUrl()}/v3/enterprise/${enterpriseId}/refresh`, {
        method: 'POST',
        headers: getTokenizedHeaders(JSON_HEADERS)
      }).then(checkErrorAndConvert(true));
    },

    updateSimpleMember({ id, data }) {
      return fetch(`${getApiUrl()}/v2/members/${id}`, {
        method: 'PUT',
        headers: getTokenizedHeaders(JSON_HEADERS),
        body: JSON.stringify(data)
      }).then(checkErrorAndConvert(true));
    },

    migrateMember(data) {
      return fetch(`${getApiUrl()}/v2/members/profile-migration`, {
        method: 'POST',
        headers: getTokenizedHeaders(JSON_HEADERS),
        body: JSON.stringify(data)
      }).then(checkErrorAndConvert(true));
    },

    exportMembers(params) {
      const { locale } = params || {};
      const query = locale ? '?' + serialize({ locale }) : '';
      const bodyParams = _omit(params, 'locale');
      const body = !_isEmpty(bodyParams) ? JSON.stringify(bodyParams) : '';
      return fetch(`${getApiUrl()}/v3/exports/members${query}`, {
        method: 'POST',
        headers: getTokenizedHeaders(JSON_HEADERS),
        body
      }).then(res => {
        if (!res.ok) {
          throw {
            type: UNHANDLED_SERVER_ERROR,
            code: res.status
          };
        }
        return res.text();
      });
    },

    getExportUrl(id) {
      return fetch(`${getApiUrl()}/v3/exports/members/${id}/url`, {
        method: 'GET',
        headers: getTokenizedHeaders(JSON_HEADERS)
      }).then(res => {
        if (res.ok) return res.json();
        else throw res;
      });
    },

    exportMembersCSV({ query, token }) {
      return fetch(`${getApiUrl()}/v2/members/export?token=${token}&request=${query}`, {
        method: 'GET',
        headers: getTokenizedHeaders()
      }).then(res => {
        if (!res.ok) {
          throw {
            type: UNHANDLED_SERVER_ERROR,
            code: res.status
          };
        }
        return res.json();
      });
    },

    getCustomFields(memberId) {
      return fetch(`${getApiUrl()}/v2/members/${memberId}/custom-fields`, {
        method: 'GET',
        headers: getTokenizedHeaders(JSON_HEADERS)
      }).then(checkErrorAndConvert(true));
    },

    getSubscriptionCustomFieldsMember(memberId) {
      return fetch(`${getApiUrl()}/v2/members/${memberId}/custom-fields?form=SUBSCRIPTION`, {
        method: 'GET',
        headers: getTokenizedHeaders(JSON_HEADERS)
      }).then(res => {
        if (!res.ok) {
          throw {
            name: UNHANDLED_SERVER_ERROR,
            code: res.status
          };
        }
        return res.json();
      });
    },
    getMemberImpersonate(id) {
      return fetch(`${getApiUrl()}/v2/members/${id}/impersonate`, {
        method: 'GET',
        headers: getTokenizedHeaders()
      }).then(checkErrorAndConvert(false));
    },

    changeSuspensionMember({ id, isSuspendedMember, comment }) {
      let payload;
      comment
        ? (payload = {
            body: comment
          })
        : (payload = null);

      return fetch(`${getApiUrl()}/v2/members/${id}/${isSuspendedMember ? 'unsuspend' : 'suspend'}`, {
        method: 'PUT',
        headers: getTokenizedHeaders(JSON_HEADERS),
        body: JSON.stringify(payload)
      }).then(res => {
        if (!res.ok) {
          throw {
            type: UNHANDLED_SERVER_ERROR,
            code: res.status
          };
        }
        return res.json();
      });
    },

    resetPasswordMember({ memberId, resetPasswordDeliveryMode }) {
      let payload = {
        mode: resetPasswordDeliveryMode
      };

      return fetch(`${getApiUrl()}/v2/members/${memberId}/password/reset`, {
        method: 'POST',
        headers: getTokenizedHeaders(JSON_HEADERS),
        body: JSON.stringify(payload)
      }).then(res => {
        if (!res.ok) {
          throw {
            type: UNHANDLED_SERVER_ERROR,
            code: res.status
          };
        }
      });
    },

    changeEmailMember({ memberId, login }) {
      return fetch(`${getApiUrl()}/v2/members/${memberId}/email`, {
        method: 'PUT',
        headers: getTokenizedHeaders(JSON_HEADERS),
        body: JSON.stringify({ login })
      }).then(res => {
        if (!res.ok) {
          throw {
            type: UNHANDLED_SERVER_ERROR,
            code: res.status
          };
        }
        return res.json();
      });
    },

    changeTechnicianMember(member) {
      let payload = member;
      let id = payload.id;

      return fetch(`${getApiUrl()}/v2/members/${id}`, {
        method: 'PUT',
        headers: getTokenizedHeaders(JSON_HEADERS),
        body: JSON.stringify(payload)
      }).then(res => {
        if (!res.ok) {
          throw {
            type: UNHANDLED_SERVER_ERROR,
            code: res.status
          };
        }
        return res.json();
      });
    },

    changeMemberType({ memberId, memberTypeId }) {
      const payload = {
        memberId,
        memberTypeId
      };
      return fetch(`${getApiUrl()}/v2/members/${memberId}/member-type/${memberTypeId}`, {
        method: 'PUT',
        headers: getTokenizedHeaders(JSON_HEADERS),
        body: JSON.stringify(payload)
      }).then(checkErrorAndConvert(true));
    },

    getCompaniesUsedAsTechnicianList(memberId) {
      return fetch(`${getApiUrl()}/v2/members/${memberId}/technician-companies`, {
        method: 'GET',
        headers: getTokenizedHeaders()
      }).then(res => {
        if (!res.ok) {
          if (res.status === 404) {
            return null;
          }
          throw {
            type: UNHANDLED_SERVER_ERROR,
            code: res.status
          };
        }
        return res.json();
      });
    },

    saveCompanyUsedAsTechnician({ memberId, params }) {
      let payload = {};
      payload.affected = params;

      return fetch(`${getApiUrl()}/v2/members/${memberId}/technician-companies`, {
        method: 'POST',
        headers: getTokenizedHeaders(JSON_HEADERS),
        body: JSON.stringify(payload)
      }).then(res => {
        if (!res.ok) {
          throw {
            type: UNHANDLED_SERVER_ERROR,
            code: res.status
          };
        }
        return res.json();
      });
    },

    getComments(memberId) {
      return fetch(`${getApiUrl()}/v2/members/${memberId}/comments`, {
        method: 'GET',
        headers: getTokenizedHeaders(JSON_HEADERS)
      }).then(res => {
        if (!res.ok) {
          throw {
            type: UNHANDLED_SERVER_ERROR,
            code: res.status
          };
        }
        return res.json();
      });
    },

    addComment({ memberId, comment }) {
      return fetch(`${getApiUrl()}/v2/members/${memberId}/comments`, {
        method: 'POST',
        headers: getTokenizedHeaders(JSON_HEADERS),
        body: JSON.stringify({ body: comment })
      }).then(res => {
        if (!res.ok) {
          throw {
            type: UNHANDLED_SERVER_ERROR,
            code: res.status
          };
        }
        return res.json();
      });
    },

    rejectMemberReason({ memberId, comment }) {
      return fetch(`${getApiUrl()}/v2/members/${memberId}/profile/reject`, {
        method: 'PUT',
        headers: getTokenizedHeaders(JSON_HEADERS),
        body: JSON.stringify({ body: comment })
      }).then(res => {
        if (!res.ok) {
          throw {
            type: UNHANDLED_SERVER_ERROR,
            code: res.status
          };
        }
      });
    },

    approveMember({ memberId }) {
      return fetch(`${getApiUrl()}/v2/members/${memberId}/profile/approve`, {
        method: 'PUT',
        headers: getTokenizedHeaders(JSON_HEADERS)
      }).then(res => {
        if (!res.ok) {
          throw {
            type: UNHANDLED_SERVER_ERROR,
            code: res.status
          };
        }
      });
    },

    anonymize({ memberId }) {
      return fetch(`${getApiUrl()}/v2/members/${memberId}/mark-for-anonymization`, {
        method: 'POST',
        headers: getTokenizedHeaders()
      }).then(res => {
        if (!res.ok) {
          switch (res.status) {
            case 422:
            case 403:
              throw {
                type: MEMBER_ANONYMIZE_FAILED_USER_IS_ACTIVE,
                code: res.status
              };
            case 404:
              throw {
                type: MEMBER_ANONYMIZE_FAILED_USER_UNKNOWN,
                code: res.status
              };
            default:
              throw {
                type: MEMBER_ANONYMIZE_FAILED,
                code: res.status
              };
          }
        } else {
          if (res.status === 204) {
            return {
              type: MEMBER_ANONYMIZE_SUCCESS,
              code: res.status
            };
          }
        }
      });
    },

    deanonymize({ memberId }) {
      return fetch(`${getApiUrl()}/v2/members/${memberId}/deanonymize`, {
        method: 'POST',
        headers: getTokenizedHeaders()
      }).then(res => {
        if (res.ok && res.status === 204) {
          return {
            type: MEMBER_DEANONYMIZE_SUCCESS,
            code: res.status
          };
        } else {
          throw {
            type: MEMBER_DEANONYMIZE_FAILED,
            code: res.status
          };
        }
      });
    },

    addMember({ params, ...query }) {
      return fetch(`${getApiUrl()}/v2/members/pre-subscription?${serialize(query)}`, {
        method: 'POST',
        headers: getTokenizedHeaders(JSON_HEADERS),
        body: JSON.stringify(params)
      }).then(checkErrorAndConvert(true));
    }
  },

  documents: {
    rejectDocumentReason({ documentId, comment }) {
      return fetch(`${getApiUrl()}/v2/documents/${documentId}/reject`, {
        method: 'PUT',
        headers: getTokenizedHeaders(JSON_HEADERS),
        body: JSON.stringify({ body: comment })
      }).then(res => {
        if (!res.ok) {
          throw {
            type: UNHANDLED_SERVER_ERROR,
            code: res.status
          };
        }
      });
    },

    approveDocument({ documentId }) {
      return fetch(`${getApiUrl()}/v2/documents/${documentId}/approve`, {
        method: 'PUT',
        headers: getTokenizedHeaders(JSON_HEADERS)
      }).then(res => {
        if (!res.ok) {
          throw {
            type: UNHANDLED_SERVER_ERROR,
            code: res.status
          };
        }
      });
    }
  },

  transactions: {
    booking(bookingId) {
      return fetch(`${getApiUrl()}/v2/transactions/booking/${bookingId}`, {
        method: 'GET',
        headers: getTokenizedHeaders()
      }).then(res =>
        parseHeader(res, fullResponse => {
          if (!res.ok) {
            const errorObj = { type: UNHANDLED_SERVER_ERROR, code: res.status };
            if (fullResponse.developerMessage) errorObj.developerMessage = fullResponse.developerMessage;
            throw errorObj;
          } else return fullResponse;
        })
      );
    }
  },

  bookings: {
    getList({ params }) {
      let payload;

      if (!params) {
        payload = apiParams.default;
      } else {
        if (params.page) {
          payload = {
            page: {
              number: params.page.number,
              size: params.page.size
            }
          };
        }

        if (params.sort) {
          payload.sort = {
            property: params.sort.property,
            direction: params.sort.isDescending ? SORT_DESC : SORT_ASC
          };
        }

        payload = Object.assign(
          {},
          payload,
          _pick(params, [
            'memberFirstName',
            'memberLastName',
            'memberLogin',
            'status',
            'states',
            'vehicleBrand',
            'vehicleModel',
            'vehicleRegistrationNumber',
            'vehicleUsageAtBookingCreation',
            'endDateMax',
            'endDateMin',
            'startDateMax',
            'startDateMin',
            'delayed',
            'failed',
            'rrsUpdateFailed',
            'companyIds',
            'subCompanyIds',
            'type',
            'voucherCode',
            'voucherGroupName',
            'voucherGroupIds'
          ])
        );

        if (params.bookingId) {
          payload.id = params.bookingId;
        }
      }

      return fetch(`${getApiUrl()}/v2/bookings/search`, {
        method: 'POST',
        headers: getTokenizedHeaders(JSON_HEADERS),
        body: JSON.stringify(payload)
      }).then(res => {
        if (!res.ok) {
          throw {
            type: UNHANDLED_SERVER_ERROR,
            code: res.status,
            body: res
          };
        }
        return res.json().then(enhancePaginationInfos);
      });
    },

    cancelBooking(bookingId) {
      return fetch(`${getApiUrl()}/v2/bookings/${bookingId}/cancel`, {
        method: 'POST',
        headers: getTokenizedHeaders()
      }).then(res => {
        if (!res.ok) {
          throw {
            type: UNHANDLED_SERVER_ERROR,
            code: res.status
          };
        }
      });
    },

    startBooking(payload) {
      let { bookingId, ...body } = payload;
      if (payload.mileage) body = JSON.stringify(body);
      else body = '';

      return fetch(`${getApiUrl()}/v2/bookings/${bookingId}/start`, {
        method: 'POST',
        headers: getTokenizedHeaders(JSON_HEADERS),
        body
      }).then(checkErrorAndConvert(true));
    },

    getBookingDetail(bookingId) {
      return fetch(`${getApiUrl()}/v2/bookings/${bookingId}`, {
        method: 'GET',
        headers: getTokenizedHeaders()
      }).then(res => {
        if (!res.ok) {
          throw {
            type: UNHANDLED_SERVER_ERROR,
            code: res.status
          };
        }
        return res.json();
      });
    },

    getSingleBooking(bookingId) {
      return fetch(`${getApiUrl()}/v2/bookings/${bookingId}/complete`, {
        method: 'GET',
        headers: getTokenizedHeaders()
      }).then(res => {
        if (!res.ok) {
          throw {
            type: UNHANDLED_SERVER_ERROR,
            code: res.status
          };
        }
        return res.json();
      });
    },

    extendShortenBooking({ bookingId, newEndDate }) {
      return fetch(`${getApiUrl()}/v2/bookings/${bookingId}/end-datetime/${newEndDate}`, {
        method: 'PUT',
        headers: getTokenizedHeaders()
      }).then(checkErrorAndConvert(false));
    },

    finishBooking({ bookingId, query }) {
      return fetch(`${getApiUrl()}/v2/bookings/${bookingId}/finish${addQuery(query)}`, {
        method: 'POST',
        headers: getTokenizedHeaders()
      }).then(res => {
        if (!res.ok) {
          throw {
            type: UNHANDLED_SERVER_ERROR,
            code: res.status
          };
        }
      });
    },

    retryItalianInvoice({ italianInvoiceNumber }) {
      return fetch(`${getApiUrl()}/v2/invoices/italian-invoices/${italianInvoiceNumber}`, {
        method: 'POST',
        headers: getTokenizedHeaders()
      }).then(checkErrorAndConvert(true));
    },

    search(payload) {
      return fetch(`${getApiUrl()}/v2/bookings/search-filtered`, {
        method: 'POST',
        headers: getTokenizedHeaders(JSON_HEADERS),
        body: JSON.stringify(payload)
      }).then(res => {
        if (!res.ok) {
          throw {
            type: UNHANDLED_SERVER_ERROR,
            code: res.status,
            body: res
          };
        }
        return res.json().then(enhanceBookingVehicleSearchRequestResults);
      });
    },

    exportBookingsCSV({ query, token }) {
      return fetch(`${getApiUrl()}/v2/bookings/export?token=${token}&request=${query}`, {
        method: 'GET',
        headers: getTokenizedHeaders()
      }).then(res => {
        if (!res.ok) {
          throw {
            type: UNHANDLED_SERVER_ERROR,
            code: res.status
          };
        }
        return res.json();
      });
    },

    exportBookingsCSVV3(params) {
      const { locale } = params;
      const query = locale ? '?' + serialize({ locale }) : '';
      const bodyParams = _omit(params, 'locale');

      const dateNames = [
        'bookingEndDate',
        'bookingStartDate',
        'bookingEndDateMax',
        'bookingEndDateMin',
        'bookingStartDateMax',
        'bookingStartDateMin'
      ];

      dateNames.forEach(dateName => toLocalDate(bodyParams, dateName));

      const body = !_isEmpty(bodyParams) ? JSON.stringify(bodyParams) : '';
      return fetch(`${getApiUrl()}/v3/exports/bookings${query}`, {
        method: 'POST',
        headers: getTokenizedHeaders(JSON_HEADERS),
        body
      }).then(res => {
        if (!res.ok) {
          throw {
            type: UNHANDLED_SERVER_ERROR,
            code: res.status
          };
        }
        return res.text();
      });
    },

    getExportUrl(id) {
      return fetch(`${getApiUrl()}/v3/exports/${id}/file-url`, {
        method: 'GET',
        headers: getTokenizedHeaders(JSON_HEADERS)
      }).then(checkErrorAndConvert(false));
    },

    create(payload) {
      let queryParams;
      queryParams = _get(payload, 'withSubscription', false) ? '?withSubscription=true' : '';
      payload = _omit(payload, 'withSubscription');
      return fetch(`${getApiUrl()}/v2/bookings${queryParams}`, {
        method: 'POST',
        headers: getTokenizedHeaders(JSON_HEADERS),
        body: JSON.stringify(payload)
      }).then(checkErrorAndConvert(false));
    },

    replace(payload) {
      return fetch(`${getApiUrl()}/v2/bookings/${payload.id}/update`, {
        method: 'POST',
        headers: getTokenizedHeaders(JSON_HEADERS),
        body: JSON.stringify(payload)
      }).then(checkErrorAndConvert(false));
    },

    retryRrsBooking(bookingId) {
      return fetch(`${getApiUrl()}/v2/bookings/rrs/${bookingId}/retry`, {
        method: 'PUT',
        headers: getTokenizedHeaders(JSON_HEADERS)
      }).then(res => {
        if (!res.ok) {
          return Promise.reject({
            type: UNHANDLED_SERVER_ERROR,
            code: res.status
          });
        }
      });
    },

    retry(params) {
      let payload = {};

      if (!params) {
        payload = apiParams.default;
      } else {
        payload.failed = true;

        payload = Object.assign({}, payload, _pick(params, ['bookingId', 'operation']));
      }

      return fetch(`${getApiUrl()}/v2/rrs/invoices/retry`, {
        method: 'POST',
        headers: getTokenizedHeaders(JSON_HEADERS),
        body: JSON.stringify(payload)
      }).then(res => {
        if (!res.ok) {
          return Promise.reject({
            type: UNHANDLED_SERVER_ERROR,
            code: res.status
          });
        }
      });
    },

    updatePriceCheck(params) {
      return fetch(`${getApiUrl()}/v2/bookings/${params.bookingId}/price`, {
        method: 'PUT',
        headers: getTokenizedHeaders(JSON_HEADERS),
        body: JSON.stringify(params)
      }).then(response => {
        if (!response.ok) {
          throw {
            type: UNHANDLED_SERVER_ERROR,
            code: response.code,
            response
          };
        }
        return response.json();
      });
    },

    updatePrice(params) {
      return fetch(`${getApiUrl()}/v2/bookings/${params.bookingId}/price`, {
        method: 'POST',
        headers: getTokenizedHeaders(JSON_HEADERS),
        body: JSON.stringify(params)
      }).then(response => {
        if (!response.ok) {
          throw {
            type: UNHANDLED_SERVER_ERROR,
            code: response.code,
            response
          };
        }
        return response;
      });
    },

    changeAutomaticPayment({ bookingId, params }) {
      return fetch(`${getApiUrl()}/v2/bookings/${bookingId}/automatic-payment-retry?${serialize(params)}`, {
        method: 'PUT',
        headers: getTokenizedHeaders(JSON_HEADERS)
      }).then(checkErrorAndConvert(true));
    },

    retryDmsPayment(bookingId) {
      // POST /api/v3/dms-invoices/resend/{bookingId}
      return fetch(`${getApiUrl()}/v3/dms-invoices/resend/${bookingId}`, {
        method: 'POST',
        headers: getTokenizedHeaders(JSON_HEADERS)
      }).then(checkErrorAndConvert(true));
    },

    settle(bookingId) {
      return fetch(`${getApiUrl()}/v2/payment-settling/bookings/${bookingId}/settle`, {
        method: 'POST',
        headers: getTokenizedHeaders(JSON_HEADERS)
      }).then(res => {
        if (!res.ok) {
          throw {
            type: UNHANDLED_SERVER_ERROR,
            code: res.status,
            body: res
          };
        }
        return true;
      });
    },

    getBookingEventsHistory(bookingId) {
      return fetch(`${getApiUrl()}/v3/bookings/events/${bookingId}`, {
        method: 'GET',
        headers: getTokenizedHeaders(JSON_HEADERS)
      }).then(checkErrorAndConvert(true));
    },

    confirm(bookingId) {
      return fetch(`${getApiUrl()}/v2/bookings/${bookingId}/confirm`, {
        method: 'PUT',
        headers: getTokenizedHeaders(JSON_HEADERS)
      }).then(checkErrorAndConvert(true));
    }
  },

  companies: {
    getCustomFields({ companyId, form = '' }) {
      let params = form ? '?' + serialize({ form: form }) : '';

      return fetch(`${getApiUrl()}/v2/customizations/${companyId}/fields${params}`, {
        method: 'GET',
        headers: getTokenizedHeaders(JSON_HEADERS)
      }).then(checkErrorAndConvert(true));
    },

    getList() {
      return fetch(`${getApiUrl()}/v2/companies`, {
        method: 'GET',
        headers: getTokenizedHeaders()
      }).then(res => {
        if (!res.ok) {
          throw {
            type: UNHANDLED_SERVER_ERROR,
            code: res.status
          };
        }
        return res.json();
      });
    },

    getListWithSearch(params) {
      let payload;

      if (!params) {
        payload = apiParams.default;
      } else {
        if (params.page) {
          payload = {
            page: {
              number: params.page.number,
              size: params.page.size
            },
            ...params
          };
        }
        if (params.sort) {
          payload.sort = {
            property: params.sort.property,
            direction: params.sort.isDescending ? SORT_DESC : SORT_ASC
          };
        }
      }

      return fetch(`${getApiUrl()}/v2/companies/search`, {
        method: 'POST',
        headers: getTokenizedHeaders(JSON_HEADERS),
        body: JSON.stringify(payload)
      }).then(checkErrorAndConvert(true));
    },

    createCompany(params) {
      const payload = cleanDeep(params);

      return fetch(`${getApiUrl()}/v2/companies`, {
        method: 'POST',
        headers: getTokenizedHeaders(JSON_HEADERS),
        body: JSON.stringify(payload)
      }).then(checkErrorAndConvert(true));
    },

    getCompanyDetail(id) {
      return fetch(`${getApiUrl()}/v2/companies/${id}`, {
        method: 'GET',
        headers: getTokenizedHeaders()
      }).then(res => {
        if (!res.ok) {
          throw {
            name: UNHANDLED_SERVER_ERROR,
            code: res.status
          };
        }
        return res.json();
      });
    },

    getCompanyInfo(id) {
      return fetch(`${getApiUrl()}/v2/companies/${id}`, {
        method: 'GET',
        headers: getTokenizedHeaders()
      }).then(checkErrorAndConvert(true));
    },

    updateCompany({ companyId, params }) {
      return fetch(`${getApiUrl()}/v2/companies/${companyId}`, {
        method: 'PUT',
        headers: getTokenizedHeaders(JSON_HEADERS),
        body: JSON.stringify(params)
      }).then(res => {
        return checkErrorAndConvert(true)(res).then(enhanceUserInfo);
      });
    },

    getCurrentContract(companyId) {
      return fetch(`${getApiUrl()}/v2/companies/${companyId}/contract/current`, {
        method: 'GET',
        headers: getTokenizedHeaders()
      }).then(res => {
        if (!res.ok) {
          if (res.status === 404) {
            return null;
          }
          throw {
            type: CONTRACTS_GET_CURRENT_CONTRACT_FAILED,
            code: res.status
          };
        }
        return res.json().then(enhanceContract);
      });
    },

    saveContract({ companyId, values }) {
      let payload = {
        businessCarSharing: values.businessCarSharing,
        contractEnd: safe(() => formatDate(values.endDate)) || values.contractEnd,
        contractStart: safe(() => formatDate(values.startDate)) || values.contractStart,
        name: values.name,
        privateCarSharing: values.privateCarSharing,
        reference: values.reference,
        rideSharing: values.rideSharing,
        preBooking: values.preBooking,
        interfaceConfig: values.interfaceConfigDto,
        flexibleSearch: values.flexibleSearch,
        // used for updates
        id: values.id
      };

      let method, url;
      if (values.id) {
        method = 'PUT';
        url = `${getApiUrl()}/v2/contracts/${values.id}`;
      } else {
        method = 'POST';
        url = `${getApiUrl()}/v2/companies/${companyId}/contracts`;
      }

      return fetch(url, {
        method: method,
        headers: getTokenizedHeaders(JSON_HEADERS),
        body: JSON.stringify(payload)
      }).then(res => checkErrorAndConvert(true)(res).then(enhanceContract));
    },

    saveSsoConfiguration({ companyId, values }) {
      let payload = {
        ...values
      };

      return fetch(`${getApiUrl()}/v2/companies/${companyId}/sso-configuration`, {
        method: 'PUT',
        headers: getTokenizedHeaders(JSON_HEADERS),
        body: JSON.stringify(payload)
      }).then(checkErrorAndConvert(true));
    },

    migrateToStripe(companyId) {
      return fetch(`${getApiUrl()}/v2/companies/${companyId}/payment-provider-migration`, {
        method: 'POST',
        headers: getTokenizedHeaders(JSON_HEADERS)
      }).then(checkErrorAndConvert(true));
    }
  },

  subCompanies: {
    getList(companyId) {
      return fetch(`${getApiUrl()}/v2/companies/${companyId}/sub-companies`, {
        method: 'GET',
        headers: getTokenizedHeaders()
      }).then(res => {
        return checkErrorAndConvert(false)(res).then(enhanceCollectionOfUserInfos);
      });
    },

    getAllList(subCompanyIds) {
      return fetch(`${getApiUrl()}/v2/companies/sub-companies`, {
        method: 'POST',
        headers: getTokenizedHeaders(JSON_HEADERS),
        body: JSON.stringify(subCompanyIds)
      }).then(res => {
        if (!res.ok) {
          throw {
            type: UNHANDLED_SERVER_ERROR,
            code: res.status
          };
        }
        return res.json().then(enhanceCollectionOfUserInfos);
      });
    },

    getSubCompanyDetails(subCompanyId) {
      return fetch(`${getApiUrl()}/v2/sub-companies/${subCompanyId}`, {
        method: 'GET',
        headers: getTokenizedHeaders()
      }).then(res => {
        if (!res.ok) {
          throw {
            name: UNHANDLED_SERVER_ERROR,
            code: res.status
          };
        }
        return res.json().then(enhanceUserInfo);
      });
    },

    createSubCompany({ companyId, params }) {
      let payload = {};

      payload = Object.assign(
        {},
        payload,
        _pick(params, [
          'name',
          'address',
          'email',
          'secondaryEmail',
          'phoneNumber',
          'secondaryPhoneNumber',
          'configurationId',
          'agencyCode',
          'logoUrl',
          'externalId'
        ])
      );

      return fetch(`${getApiUrl()}/v2/companies/${companyId}/sub-companies`, {
        method: 'POST',
        headers: getTokenizedHeaders(JSON_HEADERS),
        body: JSON.stringify(payload)
      }).then(checkErrorAndConvert(true));
    },

    updateSubCompany({ subCompanyId, params }) {
      let payload = {};

      payload = Object.assign(
        {},
        payload,
        _pick(params, [
          'name',
          'address',
          'email',
          'secondaryEmail',
          'subCompanyId',
          'phoneNumber',
          'secondaryPhoneNumber',
          'configurationId',
          'agencyCode',
          'logoUrl',
          'externalId'
        ])
      );

      return fetch(`${getApiUrl()}/v2/sub-companies/${subCompanyId}`, {
        method: 'PUT',
        headers: getTokenizedHeaders(JSON_HEADERS),
        body: JSON.stringify(payload)
      }).then(checkErrorAndConvert(true));
    }
  },

  sites: {
    getList(companyId) {
      return fetch(`${getApiUrl()}/v2/companies/${companyId}/sites`, {
        method: 'GET',
        headers: getTokenizedHeaders()
      }).then(res => checkErrorAndConvert(true)(res).then(enhanceCollectionOfUserInfos));
    },

    getSubCompanyList(subCompanyId) {
      return fetch(`${getApiUrl()}/v2/sub-companies/${subCompanyId}/sites`, {
        method: 'GET',
        headers: getTokenizedHeaders()
      }).then(res => {
        if (!res.ok) {
          throw {
            type: UNHANDLED_SERVER_ERROR,
            code: res.status
          };
        }
        return res.json().then(enhanceCollectionOfUserInfos);
      });
    },

    createSite({ subCompanyId, params }) {
      return fetch(`${getApiUrl()}/v2/sub-companies/${subCompanyId}/sites`, {
        method: 'POST',
        headers: getTokenizedHeaders(JSON_HEADERS),
        body: JSON.stringify(params)
      }).then(checkErrorAndConvert(false));
    },

    getSiteDetails(siteId) {
      return fetch(`${getApiUrl()}/v2/sites/${siteId}`, {
        method: 'GET',
        headers: getTokenizedHeaders()
      }).then(res => {
        if (!res.ok) {
          throw {
            name: UNHANDLED_SERVER_ERROR,
            code: res.status
          };
        }
        return res.json().then(enhanceUserInfo);
      });
    },

    updateSite({ siteId, params }) {
      return fetch(`${getApiUrl()}/v2/sites/${siteId}`, {
        method: 'PUT',
        headers: getTokenizedHeaders(JSON_HEADERS),
        body: JSON.stringify(params)
      }).then(checkErrorAndConvert(false));
    }
  },

  pricing: {
    getTimeSlots({ companyId, query }) {
      return fetch(`${getApiUrl()}/v2/companies/${companyId}/pricingSlots${addQuery(query)}`, {
        method: 'GET',
        headers: getTokenizedHeaders()
      }).then(checkErrorAndConvert(false));
    },

    getCancellationFees({ companyId, categoryId, memberTypeId }) {
      let params = `companyId=${companyId}&vehicleCategoryId=${categoryId}&memberTypeId=${memberTypeId}`;
      let url = `${getApiUrl()}/v2/pricing/cancellation-fees?${params}`;

      return fetch(url, {
        method: 'GET',
        headers: getTokenizedHeaders()
      }).then(res => {
        if (!res.ok) {
          throw {
            type: UNHANDLED_SERVER_ERROR,
            code: res.status
          };
        }
        return res.json();
      });
    },

    saveCancellationFees(params) {
      let payload = {
        context: params.context,
        rates: params.rates
      };

      return fetch(`${getApiUrl()}/v2/pricing/cancellation-fees`, {
        method: 'POST',
        headers: getTokenizedHeaders(JSON_HEADERS),
        body: JSON.stringify(payload)
      }).then(res => {
        if (!res.ok) {
          throw {
            type: UNHANDLED_SERVER_ERROR,
            code: res.status
          };
        }
        return res.json();
      });
    },

    getCategoryTransversalPrices({ companyId, query }) {
      return fetch(`${getApiUrl()}/v2/companies/${companyId}/pricing${addQuery(query)}`, {
        method: 'GET',
        headers: getTokenizedHeaders()
      }).then(res => {
        if (!res.ok) {
          throw {
            type: UNHANDLED_SERVER_ERROR,
            code: res.status
          };
        }
        if (res.status === 204) {
          return null;
        }
        return res.json();
      });
    },

    sendTransversalPricing({ companyId, vehicleCategoryId, memberTypeId, values }) {
      let payload = {
        vehicleCategoryId,
        lateFeePrice: values.lateFeePrice,
        latePricePerHour: values.latePricePerHour,
        freeKmPerDay: values.freeKmPerDay,
        pricePerKm: values.pricePerKm,
        lateFeeGraceInMin: values.lateFeeGraceInMin,
        minimumDurationPrice: values.minimumDurationPrice,
        memberTypeId
      };

      return fetch(`${getApiUrl()}/v2/companies/${companyId}/pricing`, {
        method: 'POST',
        headers: getTokenizedHeaders(JSON_HEADERS),
        body: JSON.stringify(payload)
      }).then(checkErrorAndConvert(false));
    },

    sendTimeSlots({ companyId, usage, vehicleCategoryId, values, memberTypeId }) {
      let payload = { usage, memberTypeId };

      payload.timeSlots = values.map(item => {
        let timeSlot;

        if (item.type !== TIME_SLOT_TYPE_PACKAGE_FOR_DURATION) {
          timeSlot = {
            interval: {
              fromDay: item.fromDayOfWeek,
              fromTime: item.fromHour + ':' + item.fromMinutes,
              toDay: item.toDayOfWeek,
              toTime: item.toHour + ':' + item.toMinutes
            },
            type: item.type
          };
        } else {
          timeSlot = {
            duration: item.duration,
            type: item.type
          };
        }

        if (!isEmpty(item.tag)) {
          timeSlot.tag = item.tag;
        }

        if (usage === TIME_SLOT_USAGE_PRIVATE) {
          timeSlot.price = isEmpty(item.price) ? undefined : Number(item.price);
        }

        return timeSlot;
      });

      payload.vehicleCategoryId = vehicleCategoryId;

      return fetch(`${getApiUrl()}/v2/companies/${companyId}/pricingSlots`, {
        method: 'POST',
        headers: getTokenizedHeaders(JSON_HEADERS),
        body: JSON.stringify(payload)
      }).then(checkErrorAndConvert(true));
    },

    copyPriceslots({ companyId, sourceMemberTypeId, targetMemberTypeId }) {
      let payload = {
        sourceMemberTypeId,
        targetMemberTypeId
      };

      return fetch(`${getApiUrl()}/v2/companies/${companyId}/copy-pricing`, {
        method: 'POST',
        headers: getTokenizedHeaders(JSON_HEADERS),
        body: JSON.stringify(payload)
      }).then(res => {
        if (res.status !== 204) {
          throw {
            type: UNHANDLED_SERVER_ERROR,
            code: res.status
          };
        }
      });
    }
  },

  parkings: {
    getList(siteId) {
      return fetch(`${getApiUrl()}/v2/sites/${siteId}/parkings`, {
        method: 'GET',
        headers: getTokenizedHeaders()
      }).then(checkErrorAndConvert(true));
    },
    getCompanyParkings(companyId) {
      return fetch(`${getApiUrl()}/v2/parkings/companies/${companyId}/`, {
        method: 'GET',
        headers: getTokenizedHeaders()
      }).then(checkErrorAndConvert(true));
    },
    getEntities(subCompanyId = false) {
      const endpoint = subCompanyId
        ? `${getApiUrl()}/v2/parkings/sub-companies/${subCompanyId}`
        : `${getApiUrl()}/v2/parkings/sub-companies`;

      return fetch(endpoint, {
        method: 'GET',
        headers: getTokenizedHeaders()
      }).then(res => {
        if (!res.ok) {
          throw {
            type: UNHANDLED_SERVER_ERROR,
            code: res.status
          };
        }
        return res.json();
      });
    },

    createParking({ siteId, params }) {
      return fetch(`${getApiUrl()}/v2/sites/${siteId}/parkings`, {
        method: 'POST',
        headers: getTokenizedHeaders(JSON_HEADERS),
        body: JSON.stringify(params)
      }).then(res => {
        return res.json().then(result => {
          if (!result.message && !result.status) {
            return result;
          } else {
            return Promise.reject({
              type: UNHANDLED_SERVER_ERROR,
              code: result.status,
              body: result
            });
          }
        });
      });
    },

    getParkingDetails(parkingId) {
      return fetch(`${getApiUrl()}/v2/parkings/${parkingId}/complete`, {
        method: 'GET',
        headers: getTokenizedHeaders()
      }).then(res => {
        return checkErrorAndConvert(false)(res).then(enhanceParkings);
      });
    },

    getBankHolidays({ start, end }) {
      return fetch(`${getApiUrl()}/v2/parkings/bank-holidays?start=${start}&end=${end}`, {
        method: 'GET',
        headers: getTokenizedHeaders()
      }).then(res => {
        if (!res.ok) {
          throw {
            name: UNHANDLED_SERVER_ERROR,
            code: res.status
          };
        }
        return res.json();
      });
    },

    updateParking({ parkingId, params }) {
      return fetch(`${getApiUrl()}/v2/parkings/${parkingId}`, {
        method: 'PUT',
        headers: getTokenizedHeaders(JSON_HEADERS),
        body: JSON.stringify(params)
      }).then(res => {
        return res.json().then(result => {
          if (!result.message && !result.status) {
            return result;
          } else {
            return Promise.reject({
              type: UNHANDLED_SERVER_ERROR,
              code: result.status,
              body: result
            });
          }
        });
      });
    }
  },

  colors: {
    getList() {
      return fetch(`${getApiUrl()}/v2/colors`, {
        method: 'GET',
        headers: getTokenizedHeaders()
      }).then(checkErrorAndConvert(true));
    },

    getColor(colorId) {
      return fetch(`${getApiUrl()}/v2/colors/${colorId}`, {
        method: 'GET',
        headers: getTokenizedHeaders()
      }).then(checkErrorAndConvert(true));
    },

    createColor(params) {
      return fetch(`${getApiUrl()}/v2/colors`, {
        method: 'POST',
        headers: getTokenizedHeaders(JSON_HEADERS),
        body: JSON.stringify(params)
      }).then(checkErrorAndConvert(true));
    },

    updateColor({ id, params }) {
      return fetch(`${getApiUrl()}/v2/colors/${id}`, {
        method: 'PUT',
        headers: getTokenizedHeaders(JSON_HEADERS),
        body: JSON.stringify(params)
      }).then(checkErrorAndConvert(true));
    }
  },

  appBrands: {
    getAll() {
      return fetch(`${getApiUrl()}/v2/account-brands`, {
        method: 'GET',
        headers: getTokenizedHeaders()
      }).then(checkErrorAndConvert(true));
    },

    createBrand(params) {
      return fetch(`${getApiUrl()}/v2/account-brands`, {
        method: 'POST',
        headers: getTokenizedHeaders(JSON_HEADERS),
        body: JSON.stringify(params)
      }).then(checkErrorAndConvert(true));
    },

    updateBrand({ id, params }) {
      return fetch(`${getApiUrl()}/v2/account-brands/${id}/name?${serialize(params)}`, {
        method: 'PUT',
        headers: getTokenizedHeaders()
      }).then(checkErrorAndConvert(true));
    }
  },

  brands: {
    getList() {
      return fetch(`${getApiUrl()}/v2/brands`, {
        method: 'GET',
        headers: getTokenizedHeaders()
      }).then(checkErrorAndConvert(true));
    },

    createBrand(params) {
      return fetch(`${getApiUrl()}/v2/brands`, {
        method: 'POST',
        headers: getTokenizedHeaders(JSON_HEADERS),
        body: JSON.stringify(params)
      }).then(checkErrorAndConvert(true));
    },

    getBrand(brandId) {
      return fetch(`${getApiUrl()}/v2/brands/${brandId}`, {
        method: 'GET',
        headers: getTokenizedHeaders()
      }).then(checkErrorAndConvert(true));
    },

    updateBrand({ id, params }) {
      return fetch(`${getApiUrl()}/v2/brands/${id}`, {
        method: 'PUT',
        headers: getTokenizedHeaders(JSON_HEADERS),
        body: JSON.stringify(params)
      }).then(checkErrorAndConvert(true));
    }
  },

  categories: {
    getCategories() {
      return fetch(`${getApiUrl()}/v2/categories`, {
        method: 'GET',
        headers: getTokenizedHeaders()
      }).then(checkErrorAndConvert(true));
    },

    getCategory(id) {
      return fetch(`${getApiUrl()}/v2/categories/${id}`, {
        method: 'GET',
        headers: getTokenizedHeaders()
      }).then(checkErrorAndConvert(true));
    },

    createCategory(params) {
      return fetch(`${getApiUrl()}/v2/categories`, {
        method: 'POST',
        headers: getTokenizedHeaders(JSON_HEADERS),
        body: JSON.stringify(params)
      }).then(checkErrorAndConvert(true));
    },

    updateCategory({ id, params }) {
      return fetch(`${getApiUrl()}/v2/categories/${id}`, {
        method: 'PUT',
        headers: getTokenizedHeaders(JSON_HEADERS),
        body: JSON.stringify(params)
      }).then(checkErrorAndConvert(true));
    }
  },

  models: {
    getList(brandId) {
      return fetch(`${getApiUrl()}/v2/brands/${brandId}/models`, {
        method: 'GET',
        headers: getTokenizedHeaders()
      }).then(checkErrorAndConvert(true));
    },

    createModel({ id, params }) {
      return fetch(`${getApiUrl()}/v2/brands/${id}/models`, {
        method: 'POST',
        headers: getTokenizedHeaders(JSON_HEADERS),
        body: JSON.stringify(params)
      }).then(checkErrorAndConvert(true));
    },

    getModel(modelId) {
      return fetch(`${getApiUrl()}/v2/models/${modelId}`, {
        method: 'GET',
        headers: getTokenizedHeaders()
      }).then(checkErrorAndConvert(true));
    },

    updateModel({ id, params }) {
      return fetch(`${getApiUrl()}/v2/models/${id}`, {
        method: 'PUT',
        headers: getTokenizedHeaders(JSON_HEADERS),
        body: JSON.stringify(params)
      }).then(checkErrorAndConvert(true));
    }
  },

  versions: {
    getList(modelId) {
      return fetch(`${getApiUrl()}/v2/models/${modelId}/versions`, {
        method: 'GET',
        headers: getTokenizedHeaders()
      }).then(checkErrorAndConvert(true));
    },

    createVersion({ id, params }) {
      return fetch(`${getApiUrl()}/v2/models/${id}/versions`, {
        method: 'POST',
        headers: getTokenizedHeaders(JSON_HEADERS),
        body: JSON.stringify(params)
      }).then(checkErrorAndConvert(true));
    },

    getVersion(versionId) {
      return fetch(`${getApiUrl()}/v2/versions/${versionId}`, {
        method: 'GET',
        headers: getTokenizedHeaders()
      }).then(checkErrorAndConvert(true));
    },

    updateVersion({ id, params }) {
      return fetch(`${getApiUrl()}/v2/versions/${id}`, {
        method: 'PUT',
        headers: getTokenizedHeaders(JSON_HEADERS),
        body: JSON.stringify(params)
      }).then(checkErrorAndConvert(true));
    }
  },

  feedbacks: {
    getList({ params }) {
      let payload;

      if (!params) {
        payload = apiParams.default;
      } else {
        if (params.page) {
          payload = {
            page: {
              number: params.page.number,
              size: params.page.size
            }
          };
        }

        if (params.sort) {
          payload.sort = {
            property: params.sort.property,
            direction: params.sort.isDescending ? SORT_DESC : SORT_ASC
          };
        }

        payload = Object.assign(
          {},
          payload,
          _pick(params, [
            'bookingId',
            'memberEmail',
            'memberName',
            'plateNumber',
            'withCleanlinessProblems',
            'withDamagesDeclared',
            'status',
            'companyIds',
            'subCompanyIds'
          ])
        );
      }

      return fetch(`${getApiUrl()}/v2/reports/search`, {
        method: 'POST',
        headers: getTokenizedHeaders(JSON_HEADERS),
        body: JSON.stringify(payload)
      }).then(res => {
        if (!res.ok) {
          throw {
            type: UNHANDLED_SERVER_ERROR,
            code: res.status
          };
        }
        return res.json().then(enhancePaginationInfos);
      });
    },

    getFeedbackDetail(id) {
      return fetch(`${getApiUrl()}/v2/reports/${id}`, {
        method: 'GET',
        headers: getTokenizedHeaders()
      }).then(res => {
        if (!res.ok) {
          throw {
            name: UNHANDLED_SERVER_ERROR,
            code: res.status
          };
        }
        return res.json();
      });
    },

    changeStatusFeedback(feedbackId) {
      let payload = {
        feedbackId
      };

      return fetch(`${getApiUrl()}/v2/feedbacks/${feedbackId}/manage`, {
        method: 'PUT',
        headers: getTokenizedHeaders(JSON_HEADERS),
        body: JSON.stringify(payload)
      }).then(res => {
        if (!res.ok) {
          throw {
            type: UNHANDLED_SERVER_ERROR,
            code: res.status
          };
        }
      });
    },

    updateFeedbackStatus({ bookingId, status }) {
      return fetch(`${getApiUrl()}/v2/reports/${bookingId}/status/${status}`, {
        method: 'PUT',
        headers: getTokenizedHeaders(JSON_HEADERS)
      }).then(res => {
        if (!res.ok) {
          throw {
            type: UNHANDLED_SERVER_ERROR,
            code: res.status
          };
        }
        return res.json();
      });
    },

    addComment({ params }) {
      return fetch(`${getApiUrl()}/v2/reports/remark`, {
        method: 'POST',
        headers: getTokenizedHeaders(JSON_HEADERS),
        body: JSON.stringify(params)
      }).then(res => {
        if (!res.ok) {
          throw {
            type: UNHANDLED_SERVER_ERROR,
            code: res.status
          };
        }
        return res.json();
      });
    }
  },

  invoices: {
    preview({ companyId, subCompanyId, language }) {
      let params = { companyId, language };
      trySet(params, 'subCompanyId', subCompanyId);
      params = serialize(params);

      return fetch(`${getApiUrl()}/v2/invoices/fake?${params}`, {
        method: 'GET',
        headers: getTokenizedHeaders(JSON_HEADERS)
      }).then(checkErrorAndConvert(true));
    },

    getDmsErrors(bookingId) {
      return fetch(`${getApiUrl()}/v3/dms-invoices/failure-responses/bookings/${bookingId}`, {
        method: 'GET',
        headers: getTokenizedHeaders(JSON_HEADERS)
      }).then(checkErrorAndConvert(true));
    },

    getItalianStatus(bookingId) {
      return fetch(`${getApiUrl()}/v2/invoices/italian-invoices?bookingId=${bookingId}`, {
        method: 'GET',
        headers: getTokenizedHeaders(JSON_HEADERS)
      }).then(checkErrorAndConvert(true));
    },

    getParams(companyId) {
      return fetch(`${getApiUrl()}/v2/invoicing-parameters/${companyId}`, {
        method: 'GET',
        headers: getTokenizedHeaders(JSON_HEADERS)
      }).then(checkErrorAndConvert());
    },

    updateParams({ companyId, params }) {
      const data = invoicingParamsEncode(params);

      return fetch(`${getApiUrl()}/v2/invoicing-parameters/${companyId}`, {
        method: 'PUT',
        headers: getTokenizedHeaders(JSON_HEADERS),
        body: JSON.stringify(data)
      }).then(checkErrorAndConvert());
    },

    createParams({ params }) {
      const data = invoicingParamsEncode(params);

      return fetch(`${getApiUrl()}/v2/invoicing-parameters`, {
        method: 'POST',
        headers: getTokenizedHeaders(JSON_HEADERS),
        body: JSON.stringify(data)
      }).then(checkErrorAndConvert());
    },

    getVatRateParam(companyId) {
      return fetch(`${getApiUrl()}/v2/vat-rates/${companyId}`, {
        method: 'GET',
        headers: getTokenizedHeaders(JSON_HEADERS)
      }).then(checkErrorAndConvert());
    },

    updateVatRateParam({ companyId, param }) {
      return fetch(`${getApiUrl()}/v2/vat-rates/${companyId}`, {
        method: 'PUT',
        headers: getTokenizedHeaders(JSON_HEADERS),
        body: JSON.stringify(param)
      }).then(checkErrorAndConvert());
    },

    createVatRateParam({ param }) {
      return fetch(`${getApiUrl()}/v2/vat-rates`, {
        method: 'POST',
        headers: getTokenizedHeaders(JSON_HEADERS),
        body: JSON.stringify(param)
      }).then(checkErrorAndConvert());
    },

    // Return the list of accounting documents for a booking
    getListForBooking(bookingId) {
      return fetch(`${getApiUrl()}/v2/invoices?bookingId=${bookingId}`, {
        method: 'GET',
        headers: getTokenizedHeaders(JSON_HEADERS)
      }).then(res => {
        if (!res.ok) {
          throw {
            type: UNHANDLED_SERVER_ERROR,
            code: res.status
          };
        }
        return res.json();
      });
    },

    getList({ params }) {
      let query;

      if (!params) {
        query = apiParams.default;
      } else {
        query = serialize(params);
      }

      return fetch(`${getApiUrl()}/v2/invoices/search?${query}`, {
        method: 'GET',
        headers: getTokenizedHeaders(JSON_HEADERS)
      }).then(res => {
        if (!res.ok) {
          throw {
            type: UNHANDLED_SERVER_ERROR,
            code: res.status
          };
        }

        return res.json().then(enhanceNewPaginationInfos, enhanced2SortingOptions(params));
      });
    },

    createInvoice({ invoiceItems, useBookingDamageDeposit, bookingId }) {
      let payload = {
        items: _map(invoiceItems, item => {
          return _pick(item, ['description', 'quantity', 'pricePerUnitWithVat']);
        }),
        bookingId,
        useBookingDamageDeposit
      };

      return fetch(`${getApiUrl()}/v2/invoices/miscellaneous`, {
        method: 'POST',
        headers: getTokenizedHeaders(JSON_HEADERS),
        body: JSON.stringify(payload)
      }).then(res => {
        if (!res.ok) {
          throw {
            type: UNHANDLED_SERVER_ERROR,
            code: res.status
          };
        }
        return res.json();
      });
    },

    payment(invoiceId) {
      if (invoiceId)
        return fetch(`${getApiUrl()}/v2/invoices/${invoiceId}/pay`, {
          method: 'POST',
          headers: getTokenizedHeaders(JSON_HEADERS)
        }).then(res => {
          if (!res.ok)
            throw {
              type: UNHANDLED_SERVER_ERROR,
              code: res.status,
              body: res
            };
          return true;
        });
      else return false;
    },

    exportCSV({ params = {} }) {
      const query = Object.keys(params).length ? serialize(params) : apiParams.default;

      return fetch(`${getApiUrl()}/v2/invoices/export?${query}`, {
        method: 'GET',
        headers: getTokenizedHeaders(JSON_HEADERS)
      }).then(res => {
        if (!res.ok) {
          throw {
            type: UNHANDLED_SERVER_ERROR,
            code: res.status
          };
        }
        return res.text();
      });
    }
  },

  applications: {
    getAll() {
      return fetch(`${getApiUrl()}/v2/applications`, {
        method: 'GET',
        headers: getTokenizedHeaders(JSON_HEADERS)
      }).then(res => {
        if (!res.ok) {
          throw {
            type: UNHANDLED_SERVER_ERROR,
            code: res.status
          };
        }
        return res.json();
      });
    }
  },

  configuration: {
    getList(paramsObj) {
      let payload;

      if (!paramsObj) {
        payload = apiParams.default;
      } else {
        if (paramsObj.page) {
          payload = {
            page: {
              number: paramsObj.page.number,
              size: paramsObj.page.size || apiParams.default.page.size
            }
          };
        }
      }

      return fetch(`${getApiUrl()}/v2/company-configurations/search`, {
        method: 'POST',
        headers: getTokenizedHeaders(JSON_HEADERS),
        body: JSON.stringify(payload)
      }).then(res => {
        if (!res.ok) {
          throw {
            type: UNHANDLED_SERVER_ERROR,
            code: res.status
          };
        }
        return res.json().then(enhancePaginationInfos);
      });
    },

    createConfiguration(params) {
      return fetch(`${getApiUrl()}/v2/company-configurations`, {
        method: 'POST',
        headers: getTokenizedHeaders(JSON_HEADERS),
        body: JSON.stringify(params)
      }).then(res => {
        if (!res.ok) {
          throw {
            type: UNHANDLED_SERVER_ERROR,
            code: res.status
          };
        }
        return res.json();
      });
    },

    getConfigurationDetail(id) {
      return fetch(`${getApiUrl()}/v2/company-configurations/${id}`, {
        method: 'GET',
        headers: getTokenizedHeaders()
      }).then(res => {
        if (!res.ok) {
          throw {
            name: UNHANDLED_SERVER_ERROR,
            code: res.status
          };
        }
        return res.json();
      });
    },

    updateConfiguration({ configurationId, params }) {
      return fetch(`${getApiUrl()}/v2/company-configurations/${configurationId}`, {
        method: 'PUT',
        headers: getTokenizedHeaders(JSON_HEADERS),
        body: JSON.stringify(params)
      }).then(res => {
        if (!res.ok) {
          throw {
            type: UNHANDLED_SERVER_ERROR,
            code: res.status
          };
        }
        return res.json();
      });
    }
  },

  hotlines: {
    getHotlines() {
      return fetch(`${getApiUrl()}/v2/hotlines`, {
        method: 'GET',
        headers: getTokenizedHeaders()
      }).then(res => {
        if (!res.ok) {
          throw {
            type: UNHANDLED_SERVER_ERROR,
            code: res.status
          };
        }
        return res.json();
      });
    },

    getHotlineDetail(hotlineId) {
      return fetch(`${getApiUrl()}/v2/hotlines/${hotlineId}`, {
        method: 'GET',
        headers: getTokenizedHeaders()
      }).then(res => {
        if (!res.ok) {
          throw {
            type: UNHANDLED_SERVER_ERROR,
            code: res.status
          };
        }
        return res.json();
      });
    },

    createHotline(params) {
      return fetch(`${getApiUrl()}/v2/hotlines`, {
        method: 'POST',
        headers: getTokenizedHeaders(JSON_HEADERS),
        body: JSON.stringify(params)
      }).then(res => {
        if (!res.ok) {
          throw {
            type: HOTLINES_CREATE_HOTLINE_FAILED,
            code: res.status,
            body: res
          };
        }
        return res.json();
      });
    },

    updateHotline({ hotlineId, params }) {
      return fetch(`${getApiUrl()}/v2/hotlines/${hotlineId}`, {
        method: 'PUT',
        headers: getTokenizedHeaders(JSON_HEADERS),
        body: JSON.stringify(params)
      }).then(res => {
        if (!res.ok) {
          throw {
            type: HOTLINES_UPDATE_HOTLINE_FAILED,
            code: res.status
          };
        }
        return res.json();
      });
    }
  },

  file: {
    getFile(fileId) {
      return fetch(`${getApiUrl()}/v2/files/${fileId}`, {
        headers: getTokenizedHeaders()
      }).then(res => {
        if (!res.ok) {
          throw {
            type: UNHANDLED_SERVER_ERROR,
            code: res.status
          };
        }
        return res.json();
      });
    },

    getFileContent(id) {
      return fetch(`${getApiUrl()}/v2/files/${id}`, {
        headers: getTokenizedHeaders()
      }).then(checkErrorAndConvert(true));
    },

    getFileUrl(fileId) {
      return fetch(`${getApiUrl()}/v2/files/${fileId}/url`, {
        headers: getTokenizedHeaders()
      }).then(res =>
        parseHeader(res, afterWait => {
          if (!res.ok) {
            const errorObj = {
              type: UNHANDLED_SERVER_ERROR,
              code: res.status
            };
            if (afterWait.developerMessage) errorObj.developerMessage = afterWait.developerMessage;
            throw errorObj;
          } else return afterWait;
        })
      );
    },

    getDamageReportFile(fileId) {
      return fetch(`${getApiUrl()}/v2/files/damage-report/${fileId}`, {
        headers: getTokenizedHeaders()
      }).then(res => {
        if (!res.ok) {
          throw {
            type: UNHANDLED_SERVER_ERROR,
            code: res.status
          };
        }
        return res.json();
      });
    },

    upload({ file, isPublicUpload = true }) {
      const formData = new FormData();
      formData.append('file', file);

      return fetch(`${getApiUrl()}/v3/files${addQuery({ isPublicUpload })}`, {
        method: 'POST',
        contentType: 'multipart/form-data',
        headers: getTokenizedHeaders(FORM_DATA_HEADERS),
        body: formData
      }).then(checkErrorAndConvert(true));
    },

    getPublicFile(fileId) {
      return fetch(`${getApiUrl()}/v2/files/public/${fileId}`, {
        headers: getTokenizedHeaders()
      }).then(checkErrorAndConvert(true));
    }
  },

  smartcards: {
    getList(params) {
      let payload;
      if (!params) {
        payload = apiParams.default;
      } else {
        if (params.page) {
          payload = {
            page: {
              number: params.page.number,
              size: params.page.size
            }
          };
        }

        payload.companyId = params.companyId;

        payload = Object.assign({}, payload, _pick(params, ['userLogin', 'companyIds']));
      }

      return fetch(`${getApiUrl()}/v2/smartcards/search`, {
        method: 'POST',
        headers: getTokenizedHeaders(JSON_HEADERS),
        body: JSON.stringify(payload)
      }).then(res => {
        if (!res.ok) {
          throw {
            type: UNHANDLED_SERVER_ERROR,
            code: res.status
          };
        }
        return res.json().then(enhancePaginationInfos);
      });
    },

    getDetail(id) {
      return fetch(`${getApiUrl()}/v2/smartcards/${id}`, {
        method: 'GET',
        headers: getTokenizedHeaders(JSON_HEADERS)
      }).then(res => {
        if (!res.ok) {
          throw {
            type: UNHANDLED_SERVER_ERROR,
            code: res.status
          };
        }
        return res.json();
      });
    },

    createSmartcard(data) {
      return fetch(`${getApiUrl()}/v2/smartcards`, {
        method: 'POST',
        headers: getTokenizedHeaders(JSON_HEADERS),
        body: JSON.stringify(data)
      }).then(res => {
        if (!res.ok) {
          throw {
            type: UNHANDLED_SERVER_ERROR,
            code: res.status
          };
        }
        return res.json();
      });
    },

    getDetailWithId(id) {
      return fetch(`${getApiUrl()}/v2/smartcards/cardId?cardId=${id}`, {
        method: 'GET',
        headers: getTokenizedHeaders(JSON_HEADERS)
      }).then(checkErrorAndConvert(true));
    },

    changeStatus(data) {
      return fetch(`${getApiUrl()}/v2/smartcards/${data.id}`, {
        method: 'PUT',
        headers: getTokenizedHeaders(JSON_HEADERS),
        body: JSON.stringify(data)
      }).then(res => {
        if (!res.ok) {
          throw {
            type: UNHANDLED_SERVER_ERROR,
            code: res.status
          };
        }
        return res.json();
      });
    },

    removeUser(smartcardId) {
      return fetch(`${getApiUrl()}/v2/smartcards/${smartcardId}/unlink`, {
        method: 'POST',
        headers: getTokenizedHeaders(JSON_HEADERS),
        body: JSON.stringify({ smartcardId: smartcardId })
      }).then(res => {
        if (!res.ok) {
          throw {
            type: UNHANDLED_SERVER_ERROR,
            code: res.status
          };
        }
        return res.json();
      });
    },

    assignUser(data) {
      return fetch(`${getApiUrl()}/v2/smartcards/${data.smartcardId}/link-user-id/${data.userId}`, {
        method: 'POST',
        headers: getTokenizedHeaders(JSON_HEADERS),
        body: JSON.stringify(data)
      }).then(res => {
        if (!res.ok) {
          throw {
            type: UNHANDLED_SERVER_ERROR,
            code: res.status
          };
        }
        return res.json();
      });
    }
  },

  smartcardsEvents: {
    getList(params) {
      let payload;
      if (!params) {
        payload = apiParams.default;
      } else {
        payload = {
          ...params
        };
      }

      return fetch(`${getApiUrl()}/v2/event-logs/search`, {
        method: 'POST',
        headers: getTokenizedHeaders(JSON_HEADERS),
        body: JSON.stringify(payload)
      }).then(res => {
        if (!res.ok) {
          throw {
            type: UNHANDLED_SERVER_ERROR,
            code: res.status
          };
        }
        return res.json().then(enhancePaginationInfos);
      });
    },

    getDetail(id) {
      return fetch(`${getApiUrl()}/v2/smartcards/${id}`, {
        method: 'GET',
        headers: getTokenizedHeaders(JSON_HEADERS)
      }).then(res => {
        if (!res.ok) {
          throw {
            type: UNHANDLED_SERVER_ERROR,
            code: res.status
          };
        }
        return res.json();
      });
    },

    removeUser(smartcardId) {
      return fetch(`${getApiUrl()}/v2/smartcards/${smartcardId}/unlink`, {
        method: 'POST',
        headers: getTokenizedHeaders(JSON_HEADERS),
        body: JSON.stringify({ smartcardId: smartcardId })
      }).then(res => {
        if (!res.ok) {
          throw {
            type: UNHANDLED_SERVER_ERROR,
            code: res.status
          };
        }
        return res.json();
      });
    },

    changeStatus(data) {
      return fetch(`${getApiUrl()}/v2/smartcards/${data.id}`, {
        method: 'PUT',
        headers: getTokenizedHeaders(JSON_HEADERS),
        body: JSON.stringify(data)
      }).then(res => {
        if (!res.ok) {
          throw {
            type: UNHANDLED_SERVER_ERROR,
            code: res.status
          };
        }
        return res.json();
      });
    }
  },

  memberTypes: {
    getList(payload) {
      const params = serialize(payload);

      return fetch(`${getApiUrl()}/v2/member-types?${params}`, {
        method: 'GET',
        headers: getTokenizedHeaders(JSON_HEADERS)
      }).then(res => {
        if (!res.ok) {
          throw {
            type: UNHANDLED_SERVER_ERROR,
            code: res.status
          };
        }
        return res.json();
      });
    },

    create(params) {
      return fetch(`${getApiUrl()}/v2/member-types`, {
        method: 'POST',
        headers: getTokenizedHeaders(JSON_HEADERS),
        body: JSON.stringify(params)
      }).then(checkErrorAndConvert(true));
    },

    update(params) {
      let id = params.id;
      return fetch(`${getApiUrl()}/v2/member-types/${id}`, {
        method: 'PUT',
        headers: getTokenizedHeaders(JSON_HEADERS),
        body: JSON.stringify(params)
      }).then(checkErrorAndConvert(true));
    },

    remove(id) {
      return fetch(`${getApiUrl()}/v2/member-types/${id}`, {
        method: 'DELETE',
        headers: getTokenizedHeaders(JSON_HEADERS),
        body: JSON.stringify(id)
      }).then(res => {
        if (!res.ok) {
          throw {
            type: UNHANDLED_SERVER_ERROR,
            code: res.status
          };
        }
      });
    }
  },

  search: {
    memberName(params) {
      return fetch(`${getApiUrl()}/v2/members/search`, {
        method: 'POST',
        headers: getTokenizedHeaders(JSON_HEADERS),
        body: JSON.stringify(params)
      })
        .then(checkErrorAndConvert(true))
        .then(x => {
          enhancePostMembersSearchFlatResult(x, false);
          return x;
        });
    },

    memberEmail(params) {
      return fetch(`${getApiUrl()}/v2/members/search`, {
        method: 'POST',
        headers: getTokenizedHeaders(JSON_HEADERS),
        body: JSON.stringify(params)
      })
        .then(checkErrorAndConvert(true))
        .then(x => {
          enhancePostMembersSearchFlatResult(x, false);
          return x;
        });
    },

    memberEmailAll(params) {
      return fetch(`${getApiUrl()}/v2/members/search?ignore_auth_subCompanies=true`, {
        method: 'POST',
        headers: getTokenizedHeaders(JSON_HEADERS),
        body: JSON.stringify(params)
      })
        .then(checkErrorAndConvert(true))
        .then(x => {
          enhancePostMembersSearchFlatResult(x, false);
          return x;
        });
    },

    bookings(params) {
      return fetch(`${getApiUrl()}/v2/bookings/search`, {
        method: 'POST',
        headers: getTokenizedHeaders(JSON_HEADERS),
        body: JSON.stringify(params)
      }).then(checkErrorAndConvert(true));
    },

    vehicles(params) {
      return fetch(`${getApiUrl()}/v2/vehicles/search`, {
        method: 'POST',
        headers: getTokenizedHeaders(JSON_HEADERS),
        body: JSON.stringify(params)
      }).then(checkErrorAndConvert(true));
    },

    companies(params) {
      return fetch(`${getApiUrl()}/v2/companies/search`, {
        method: 'POST',
        headers: getTokenizedHeaders(JSON_HEADERS),
        body: JSON.stringify(params)
      }).then(checkErrorAndConvert(true));
    }
  },

  bankouts: {
    updateBankDetails(params) {
      return fetch(`${getApiUrl()}/v3/payback/configs/bank-account-details`, {
        method: 'PUT',
        headers: getTokenizedHeaders(JSON_HEADERS),
        body: JSON.stringify(params)
      }).then(checkErrorAndConvert(true));
    },

    storeBankDetails(params) {
      return fetch(`${getApiUrl()}/v2/bankout-token`, {
        method: 'PUT',
        headers: getTokenizedHeaders(JSON_HEADERS),
        body: JSON.stringify(params)
      }).then(res => {
        if (!res.ok) {
          throw {
            type: UNHANDLED_SERVER_ERROR,
            code: res.status
          };
        }
        return res.json();
      });
    },

    createConfig(params) {
      return fetch(`${getApiUrl()}/v3/payback/configs`, {
        method: 'POST',
        headers: getTokenizedHeaders(JSON_HEADERS),
        body: JSON.stringify(params)
      }).then(checkErrorAndConvert(true));
    },

    updateConfig(params) {
      return fetch(`${getApiUrl()}/v3/payback/configs`, {
        method: 'PUT',
        headers: getTokenizedHeaders(JSON_HEADERS),
        body: JSON.stringify(params)
      }).then(checkErrorAndConvert(true));
    },

    getConfig(companyId) {
      return fetch(`${getApiUrl()}/v3/payback/configs?billingEntityId=${companyId}`, {
        method: 'GET',
        headers: getTokenizedHeaders(JSON_HEADERS)
      }).then(checkErrorAndConvert(true));
    },

    getCompanyPaymentDetails(companyId) {
      return fetch(`${getApiUrl()}/v2/bankout-token/companies/${companyId}`, {
        method: 'GET',
        headers: getTokenizedHeaders()
      }).then(res => {
        if (res.status === 404) {
          return {};
        }
        if (!res.ok) {
          throw {
            name: UNHANDLED_SERVER_ERROR,
            code: res.status
          };
        }
        return res.json();
      });
    },

    getList(params = apiParams.default.page) {
      params = decreasePageNumber(params);

      return fetch(`${getApiUrl()}/v3/payback/bankouts/search?${serialize(params)}`, {
        method: 'GET',
        headers: getTokenizedHeaders(JSON_HEADERS)
      }).then(res => {
        return checkErrorAndConvert(true)(res).then(data => {
          data = increasePageNumber(data);
          data = enhanceNewPaginationInfos(data);
          return data;
        });
      });
    },

    getDetail(id) {
      return fetch(`${getApiUrl()}/v3/payback/bankouts/${id}`, {
        method: 'GET',
        headers: getTokenizedHeaders(JSON_HEADERS)
      }).then(checkErrorAndConvert(true));
    },

    getPaybackList({ bankoutId, params }) {
      return fetch(`${getApiUrl()}/v3/payback/bankouts/${bankoutId}/paybacks?${serialize(params)}`, {
        method: 'GET',
        headers: getTokenizedHeaders(JSON_HEADERS)
      }).then(res => {
        return checkErrorAndConvert(true)(res).then(data => {
          data = enhanceNewPaginationInfos(data);
          return data;
        });
      });
    },

    retryBankoutExecution(id) {
      return fetch(`${getApiUrl()}/v3/payback/bankouts/${id}/execute/retry`, {
        method: 'POST',
        headers: getTokenizedHeaders(JSON_HEADERS)
      }).then(checkErrorAndConvert(true));
    },

    exportBankout(id) {
      return fetch(`${getApiUrl()}/v3/payback/bankout-export/${id}`, {
        method: 'GET',
        headers: getTokenizedHeaders(JSON_HEADERS)
      }).then(checkErrorAndConvert(true));
    },

    urlExportId(id) {
      return fetch(`${getApiUrl()}/v3/payback/bankout-export/url?exportId=${id}`, {
        method: 'GET',
        headers: getTokenizedHeaders(JSON_HEADERS)
      }).then(res => {
        if (res.ok) return res.text();
        else throw res;
      });
    },

    createStripeAccountLink(params) {
      const payload = cleanDeep(params);
      return fetch(`${getApiUrl()}/v2/payments/stripe/accounts`, {
        method: 'POST',
        headers: getTokenizedHeaders(JSON_HEADERS),
        body: JSON.stringify(payload)
      }).then(checkErrorAndConvert(true));
    },

    stripeAccountLinkRefresh(params) {
      const { companyGlideId, ...payload } = cleanDeep(params);
      return fetch(`${getApiUrl()}/v2/payments/stripe/accounts/${params.companyGlideId}/account-links`, {
        method: 'POST',
        headers: getTokenizedHeaders(JSON_HEADERS),
        body: JSON.stringify(payload)
      }).then(checkErrorAndConvert(true));
    },

    getStripeAccount(companyGlideId) {
      return fetch(`${getApiUrl()}/v2/payments/stripe/accounts/${companyGlideId}`, {
        method: 'GET',
        headers: getTokenizedHeaders(JSON_HEADERS)
      }).then(checkErrorAndConvert(true));
    },

    supressAccount(companyGlideId) {
      return fetch(`${getApiUrl()}/v2/payments/stripe/accounts/${companyGlideId}`, {
        method: 'DELETE',
        headers: getTokenizedHeaders(JSON_HEADERS)
      }).then(checkErrorAndConvert(true));
    }
  },

  voucher: {
    getGroups(params) {
      const page = _get(params, 'page');
      const sort = _get(params, 'sort');
      const sortProperty = _get(sort, 'property');

      const parsedParams = {
        page: {
          number: _get(page, 'number') || apiParams.default.page.number,
          size: _get(page, 'size') || apiParams.default.page.size
        }
      };

      trySet(parsedParams, 'superCompanyId', _get(params, 'superCompanyId'));
      trySet(parsedParams, 'name', _get(params, 'name'));
      trySet(parsedParams, 'toCreationDate', checkDate(_get(params, 'toCreationDate'), ISO_DATE_FLAT));
      trySet(parsedParams, 'fromCreationDate', checkDate(_get(params, 'fromCreationDate'), ISO_DATE_FLAT));
      trySet(parsedParams, 'voucherType', _get(params, 'voucherType'));
      trySet(parsedParams, 'voucherCode', _get(params, 'voucherCode'));

      if (sortProperty)
        parsedParams.sort = {
          property: sortProperty,
          direction: _get(sort, 'isDescending') ? SORT_DESC : SORT_ASC
        };

      return fetch(`${getApiUrl()}/v2/vouchers/groups/search`, {
        method: 'POST',
        headers: getTokenizedHeaders(JSON_HEADERS),
        body: JSON.stringify(parsedParams)
      }).then(res => {
        if (!res.ok) {
          throw {
            type: UNHANDLED_SERVER_ERROR,
            code: res.status,
            body: res
          };
        }
        return res.json().then(enhancePaginationInfosWithoutFacets);
      });
    },

    getVouchers(params) {
      return fetch(`${getApiUrl()}/v2/vouchers/search`, {
        method: 'POST',
        headers: getTokenizedHeaders(JSON_HEADERS),
        body: JSON.stringify(parseApiVoucherParams(params))
      }).then(res => {
        if (!res.ok) {
          throw {
            type: UNHANDLED_SERVER_ERROR,
            code: res.status,
            body: res
          };
        }
        return res.json().then(enhancePaginationInfosMore);
      });
    },

    export(params) {
      const parsedParams = parseApiVoucherParams(params);
      const str = JSON.stringify(parsedParams);
      const query = encodeURIComponent(str);

      return fetch(`${getApiUrl()}/v2/vouchers/export?request=${query}`, {
        method: 'GET',
        headers: getTokenizedHeaders()
      }).then(res => {
        if (!res.ok) {
          throw {
            type: UNHANDLED_SERVER_ERROR,
            code: res.status,
            body: res
          };
        }
        return res.text();
      });
    },

    revoke(voucherId) {
      if (voucherId)
        return fetch(`${getApiUrl()}/v2/vouchers/${voucherId}/revoke`, {
          method: 'POST',
          headers: getTokenizedHeaders(JSON_HEADERS)
        }).then(res => {
          if (!res.ok)
            throw {
              type: UNHANDLED_SERVER_ERROR,
              code: res.status,
              body: res
            };
          return true;
        });
      else return false;
    },

    createGroup(params) {
      return fetch(`${getApiUrl()}/v2/vouchers/groups`, {
        method: 'POST',
        headers: getTokenizedHeaders(JSON_HEADERS),
        body: JSON.stringify(params)
      }).then(res => {
        if (!res.ok) {
          throw {
            type: UNHANDLED_SERVER_ERROR,
            code: res.status,
            body: res
          };
        }
        return res.json();
      });
    },

    getVoucherGroupDetail(id) {
      return fetch(`${getApiUrl()}/v2/vouchers/groups/${id}`, {
        method: 'GET',
        headers: getTokenizedHeaders(JSON_HEADERS)
      }).then(res => {
        if (!res.ok) {
          throw {
            type: UNHANDLED_SERVER_ERROR,
            code: res.status,
            body: res
          };
        }
        return res.json();
      });
    },

    createCode(data) {
      return fetch(`${getApiUrl()}/v2/vouchers`, {
        method: 'POST',
        headers: getTokenizedHeaders(JSON_HEADERS),
        body: JSON.stringify(data)
      }).then(res => {
        if (!res.ok) {
          throw {
            type: UNHANDLED_SERVER_ERROR,
            code: res.status,
            body: res
          };
        }
        return res.json();
      });
    },

    getBookingsCountUsingVouchers(id) {
      return fetch(`${getApiUrl()}/v2/vouchers/groups/${id}/uses`, {
        method: 'GET',
        headers: getTokenizedHeaders(JSON_HEADERS)
      }).then(checkErrorAndConvert(false));
    }
  },

  vehiclePlanning: {
    getData(payload) {
      const params = serialize(payload);

      return fetch(`${getApiUrl()}/v2/vehicles/planning?${params}`, {
        method: 'GET',
        headers: getTokenizedHeaders(JSON_HEADERS)
      }).then(res => {
        if (!res.ok) {
          throw {
            type: UNHANDLED_SERVER_ERROR,
            code: res.status,
            body: res
          };
        }
        return res.json().then(data => enhancedCloseHoursData(data, payload.startDate));
      });
    }
  },

  currencies: {
    list() {
      return fetch(`${getApiUrl()}/v2/currencies`, {
        method: 'GET',
        headers: getTokenizedHeaders(JSON_HEADERS)
      }).then(res => {
        if (!res.ok) {
          throw {
            type: UNHANDLED_SERVER_ERROR,
            code: res.status,
            body: res
          };
        }
        return res.json();
      });
    }
  }
};

export default function ApiCaller(resource, method, params) {
  if (!Api[resource]) {
    throw new Error("Unkown resource: '" + resource + "'");
  }
  if (!Api[resource][method]) {
    throw new Error("Unkown method: '" + method + "' for resource '" + resource + "'");
  }

  return Api[resource][method](params);
}

export function initTokenHandlers(setToken, getToken) {
  _setToken = setToken;
  _getToken = getToken;
}

export function isAuthenticated() {
  return !!_getToken();
}
