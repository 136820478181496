import React, { Component, PropTypes } from 'react';
import Checkbox from 'material-ui/Checkbox';
import { safe } from '../../utils/utils';
import cs from 'classnames';

class LabeledCheckbox extends Component {
  constructor(props) {
    super(props);
    this.handleCheck = this.handleCheck.bind(this);
  }

  handleCheck() {
    const { field, onCheck } = this.props;
    const value = !field.value;

    field.onChange(value);
    onCheck && onCheck(value);
  }

  render() {
    const { disabled } = this.props;

    return (
      <div style={{ ...this.props.containerStyle }}>
        <div className={cs('checkboxMaterial', { disabled })}>
          <Checkbox
            id={this.props.id}
            disabled={disabled}
            label={this.props.children}
            onCheck={this.handleCheck}
            checked={!!safe(() => this.props.field.value)}
            labelStyle={{ color: 'rgba(0,0,0,.65)' }}
            {...this.props.checkboxProps}
          />
        </div>
      </div>
    );
  }
}

LabeledCheckbox.displayName = 'LabeledCheckbox';

LabeledCheckbox.propTypes = {
  children: PropTypes.element,
  disabled: PropTypes.any,
  onCheck: PropTypes.func,
  checkboxProps: PropTypes.any
};

export default LabeledCheckbox;
