import { getVehicleStatusesDates } from '../actions/vehicleStatuses-actions';

import {
  VEHICLE_STATUSES_CLEAR_LIST_DATA,
  VEHICLE_STATUSES_GET_FLEET_ERROR,
  VEHICLE_STATUSES_GET_FLEET_REQUEST,
  VEHICLE_STATUSES_GET_FLEET_SUCCESS,
  VEHICLE_STATUSES_SET_DATES,
  VEHICLE_STATUSES_SET_START_DATE,
  VEHICLE_STATUSES_SET_END_DATE,
  VEHICLE_STATUSES_GET_PIE_DATA_SUCCESS,
  VEHICLE_STATUSES_TOGGLE_REPORT_MODAL,
  VEHICLE_STATUSES_GET_EXPORT_REQUEST,
  VEHICLE_STATUSES_GET_EXPORT_FINISHED
} from '../constants/actionTypes-constants';

export default function vehicleStatuses(
  state = {
    vehicleStatusesDates: getVehicleStatusesDates(),
    openedReportFormModal: false,
    selectedPieData: {},
    metadata: {},
    urlParams: {},
    results: [],
    loadingError: false,
    loadingList: false,
    loadingData: false,
    reportStarted: false
  },
  action
) {
  switch (action.type) {
    case VEHICLE_STATUSES_SET_DATES:
      return {
        ...state,
        vehicleStatusesDates: getVehicleStatusesDates()
      };

    case VEHICLE_STATUSES_SET_START_DATE:
      return {
        ...state,
        vehicleStatusesDates: {
          ...state.vehicleStatusesDates,
          startDate: action.startDate
        },
        loadingData: true
      };

    case VEHICLE_STATUSES_SET_END_DATE:
      return {
        ...state,
        vehicleStatusesDates: {
          ...state.vehicleStatusesDates,
          endDate: action.endDate
        },
        loadingData: true
      };

    case VEHICLE_STATUSES_TOGGLE_REPORT_MODAL:
      return {
        ...state,
        openedReportFormModal: !state.openedReportFormModal
      };

    case VEHICLE_STATUSES_GET_PIE_DATA_SUCCESS:
      return {
        ...state,
        selectedPieData: action.data,
        loadingData: false
      };

    case VEHICLE_STATUSES_GET_FLEET_SUCCESS:
      return {
        ...state,
        metadata: action.metadata,
        results: action.results,
        urlParams: action.urlParams,
        loadingList: false
      };

    case VEHICLE_STATUSES_GET_FLEET_REQUEST:
      return {
        ...state,
        loadingError: false,
        loadingList: true
      };

    case VEHICLE_STATUSES_CLEAR_LIST_DATA:
      return {
        ...state,
        results: [],
        metadata: {}
      };

    case VEHICLE_STATUSES_GET_FLEET_ERROR:
      return {
        ...state,
        loadingError: true,
        urlParams: action.urlParams
      };

    case VEHICLE_STATUSES_GET_EXPORT_REQUEST:
      return {
        ...state,
        reportStarted: true
      };

    case VEHICLE_STATUSES_GET_EXPORT_FINISHED:
      return {
        ...state,
        reportStarted: false
      };

    default:
      return state;
  }
}
